import { FC, useEffect, useState } from 'react';

// hooks
// import useAppSelector from 'hooks/useAppSelector';

// components
import TextInput from 'components/ui/TextInput';
import ModifyButtons from '../ModifyButtons';
import ConfirmCodeForm from './ConfirmCodeForm/ConfirmCodeForm.component';
import EmailForm from './EmailForm/EmailForm.component';

// style
import { EmailBox } from './style/EditEmailBox.style';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Email box (edit form)
 * 
 * @author Alessio Grassi and Frank Berni
 * 
 * @returns JSX 
 */


const EditEmailBox: FC<{ userData: any }> = ({ userData }) => {

    // const { email } = useAppSelector(state => state.user);
    const [isUserModifyingEmail, setIsUserModifyingEmail] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>(undefined);

    const userIsModifyingEmailHandler = () => setIsUserModifyingEmail(true);
    const userIsNotModifyingEmailHandler = () => setIsUserModifyingEmail(false);
    
    useEffect(() => {
        
        if (!userData || userData === undefined) return setEmail("NO_DATA");

        setEmail(userData.federatedEmail);
    }, [userData]);
    
    const finalValue = ((email === 'NO_DATA') || (email === undefined)) ? undefined : email;
    const isLoading = (email === undefined);

    if (isLoading) return (
        <LoadingMask 
            size='medium'
            isCenter
            paddingTop='50px'   
        />
    );

    if (!isUserModifyingEmail) {

        return (
            <EmailBox>
                <TextInput
                    isDisabled
                    label='Email'
                    value={finalValue}
                    onChange={() => {}}
                />

                {/* <ModifyButtons
                    onModifyButtonClick={userIsModifyingEmailHandler}
                /> */}
            </EmailBox>
        );
    }

    return (
        <EmailBox isUserModifyingInput>
            <EmailForm onCancelButtonClick={userIsNotModifyingEmailHandler} />

            <ConfirmCodeForm onVerifyCodeSuccess={userIsNotModifyingEmailHandler} />
        </EmailBox>
	);
};

export default EditEmailBox;
