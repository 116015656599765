import { FC } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// assets
import { ReactComponent as SuperautoSVG } from './assets/superauto.svg';

// style
import {
    HighwayBox,
    Content,
    CTABox,
    CardTitle,
    BoldText,
    HighwayButton
} from './style/HighwayCard.style';


const HighwayCard: FC<{}> = () => {

    const navigate = useNavigate();

    const highwayButtonHandler = () => {
        navigate('/reservedArea/highway-page');
    };

    return (
        <HighwayBox>
            <Content>
                <SuperautoSVG />

                <CTABox>
                    <CardTitle>
                        Hai attivo il percorso <BoldText>L’Autostrada:</BoldText>
                    </CardTitle>

                    <HighwayButton
                        onClick={highwayButtonHandler}
                    >
                        Prenota lezione
                    </HighwayButton>
                </CTABox>
            </Content>
        </HighwayBox>
    );
};

export default HighwayCard;
