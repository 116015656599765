import devices from 'style/breakpoints';
import colors from 'style/palette';
import styled, { css } from 'styled-components';


export const Totalpage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0px 20px 15px;

    gap: 15px;

    @media ${devices.tablet}{

        padding: 30px 40px 40px;
    }
`;

export const FirstBlock = styled.div`
    display: flex;
    flex-direction: column;

    gap: 5px;
    @media ${devices.tablet}{
        flex-direction: row;
        align-items: stretch;
        padding: 0px 0px 25px;
        gap: 40px;
    }
`;

export const SecondBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media ${devices.tablet}{
        flex-direction: row-reverse;
        align-items: stretch;
        padding: 0px;
        gap: 40px;
    }
`;

export const Video = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    background-color: white;

    width: 100%;
    height: 219px;

    box-shadow: 0px 3px 1px -2px ${colors.black020};
    filter: drop-shadow(0px 2px 2px ${colors.black014}) drop-shadow(0px 1px 5px ${colors.black012});

    @media ${devices.tablet}{
        width: 630px;
        height: auto;

        flex-shrink: 0;

        background: white;
        border-radius: 15px;

        video, div {
            border-radius: 15px;
        }
    }
`;

export const MainTitle = styled.h1`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 10px 0px;
    margin: 0px;

    font-weight: 400;
    font-size: 20px;

    color: ${colors.white};

    @media ${devices.tablet}{
        flex-direction: row;
        padding: 0px 15px 0px 10px;
        gap: 20px;
    }
`;

export const FirstCardTotal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
    gap: 25px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    @media ${devices.tablet}{
        padding: 25px 25px 28px;
    }
`;

export const FirstCardUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 12px;
    gap: 27px;

    @media ${devices.tablet}{
        gap: 10px;
        padding: 0;
    }
`;

export const FirstCardDown = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 12px;
    gap: 27px;
    
    @media ${devices.tablet}{
        gap: 10px;
        padding: 0;
    }
`;

export const CardTitle = styled.h3`

    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 20px;

    display: flex;
    align-items: center;

    color: ${colors.primaryColor};
`;

export const CardText = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;

    display: flex;
    align-items: center;
    text-align: center;
    
    margin: 0px;
    padding: 0px;

    color: ${colors.white};
`;

export const FirstCardTitle = styled.h3`

    margin: 0;
    padding: 0;
    
    font-weight: 500;
    font-size: 20px;

    display: flex;
    align-items: flex-start;
    text-align: start;

    color: ${colors.primaryColor};
`;

export const FirstCardP = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;

    display: flex;
    align-items: flex-start;
    text-align: start;
    
    margin: 0px;
    padding: 0px;

    color: ${colors.white};

`;

export const FirstCardList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
`;

export const FirstCardListIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 9px;
`;

export const FirstCardListText = styled.p`
    font-weight: 500;
    font-size: 15px;

    display: flex;
    align-items: center;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};
`;

export const FirstCardButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;

    background: ${colors.primaryColor};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 5px;

    border: none;

    cursor: pointer;
`;

export const FirstCardButtonText = styled.p`
    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-end;
    text-align: center;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.87);
`;

export const SecondCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
    gap: 27px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    @media ${devices.tablet}{
        padding: 25px 30px;
    }
`;

export const SecondCardList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    flex-wrap: wrap;
`;

// export const SecondCardButton = styled.button`
//     box-sizing: border-box;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 9px 0px 10px;

//     width: 85px;
//     height: 41px;

//     color: ${colors.white};
//     background: none;

//     border: 1px solid ${colors.white};
//     border-radius: 5px;

//     :focus{ 
//         background: linear-gradient(180deg, #464A4F 0%, #1C2023 100%);
//     }
// `;

export const SecondCardButton = styled.button<{ isActive?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 0px 10px;
  width: 85px;
  height: 41px;
  color: ${colors.white};
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #464A4F 0%, #1C2023 100%)"
      : "none"};
  border: 1px solid ${colors.white};
  border-radius: 5px;
  &:focus {
    background: linear-gradient(180deg, #464a4f 0%, #1c2023 100%);
  }
`;

export const SecondCardText = styled.p`
    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;

    color: ${colors.white};
`;

export const SecondCardPrice = styled.h2`
    font-weight: 500;
    font-size: 40px;

    margin: 0px;
    padding: 4px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.white};

    border-radius: 5px;
    /* background-color: rgba(255,255,255, 0.3); */
`;

export const SecondCardButtonPrice = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 11px;
`;

export const SecondCardPriceBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 40px;
    color: ${colors.white};
    
    padding: 0px;

    gap: 5px;
`;

export const SecondCardButton2 = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 30px 10px;

    gap: 4px;

    background: ${colors.primaryColor};

    border: none;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 5px;

`;

export const SecondCardButton2Text = styled.p`
    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-end;

    color: ${colors.black};
`;

export const SecondCardButtonText = styled.p`
    font-weight: 500;
    font-size: 11px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.87);
`;

export const ThirdCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 0 15px;
    gap: 15px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    @media ${devices.tablet}{
        padding: 25px 0;
        gap: 20px;
        flex-shrink: 0;
        max-width: 433px;
    }
`;

export const ThirdCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
`;

export const ThirdCardName = styled.h3`
    font-weight: 500;
    font-size: 20px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;

    color: ${colors.primaryColor};
`;

export const ThirdCardInfoName = styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;

    color: ${colors.white};
`;

export const ThirdCardShop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
`;

export const ThirdCardImmage = styled.img`
    width: 100px;
    height: 100px;

    background: white;
    border-radius: 100px;

    display: flex;
    flex-shrink: 0;
`;

export const ThirdCardButtonShopDesktop = styled.div`

    @media ${devices.tablet}{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 20px;
    }
`;

export const ThirdCardButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${devices.tablet}{
        flex-direction: column;
        align-items: flex-start;
        padding: 3px 0px 0px;
        gap: 18px;
    }
`;

export const ThirdCardButtonShop = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7px 0px 5px;

    width: 290px;
    height: 42px;

    background: ${colors.primaryColor};
    border-radius: 0 0 5px 5px;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border: none;
`;

export const ThirdCardButtonShopText = styled.p`
    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.87);
`;

export const ButtonShopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 5px 0px 0px;
    gap: 7px;
`;

export const CompleteButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    width: 100%;
`;

export const SelectionButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 1px 0px 0px;

    width: 100%;
`;

export const Selection1 = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 10px 8px;

    background: ${colors.primaryColor};
    border-radius: 5px 0px 0px 0px;
    border: none;

    width: 100%;

`;

export const Selection2 = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;

    background: ${colors.grey};
    border: none;

    color: ${colors.white050};

    width: 100%;

`;

export const Selection3 = styled.button`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;

    background: ${colors.grey};

    border-left: 1px solid ${colors.darkGrey};
    border-radius: 0px 5px 0px 0px;
    border: none;

    color: ${colors.white050};

    width: 100%;

`;

export const ButtonPrice = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 3px;

    position: relative;
`;

export const ButtonShopText = styled.p`
    font-weight: 500;
    font-size: 11px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    justify-self: flex-end;

    color: rgba(0, 0, 0, 0.87);
`;

export const ButtonShopArrow = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 7px 8px;
    gap: 10px;

    border: none;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
`;

export const ThirdCardTitle = styled.h2`
    font-weight: 500;
    font-size: 20px;

    padding: 0 35px;
    margin: 0px;

    display: flex;
    align-items: center;

    color: ${colors.primaryColor};
`;

export const ThirdCardText = styled.p`
    font-weight: 400;
    font-size: 15px;

    padding: 0 30px;
    margin: 0px;

    display: flex;
    align-items: center;

    color: ${colors.white};
`;

export const ButtonChoose = styled.button<{isOpen:boolean}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    padding: 10px 7px 8px;
    gap: 10px;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
    border: none;
    box-shadow: none;

    cursor: pointer;
    
    ${({isOpen}) => isOpen && 
        css` 
            border-radius: 5px 5px 0 0;


        `
    }
`;