import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const TotalPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    height: 100%;
    width: 100%;

    @media ${devices.tablet} {
        min-width: 770px;
        max-width: 800px;
    }
`;

export const TitleModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    gap: 10px;

    padding: 0px 15px 15px 10px;

    @media ${devices.tablet} {
        
    }
`;

export const AutorText = styled.div`
    color: rgba(255, 255, 255, 0.50);
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;

    width: 100%;

    padding: 0;
    margin: 0;

    @media ${devices.tablet} {
        
    }
`;

export const TitleCopy = styled.div`
    display: flex;
    align-items: center;

    gap: 15px;

    width: 100%;

    >h1{
        white-space: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        padding: 7px 0px 10px 0px;

        color: ${colors.white};
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        margin: 0;
        padding: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 17px;
        height: 17px;
    }

    @media ${devices.tablet} {
        
    }
`;

export const CardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: stretch;

    gap: 40px;
    width: 100%;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const Card = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 27px;
    padding: 35px;

    width: 100%;
    min-height: 262px;

    border-radius: 15px;
    background: ${colors.darkGrey};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    >svg {
        flex-shrink: 0;
        width: 50.415px;
        height: 50px;
    }

    @media ${devices.tablet} {
        
    }
`;

export const SecondCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 27px;

    width: 100%;

    >svg {
        flex-shrink: 0;
        width: 50.415px;
        height: 50px;
    }

    @media ${devices.tablet} {

    }
`;


export const CardTitle = styled.h1`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;

    color: ${colors.primaryColor};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 

    margin: 0;
    padding: 0;

    @media ${devices.tablet} {
        
    }
`;

export const CardText = styled.p`  
    align-items: center;
    text-align: center;
    color: ${colors.white};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    width: 100%;

    margin: 0;
    padding: 0;

    >span{
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;

        margin: 0;
        padding: 0;
    }

    >svg{
        flex-shrink: 0;

        margin: 0 5px 0 0;
        padding: 0;
    }

    @media ${devices.tablet} {
        
    }
`;

export const BulletBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 10px;

    @media ${devices.tablet} {
        
    }
`;

export const TextBullet = styled.div`
    display: flex;
    align-items: center;

    gap: 9px;

    >svg{
        flex-shrink: 0;
        width: 20px;
        height: 20px;
    }

    @media ${devices.tablet} {
        
    }
`;

export const TextP = styled.p`
    color: ${colors.white};
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 15px;

    margin: 0;
    padding: 0;

    @media ${devices.tablet} {
        
    }
`;

export const ButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    padding: 0px 14px;

    width: 100%;
    max-width: 284px;

    @media ${devices.tablet} {

    }
`;

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    @media ${devices.tablet} {

    }
`;

export const ButtonSelection = styled.div`
    
    display: flex;
    align-items: flex-end;
    align-self: stretch;

    padding-right: 0px;

    width: 100%;
    max-height: 33px;

    @media ${devices.tablet} {
        max-width: 262px;
    }
`;

export const WaiterButton = styled.div<{isSelected: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 11px 10px 8px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 5px 0px 0px 0px;
    background: ${props => props.isSelected ? colors.primaryColor : colors.grey};

    cursor: default;

    >p{
        color: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;
        fill: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
    }

    @media ${devices.tablet} {

    }
`;

export const ProtagonistButton = styled.div<{isSelected: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 9px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 0px 5px 0px 0px;
    border-left: 1px solid ${colors.darkGrey};
    background: ${props => props.isSelected ? colors.primaryColor : colors.grey};

    cursor: default;

    >p{
        color: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;

        fill: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
    }
    @media ${devices.tablet} {

    }
`;

export const MainButton = styled.div <{isOwned?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 3px 0px 5px 0px;

    border-radius: ${({ isOwned }) => (isOwned ? '5px' : '0px 0px 5px 5px')}; 
    background: ${({ isOwned }) => (isOwned ? '#626D78' : colors.primaryColor)};

    width: 100%;
    height: ${({ isOwned }) => (isOwned ? '39px' : '30px')};
    overflow: hidden;

    cursor: ${({ isOwned }) => (isOwned ? 'auto' : 'pointer')};

    position: relative;

    >svg{
        position: absolute;

        top: -2px;
        right: 0;
    }

    @media ${devices.tablet} {

    }
`;


export const LibertyPrice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 0px;
    gap: 4px;

    >svg{
        flex-shrink: 0;

        width: 21.08px;
        height: 19px;

        fill: ${colors.black};
    }

    >p{
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        
        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const ButtonText = styled.p`
    
    flex-direction: row;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11px;
    font-style: normal;
    font-weight: 500;

    padding: 0;
    margin: 0;
    @media ${devices.tablet} {

    }
`;

export const PaymentSelector = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const PriceInfo = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    >svg{
        flex-shrink: 0;
        
        width: 12.641px;
        height: 15px;
    }

    >p{
        color: ${colors.black};
        font-size: 11px;
        font-style: italic;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }
    
    @media ${devices.tablet} {

    }
`;

export const ArrowSelection = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 7px 8px 7px;
    gap: 10px;

    border-radius: 5px;
    background: ${colors.grey};

    cursor: pointer;

    >svg{
        flex-shrink: 0;

        width: 10px;
        height: 5px;

        fill: rgba(255, 255, 255, 0.50);
    }

    @media ${devices.tablet} {

    }
`;

export const TitleCategory = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    gap: 20px;
    width: 100%;

    > svg {
        flex-shrink: 0;

        width: 50px;
        height: 50px;
    }
    @media ${devices.tablet} {

    }
`;

export const TitleCategoryText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 5px;
    
    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const CardTextDescription = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    
    color: ${colors.white};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    width: 100%;

    margin: -15px 0 0 0;
    padding: 0;

    >span{
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;

        margin: 0;
        padding: 0;
    }

    @media ${devices.tablet} {
        
    }
`;

export const OutCardText = styled.p`
    color: rgba(255, 255, 255, 0.50);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    width: 100%;

    margin: 0;
    padding: 20px 0;

    >span{
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;

        margin: 0;
        padding: 0;
    }

    @media ${devices.tablet} {
        
    }
`;

export const TextBoxSecondCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    gap: 5px;

    @media ${devices.tablet} {

    }
`;

export const LimitedBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 15px;
    
    width: 100%;
    
    >h2{
        color: #FF5957;
        font-size: 21px;
        font-style: italic;
        font-weight: 600;
        line-height: 17px;

        padding: 0;
        margin: 0;
    }

    @media ${devices.tablet} {
        flex-direction: row;

    }
`;

export const LimitedBoxText = styled.div`
    display: flex;
    align-items: center;

    gap: 8px;

    @media ${devices.tablet} {

    }
`;

export const LimitedCardText = styled.p` 
    text-align: center;
    color: ${colors.white};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    width: fit-content;

    margin: 0;
    padding: 0;

    >span{
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;

        margin: 0;
        padding: 0;
    }

    >h3{        
        color: ${colors.primaryColor};
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        display: inline-block;

        margin: 0;
        padding: 0 5px;
    }

    @media ${devices.tablet} {
        
    }
`;

export const LimitedNumberText = styled.h3`
    color: ${colors.primaryColor};
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    margin: 0;
    padding: 0;

    @media ${devices.tablet} {

    }
`;

export const DonationBox = styled.div`
    display: flex;
    align-items: center;

    gap: 5px;

    >svg{
        flex-shrink: 0;

        width: 16px;
        height: 16px;
    }

    >span{
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;

        margin: 0;
        padding: 0;
    }

    @media ${devices.tablet} {

    }
`;

export const NewButtonShop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const ProtagonistButtonForSpectator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 9px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 5px 5px 0 0;
    border: 1px solid ${colors.primaryColor};
    background: ${colors.primaryColor};

    cursor: default;

    >p{
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;

        fill: rgba(0, 0, 0, 0.87);
    }
    @media ${devices.tablet} {

    }
`;

export const TimeText = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 4px;

    color: ${colors.grey400};;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    padding: 0;
    margin: 0;
    
    >svg{
        flex-shrink: 0;

        width: 12px;
        height: 12px;
        
    }
    @media ${devices.tablet} {
        color: ${colors.grey400};;
        font-size: 14px;

        >svg{
            flex-shrink: 0;

            width: 14px;
            height: 14px;
        }
    }
`;

export const DirectInfo = styled.div`
    display: flex;
    align-items: center;

    padding-right: 0px;
    margin-bottom: -10px;
    margin-top: -10px;
    gap: 15px;

    >h2{
        color: ${colors.grey400};;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;

        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 10px;
        height: 10px;
    }

    @media ${devices.tablet} {
        gap: 13px;

        >h2{
            color: ${colors.grey400};;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            padding: 0;
            margin: 0;
        }

        >svg{
            flex-shrink: 0;

            width: 14px;
            height: 14px;
        }
    }
`;
export const MobileLimitBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    @media ${devices.tablet} {

    }
`;