import devices from 'style/breakpoints';
import colors from 'style/palette';
import styled from 'styled-components';


export const TotalPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px 20px 20px;

`;

export const LessonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 0px;
    gap: 30px;

`;

export const CardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 35px 25px;
    gap: 50px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    @media ${devices.tablet} {

        align-items: center;
        padding: 10px 20px 30px;
        gap: 20px;

    }
`;

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 0px;
    gap: 40px;

    @media ${devices.tablet} {
                
        align-items: flex-start;
        padding: 30px 30px 10px;
    }
`;

export const ActiveLessons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 0px;
    gap: 3px;

    @media ${devices.tablet} { 
        align-items: flex-start;
    }
`;

export const EndedLessons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 0px;
    gap: 3px;

    @media ${devices.tablet} { 

    }
`;