import { getEnvironmentMonolithLinkUrl, getEnvironmentProductId } from 'configs/config.helper';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

// assets
import { ReactComponent as TenKClubBoxSVG } from './assets/tenKClubBox.svg';

// style
import {
    TenKClubCardBox,
    Content,
    CTABox,
    CardTitle,
    TenKClubCardButton
} from './style/TenKClubCard.style';


const TenKClubCard: FC<{}> = () => {

    const navigate = useNavigate();

    const tenKClucClickBandler = () => {
        navigate(`/reservedArea/public/product(${getEnvironmentProductId('club dei 10k stagione 2023() corso')})`);
    };

    return (
        <TenKClubCardBox>
            <Content>
                <TenKClubBoxSVG />

                <CTABox>
                    <CardTitle>
                        Complimenti (!)
                    </CardTitle>

                    <TenKClubCardButton
                        onClick={tenKClucClickBandler}
                    >
                        Vai al club dei 10k
                    </TenKClubCardButton>
                </CTABox>
            </Content>
        </TenKClubCardBox>
    );
};

export default TenKClubCard;
