import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const GraphSVG = styled.svg`

    /* width: 100%;
    max-width: 900px;
    height: 235px;
    
    margin: 0; */

    g {

        /* font-size: 13px; */
        /* font-family: 'Montserrat'; */
        /* font-weight: 400; */
        /* color: ${colors.white}; */
    }

    /* text {
        
        font-size: 9px;
        font-weight: 400;
        color: ${colors.white};
    } */

    /* @media ${devices.tablet} {
        height: 235px;
    } */
`;

export const PlotArea = styled.g``;

export const Xaxis = styled.g``;

export const Yaxis = styled.g``;
