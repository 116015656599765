import { FC, useEffect, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { enableFacebookExceptioModal } from 'redux/actions/actions-app-main';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

// utils
import { getEnvironmentMonolithLinkUrl, getEnvironmentProductId } from 'configs/config.helper';
import { getCurrentAuthUser, getUsersProducts } from 'services/user.services';
import { callGET, callOldGET } from 'network/network';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';
import config from 'configs/config';
import {
    CARD_CONTENT,
    checkHasUserUsedFreeLesson,
    checkTimeFromUsersLastLesson,
    getUsersLastLesson,
    getVisibleCard
} from './utils/ActiveHelpCard.utils';
import {
    getUsersCourseList,
    checkCashAcademyFreeSlots,
    checkCoachLesson,
    getAssignedHighwayInfo,
    getActiveHighwayInfo,
    getUsersMissions,
    getUsersLessons,
    getUsersLastLessonDate,
    addFreeLesson
} from './services/ActiveHelpCard.services';
import { getUserFacebookGroups, updateFacebookDataOnDB } from 'pages/LibertyDashboardPage/ShareModal/services/ShareModal.services';

// types
import { GetVisibleCardProps } from './utils/ActiveHelpCard.utils.d';

// components
import LoadingMask from 'components/ui/LoadingMask';
import HighwayCard from './HighwayCard/HighwayCard.component';
import MissionsFlow from './MissionsFlow/MissionsFlow.component';
import LessonReservation from './LessonReservation/LessonReservation.component';
import CashAcademyCard from './CashAcademyCard/CashAcademyCard.component';

// style
import {
    ActiveHelpCardBox,
    Content,
    CardTitle,
    HelpButton,
    WaitText,
    MissionBetfairSupportBox,
    SupportText,
    SupportButton
} from './style/ActiveHelpCard.style';

const FB_GROUP_ID = () => {

    if (!config || config === undefined) return '000';
    if (!config.socialLinks || config.socialLinks === undefined) return '000';
    if (config.environment === 'production') return config.socialLinks.fbGroupID;
    return config.socialLinks.fbGroupTestID;
};


const ActiveHelpCard: FC<{
    showOtherCards: React.Dispatch<React.SetStateAction<boolean>>,
    openCloseLessonModalHandler: () => void,
    lessonIdHandler: (lessonId: number) => void,
    refreshStopFunction?: () => void,
    hasUserLessonToRefresh?: boolean | undefined,
    userClicksOpenCard?: string | undefined,
    missionBadgesVisibleHandler?: () => void,
    missionBadgesHiddenHandler?: () => void,
    completedMissionsHandler?: (b1: boolean | undefined, b2: boolean | undefined, b3: boolean | undefined) => void,
}> = ({ 
    showOtherCards,
    openCloseLessonModalHandler,
    lessonIdHandler,
    refreshStopFunction,
    hasUserLessonToRefresh,
    userClicksOpenCard,
    missionBadgesHiddenHandler,
    missionBadgesVisibleHandler,
    completedMissionsHandler,
}) => {

    const { facebook: isFacebookMissionCompleted, app: isAppMissionCompleted, betfair: isBetfairMissionCompleted } = useAppSelector(state => state.user.missions);
    const { facebook: isUserFederetedWithFacebook} = useAppSelector(state => state.user.federateds);
    const { clientId } = useAppSelector(state => state.user);
    const { assigned: isHighwayAssigned, active: isHighwayActive } = useAppSelector(state => state.user.products.highway);
    const { lessons } = useAppSelector(state => state.user.products);
    // const [isFacebookMissionCompleted, setIsFacebookMissionCompleted] = useState<boolean | undefined>(undefined); 
    // const [isAppMissionCompleted, setIsAppMissionCompleted] = useState<boolean | undefined>(undefined); 
    // const [isBetfairMissionCompleted, setIsBetfairMissionCompleted] = useState<boolean | undefined>(undefined); 
    // const [isHighwayActive, setIsHighwayActive] = useState<boolean | undefined>(undefined); 
    // const [isHighwayAssigned, setIsHighwayAssigned] = useState<boolean | undefined>(undefined); 
    // const [isCoachLessonActive, setIsCoachLessonActive] = useState<boolean | undefined>(undefined); 
    const [isCashAcademySlotFree, setIsCashAcademySlotFree] = useState<boolean | undefined>(undefined); 
    const [userLastFreeLesson, setUserLastFreeLesson] = useState<any>({}); 
    const [userLastAPLesson, setUserLastAPLesson] = useState<any>({}); 
    const [isLastLessonDateBeforeTimeLimit, setIsLastLessonDateBeforeTimeLimit] = useState<boolean | undefined>(undefined); 
    const [isLastLessonOpen, setIsLastLessonOpen] = useState<boolean | undefined>(undefined); 
    // const [isLastLessonFree, setIsLastLessonFree] = useState<boolean | undefined>(undefined); 
    // const [isLessonAssigned, setIsLessonAssigned] = useState<boolean | undefined>(undefined); 
    const [hasUserUsedFreeLesson, setHasUserUsedFreeLesson] = useState<boolean | undefined>(undefined); 
    const [hasUserSicureBonusPlusCourse, setHasUserSicureBonusPlusCourse] = useState<boolean | undefined>(undefined);
    const [hasUserSureShotCourse, setHasUserSureShotCourse] = useState<boolean | undefined>(undefined);
    const [hasUserMultiBonusEvolutionCourse, setHasUserMultiBonusEvolutionCourse] = useState<boolean | undefined>(undefined);
    const [hasUserClub10KCourse, setHasUserClub10KCourse] = useState<boolean | undefined>(undefined);
    const [hasUserSecretBonusCourse, setHasUserSecretBonusCourse] = useState<boolean | undefined>(undefined);
    const [isAPLessonAssigned, setIsAPLessonAssigned] = useState<boolean>(false);
    const [isAPLessonActive, setIsAPLessonActive] = useState<boolean>(false);
    const [isCoachLessonActive, setIsCoachLessonActive] = useState<boolean>(false);
    const [hasFreeLesson, setHasFreeLesson] = useState<boolean>(false);
    const [isFreeLessonAssigned, setIsFreeLessonAssigned] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const hasUserUsedFreeLessons = (lessonsToFilter: any) => {
        
        if (lessonsToFilter === undefined) return false;
        // if (lessonsToFilter === undefined) return [];
        const freeLessons = lessonsToFilter.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('lezione in regalo'));
        if (freeLessons.length === 0) return false;
        return (freeLessons.filter((lesson: any) => lesson.productLessonPack.lessons[0].status === 'CLOSED').length > 0 );
    };
    const hasUserFreeLessons = (lessonsToCheck: any) => {
        // sub
        if (lessonsToCheck === undefined) return false;
        // if (lessonsToCheck.productLessonPack === undefined) return false;
        const freeLessons = lessonsToCheck.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('lezione in regalo'));
        if (freeLessons === undefined) return false;
        if (freeLessons.length === 0) return false;
        return freeLessons.productLessonPack.lessons.length > 0;
    };
    const freeLessonAssigned = (lessonsToCheck: any) => {
        
        if (lessonsToCheck === undefined) return false;
        // if (lessonsToCheck.productLessonPack === undefined) return false;
        const freeLessons = lessonsToCheck.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('lezione in regalo'));
        if (freeLessons === undefined) return false;
        return freeLessons.filter((lesson: any) => lesson.productLessonPack.lessons[0].status === 'TO_DO');
    };
    const apLessonOpen = (lessonsToCheck: any) => {
        
        if (lessonsToCheck === undefined) return false;
        // if (lessonsToCheck.productLessonPack === undefined) return false;
        const apLessons = lessonsToCheck.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('assistente personale'));
        return apLessons.filter((lesson: any) => lesson.productLessonPack.lessons[0].status === 'TO_ASSIGN').length > 0;
        
        // if (lessonsToCheck === undefined) return false;
        // return lessonsToCheck[0].productLessonPack.lessons.some((lesson: any) => lesson.status === 'TO_ASSIGN');
    };
    const apLessonAssigned = (lessonsToCheck: any) => {
        
        if (lessonsToCheck === undefined) return false;
        // if (lessonsToCheck.productLessonPack === undefined) return false;
        const apLessons = lessonsToCheck.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('assistente personale'));
        
        if (apLessons === undefined) return false;
        return apLessons.filter((lesson: any) => (lesson.productLessonPack.lessons[0].status === 'TO_DO') || (lesson.productLessonPack.lessons[0].status === 'CLOSED_BY_COACH')).length > 0;
    };
    
    const apLesson = (lessonsToCheck: any) => {
        
        // console.log("lessonsToCheck",lessonsToCheck[0].productLessonPack.lessons.some((lesson: any) => lesson.status === 'TO_ASSIGN'));
        if (lessonsToCheck === undefined) return [];
        // if (lessonsToCheck.productLessonPack === undefined) return false;
        const apLessons = lessonsToCheck.filter((lesson: any) => lesson.product.id === getEnvironmentProductId('assistente personale'));
        if (apLessons.length === 0) return [];
        const lessonToReturn = apLessons[apLessons.length - 1].productLessonPack.lessons.filter((lesson: any) => lesson.status !== 'CLOSED');
        if (lessonToReturn.length === 0) return [];
        return lessonToReturn;
    };
    
    const activeCoachLessons = (lessonsToFilter: any) => {

        console.log("LESSON TO FILTER:", lessonsToFilter);
        if (lessonsToFilter === undefined) return false;
        // if (lessonsToFilter.productLessonPack === undefined) return false;
        const coachLessons = lessonsToFilter.filter((lesson: any) => ((lesson.product.id !== getEnvironmentProductId('autostrada')) && 
            (lesson.product.id !== getEnvironmentProductId('assistente personale')) && 
            (lesson.product.id !== getEnvironmentProductId('aiuto privato')) && 
            (lesson.product.id !== getEnvironmentProductId('lezione in regalo'))) &&
            (lesson.productLessonPack.status !== 'CLOSED_SUCCESS') &&
            (lesson.productLessonPack.status !== 'CLOSED_FAILURE')
        );
        
        return coachLessons.length > 0;
    };
    // const hasUserUsedFreeLesson = checkHasUserUsedFreeLessons(freeLessons(lessons));
    
    const betfairSupportClickHandler = () => {

        navigate("/reservedArea/aiuto-privato");
    };

    // const checkUserConnectedAccount = (userData: any, accountType: string) => {
        
    //     if (!userData || userData === undefined) return false;
    //     if (!userData.federateds || userData.federateds === undefined) return false;
        
    //     return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    // };

    const getUserLastLessonFromTimestamp = (lessons: any) => {

        if (!lessons || lessons === undefined) return ;
        if (lessons.length === 0) return;
        if (lessons.length === 1) return lessons[0];

        const sortedLessons = lessons.sort((prev: any, next: any) => new Date(next.creationDate).getTime() - new Date(prev.creationDate).getTime())
        return sortedLessons[0];
    };

    const updateFacebookMissionOnBackend = async (backendStatus: boolean | undefined, facebookStatus: boolean) => {
        
        if ((backendStatus === undefined) || (facebookStatus === undefined)) return;
        if (backendStatus === facebookStatus) return;

        const facebookCallParams = {
            completed: facebookStatus
        };

        await callGET('v1/self/missions/1', true, facebookCallParams);

        // if (facebookStatus) {
            
        //     await callOldGET('user/misFacebookON', true);
        //     return;
        // }

        // await callOldGET('user/misFacebookOFF', true);
    };

    const setUsersCourseList = async () => {

        try {

            const courseList = await getUsersCourseList();
            if (!isMounted()) return;

            if (courseList === undefined) {
                throw new Error("Response getting User's course list is undefined");
            }

            setHasUserSicureBonusPlusCourse(courseList.some((course: any) => course.id === getEnvironmentProductId('bonus sicuro plus')));
            setHasUserSureShotCourse(courseList.some((course: any) => course.id === getEnvironmentProductId('colpo sicuro')));
            setHasUserMultiBonusEvolutionCourse(courseList.some((course: any) => course.id === getEnvironmentProductId('multibonus evolution')));
            setHasUserClub10KCourse(courseList.some((course: any) => course.id === getEnvironmentProductId('club dei 10k stagione 2023() corso')));
            setHasUserSecretBonusCourse(courseList.some((course: any) => course.id === getEnvironmentProductId('secret bonus 3.0 corso (nda)')) || courseList.some((course: any) => course.id === getEnvironmentProductId('secret bonus 3.0 corso completo')));
        } catch (error: any) {
            console.warn(error);
        }
    };

    // const setAssignedHighwayInfo = async () => {
    
    //     if (clientId === undefined) return;
        
    //     try {

    //         const highwayInProgress = await getAssignedHighwayInfo(clientId, setIsHighwayAssigned);
    //         if (!isMounted()) return;

    //         if (highwayInProgress === undefined) {
    //             setIsHighwayAssigned(false);
    //             throw new Error("Response of Assigned Highway info is undefined");
    //         }

    //         if (highwayInProgress.length === 0) {

    //             setIsHighwayAssigned(false)
    //             return;
    //         };

    //         return setIsHighwayAssigned(true);           
    //     } catch (error: any) {

    //         setIsHighwayAssigned(false)
    //         console.warn(error);
    //     }
    // };

    // const setActiveHighwayInfo = async () => {

    //     try {

    //         if (clientId === undefined) return;
    //         const activeHighwayData = await getActiveHighwayInfo(clientId, setIsHighwayActive);
    //         if (!isMounted()) return;

    //         if (activeHighwayData === undefined) {

    //             setIsHighwayActive(false);
    //             throw new Error("Response of Active Highway info is undefined");
    //         }

    //         if (activeHighwayData.length === 0) {

    //             setIsHighwayActive(false);
    //             return;
    //         }
            
    //         return setIsHighwayActive(true);
    //     } catch (error: any) {

    //         setIsHighwayActive(false);
    //         console.warn(error);
    //     }
    // };

    const setUsersMissions = async () => {

        try {

            let userIsInFacebookGroup = false;

            // const usersMissions = await getUsersMissions();
            // if (!isMounted()) return;

            // if (usersMissions === undefined) {
            //     throw new Error("Response of User Missions is undefined");
            // }

            // console.log("MISSIONS:", usersMissions);
            
            // if (checkUserConnectedAccount(userData, 'Facebook')) {

            if (isUserFederetedWithFacebook) {

                try {
                    await updateFacebookDataOnDB()
                
                    
                    const isUserInFB = await getUserFacebookGroups();
                    if (!isMounted) return false;
        
                    if (!isUserInFB || isUserInFB === undefined) return false;
        
                    
                    if (isUserInFB.message) {

                        if (isUserInFB.message.includes('Error getting facebook groups.')) {
    
                            // setIsFacebookMissionCompleted(usersMissions.misFacebook2022 === true);
                            // setIsAppMissionCompleted(usersMissions.misApp === true);
                            // setIsBetfairMissionCompleted(usersMissions.misFedelta === true);
        
                            dispatch( enableFacebookExceptioModal() );
                            return;
                        };
                    };
                    
                    userIsInFacebookGroup = isUserInFB.some((group: any) => group.id === FB_GROUP_ID());

                    // updateFacebookMissionOnBackend(isFacebookMissionCompleted, userIsInFacebookGroup);
                    // setIsFacebookMissionCompleted(userIsInFacebookGroup);
                    // setIsAppMissionCompleted(usersMissions.misApp === true);
                    // setIsBetfairMissionCompleted(usersMissions.misFedelta === true);
        
                } catch (error: any) {

                    if (error.response.status === 404) dispatch( enableFacebookExceptioModal() );

                    // setIsFacebookMissionCompleted(usersMissions.misFacebook2022 === true);
                    // setIsAppMissionCompleted(usersMissions.misApp === true);
                    // setIsBetfairMissionCompleted(usersMissions.misFedelta === true);
        
                    throw error;
                }
            };

            // updateFacebookMissionOnBackend(isFacebookMissionCompleted, userIsInFacebookGroup);
            // setIsFacebookMissionCompleted(userIsInFacebookGroup);
            // setIsAppMissionCompleted(usersMissions.misApp === true);
            // setIsBetfairMissionCompleted(usersMissions.misFedelta === true);
        } catch (error: any) {
            console.warn(error);
        }
    };

    const setUsersLessonData = async () => {

        try {
            
            const userLessons = await getUsersLessons();
            if (!isMounted()) return;
            
            if (userLessons === undefined) return;
            
            if (userLessons.length === 0) {
                setUserLastFreeLesson(null);
                setUserLastAPLesson(null);
                setHasUserUsedFreeLesson(false);
                // setIsLastLessonFree(false);
                setIsLastLessonOpen(false);
                // setIsLessonAssigned(false);
                return;
            }
            
            setIsAPLessonActive(apLessonOpen(userLessons));
            setIsAPLessonAssigned(apLessonAssigned(userLessons));
            setIsCoachLessonActive(activeCoachLessons(userLessons));
            setHasFreeLesson(hasUserFreeLessons(userLessons));
            setIsFreeLessonAssigned(freeLessonAssigned(userLessons).length !== 0);
            
            const lastUsersApLesson = getUserLastLessonFromTimestamp(apLesson(userLessons));
            const lastUsersFreeLesson = getUserLastLessonFromTimestamp(freeLessonAssigned(userLessons));
            // const usersLastLessonDate = await getUsersLastLessonDate();
            // const lastUsersLesson = getUsersLastLesson(userLessons, usersLastLessonDate);
            // const checkUsersUsedFreeLesson = checkHasUserUsedFreeLesson(lessons);
            
            setUserLastFreeLesson(lastUsersFreeLesson);
            setUserLastAPLesson(lastUsersApLesson);
                // checkTimeFromUsersLastLesson(lastUsersLesson, setIsLastLessonDateBeforeTimeLimit);
            setHasUserUsedFreeLesson(hasUserUsedFreeLessons(userLessons));
            // setIsLastLessonFree(lastUsersLesson.idPagamento === null);
                // setIsLastLessonOpen(lastUsersLesson.dataChiusuraCliente === null);
            // setIsLessonAssigned(lastUsersLesson.idAssistentePersonale !== null);

        } catch (error: any) {
            console.warn(error);
        }
    };

    const getFreeLessonAsAGift =async () => {
        
        const snackbarData: SnackbarOptions = {};

        try {
    
            const getFreeLesson = await addFreeLesson();
            if (!isMounted()) return;
    
            if (getFreeLesson === undefined) {
                throw new Error ("Response getting Free lesson is undefined!");
            }
    
            await setUsersLessonData(); // to do it changes the card into homepage?
            snackbarData.type = 'success';
            snackbarData.message = "Cogratulazioni! Lezione in REGALO aggiunta(!)";
            dispatch( addSnackbar(snackbarData) );
            setUsersLessonData();
        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = "C'è stato un problema con il server, non è stato possibile aggiungere la lezione in REGALO...";
            dispatch( addSnackbar(snackbarData) );

            return new Error ("ERROR: Unable to get Free Lesson as a gift");
        }
    };

    useEffect(() => {

        setUsersLessonData();
        // setUsersMissions();
        // setActiveHighwayInfo();
        // setAssignedHighwayInfo();
        // checkCoachLesson(setIsCoachLessonActive);
        checkCashAcademyFreeSlots(setIsCashAcademySlotFree);
        setUsersCourseList();
    }, []);

    useEffect(() => {

        // setUsersLessonData();
        setUsersMissions();
        // setActiveHighwayInfo();
        // setAssignedHighwayInfo();
        // checkCoachLesson(setIsCoachLessonActive);
        // checkCashAcademyFreeSlots(setIsCashAcademySlotFree);
        // setUsersCourseList();
    }, [isUserFederetedWithFacebook]);

    useEffect(() => {

        if (hasUserLessonToRefresh === false) return;
        
        setUsersLessonData();
        if (refreshStopFunction) refreshStopFunction();
    }, [hasUserLessonToRefresh]);

    useEffect(() => {

        if (completedMissionsHandler) completedMissionsHandler(
            isFacebookMissionCompleted,
            isAppMissionCompleted,
            isBetfairMissionCompleted
        )
    }, [completedMissionsHandler, isAppMissionCompleted, isBetfairMissionCompleted, isFacebookMissionCompleted]);

    const isMissionsCompleted = (
        isFacebookMissionCompleted && 
        isAppMissionCompleted && 
        isBetfairMissionCompleted
    );
    const canUserHaveFreelesson = (
        // !hasUserSicureBonusPlusCourse &&
        !isHighwayActive &&
        !hasUserUsedFreeLesson &&
        !hasUserMultiBonusEvolutionCourse &&
        !hasUserClub10KCourse &&
        !hasUserSureShotCourse &&
        !hasUserSecretBonusCourse
        // !isLastLessonFree
    );
    const isCashAcademyCardVisible = (
        !isHighwayActive && 
        !canUserHaveFreelesson && 
        !isLastLessonOpen && 
        !isCoachLessonActive && 
        isCashAcademySlotFree &&
        (hasUserSureShotCourse || hasUserSecretBonusCourse)
    );
    const isLoading = (
        isFacebookMissionCompleted === undefined ||
        isAppMissionCompleted === undefined ||
        isBetfairMissionCompleted === undefined ||
        isHighwayActive === undefined ||
        isHighwayAssigned === undefined ||
        isCoachLessonActive === undefined ||
        isCashAcademySlotFree === undefined ||
        // isLessonAssigned === undefined ||
        // hasUserUsedFreeLesson === undefined ||
        hasUserSicureBonusPlusCourse === undefined ||
        hasUserSureShotCourse === undefined ||
        hasUserMultiBonusEvolutionCourse === undefined ||
        hasUserClub10KCourse === undefined ||
        hasUserSecretBonusCourse === undefined
    );

    // console.log("isFacebookMissionCompleted", isFacebookMissionCompleted);
    // console.log("isAppMissionCompleted", isAppMissionCompleted);
    // console.log("isBetfairMissionCompleted", isBetfairMissionCompleted);
    // console.log("isHighwayActive", isHighwayActive);
    // console.log("isHighwayAssigned", isHighwayAssigned);
    // console.log("isCashAcademySlotFree", isCashAcademySlotFree);
    // console.log("isLessonAssigned", isLessonAssigned);
    // console.log("hasUserSicureBonusPlusCourse", hasUserSicureBonusPlusCourse);
    // console.log("hasUserSureShotCourse", hasUserSureShotCourse);
    // console.log("hasUserMultiBonusEvolutionCourse", hasUserMultiBonusEvolutionCourse);
    // console.log("hasUserClub10KCourse", hasUserClub10KCourse);
    // console.log("hasUserSecretBonusCourse", hasUserSecretBonusCourse);
    console.log("isCoachLessonActive", isCoachLessonActive);
    // console.log("hasUserUsedFreeLesson", hasUserUsedFreeLesson);

    useEffect(() => {

        if (missionBadgesVisibleHandler === undefined || !missionBadgesVisibleHandler) return;
        if (!canUserHaveFreelesson) missionBadgesVisibleHandler();
        
        if (missionBadgesHiddenHandler === undefined || !missionBadgesHiddenHandler) return;
        if (canUserHaveFreelesson) missionBadgesHiddenHandler();

    }, [canUserHaveFreelesson, missionBadgesHiddenHandler, missionBadgesVisibleHandler]);

    useEffect(() => {

        const localStorageItem = getItemFromLocalStorage('returnFrom');
       
        if (localStorageItem === 'facebook mission') {
            window.open (
                `https://www.facebook.com/groups/${FB_GROUP_ID()}`,
                '_blank',
                'noopener noreferrer'
            );
    
            removeItemFromLocalStorage('returnFrom');
        };        
    }, []);
    
    // to do delete logs after test
//     useEffect(() => {
    // console.log("isHighwayActive", isHighwayActive);
    // console.log("canUserHaveFreeLesson", canUserHaveFreelesson);
    // console.log("isLastLessonOpen", isLastLessonOpen);
    // console.log("isCoachLessonActive", isCoachLessonActive);
    // console.log("isAPLessonActive", isAPLessonActive);
    // console.log("isAPLessonAssigned", isAPLessonAssigned);
    // console.log("hasFreeLesson", hasFreeLesson);
    // console.log("isFreeLessonAssigned", isFreeLessonAssigned);
// console.log("isFacebookMissionCompleted", isFacebookMissionCompleted);
// console.log("isAppMissionCompleted", isAppMissionCompleted);
// console.log("isBetfairMissionCompleted", isBetfairMissionCompleted);
// console.log("isHighwayAssigned", isHighwayAssigned);
// console.log("isCashAcademySlotFree", isCashAcademySlotFree);
// console.log("hasUserSicureBonusPlusCourse", hasUserSicureBonusPlusCourse);
// console.log("hasUserSureShotCourse", hasUserSureShotCourse);
// console.log("hasUserMultiBonusEvolutionCourse", hasUserMultiBonusEvolutionCourse);
// console.log("hasUserClub10KCourse", hasUserClub10KCourse);
// console.log("hasUserSecretBonusCourse", hasUserSecretBonusCourse);
// console.log("isHighwayAssigned", isHighwayAssigned);

    const getVisibleCardPayload: GetVisibleCardProps = {
        isHighwayActive,
        canUserHaveFreelesson,
        isLastLessonOpen,
        isMissionsCompleted,
        isFacebookMissionCompleted,
        isAppMissionCompleted,
        isBetfairMissionCompleted,
        isLastLessonDateBeforeTimeLimit,
        isCoachLessonActive,
        isAPLessonAssigned,
        isAPLessonActive,
        hasFreeLesson,
        isFreeLessonAssigned,
        isHighwayAssigned
    };
    
    let visibleCard = '';

    if (!isLoading && !userClicksOpenCard) visibleCard = getVisibleCard( getVisibleCardPayload );
    if (!isLoading && userClicksOpenCard) visibleCard = userClicksOpenCard;
    if (!isLoading && visibleCard === 'betfairMissionCard') showOtherCards(false);
    
    const visibleCardContent = CARD_CONTENT[visibleCard];

    const buttonClickHandler = () => {

        if (visibleCardContent.buttonLink?.link) {

            window.open(
                visibleCardContent.buttonLink.link,
                '_blank',
                'noreferrer noopener'
            )
        };
        if (visibleCardContent.buttonLink?.path) {
            navigate(visibleCardContent.buttonLink.path);
        };
        if (visibleCardContent.buttonLink?.function) {
            if (visibleCardContent.buttonLink.function === 'getFreeLessonAsAGift') getFreeLessonAsAGift();
        };
        
        return;
    };
    
    if (isLoading) {
        return (
            <LoadingMask
                size='medium'
                height='145px'
                paddingTop='20px'
            />);
        }
        
    if (isHighwayAssigned) return <HighwayCard />;
    if (isCashAcademyCardVisible) return <CashAcademyCard />;

    return (
        <ActiveHelpCardBox flavor={visibleCardContent.flavor}>
            <Content>
                <CardTitle flavor={visibleCardContent.flavor}>
                    {visibleCardContent.title}
                </CardTitle>

                {(
                    (visibleCard === 'appMissionCard') || 
                    (visibleCard === 'facebookMissionCard') || 
                    (visibleCard === 'betfairMissionCard')
                ) && 
                    <MissionsFlow 
                        isAppMissionCompleted={isAppMissionCompleted}
                        isFacebookMissionCompleted={isFacebookMissionCompleted}
                    />
                }

                {visibleCardContent.buttonText && (
                    <HelpButton onClick={buttonClickHandler}>
                        {visibleCardContent.buttonText}
                    </HelpButton>
                )}

                {visibleCardContent.waitText && (
                    <WaitText flavor={visibleCardContent.flavor}>
                        {visibleCardContent.waitText}
                    </WaitText>)
                }

                {(
                    (visibleCard === 'freeLessonAssignedCard')
                ) && 
                    <LessonReservation 
                        lessonData={userLastFreeLesson}
                        openCloseLessonModalHandler={openCloseLessonModalHandler}
                        lessonIdHandler={lessonIdHandler}
                    />
                }

                {(
                    (visibleCard === 'buyedLessonAssignedCard')
                ) && 
                    <LessonReservation 
                        lessonData={userLastAPLesson}
                        openCloseLessonModalHandler={openCloseLessonModalHandler}
                        lessonIdHandler={lessonIdHandler}
                    />
                }

                {(visibleCard === 'betfairMissionCard') && (
                    <MissionBetfairSupportBox>
                        <SupportText>
                            Se non vuoi o non puoi registrarti a Betfair:
                        </SupportText>

                        <SupportButton onClick={betfairSupportClickHandler}>
                            PRENOTA UNA LEZIONE PRIVATA
                        </SupportButton>
                    </MissionBetfairSupportBox>
                )}
            </Content>
        </ActiveHelpCardBox>
    );
};

export default ActiveHelpCard;
