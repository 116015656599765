import styled from 'styled-components';
import devices from 'style/breakpoints';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    height: 100%;

    > svg {

        align-self: center;
        justify-self: center;

        width: 100%;
        min-width: 400px;
        max-width: 400px;
        margin: 50px 0 0;
    }
`;

export const Title = styled.h4`

    margin: 50px 0 0;
    padding: 0 10px;

    font-weight: 400;
    font-size: 28px;

    color: rgba(255, 255, 255, 0.7);

    a {

        margin: 0 10px 0 0;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
    }
`;

export const MobileVersion = styled.div`

    display: block;

    @media ${devices.tablet} {

        display: none;
}
`;

export const DesktopVersion = styled.div`

    display: none;

    @media ${devices.tablet} {

        display: block;
}
`;