import { FC } from 'react';

// routing
import { Link, useLocation, useNavigate } from 'react-router-dom';

// redux
import { enableAppMain } from 'redux/actions/actions-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';

// components
import HamburgerButton from './HamburgerButton';
import TabButton from './TabButton';
import UserProfileSubmenu from '../UserProfileSubmenu';
import BrandSelectorMobile from 'App/layout/Footer/BrandSelectorMobile/BrandSelectorMobile.component';


// types
import { TabBarProps } from './TabBar.d';

// assets
import { ReactComponent as BrandSVG } from '../assets/brand-icon.svg';
import { ReactComponent as SupportSVG } from '../assets/support-icon.svg';
import { ReactComponent as LoginSVG } from '../assets/login-icon.svg';
import { ReactComponent as DashboardSVG } from './assets/dashboard-icon.svg';
import { ReactComponent as ShopSVG } from './assets/shop-icon.svg';
import { ReactComponent as NotifySVG } from '../assets/notify-icon.svg';
import { ReactComponent as DirectSVG } from './assets/ThematicDirect.svg';

// style
import {
    LoginArea,
    SupportBox,
    LoginBox
} from '../style/Header.style';
import {
    TabBarBox,
    BrandBox,
    SignupText,
    IconsBar,
    NewNotificationAlert
} from './style/TabBar.style';
import store from 'redux/store';


/**
 * TabBar
 * 
 * @author Alessio Grassi
 * 
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onSubmenuButtonClick -  toggle handler of the Submenu
 * @param onSubmenuLinkClick -  handler for Submenu's closure
 * 
 * @returns JSX
 */


const TabBar: FC<TabBarProps> = ({ isSubmenuOpen, onSubmenuButtonClick, onSubmenuLinkClick }) => {

    const { tokenID, messagesNotSeen } = useAppSelector(state => state.user);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { isMainActive } = useAppSelector(state => state.appMain);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    
    
    const isPublicPath = location.pathname.includes('/public');
    const isLandingPath = location.pathname.includes('/lp');
    
    const brandClickHandler = () => {
        
        navigate(brandSvgLinkTo);
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        if (!isMainActive) dispatch( enableAppMain() );
    };
    
    const dashboardClickHandler = () => {

        navigate('reservedArea/libertydashboard');
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        if (!isMainActive) dispatch( enableAppMain() );
    };
    
    const shopClickHandler = () => {
        if (!isMainActive) dispatch( enableAppMain() );
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        navigate('/reservedArea/shop');
    };
    
    const notifyClickHandler = () => {
        if (!isMainActive) dispatch( enableAppMain() );
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        navigate('/reservedArea/notification');
    };

    const directClickHandler = () => {
        if (!isMainActive) dispatch( enableAppMain() );
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        navigate('/reservedArea/dirette-tematiche');
    };

    const soonClickHandler = () => {
        if (!isMainActive) dispatch( enableAppMain() );
        if (onSubmenuLinkClick) onSubmenuLinkClick();
        navigate('/coomingSoon');
    };
    
    const isUserLoggedIn = !!tokenID;
    const brandSvgLinkTo = isUserLoggedIn ? '/ReservedArea' : '/ReservedArea';
    const isHeaderHidden = (isLandingPath || isPublicPath);
    
    if (isHeaderHidden) {
        
        return (
            // <TabBarBox>
            //     <BrandBox>
            //         <BrandSVG />
            //     </BrandBox>
            // </TabBarBox>
            <BrandSelectorMobile />

        )
    };
    
    return (
        <TabBarBox isUserLoggedIn={isUserLoggedIn}>
            {/* <BrandBox onClick={brandClickHandler}>
                <BrandSVG />
            </BrandBox> */}

            <BrandSelectorMobile />


            {!isUserLoggedIn && (
                <>
                    <SignupText>
                        <Link to='/sign-up'>Iscriviti Ora!</Link>
                    </SignupText>

                    <LoginArea>
                        <SupportBox
                            href='https://www.liberidalavoro.it/public/supporto' 
                            target={'_blank'} 
                            rel='noreferrer noopener'
                        >
                            <SupportSVG />
                        </SupportBox>

                        <LoginBox>
                            <Link to='/login'>
                                <LoginSVG />
                            </Link>
                        </LoginBox>
                    </LoginArea>
                </>
            )}

            {isUserLoggedIn && store.getState().ui.brand?.id !== 3 &&(
                <>
                    <IconsBar>
                        {/* <TabButton 
                            SVGAsset={<DashboardSVG />}
                            onClick={dashboardClickHandler}
                        /> */}
                        <TabButton 
                            SVGAsset={<DirectSVG />}
                            onClick={directClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<ShopSVG />}
                            onClick={shopClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<NotifySVG />}
                            onClick={notifyClickHandler}
                        >
                            {(messagesNotSeen!== undefined) &&
                            (messagesNotSeen !== 0) && (
                                <NewNotificationAlert>
                                    {messagesNotSeen}
                                </NewNotificationAlert>
                            )}
                        </TabButton>
                    </IconsBar>
        
                    <HamburgerButton onClick={onSubmenuButtonClick} />
        
                    {!isAppMobile && (
                        <UserProfileSubmenu
                            isSubmenuOpen={isSubmenuOpen}
                            onLinkClick={onSubmenuLinkClick}
                        />
                    )}
                </>
            )}

            {isUserLoggedIn && store.getState().ui.brand?.id === 3 &&(
                <>
                    <IconsBar>
                        {/* <TabButton 
                            SVGAsset={<DashboardSVG />}
                            onClick={dashboardClickHandler}
                        /> */}
                        <TabButton 
                            SVGAsset={<DirectSVG />}
                            onClick={soonClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<ShopSVG />}
                            onClick={soonClickHandler}
                        />
                        <TabButton 
                            SVGAsset={<NotifySVG />}
                            onClick={notifyClickHandler}
                        >
                            {(messagesNotSeen!== undefined) &&
                            (messagesNotSeen !== 0) && (
                                <NewNotificationAlert>
                                    {messagesNotSeen}
                                </NewNotificationAlert>
                            )}
                        </TabButton>
                    </IconsBar>
        
                    <HamburgerButton onClick={onSubmenuButtonClick} />
        
                    {!isAppMobile && (
                        <UserProfileSubmenu
                            isSubmenuOpen={isSubmenuOpen}
                            onLinkClick={onSubmenuLinkClick}
                        />
                    )}
                </>
            )}
        </TabBarBox>
    );
}

export default TabBar;
