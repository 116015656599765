import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { FullScreenImageModalProps } from './FullScreenImageModal.component.d';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// components
import { Row } from 'style/layout';
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// style
import {
    CardContent,
    CloseButton,
    ImgToEnlarge
} from './style/FullScreenImageModal.style';


const FullScreenImageModal: FC<FullScreenImageModalProps> = ({
    imgUrl,
    onCloseButtonModal
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const buyInfoModalHandler = () => {
        closeButtonHandler();
    };

    const libertyInfoModalHandler = () => {
        closeButtonHandler();
    };

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
            modalFixedStart
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}
                        <ImgToEnlarge 
                            src={imgUrl}
                            alt='Immagine a tutto schermo'
                        />
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default FullScreenImageModal;
