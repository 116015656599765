import { BrandMappers } from './brandMap.d';

const brandMap: BrandMappers = [
    { 
        key: 'homepage',
        brands: ['LDL', 'VDR', 'MB'],
    },
    {
        key: 'shop',
        brands: ['LDL', 'MB'],
    },
    {
        key: 'dirette-tematiche',
        brands: ['LDL', 'MB'],
    },
    {
        key: 'aiuto-privato',
        brands: ['LDL'],
    },
    {
        key: 'oddsscasser',
        brands: ['LDL'],
    },
    {
        key: 'testimonials',
        brands: ['LDL'],
    },
    {
        key: 'liberty-point',
        brands: ['LDL', 'VDR', 'MB'],
    },
    {
        key: 'account-page',
        brands: ['LDL', 'VDR', 'MB', 'TCR'],
    },
    {
        key: 'mycourses',
        brands: ['LDL', 'MB'],
    },
    {
        key: 'myservices',
        brands: ['LDL'],
    },
    {
        key: 'mie-dirette',
        brands: ['LDL', 'MB'],
    },
    {
        key: 'lesson-page',
        brands: ['LDL'],
    },
    {
        key: 'highway-page',
        brands: ['LDL'],
    },
    {
        key: 'libertydashboard',
        brands: ['LDL'],
    },
    {
        key: 'automatic-incomes',
        brands: ['LDL'],
    },
    {
        key: 'notifications',
        brands: ['LDL', 'VDR', 'MB'],
    },
    {
        key: 'support',
        brands: ['LDL', 'VDR', 'MB'],
    },
    {
        key: 'blog',
        brands: ['MB'],
    },
    {
        key: 'strumenti',
        brands: ['VDR'],
    }
];

export default brandMap;