import { FC, useEffect, useRef, useState } from 'react';

//components
import CardOfPower from '../CardOfPower/CardOfPower.component';

//utils
import { getCenteredSubArray } from 'components/Carousel/ImageCarousel/ImageCarousel.helper';
import { getEnvironmentAssetLinkUrl } from 'configs/config.helper';
import {
	getItemFromLocalStorage,
	setItemToLocalStorage,
} from 'utils/localStorage';

//types
import { CardsCarouselProps } from './CardsCarouselMobile.component.d';
import { CardOfPower as CardOfPowerType } from '../VdrCards.d';

//style
import { Carousel } from './style/CardsCarouselMobile.style';
import useAppSelector from 'hooks/useAppSelector';

const initialShuffleTransitionDuration = 50;
const shuffleSpeedChangeDuration = 500;
const totalShuffleDuration = 10000;

const audioFxUrl = getEnvironmentAssetLinkUrl(`/audio/fx-audio-cdp.mp3`);

const CardsCarouselMobile: FC<CardsCarouselProps> = ({
	cards,
	isShuffling,
	setIsShuffling,
	showResult,
	setShowResult,
}) => {
	const [currentCardIndex, setCurrentCardIndex] = useState(
		Math.floor(Math.random() * cards.length)
	);
	const [drawnCard, setDrawnCard] = useState<CardOfPowerType | null>(null);
	const [hasStartedShuffling, setHasStartedShuffling] = useState(false);

	const shuffleIntervalDuration = useRef(initialShuffleTransitionDuration);

	const shuffleTimeoutRef = useRef<NodeJS.Timeout>();
	const progressionTimeoutRef = useRef<NodeJS.Timeout>();

	let audioFx = new Audio(audioFxUrl);

	const { tokenID } = useAppSelector((state) => state.user);
	const isUserLoggedIn = tokenID !== undefined ? !!tokenID : false;
	// const isUserLoggedIn = true;

	const startShuffle = () => {
		setHasStartedShuffling(true);
		audioFx.play();
		shuffleTimeoutRef.current = setTimeout(() => {
			setCurrentCardIndex((currentCardIndex) =>
				currentCardIndex === cards.length - 1 ? 0 : currentCardIndex + 1
			);
			startShuffle(); // Recursively start the next shuffle
		}, shuffleIntervalDuration.current);
	};

	const startProgression = (remainingDuration: number) => {
		if (remainingDuration <= 0) {
			setIsShuffling(false);
			clearAllTimeouts();
			return;
		}

		progressionTimeoutRef.current = setTimeout(() => {
			shuffleIntervalDuration.current *= 1.25;
			startProgression(remainingDuration - shuffleSpeedChangeDuration);
		}, shuffleSpeedChangeDuration);
	};

	const clearAllTimeouts = () => {
		clearTimeout(shuffleTimeoutRef.current);
		clearTimeout(progressionTimeoutRef.current);
		// Set the drawn card when shuffling ends
		if (!isShuffling && hasStartedShuffling) {
			setDrawnCard(cards[currentCardIndex + 1]);
			setHasStartedShuffling(false); // Reset the flag
		}
	};

	useEffect(() => {
		// Check for a stored drawn card on component mount
		const storedDrawnCardId = getItemFromLocalStorage('drawnCardId');

		if (storedDrawnCardId) {
			const storedCard = cards.find(
				(card) => card.id.toString() === storedDrawnCardId
			);
			if (storedCard) {
				setDrawnCard(storedCard);
				setIsShuffling(false);
				setCurrentCardIndex(
					cards.findIndex((card) => card.id === storedCard.id) - 1
				);
			}
		}
	}, []); // Empty dependency array ensures this runs only once on mount

	useEffect(() => {
		if (isShuffling) {
			// Reset the drawn card when shuffling starts
			setDrawnCard(null);
			shuffleIntervalDuration.current = initialShuffleTransitionDuration; // Reset to initial duration
			startShuffle();
			startProgression(totalShuffleDuration);
		} else {
			clearAllTimeouts();
		}
		return () => {
			if (hasStartedShuffling) {
				clearAllTimeouts();
			}
		};
	}, [isShuffling]);

	useEffect(() => {
		if (isUserLoggedIn) {
			// Retrieve the stored drawn card if the user is logged in
			const storedDrawnCardId = getItemFromLocalStorage('drawnCardId');
			var timeoutId: any;
			if (storedDrawnCardId) {
				const storedCard = cards.find(
					(card) => card.id.toString() === storedDrawnCardId
				);
				if (storedCard) {
					setDrawnCard(storedCard);
				}
				// Optionally clear the local storage after retrieving the card
				timeoutId = setTimeout(() => {
					setItemToLocalStorage('drawnCardId', '');
				}, 5000);
			}
			return () => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
			};
		}
	}, [isUserLoggedIn, cards]);

	useEffect(() => {
		// Store the drawn card in local storage if the user is not logged in
		if (!isUserLoggedIn && drawnCard) {
			setItemToLocalStorage('drawnCardId', drawnCard.id.toString());
		}
	}, [drawnCard, isUserLoggedIn]);

	return (
		<Carousel>
			{getCenteredSubArray(cards, currentCardIndex, 7).map(
				(card, index) => {
					return (
						<CardOfPower
							key={card.assetName}
							card={card}
							cIndex={index - 3}
							duration={shuffleIntervalDuration.current}
							showCard={drawnCard?.id === card.id && !isShuffling}
							//   handleClickCard={handleClickCard}
							//   setIsVideoPaused={setIsVideoPaused}
							//   isTestimonialImg={areTestimonialsIMG}
							//   startCarousel={startInterval}
							//   stopCarousel={stopInterval}
						/>
					);
				}
			)}
		</Carousel>
	);
};

export default CardsCarouselMobile;
