import { FC } from "react";

//hooks
import useAppSelector from "hooks/useAppSelector";

//components
import CtaCard from '../CtaCard/CtaCard.component'

//style
import { CtaBlockBox } from "./styled/CtaBlock.component.style";

const CtaBlock: FC = () => {

    const { isAppMobile } = useAppSelector( state => state.ui);

    return (
        <CtaBlockBox>
            {isAppMobile && 
                <>
                    <CtaCard 
                        title='NON ho Soldi' 
                        description={<>Se NON hai soldi, un REGALO per TE:<br/>Puoi Ricevere in Bonus il capitale<br/>che ti serve per iniziare</>} 
                        ctaText='VOGLIO IL REGALO' 
                        ctaUrl='https://liberidalavoro.it/app/public/product/93'
                    />
                    <CtaCard 
                        title='Sono Diffidente' 
                        description={<>Entra nel Gruppo Aperto a Tutti, <br/>trovi centinaia di risultati pubblicati ogni mese e puoi parlare Liberamente coi nostri iscritti</>}
                        ctaText='ENTRA NEL GRUPPO' 
                        ctaUrl='https://www.facebook.com/groups/LiberoDalLavoro'
                    />           
                </>
            }
            {!isAppMobile && 
                <>
                    <CtaCard 
                        title='Sono Diffidente' 
                        description={<>Entra nel Gruppo Aperto a Tutti, <br/>trovi centinaia di risultati pubblicati ogni mese e puoi parlare Liberamente coi nostri iscritti</>}
                        ctaText='ENTRA NEL GRUPPO' 
                        ctaUrl='https://www.facebook.com/groups/LiberoDalLavoro'
                    />   
                    <CtaCard 
                        title='NON ho Soldi' 
                        description={<>Se NON hai soldi, un REGALO per TE:<br/>Puoi Ricevere in Bonus il capitale<br/>che ti serve per iniziare</>} 
                        ctaText='VOGLIO IL REGALO' 
                        ctaUrl='https://liberidalavoro.it/app/public/product/93'
                    />       
                </>
            }
            <CtaCard 
                title='Voglio Partire Subito' 
                description={<>Ottimo, puoi iniziare adesso dal primo piccolo passo e scoprire come Avere un Assistente Personale che ti segue durante tutto il Percorso</>} 
                ctaText='VOGLIO INIZIARE' 
                ctaUrl='https://liberidalavoro.it/app/public/product/151'
            />
        </CtaBlockBox>
    )
}

export default CtaBlock;