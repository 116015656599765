import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 24px 0 0;
`;

export const UserInputsBox = styled.div`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    flex-direction: column;
`;

export const NameInput = styled.div`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    flex-direction: column;

    @media ${devices.tablet} {

        align-items: flex-start;

        gap: 12px;
        flex-direction: row;
    }
`

export const PasswordInput = styled.div`

    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    flex-direction: column;

    @media ${devices.tablet} {

        align-items: flex-start;

        gap: 12px;
        flex-direction: row;
    }
`

export const LoginButton = styled(Button)`

    width: 100%;

    margin-top: 24px;
    padding: 15px 8px;

    font-size: 15px;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    color: ${colors.black087};

    @media ${devices.tablet} {

        letter-spacing: normal;
    }
`;

export const PrivacyAndCookiesCheck = styled.div`

    padding: 0 35.5px 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 13px;

    margin: 24px 0 0;

    @media ${devices.tablet} {
        
        padding: 0 20px;
    }
`;

export const PrivacyAndCookiesText = styled.p`    
    margin: 0;
    padding: 0;
    color: ${colors.white};

    font-size: 15px;
    line-height: 15px;
    font-weight: 400;

    > a {
        color: ${colors.primaryColor};
        text-decoration: none;
    }
`;

export const CheckBoxRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    width: 100%;

    @media ${devices.tablet} {
        gap: 10px;
    }
`;

export const CheckBoxErrorMessage = styled.p`

    width: 100%;

    margin: 0;
    padding: 0;
    
    font-size: 12px;
    font-weight: 400;

    text-align: center;
    color: ${colors.red};
`;

export const LoginReturnLink = styled.div`

    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid ${colors.white};
    color: ${colors.white};

    cursor: pointer;

    > a {
        
        display: inline-block;
        text-decoration: none;
        
        padding: 0 0 6px;

        font-size: 15px;
        font-weight: 400;

        color: ${colors.white};
    }
`;
