import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';

export const Title = styled.div<{isActive?: boolean, tier: number}>`

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    /* border: none; */

    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #76838D;
    /* padding: 13px 20px; */

    padding: ${({tier}) => `13px ${(tier * 15)}px`};
    font-weight: 600;
    font-size: 13px;
    
    color: ${colors.white};
    background: #3C4B57;
    
    cursor: pointer;
    
    span {
        margin: 0 0 0 10px;
        font-style: italic;
    }

    svg {
        margin: 0 12px 0 0;
        flex-shrink: 0;
    }

    ${({isActive}) => isActive && 
        css`
            background: ${colors.primaryColor};

            color: ${colors.black};
        `
    }
`;

export const TitleText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
`;