export const formatAmountToPadNumber = (amount: any): string => {

    if ( !amount || Number.isNaN(amount) ) return "00";

    if ((typeof amount) === 'string') {

        if (amount.length < 2) return `0${amount}`;
        return amount;
    }

    if (amount < 0) return "00";
    if (amount < 10) return `0${amount}`;

    return amount.toString();
};
