import { FC } from 'react';

// style
import {
    WhatsAppCallAssignedBox,
    WhatsAppCallAssignedBoxContent,
    WhatsAppCallAssignedTextContent,
    WhatsAppCallAssignedText,
    WhatsAppCongratulationText,
} from './style/WhatsAppCallAssignedCard.style';

const WhatsAppCallAssignedCard: FC<{coach: any}> = ({ coach }) => {

    console.log("COACH", coach);
    
    return (
        <WhatsAppCallAssignedBox>
            <WhatsAppCallAssignedBoxContent>
                    <WhatsAppCallAssignedTextContent>
                        <WhatsAppCallAssignedText>
                            <WhatsAppCongratulationText>Complimenti (!) Ti è stato assegnato un Assistente Personale: {coach.name}</WhatsAppCongratulationText> 
                            <br />
                            <br />
                            Ti contatterà a breve su Whatsapp
                        </WhatsAppCallAssignedText>
                    </WhatsAppCallAssignedTextContent>
            </WhatsAppCallAssignedBoxContent>
        </WhatsAppCallAssignedBox>
    )
};

export default WhatsAppCallAssignedCard;