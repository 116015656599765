import React, { FC, useState, useEffect, useRef } from 'react';

// assets
import { ReactComponent as PlayIcon } from './assets/play.svg';
import { ReactComponent as PauseIcon } from './assets/pause.svg';

//components

// style
import {
	AudioRange,
	InstructionsPlayerCard,
	InstructionsPlayerIcon,
	InstructionsPlayerTrack,
	InstructionsPlayerTrackAuthor,
	InstructionsPlayerTrackTitle,
} from './style/VdrToolsAudioPlayer.style';

const VdrToolsAudioPlayer: FC<{
	title: string;
	author: string;
	audioSrc: string;
}> = ({ title, author, audioSrc }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const audioRef = useRef<HTMLAudioElement>(new Audio(audioSrc));
	const intervalRef = useRef<number | null>(null);

	const handlePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const onLoadedMetadata = () => {
		setDuration(audioRef.current.duration);
	};

	const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const seekTime = Number(e.target.value);
		audioRef.current.currentTime = seekTime;
		setCurrentTime(seekTime);
	};

	const updateCurrentTime = () => {
		setCurrentTime(audioRef.current.currentTime);
	};

	useEffect(() => {
		audioRef.current.addEventListener('timeupdate', updateCurrentTime);

		return () => {
			audioRef.current.removeEventListener(
				'timeupdate',
				updateCurrentTime
			);
		};
	}, []);

	useEffect(() => {
		audioRef.current.addEventListener('loadedmetadata', onLoadedMetadata);

		return () => {
			audioRef.current.removeEventListener(
				'loadedmetadata',
				onLoadedMetadata
			);
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, []);

	return (
		<InstructionsPlayerCard>
			<InstructionsPlayerIcon
				isPlaying={isPlaying}
				onClick={handlePlayPause}
			>
				{isPlaying ? (
					<PauseIcon
						width={20}
						height={20}
					/>
				) : (
					<PlayIcon
						width={20}
						height={20}
					/>
				)}
			</InstructionsPlayerIcon>
			<InstructionsPlayerTrack>
				<InstructionsPlayerTrackTitle>
					{title}
				</InstructionsPlayerTrackTitle>
				<InstructionsPlayerTrackAuthor>
					di {author}
				</InstructionsPlayerTrackAuthor>
			</InstructionsPlayerTrack>
			<AudioRange
				type='range'
				value={currentTime}
				step='1'
				min='0'
				max={duration}
				onChange={handleSliderChange}
			/>
		</InstructionsPlayerCard>
	);
};

export default VdrToolsAudioPlayer;
