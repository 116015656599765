import { FC, ChangeEvent, useEffect, useState, useRef } from 'react';

// types
import { NumberInputProps } from './NumberInput.component.d';

// style
import {
    InputLabelBox,
    Label,
    Input,
    StepButton
} from './style/NumberInput.style';


const NumberInput: FC<NumberInputProps> = ({
    label,
    value,
    isDisabled,
    isDisabledOpacity,
    minValue,
    maxValue,
    includeLimits,
    allowEmpty,
    noDecimals,
    negative,
    decimals,
    stepFunction,
    onChange,
    onEditFinished,
    labelWidth,
    inputWidth,
    gap,
    height,
    small,
    inputPlaceholder,
    labelFontSize
}) => {

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [focus, setFocus] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        validateNumber(event.target.value, false);
    };
    
    useEffect(() => {
        validateNumber(value, false);
    }, [value, minValue, maxValue]);

    const validateNumber = (newValue: string, editFinished: boolean) => {
        newValue = newValue.replace(',','.');//Allow to use ','
        if(!negative) newValue.replace('-','');
        newValue = newValue.replace(noDecimals ? /[^\d-]/g : /[^\d.-]/g, '');//Allow only numeric characters and '.'
        const v = parseFloat(newValue);
        let newErrorMessage = undefined;
        if(isNaN(v)) newErrorMessage = 'NaN';
        else {
            if(minValue !== undefined) {
                if(v < minValue) newErrorMessage = 'Under minValue';
                else if(!includeLimits && v == minValue) newErrorMessage = 'Equal to minValue';
            }
            if(maxValue !== undefined) {
                if(v > maxValue) newErrorMessage = 'Over maxValue';
                else if(!includeLimits && v == maxValue) newErrorMessage = 'Equal to maxValue';
            }
        }
        if(newValue.length===0 && allowEmpty) newErrorMessage = undefined;
        setErrorMessage(newErrorMessage);
        (value !== newValue || errorMessage !== newErrorMessage) && onChange && onChange({value: newValue, errorMessage: newErrorMessage});
        editFinished && onEditFinished && onEditFinished({value: newValue, errorMessage: newErrorMessage});
    };

    const step = (direction: 'up' | 'down') => {
        if(stepFunction) {
            if(errorMessage !== 'NaN' && !isNaN(parseFloat(value))) {
                const v = parseFloat(value);
                let newVal = v;
                if(includeLimits && maxValue !== undefined && minValue !== undefined && ((direction === 'up' && Math.abs(maxValue-v)<0.0001) || (direction === 'down' && Math.abs(minValue-v)<0.0001))) {
                    newVal = direction === 'up' ? minValue : maxValue;
                } else {
                    newVal = stepFunction(v, direction);
                    if(minValue !== undefined && newVal < minValue) newVal = minValue + (includeLimits ? 0 : 0.01);
                    if(maxValue !== undefined && newVal > maxValue) newVal = maxValue - (includeLimits ? 0 : 0.01);
                }
                validateNumber(decimals ? newVal.toFixed(decimals) : newVal.toString(), true);
            } else {
                let newVal = 0;
                
                if(maxValue !== undefined && direction === 'down') newVal = maxValue;
                else if(minValue !== undefined && direction === 'up') newVal = minValue;
                else if(maxValue !== undefined) newVal = maxValue;
                else if(minValue !== undefined) newVal = minValue;
                validateNumber(decimals ? newVal.toFixed(decimals) : newVal.toString(), true);
            }
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') step('down');
        else if (event.key === 'ArrowUp') step('up');
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        onEditFinished && onEditFinished({value, errorMessage});
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if(!isDisabled) {
            event.target.select();
            setFocus(true);
        }
    }

    const handleLabelClick = () => {
        if(!isDisabled && inputRef.current) {
            inputRef.current.select();
            setFocus(true);
        }
    }

    return (
        <InputLabelBox onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} hover={hover} focus={focus} isDisabled={isDisabled} isDisabledOpacity={isDisabledOpacity} label={label} labelWidth={labelWidth} inputWidth={inputWidth} gap={gap} small={small} height={height}>
            {label && <Label isInputInvalid={!!errorMessage} focus={focus} label={label} fontSize={labelFontSize} labelWidth={labelWidth} inputWidth={inputWidth} gap={gap} isDisabled={isDisabled} onClick={handleLabelClick} >{label}</Label>}
            <Input ref={inputRef} type='text' value={value} placeholder={inputPlaceholder} readOnly={(isDisabledOpacity ?? 0)>0.99 ? false : isDisabled} isDisabled={isDisabled} isInputInvalid={!!errorMessage} label={label} labelWidth={labelWidth} inputWidth={inputWidth} gap={gap} onChange={inputChangeHandler} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown} small={small} height={height}/>
            { stepFunction && <StepButton hover={hover} focus={focus} isDisabled={isDisabled} label={label} labelWidth={labelWidth} gap={gap} small={small} height={height} onClick={() => step('down')}></StepButton>}
            { stepFunction && <StepButton up={true} hover={hover} focus={focus} isDisabled={isDisabled} label={label} labelWidth={labelWidth} gap={gap} small={small} height={height} onClick={() => step('up')}/>}
        </InputLabelBox>
    );
};

NumberInput.defaultProps = {
    label: '',
    value: '',
    isDisabled: false
};

export default NumberInput;
