import React, { ReactNode, AnchorHTMLAttributes } from 'react';
import { Link as OriginalLink, useLocation, To } from 'react-router-dom';

interface BrandedLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: To;
  children: ReactNode;
  // Include any other props you might need
}

const BrandedLink: React.FC<BrandedLinkProps> = ({ to, children, ...props }) => {
  const location = useLocation();
  const isLocalhost = window.location.hostname === 'localhost';

  let modifiedTo: To = to;

  if (isLocalhost) {
    // Check if 'brand' parameter exists in the current URL
    const searchParams = new URLSearchParams(location.search);
    const brand = searchParams.get('brand');

    if (brand) {
      // Append 'brand' parameter to the 'to' prop
      const newSearchParams = new URLSearchParams({ brand });

      if (typeof to === 'string') {
        const delimiter = to.includes('?') ? '&' : '?';
        modifiedTo = `${to}${delimiter}${newSearchParams}`;
      } else if (typeof to === 'object') {
        // Handle case where 'to' is an object
        const search = to.search ? `${to.search}&${newSearchParams}` : `?${newSearchParams}`;
        modifiedTo = { ...to, search };
      }
    }
  }

  return <OriginalLink to={modifiedTo} {...props}>{children}</OriginalLink>;
};

export default BrandedLink;
