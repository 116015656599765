import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const MainComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    
    gap: 12px;
    flex-shrink: 0;

    @media ${devices.tablet} {
        width: 290px;

    }
`;

export const TitleComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    gap: 11px;
    padding: 0px 10px;
    width: 100%;

    >svg{
        flex-shrink: 0;

        width: 35.29px;
        height: 35px;
    }

    >p{
        flex-shrink: 0;

        width: 35.29px;
        height: 35px;

        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const NoCategoryBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    width: 35.29px;
    height: 35px;
`;

export const TitleText = styled.h3`
    color: ${colors.white};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; 

    margin: 0;
    padding: 0;

    width: 179px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    max-height: 38px; 
    text-overflow: ellipsis;
    white-space: normal;
`;

export const InfoComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    padding-right: 0px;
    gap: 15px;

    width: 100%;

    >svg{
        flex-shrink: 0;

        width: 21.589px;
        height: 13.619px;
    }

    @media ${devices.tablet} {

    }
`;

export const InfoButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 66.667px;
    border: 0.667px solid ${colors.white};
    background: ${colors.black};
    box-shadow: 
        0px 0.6666668057441711px 3.333333730697632px 0px ${colors.black012}, 
        0px 1.3333336114883423px 1.3333336114883423px 0px ${colors.black014}, 
        0px 2.000000476837158px 0.6666668057441711px -1.3333336114883423px rgba(0, 0, 0, 0.20);
    
    padding: 6px 10px 7.333px 10px;

    cursor: pointer;

    >svg{
        flex-shrink: 0;

        width: 8px;
        height: 14.667px;
        fill: ${colors.white};
    }

    @media ${devices.tablet} {

    }
`;

export const PlayButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 66.667px;
    border: 0.667px solid ${colors.white};
    background: ${colors.black};
    box-shadow: 
        0px 0.6666668057441711px 3.333333730697632px 0px ${colors.black012}, 
        0px 1.3333336114883423px 1.3333336114883423px 0px ${colors.black014}, 
        0px 2.000000476837158px 0.6666668057441711px -1.3333336114883423px rgba(0, 0, 0, 0.20);
    
    padding: 6px 10px 7px 10px;

    cursor: pointer;

    >svg{
        flex-shrink: 0;

        width: 8px;
        height: 15px;
        fill: ${colors.white};
    }

    @media ${devices.tablet} {

    }
`;

export const DoubleActionBox = styled.div`
    
    display: flex;
    align-items: center;
    gap: 8px;
    @media ${devices.tablet} {

    }
`;

export const DayTime = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    @media ${devices.tablet} {

    }
`;

export const DayText = styled.p`
    color: ${colors.blueGrey};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;

    margin: 0;
    padding: 0;

    max-width: 71px;
    @media ${devices.tablet} {

    }
`;

export const TimeText = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 4px;

    color: ${colors.blueGrey};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;

    padding: 0;
    margin: 0;
    
    >svg{
        flex-shrink: 0;

        width: 11px;
        height: 11px;
    }
    @media ${devices.tablet} {

    }
`;

export const ButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    gap: 7px;
    padding: 0px 14px;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const NewButtonShop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);

    @media ${devices.tablet} {

    }
`;

export const ButtonSelection = styled.div`
    
    display: flex;
    align-items: flex-end;
    align-self: stretch;

    padding-right: 0px;

    width: 100%;
    max-height: 33px;

    @media ${devices.tablet} {
        max-width: 262px;
    }
`;

export const WaiterButton = styled.div<{isSelected: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 11px 10px 8px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 5px 0px 0px 0px;
    background: ${props => props.isSelected ? colors.primaryColor : colors.grey};

    cursor: default;

    >p{
        color: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;
        fill: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
    }

    @media ${devices.tablet} {

    }
`;

export const ProtagonistButton = styled.div<{isSelected: boolean, isSpectatorOwn?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 9px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 0px 5px 0px 0px;
    border-left: 1px solid ${colors.darkGrey};
    background: ${props => props.isSelected ? colors.primaryColor : colors.grey};

    cursor: default;

    >p{
        color: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;

        fill: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.50)'};
    }
    @media ${devices.tablet} {

    }
`;

export const ProtagonistButtonForSpectator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 9px 10px;
    gap: 5px;

    width: 100%;
    height: 100%;

    border-radius: 5px 5px 0 0;
    border: 1px solid ${colors.primaryColor};
    background: ${colors.primaryColor};

    cursor: default;

    >p{
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    >svg{
        flex-shrink: 0;

        width: 13.333px;
        height: 12px;

        fill: rgba(0, 0, 0, 0.87);
    }
    @media ${devices.tablet} {

    }
`;


export const MainButton = styled.div <{isOwned?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 3px 0px 5px 0px;

    border-radius: ${({ isOwned }) => (isOwned ? '5px' : '0px 0px 5px 5px')}; 
    background: ${({ isOwned }) => (isOwned ? '#626D78' : colors.primaryColor)};

    width: 100%;
    height: ${({ isOwned }) => (isOwned ? '39px' : '30px')};
    overflow: hidden;

    cursor: ${({ isOwned }) => (isOwned ? 'auto' : 'pointer')};

    position: relative;

    >svg{
        position: absolute;

        top: -2px;
        right: 0;
    }

    @media ${devices.tablet} {

    }
`;

export const LibertyPrice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 0px;
    gap: 4px;

    >svg{
        flex-shrink: 0;

        width: 21.08px;
        height: 19px;

        fill: ${colors.black};
    }

    >p{
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        
        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const ButtonText = styled.p`
    
    flex-direction: row;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11px;
    font-style: normal;
    font-weight: 500;

    padding: 0;
    margin: 0;
    @media ${devices.tablet} {

    }
`;

export const PaymentSelector = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const PriceInfo = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    >svg{
        flex-shrink: 0;
        
        width: 12.641px;
        height: 15px;
    }

    >p{
        color: ${colors.black};
        font-size: 11px;
        font-style: italic;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }
    
    @media ${devices.tablet} {

    }
`;

export const ArrowSelection = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 7px 8px 7px;
    gap: 10px;

    border-radius: 5px;
    background: ${colors.grey};


    >svg{
        flex-shrink: 0;

        width: 10px;
        height: 5px;

        fill: rgba(255, 255, 255, 0.50);
    }

    @media ${devices.tablet} {

    }
`;

export const DescriptionIcon = styled.div<{isOwned: boolean}>`
    
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    right: ${({ isOwned }) => (isOwned ? '-50px' : '-33px')};
    top: -33px;


    >svg{
        width: 13.854px;
        height: 4.5px;

        fill: ${colors.veryDarkGrey};
    }

    @media ${devices.tablet} {
        right: ${({ isOwned }) => (isOwned ? '-45px' : '-23px')};
    }
`;

export const IconBox = styled.div`
    display: flex;
    align-items: center;
    gap: 2;

    >svg{
        cursor: pointer;
        flex-shrink: 0;

        width: 21.589px;
        height: 13.619px;
    }

    @media ${devices.tablet} {

    }
`;

export const DirectTvIconBox = styled.div`

    >svg{
        flex-shrink: 0;

        width: 21.589px;
        height: 13.619px;
    }

    @media ${devices.tablet} {

    }
`;

export const DirectTvIconLinkBox = styled.div`

    >svg{
        cursor: pointer;
        flex-shrink: 0;

        width: 21.589px;
        height: 13.619px;
    }

    @media ${devices.tablet} {

    }
`;

export const IconText = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding: 9px 9px 9px 9px;

    border-radius: 5px;
    background: ${colors.veryDarkGrey};

    >p{
        color: ${colors.white};
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;

        margin: 0;
        padding: 0;
    }

    @media ${devices.tablet} {

    }
`;