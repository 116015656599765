import { FC, useState, useEffect } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { CoverOddsModalProps } from './CoverOddsModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import CheckboxesGroup from 'components/CheckboxesGroup';
import Dropdown from 'components/Dropdown';

// assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as SwitchIconSVG } from 'pages/OddsScasserPage/assets/switch.svg';

// style
import { BaseCard } from 'style/wrappers';
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    CoverOddsContainer,
    CoverRow,
    CoversList,
    CoversListContainer
} from './style/CoverOddsModal.style';
import { DropdownContent, OddsValue, Rating, SVGIconContainer, SelectAll, SiteAndOdds, Selection, SiteBox, SiteLogo, SiteLogoContainer, SiteSelectionAndOdds, SitesCheckboxes, StandardCheckbox, VerticalScrollContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

//types
import { Site } from 'pages/OddsScasserPage/types/Site';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';

const CoverOddsModal: FC<CoverOddsModalProps> = ({ eventId, selection, oddsMatches, onChangeCoverClick, onCloseButtonModal, copyToClipboard }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);

    const { sites } = useAppSelector(state => state.oddsScasser);

    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [showThreeOutcomes, setShowThreeOutcomes] = useState<boolean>(true);
    const [sitesList, setSitesList] = useState<Site[]>([]);
    const [sitesChecked, setSitesChecked] = useState<boolean[]>([]);
    
    //Set sites when mode/sites changes
    useEffect(() => {
        if(sites.size>0 && sitesList.length === 0) setSitesList(Array.from(sites.values()).filter(site =>  site.type === 'bookmaker'));
    }, [sites])
    useEffect(() => {
        setSitesChecked(new Array(sitesList.length).fill(false));
    }, [sitesList])

    const handleChangeCoverClick = (eventId: number, oddsMatch: OddsMatch) => {
        onChangeCoverClick(eventId, oddsMatch);
        setIsCloseButtonClicked(true);
    }

    const sitesSelected = sitesChecked.filter(Boolean).length;
    const sitesSelectedIds = sitesList.filter((_,i) => sitesChecked[i]).map(x => x.id);
    const filteredOddsMatches = oddsMatches.filter(x => {
        if(!showThreeOutcomes && x.odds.length>1) return false;
        if(sitesSelected>0) {
            if(!sitesSelectedIds.includes(x.odds[0].site.id)) return false;
            if(x.odds.length>1 && !sitesSelectedIds.includes(x.odds[1].site.id)) return false;
        }
        return true;
    });
    const threeOutcomes = ['1','X','2'].includes(selection);
    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive={true}
            modalFixedStart={true}
            width='auto'
            overflow='visible'
        >
            <BaseCard flavor='outlined' maxHeight={isAppMobile ? 'calc(100% - 80px)' : undefined} height='fit-content' isModal>
                <CardContent>
                    <Row crossAxis='center' mainAxis='flex-end'>
                        <CloseButton onClick={() => setIsCloseButtonClicked(true)}><CloseSVG /></CloseButton>
                    </Row>
                    <Row gap='10px' mainAxis='space-evenly'>
                        <Dropdown 
                            buttonText={'Bookmakers'}
                            buttonWidth={110}
                            buttonHighlighted={sitesSelected>0 && sitesSelected<sitesChecked.length}
                            button={sitesSelected===1 ? <SiteBox checked={true} width={110} height={36}><SiteLogoContainer visible={true} backgroundColor={sitesList[Math.max(0, sitesChecked.findIndex(Boolean))].color}><SiteLogo width='75px' imageUrl={sitesList[Math.max(0, sitesChecked.findIndex(Boolean))].imageUrl}/></SiteLogoContainer></SiteBox> : undefined}
                            content={
                                <DropdownContent width={175}>   
                                    <SelectAll onClick={() => setSitesChecked(new Array(sitesList.length).fill(sitesSelected===0))}>
                                        {sitesSelected===0 ? 'Seleziona tutti' : 'Deseleziona tutti'}
                                    </SelectAll>
                                    <SitesCheckboxes>
                                        <CheckboxesGroup options={ sitesList.map((site, index) => <SiteBox checked={sitesChecked[index]} name={site.name}><SiteLogoContainer visible={sitesChecked[index]} backgroundColor={site.color}><SiteLogo  imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>) } checked={sitesChecked} onOptionClick={(checked: boolean[]) => setSitesChecked(checked)}/>
                                    </SitesCheckboxes>
                                </DropdownContent>
                            }
                        />
                        {threeOutcomes && <StandardCheckbox checked={showThreeOutcomes} width='75px' backgroundColor='#3672ff' onClick={() => setShowThreeOutcomes(prevState => !prevState)}>3 esiti</StandardCheckbox>}
                    </Row>
                    {filteredOddsMatches.length>0 &&
                        <CoversListContainer>
                            <VerticalScrollContainer>
                                <CoversList>
                                    {filteredOddsMatches.map((x,i) => {
                                        const site1 = sites.get(x.odds[0].site.id); 
                                        const site2 = x.odds.length>1 ? sites.get(x.odds[1].site.id) : undefined; 
                                        return(
                                            <CoverRow key={i} withSelection={threeOutcomes}>
                                                <Rating color='white'>{(100*x.rating).toFixed(2)}%</Rating>
                                                <CoverOddsContainer>
                                                    {!threeOutcomes &&
                                                        <SiteAndOdds>
                                                            <SiteBox checked={true} notClickable={x.odds[0].url===undefined} onClick={() => x.odds[0].url && copyToClipboard && copyToClipboard(x.odds[0].url, `URL ${site1?.name} copiato`)}>
                                                                <SiteLogoContainer visible={true} backgroundColor={site1?.color}><SiteLogo imageUrl={site1?.imageUrl}/></SiteLogoContainer>
                                                            </SiteBox>
                                                            <OddsValue>{(x.odds[0].odds).toFixed(2)}</OddsValue>
                                                        </SiteAndOdds>
                                                    }
                                                    {threeOutcomes &&
                                                        <SiteSelectionAndOdds>
                                                            <SiteBox checked={true} notClickable={x.odds[0].url===undefined} onClick={() => x.odds[0].url && copyToClipboard && copyToClipboard(x.odds[0].url, `URL ${site1?.name} copiato`)}>
                                                                <SiteLogoContainer visible={true} backgroundColor={site1?.color}><SiteLogo imageUrl={site1?.imageUrl}/></SiteLogoContainer>
                                                            </SiteBox>
                                                            <Selection>{x.odds[0].type}</Selection>
                                                            <OddsValue>{(x.odds[0].odds).toFixed(2)}</OddsValue>
                                                        </SiteSelectionAndOdds>
                                                    }
                                                    {site2 &&
                                                        <SiteSelectionAndOdds>
                                                            <SiteBox checked={true} notClickable={x.odds[1].url===undefined} onClick={() => x.odds[1].url && copyToClipboard && copyToClipboard(x.odds[1].url, `URL ${site2.name} copiato`)}>
                                                                <SiteLogoContainer visible={true} backgroundColor={site2.color}><SiteLogo imageUrl={site2.imageUrl}/></SiteLogoContainer>
                                                            </SiteBox>
                                                            <Selection>{x.odds[1].type}</Selection>
                                                            <OddsValue>{(x.odds[1].odds).toFixed(2)}</OddsValue>
                                                        </SiteSelectionAndOdds>
                                                    }
                                                </CoverOddsContainer>
                                                <SVGIconContainer size={30} right={0}><SwitchIconSVG title='Seleziona questa copertura' onClick={() => handleChangeCoverClick(eventId, x)}/></SVGIconContainer>
                                            </CoverRow>
                                        );
                                    })}
                                </CoversList>
                            </VerticalScrollContainer>
                        </CoversListContainer>
                    }
                    

                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default CoverOddsModal;
