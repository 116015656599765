import { callGET } from "network/network";
import store from "redux/store";


export const getUserChannels = async () => {

    try {
        const brandCode = store.getState().ui.brand?.code;
        if (!brandCode) return;
        const response = await callGET(`v1/self/channelsubscriptions?brandCode=${brandCode}`, true);
        if (!response || response === undefined) throw new Error ("Unable getting user's channels list.");

        return response;        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getMessagesFromChannel = async (channelId: number, page?: number) => {

    try {

        const channelParams = {page: page??0}
        
        const response = await callGET(`v1/self/channelmessages/${channelId}/all`, true, channelParams);
        if (!response || response === undefined) throw new Error ("Unable getting user's channels list.");

        return response;        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};
