import { getEnvironmentProductId } from "configs/config.helper";
import { callGET, callOldGET, callOldPOST, callPOST, callPUT } from "network/network";
import store from "redux/store";

export const checkForHighway =async (paramsPayload: any) => {
    
    try {

        const response = await callOldGET('autostrada/getbyidclienteanddatachiusuraisnull', true, paramsPayload)
        if (!response || response === undefined) {
            throw new Error ("Unable to get Highway check.");
        }

        return response;
    } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
}

export const getHighwayId = async (paramsPayload: any) => {

    try {

        const response = await callOldGET('autostrada/getincorsobyidcliente', true, paramsPayload);
        if (!response || response === undefined) {
            throw new Error ("Unable to get Highway ID.");
        }

        return response;
    } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getHighwayLessons = async (paramsPayload?: any) => {

    // try {
    //     const response = await callOldGET('autostradalesson/getByAutostradaId', true, paramsPayload);
    //     if (!response || response === undefined) {
    //         throw new Error ("Unable to get Highway lessons.");
    //     }

    //     return response;
    // } catch (error:any) {
    //     if (error.message.includes('403')) throw new Error ('403');
    //     throw error;
    // }

    const highwayParams = {
        brandCode: store.getState().ui.brand?.code
    };

    try {

        const response = await callGET(`v1/self/productsubscriptions/products/${getEnvironmentProductId('autostrada')}`, true, highwayParams);
        if (!response || response === undefined) {
            throw new Error ("Unable to get Highway ID.");
        }

        return response.filter((highway: any) => highway.productLessonPack.status === 'OPENED')[0];
    } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }

};

export const getNewHighwayLesson =async (subscriptionId: number) => {
     
    // try {

    //     const response = await callOldPOST('autostradalesson', null, true, paramsPayload);
    //     if (!response || response === undefined) {
    //         throw new Error ("Unable to get New Highway lesson.");
    //     }

    //     return response;        
    //  } catch (error: any) {

    //     if (error.message.includes('403')) throw new Error ('403');
    //     throw error;    
    //  }
    try {
        await callPOST(`v1/self/productlessonpacks/subscriptions/${subscriptionId}/lesson`, {}, true);
     } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;    
     }
};

export const setPersonalAssistant =async (subscriptionId: number | string, coachId: number | string) => {
     
    try {
        await callPUT(`v1/self/productlessonpack/subscriptions/${subscriptionId}/assign/${coachId}`, {}, true);
     } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;    
     }
};
