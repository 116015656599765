// component
import BaseFieldset from "components/ui/BaseFieldset";
import PersonalAssistantNotesModal from "pages/HighwayPage/PersonalAssistantNotesModal/PersonalAssistantNotesModal";
import { FC, useState } from "react";
import Lesson from "../LessonContent/Lesson.component";

// style
import { 
    ActionDesktop,
    CoachTextDesktop, 
    InfoText, 
    LessonsGrid,
    LessonsTable,
    RowLabel,
    TitleDesktop
} from "./style/NewLessonDesktop.style";


const NewLessonDesktop : FC <{
    lesson: any,
    choiceLessonHandler: (lessonId: number, typeOfLesson: 'new' | 'ended') => void, 
    openPersonalAsistantNotesModal: () => void,
    openCustomerRatingModal: () => void,
    typeOfLesson?: 'new' | 'ended'
}>= ({
    lesson,
    choiceLessonHandler,
    openPersonalAsistantNotesModal,
    openCustomerRatingModal,
    typeOfLesson
}) => {

    return (
        <BaseFieldset legend="LEZIONI ATTIVE">
            <LessonsGrid>
                <LessonsTable>
                    <RowLabel>
                        <InfoText>SCADENZA</InfoText>

                        <CoachTextDesktop>ASSISTENTE / COACH</CoachTextDesktop>

                        <TitleDesktop>TITOLO LEZIONE</TitleDesktop>

                        <ActionDesktop>AZIONI</ActionDesktop>

                    </RowLabel>

                    {(lesson !== undefined) && lesson.map((singleLesson: any, index: number) => {

                        if ((lesson.lesson !== undefined) && (lesson.lesson.coach === null)) return <></>;
                        
                        return (
                            <Lesson
                                key={index}
                                lesson={singleLesson}
                                choiceLessonHandler={choiceLessonHandler}
                                onCommentClick={openPersonalAsistantNotesModal}
                                onRatingClick={openCustomerRatingModal}
                                typeOfLesson={typeOfLesson}
                            />
                        );
                    })}

                </LessonsTable>
            </LessonsGrid>
        </BaseFieldset>
    );
};

export default NewLessonDesktop;
