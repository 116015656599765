import { FC } from 'react';

// routing
import { useLocation } from 'react-router-dom';

// types
// import { Footer } from './Footer.d.ts';

// ultils
import config from 'configs/config';

// assets
import { ReactComponent as LDLHorizontalLogoSVG } from './assets/LDL-extended-logo.svg';
import { ReactComponent as LDLShortIconSVG } from './assets/LDL-icon.svg';
import { ReactComponent as FacebookIconSVG } from './assets/facebook-icon.svg';
import { ReactComponent as TelegramIconSVG } from './assets/telegram-icon.svg';
import { ReactComponent as YouTubeIconSVG } from './assets/youtube-icon.svg';
import { ReactComponent as InstagramIconSVG } from './assets/instagram-icon.svg';
import { ReactComponent as TwitchIconSVG } from './assets/twitch-icon.svg';
import { ReactComponent as TikTokIconSVG } from './assets/tiktok-icon.svg';
import { ReactComponent as MBOrizontalIconSVG } from './assets/LOGO-MB.svg';
import { ReactComponent as VDRIconSVG } from './assets/VDR.svg';

// style
import {
    FooterBar,
    Content,
    BrandBox,
    InfoBox,
    PrivacyAndSocials,
    PrivacyBox,
    Socials,
    SocietyName,
    SocietyAddress,
    SeparatorLine
} from './style/Footer.style';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import store from 'redux/store';
import useAppSelector from 'hooks/useAppSelector';


/**
 * Footer
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const Footer: FC<{}> = () => {

    const location = useLocation();
    
    const { isAppMobile } = useAppSelector(state => state.ui);

    const isLandingPath = location.pathname.includes('/lp');
    const isPublicPath = location.pathname.includes('/public');

    const isHeaderHidden = (isLandingPath || isPublicPath);
    const { socialLinks, socialLinksMB, socialLinksVDR } = config;

    if (isHeaderHidden) {
        console.log("HEADER IS HIDDEN (FOOTER)");
        return (
            <></>
            // <FooterBar>
            //     <Content>
            //         <BrandBox>
            //             <LDLShortIconSVG />
                        
            //             <LDLHorizontalLogoSVG />

            //             <InfoBox>
            //                 <SocietyName>EASYTENERLIFE SL </SocietyName>
            //                 <SocietyAddress>Avda Moscu (DE), 6A - C.C. Aquamall 38679 Adeje - S.C. Tenerife NIF: B76784420</SocietyAddress>
            //             </InfoBox>
            //         </BrandBox>
            //     </Content>
            // </FooterBar>
        )
    };

    return (
        <FooterBar>
            <Content>
                <BrandBox>

                    {store.getState().ui.brand?.id === 1 && isAppMobile &&
                        <LDLShortIconSVG />
                    }
                                                
                    {store.getState().ui.brand?.id === 1 && !isAppMobile &&
                        <LDLHorizontalLogoSVG />
                    }
                    {store.getState().ui.brand?.id === 3 &&
                        <VDRIconSVG />     
                    }

                    {store.getState().ui.brand?.id === 4 &&
                        <MBOrizontalIconSVG />
                    }

                    <InfoBox>
                        <SocietyName>EASYTENERLIFE SL </SocietyName>
                        <SocietyAddress>Calle El Mojon 20 - puerta 26 38679 ADEJE S.C. Tenerife NIF: B76784420</SocietyAddress>
                    </InfoBox>
                </BrandBox>

                <PrivacyAndSocials>
                    <PrivacyBox>
                        <span>
                            <a 
                                href={`${getEnvironmentBaseUrl('/app/cookies')}`}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Cookie
                            </a>

                            <SeparatorLine>|</SeparatorLine>
                        </span>

                        <span>
                            <a 
                                href={`${getEnvironmentBaseUrl('/app/privacy')}`}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Privacy
                            </a>

                            <SeparatorLine>|</SeparatorLine>
                        </span>

                        <span>
                            <a 
                                href={`${getEnvironmentBaseUrl('/app/terms')}`}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                T&C
                            </a>
                        </span>
                    </PrivacyBox>

                    {(store.getState().ui.brand?.code === "LDL" || store.getState().ui.brand?.code === "TCR") &&
                        <Socials>
                            <a 
                                href={socialLinks?.facebook}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <FacebookIconSVG />
                            </a>

                            <a 
                                href={socialLinks?.telegram}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <TelegramIconSVG />
                            </a>

                            <a 
                                href={socialLinks?.youtube}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <YouTubeIconSVG />
                            </a>
                            
                            <a 
                                href={socialLinks?.instagram}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <InstagramIconSVG />
                            </a>

                            <a 
                                href={socialLinks?.twitch}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <TwitchIconSVG />
                            </a>
                            
                            <a 
                                href={socialLinks?.tikTok}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <TikTokIconSVG />
                            </a>
                        </Socials>
                    }

                    {store.getState().ui.brand?.code === "VDR" &&
                        <Socials>
                            <a 
                                href={socialLinksVDR?.facebook}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <FacebookIconSVG />
                            </a>

                            <a 
                                href={socialLinksVDR?.telegram}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <TelegramIconSVG />
                            </a>

                            <a 
                                href={socialLinksVDR?.youtube}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <YouTubeIconSVG />
                            </a>
                        </Socials>
                    }

                    {store.getState().ui.brand?.code === "MB" &&
                        <Socials>
                            <a 
                                href={socialLinksMB?.facebook}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <FacebookIconSVG />
                            </a>

                            <a 
                                href={socialLinksMB?.telegram}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <TelegramIconSVG />
                            </a>

                            <a 
                                href={socialLinksMB?.youtube}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <YouTubeIconSVG />
                            </a>
                        </Socials>
                    }
                </PrivacyAndSocials>
            </Content>
        </FooterBar>
    );
}

export default Footer;
