import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;

    width: 100%;
    max-width: 640px;
    height: calc(100vh - 100px);

    padding: 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
        padding: 35px 45px 50px;
    }
`;

export const IconBox = styled.div`

    margin: 10px 0 0;
    text-align: center;

    @media ${devices.tablet} {
        margin: 0;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const Title = styled.h2`
    
    width: 100%;
    margin: 20px 0 0;
    padding: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    white-space: nowrap;
    color: ${colors.red};

    text-align: center;
    text-transform: uppercase;
`;

export const TextContent = styled.p`
    
    margin: 30px 0 0;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    color: ${colors.white};

    text-align: center;

    @media ${devices.tablet} {
        margin: 40px 0 0;
    }
`;

export const NotesBox = styled.p`

    min-height: 200px;

    margin: 30px 0 0;
    padding: 25px;

    border: 1px solid ${colors.white023};
    border-radius: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    
    color: ${colors.white070};
`;

export const RedText = styled.span`
    color: ${colors.red};
`;

export const GoldText = styled.span`
    color: ${colors.primaryColor};
`;

export const StartButton = styled(Button)`

    margin: 50px 0 0;
    padding: 13px 42px;

    font-size: 15px;

    cursor: pointer;

    @media ${devices.tablet} {
        margin: 30px 0 0;
    }
`;

export const AdviceProduct = styled.img`
    
    max-width: 200px;
    margin: 20px 0 0;
`;
