import { callGET, callPOST } from "network/network";


export const updateFacebookDataOnDB = async () => {

    try {
        const response = await callGET('v1/users/self/updatefacebookdata', true);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getUserFacebookGroups = async () => {

    try {
        const response = await callGET('v1/users/self/getfacebookgroups', true);

        return response;
    } catch (error: any) {
        throw error;
    }
};

//endpoint create lesson after post facebook
export const createActiveHelpLesson = async () => {
    try {
        const response = await callPOST('v1/self/productsubscriptions/activehelp', {}, true);
        return response;
    } catch (error) {
        throw error;
    }
};

