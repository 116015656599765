import styled, { css } from 'styled-components';

export const SwitchSelectorWithLabel = styled.div<{width?: number, height?: number, isDisabled?: boolean, isDisabledOpacity?: number}>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${(props) => props.height && css`height: ${props.height+'px'};`};
    ${(props) => props.width && css`width: ${props.width+'px'};`};
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    ${(props) => props.isDisabled && 
        css`
            opacity: ${props.isDisabledOpacity ?? '0.75'};
            pointer-events: none;
        `
    }
`;

export const SwitchLabel = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-right: 5px;
    
`;

export const SwitchBox = styled.div<{isActive?: boolean, width?: number, height?: number, isDisabled?: boolean}>`

    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => props.height && css`height: ${props.height+'px'};`}
    ${(props) => props.width && css`width: ${props.width+'px'};`};
    
    cursor: pointer;

    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    ${(props) => props.isDisabled && 
        css`
            opacity: 0.75;
            pointer-events: none;
        `
    }
`;
