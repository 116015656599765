import useBrand from 'hooks/useBrand';
import HomePageLDL from 'pages/LDL/HomePageLDL';
import HomePageMB from 'pages/MB/HomePageMB';
import HomePageTCR from 'pages/TCR/HomePageTCR';
import HomePageVDR from 'pages/VDR/HomePageVDR';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { addFBuser } from './services/GeneralHome.services';

const GeneralHome : FC<{}> = () => {
    
    const brand = useBrand();
    const [searchParams] = useSearchParams();

    const getHomePageComponent = () => {
        if (!brand) return (<></>);
        const brandCode = brand.code;
        switch (brandCode) {
          case 'LDL':
            console.log('LDL Case Triggered');
            return <HomePageLDL />;
          case 'TCR':
            console.log('TCR Case Triggered');
            return <HomePageTCR />;
          case 'VDR':
            console.log("VDR Case Triggered");
            return <HomePageVDR />;
          case 'MB':
            console.log('MB Case Triggered');
            return <HomePageMB />;
          default:
            console.log('Default Case Triggered');
            return <HomePageLDL />;
        }
    };

    useEffect(() => {
      const fbsignup = localStorage.getItem('fromFBsignup');
      if (fbsignup === 'true') {
        const refSearchParam = searchParams.get("ref");
        addFBuser(refSearchParam);
      }
    }, [brand]);

    return (
        <>
            {brand && getHomePageComponent()}
        </>
    );
};

export default GeneralHome;