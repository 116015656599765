import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const PageCourse = styled.div`

    width: 100%;
    height: 100%;

    overflow: hidden;
`;

export const PageContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    padding: 20px;

    flex-direction: column;

`;

export const VideoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 219px;

    background: black;
`;

export const CourseInfoBox = styled.div`

    display: flex;
    flex-direction: column;
    align-self: flex-start;

    width: 100%;
     
    gap: 17px;

    a {
        text-decoration: none;
        color: inherit;
    }
`;

export const CourseTitle = styled.h4`

    margin: 0;
    padding: 0 5px;

    font-weight: 500;
    font-size: 16px;

    color: ${colors.white};
`;


export const InterectionBox = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: 100%;

    margin: 17px 0 0;
`;

export const InterectionButtonRow = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0 0 0 15px;
`;

export const DescriptionButton = styled.button<{isActive?: boolean}>`

    margin: 0;
    padding: 8px 20px;
    border: none;
    border-radius: 10px 0 0 0;

    background: ${colors.darkGrey};

    font-weight: 500;
    font-size: 13px;
    color: ${colors.white};

    ${({isActive}) => isActive && 
            css`
                background: ${colors.veryDarkGrey};
;
                `
        }
`;

export const LessonButton = styled.button<{isActive?: boolean}>`

    margin: 0;
    padding: 8px 20px;
    border: none;
    border-radius: 0 10px 0 0;

    background: ${colors.darkGrey};

    font-weight: 500;
    font-size: 13px;
    color: ${colors.white};

    ${({isActive}) => isActive && 
            css`
                background: ${colors.veryDarkGrey};
                `
        }
`;

export const  InterectionLessonBox = styled.div`

    display: flex;
    align-items: center;

    height: 30px;
    max-height: 30px;

    gap: 14px;
    
`;

// export const LessonSearch = styled.div``;

export const ExpandLesson = styled.div`

> svg path {
    fill: ${colors.white};      
}

`;
// export const ProgressBar = styled.div`

//     width: 100%;
//     height: 28px;

//     background: white;
// `;

export const FreePromoButton = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 13px;
    line-height: 17px;

    padding: 5px 20px;
    margin: 30px 0 0;

    background: ${colors.green};

    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};

    border-radius: 5px;
    border: none;

    width: 100%;
    text-transform: uppercase;

    cursor: pointer;
`;