import devices from 'style/breakpoints';
import colors from 'style/palette';

import styled, { css } from 'styled-components';
import { LessonIconBoxProps } from '../LessonContent.style.d'; 

export const LessonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 13px 0px 18px;
    gap: 15px;

    background: rgba(0, 0, 0, 0.002);
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);

    @media ${devices.tablet} { 
        flex-direction: row;
        padding: 10px 33px;
    }
`;

export const LessonInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet} { 
        justify-content: flex-start;
        //gap: 50px;
    }
`;

export const DateLesson = styled.p`
    font-weight: 500;
    font-size: 15px;

    width: 100%;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;

    color: ${colors.white};

    @media ${devices.tablet} { 

        align-items: flex-start;
        justify-content: flex-start;
        flex-basis: 18%;

        width: fit-content;
    }
`;

export const CoachLesson = styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 15px;

    margin: 0px;

    //margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: right;
    white-space: nowrap;

    color: ${colors.white};

    @media ${devices.tablet} { 
        align-items: flex-start;
        justify-content: flex-start;
        white-space: nowrap;
        flex-basis: 31%;
        text-align: left;

    }
`;

export const Lessons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 0px;
    gap: 18px;

    @media ${devices.tablet} { 
        align-items: flex-end;
        justify-content: flex-end;
    }
`;

export const LessonsDescr = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    overflow: hidden;

    color: ${colors.white};
`;

export const LessonsDescrDesktop = styled.p`
    font-weight: 600;
    font-size: 15px;

    width: 100%;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-basis: 20%;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    color: ${colors.white};
`;

export const LessonIcons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    padding: 0px;
    gap: 18px;
`;

export const IconButton = styled.button<LessonIconBoxProps>`
    background: transparent;
    border: none;
    box-shadow: none;
    padding:0px;

    cursor: pointer;
    
    > svg {
        cursor: pointer;
    }
    
    > svg path {
        
        fill: ${colors.primaryColor};
        fill-opacity: 1;

        cursor: pointer;
    }

    ${({isDisabled}) => isDisabled && (

        css`

            cursor: default;
            
            > svg path {
                
                fill: ${colors.lightGrey};
                fill-opacity: 1;
                cursor: default;
            };

            > svg {
                cursor: default;
            };
        `
    )}

    ${({isCompleted}) => isCompleted && (

        css`

            cursor: default;
            
            > svg path {
                
                fill: ${colors.green};
                fill-opacity: 1;
                cursor: default;
            };

            > svg {
                cursor: default;
            };
        `
    )}

    ${({isCompletedAndCliccable}) => isCompletedAndCliccable && (

        css`

            cursor: pointer;

            > svg {
                cursor: pointer;
            }

            > svg path {
                
                fill: ${colors.green};
                fill-opacity: 1;
            };
        `
    )}

    ${({isIconInvisible}) => isIconInvisible && (

        css`

            cursor: default;

            > svg {
                cursor: default;
            }

            > svg path {

                fill: ${colors.transparent};
                fill-opacity: 0;
                
                cursor: default;
            };
        `
    )}
`;
