import React, { FC, useState, useEffect, useRef } from 'react';
import { Brand } from 'types/Brand';
import useAppSelector from 'hooks/useAppSelector';
import { getBrandsList } from 'services/brand.services';

// Style import
import { MainLogo, LogoSelect, LogoOption, LogoContainer, DropdownContainer, DropdownItem } from './style/BrandSelectorMobile.style';

const BrandSelectorMobile: FC<{}> = () => {
    const [brandList, setBrandList] = useState<Brand[]>([]);
    const [urlParams, setUrlParams] = useState<URLSearchParams>();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const currentBrand = useAppSelector((state) => state.ui.brand) as Brand;
    const selectRef = useRef<HTMLSelectElement>(null);

    const domain = window.location.hostname;
    const topLevelDomain = domain.split('.').slice(-2).join('.');
    const brandSvgLinkTo = `https://www.${topLevelDomain}`;
    const path = window.location.pathname;
    const searchQuery = window.location.search;
    const completePathQuery = `${path}${searchQuery}`;

    useEffect(() => {
        const fetchBrands = async () => {
            try {
            const brands = await getBrandsList();
            setBrandList(brands);
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const searchParams = new URLSearchParams(url.search);
            searchParams.delete('brand');
            setUrlParams(searchParams);
            } catch (error) {

            }
        };
        try {
            fetchBrands();
        } catch (error) {
            
        }
    }, []);

    const handleSelectBrand = (brandId: number) => {
        const selectedBrand = brandList.find(brand => brand.id === brandId);
        if (!selectedBrand) return;

        const redirectUrl = (selectedBrand.id === currentBrand.id) ? domain.includes('localhost') ? '/app/?'+urlParams?.toString()+'&brand='+selectedBrand.code : window.location.href.split('app/')[0]+'?'+urlParams?.toString() : selectedBrand.id === currentBrand.id ? domain.includes('localhost') ? '' : brandSvgLinkTo : domain.includes('localhost') ? window.location.href.split('?')[0]+'?'+urlParams?.toString()+'&brand='+selectedBrand.code : 'https://'+selectedBrand.domain+completePathQuery;
        window.location.href = redirectUrl;
    };

    const handleLogoClick = () => {
        if (isDropdownVisible) {
            const selectedBrand = currentBrand;
            const redirectUrl = (selectedBrand.id === currentBrand.id) ? domain.includes('localhost') ? '/app/?'+urlParams?.toString()+'&brand='+selectedBrand.code : window.location.href.split('app/')[0]+'?'+urlParams?.toString() : selectedBrand.id === currentBrand.id ? domain.includes('localhost') ? '' : brandSvgLinkTo : domain.includes('localhost') ? window.location.href.split('?')[0]+'?'+urlParams?.toString()+'&brand='+selectedBrand.code : 'https://'+selectedBrand.domain+completePathQuery;
            window.location.href = redirectUrl;  
        }
        setIsDropdownVisible(!isDropdownVisible);
    };

    const renderLogo = (brand: Brand) => {
        if (!brand) return;
        return brand.logo ? (
            <img src={brand.logo.url ? brand.logo.url : ''} alt={brand.name} />
        ) : (
            <span>{brand.code}</span>
        );
    };

    if (!brandList || !currentBrand) {
        return <div>Loading...</div>;
    } else {

        const mainBrand = brandList.find(brand => brand.id === currentBrand.id) || brandList[0];

        return (
            <LogoContainer>
                <MainLogo onClick={handleLogoClick}>
                    {renderLogo(mainBrand)}
                </MainLogo>
                {isDropdownVisible && (
                <DropdownContainer>
                        {brandList.map((brand) => {
                            if (brand.id !== currentBrand.id) return (
                            <>
                                <DropdownItem key={brand.id} onClick={() => handleSelectBrand(brand.id)}>
                                    {renderLogo(brand)}
                                </DropdownItem>
                            </>)}
                        )}
                </DropdownContainer>
            )}
            </LogoContainer>
        );
    }
};

export default BrandSelectorMobile;
