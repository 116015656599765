import { FC, useEffect, useState } from 'react';

//hooks
import { useLocation, useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';

//utils
import { getEnvironmentAssetLinkUrl } from 'configs/config.helper';
import { appendBrandToUrl } from 'configs/brandMap/brandMap.helper';

// types
import { CardOfPowerProps } from './CardOfPower.d';

//assets
import { ReactComponent as CardOfPowerPlaceholder } from './assets/card-of-power-placeholder.svg';

//style
import {
	CardBox,
	CardImage,
	DarkOverlay,
	LoginButton,
} from './style/CardOfPower.style';
import { debug } from 'console';

const CardOfPower: FC<CardOfPowerProps> = ({
	card,
	cIndex,
	duration,
	showCard,
}) => {
	const imageUrl = card.assetName
		? getEnvironmentAssetLinkUrl(`/images/cards-of-power/${card.assetName}`)
		: '';

	const navigate = useNavigate();
	const location = useLocation();

	const { isAppMobile } = useAppSelector((state) => state.ui);
	const { tokenID } = useAppSelector((state) => state.user);
	const isUserLoggedIn = tokenID !== undefined ? !!tokenID : false;
	// const isUserLoggedIn = false;

	const onLoginClickHandler = () => {
		const path =
			'/login?redirectPath=' +
			encodeURIComponent(
				isAppMobile
					? appendBrandToUrl('/reservedArea/strumenti/carte-del-potere', location)
					: appendBrandToUrl('/reservedArea/strumenti', location)
			);
		navigate(path);
	};

	// const onLoginClickHandler = () => {
	// 	const path =
	// 		'/login?redirectPath=' +
	// 		encodeURIComponent(
	// 			isAppMobile
	// 				? '/public/strumenti/carte-del-potere'
	// 				: '/public/strumenti'
	// 		);
	// 	navigate(path);
	// };

	useEffect(() => {
		if (
			!isAppMobile &&
			location.pathname === '/public/strumenti/carte-del-potere'
		)
			navigate('/public/strumenti');
		if (
			!isAppMobile &&
			location.pathname === '/reservedArea/strumenti/carte-del-potere'
		)
			navigate('/reservedArea/strumenti');
	}, [isAppMobile, location]);

	return (
		<CardBox
			cIndex={cIndex}
			duration={duration}
		>
			{showCard ? (
				<CardImage
					src={imageUrl}
					alt={card.assetName}
					isBlurred={!isUserLoggedIn}
				/>
			) : (
				<CardOfPowerPlaceholder />
			)}
			{showCard && !isUserLoggedIn && (
				<LoginButton onClick={onLoginClickHandler}>
					Accedi per vedere la carta!
				</LoginButton>
			)}
			{cIndex !== undefined && cIndex !== 0 && (
				<DarkOverlay
					cIndex={cIndex}
					duration={duration}
				/>
			)}
		</CardBox>
	);
};

export default CardOfPower;
