import styled, { css } from 'styled-components';

// types
import { InfoBoxProps } from './HomePageMB.style.d'

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';
import { BaseCard } from 'style/wrappers';
import { HomePageLDLButton } from 'style/buttons/buttons';


export const Page = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 1440px;
    padding: 30px 20px 20px;
    margin: 0 auto;

    @media ${devices.tablet} {
        padding: 30px 40px 20px;
    }
`;

export const HeaderRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const Offert = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    padding: 20px 27px 0px;

`;

export const InfoRow = styled.div`

    width: 100%;
    margin-top: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    
    @media ${devices.tablet} {
        
        align-items: flex-start;
        
        gap: 30px;
        flex-direction: row-reverse;
    }
    
    @media screen and (max-width: 1300px) {

        align-items: center;
        
        gap: 30px;
        flex-direction: column;
    }
`;

export const GuidedFinancialJourneyRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    
    width: 100%;
    margin-top: 20px;

    @media ${devices.tablet} {

        flex-direction: column;
        margin-top: 20px;
    }

    @media ${devices.wideDesktop} {

        align-items: stretch;
        justify-content: space-between;

        flex-direction: row;
    }

    /* @media ${devices.tablet} {

        margin-top: 20px;

        align-items: stretch;
        justify-content: flex-start;

        flex-direction: row;
    } */
`;

export const WellcomeText = styled.h1`

    font-size: 22px;
    font-weight: 400;
    line-height: 22px;

    color: ${colors.white};

    margin: 0;
    padding: 0 0 0 10px;

    @media ${devices.tablet} {
        
        font-size: 28px;
        line-height: 28px;
    }
`;

export const OfferButton = styled(Button)`

    gap: 8px;
    height: 42px;

    text-transform: none;
    font-size: 14px;
    font-weight: 500;
`;

export const BadgeBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 10px 0 0;

    gap: 15px;

    @media ${devices.tablet} {
        padding: 0 15px 0 0;
    }
`;

export const MissionsBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 25px;

    margin: 0 25px 0 0;

    @media ${devices.tablet} {
        margin: 0 40px 0 0;
    }
`;

export const MissionsBadgeButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    > svg {
        
        height: 20px;
        width: 10px;

        path {
        
            fill: ${colors.green};
            fill-opacity: 1;

        }

        @media ${devices.tablet} {

            height: 29.17px;
            width: 14.58px;
        }

    }

    :disabled {
        > svg path {

            fill: ${colors.white};
            fill-opacity: 0.5;
        }
    }
`;

export const SingleMissionBadgeButton = styled.button<{isCompleted?: boolean | undefined}>`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    // until new disposition for users mission card
    cursor: default;
    /* cursor: pointer; */

    ${({isCompleted}) => isCompleted && (

        css`

            cursor: default;
            
            > svg path {

                fill: ${colors.green};
                fill-opacity: 1;
            }
        `
    )}
`;

export const WarningBadgeButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;
    cursor: pointer;

    > svg {
        
        height: 19px;
        width: 22px;

        path {
        
            fill: ${colors.red};
            fill-opacity: 1;

        }

        @media ${devices.tablet} {

            height: 27.71px;
            width: 32.08px;
        }

    }

    :disabled {
        
        cursor: default;

        > svg path {

            fill: ${colors.white};
            fill-opacity: 0.5;
        }
    }
`;

export const InfoBox = styled.div<InfoBoxProps>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
    margin-top: 20px;
    
    @media ${devices.tablet} {

        gap: 30px;
        
        width: 32.33%;
        margin-top: 0;

        justify-content: flex-start;

        ${({isRoadMapExpanded}) => isRoadMapExpanded && (

            css`display: none;`
        )}
    }

    @media screen and (max-width: 1300px) {
        
        justify-content: center;
        
        flex-direction: row;

        width: 100%;
    }

    @media screen and (max-width: 900px) {
        
        justify-content: center;

        flex-direction: column;

        width: 100%;
    }

`;

export const MissionsCard = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};
    border: 0.5px solid ${colors.primaryColor};

    width: 100%;
    height: 110px;
`;

export const GiftCard = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};
    border: 0.5px solid ${colors.primaryColor};

    width: 100%;
    height: 167px;
    
    margin-top: 20px;
`;

export const TenKMonthBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};
    border: 0.5px solid ${colors.primaryColor};

    width: 100%;
    height: 165px;
`;

export const JourneyText = styled.h3`

        margin: 20px 0 0;
        padding: 0;

        font-size: 24px;
        line-height: 24px;
        font-weight: 400;

        color: ${colors.white};

    @media ${devices.tablet} {
        margin: 30px 0 0;
        padding: 0 0 0 10px;
    }
`;

export const JourneyBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 433.33px;

    margin: 0 auto;

    @media ${devices.tablet} {
        max-width: none;
    }
`;

export const CenterCards = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap:  20px;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;
        width: unset;
    }
`;

export const InteractionRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    width: 100%;

    margin: 20px 0 0;
    padding: 25px 0 0;

    border-top: 1px solid ${colors.veryDarkGrey};
`;

export const BroadcastBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 100%;

    @media ${devices.tablet} {
        flex-direction: row;

        width: fit-content;
    }
`;

export const ThematicBroadCastCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    width: 100%;
    height: 210px;

    padding: 25px 15px 10px 15px;
    gap: 16px;
    border-radius: 15px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    
    position: relative;

    > svg {
        flex-shrink: 0;
        position: absolute;

        top: 15px;
        right: 15px;

        cursor: pointer;
    }

    @media ${devices.tablet} {
        width: 325px;

    }
`;

export const FirstThematicBroadCastCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    width: 100%;
    max-width: 430px;
    min-height: 145px;
    height: auto;


    padding: 25px 15px 10px 15px;
    gap: 16px;
    border-radius: 15px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    
    position: relative;

    > svg {
        flex-shrink: 0;
        position: absolute;

        top: 15px;
        right: 15px;

        cursor: pointer;
    }

    @media ${devices.tablet} {
        height: fit-content;
        padding: 15px 15px 0px 15px;

    }
`;

export const WhatsAppCoomingSoonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    width: 100%;
    max-width: 430px;
    min-height: 145px;
    height: auto;


    padding: 25px 15px 10px 15px;
    gap: 16px;
    border-radius: 15px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    
    position: relative;

    > svg {
        flex-shrink: 0;
        position: absolute;

        top: 15px;
        right: 15px;

        cursor: pointer;
    }

    @media ${devices.tablet} {
        height: fit-content;
        padding: 1px 1px 0px 1px;

    }
`;

export const StackingBox = styled(BaseCard)`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 350px;

    padding: 20px;

    background: ${colors.veryDarkGrey};
`;

export const BalancePointBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 12px;
`;

export const BalanceText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 599;
    color: ${colors.white};
`;

export const BalancePoint = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ActionsRow = styled.div<{ isDisabled: boolean }>`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    ${({isDisabled}) => isDisabled && (

        css`
            > svg path {
                fill: ${colors.white050};
            }
        `
    )}
`;

export const StakeButton = styled(Button)`

    width: 130px;

    background: ${colors.green};
    color: ${colors.white};

    > svg {
        margin: 0 7px 0 0;
    }

    :disabled {

        background: #424242;
        color: ${colors.white050};

        > svg path {
            fill: ${colors.white050};
        }
    }
`;

export const UnstakeButton = styled(Button)`

    width: 130px;

    padding-left: 0;
    padding-right: 0;

    background: ${colors.red};
    color: ${colors.white};

    > svg {
        margin: 0 0 0 7px;
    }

    :disabled {

        background: #424242;
        color: ${colors.white050};

        > svg path {
            fill: ${colors.white050};
        }
    }
`;

export const StakingPointBox = styled(BalancePointBox)``;

export const StakingText = styled(BalanceText)``;

export const StakingPoint = styled(BalancePoint)``;

export const BalanceBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const Balance = styled.p`

    margin: 0;
    padding: 0;

    font-size: 30px;
    font-weight: 500;
    color: #246EC6;
`;

export const BalancePointText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    color: #246EC6;
`;

export const StakingBox = styled(BalanceBox)``;

export const StakingBalance = styled(Balance)``;

export const StakingPointText = styled(BalancePointText)``;

export const VipPointCard = styled.div<{isActive: boolean}>`

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;

    padding: 15px 15px 20px;

    background: ${colors.darkGrey};
    border-radius: 15px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};

    cursor: pointer;
    
    position: relative;
    overflow: hidden;
    @media ${devices.tablet} {
        
        flex-shrink: 0;
        
        width: 325px;
        max-height: 210px;
    }

    ${({isActive}) => isActive && (

        css`

            background: ${colors.veryDarkGrey};
            border: 1px solid ${colors.primaryColor};
        `
    )}
`;

export const VipCardContent = styled.div`

    display: flex;
    align-items: space-between;
    justify-content: center;

    flex-direction: column;
`;

export const AssetsRow = styled.div``;

export const VipSVG = styled.div``;

export const InfoSVG = styled.div``;

export const PointBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const PointText = styled.p`

    margin: 8px 0 0;
    padding: 0;
    
    font-size: 13px;
    font-weight: 500;
    color: ${colors.white};
`;

export const VipPointBalance = styled.h3`

    margin: 0;
    padding: 13px 0 0;
    
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    color: ${colors.primaryColor};
`;

export const VipPointsLabel = styled.p`


    margin: 0;
    padding: 5px 0 0;
    
    font-size: 13px;
    font-weight: 600;
    color: ${colors.primaryColor};
`;

export const VipButton = styled(HomePageLDLButton)``;

export const InfoButton = styled.button`

    margin: 0;
    padding: 0;

    border: none;
    background: none;

    cursor: pointer;
`;

export const BoostBand = styled.div`

    width: 180px;
    height: 26px;

    background: linear-gradient(180deg, #B92D2D 0%, #510101 100%);
    transform: rotate(45deg);

    font-size: 11px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.white};
    white-space: nowrap;

    position: absolute;

    top: 127px;
    left: -62px;

    @media ${devices.tablet} {

        top: 75%;
        left: -20%;
    }
`;

export const StandingTitle = styled.h3`

    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    color: ${colors.white};
    text-align: center;

    span {

        color: ${colors.primaryColor};
        text-transform: uppercase;
        line-height: 50px;
    }

    a{

        font-style: italic;
        font-weight: 400;
        text-decoration: underline;
        color: ${colors.primaryColor};
        cursor: pointer;
    }
`;

export const StandingCard = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 350px;
    margin: 25px 0 0;
    padding: 20px 20px;
    
    background: ${colors.veryDarkGrey};
    border: 0.5px solid ${colors.primaryColor};
    border-radius: 15px;
    
    @media ${devices.tablet} {
        
        width: unset;
        min-width: 384px;
        padding: 20px 45px;
    }
`;

export const StandingRow = styled.div<{isOver?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    margin: 10px 0 0;
    > svg {
        margin: 0 12px 0 0;
    }

    ${({isOver}) => isOver && (

        css`
            margin: 15px 0 0;
        `
    )}
`;

export const PositionText = styled.p<{isOver?: boolean}>`

    margin: 0;
    padding: 0;
    width: 85px;

    font-size: 16px;
    font-weight: 600;
    color: ${colors.white};
    white-space: nowrap;
    text-align: left;

    ${({isOver}) => isOver && (

        css`
            
            margin: 0 0 0 35px;
            font-weight: 500;
            font-size: 15px;
        `
    )}
`;

export const PointsText = styled.p<{isOver?: boolean}>`

    margin: 0;
    padding: 0;
    width: 70px;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.green};
    white-space: nowrap;
    font-style: italic;
    text-align: left;

    ${({isOver}) => isOver && (

        css`
            font-size: 18px;
        `
    )}
`;

export const NameText = styled.p<{isOver?: boolean}>`

    margin: 0;
    padding: 0;

    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.green};
    white-space: nowrap;
    text-align: left;
    text-transform: capitalize;

    ${({isOver}) => isOver && (

        css`
            font-size: 16px;
        `
    )}
`;

export const CryptoTitle = styled.h3`


    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    color: ${colors.white};
    text-align: center;

    span {

        color: ${colors.primaryColor};
        text-transform: uppercase;
    }
`;

export const CryptoButton = styled(Button)`

    margin: 25px 0 0;
`;

