import { FC, useEffect, useState } from 'react';

//components
import { MENU_FILTER } from '../..';
import FilterShopButtonMobile from '../../FilterShopButton/layout/FilterShopButtonMobile/FilterShopButtonMobile.component';

//style
import { FilterShopBox } from './style/FilterShopMobile.style';


const FilterShopMobile: FC<{
    changeFilterOpenActive: (text: string) => void
}> = ({
    changeFilterOpenActive,
}) => {

    const [visibleButtonText, setVisibleButtonText] = useState<string>("Senza Rischio");
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedMenuPosition, setSelectedMenuiPosition] = useState<number>(0);

    const filterOpenClickHandler = () => {

        setIsMenuOpen(prevState => !prevState);
    };

    const filterHandler = (newFilterButtonText: string, position: number) => {

        changeFilterOpenActive(MENU_FILTER[position].text);
        setVisibleButtonText(newFilterButtonText);
        setSelectedMenuiPosition(position);
        setIsMenuOpen(false);
    };      

    const menuIcon = () => {

        if (visibleButtonText === 'Senza Rischio') return MENU_FILTER[0].icon;
        if (MENU_FILTER[selectedMenuPosition].text === visibleButtonText) return MENU_FILTER[selectedMenuPosition].icon;
        return MENU_FILTER[selectedMenuPosition].icon;
    };

    // useEffect(() => {
    //     changeFilterOpenActive('Senza Rischio');
    // }, [changeFilterOpenActive]);
    
    return (
        <FilterShopBox>
            <>
                <FilterShopButtonMobile 
                    icon={menuIcon()}
                    text={visibleButtonText}
                    onButtonClick={filterOpenClickHandler}
                    position={selectedMenuPosition}
                />

                {isMenuOpen && (
                    MENU_FILTER.map((element: any, index: number) => {
                        
                        // if (index === 0) return <></>;
                        if (index === selectedMenuPosition) return <></>;
                        return (
                            <FilterShopButtonMobile 
                                key={index}
                                position={index}
                                icon={element.icon}
                                text={element.text}
                                onButtonClick={filterHandler}
                                showMenuIcon={false}
                            />
                        )
                    })
                )}
            </>
        </FilterShopBox>
    );
};

export default FilterShopMobile;