import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const Chapters = styled.div<{isActive?: boolean}>`

display: flex;
justify-content: space-between;
align-items: center;

width: 100%;
border: none;

margin: 0 0 1px;
padding: 17px 16px;

font-weight: 500;
font-size: 14px;
letter-spacing: 0.1px;

color: ${colors.white050};

text-transform: uppercase;

background: ${colors.veryDarkGrey};

cursor: pointer;

svg {
    margin: 0 12px 0 0;
    flex-shrink: 0;
}

${({isActive}) => isActive && 
    css`
        background: ${colors.primaryColor};

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: ${colors.black};
    `
}
`;