export const getYoutubeVideoId = (videoUrl: string) => {

    const isYoutubeVideo = videoUrl.includes('www.youtube.com');

    if (!isYoutubeVideo) return undefined;

    const videoSearchParams = videoUrl.split('?')[1];
    const videoSingleSearchParams = videoSearchParams.split('&');
    const videoSearchParamsId = videoSingleSearchParams.filter((searchParams: string) => searchParams.includes('v='))[0];
    const videoId = videoSearchParamsId.split('=')[1];
    
    return videoId;
};

export const getImageFromYoutubeUrl = (videoUrl: string) => {

    const youtubeVideoId = getYoutubeVideoId(videoUrl);

    if (youtubeVideoId === undefined) return undefined;

    const youtubeVideoCover = `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`

    return youtubeVideoCover;
};
