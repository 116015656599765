import { FC, useEffect, useState } from 'react';

//service
import { createActiveHelpLesson } from '../ShareNeedCallModal/services/ShareNeedCallModal.services';

// style
import {
    WhatsAppRequireCallBox,
    WhatsAppRequireCallBoxContent,
    WhatsAppRequireCallTextContent,
    WhatsAppRequireCallText,
    WhatsAppRequireCallButton
} from './style/WhatsAppRequireCallCard.style';


const WhatsAppRequireCallCard: FC<{
    activeLesson:() => void,
    openModalHandler:() => void
}> = ({
    activeLesson,
    openModalHandler
}) => {

    // const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
    const [hasUserShared, setHasUserShared] = useState<boolean>(false);
    const [shareImg, setShareImg] = useState<any>({});

    // const closeModalHandler = () => setIsShareModalOpen(false);
    const userHasSharedHandler = () => setHasUserShared(true);

    const createWhatsAppLesson = async () => {
        try {
            await createActiveHelpLesson();
            console.log("Lezione attiva di Aiuto creata con successo");
        } catch (error) {
            console.error("Errore durante la creazione della lezione attiva di Aiuto: ", error);
        }
    };

    const shareRequireCallHandler = () => {

        createWhatsAppLesson();

        openModalHandler();
	};

    return (
            <WhatsAppRequireCallBox>
                <WhatsAppRequireCallBoxContent>
                        <WhatsAppRequireCallTextContent>
                            <WhatsAppRequireCallText>
                                Numero inserito correttamente. Puoi procedere con la richiesta di una call in REGALO (!)
                            </WhatsAppRequireCallText>
                        </WhatsAppRequireCallTextContent>

                    {/* <WhatsAppRequireCallButton onClick={shareRequireCallHandler}>RICHIEDI LA CALL</WhatsAppRequireCallButton> */}
                    <WhatsAppRequireCallButton disabled>SOLD OUT</WhatsAppRequireCallButton>
                </WhatsAppRequireCallBoxContent>
            </WhatsAppRequireCallBox>
    )
};

export default WhatsAppRequireCallCard;