import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Page = styled.div`

    min-height: inherit;
    height: 100%;

    padding: 40px 25px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    > svg:first-child {

        flex-shrink: 0;
        margin-bottom: 20px;
    }

    @media ${devices.tablet} {

        padding: 40px 40px 20px;
    }
`;

export const Content = styled.div`

    padding: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    > svg {
        
        margin-top: 24px;
    }

    @media ${devices.tablet} {

        padding: 30px 40px;
    }
`;

export const CardText = styled.p`

    padding: 0 5px;

    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;

    color: ${colors.white};

    @media ${devices.tablet} {

        padding: 0 30px;
    }
`;

export const GiftImage = styled.img`

    min-height: 120px;
`;
