import { FC, useEffect, useState, useMemo, Fragment, useRef } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { getItemFromLocalStorage } from 'utils/localStorage';

//redux
import { setSites, setEvents, setMultiples, setRollovers, setSingles, setFilters } from 'redux/actions/actions-oddsscasser';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//componenets
import LoadingMask from 'components/ui/LoadingMask';
import Dropdown from 'components/Dropdown';
import CheckboxesGroup from 'components/CheckboxesGroup';
import TextInputWithLabel from 'components/ui/TextInputWithLabel';
import NumberInput from 'components/ui/NumberInput';
import DateInput from 'components/ui/DateInput';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import Upload from 'components/Upload';
import ClickableResource from 'components/ClickableResource';
import Timer from 'components/Timer/Timer.component';
import SingleSelector from './SingleSelector';
import MultipleSelector from './MultipleSelector';
import RolloverSelector from './RolloverSelector';

//assets
import { ReactComponent as ChevronLeftIcon } from 'pages/OddsScasserPage/assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'pages/OddsScasserPage/assets/chevronRight.svg';
import { ReactComponent as PlusIconSVG } from 'pages/OddsScasserPage/assets/plus.svg';
import { ReactComponent as MinusIconSVG } from 'pages/OddsScasserPage/assets/minus.svg';
import { ReactComponent as EditIconSVG } from 'pages/OddsScasserPage/assets/edit.svg';
import { ReactComponent as DuplicateIconSVG } from 'pages/OddsScasserPage/assets/duplicate.svg';
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';
import { ReactComponent as CheckboxOnSVG } from 'pages/OddsScasserPage/assets/checkboxOn.svg';
import { ReactComponent as CheckboxOffSVG } from 'pages/OddsScasserPage/assets/checkboxOff.svg';

//types
import { MultipleObj } from '../OddsScasser/Multiple/types/MultipleObj';
import { RolloverObj } from '../OddsScasser/Rollover/types/RolloverObj';
import { SingleObj } from '../OddsScasser/types/SingleObj';
import { FiltersObj } from '../OddsScasser/types/FiltersObj';
import { BonusPageProps } from './BonusPage.component.d';
import { Bonus, BonusEditing, BonusStep, BonusStepEditing, BonusesResponse, BonusType, bonusTypes, BonusStatus, bonusStatuses, QualifyingMode, qualifyingModes, BonusMode, bonusModes, HoursFromType, hoursFromTypes, betTypes, BetType, UserBonus} from './types/BonusPage.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { DateInputState } from 'components/ui/DateInput/types/DateInputState';
import { LdlFile } from 'pages/OddsScasserPage/types/LdlFile';
import { Odds } from '../types/Odds';

// utils
import { _getSites, _getEvents, _getBonuses, _saveBonus, _uploadImage, _addVideo, _getMultiples, _getRollovers, _getSingles, _getFilters, _getOdds, _getBonusesInProgress, _getBonusesFinished, _startBonus, _saveSingle, _saveMultiple, _saveRollover, _saveUserBonus } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//style
import { FooterNavigationButton, FooterPageButton, FooterPagesButtonsContainer, NoResults, TBody, TH, THead, TR, Table, Section, TableFooter, CellContainer, TD, SingleDivCell, AddButton, StartButton, BonusStepsTitle, BonusStepBox, BonusStepDescriptionEdit, BonusStepDescription, BonusStepTypeLabel, NoWrapLabel, BoxedLabel, ExpirationDateLabel, BonusStepDescriptionPar, SectionTitle } from './style/BonusPage.style';
import { DropdownContent, SVGIconContainer, Label, Select, StandardCheckbox, ToolButton, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, ExpirationLabel, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Col, Row } from 'style/layout';

//helper
import { getEnvironmentProductId } from 'configs/config.helper';
import { formatDate, getDate, localeStringToUTCString, stepEach, stepIntegers, timestampToUTCString } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import { BestOddsFilters } from '../OddsScasser/BestOddsFilter/types/BestOddsFilters';

const BonusPage: FC<BonusPageProps> = ({ mode }) => {
    
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events, multiples, rollovers, singles, filters } = useAppSelector(state => state.oddsScasser);
    const { roles } = useAppSelector(state => state.user);
    
    const isAdmin: boolean = useMemo(() => roles ? roles.map((role: any) => role.id).includes(31) : false, [roles]);
    const productId: number = useMemo(() => getEnvironmentProductId(mode), [mode]);

    const dispatch = useAppDispatch();

    const [sitesList, setSitesList] = useState<Site[]>([]);
    const [bonuses, setBonuses] = useState<BonusesResponse|undefined>(undefined);
    const [bonusesInProgress, setBonusesInProgress] = useState<Bonus[]|undefined>(undefined);
    const [bonusesFinished, setBonusesFinished] = useState<BonusesResponse|undefined>(undefined);
    const [openedBonusId, setOpenedBonusId] = useState<number|null>(-1);
    const [bonusEditing, setBonusEditing] = useState<BonusEditing|undefined>(undefined);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [stepsOpened, setStepsOpened] = useState<boolean[]>([]);
    const [eventsOdds, setEventsOdds] = useState<Map<number, Map<string, Odds[]>>>(new Map<number, Map<string, Odds[]>>());
    const [stepsResults, setStepsResults] = useState<Map<number, number|null>>(new Map<number, number|null>());

    useEffect(() => {
        const getSites = async () => {
            try {
                dispatch(setSites(await _getSites()));
            } catch (error: any) {
                console.warn(error);
            }
        };
        const getEvents = async () => {
            try {
                dispatch(setEvents(await _getEvents()));
            } catch (error: any) {
                console.warn(error);
            }
        };
        const getMultiples = async () => {
            try {
                const _multiples = await _getMultiples(false) as MultipleObj[];
                dispatch(setMultiples(_multiples));
            } catch (error: any) {
                console.warn(error);
            }
        };
        const getRollovers = async () => {
            try {
                const _rollovers = await _getRollovers(false) as RolloverObj[];
                dispatch(setRollovers(_rollovers));
            } catch (error: any) {
                console.warn(error);
            }
        };
        const getSingles = async () => {
            try {
                const _singles = await _getSingles() as SingleObj[];
                dispatch(setSingles(_singles));
            } catch (error: any) {
                console.warn(error);
            }
        };
        const getFilters = async () => {
            try {
                const _filters = await _getFilters() as FiltersObj[];
                dispatch(setFilters(_filters));
            } catch (error: any) {
                console.warn(error);
            }
        };
        if(sites.size === 0) getSites();
        if(events.size === 0) getEvents();
        if(!multiples && isAdmin) getMultiples();
        if(!rollovers && isAdmin) getRollovers();
        if(!singles && isAdmin) getSingles();
        if(!filters && isAdmin) getFilters();
    }, []);

    //Set sitesList when sites changes
    useEffect(() => {
        if(sites.size>0) setSitesList(Array.from(sites.values()));
    }, [sites])

    const waitSnackbar = () => {
        dispatch(addSnackbar({ type: 'error', message: 'Stai modificando un bonus. Salva o annulla le modifiche per continuare.'}));
    }

    //Get bonuses
    const getBonuses = async (page: number, bonusToIncludeId?: number) => {
        if(editingIndex !== -1) return waitSnackbar();
        try {
            setBonuses(await _getBonuses(productId, page, bonusToIncludeId));
        } catch (error: any) {
            console.warn(error);
        }
    };
    const getBonusesInProgress = async () => {
        if(editingIndex !== -1) return waitSnackbar();
        try {
            setBonusesInProgress(await _getBonusesInProgress(productId));
        } catch (error: any) {
            console.warn(error);
        }
    };
    const getBonusesFinished = async (page: number, bonusToIncludeId?: number) => {
        if(editingIndex !== -1) return waitSnackbar();
        try {
            setBonusesFinished(await _getBonusesFinished(productId, page, bonusToIncludeId));
        } catch (error: any) {
            console.warn(error);
        }
    };
    useEffect(() => {
        getBonuses(0);
        getBonusesInProgress();
        getBonusesFinished(0);
    }, [productId])

    const addNewBonus = () => {
        if(!bonuses) return;
        if(editingIndex !== -1) return waitSnackbar();
        setBonuses(prevState => {
            if(!prevState) return prevState;
            setEditingData({id: null, productId, siteId: -1, status: 'CREATED', steps: []});
            setEditingIndex(0);
            setOpenedBonusId(null);
            return {...prevState, empty: false, content: [{id: null, productId, siteId: -1, status: 'CREATED', steps: []}, ...prevState.content]};
        });
    }
        
    const handleDuplicateClick = (index: number) => {
        if(!bonuses) return;
        if(editingIndex !== -1) return waitSnackbar();
        setBonuses(prevState => {
            if(!prevState) return prevState;
            const bonusCopy: Bonus = {
                ...bonuses.content[index],
                id: null,
                status: 'CREATED',
                creationDate: undefined,
                expirationDate: undefined,
                createdBy: undefined,
                steps: bonuses.content[index].steps.map(x => ({...x, id: null, expirationDate: undefined}))
            };
            setEditingData(bonusCopy);
            setEditingIndex(0);
            setOpenedBonusId(null);
            return {...prevState, content: [bonusCopy, ...prevState.content]};
        });
    }

    const setEditingData = (bonus: Bonus) => {
        let d = new Date(bonus.expirationDate ?? '');
        const expirationDate = (bonus.expirationDate ?? '').length>0
            ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
            : {day: '', month: '', year: '', hour: '', minute: ''};
        setBonusEditing({
            siteId: bonus.siteId,
            title: {value: bonus.title ?? ''},
            type: bonus.type ?? bonusTypes[0],
            status: bonus.status ?? bonusStatuses[0],
            expectedProfit: {value: bonus.expectedProfit ? bonus.expectedProfit.toString() : ''},
            qualifyingMode: bonus.qualifyingMode ?? qualifyingModes[0],
            qualifyingLive: bonus.qualifyingLive ?? false,
            bonusMode: bonus.bonusMode ?? bonusModes[0],
            bonusLive: bonus.bonusLive ?? false,
            expirationDate: expirationDate,
            steps: (bonus.steps ?? []).map((x: BonusStep) => {
                let d = new Date(x.expirationDate ?? '');
                const expirationDate = (x.expirationDate ?? '').length>0
                    ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
                    : {day: '', month: '', year: '', hour: '', minute: ''};
                d = new Date(x.unlockingDate ?? '');
                const unlockingDate = (x.unlockingDate ?? '').length>0
                    ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
                    : {day: '', month: '', year: '', hour: '', minute: ''};
                const betType: BetType = x.multiple ? 'Multipla' : (x.rollover ? 'Rollover' : 'Singola');
                return({
                    id: x.id,
                    expirationDate: expirationDate,
                    expirationHours: {value: x.expirationHours ? x.expirationHours.toString() : ''},
                    expirationHoursFrom: x.expirationHoursFrom ?? hoursFromTypes[0],
                    unlockingDate: unlockingDate,
                    unlockingHours: {value: x.unlockingHours ? x.unlockingHours.toString() : ''},
                    unlockingHoursFrom: x.unlockingHoursFrom ?? hoursFromTypes[0],
                    type: x.type ?? '',
                    description: x.description ?? '',
                    files: x.files ? x.files.map(y => ({...y})) : [],
                    betType,
                    betId: betType==='Multipla' ? ((x.multiple?.id || undefined)?.toString() || undefined) : (betType==='Rollover' ? ((x.rollover?.id || undefined)?.toString() || undefined) : ((x.single?.id || undefined)?.toString() || undefined)),
                    filtersId: (x.filters?.id || undefined)?.toString() || undefined,
                    pos: x.pos
                });
            })
        });
    }

    const handleSiteChange = (siteIndex: number) => {
        setBonusEditing(prevState => prevState ? {...prevState, siteId: siteIndex} : prevState);
    }
    const handleTitleChange = (newState: TextInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, title: newState} : prevState);
    }
    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBonusEditing(prevState => prevState ? {...prevState, type: event.target.value as BonusType} : prevState);
    }
    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBonusEditing(prevState => prevState ? {...prevState, status: event.target.value as BonusStatus} : prevState);
    }
    const handleExpectedProfitChange = (newState: NumberInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, expectedProfit: newState} : prevState);
    }
    const handleExpirationDateChange = (newState: DateInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, expirationDate: newState} : prevState);
    }
    const handleQualifyingTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBonusEditing(prevState => prevState ? {...prevState, qualifyingMode: event.target.value as QualifyingMode} : prevState);
    }
    const handleQualifyingLiveChange = () => {
        setBonusEditing(prevState => prevState ? {...prevState, qualifyingLive: !prevState.qualifyingLive} : prevState);
    }
    const handleBonusTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBonusEditing(prevState => prevState ? {...prevState, bonusMode: event.target.value as BonusMode} : prevState);
    }
    const handleBonusLiveChange = () => {
        setBonusEditing(prevState => prevState ? {...prevState, bonusLive: !prevState.bonusLive} : prevState);
    }

    const handleDeleteStepClick = (index: number) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.filter((x,i) => i!==index)} : prevState);
    }
    const handleBonusStepTypeChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, type: newValue} : x)} : prevState);
    }
    const handleBonusStepExpirationDateChange = (index: number, newState: DateInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, expirationDate: newState} : x)} : prevState);
    }
    const handleBonusStepExpirationHoursChange = (index: number, newState: NumberInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, expirationHours: newState} : x)} : prevState);
    }
    const handleBonusStepExpirationHoursFromChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, expirationHoursFrom: newValue as HoursFromType} : x)} : prevState);
    }
    const handleBonusStepUnlockingDateChange = (index: number, newState: DateInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, unlockingDate: newState} : x)} : prevState);
    }
    const handleBonusStepUnlockingHoursChange = (index: number, newState: NumberInputState) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, unlockingHours: newState} : x)} : prevState);
    }
    const handleBonusStepUnlockingHoursFromChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, unlockingHoursFrom: newValue as HoursFromType} : x)} : prevState);
    }
    const handleBonusStepDescriptionChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, description: newValue} : x)} : prevState);
    }
    const handleDeleteFileClick = (index: number, fileIndex: number) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, files: x.files.filter((y,j) => j!==fileIndex)} : x)} : prevState);
    }
    const upload = async (index: number, fileType: string, file?: File, url?: string) => {
        const newFile: LdlFile|undefined = fileType==='Video' ? await _addVideo(productId, url) : await _uploadImage(productId, file, url);
        if(!newFile) return dispatch(addSnackbar({ type: 'error', message: "Errore nell'aggiunta del file"}));
        dispatch(addSnackbar({ type: 'success', message: "File aggiunto"}));
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, files: [...x.files, newFile]} : x)} : prevState);
    }
    const handleBetTypeChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, betType: newValue as BetType, betId: undefined} : x)} : prevState);
    }
    const handleBetChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, betId: newValue} : x)} : prevState);
    }
    const handleFiltersChange = (index: number, newValue: string) => {
        setBonusEditing(prevState => prevState ? {...prevState, steps: prevState.steps.map((x,i) => i===index ? {...x, filtersId: newValue} : x)} : prevState);
    }

    const addNewBonusStep = () => {
        if(!bonusEditing) return;
        setBonusEditing(prevState => prevState ? {...prevState, steps: [...prevState.steps, {
            id: null,
            expirationDate: {day: '', month: '', year: '', hour: '', minute: ''},
            expirationHours: {value: ''},
            expirationHoursFrom: hoursFromTypes[0],
            unlockingDate: {day: '', month: '', year: '', hour: '', minute: ''},
            unlockingHours: {value: ''},
            unlockingHoursFrom: hoursFromTypes[0],
            type: '',
            description: '', 
            files: [],
            betType: 'Singola',
            pos: prevState.steps.length
        }]} : prevState);
    }

    const handleEditClick = (index: number) => {
        if(!bonuses) return;
        if(editingIndex !== -1) return waitSnackbar();
        setOpenedBonusId(bonuses.content[index].id);
        setEditingIndex(index);
        setEditingData(bonuses.content[index]);
    }
    
    const handleOpenClick = (id: number|null) => {
        if(editingIndex !== -1) return waitSnackbar();
        setOpenedBonusId(openedBonusId!==id ? id : -1);
        if(!bonusesInProgress) return;
        const bonus = bonusesInProgress.find(x => x.id===id) ?? (bonusesFinished?.content ?? []).find(x => x.id===id);
        if(!bonus) return;
        const reachedStepIndex: number = bonus.steps.findIndex(s => s.id===bonus.userBonus?.reachedStepId);
        setStepsOpened(bonus.steps.map((x,i) => reachedStepIndex>-1 && i>=reachedStepIndex));
    }

    const getOdds = async (payload: BestOddsFilters) => {
        try {
            const odds = await _getOdds(payload);
            const oddsMap = new Map<string, Odds[]>();
            odds.forEach((element: Odds) => {
                if(element.type) {
                    if(!oddsMap.has(element.type)) oddsMap.set(element.type, []);
                    oddsMap.get(element.type)?.push(element);
                }
            })
            return oddsMap;
        } catch (error: any) {
            console.warn(error);
        }
    };

    useEffect(() => {
        if(!bonuses || openedBonusId===-1) return;
        // const eventsIds: number[] = [];
        // const selections: string[] = [];
        // bonuses.content[openedBonusIndex].steps.forEach(s => {
        //     if(s.single && s.single.data.oddsMatch.event.datetime && getDate(s.single.data.oddsMatch.event.datetime) < new Date()) {
        //         eventsIds.push(s.single.data.oddsMatch.event.id);
        //         selections.push(s.single.data.oddsMatch.odds.map(x => x.type).filter(x => !!x).join(','));
        //     }
        //     // if(s.multiple) {
        //     //     s.multiple.
        //     //     eventsIds.push(s.single.data.oddsMatch.event.id);
        //     //     selections.push(s.single.data.oddsMatch.odds.map(x => x.type).filter(x => !!x).join(','));
        //     // }
        // });
        // setEventsOdds((prevState: Map<number, Map<string, Odds[]>>) => {
        //     const newState: Map<number, Map<string, Odds[]>> = new Map(prevState);
        //     eventsIds.forEach((eventId, index) => {
        //         if(!newState.has(eventId)) newState.set(eventId, new Map<string, Odds[]>);
        //         getOdds({eventId, selections: selections[index], feesBetfair: '0', feesBetflag: '0'}).then((oddsMap) => {
        //             if(!oddsMap) return;
        //             oddsMap.forEach((odds: Odds[], selection: string) => newState.get(eventId)?.set(selection, odds));
        //         });
        //     })
        //     return newState;
        // });
        
    }, [openedBonusId])

    const saveChanges = async () => {
        if(!bonusEditing || !bonuses) return;
        if(bonusEditing.title.errorMessage) return dispatch(addSnackbar({ type: 'error', message: 'Inserire il nome del bonus'}));
        if(bonusEditing.siteId === -1) return dispatch(addSnackbar({ type: 'error', message: 'Selezionare il bookmaker del bonus'}));
        if(bonusEditing.steps.map(s => !!s.betId && !s.filtersId).filter(Boolean).length > 0) return dispatch(addSnackbar({ type: 'error', message: 'Sono presenti degli step con una puntata selezionata ma senza filtri'}));
        const bonusToSave: Bonus = {
            id: bonuses.content[editingIndex].id,
            productId: bonuses.content[editingIndex].productId,
            siteId: bonusEditing.siteId,
            title: bonusEditing.title.value,
            type: bonusEditing.type,
            status: bonusEditing.status,
            expectedProfit: (!bonusEditing.expectedProfit.errorMessage && bonusEditing.expectedProfit.value.length>0) ? Math.round(+bonusEditing.expectedProfit.value*100)/100 : undefined,
            qualifyingMode: bonusEditing.qualifyingMode,
            qualifyingLive: bonusEditing.qualifyingLive,
            bonusMode: bonusEditing.bonusMode,
            bonusLive: bonusEditing.bonusLive,
            expirationDate: (!bonusEditing.expirationDate.errorMessage && bonusEditing.expirationDate.day.length>0) ? localeStringToUTCString(`${bonusEditing.expirationDate.year}-${bonusEditing.expirationDate.month.padStart(2, '0')}-${bonusEditing.expirationDate.day.padStart(2, '0')}T${bonusEditing.expirationDate.hour.padStart(2, '0')}:${bonusEditing.expirationDate.minute.padStart(2, '0')}`) : undefined,
            steps: bonusEditing.steps.map((s,i) => ({
                id: s.id,
                expirationDate: (!s.expirationDate.errorMessage && s.expirationDate.day.length>0) ? localeStringToUTCString(`${s.expirationDate.year}-${s.expirationDate.month.padStart(2, '0')}-${s.expirationDate.day.padStart(2, '0')}T${s.expirationDate.hour.padStart(2, '0')}:${s.expirationDate.minute.padStart(2, '0')}`) : undefined,
                expirationHours: !s.expirationHours.errorMessage && s.expirationHours.value.length>0 ? parseInt(s.expirationHours.value) : undefined,
                expirationHoursFrom: s.expirationHoursFrom,
                unlockingDate: (!s.unlockingDate.errorMessage && s.unlockingDate.day.length>0) ? localeStringToUTCString(`${s.unlockingDate.year}-${s.unlockingDate.month.padStart(2, '0')}-${s.unlockingDate.day.padStart(2, '0')}T${s.unlockingDate.hour.padStart(2, '0')}:${s.unlockingDate.minute.padStart(2, '0')}`) : undefined,
                unlockingHours: !s.unlockingHours.errorMessage && s.unlockingHours.value.length>0 ? parseInt(s.unlockingHours.value) : undefined,
                unlockingHoursFrom: s.unlockingHoursFrom,
                type: s.type,
                description: s.description.length>0 ? s.description : undefined,
                files: s.files,
                single: s.betType==='Singola' ? (singles ?? []).find(x => x.id === parseInt(s.betId ?? '0')) : undefined,
                multiple: s.betType==='Multipla' ? (multiples ?? []).find(x => x.id === parseInt(s.betId ?? '0')) : undefined,
                rollover: s.betType==='Rollover' ? (rollovers ?? []).find(x => x.id === parseInt(s.betId ?? '0')) : undefined,
                filters: (filters ?? []).find(x => x.id === parseInt(s.filtersId ?? '0')),
                pos: i
            })),
        }
        const updatedBonus = await _saveBonus(bonusToSave);
        if(!updatedBonus) return dispatch(addSnackbar({ type: 'error', message: "Errore nel salvataggio del bonus"}));
        dispatch(addSnackbar({ type: 'success', message: "Bonus salvato"}));
        setBonuses(prevState => prevState ? {...prevState, content: prevState.content.map((x,i) => i===editingIndex ? updatedBonus : x)} : prevState);
        setOpenedBonusId(updatedBonus.id);
    }
    const discardChanges = () => {
        if(!bonuses) return;
        if(editingIndex === 0 && bonuses.content[editingIndex].id === null) setBonuses(prevState => prevState ? {...prevState, empty: prevState.content.length<2, content: prevState.content.slice(1)} : undefined);
        setOpenedBonusId(-1);
        setEditingIndex(-1);
        setBonusEditing(undefined);
    }

    const start = async (bonusId: number) => {
        const bonus: Bonus|undefined = await _startBonus(productId, bonusId);
        if(!bonus) return dispatch(addSnackbar({ type: 'error', message: "Errore"}));
        setBonuses(prevState => prevState ? {...prevState, empty: prevState.content.length<2, content: prevState.content.filter(x => x.id!==bonusId)} : prevState);
        setBonusesInProgress(prevState => prevState ? [bonus, ...prevState] : [bonus]);
    }

    const saveUserSingle = async (bonusId: number, userBonusId: number, bonusStepId: number, single: SingleObj) => {
        const _id: number|undefined = await _saveSingle({...single, userBonusId, bonusStepId});
        if(!_id) return dispatch(addSnackbar({ type: 'error', message: "Errore nel salvataggio della singola"}));
        setBonusesInProgress(prevState => prevState ? prevState.map(x => x.id===bonusId ? {
            ...x, 
            userBonus: x.userBonus ? {
                ...x.userBonus, 
                singles: single.id===null ? [...(x.userBonus?.singles ?? []), {...single, id: _id, userBonusId, bonusStepId}] : (x.userBonus?.singles ?? []).map(y => y.id===single.id ? {...single, id: _id, userBonusId, bonusStepId} : y)
            } : undefined
        } : x) : prevState);
    }
    const saveUserMultiple = async (bonusId: number, userBonusId: number, bonusStepId: number, multiple: MultipleObj) => {
        const _id: number|undefined = await _saveMultiple({...multiple, userBonusId, bonusStepId});
        if(!_id) return dispatch(addSnackbar({ type: 'error', message: "Errore nel salvataggio della multipla"}));
        setBonusesInProgress(prevState => prevState ? prevState.map(x => x.id===bonusId ? {
            ...x, 
            userBonus: x.userBonus ? {
                ...x.userBonus, 
                multiples: multiple.id===null ? [...(x.userBonus?.multiples ?? []), {...multiple, id: _id, userBonusId, bonusStepId}] : (x.userBonus?.multiples ?? []).map(y => y.id===multiple.id ? {...multiple, id: _id, userBonusId, bonusStepId} : y)
            } : undefined
        } : x) : prevState);
    }
    const saveUserRollover = async (bonusId: number, userBonusId: number, bonusStepId: number, rollover: RolloverObj) => {
        const _id: number|undefined = await _saveRollover({...rollover, userBonusId, bonusStepId});
        if(!_id) return dispatch(addSnackbar({ type: 'error', message: "Errore nel salvataggio della multipla"}));
        setBonusesInProgress(prevState => prevState ? prevState.map(x => x.id===bonusId ? {
            ...x, 
            userBonus: x.userBonus ? {
                ...x.userBonus, 
                rollovers: rollover.id===null ? [...(x.userBonus?.rollovers ?? []), {...rollover, id: _id, userBonusId, bonusStepId}] : (x.userBonus?.rollovers ?? []).map(y => y.id===rollover.id ? {...rollover, id: _id, userBonusId, bonusStepId} : y)
            } : undefined
        } : x) : prevState);
    }

    const handleStepDoneClick = async (bonusIndex: number, stepIndex: number) => {
        if(!bonusesInProgress) return;
        const bonus: Bonus = bonusesInProgress[bonusIndex];
        const reachedStepId = stepIndex<bonus.steps.length-1 ? bonus.steps[stepIndex+1].id : null;
        const userBonus: UserBonus|undefined = bonus.userBonus ? {
            ...bonus.userBonus, 
            reachedStepId,
            profit: reachedStepId===null ? Math.round(bonus.steps.reduce((sum,step) => sum+(stepsResults.get(step.id ?? 0) ?? 0.0), 0.0)*100)/100 : null
        } : undefined;
        if(reachedStepId===null) {
            if(bonus.steps.some(s => (s.single || s.multiple || s.rollover) && stepsResults.get(s.id || 0)===null)) return dispatch(addSnackbar({ type: 'error', message: "Impossibile concludere il bonus. Sono presenti singole, multiple o rollover ancora in corso."}));
            if(!bonus.userBonus?.friendsNumber) return dispatch(addSnackbar({ type: 'error', message: "Prima di concludere il bonus inserisci il numero di amici."}));
            setBonusesFinished(prevState => prevState ? {...prevState, empty: false, content: [{...bonus, userBonus}, ...prevState.content]} : prevState);
            setBonusesInProgress(prevState => prevState ? prevState.filter((x,i) => i!==bonusIndex) : prevState);
            setOpenedBonusId(-1);
        }
        if(userBonus) saveUserBonus({...userBonus, singles: [], multiples: [], rollovers: []});
    }

    const handleStepNotDoneClick = async (bonusIndex: number, stepIndex: number) => {
        if(!bonusesInProgress) return;
        const bonus: Bonus = bonusesInProgress[bonusIndex];
        saveUserBonus(bonus.userBonus ? {
            ...bonus.userBonus, 
            reachedStepId: bonus.steps[stepIndex].id,
            singles: [], multiples: [], rollovers: []
        } : undefined);
    }

    const handleFriendsNumberChange = (bonusIndex: number, newState: NumberInputState) => {
        if(!bonusesInProgress) return;
        const bonus: Bonus = bonusesInProgress[bonusIndex];
        const friendsNumber: number|null = newState.errorMessage ? null : parseInt(newState.value);
        saveUserBonus(bonus.userBonus ? {
            ...bonus.userBonus, 
            friendsNumber,
            singles: [], multiples: [], rollovers: []
        } : undefined);
    }

    const saveUserBonus = async (userBonus: UserBonus|undefined) => {
        if(!userBonus) return;
        const updatedUserBonus = await _saveUserBonus(userBonus);
        if(!updatedUserBonus) return dispatch(addSnackbar({ type: 'error', message: "Errore nel salvataggio del bonus"}));
        setBonusesInProgress(prevState => prevState ? prevState.map(x => userBonus.bonusId===x.id ? {...x, userBonus: {...updatedUserBonus, singles: x.userBonus?.singles ?? [], multiples: x.userBonus?.multiples ?? [], rollovers: x.userBonus?.rollovers ?? []}} : x) : prevState);
    }
    
    const handleBetResultChange = (bonusStepId: number, result: number|null) => {
        setStepsResults(prevState => {
            if(prevState.get(bonusStepId)===result) return prevState;
            const map = new Map<number, number|null>(prevState);
            map.set(bonusStepId, result);
            return map;
        })
    }

    const clipboardText = useRef<string>('');
    const copyToClipboard = (text: string|null, message?: string) => {
        if(!text) return;
        if(text.includes('bwin') && clipboardText.current.includes('bwin')) text.replace('bwin', 'GD');
		navigator.clipboard.writeText(text);
		clipboardText.current = text;
        if(message) dispatch(addSnackbar({ type: 'info', message }));
	};

    const pages: number[] = useMemo(() => {
        if(!bonuses || bonuses.totalPages<=1) return [];
        let start = 0;
        let end = 4;
        if(bonuses.number-2 < 0) end = Math.min(4, bonuses.totalPages-1);
        else if(bonuses.number+3 > bonuses.totalPages)  {end = bonuses.totalPages-1; start = Math.max(0, bonuses.totalPages-5);}
        else {start = bonuses.number-2; end = bonuses.number+2;}
        return Array.from({ length: end-start+1 }, (value, index) => start + index);
    }, [bonuses]);

    const pagesFinished: number[] = useMemo(() => {
        if(!bonusesFinished) return [];
        let start = 0;
        let end = 4;
        if(bonusesFinished.number-2 < 0) end = Math.min(4, bonusesFinished.totalPages-1);
        else if(bonusesFinished.number+3 > bonusesFinished.totalPages)  {end = bonusesFinished.totalPages-1; start = Math.max(0, bonusesFinished.totalPages-5);}
        else {start = bonusesFinished.number-2; end = bonusesFinished.number+2;}
        return Array.from({ length: end-start+1 }, (value, index) => start + index);
    }, [bonusesFinished]);

    const bonusesTable = (bonusesList: Bonus[]) => {
        const columns = ['#', 'Tipologia', 'Data', 'Bookmaker', 'Nome Bonus', 'Stato', 'Attivazione entro', 'Profitto Stimato', ''];
        return(
            <Table borderSpacing={isAppMobile ? '0' : undefined}>
                <THead><TR>{columns.map((col, index) => <TH key={index}>{col}</TH>)}</TR></THead>
                <TBody>
                    {bonusesList.map((x,index) => {
                        const editing = editingIndex === index && bonusEditing;
                        const site = sites.get(editing ? bonusEditing.siteId : x.siteId);
                        const sitesChecked: boolean[] = editing ? sitesList.map(x => x.id===site?.id) : [];
                        const expired = x.status!=='CREATED' && x.expirationDate && getDate(x.expirationDate) < new Date();
                        const opened = x.id === openedBonusId;
                        return(
                            <Fragment key={index}>
                                <TR cellPaddingVert='5px' backgroundColor={index%2===1 ? '#29353e8a' : undefined}>
                                    {editing && 
                                        <>
                                            <TD>{x.id}</TD>
                                            <TD>
                                                <Select 
                                                    name={"select-bonus-type"} 
                                                    value={bonusEditing.type} height='25px'
                                                    backgroundColor={bonusEditing.type ? (bonusEditing.type==='Programma VIP' ? '#af7b1a' : (bonusEditing.type==='Possibile ESCLUSIVA' ? '#a63e3e' : '#1b6c1b')) : undefined}
                                                    onChange={handleTypeChange}
                                                >
                                                    {bonusTypes.map((y,i) => <option key={'bonus-type'+i} value={y}>{y}</option>)}
                                                </Select>
                                            </TD>
                                            <TD>{x.creationDate ? formatDate(x.creationDate) : ''}</TD>
                                            <TD>
                                                <CellContainer>
                                                    <Dropdown 
                                                        buttonText={site ? site.name : 'Book'}
                                                        button={(site && site.id>0) 
                                                            ? <SiteBox checked={true} name={site.name} width={75} height={23}><SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                            : undefined
                                                        }
                                                        buttonColor={site ? site.color : undefined}
                                                        buttonWidth={75}
                                                        buttonHeight='23px'
                                                        content={
                                                            <DropdownContent width={175}>   
                                                                <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                        sitesList.map((site, i) => site.id===0 
                                                                        ? <StandardCheckbox checked={sitesChecked[i]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                        : <SiteBox checked={sitesChecked[i]} name={site.name}><SiteLogoContainer visible={sitesChecked[i]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                    } checked={sitesChecked} onOptionClick={(checked: boolean[]) => handleSiteChange(checked.findIndex(Boolean)>-1 ? sitesList[checked.findIndex(Boolean)].id : -1)}/>
                                                                </SitesCheckboxes>
                                                            </DropdownContent>
                                                        }
                                                    />
                                                </CellContainer>
                                            </TD>
                                            <TD> <TextInputWithLabel value={bonusEditing.title.value} width='auto' height={25} onChange={handleTitleChange}/></TD>
                                            <TD>
                                                <Select 
                                                    name={"select-bonus-status"} 
                                                    value={bonusEditing.status} height='25px'
                                                    backgroundColor={bonusEditing.status ? (bonusEditing.status==='CREATED' ? '#21727a' : (bonusEditing.status==='Promo Terminata' ? '#a63e3e' : '#1b6c1b')) : undefined}
                                                    onChange={handleStatusChange}
                                                >
                                                    {bonusStatuses.map((y,i) => {
                                                        if(x.status!=='CREATED' && y==='CREATED') return;
                                                        return(<option key={'bonus-status'+i} value={y}>{y}</option>);
                                                    })}
                                                </Select>
                                            </TD>
                                            <TD><DateInput value={bonusEditing.expirationDate} minDate={new Date()} allowEmpty={true} height={25} onChange={handleExpirationDateChange}/></TD>
                                            <TD><Row mainAxis='center'><NumberInput value={bonusEditing.expectedProfit.value} height={25} small={true} allowEmpty={true} negative={true} decimals={2} stepFunction={(value, direction) => stepEach(0.01, value, direction)} onChange={handleExpectedProfitChange}/></Row></TD>
                                        </>

                                    }
                                    {!editing &&
                                        <>
                                            <TD>{x.id}</TD>
                                            <TD backgroundColor={x.type ? (x.type==='Programma VIP' ? '#af7b1a' : (x.type==='Possibile ESCLUSIVA' ? '#a63e3e' : '#1b6c1b')) : undefined}>{x.type}</TD>
                                            <TD>{x.creationDate ? formatDate(x.creationDate) : ''}</TD>
                                            <TD>
                                                <CellContainer>
                                                    <SiteBox height={23} width={75} checked={true} notClickable={true}>
                                                        <SiteLogoContainer visible={true} backgroundColor={site?.color}><SiteLogo imageUrl={site?.imageUrl}/></SiteLogoContainer>
                                                    </SiteBox>
                                                </CellContainer>
                                            </TD>
                                            <TD>{x.title}</TD>
                                            <TD backgroundColor={expired ? '#a63e3e' : (x.status ? (x.status==='CREATED' ? '#21727a' : (x.status==='Promo Terminata' ? '#a63e3e' : '#1b6c1b')) : undefined)}>{expired ? 'Scaduto' : x.status}</TD>
                                            <TD>{x.expirationDate ? formatDate(x.expirationDate) : 'ND'}</TD>
                                            <TD fontSize={x.expectedProfit!==undefined ? 20 : 14} color={x.expectedProfit ? (x.expectedProfit>0 ? '#00c100' : '#ff4242') : undefined}>{x.expectedProfit!==undefined ? '€'+x.expectedProfit.toFixed(2) : 'ND'}</TD>
                                        </>
                                    }
                                    <TD>
                                        <Row gap='5px' mainAxis='center'>
                                            {isAdmin && 
                                                <>
                                                    <SVGIconContainer title='Modifica' opacity={editingIndex>-1 ? 0.2 : undefined} notClickable={editingIndex>-1} onClick={() => handleEditClick(index)}><EditIconSVG/></SVGIconContainer>
                                                    <SVGIconContainer title='Duplica' opacity={editingIndex>-1 ? 0.2 : undefined} notClickable={editingIndex>-1} onClick={() => handleDuplicateClick(index)}><DuplicateIconSVG/></SVGIconContainer>
                                                </>
                                            }
                                            {(opened && editingIndex==-1)
                                                ? <SVGIconContainer title={'Nascondi dettagli'} opacity={editingIndex>-1 ? 0.2 : undefined} notClickable={editingIndex>-1} onClick={() => handleOpenClick(x.id)}><MinusIconSVG/></SVGIconContainer>
                                                : <SVGIconContainer title={'Vedi dettagli'} opacity={editingIndex>-1 ? 0.2 : undefined} notClickable={editingIndex>-1} onClick={() => handleOpenClick(x.id)}><PlusIconSVG/></SVGIconContainer>
                                            }
                                        </Row>
                                    </TD>
                                </TR>
                                {opened &&
                                    <TR>
                                        <SingleDivCell colSpan={columns.length} paddingBottom='20px'>
                                            <Col gap='20px'>
                                                {editing && 
                                                    <Row mainAxis='flex-end' gap='5px'>
                                                        <ToolButton backgroundColor='rgb(1 79 1)' onClick={saveChanges}>Salva</ToolButton>
                                                        <ToolButton backgroundColor='#9b2c2c' onClick={discardChanges}>Annulla</ToolButton>
                                                    </Row>
                                                }
                                                <Row mainAxis='center' gap='20px' flexWrap='wrap'>
                                                    <Row gap='5px' mainAxis='center' width='auto'>
                                                        <Label>Qualificante</Label>
                                                        {editing ?
                                                            <>
                                                                <Select 
                                                                    name={"select-qualifying-mode"} 
                                                                    value={bonusEditing.qualifyingMode} height='25px'
                                                                    onChange={handleQualifyingTypeChange}
                                                                >
                                                                    {qualifyingModes.map((y,i) => <option key={'qualifying-mode'+i} value={y}>{y}</option>)}
                                                                </Select>
                                                                <SwitchSelector isFilterActive={bonusEditing.qualifyingLive} height={25} filterHandler={handleQualifyingLiveChange} label="Live"/>
                                                            </>
                                                            :
                                                            <Row gap='5px'>
                                                                <BoxedLabel backgroundColor='#0c6caa'>{x.qualifyingMode}</BoxedLabel>
                                                                {(x.qualifyingMode!=='No' && x.qualifyingLive) && <BoxedLabel backgroundColor='red'>LIVE</BoxedLabel>}
                                                            </Row>
                                                        }
                                                    </Row>
                                                    <Row gap='5px' mainAxis='center' width='auto'>
                                                        <Label>Bonus</Label>
                                                        {editing ?
                                                            <>
                                                                <Select 
                                                                    name={"select-bonus-mode"} 
                                                                    value={bonusEditing.bonusMode} height='25px'
                                                                    onChange={handleBonusTypeChange}
                                                                >
                                                                    {bonusModes.map((y,i) => <option key={'bonus-mode'+i} value={y}>{y}</option>)}
                                                                </Select>
                                                                <SwitchSelector isFilterActive={bonusEditing.bonusLive} height={25} filterHandler={handleBonusLiveChange} label="Live"/>
                                                            </>
                                                            :
                                                            <Row gap='5px'>
                                                                <BoxedLabel backgroundColor='#0c6caa'>{x.bonusMode}</BoxedLabel>
                                                                {(x.bonusMode!=='No' && x.bonusLive) && <BoxedLabel backgroundColor='red'>LIVE</BoxedLabel>}
                                                            </Row>
                                                        }
                                                    </Row>
                                                </Row>
                                                <Col gap='10px'>
                                                    <Col gap='5px'>
                                                        {editing &&
                                                            <>
                                                                {bonusEditing.steps.map((s,i) => {
                                                                    const bets = s.betType==='Singola' ? (singles ?? []) : (s.betType==='Rollover' ? (rollovers ?? []) : (multiples ?? []));
                                                                    return(
                                                                        <BonusStepBox key={'step-'+i}>
                                                                            <Row gap='5px' mainAxis='space-between' crossAxis='flex-start'>
                                                                                <SVGIconContainer title='Elimina step' size={23} onClick={() => handleDeleteStepClick(i)}><TrashIconSVG/></SVGIconContainer>
                                                                                <TextInputWithLabel value={s.type} width='auto' height={25} onChange={(newState: TextInputState) => handleBonusStepTypeChange(i, newState.value)}/>
                                                                                <Col gap='2px' crossAxis='flex-end'>
                                                                                    <Row gap='5px' width='auto'>
                                                                                        <Label>Sblocco</Label>
                                                                                        <Row gap='5px'>
                                                                                            <DateInput value={s.unlockingDate} minDate={new Date()} allowEmpty={true} height={25} onChange={(newState: DateInputState) => handleBonusStepUnlockingDateChange(i, newState)}/>
                                                                                            <Row gap='3px'>
                                                                                                <NumberInput value={s.unlockingHours.value} inputWidth={35} height={25} small={true} noDecimals={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBonusStepUnlockingHoursChange(i, newState)}/>
                                                                                                <NoWrapLabel>ore da</NoWrapLabel>
                                                                                                <Select 
                                                                                                    name={"select-bonus-step-exp-hours-from"} 
                                                                                                    value={s.unlockingHoursFrom} height='25px'
                                                                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleBonusStepUnlockingHoursFromChange(i, event.target.value)}
                                                                                                >
                                                                                                    {hoursFromTypes.map((y,j) => <option key={'bonus-step-exp-hours-from'+j} value={y}>{y}</option>)}
                                                                                                </Select>
                                                                                            </Row>
                                                                                        </Row>
                                                                                    </Row>
                                                                                    <Row gap='5px' width='auto'>
                                                                                        <Label>Scadenza</Label>
                                                                                        <Row gap='5px'>
                                                                                            <DateInput value={s.expirationDate} minDate={new Date()} allowEmpty={true} height={25} onChange={(newState: DateInputState) => handleBonusStepExpirationDateChange(i, newState)}/>
                                                                                            <Row gap='3px'>
                                                                                                <NumberInput value={s.expirationHours.value} inputWidth={35} height={25} small={true} noDecimals={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBonusStepExpirationHoursChange(i, newState)}/>
                                                                                                <NoWrapLabel>ore da</NoWrapLabel>
                                                                                                <Select 
                                                                                                    name={"select-bonus-step-exp-hours-from"} 
                                                                                                    value={s.expirationHoursFrom} height='25px'
                                                                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleBonusStepExpirationHoursFromChange(i, event.target.value)}
                                                                                                >
                                                                                                    {hoursFromTypes.map((y,j) => <option key={'bonus-step-exp-hours-from'+j} value={y}>{y}</option>)}
                                                                                                </Select>
                                                                                            </Row>
                                                                                        </Row>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                            <BonusStepDescriptionEdit value={s.description} rows={8} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleBonusStepDescriptionChange(i, event.target.value)}/>
                                                                            {s.files.length>0 && 
                                                                                <Row gap='10px' mainAxis='center' flexWrap='wrap'>
                                                                                    {s.files.map((f,j) => {
                                                                                        return(
                                                                                            <Col key={'f'+j} gap='5px'>
                                                                                                <ClickableResource fileType={f.fileType.name.includes('Image') ? 'Image' : 'Video'} url={f.url} alt={f.fileName} width='200px'/>
                                                                                                <SVGIconContainer title='Rimuovi' onClick={() => handleDeleteFileClick(i, j)}><TrashIconSVG/></SVGIconContainer>
                                                                                            </Col>
                                                                                        );
                                                                                    })}
                                                                                </Row>
                                                                            }
                                                                            <Row gap='10px' mainAxis='center' flexWrap='wrap'>
                                                                                <Upload onUploadClick={(fileType: string, file?: File, url?: string) => upload(i, fileType, file, url)}/>
                                                                                <Col gap='5px'>
                                                                                    <Row gap='5px' width='auto'>
                                                                                        <Label>Puntata</Label>
                                                                                        <Select 
                                                                                            name={"select-bet-type"} 
                                                                                            value={s.betType} height='25px'
                                                                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleBetTypeChange(i, event.target.value)}
                                                                                        >
                                                                                            {betTypes.map((y,j) => <option key={'bet-type'+j} value={y}>{y}</option>)}
                                                                                        </Select>
                                                                                        <Select 
                                                                                            name={"select-bet"} 
                                                                                            value={s.betId ?? ''} height='25px'
                                                                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleBetChange(i, event.target.value)}
                                                                                        >
                                                                                            <option value="">-</option>
                                                                                            {bets.map((y,j) => <option key={'bet'+j} value={(y.id || 0).toString()}>{y.description}</option>)}
                                                                                        </Select>
                                                                                    </Row>
                                                                                    <Row gap='5px'>
                                                                                        <Label>Filtri</Label>
                                                                                        <Select 
                                                                                            name={"select-filters"} 
                                                                                            value={s.filtersId ?? ''} width='100%' height='25px'
                                                                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleFiltersChange(i, event.target.value)}
                                                                                        >
                                                                                            <option value="">-</option>
                                                                                            {(filters || []).map((y,j) => <option key={'filters'+j} value={(y.id || 0).toString()}>{y.description}</option>)}
                                                                                        </Select>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        </BonusStepBox>
                                                                    );
                                                                })}
                                                                <AddButton onClick={addNewBonusStep}>Aggiungi Step</AddButton>
                                                            </>
                                                        }
                                                        {!editing &&
                                                            <>
                                                                {(x.steps ?? []).map((s,i) => {
                                                                    return(
                                                                        <BonusStepBox key={'step-'+i}>
                                                                            <Row gap='10px' mainAxis='space-between'>
                                                                                <Row>
                                                                                    <BonusStepTypeLabel>{s.type}</BonusStepTypeLabel>
                                                                                </Row>
                                                                                {(s.unlockingDate || s.unlockingHours || s.expirationDate || s.expirationHours) &&
                                                                                    <Row gap='10px'>
                                                                                        {(s.unlockingDate || s.unlockingHours) &&
                                                                                            <Row gap='5px' width='auto'>
                                                                                                <Label>Sblocco</Label>
                                                                                                <Row gap='5px'>
                                                                                                    {s.unlockingDate && <ExpirationDateLabel>{formatDate(s.unlockingDate)}</ExpirationDateLabel>}
                                                                                                    {s.unlockingHours && <ExpirationDateLabel>{s.unlockingHours} ore da {s.unlockingHoursFrom}</ExpirationDateLabel>}
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }
                                                                                        {(s.expirationDate || s.expirationHours) &&
                                                                                            <Row gap='5px' width='auto'>
                                                                                                <Label>Scadenza</Label>
                                                                                                <Row gap='5px'>
                                                                                                    {s.expirationDate && <ExpirationDateLabel>{formatDate(s.expirationDate)}</ExpirationDateLabel>}
                                                                                                    {s.expirationHours && <ExpirationDateLabel>{s.expirationHours} ore da {s.expirationHoursFrom}</ExpirationDateLabel>}
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }
                                                                                    </Row>
                                                                                }
                                                                            </Row>
                                                                            {s.description && <BonusStepDescription>{s.description.split('\n').map((y,j) => <BonusStepDescriptionPar key={'sd'+j}>{y}</BonusStepDescriptionPar>)}</BonusStepDescription>}
                                                                            {s.files.length>0 && 
                                                                                <Row gap='10px' mainAxis='center' flexWrap='wrap'>
                                                                                    {s.files.map((f,j) => {
                                                                                        return(<ClickableResource key={'f'+j} fileType={f.fileType.name.includes('Image') ? 'Image' : 'Video'} url={f.url} alt={f.fileName} width='200px'/>);
                                                                                    })}
                                                                                </Row>
                                                                            }
                                                                            {s.single && <BoxedLabel backgroundColor='#0c6caa'>Singola</BoxedLabel>}
                                                                            {s.multiple && <BoxedLabel backgroundColor='#0c6caa'>Multipla</BoxedLabel>}
                                                                            {s.rollover && <BoxedLabel backgroundColor='#0c6caa'>Rollover</BoxedLabel>}
                                                                        </BonusStepBox>
                                                                    );
                                                                })}
                                                            </>
                                                        }
                                                    </Col>
                                                </Col>
                                                {(!editing && x.status==='Nuova Promozione' && x.id!==null) && <Row mainAxis='center'><StartButton onClick={() => x.id!==null && start(x.id)}>Inizia Bonus</StartButton></Row>}
                                            </Col>
                                        </SingleDivCell>
                                    </TR>
                                }
                            </Fragment>
                        );
                    })}
                </TBody>
            </Table>
        );
    }

    const userBonusesTable = (bonusesList: Bonus[], finished: boolean) => {
        const columns = finished 
            ? ['#', 'Tipologia', 'Data', 'Bookmaker', 'Nome Bonus', 'Stato', 'Profitto Stimato', 'Profitto', '']
            : ['#', 'Tipologia', 'Data', 'Bookmaker', 'Nome Bonus', 'Stato', 'Attivazione entro', 'Profitto Stimato', ''];
        return(
            <Table borderSpacing={isAppMobile ? '0' : undefined}>
                <THead><TR>{columns.map((col, index) => <TH key={index}>{col}</TH>)}</TR></THead>
                <TBody>
                    {bonusesList.map((x,index) => {
                        const site = sites.get(x.siteId);
                        const opened: boolean = x.id === openedBonusId;
                        let reachedStepIndex: number = x.steps.findIndex(s => s.id===x.userBonus?.reachedStepId);
                        const stepsSingles = new Map<number, SingleObj>([]); 
                        if(opened) (x.userBonus?.singles ?? []).forEach((s: SingleObj) => !!s.bonusStepId && stepsSingles.set(s.bonusStepId, s));
                        const stepsMultiples = new Map<number, MultipleObj>([]); 
                        if(opened) (x.userBonus?.multiples ?? []).forEach((s: MultipleObj) => !!s.bonusStepId && stepsMultiples.set(s.bonusStepId, s));
                        const stepsRollovers = new Map<number, RolloverObj>([]); 
                        if(opened) (x.userBonus?.rollovers ?? []).forEach((s: RolloverObj) => !!s.bonusStepId && stepsRollovers.set(s.bonusStepId, s));
                        if(reachedStepIndex<0) reachedStepIndex = x.steps.length;

                        let expired: boolean = reachedStepIndex===0 ? (!!x.expirationDate && getDate(x.expirationDate) < new Date()) : false;
                        if(!expired &&reachedStepIndex<x.steps.length) {
                            const s: BonusStep = x.steps[reachedStepIndex];
                            let stepExpirationDate: Date|undefined = undefined;
                            if(!s.expirationDate && s.expirationHours) {
                                if(s.expirationHoursFrom==='Attivazione' && x.expirationDate) stepExpirationDate = new Date(x.expirationDate);
                                else if(s.expirationHoursFrom==='Conclusione evento') {
                                    const prevStepSingle: SingleObj|undefined = stepsSingles.get((reachedStepIndex>0 ? x.steps[reachedStepIndex-1].id : 0) ?? 0);
                                    const prevStepMultiple: MultipleObj|undefined = stepsMultiples.get((reachedStepIndex>0 ? x.steps[reachedStepIndex-1].id : 0) ?? 0);
                                    if(prevStepSingle && prevStepSingle.data.oddsMatch.event.datetime) {
                                        let d = prevStepSingle.data.oddsMatch.event.datetime;
                                        if(d) stepExpirationDate = new Date(d);
                                    } else if(prevStepMultiple) {
                                        let d = prevStepMultiple.data.multiple[prevStepMultiple.data.multiple.length-1].event?.datetime;
                                        if(d) stepExpirationDate = new Date(d);
                                    }
                                    if(stepExpirationDate) stepExpirationDate.setTime(stepExpirationDate.getTime()+2*60*60*1000);
                                }
                                if(stepExpirationDate) stepExpirationDate.setTime(stepExpirationDate.getTime()+s.expirationHours*60*60*1000);
                            } else if(s.expirationDate) stepExpirationDate = new Date(s.expirationDate);
                            expired = !!stepExpirationDate && stepExpirationDate < new Date();
                        }

                        const friendsNumber: number = finished ? (x.userBonus?.friendsNumber ?? 1) : 1;
                        const status: string = (expired ? 'Scaduto' : (reachedStepIndex < x.steps.length ? x.steps[reachedStepIndex].type : 'Concluso')) ?? '';
                        return(
                            <Fragment key={index}>
                                <TR cellPaddingVert='5px' backgroundColor={index%2===1 ? '#29353e8a' : undefined}>
                                    <TD>{x.id}</TD>
                                    <TD backgroundColor={x.type ? (x.type==='Programma VIP' ? '#af7b1a' : (x.type==='Possibile ESCLUSIVA' ? '#a63e3e' : '#1b6c1b')) : undefined}>{x.type}</TD>
                                    <TD>{x.userBonus?.creationDate ? formatDate(x.userBonus.creationDate) : ''}</TD>
                                    <TD>
                                        <CellContainer>
                                            <SiteBox height={23} width={75} checked={true} notClickable={true}>
                                                <SiteLogoContainer visible={true} backgroundColor={site?.color}><SiteLogo imageUrl={site?.imageUrl}/></SiteLogoContainer>
                                            </SiteBox>
                                        </CellContainer>
                                    </TD>
                                    <TD>{x.title}</TD>
                                    <TD backgroundColor={status==='Scaduto' ? '#a63e3e' : (status==='Concluso' ? '#1b6c1b' : '#cc6900')}>{status}</TD>
                                    {!finished && <TD>{x.expirationDate ? formatDate(x.expirationDate) : 'ND'}</TD>}
                                    <TD 
                                        fontSize={x.expectedProfit!==undefined ? 20 : 14} 
                                        color={x.expectedProfit ? (x.expectedProfit>0 ? '#00c100' : '#ff4242') : undefined} 
                                        title={x.expectedProfit!==undefined ? '[Profitto Stimato] x [Numero Amici] = €'+x.expectedProfit.toFixed(2)+' x '+friendsNumber.toString() : undefined}
                                    >
                                        {x.expectedProfit!==undefined ? '€'+(x.expectedProfit*friendsNumber).toFixed(2) : 'ND'}
                                    </TD>
                                    {finished && 
                                        <TD 
                                            fontSize={x.userBonus?.profit!==null ? 20 : 14} 
                                            color={x.userBonus?.profit ? (x.userBonus.profit>0 ? '#00c100' : '#ff4242') : undefined} 
                                            title={x.userBonus?.profit!==null ? '[Profitto] x [Numero Amici] = €'+(x.userBonus?.profit ?? 0).toFixed(2)+' x '+friendsNumber.toString() : undefined}
                                        >
                                            {x.userBonus?.profit!==null ? '€'+((x.userBonus?.profit ?? 0)*friendsNumber).toFixed(2) : 'ND'}
                                        </TD>
                                    }
                                    <TD>
                                        <Row gap='5px' mainAxis='center'>
                                            {opened 
                                                ? <SVGIconContainer title={'Nascondi dettagli'} notClickable={editingIndex>-1} onClick={() => handleOpenClick(x.id)}><MinusIconSVG/></SVGIconContainer>
                                                : <SVGIconContainer title={'Vedi dettagli'} notClickable={editingIndex>-1} onClick={() => handleOpenClick(x.id)}><PlusIconSVG/></SVGIconContainer>
                                            }
                                        </Row>
                                    </TD>
                                </TR>
                                {opened &&
                                    <TR>
                                        <SingleDivCell colSpan={columns.length} paddingBottom='20px'>
                                            <Col gap='20px'>
                                                <Row mainAxis='space-between' gap='20px' flexWrap='wrap'>
                                                    <Row gap='10px' width='auto' flexWrap='wrap'>
                                                        <Row gap='5px' mainAxis='center' width='auto'>
                                                            <Label>Qualificante</Label>
                                                            <Row gap='5px'>
                                                                <BoxedLabel backgroundColor='#0c6caa'>{x.qualifyingMode}</BoxedLabel>
                                                                {(x.qualifyingMode!=='No' && x.qualifyingLive) && <BoxedLabel backgroundColor='red'>LIVE</BoxedLabel>}
                                                            </Row>
                                                        </Row>
                                                        <Row gap='5px' mainAxis='center' width='auto'>
                                                            <Label>Bonus</Label>
                                                            <Row gap='5px'>
                                                                <BoxedLabel backgroundColor='#0c6caa'>{x.bonusMode}</BoxedLabel>
                                                                {(x.bonusMode!=='No' && x.bonusLive) && <BoxedLabel backgroundColor='red'>LIVE</BoxedLabel>}
                                                            </Row>
                                                        </Row>
                                                    </Row>
                                                    {x.userBonus && <NumberInput label='Numero Amici' value={x.userBonus.friendsNumber!==null ? x.userBonus.friendsNumber.toString() : ''} isDisabled={finished} isDisabledOpacity={1} labelWidth={100} inputWidth={35} height={28} small={true} minValue={1} includeLimits={true} noDecimals={true} allowEmpty={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleFriendsNumberChange(index, newState)}/>}
                                                </Row>
                                                <Col gap='10px'>
                                                    <Col gap='5px'>
                                                        {(x.steps ?? []).map((s,i) => {
                                                            let stepUnlockingDate: Date|undefined = undefined;
                                                            if(!s.unlockingDate && s.unlockingHours) {
                                                                if(s.unlockingHoursFrom==='Attivazione' && x.expirationDate) stepUnlockingDate = new Date(x.expirationDate);
                                                                else if(s.unlockingHoursFrom==='Conclusione evento') {
                                                                    const prevStepSingle: SingleObj|undefined = stepsSingles.get((i>0 ? x.steps[i-1].id : 0) ?? 0);
                                                                    const prevStepMultiple: MultipleObj|undefined = stepsMultiples.get((i>0 ? x.steps[i-1].id : 0) ?? 0);
                                                                    if(prevStepSingle && prevStepSingle.data.oddsMatch.event.datetime) {
                                                                        let d = prevStepSingle.data.oddsMatch.event.datetime;
                                                                        if(d) stepUnlockingDate = new Date(d);
                                                                    } else if(prevStepMultiple) {
                                                                        let d = prevStepMultiple.data.multiple[prevStepMultiple.data.multiple.length-1].event?.datetime;
                                                                        if(d) stepUnlockingDate = new Date(d);
                                                                    }
                                                                    if(stepUnlockingDate) stepUnlockingDate.setTime(stepUnlockingDate.getTime()+2*60*60*1000);
                                                                }
                                                                if(stepUnlockingDate) stepUnlockingDate.setTime(stepUnlockingDate.getTime()+s.unlockingHours*60*60*1000);
                                                            } else if(s.unlockingDate) stepUnlockingDate = new Date(s.unlockingDate);
                                                            const locked: boolean = !!stepUnlockingDate && stepUnlockingDate > new Date();

                                                            let stepExpirationDate: Date|undefined = undefined;
                                                            if(!s.expirationDate && s.expirationHours) {
                                                                if(s.expirationHoursFrom==='Attivazione' && x.expirationDate) stepExpirationDate = new Date(x.expirationDate);
                                                                else if(s.expirationHoursFrom==='Conclusione evento') {
                                                                    const prevStepSingle: SingleObj|undefined = stepsSingles.get((i>0 ? x.steps[i-1].id : 0) ?? 0);
                                                                    const prevStepMultiple: MultipleObj|undefined = stepsMultiples.get((i>0 ? x.steps[i-1].id : 0) ?? 0);
                                                                    if(prevStepSingle && prevStepSingle.data.oddsMatch.event.datetime) {
                                                                        let d = prevStepSingle.data.oddsMatch.event.datetime;
                                                                        if(d) stepExpirationDate = new Date(d);
                                                                    } else if(prevStepMultiple) {
                                                                        let d = prevStepMultiple.data.multiple[prevStepMultiple.data.multiple.length-1].event?.datetime;
                                                                        if(d) stepExpirationDate = new Date(d);
                                                                    }
                                                                    if(stepExpirationDate) stepExpirationDate.setTime(stepExpirationDate.getTime()+2*60*60*1000);
                                                                }
                                                                if(stepExpirationDate) stepExpirationDate.setTime(stepExpirationDate.getTime()+s.expirationHours*60*60*1000);
                                                            } else if(s.expirationDate) stepExpirationDate = new Date(s.expirationDate);
                                                            const expired: boolean = i>=reachedStepIndex && !!stepExpirationDate && stepExpirationDate < new Date();
                                                            
                                                            const stepSingle: SingleObj|undefined = s.id!==null ? stepsSingles.get(s.id) : undefined;
                                                            const stepMultiple: MultipleObj|undefined = s.id!==null ? stepsMultiples.get(s.id): undefined;
                                                            const stepRollover: RolloverObj|undefined = s.id!==null ? stepsRollovers.get(s.id): undefined;
                                                            
                                                            const winnings: number|null|undefined = stepsResults.get(s.id || 0);
                                                            const stepOpened: boolean = stepsOpened.length>i && stepsOpened[i];
                                                            return(
                                                                <BonusStepBox key={'step-'+i} isDisabled={(i>reachedStepIndex && !(stepSingle || stepMultiple || stepRollover)) || locked || (i>=reachedStepIndex && expired)} opacity={((locked || expired) && i===reachedStepIndex) ? '1' : undefined}>
                                                                    <Row gap='10px' mainAxis='space-between'>
                                                                        <Row gap='5px'>
                                                                            <SVGIconContainer notClickable={i<reachedStepIndex-1 || i>reachedStepIndex || finished}>
                                                                                {i<reachedStepIndex
                                                                                    ? <CheckboxOnSVG title={'Step fatto'} onClick={() => handleStepNotDoneClick(index, i)}/>
                                                                                    : <CheckboxOffSVG title={'Step da fare'} onClick={() => handleStepDoneClick(index, i)}/>
                                                                                }
                                                                            </SVGIconContainer>
                                                                            <BonusStepTypeLabel onClick={() => setStepsOpened(prevState => prevState.map((y,j) => j===i ? !y : y))}>{s.type}</BonusStepTypeLabel>
                                                                        </Row>
                                                                        {stepOpened && (s.unlockingDate || s.unlockingHours || s.expirationDate || s.expirationHours) &&
                                                                            <Row gap='10px' mainAxis='flex-end'>
                                                                                {locked && (s.unlockingDate || s.unlockingHours) &&
                                                                                    <Row gap='5px' width='auto'>
                                                                                        <Label>Sblocco</Label>
                                                                                        <Row gap='5px'>
                                                                                            {stepUnlockingDate && <ExpirationDateLabel>{formatDate(stepUnlockingDate)}</ExpirationDateLabel>}
                                                                                            {(s.unlockingHours && !stepUnlockingDate) && <ExpirationDateLabel>{s.unlockingHours} ore da {s.unlockingHoursFrom}</ExpirationDateLabel>}
                                                                                        </Row>
                                                                                    </Row>
                                                                                }
                                                                                {(s.expirationDate || s.expirationHours) &&
                                                                                    <Row gap='5px' width='auto'>
                                                                                        <Label>Scadenza</Label>
                                                                                        <Row gap='5px'>
                                                                                            {stepExpirationDate && <ExpirationDateLabel backgroundColor={expired ? '#ff2626' : undefined}>{formatDate(stepExpirationDate)}</ExpirationDateLabel>}
                                                                                            {(s.expirationHours && !stepExpirationDate) && <ExpirationDateLabel>{s.expirationHours} ore da {s.expirationHoursFrom}</ExpirationDateLabel>}
                                                                                        </Row>
                                                                                    </Row>
                                                                                }
                                                                            </Row>
                                                                        }
                                                                        {winnings!==undefined && <Winnings amount={winnings || 0} size='m'>€{winnings!==null ? winnings.toFixed(2) : '-'}</Winnings>}
                                                                    </Row>
                                                                    {stepOpened &&
                                                                        <>
                                                                            {(locked && stepUnlockingDate) &&
                                                                                <Row gap='10px' mainAxis='center'>
                                                                                    <ExpirationLabel>Sblocco tra:</ExpirationLabel>
                                                                                    <Timer limitTime={timestampToUTCString(stepUnlockingDate.getTime()/1000)} timerCount='HOURS' size='s' color='white'/>
                                                                                </Row>
                                                                            }
                                                                            {s.description && <BonusStepDescription>{s.description.split('\n').map((y,j) => <BonusStepDescriptionPar key={'sd'+j}>{y}</BonusStepDescriptionPar>)}</BonusStepDescription>}
                                                                            {s.files.length>0 && 
                                                                                <Row gap='10px' mainAxis='center' flexWrap='wrap'>
                                                                                    {s.files.map((f,j) => {
                                                                                        return(<ClickableResource key={'f'+j} fileType={f.fileType.name.includes('Image') ? 'Image' : 'Video'} url={f.url} alt={f.fileName} width='200px'/>);
                                                                                    })}
                                                                                </Row>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {(s.single && s.filters) && 
                                                                        <SingleSelector 
                                                                            single={stepSingle ?? {...s.single, id: null}} 
                                                                            filters={s.filters.data.filters} 
                                                                            feesBetfair={getItemFromLocalStorage('feesBetfair') ?? '4.5'} feesBetflag={getItemFromLocalStorage('feesBetflag') ?? '5'} 
                                                                            saveSingle={(single: SingleObj) => x.id!==null && s.id!==null && !!x.userBonus?.id && saveUserSingle(x.id, x.userBonus.id, s.id, single)} 
                                                                            isHidden={!stepOpened}
                                                                            readOnly={finished}
                                                                            saveResult={(result: number|null) => s.id!==null && handleBetResultChange(s.id, result)}
                                                                            copyToClipboard={copyToClipboard}
                                                                        />
                                                                    }
                                                                    {(s.multiple && s.filters) && 
                                                                        <MultipleSelector 
                                                                            multiple={stepMultiple ?? {...{...s.multiple, data: {...s.multiple.data, baNotifications: false, notifications: true}}, id: null}} 
                                                                            filters={s.filters.data.filters} 
                                                                            feesBetfair={getItemFromLocalStorage('feesBetfair') ?? '4.5'} feesBetflag={getItemFromLocalStorage('feesBetflag') ?? '5'} 
                                                                            saveMultiple={(multiple: MultipleObj) => x.id!==null && s.id!==null && !!x.userBonus?.id && saveUserMultiple(x.id, x.userBonus.id, s.id, multiple)} 
                                                                            isHidden={!stepOpened}
                                                                            readOnly={finished}
                                                                            saveResult={(result: number|null) => s.id!==null && handleBetResultChange(s.id, result)}
                                                                            copyToClipboard={copyToClipboard} 
                                                                        />
                                                                    }
                                                                    {(s.rollover && s.filters) && 
                                                                        <RolloverSelector 
                                                                            rollover={stepRollover ?? {...s.rollover, id: null}} 
                                                                            filters={s.filters.data.filters} 
                                                                            feesBetfair={getItemFromLocalStorage('feesBetfair') ?? '4.5'} feesBetflag={getItemFromLocalStorage('feesBetflag') ?? '5'} 
                                                                            saveRollover={(rollover: RolloverObj) => x.id!==null && s.id!==null && !!x.userBonus?.id && saveUserRollover(x.id, x.userBonus.id, s.id, rollover)} 
                                                                            isHidden={!stepOpened}
                                                                            readOnly={finished}
                                                                            saveResult={(result: number|null) => s.id!==null && handleBetResultChange(s.id, result)}
                                                                            copyToClipboard={copyToClipboard} 
                                                                        />
                                                                    }
                                                                </BonusStepBox>
                                                            );
                                                        })}
                                                    </Col>
                                                </Col>
                                            </Col>
                                        </SingleDivCell>
                                    </TR>
                                }
                            </Fragment>
                        );
                    })}
                </TBody>
            </Table>
        );
    }

    return(
        <Col gap='20px'>
            <Section>
                <SectionTitle>Bonus in corso</SectionTitle>
                {(!bonusesInProgress || !sites) && <LoadingMask paddingTop='20px' isCenter size='page'/>}
                {bonusesInProgress && sites && bonusesInProgress.length===0 && <NoResults>Nessun bonus trovato</NoResults>}
                {bonusesInProgress && sites && bonusesInProgress.length>0 && userBonusesTable(bonusesInProgress, false)}
            </Section>
            <Section>
                <SectionTitle>Bonus disponibili</SectionTitle>
                {(isAdmin && bonuses && (bonuses.empty || bonuses.content[0].id!==null)) && <AddButton onClick={addNewBonus}>Aggiungi Nuovo Bonus</AddButton>}
                {(!bonuses || !sites) && <LoadingMask paddingTop='100px' isCenter size='page'/>}
                {bonuses && sites && bonuses.empty && <NoResults>Nessun bonus trovato</NoResults>}
                {bonuses && sites && !bonuses.empty && bonusesTable(bonuses.content)}
                {bonuses && sites && bonuses.totalPages>1 && 
                    <TableFooter>
                        <FooterNavigationButton onClick={() => getBonuses(bonuses.number - 1)} disabled={bonuses.first}><ChevronLeftIcon /></FooterNavigationButton>
                        <FooterPagesButtonsContainer>
                            {pages.map(page => <FooterPageButton key={page} onClick={() => bonuses.number!==page && getBonuses(page)} disabled={bonuses.number===page}>{page+1}</FooterPageButton>)}
                        </FooterPagesButtonsContainer>
                        <FooterNavigationButton onClick={() => getBonuses(bonuses.number + 1)} disabled={bonuses.last}><ChevronRightIcon /></FooterNavigationButton>
                    </TableFooter>
                }
            </Section>
            
            <Section>
                <SectionTitle>Bonus conclusi</SectionTitle>
                {(!bonusesFinished || !sites) && <LoadingMask paddingTop='100px' isCenter size='page'/>}
                {bonusesFinished && sites && bonusesFinished.empty && <NoResults>Nessun bonus trovato</NoResults>}
                {bonusesFinished && sites && !bonusesFinished.empty && userBonusesTable(bonusesFinished.content, true)}
                {bonusesFinished && sites && bonusesFinished.totalPages>1 && 
                    <TableFooter>
                        <FooterNavigationButton onClick={() => getBonusesFinished(bonusesFinished.number - 1)} disabled={bonusesFinished.first}><ChevronLeftIcon /></FooterNavigationButton>
                        <FooterPagesButtonsContainer>
                            {pages.map(page => <FooterPageButton key={page} onClick={() => bonusesFinished.number!==page && getBonusesFinished(page)} disabled={bonusesFinished.number===page}>{page+1}</FooterPageButton>)}
                        </FooterPagesButtonsContainer>
                        <FooterNavigationButton onClick={() => getBonusesFinished(bonusesFinished.number + 1)} disabled={bonusesFinished.last}><ChevronRightIcon /></FooterNavigationButton>
                    </TableFooter>
                }
            </Section>
        </Col>
    );
}

export default BonusPage;