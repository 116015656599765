export const fillArrayWithIndexNumbers = (maxLength?: number | undefined): number[] => {

    const newArray: number[] = [];

    if (maxLength === undefined || typeof maxLength !== 'number') {
        return newArray;
    }

    for (let i=0; i < maxLength; i++) {
        newArray.push(i);
    }

    return newArray;
};
