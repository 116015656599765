import styled, { css } from 'styled-components';
import { BaseCardProps } from './wrappers.d';
import colors from 'style/palette/palette';
import devices from 'style/breakpoints';


/**
 * GreyCard is default BaseCard
 */
export const BaseCard = styled.div<BaseCardProps>`

    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth ?? "none"};
    /* max-width: 1360px; */

    height: fit-content;
    max-height: 800px;
    ${({maxHeight}) => maxHeight && css`max-height: ${maxHeight};`}

    border-radius: 15px;
    border: none;

    background: ${colors.darkGrey};
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    ${({ flavor }) => (flavor === 'simpleGrey') && (

        css`
            background: ${colors.darkGrey};
            box-shadow:
                0 0 0 1px ${colors.white012};
        `
    )}

    ${({ flavor }) => (flavor === 'outlined') && (

        css`
            background: ${colors.transparent};
            border: 0.5px solid ${colors.primaryColor};
        `
    )}

    ${({ flavor }) => (flavor === 'goldBorderBlue') && (

        css`
            background: ${colors.veryDarkGrey};
            border: 0.5px solid ${colors.primaryColor};
        `
    )}

    ${({ flavor }) => (flavor === 'success') && (

        css`
            background: ${colors.veryDarkGrey};
            border: 0.5px solid ${colors.green};
        `
    )}

    ${({isModal}) => isModal && (

        css`

            display: flex;
            justify-content: flex-start;

            flex-direction: column;
            
            height: 100%;
        `
    )}

    @media ${devices.tablet} {
        height: fit-content;
    }
    
    ${({height}) => height && css`height: ${height};`}

`;
