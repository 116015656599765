import { getEnvironmentProductId } from 'configs/config.helper';
import { callGET, callOldGET, callOldPOST, callOldPUT, callPOST, callPUT } from 'network/network';
import store from 'redux/store';


export const getRoadmapEvents = async () => {

    try {

        const response = await callOldGET('roadmapevent', true);
        if (response === undefined || !response) {
            throw new Error();
        }
        
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const getUsersBonusJourneyStep = async () => {

    // try {

    //     const usersBonusJourneyStep = await callOldGET('flowutentestep/user', true);
    //     if (usersBonusJourneyStep === undefined || !usersBonusJourneyStep) {
    //         throw new Error();
    //     }
        
    //     return usersBonusJourneyStep;
    // } catch (error: any) {
    //     throw error;        
    // }

    try {

        const usersBonusJourneyStep = await callGET('v1/self/steps', true);
        if (usersBonusJourneyStep === undefined || !usersBonusJourneyStep) {
            throw new Error();
        }
        
        return usersBonusJourneyStep;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const getMaxSteps = async () => {

    try {

        const bonusJourneySteps = await callGET('v1/self/steps/maxstep', true);
        if (bonusJourneySteps === undefined || !bonusJourneySteps) {
            throw new Error();
        }
        
        return bonusJourneySteps;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const getUsersWarnings = async () => {

    try {

        // const usersWarnings = await callOldGET('flowutentewarningbyuser/user', true);

        // warning responds undefined (no response) if no warning are stored
        // if (usersWarnings === undefined || !usersWarnings) {        
        //     throw new Error();
        // }
        const usersWarnings = await callGET('v1/self/warnings', true);

        return usersWarnings;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const setUsersWarnings = async (warningId: string) => {

    try {
        const setWarning = await callOldPUT(`flowutentewarningbyuser/user/${warningId}`, {}, true);

        if (setWarning === undefined || !setWarning) {
            throw new Error();
        }
        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getUsersCourseList = async () => {

    try {

        const usersCourseList = await callOldGET('user/getCourseList', true);
        if (usersCourseList === undefined || !usersCourseList) {
            throw new Error();
        }
        
        return usersCourseList;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const getUsersActiveServicesList = async () => {

    try {
        const brandCode = store.getState().ui.brand?.code;
        const usersActiveServicesList = await callGET(`v1/self/products?brandCode=${brandCode}&types=service`, true);        
        
        if (usersActiveServicesList === undefined || !usersActiveServicesList) {
            throw new Error();
        }
        
        return usersActiveServicesList;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

export const checkOfflinePayments = async () => {

    try {

        const checkPayment = await callOldGET('user/checkPayment', true);
        if (checkPayment === undefined || !checkPayment) {
            throw new Error();
        }
        
        return checkPayment;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

// export const stakeLibertyPoints = async () => {

//     try {

//         const usersBonusJourneyStep = await callOldPOST('stacking/addStacking', null, true);
//         if (usersBonusJourneyStep === undefined || !usersBonusJourneyStep) {
//             throw new Error();
//         }
        
//         return usersBonusJourneyStep;
//     } catch (error: any) {
    // if (error.message.includes('403')) throw new Error ('403');
//         throw error;        
//     }
// };

export const stakeLibertyPoints = async () => {

    try {

        const response = await callPUT('v1/self/pools/stacking/add', {}, true);
        // if (response === undefined || !response) {
        //     throw new Error();
        // }
        
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

// export const unstakeLibertyPoints = async () => {

//     try {

//         const usersBonusJourneyStep = await callOldPOST('stacking/removeStacking', null, true);
//         if (usersBonusJourneyStep === undefined || !usersBonusJourneyStep) {
//             throw new Error();
//         }
        
//         return usersBonusJourneyStep;
//     } catch (error: any) {
    // if (error.message.includes('403')) throw new Error ('403');
//         throw error;        
//     }
// };

export const unstakeLibertyPoints = async () => {

    try {

        const response = await callPUT('v1/self/pools/stacking/remove', {}, true);
        // if (response === undefined || !response) {
        //     throw new Error();
        // }
        
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;        
    }
};

// export const getUserLibertyPoints = async () => {

//     try {
//         const response = await callOldGET('user/getPuntiLiberta', true);

//         return response;
//     } catch (error: any) {
    // if (error.message.includes('403')) throw new Error ('403');
//         throw error;
//     }
// };

export const getUserPools = async () => {

    try {
        const response = await callGET('v1/self/pool', true);

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getUserLibertyPoints = async () => {

    try {
        const response = await getUserPools();

        const personalPool = response.filter((pool: any) => pool.type === 'PERSONAL');
        return personalPool[0].availableBalance;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getUserStakedLibertyPoints = async () => {

    try {
        const response = await getUserPools();

        const stakingPool = response.filter((pool: any) => pool.type === 'STACKING');
        return stakingPool[0].availableBalance;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getUserVipPoints = async () => {

    try {
        const response = await callGET('v1/self/vippoints', true);

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getVipStandings = async () => {

    try {
        const response = await callGET('v1/vippoints/standings', true);
        if (!response || response === undefined) throw new Error("ERROR: Unable to get vip points standing.");

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const setPersonalAssistant =async (coachId: number | string) => {
     
    const highwayParams = {
        brandCode: store.getState().ui.brand?.code
    };

    try {

        const response = await callGET(`v1/self/productsubscriptions/products/${getEnvironmentProductId('autostrada')}`, true, highwayParams);
        await callPUT(`v1/self/productlessonpacks/subscriptions/${response[0].id}/assign/${coachId}`, {}, true);
     } catch (error: any) {

        if (error.message.includes('403')) throw new Error ('403');
        throw error;    
     }
};

export const getWhatsAppCallLessons = async () => {

    try {
        
        const addFreeCall = await callGET('v1/self/productsubscriptions/activehelp', true);
        console.log("LEZIONE IN SERVICE", addFreeCall);
        
        return addFreeCall;            
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};
