import styled, { css } from "styled-components";

// asset
import colors from "style/palette/palette";
import ButtonShop from "components/ButtonShop/ButtonShop.component";
import devices from "style/breakpoints/breakpoints";


export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 20px;
`;
    
export const TitleBox = styled.div`

    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 10px 20px 20px;
`;

export const TitleB = styled.h2`

    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 0px;
    padding: 0 0 0 10px;

    font-weight: 400;
    font-size: 20px;

    color: ${colors.white};

    svg {
        margin: 3px 0 0 15px;
    }
`;

export const FirstTwo = styled.div`
    
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0px;
    gap: 40px;

    width: 100%;
`;

export const MediaGrid = styled.div`

    box-sizing: border-box;

    overflow: visible;

    width: 100%;
    /* height: 400px; */

    /* border-radius: 15px; */
`;

export const VideoImg = styled.img`

    width: 100%;
`;

export const CardPrice = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* flex-shrink: 0; */
    /* align-self: stretch; */

    padding: 0;
    
    width: 100%;
    /* width: 620px; */
    /* max-width: 690px; */
    /* flex-shrink: 0; */
    
    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 15px 15px 0 0;
`;

export const CardContent = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px;
`;

export const InfoPrice = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 30px 25px;
`;

export const TitleCard = styled.h2`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0;
    padding: 0px;
    
    font-weight: 400;
    font-size: 20px;
    /* line-height: 35px; */
    
    color: ${colors.primaryColor};
`;

export const TextCheck = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 30px;
    
    width: 100%;
    padding: 0px 16px;
    margin: 0px;

    > svg {
        margin: 0 17px 0 0;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
    }
`;

export const TextP = styled.p<{noMargin?: boolean, notCenter?: boolean}>`
    
    max-width: 900px;
    padding: 0px;
    margin: 20px 0 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: ${({notCenter}) => notCenter ? 'left' : 'center'};

    color: ${colors.white};

    span {
        color: ${colors.primaryColor}
    }

    ${({noMargin}) => noMargin && 
    
        css`
            margin: 0;
        `
    }
`;

// export const ButtonP = styled.button`

//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     align-self: center;

//     margin: 20px 0 0;
//     padding: 4px 15px;

//     border: none;
//     box-shadow: none;

//     font-weight: 500;
//     font-size: 13px;
//     letter-spacing: 0.46px;

//     background: ${colors.primaryColor};

//     border-radius: 4px;
//     box-shadow: 
//         0px 3px 1px -2px ${colors.black020}, 
//         0px 2px 2px ${colors.black014}, 
//         0px 1px 5px ${colors.black012}
//         ;
// `;

export const SecondaCard = styled.div`

    display: flex;
    align-items: center;
    
    flex-direction: column;

    margin: 0;
    padding: 0 35px;
    width: 100%;

    background: ${colors.darkGrey};
    color: ${colors.white};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
        ;
    border-radius: 0 0 15px 15px;

    /* h1 {

        margin: 0;
        padding: 0;

        font-size: 48px !important;
        font-weight: 700 !important;
        color: ${colors.primaryColor} !important;
    }
    h2 {

        margin: 0;
        padding: 0;

        font-size: 38px !important;
        font-weight: 600 !important;
        color: ${colors.primaryColor} !important;
    }
    h3 {

        margin: 0;
        padding: 0;

        font-size: 30px !important;
        font-weight: 500 !important;
        color: ${colors.primaryColor} !important;
    }
    h4 {

        margin: 0;
        padding: 0;

        font-size: 25px !important;
        font-weight: 400 !important;
        color: ${colors.primaryColor} !important;
    }
    h5 {

        margin: 0;
        padding: 0;

        font-size: 20px !important;
        font-weight: 400 !important;
        color: ${colors.primaryColor} !important;
    }
    h6 {

        margin: 0;
        padding: 0;

        font-size: 18px !important;
        font-weight: 400 !important;
        color: ${colors.primaryColor} !important;
    }
    p {

        margin: 0;
        padding: 0;

        color: ${colors.white} !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 25px !important;

        > span {
            color: ${colors.white} !important;
            font-size: 15px !important;
            font-weight: 400 !important;
            line-height: 25px !important; 
        }
    }
    em {

        margin: 0;
        padding: 0;

        color: ${colors.white} !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 25px !important;
        font-style: italic !important;
    } */
`;

export const BulletBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    align-self: center;
    flex-direction: column;
    
    margin: 15px 0 0;
`;

export const TextBulllet = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    /* padding: 4px 0px 4px 0px; */
    margin: 0px;

    font-style: italic;

    > svg {
        margin: 0 9px 0 0;
        flex-shrink: 0;
        width: 17px;
        height: 17px;
    }
`;

export const ButtonBox = styled.div<{paddingTop?: string, paddingBottom?: string}>`

    align-self: center;
    min-width: 250px;
    max-width: 330px;

    margin: 10px 0 0;

    padding-top: ${({paddingTop}) => paddingTop ?? '0'};
    padding-bottom: ${({paddingBottom}) => paddingBottom ?? '0'};
`;

export const BlockText = styled.div`

    width: 100%;
    text-align: center;
`;

export const BlockImg = styled.img<{isInGroup?: boolean | undefined}>`

    width: 100%;
    /* max-width: 375px; */

    ${({isInGroup}) => isInGroup && (

        css`
            width: 32%;
        `
    )}
`;

export const BlockRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

`;

export const BlockGroup = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
    flex-wrap: wrap;

    width: 100%;
`;

export const ProductLimitationRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;
`;

export const LimitedSlotBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

`;

export const LimitedTimeBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    color: ${colors.white};

    span {
        
        margin: 0;
        padding: 0;

        color: ${colors.red};
        font-weight: 500;
        font-size: 17px;
    }
`;

export const LimitedSlots = styled.h4`

    margin: 0;
    padding: 0;

    color: ${colors.primaryColor};
    font-size: 25px;
    font-weight: 700;
`;

export const LimitationDisclaimer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    
    margin: 0;
    padding: 0;

    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    
    > span {
        color: ${colors.red};
        font-weight: 600;
        font-size: 21px;
    }
`;

export const CarouselBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 30px 0 0;
`;

export const ThreeBlockRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    margin: 40px 0 0;
`;

export const BlockItem = styled.div<{isCentral?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    /* padding: 0 35px; */
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;

    h3 {

        font-size: 20px;
        font-weight: 400;
        color: ${colors.white050};
    }

    svg {}

    span {
        color: ${colors.primaryColor};
    }

    ${({isCentral}) => isCentral && 

        css`
        
            margin: 20px 0;
            padding: 20px 0;

            border-top: 1px solid ${colors.grey};
            border-bottom: 1px solid ${colors.grey};
        `
    }
`;

export const IMGItem = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 30px;

    width: 100%;
    max-width: 900px;
    
    margin: 20px 0 0;
`;

export const IMGBox = styled.div`

    flex-shrink: 0;

    width: 100%;
    /* height: 170px; */

    background: ${colors.transparent};
    border-radius: 15px;

    img {

        width: 100%;
        border-radius: 10px;
    }
`;

export const IMGDescription = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const VideoCourseListBox = styled.div`
    margin: 50px 0 0;
`;
