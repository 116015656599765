// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { getCourseWithBrand, getPrivateInfoBroadcast } from '../services/ThematicDirectCatalogue.services';
import { useNavigate, useParams } from 'react-router-dom';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import { useCopyToClipboard } from 'usehooks-ts';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import useAppDispatch from 'hooks/useAppDispatch';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// components
import HorizontalSlider from 'components/HorizontalSlider/HorizontalSlider.component';
import { ReactComponent as PuntiLibertaBSVG } from './assets/PuntiLibertàB.svg';

// assets
import { ReactComponent as CopyLinkSVG } from './assets/linkcopy.svg';
import { ReactComponent as PuntiLibertaSVG } from './assets/libertyPoint.svg';
import { ReactComponent as ChatSVG } from './assets/Chat.svg';
import { ReactComponent as ChatGraySVG } from './assets/chatgrey.svg';

// styles
import { 
    ButtonBox,
    ButtonTipSelection,
    ChatSelector,
    DescriptionText,
    DirectSelector,
    Donation,
    DonationButtonBox,
    DonationButtonText,
    DonationInfo,
    DonationInfoText,
    DonationRegulation,
    DonationSelection,
    DonationSelectionButton,
    DonationSelector,
    EmbededChat,
    LibertyValue,
    MainPage,
    TitlePage,
    TitleText,
    TotalPage,
    VideoBox,
    VideoDonation, 
} from "./style/ThematicDirectPastPage.style";
import LoadingMask from 'components/ui/LoadingMask';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import ButtonShopStaking from 'components/ButtonShopStaking/ButtonShopStaking.component';
import MediaPlayer from 'components/MediaPlayer';
import useBrand from 'hooks/useBrand';




const ThematicDirectPastPage = () => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [selectionLiberty, setSelectionLiberty] = useState <number>(1);
    const [selectedButton, setSelectedButton] = useState<number>(0);
    const navigate = useNavigate();
    const [thematicBroadcastProducts, setThematicBroadcastProducts] = useState<any[]>([]);
    const [thematicBroadcastPrivateProducts, setThematicBroadcastPrivateProducts] = useState<any>();
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();

    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };
    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const onSliderChangeHandler = (newValue: number) => setSelectionLiberty(newValue);

    const onClickSelectionPoint = (amount: number) => {
        const newSelectedValue = selectionLiberty + amount;

        const cappedValue = Math.min(newSelectedValue, 100);

        setSelectedButton(cappedValue);
        setSelectionLiberty(cappedValue);
    };

    const getProducts = async () => {
        
        try {

            const response = await getCourseWithBrand();

            if (response) {

                const thematicBroadcastProducts = response.filter((product: any) => ((product.type === "THEMATIC_BROADCAST")))
                console.log("DIRETTE:", thematicBroadcastProducts);

                setThematicBroadcastProducts(thematicBroadcastProducts);
            }

        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {
        
        getProducts();
    },[useBrand()])

    const { productId } = useParams();
    const productData = thematicBroadcastProducts.find(product => product.id === Number(productId));
    const requisites = productData ? productData.requisitesSatisfied : true ;
    const id = productData ? productData.id : 0;

    console.log("PRODUCTDATA :", productData)

    const description = productData ? productData.productThematicCall.description : '';

    const getMyProducts = async () => {
        
        try {
            
            const response = await getPrivateInfoBroadcast(productId!);
            
            if (response) {
                const thematicBroadcastPrivateProducts = response;
                
                setThematicBroadcastPrivateProducts(thematicBroadcastPrivateProducts);
            }
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {

        getMyProducts();
       
    },[productId, useBrand()])

    
    const privateData = (thematicBroadcastPrivateProducts !== undefined) ? thematicBroadcastPrivateProducts.productThematicCall.registrationLink.url : '' ;

    if (thematicBroadcastPrivateProducts === undefined) return (<LoadingMask/>)

    return (
        <>
            {isAppMobile && 
                <VideoBox>
                    <MediaPlayer isAppMobile={true} maxWidth="none" media={{src: `${privateData}`, type: 'VIDEO/MP4'}} />
                </VideoBox>            
            }

            <TotalPage>
                <TitlePage>
                    <TitleText>
                        <h1>{productData ? productData.name : ''}</h1>
                    </TitleText>
                </TitlePage>

                {isAppMobile && 
                    <MainPage>
                        <Donation>
                            <DonationInfo>
                                <DonationInfoText>
                                    <p>FAI UNA DONAZIONE:</p>

                                    <h3>{selectionLiberty} €</h3>

                                    <PuntiLibertaBSVG/>
                                </DonationInfoText>

                                <DonationSelection>
                                    <DonationSelectionButton>
                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(1)}
                                            className={selectedButton === 1 ? 'selected' : ''}
                                        >
                                            <p>+ 1 €</p>
                                        </ButtonTipSelection>

                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(3)}
                                            className={selectedButton === 3 ? 'selected' : ''}
                                        >
                                            <p>+ 3 €</p>
                                        </ButtonTipSelection>

                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(10)}
                                            className={selectedButton === 10 ? 'selected' : ''}
                                        >
                                            <p>+ 10 €</p>
                                        </ButtonTipSelection>
                                    </DonationSelectionButton>

                                    <DonationRegulation>
                                        <HorizontalSlider 
                                            minValue={1} 
                                            maxValue={100} 
                                            startingValue={selectionLiberty}
                                            step={1}
                                            onChange={onSliderChangeHandler}
                                        />
                                    </DonationRegulation>
                                </DonationSelection>
                            </DonationInfo>

                            <DonationButtonBox>
                                <ButtonShopStaking
                                    PriceProduct={selectionLiberty * 1.22} 
                                    TypeOfProduct={'DONATION'} 
                                    courseElement={requisites} 
                                    courseID={id} 
                                    payments={'Punti Libertà'} 
                                    onTransferClick={transferModalHandler}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    productIdHandler={libertyProductID}
                                    productPriceHandler={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    isDonationPage
                                />
                            </DonationButtonBox>

                            <DescriptionText>
                                <p dangerouslySetInnerHTML={{ __html: description }}/>
                                {/* <a href='https://liberidalavoro.it/app/public/product/3'>https://liberidalavoro.it/app/public/product/3</a> */}
                            </DescriptionText>
                        </Donation> 
                   </MainPage> 
                }
            

                {!isAppMobile &&
                    <>
                        <MainPage>
                            <VideoDonation>
                                <VideoBox>
                                    <MediaPlayer maxWidth="none" media={{src: `${privateData}`, type: 'VIDEO/MP4'}} />
                                </VideoBox>

                                <Donation>
                                        <DonationInfo>
                                            <DonationInfoText>
                                                <p>FAI UNA DONAZIONE:</p>

                                                <h3>{selectionLiberty}</h3>

                                                <PuntiLibertaBSVG/>
                                            </DonationInfoText>

                                            <DonationSelection>
                                                <DonationSelectionButton>
                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(1)}
                                                        className={selectedButton === 1 ? 'selected' : ''}
                                                    >
                                                        <p>+ 1 </p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>

                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(3)}
                                                        className={selectedButton === 3 ? 'selected' : ''}
                                                    >
                                                        <p>+ 3</p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>

                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(10)}
                                                        className={selectedButton === 10 ? 'selected' : ''}
                                                    >
                                                        <p>+ 10</p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>
                                                </DonationSelectionButton>

                                                <DonationRegulation>
                                                    <HorizontalSlider 
                                                        minValue={1}
                                                        maxValue={100}
                                                        startingValue={selectionLiberty}
                                                        step={1}
                                                        onChange={onSliderChangeHandler}
                                                    />
                                                </DonationRegulation>
                                            </DonationSelection>
                                        </DonationInfo>

                                        <DonationButtonBox>
                                            <ButtonShopStaking
                                                PriceProduct={selectionLiberty * 1.22} 
                                                TypeOfProduct={'DONATION'} 
                                                courseElement={requisites} 
                                                courseID={id} 
                                                payments={'Punti Libertà'} 
                                                onTransferClick={transferModalHandler}
                                                onLibertyClick={libertyModalHandler}
                                                openLibertyPaymentModal={openLibertyPaymentModal}
                                                productIdHandler={libertyProductID}
                                                productPriceHandler={libertyProductPrice}
                                                loadingOn={loadingOn}
                                                loadingOff={loadingOff}
                                                isDonationPage
                                            />
                                        </DonationButtonBox>
                                    </Donation>
                                        
                            </VideoDonation>

                           {description !== ('' || null) &&
                                <DescriptionText>
                                    <p dangerouslySetInnerHTML={{ __html: description }}/>
                                </DescriptionText>
                            }
                        </MainPage>
                    </>
                }
            </TotalPage>

            {isLibertyPaymentModalVisible &&
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={selectionLiberty * 1.22}
                    productType="Donation"
                    isDonationPage
                />
            }
        </>
    );
};

export default ThematicDirectPastPage;