export const createArrayForMappingUse = (numberOfItems: number) => {

    const supportArray = [];
    
    for (let i=0; i < numberOfItems ; i++) {
        supportArray.push(i);
    }
    
    return supportArray;
};
