import styled from 'styled-components';
import colors from 'style/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';


export const HighwayBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 430px;
    
    border-radius: 15px;
    box-shadow:
    0px 3px 1px -2px ${colors.black020},
    0px 2px 2px ${colors.black014},
    0px 1px 5px ${colors.black012};
    background-color: ${colors.darkGrey};
    
    @media ${devices.tablet} {
        max-height: 145px;
    }
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    width: 100%;

    padding: 25px 20px;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const CTABox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
`;

export const CardTitle = styled.h3`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.white};
    text-align: center;
`;

export const BoldText = styled.span`

    font-weight: 700;
`;

export const HighwayButton = styled(Button)``;
