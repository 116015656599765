import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';



export const TotalPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    padding: 30px 20px 20px 20px;

    @media ${devices.tablet} {
        padding: 40px;
        gap: 20px;
    }
`;

export const ShopOperationBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    margin: 0;

    @media ${devices.tablet} {

        flex-direction: row;
        
        padding: 0 0 0 20px;
    }
`;

export const ShopTitleBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;

    padding: 0 0 20px;
    width: 100%;

    gap: 10px;

    @media ${devices.tablet} {

        padding: 0;

    }
`;

export const ShopTitle = styled.div`

    font-weight: 400;
    font-size: 17px;

    color: rgba(255, 255, 255, 0.7);

    cursor: pointer;

    a {
        
        text-decoration: none;
        color: inherit;
    }
    @media ${devices.tablet} {

        font-size: 28px;
    }
`;

export const TypeProductTitle = styled.p`

    font-weight: 400;
    font-size: 13px;

    color: ${colors.white};

    @media ${devices.tablet} {

        font-size: 22px;
    }
`;

export const ProductMainInteractionBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 25px 0 0;

    flex-direction: column;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;

        gap: 25px;
        
        width: auto;

        margin: 0;
    }
`;

export const FilterProductBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    gap: 20px;

    @media ${devices.tablet} {
        
        padding: 0;
    }
`;

export const ProductFilterBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0;

    gap: 7px;
`;

export const FilterText = styled.p`

    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 14px;
    text-align: center;

    color: ${colors.white};

    white-space: nowrap;

    @media ${devices.tablet} {

    }
`;

export const ShopInteractionBox = styled.div`

    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 0 20px;

    width: 100%;

    gap: 20px;

    @media ${devices.tablet} {

        justify-content: flex-end;

        padding: 0;
    }
`;

export const SearchBarBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    border: 1px solid ${colors.white};
    border-radius: 100px;
    height: 29px;
    padding: 12px;

    @media ${devices.tablet} {

        max-width: 180px;
        min-width: 180px;
    }
`;

export const SearchBar = styled.input`

    text-align: center;
    font-style: italic;

    width: 100%;
    height: 29px;
    padding: 12px;

    background-color: transparent;

    border: none;

    outline: none;

    color: white;
`;

export const CleanBox = styled.div`

    display: flex;
`;

export const MainCatalogue = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    gap: 30px;
    padding: 30px 25px;
    border-radius: 15px;

    background: ${colors.darkGrey};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014},
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);

    @media ${devices.tablet} {
        padding: 40px;
    }
`;

export const NewDirect = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    padding-bottom: 25px;

    flex-wrap: wrap;

    gap: 25px;

    @media ${devices.tablet} {

        column-gap: 40px;
        row-gap: 30px;
    }
`;

export const ReservedDirect = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    padding-bottom: 25px;

    flex-wrap: wrap;

    gap: 25px;

    @media ${devices.tablet} {

        column-gap: 40px;
        row-gap: 30px;
    }
`;

export const SeparatorDirect = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    padding: 15px 0px;
    gap: 10px;
    width: 100%;


    border-bottom: 1px solid ${colors.grey};
`;

export const TitleSeparator = styled.h3`
    color: rgba(255, 255, 255, 0.50);

    font-size: 17px;
    font-style: italic;
    font-weight: 500;
    line-height: 16px; 

    margin: 0;
    padding: 0;
`;

export const PastDirect = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    column-gap: 40px;
    row-gap: 30px;
    
    width: 100%;

`;

export const FilterBox = styled.div`
    display: flex;
    align-items: flex-end;
    align-self: stretch;

    width: 100%;

    gap: 25px;
    padding: 7px 0px 22px 10px;

    @media ${devices.tablet} {
        padding: 0px;
    }

`;