import { FC, useState } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import {
    channelLastMessageDate,
    channelMessageText
} from './Channel.helper';

//asset
import { ReactComponent as ChannelNoIconSVG } from './asset/channelNoIcon.svg';

// style
import { 
    Chat,
    Notification,
    Identifyer,
    Information,
    Name,
    Hour,
    Message,
    Notnumber,
    Number
} from './style/Channel.style';
import { saveMessagesNotSeen } from 'redux/actions/actions-user';
import useAppSelector from 'hooks/useAppSelector';


const Channel: FC<{channelData: any, onChannelClick: (channelId: number) => void}> = ({ channelData, onChannelClick }) => {

    const { messagesNotSeen: initialMessagesNotSeen } = useAppSelector(state => state.user)
    const { channel, messagesNotSeen, lastMessage } = channelData;
    const { icon, name: channelName, id: channelId } = channel;
    const lastMessageContent = channelMessageText(lastMessage);
    const lastMessageDate = channelLastMessageDate(lastMessage);
    // const { url: channelImageUrl } = icon;
    const channelImageUrl = icon && icon.url ? icon.url : '/default-icon.png';

    const [unreadMessages, setUnreadMessages] = useState(messagesNotSeen);
    const dispatch = useAppDispatch();

    const onClickHandler = () => {
        onChannelClick(channelId);
        setUnreadMessages(0);
        if (initialMessagesNotSeen) dispatch(saveMessagesNotSeen(initialMessagesNotSeen - unreadMessages));
    };
    
    return (
        <Chat onClick={onClickHandler}>
            <Identifyer src={channelImageUrl} /> 
                {!channelImageUrl && (
                <Identifyer>
                    <ChannelNoIconSVG />
                </Identifyer>
            )}

            <Notification>
                <Information>
                    <Name>
                        {channelName}
                    </Name>
                    <Hour>
                        {lastMessageDate}
                    </Hour>
                </Information>

                <Information>
                    <Message dangerouslySetInnerHTML={{__html: lastMessageContent}} />

                    {(messagesNotSeen  !== 0) && (
                        <Notnumber>
                            <Number>
                                {messagesNotSeen} 
                                {/* {unreadMessages} */}
                            </Number>
                        </Notnumber>
                    )}
                </Information>
            </Notification>
        </Chat>
    );
};

export default Channel;
