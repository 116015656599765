import { FC } from 'react';

//routing
import { Link } from 'react-router-dom';

// utils
import { getEnvironmentAssetLinkUrl } from 'configs/config.helper';

//asset
import genericBoxIcon from '../MyCourse/asset/genericBoxIcon.png';

//style
import {
    MyCourse,
    Title,
    ImageCourse,
    ContentCourseBorder
} from './style/MyCourse.style';

const CourseBox: FC<{courseElement: any}> = ({courseElement}) => {

    const {sellImage, name, id: productId} = courseElement;

    return (
        <Link to={`/reservedArea/product-page/course/${productId}`} state={{productId}}>
            <ContentCourseBorder>
                <MyCourse>
                    {sellImage && sellImage.url && <ImageCourse alt="course" src={sellImage.url} />}

                    {/* {(!sellImage || !sellImage.url) && <EmptyImage isProductBox />} */}
                    {(!sellImage || !sellImage.url) && <ImageCourse alt="placeholder" src={getEnvironmentAssetLinkUrl(genericBoxIcon)} />}


                    <Title>{name}</Title>
                </MyCourse>
            </ContentCourseBorder>
        </Link>
    )
};

export default CourseBox;