import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useIsMounted } from 'usehooks-ts';

// types
import { CustomerRatingModalProps } from './CustomerRatingModal';

// utils
import { closeCurrentLesson } from './CustomerRatingModal.helper';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import RatingStars from './RatingStars/RatingStars.component';

// assets
import { ReactComponent as CompleteSVG } from './assets/complete.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    TextDisclaimer,
    Title,
    CommentBox,
    SendRatingButton
} from './style/CustomerRatingModal.style';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import useAppDispatch from 'hooks/useAppDispatch';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { timeout } from 'd3';


/**
 * Customer rating modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for setting the states on the closure of the modal 
 * 
 * @returns JSX
 */


const CustomerRatingModal: FC<CustomerRatingModalProps> = ({ onCloseButtonModal, isHighway, lessonId, refreshFunction }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [starsRating, setStarsRating] = useState<number>(-1);
    const [customerRating, setCustomerRating] = useState<string | undefined>(undefined);
    const [hasToRefresh, setHasToRefresh] = useState<boolean | undefined>(undefined);
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    
    const closeHighwayLesson = async ( lessonId: number, rating: number, ratingComments: string | undefined ) => {
        
        const snackbarData: SnackbarOptions = {};

        try {
            const closeLessonResponse = await closeCurrentLesson( lessonId, rating, ratingComments);
            if (!isMounted) return;

            // if (closeLessonResponse === undefined || !closeLessonResponse) {
            //     throw new Error ("Close Lesson response in undefined.");
            // }
            
            snackbarData.type = 'success';
            snackbarData.message = "Lezione chiusa con successo.";
            dispatch( addSnackbar(snackbarData) );
        } catch (error: any) {

            console.warn("Unable to Close Lesson.");
            // snackbarData.type = 'error';
            // snackbarData.message = "Non è stato possibile chiudere la lezione, riprova più tardi.";
            // dispatch( addSnackbar(snackbarData) );
        }
    };
    
    const closeSingleLesson = async () => {
        
        const snackbarData: SnackbarOptions = {};

        if (lessonId === undefined || !lessonId) {
            throw new Error ("No lesson ID found.");
        };

        try {
            const closeLessonResponse = await closeCurrentLesson(lessonId);
            if (!isMounted) return;

            // if (closeLessonResponse === undefined || !closeLessonResponse) {
            //     throw new Error ("Close Lesson response in undefined.");
            // }

            snackbarData.type = 'success';
            snackbarData.message = "Lezione chiusa con successo.";
            dispatch( addSnackbar(snackbarData) );
        } catch (error: any) {

            console.warn("Unable to Close Lesson.");
            snackbarData.type = 'error';
            snackbarData.message = "Non è stato possibile chiudere la lezione, riprova più tardi.";
            dispatch( addSnackbar(snackbarData) );
        }
    };
    
    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const starsRatingHandler = (rating: number) => setStarsRating(rating);

    const customerCommentChangeHandler = (event: any) => {
        setCustomerRating(event.target.value);
    };
 
    const closeLessonHandler = async () => {

        if (isHighway) {

            if ((starsRating === -1) || !customerRating) {
                return
            };

            if (!lessonId) {
                return
            };
            
            await closeHighwayLesson(lessonId, starsRating, customerRating);
            setIsCloseButtonClicked(true);
            if (refreshFunction) setTimeout(() => refreshFunction(), 1000);
            return;
        }
        
        await closeSingleLesson();
        setIsCloseButtonClicked(true);
        if (refreshFunction) refreshFunction();
        return 
    };

    useEffect(() => {
        if (!hasToRefresh) return;
        if (!refreshFunction) return;

        window.location.reload();
        // const timeout = setTimeout(() => refreshFunction(), 10000);
        // setHasToRefresh(false);
        // return () => clearTimeout(timeout);
    }, [hasToRefresh, refreshFunction]);
    
    return (
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
        {!isAppMobile && (
            <Row 
                padding='0 21px 12px 0'
                crossAxis='center'
                mainAxis='flex-end'
            >
                <CloseButton onClick={closeButtonHandler}>
                    <CloseSVG />
                </CloseButton>
            </Row>
        )}

        <BaseCard 
            flavor='outlined'
            isModal
        >
            <CardContent>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <IconBox>
                    <CompleteSVG />
                </IconBox>

                <Title>
                    {isHighway && `COMPLIMENTI LEZIONE TERMINATA!`}

                    {!isHighway && `COMPLIMENTI, LA TUA LEZIONE STA PER INIZIARE!`}
                </Title>

                <TextContent>
                    <TextDisclaimer>
                        {isHighway && `Per poter prenotare una nuova lezione devi prima chiudere questa.`}

                        {!isHighway && (
                            `Il Tuo Assistente Personale ti guiderà nei 
                            primi passi da fare per iniziare SUBITO a guadagnare.`
                        )}
                    </TextDisclaimer>
                    
                    <br />
                    
                    {isHighway && `Per chiudere la lezione basta valutarla tramite le stelle e lasciare un commento:`}
                </TextContent>

                {isHighway && (
                <>
                    <RatingStars 
                        numberOfStars={5}
                        currentRating={starsRating}
                        ratingHandler={starsRatingHandler}
                    />

                    <CommentBox 
                        placeholder='Scrivi un commento...' 
                        value={customerRating}
                        onChange={customerCommentChangeHandler}
                    />

                    <SendRatingButton 
                        onClick={closeLessonHandler}
                        disabled={(starsRating === -1) || !customerRating}    
                    >
                        INVIA E CHIUDI LA LEZIONE
                    </SendRatingButton>
                </>
                )}

                {!isHighway && 
                <SendRatingButton 
                    onClick={closeLessonHandler}
                >
                    INIZIA LA LEZIONE (!)
                </SendRatingButton>
                }
            </CardContent>
        </BaseCard>
    </BaseModal>
    );
};

export default CustomerRatingModal;
