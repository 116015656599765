import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const ServiceContent = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    flex-direction: column;
    
    padding: 0px 0px 10px;

    gap: 12px;

    border-bottom: 1px solid ${colors.grey};
    width: 100%;

    @media ${devices.tablet} {
}
`;

export const ServiceElipsisBox = styled.div`
    
    overflow: hidden;
`;

export const ServiceName = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 15px;
    min-height: 37px;

    color: ${colors.white};

    /* max-width: 13ch;

    white-space: nowrap; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
`;

export const ServiceDescriptionBox = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    padding: 0;
    margin: 10px 0 0;

    @media ${devices.tablet} {
}
`;

export const ServiceInfoBox = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;

    max-width: 230px;

    @media ${devices.tablet} {
}
`;

export const ServiceStatusMainBox = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    padding: 0px;

    @media ${devices.tablet} {
}
`;

export const ServiceStatusBox = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 0px;

    gap: 6px;

    @media ${devices.tablet} {
}
`;

export const ServiceGuideButton = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;

    width: 25px;
    height: 23px;

    background: ${colors.grey};
    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
    border-radius: 5px;

    cursor: pointer;
`;

export const ServiceSelectorBox = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;

    border-radius: 5px;
    border: none;

    cursor: pointer;
`;

export const ServiceStatusText = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 13px;

    color: ${colors.red};

    @media ${devices.tablet} {
}
`;

export const ServiceDeadlineDate = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 13px;

    color: ${colors.white};

    @media ${devices.tablet} {
}
`;

export const ReactivationServiceBox = styled.div`

    display: flex;
    justify-content: flex-end;
    align-self: flex-end;

    width: 100%;
`;