import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import {useSwipeable} from 'react-swipeable'

// types
import { TestimonialCardProps } from './ImageCard.d';

//components
import VideoPlayerLight from 'components/VideoPlayerLight';

// style
import {
    TestimonialWrapper,
    VideoCard,
    DarkOverlay,
} from "./style/ImageCard.style";


/**
 * Testimonial card
 * 
 * @author Marco Braglia
 * 
 * 
 * @returns JSX
 */

const backgroundUrl = 'https://viveredipoker.it/2021_Testimonianze/testimonialProfilePicBackground.webp'

const TestimonialCard: FC<TestimonialCardProps> = ({
    testimonial,
    cIndex,
    inCarousel,
    isTestimonialImg,
    handleClickCard,
    onSwipe,
    setIsVideoPaused,
    onClickHandler
}) => {

    const [opacity, setOpacity] = useState(onSwipe !== undefined ? 0 : 1);

    const { isAppMobile } = useAppSelector( state => state.ui);

    const onCardClickHandler = () => {
        if (handleClickCard && title && cIndex) handleClickCard(title, cIndex)
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (onSwipe)onSwipe('left');
        },
        onSwipedRight: () => {
            if (onSwipe) onSwipe('right');
        }});

    
    useEffect(() => {        
        setTimeout(() => {
            setOpacity(1);
          }, 50);
        return () => setOpacity(0);
    }, []);

    // Handle null/undefined properties using optional chaining and default values
    const title = testimonial?.title ?? '';
    const description = testimonial?.description ?? '';

    return (
        <TestimonialWrapper
            key={description}
            opacity={opacity}
            inCarousel={inCarousel} 
            cIndex={cIndex} 
            onClick={onCardClickHandler}
            {...swipeHandlers} // add swipe handlers
        >
            {cIndex !== undefined && !isAppMobile && <DarkOverlay cIndex={cIndex} /> }
            <VideoCard inCarousel={inCarousel}>
                <img src={testimonial.imgSRC} alt='Carousel pic' onClick={() => onClickHandler!(testimonial.imgSRC)}/>
            </VideoCard>

        </TestimonialWrapper>
    );


};

export default TestimonialCard;
