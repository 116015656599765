import { getEnvironmentProductId } from 'configs/config.helper';
import { callPUT } from 'network/network';


export const updatePhoneNumber = async (phoneNumber: string) => {
    try {
        const response = await callPUT(`v1/users/self/phonenumber?value=${phoneNumber}`, {}, true);
        return response;
    } catch (error) {
        throw error;
    }
};