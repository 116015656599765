import { FC, useEffect, useMemo } from 'react';

//routing
import { Outlet, useLocation } from "react-router-dom";

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//components
import Selector from 'components/Selector/Selector.component';
import Timer from 'components/Timer/Timer.component';

//assets
import { ReactComponent as CalculatorIconSVG } from './assets/calculator.svg';
import { ReactComponent as TipsterIconSVG } from './assets/tipsterIcon.svg';
import { ReactComponent as BonusIconSVG } from './assets/bonusIcon.svg';
import { ReactComponent as ColpoIconSVG } from './assets/colpoIcon.svg';
import { ReactComponent as ComingSoonSVG } from 'pages/OddsScasserPage/assets/comingSoonImg.svg';
import { ReactComponent as AccessDeniedSVG } from 'pages/OddsScasserPage/assets/accessDenied.svg';

//types
import Option from 'components/Selector/Selector.component.d'

//style
import { PageOddsScasserContent, PageOddsScasser, OddsScasserContentContainer, OddsScasserSectionContent, CTALabel, SVGIconContainer, CTABox, CTAButton, PostFBButton, ExpirationLabel, RenewLabel, ExpirationCTAButton, ExpirationContainer, ExpirationCTAContainer, SelectorsContainer, OldVersion } from './style/OddsScasserPage.style';
import { setUserLevel } from 'redux/actions/actions-oddsscasser';
import { _getUserLevel } from './services/OddsScasserPage.services';
import { Col, Row } from 'style/layout';

//helper
import { getEnvironmentProductId, getEnvironmentBaseUrl } from 'configs/config.helper';
import { timestampToUTCString } from './OddsScasserPage.helper';

const OddsScasserPage: FC<{}> = () => {
    const { userLevel } = useAppSelector(state => state.oddsScasser);
    const { courses, subscriptions } = useAppSelector(state => state.user.products)
    const { roles } = useAppSelector(state => state.user);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const getUserLevel = async () => {
            try {
                dispatch(setUserLevel(await _getUserLevel()));
            } catch (error: any) {
                console.warn(error);
            }
        };
        if(userLevel === -1) getUserLevel();
    }, []);

    const { pathname } = useLocation();

    const isAdmin: boolean = useMemo(() => roles ? roles.map((role: any) => role.id).includes(31) : false, [roles]);
    const hasBonusSicuro: boolean = useMemo(() => courses ? courses.map((course: any) => course.id).includes(getEnvironmentProductId('bonus sicuro')) : false, [courses]);
    const hasBonusSicuroPlus: boolean = useMemo(() => courses ? courses.map((course: any) => course.id).includes(getEnvironmentProductId('bonus sicuro plus')) : false, [courses]);
    const hasBonusAutomatici: boolean = useMemo(() => subscriptions ? subscriptions.map((service: any) => service.id).includes(getEnvironmentProductId('bonus automatici')) : false, [courses]);
    
    const SECTIONS: Option[] = [
        {text: 'Calcolatori', icon: <CalculatorIconSVG />, path: '/reservedArea/oddsscasser/calculators'},
        {text: 'OddsScasser', icon: <TipsterIconSVG />, path: '/reservedArea/oddsscasser/puntabanca'},
        {text: 'Bonus', icon: <BonusIconSVG />, path: '/reservedArea/oddsscasser/bonus/bonusautomatici'}
        // {text: 'Colpo Sicuro', icon: <ColpoIconSVG />, path: '/reservedArea/oddsscasser/colposicuro'}
    ];
    
    const SUBSECTIONS: Option[][] = [
        [
            {text: 'Base', path: '/reservedArea/oddsscasser/calculators', status: userLevel<1 ? 'CTA' : 'accessible', 
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro plus'), productType: 'COURSE', productName: 'Bonus Sicuro - Corso PLUS', productPrice: '€ 197,03', productInfo: '3 mesi + corso'},
                    {productId: getEnvironmentProductId('bonus automatici'), productType: 'SERVICE', productName: 'Bonus Automatici', productPrice: '€ 47,07', productInfo: 'al mese'},
                    {productId: getEnvironmentProductId('oddsscasser'), productType: 'SERVICE', productName: 'Odds ScaSSeR (Trova le Quote Migliori)', productPrice: '€ 16,21', productInfo: 'al mese'}
                ]
            },
            {text: 'Secret Bonus', path: '/reservedArea/oddsscasser/calculators/secretbonus', status: 'comingSoon'},
            {text: 'Impossible Bonus', path: '/reservedArea/oddsscasser/calculators/impossiblebonus', status: 'comingSoon'}
        ],
        [
            {text: 'Punta Banca', path: '/reservedArea/oddsscasser/puntabanca', status: userLevel<2 ? 'CTA' : 'accessible',
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro plus'), productType: 'COURSE', productName: 'Bonus Sicuro - Corso PLUS', productPrice: '€ 197,03', productInfo: '3 mesi + corso'},
                    {productId: getEnvironmentProductId('bonus automatici'), productType: 'SERVICE', productName: 'Bonus Automatici', productPrice: '€ 47,07', productInfo: 'al mese'},
                    {productId: getEnvironmentProductId('oddsscasser'), productType: 'SERVICE', productName: 'Odds ScaSSeR (Trova le Quote Migliori)', productPrice: '€ 16,21', productInfo: 'al mese'}
                ]
            },
            {text: 'Punta Punta', path: '/reservedArea/oddsscasser/puntapunta', status: userLevel<2 ? 'CTA' : 'accessible',
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro plus'), productType: 'COURSE', productName: 'Bonus Sicuro - Corso PLUS', productPrice: '€ 197,03', productInfo: '3 mesi + corso'},
                    {productId: getEnvironmentProductId('bonus automatici'), productType: 'SERVICE', productName: 'Bonus Automatici', productPrice: '€ 47,07', productInfo: 'al mese'},
                    {productId: getEnvironmentProductId('oddsscasser'), productType: 'SERVICE', productName: 'Odds ScaSSeR (Trova le Quote Migliori)', productPrice: '€ 16,21', productInfo: 'al mese'}
                ]
            },
            {text: 'Quote Migliori', path: '/reservedArea/oddsscasser/bestodds', status: userLevel<2 ? 'CTA' : 'accessible', 
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro plus'), productType: 'COURSE', productName: 'Bonus Sicuro - Corso PLUS', productPrice: '€ 197,03', productInfo: '3 mesi + corso'},
                    {productId: getEnvironmentProductId('bonus automatici'), productType: 'SERVICE', productName: 'Bonus Automatici', productPrice: '€ 47,07', productInfo: 'al mese'},
                    {productId: getEnvironmentProductId('oddsscasser'), productType: 'SERVICE', productName: 'Odds ScaSSeR (Trova le Quote Migliori)', productPrice: '€ 16,21', productInfo: 'al mese'}
                ]
            }   
        ],
        [
            {text: 'Bonus Sicuro', path: '/reservedArea/oddsscasser/bonus/bonussicuro', status: (!hasBonusSicuro && !isAdmin) ? 'CTA' : 'accessible',
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro'), productType: 'COURSE', productName: 'Bonus Sicuro 3.0 - Corso Base', productPrice: '€ 47,03', productInfo: 'Corso'},
                ]
            },
            {text: 'Bonus Sicuro Plus', path: '/reservedArea/oddsscasser/bonus/bonussicuroplus', status: (!hasBonusSicuroPlus && !isAdmin) ? 'CTA' : 'accessible',
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus sicuro plus'), productType: 'COURSE', productName: 'Bonus Sicuro - Corso PLUS', productPrice: '€ 197,03', productInfo: '3 mesi + corso'},
                ]
            },
            {text: 'Bonus Automatici', path: '/reservedArea/oddsscasser/bonus/bonusautomatici', status: (!hasBonusAutomatici && !isAdmin) ? 'CTA' : 'accessible',
                ctaProducts: [
                    {productId: getEnvironmentProductId('bonus automatici'), productType: 'SERVICE', productName: 'Bonus Automatici', productPrice: '€ 47,07', productInfo: 'al mese'},
                ]
            }
        ],
        // [
        //     {text: 'Colpo Sicuro', path: '/reservedArea/oddsscasser/colposicuro'}
        // ]
    ];

    //Obtain indexes from pathname
    let sectionIndex = 0;
    let subSectionIndex = 0;
    SUBSECTIONS.forEach((section, i) => {
        section.forEach((subsection, j) => {
            if(pathname === subsection.path) {
                sectionIndex = i;
                subSectionIndex = j;
            }
        })
    })

    const ctaHref = (productId: number, productType: string) => {
        if (productId === undefined || productId === null) return;
        let path: string = `app/public/product/${productId}`;
        if ((productType === 'LESSON_PACK') && (productId !== getEnvironmentProductId('autostrada'))) path = 'app/reservedArea/aiuto-privato';
        return getEnvironmentBaseUrl(path);
    };

    const status = SUBSECTIONS[sectionIndex][subSectionIndex].status;
    const secretBonus = sectionIndex===0 && subSectionIndex===1;
    const impossibleBonus = sectionIndex===0 && subSectionIndex===2;

    return(
        <>
            <PageOddsScasser>
                <PageOddsScasserContent>
                    <Row mainAxis='flex-end' margin='5px 0 10px 0'>
                        <PostFBButton href='https://www.facebook.com/groups/LiberoDalLavoro/posts/1547179672329823/' target='_blank' rel='noreferrer noopener'>
                            Post FB segnalazioni
                        </PostFBButton>
                    </Row>
                    {((!secretBonus && !impossibleBonus) && userLevel>2 && courses!==undefined && (sectionIndex>0 || !hasBonusSicuroPlus)) &&
                        <ExpirationContainer>
                            <Col gap='5px'>
                                <Row gap='10px'>
                                    <ExpirationLabel>Scade tra:</ExpirationLabel>
                                    <Timer limitTime={timestampToUTCString(userLevel)} timerCount='DAYS' size='s' color='white'/>
                                </Row>
                            </Col>
                            <ExpirationCTAContainer>
                                <RenewLabel>Rinnova prima della scadenza per continuare ad utilizzare questo Strumento:</RenewLabel>
                                <Row gap='5px' mainAxis='center'>
                                    <ExpirationCTAButton href={ctaHref(getEnvironmentProductId('bonus automatici'), 'SERVICE')} target='_blank' rel='noreferrer noopener'>
                                        Bonus Automatici
                                    </ExpirationCTAButton>
                                    <ExpirationCTAButton href={ctaHref(getEnvironmentProductId('oddsscasser'), 'SERVICE')} target='_blank' rel='noreferrer noopener'>
                                        OddsScasser
                                    </ExpirationCTAButton>
                                </Row>
                            </ExpirationCTAContainer>
                        </ExpirationContainer>
                    }
                    <SelectorsContainer>
                        <Selector mode='buttons' options={SECTIONS} active={sectionIndex} />
                        <Selector mode='tabs' options={SUBSECTIONS[sectionIndex]} active={subSectionIndex} />
                    </SelectorsContainer>
                    <OddsScasserContentContainer>
                        <OddsScasserSectionContent>
                            {userLevel>-1 &&
                                <>
                                    {status==='accessible' && <Outlet/>}
                                    {status==='comingSoon' && 
                                        <>
                                            {(sectionIndex===0 && subSectionIndex===2) && <OldVersion>Nel frattempo puoi ancora utilizzare la versione precedente dei calcolatori cliccando <a href='https://liberidalavoro.it/reservedArea/oddsScasser/impossible_bonus' target='_blank'>qui</a></OldVersion>}
                                            <ComingSoonSVG/>
                                        </>
                                    }
                                    {(status==='CTA' && courses!==undefined && roles!==undefined) && 
                                        <Col gap='25px' mainAxis='center' margin='0px 0'>
                                            <Col gap='5px' mainAxis='center'>
                                                <SVGIconContainer notClickable={true} size={150}><AccessDeniedSVG/></SVGIconContainer>
                                                <CTALabel fontSize='28px' fontWeight='700'>Accesso negato</CTALabel>
                                            </Col>
                                            <Col gap='15px' mainAxis='center'>
                                                <CTALabel fontSize='18px' fontWeight='400'>Acquista uno dei seguenti prodotti per accedere a questo strumento:</CTALabel>
                                                <Row gap='15px' mainAxis='center' crossAxis='stretch' flexWrap='wrap'>
                                                    {(SUBSECTIONS[sectionIndex][subSectionIndex].ctaProducts ?? []).map((x,i) => {
                                                        if(sectionIndex===1 && hasBonusSicuroPlus && x.productId===getEnvironmentProductId('bonus sicuro plus')) return;
                                                        return(
                                                            <CTABox key={i}>
                                                                <CTALabel fontSize='14px' fontWeight='500' color='#FFC000'>{x.productName}</CTALabel>
                                                                <Col gap='10px'>
                                                                    <Col gap='0px'>
                                                                        <CTALabel fontSize='14px' fontWeight='400' color='white'>{x.productPrice}</CTALabel>
                                                                        <CTALabel fontSize='14px' fontWeight='400' color='white'>{x.productInfo}</CTALabel>
                                                                    </Col>
                                                                    <CTAButton href={ctaHref(x.productId, x.productType)} target='_blank' rel='noreferrer noopener'>
                                                                        {x.productType==='SERVICE' ? 'abbonati' : 'acquista'} (!)
                                                                    </CTAButton>
                                                                </Col>
                                                            </CTABox>
                                                        );
                                                    })}
                                                </Row>
                                            </Col>
                                            
                                        </Col>
                                    }
                                </>
                            }
                        </OddsScasserSectionContent>
                    </OddsScasserContentContainer>
                </PageOddsScasserContent>
            </PageOddsScasser>
        </>        
    );
}

export default OddsScasserPage;