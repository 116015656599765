import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// types
import { ProductRequisitesProps } from './ProductRequisitesModal.component.d';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    Card,
    CardContent,
    CardTitle,
    CloseButton,
    CardInfo,
    CardProducts,
    ProductTitle,
    NegativeText
} from './style/ProductRequisitesModal.style';
import CourseBox from 'pages/ShopPage/CourseBox/CourseBox.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import ServiceBox from 'pages/ShopPage/ServiceBox/ServiceBox.component';

const ProductRequisitesModal: FC<ProductRequisitesProps> = ({ onCloseButtonModal, requisites, requisitesLogicalOperator }) => { 

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isProductInfoModalVisible, setIsProductInfoModalVisible] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>('');
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();


    console.log("requisiti", requisites);
    
    // const products = requisites.map((requisite: any) => {
    //     const { requisiteProduct } = requisite;
    //     const { productId, price } = requisiteProduct.productCourse;
    //     // const delivery = requisiteProduct.salesData.delivery;
    //     const delivery = requisiteProduct.salesData && requisiteProduct.salesData.delivery !== undefined ? requisiteProduct.salesData.delivery : "No delivery information available";
    //     const url = requisiteProduct.sellImage && requisiteProduct.sellImage.url !== undefined ? requisiteProduct.sellImage.url : "No sell image available";

    //     return {
    //       name: requisiteProduct.name,
    //       salesData: {
    //         delivery: delivery
    //       },
    //       sellImage: {
    //         url: url
    //       },
    //       productCourse: productId && price ? {
    //         productId: productId,
    //         price: price
    //       } : "REGALO",
    //       id: requisiteProduct.id,
    //       paymentTypes: {} // add the paymentTypes data here
    //     };
    //   });


    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const onCloseModalHandler = () => {
        if (onCloseButtonModal) onCloseButtonModal();
    }
    
    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };
    
    const setNewProductId = (newProductId: string, productName: string, productPrice: string) => {
        
        setIsProductInfoModalVisible(true);
        setProductId(newProductId);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
    };
    
    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };
    
    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    // useEffect(() => {
    //     if (isError) {
    //         onCloseModalHandler();
    //     }
    // }, [isError]);
    
    console.log("map requisiti", requisites);

    return (
        <>
            <BaseModal 
                onClose={onCloseModalHandler}
                isCloseButtonClicked={isCloseButtonClicked}
            >
                {!isAppMobile && (
                    <Row 
                        padding='0 21px 12px 0'
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <BaseCard 
                    flavor='outlined'
                    isModal
                >
                    <CardContent>
                        {isAppMobile && (
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={closeButtonHandler}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                        )}

                            {
                                <>
{requisites.some((requisite: any) => requisite.negation === true && requisite.satisfied === false) && (
  <Card>
    <CardTitle>Questo prodotto è dedicato a chi ancora non ha il prodotto</CardTitle>
    <CardProducts>
      {requisites
        .filter((requisite: any) => requisite.negation === true && requisite.satisfied === false)
        .map((requisite: any, index: any) => (
          <ProductTitle key={index}>{requisite.requisiteProduct.name}</ProductTitle>
        ))}
    </CardProducts>
    <NegativeText>Pertanto non potrai effettuare l'acquisto</NegativeText>
  </Card>
)}

{requisites.every((requisite: any) => requisite.negation === false || (requisite.negation === true && requisite.satisfied === true)) && (
  <Card>
    <CardTitle>Attenzione (!) non si hanno i requisiti per l'acquisto del prodotto</CardTitle>
    <CardInfo>
      I requisiti richiesti sono
      {requisitesLogicalOperator === 'OR' && requisites.length > 1 ? ` almeno uno tra questi` : ':'}
    </CardInfo>
    <CardProducts>
      {requisites
        .filter((requisite: any) => requisite.negation === false && requisite.satisfied === false)
        .map((requisite: any, index: any) => {
          return requisite.requisiteProduct.type === 'SERVICE' ? (
            <ServiceBox
              key={index}
              courseElement={requisite.requisiteProduct}
              onInfoButtonClick={setNewProductId}
              onTransferClick={transferModalHandler}
              openBankTransferModal={openBankTransferModal}
              onLibertyClick={libertyModalHandler}
              openLibertyPaymentModal={openLibertyPaymentModal}
              libertyProductID={libertyProductID}
              libertyProductPrice={libertyProductPrice}
              loadingOn={loadingOn}
              loadingOff={loadingOff}
              isRequisite
            />
          ) : (
            <CourseBox
              key={index}
              courseElement={requisite.requisiteProduct}
              onInfoButtonClick={setNewProductId}
              onTransferClick={transferModalHandler}
              openBankTransferModal={openBankTransferModal}
              onLibertyClick={libertyModalHandler}
              openLibertyPaymentModal={openLibertyPaymentModal}
              libertyProductID={libertyProductID}
              loadingOn={loadingOn}
              loadingOff={loadingOff}
              libertyProductPrice={libertyProductPrice}
              isRequisite
            />
          )
        })}
    </CardProducts>
  </Card>
)}

                             </>
                        }
                    </CardContent>
                </BaseCard>
            </BaseModal>

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                />}
        </>                
    );
};

export default ProductRequisitesModal;
