import styled, { css } from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const DateLabel = styled.div`
    width: 55px;
    min-width: 55px;
    height: 36px;
    border: 1px solid #29353E;
    border-radius: 5px;
    background: #29353E66;
    color: ${colors.white};
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    text-align: center;
`;

export const DatesRangeButtonContainer = styled.div`
    display: flex;
    cursor: pointer;
    height: 36px;
`;

export const DatesRangeLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.grey};
    border-radius: 5px 0 0 5px;
    border: 1px solid ${colors.grey};
    padding: 10px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${colors.white};
    height: 36px;
    width: 50px;
`;

export const DatesRangeLabels = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 36px;
    border: 1px solid ${colors.grey};
    border-right: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const DatesLimitLabel = styled.div`
    color: white;
    padding: 0 5px;
    text-align: right;
`;

export const DatesRangeValues = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 65px;
    border-radius: 0 5px 5px 0;
    border: 1px solid ${colors.grey};
    border-left: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const DatesLimit = styled.div`
    color: white;
    line-height: 18px;
`;

export const DatesRangeOptionsContainer = styled.div`
    display: flex;
    column-gap: 4px;
    align-items: center;
    color: white;
    font-size: 12px;
`;

export const DatesRangeOptionContainer = styled.div`
    height: 24px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
`;

export const DatesRangeOption = styled(Button)`
    text-transform: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    height: 24px;
    padding: 10px 5px;
    background-color: ${colors.grey};
    color: white;
    svg {
        height: 18px;
        width: 18px;
    }

`;

export const DoubleSliderContainer = styled.div`
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
`;