import devices from "style/breakpoints";
import colors from "style/palette";
import styled from "styled-components";

export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 35px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: center;

        max-width: 1200px;

        padding: 35px 45px 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 35px;
`;

export const CardTitle = styled.h2`
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #FF5957;
`;

export const NegativeText = styled.h2`
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: ${colors.grey400};
`;

export const CardInfo = styled.p`
    font-weight: 400;
    font-size: 15px;
    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.white};

    @media ${devices.tablet} {
        
    }
`;

export const CardProducts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 35px;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const ProductTitle = styled.h2`

    margin: 0;
    padding: 0;

    /* min-height: 37px; */

    font-weight: 400;
    font-size: 15px;

    color: ${colors.primaryColor};

    text-transform: uppercase;

    @media ${devices.tablet} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        flex-grow: 1;

        height: 100%;
        max-height: 37px;
    }
`;