import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';


export const ActiveHelpCardBox = styled.div<{ flavor?: 'green' | 'invertedGreen' | 'gold' | 'big' | undefined}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 430px;
    height: auto;

    margin: 0;
    padding: 0;

    background-color: ${colors.darkGrey};
    border-radius: 15px;
    border: none;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};

    @media ${devices.tablet} {

        height: 145px;
    }

    ${({flavor}) => ((flavor === 'green') || (flavor === 'invertedGreen')) &&

        css`

            background-color: ${colors.veryDarkGrey};
            border: 0.5px solid ${colors.green};
        `
    }

    ${({flavor}) => (flavor === 'gold') &&

        css`

            background-color: ${colors.veryDarkGrey};
            border: 0.5px solid ${colors.primaryColor};
        `
    }

    ${({flavor}) => (flavor === 'big') && 
    
        css`
            
            height: auto;

            @media  ${devices.tablet} {
                min-height: 320px;
            }
        `
    }
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    margin: 0;
    padding: 30px;

    @media ${devices.tablet} {

        gap: unset;
        padding: 25px 25px 30px;
    }
`;

export const CardTitle = styled.h3<{ flavor?: any }>`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    color: ${colors.white};
    text-align: center;

    p {

        margin: 0;
        padding: 0;
    }

    ${({flavor}) => (flavor === 'green') &&

        css`
            color: ${colors.green};
        `
    }

    ${({flavor}) => (flavor === 'gold') &&

        css`
            color: ${colors.white};
        `
    }

    > span {
        font-weight: 700;
    }

`;

export const HelpButton = styled(Button)`
    margin: 10px 0 0;
`;

export const WaitText = styled.p<{ flavor?: any }>`

    padding: 0;

    color: ${colors.white};
    
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;

    ${({flavor}) => (flavor === 'invertedGreen' &&
    
        css`
            color: ${colors.green};
        `
    )}
`;

export const StrongText = styled.span`
    font-weight: 700;
`;

export const GoldText = styled.span`
    color: ${colors.primaryColor};
`;

export const MissionBetfairSupportBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    border-top: 1px solid ${colors.grey};
`;

export const SupportText = styled.h4`

    margin: 35px 0 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.white};
    text-align: center;
`;

export const SupportButton = styled(Button)`

    margin: 20px 0 0;
    padding: 15px 20px;
`;
