import { Auth } from 'aws-amplify';

// types
import { SendForgotPasswordConfirmationPayload } from './PasswordRecoveryForm.services.d';


export const sendForgotPasswordConfirmation = async (payload: SendForgotPasswordConfirmationPayload) => {

    try {

        const response = await Auth.forgotPassword(payload.username);

        if (response === undefined || !response) {
            throw new Error();
        }
        
        return response;
        
    } catch (error: any) {
        throw error;
    }
};
