const sumDigitsToOneDigit = (num: number): number =>
	num > 9
		? sumDigitsToOneDigit(
				num
					.toString()
					.split('')
					.reduce((acc, digit) => acc + +digit, 0)
		  )
		: num;

const dayCalc: (date: Date) => string = (date) => {
	return sumDigitsToOneDigit(date.getDate()).toString();
};

const karmaCalc: (date: Date) => string = (date) => {
	return sumDigitsToOneDigit(
		sumDigitsToOneDigit(date.getDate()) +
			sumDigitsToOneDigit(date.getMonth()+1) +
			sumDigitsToOneDigit(date.getFullYear())
	).toString();
};

export const birthdayNumerologyValue: (date: Date) => string = (date) => {
	return `${dayCalc(date)}K${karmaCalc(date)}`;
};

const chaldeanNumerologyMap = (char: string): number => {
    const chaldeanTable: { [key: string]: number } = {
        'a': 1, 'i': 1, 'j': 1, 'q': 1, 'y': 1,
        'b': 2, 'k': 2, 'r': 2,
        'c': 3, 'g': 3, 'l': 3, 's': 3,
        'd': 4, 'm': 4, 't': 4,
        'e': 5, 'h': 5, 'n': 5, 'x': 5,
        'u': 6, 'v': 6, 'w': 6,
        'o': 7, 'z': 7,
        'f': 8, 'p': 8
    };

    return chaldeanTable[char.toLowerCase()] || 0;
};

const sumDigits = (num: number): number => num.toString().split('').reduce((acc, digit) => acc + +digit, 0);

export const calculateChaldeanFrequency = (input: string): number[] => {
    let total = input.split('').reduce((acc, char) => acc + chaldeanNumerologyMap(char), 0);
    let results = [total];
    
    while (results[results.length - 1] > 9 && results.length < 3) {
        total = sumDigits(total);
        results.push(total);
    }

    return results;
};