import { FC, useEffect, useState } from 'react';

//routing
import { useNavigate } from "react-router-dom";

//types
import { SelectorProps } from './Selector.component.d';

//style
import {
    SelectorWithTabsBox,
    SelectorTab,
    SelectorWithButtonsBox,
    SelectorButton,
    SelectorButtonText
} from './style/Selector.style';

const Selector: FC<SelectorProps> = ({mode, options, active, onOptionClick }) => {

    const [activeIndex, setActiveIndex] = useState<number>(active);
    const navigate = useNavigate();

    useEffect(() => {
        navigate(options[activeIndex].path);
        onOptionClick && onOptionClick(activeIndex);
    }, [activeIndex]);

    useEffect(() => {
        setActiveIndex(active);
    }, [active]);
    
    return (
        <>
            { mode === 'tabs' && 
                <SelectorWithTabsBox>
                    { options.map((option, index) => {
                        return(
                            <SelectorTab 
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                isActive={index === activeIndex}
                            >
                                {option.text.toUpperCase()}
                            </SelectorTab>
                        )
                    })}
                </SelectorWithTabsBox> 
            }
            {mode === 'buttons'  && 
                <SelectorWithButtonsBox>
                    { options.map((option, index) => {
                        return(
                            <SelectorButton 
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                isActive={index === activeIndex}
                            >
                                {option.icon}
                                <SelectorButtonText>{option.text}</SelectorButtonText>
                            </SelectorButton> 
                    )
                    })}
                </SelectorWithButtonsBox>
            }
        </>
    );
}

export default Selector;