import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const PageCourse = styled.div`
    padding: 40px;

    width: 100%;
    height: 100%;

    overflow: hidden;
`;

export const PageContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    max-width: 1440px;
    width: 100%;
    max-width: 1440px;

    gap: 30px;

    overflow: hidden;

`;

export const ReproductionBox = styled.div`

    display: flex;
    flex-direction: column;

    width: calc(100% - 400px - 40px);
`;

export const BreadCrumb = styled.div`

    a {
        text-decoration: none;
        color: inherit;
    }
`;

export const CourseTitle = styled.h4`

    margin: 0;
    padding: 0 10px;

    font-weight: 400;
    font-size: 28px;

    color: rgba(255, 255, 255, 0.7);
`;

export const VideoBox = styled.div<{isVideoAlone?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 20px 0 0;

    border-radius: ${({isVideoAlone}) => (isVideoAlone === true) ? '15px' : '15px 15px 0px 0px'};
    border-bottom: 1px solid ${colors.white050};

    overflow: hidden;

    background: black;
`;

export const DescriptionBox = styled.div<{isFirstElement?: boolean}>`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 25px 35px;

    width: 100%;
    /* height: calc(100vh - 70px); */

    background: ${colors.darkGrey};

    border-radius: 0px 0px 15px 15px;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};

    overflow: auto;

    p {
        overflow-wrap: anywhere;
        width: 100%;
    }

    a {
        color: #FFE662;
    }

    ${({isFirstElement}) => isFirstElement && (

        css`

            margin: 20px 0 0;
            border-radius: 15px;
        `
    )}
`;


export const LessonTitle = styled.h3`

    margin: 17px 0 20px;;
    padding: 0; 

    font-weight: 400;
    font-size: 28px;

    color: ${colors.primaryColor};
`;

export const LessonDescription = styled.div`

    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    color: ${colors.white};
`;

export const InterectionBox = styled.div`

    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
    align-items: center;
    flex-direction: column;
    
    right: 0px;
    
    width: 400px;

    /* position: absolute; */

    /* overflow: hidden; */
`;


export const CourseInfoBox = styled.div`

    display: flex;
    align-self: flex-start;

    width: 100%;

    margin: 35px 0 0;

    gap: 15px;
`;

export const ProgressBar = styled.div`

    width: 100%;
    height: 28px;

    background: white;
`;

export const FlowBox = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;

    padding: 0;
    margin: 25px 0 0;

    /* right: 40px;
    top: 137px; */

    width: 100%;
    max-width: 400px;

    /* height: calc(100vh - 350px); */

    overflow: auto;

    
    ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    
`;

export const ChaptersList = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 15px 0;

    font-weight: 500;
    font-size: 14px;

    color: ${colors.white050};
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0.07%, rgba(207, 222, 235, 0.1) 99.93%);

`;

// export const CurrentLesson = styled.h4`

//     margin: 0;
//     padding: 0;

//     font-weight: 400;
//     font-size: 17px;

//     color: ${colors.white};
// `;

export const  InterectionLessonBox = styled.div`

display: flex;
justify-content: space-between;
align-items: center;

width: 100%;
max-width: 100%;
`;

export const  SearchBox = styled.div`

display: flex;
align-items: center;
justify-content: space-between;


gap: 15px;
`;

export const SearchBar = styled.input`

display: flex;
align-items: center;
flex-direction: column;

width: 243px;
height: 36px;
padding: 12px;

background-color: transparent;
border: 1px solid ${colors.white};
border-radius: 100px;

color: white;
`;

export const LessonSearch = styled.div`

> svg {
        min-width: 35px;
        min-height: 35px;          
}
`;

export const ExpandLesson = styled.div`

> svg path {
    fill: ${colors.white};      
}
`;

export const FreePromoButton = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 13px;
    line-height: 17px;

    padding: 5px 20px;
    margin: 30px 0 0;

    background: ${colors.green};

    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};

    border-radius: 5px;
    border: none;

    width: 100%;
    text-transform: uppercase;

    cursor: pointer;
`;
