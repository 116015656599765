import { FC, useState } from 'react';

//components
import { MENU_FILTER } from '../..';
import FilterShopButtonMobile from '../../FilterShopButton/layout/FilterShopButtonMobile/FilterShopButtonMobile.component';

//assets
import { ReactComponent as ArrowCloseSubfilterIconSVG } from '../../assets/arrowCloseSubfilterIcon.svg';


//style
import { 
    FilterShopBox,
    FilterReturnBox
} from './style/FilterShopMobile.style';


const FilterShopMobile: FC<{
    changeFilterOpenActive: (text: string) => void,
    changeSubFilterOpenActive: (text: string) => void
}> = ({
    changeFilterOpenActive,
    changeSubFilterOpenActive
}) => {

    const [visibleButtonText, setVisibleButtonText] = useState<string>("Tutti i Prodotti");
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedMenuPosition, setSelectedMenuiPosition] = useState<number>(0);
    const [selectedSubMenuPosition, setSelectedSubMenuiPosition] = useState<number>(0);

    const filterOpenClickHandler = () => {

        setIsMenuOpen(prevState => !prevState);
    };

    const filterHandler = (newFilterButtonText: string, position: number) => {

        changeFilterOpenActive(MENU_FILTER[position].text);
        setVisibleButtonText(newFilterButtonText);
        setSelectedMenuiPosition(position);
        setIsMenuOpen(false);
    };    

    const filterSubCategoryHandler = (newFilterButtonText: string, position: number) => {

        changeSubFilterOpenActive(newFilterButtonText);
        setVisibleButtonText(newFilterButtonText);
        setSelectedSubMenuiPosition(position);
        setIsMenuOpen(false);
    };
    
    const fiterClearHandler = () => {

        changeFilterOpenActive('');
        changeSubFilterOpenActive('');
        setVisibleButtonText("Tutti i Prodotti");
        setSelectedMenuiPosition(0);
        setSelectedSubMenuiPosition(0);
        setIsMenuOpen(false);
    };

    const menuIcon = () => {

        if (visibleButtonText === 'Tutti i Prodotti') return MENU_FILTER[0].icon;
        if (MENU_FILTER[selectedMenuPosition].text === visibleButtonText) return MENU_FILTER[selectedMenuPosition].icon;
        return MENU_FILTER[selectedMenuPosition].subCategory[selectedSubMenuPosition].icon;
    };

    return (
        <FilterShopBox>
            <>
                <FilterReturnBox>

                    {visibleButtonText !== 'Tutti i Prodotti' && (
                        <ArrowCloseSubfilterIconSVG onClick={fiterClearHandler} />
                    )}

                    <FilterShopButtonMobile 
                        icon={menuIcon()}
                        text={visibleButtonText}
                        onButtonClick={filterOpenClickHandler}
                        position={selectedMenuPosition}
                        visibleButtonText={visibleButtonText}
                    />
                </FilterReturnBox>

                {isMenuOpen && (visibleButtonText === 'Tutti i Prodotti') && (
                    MENU_FILTER.map((element: any, index: number) => {
                        if (index === 0) return;
                        return (
                        <FilterShopButtonMobile 
                            key={"F" + index}
                            position={index}
                            icon={element.icon}
                            text={element.text}
                            onButtonClick={filterHandler}
                            showMenuIcon={false}
                            visibleButtonText={visibleButtonText}
                        />
                        )
                    })
                )}

                {isMenuOpen && (visibleButtonText !== 'Tutti i Prodotti') && (
                <>
                    {MENU_FILTER[selectedMenuPosition].subCategory.map((element: any, index: number) => {
                    if (element.text !== visibleButtonText) {
                        return (
                        <FilterShopButtonMobile 
                            key={"SF" + index}
                            position={index}
                            icon={element.icon}
                            text={element.text}
                            onButtonClick={filterSubCategoryHandler}
                            showMenuIcon={false}
                            visibleButtonText={visibleButtonText}
                        />
                        )
                    }
                    return null;
                    })}
                </>
                )}
            </>
        </FilterShopBox>
    );
};

export default FilterShopMobile;