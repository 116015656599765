import { FC } from 'react';

// assets
import { ReactComponent as ComingSoonSVG } from './assets/comingSoonImg.svg';

// style
import { Page } from './style/ComingSoonPage.style';


const ComingSoonPage: FC<{}> = () => {

    return (
        <Page>
            <ComingSoonSVG />
        </Page>
    );
};

export default ComingSoonPage;
