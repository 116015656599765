import devices from 'style/breakpoints';
import colors from 'style/palette';
import styled from 'styled-components';


export const ActiveLessonsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    padding: 0px;

    border-top: 1px solid rgba(255, 255, 255, 0.23);

`;

export const LabelBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0px 4px;
    gap: 10px;

    background: ${colors.darkGrey};
`;

export const InfoText = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    color: rgba(255, 255, 255, 0.7);
`;

export const Label = styled.h6`

    font-weight: 400;
    font-size: 12px;

    margin: 0px;
    padding: 0px;

    background: ${colors.darkGrey};

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: rgba(255, 255, 255, 0.7);

    transform: translate(-4px, -8px);
`;

export const LessonsGrid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    width: 100%;

    padding: 16px 0px 5px;

    @media ${devices.tablet} { 
        
    }
`;

export const LessonsTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 0px;

    @media ${devices.tablet} { 
        gap: 0px;
    }
`;

export const RowLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px 0px;

    width: 100%;

    background: rgba(0, 0, 0, 0.002);
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);

    @media ${devices.tablet} { 
        padding: 10px 15px;
        gap: 20px;

    }
`;

export const CoachText = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-end;
    justify-self: flex-end;

    width: fit-content;
    color: rgba(255, 255, 255, 0.7);
`;