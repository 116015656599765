import { callGET, callOldGET } from "network/network";

export const getHistoricalResults =async () => {
    
    try {
        const results = await callGET('v1/self/userresults', true)
        if (!results || results === undefined) {
            throw new Error ("Unable getting Historical Data.")
        }

        return results;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
    // try {
    //     const results = await callOldGET('libertydashboardresult/getmyresults', true)
    //     if (!results || results === undefined) {
    //         throw new Error ("Unable getting Historical Data.")
    //     }

    //     return results;
    // } catch (error: any) {
    //     if (error.message.includes('403')) throw new Error ('403');
    //     throw error;
    // }
};

export const getUsersCourseList = async () => {

    try {
        
        const courseListData = await callOldGET('user/getCourseList', true);

        if (courseListData === undefined) {
            throw new Error("Response of User's course list is undefined");
        }

        return courseListData;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getUsersServicesList = async () => {

    try {
        
        const servicesListData = await callOldGET('user/getServiceList', true);

        if (servicesListData === undefined) {
            throw new Error("Response of User's services list is undefined");
        }

        return servicesListData;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};
