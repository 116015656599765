import { FC } from "react";

//types
import { CtaCardProps} from './CtaCard.component.d'

// style
import {
    CtaBaseCard,
    CtaCardBox,
    Title,
    CtaCardContent,
    CtaCardButton
} from "./style/CtaCard.style";


/**
 * Cta card
 * 
 * @author Frank Berni <frank.berni.79@gmail.com>
 * 
 * @returns JSX
 */


const CtaCard: FC<CtaCardProps> = ({title, description, ctaText, ctaUrl}) => {

    return (
        <CtaCardBox>
            <Title>{title}</Title>


                <CtaCardContent>
                    {description}
                    <CtaCardButton onClick={ () => window.open(ctaUrl, "blank")}>{ctaText}</CtaCardButton>
                </CtaCardContent>

        </CtaCardBox>
    );
};

export default CtaCard;
