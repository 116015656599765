import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;

    width: 100%;
    max-width: 640px;
    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;
    
    @media ${devices.tablet} {
        padding: 35px 45px 50px;
        max-width: none;

        min-width: 500px;
    }
`;


export const CloseButton = styled.button`

margin: 0;
padding: 0;

background-color: ${colors.transparent};
border: none;

cursor: pointer;
`;

export const ResultEaningBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    gap: 20px;
`;

export const EarningBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* flex-direction: column; */

    width: 100%;

    gap: 12px;

    > svg {
        flex-shrink: 0;
    }
`;

export const EarningText = styled.p`
    
    margin: 0;
    padding: 0;
    
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    
    color: ${colors.white};
    
    text-align: center;
`;

export const ResultEarningText = styled.span`
    
    margin: 0 0 0 12px;
    padding: 0;
    
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    
    color: #81C784;
    
    text-align: center;
    text-transform: capitalize;
`;

export const GeneralTableBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 30px;
    margin-right: -8%;
    width: 108%;
    overflow-x: auto;
    min-height: 315px;

    ::-webkit-scrollbar{

        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media ${devices.tablet} {
        margin-right: 0px;
        max-width: 642px;
        overflow-x: none;
        border-radius: none;
    }

`;

export const AnnualEarningTableBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    max-width: 642px;

    border-radius: 10px;
    background: ${colors.simpleGrey};

    > div:first-child {
        border-radius: 10px 10px 0 0;
    }

    > div:last-child {
        border-radius: 0 0 10px 10px;
    }
`;

export const AnnualBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    /* width: 505px;
    height: 60px; */

    width: 100%;
    height: 60px;
    max-width: 642px;

    background: #262C31;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);
    /* border-radius: 10px 10px 0px 0px; */
`;

export const CurrentYarsText = styled.h2`
    
    margin: 0;
    padding: 0;
    
    font-size: 15px;
    font-weight: 500;
    
    color: ${colors.primaryColor};
    
    text-align: center;

`;

export const MainTableBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    position: relative;

    overflow-x: auto;

    width: 100%;

    /* width: 505px; */
    /* height: 60px; */
`;

export const TableBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    

    width: 642px;
    height: 44px;

    gap: 30px;
    padding: 10px 20px;

    background: #353E45;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);
`;

export const MounthBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 51px;
    height: 10px;
`;

export const TotalMounthBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 87px;
    height: 10px;
`;

export const MiddleEarningBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 122px;
    height: 24px;
`;

export const MounthrRecordBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 140px;
    height: 10px;
`;

export const TableText = styled.p`
    white-space: nowrap;

    margin: 0;
    padding: 0;
    
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    
    color: rgba(255, 255, 255, 0.7);
    
    text-align: center;
`;

export const TopEarningBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 44px 0 0;

    width: 100%;

    gap: 8px;

    > svg {
        flex-shrink: 0;
    }

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const RecordBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 12px;
`;

export const Nuance = styled.div`

    width: 40px;
    height: 100%;

    /* background: linear-gradient(270deg, rgba(27, 31, 34, 0.8) 0%, rgba(76, 88, 98, 0) 100%); */
    background: red;

    position: absolute;
    right: 0px;
`;

export const TotalEarningBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* flex-direction: column; */
    margin: 20px 0 0;

    width: 100%;

    gap: 12px;
`;

export const TotalEarningText = styled.p`
    
    margin: 0;
    padding: 0;
    
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    
    color: ${colors.white};
    
    text-align: center;
`;

export const TotalResultEarningText = styled.span`
    
    margin: 0 0 0 12px;
    padding: 0;
    
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    
    color: #81C784;
    
    text-align: center;
`;