import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 35px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: center;

        max-width: 1200px;

        padding: 35px 45px 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const LibertyTitleBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 50px 0 0;
`;

export const LibertyTitle = styled.h2`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 16px;

    text-transform: uppercase;

    color: ${colors.green};
`;

export const LibertyTextBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LibertyText = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    text-align: center;

    color: ${colors.white};

    > svg {

        path {
        
        fill: ${colors.white};
        fill-opacity: 1;
        }
    }
`;

export const LibertyButton = styled(Button)``;
