import { FC, useEffect, useState } from 'react';

// components
import Slider from 'react-input-slider';
import colors from 'style/palette';
import { ReactComponent as MinusCircleSVG } from './assets/minus-icon.svg';
import { ReactComponent as PlusCircleSVG } from './assets/plus-icon.svg';

// types
import { HorizontalSliderProps } from './HorizontalSlider.component.d';

// style
import { SliderBox, ChangeValueBotton } from './style/HorizontalSlider.style';


export const HorizontalSlider: FC<HorizontalSliderProps> = ({
    maxValue,
    minValue,
    startingValue,
    step,
    onChange
}) => {

    const [sliderValue, setSliderValue] = useState<number>(startingValue);
    const sliderStep =  step ?? minValue;

    const onChangeValueHandler = (values: { x: number, y: number }) => {
        onChange(values.x)
    };

    const onButtonMinusClickValueHandler = () => {
        // if (startingValue >= (minValue * 3)) return;
        if (startingValue >= (sliderStep * 2)) return onChange(startingValue - sliderStep);
        onChange(minValue);
    };

    const onButtonPlusClickValueHandler = () => {
        if (startingValue <= (maxValue - sliderStep))onChange(startingValue + sliderStep);
    };

    useEffect(() => {
        setSliderValue(startingValue);
    }, [startingValue]);

    return (
        <SliderBox>
            <ChangeValueBotton onClick={onButtonMinusClickValueHandler} >
                <MinusCircleSVG />
            </ChangeValueBotton>

            <Slider 
                axis='x'
                xmin={minValue}
                x={sliderValue}
                xmax={maxValue}
                onChange={onChangeValueHandler}
                xstep={sliderStep}
                styles={{
                    track: {
                        height: '4px',
                        background: `${colors.lightGrey}`
                    },
                    active: {
                        background: `${colors.white}`,
                    }
                }}
            />

            <ChangeValueBotton onClick={onButtonPlusClickValueHandler}>
                <PlusCircleSVG />
            </ChangeValueBotton>
        </SliderBox>
    );
};

export default HorizontalSlider;
