import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { FacebookExceptionModalProps } from './FacebookExceptionModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as FacebookSVG } from './assets/facebook-icon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/FacebookExceptionModal.style';
import { facebookConnect } from 'services/facebook.services';
import FacebookLoginButton from './FacebookLoginButton';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const FacebookExceptionModal: FC<FacebookExceptionModalProps> = ({ onCloseButtonModal }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { email } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = () => {
        if (email) facebookConnect(false, email);
    };

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <Title>
                        ATTENZIONE
                    </Title>

                    <TextContent>
                    Le tue credenziali Facebook sono scadute. <br />
                    Per poter utilizzare tutte le funzioni integrate correggi il tuo account.
                    </TextContent>

                    <FacebookLoginButton onClick={startButtonHandler}>correggi il tuo account</FacebookLoginButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default FacebookExceptionModal;
