import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Nav = styled.nav`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 5.215%;
    flex: 4;

    color: ${colors.white};

    > a, > div {

        margin: 0;
        padding: 0;
        text-decoration: none;

        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        
        color: ${colors.white};

        &.active {

            color: ${colors.primaryColor};
        }
    }

    @media ${devices.desktop} {

        justify-content: center;
    }
`;
