import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { ProductInfoModalProps } from './ProductInfoModal.component.d';

// components
import PublicProductPages from 'pages/PublicProductPages/PublicProductPage.component';
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton
} from './style/ProductInfoModal.style';
import { getEnvironmentProductId } from 'configs/config.helper';


/**
 * Product info modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * @param productInfoId - Id of the chosen product
 * 
 * @returns JSX
 */

const ProductInfoModal: FC<ProductInfoModalProps> = ({ 
    onCloseButtonModal, 
    productInfoId, 
    // liberty, 
    onBankModalOpen,
    onLibertyModalOpen,
    modalGetPaymentID,
    libertyProductID,
    publicInfoPageTransferPrice
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [productType, setProductType] = useState<string>('');

    const buyInfoModalHandler = () => {
        closeButtonHandler();
        if(onBankModalOpen)onBankModalOpen();
    };

    const libertyInfoModalHandler = () => {
        closeButtonHandler();
        onLibertyModalOpen();
    };

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    useEffect(() => {
        if (parseInt(productInfoId) === getEnvironmentProductId('bonus sicuro')) {
            setProductType('bonus sicuro');
        } else if (parseInt(productInfoId) === getEnvironmentProductId('liberi dalla stanchezza')) {
            setProductType('liberi dalla stanchezza');
        } else if (parseInt(productInfoId) === getEnvironmentProductId('autostrada')) {
            setProductType('autostrada');
        } else if (parseInt(productInfoId) === getEnvironmentProductId('fare soldi senza soldi')) {
            setProductType('fare soldi senza soldi');
        } else {
            setProductType('');
        }
    }, [productInfoId]);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            // isBackdropClickActive
            modalFixedStart
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent productType={productType}>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton productType={productType} onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <PublicProductPages
                        productInfoId={productInfoId}
                        // libertyPoints={liberty}
                        isOpenInModal
                        buyInfoModalHandler={buyInfoModalHandler}
                        libertyInfoModalHandler={libertyInfoModalHandler}
                        modalGetPaymentID={modalGetPaymentID}
                        libertyProductID={libertyProductID}
                        publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                    />
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default ProductInfoModal;