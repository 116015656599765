import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 35px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: center;

        max-width: 1200px;

        padding: 35px 45px 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const TransferStepBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const FirstStepTransferBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 7px;
`;

export const TextStep = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 13px;

    color: ${colors.white050};
`;

export const SecondStepTransferBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 7px;
`;

export const ThirdStepTransferBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 7px;
`;

export const TitleStepBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 50px 0 0;
`;

export const TitleStepText = styled.h2`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 16px;

    text-transform: uppercase;

    color: ${colors.primaryColor};
`;

export const TransferGlobalBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: 20px 0px 50px;
    gap: 15px;
`;

export const HeaderTransferBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex-basis: 25%;

    /* width: 100%; */
`;

export const HeaderText = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 12px;

    text-transform: uppercase;

    color: ${colors.white050};
`;

export const DataTransferRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 12px;

    width: 100%;
`;

export const DataTextBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 75%;

    /* width: 100%; */

    gap: 12px
`;

export const DataText = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 15px;

    color: ${colors.white};
`;

export const CompletingTransferBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 100%;
`;

export const CompletingTransferText = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 15px;

    color: ${colors.white};
`;

export const CompletingTransferButton = styled(Button)``;

export const Line = styled.div`

    width: 100%;
    min-width: 70px;
    height: 1px;

    background: ${colors.darkGrey};

    @media ${devices.tablet} {

        min-width: 150px;
    }
`;

