import { FC, useEffect, useState } from 'react';

//asset
import { ReactComponent as InfoIconSVG } from './assets/infoIcon.svg';
import { ReactComponent as OpenDropIconSVG } from './assets/openDropIcon.svg';
import { ReactComponent as ClosedDropIconSVG } from './assets/closedDropIcon.svg';
import { ReactComponent as ExspireIconSVG } from './assets/expiredIcon.svg';

//style
import {
    ServiceContent,
    ServiceDescriptionBox,
    ServiceStatusMainBox,
    ServiceName,
    ServiceSelectorBox,
    ServiceStatusText,
    ServiceDeadlineDate,
    ServiceStatusBox,
    ServiceGuideButton,
    ServiceElipsisBox,
    ServiceInfoBox,
    ReactivationServiceBox
} from './style/MyServiceDisactive.style';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

const MyServiceDisactive: FC<{
    service: any,
    onInfoButtonClick: (productId: string, productName: string, productPrice: string) => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    servicesOwned: any[];
    refreshFunction?: () => void
}> = ({ 
    service,
    onInfoButtonClick,
    onLibertyClick,
    openLibertyPaymentModal,
    servicesOwned,
    refreshFunction
}) => {

    const [isFirstSelectorOpenActive, setIsFirstSelectorOpenActive] = useState(false);
    const [isSecondSelectorOpenActive, setIsSecondtSelectorOpenActive] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [expirationDate, setExpirationDate] = useState('');

    const finalDate = new Date(expirationDate);

    const {
        name,
        expired,
        productService,
        id,
        paymentTypes
      } = service;

    const vipPoints = (productService?.paymentPlan.vipPoints);

    
    const onClickFirstSelectorOpenHandler = () => {
        
        setIsFirstSelectorOpenActive(prevState => !prevState);
    };

    const onClickSecondSelectorOpenHandler = () => {
        
        setIsSecondtSelectorOpenActive(prevState => !prevState);
    };

    const infoButtonClickHandler = () => onInfoButtonClick(id, name, '0');

    const libertyModalHandler = () => {
        openLibertyPaymentModal();
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const isExspired = (expired === true);

    useEffect(() => {
        if (expired) {
            const expiredService = servicesOwned.find(servicesOwned => servicesOwned.product.id === id);
            if (expiredService && expiredService.productService.expirationDate) {
                setExpirationDate(expiredService.productService.expirationDate);
            }
        }
    }, [expired, servicesOwned, id]);
    
    return (
        <ServiceContent>
            <ServiceDescriptionBox>
                <ServiceInfoBox>
                    <ServiceGuideButton onClick={infoButtonClickHandler}>
                        <InfoIconSVG />
                    </ServiceGuideButton>

                    <ServiceElipsisBox>
                        <ServiceName dangerouslySetInnerHTML={{__html: name}} />
                    </ServiceElipsisBox>
                </ServiceInfoBox>

                {!isExspired && 
                    <ServiceSelectorBox onClick={onClickFirstSelectorOpenHandler}>
                        {!isFirstSelectorOpenActive && <OpenDropIconSVG />}

                        {isFirstSelectorOpenActive && <ClosedDropIconSVG />}
                    </ServiceSelectorBox>}
            </ServiceDescriptionBox>

            {isFirstSelectorOpenActive && 
                <ReactivationServiceBox>
                        <ButtonShop 
                            PriceProduct={productService.paymentPlan.price} 
                            TypeOfProduct="SERVICE"
                            courseElement={service}
                            courseID={id}
                            payments={paymentTypes}
                            // onTransferClick={onTransferClick}
                            // openBankTransferModal={openBankTransferModal}
                            onLibertyClick={libertyModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            vipPoints={vipPoints}
                            isServicePageRequest
                            refreshFunction={refreshFunction}
                            isIntoServicesPage
                        />
                </ReactivationServiceBox>
            }

            {isExspired &&
            <ServiceStatusMainBox>

                <ServiceStatusBox>
                    <ExspireIconSVG />

                    <ServiceStatusText>Scaduto</ServiceStatusText>

                    <ServiceDeadlineDate>{`${finalDate.getDate().toString().padStart(2, '0')}/${(finalDate.getMonth()+1).toString().padStart(2, '0')}/${finalDate.getFullYear()}`}</ServiceDeadlineDate>
                </ServiceStatusBox>

                <ServiceSelectorBox onClick={onClickSecondSelectorOpenHandler}>
                    {!isSecondSelectorOpenActive && <OpenDropIconSVG />}

                    {isSecondSelectorOpenActive && <ClosedDropIconSVG />}
                </ServiceSelectorBox>
            </ServiceStatusMainBox>}

            {isSecondSelectorOpenActive && 
                <ReactivationServiceBox>
                    <ButtonShop 
                        PriceProduct={productService.paymentPlan.price} 
                        TypeOfProduct="SERVICE"
                        courseElement={service}
                        courseID={id}
                        payments={paymentTypes}
                        // onTransferClick={onTransferClick}
                        // openBankTransferModal={openBankTransferModal}
                        onLibertyClick={libertyModalHandler}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                        vipPoints={vipPoints}
                        isServicePageRequest
                        refreshFunction={refreshFunction}
                        isIntoServicesPage
                    />
                </ReactivationServiceBox>
            }

        </ServiceContent>
    )
};

export default MyServiceDisactive;