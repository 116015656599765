import { 
    NormalText, 
    Page, 
    TextBox, 
    TextYellow
} from "./style/AutorizationError.style";

const AutorizationError = () => {
    
    return (
        <Page>
            <TextBox>
                <TextYellow>Non sei autorizzato a vedere questa pagina.</TextYellow>

                <NormalText>Torna alla Home.</NormalText>
            </TextBox>
        </Page>
    )
};

export default AutorizationError;