import colors from "style/palette";
import styled, { css } from "styled-components";

export const UserLevelCardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;

    height: 145px;
    width: 100%;
    max-width: 430px;
    
    padding: 20px 40px;
    gap: 11px;

    border-radius: 15px;
    border: 1px solid  #81C784;
    background: ${colors.veryDarkGrey};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;
`;

export const InfoButton = styled.div`
    width: 22px;
    height: 22px;

    position: absolute;
    right: 16px;
    top: 15px;

    cursor: pointer;
`;

export const LevelBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

`;

export const Conectors = styled.div<{isModal: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    position: relative;

    ${({ isModal }) => isModal === true && `
        gap: 18px;
    `}
`;

export const ConectorDown = styled.div<{currentLevel: number; index: number; isModal: boolean}>`
    width: 12px;
    height: 1px;
    transform: rotate(40deg);
    flex-shrink: 0;
    background: ${colors.lightGrey};

    position: absolute;
    left: 21px;
    bottom: -1px;

    ${({ currentLevel, index }) =>
        currentLevel === index
            ? css`
                background: ${colors.white};
            `
            : currentLevel < index
            ? css`
                background: ${colors.lightGrey};
            `
            : css`
                background: #81c784;
            `
    };
    ${({ isModal }) => isModal === true && `
        left: 14px;
    `}
`;

export const ConectorUp = styled.div<{currentLevel: number; index: number; isModal: boolean}>`
    width: 12px;
    height: 1px;
    transform: rotate(-40deg);
    flex-shrink: 0;
    background: ${colors.lightGrey};

    position: absolute;
    left: 21px;

    ${({ currentLevel, index }) =>
        currentLevel === index
            ? css`
                background: ${colors.white};
            `
            : currentLevel < index
            ? css`
                background: ${colors.lightGrey};
            `
            : css`
                background: #81c784;
            `
    };
    ${({ isModal }) => isModal === true && `
        left: 14px;
    `}
`;

export const Levels = styled.div<{isModal: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 35.5px;

    max-width: 265px;

    ${({ isModal }) => isModal === true && `
        gap: 26px;
    `}
`;

export const LevelBall = styled.div<{currentLevel: number; index: number; isModal: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 24px;
    height: 24px;
    padding: 0px 7px;

    border-radius: 64.215px;
    ${({ currentLevel, index}) =>
        currentLevel === index
            ? css`
                background: ${colors.white};
                border: 2px solid ${colors.green};
                width: 28px;
                height: 28px;
                > p {
                color: ${colors.black};
                }
            `
            : currentLevel < index
            ? css`
                background: ${colors.lightGrey};
            `
            : css`
                background: #81c784;
                > p {
                color: ${colors.black};
                }
            `
    };

    ${({ isModal }) => isModal === true && `
        width: 18px;
        height: 18px;

        padding: 0 4.5px;
    `}

`;

export const GrayText = styled.p<{isModal: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    ${({ isModal }) => isModal === true && `
        font-size: 9.405px;
        line-height: 166%;
        letter-spacing: 0.313px;
    `}
`;

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    padding: 15px 20px;
    border-radius: 5px;
    background: ${colors.primaryColor};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    cursor: pointer;
`;

export const ButtonText = styled.p`
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    padding: 0px;
    margin: 0px;

    text-transform: uppercase;
`;