import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const FilterShopSelector = styled.div<{isActive?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-height: 69px;

    padding: 17px 15px;
    gap: 10px;

    background: ${colors.darkGrey};
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    cursor: pointer;

    ${({isActive}) => isActive && 
        css`
            background: ${colors.grey};
            border: 0.5px solid ${colors.primaryColor};
        `
    }

    @media ${devices.tablet} {

    }
`;

export const FilterIcon = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilterText = styled.p`

    font-weight: 400;
    font-size: 15px;

    text-align: center;

    color: ${colors.white};

    text-transform: uppercase;
`;
