import { callGET, callOldGET } from 'network/network';

export const getUserBalance =async () => {
    
    try {
        const response = await callOldGET('user/getPuntiLiberta', true);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getUsersRoles =async () => {
    
    try {
        const response = await callOldGET('user/getRolesList', true);
        if (!response || response === undefined) {
            throw new Error ("User's role response undefined.");
        } 

        return response;
    } catch (error: any) {
        throw error;
    }
};


export const getUsersInviteFriends =async () => {
    
    try {
        const response = await callGET('v1/users/self/friends', true);
        if (!response || response === undefined) {
            throw new Error ("User's friends response undefined.");
        } 
        console.log("UTENTI INVITATI NEL SERVICES:", response);
        return response;
    } catch (error: any) {
        throw error;
    }
};
