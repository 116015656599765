import axios, { AxiosRequestConfig } from "axios";

export const postFacebookEvent =  async (pixelId: string, conversionToken: string, payload: any) => {

    try {

        const axiosRequestConfig: AxiosRequestConfig = {};
               
        const response = await axios.post(`https://graph.facebook.com/v16.0/${pixelId}/events?access_token=${conversionToken}`, payload, axiosRequestConfig);
        if (response.status >= 400) throw Error("C'è stato un errore nella risposta");

        return response.data;

    } catch (error: any) {
        throw error;
    }
};