import { FC, useEffect, useRef, useState } from "react";

//components
import TestimonialCard from "pages/LandingPage/TestimonialCard/TestimonialCard.component";

//types
import {TestimonialsCarouselMobileProps} from './TestimonialsCarouselMobile.component.d'

//style
import { TestimonialsCarouselMobileBox } from "./style/TestimonialsCarouselMobile.component.style";
import { Dot, Dots } from "../style/TestimonialsCarousel.component.style";
import { getCenteredSubArray } from "../TestimonialsCarousel.helper";

const TestimonialsCarouselMobile: FC<TestimonialsCarouselMobileProps> = ({testimonials, areTestimonialsIMG}) => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [isVideoPaused, setIsVideoPaused] = useState(true);

  const intervalRef = useRef<NodeJS.Timeout>();

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      handleSwipe("left")
    }, 5000);
  }

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  }

  const handleSwipe = (direction: "left" | "right") => {

    if (testimonials.length > 0) {
      setCurrentTestimonialIndex(prevIndex => {
        const nextTestimonialIndex =
          direction === "left"
            ? prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            : prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1;
        return nextTestimonialIndex;
      });
      stopInterval();
      startInterval();
      setIsVideoPaused(true)
    }
  };

  useEffect(() => {
    if (isVideoPaused && testimonials.length > 0) startInterval();
    if (!isVideoPaused) stopInterval();
  }, [isVideoPaused, testimonials.length]);


  return (
    <TestimonialsCarouselMobileBox>
        {testimonials[currentTestimonialIndex] &&
          <TestimonialCard
            key={areTestimonialsIMG ? `${currentTestimonialIndex}TC` : `${testimonials[currentTestimonialIndex].description}${currentTestimonialIndex}`}
            testimonial={testimonials[currentTestimonialIndex]}
            onSwipe={handleSwipe}
            setIsVideoPaused={setIsVideoPaused}
            inCarousel={true}
            isTestimonialImg={areTestimonialsIMG}
            startCarousel={startInterval}
            stopCarousel={stopInterval}
          />
        }
        <Dots isIMGTestimonials={areTestimonialsIMG}>
            {testimonials[currentTestimonialIndex] && getCenteredSubArray(testimonials, currentTestimonialIndex, 7)
              .map((testimonial, index) => {
                    return (
                        <Dot key={areTestimonialsIMG ? `${currentTestimonialIndex}TC` : `${testimonial.description}${index}`} cIndex={index-3} />
                    );
                 })
            }
        </Dots> 
    </TestimonialsCarouselMobileBox>
  );
};

export default TestimonialsCarouselMobile;