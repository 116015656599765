import { FC, useState, useEffect } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// routing
import { useNavigate } from 'react-router-dom';

//utils
import { cardsOfPower } from './data/cards';

// assets
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as BackIcon } from '../VdrTools/assets/back.svg';

//components
import CardsCarouselMobile from './CardsCarouselMobile/CardsCarouselMobile.component';
import VdrToolsAudioPlayer from 'components/VdrToolsAudioPlayer/VdrToolsAudioPlayer.component';

// style
import {
	CardDrawButton,
	CardsBox,
	Page,
	PageTitle,
	PageTitleBox,
	ShareBox,
	ShareButton,
	ShareText,
	SubTitle,
	TextInput,
} from './style/VdrCardsOfPower.style';

const VdrCardsOfPower: FC<{}> = () => {
	const [inputText, setInputText] = useState('');
	const [isShuffling, setIsShuffling] = useState(false);
	const [showResult, setShowResult] = useState(false);

	const { isAppMobile } = useAppSelector((state) => state.ui);
	const { clientId } = useAppSelector((state) => state?.user);

	const navigate = useNavigate();

	const onInputTextChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setInputText(event.currentTarget.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
		  const fakeEvent = new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window,
		  });
		  onDrawingButtonClickHandler(fakeEvent as unknown as React.MouseEvent<HTMLButtonElement>);
		}
	  };

	const onDrawingButtonClickHandler = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		if (!clientId) localStorage.setItem('vdrQuestion', inputText);
		setIsShuffling((isShuffling) => !isShuffling);
	};

	const onBackClickHandler = () => {
		navigate('/public/strumenti');
	};


	useEffect(() => {
		const localQuestion = localStorage.getItem('vdrQuestion');
		if (!localQuestion || localQuestion === '') return;
		setInputText(localQuestion);
		var timeoutId: any;
		timeoutId = setTimeout(() => {
			localStorage.removeItem('vdrQuestion');
		}, 1500);
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [inputText]);

	useEffect(() => {
		
	}, []);

	return (
		<Page>
			<PageTitleBox>
				<PageTitle>Carte del Potere</PageTitle>
				{isAppMobile && <BackIcon onClick={onBackClickHandler} />}
			</PageTitleBox>

			<VdrToolsAudioPlayer
				title='Come funzionano le Carte?'
				author='Paolo Luini'
				audioSrc='https://www.viveredipoker.it/LaVoceDellaRicchezza/TracceAudio/come_funzionano_le_carte_del_potere.mp3'
			/>

			<SubTitle>Scrivi la tua domanda</SubTitle>
			<TextInput
				type='text'
				id='numerology-text'
				name='numerology-text'
				value={inputText}
				onChange={onInputTextChangeHandler}
				onKeyDown={handleKeyPress}
				placeholder='es. Cosa devo fare per sentirmi felice?'
			></TextInput>

			<CardsBox>
				<CardsCarouselMobile
					cards={cardsOfPower}
					isShuffling={isShuffling}
					setIsShuffling={setIsShuffling}
					showResult={showResult}
					setShowResult={setShowResult}
				/>
				<CardDrawButton onClick={onDrawingButtonClickHandler}>
					estrai una carta
				</CardDrawButton>
			</CardsBox>

			{/* <ShareBox>
				<ShareText>Ti piace questo strumento?</ShareText>
				<ShareButton>
					<ShareIcon />
					condividi
				</ShareButton>
			</ShareBox> */}
		</Page>
	);
};

export default VdrCardsOfPower;
