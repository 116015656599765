// routing
import { Outlet } from 'react-router-dom';


const LandingPages = () => {

    return (
        <>
            <Outlet />
        </>
    )
};

export default LandingPages;