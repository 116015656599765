import { FC, useRef, useMemo, useState, useEffect, Fragment } from 'react';

//utils
// import { getItemFromLocalStorage } from 'utils/localStorage';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useDraggableScroll from 'hooks/useDraggableScroll';

//components
// import OddsScasserTableFilter from './OddsScasserTableFilter';
import LoadingMask from 'components/ui/LoadingMask';
import OddsMatchCalculatorModal from './OddsMatchCalculatorModal';

//types
import { OddsScasserTableProps } from './OddsScasserTable.component.d';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';

//assets
import { ReactComponent as CalculatorIconSVG } from 'pages/OddsScasserPage/assets/calculator.svg';
import { ReactComponent as ChevronLeftIcon } from 'pages/OddsScasserPage/assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'pages/OddsScasserPage/assets/chevronRight.svg';
import { ReactComponent as InfoIconSVG } from 'pages/OddsScasserPage/assets/info-icon.svg';
import { ReactComponent as MinusIconSVG } from 'pages/OddsScasserPage/assets/minus.svg';
import { ReactComponent as CollapseIconSVG } from 'pages/OddsScasserPage/assets/collapse.svg';
import { ReactComponent as ExpandIconSVG } from 'pages/OddsScasserPage/assets/expand.svg';

//style
import { AddButton, AddButtons, CoverBets, FooterNavigationButton, FooterPageButton, FooterPagesButtonsContainer, NoResults, TBody, TD, TH, THead, TR, Table, TableContainer, TableFooter, CellContainer, OddsMatchIconsContainer, EventInfo, EventInfoElement, SingleDivCell, EventCol } from './style/OddsScasserTable.style';
import { Bet, BetContainer, HorizontalScrollContainer, Rating, Resp, SVGIconContainer, SiteBox, SiteLogo, SiteLogoContainer, WinnigsAndRating, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

//helper
import { formatDate } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import { Col, Row } from 'style/layout';

const  OddsScasserTable: FC<OddsScasserTableProps> = ({ mode, filters, oddsMatches, showWinnings, isLoading, addToBlacklist, addToRollover, addToMultiple, copyToClipboard, pageChangeHandler }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events } = useAppSelector(state => state.oddsScasser);

    const [modalOddsMatch, setModalOddsMatch] = useState<OddsMatch|undefined>(undefined);
    const [oddsMatchDetailOpened, setOddsMatchDetailOpened] = useState<boolean[]>(new Array(oddsMatches.length).fill(true));

    const ref = useRef(null);
    const { onMouseDown } = useDraggableScroll(ref, 'horizontal');

    const columns: string[] = useMemo(() => {
        const _columns = isAppMobile ? (
            mode === 'puntabanca' 
            ? [(filters.isFreebet || (parseFloat(filters.refund) || 0)<0.001) ? 'Rating' : 'RF', 'Esito', 'Book', 'Exch', '']
            : [(filters.isFreebet || (parseFloat(filters.refund) || 0)<0.001) ? 'Rating' : 'RF', 'Book1', 'Book2', 'Book3', '']
        ) : (
            mode === 'puntabanca' 
            ? [(filters.isFreebet || (parseFloat(filters.refund) || 0)<0.001) ? 'Rating' : 'RF', 'Data', 'Competizione', 'Evento', 'Esito', 'Bookmaker', 'Punta', 'Exchange', 'Banca', 'Liquidità', 'Importo Banca']
            : [(filters.isFreebet || (parseFloat(filters.refund) || 0)<0.001) ? 'Rating' : 'RF', 'Data', 'Competizione', 'Evento', 'Esito1', 'Book1', 'Quota1', 'Esito2', 'Book2', 'Quota2', 'Esito3', 'Book3', 'Quota3', 'Importo Punta']
        );
        if(!isAppMobile && mode==='puntapunta' && oddsMatches.every(x => x.odds.length<3)) _columns.splice(10, 3);
        if(isAppMobile && mode==='puntapunta' && oddsMatches.every(x => x.odds.length<3)) _columns.splice(3, 1);
        return _columns;
    }, [mode, filters, oddsMatches, isAppMobile])

    useEffect(() => {
        setOddsMatchDetailOpened(prevState => new Array(oddsMatches.length).fill(prevState.every(Boolean)));
    }, [oddsMatches])

    if (isLoading || sites.size===0 || events.size===0 || oddsMatches.length!==oddsMatchDetailOpened.length) return (<LoadingMask paddingTop='100px' isCenter size='page'/>);
    const currentPage = filters.page ?? 0;
    const pages = currentPage<3 ? [0,1,2,3,4] : [currentPage-2,currentPage-1,currentPage,currentPage+1,currentPage+2];
    if(oddsMatches.length<(filters.size ?? 15)) pages.splice(pages.indexOf(currentPage)+1);
    return(
        <>
            <TableContainer>
                {oddsMatches.length===0 && <NoResults>La ricerca non ha prodotto risultati</NoResults>}
                {oddsMatches.length>0 &&
                    <HorizontalScrollContainer ref={ref} onMouseDown={onMouseDown}>
                        <Table borderSpacing={isAppMobile ? '0' : undefined}>
                            <THead>
                                <TR>
                                    {columns.map((col, index) => {
                                        if(isAppMobile && index===columns.length-1) {
                                            const n = oddsMatchDetailOpened.filter(Boolean).length;
                                            return(
                                                <TH key={index}>
                                                    <CellContainer>
                                                        <SVGIconContainer notClickable={n===oddsMatches.length} opacity={n===oddsMatches.length ? 0.2 : undefined} size={18}><ExpandIconSVG title="Mostra tutti" onClick={() => setOddsMatchDetailOpened(new Array(oddsMatches.length).fill(true))}/></SVGIconContainer>
                                                        <SVGIconContainer notClickable={n===0} opacity={n===0 ? 0.2 : undefined} size={18}><CollapseIconSVG title="Nascondi tutti" onClick={() => setOddsMatchDetailOpened(new Array(oddsMatches.length).fill(false))}/></SVGIconContainer>
                                                    </CellContainer>
                                                </TH>
                                            );
                                        }
                                        return <TH key={index}>{col}</TH>;
                                    })}
                                </TR>
                            </THead>
                            {!isAppMobile &&
                                <TBody rowsHeight='40px'>
                                    {oddsMatches.map((oddsMatch, index) => {
                                        let ratings = mode === 'puntapunta' ? oddsMatch.odds.length : 1;
                                        const event = events.get(oddsMatch.event.id);
                                        const color = event ? (event.league?.sport?.id === 1 ? '#1b7700' : (event.league?.sport?.id === 2 ? '#bf9a17' : '#91311d')) : '#808080';
                                        const oddsSites = oddsMatch.odds.map(x => sites.get(x.site.id));
                                        return(
                                            <TR key={index} backgroundColor={index%2===1 ? '#29353e8a' : undefined}>
                                                <TD borderLeft={`5px solid ${color}`}>
                                                    <AddButtons><AddButton backgroundColor='dodgerblue' onClick={() => addToMultiple({...oddsMatch})}>M</AddButton><AddButton backgroundColor='#ff7600' onClick={() => addToRollover({...oddsMatch})}>R</AddButton></AddButtons>
                                                    {!showWinnings && oddsMatch.odds.slice(0, ratings).map(x => x.rating?.toFixed(2)+'%').join(' ')}
                                                    {showWinnings && 
                                                        <CellContainer>
                                                            {oddsMatch.odds.slice(0, ratings).map((x, i) => {
                                                                const _bet = parseFloat(filters.bet) || 0;
                                                                const _bonus = filters.isFreebet ? 0 : parseFloat(filters.bonus) || 0;
                                                                const _refund = parseFloat(filters.isFreebet ? filters.bet : filters.refund) || 0;
                                                                const winnings = x.rating ?  (_refund>0.01 ? x.rating*_refund/100 : (x.rating-100)*_bet/100)+_bonus : undefined; 
                                                                return(
                                                                    <WinnigsAndRating key={'R'+i} flexDirection='column'>
                                                                        <Winnings translateY={-5} size='s' fontWeight={400}>{x.rating!==undefined ? x.rating.toFixed(2) : '-'}%</Winnings>
                                                                        <Rating translateY={7} top={4} size='s' amount={winnings}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Rating>
                                                                    </WinnigsAndRating>
                                                                );
                                                            })}
                                                        </CellContainer>
                                                    }
                                                </TD>
                                                <TD>{event?.datetime && formatDate(event.datetime)}</TD>
                                                <TD>{event?.league?.country+', '+event?.league?.name}</TD>
                                                <TD>{event?.home?.name+' - '+event?.away?.name}</TD>
                                                <TD>{oddsMatch.odds[0].type}</TD>
                                                <TD>
                                                    <CellContainer>
                                                        <SiteBox height={23} width={75} checked={true} notClickable={oddsMatch.odds[0].url===undefined} onClick={() => oddsMatch.odds[0].url && copyToClipboard(oddsMatch.odds[0].url, `URL ${oddsSites[0]?.name} copiato`)}>
                                                            <SiteLogoContainer visible={true} backgroundColor={oddsSites[0]?.color}><SiteLogo imageUrl={oddsSites[0]?.imageUrl}/></SiteLogoContainer>
                                                        </SiteBox>
                                                    </CellContainer>
                                                </TD>
                                                <TD cursor='pointer' 
                                                    title={`Click per aggiungere alla blacklist\n\nUltima variazione: ${formatDate(oddsMatch.odds[0].lastChange ?? '')}\nUltimo aggiornamento: ${formatDate(oddsMatch.odds[0].lastUpdate ?? '')}`} 
                                                    onClick={() => addToBlacklist(oddsMatch.event, oddsMatch.odds[0])}>
                                                    {oddsMatch.odds[0].odds.toFixed(2)}
                                                </TD>
                                                {mode === 'puntapunta' && <TD>{oddsMatch.odds[1].type}</TD>}
                                                <TD>
                                                    <CellContainer>
                                                        <SiteBox height={23} width={75} checked={true} notClickable={oddsMatch.odds[1].url===undefined} onClick={() => oddsMatch.odds[1].url && copyToClipboard(oddsMatch.odds[1].url, `URL ${oddsSites[1]?.name} copiato`)}>
                                                            <SiteLogoContainer visible={true} backgroundColor={oddsSites[1]?.color}><SiteLogo imageUrl={oddsSites[1]?.imageUrl}/></SiteLogoContainer>
                                                        </SiteBox>
                                                    </CellContainer>
                                                </TD>
                                                <TD cursor='pointer' 
                                                    title={`Click per aggiungere alla blacklist\n\nUltima variazione: ${formatDate(oddsMatch.odds[1].lastChange ?? '')}\nUltimo aggiornamento: ${formatDate(oddsMatch.odds[1].lastUpdate ?? '')}`} 
                                                    onClick={() => addToBlacklist(oddsMatch.event, oddsMatch.odds[1])}>
                                                    {oddsMatch.odds[1].odds.toFixed(2)}
                                                </TD>
                                                {mode === 'puntabanca' && <TD title='Liquidità'>€{oddsMatch.odds[1].available?.toFixed(2)}</TD>}
                                                {columns.length>13 && 
                                                    <>
                                                        <TD>{oddsMatch.odds.length>2 && oddsMatch.odds[2].type}</TD>
                                                        <TD>{oddsMatch.odds.length>2 && 
                                                            <CellContainer>
                                                                <SiteBox height={23} width={75} checked={true} notClickable={oddsMatch.odds[2].url===undefined} onClick={() => oddsMatch.odds[2].url && copyToClipboard(oddsMatch.odds[2].url, `URL ${oddsSites[2]?.name} copiato`)}>
                                                                    <SiteLogoContainer visible={true} backgroundColor={oddsSites[2]?.color}><SiteLogo imageUrl={oddsSites[2]?.imageUrl}/></SiteLogoContainer>
                                                                </SiteBox>
                                                            </CellContainer>
                                                        }</TD>
                                                        <TD cursor='pointer' 
                                                            title={oddsMatch.odds.length>2 ? `Click per aggiungere alla blacklist\n\nUltima variazione: ${formatDate(oddsMatch.odds[2].lastChange ?? '')}\nUltimo aggiornamento: ${formatDate(oddsMatch.odds[2].lastUpdate ?? '')}` : undefined} 
                                                            onClick={() => oddsMatch.odds.length>2 && addToBlacklist(oddsMatch.event, oddsMatch.odds[2])}>
                                                            {oddsMatch.odds.length>2 && oddsMatch.odds[2].odds.toFixed(2)}
                                                        </TD>
                                                    </>
                                                }
                                                <TD borderRight={`5px solid ${color}`}>
                                                    <CoverBets>
                                                        <BetContainer flexDirection='row' title={`${mode === 'puntabanca' ? 'Banca' : 'Punta'} esito ${oddsMatch.odds[1].type} a quota ${oddsMatch.odds[1].odds.toFixed(2)} su ${oddsSites[1]?.name}`}>
                                                            <Bet>{'€'+oddsMatch.odds[1].coverBet?.toFixed(mode==='puntabanca' ? 2 : 0)}</Bet>
                                                            {mode === 'puntabanca' && <Resp title='Responsabilità'>{'€'+oddsMatch.odds[1].coverResp?.toFixed(2)}</Resp>}
                                                        </BetContainer>
                                                        {oddsMatch.odds.length>2 && <BetContainer title={`Punta esito ${oddsMatch.odds[2].type} a quota ${oddsMatch.odds[2].odds.toFixed(2)} su ${oddsSites[2]?.name}`}><Bet>{'€'+oddsMatch.odds[2].coverBet?.toFixed(0)}</Bet></BetContainer>}
                                                        <SVGIconContainer size={22} right={10}><CalculatorIconSVG title="Apri calcolatore" onClick={() => setModalOddsMatch(oddsMatch)}/></SVGIconContainer>
                                                    </CoverBets>
                                                </TD>
                                            </TR>
                                        );
                                    })}
                                </TBody>
                            }
                            {isAppMobile &&
                                <TBody>
                                    {oddsMatches.map((oddsMatch, index) => {
                                        let ratings = mode === 'puntapunta' ? oddsMatch.odds.length : 1;
                                        const event = events.get(oddsMatch.event.id);
                                        const color = event ? (event.league?.sport?.id === 1 ? '#1b7700' : (event.league?.sport?.id === 2 ? '#bf9a17' : '#91311d')) : '#808080';
                                        const oddsSites = oddsMatch.odds.map(x => sites.get(x.site.id));
                                        const backgroundColor = index%2===1 ? '#29353e8a' : undefined;
                                        return(
                                            <Fragment key={index}>
                                                <TR backgroundColor={backgroundColor}>
                                                    <TD borderLeft={`5px solid ${color}`}>
                                                        <AddButtons><AddButton backgroundColor='dodgerblue' onClick={() => addToMultiple({...oddsMatch})}>M</AddButton><AddButton backgroundColor='#ff7600' onClick={() => addToRollover({...oddsMatch})}>R</AddButton></AddButtons>
                                                        <WinnigsAndRating flexDirection='column'>
                                                            <Winnings translateY={-5} size='s' fontWeight={400}>{oddsMatch.avgRating!==undefined ? oddsMatch.avgRating.toFixed(2) : '-'}%</Winnings>
                                                            <Rating translateY={7} top={4} size='s' amount={oddsMatch.avgWinnings}>€{oddsMatch.avgWinnings!==undefined ? oddsMatch.avgWinnings.toFixed(2) : '-'}</Rating>
                                                        </WinnigsAndRating>
                                                    </TD>
                                                    {mode==='puntabanca' && <TD fontSize={12}>{oddsMatch.odds[0].type}</TD>}
                                                    <TD>
                                                        <CellContainer>
                                                            <SiteBox height={23} width={50} checked={true} notClickable={oddsMatch.odds[0].url===undefined} onClick={() => oddsMatch.odds[0].url && copyToClipboard(oddsMatch.odds[0].url, `URL ${oddsSites[0]?.name} copiato`)}>
                                                                <SiteLogoContainer visible={true} backgroundColor={oddsSites[0]?.color}><SiteLogo width='95%' imageUrl={oddsSites[0]?.imageUrl}/></SiteLogoContainer>
                                                            </SiteBox>
                                                        </CellContainer>
                                                    </TD>                                                  
                                                    <TD>
                                                        <CellContainer>
                                                            <SiteBox height={23} width={50} checked={true} notClickable={oddsMatch.odds[1].url===undefined} onClick={() => oddsMatch.odds[1].url && copyToClipboard(oddsMatch.odds[1].url, `URL ${oddsSites[1]?.name} copiato`)}>
                                                                <SiteLogoContainer visible={true} backgroundColor={oddsSites[1]?.color}><SiteLogo width='95%' imageUrl={oddsSites[1]?.imageUrl}/></SiteLogoContainer>
                                                            </SiteBox>
                                                        </CellContainer>
                                                    </TD>
                                                    {(mode==='puntapunta' && columns.length>4) && 
                                                        <>
                                                            <TD>{oddsMatch.odds.length>2 && 
                                                                <CellContainer>
                                                                    <SiteBox height={23} width={50} checked={true} notClickable={oddsMatch.odds[2].url===undefined} onClick={() => oddsMatch.odds[2].url && copyToClipboard(oddsMatch.odds[2].url, `URL ${oddsSites[2]?.name} copiato`)}>
                                                                        <SiteLogoContainer visible={true} backgroundColor={oddsSites[2]?.color}><SiteLogo width='95%' imageUrl={oddsSites[2]?.imageUrl}/></SiteLogoContainer>
                                                                    </SiteBox>
                                                                </CellContainer>
                                                            }</TD>
                                                            
                                                        </>
                                                    }
                                                    <TD borderRight={`5px solid ${color}`}>
                                                        <OddsMatchIconsContainer>
                                                            <SVGIconContainer size={22}><CalculatorIconSVG title="Apri calcolatore" onClick={() => setModalOddsMatch(oddsMatch)}/></SVGIconContainer>
                                                            <SVGIconContainer size={20}>
                                                                {!oddsMatchDetailOpened[index] && <InfoIconSVG title="Mostra dettagli" onClick={() => setOddsMatchDetailOpened(prevState => prevState.map((x,i) => i===index ? !x : x))}/>}
                                                                {oddsMatchDetailOpened[index] && <MinusIconSVG title="Mostra dettagli" onClick={() => setOddsMatchDetailOpened(prevState => prevState.map((x,i) => i===index ? !x : x))}/>}
                                                            </SVGIconContainer>
                                                        </OddsMatchIconsContainer>
                                                    </TD>
                                                </TR>
                                                {oddsMatchDetailOpened[index] &&
                                                    <>
                                                        {mode==='puntapunta' &&
                                                            <TR fontSize={13} cellPaddingVert='3px' cellPaddingHor='2px' backgroundColor={backgroundColor}>{/*Selection*/}
                                                                <TD></TD>
                                                                <TD>{oddsMatch.odds[0].type}</TD>
                                                                <TD>{oddsMatch.odds[1].type}</TD>
                                                                {(mode==='puntapunta' && columns.length>4) && <TD>{oddsMatch.odds.length>2 && oddsMatch.odds[2].type}</TD>}
                                                                <TD></TD>
                                                            </TR>
                                                        }
                                                        <TR cellPaddingVert='2px' backgroundColor={backgroundColor}>{/*Odds*/}
                                                            <TD></TD>
                                                            {mode==='puntabanca' && <TD></TD>}
                                                            <TD cursor='pointer' onClick={() => addToBlacklist(oddsMatch.event, oddsMatch.odds[0])} title='Aggiungi alla blacklist'>{oddsMatch.odds[0].odds.toFixed(2)}</TD>
                                                            <TD cursor='pointer' onClick={() => addToBlacklist(oddsMatch.event, oddsMatch.odds[1])} title='Aggiungi alla blacklist'>{oddsMatch.odds[1].odds.toFixed(2)}</TD>
                                                            {(mode==='puntapunta' && columns.length>4) && <TD cursor='pointer' onClick={() => oddsMatch.odds.length>2 && addToBlacklist(oddsMatch.event, oddsMatch.odds[2])} title='Aggiungi alla blacklist'>{oddsMatch.odds.length>2 && oddsMatch.odds[2].odds.toFixed(2)}</TD>}
                                                            <TD></TD>
                                                            {/* <TD>{mode==='puntabanca' ? 'Liq: €'+oddsMatch.odds[1].available?.toFixed(2) : ''}</TD> */}
                                                        </TR>
                                                        <TR cellPaddingVert='2px' cellPaddingHor='2px' backgroundColor={backgroundColor}>{/*Bets*/}
                                                            <TD></TD>
                                                            {mode==='puntabanca' && <TD></TD>}
                                                            <TD color='#FFC000' fontWeight={500}>{mode==='puntabanca' ? 'Banca' : 'Punta'}</TD>
                                                            <TD>
                                                                <CellContainer>
                                                                    <BetContainer title={`${mode === 'puntabanca' ? 'Banca' : 'Punta'} esito ${oddsMatch.odds[1].type} a quota ${oddsMatch.odds[1].odds.toFixed(2)} su ${oddsSites[1]?.name}`}>
                                                                        <Bet translateY={mode === 'puntabanca' ? 5 : undefined}>{'€'+oddsMatch.odds[1].coverBet?.toFixed(mode==='puntabanca' ? 2 : 0)}</Bet>
                                                                        {mode === 'puntabanca' && <Resp translateY={2} title='Responsabilità'>{'€'+oddsMatch.odds[1].coverResp?.toFixed(2)}</Resp>}
                                                                    </BetContainer>
                                                                </CellContainer>
                                                            </TD>
                                                            {(mode==='puntapunta' && columns.length>4) && <TD>{oddsMatch.odds.length>2 ? <BetContainer><Bet>{'€'+oddsMatch.odds[2].coverBet?.toFixed(0)}</Bet></BetContainer> : ''}</TD>}
                                                            <TD></TD>
                                                        </TR>
                                                        {mode==='puntapunta' &&
                                                            <TR backgroundColor={backgroundColor}>{/*Ratings*/}
                                                                <TD></TD>
                                                                {oddsMatch.odds.slice(0, ratings).map((x, i) => {
                                                                    const _bet = parseFloat(filters.bet) || 0;
                                                                    const _bonus = filters.isFreebet ? 0 : parseFloat(filters.bonus) || 0;
                                                                    const _refund = parseFloat(filters.isFreebet ? filters.bet : filters.refund) || 0;
                                                                    const winnings = x.rating ?  (_refund>0.01 ? x.rating*_refund/100 : (x.rating-100)*_bet/100)+_bonus : undefined; 
                                                                    return(
                                                                        <TD>
                                                                            <WinnigsAndRating key={'R'+i} flexDirection='column'>
                                                                                <Winnings translateY={-5} size='s' fontWeight={400}>{x.rating!==undefined ? x.rating.toFixed(2) : '-'}%</Winnings>
                                                                                <Rating translateY={7} top={4} size='s' amount={winnings}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Rating>
                                                                            </WinnigsAndRating>
                                                                        </TD>
                                                                    );
                                                                })}
                                                                {(mode==='puntapunta' && columns.length>4 && oddsMatch.odds.length<=2) && <TD></TD>}
                                                                <TD></TD>
                                                            </TR>
                                                        }
                                                        <TR backgroundColor={backgroundColor}>{/*Event*/}
                                                            <SingleDivCell colSpan={columns.length}>
                                                                <EventInfo>
                                                                    <Col margin='0 10px'>
                                                                        <EventInfoElement>{event?.datetime && formatDate(event.datetime).split(' ')[0]}</EventInfoElement>
                                                                        <EventInfoElement>{event?.datetime && formatDate(event.datetime).split(' ')[1]}</EventInfoElement>
                                                                    </Col>
                                                                    <EventCol>
                                                                        <EventInfoElement>{event?.home?.name+' - '+event?.away?.name}</EventInfoElement>
                                                                        <EventInfoElement>{event?.league?.country+', '+event?.league?.name}</EventInfoElement>
                                                                    </EventCol>
                                                                </EventInfo>
                                                            </SingleDivCell>
                                                        </TR>
                                                    </>
                                                }
                                            </Fragment>
                                        );
                                    })}
                                </TBody>
                            }
                        </Table>
                    </HorizontalScrollContainer>
                }
                {(oddsMatches.length>0 || filters.page) &&
                    <TableFooter>
                        <FooterNavigationButton onClick={() => pageChangeHandler(currentPage - 1)} disabled={currentPage === 0}><ChevronLeftIcon /></FooterNavigationButton>
                        <FooterPagesButtonsContainer>
                            {pages.map(page => <FooterPageButton key={page} onClick={() => currentPage!==page &&pageChangeHandler(page)} disabled={currentPage===page}>{page+1}</FooterPageButton>)}
                        </FooterPagesButtonsContainer>
                        <FooterNavigationButton onClick={() => pageChangeHandler(currentPage + 1)} disabled={oddsMatches.length < (filters.size ?? 15)}><ChevronRightIcon /></FooterNavigationButton>
                    </TableFooter>
                }
            </TableContainer>
            {modalOddsMatch && <OddsMatchCalculatorModal mode={mode} oddsMatch={modalOddsMatch} filters={filters} onCloseButtonModal={() => setModalOddsMatch(undefined)} copyToClipboard={copyToClipboard}/>}
        </>
    );
}

export default OddsScasserTable;