import { getEnvironmentProductId } from 'configs/config.helper';
import { callOldGET, callGET, callOldPOST, callPOST } from 'network/network';
import store from 'redux/store';


export const getUsersCourseList = async () => {

    // try {
        
    //     const courseListData = await callOldGET('user/getCourseList', true);

    //     if (courseListData === undefined) {
    //         throw new Error("Response of User's course list is undefined");
    //     }

    //     return courseListData;
    // } catch (error: any) {
    //     throw error;
    // }
    try {

        const courseParams = {
            brandCode: store.getState().ui.brand?.code,
            type: 'COURSE'
        }
        
        const courseListData = await callGET('v1/self/products', true, courseParams);


        if (courseListData === undefined) {
            throw new Error("Response of User's course list is undefined");
        }

        return courseListData;
    } catch (error: any) {
        throw error;
    }
};

export const checkCashAcademyFreeSlots = async (setIsCashAcademySlotFree: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {

    // try {
        
    //     const cashAcademySlots = await callOldGET('subscription/getPostiDisponibili/132', true);

    //     if (cashAcademySlots === undefined) {
    //         throw new Error("Response of check Cash Academy free slots is undefined");
    //     }

    //     setIsCashAcademySlotFree(cashAcademySlots.data === true);
    // } catch (error: any) {
    //     throw new Error ("Unable to check Cash Academy free slots")
    // }

    try {
        
        // to do change id with the right one
        const cashAcademyData = await callGET(`v1/productsforsell/${getEnvironmentProductId('cash academy')}`, true);

        if (cashAcademyData === undefined) {
            throw new Error("Response of check Cash Academy free slots is undefined");
        }

        setIsCashAcademySlotFree((cashAcademyData.maxSubscription - cashAcademyData.subscriptions) > 0);
    } catch (error: any) {
        throw new Error ("Unable to check Cash Academy free slots")
    }
};

export const checkCoachLesson = async (setIsCoachLessonActive: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {

    try {
        
        const coachData = await callOldGET('user/checkHasCoach', true);

        if (coachData === undefined) {
            throw new Error("Response of check Coach lesson is undefined");
        }

        setIsCoachLessonActive(coachData.data === true);
    } catch (error: any) {
        throw new Error ("Unable to check Coach lesson");
    }
};

export const getAssignedHighwayInfo = async (clientId: number, setIsHighwayAssigned: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
    
    try {

        // const idCliente = await getClientId();
        const highwayInProgressData = await callOldGET('autostrada/getincorsobyidcliente', true, { idCliente: clientId });

        if (highwayInProgressData === undefined) {
            
            setIsHighwayAssigned(false);
            throw new Error("Response getting Assigned Highway info is undefined");
        }

        if (highwayInProgressData.length === 0) {
            
            return setIsHighwayAssigned(false);
        };

        return highwayInProgressData;   
    } catch (error: any) {
        throw error;
    }
};

export const getActiveHighwayInfo = async (clientId: number, setIsHighwayActive: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {

    try {

        const openHighwayData = await callOldGET('autostrada/getbyidclienteanddatachiusuraisnull', true, { idCliente: clientId });

        if (openHighwayData === undefined) {
            setIsHighwayActive(false);
            throw new Error("Response getting Active Highway info is undefined");
        }

        if (openHighwayData.length === 0) {
            return setIsHighwayActive(false);
        }
        
        return openHighwayData;
    } catch (error: any) {
        throw error;
    }
};

export const getUsersMissions = async () => {

    // try {

    //     const currenUserData = await callOldGET('user/getCurrentUser', true);

    //     if (currenUserData === undefined) {
    //         throw new Error("Response getting Users missions is undefined");
    //     }

    //     return currenUserData;            
    // } catch (error: any) {
    //     throw error;
    // }

    try {

        const userMissions = await callGET('v1/self/missions', true);

        if (userMissions === undefined) {
            throw new Error("Response getting Users missions is undefined");
        }

        return userMissions;            
    } catch (error: any) {
        throw error;
    }
};

// export const getClientId = async () => {

//     try {

//         const currentUserData = await callGET('v1/users/self/currentuser', true);

//         if (currentUserData === undefined) {
//             throw new Error("Response getting Client Id is undefined");
//         }

//         return currentUserData.id;            
//     } catch (error: any) {
//         throw error;
//     }
// };

export const getUsersLessons = async () => {

    try {
        
        const usersLessons = await callGET('v1/self/productsubscriptions', true, { types: 'LESSON_PACK', brandCode: store.getState().ui.brand?.code });

        if (usersLessons === undefined) {
            throw new Error("Response of Users lessons is undefined");
        }

        return usersLessons;            
    } catch (error: any) {
        throw error;
    }
};

export const getUsersLastLessonDate = async () => {

    try {
        
        const lastLessonDate = await callOldGET('user/getLastLesson', true);

        if (lastLessonDate === undefined) {
            throw new Error ("Response of Users last lesson date is undefined!");
        }

        return lastLessonDate;
    } catch (error: any) {
        throw error;
    }
};

export const addFreeLesson =async () => {
        
    // try {

    //     const addFreeLesson = await callOldPOST('lezioneRegaloAP', {}, true);

    //     if (addFreeLesson === undefined) {
    //         throw new Error ("Response adding Free lesson is undefined!");
    //     }

    //     console.log("ADD FREE LESSON", addFreeLesson);
        
    //     return addFreeLesson;
    // } catch (error: any) {
    //     throw error;
    // }

    try {

        const freeLessonParams = {
            bandCode: store.getState().ui.brand?.code,
            paymentTypeId: 1
        };
        const addFreeLesson = await callPOST(`v1/publicpurchase/single/${getEnvironmentProductId('lezione in regalo')}`, freeLessonParams, true);

        if (addFreeLesson === undefined || !addFreeLesson) {
            throw new Error ("Response adding Free lesson is undefined!");
        }
        
        return addFreeLesson;
    } catch (error: any) {
        throw error;
    }
};