import { FC, useState } from 'react';

// assets
import { ReactComponent as StarSVG } from '../assets/star.svg';

// type
import { RatingStarsProps } from './RatingStars';

// utils
import { createArrayForMappingUse } from './utils/RatingStars.helper';

// style
import { Row } from 'style/layout';
import { StarBox } from './style/RatingStars.style';


/**
 * Rating stars
 * 
 * @author Frank Berni
 * 
 * @param numberOfStars - total number of stars
 * 
 * @returns 
 */


const RatingStars: FC<RatingStarsProps> = ({ numberOfStars, ratingHandler, currentRating }) => {

    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const useExternalRatingState = (!!ratingHandler && currentRating !== undefined);
    
    const starClickHandler = (index: number) => {
        
        if(useExternalRatingState) return ratingHandler(index + 1);
        setCurrentIndex(index);
    };
    
    const starsArray: number[] = createArrayForMappingUse(numberOfStars);

    const finalIndex = useExternalRatingState ? currentRating : currentIndex;

    return (
        <Row
            margin='30px 0 0'
            mainAxis='center'
            crossAxis='center'
            gap='5px'
        >
            {starsArray.map((_, index) => (
                <StarBox 
                    key={index}
                    isActive={index <= finalIndex}
                    onClick={() => starClickHandler(index)}
                >
                    <StarSVG />
                </StarBox>
            ))}
        </Row>
    );
};

export default RatingStars;
