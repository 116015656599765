import { FC } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

//utils
import { getEnvironmentAssetLinkUrl, getEnvironmentProductId } from 'configs/config.helper';

// assets
import cashAcademyIMG from './assets/cashAcademy.png';

// style
import {
    CardTitle,
    CashAcademyBox,
    CashAcademyButton,
    CashAcademyImg,
    Content,
    ButtonRow
} from './style/CashAcademyCard.style';
import { useNavigate } from 'react-router-dom';


const CashAcademyCard: FC<{}> = () => {

    const navigate = useNavigate();

    const cashAcademyButtonHandler = () => {
        navigate(`/public/product/${getEnvironmentProductId('cash academy')}`);
    };

    const { isAppMobile } = useAppSelector(state => state.ui);

    return (
        <CashAcademyBox>
            <Content>
                {isAppMobile && <CashAcademyImg src={getEnvironmentAssetLinkUrl(cashAcademyIMG)}/>}

                <CardTitle>Sei fortunato ci sono posti per la Cash Academy con Lezioni Private Infinite:</CardTitle>

                <ButtonRow>
                    {!isAppMobile && <CashAcademyImg src={getEnvironmentAssetLinkUrl(cashAcademyIMG)}/>}

                    <CashAcademyButton
                        onClick={cashAcademyButtonHandler}
                    >
                        SCOPRI DI PIÙ
                    </CashAcademyButton>
                </ButtonRow>
            </Content>
        </CashAcademyBox>
    );
};

export default CashAcademyCard;