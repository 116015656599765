import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette';


export const WhatsAppWaitingBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    max-width: 430px;

    padding: 25px 20px;

    background: ${colors.veryDarkGrey};
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;
    border: 0.5px solid ${colors.green};

    @media ${devices.tablet} {

        height: 145px;
        padding: 24px 40px;
    }
`;

export const WhatsAppWaitingBoxContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 15px;

    width: 100%;

    @media ${devices.tablet} {
        
    }
`;

export const WhatsAppWaitingTextContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WhatsAppCongratulationText = styled.span`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    color: ${colors.green};
`;

export const WhatsAppWaitingText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    
    color: ${colors.white};
`;

export const WhatsAppContributeButton = styled(Button)`
    font-size: 14px;
    line-height: 13px;
`;

export const WaitingCount = styled.span`
    color: ${colors.primaryColor};
    font-weight: 700;
    font-size: 15px;
`;