import { FC, useEffect, useState } from 'react';

// types
import { StepInfoProps } from './UserStep.component.d';

// style
import {
    StepBox,
    StepText,
    ChoiceRow,
    ChoiceButton,
    CTAButton
} from './style/UserStep.style';
import { callGET } from 'network/network';
import { useNavigate } from 'react-router-dom';
import { getUsersWarnings } from 'pages/LDL/HomePageLDL/services/HomePageLDL.services';
import useAppDispatch from 'hooks/useAppDispatch';
import { saveUserWarnings } from 'redux/actions/actions-user';
import { getEnvironmentProductId } from 'configs/config.helper';


const StepInfo: FC<StepInfoProps> = ({ 
    stepInfo,
    finalCTAButtonText,
    isChoiceOneClicked,
    isChoiceTwoClicked,
    isChoiceTwoInError,
    warningModalOpenHandler,
    newWarningSetting
}) => {
    
    const [stepData, setStepData] = useState<any>(stepInfo);
    const [displayCopy, setDisplayCopy] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    console.log("STEP DATA:", stepData);
    
    const ctaHandler = (productId: number, productType: string) => {
        
        if (productId === undefined || productId === null) return;
        if ((productType === 'LESSON_PACK') && (productId !== getEnvironmentProductId('autostrada'))) return navigate('/reservedArea/aiuto-privato');

        navigate(`/public/product/${productId}`);
    };

    const ctaLessonHandler = (productCopy: string) => {
        
        if (productCopy === undefined || productCopy === null) return;
        if ((productCopy === 'Prenota la tua lezione')) return navigate(`/reservedArea/lesson-page`);

        navigate('/reservedArea/aiuto-privato');
        
    };

    const onAnswerButtonClickHandler = async (answer: 'LEFT' | 'RIGHT') => {

        try {
            if (!stepData.id) return;
            const response = await getStepAnswerData(stepData.id, answer);

            if (response.warning !== null) {

                newWarningSetting();
                // const warning = await getUsersWarnings();
                dispatch( saveUserWarnings(response.warning) );
                warningModalOpenHandler();
            };

            if (response.step) {
                // if ((response.step.copyChoiceLeft === null) || (response.step.copyChoiceRight === null)) {
                //     const forwardChoice = (response.step.copyChoiceLeft === null) ? 'RIGHT' : 'LEFT';
                //     onAnswerButtonClickHandler(forwardChoice);
                //     return;
                // }
                setDisplayCopy(response.step.copy);
                setStepData(response.step);
                return;
            };

            setStepData(response);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const getStepAnswerData = async (stepId: number, answer: 'LEFT' | 'RIGHT') => {

        try {
            
            const response = await callGET(`v1/self/steps/${stepId}/${answer}`, true);
            if (response === undefined || !response) throw new Error("Error getting answer response data");

            return response
        } catch (error: any) {
            console.warn("ERROR:", error);
        }    
    };    

    useEffect(() => {

        if (stepInfo === undefined || !stepInfo) return;
        setDisplayCopy(stepInfo.copy);
        // if ((stepInfo.copyChoiceLeft === null) || (stepInfo.copyChoiceRight === null)) {
        //     const forwardChoice = (stepInfo.copyChoiceLeft === null) ? 'RIGHT' : 'LEFT';
        //     onAnswerButtonClickHandler(forwardChoice);
        // }
    }, [stepInfo]);
    
    return (
        <StepBox id='bonusFlow'>
            {displayCopy && <StepText dangerouslySetInnerHTML={{__html: displayCopy}} />}

            <ChoiceRow>
                {stepData.copyChoiceLeft && (stepData.copyChoiceLeft !== ' ') && (
                    <ChoiceButton 
                        onClick={() => onAnswerButtonClickHandler('LEFT')}
                        isActive={isChoiceOneClicked}
                    >
                        {stepData.copyChoiceLeft}
                    </ChoiceButton>
                )}

                {stepData.copyChoiceRight && (stepData.copyChoiceRight !== ' ') && (
                    <ChoiceButton
                        onClick={() => onAnswerButtonClickHandler('RIGHT')}
                        isActive={isChoiceTwoClicked}
                        isError={isChoiceTwoInError}
                    >
                        {stepData.copyChoiceRight}
                    </ChoiceButton>
                )}
            </ChoiceRow>

            {stepData.productId && !stepData.lessonPackType &&<CTAButton onClick={() => ctaHandler(stepData.productId, stepData.productType)}>{stepData.copy}</CTAButton>}

            {stepData.lessonPackType && <CTAButton onClick={() => ctaLessonHandler(stepData.copy)}>{stepData.copy}</CTAButton>}
        </StepBox>
    )
};

export default StepInfo;