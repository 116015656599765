import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Backdrop = styled.div`

    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    // real modal background here
    background: ${colors.transparent};

    display: none;

    @media ${devices.appMinWidth} {

        display: block;
    }
`;

// export const ModalWindow = styled.div`

//     width: 100%;
//     max-width: 100vw;
    
//     height: fit-content;
//     max-height: 100vh;

//     /* margin: auto; */
//     padding: 40px 0 0;
        
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 100;
    
//     display: none;
//     align-items: center;
//     justify-content: center;

//     overflow-y: auto;
//     overflow-x: auto;

//     @media ${devices.appMinWidth} {

//         display: flex;
//     }

//     @media ${devices.tablet} {

//         width: fit-content;
//         padding: 0;
//     }
// `;

export const ModalWindow = styled.div`

    width: 100%;
    max-width: 100vw;
    
    height: 100%;
    max-height: 100vh;

    /* margin: auto; */
    padding: 40px 0 0;
        
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    
    display: none;
    align-items: center;
    justify-content: center;

    overflow-y: auto;
    overflow-x: auto;

    ::-webkit-scrollbar {
            display: none;
        }

    -ms-overflow-style: none;
    scrollbar-width: none;

    @media ${devices.appMinWidth} {

        display: flex;
    }

    @media ${devices.tablet} {

        align-items: center;
        justify-content: center;
    
        padding: 0;
    }
`;

// export const ModalPanel = styled.div`

//     width: 100%;
//     max-width: 100vw;

//     height: auto;
//     // max - modal top padding
//     max-height: calc(100vh - 40px);

//     margin: 0 15px;

//     @media ${devices.tablet} {
//         width: auto;
//     }
// `;

export const ModalPanel = styled.div<{modalFixedStart?: boolean, width?: string, height?: string, paddingBottom?: string, overflow?: string}>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
    
    width: ${props => props.width ?? '100%'};
    max-width: 100vw;

    height: 100%;
    // max - modal top padding
    max-height: calc(100vh -80px);

    ${({paddingBottom}) => paddingBottom && css`padding-bottom: ${paddingBottom};`}

    margin: 0 15px;
    overflow-x: hidden;

    ::-webkit-scrollbar {
            display: none;
        }

    -ms-overflow-style: none;
    scrollbar-width: none;

    @media ${devices.tablet} {

        align-items: center;
        justify-content: center;

        overflow-x: auto;

        width: ${props => props.width ?? 'auto'};
        
        ${({modalFixedStart}) => modalFixedStart && (
            
            css`

                justify-content: flex-start;

                padding: 100px 0;
            `
        )}
    }
    ${({overflow}) => overflow && css`overflow-x: ${overflow}!important;`}
`;