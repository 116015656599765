import devices from "style/breakpoints";
import { Button } from "style/buttons";
import colors from "style/palette";
import styled, { css } from "styled-components";


export const HelpCardS = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: 100%;

    padding: 25px 30px;
    gap: 17px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 15px;

    @media ${devices.tablet}{       
        
        padding: 30px 35px 15px;
        gap: 20px;
    }
`;

export const HelpCards = styled.div`


    @media ${devices.tablet}{       
        
        display: flex;
        align-items: flex-start;
        
        flex-direction: row;

        width: 100%;
        max-width: 434px;
        padding: 0px;
        gap: 30px;
    }
`;

export const Intestation = styled.p`

    font-weight: 400;
    font-size: 13px;

    margin: 0 0 17px;
    padding:0px;

    display: flex;
    align-items: center;

    color: ${colors.white};

    @media ${devices.tablet}{       
    
        display: none;
    }
`;

export const IntestationDesktop = styled.p`

    display: none;

    @media ${devices.tablet}{       
        
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        display: flex;
        align-items: center;

        padding: 0px;
        margin: 0px;

        color: ${colors.white};
    }
`;

export const AutostradaDescription = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 0 25px;

    @media ${devices.tablet}{       
    
        padding: 0;
        
    }
`;

export const Text = styled.p`

    margin: 0px;
    padding:0px;

    font-weight: 400;
    font-size: 13px;

    color: ${colors.white};

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    overflow: hidden;

    @media ${devices.tablet}{ }
`;

export const Information = styled.div`

    display: flex;
    align-items: flex-start;
    
    flex-direction: row;
    
    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
    
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }
`;

export const LessonPhotoBox = styled.div`

    display: flex;

    flex-shrink: 0;

    width: 112px;
    height: 58px;

    border-radius: 20px;

    overflow: hidden;
    position: relative;

    @media ${devices.tablet} {
        
        width: 180px;
        height: 100px;
    }
`;

export const Poster = styled.img`

    position: absolute;
    
    width: auto;
    height: 150px;
    margin: auto;

    left: -100%;
    right: -100%;
    top: -60%;
    bottom: -100%;
`;

export const HelpCardDesktop = styled.div`

    //display: none;

    @media ${devices.tablet}{  
        
        display: flex;
        flex-direction: row;
        align-items: stretch;

        gap: 25px;

        flex-grow: 1;

    }
`;

export const PosterDesktop = styled.img`

    display: none;

    @media ${devices.tablet}{  
        
        display: flex;
    
        flex-shrink: 0;

        width: 182px;
        height: 100px;
        
        background-color: white;
        border-radius: 20px;
    }
`;

export const PriceGrid = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
    
        
    }
`;

export const PriceButton = styled.button`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 7px 5px 9px;

    width: 160px;
    max-width: 160px;
    height: 42px;

    background: ${colors.primaryColor};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 5px;
    border: none;

    cursor: pointer;
    
    @media ${devices.tablet}{     

    }
`;


export const PriceButtonAutostrada = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 7px 5px 9px;

    width: 160px;
    max-width: 160px;
    height: 42px;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 5px;
    border: none;
`;

export const PaypalPrice = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;

    padding: 0px;

    gap: 5px;

    position: relative;

    @media ${devices.tablet}{       
    
    }
`;

export const PriceButtonText = styled.p`

    font-weight: 500;
    font-size: 11px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: ${colors.black087};

    @media ${devices.tablet}{       
    
        
    }
`;

export const Price = styled.p`

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    font-style: italic;
    font-weight: 500;
    font-size: 11px;

    margin: 0px;
    padding: 0px;

    /* gap: 10px; */

    @media ${devices.tablet}{       
    
        
    }
`;

export const ButtonChoose = styled.button<{isOpen:boolean}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    padding: 10px 7px 8px;
    gap: 10px;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
    border: none;
    box-shadow: none;

    cursor: pointer;
    
    ${({isOpen}) => isOpen && 
        css` 
            border-radius: 5px 5px 0 0;
        `
    }
`;

export const ProductIconBox = styled.div`

    margin: -15px 0 0 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InfoIconBox = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;

    cursor: pointer;

    background: ${colors.black};

    border: 1px solid ${colors.white};
    border-radius: 100px;
    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
`;

