import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import config from 'configs/config';
import { getCurrentAuthUser, getUsersProducts } from 'services/user.services';
import { facebookConnect, postSvgStandardToFacebook } from 'services/facebook.services';

// components
import BaseModal from 'components/ui/BaseModal';
import LoadingMask from 'components/ui/LoadingMask';
import EmptyUserImageProfile from 'components/EmptyUserImageProfile';
import FacebookLoginButton from 'components/ui/FacebookLoginButton';

// assets
import { ReactComponent as ShareIconSVG } from './assets/shareIcon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as UserIcon } from './assets/user-icon.svg';
import ShareImageCallSVG from './assets/shareCallImage.svg'

// style
import { Row } from 'style/layout';
import {
    Content,
    CloseButton,
    MainBox,
    MainBoxConnect,
    ImageToShare,
    CommentColumn,
    CommentText,
    CommentBox,
    ShareButton,
    Disclaimer,
    UserImageProfile,
    UserNameRow,
    LoadingModal
 } from './style/ShareNeedCallModal.style';
import { useIsMounted } from 'usehooks-ts';
import { callOldGET, callOldPOST } from 'network/network';
import { getEnvironmentAssetLinkUrl, getEnvironmentBaseUrl, getEnvironmentProductId } from 'configs/config.helper';
import { AxiosResponse } from 'axios';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import useAppDispatch from 'hooks/useAppDispatch';
import { createActiveHelpLesson, getUserFacebookGroups, updateFacebookDataOnDB } from './services/ShareNeedCallModal.services';
import { postSvgToFacebook } from 'services/facebook.services';
import { enableFacebookExceptioModal } from 'redux/actions/actions-app-main';
import { removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';

const imageURL = 'https://www.viveredipoker.it/ldlapifile/Varie/Post_call_regalo.png';

 const FB_GROUP_ID = () => {

    if (!config || config === undefined) return;
    if (!config.socialLinks || config.socialLinks === undefined) return;
    if (config.environment === 'production') return config.socialLinks.fbGroupID;
    return config.socialLinks.fbGroupTestID;
};


const ShareNeedCallModal: FC<{
    onCloseButtonModal: () => any,
    refreshFunction?: () => any
}> = ({ 
    onCloseButtonModal,
    refreshFunction
}) => {

    const { isFacebookExceptionModalVisible } = useAppSelector(state => state.appMain);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { email } = useAppSelector(state => state.user);
    const { tokenID } = useAppSelector(state => state.user)
    const { products } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [commentInput, setCommentInput] = useState<string | undefined>('Voglio capire meglio come funziona il percorso di Liberi dal Lavoro...');
    const [userData, setUserData] = useState<any>({});
    const [hasUserFacebookMissionCompleted, setHasUserFacebookMissionCompleted] = useState<boolean>(false);
    const [isUserInFacebookGroup, setIsUserInFacebookGroup] = useState<boolean | undefined>(undefined);
    const [isUserDataLoading, setIsUserDataLoading] = useState<boolean | undefined>(true);
    const [isFacebookGroupsDataLoading, setIsFacebookGroupsDataLoading] = useState<boolean | undefined>(undefined);
    const [isSharingLoading, setIsSharingLoading] = useState<boolean>(false);
    const [userHasBonusSicuroCourse, setUserHasBonusSicuroCourse] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    
    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const commentInputChangeHandler = (event: any) => setCommentInput(event.target.value);

    //URL IMMAGINE PER POST FACEBOOK
    const svgImageUrl = ShareImageCallSVG;
    const facebookButtonClickHandler = () => {
        if (email) facebookConnect(false, email);
    }
    const facebookGroupJoinClickHandler = () => {
        window.open (
            `https://www.facebook.com/groups/${FB_GROUP_ID()}`,
            '_blank',
            'noopener noreferrer'
        );
    };

    const createLessonAfterSuccess = async () => {
        try {
            await createActiveHelpLesson();
            console.log("Lezione attiva di Aiuto creata con successo");
        } catch (error) {
            console.error("Errore durante la creazione della lezione attiva di Aiuto: ", error);
        }
    };

    const shareToFacebookHandler = () => {
        console.log("Entro nello share");
        
        setIsSharingLoading(true);
        const fbData = getUserConnectedAccountData(userData, 'Facebook')[0];
        console.log("Ho l'account FB?", fbData);
        const snackbarData: SnackbarOptions = {};
        
        if (!fbData) {
            setIsSharingLoading(false);
            return;
        }
        
        let successHandler = async (res: AxiosResponse) => {
            console.log("Entro in succes");

            snackbarData.type = 'success';
            snackbarData.message = ("RICHIESTA CONDIVISA (!) Bravo. La vedrai presto nel gruppo Facebook.");
            dispatch( addSnackbar(snackbarData) );
            setIsCloseButtonClicked(true);            
            setIsSharingLoading(false);
            // hasPubblicToFacebook();

            // await createLessonAfterSuccess();
            
            if (refreshFunction) refreshFunction();
        };
        
        let errorHandler = (res: AxiosResponse) => {
            console.log("Entro in Error");
            snackbarData.type = 'error';
            snackbarData.message = ("C'è stato un problema nella condivisione, riprova più tardi.");
            dispatch( addSnackbar(snackbarData) );
            setIsCloseButtonClicked(true);
            setIsSharingLoading(false);
        };

        let caption = "Ciao a tutti (!)\nHo appena richiesto la mia prima call con un Assistente Personale, il motivo è che:\n";
        if (commentInput) caption += commentInput;
        
        caption += `\n\n\n\nAnche tu puoi richiedere una Call In Regalo sulla Home page del nostro sito. Iscriviti ora:\n${getEnvironmentBaseUrl("/app/reservedArea")}\n\n#VoglioUnAssistentePersonale`;
    
        if (tokenID) {
            console.log("Entro nel post SVG");
            postSvgStandardToFacebook(tokenID, imageURL, caption, successHandler, errorHandler, refreshFunction); 
        }
        console.log("TokenID", tokenID);
        

    };

    const checkUserConnectedAccount = (userData: any, accountType: string) => {
        
        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;
        
        return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };

    const getUserPictureUrl = (userData: any, accountType: string) => {
        if (!userData || userData === undefined) return;
        if (!userData.federateds || userData.federateds === undefined) return;

        const userAccountData = userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
        if (userAccountData.length === 0) return;
        
        return JSON.parse(userAccountData[0].pictureOnFederated).data.url;
    };

    const getUserFederatedName = (userData: any, accountType: string) => {
        if (!userData || userData === undefined) return;
        if (!userData.federateds || userData.federateds === undefined) return;

        const federatedData = userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
        if (federatedData.length === 0) return;

        return federatedData[0].nameOnFederated;
    };

    const getUserConnectedAccountData = (userData: any, accountType: string) => {

        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;

        return userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };
   
    const updateFacebookMissionOnBackend = async (backendStatus: boolean, facebookStatus: boolean) => {
        
        if ((backendStatus === undefined) || (facebookStatus === undefined)) return;
        if (backendStatus === facebookStatus) return;

        if (facebookStatus) {
            
            await callOldGET('user/misFacebookON', true);
            return;
        }

        await callOldGET('user/misFacebookOFF', true);
    };

    const getFacebookMission = async () => {

        try {
            const currenUserData = await callOldGET('user/getCurrentUser', true);
            if (!currenUserData || currenUserData === undefined) {
                
                setHasUserFacebookMissionCompleted(false);
                return;
            };

            const facebookMissionStatus = currenUserData.misFacebook2022;

            if (!facebookMissionStatus || 
                (facebookMissionStatus === undefined) ||
                (facebookMissionStatus === null)
            ) {
                setHasUserFacebookMissionCompleted(false);
                return;
            }

            setHasUserFacebookMissionCompleted(facebookMissionStatus);
        } catch (error: any) {

            console.warn("Unable to get Facebook mission status.", error);
            setHasUserFacebookMissionCompleted(false);
        }
    };

    const getUserData =async () => {
        
        setIsUserDataLoading(true);
        try {
            
            const userAuthData = await updateFacebookDataOnDB();
            
            console.log("USER DATA:", userAuthData);
            setUserData(userAuthData);
            setIsUserDataLoading(false);
        } catch (error: any) {
            setIsUserDataLoading(false);
            console.warn("Unable getting User data.", error);
        }
    };
    
    const checkUserFacebookGroupConnection =async () => {
        
        setIsFacebookGroupsDataLoading(true);
        const environment = config.environment;
        let ldlFbID: any;
        if (environment === 'production') ldlFbID = config.socialLinks?.fbGroupID;
        if (environment === 'test' || environment === 'development') ldlFbID = config.socialLinks?.fbGroupTestID;
        const fbData = getUserConnectedAccountData(userData, 'Facebook')[0];
        
        if (!fbData) {

            // to do if (fbMission) call db for set false state to fbMission
            setIsUserInFacebookGroup(false);    
            setIsFacebookGroupsDataLoading(false);
            return
        }
        
        const snackbarData: SnackbarOptions = {};

        try {
            const response = await getUserFacebookGroups();
            if (!isMounted) return;

            if (!response || response === undefined) return;
            
            if (response.message) {

                if (response.message.includes('Error getting facebook groups.')) {
                    console.log("7");

                    // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, false);
                    setIsUserInFacebookGroup(false);    
                    setIsFacebookGroupsDataLoading(false);
        
                    dispatch( enableFacebookExceptioModal() );
                    return;
                };
            };

            const userIsInFacebookGroup = response.some((group: any) => group.id === ldlFbID);
            setIsUserInFacebookGroup(userIsInFacebookGroup);
            // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, userIsInFacebookGroup);
            setIsFacebookGroupsDataLoading(false);
        } catch (error: any) {

            if (error.response.status === 404) dispatch( enableFacebookExceptioModal() );

            // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, false);
            setIsUserInFacebookGroup(false);    
            setIsFacebookGroupsDataLoading(false);
            if (error.code === 'ERR_BAD_REQUEST') {
                
                snackbarData.type = 'warning';
                snackbarData.message = ("C'è stato un problema, il tuo browser sembra aver bloccato la condivisione, prova a seguire quest istruzioni: https://bit.ly/Sito_Sicuro");
                dispatch( addSnackbar(snackbarData) );
    
                return;
            };
            
            console.warn("Unable to check if User is in facebook group.", error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {

        if (!userData) return;
        // getFacebookMission();
        checkUserFacebookGroupConnection();
    }, [userData]);

    useEffect(() => {
        if (isFacebookExceptionModalVisible) onCloseButtonModal();
    }, [isFacebookExceptionModalVisible]);

    useEffect(() => {
        const courseList = products?.courses;
        const hasBonusSicuroCourse = (courseList !== undefined) ? courseList.some((course: any) => course.id === getEnvironmentProductId('bonus sicuro')) : false;
        setUserHasBonusSicuroCourse(hasBonusSicuroCourse);
        setCommentInput(hasBonusSicuroCourse ? 'Ho acquistato Bonus Sicuro e ho bisogno di una mano per applicare i contenuti del corso...' : 'Voglio capire meglio come funziona il percorso di Liberi dal Lavoro...');
    }, [products]);

    const hasUserFacebook = checkUserConnectedAccount(userData, 'Facebook');
    const nameOnFederated = getUserFederatedName(userData, 'Facebook');
    const facebookProfilePicture = getUserPictureUrl(userData, 'Facebook');
    const isLoading = isUserDataLoading || isFacebookGroupsDataLoading || isSharingLoading;

    useEffect(() => {
        if (!hasUserFacebook || !isUserInFacebookGroup) {
            setItemToLocalStorage("Missing Facebook account", "true");
            // setItemToLocalStorage("Missing pubblic Facebook", "true");
        } else {
            removeItemFromLocalStorage("Missing Facebook account")
            // removeItemFromLocalStorage("Missing pubblic Facebook")
        }
    }, [hasUserFacebook, isUserInFacebookGroup]);

    if (isLoading) return (
        <LoadingModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            <LoadingMask 
                size='big'
                isCenter
                paddingTop='200px'
                paddingBottom='200px'
                // width='900px'
            />
        </LoadingModal>
    );

    if (isFacebookExceptionModalVisible) return <></>;

    if (!hasUserFacebook) return (
        
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <MainBoxConnect>
                    <CommentText>
                        Per poter richiedere la tua call sul Gruppo<br />
                        devi prima collegare il tuo account Facebook:
                    </CommentText>

                    <EmptyUserImageProfile 
                        flavor='facebook'
                        isEditButtonDisabled
                    />

                    <FacebookLoginButton
                        onClick={facebookButtonClickHandler}
                    >
                        collega facebook
                    </FacebookLoginButton>
                </MainBoxConnect>
            </Content>
        </BaseModal>
    );

    if (!isUserInFacebookGroup) return (
        
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <>
                    <MainBoxConnect>
                        <CommentText>
                            Per poter poter richiedere la Tua call gratuita, iscriviti al nostro Gruppo Facebook:
                        </CommentText>

                        <UserImageProfile
                            flavor='facebook'
                            src={facebookProfilePicture}
                            alt="User profile picture"
                        />

                        <UserNameRow>
                            <UserIcon />

                            <span>
                                {nameOnFederated}
                            </span>
                        </UserNameRow>

                        <FacebookLoginButton
                            onClick={facebookGroupJoinClickHandler}
                        >
                            entra nel gruppo facebook
                        </FacebookLoginButton>
                        
                        <CommentText>
                            Se sei già nel nostro Gruppo Facebook è possibile che il tuo Browser stia<br />
                            bloccando alcune funzioni: 
                                <a 
                                    href='https://bit.ly/Sito_Sicuro' 
                                    target='_blank'
                                    rel='noopener noreferrer'    
                                >segui queste Istruzioni</a>.
                        </CommentText>

                    </MainBoxConnect>
                </>
            </Content>
        </BaseModal>
    );

    return (
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <MainBox>
                    {/* <ImageToShare 
                        src={svgImageUrl}
                    /> */}

                    <CommentColumn>
                        {!userHasBonusSicuroCourse &&
                            <CommentText>
                                In Liberi dal Lavoro abbiamo gli assistenti personali che ti aiutano a iniziare col piede giusto.
                                <br />
                                <br />
                                Per ricevere una chiamata in Regalo con uno dei nostri assistenti, occorre scrivere i motivi per i quali richiedi la call e condividere il post su Facebook.
                                <br />
                                <br />
                                I motivi possono anche essere semplicemente "Voglio capire meglio come funziona il percorso di Liberi dal Lavoro", oppure puoi scrivere qualcosa di più specifico. Tutte le informazioni che scrivi permettono all'Assistente Personale di capire meglio come aiutarti.
                            </CommentText>}

                        {userHasBonusSicuroCourse &&
                            <CommentText>
                                In Liberi dal Lavoro abbiamo gli assistenti personali che ti aiutano a iniziare col piede giusto.
                                <br />
                                <br />
                                Per ricevere una chiamata in Regalo con uno dei nostri assistenti, occorre scrivere i motivi per i quali richiedi la call e condividere il post su Facebook.
                                <br />
                                <br />
                                I motivi possono anche essere semplicemente "Ho acquistato Bonus Sicuro e ho bisogno di una mano per applicare i contenuti del corso...", oppure puoi scrivere qualcosa di più specifico. Tutte le informazioni che scrivi permettono all'Assistente Personale di capire meglio come aiutarti.
                            </CommentText>}

                        <CommentBox 
                            placeholder="Aggiungi un commento..."
                            value={commentInput}
                            onChange={commentInputChangeHandler}
                        />

                        <ShareButton onClick={shareToFacebookHandler}>
                            <ShareIconSVG />

                            Condividi sul gruppo
                        </ShareButton>
                    </CommentColumn>
                </MainBox>
            </Content>
        </BaseModal>
    );
}

export default ShareNeedCallModal;
