import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Page = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px 20px 20px;

    @media ${devices.tablet} {
        padding: 40px 40px 20px;
    }
`;

export const MailInfo = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 7px;
`;

export const InfoText = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: ${colors.white};
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: 25px; 

    @media ${devices.tablet} {
    }
`;

export const MailText = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: ${colors.primaryColor};
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: 25px; 

    cursor: pointer;

    @media ${devices.tablet} {
    }
`;