import { FC, useState } from 'react';

//style
import {
    WaitTitleBox,
    WaitTitle,
    WeitTextBox,
    WaitText
} from './style/ThirdStepBankTransfer.style';

const ThirdStepBankTransfer: FC<{fileUploaded: boolean, productName?: string}> = ({fileUploaded, productName}) => {

    const isUpload = fileUploaded;
    
    return (
        <>
            <WaitTitleBox>
                {isUpload && <WaitTitle >ATTENDI :)</WaitTitle>}

                {!isUpload && <WaitTitle isActive={!isUpload}>RICORDA DI INVIARE LA CONTABILE PER COMPLETARE IL TUO ACQUISTO (!)</WaitTitle>}        
            </WaitTitleBox>

            <WeitTextBox>
                {isUpload && 
                    <WaitText>
                        Ora che hai completato correttamente tutti gli step, devi solamente attendere che ti venga accreditato il prodotto {productName}.
                        <br />
                        <br />
                    </WaitText>}

                {!isUpload && 
                    <WaitText>
                        Invia la contabile del bonifico a info@liberidallavoro.it in modo che ti venga accreditato il prodotto {productName}.
                        <br />
                        <br />
                    </WaitText>}

                <WaitText>
                    La verifica e la gestione dei bonifici richiedono inteventi manuali dal parte del nostro staff.
                    <br />
                    Oltre ai tempi tecnici delle banche, pertanto, prima dell'accredito del prodotto si possono aggiungere tempi tecnici ulteriori.
                    <br />
                    <br />
                </WaitText>

                <WaitText>
                    In totale, per l'accredito del prodotto acquistato, possono passare fino a 10 giorni dal momento in cui hai effettuato il pagamento.
                </WaitText>
            </WeitTextBox>
        </>
    );
};

export default ThirdStepBankTransfer;