import { isTextEmpty } from 'utils/inputValidation';


export const validateNameInput = (nameInput: string) => {
    console.log("NAME:", nameInput);
    
    if (isTextEmpty(nameInput)) return "Il nome non può essere vuoto";
    return;
};

export const validateSurnameInput = (surnameInput: string) => {

    if (isTextEmpty(surnameInput)) return "Il cognome non può essere vuoto";
    return;
};

export const validateCodeInput = (codeInput: string) => {

    if (isTextEmpty(codeInput)) return "Il codice fiscale non può essere vuoto";
    return;
};

export const validateAddressInput = (addressInput: string) => {

    if (isTextEmpty(addressInput)) return "L'indirizzo non può essere vuoto";
    return;
};

export const validateCapInput = (capInput: string) => {

    if (isTextEmpty(capInput)) return "Il CAP non può essere vuoto";
    return;
};

export const validateMnicipalityInput = (municipalityInput: string) => {

    if (isTextEmpty(municipalityInput)) return "Il Comune non può essere vuoto";
    return;
};

export const validateProvinceInput = (provinceInput: string) => {

    if (isTextEmpty(provinceInput)) return "ILa Provincia non può essere vuoto";
    return;
};

export const validateNationInput = (provinceInput: string) => {

    if (isTextEmpty(provinceInput)) return "ILa Provincia non può essere vuoto";
    return;
};

export const validatePrivacyAndCookiesCheckboxInput = (isPrivacyAndCookiesInputChecked: boolean) => {

    if (!isPrivacyAndCookiesInputChecked) {
        return "Per poterti registrare devi visionare e accettare le condizioni su privacy e cookies";
    }

    return;
};
