import { FC, FormEvent, useState, useCallback } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// components
import TextInput from 'components/ui/TextInput';

// utils
import {
    validateEmailInput,
    validatePasswordInput,
    validateRepeatedPasswordInput,
    validateConfirmCodeInput
} from 'utils/inputValidation';
/* to do remove later after aws centralization of errors */
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { changePasswordWithConfirmation } from './services/PasswordChangeForm.services';
import { ChangePasswordWithConfirmationPayload } from './services/PasswordChangeForm.services.d';

// style
import {
    Form,
    UserInputsBox,
    LoginButton
} from './style/PasswordChangeForm.style';


/**
 * Password change form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const PasswordChangeForm: FC<{}> = () => {

    const [emailInput, setEmailInput] = useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
    const [passwordInput, setPasswordInput] = useState<string>("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>(undefined);
    const [repeatedPasswordInput, setRepeatedPasswordInput] = useState<string>("");
    const [repeatedPasswordErrorMessage, setRepeatedPasswordErrorMessage] = useState<string | undefined>(undefined);
    const [confirmCodeValue, setConfirmCodeValue] = useState<string>("");
    const [confirmCodeErrorMessage, setConfirmCodeErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();

    const validateEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setEmailErrorMessage(error);

        return error;
    };

    const emailInputChangeHandler = (newEmail: string) => {

        validateEmailHandler(newEmail);
        setEmailInput(newEmail);
    };

    const validatePasswordHandler = (newPassword: string) => {

        const error = validatePasswordInput(newPassword);
        setPasswordErrorMessage(error);

        return error;
    };

    const passwordInputChangeHandler = (newPassword: string) => {

        validatePasswordHandler(newPassword);
        setPasswordInput(newPassword);
    };

    const validateRepeatedPasswordHandler = (newRepeatedPassword: string, prevPassword: string) => {

        const error = validateRepeatedPasswordInput(newRepeatedPassword, prevPassword);
        setRepeatedPasswordErrorMessage(error);

        return error;
    };
    
    const repeatedPasswordInputChangeHandler = useCallback((newRepeatedPassword: string) => {

        validateRepeatedPasswordHandler(newRepeatedPassword, passwordInput);
        setRepeatedPasswordInput(newRepeatedPassword);

    }, [passwordInput]);

    const validateConfirmCodeHandler = (newConfirmCode: string) => {

        const error = validateConfirmCodeInput(newConfirmCode);
        setConfirmCodeErrorMessage(error);

        return error;
    };

    const confirmCodeInputChangeHandler = (newConfirmCode: string) => {

        validateConfirmCodeHandler(newConfirmCode);
        setConfirmCodeValue(newConfirmCode);
    };

    const cleanFormInputs = () => {

        setEmailInput("");
        setEmailErrorMessage(undefined);
        setPasswordInput("");
        setPasswordErrorMessage(undefined);
        setRepeatedPasswordInput("");
        setRepeatedPasswordErrorMessage(undefined);
        setConfirmCodeValue("");
        setConfirmCodeErrorMessage(undefined);
    };

    const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (isLoading) return;
        const emailError = validateEmailHandler(emailInput);
        if (emailError) return;
        const passwordError = validatePasswordHandler(passwordInput);
        if (passwordError) return;
        const repeatedPasswordError = validateRepeatedPasswordHandler(repeatedPasswordInput, passwordInput);
        if (repeatedPasswordError) return;
        const confirmCodeError = validateConfirmCodeHandler(confirmCodeValue);
        if (confirmCodeError) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {

            const userPayload: ChangePasswordWithConfirmationPayload = {
                
                username: emailInput,
                code: confirmCodeValue,
                newPassword: passwordInput
            };

            const response = await changePasswordWithConfirmation(userPayload);
            if (!isMounted()) return;
            
            if (response === undefined || !response) {
                throw new Error();
            }

            cleanFormInputs();
            snackbarData.type = 'success';
            snackbarData.message = "Password cambiata con successo";
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
            navigate('/login', { replace: true });

        } catch (error: any) {
            
            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    };

    return (
        <Form onSubmit={formSubmitHandler}>
            <UserInputsBox>
                <TextInput
                    label="Email:"
                    type="text"
                    flavor="filled"
                    value={emailInput}
                    errorMsg={emailErrorMessage}
                    onChange={emailInputChangeHandler}
                    onValidate={() => validateEmailHandler(emailInput)}
                />

                <TextInput
                    label="Password:"
                    type="password"
                    flavor="filled"
                    value={passwordInput}
                    errorMsg={passwordErrorMessage}
                    onChange={passwordInputChangeHandler}
                    onValidate={() => validatePasswordHandler(passwordInput)}
                />

                <TextInput
                    label="Ripeti Password:"
                    type="password"
                    flavor="filled"
                    value={repeatedPasswordInput}
                    errorMsg={repeatedPasswordErrorMessage}
                    onChange={repeatedPasswordInputChangeHandler}
                    onValidate={() => validateRepeatedPasswordHandler(repeatedPasswordInput, passwordInput)}
                />

                <TextInput
                    label="Codice di verifica:"
                    flavor="filled"
                    value={confirmCodeValue}
                    errorMsg={confirmCodeErrorMessage}
                    onChange={confirmCodeInputChangeHandler}
                    onValidate={() => validateConfirmCodeHandler(confirmCodeValue)}
                />
            </UserInputsBox>

            <LoginButton
                type="submit"
                value="login"
                disabled={isLoading}
            >
                Log In
            </LoginButton>
        </Form>
    );
};

export default PasswordChangeForm;
