export const TESTIMONIALS_IMAGES = [
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t1.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t2.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t3.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t4.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t5.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t6.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t7.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t8.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t9.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t10.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t11.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t12.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t13.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t14.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t15.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t16.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t17.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t18.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t19.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t20.png'
    },
    {
        imgSRC: 'https://www.viveredipoker.it/CorsoBonusSicuro3/t21.png'
    },
];
