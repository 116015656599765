import { FC } from 'react';

// style
import { 
    CaptionContent,
    ContentTitle,
    StepTitle,
    TextSeparator,
    StepName,
    PageTitle,
    Content,
    BoldText,
    SubTitle,
    ItalicText
} from './style/CaptionText.style';


/**
 * Caption text
 * 
 * @author Frank Berni
 * 
 * @returns JSX
 */


const CaptionText: FC<{}> = () => {

    return (
        <CaptionContent>
            <ContentTitle>
                <StepTitle>Intoduzione</StepTitle>

                <TextSeparator>/</TextSeparator>

                <StepName>Benvenuto</StepName>
            </ContentTitle>

            <PageTitle>Benvenuto in Autostrada per Secret Bonus!</PageTitle>

            <Content>
                <p><BoldText>Sei fortunato (!)</BoldText><br />
                Al momento ci sono posti liberi per l’Autostrada, 
                il percorso di chi vuole GRANDI RISULTATI e li vuole SUBITO.</p>

                <p>Autostrada per Secret Bonus è l'unico percorso che, <BoldText>da anni,</BoldText> 
                porta a Liberarsi dal Lavoro in 10 settimane con record di persone 
                che sono riuscite anche in 4 settimane…<br />
                La cosa interessante è che NON sei TU a doverti 
                adattare al percorso, è <BoldText>il percorso che si adatta a TE.</BoldText><br /> 
                Proprio per questo, Autostrada durerà 
                tutto il tempo necessario a raggiungere <BoldText>l’obiettivo garantito: </BoldText>
                arrivare a Secret Bonus e quindi <BoldText>Liberarti dal Lavoro.</BoldText></p>

                <p><SubTitle>Come Funziona Autostrada per Secret Bonus?</SubTitle><br/>
                Ti viene assegnato un Assistente Personale che vedrai settimanalmente. <br />
                Il Tuo assistente ti indicherà esattamente cosa fare ogni settimana e, 
                se seguirai le sue indicazioni, arriverai anche tu a Secret Bonus come 
                tanti altri prima di te…</p>

                <p><SubTitle>Quanto dura Autostrada per Secret Bonus?</SubTitle><br />
                Tutto il tempo che ti serve.<br />
                Hai infinite lezioni* fino a che non raggiungi il tuo obiettivo.<br />
                Normalmente le persone completano autostrada in meno di 10 lezioni, 
                alcuni addirittura in 4/5 lezioni, ma puoi <BoldText>prenderti i tuoi tempi, senza stress.</BoldText></p>

                <p><BoldText>ATTENZIONE: </BoldText>l’Assistente Personale è a tua disposizione 
                MA non fa il percorso per te.<br />
                Ti aiuta, è al tuo servizio, <BoldText>MA sei TU a dover fare il percorso…</BoldText><br />
                <ItalicText>“L’unica cosa che Liberi dal Lavoro non può fare, è fare il percorso 
                al posto tuo.” </ItalicText>Cit. Paolo Luini<br />
                <ItalicText>“Per guadagnare con i Bonus, bisogna farli.” </ItalicText>Cit. Flavio Catapano</p>

                <p><SubTitle>NOTE IMPORTANTI:</SubTitle><br />
                *Le lezioni sono infinite a patto che tu ti presenti regolarmente 
                e svolgi i task assegnati con impegno.<br />
                L’assistente personale può chiudere la tua autostrada in due casi:<br />
                <BoldText>1) SUCCESSO:</BoldText> sei arrivato a Multi Bonus, hai tutto chiaro e ora puoi passare a Secret
                (oppure devi solo accumulare i fondi)<br />
                <BoldText>2) INSUCCESSO, ritenta:</BoldText> non stai facendo il percorso e quindi 
                NON siamo in grado di garantire il risultato, questo avviene in alcuni casi specifici
                a) non ti presenti alle lezioni<br />
                b) non svolgi i task assegnati <br />
                c) molli il percorso per dedicarti ad altro, 
                emergenze famigliari o altri interessi</p>

                <p>È importante ricordare che <BoldText>AUTOSTRADA garantisce il risultato 
                SOLO SE ti impegni nel percorso</BoldText>, se NON ti presenti alle lezioni 
                o non fai il percorso indicato, la Tua Autostrada verrà considerata terminata 
                e l’assistente verrà allocato ad un altro utente in attesa della sua Occasione…</p>

                <p>Il successo di Autostrada dipende da te e la quota pagata 
                è considerata un “gettone” per poter avere questa occasione, 
                <BoldText>il gettone NON è rimborsabile in nessun caso…</BoldText><br />
                Pensaci bene, è vero che puoi entrare in Autostrada anche più volte, 
                pagando un gettone ogni volta, e quindi in caso di Insuccesso puoi ricominciare, 
                però è anche vero che <BoldText>c’è una coda, un tempo di attesa.</BoldText></p>

                <p>Se hai avuto la fortuna di trovare posto in Autostrada, 
                <BoldText>NON sprecare questa occasione, IMPEGNATI, dai il massimo,</BoldText> 
                puoi diventare anche TU un iscritto che posta <BoldText>guadagni a 5 cifre sul gruppo…</BoldText></p>
                <SubTitle></SubTitle>
            </Content>
        </CaptionContent>
    );
};

export default CaptionText;
