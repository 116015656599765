import { FC, useEffect, useState } from 'react';

// components
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';

// assets
import { ReactComponent as PuntiLibertaSVG } from './assets/puntiLiberta.svg';
import { ReactComponent as BulletCheck } from './assets/bulletCheck.svg';

// style
import { 
    BulletBox,
    BulletPoint,
    BulletRow,
    ButtonContainer,
    ButtonPoint,
    ButtonPointContainer,
    ButtonShopContainer,
    ButtonUp,
    Card,
    CourseDescriptionContainer,
    DescriptionBox,
    DescriptionTitle,
    FirstRow,
    ImageTextBox,
    ImportantPhrase,
    InfoText,
    MainText,
    MainTitle, 
    Page, 
    PointCounter, 
    PointCounterCard, 
    PointCounterChange, 
    SimpleText, 
    SimpleTextLight, 
    ThanksText, 
    Title, 
    TitleCard, 
    Totalpage, 
    VideoBox
} from "./style/LibertyPoint.style";

import { callGET } from 'network/network';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import LoadingMask from 'components/ui/LoadingMask';
import { formattedCurrency } from 'utils/supportFunctions';
import HorizontalSlider from 'components/HorizontalSlider/HorizontalSlider.component';
import MediaPlayer from 'components/MediaPlayer';
import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import store from 'redux/store';
import useBrand from 'hooks/useBrand';


const VideoBoxComponent: FC<{ videoUrl: string, posterUrl: string }> = ({ videoUrl, posterUrl }) => (
    <VideoBox id="top">
        <MediaPlayer
            media={{ src: videoUrl, type: "video/mp4" }}
            preload='metadata'
            poster={posterUrl}
        />
    </VideoBox>
);

const VideoBoxMemoized = React.memo(VideoBoxComponent);

const LibertyPoint: FC<{
    onTransferClick?: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    onLibertyClick?: () => void,
    onCloseButtonModal?: () => any,
    isUserLoggedIn?: boolean,
    isOpenInModal?: boolean | undefined
}> = ({
    onTransferClick,
    onLibertyClick,
    onCloseButtonModal,
    isUserLoggedIn,
    isOpenInModal,
}) => {

    const [selectionLiberty, setSelectionLiberty] = useState <number>(25);
    const [isPointEditable, setIsPointEditable] = useState (false);
    const [productList, setProductList] = useState<any>(undefined);
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [selectedButton, setSelectedButton] = useState<number>(0);

    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const onSliderChangeHandler = (newValue: number) => setSelectionLiberty(newValue);

    const { isAppMobile } = useAppSelector(state => state.ui);

    const videoPosterUrl = "https://www.viveredipoker.it/ldlapifile/products/salespages/videocovers/Punti_liberta.png";
    
    const localMedia = {
        src:  "https://www.viveredipoker.it/LaVoceDellaRicchezza/PuntiLiberta.mp4", 
        type: "video/mp4"
    };

    const scrollUpHandler = () => {
        window.scroll({
            top: 0, 
            behavior: "smooth"
        })
    }

    const scrollUpHandlerMobile = () => {
        const scrollToTop = () => {
          const topElement = document.getElementById('top');
          if (topElement) {
            topElement.scrollIntoView({ behavior: 'smooth' });
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        };
        scrollToTop();
    };

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };


    const getProduct = async () => {

        try {

            const lessonParams = {
                brandCode: store.getState().ui.brand?.code,
                types: 'CREDIT_PACK'
            };

            const response = await callGET('v1/productsforsell/bytypes', true, lessonParams); 

            setProductList(response);
            setIsLoading(false);

        } catch (error:any) {
            setProductList([]);
            setIsLoading(false);
            console.warn(error);
        }
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const code = event.code;
    
        if (code === 'Enter' || code === 'NumpadEnter') {
            if (+event.currentTarget.value < 100)  setSelectionLiberty(82);
            if (+event.currentTarget.value > 5000)  setSelectionLiberty(5000);
            onChange(event) 
            setIsPointEditable(false)
        }
    
    };

    const onBlurHandler = (event: any) => {
        if (+event.target.value < 100)  setSelectionLiberty(82);
        if (+event.target.value > 5000)  setSelectionLiberty(5000);
        if ((+event.target.value >= 100) && (+event.target.value <= 5000)) {
            onChange(event);
            setSelectedButton(0); // Disattivo il ButtonPoint selezionato quando il valore di PointCounterChange viene cambiato manualmente
        }
        setIsPointEditable(false);
    };

    const onChange = (event : any) => {
        setSelectionLiberty(+event.target.value);
    };
    
    const onClickSelectionPoint = (amount : number) => {
        setSelectionLiberty(amount);
        setSelectedButton(amount)
    };
    
    const onCloseModalHandler = () => {
        closeBankTransferModal();
    }
    
    const CalculateLibertyPrice = () => {
        if (productList === undefined ) return 0
        if (productList?.length === 0 ) return 0
        const pointPrice = productList[0]?.productCreditPack.pricePerPoint
        const resultPrice =  pointPrice*selectionLiberty;
        return resultPrice;
    };

    const CalculateLibertyPriceMB = () => {
        const resultPrice =  1.33*selectionLiberty;
        return resultPrice;
    };

    
    useEffect(() => {
        getProduct()
    },[useBrand()]);

    // const isLoading = (productList === undefined);

    if (isLoading) return <LoadingMask isCenter paddingTop='200px' size='page'/>

    return (
        <>
            {isAppMobile && (
                <VideoBox id="top">
                    <VideoBoxMemoized videoUrl={localMedia.src} posterUrl={videoPosterUrl} />
                </VideoBox>
            )}
            <Page>
            {!isAppMobile && (
                <Title>
                    Acquista Punti Libertà

                    {/* <CopySVG onClick={copyIntoClipboardHandler} /> */}
                </Title>
            )}

            <FirstRow>
                {!isAppMobile && (
                    <VideoBox id="top">
                        <VideoBoxMemoized videoUrl={localMedia.src} posterUrl={videoPosterUrl} />
                    </VideoBox>
                )}
                {isAppMobile && (
                    <Title>
                        Acquista Punti Libertà

                        {/* <CopySVG onClick={copyIntoClipboardHandler} /> */}
                    </Title>
                )}

                <DescriptionBox>
                    <InfoText>
                        Quanti Punti Libertà vuoi allocare oggi per la tua 
                        crescita personale ed Economica?
                    </InfoText>

                    <ButtonPointContainer>
                        <ButtonContainer>        
                            <ButtonPoint
                                onClick={() => onClickSelectionPoint(165)}
                                className={selectedButton === 165 ? 'selected' : ''}
                            > 165 <PuntiLibertaSVG/> </ButtonPoint>

                            <ButtonPoint
                                onClick={() => onClickSelectionPoint(410)}
                                className={selectedButton === 410 ? 'selected' : ''}
                            > 410 <PuntiLibertaSVG/> </ButtonPoint>

                            <ButtonPoint
                                onClick={() => onClickSelectionPoint(1313)}
                                className={selectedButton === 1313 ? 'selected' : ''}
                            > 1313 <PuntiLibertaSVG/> </ButtonPoint>
                        </ButtonContainer>  

                        <PointCounterCard>
                        
                            <PointCounterChange 
                                type="number"
                                min="25"
                                value={selectionLiberty}
                                onChange={onChange}
                                onKeyDown={onKeyDownHandler}
                                onBlur={onBlurHandler}
                            />
                            
                            <PuntiLibertaSVG/>
                        </PointCounterCard>

                        <HorizontalSlider 
                            minValue={25}
                            maxValue={5000}
                            startingValue={selectionLiberty}
                            onChange={onSliderChangeHandler}
                        />
                    
                        {store.getState().ui.brand?.id !== 1 &&
                            <ButtonShop
                                PriceProduct={1.22*selectionLiberty}
                                TypeOfProduct={productList[0]?.type}
                                courseElement={productList}
                                courseID={productList[0]?.productCreditPack.productId}
                                payments={productList[0]?.paymentTypes}
                                onTransferClick={transferModalHandler!}
                                onLibertyClick={onLibertyClick!}
                                openBankTransferModal={openBankTransferModal}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isLibertyPage
                            />
                        }

                        {store.getState().ui.brand?.id === 1 &&
                            <ButtonShop
                                PriceProduct={CalculateLibertyPrice()}
                                TypeOfProduct={productList[0]?.type}
                                courseElement={productList}
                                courseID={productList[0]?.productCreditPack.productId}
                                payments={productList[0]?.paymentTypes}
                                onTransferClick={transferModalHandler!}
                                onLibertyClick={onLibertyClick!}
                                openBankTransferModal={openBankTransferModal}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isLibertyPage
                            />
                        }
                    </ButtonPointContainer>

                </DescriptionBox> 
            </FirstRow>

            <CourseDescriptionContainer>
                <DescriptionTitle>
                    ACQUISTA PUNTI LIBERTÀ
                </DescriptionTitle>

                <ImportantPhrase>
                    I Punti Libertà ti danno accesso a moltissimi contenuti e funzioni <span>che non puoi avere senza Punti Libertà.</span>
                </ImportantPhrase>

                <BulletBox>
                    <BulletRow>
                        <BulletCheck />

                        <BulletPoint>
                            Le dirette tematiche sono acquistabili solo coi Punti Libertà
                        </BulletPoint>
                    </BulletRow>

                    <BulletRow>
                        <BulletCheck />

                        <BulletPoint>
                            I Punti VIP possono essere creati solo coi Punti Libertà
                        </BulletPoint>
                    </BulletRow>

                    <BulletRow>
                        <BulletCheck />

                        <BulletPoint>
                            Grazie ai Punti Libertà potrai avere una corsia preferenziale
                            per corsi e servizi limitati
                        </BulletPoint>
                    </BulletRow>
                </BulletBox>

                <ImageTextBox isDesktopDirectionColumn>
                    <SimpleTextLight>
                        Ogni volta che vuoi fare una spesa futile, una pizza o l’ennesimo maglione, <span>RINUNCIA</span> e 
                        acquista Punti Libertà per una cifra equivalente a quello che avresti speso.
                    </SimpleTextLight>    

                    <SimpleText> 
                        In questo modo stai <span>pagando prima te stesso</span>, accumuli un Asset proprio come fanno i ricchi e, 
                        quando avrai bisogno di un corso nuovo o di rinnovare un servizio, non dovrai sottrarre soldi ai tuoi fondi.
                    </SimpleText>
                    
                    <SimpleTextLight>
                        Allo stesso modo puoi destinare un budget settimanale o mensile ai Punti Libertà, ovvero alla tua crescita. <span>Un modo intelligente di investire su te stesso.</span>
                    </SimpleTextLight>

                    <SimpleText>
                        E siccome gli imprenditori detestano avere asset fermi che non producono nulla, i Punti Libertà fermi producono <span>Punti VIP in REGALO.</span>
                    </SimpleText>

                    <SimpleTextLight>
                        I Punti VIP ti permettono di diventare cliente VIP e di avere accesso a prodotti che <span>NON HANNO PREZZO</span>, 
                        perché non sono in vendita, si possono avere solo con i Punti VIP.
                    </SimpleTextLight>

                    {isAppMobile && (<ButtonUp onClick={scrollUpHandlerMobile}>VOGLIO PUNTI LIBERTÀ</ButtonUp>)}

                    <SimpleText>
                        Questo comprende inviti ad eventi esclusivi come la <span>Settimana del SUPERVIVENTE</span>, con il nostro staff e i nostri 
                        Migliori Maestri, e tanti altri premi esclusivi. E non è finita, grazie ai punti VIP sarai sempre il primo a 
                        poter accedere a prodotti limitati e <span> potrai saltare le code di attesa.</span>
                    </SimpleText>

                    <SimpleTextLight>
                        Mille motivi per rinunciare a una pizza ogni tanto e a qualche altra cavolata, e scegliere invece di investire 
                        su te stesso.
                    </SimpleTextLight>

                    <SimpleText>
                        Ricorda, guadagnerai sempre in base al tuo VALORE; <span>se vuoi fare 5.000 euro al mese, devi diventare una 
                        persona che vale 5.000 euro al mese </span>e i Punti Libertà ti permettono di investire su te stesso, sull’aumento 
                        del tuo valore.
                    </SimpleText>

                    <SimpleTextLight>
                        Inizia oggi stesso ad accumulare Punti Libertà, i punti di oggi <span>saranno il tuo Guadagno di domani (!)</span>
                    </SimpleTextLight>
                </ImageTextBox>

                {!isAppMobile && (<ButtonUp onClick={scrollUpHandler}>VOGLIO PUNTI LIBERTÀ</ButtonUp>)}
                {isAppMobile && (<ButtonUp onClick={scrollUpHandlerMobile}>VOGLIO PUNTI LIBERTÀ</ButtonUp>)}

            </CourseDescriptionContainer>
        </Page>

        {isBankTransferModalVisible && (
            <BankTransferModal
                onCloseButtonModal={onCloseModalHandler}
                productName={productList[0].name}
                productPrice={`${CalculateLibertyPrice()}`} 
                // paymentTransferID={productList[0].id}
                paymentTransferID={paymentTransferID}
            />
        )}

        {!isUserLoggedIn && !isOpenInModal && isLibertyPaymentModalVisible && (
            <LibertyPaymentModal
                onCloseButtonModal={onCloseModalHandler}
                courseID={productList[0].productCreditPack.productId}
            />
        )}
    </>
    )
};

export default LibertyPoint;
