import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { WarningModalProps } from './WarningModal';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as WarningSVG } from './assets/warning.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton,
    RedText,
    GoldText,
    AdviceProduct
} from './style/WarningModal.style';
import { getEnvironmentMonolithLinkUrl, getEnvironmentProductId } from 'configs/config.helper';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { enableAppMain } from 'redux/slices/slice-app-main';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const WarningModal: FC<WarningModalProps> = ({ onCloseButtonModal }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { warning: warningData } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const buttonHandler = () => {
        dispatch( enableAppMain() );
        if (warningData!.productId === getEnvironmentProductId('assistente personale')) return navigate('/reservedArea/aiuto-privato');
        navigate(`/public/product/${warningData!.productId}`)
    };

    console.log("WARNING DATA:", warningData);
    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <WarningSVG />
                    </IconBox>

                    <Title>
                        WARNING (!) FAI ATTENZIONE
                    </Title>

                    <TextContent>
                        Noi possiamo garantire che il nostro percorso ti porta a liberarti dal lavoro in poco tempo e lo possiamo fare grazie alle centinaia di testimonianze e casi studio che trovi pubblicati sul nostro sito. Se decidi di fare un percorso diverso i risultati non sono garantiti e potresti ritrovarti dopo mesi di lavoro al punto di partenza.
                            <br />
                            <br />
                            {/* <p dangerouslySetInnerHTML={{__html: warningData[0].copy}}/> */}
                            <b>{warningData!.copy}</b>
                            <br />
                            <br />        
                            <RedText>
                            Questo warning continuerà a comparirti ridotto a icona per ricordarti
                            che non stai seguendo i suggerimenti e il percorso di Liberi dal
                            Lavoro. 
                            <br />
                            <br />
                            </RedText>
                        <GoldText>Ci hai ripensato?</GoldText>
                    </TextContent>

                    {warningData!.sellImage !== null && (
                        <AdviceProduct alt='Prodotto per passare oltre il warning' src={warningData!.sellImage.url} />
                    )}

                    <StartButton onClick={buttonHandler}>Riprendi il percorso guidato</StartButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default WarningModal;
