import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { UserProfileSubmenuModalProps } from './UserProfileSubmenuModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    SubmenuBaseModal,
    SubmenuBaseCard,
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/UserProfileSubmenuModal.style';
import UserProfileSubmenu from 'App/layout/Header/UserProfileSubmenu';
import SocietyInfoMobile from 'components/SocietyInfoMobile/SocietyInfoMobile';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const UserProfileSubmenuModal: FC<UserProfileSubmenuModalProps> = ({ onCloseButtonModal }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { email } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    return (
        <SubmenuBaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isModalCardClickActive
        >
            <SubmenuBaseCard>
                <CardContent>
                    <UserProfileSubmenu isSubmenuOpen={true} />

                    <SocietyInfoMobile />
                </CardContent>
            </SubmenuBaseCard>
        </SubmenuBaseModal>
    );
};

export default UserProfileSubmenuModal;
