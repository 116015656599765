import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

const breakPoint = '(max-width: 517px)';

export const MultipleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
    margin-top: 5px;
`;

export const Parameters = styled.div<{width?: string, direction?: string, justify?: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${devices.mobile} {
        flex-direction: ${(props) => props.direction ?? 'row'};
        align-items: ${(props) => (props.direction ?? 'row')==='row' ? 'flex-start' : 'center'};
    };
    ${(props) => props.width && css`width: ${props.width};`};
    justify-content: ${(props) => props.justify ?? 'center'};
    gap: 5px;
    flex-wrap: wrap;
    div:first-of-type {
        align-self: stretch;
    }
`;

export const MultipleSectionsContainer = styled.div`
    display: flex;
    flex-direction: 'row';
    justify-content: space-evenly;
    align-items: 'flex-start';
    flex-wrap: wrap;
    gap: 20px;
    /* margin-top: 20px; */
    width: 100%;
`;

export const MultipleSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    max-width: 100%;
    min-width: 0;
    position: relative;
`;

export const MultipleList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    max-width: 100%;
    
`;

export const ConcurrentEventsWarning = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    color: white;
    padding: 2px;
    background: #ff000069;
    border-radius: 5px;
    border: 1px solid #610000;
    font-size: 15px;
`;

export const MultipleElement = styled.div<{isDisabled?: boolean, backgroundColor?: string, border?: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: fit-content;
    padding: 5px;
    background: ${props => props.backgroundColor ?? '#29353E66'};
    border: ${props => props.border ?? '1px solid #29353E'};
    border-radius: 5px;
    width: 100%;
    max-width: 610px;
    transition: opacity 0.3s ease-in-out;
    ${(props) => props.isDisabled && (
        css`
            opacity: 0.5;

        `
    )}
    min-height: 73px;
`;

export const MultipleElementEventRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    gap: 5px;
`;

export const MultipleElementEvent = styled.div`
    white-space: nowrap;
    width: fit-content;
    color: white;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    flex-grow: 1;
    cursor: default;
`;

export const MultipleElementOddsMatchRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5px;
    width: 100%;
`;

export const MultipleElementOdds = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 2px;
    @media ${breakPoint} {
        flex-direction: column;
    };
`;

export const MultipleElementCell = styled.div`
    height: 36px;
    @media ${breakPoint} {
        height: 24px;
    };
`;
export const MultipleElementOddsSelection = styled.div`

    width: fit-content;
    height: 36px;
    @media ${breakPoint} {
        width: 60px;
        height: 24px;
    };
    max-width: 60px;
    min-width: 24px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background: #243038;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    /* @media (max-width: 450px) {
        display: none;
    }; */
`;

// export const MultipleElementOddsSelectionInput = styled.input<{width: string}>`

//     min-width: 0;
//     max-width: 70px;
//     width: ${(props) => props.width};
//     @media (max-width: 521px) {
//         width: 50px;
//     }
//     height: 36px;
//     font-size: 11px;
//     font-weight: 500;
//     letter-spacing: 0.15px;
//     text-align: center;

//     border: 1px solid #29353E;
//     border-radius: 5px;
//     background: #29353E66;
//     color: white;
//     transition: .3s ease-out all;
//     :focus {
//         border: 1px solid #202a31;
//     }
//     :focus-visible {
//         outline: none;
//     }

//     :read-only {
//         padding: 0;
//         color: ${colors.white056};
//         cursor: default;
//     }

// `;

export const MultipleElementOddsCovers = styled.div`
    display: flex;
    align-items: center; 
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 5px;
`;

export const MultipleElementOddsCoversContainer = styled.div`
    display: flex;
    align-items: center; 
    flex-wrap: nowrap;
    gap: 5px;
`;

export const MultipleBonusAndOdds = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 5px;
    height: 24px;
`;

export const MultipleBonusContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    @media (max-width: 330px) {
        gap: 1px;
    };
`;

export const MultipleBonusLabel = styled.span`
    font-size: 12px;
    color: white;
    white-space: nowrap;    
`;

export const MultipleOddsContainer = styled.div<{altValue?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px;
    height: 24px;
    background-color: #0c1542;
    border: 1px solid ${colors.grey};
    color: white;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    min-width: 56px;
    cursor: default;
    position: relative;
    ${(props) => props.altValue && (
        css`
            text-decoration: line-through;
            ::after{
                position: absolute;
                top: 20px;
                content: '${props.altValue}';
            }

        `
    )}
`;

export const MultipleInfoContainer = styled.div<{marginTop?: string}>`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5px 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ${props => props.marginTop ?? '15px'};
`;

export const MultipleInfoElement = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;
    
`;

export const MultipleInfoLabel = styled.div`
    font-size: 12px;
    color: white;
    font-weight: 500;
    text-align: center;
    @media (max-width: 350px) {
        font-size: 10px;
    };
`;

export const MultipleInfoValue = styled.div<{v?: number}>`
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.v ? (props.v>0 ? '#00c100' : '#ff4242') : colors.primaryColor};
`;

export const MultipleFinishedReason = styled.div`
    color: white;
    font-weight: 500;
`;

export const NextCoverContainer = styled.div<{isDisabled: boolean}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
    border-top: 2px solid white;
    transition: opacity 0.5s ease;
    opacity: ${props => props.isDisabled ? '0.3' : '1'};
    @media ${devices.mobile} {
        min-width: 350px;
    };
`;

export const CoverTitle = styled.div`
    /* text-transform: uppercase; */
    color: ${colors.primaryColor};
    font-weight: 500;
    font-size: 20px;
`;

export const AddToCalculatorsMultiple = styled.div`
    width: 48px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #009b14;
    border-radius: 5px;
    font-size: 28px;
    color: #009b14;
    background: #009b1440;
    cursor: pointer;
    margin: 0 auto;
`;

export const NotificationsLabel = styled.div`
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
`;

export const MultipleAdd = styled.div<{absolute?: boolean}>`
    
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #009b14;
    border-radius: 5px;
    font-size: 23px;
    color: #009b14;
    background: #009b1440;
    cursor: pointer;
    

    ${(props) => props.absolute ?
        css`
            position: absolute;
            width: 24px;
            height: 16px;
            top: calc(100% + 3px);
            right: 0;
            width: 100%;
        ` :
        css`
            width: 48px;
            height: 24px;
        `
    }
`;

export const MultipleAmountLabel = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: white;
`;

export const MultipleAmount = styled.div`
    font-weight: 500;
    font-size: 24px;
    color: ${colors.primaryColor};
`;