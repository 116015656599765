// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { getCourseWithBrand, getDonation, getPrivateInfoBroadcast } from '../services/ThematicDirectCatalogue.services';
import { useNavigate, useParams } from 'react-router-dom';
import { useCopyToClipboard } from 'usehooks-ts';
import useAppDispatch from 'hooks/useAppDispatch';

// components
import HorizontalSlider from 'components/HorizontalSlider/HorizontalSlider.component';

// assets
import { ReactComponent as PuntiLibertaSVG } from './assets/libertyPoint.svg';
import { ReactComponent as PuntiLibertaBSVG } from './assets/PuntiLibertàB.svg';


// styles
import { 
    ButtonTipSelection,
    DescriptionText,
    Donation,
    DonationButtonBox,
    DonationInfo,
    DonationInfoText,
    DonationRegulation,
    DonationSelection,
    DonationSelectionButton,
    MainPage,
    TitlePage,
    TitleText,
    TotalPage,
    VideoBox,
    VideoDonation, 
} from "./style/ThematicDirectPage.style";
import LoadingMask from 'components/ui/LoadingMask';
import ButtonShopStaking from 'components/ButtonShopStaking/ButtonShopStaking.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import useBrand from 'hooks/useBrand';




const ThematicDirectPage = () => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [selectionLiberty, setSelectionLiberty] = useState <number>(1);
    const [selectedButton, setSelectedButton] = useState<number>(0);
    const navigate = useNavigate();
    const [thematicBroadcastProducts, setThematicBroadcastProducts] = useState<any[]>([]);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();
    const [thematicBroadcastPrivateProducts, setThematicBroadcastPrivateProducts] = useState<any>();
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const { productId } = useParams();
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };
    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const onSliderChangeHandler = (newValue: number) => setSelectionLiberty(newValue);

    const onClickSelectionPoint = (amount: number) => {
        const newSelectedValue = selectionLiberty + amount;

        const cappedValue = Math.min(newSelectedValue, 100);

        setSelectedButton(cappedValue);
        setSelectionLiberty(cappedValue);
    };

    const getProducts = async () => {
        
        try {

            const response = await getCourseWithBrand();

            if (response) {
                const thematicBroadcastProducts = response.filter((product: any) => ((product.type === "THEMATIC_BROADCAST")))
                console.log("DIRETTE:", thematicBroadcastProducts);

                setThematicBroadcastProducts(thematicBroadcastProducts);
            }

        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {
        getProducts();
    },[useBrand()])

    const getMyProducts = async () => {
        
        try {
            
            const response = await getPrivateInfoBroadcast(productId!);

            if (response) {
                const thematicBroadcastPrivateProducts = response;
                
                setThematicBroadcastPrivateProducts(thematicBroadcastPrivateProducts);
            }
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };

    const broadcastDonation = async () => {
        
        try {
            
            const response = await getDonation(productId!);
            
            console.log("DONAZIONE",response)            
            
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {
        broadcastDonation();
        getMyProducts();
        startedStream();
    },[productId, useBrand()])

    const privateData = (thematicBroadcastPrivateProducts !== undefined) ? thematicBroadcastPrivateProducts : '' ;
    console.log("PRODUCTDATA PRIVATE :", privateData)


    const productData = thematicBroadcastProducts.find(product => product.id === Number(productId));
    console.log("PRODUCTDATA :", productData)

    const description = productData ? productData.productThematicCall.description : 'nessuna descrizione';
    let spectatorLink = productData ? productData.productThematicCall.spectatorLink : '';

    const liveDate = productData ? productData.productThematicCall.liveDate : '';
    const formattedTime = `${new Date(liveDate + "Z").getHours() <10 ? '0' + new Date(liveDate + "Z").getHours() : new Date(liveDate + "Z").getHours()}:${new Date(liveDate + "Z").getMinutes() <10 ? '0' + new Date(liveDate + "Z").getMinutes() : new Date(liveDate + "Z").getMinutes()}`;

    const currentDate = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const id = productData ? productData.id : 0;
    const requisites = productData ? productData.requisitesSatisfied : true ;


    const startedStream = () => {
        if (formattedTime === currentDate) {
            setIsStarted(true);
            window.location.reload();
        } else if (formattedTime > currentDate) {
            setIsStarted(true);
        } else {
            setIsStarted(false);
        }
    }

    console.log("isStarted :", isStarted)


    if (spectatorLink === "") {
        spectatorLink = '4kLviL8XwAI';
    }

    if (thematicBroadcastPrivateProducts === undefined) return (<LoadingMask/>)

    return (
        <>
            {isAppMobile && 
                <VideoBox src={`https://streamyard.com/watch/${spectatorLink}?embed=true`} title="Streamyard video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></VideoBox>
            }

            <TotalPage>
                <TitlePage>
                    <TitleText>
                        <h1>{productData ? productData.name : ''}</h1>
                    </TitleText>
                </TitlePage>

                {isAppMobile && 
                    <MainPage>
                        <Donation>
                            <DonationInfo>
                                <DonationInfoText>
                                    <p>FAI UNA DONAZIONE:</p>

                                    <h3>{selectionLiberty} €</h3>

                                    <PuntiLibertaBSVG/>
                                </DonationInfoText>

                                <DonationSelection>
                                    <DonationSelectionButton>
                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(1)}
                                            className={selectedButton === 1 ? 'selected' : ''}
                                        >
                                            <p>+ 1 €</p>
                                        </ButtonTipSelection>

                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(3)}
                                            className={selectedButton === 3 ? 'selected' : ''}
                                        >
                                            <p>+ 3 €</p>
                                        </ButtonTipSelection>

                                        <ButtonTipSelection
                                            onClick={() => onClickSelectionPoint(10)}
                                            className={selectedButton === 10 ? 'selected' : ''}
                                        >
                                            <p>+ 10 €</p>
                                        </ButtonTipSelection>
                                    </DonationSelectionButton>

                                    <DonationRegulation>
                                        <HorizontalSlider 
                                            minValue={1} 
                                            maxValue={100} 
                                            startingValue={selectionLiberty}
                                            step={1}
                                            onChange={onSliderChangeHandler}
                                        />
                                    </DonationRegulation>
                                </DonationSelection>
                            </DonationInfo>

                            <DonationButtonBox>
                                <ButtonShopStaking
                                    PriceProduct={selectionLiberty * 1.22} 
                                    TypeOfProduct={'DONATION'} 
                                    courseElement={requisites} 
                                    courseID={id} 
                                    payments={'Punti Libertà'} 
                                    onTransferClick={transferModalHandler}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    productIdHandler={libertyProductID}
                                    productPriceHandler={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    isDonationPage
                                />
                            </DonationButtonBox>

                            <DescriptionText>
                                <p dangerouslySetInnerHTML={{ __html: description }}/>
                                {/* <a href='https://liberidalavoro.it/app/public/product/3'>https://liberidalavoro.it/app/public/product/3</a> */}
                            </DescriptionText>
                        </Donation> 
                    </MainPage>

                }

                {!isAppMobile &&
                    <>
                        <MainPage>
                            <VideoDonation>
                                {/*isStarted = {isStarted}*/}
                                <VideoBox  src={`https://streamyard.com/watch/${spectatorLink}?embed=true`} title="Streamyard video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen ></VideoBox>
                                    <Donation>
                                        <DonationInfo>
                                            <DonationInfoText>
                                                <p>FAI UNA DONAZIONE:</p>

                                                <h3>{selectionLiberty}</h3>

                                                <PuntiLibertaBSVG/>
                                            </DonationInfoText>

                                            <DonationSelection>
                                                <DonationSelectionButton>
                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(1)}
                                                        className={selectedButton === 1 ? 'selected' : ''}
                                                    >
                                                        <p>+ 1 </p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>

                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(3)}
                                                        className={selectedButton === 3 ? 'selected' : ''}
                                                    >
                                                        <p>+ 3</p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>

                                                    <ButtonTipSelection
                                                        onClick={() => onClickSelectionPoint(10)}
                                                        className={selectedButton === 10 ? 'selected' : ''}
                                                    >
                                                        <p>+ 10</p>
                                                        <PuntiLibertaSVG/>
                                                    </ButtonTipSelection>
                                                </DonationSelectionButton>

                                                <DonationRegulation>
                                                    <HorizontalSlider 
                                                        minValue={1}
                                                        maxValue={100}
                                                        startingValue={selectionLiberty}
                                                        step={1}
                                                        onChange={onSliderChangeHandler}
                                                    />
                                                </DonationRegulation>
                                            </DonationSelection>
                                        </DonationInfo>

                                        <DonationButtonBox>
                                            <ButtonShopStaking
                                                PriceProduct={selectionLiberty * 1.22} 
                                                TypeOfProduct={'DONATION'} 
                                                courseElement={requisites} 
                                                courseID={id} 
                                                payments={'Punti Libertà'} 
                                                onTransferClick={transferModalHandler}
                                                onLibertyClick={libertyModalHandler}
                                                openLibertyPaymentModal={openLibertyPaymentModal}
                                                productIdHandler={libertyProductID}
                                                productPriceHandler={libertyProductPrice}
                                                loadingOn={loadingOn}
                                                loadingOff={loadingOff}
                                                isDonationPage
                                            />
                                        </DonationButtonBox>
                                    </Donation>
                            </VideoDonation>
                        </MainPage>

                        <DescriptionText>
                            <p dangerouslySetInnerHTML={{ __html: description }}/>
                        </DescriptionText>
                    </>
                }
            </TotalPage>

            {isLibertyPaymentModalVisible &&
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={selectionLiberty * 1.22}
                    productType="Donation"
                    isDonationPage
                />
            }
        </>
    );
};

export default ThematicDirectPage;