import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const ServiceContent = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    flex-direction: column;
    
    padding: 0px 0px 10px;

    gap: 12px;

    border-bottom: 1px solid ${colors.grey};
    width: 100%;

    @media ${devices.tablet} {
}
`;

export const ServiceName = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 15px;

    color: ${colors.white};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ServiceElipsisBox = styled.div`
    
    overflow: hidden;
`;

export const ServiceDescriptionBox = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    
    padding: 0;
    margin: 10px 0 0;

    gap: 10px;


    @media ${devices.tablet} {
}
`;

export const ServiceStatusMainBox = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    padding: 0px;

    @media ${devices.tablet} {
}
`;

export const ServiceStatusBox = styled.div<{isToRenew?: boolean}>`

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 0px;

    gap: 6px;

    position: relative;
    @media ${devices.tablet} { }

    svg {
        cursor: pointer;
        margin: 0 14px 0 4px;
    }

    svg:first-child {
        cursor: default;
        margin: 0;
    }

    svg:last-child {
        margin: 0;
    }

    ${({isToRenew}) => isToRenew && 
    
        css`
            svg:first-child {
                /* fill: ${colors.orange}; */
                stroke: ${colors.orange};
            }
        `
    }
`;

export const ServiceGuideButton = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 23px;

    background: ${colors.grey};
    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
    border-radius: 5px;

    cursor: pointer;
`;

export const ServiceSelectorBox = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;

    border-radius: 5px;
    border: none;

    cursor: pointer;
`;

export const ServiceStatusText = styled.p<{isActive: "toRenew" | "disactive" | "active"}>`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 13px;

    color: ${props => props.isActive === 'active' ? '#81C784' : colors.orange};

    cursor: ${props => props.isActive === 'toRenew' ? 'default' : 'pointer'};

    position: relative;
    @media ${devices.tablet} {
}
`;

export const ServiceDeadlineDate = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 13px;

    color: ${colors.white};

    @media ${devices.tablet} {
}
`;

export const ReactivationServiceBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const DisactiveTex = styled.p`
    
    padding: 0px;
    margin: 0px;
    
    font-weight: 500;
    font-size: 11px;

    color: ${colors.white050};

    @media ${devices.tablet} {
}
`;

export const DisactiveBox = styled.div<{isActive: boolean}>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;

    ${({isActive}) => isActive && 
        css`
            display: none;
        `
    }
    
`;

export const PaymentTooltip = styled.div`

    display: block;

    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;

    padding: 10px;

    background: ${colors.veryDarkGrey};
    color: ${colors.white};
    border-radius: 5px;

    position: absolute;
    top: -40px;
`;

export const RenewTooltip = styled.div`

    display: block;

    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;

    padding: 10px;

    background: ${colors.veryDarkGrey};
    color: ${colors.white};
    border-radius: 5px;

    position: absolute;
    top: -40px;
    left: -20px;

    @media ${devices.tablet} {
        left: -75px;
    }
`;

export const TooltipArrow = styled.div<{isIntoRenewTooltip?: boolean}>`

    width: 10px;
    height: 10px;

    margin: 5px 37% 0;
    background: ${colors.veryDarkGrey};

    transform: rotate(45deg);
    position: absolute;

    ${({isIntoRenewTooltip}) => isIntoRenewTooltip && 

        css`
            margin: 5px 20px;
        `
    }

    @media ${devices.tablet} {
        ${({isIntoRenewTooltip}) => isIntoRenewTooltip && 

            css`
                margin: 5px 37% 0;
            `
        }
    }
`;