import { FC, useEffect, useState, useRef, useMemo } from 'react';

//redux
import { setSites, setEvents } from 'redux/actions/actions-oddsscasser';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// utils
import { _getSites, _getEvents, _getOddsMatches, _getOdds } from '../services/OddsScasserPage.services';
import { getItemFromLocalStorage, setItemToLocalStorage, getObjFromLocalStorage, setObjToLocalStorage } from 'utils/localStorage';
import { getObjFromSessionStorage, setObjToSessionStorage } from 'utils/sessionStorage';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//components
import OddsScasserFilter from './OddsScasserFilter';
import OddsScasserTable from './OddsScasserTable';
import BestOddsFilter from './BestOddsFilter/BestOddsFilter.component';
import BestOddsResults from './BestOddsResults';
import BestOddsMultiple from './BestOddsMultiple';
import Rollover from './Rollover';
import Multiple from './Multiple';

//types
import { OddsScasserProps } from './OddsScasser.component.d';
import { OddsScasserFilters } from './OddsScasserFilter/types/OddsScasserFilters';
import { BestOddsFilters } from './BestOddsFilter/types/BestOddsFilters';
import { OddsMatch } from '../types/OddsMatch';
import { Odds } from '../types/Odds';
import { Event } from '../types/Event';
import { Multiple as MultipleObj } from './BestOddsMultiple/types/Multiple';

//style
import { OddsScasserContainer, ToolsContainer } from './style/OddsScasser.style';

//helpers
import { calculatePuntaBanca, calculatePuntaPunta } from '../OddsScasserPage.helper';


const OddsScasser: FC<OddsScasserProps> = ({ mode }) => {
    // console.count('oddsScasser');

    const { sites, events } = useAppSelector(state => state.oddsScasser);

    const dispatch = useAppDispatch();
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //OddsScasser
    const [oddsMatches, setOddsMatches] = useState<OddsMatch[]>([]);
    const [filters, setFilters] = useState<OddsScasserFilters>(getObjFromLocalStorage('oddsScasserFilters') as OddsScasserFilters ||  {bet: '100', bonus: '0', refund: '0', isFreebet: false, order: 'rating', feesBetfair: getItemFromLocalStorage('feesBetfair') ?? '4.5', feesBetflag: getItemFromLocalStorage('feesBetflag') ?? '5'});
    const [blacklist, setBlacklist] = useState<Odds[]>(getObjFromSessionStorage('oddsScasserBlacklist') as Odds[] || []);
    const [multipleVisible, setMultipleVisible] = useState<boolean>(getItemFromLocalStorage('multipleVisible')==='true');
    const [oddsMatchToAddToMultiple, setOddsMatchToAddToMultiple] = useState<OddsMatch|undefined>(undefined);
    const [rolloverVisible, setRolloverVisible] = useState<boolean>(getItemFromLocalStorage('rolloverVisible')==='true');
    const [oddsMatchToAddToRollover, setOddsMatchToAddToRollover] = useState<OddsMatch|undefined>(undefined);
    const [feesBetfair, setFeesBetfair] = useState<string>(getItemFromLocalStorage('feesBetfair') ?? '4.5');
    const [feesBetflag, setFeesBetflag] = useState<string>(getItemFromLocalStorage('feesBetflag') ?? '5');
    //BestOdds
    const [odds, setOdds] = useState<Map<string, Odds[]>>(new Map<string, Odds[]>());
    const [bestOddsFilters, setBestOddsFilters] = useState<BestOddsFilters>({});
    const [bestOddsMultiple, setBestOddsMultiple] = useState<MultipleObj>({events: [], selections: [], sites: new Map<number, (Odds|undefined)[]>()});
    const [bestOddsMultipleVisible, setBestOddsMultipleVisible] = useState<boolean>(true);

    const clipboardText = useRef<string>('');
    
    const search = async (payload: OddsScasserFilters) => {
        try {
            setIsLoading(true);
            const results = await _getOddsMatches(mode, {...payload, sites2: mode==='puntabanca' ? payload.sites2PuntaBanca : payload.sites2PuntaPunta});
            if(results) {
                calculate(results, payload);
                setOddsMatches(results);
                setFilters(payload);
                setObjToLocalStorage('oddsScasserFilters', payload);
                setIsLoading(false);
            }
        } catch (error: any) {
            console.warn(error);
            dispatch(addSnackbar({ type: 'error', message: 'Errore nella richiesta' }));
        }
    }
    
    useEffect(() => {
        setItemToLocalStorage('multipleVisible', multipleVisible ? 'true' : 'false');
    }, [multipleVisible]);
    useEffect(() => {
        setItemToLocalStorage('rolloverVisible', rolloverVisible ? 'true' : 'false');
    }, [rolloverVisible]);
    
    const calculate = (results: OddsMatch[], betInfo: OddsScasserFilters) => {
        results.forEach(oddsMatch => {
            const res = mode === 'puntabanca' 
                ? calculatePuntaBanca(parseFloat(betInfo.bet) || 0, betInfo.isFreebet ? 0 : parseFloat(betInfo.bonus), parseFloat(betInfo.isFreebet ? betInfo.bet : betInfo.refund) || 0, oddsMatch.odds[1].site?.id===9 ? parseFloat(betInfo.feesBetflag ?? '5')/100 : parseFloat(betInfo.feesBetfair ?? '4.5')/100, oddsMatch.odds.map(x => x.odds), 0.01)
                : calculatePuntaPunta(parseFloat(betInfo.bet) || 0, betInfo.isFreebet ? 0 : parseFloat(betInfo.bonus), parseFloat(betInfo.isFreebet ? betInfo.bet : betInfo.refund) || 0, oddsMatch.odds.map(x => x.odds), 1);
            for (let i = 0; i < res.bets.length; i++) {
                if(i>oddsMatch.odds.length-1) break; 
                oddsMatch.odds[i].coverBet = res.bets[i];
                oddsMatch.odds[i].coverResp = res.resp[i];
                oddsMatch.odds[i].winnings = res.winnings[i];
                oddsMatch.odds[i].rating = res.ratings[i];
            }
            oddsMatch.avgRating = res.avgRating;
            oddsMatch.avgWinnings = res.avgWinnings;
        });
    }

    const removeFromBlacklist = (indexes: number[]) => {
        setBlacklist(blacklist.filter((b, index) => !indexes.includes(index)));
    }
    const addToBlacklist = (event: Event, odds: Odds) => {
        odds.event = event;
        if(!blacklist.some(b => b.id===odds.id)) {
            setBlacklist([...blacklist, odds]);
            dispatch(addSnackbar({ type: 'info', message: "Quota aggiunta alla blacklist" }));
        } else {
            dispatch(addSnackbar({ type: 'error', message: "Quota già presente nella blacklist" }));
        }
    }
    useEffect(() => {
        setObjToSessionStorage('oddsScasserBlacklist', blacklist);
    }, [blacklist]);


    const searchBestOdds = async (payload: BestOddsFilters) => {
        try {
            setIsLoading(true);
            const results = await _getOdds(payload);
            const oddsMap = new Map<string, Odds[]>();
            results.forEach((element: Odds) => {
                if(element.type) {
                    if(!oddsMap.has(element.type)) oddsMap.set(element.type, []);
                    oddsMap.get(element.type)?.push(element);
                }
            })
            setOdds(oddsMap);
            setBestOddsFilters(payload);
        } catch (error: any) {
            console.warn(error);
            dispatch(addSnackbar({ type: 'error', message: 'Errore nella richiesta' }));
        }
        setIsLoading(false);
    }

    const addToBestOddsMultiple = (event: Event, oddsList: Odds[]) => {
        if(oddsList.length===0) return;
        if(!oddsList[0].type) return;
        if(bestOddsMultiple.events.some(x => x.id === event.id)) {
            dispatch(addSnackbar({ type: 'error', message: 'Evento già presente nella multipla' }));
            return;
        }
        const eventsNew = [...bestOddsMultiple.events, event];
        const selectionsNew = [...bestOddsMultiple.selections, oddsList[0].type];
        const sitesNew = new Map(bestOddsMultiple.sites);
        oddsList.forEach((x) => {
            if(x.site.type === 'exchange') return;
            if(!sitesNew.has(x.site.id)) {
                sitesNew.set(x.site.id, [...new Array(bestOddsMultiple.events.length).fill(undefined), x]);
            } else sitesNew.set(x.site.id, [...(sitesNew.get(x.site.id) ?? []), x]);
        })
        //Add undefined for the sites that there aren't in oddsList
        sitesNew.forEach((value, key) => {
            if(eventsNew.length > value.length) sitesNew.set(key, [...value, undefined]);
        })
        setBestOddsMultiple({events: eventsNew, selections: selectionsNew, sites: sitesNew});
        dispatch(addSnackbar({ type: 'info', message: 'Esito aggiunto alla multipla' }));
    }

    const removeFromBestOddsMultiple = (index: number) => {
        if(bestOddsMultiple.events.length===1 && index===0) {
            setBestOddsMultiple({events: [], selections: [], sites: new Map<number, (Odds|undefined)[]>()});
            return;
        }
        const sitesNew = new Map(bestOddsMultiple.sites);
        sitesNew.forEach((value, key) => sitesNew.set(key, value.filter((x,i) => i !== index)));
        setBestOddsMultiple({events: bestOddsMultiple.events.filter((x,i) => i !== index), selections: bestOddsMultiple.selections.filter((x,i) => i !== index), sites: sitesNew});
    }

    useEffect(() => {
        const getSites = async () => {
            try {
                dispatch(setSites(await _getSites()));
            } catch (error: any) {
                console.warn(error);
            }
        };
    
        const getEvents = async () => {
            try {
                dispatch(setEvents(await _getEvents()));
            } catch (error: any) {
                console.warn(error);
            }
        };

        
        if(sites.size === 0) getSites();
        if(events.size === 0) getEvents();
    }, [])

    const copyToClipboard = (text: string|null, message?: string) => {
        if(!text) return;
        if(text.includes('bwin') && clipboardText.current.includes('bwin')) text.replace('bwin', 'GD');
		navigator.clipboard.writeText(text);
		clipboardText.current = text;
        if(message) dispatch(addSnackbar({ type: 'info', message }));
	};

    return(
        <OddsScasserContainer>
            { (mode === 'puntabanca' || mode === 'puntapunta') && 
                <>
                    <OddsScasserFilter mode={mode} filtersNow={filters} search={search} blacklist={blacklist} removeFromBlacklist={removeFromBlacklist} onFeesBetfairChange={(newValue: string) => setFeesBetfair(newValue)} onFeesBetflagChange={(newValue: string) => setFeesBetflag(newValue)} copyToClipboard={copyToClipboard}/>
                    <ToolsContainer direction='column'>
                        <Multiple calculators={false} oddsMatchToAdd={oddsMatchToAddToMultiple} visible={multipleVisible} changeVisibility={() => setMultipleVisible(prevState => !prevState)} feesBetfair={feesBetfair} feesBetflag={feesBetflag} copyToClipboard={copyToClipboard}/>
                        <Rollover calculators={false} oddsMatchToAdd={oddsMatchToAddToRollover} filters={filters} search={search} visible={rolloverVisible} changeVisibility={() => setRolloverVisible(prevState => !prevState)} feesBetfair={feesBetfair} feesBetflag={feesBetflag} copyToClipboard={copyToClipboard}/>
                    </ToolsContainer>
                    <OddsScasserTable mode={mode} filters={filters} oddsMatches={oddsMatches} showWinnings={true} isLoading={isLoading} addToBlacklist={addToBlacklist} addToRollover={(oddsMatch: OddsMatch) => setOddsMatchToAddToRollover(oddsMatch)} addToMultiple={(oddsMatch: OddsMatch) => setOddsMatchToAddToMultiple(oddsMatch)} copyToClipboard={copyToClipboard} pageChangeHandler={(page) => search({...filters, page})}/>
                </>
            }
            { mode === 'bestodds' && 
                <>
                    <BestOddsFilter search={searchBestOdds}/>
                    {bestOddsMultiple.events.length>0 && <BestOddsMultiple multiple={bestOddsMultiple} removeFromMultiple={removeFromBestOddsMultiple} visible={bestOddsMultipleVisible} changeVisibility={() => setBestOddsMultipleVisible(prevState => !prevState)} copyToClipboard={copyToClipboard}/>}
                    <BestOddsResults filters={bestOddsFilters} odds={odds} isLoading={isLoading} addToMultiple={addToBestOddsMultiple} copyToClipboard={copyToClipboard}/>
                </>
            }
        </OddsScasserContainer>
    );
}

export default OddsScasser;