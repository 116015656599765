import { FC, useEffect, useState } from 'react';

// assets
import { ReactComponent as DatePickerSVG } from '../assets/calendar.svg';
import { ReactComponent as CheckCircleSVG } from '../assets/check-circle.svg';
import { ReactComponent as CommentSVG } from '../assets/comment.svg';
import { ReactComponent as VideocallSVG } from '../assets/videocall.svg';

// style
import {
    RowBox,
    IconBox,
    LessonIconButton,

} from './style/LessonRow.style';


const LessonRow: FC<{
    lesson: any, 
    index: number, 
    coach: any,
    onCommentClick?: () => void, 
    onRatingClick?: () => void
    choiceLessonHandler?: (n: number) => void
}> = ({ 
        lesson, 
        index, 
        coach,
        onCommentClick, 
        onRatingClick,
        choiceLessonHandler
    }) => {

    const [highwayLesson, setHighwayLesson] = useState<any>(lesson);
    const [isDatePickerButtonCompleted, setIsDatePickerButtonCompleted] = useState<boolean | undefined>(undefined);
    const [isZoomLinkButtonCompleted, setIsZoomLinkButtonCompleted] = useState<boolean | undefined>(undefined);
    const [isAssistentNoteButtonCompleted, setIsAssistentNoteButtonCompleted] = useState<boolean | undefined>(undefined);
    const [isCommentButtonCompleted, setIsCommentButtonCompleted] = useState<boolean | undefined>(undefined);

    const datePickerButtonClickHandler = () => {

        if (isDatePickerButtonCompleted) return;
        if (coach == null) return;

        if (coach.calendly) {
            window.open(
                coach.calendly,
                '_blank',
                'noopener noreferrer'
            )
        }
    };
    const zoomButtonClickHandler = () => {

        if (isZoomLinkButtonCompleted) return;
        if (highwayLesson.zoom) {
            window.open(
                highwayLesson.zoom,
                '_blank',
                'noopener noreferrer'
            )
        }
    };
    const assistantCommentClickButtonkHandler = () => {

        if (!isZoomLinkButtonCompleted) return;
        if (onCommentClick && choiceLessonHandler) {
            choiceLessonHandler(highwayLesson.id)
            onCommentClick();
        }
    };
    const ratingClickButtonHandler = () => {
        
        if (!isAssistentNoteButtonCompleted || isCommentButtonCompleted) return;
        if (onRatingClick) onRatingClick();
    };

    useEffect(() => {
        setHighwayLesson(lesson)
    }, [lesson]);

    useEffect(() => {

        if (highwayLesson.zoom) {
            setIsDatePickerButtonCompleted(true);
        }

        if (highwayLesson.coachClosingDate) {
            setIsDatePickerButtonCompleted(true);
            setIsZoomLinkButtonCompleted(true);
            setIsAssistentNoteButtonCompleted(true);
        }
        
        if (highwayLesson.closingDate) {
            setIsCommentButtonCompleted(true);
        }
    }, [highwayLesson]);

    console.log(`LESSON ${index + 1}:`, highwayLesson);
    
    return (
        <RowBox>
            <p>
                Lezione {index + 1}
            </p>

            <IconBox>
                <LessonIconButton
                    type="button"
                    isCompleted={isDatePickerButtonCompleted}
                    onClick={datePickerButtonClickHandler}
                >
                    <DatePickerSVG />
                </LessonIconButton>
                
                <LessonIconButton
                    type="button"
                    isCompleted={isZoomLinkButtonCompleted}
                    disabled={!isDatePickerButtonCompleted}
                    onClick={zoomButtonClickHandler}
                >
                    <VideocallSVG />
                </LessonIconButton>
                
                <LessonIconButton
                    type="button"
                    isCompletedAndCliccable={isAssistentNoteButtonCompleted}
                    disabled={!isZoomLinkButtonCompleted}
                    onClick={assistantCommentClickButtonkHandler}
                >
                    <CommentSVG />
                </LessonIconButton>

                <LessonIconButton
                    type="button"
                    isCompleted={isCommentButtonCompleted}
                    disabled={!isAssistentNoteButtonCompleted}
                    onClick={ratingClickButtonHandler}
                >
                    <CheckCircleSVG />
                </LessonIconButton>
            </IconBox>
        </RowBox>
    );
}

export default LessonRow;