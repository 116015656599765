import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';


export const Fieldset = styled.fieldset<{padding?: number, fitContent?: boolean, minWidth?: number, borderColor?: string, backgroundColor?: string, cursor?: string}>`

    position: relative;
    
    width: ${(props) => props.fitContent ? 'fit-content' : '100%'};
    max-width: 100%;
    height: 100%;
    min-width: 0;
    // for the future:
    //   children padding left is final minus 16px!
    ${(props) => props.padding ?
        css`
            padding: ${props.padding+'px'};
        `
        :
        css`
            padding: 0 0 0 16px;
            padding-top: revert;
        `
    }

    border-radius: 4px;
    border: ${(props) => '1px solid '+(props.borderColor ?? colors.white023)};
    background-color: ${(props) => props.backgroundColor ?? 'transparent'};
    ${(props) => props.cursor && css`cursor: ${props.cursor};`}
    ${(props) => props.minWidth &&
        css`
            @media (min-width: ${props.minWidth+103}px) {
                min-width: ${props.minWidth}px;
            };
        `
    }
`;

export const Legend = styled.legend<{color?: string}>`

    padding: 0 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    cursor: default;

    color: ${(props) => props.color ?? colors.white023};

    display: flex;
    align-items: center;
    gap: 5px;
`;

export const SVGIconContainer = styled.span<{notClickable?: boolean, size?: number, fill?: string, top?: number, right?: number, left?: number, bottom?: number, opacity?: number}>`
    display: flex;
    align-items: center;
    height: ${(props) => props.size ? props.size+'px' : '20px'};
    width: ${(props) => props.size ? props.size+'px' : '20px'};
    z-index: 1;
    svg {
        transition: opacity .3s ease;
        opacity: ${(props) => props.opacity ?? '1'};
        cursor: ${(props) => props.notClickable ? 'default' : 'pointer'};
        height: ${(props) => props.size ? props.size+'px' : '20px'};
        width: ${(props) => props.size ? props.size+'px' : '20px'};
        ${(props) => props.fill && css`path {fill: ${props.fill};};`}
    }
    ${(props) => ([props.top,props.left,props.right,props.bottom].some(x => x!==undefined)) && (css`position: absolute;`)};
    ${(props) => props.top!==undefined && (css`top: ${props.top}px;`)};
    ${(props) => props.right!==undefined && (css`right: ${props.right}px;`)};
    ${(props) => props.left!==undefined && (css`left: ${props.left}px;`)};
    ${(props) => props.bottom!==undefined && (css`bottom: ${props.bottom}px;`)};
`;