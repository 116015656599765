// routing
import { Outlet, useNavigate } from 'react-router-dom';

// hooks
import useCheckUserLogin from 'hooks/useCheckUserLogin';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsFirstRender, useIsMounted } from 'usehooks-ts';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { checkPaypalPayment, checkPaypalPaymentWhenLoggedIn, checkPaypalSubscriptionPayment } from 'services/payments.services';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { getUserData, getUsersProducts } from 'services/user.services';
import { setPersonalAssistant } from 'pages/LDL/HomePageLDL/services/HomePageLDL.services';
import LoadingMask from 'components/ui/LoadingMask';
import { getEnvironmentBaseUrl } from 'configs/config.helper';


const ReservedArea = () => {

    useCheckUserLogin();

    const { clientId, tokenID } = useAppSelector(state => state.user);
    const [newPaymentChecked, setNewPaymentChecked] = useState<boolean>(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();
    const navigate = useNavigate();
    const isFirstRender = useIsFirstRender();

    const isUserLoggedIn = !!tokenID;

    const checkPayments = async () => {

        const pendingCoursePurchase = getItemFromLocalStorage('Pending purchase ID');
        const pendingSubscription = getItemFromLocalStorage('Pending subscription ID');
        const pendingBankTransfer = getItemFromLocalStorage('Pending Bank Transfer');
        const personalAssistantCode = getItemFromLocalStorage('Personal Assistant Code');
        const backendIsWaitingFrom = getItemFromLocalStorage('Backend is waiting from');
        if (
            !pendingCoursePurchase && 
            !pendingSubscription && 
            !pendingBankTransfer &&
            !backendIsWaitingFrom
        ) return removeItemFromLocalStorage('Personal Assistant Code');
        
        if (pendingCoursePurchase) {

            const snackbarData: SnackbarOptions = {};
            
            try {
                
                if (backendIsWaitingFrom !== null) await checkPaypalPayment(pendingCoursePurchase);

                // console.log("Controllo Pagamento Pendente", pendingCoursePurchase);
                await checkPaypalPaymentWhenLoggedIn(pendingCoursePurchase);
                
                if (!isMounted()) return;
                
                if (pendingBankTransfer === "true") {
                    setIsBankTransferPending(true)
                    return
                };

                if (personalAssistantCode != null) {
                    
                    try {                        
                        await setPersonalAssistant(personalAssistantCode);
                        removeItemFromLocalStorage('Personal Assistant Code');
                        dispatch( getUsersProducts() );
                    } catch (error: any) {
                        console.warn("ERROR:", error);
                    }
                };
    
                removeItemFromLocalStorage('Pending purchase ID');
                setNewPaymentChecked(true);
                snackbarData.type = 'success';
                snackbarData.message = "COMPLIMENTI! Il tuo corso è stato accreditato con Successo!";
                dispatch( addSnackbar(snackbarData) );
                // if (backendIsWaitingFrom === null) 
                navigate('/reservedArea/mycourses');
                removeItemFromLocalStorage('Backend is waiting from');
            } catch (error: any) {
                // alert("son già in errore")
                // const response = error.response;
                // console.log("ERRORE RISPOSTA", response); 
                
                // console.log("ERRORE MESSAGGIO", response.data.message);
                if(error.message.includes("Already assigned")) {
                    if(error.message.includes(clientId)) {
                        // TO DO gestisce ID sbagliato quando abbiamo sezione pagamenti
                        localStorage.clear();
                    }
                    localStorage.clear();
                    return;
                };

                if (backendIsWaitingFrom) {

                    const isBackendWaitingPaypal: boolean = (error.message === 'Waiting for paypal authorization');
                    const backEndWaitingTime = ((new Date().getTime()) - +backendIsWaitingFrom!);
                    const isWaitingTimeExpired = ((+backEndWaitingTime! / 300000) > 12);
    
                    if (isBackendWaitingPaypal && !isWaitingTimeExpired) return;
                    if (isBackendWaitingPaypal && isWaitingTimeExpired) {
                        
                        snackbarData.type = 'error';
                        snackbarData.message = "Qulcosa è andato storto nella procedura del tuo ultimo acquisto! Contatta il Supporto";
                        dispatch( addSnackbar(snackbarData) );
                        setNewPaymentChecked(true);
                        localStorage.clear();
                        return;
                    }
                }
                if (error.message === '403') return navigate('/fallback-page/permission-denied');

                console.warn("Unable to check offline payments.", error);
                // snackbarData.type = 'error';
                // snackbarData.message = "Qulcosa è andato storto nella procedura di acquisto! Riprova più tardi";
                // dispatch( addSnackbar(snackbarData) );
            }
        }

        if (pendingSubscription) {

            const snackbarData: SnackbarOptions = {};

            try {

                const response = await checkPaypalSubscriptionPayment(pendingSubscription);
                if (!isMounted()) return;
                if (!response || response === undefined) {
                    new Error ("Offline payments check response is undefined.")
                }
    
                removeItemFromLocalStorage('Pending subscription ID');
                setNewPaymentChecked(true);
                navigate('/reservedArea/myservices');
                snackbarData.type = 'success';
                snackbarData.message = "COMPLIMENTI! Il tuo Servizio è stato caricato con Successo!";
                dispatch( addSnackbar(snackbarData) );
            } catch (error: any) {
                if (error.message === '403') return navigate('/fallback-page/permission-denied');
                // TO DO error.message case
                console.warn("Unable to check offline payments.", error);
                snackbarData.type = 'error';
                snackbarData.message = "Qualcosa è andato storto nella procedura di acquisto! Riprova più tardi";
                dispatch( addSnackbar(snackbarData) );
            }
        }
    };

    useEffect(() => {

        if (newPaymentChecked === undefined || !newPaymentChecked) return;
        dispatch( getUserData() );
    }, [newPaymentChecked, dispatch])

    useEffect(() => {

        if (isFirstRender) checkPayments();

        const paymentInterval = setInterval(() => {
            
            checkPayments();
        }, 300000);
        
        if (newPaymentChecked) return clearInterval(paymentInterval);
        return () => clearInterval(paymentInterval);
    }, [newPaymentChecked, isFirstRender])

    useEffect(() => {

        if (isBankTransferPending === false) return;
        navigate("/reservedArea/shop")
    },[isBankTransferPending, navigate]);

    useEffect(() => {
        
        if (tokenID === undefined) return;
        if (isUserLoggedIn) {
            
            const whereRedirectUser = (getItemFromLocalStorage('To redirect at'));
            if (!whereRedirectUser || (whereRedirectUser === null)) return;
            
            removeItemFromLocalStorage('To redirect at');
            window.location.href = (whereRedirectUser);
            // navigate(whereRedirectUser);
            return;
        }

        if ((tokenID === null) && 
            !window.location.href.includes('login') &&
            !(window.location.href === `${getEnvironmentBaseUrl}/app/reservedArea`) 
            ) setItemToLocalStorage('To redirect at', window.location.href);

        if (getItemFromLocalStorage('To redirect at')) {const snackbarData: SnackbarOptions = {};
        snackbarData.type = 'info';
        snackbarData.message = "Per accedere a questo contenuto devi fare il login al nostro sito.";
        if (!(window.location.href === `${getEnvironmentBaseUrl}/app/reservedArea`)) dispatch( addSnackbar(snackbarData) );}
    }, [tokenID]);
    

    if (!isUserLoggedIn) return (
        <LoadingMask
            isCenter
            size='page'
            paddingTop='200px'
        />
    );

    return (
        <>
            {isUserLoggedIn && <Outlet />}
        </>
    )
};

export default ReservedArea;

