import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';

export const Section = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const SectionTitle = styled.h2`

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 0px;
    padding: 0px 10px;

    font-weight: 400;
    font-size: 22px;

    color: white;

    @media ${devices.tablet}{       
    
        padding: 0px 0px 0 10px;
    }
`;

export const Table = styled.table<{borderSpacing?: string}>`
    background-color: #29353e5e;
    color: white;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
    ${(props) => props.borderSpacing && css`border-spacing: ${props.borderSpacing};`}
`;

export const THead = styled.thead`
    background-color: #29353E;
    border-radius: 5px 5px 0 0;
    tr :first-child {
        border-radius: 5px 0 0 0;
    }
    tr :last-child {
        border-radius: 0 5px 0 0;
    }
    
`;

export const TBody = styled.tbody<{rowsHeight?: string}>`
    ${(props) => props.rowsHeight && (
        css`
            td {
                height: ${props.rowsHeight};
            }
        `
    )}
    /* tr:nth-of-type(even) {
        background-color: #29353E66;
    } */
`;

export const TR = styled.tr<{backgroundColor?: string, fontSize?: number, cellPaddingVert?: string, cellPaddingHor?: string}>`
    position: relative;
    :hover td:first-of-type>div {
        transform: scale(1);
    }
    ${(props) => props.backgroundColor && css`background-color: ${props.backgroundColor};`}
    ${(props) => props.fontSize && css`font-size: ${props.fontSize}px;`}
    td {
        padding: 5px;
        /* @media ${devices.mobile} {
            padding: 8px;
        };
        @media (max-width: 344px) {
            padding: 5px 3px;
        }; */
        ${(props) => props.cellPaddingVert && 
            css`
                padding-top: ${props.cellPaddingVert}!important;
                padding-bottom: ${props.cellPaddingVert}!important;
            `
        }
        ${(props) => props.cellPaddingHor && 
            css`
                padding-left: ${props.cellPaddingHor}!important;
                padding-right: ${props.cellPaddingHor}!important;
            `
        }
    }
`;

export const TH = styled.th`
    border: 0;
    /* white-space: wrap; */
    transition: all 0.5s;
    padding: 8px;
    font-size: 13px;
    @media (max-width: 369px) {
        padding: 5px 2px;
    };
`;

export const TD = styled.td<{borderLeft?: string, borderRight?: string, cursor?: string, fontSize?: number, fontWeight?: number, color?: string, backgroundColor?: string}>`
    position: relative;
    border: 0;
    white-space: wrap;
    transition: all 0.5s;
    font-weight: 400;
    text-align: center;
    ${(props) => props.borderLeft && css`border-left: ${props.borderLeft};`}
    ${(props) => props.borderRight && css`border-right: ${props.borderRight};`}
    ${(props) => props.cursor && (
        css`
            cursor: ${props.cursor};
            :hover {
                opacity: 0.5;
            }
        `
    )}
    ${(props) => props.fontSize && css`font-size: ${props.fontSize}px;`}
    ${(props) => props.fontWeight && css`font-weight: ${props.fontWeight};`}
    ${(props) => props.color && css`color: ${props.color};`}
    ${(props) => props.backgroundColor && css`background-color: ${props.backgroundColor};`}
`;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: white;
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: #ffffff17;
		}
	}
`;

export const FooterPagesButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export const FooterPageButton = styled.button`
    font-size: 14px;
    color: white;
    background: none;
	border: none;
	cursor: pointer;
    outline: none;
    border-radius: 2px;
    :focus {
        background-color: #000b4355;
    }
	&:disabled {
		cursor: default;
        background-color: #000b43;
	}
`;

export const CellContainer = styled.div<{width?: string}>`
    display: flex;
    gap: 5px;
    justify-content: center;
    ${(props) => props.width && css`width: ${props.width};`}
`;

export const NoResults = styled.div`
    width: fit-content;
    background-color: #29353E;
    border-radius: 5px;
    color: white;
    text-align: center;
    padding: 15px;
`;

export const SingleDivCell = styled.td<{paddingBottom?: string}>`
    padding-bottom: ${props => props.paddingBottom ?? '0'}!important;
`;

export const AddButton = styled(Button)`
    height: 28px;
    padding: 5px;
    font-size: 12px;
`;

export const StartButton = styled(Button)`
    height: 28px;
    padding: 5px;
    font-size: 12px;
`;


export const BonusStepsTitle = styled.div`
    color: white;
    padding: 5px 20px;
    border-bottom: 2px solid rgb(221, 221, 221);
    text-align: center;
    cursor: default;
    font-size: 20px;
    font-weight: 500;
`;

export const BonusStepBox = styled.div<{isDisabled?: boolean, opacity?: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    min-width: 250px;
    width: 100%;
    border-radius: 5px;
    background-color: #003754;
    padding: 5px;
    opacity: ${(props) => props.opacity ? props.opacity : (props.isDisabled ? '0.3' : '1')};
    pointer-events: ${(props) => props.isDisabled ? 'none' : 'auto'};
    transition: opacity 0.3s ease-in-out;
`;

export const BonusStepDescriptionEdit = styled.textarea`
    width: 100%;
`;

export const BonusStepDescription = styled.div`
    width: 100%;
    padding: 10px;
    background: #3c5772;
    border-radius: 5px;
`;

export const BonusStepDescriptionPar = styled.p`
    margin: 0;
`;


export const BonusStepTypeLabel = styled.div`
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
`;

export const NoWrapLabel = styled.span`
    white-space: nowrap;
`;

export const BoxedLabel = styled.div<{backgroundColor?: string}>`
    cursor: default;
    padding: 5px;
    background-color: ${(props) => props.backgroundColor ?? 'transparent'};
    border-radius: 3px;
    font-weight: 500;
`;

export const ExpirationDateLabel = styled.span<{backgroundColor?: string}>`
    background-color: ${(props) => props.backgroundColor ?? '#007207'};
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    white-space: nowrap;
    font-weight: 500;
`;
