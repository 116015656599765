import { callGET, callOldGET } from "network/network";

export const getEarningRestults = async () => {

    try {
        
        const response = await callGET('v1/self/userresults/libertydashboardresults', true);
        if (!response || response === undefined) throw new Error("ERROR: Unable to get results.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getProductSubscribers = async (productId: number, dateToCheck: string) => {

    try {
        
        const productSubscribersParams = {
            date: dateToCheck
        };
        const response = await callGET(`v1/productsubscriptions/products/${productId}/active`, true, productSubscribersParams);
        if (!response || response === undefined) throw new Error("ERROR: Unable to get subscribers.");

        return response;
    } catch (error:any) {
        throw error;
    }
}