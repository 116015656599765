import styled, { css } from "styled-components";
import colors from "style/palette";
import { Button } from "style/buttons";
import devices from "style/breakpoints";
import BaseModal from "components/ui/BaseModal";

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    width: 100%;
    max-width: 900px;
    height: 100%;

    padding: 30px 30px 100px 30px;

    background: ${colors.veryDarkGrey};
    border: 0.5px solid ${colors.primaryColor};
    border-radius: 15px;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};

    overflow: auto;
    
    @media ${devices.tablet} {
        
        height: fit-content;
        padding: 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const MainBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 35px;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const MainBoxConnect = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 35px;


    text-align: center;

    > button {
        max-width: fit-content;
    }

    @media ${devices.tablet} {

        min-width: 600px;

    }
`;

export const ImageToShare = styled.img`
    
    margin: 35px 0 0;
    
    width: 100%;

    @media ${devices.tablet} {   
        
        margin: 0;

        max-width: 490px;
    }
`;

export const CommentColumn = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 35px;

    width: 100%;

    @media ${devices.tablet} {
        max-width: 290px;
    }
`;

export const CommentText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    color: ${colors.white};

    > a {
        color: ${colors.primaryColor};
    }
`;

export const CommentBox = styled.textarea`

    width: 100%;
    height: 180px;

    padding: 25px;

    background: ${colors.transparent};
    border: 1px solid ${colors.white023};
    border-radius: 10px;
    color: ${colors.white};
`;

export const ShareButton = styled(Button)`

    background: ${colors.green};
    color: ${colors.white};

    > svg {
        margin: 0 15px 0 0;
    }

    @media ${devices.tablet} {
        white-space: normal;
    }
`;

export const Disclaimer = styled.div`

    margin: 45px 0 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    color: ${colors.blueGrey};

    text-align: center;

    @media ${devices.tablet} {
        margin: 40px 0 0;
    }
`;

export const UserImageProfile = styled.img<{flavor: 'facebook' | undefined}>`

    min-width: 80px;
    max-width: 80px;

    min-height: 80px;
    max-height: 80px;

    border-radius: 50%;
    border: 1px solid ${colors.white};

    ${({ flavor }) => (flavor === 'facebook') && (

        css`
            border: 3px solid ${colors.blue};
        `
    )}
`;

export const UserNameRow = styled.div`

    width: 100%;

    margin: 20px 0 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    gap: 10px;
    flex-direction: row;

    > span {

        font-size: 15px;
        font-weight: 500;
        line-height: 17px;

        color: ${colors.white};
    }
`;

export const LoadingModal = styled(BaseModal)`

    overflow: hidden;
`