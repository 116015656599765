import { FC } from 'react';

// types
import { EventDetailsProps } from './EventDetails';

// style
import {
    EventDetailsBox,
    AssetBox,
    RoundImg,
    InfoBox,
    DateBox
} from './style/EventDetails.style';


/**
 * RoadMap event details
 * 
 * @author Alessio Grassi
 * 
 * @param imgSrc - source of the rounded image of the event
 * @param date - formatted date of the event (string)
 * @param hour - formatted hour of the eventi (string)
 * @param info - event title
 * @param link - event link
 * @param isBottom - the event is displayed on the bottom of the timeline
 * @param isActive - the event is the "next" one
 * @param isPast - the event is past
 * 
 * @returns JSX
 */


const EventDetails: FC<EventDetailsProps> = ({
        imgSrc,
        date,
        hour,
        info,
        link,
        isBottom,
        isActive,
        isPast,
        isTbd
    }) => {

    const viewHourData = ((hour !== 'h 03:00') && (hour !== 'h 02:00') && (hour !== 'h 01:00'));

    return (
        <EventDetailsBox 
            isBottom={isBottom} 
            href={link}
            target='_blank'
            rel='noopener noreferrer'
        >
            <AssetBox>
                <RoundImg
                    src={imgSrc}
                    isPast={isPast}
                    isActive={isActive}
                />
            </AssetBox>

            <InfoBox
                isPast={isPast}
                isActive={isActive}
            >
                {!isTbd && (
                    <DateBox
                        isPast={isPast}
                        isActive={isActive}
                    >
                        <span>
                            {date}
                        </span>

                        {viewHourData && 
                            <span>
                                {hour}
                            </span>
                        }
                    </DateBox>
                )}

                <span>
                    {info}
                </span>
            </InfoBox>
        </EventDetailsBox>
    );
};

export default EventDetails;
