import { Auth } from 'aws-amplify';


export const awsGetCurrentAuthenticatedUser = async () => {

    try {
        
        const response = await Auth.currentAuthenticatedUser();

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};

export const awsGetUserSession = async () => {

    try {

        const response = await Auth.currentSession();
        
        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

export const awsRefreshUserSession = async () => {

    try {

        const refreshedUser = await Auth.currentAuthenticatedUser();
        
        if (refreshedUser === undefined || !refreshedUser) {
            throw new Error();
        }

        // just in case relaunch local currentSession
        const response = await Auth.currentSession();

        if (response === undefined || !response) {
            throw new Error();
        }

        return refreshedUser;
        
    } catch (error: any) {
        throw error;
    }
};

export const awsSignOutUser = async () => {

    try {

        const response = await Auth.signOut();
        
        if (response === undefined || !response) {
            throw new Error();
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};

export const awsGetTokenID = async () => {

    try {

        const response = await Auth.currentSession();
        return response.getAccessToken().getJwtToken();
        
    } catch (error: any) {
        throw error        
    }    
}