import { callGET } from "network/network";


export const getProductData = async (productId: string, isUserLoggedIn?: boolean) => {
    
    try {
        
        const isAuthNeeded = isUserLoggedIn ?? false;
        const response = await callGET(`v1/productsforsell/${productId}`, isAuthNeeded);
        if (!response || response === undefined) throw new Error("Unable getting product for sell dada.");

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getOldIdProductData = async (productId: string, productType: string, isUserLoggedIn?: boolean) => {
    
    try {
        
        const productParams = {
            oldId: productId,
            type: productType
        }
        const isAuthNeeded = isUserLoggedIn ?? false;
        const response = await callGET(`v1/productsforsell/byoldid`, isAuthNeeded, productParams);
        if (!response || response === undefined) throw new Error("Unable getting product for sell dada.");

        return response;
    } catch (error: any) {
        throw error;
    }
};
