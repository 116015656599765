import styled from 'styled-components';
import devices from 'style/breakpoints';


export const Form = styled.form`

    width: 100%;
    max-width: 400px;

    margin: 0 auto;

    @media ${devices.tablet} {

        height: 100%;

        margin: 0;

        position: sticky;
        top: 20px;
    }
`;
