// component
import BaseFieldset from "components/ui/BaseFieldset";
import { FC } from "react";
import Lesson from "../LessonContent/Lesson.component";

// style
import { 
    ActionDesktop,
    CoachTextDesktop, 
    EndedGrid, 
    EndedTable, 
    InfoText, 
    RowLabel,
    TitleDesktop
} from "./style/EndedLessonDesktop.style";


const EndedLessonDesktop : FC <{
    lessons: any, 
    choiceLessonHandler: (lessonId: number, typeOfLesson: 'new' | 'ended') => void,
    openPersonalAsistantNotesModal: () => void,
    typeOfLesson: 'new' | 'ended'
}>= ({
    lessons, 
    choiceLessonHandler, 
    openPersonalAsistantNotesModal,
    typeOfLesson
}) => {

    return (
        <BaseFieldset legend="STORICO LEZIONI">
            <EndedGrid>
                <EndedTable>
                    <RowLabel>
                        <InfoText>SCADENZA</InfoText>

                        <CoachTextDesktop>ASSISTENTE / COACH</CoachTextDesktop>

                        <TitleDesktop>TITOLO LEZIONE</TitleDesktop>

                        <ActionDesktop>AZIONI</ActionDesktop>
                    </RowLabel>

                    {(lessons !== undefined) && lessons.map((lesson: any, index: number) => {
                        return (
                            <Lesson
                                key={"ENDED" + index}
                                lesson={lesson}
                                choiceLessonHandler={choiceLessonHandler}
                                onCommentClick={openPersonalAsistantNotesModal}
                                typeOfLesson={typeOfLesson}
                            />
                        );
                    })}

                </EndedTable>
            </EndedGrid>
        </BaseFieldset>
    );
};

export default EndedLessonDesktop;
