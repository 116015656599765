import { FC, useEffect, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';

//utils
import { 
    validateAddressInput,
    validateCapInput,
    validateCodeInput,
    validateMnicipalityInput,
    validateNameInput, 
    validateNationInput, 
    validateProvinceInput, 
    validateSurnameInput 
} from 'pages/SignUp/SignUpForm/SignUpForm.helper';
import { addTransferPaymentInfo } from 'services/payments.services';
import { callPOST, callPUT } from 'network/network';
import { setItemToLocalStorage } from 'utils/localStorage';

//components
import TextInput from 'components/ui/TextInput';

//style
import {
    TitleSecondStepBox,
    TitleSecondStepText,
    ClientDataMainBox,
    ClientDataRow,
    ReceiptGlobalBox,
    TextReceipt,
    UpdloadReceiptBox,
    UploadButton,
    UploadText,
    SandButton
} from './style/SecondStepBankTransfer.style';
import { enableAppMain } from 'redux/actions/actions-app-main';
import useAppDispatch from 'hooks/useAppDispatch';
import LoadingMask from 'components/ui/LoadingMask';

const SecondStepBankTransfer: FC<{secondActive: () => void, paymentTransferID: number | undefined, onFileUpload: () => void, isTooLargeFile: () => void,  onError: (error: any) => void}> = ({secondActive, paymentTransferID, onFileUpload, isTooLargeFile, onError}) => {

    const {tokenID} = useAppSelector(state => state.user);
    const [nameInput, setNameInput] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>(undefined);
    const [surnameInput, setSurnamelInput] = useState<string>("");
    const [surnameErrorMessage, setSurnameErrorMessage] = useState<string | undefined>(undefined);
    const [codeInput, setCodeInput] = useState<string>("");
    const [codeErrorMessage, setCodeErrorMessage] = useState<string | undefined>(undefined);
    const [addressInput, setAddressInput] = useState<string>("");
    const [addressErrorMessage, setAddressErrorMessage] = useState<string | undefined>(undefined);
    const [capInput, setCapInput] = useState<string>("");
    const [capErrorMessage, setCapErrorMessage] = useState<string | undefined>(undefined);
    const [municipalityInput, setMunicipalityInput] = useState<string>("");
    const [municipalityErrorMessage, setMunicipalityErrorMessage] = useState<string | undefined>(undefined);
    const [provinceInput, setProvinceInput] = useState<string>("");
    const [provinceErrorMessage, setProvinceErrorMessage] = useState<string | undefined>(undefined);
    const [nationInput, setNationInput] = useState<string>("");
    const [nationErrorMessage, setNationErrorMessage] = useState<string | undefined>(undefined);
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const validateNameHandler = (newName: string) => {

        // if (!/^[a-zA-Z]+$/.test(newName)) {
        if (!/^[\p{L}\p{M}]+$/u.test(newName)) {
            setNameErrorMessage("Il nome deve contenere solo lettere.");
            return "Il nome deve contenere solo lettere.";
        }
    
        const error = validateNameInput(newName);
        setNameErrorMessage(error);
    
        return error;
    };

    const nameInputChangeHandler = (newName: string) => {

        setNameInput(newName);
        validateNameHandler(newName);
    };

    const validateSurnameHandler = (newSurname: string) => {

        if (!/^[\p{L}\p{M}]+$/u.test(newSurname)) {
            setSurnameErrorMessage("Il cognome deve contenere solo lettere.");
            return "Il cognome deve contenere solo lettere.";
        }
    
        const error = validateSurnameInput(newSurname);
        setSurnameErrorMessage(error);
        
        return error;
    };
    
    const surnameInputChangeHandler = (newSurname: string) => {

        setSurnamelInput(newSurname);
        validateSurnameHandler(newSurname);
    };

    // const CODE_LENGTH = 16
    const CODE_LENGTH = /^[\w\d\s]{1,16}$/;

    // const validateCodeHandler = (newCode: string) => {

    //     if (newCode.length !== CODE_LENGTH) { 
    //         return "Il tuo Codice Fiscale non è del formato corretto";
    //     };
        
    //     const error = validateCodeInput(newCode);
    //     setCodeErrorMessage(error);
        
    //     return error;
    // };

    const validateCodeHandler = (newCode: string) => {

        if (!CODE_LENGTH.test(newCode)) { 
            return "Your Tax Code is not in the correct format";
        };
       
       const error = validateCodeInput(newCode);
       setCodeErrorMessage(error);
       
       return error;
   };

    const codeInputChangeHandler = (newCode: string) => {
        
        if (CODE_LENGTH.test(newCode)) { 
            validateCodeHandler(newCode);
        } else {
            setCodeErrorMessage("Il tuo Codice Fiscale non è del formato corretto");
        }
        setCodeInput(newCode);
    };
    
    const validateAdrressHandler = (newAddress: string) => {
        
        const error = validateAddressInput(newAddress);
        setAddressErrorMessage(error);
        
        return error;
    };
    
    const addressInputChangeHandler = (newAddress: string) => {
        
        validateAdrressHandler(newAddress);
        setAddressInput(newAddress);
    };
    
    const validateCapHandler = (newCap: string) => {

        const capRegex = /^[0-9]{1,5}$/;
  
        if (!capRegex.test(newCap)) {
          return "Il Cap inserito è errato.";
        }
      
        return undefined;
    };
    
    const capInputChangeHandler = (newCap: string) => {

        setCapInput(newCap);
        const error = validateCapHandler(newCap);
        setCapErrorMessage(error);
    };
    
    const validateMunicipalityHandler = (newMunicipality: string) => {

        if (!/^[\p{L}\p{M}]+$/u.test(newMunicipality)) {
            setMunicipalityErrorMessage("Il Comune deve contenere solo lettere.");
            return "Il Comune deve contenere solo lettere.";
        }
    
        const error = validateNameInput(newMunicipality);
        setMunicipalityErrorMessage(error);
    
        return error;
    };
    
    const municipalityInputChangeHandler = (newMunicipality: string) => {
        
        setMunicipalityInput(newMunicipality);
        validateMunicipalityHandler(newMunicipality);
    };
    
    const validateProvinceHandler = (newProvince: string) => {

        if (!/^[\p{L}\p{M}]+$/u.test(newProvince)) {
            setProvinceErrorMessage("La Provincia deve contenere solo lettere.");
            return "La Provincia deve contenere solo lettere.";
        }
    
        const error = validateNameInput(newProvince);
        setProvinceErrorMessage(error);
    
        return error;
    };
    
    const provinceInputChangeHandler = (newProvince: string) => {
        
        setProvinceInput(newProvince);
        validateProvinceHandler(newProvince);
    };
    
    const validateNationHandler = (newNation: string) => {

        if (!/^[\p{L}\p{M}]+$/u.test(newNation)) {
            setNationErrorMessage("La Nazione deve contenere solo lettere.");
            return "La Nazione deve contenere solo lettere.";
        }
    
        const error = validateNameInput(newNation);
        setNationErrorMessage(error);
    
        return error;
    };
    
    const nationInputChangeHandler = (newNation: string) => {
        
        setNationInput(newNation);
        validateNationHandler(newNation);
    };
    
    console.log("id bonifico", paymentTransferID);
    
    
    const onSecondButtonHandler = async () => {
        if (!isButtonActive) return;
        
        const transferPayload = {
            purchaseId: paymentTransferID,
            name: nameInput,
            surname: surnameInput,
            cfOrPi: codeInput,
            address: addressInput,
            cap: capInput,
            city: municipalityInput,
            province: provinceInput,
            country:nationInput
        };
        
        try {
            setIsLoading(true)
            const response = await addTransferPaymentInfo(paymentTransferID, transferPayload, isUserLoggedIn);    
            secondActive();
            setIsLoading(false)
            return response;
        } catch (error: any) {
            setIsLoading(false)
            const snackbarData: SnackbarOptions = {
                type: 'error',
                message: "C'è stato un problema con il tuo acquisto, riprova più tardi!"
            };
            dispatch(addSnackbar(snackbarData));
            onError(error);
            dispatch(enableAppMain());
            navigate('/reservedArea/shop');
            throw error;
        }
        
    };
    
    const handleFileUpload = async (files: FileList | null) => {
        if (!files) return;

        setFile(files[0]);
    };

    const checkFileSize = () => {
        if (file === null || file === undefined) return "No File";
        if (file.size > 2 * 1024 * 1024) {
            setFile(null);
            const snackbarData: SnackbarOptions = {};
            snackbarData.type = 'error';
            snackbarData.message = 'La dimensione deil file supera i 2MB massimi consentiti';
            dispatch( addSnackbar(snackbarData) )
            return "Too Large"
        };
        return "File OK"
    }
    
    const uploadFile = async () => {
        
        try {
            setIsLoading(true)
            if (!file) return;
            if (file.size > 2 * 1024 * 1024) throw new Error("Il File supera i 2MB.");
            const formBody = new FormData();
            formBody.append('file', file);

            const isAuthNeeded = isUserLoggedIn ?? false;

            const uploadResponse = await callPOST(`v1/self/purchases/${paymentTransferID}/upload`, formBody, isAuthNeeded);
            if (uploadResponse === undefined || !uploadResponse) throw new Error("ERROR: Unable to upload file.");

            const formParams = {
                fileId: uploadResponse.id
            }

            const connectResponse = await callPUT(`v1/self/purchases/${paymentTransferID}/file`, {}, isAuthNeeded, formParams);
            onFileUpload();
            setIsLoading(false)
            return connectResponse;
        } catch (error: any) {
            setIsLoading(false)
            const snackbarData: SnackbarOptions = {};
            snackbarData.type = 'error';
            snackbarData.message = error.message;
            dispatch( addSnackbar(snackbarData) )
            throw error;
        }
    };

    useEffect (()=> {
        if (file === null || file === undefined || !file) return;
        if (checkFileSize() !== "File OK") return;
        uploadFile();
        
    },[file]);

    const isButtonActive = (
        !nameErrorMessage &&
        !surnameErrorMessage &&
        !codeErrorMessage &&
        !addressErrorMessage &&
        !capErrorMessage &&
        !municipalityErrorMessage &&
        !provinceErrorMessage &&
        !nationErrorMessage &&
        (nameInput !== "") &&
        (surnameInput !== "") &&
        (codeInput !== "") &&
        (addressInput !== "") &&
        (capInput !== "") &&
        (municipalityInput !== "") &&
        (provinceInput !== "") &&
        (nationInput !== "") 
        );
        
        const isUserLoggedIn = !!tokenID;
        
        if (!isUserLoggedIn) {
            
            setItemToLocalStorage("Pending Bank Transfer", "true");
            
            setTimeout(() => {
                dispatch(enableAppMain());
                navigate('/login');
              }, 5000);
              
            return (
                <TitleSecondStepBox>
                    <TitleSecondStepText>VERRAI REINDIRIZZATO AL LOGIN PER COMPLETARE L'ACQUISTO!</TitleSecondStepText>
                </TitleSecondStepBox>
            );
        };

        if (isLoading) return (
            <LoadingMask 
                paddingTop='200px'
                isCenter
                size='page'
            />
        );

        return (
            <>
                <TitleSecondStepBox>
                    <TitleSecondStepText>COMPILA CON I TUOI DATI:</TitleSecondStepText>
                </TitleSecondStepBox>

                <ClientDataMainBox>
                    <ClientDataRow>
                        <TextInput
                            label="Nome:"
                            type="text"
                            flavor='filled'
                            value={nameInput}
                            errorMsg={nameErrorMessage}
                            onChange={nameInputChangeHandler}
                            onValidate={() => validateNameHandler(nameInput)}
                        />

                        <TextInput
                            label="Cognome:"
                            type="text"
                            flavor='filled'
                            value={surnameInput}
                            errorMsg={surnameErrorMessage}
                            onChange={surnameInputChangeHandler}
                            onValidate={() => validateSurnameHandler(surnameInput)}
                        />
                    </ClientDataRow>

                    <ClientDataRow>
                        <TextInput
                            label="Cod.Fisc. / P.IVA:"
                            type="text"
                            flavor='filled'
                            value={codeInput}
                            errorMsg={codeErrorMessage}
                            onChange={codeInputChangeHandler}
                            onValidate={() => validateCodeHandler(codeInput)}
                        />

                        <TextInput
                            label="Via:"
                            type="text"
                            flavor='filled'
                            value={addressInput}
                            errorMsg={addressErrorMessage}
                            onChange={addressInputChangeHandler}
                            onValidate={() => validateAdrressHandler(addressInput)}
                        />
                    </ClientDataRow>

                    <ClientDataRow>
                        <TextInput
                            label="Cap:"
                            type="text"
                            flavor='filled'
                            value={capInput}
                            errorMsg={capErrorMessage}
                            onChange={capInputChangeHandler}
                            onValidate={() => validateCapHandler(capInput)}
                        />

                        <TextInput
                            label="Comune:"
                            type="text"
                            flavor='filled'
                            value={municipalityInput}
                            errorMsg={municipalityErrorMessage}
                            onChange={municipalityInputChangeHandler}
                            onValidate={() => validateMunicipalityHandler(municipalityInput)}
                        />
                    </ClientDataRow>

                    <ClientDataRow>
                        <TextInput
                            label="Provincia:"
                            type="text"
                            flavor='filled'
                            value={provinceInput}
                            errorMsg={provinceErrorMessage}
                            onChange={provinceInputChangeHandler}
                            onValidate={() => validateProvinceHandler(provinceInput)}
                        />

                        <TextInput
                            label="Nazione:"
                            type="text"
                            flavor='filled'
                            value={nationInput}
                            errorMsg={nationErrorMessage}
                            onChange={nationInputChangeHandler}
                            onValidate={() => validateNationHandler(nationInput)}
                        />
                    </ClientDataRow>
                </ClientDataMainBox>

                <ReceiptGlobalBox>
                    <TextReceipt>
                        Carica la ricevuta contabile del bonifico effettuato (formato PDF, max 2 MB):
                    </TextReceipt>

                    <UpdloadReceiptBox>
                        <UploadButton onClick={() => document.getElementById("file")?.click()}>
                            Sfoglia...
                        </UploadButton>

                        <input
                            type="file"
                            id="file"
                            accept=".pdf"
                            onChange={(e) => handleFileUpload(e.target.files)}
                            style={{ display: 'none' }}
                        />
                        <UploadText>{file ? file.name : 'Nessun file selezionato.'}</UploadText>
                    </UpdloadReceiptBox>
                </ReceiptGlobalBox>

                <SandButton onClick={onSecondButtonHandler} disabled={!isButtonActive}>INVIA e concludi</SandButton>
            </>
    );
};

export default SecondStepBankTransfer;