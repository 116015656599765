import { FC, useEffect, useMemo, useState } from "react";

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';


// routing
import { Link, useSearchParams } from "react-router-dom";

// hooks
import useAppSelector from "hooks/useAppSelector";
import { useIsFirstRender } from "usehooks-ts";
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { postSubscibeTrial, updateLessonData } from "pages/ProductPage/services/ProductPage.services";

//components
import DescriptionTab from "./DescriptionTab/DescriptionTab.component";
import VideoCourseList from "../../VideoCourseList/VideoCourseList.component";
import MediaPlayer from "components/MediaPlayer";

//types
import { MediaProp } from "components/MediaPlayer/MediaPlayer";

//assets
// import { ReactComponent as SearchIconSVG } from '../../assets/searchIcon.svg';
import { ReactComponent as ExpandIconSVG } from '../../assets/expandIcon.svg';
import { ReactComponent as CollapseIconSVG } from '../../assets/collapseIcon.svg';

//style
import {
    InterectionLessonBox,
    // LessonSearch,
    ExpandLesson,
    // ProgressBar,
    PageCourse,
    PageContent,
    VideoBox,
    CourseInfoBox,
    CourseTitle,
    InterectionBox,
    InterectionButtonRow,
    DescriptionButton,
    LessonButton,
    FreePromoButton
} from './style/ProductPageMobile.style';
import { getCoursePayment } from "services/payments.services";
import { getPaypalUrl } from "utils/paypal";
import { setItemToLocalStorage } from "utils/localStorage";




const ProductPageMobile: FC<{
    courseItem: any,
    lessonToDisplay: any,
    typeOfProduct: string,
    lessonStatus: any,
    updateProductDetails: () => Promise<void>,
    isTrialCourseButtonVisible: boolean
}> = ({
    courseItem,
    lessonToDisplay,
    typeOfProduct, 
    lessonStatus,
    updateProductDetails,
    isTrialCourseButtonVisible
}) => {

    const { productsLessonsStatus, clientId } = useAppSelector(state => state.user);
    const [isButtonDescriptionActive, setIsButtonDescriptionActive] = useState(true);
    const [isButtonCurseListActive, setIsButtonCurseListActive] = useState(false);
    const [lessonRendering, setLessonRendering] = useState<any>(lessonToDisplay ?? undefined);
    const [lesson, setLesson] = useState<any>(lessonToDisplay.lesson ?? undefined);
    const [video, setVideo] = useState<any>(lessonToDisplay.lesson?.video ?? {});
    const [fileType, setFileType] = useState<any>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileType ?? undefined));
    const [videoUrl, setVideoUrl] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (
        lessonToDisplay.lesson?.video.url ? 
        lessonToDisplay.lesson?.video.url : 
        lessonToDisplay.lesson?.video.fileType.relativePath ? 
        `https://www.viveredipoker.it/ldlapifiletest/${lessonToDisplay.lesson?.video.fileType.relativePath}/${lessonToDisplay.lesson?.video.fileName}` :
         undefined
         ));
    const [fileName, setFileName] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileName ?? undefined));
    const [relativePath, setRelativePath] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileType.relativePath ?? undefined));
    const [finalUrl, setFinalUrl] = useState<string | undefined>(undefined);
    const [courseTitle, setcourseTitle] = useState<any>(undefined);
    const [filteredLessons, setFilteredLessons] = useState<any>(courseItem.course.content ?? undefined);
    const [searchInput, setSearchInput] = useState<any>("");
    const [collapse, setCollapse] = useState(true);
    // const [isLessonCompleted, setIsLessonCompleted] = useState<boolean | undefined>(undefined);
    const [lessonStatusData, setLessonStatusData] = useState<any>(lessonStatus);
    const [lessonStatusUpdated, setLessonStatusUpdated] = useState<boolean>(false);
    const [lessonInitialStatusUpdated, setLessonInitialStatusUpdated] = useState<boolean>(false);
    const [lessonVideoDuration, setLessonVideoDuration] = useState<number>(2);
    const [lessonVideoCurrentTime, setLessonVideoCurrentTime] = useState<number>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const irFirstRender = useIsFirstRender();

    const dispatch = useAppDispatch();

    const saveCompleteLessonData = (duration: number, currentTime: number) => {

        if (!currentTime || !duration) return;

        setLessonVideoDuration(duration);
        setLessonVideoCurrentTime(currentTime);
        console.log("DUR", lessonVideoDuration);
        console.log("CURR", lessonVideoCurrentTime);
        
    };

    const onClickButtonDescriptionHandler = () => {

        setIsButtonDescriptionActive(true);
        setIsButtonCurseListActive(false);         
    };

    const onClickButtonCurseListHandler = () => {

        setIsButtonCurseListActive(true);         
        setIsButtonDescriptionActive(false);
    };

    const lessonHandler = (lessonItem: any) => {
        setLessonRendering(lessonItem);
        onClickButtonDescriptionHandler();
    }

    const collapseAll = () => {
        console.log("collasso", collapse);
        
        setCollapse(!collapse);
    };

    const closeCollapase = () => {
        setCollapse(false)
    };

    const searchHandler = (searchValue: any) => {
        setSearchInput(searchValue.target.value)
    };

    const getLessonStatus = (lessonId: number) => {
        if (lessonStatusData === undefined) return;
        if (lessonStatusData.length === 0) return {};
        console.log("STATUS mobile id: "+lessonId+"???", lessonStatusData);
        return (lessonStatusData.filter((lesson: any) => lesson.lessonId === lessonId)[0]);
    };

    const updateLessonsDetails = async () => {

        const renderingLessonStatus = getLessonStatus(lessonRendering.id);
        console.log("LESSON STATUS mobile id "+lessonRendering.id+":", renderingLessonStatus);

        const baseStatus = {
            completed: false,
            courseId: courseItem.course.id,
            favorite: false,
            lessonId: lessonRendering.id,
            userId: clientId
        };
        
        const statusToUpdate = ((renderingLessonStatus !== undefined) && renderingLessonStatus.id) ? renderingLessonStatus : baseStatus;
        try {
            const hasUserCompletedTheLesson = (
                (lessonRendering.lesson.video === null) ? true : ((lessonVideoCurrentTime / lessonVideoDuration) > 0.9)
            );

            console.log("COMPLETATO???", hasUserCompletedTheLesson);
            console.log("lessonVideoCurrentTime???", lessonVideoCurrentTime);
            console.log("lessonVideoDuration???", lessonVideoDuration);
            
            const updateData = {
                ...statusToUpdate,
                lastSeenDate: new Date().toISOString(),
            };

            if (!statusToUpdate.completed) updateData.completed = hasUserCompletedTheLesson;

            console.log("TO UPDATE:", updateData);
            
            setLessonVideoCurrentTime(1);
            setLessonVideoDuration(2);
            await updateLessonData(updateData);
            // updateLessonData(updateData);
            // updateReduxLessonDetalis();
        } catch (error: any) {
            console.log("ERROR statusToUpdate: "+JSON.stringify(statusToUpdate));
        }
    };

    const getAssaingProductTrial = async () => {
        try {

            const response = await postSubscibeTrial(+courseItem.id!, 3);

            setItemToLocalStorage('Pending subscription ID', `${response.productService.productSubscriptionId}`)
             window.location.href = response.productService.approvalLink;
             return;
         } catch (error: any) {
            const snackbarData: SnackbarOptions = {
                type: 'error',
                message: "Non è stato possibile assegnarti il periodo di prova."
            };
            dispatch(addSnackbar(snackbarData));
            console.warn(error);
        }
    };

    useEffect(() => {
        setFilteredLessons(courseItem.course.content.filter(
            (lesson: any) => {
                if (lesson.title === undefined || lesson.title === '') return lesson;
                if (lesson.title.toLowerCase().includes(searchInput.toLowerCase())) return lesson;
                if (lesson.lesson){
                if (lesson.lesson.description) return lesson.lesson.description.toLowerCase().includes(searchInput.toLowerCase())};
            })
        )
    }, [searchInput]);

    useEffect(() => {
        if (lessonRendering !== undefined) {
            setLesson(lessonRendering.lesson);
        }

        if (lesson !== undefined) {
            setVideo(lesson.video);

        }

        if (video !== undefined && video !== null) {
            setVideoUrl(video.url);
            setFileType(video.fileType)
            setFileName(video.fileName);
        }
        
        if (fileType !== undefined) {
            setRelativePath(fileType.relativePath);
        }

        if (courseItem !== undefined) {
            if (typeOfProduct === 'level') setcourseTitle(courseItem.description);
            if ((typeOfProduct === 'course') || (typeOfProduct === 'service')) setcourseTitle(courseItem.name);
        }


    }, [fileName, fileType, lessonRendering, lesson, relativePath, video, videoUrl, courseTitle, courseItem]);

    useEffect(() => {

        if (video === undefined && video === null) return;

        if (videoUrl && videoUrl !== null) return setFinalUrl(videoUrl);
        
        if (relativePath && 
            (relativePath !== null) &&
            fileName &&
            (fileName !== null)) return setFinalUrl(`https://www.viveredipoker.it/ldlapifiletest/${relativePath}/${fileName}`)
    }, [fileName, relativePath, videoUrl, video, lessonRendering]);

    useEffect(() => {
        // if (productsLessonsStatus === undefined) return;
        setLessonStatusUpdated(false);
        setLessonVideoDuration(2);
        setLessonVideoCurrentTime(1);
        setLessonInitialStatusUpdated(false);
        // console.log("LEZIONE:", lessonRendering);
        // console.log("UPLOAD DI BASE");
        // console.log("UPLOAD DI BASE SENZA VIDEO", lessonRendering.lesson.video === null);
        // updateLessonsDetails()
        setSearchParams({nodeID: lessonRendering.id})
    }, [lessonRendering]);

    useEffect(() => {

        if (!irFirstRender) return;
        if (!searchParams.get('nodeID') || searchParams.get('nodeID') === null) return;

        const newLessonToRender = courseItem.course.content.filter((lesson: any) => lesson.id === lessonToDisplay.id);

        if (newLessonToRender.length === 0) return;

        setLessonRendering(newLessonToRender[0])

    }, [searchParams]);

    useEffect(() => {
        if (lessonInitialStatusUpdated) return;
        if ((lessonVideoCurrentTime === 1) && (lessonVideoDuration === 2)) {
            updateLessonsDetails();
            updateProductDetails();
            setLessonInitialStatusUpdated(true);
        };
    }, [lessonInitialStatusUpdated]);

    useEffect(() => {
        
        console.log("CURR", lessonVideoCurrentTime);
        console.log("DUR", lessonVideoDuration);
        // console.log("PERC", (lessonVideoCurrentTime / lessonVideoDuration));
        
        if (lessonStatusUpdated) return;
        const isVideoLesson = (lessonRendering && lessonRendering.lesson && lessonRendering.lesson.video !== null);
        
        if (!isVideoLesson) return;
        
        // console.log("HA IL VIDEO");
        if ((lessonVideoDuration === 0) || (lessonVideoCurrentTime === 0)) return;
        const lessonCompletePercentage = (lessonVideoCurrentTime / lessonVideoDuration);
        console.log("PERC", (lessonCompletePercentage));
        // console.log("PERCENTUALE:", `${lessonCompletePercentage * 100}%`);
        if (lessonCompletePercentage > 0.9) {
            console.log("DENTRO");
            setLessonStatusUpdated(true);
            // console.log("LEZIONE COMPLETATA!");
            // console.log("UPLOAD CON VIDEO");
            updateLessonsDetails();
        }
    }, [lessonVideoDuration, lessonVideoCurrentTime, lessonRendering, lessonStatusUpdated]);

    useEffect(() => {
        setLessonStatusData(lessonStatus);
    }, [lessonStatus]);

    const localMedia: MediaProp = useMemo(() => {

        return {
           src: finalUrl ?? "",
           type: 'video/mp4'
       };
    }, [finalUrl]);

    const finalLinkTo = (
        (typeOfProduct === 'course') ?
        '/reservedArea/mycourses' :
        (
            (typeOfProduct === 'service') ?
            '/reservedArea/myservices' :
            '/reservedArea'
        ));
    const finalPath = (
        (typeOfProduct === 'course') ?
        'Corsi' :
        (
            (typeOfProduct === 'service') ?
            'Servizi' :
            (
                (typeOfProduct === 'level') ? 
                'Area Riservata' :
                ''
            )
        ));

    return (
        
        <PageCourse>
            {courseItem && 
            <>
                {lessonRendering?.lesson?.video && (
                <VideoBox>
                    {finalUrl && <MediaPlayer media={localMedia} preload='metadata' saveCompleteLessonData={saveCompleteLessonData} />}
                </VideoBox>
                )}

                <PageContent>
                    <CourseInfoBox>
                        <CourseTitle>
                            <Link to={finalLinkTo}>
                                {finalPath}    
                            </Link> {(typeOfProduct !== 'level') && "/"} {courseTitle}
                        </CourseTitle>

                        {/* <ProgressBar>Progress Bar</ProgressBar> */}
                    </CourseInfoBox>

                    {isTrialCourseButtonVisible && 
                    (courseItem.productCourse?.paymentPlan !== undefined) &&
                    (courseItem.productCourse?.paymentPlan !== null) &&
                        <FreePromoButton onClick={getAssaingProductTrial}>
                            ATTIVA PERIODO IN REGALO DI 
                            <br />
                            “{courseItem.productCourse?.paymentPlan?.productName}”
                        </FreePromoButton>}

                    <InterectionBox>
                        <InterectionButtonRow>
                            <DescriptionButton isActive={isButtonDescriptionActive} onClick={onClickButtonDescriptionHandler}>Descrizione</DescriptionButton>

                            <LessonButton isActive={isButtonCurseListActive} onClick={onClickButtonCurseListHandler}>Scaletta</LessonButton>
                        </InterectionButtonRow>

                       {/* We keep this component until analysis of the Designer */}
                        <InterectionLessonBox>
                            {/* <LessonSearch>
                                <SearchIconSVG />
                            </LessonSearch> */}
                        
                            <ExpandLesson onClick={collapseAll}>
                                {!collapse && <ExpandIconSVG />}
                                {collapse && <CollapseIconSVG />}
                            </ExpandLesson>
                        </InterectionLessonBox> 
                    </InterectionBox>

                    {isButtonDescriptionActive && <DescriptionTab courseItem={lessonRendering} />}

                    {isButtonCurseListActive && (
                        <VideoCourseList 
                            lessonSetting={lessonHandler}
                            courseItem={filteredLessons}
                            collapse={collapse}
                            collapseHandler={closeCollapase}
                            lessonStatus={lessonStatus}
                            nodeId={lessonToDisplay.id}
                        />)}
                </PageContent>
            </>}
        </PageCourse>
    );
};

export default ProductPageMobile;