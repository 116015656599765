import styled, { css } from "styled-components";
import colors from "style/palette";
import devices from "style/breakpoints";
import { Button } from "style/buttons";

export const MissionsFlowBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media ${devices.tablet} {

        flex-direction: row;
        gap: 30px;
    }
`;

export const MissionsFlowRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

`;

export const MissionBox = styled.div<{completed?: boolean | undefined}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    ${({completed}) => completed &&
    
        css`
            > svg path {
                fill: ${colors.green};
            }
        `
    }
`;

export const MissionName = styled.h3`

    margin: 7px 0 0;
    padding: 0;

    font-size: 12px;
    font-weight: 500;
    color: ${colors.white};
`;

export const MissionButtonRow = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    gap: 12px;
`;

export const MissionButton = styled(Button)<{ flavor?: 'yellow' | 'doubleYellow' | undefined}>`

    margin: 20px 0 0;
    padding: 15px 20px;

    background-color: ${colors.blue};

    color: ${colors.white};

    @media ${devices.tablet} {
        margin: 0;
    }

    ${({flavor}) => (flavor === 'yellow') && (

        css`

            background-color: ${colors.primaryColor};
            color: ${colors.black087};
        `
    )}

    ${({flavor}) => (flavor === 'doubleYellow') && (

        css`

            background-color: ${colors.primaryColor};
            color: ${colors.black087};

            @media ${devices.tablet} {

                width: 100px;
                height: 42px;

                padding: 5.5px 0;
            }
        `
    )}

`;

export const ButtonImg = styled.img``;

export const Separator = styled.div`

    width: 20px;
    
    margin: 0 4px;

    color: ${colors.grey400};
`;
