import { FC, useState, useEffect, useRef, useMemo  } from 'react';

//types
import DropdownProps from './Dropdown.component.d'

//style
import {
    DropdownContainer,
    DropdownButton,
    DropdownContent,
    DropdownButtonContainer
} from './style/Dropdown.style';


const Dropdown: FC<DropdownProps> = ({position, buttonText, buttonColor, button, buttonWidth, buttonHeight, buttonHighlighted, content, contentMaxWidth, top, left, right}) => {

    const [isOpened, setIsOpened] = useState(false);
    const [contentWidth, setContentWidth] = useState<number|undefined>(undefined);

    const ref = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        //Close if clicked on outside of element
        const handleClickOutside = (e: MouseEvent): void => {
            if (e.target instanceof HTMLElement && !ref.current?.contains(e.target)) setIsOpened(false);
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);

    useEffect(() => {
        setContentWidth(isOpened ? getContentWidth() : undefined);
    }, [contentRef, isOpened]);

    const getContentWidth = () => {
        if(!contentRef || !contentRef.current) return;
        return contentRef.current.getBoundingClientRect().width;
    };

    return (
        <DropdownContainer ref={ref} position={position}>
            <DropdownButtonContainer onClick={() => setIsOpened(prevState => !prevState)}>
                {button ? button : <DropdownButton buttonWidth={buttonWidth} buttonColor={buttonColor} buttonHeight={buttonHeight} highlighted={buttonHighlighted}>{buttonText}</DropdownButton>}
            </DropdownButtonContainer>
            {content && <DropdownContent ref={contentRef} hidden={!isOpened} opacity={(!isOpened || !contentWidth) ? 0 : 1} maxWidth={contentMaxWidth} width={contentWidth} top={top} left={left} right={right}>{content}</DropdownContent>}
        </DropdownContainer>
    );
};

export default Dropdown;