// asset
import devices from "style/breakpoints";
import colors from "style/palette/palette";

import styled from "styled-components";


export const TotalPage = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: calc(100vh - 70px);

    overflow: auto;
    
    @media ${devices.tablet} {

        justify-self: flex-end;
        max-width: calc(100% - 400px);
        height: unset;

        overflow: unset;
    }
`;

export const Header = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 15px 20px 10px;
    gap: 10px;

    box-sizing: border-box;

    width: 100%;

    background: ${colors.grey};

    border-bottom: 1px solid ${colors.darkGrey};

    position: fixed;
    top: 0;
    z-index: 10;

    @media ${devices.tablet} {

        border-radius: 15px 15px 0px 0px;
        position: unset;
        top: unset;
        z-index: unset;

    }
`;

export const ReturnB = styled.button`

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 0;
    padding: 0;

    border: none;
    box-shadow: none;
    background-color: transparent;
    gap: 7px;
`;

export const Return = styled.p`

    display: flex;
    align-items: center;
    text-align: center;

    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    color: ${colors.primaryColor};
`;

export const Name = styled.p`

    display: flex;
    align-items: center;
    text-align: center;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};

    @media ${devices.tablet} {

        align-items: flex-start;

        width: 100%;
    }
`;

export const Img = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    padding: 0px 0px 0px 26px;

    width: 76px;
    height: 50px;

    @media ${devices.tablet} {

    }
`;

export const Person = styled.img`

    width: 50px;
    height: 50px;

    background-color: ${colors.white};
    border-radius: 50px;
`;

export const MessagesBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    width: 100%;

    padding: 70px 0 0;

    @media ${devices.tablet} {

        width: 100%;
        height: calc(100vh - 350px);

        padding: 0;
        overflow: auto;
        
        vertical-align: bottom;

        ::-webkit-scrollbar {
            background: rgba(255, 255, 255, 0.03);
            border-radius: 15px;
            width: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${colors.blueGrey};
            height: 70px;
            width: 6px; 
            border-radius: 5px;
        }

        -ms-overflow-style: none;
    }
`;
