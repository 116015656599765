import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//components
import LoadingMask from 'components/ui/LoadingMask';

//style
import { BestOddsResultsContainer, EventTitle, OddsContainer, SelectionContainer, SelectionLabel } from './style/BestOddsResults.style';
import { SiteBox, SiteLogoContainer, SiteLogo, OddsValue, SiteAndOdds } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

//types
import { BestOddsResultsProps } from './BestOddsResults.component.d';
import { Event } from 'pages/OddsScasserPage/types/Event';

//helper
import { formatDateWithYear } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const BestOddsResults: FC<BestOddsResultsProps> = ({filters, odds, isLoading, addToMultiple, copyToClipboard}) => {

    const { sites, events } = useAppSelector(state => state.oddsScasser);
    const [event, setEvent] = useState<Event|undefined>(undefined);

    useEffect(() => {
        if(events.size>0 && filters.eventId) {
            setEvent(events.get(filters.eventId));
        }
    }, [filters, events]);

    if (isLoading) return (<LoadingMask paddingTop='100px' isCenter size='page'/>);

    const selections = ['V1', 'V2', '1', 'X', '2', '1X', 'X2', '12', 'Under 1.5', 'Over 1.5', 'Under 2.5', 'Over 2.5', 'Under 3.5', 'Over 3.5', 'Goal', 'No Goal'];

    return(
        <>
        {
            event &&
            <BestOddsResultsContainer>
                <EventTitle>{`${event?.datetime && formatDateWithYear(new Date(event?.datetime))}`} &nbsp; {`${event.home?.name} - ${event.away?.name} (${event?.league?.country}, ${event?.league?.name})`}</EventTitle>
                <OddsContainer>
                    {selections.map((selection, i) => {
                        if(odds.has(selection)) {
                            return(
                                <SelectionContainer key={'S'+i}>
                                    <SelectionLabel title='Aggiungi alla multipla' onClick={() => addToMultiple(event, odds.get(selection) ?? [])}>{selection}</SelectionLabel>
                                    {odds.get(selection)?.map((element, j) => {
                                        const site = sites.get(element.site.id);
                                        let fees = site?.type==='exchange' ? (element.odds-1)*parseFloat(filters[site.name==='Betfair' ? 'feesBetfair' : 'feesBetflag'] ?? '0')/100 : undefined;
                                        return(
                                            <SiteAndOdds key={j}>
                                                <SiteBox checked={true} notClickable={element.url===undefined} onClick={() => element.url && copyToClipboard(element.url, `URL ${site?.name} copiato`)}>
                                                    <SiteLogoContainer visible={true} backgroundColor={site?.color}><SiteLogo imageUrl={site?.imageUrl}/></SiteLogoContainer>
                                                </SiteBox>
                                                <OddsValue title={fees ? 'Quota al netto delle commissioni: '+(element.odds-fees).toFixed(2) : undefined}>{element.odds.toFixed(2)}</OddsValue>
                                            </SiteAndOdds>
                                        );
                                    })}
                                </SelectionContainer>
                            );
                        }
                    })}
                </OddsContainer>
            </BestOddsResultsContainer> 
        }
        </>
    );
}

export default BestOddsResults;