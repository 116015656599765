import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import config from 'configs/config';
import { getCurrentAuthUser } from 'services/user.services';
import { facebookConnect } from 'services/facebook.services';

// components
import BaseModal from 'components/ui/BaseModal';
import LoadingMask from 'components/ui/LoadingMask';
import EmptyUserImageProfile from 'components/EmptyUserImageProfile';
import FacebookLoginButton from 'components/ui/FacebookLoginButton';

// assets
import { ReactComponent as ShareIconSVG } from '../assets/shareIcon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as UserIcon } from './assets/user-icon.svg';

// style
import { Row } from 'style/layout';
import {
    Content,
    CloseButton,
    MainBox,
    MainBoxConnect,
    ImageToShare,
    CommentColumn,
    CommentText,
    CommentBox,
    ShareButton,
    Disclaimer,
    UserImageProfile,
    UserNameRow,
    LoadingModal
 } from './style/ShareModal.style';
import { useIsMounted } from 'usehooks-ts';
import { callOldGET, callOldPOST, callPOST } from 'network/network';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import { AxiosResponse } from 'axios';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import useAppDispatch from 'hooks/useAppDispatch';
import { getUserFacebookGroups, updateFacebookDataOnDB } from './services/ShareModal.services';
import { postSvgToFacebook } from 'services/facebook.services';
import { enableFacebookExceptioModal } from 'redux/actions/actions-app-main';

 const FB_GROUP_ID = () => {

    if (!config || config === undefined) return;
    if (!config.socialLinks || config.socialLinks === undefined) return;
    if (config.environment === 'production') return config.socialLinks.fbGroupID;
    return config.socialLinks.fbGroupTestID;
};


const ShareModal: FC<{onCloseButtonModal: () => any,
    prevMonth: string,
    previewShareImg?: any,
    resultBonus: number,
    resultColpo: number,
    resultTrading: number,
    isDataLoading: boolean,
    isHistorical: boolean | undefined,
    refreshFunction?: () => any
}> = ({ 
    onCloseButtonModal,
    refreshFunction,
    isDataLoading,
    prevMonth,
    previewShareImg,
    resultBonus,
    resultColpo,
    resultTrading,
    isHistorical
}) => {

    const { isFacebookExceptionModalVisible } = useAppSelector(state => state.appMain);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { email } = useAppSelector(state => state.user);
    const { tokenID } = useAppSelector(state => state.user);
    const { clientId } = useAppSelector(state => state.user);
    const { federateds } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [commentInput, setCommentInput] = useState<string | undefined>(undefined);
    const [userData, setUserData] = useState<any>({});
    const [hasUserFacebookMissionCompleted, setHasUserFacebookMissionCompleted] = useState<boolean>(false);
    const [isUserInFacebookGroup, setIsUserInFacebookGroup] = useState<boolean | undefined>(undefined);
    const [isUserDataLoading, setIsUserDataLoading] = useState<boolean | undefined>(true);
    const [isFacebookGroupsDataLoading, setIsFacebookGroupsDataLoading] = useState<boolean | undefined>(undefined);
    const [isSharingLoading, setIsSharingLoading] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    
    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const commentInputChangeHandler = (event: any) => setCommentInput(event.target.value);
    const facebookButtonClickHandler = () => {
        if (email) facebookConnect(false, email);
    }
    const facebookGroupJoinClickHandler = () => {
        window.open (
            `https://www.facebook.com/groups/${FB_GROUP_ID()}`,
            '_blank',
            'noopener noreferrer'
        );
    };

    const shareToFacebookHandler = () => {

        setIsSharingLoading(true);
        const timeStamp = new Date().setDate(0);
        const monthNumber = new Date(timeStamp).getMonth() + 1;
        const year = new Date(timeStamp).getFullYear(); 
        const fbData = getUserConnectedAccountData(userData, 'Facebook')[0];
        const snackbarData: SnackbarOptions = {};
        
        if (!fbData) {
            setIsSharingLoading(false);
            return;
        }
        
        // const token = fbData.accessTokenOnFederated;

        if (!previewShareImg) {
            setIsSharingLoading(false);
            return;
        }
        
        let successHandler = (res: AxiosResponse) => {
            //save results to backend
            if (!isHistorical) {

                const payload = {
                    cliente: {
                        id: clientId
                    },
                    bonus: resultBonus,
                    colpoSicuro: resultColpo,
                    tradingSportivo: resultTrading,
                    month: monthNumber,
                    year
                };
                callPOST("v1/self/userresults", payload, true);
            };

            snackbarData.type = 'success';
            snackbarData.message = ("RISULTATO CONDIVISO (!) Bravo. Lo vedrai presto nel gruppo Facebook.");
            dispatch( addSnackbar(snackbarData) );
            setIsCloseButtonClicked(true);            
            setIsSharingLoading(false);
            
            if (refreshFunction) refreshFunction();
        };
        
        let errorHandler = (res: AxiosResponse) => {

            snackbarData.type = 'error';
            snackbarData.message = ("C'è stato un problema nella condivisione, riprova più tardi.");
            dispatch( addSnackbar(snackbarData) );
            setIsCloseButtonClicked(true);
            setIsSharingLoading(false);
        };

        let caption = "";
        if (commentInput) caption += commentInput;
        if (!commentInput && isHistorical) {
            caption += `Ciao a Tutti (!)\nEcco il grafico del mio Cash Flow Sicuro.\n`;
        }
        if (!commentInput && !isHistorical) {
            caption += `Ciao a tutti (!)\nSono felice di condividere i miei risultati di ${prevMonth}.\n`;
        }
        
        caption += `\n\n\n\nScopri come avere anche Tu questi risultati 👇\n${getEnvironmentBaseUrl("/app/reservedArea?bonusFlow=1")}\n\n#RisultatiSubito`;
    
        if (tokenID) postSvgToFacebook(tokenID, "1350", "1080", previewShareImg.svgHtml, caption, successHandler, errorHandler, refreshFunction);   
    };

    const checkUserConnectedAccount = (userData: any, accountType: string) => {
        
        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;
        
        return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };

    const getUserPictureUrl = (userData: any, accountType: string) => {
        if (!userData || userData === undefined) return;
        if (!userData.federateds || userData.federateds === undefined) return;

        const userAccountData = userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
        if (userAccountData.length === 0) return;
        
        return JSON.parse(userAccountData[0].pictureOnFederated).data.url;
    };

    const getUserFederatedName = (userData: any, accountType: string) => {
        if (!userData || userData === undefined) return;
        if (!userData.federateds || userData.federateds === undefined) return;

        const federatedData = userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
        if (federatedData.length === 0) return;

        return federatedData[0].nameOnFederated;
    };

    const getUserConnectedAccountData = (userData: any, accountType: string) => {

        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;

        return userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };
   
    const updateFacebookMissionOnBackend = async (backendStatus: boolean, facebookStatus: boolean) => {
        
        if ((backendStatus === undefined) || (facebookStatus === undefined)) return;
        if (backendStatus === facebookStatus) return;

        if (facebookStatus) {
            
            await callOldGET('user/misFacebookON', true);
            return;
        }

        await callOldGET('user/misFacebookOFF', true);
    };

    const getFacebookMission = async () => {

        try {
            const currenUserData = await callOldGET('user/getCurrentUser', true);
            if (!currenUserData || currenUserData === undefined) {
                
                setHasUserFacebookMissionCompleted(false);
                return;
            };

            const facebookMissionStatus = currenUserData.misFacebook2022;

            if (!facebookMissionStatus || 
                (facebookMissionStatus === undefined) ||
                (facebookMissionStatus === null)
            ) {
                setHasUserFacebookMissionCompleted(false);
                return;
            }

            setHasUserFacebookMissionCompleted(facebookMissionStatus);
        } catch (error: any) {

            console.warn("Unable to get Facebook mission status.", error);
            setHasUserFacebookMissionCompleted(false);
        }
    };

    const getUserData =async () => {
        
        setIsUserDataLoading(true);
        try {
            
            const userAuthData = await updateFacebookDataOnDB();
            
            console.log("USER DATA:", userAuthData);
            setUserData(userAuthData);
            setIsUserDataLoading(false);
        } catch (error: any) {
            setIsUserDataLoading(false);
            console.warn("Unable getting User data.", error);
        }
    };
    
    const checkUserFacebookGroupConnection =async () => {
        
        setIsFacebookGroupsDataLoading(true);
        const environment = config.environment;
        let ldlFbID: any;
        if (environment === 'production') ldlFbID = config.socialLinks?.fbGroupID;
        if (environment === 'test' || environment === 'development') ldlFbID = config.socialLinks?.fbGroupTestID;
        const fbData = getUserConnectedAccountData(userData, 'Facebook')[0];
        
        if (!fbData) {

            // to do if (fbMission) call db for set false state to fbMission
            setIsUserInFacebookGroup(false);    
            setIsFacebookGroupsDataLoading(false);
            return
        }
        
        const snackbarData: SnackbarOptions = {};

        try {
            const response = await getUserFacebookGroups();
            if (!isMounted) return;

            if (!response || response === undefined) return;
            
            if (response.message) {

                if (response.message.includes('Error getting facebook groups.')) {
                    console.log("7");

                    // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, false);
                    setIsUserInFacebookGroup(false);    
                    setIsFacebookGroupsDataLoading(false);
        
                    dispatch( enableFacebookExceptioModal() );
                    return;
                };
            };

            const userIsInFacebookGroup = response.some((group: any) => group.id === ldlFbID);
            setIsUserInFacebookGroup(userIsInFacebookGroup);
            // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, userIsInFacebookGroup);
            setIsFacebookGroupsDataLoading(false);
        } catch (error: any) {

            if (error.response.status === 404) dispatch( enableFacebookExceptioModal() );

            // updateFacebookMissionOnBackend(hasUserFacebookMissionCompleted, false);
            setIsUserInFacebookGroup(false);    
            setIsFacebookGroupsDataLoading(false);
            if (error.code === 'ERR_BAD_REQUEST') {
                
                snackbarData.type = 'warning';
                snackbarData.message = ("C'è stato un problema, il tuo browser sembra aver bloccato la condivisione, prova a seguire quest istruzioni: https://bit.ly/Sito_Sicuro");
                dispatch( addSnackbar(snackbarData) );
    
                return;
            };
            
            console.warn("Unable to check if User is in facebook group.", error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {

        if (!userData) return;
        // getFacebookMission();
        checkUserFacebookGroupConnection();
    }, [userData]);

    useEffect(() => {
        if (isFacebookExceptionModalVisible) onCloseButtonModal();
    }, [isFacebookExceptionModalVisible]);

    // const hasUserFacebook = checkUserConnectedAccount(userData, 'Facebook');
    const hasUserFacebook = !!federateds.facebook;
    const nameOnFederated = getUserFederatedName(userData, 'Facebook');
    const facebookProfilePicture = getUserPictureUrl(userData, 'Facebook');
    const isLoading = isUserDataLoading || isFacebookGroupsDataLoading || isSharingLoading || isDataLoading;

    if (isLoading) return (
        <LoadingModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            <LoadingMask 
                size='big'
                isCenter
                paddingTop='200px'
                paddingBottom='200px'
                // width='900px'
            />
        </LoadingModal>
    );

    if (isFacebookExceptionModalVisible) return <></>;

    if (!hasUserFacebook) return (
        
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <MainBoxConnect>
                    <CommentText>
                        Per poter condividere il risultato sul Gruppo<br />
                        devi prima collegare il tuo account Facebook:
                    </CommentText>

                    <EmptyUserImageProfile 
                        flavor='facebook'
                        isEditButtonDisabled
                    />

                    <FacebookLoginButton
                        onClick={facebookButtonClickHandler}
                    >
                        collega facebook
                    </FacebookLoginButton>
                </MainBoxConnect>
            </Content>
        </BaseModal>
    );

    if (!isUserInFacebookGroup) return (
        
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <>
                    <MainBoxConnect>
                        <CommentText>
                            Per poter condividere i tuoi Risultati iscriviti al nostro Gruppo Facebook:
                        </CommentText>

                        <UserImageProfile
                            flavor='facebook'
                            src={facebookProfilePicture}
                            alt="User profile picture"
                        />

                        <UserNameRow>
                            <UserIcon />

                            <span>
                                {nameOnFederated}
                            </span>
                        </UserNameRow>

                        <FacebookLoginButton
                            onClick={facebookGroupJoinClickHandler}
                        >
                            entra nel gruppo facebook
                        </FacebookLoginButton>
                        
                        <CommentText>
                            Se sei già nel nostro Gruppo Facebook è possibile che il tuo Browser stia<br />
                            bloccando alcune funzioni: 
                                <a 
                                    href='https://bit.ly/Sito_Sicuro' 
                                    target='_blank'
                                    rel='noopener noreferrer'    
                                >segui queste Istruzioni</a>.
                        </CommentText>

                    </MainBoxConnect>
                </>
            </Content>
        </BaseModal>
    );

    return (
        <BaseModal
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <Content>
                {isAppMobile && (
                    <Row 
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                {/* <LoadingMask 
                    size='big'
                    isCenter
                    paddingTop='175px'
                    paddingBottom='200px'
                    width='900px'
                /> */}

                <MainBox>
                    <ImageToShare 
                        src={previewShareImg.url}
                    />

                    <CommentColumn>
                        <CommentText>
                            {!isHistorical && `Ecco l’anteprima del tuo post con i risultati di ${prevMonth} che vedrai pubblicato nel gruppo:`}
                            {isHistorical && `Ecco l’anteprima del tuo post che vedrai pubblicato nel gruppo:`}
                        </CommentText>

                        <CommentBox 
                            placeholder="Aggiungi un commento..."
                            value={commentInput}
                            onChange={commentInputChangeHandler}
                        />

                        <ShareButton onClick={shareToFacebookHandler}>
                            <ShareIconSVG />

                            Condividi sul gruppo
                        </ShareButton>
                    </CommentColumn>
                </MainBox>

                {!isHistorical && (
                     <Disclaimer>
                        Condividi per 3 mesi e vedrai il grafico del tuo percorso… 
                        aggiornalo ogni mese, è importante (!)
                    </Disclaimer>
                )}
            </Content>
        </BaseModal>
    );
}

export default ShareModal;
