import { FC, ChangeEvent, useEffect, useState, useRef } from 'react';

// types
import { TextInputWithAutocompleteProps } from './TextInputWithAutocomplete.component.d';

// style
import {
    InputLabelBox,
    Label,
    Input,
    List,
    ListElement,
    IconContainer
} from './style/TextInputWithAutocomplete.style';


const TextInputWithAutocomplete: FC<TextInputWithAutocompleteProps> = ({
    label,
    labelWidth,
    inputWidth,
    height,
    value,
    possibleValues,
    isDisabled,
    onChange,
    specialIcon,
    specialText,
    allowInvalid,
    emptyIsInvalid,
    maxMatches,
    inputPlaceholder,
    fontSize,
    listAllWidth,
    inputPadding,
    minWidth
}) => {

    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [isListVisible, setIsListVisible] = useState<boolean>(false);
    const [matches, setMatches] = useState<number[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [isSpecialActive, setIsSpecialActive] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        onChange && onChange(newValue, checkMatches(newValue, true));
    };

    const checkMatches = (_value: string, showList: boolean) => {
        const {index, matches} = validateText(_value, true);
        setMatches(matches);
        setSelectedIndex(-1);
        if(showList) setIsListVisible(matches.length>0);
        return index;
    }
    
    useEffect(() => {
        validateText(value);
    }, [value]);

    useEffect(() => {
        checkMatches(value, false);
    }, [possibleValues]);

    const validateText = (newValue: string, searchMatches?: boolean) => {
        let index = possibleValues.length;
        const matches: number[] = [];
        if(isSpecialActive && specialText) {
            setIsInvalid(false);
            return {index, matches};
        }
        if(specialText && newValue === specialText) {
            setIsInvalid(false);
            setIsSpecialActive(true);
            return {index, matches};
        }
        index = -1;
        if(newValue === "") {
            setIsInvalid(false);
            return {index, matches};
        }
        newValue = newValue.toUpperCase();
        for (let i = 0; i < possibleValues.length; i++) {
            const x = possibleValues[i];
            if(x.text.toUpperCase() === newValue) {
                index = i;
                if(!searchMatches) break;
            }
            if(searchMatches) {
                for (let j = 0; j < x.pieces.length; j++) {
                    if (x.pieces[j].substring(0, newValue.length).toUpperCase() === newValue) {
                        matches.push(i);
                        break;
                    }
                }
            }
            if(matches.length===maxMatches ?? 5) break;
        }
        setIsInvalid(index===-1 && newValue !== "");
        return {index, matches};
    }
    
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if(!isDisabled && !isSpecialActive) {
            event.target.select();
            setFocus(true);
            checkMatches(value, true);
        }
    }

    const handleLabelClick = () => {
        if(!isDisabled && inputRef.current) {
            inputRef.current.select();
            setFocus(true);
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        setTimeout(() => setIsListVisible(false), 250);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') setSelectedIndex((selectedIndex+1) % matches.length);//If the arrow DOWN key is pressed, increase the currentFocus variable
        else if (event.key === 'ArrowUp') setSelectedIndex((selectedIndex-1) % matches.length);//If the arrow UP key is pressed, decrease the currentFocus variable
        else if (event.key == 'Enter' && selectedIndex>-1) {//If the ENTER key is pressed, select item
            // event.preventDefault();
            setIsListVisible(false);
            onChange && onChange(possibleValues[matches[selectedIndex]].text, matches[selectedIndex]);
        }
    }

    useEffect(() => {
        if(specialText && onChange) {
            setIsInvalid(false);
            isSpecialActive ? onChange(specialText, possibleValues.length) : onChange("", -1);
        }
    }, [isSpecialActive]);

    const handleListElementClick = (text: string, index: number) => {
        onChange && onChange(text, index);
    }

    return (
        <InputLabelBox isDisabled={isDisabled} height={height} minWidth={minWidth}>
            {label && <Label isInputInvalid={isInvalid} focus={focus} isDisabled={isDisabled} labelWidth={label ? labelWidth : 0} fontSize={fontSize} onClick={handleLabelClick}>{label}</Label>}
            <Input ref={inputRef} type='text' label={label} placeholder={inputPlaceholder} width={inputWidth} fontSize={fontSize} padding={inputPadding} spellCheck={false} autoComplete='off' value={value} readOnly={isSpecialActive || possibleValues.length===0} isDisabled={isDisabled} isInputInvalid={(isInvalid && !allowInvalid) || (emptyIsInvalid && value==='')} onChange={inputChangeHandler} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown}/>
            {specialIcon && specialText && <IconContainer isIconActive={isSpecialActive} title={specialText} height={height} onClick={() => setIsSpecialActive(prevState => !prevState)}>{specialIcon}</IconContainer>}
            <List visible={isListVisible} labelWidth={label ? labelWidth : 0} iconPresent={specialIcon!==undefined && specialText!==undefined} height={height} listAllWidth={listAllWidth}>
                {matches.map((index, i) => {
                    if(!possibleValues[index]) return;
                    return <ListElement key={i} isActive={i===selectedIndex} fontSize={fontSize} color={possibleValues[index].color} onClick={() => handleListElementClick(possibleValues[index].text, index)}>{possibleValues[index].text}</ListElement>
                })}
            </List>
        </InputLabelBox>
    );
};

export default TextInputWithAutocomplete;
