import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';

//utils
import { getCookie, deleteCookie, deleteAllNamedCookie } from 'utils/cookie';
import config from 'configs/config';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';
import { getCurrentAuthUser } from 'services/user.services';
import { updateFacebookDataOnDB } from 'pages/LibertyDashboardPage/ShareModal/services/ShareModal.services';
import { getEnvironmentAPILinkUrl } from 'configs/config.helper';

// to do refactor and move all the cookie storage into localStorage
let finalAuthConfiguration: any;
if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;


const checkUserConnectedAccount = (userData: any, accountType: string) => {
    console.log(finalAuthConfiguration);
    if (!userData || userData === undefined) return false;
    if (!userData.federateds || userData.federateds === undefined) return false;
    
    return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
};

export const facebookCheckConnection = async () => {
    
    const fbaCookie: any = getCookie("fba");
    if (fbaCookie === "1") {
        const RealCheck = () => {
            const COOKIE_PREFIX = "CognitoIdentityServiceProvider." + finalAuthConfiguration.userPoolWebClientId + ".";
            const initialLastAuthUser = getCookie(COOKIE_PREFIX + "LastAuthUser");
            const cognitoEmail = getItemFromLocalStorage('cognitoEmail')          

            if ((initialLastAuthUser.includes("facebook"))&&(cognitoEmail!=null)) {             
                let cognitoAccessToken = getCookie(COOKIE_PREFIX + encodeURI(cognitoEmail) + ".accessToken");
                let fbAccessToken = getCookie(COOKIE_PREFIX + initialLastAuthUser + ".accessToken");
                let fbUrl = getCookie("fbUrl");
                const associateFBFN = async () => {
                    // NON SOSTITUIRE con httpApiClient perché userebbe il token di FB invece di Cognito e quindi 
                    // l'utente non verrebbe riconosciuto lato backend
                    const response = await fetch(getEnvironmentAPILinkUrl("v1/users/self/addfacebook"), {
                    // const response = await fetch(config.baseTestURL + "v1/users/self/addfacebook", {
                        mode: "cors",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "content-type": "application/json",
                            Authorization: "Bearer " + cognitoAccessToken,
                            accept: "application/json",
                        },
                        method: "post",
                        body: fbAccessToken,
                    });
                    // console.log("RESPONSE addfacebook:" + response.status);
                    if (response.status === 200) {
                        const userData = await getCurrentAuthUser();
                        if (checkUserConnectedAccount(userData, 'Facebook')) await updateFacebookDataOnDB();
            
                        deleteCookie("fba");
                        deleteCookie("fbUrl");
                        if (cognitoEmail) deleteAllNamedCookie(cognitoEmail);
                        removeItemFromLocalStorage('cognitoEmail');
                        let path = getItemFromLocalStorage('afterLoginRedirectPath');
			            setItemToLocalStorage('afterLoginRedirectPath', '');
			            window.location.replace(path === null || path === '' ? fbUrl : path);
                    }
                };
                associateFBFN();
            }
        }
        RealCheck();
    }
};
