import styled from 'styled-components';
import colors from 'style/palette/palette';

export const DashedLinesBox = styled.div`

    width: auto;
    height: auto;

    margin: 0 5px 0 0;

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    gap: 5px;
    flex-wrap: nowrap;
    flex-direction: row;
`;

export const DashedLine = styled.span`

    display: inline-block;

    min-width: 5px;
    min-height: 3px;

    border: none;
    background: ${colors.primaryColor};
`;
