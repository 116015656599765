import { FC } from 'react';

// types
import { LoadingMaskProps, CustomLoadingProps } from './LoadingMask.d';

// assets
import { ReactComponent as LoadingSVG } from './assets/loading.svg';
import { ReactComponent as LoadingMBSVG } from './assets/logo-mb.svg';
import { ReactComponent as LoadingLVDRSVG } from './assets/logo-LVDR.svg';
import { ReactComponent as LoadingTCRSVG } from './assets/logo-tutorialcrypto.svg';

// style
import { LoadingContainer, LoadingBox, AssetBox } from './style/LoadingMask.style';
import store from 'redux/store';


/**
 * Loading mask
 * 
 * @author Alessio Grassi
 * 
 * @param size - loading box size
 * @param width - asset width
 * @param height - asset height
 * @param paddingTop - box padding top
 * @param paddingBottom - box padding bottom
 * @param isCenter - set the loading mask at the center of the parent
 * @param isBouncing - bouncing effect
 * 
 * @returns JSX
 */


const LoadingMask: FC<LoadingMaskProps> = ({
        size,
        width,
        height,
        paddingTop,
        paddingBottom,
        isCenter,
        isBouncing
    }) => {

    const customLoadingProps: CustomLoadingProps = {

        size,
        width,
        height,
        paddingTop,
        paddingBottom
    };

    return (
        <LoadingContainer
            {...customLoadingProps}
            isCenter={isCenter}
        >
            <LoadingBox isBouncing={isBouncing}>
                <AssetBox {...customLoadingProps}>
                    {store.getState().ui.brand?.code === "LDL" &&
                        <LoadingSVG />
                    }

                    {store.getState().ui.brand?.code === "VDR" &&
                        <LoadingLVDRSVG />
                    }

                    {store.getState().ui.brand?.code === "MB" &&
                        <LoadingMBSVG />
                    }

                    {store.getState().ui.brand?.code === "TCR" &&
                        <LoadingTCRSVG />
                    }
                </AssetBox>
            </LoadingBox>
        </LoadingContainer>
    );
};

export default LoadingMask;
