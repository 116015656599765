import { FC, useEffect, useState } from 'react';

// network
import { callGET } from 'network/network';

// style
import {
    WhatsAppWaitingBox,
    WhatsAppWaitingBoxContent,
    WhatsAppWaitingTextContent,
    WhatsAppCongratulationText,
    WhatsAppWaitingText,
    WhatsAppContributeButton,
    WaitingCount
} from './style/WhatsAppWaitingCard.style';
import { getCoursePayment } from 'services/payments.services';
import { setItemToLocalStorage } from 'utils/localStorage';
import { getEnvironmentProductId } from 'configs/config.helper';
import { getPaypalUrl } from 'utils/paypal';
import LoadingMask from 'components/ui/LoadingMask';


const WhatsAppWaitingCard: FC = () => {

    const [waitingCount, setWaitingCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getWaitingCount = async () => {
        try {
            const creationDate = new Date().toISOString();
            const response = await callGET(`v1/productsubscriptions/activehelp/count?statuses=OPENED&assigned=false&to=${creationDate}`, true);
            const count = response;
            setWaitingCount(count);
            console.log("CODA", count);
            
        } catch (error) {
            console.error('Errore nel recupero del conteggio delle chiamate in attesa:', error);
        }
    };

    const skipTheLineHandler = async() => {
        const lessonID = getEnvironmentProductId('assistente personale')
        try {
            setIsLoading(true);
            const response = await getCoursePayment(lessonID, 1, true, true);
            if (!response || response === undefined) return;
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);

            window.location.href = paypalUrl;
            setItemToLocalStorage('New Help', 'true')
        } catch (error: any) {
            setIsLoading(false);
            console.warn("ERROR:", error);
        }
    };

    useEffect(() => {
        getWaitingCount();
    }, []);

    // if (isLoading) return (
    //     <LoadingMask 
    //         paddingTop='200px'
    //         isCenter
    //         size='page'
    //     />
    // );
    
    return (
        <WhatsAppWaitingBox>
            {isLoading && 
                <LoadingMask 
                    isCenter 
                    height='254px'
                    size='small'
                />
            }

            {!isLoading &&
                <WhatsAppWaitingBoxContent>
                    <WhatsAppWaitingTextContent>
                        <WhatsAppWaitingText>
                            <WhatsAppCongratulationText>Complimenti, hai prenotato la tua call (!)</WhatsAppCongratulationText> 
                            <br />
                            Sei il n. <WaitingCount>{waitingCount}</WaitingCount> in lista d'attesa, attendi oppure salta la coda con un piccolo contributo
                        </WhatsAppWaitingText>
                    </WhatsAppWaitingTextContent>

                    <WhatsAppContributeButton onClick={skipTheLineHandler}>SALTA LA LISTA D'ATTESA</WhatsAppContributeButton>
                </WhatsAppWaitingBoxContent>
            }
        </WhatsAppWaitingBox>
    )
};

export default WhatsAppWaitingCard;