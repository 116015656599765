import { FC, useRef, useState } from 'react';

//components
import useCopyToClipboard from 'hooks/useCopyToCliboard';

// assets
import { ReactComponent as CopyIconSVG } from './assets/copyIcon.svg';

//style
import {
    TitleStepBox,
    TitleStepText,
    TransferGlobalBox,
    HeaderTransferBox,
    HeaderText,
    DataTransferRow,
    DataTextBox,
    DataText,
    CompletingTransferBox,
    CompletingTransferText,
    CompletingTransferButton,
    CopyBox,
    CopyText
} from './style/FirstStepBankTransfer.style';

const FirstStepBankTransfer: FC<{firstActive: () => void, productPrice: string, productName?: string}> = ({firstActive, productPrice, productName}) => {

    const [textRefOwnerCopied, setTextRefOwnerCopied] = useState(false);
    const [textRefBankCopied, setTextRefBankCopied] = useState(false);
    const [textRefIbanCopied, setTextRefIbanCopied] = useState(false);
    const [textRefBicCopied, setTextRefBicCopied] = useState(false);
    const [textRefCausaleCopied, setTextRefCausaleCopied] = useState(false);
    const [textRefImportCopied, setTextRefImportCopied] = useState(false);

    const textRefOwner = useRef(null);
    const textRefBank = useRef(null);
    const textRefIban = useRef(null);
    const textRefBic = useRef(null);
    const textRefCausale = useRef(null);
    const textRefImport = useRef(null);
    const { copyToClipboard, copied } = useCopyToClipboard();

    const textRefOwnerClickHandler = () => {
        copyToClipboard(textRefOwner);
        setTextRefOwnerCopied(true);
        setTimeout(() => setTextRefOwnerCopied(false),1000);
    };

    const textRefBankClickHandler = () => {
        copyToClipboard(textRefBank);
        setTextRefBankCopied(true);
        setTimeout(() => setTextRefBankCopied(false),1000);
    };

    const textRefIbanClickHandler = () => {
        copyToClipboard(textRefIban);
        setTextRefIbanCopied(true);
        setTimeout(() => setTextRefIbanCopied(false),1000);
    };

    const textRefBicClickHandler = () => {
        copyToClipboard(textRefBic);
        setTextRefBicCopied(true);
        setTimeout(() => setTextRefBicCopied(false),1000);
    };

    const textRefCausaleClickHandler = () => {
        copyToClipboard(textRefCausale);
        setTextRefCausaleCopied(true);
        setTimeout(() => setTextRefCausaleCopied(false),1000);
    };

    const textRerImportClickHandler = () => {
        copyToClipboard(textRefImport);
        setTextRefImportCopied(true);
        setTimeout(() => setTextRefImportCopied(false),1000);
    };

    const pendingLessonPaymentExists = 'New Coach Lesson' in localStorage;
    const pendingAssistenteExists = 'Assistente' in localStorage;

    return (
        <>          
            <TitleStepBox>
                <TitleStepText>EFFETTUA IL BONIFICO</TitleStepText>
            </TitleStepBox>

            <TransferGlobalBox>
                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>intestatario</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefOwnerCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefOwnerCopied &&<DataText ref={textRefOwner}>EasyTenerLife S.L.</DataText>}
                        
                        <CopyBox onClick={textRefOwnerClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>

                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>banca</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefBankCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefBankCopied &&<DataText ref={textRefBank}>Banca March (Spagna)</DataText>}

                        <CopyBox onClick={textRefBankClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>

                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>iban</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefIbanCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefIbanCopied && <DataText ref={textRefIban}>ES8700610216690121330111</DataText>}

                        <CopyBox onClick={textRefIbanClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>

                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>bic</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefBicCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefBicCopied && <DataText ref={textRefBic}>BMARES2M</DataText>}

                        <CopyBox onClick={textRefBicClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>

                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>causale</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefCausaleCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefCausaleCopied && !pendingLessonPaymentExists && !pendingAssistenteExists && <DataText ref={textRefCausale}>Acquisto corso {productName}</DataText>}

                        {!textRefCausaleCopied && pendingLessonPaymentExists && <DataText ref={textRefCausale}>Acquisto pacchetto lezioni con {productName}</DataText>}

                        {!textRefCausaleCopied && pendingAssistenteExists && <DataText ref={textRefCausale}>Acquisto {productName}</DataText>}

                        <CopyBox onClick={textRefCausaleClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>

                <DataTransferRow>
                    <HeaderTransferBox>
                        <HeaderText>importo</HeaderText>
                    </HeaderTransferBox>

                    <DataTextBox>

                        {textRefImportCopied && <CopyText>Testo copiato negli appunti</CopyText>}

                        {!textRefImportCopied && <DataText ref={textRefImport}>{productPrice}</DataText>}

                        <CopyBox onClick={textRerImportClickHandler}>
                            <CopyIconSVG />
                        </CopyBox>
                    </DataTextBox>
                </DataTransferRow>
            </TransferGlobalBox>

            <CompletingTransferBox>
                <CompletingTransferText>
                    Dopo aver effettuato il bonifico continua qui:
                </CompletingTransferText>

                <CompletingTransferButton onClick={firstActive}>PROSEGUI AL PUNTO 2</CompletingTransferButton>
            </CompletingTransferBox>
        </>
    );
};

export default FirstStepBankTransfer;