import { FC, useEffect, useState } from 'react';

// hooks
import { useIsMounted } from 'usehooks-ts';

// service
import { setUsersWarnings } from '../services/HomePageLDL.services';

// assets
import { ReactComponent as InfoSVG } from 'assets/imgs/home-page/cards/info-icon.svg';
import { ReactComponent as ComingSoonSVG } from './assets/coming-soon.svg';
import { ReactComponent as CompletedSVG } from './assets/complete.svg';
import { ReactComponent as VipSVG } from '../assets/vip-point-icon.svg';

// components
import CircularDoughnutChart from 'components/CircularDoughnutChart'
import LoadingMask from 'components/ui/LoadingMask';

// types
import { StepInfo, UserPathCardProps } from './UserPathCard';

// style
import { BaseCard } from 'style/wrappers';
import {
    PathCardContainer,
    PathCardBox,
    CompletedCard,
    PathBaseCard,
    Content,
    CardText,
    AssetBox,
    IconRow,
    InfoButton,
    StepBox,
    StepText,
    ChoiceRow,
    ChoiceButton,
    CTAButton
} from './style/UserPathCard.style';
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { callOldGET } from 'network/network';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { activateTimer } from './services/UserPathCard.services';


/**
 * User path card
 * 
 * @author Frank Berni
 * 
 * @param cardType - the type (and flavor) of the card
 * @param cardAsset - the central asset
 * @param label - text of the card
 * @param isDisabled - disabled status (and flavor)
 * @param numberOfCompletedSteps - number of completed path steps
 * @param maxNumberOfSteps - total number of path steps
 * 
 * @returns JSX
 */


const UserPathCard: FC<UserPathCardProps> = ({
        cardType,
        cardAsset,
        label,
        isDisabled,
        numberOfCompletedSteps,
        maxNumberOfSteps,
        stepInfo,
        isComplete,
        isShrunk,
        isActive,
        isSmall,
        isVip,
        isInfoButtonVisible,
        newWarning,
        openModalHandler,
        shrinkClickHandler,
        activeCardHandler
    }) => {

    const [isStepBoxOpen, setIsStepBoxOpen] = useState<boolean | undefined>(false);
    const [isChoiceOneClicked, setIsChoiceOneClicked] = useState<boolean | undefined>(undefined);
    const [isChoiceTwoClicked, setIsChoiceTwoClicked] = useState<boolean | undefined>(undefined);
    const [isChoiceTwoInError, setIsChoiceTwoInError] = useState<boolean | undefined>(false);
    const isMounted = useIsMounted;

    // const setWarnings = async (warningCode: string) => {

    //     try {
            
    //         if (warningCode === undefined || !warningCode) return;
    //         const warningSetting = await setUsersWarnings(warningCode);
    //         if (!isMounted()) return;
            
    //         if (warningSetting === undefined) {
    //             new Error ("Users warning setting response is undefined.")
    //         }
            
    //         if (newWarning === undefined || !newWarning) return;
    //         newWarning();
    //     } catch (error: any) {
    //         console.warn("Unable setting User's Warnings.", error);
    //     }
    // };

    const chargeNewStep = async (step: string) => {

        try {
            const response = await newStep(step);
            if (!isMounted()) return;
            
            if (response === undefined) {
                new Error ("New step response is undefined.")
            }
        } catch (error: any) {
            console.warn("Unable getting user new step.");
        }
    };

    const newStep = async (step: string) => {

        // try {
        //     const bonusStep = await callOldGET('flowutentestep/' + step, true);
        //     if (!bonusStep || bonusStep === undefined) return;

        //     const shoppingTipsPayload = {
        //         imgUrl: bonusStep.immagineConsiglio,
        //         text: bonusStep.copyConsiglio,
        //         buttonText: bonusStep.pulsanteConsiglio,
        //         link: bonusStep.destinazioneConsiglio
        //     };
         
        //     const journeyFlowPayload: StepInfo = {
        //         stepId: bonusStep.id,
        //         stepNumber: bonusStep.stepNum,
        //         stepText: bonusStep.copyStep,
        //         choiceOne: bonusStep.sceltaUno,
        //         choiceOneText: bonusStep.copySceltaUno,
        //         linkChoiceOne: bonusStep.destinazioneSceltaUno,
        //         typeOfDestinationChoiceOne: bonusStep.tipoDestinazioneUno,
        //         warningStepOne: bonusStep.idWarningSceltaUno,
        //         choiceTwo: bonusStep.sceltaDue,
        //         choiceTwoText: bonusStep.copySceltaDue,
        //         linkChoiceTwo: bonusStep.destinazioneSceltaDue,
        //         warningStepTwo: bonusStep.idWarningSceltaDue,
        //         typeOfDestinationChoiceTwo: bonusStep.tipoDestinazioneDue,
        //         textualRequirements: bonusStep.requisitiTestuali,
        //     };

        //     console.log("SHOPPING",shoppingTipsPayload);
        //     console.log("STEP",bonusStep.stepNum.toString());
        //     console.log("JOURNEY",journeyFlowPayload);
        //     console.log(newStepHandler);
        //     if (newStepHandler) {               
        //         newStepHandler(shoppingTipsPayload, `${bonusStep.stepNum - 1}`, journeyFlowPayload);
        //         setIsChoiceTwoClicked(false);
        //     };
        // } catch (error: any) {
        //     throw error;
        // }
    };

    const activateOfferTimer = async () => {

        const snackbarData: SnackbarOptions = {};

        try {
            const response = await activateTimer();
            if (!isMounted) return;

        } catch (error: any) {
            console.warn("Unable to activate offer timer.");
            snackbarData.type = 'error';
            snackbarData.message = "C'è stato un errore nell'attivare la promozione."
        }
    };

    const choiceOneButtonHandler = () => {

        setIsChoiceOneClicked(true);
        setIsChoiceTwoClicked(false);
    };

    const choiceTwoButtonHandler = () => {

        setIsChoiceTwoClicked(true);
        setIsChoiceOneClicked(false);

        // to do set in the next if statement the watning setting
        if (stepInfo === undefined) return;
        if (stepInfo.typeOfDestinationChoiceTwo === 'warning') setIsChoiceTwoInError(true);

        if (stepInfo.typeOfDestinationChoiceTwo === 'step') {
            if (stepInfo.linkChoiceTwo) chargeNewStep(stepInfo.linkChoiceTwo);
        }

    };

    // const buttonCTAHandler = async () => {

    //     if (isChoiceOneClicked) {

    //         if (!stepInfo || stepInfo === undefined) return;
    //         if (!stepInfo.linkChoiceOne || stepInfo.linkChoiceOne === undefined) return;
    //         if (stepInfo.choiceOneText?.includes('offerta')) await activateOfferTimer();
    //         if (stepInfo.typeOfDestinationChoiceOne === 'url') {
    //             window.open(
    //                 getEnvironmentMonolithLinkUrl(stepInfo.linkChoiceOne),
    //                 '_blank',
    //                 'noreferrer noopener'
    //             );
    //         }
    //     }

    //     if (isChoiceTwoClicked) {

    //         console.log("CLICKED CHOICE 2");
            
    //         if (!stepInfo || stepInfo === undefined) return;
    //         if (!stepInfo.linkChoiceTwo || stepInfo.linkChoiceTwo === undefined) return;
    //         if (stepInfo.choiceTwoText?.includes('offerta')) await activateOfferTimer();
    //         if (stepInfo.typeOfDestinationChoiceTwo === 'url') {
    //             window.open(
    //                 getEnvironmentMonolithLinkUrl(stepInfo.linkChoiceTwo),
    //                 '_blank',
    //                 'noreferrer noopener'
    //             );
    //         }

    //         if (stepInfo.typeOfDestinationChoiceTwo === 'warning') {

    //             if (stepInfo.warningStepTwo === undefined || !stepInfo.warningStepTwo) return;
    //             setWarnings(stepInfo.warningStepTwo.toString());
    //         }

    //         if (stepInfo.typeOfDestinationChoiceTwo === 'step') {
    //             chargeNewStep(stepInfo.linkChoiceTwo);
    //         }
    //     }
    //     return;
    // };

    const cardBoxClickHandler = () => {

        if (isDisabled) return;
        // if (shrinkClickHandler) shrinkClickHandler();
        if (activeCardHandler) activeCardHandler();

        if (isActive) return setIsStepBoxOpen(false);
        setIsStepBoxOpen(true);
    };

    const infoModalHandler = () => {

        if (openModalHandler === undefined || !openModalHandler) return;

        cardBoxClickHandler();
        openModalHandler();
    };

    useEffect(() => {

        if (isActive) return setIsStepBoxOpen(true);
        setIsStepBoxOpen(false);
    },[isActive, isStepBoxOpen]);
    
    const finalNumberOfCompletedSteps = (numberOfCompletedSteps === undefined) ? "" : numberOfCompletedSteps;
    const finalMaxNumberOfSteps = (maxNumberOfSteps === undefined) ? "" : maxNumberOfSteps;
    const isCircleDoughtnutVisible = (numberOfCompletedSteps !== undefined) && (maxNumberOfSteps !== undefined);
    const finalCTAButtonText = (isChoiceOneClicked && !isChoiceTwoClicked) ? stepInfo?.choiceOneText : (!isChoiceOneClicked && isChoiceTwoClicked) ? stepInfo?.choiceTwoText : undefined;

    // console.log();
    
    if (!isDisabled && numberOfCompletedSteps === undefined) {

        return (
            <LoadingMask
                size='medium'
                width='433px'
                paddingTop='80px'
        />);
    }

    if (isDisabled) {

        return (
            <PathCardContainer isSmall={isSmall}>
                <PathCardBox
                    onClick={cardBoxClickHandler}
                    isDisable={isDisabled}
                >
                    <PathBaseCard
                        flavor={cardType} 
                        maxWidth="433.33px"
                        isActive={isActive}
                        isShrunk={isShrunk}
                    >
                        <Content isDisabled>
                            <AssetBox>                    
                                ?
                            </AssetBox>
                        </Content>

                        <IconRow isDisabled>
                            <ComingSoonSVG />
                        </IconRow>
                    </PathBaseCard>
                </PathCardBox>
            </PathCardContainer>
        );
    }

    if (isComplete) {

        return (
            <PathCardContainer >
                <PathCardBox onClick={cardBoxClickHandler}>
                    <CompletedCard 
                        isActive={isActive}
                        isShrunk={isShrunk}
                    >
                        <Content isComplete>
                            {(label !== undefined) && (
                                <CardText 
                                    isComplete
                                >
                                    {label}
                                </CardText>
                            )}

                            <IconRow isComplete>
                                <CircularDoughnutChart
                                    value={finalMaxNumberOfSteps}
                                    max={finalMaxNumberOfSteps}
                                />

                                <CompletedSVG />
                            </IconRow>

                        </Content>

                    </CompletedCard>
                </PathCardBox>
            </PathCardContainer>
        );
    };

    return (
        <PathCardContainer isSmall={isSmall}>
            <PathCardBox
                onClick={cardBoxClickHandler}
                isDisable={isDisabled}
            >
                <PathBaseCard
                    flavor={cardType} 
                    maxWidth="433.33px"
                    isActive={isActive}
                    isShrunk={isShrunk}
                >
                    <Content >
                        {!isShrunk &&
                        <AssetBox>                    
                            {cardAsset}
                        </AssetBox>}

                        {(label !== undefined) && (
                            <CardText>
                                {label}
                            </CardText>
                        )}
                    </Content>

                    <IconRow>
                        {isDisabled && (
                            <ComingSoonSVG />
                        )}

                        {isVip && (
                            <VipSVG />
                        )}

                        {(!isDisabled && isCircleDoughtnutVisible) && (
                            <CircularDoughnutChart
                                value={finalNumberOfCompletedSteps}
                                max={finalMaxNumberOfSteps}
                            />
                        )}

                        {!isDisabled && isInfoButtonVisible && (
                            <InfoButton  onClick={infoModalHandler}>
                                <InfoSVG />
                            </InfoButton>
                        )}
                    </IconRow>

                </PathBaseCard>
            </PathCardBox>
        </PathCardContainer>
    );
};

export default UserPathCard;
