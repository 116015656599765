import styled, { css } from "styled-components";
import colors from "style/palette";


export const LessonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const LessonTitle = styled.h4`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 500;
    color: ${colors.white};
`;

export const LessonActionButtonsRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
`;

export const LessonActionButton = styled.a<{ isDisabled?: boolean | undefined, isCompleted?: boolean | undefined}>`

    cursor: pointer;

    ${({isDisabled}) => isDisabled &&

        css`
            cursor: default;
        `
    }

    ${({isDisabled}) => !isDisabled &&

        css`
            > svg path {
                fill-opacity: 1;
            }
        `
    }

    ${({isCompleted}) => isCompleted &&

        css`
            cursor: default;
            
            > svg path {

                fill: ${colors.primaryColor};
                fill-opacity: 1;
            }
        `
    }


`;
