import { 
    MainTitle, 
    Paragraph, 
    ParagraphTitle, 
    TotalPage,
    Text,
    List,
    ListText,
    Link,
    Table,
    FirstLine,
    LineText,
    SecondLine,
    SecondLineTitle
} from "./style/Privacy.style";

const Privacy = () => {
    
    return (
        <TotalPage>

        <MainTitle>Informativa sul trattamento dei dati personali degli utenti.</MainTitle>

        {/* <Paragraph>
            <ParagraphTitle>Introduzione</ParagraphTitle>

            <Text>
                Grazie per averci scelto
            </Text>

            <Text>
                Desideriamo offrire ai nostri utenti la migliore esperienza possibile affinché possano apprezzare 
                il nostro servizio oggi, domani e in futuro. La sicurezza dei dati personali dell'utente sono e saranno 
                sempre una delle nostre maggiori priorità. Desideriamo pertanto spiegare in modo trasparente come e perché 
                raccogliamo, memorizziamo e utilizziamo i dati personali dell'utente le scelte che egli può esercitare sui 
                dati personali.
            </Text>

            <Text>
                Questo è il nostro obiettivo, e nella presente Informativa sulla privacy ("Informativa") spiegheremo in 
                dettaglio cosa intendiamo.
            </Text>
        </Paragraph> */}

        <Paragraph>
            <ParagraphTitle>Artt. 13 e 14 del Regolamento 2016/679/UE (nel seguito anche “GDPR”)</ParagraphTitle>

            <br />

            <Text>
                EASYTENERLIFE SL informa che i dati personali forniti ed acquisiti contestualmente alla registrazione al sito ed all’utilizzo dei servizi, 
                nonché i dati necessari all’erogazione di tali servizi, ivi compresi i dati di navigazione ed i dati utilizzati per l’eventuale acquisto 
                dei prodotti e servizi offerti, saranno trattati nel rispetto della normativa applicabile e vigente.
            </Text>

            <Text>
                EASYTENERLIFE SL raccoglie, conserva ed elabora i dati personali allo scopo di fornire i prodotti e servizi offerti, ovvero per obblighi di Legge. 
                Relativamente ad alcuni specifici Servizi, Prodotti, Promozioni, ecc. EASYTENERLIFE SL potrà trattare i dati anche per scopi commerciali. 
                In tali casi, verrà richiesto un consenso specifico, separato, facoltativo e sempre revocabile con le modalità e ai recapiti nel seguito indicati.
            </Text>

            <Text>
                L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati nell’apposita sezione del Sito Web, nonché la compilazione 
                di questionari, la comunicazione tramite chat, social network, call center, etc., comporta la successiva acquisizione di alcuni dati personali 
                necessari per rispondere alle richieste. 
            </Text>
        </Paragraph>

        <Paragraph>
            <Text>I dati sono trattati per:</Text>

            <List>
                <ListText>
                    ◉ le finalità strettamente connesse e necessarie alla registrazione ai siti web dei brand collegati ad EASYTENERLIFE SL, ai servizi sviluppati 
                    e resi disponibili da EASYTENERLIFE SL, alla fruizione dei relativi servizi informativi, alla gestione delle richieste di contatto o di 
                    informazioni, per l’effettuazione di acquisti di prodotti e servizi offerti attraverso il sito;
                </ListText>

                <ListText>
                    ◉ attività connesse alla gestione delle richieste dell’Utente/Cliente e l’invio del riscontro che può prevedere la trasmissione di materiale 
                    promozionale; per il perfezionamento dell’ordine di acquisto dei prodotti e servizi offerti, inclusi gli aspetti relativi al pagamento con 
                    arta di credito;
                </ListText>

                <ListText>
                    ◉ lo svolgimento delle attività correlate all’adempimento di obblighi previsti da normative comunitarie e nazionali, alla tutela dell’ordine 
                    pubblico, all’accertamento e repressione dei reati;
                </ListText>

                <ListText>
                    ◉ vendita diretta, compimento di ricerche di mercato o comunicazione commerciale di prodotti e/o servizi offerti da EASYTENERLIFE SL; tale 
                    attività potrà riguardare anche prodotti e servizi di Enti partner ed essere eseguita mediante 
                    l’invio di materiale pubblicitario/informativo/promozionale e/o di inviti di partecipazione ad iniziative, eventi ed offerte volte a premiare 
                    gli utenti/clienti;                
                </ListText>
            </List>

            <Text>
                Il conferimento dei dati per le finalità suddette, con l’eccezione già indicata, è obbligatorio e, in difetto, non sarà possibile ricevere 
                le informazioni ed accedere ai servizi eventualmente richiesti.
            </Text>
        </Paragraph>

        <Paragraph>
            <Text>
                EASYTENERLIFE SL potrà inviare comunicazioni commerciali relative a prodotti e/o servizi analoghi a quelli già forniti, ai sensi della 
                Direttiva 2002/58/UE, utilizzando le coordinate di posta elettronica, o quelle cartacee, indicate in tali occasioni alle quali ci si potrà 
                opporre con le modalità e ai recapiti di seguito specificati.
            </Text>

            <Text>
                Il trattamento è effettuato anche con l’ausilio di mezzi elettronici o comunque automatizzati ed è svolto da EASYTENERLIFE SL e/o da terzi di cui 
                EASYTENERLIFE SL può avvalersi per memorizzare, gestire e trasmettere i dati stessi. Il trattamento sarà effettuato in modo da garantire la 
                sicurezza e la riservatezza dei dati. I dati personali trattati verranno conservati per i tempi previsti dalla normativa nel tempo applicabile.
            </Text>

            <Text>
                EASYTENERLIFE SL utilizza i cookie per diverse finalità, allo scopo di offrirti un’esperienza digitale rapida e sicura.
            </Text>

            <Text>
                Il Cliente/Utente potrà esercitare in ogni momento i diritti che gli sono riconosciuti dalla legge, tra cui quello:
            </Text>

            <List>
                <ListText>
                    ◉ di accedere ai suoi dati personali, ottenendo evidenza delle finalità perseguite da parte del Titolare, delle categorie di dati coinvolti, 
                    dei destinatari a cui gli stessi possono essere comunicati, del periodo di conservazione applicabile, dell’esistenza di processi decisionali 
                    automatizzati;
                </ListText>

                <ListText>
                    ◉ di ottenere senza ritardo la rettifica dei dati personali inesatti;
                </ListText>

                <ListText>
                    ◉ di ottenere, nei casi previsti, la cancellazione dei dati;
                </ListText>

                <ListText>
                    ◉ di ottenere la limitazione del trattamento o di opporsi allo stesso, quando possibile;              
                </ListText>

                <ListText>
                    ◉ di richiedere la portabilità dei dati che ha fornito a EASYTENERLIFE SL;           
                </ListText>
            </List>

            <Text>
                Inoltre, potrà proporre reclamo all’Autorità Garante per la Protezione dei Dati Personali ai sensi dell’art. 77 del GDPR.
            </Text>

            <Text>
                Titolare del trattamento, ai sensi dell’art. 4 del GDPR, è A.I.E.F. – EASYTENERLIFE SL con sede in 
            </Text>

            <Text>
                I diritti sopra indicati possono essere esercitati dall’Interessato con le modalità di cui alla apposita sezione del sito ovvero utilizzando 
                i seguenti riferimenti: info@easytenerlife.es .
            </Text>

            <Text>
                L’uso del Sito Web da parte del Cliente e/o dell’Utente implica la piena conoscenza e accettazione del contenuto e delle eventuali indicazioni 
                incluse in questa informativa.
            </Text>

            <Text>
                Il Responsabile della Protezione dei Dati personali (di seguito RPD) incaricato è: Raffaella Meazza.
            </Text>
        </Paragraph>
    </TotalPage>
    )
};

export default Privacy;