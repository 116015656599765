import { FC, useEffect, useRef, useState } from "react";

//types
import { TestimonialsCarouselProps} from './TestimonialsCarousel.component.d'

//components
import TestimonialCard from '../TestimonialCard/TestimonialCard.component'

// assets
import { ReactComponent as ArrowLeftSVG } from "./assets/arrow-left-icon.svg";
import { ReactComponent as ArrowRightSVG } from "./assets/arrow-right-icon.svg";

//style
import { CardBack, CardFront, CardWrapper, Carousel, Dot, Dots, NextButton, PrevButton, SliderWrapper, TestimonialsCarouselBox } from "./style/TestimonialsCarousel.component.style";
import { getCenteredSubArray } from "./TestimonialsCarousel.helper";

const TestimonialsCarousel: FC<TestimonialsCarouselProps> = ({ testimonials, areTestimonialsIMG }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVideoPaused, setIsVideoPaused] = useState(true);

  const intervalRef = useRef<NodeJS.Timeout>();

  const startInterval = () => {    
    intervalRef.current = setInterval(() => { 
      handleNextClick();
    }, 5000);
    
    // console.log('start interval', intervalRef.current);
  }

  const stopInterval = () => {
    // console.log('stop interval', intervalRef.current);
    clearInterval(intervalRef.current);
  }

  const handlePrevClick = () => {
    stopInterval();
    setIsVideoPaused(true)
    setCurrentSlide(currentSlide => currentSlide === 0 ? testimonials.length - 1 : currentSlide - 1);
    startInterval();
  };

  const handleNextClick = () => {  
    stopInterval();
    setIsVideoPaused(true)
    setCurrentSlide(currentSlide => currentSlide === testimonials.length - 1 ? 0 : currentSlide + 1);
    if (isVideoPaused) startInterval();
  };

  const handleClickCard = (title: string, cIndex: number) => { 
      cIndex > 0 ? handleNextClick() : handlePrevClick();
      stopInterval();
  };

  useEffect(() => {
    startInterval()
    return () => stopInterval()
  }, []);

  useEffect(() => {
    if (!isVideoPaused) stopInterval()
  }, [isVideoPaused]);

  // const handleMouseEnter = () => {
  //   setIsHovering(true);
  // }
  
  // const handleMouseLeave = () => {
  //   setIsHovering(false);
  // }


  return (
    <TestimonialsCarouselBox >
        {/* <Carousel onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> */}
        <Carousel>
            <PrevButton onClick={handlePrevClick}>
                <ArrowLeftSVG/>
            </PrevButton>

            <CardWrapper>
                {getCenteredSubArray(testimonials, currentSlide, 5).map((testimonial, index) => {
                return (
                    <TestimonialCard 
                      key={areTestimonialsIMG ? `${index}TC` : `${testimonial.description}${index}`} 
                      testimonial={testimonial} 
                      cIndex={index-2} 
                      inCarousel={true} 
                      handleClickCard={handleClickCard}
                      setIsVideoPaused={setIsVideoPaused}
                      isTestimonialImg={areTestimonialsIMG}
                      startCarousel={startInterval}
                      stopCarousel={stopInterval}
                    />
                );
                })}
            </CardWrapper>
            
            <NextButton onClick={handleNextClick}>
                <ArrowRightSVG/>
            </NextButton>
        </Carousel>
        <Dots isIMGTestimonials={areTestimonialsIMG}>
            {getCenteredSubArray(testimonials, currentSlide, 7).map((testimonial, index) => {
                    return (
                        <Dot key={areTestimonialsIMG ? `${index}TC` : `${testimonial.description}${index}`} cIndex={index-3} />
                    );
                    })}
        </Dots> 
    </TestimonialsCarouselBox>
  );
};



  export default TestimonialsCarousel;