import { FC, useEffect, useState } from 'react';

// assets
import { ReactComponent as CalendarSVG } from './assets/calendar.svg';
import { ReactComponent as VideocallSVG } from './assets/videocall.svg';
import { ReactComponent as CompleteSVG } from './assets/complete.svg';

// style
import {
    LessonRow,
    LessonTitle,
    LessonActionButtonsRow,
    LessonActionButton
} from './style/LessonReservation.style';


const LessonReservation: FC<{ lessonData: any, openCloseLessonModalHandler: () => void, lessonIdHandler: (n: number) => void }> = ({ lessonData, openCloseLessonModalHandler, lessonIdHandler }) => {

    // console.log("LESSONDATA:", lessonData);
    
    const [isVideoCallButtonActive, setIsVideoCallButtonActive] = useState<boolean | undefined>(true);
    const [isVideoCallButtonComplete, setIsVideoCallButtonComplete] = useState<boolean | undefined>(true);

    const reservationClickHandler = () => {
        
        if (isVideoCallButtonActive) return;
        window.open(
            lessonData.coach.calendly,
            '_blank',
            'noreferrer noopener'
        );
    };
    const videocallClickHandler = () => {

        if (!isVideoCallButtonActive) return;
        if (isVideoCallButtonComplete) return;
        window.open(
            lessonData.zoom,
            '_blank',
            'noreferrer noopener'
        );
    };
    const completeClickHandler = () => {

        if (!isVideoCallButtonActive) return;
        if (!isVideoCallButtonComplete) return;
        if (!openCloseLessonModalHandler) return;
        lessonIdHandler(lessonData.id)
        openCloseLessonModalHandler();
    };

    useEffect(() => {
            
        if (lessonData.zoom === null) setIsVideoCallButtonActive(false);
        if (lessonData.coachClosingDate === null) setIsVideoCallButtonComplete(false);
    }, [lessonData.coachClosingDate, lessonData.zoom]);

    return (
        <LessonRow>
            <LessonTitle>Prenota qui:</LessonTitle>

            <LessonActionButtonsRow>
                <LessonActionButton 
                    onClick={reservationClickHandler}
                    isCompleted={isVideoCallButtonActive}    
                >
                    <CalendarSVG />
                </LessonActionButton>

                <LessonActionButton
                    onClick={videocallClickHandler}
                    isCompleted={isVideoCallButtonComplete}
                    isDisabled={!isVideoCallButtonActive}
                >
                    <VideocallSVG />
                </LessonActionButton>
                
                <LessonActionButton 
                    onClick={completeClickHandler} 
                    isDisabled={!isVideoCallButtonComplete}
                >
                    <CompleteSVG />
                </LessonActionButton>
            </LessonActionButtonsRow>
        </LessonRow>
    );
};

export default LessonReservation;
