import styled from 'styled-components';
import colors from 'style/palette/palette';


export const InfoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 300px 0 0;
`;

export const SocietyName = styled.p`

    margin: 17px 0 0;

    font-size: 13px;
    font-weight: 400;
    line-height: 13px;

    color: ${colors.white};
    text-transform: uppercase;
`;

export const SocietyAddress = styled.p`

    margin: 15px 0 0;
    max-width: 277px;

    font-size: 13px;
    font-weight: 400;
    line-height: 22px;

    color: ${colors.white056};
    text-align: center;
`;

export const SocialRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 25px;

    margin: 25px 0 0;

    > a {
        max-height: 20px;
    }
`;

export const PrivacyBox = styled.p`

    margin: 25px 0 0;

    font-size: 13px;
    font-weight: 400;
    line-height: 13px;

    color: ${colors.white056};
    text-align: center;

    > a {
        
        color: ${colors.white056};
        text-decoration: none;
    }
`;

export const SeparatorLine = styled.span`

    display: inline-block;
    margin: 0 8px;
`;
