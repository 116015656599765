import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const WaitTitleBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 50px 0 0;
`;

export const WaitTitle = styled.h2<{isActive?: boolean}>`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 16px;

    text-transform: uppercase;

    color: ${colors.green};

    ${({isActive}) => isActive && 
        css`
            color: ${colors.red};
        `
    }
`;

export const WeitTextBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const WaitText = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    text-align: center;

    color: ${colors.white};
`;
