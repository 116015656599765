import { callPOST } from 'network/network';
import { VerifyConfirmCodePayload } from './ConfirmSignUpForm.services.d';


const _CONFIRM_SIGNUP_PATH = 'v1/signup';

export const verifyConfirmCode = async (payload: VerifyConfirmCodePayload) => {

    try {
        
        const response = await callPOST(_CONFIRM_SIGNUP_PATH, payload);

        if (response === undefined || !response) {
            throw new Error();
        }
        
        return response;

    } catch (error: any) {
        throw error;
    }
};
