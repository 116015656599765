import styled, { css } from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const OddsRangeButtonContainer = styled.div`
    display: flex;
    cursor: pointer;
    height: 36px;
`;

export const OddsRangeLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.grey};
    border-radius: 5px 0 0 5px;
    border: 1px solid ${colors.grey};
    padding: 10px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${colors.white};
    height: 36px;
    width: 60px;
`;

export const OddsRangeLabels = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 36px;
    border: 1px solid ${colors.grey};
    border-right: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const OddsLimitLabel = styled.div`
    color: white;
    padding: 0 5px;
    text-align: right;
`;

export const OddsRangeValues = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 45px;
    border-radius: 0 5px 5px 0;
    border: 1px solid ${colors.grey};
    border-left: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const OddsLimit = styled.div`
    color: white;
    line-height: 18px;
`; 

export const OddsRangeContainer = styled.div`
    display: flex;
    grid-template-columns: 60px auto 60px;
    grid-template-rows: 36px 18px;
    gap: 1px 5px;
    align-items: center;

`;

export const OddsRangeOptionsContainer = styled.div`
    display: flex;
    column-gap: 4px;
`;

export const OddsRangeOption = styled(Button)`
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    height: 24px;
    padding: 10px 5px;
    background-color: ${colors.grey};
    color: white;
    svg {
        height: 18px;
        width: 18px;
    }
`;

export const DoubleSliderContainer = styled.div`
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
`;