import React, { useEffect, useState } from 'react';

// style
import { 
  InfoText,
  Page 
} from './style/GeneralSupport.style';

import store from 'redux/store';
import MBSupport from './MBSupport/MBSupport.component';
import VDRSupport from './VDRSupport/VDRSupport.component';
import TCRSupport from './TCRSupport/TCRSupport.component';
import useBrand from 'hooks/useBrand';
import LoadingMask from 'components/ui/LoadingMask';

const GeneralSupport = () => {
  const [isReduxReady, setReduxReady] = useState(false);

  useEffect(() => {
    // Qui potresti anche aggiungere un ascoltatore per eventuali cambiamenti nello stato di Redux
    // per essere sicuro che questo componente si aggiorni quando necessario.
    if (store.getState().ui.brand) {
      setReduxReady(true);
    }
  }, [useBrand()]);

  if (!isReduxReady) {
    // Puoi renderizzare un caricamento o un altro componente placeholder fino a quando lo stato di Redux non è pronto
    return <LoadingMask/>;
  }
  
  const isTCR = store.getState().ui.brand?.id === 2;
  const isVDR = store.getState().ui.brand?.id === 3;
  const isMB = store.getState().ui.brand?.id === 4;

  return (
    <Page>   
      <InfoText>Per segnalare eventuali problemi o richieste, invia un'email a:</InfoText>
   
      {isMB && <MBSupport/>}
      {isVDR && <VDRSupport/>}
      {isTCR && <TCRSupport/>}
    </Page>
  );
};

export default GeneralSupport;
