import { FC, useState, useRef } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import TextInputWithLabel from 'components/ui/TextInputWithLabel';

// assets
import { ReactComponent as TrashSVG } from 'pages/OddsScasserPage/assets/trash.svg';

// types
import { UploadProps } from './Upload.component.d';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';

//style
import { Col, Row } from 'style/layout';
import { AddButton, FileInput } from './style/Upload.style';
import { SVGIconContainer, Select } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

const Upload: FC<UploadProps> = ({ onUploadClick }) => {
    const [imageToUpload, setImageToUpload] = useState<File|undefined>(undefined);
    const [url, setUrl] = useState<TextInputState>({value: ''});
    const [fileType, setFileType] = useState<string>('Immagine');

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useAppDispatch();

    const handleFileChange = (newFile: FileList | null) => {
        console.log(newFile);
        if (!newFile) return;
        setImageToUpload(newFile[0]);
    };

    const handleFileDelete = () => {
        setImageToUpload(undefined);
        if(inputRef.current) inputRef.current.value = '';
    };

    const onAddClick = () => {
        if((url.errorMessage || url.value.length===0) && !imageToUpload) return dispatch(addSnackbar({ type: 'error', message: 'Seleziona un file o inserisci un url'}));
        if((url.errorMessage || url.value.length===0) && fileType==='Video') return dispatch(addSnackbar({ type: 'error', message: 'Inserire url del video'}));
        onUploadClick && onUploadClick(fileType, fileType==='Video' ? undefined : imageToUpload, url.errorMessage ? undefined : url.value);
    };

    return(
        <Row gap='5px' mainAxis='center' width='auto'>
            <Col gap='5px'>
                <Row gap='5px' height='25px'>
                    <SVGIconContainer size={21} onClick={handleFileDelete}><TrashSVG/></SVGIconContainer>
                    <FileInput 
                        ref={inputRef}
                        type="file"
                        id="image"
                        accept=".png, .jpeg, .jpg"
                        onChange={(e) => handleFileChange(e.target.files)}
                        // style={{ display: 'none' }}
                    />
                </Row>
                <TextInputWithLabel value={url.value} allowEmpty={true} height={25} width='100%' onChange={(newState: TextInputState) => setUrl(newState)}/>
            </Col>
            <Col gap='5px'>
                <Select 
                    name={"select-file-type"} 
                    value={fileType} 
                    width='100px' height='25px'
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setFileType(event.target.value)}
                >
                    <option value='Immagine'>Immagine</option>
                    <option value='Video'>Video</option>
                </Select>
                <AddButton onClick={onAddClick}>Aggiungi file</AddButton>
            </Col>
        </Row>
    );
}

export default Upload;