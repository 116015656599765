import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const UserInputsBox = styled.div`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 16px;
    flex-direction: column;
`;

export const LoginButton = styled(Button)`

    width: 100%;

    margin: 25px 0 0;
    padding: 15px 8px;

    font-size: 15px;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    color: ${colors.black087};

    @media ${devices.tablet} {

        padding: 15px 8px;
        letter-spacing: normal;
    }
`;

export const RememberUserAndRecoverPasswordRow = styled.div`

    width: 100%;

    margin: 35.5px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    flex-direction: row;
    flex-wrap: nowrap;

    @media ${devices.tablet} {

        margin: 25.5px 0 0;
    }
`;

export const ForgotPasswordLink = styled.div`

    > a {

        display: inline-block;
        text-decoration: none;
    
        padding: 0 0 7px;
        
        border: none;
        border-bottom: 1px solid ${colors.white};
    
        font-size: 13px;
        font-weight: 400;
    
        color: ${colors.white};
    
        @media ${devices.tablet} {
    
            font-size: 15px;
        }
    }
`;
