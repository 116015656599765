import { callGET } from "network/network";


export const updateFacebookDataOnDB = async () => {

    try {
        const response = await callGET('v1/users/self/updatefacebookdata', true);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getUserFacebookGroups = async () => {

    try {
        const response = await callGET('v1/users/self/getfacebookgroups', true);

        return response;
    } catch (error: any) {
        throw error;
    }
};

