import { FC, FormEvent, useState } from 'react';

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { saveUserEmail } from 'redux/actions/actions-user';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

//types
import { ConfirmCodeFormProps } from './ConfirmCodeForm.d';

// components
import TextInput from 'components/ui/TextInput';

//utils
import { validateConfirmCodeInput } from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { awsGetCurrentAuthenticatedUser } from 'services/awsUser.services';
import {
    awsVerifyEmailConfirmCode,
    changeUserEmail
} from './services/ConfirmCodeForm.services';

// style
import {
    ConfirmCodeFormBox,
    VerifyCodeButtonBox,
    VerifyCodeButton
} from './style/ConfirmCodeForm.style';


/**
 * Confirm code form
 * 
 * @author Alessio Grassi and Frank Berni
 * 
 * @param onVerifyCodeSuccess - handler for the closure of the edit form
 * 
 * @returns JSX
 */


const ConfirmCodeForm: FC<ConfirmCodeFormProps> = ({ onVerifyCodeSuccess }) => {

    const [verifyCodeInput, setVerifyCodeInput] = useState<string>("");
    const [verifyCodeInputErrorMessage, setVerifyCodeInputErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();

    const validateVerifyCodeHandler = (verifyCode: string) => {

        const error = validateConfirmCodeInput(verifyCode);
        setVerifyCodeInputErrorMessage(error);

        return error;
    };

    const verifyCodeChangeHandler = (verifyCode: string) => {

        validateVerifyCodeHandler(verifyCode);
        setVerifyCodeInput(verifyCode);
    };
    
    const confirmCodeFormSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (isLoading) return;
        const verifyCodeError = validateVerifyCodeHandler(verifyCodeInput);
        if (verifyCodeError) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {
            
            const awsVerifyEmailConfirmCodePayload = { confirmCode: verifyCodeInput };
            const awsVerifyEmailConfirmCodeResponse = await awsVerifyEmailConfirmCode(awsVerifyEmailConfirmCodePayload);
            if (!isMounted()) return;

            if (awsVerifyEmailConfirmCodeResponse === undefined) {
                throw new Error();
            }

            const awsGetCurrentAuthenticatedUserResponse = await awsGetCurrentAuthenticatedUser();
            if (!isMounted()) return;

            if (awsGetCurrentAuthenticatedUserResponse === undefined) {
                throw new Error();
            }

            const newUserEmail = awsGetCurrentAuthenticatedUserResponse.attributes.email;
            dispatch( saveUserEmail(newUserEmail) );
            
            // to do check after backend soles payload issue
            const changeUserEmailPayload = { newEmail: newUserEmail };
            const changeUserEmailResponse = await changeUserEmail(changeUserEmailPayload);

            if (!isMounted()) return;

            if (changeUserEmailResponse === undefined || !changeUserEmailResponse) {
                console.warn("form confirm code change user email undefined response");
            }

            snackbarData.type = 'success';
            snackbarData.message = "Cambio di email avvenuto con successo.";
            dispatch( addSnackbar(snackbarData) );
            onVerifyCodeSuccess();
            setIsLoading(false);

        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    }

    return (
        <ConfirmCodeFormBox
            onSubmit={confirmCodeFormSubmitHandler}
        >
            <TextInput
                label='Codice di verifica'
                value={verifyCodeInput}
                errorMsg={verifyCodeInputErrorMessage}
                onChange={verifyCodeChangeHandler}
                onValidate={() => validateVerifyCodeHandler(verifyCodeInput)}
            />

            <VerifyCodeButtonBox>
                <VerifyCodeButton
                    type='submit'
                    disabled={isLoading}
                >
                    Verifica
                </VerifyCodeButton>
            </VerifyCodeButtonBox>
        </ConfirmCodeFormBox>
    );
};

export default ConfirmCodeForm;
