import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;
`;

export const Content = styled.div`

    width: 100%;
    height: 100%;

    padding: 27px 27px 30px 11px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media ${devices.tablet} {

        padding: 27px 27px 20px 11px;
        justify-content: flex-start;
    }
`;

export const UserNameRow = styled.div`

    width: 100%;

    margin: 20px 0 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    gap: 10px;
    flex-direction: row;

    > span {

        font-size: 15px;
        font-weight: 500;
        line-height: 17px;

        color: ${colors.white};
    }
`;

export const LoginRow = styled.div`

    margin: 20px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;
