import { FC, useState } from 'react';

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCopyToClipboard } from 'usehooks-ts';

// utils
import { getEnvironmentBaseUrl, getEnvironmentProductId } from 'configs/config.helper';

// types
import { MakeMoneyWithoutMoneyProps } from './MakeMoneyWithoutMoney.component.d';

// assets
import { ReactComponent as BulletCheck } from './assets/bulletCheck.svg';
import { ReactComponent as CopySVG } from './assets/link-icon.svg';

// components
import MediaPlayer from 'components/MediaPlayer/MediaPlayer.component';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

// style
import {
    Page,
    Title,
    FirstRow,
    VideoBox,
    DescriptionBox,
    DescriptionTitle,
    ImportantPhrase,
    BulletBox,
    BulletRow,
    BulletPoint,
    ButtonBox,
    CourseDescriptionContainer,
    FocusPointsBox,
    FocusPoint,
    FocusTitle,
    FocusDescription,
    ImageTextBox,
    ImageBox,
    TextBox,
    DescriptionText,
    SimpleText,
    TitleCard,
    VideoCourseListBox,
    ModalPage,
    ListBox
} from './style/MakeMoneyWithoutMoney.style';
import VideoCourseList from 'pages/ProductPage/VideoCourseList/VideoCourseList.component';


const MakeMoneyWithoutMoney: FC<MakeMoneyWithoutMoneyProps> = ({
    product,
    isOpenInModal,
    hasRequire
}) => {

    
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();
    
    if (product === undefined) return <></>;

    const { name, salesData } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${getEnvironmentProductId('fare soldi senza soldi')}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    const localMedia = {
        src: "https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/FareSoldiSenzaSoldi_VideoIntroFla.mp4",
        type: "video/mp4"
    };

    return (
        <ModalPage>
            {isAppMobile && (
                <VideoBox>
                    <MediaPlayer
                        media={localMedia}
                        preload='metadata'
                        poster={videoPosterUrl}
                    />
                </VideoBox>
            )}

            <Page>
                {!isAppMobile && (
                    <Title>
                        Fare SOLDI senza SOLDI

                        <CopySVG onClick={copyIntoClipboardHandler} />
                    </Title>
                )}

                <FirstRow>
                    {!isAppMobile && (
                        <VideoBox>
                            <MediaPlayer
                                media={localMedia}
                                preload='metadata'
                                poster={videoPosterUrl}
                            />
                        </VideoBox>
                    )}

                    {isAppMobile && (
                        <Title>
                            Fare SOLDI senza SOLDI

                            <CopySVG onClick={copyIntoClipboardHandler} />
                        </Title>
                    )}

                    <DescriptionBox>
                        <DescriptionTitle>
                            Corso in regalo
                        </DescriptionTitle>

                        <ImportantPhrase>
                            Un sistema immediato per <span>creare SOLDI dal NULLA</span>, 
                            senza comprare e senza vendere niente. Scarica questo corso in REGALO e tocca con mano
                        </ImportantPhrase>

                        <BulletBox>
                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    Non Vendi Nulla
                                </BulletPoint>
                            </BulletRow>

                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    Non Investi Nulla
                                </BulletPoint>
                            </BulletRow>

                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    Non fai Network Marketing
                                </BulletPoint>
                            </BulletRow>
                        </BulletBox>

                        <ButtonBox>
                            <ButtonShop 
                                PriceProduct={0} 
                                TypeOfProduct={product.type}
                                courseElement={product} 
                                courseID={product.id} 
                                payments={product.paymentTypes}
                                isOpenInModal={isOpenInModal}
                                isOnPublicProductPage
                                loadingOff={loadingOff}
                                loadingOn={loadingOn}
                                onLibertyClick={loadingOff}
                                hasRequire={hasRequire}
                                isPresentTextHidden
                                customLabel={`VOGLIO SCOPRIRE DI CHE SI TRATTA`}
                            />
                        </ButtonBox>
                    </DescriptionBox>
                </FirstRow>

                <CourseDescriptionContainer>
                    <FocusPointsBox>
                        <FocusPoint>
                            <FocusTitle>
                                Crea Soldi dal Nulla
                            </FocusTitle>

                            <FocusDescription>
                                Ti insegneremo un metodo facile e <span>IMMEDIATO</span> per
                                avere una seconda entrata
                            </FocusDescription>
                        </FocusPoint>
                        
                        <FocusPoint>
                            <FocusTitle>
                                Senza Vendere Niente
                            </FocusTitle>

                            <FocusDescription>
                                Anche se ti sembra IMPOSSIBILE ti insegneremo a fare soldi <span>senza</span> utilizzare 
                                il tuo <span>capitale</span> e senza vendere nulla
                            </FocusDescription>
                        </FocusPoint>

                        <FocusPoint>
                            <FocusTitle>
                                Il Corso è in Regalo
                            </FocusTitle>

                            <FocusDescription>
                                Questo corso non ti costa niente. <span>È un regalo.</span> Non 
                                hai niente da perdere, hai solo da guadagnare.
                            </FocusDescription>
                        </FocusPoint>
                    </FocusPointsBox>

                    <ImageTextBox>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/img/img1.jpg' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <DescriptionText>
                                E se ti dicessi che esiste un <span>sistema immediato</span> per 
                                creare <span>SOLDI dal NULLA,</span> senza comprare e senza vendere niente? Sì lo so, 
                                Sembra IMPOSSIBILE. Ma continua a leggere e ti renderai conto che è tutto vero...
                            </DescriptionText>
                            
                            <DescriptionText>
                                Infatti un sistema <span>provato e collaudato</span> in più di 5 anni di esperienza 
                                (con risultati dimostrabili) esiste. E tu puoi averlo <span>IN REGALO</span>, lo so, 
                                sembra troppo bello per essere vero.
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <TextBox>
                            <DescriptionText>
                                Non mi devi credere sulla parola, puoi provare tu stesso e verificare sulla tua 
                                stessa pelle quanto è <span>facile</span> portarti a 
                                casa <span>centinaia di Euro</span> in pochissimo tempo.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Per provare non devi fare altro che scaricare questo 
                                corso <span>in REGALO</span> e toccare con mano.
                            </DescriptionText>
                            
                            <DescriptionText>
                                <span>SENZA VENDERE NULLA, SENZA INVESTIRE UN EURO E SENZA FARE NETWORK MARKETING</span>
                            </DescriptionText>
                        </TextBox>

                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/img/img2.png' alt='Money'/>
                        </ImageBox>
                    </ImageTextBox>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={0} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            isOpenInModal={isOpenInModal}
                            isOnPublicProductPage
                            loadingOff={loadingOff}
                            loadingOn={loadingOn}
                            onLibertyClick={loadingOff}
                            hasRequire={hasRequire}
                            isPresentTextHidden
                            customLabel={`VOGLIO SCOPRIRE DI CHE SI TRATTA`}
                        />
                    </ButtonBox>

                    <ImageTextBox>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/img/img3.png' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <DescriptionText>
                                Come funziona?
                            </DescriptionText>
                            
                            <DescriptionText>
                                Semplice: è un sistema che si basa sui <span>Bonus</span> elargiti da 
                                Istituti Finanziari che lanciano di continuo promozioni e offerte Bonus per promuoversi.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Questo perché operano in un mercato dove c’è una forte <span>concorrenza</span> e 
                                per invogliare le persone ad affidarsi al loro istituto offrono un regalo, un incentivo...
                            </DescriptionText>
                            
                            <DescriptionText>
                                Un <span>BONUS</span>, appunto.
                                E tu non devi fare altro che intercettare queste promozioni e intascarti questi Soldi...
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <SimpleText>
                        Adesso però la palla passa a te.
                        
                        <br />
                        
                        Se vuoi cambiare la tua Vita il primo passo da compiere è quello di scaricare questo corso.
                    </SimpleText>
                    
                    <SimpleText>
                        Devi solo provare e ti renderai conto che tutto è possibile.
                    </SimpleText>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={0} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            isOpenInModal={isOpenInModal}
                            isOnPublicProductPage
                            loadingOff={loadingOff}
                            loadingOn={loadingOn}
                            onLibertyClick={loadingOff}
                            hasRequire={hasRequire}
                            isPresentTextHidden
                            customLabel={`VOGLIO SCOPRIRE DI CHE SI TRATTA`}
                        />
                    </ButtonBox>

                    <ImageTextBox>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/img/img4.jpg' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <DescriptionText>
                                Succede spesso che in molti si chiedono se ha senso spendere soldi per 
                                acquistare o meno un corso. <span>Hanno dubbi, ed è comprensibile.</span>
                            </DescriptionText>
                            
                            <DescriptionText>
                                Questo perché si pongono domande del tipo: “ci riuscirò?”, 
                                “magari questo corso funziona ma io non sono capace”, oppure pensano 
                                che è la solita truffa online. E quando un corso costa 
                                svariate centinaia di Euro <span>è lecito</span> porsi questo genere di domande.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Ma in questo caso è diverso.
                            </DescriptionText>
                            
                            <DescriptionText>
                                <span>Trattandosi di un regalo non hai niente da perdere. Hai solo da guadagnare.</span>
                            </DescriptionText>

                            <DescriptionText>
                                Una volta scaricato questo corso potrai decidere tranquillamente se seguire i metodi spiegati oppure no.
                            </DescriptionText>

                            <DescriptionText>
                                Tanti dei nostri partecipanti hanno guadagnato <span>centinaia di Euro in meno di 
                                una settimana.</span> E come ci sono riusciti loro, ci riuscirai tranquillamente anche tu.
                            </DescriptionText>

                            <DescriptionText>
                                Se, però, arrivati a questo punto sei ancora dubbioso...
                            </DescriptionText>

                            <DescriptionText>
                                <span>Guarda quante persone stanno già applicando con successo questo metodo.</span>
                            </DescriptionText>

                            <DescriptionText>
                                Questa è la dimostrazione che con questo corso TUTTI riescono a fare soldi.
                            </DescriptionText>

                            <DescriptionText>
                                Queste sono tutte persone che troverai all’interno del nostro gruppo 
                                Facebook e con le quali potrai addirittura parlare.
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <TextBox>
                            <DescriptionText>
                                Se migliaia di persone hanno applicato con successo questo corso
                            </DescriptionText>
                            
                            <DescriptionText>
                                puoi farlo <span>anche tu.</span>
                            </DescriptionText>
                            
                            <DescriptionText>
                                La procrastinazione costa cara, ogni giorno che aspetti è un giorno di guadagno in meno.
                            </DescriptionText>

                            <DescriptionText>
                                Clicca sul pulsante qua sotto e portati a casa il metodo che 
                                ti insegnerà a <span>FARE SOLDI SENZA SOLDI.</span>
                            </DescriptionText>

                            <DescriptionText>
                                Non hai niente da perdere. Solo da guadagnare...
                            </DescriptionText>
                            
                            <DescriptionText>
                                Ci vediamo dall’altra parte.
                            </DescriptionText>
                        </TextBox>

                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoFareSoldiSenzaSoldi/img/img5.png' alt='Money'/>
                        </ImageBox>
                    </ImageTextBox>
                    
                    <ListBox>
                        <TitleCard>
                            ESPLORA IL CONTENUTO DEL CORSO:
                        </TitleCard>

                        <VideoCourseListBox>
                            <VideoCourseList 
                                courseItem={product.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos)}
                                collapseHandler={() => {}}
                                isOnPublicPage
                            />
                        </VideoCourseListBox>
                    </ListBox>

                    <ButtonBox paddingTop='10px'>       
                        <ButtonShop 
                            PriceProduct={0} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            isOpenInModal={isOpenInModal}
                            isOnPublicProductPage
                            loadingOff={loadingOff}
                            loadingOn={loadingOn}
                            onLibertyClick={loadingOff}
                            hasRequire={hasRequire}
                            isPresentTextHidden
                            customLabel={`VOGLIO SCOPRIRE DI CHE SI TRATTA`}
                        />
                    </ButtonBox>
                </CourseDescriptionContainer>
            </Page>
        </ModalPage>
    );
};

export default MakeMoneyWithoutMoney;
