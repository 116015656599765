export class FnError extends Error {

    constructor(
            errorFnLocation?: string | undefined,
            message?: string | undefined,
            ...options: any
        ) {

        super(message, ...options);
        this.name = `${this.name} in ${errorFnLocation}`;
        this.message = message ?? "";
    };
};
