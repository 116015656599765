import { FC, useEffect } from 'react';

// utils
import { snackbarIconByType } from './SnackBar.helper';

// types
import { SnackBarProps, SnackbarIcon } from './SnackBar.d';

// assets
import { ReactComponent as CloseIconSVG } from './assets/close-icon.svg';

// style
import { SnackBarCard, MessageBox, CloseButton } from './style/SnackBar.style';
import { getEnvironmentAssetLinkUrl, getEnvironmentMonolithLinkUrl } from 'configs/config.helper';


/**
 * Snackbar
 * 
 * @author Alessio Grassi
 * 
 * @param id - Snackbar id (in case you have more than one active Snackbar)
 * @param message - Snackbar text
 * @param type - Snackbar severity
 * @param onSnackClose - handler for closure before setted time
 * 
 * @returns JSX - Warning Snackbar is default snackbar type
 */


const SnackBar: FC<SnackBarProps> = ({ id, message, type, onSnackClose }) => {

    const snackbarIcon: SnackbarIcon = snackbarIconByType(type);

    const snackCloseHandler = (id: string) => {
        
        if (onSnackClose === undefined) return;
        onSnackClose(id);
    };

    useEffect(() => {

        if (onSnackClose === undefined) return;
        const timerID = setTimeout(() => onSnackClose(id), 6000);

        return () => clearTimeout(timerID);

    }, [id, onSnackClose]);

    return (
        <SnackBarCard type={type}>
            {/* <img
                src={getEnvironmentAssetLinkUrl(snackbarIcon.src)}
                alt={snackbarIcon?.alt ?? "snackbar type"}
            /> */}
            
            <MessageBox>
                {message}
            </MessageBox>

            <CloseButton onClick={() => snackCloseHandler(id)}>
                <CloseIconSVG />
            </CloseButton>
        </SnackBarCard>
    );
}

export default SnackBar;
