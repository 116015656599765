import { FC, useEffect, useState, Fragment, useMemo, memo } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { setItemToLocalStorage, getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';
import { setObjToSessionStorage, getObjFromSessionStorage, setItemToSessionStorage, getItemFromSessionStorage } from 'utils/sessionStorage';
import { _saveRollover, _getRollovers, _deleteRollover } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { setRollovers, addRollover, updateRollover, removeRollover, setCalculatorsRollovers, addCalculatorsRollover, updateCalculatorsRollover, removeCalculatorsRollover } from 'redux/actions/actions-oddsscasser';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import NumberInput from 'components/ui/NumberInput';
import Dropdown from 'components/Dropdown';
import TextInputWithLabel from 'components/ui/TextInputWithLabel';
import CheckboxesGroup from 'components/CheckboxesGroup';
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete';
import EventInput from '../Multiple/EventInput';

//assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';
import { ReactComponent as SearchIconSVG } from 'pages/OddsScasserPage/assets/searchIcon.svg';
import { ReactComponent as EditIconSVG } from 'pages/OddsScasserPage/assets/edit.svg';
import { ReactComponent as CheckmarkSVG } from 'pages/OddsScasserPage/assets/checkmark.svg';
import { ReactComponent as WarningIconSVG } from 'pages/OddsScasserPage/assets/warning.svg';

//style
import { RolloverContainer, Parameters, CoverBets, Action, RolloverStageContainer, RolloverStageTitle, RolloverStagesContainer, RolloverCompletionAdd, RolloverFinishedReason, RolloverFinishedValue, RolloverInfoContainer, RolloverInfoElement, RolloverInfoLabel, RolloverInfoValue, RolloverStage2Info, CoverBetsAndRatingContainer, CoverBetsContainer, RolloverStageTitleLabel, RolloverList, RolloverElementEventRow, RolloverElement, RolloverElementEvent, RolloverElementOddsMatchRow, RolloverElementCell, RolloverElementOdds, RolloverElementOddsSelection, RolloverElementOddsCoversContainer, RolloverElementOddsCovers, OverRolloverWarning, WarningIconsContainer, OverRolloverWarningText } from './style/Rollover.style';
import { Result, ToolButton, ToolButtons, SVGIconContainer, Select, BetContainer, Bet, Resp, WinnigsAndRating, Rating, Winnings, BetsTable, BetsTableBody, BetsTableCell, BetsTableHead, BetsTableHeaderCell, BetsTableRow, BetsTableSingleDivCell, CoverOdds, DropdownContent, HorizontalScrollContainer, StandardCheckbox, CoverInstructions, CoverInstructionsContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, SavedElementCheckbox, SavedElementsContainer, Flex, WinnigsAndRatingContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

//types
import { RolloverProps } from './Rollover.component.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Odds } from 'pages/OddsScasserPage/types/Odds';
import { Event } from 'pages/OddsScasserPage/types/Event';
import { Covers } from '../../types/Covers';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';
import { RolloverObj, RolloverData, RolloverOddsMatch } from './types/RolloverObj';
import { RolloverProgress } from './types/RolloverProgress';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { EventInputState } from '../Multiple/EventInput/types/EventInputState';

//helper functions
import { calculatePuntaPunta, calculatePuntaBanca, stepIntegers, stepOdds, stepEach, resultComment, formatDate, selectionsValues, coverSelectionsTriscasser, coverSelectionPuntaPunta, getResult, localeStringToUTCString, stepOddsPP } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const Rollover: FC<RolloverProps> = ({calculators, oddsMatchToAdd, filters, search, visible, feesBetfair, feesBetflag, changeVisibility, copyToClipboard}) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    
    const rolloverIdStorageKey: string = calculators ? 'calculatorsRolloverId' : 'rolloverId';
    const rolloverStorageKey: string = calculators ? 'calculatorsRollover' : 'rollover';

    const { sites, events, rollovers, calculatorsRollovers } = useAppSelector(state => state.oddsScasser);
    const rolloversList: RolloverObj[]|undefined = useMemo(() => calculators ? calculatorsRollovers : rollovers, [rollovers, calculatorsRollovers, calculators]);
    const { roles } = useAppSelector(state => state.user);
    const isAdmin: boolean = useMemo(() => roles ? roles.map((role: any) => role.id).includes(31) : false, [roles]);

    const dispatch = useAppDispatch();

    const [initializationCompleted, setInitializationCompleted] = useState<boolean>(false);
    const [rolloverIndex, setRolloverIndex] = useState<number>(-1);
    const [toSave, setToSave] = useState<boolean>(false);

    const [description, setDescription] = useState<TextInputState>({value: ''});
    const [siteId, setSiteId] = useState<number>(-1);
    const [bonus, setBonus] = useState<NumberInputState>({value: '', errorMessage: 'NaN'});
    const [multiplier, setMultiplier] = useState<NumberInputState>({value: '2'});
    const [bets, setBets] = useState<NumberInputState>({value: '1'});
    const [avgRating, setAvgRating] = useState<NumberInputState>({value: '', errorMessage: 'NaN'});
    //Bonus Bets (stage1)
    const [stage1Bets, setStage1Bets] = useState<RolloverOddsMatch[]>(Array.from({length:5}, () => ({
        mode: 'pb',
        amount: {value: '', errorMessage: 'NaN'},
        covered: false,
        result: null,
        event: null,
        eventText: '',
        eventDate: '',
        fees: feesBetfair,
        selections: ['', '', ''],
        sitesIds: [-1, -1, -1],
        sitesUrls: [null, null, null],
        odds: Array.from({length:3}, () => ({value: '', errorMessage: 'NaN'})),
        isOpened: true,
        coversToRecalculate: true
    })));
    const [stage1Covers, setStage1Covers] = useState<Covers[]>(Array.from({length:5}, () => ({covers: [{}, {}, {}]})));
    //Rollover completion (stage2)
    const [stage2Bets, setStage2Bets] = useState<RolloverOddsMatch[]>([]);
    const [stage2Covers, setStage2Covers] = useState<Covers[]>([]);
    const [stage2OddsMin, setStage2OddsMin] = useState<NumberInputState>({value: ''});
    //Edit
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [editingEvent, setEditingEvent] = useState<EventInputState|undefined>(undefined);
    const [editingOddsMatch, setEditingOddsMatch] = useState<RolloverOddsMatch|undefined>(undefined);
    //Calculators
    const [fees, setFees] = useState<NumberInputState>({value: feesBetfair});

    const initialData = () => {
        const data: RolloverData = {
            description: {value: ''}, siteId: -1, bonus: {value: '', errorMessage: 'NaN'}, multiplier: {value: '2'}, bets: {value: '1'}, avgRating: {value: '', errorMessage: 'NaN'},
            stage1Bets: Array.from({length:5}, () => ({
                mode: 'pb',
                amount: {value: '', errorMessage: 'NaN'},
                covered: false,
                result: null,
                event: null,
                eventText: '',
                eventDate: '',
                fees: feesBetfair,
                selections: ['', '', ''],
                sitesIds: [-1, -1, -1],
                sitesUrls: [null, null, null],
                odds: Array.from({length:3}, () => ({value: '', errorMessage: 'NaN'})),
                isOpened: true,
                coversToRecalculate: true
            })),
            stage2Bets: [],
            stage2OddsMin: {value: ''}
        }
        return data;
    }

    useEffect(() => {
        //Get rollovers
        const getRollovers = async () => {
            try {
                let _rollovers = rollovers;
                if(!_rollovers) {
                    _rollovers = await _getRollovers(calculators) as RolloverObj[];
                    dispatch(calculators ? setCalculatorsRollovers(_rollovers) : setRollovers(_rollovers));
                }
                const id = getItemFromLocalStorage(rolloverIdStorageKey);
                setRolloverIndex(id ? _rollovers.findIndex(x => x.id===parseInt(id)) : -1);
                //If there's a rollover in the sessionStorage, load it
                if(getItemFromSessionStorage(rolloverStorageKey)) {
                    setToSave(!(rolloversList!==undefined && rolloverIndex>-1 && getItemFromSessionStorage(rolloverStorageKey)===JSON.stringify(rolloversList[rolloverIndex].data)));
                    loadFromSessionStorage();
                    return;
                }
                if(!id) return;
                //Otherwise, load the saved rollover
                let found = false;
                _rollovers.forEach((x: RolloverObj) => {
                    if(x.id && x.id.toString() === id) {
                        found = true;
                        setItemToSessionStorage(rolloverStorageKey, JSON.stringify(x.data));
                        loadFromSessionStorage();
                        return;
                    }
                })
                if(!found) removeItemFromLocalStorage(rolloverIdStorageKey);
            } catch (error: any) {
                console.warn(error);
                // navigate(error.message === '403' ? '/fallback-page/permission-denied' : '/fallback-page/generic-error');
            }
        };
        if(!initializationCompleted) getRollovers().then(() => setInitializationCompleted(true));
        else loadFromSessionStorage();
    }, []);
    
    const loadFromSessionStorage = () => {
        const rollover = getObjFromSessionStorage(rolloverStorageKey) as RolloverData;
        if(!rollover) return;
        setDescription(rollover.description);
        setSiteId(rollover.siteId ?? -1);
        setBonus(rollover.bonus);
        setMultiplier(rollover.multiplier);
        setBets(rollover.bets);
        setAvgRating(rollover.avgRating);
        setStage1Bets(prevState => {
            return rollover.stage1Bets.map(x => ({...x, coversToRecalculate: true}));
        });
        setStage2Bets(rollover.stage2Bets.map(x => ({...x, coversToRecalculate: true})));
        setStage2OddsMin(rollover.stage2OddsMin ? rollover.stage2OddsMin : {value: ''});
    }

    //Set results of finished events
    useEffect(() => {
        if(!initializationCompleted || events.size === 0) return;
        setStage1Bets(prevState => {
            return prevState.map((bet, i) => {
                if(bet.result !== null) return bet;
                const event: Event|undefined = bet.event!==null ? events.get(bet.event?.id ?? 0) : undefined;
                if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
                    return {...bet, result: getResult(bet.mode, bet.selections, event.home.score, event.away.score)};
                }
                return bet;
            });
        });
        setStage2Bets(prevState => prevState.map((bet, i) => {
            if(bet.result !== null) return bet;
            const event: Event|undefined = bet.event!==null ? events.get(bet.event?.id ?? 0) : undefined;
            if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
                return {...bet, result: getResult(bet.mode, bet.selections, event.home.score, event.away.score)};
            }
            return bet;
        }));
    }, [initializationCompleted, events, rolloversList, rolloverIndex]);

    useEffect(() => {
        setStage1Bets(prevState => {
            return prevState.map((x,i) => {
                if(x.result!==null || x.mode !== 'pb') return x;
                return {
                    ...x, 
                    fees: calculators ? (!fees.errorMessage ? fees.value : x.fees) : (x.sitesIds[1] === 9 ? feesBetflag : feesBetfair),
                    coversToRecalculate: true
                };
            });
        });
        setStage2Bets(prevState => {
            return prevState.map((x,i) => {
                if(x.result!==null || x.mode !== 'pb') return x;
                return {
                    ...x, 
                    fees: calculators ? (!fees.errorMessage ? fees.value : x.fees) : (x.sitesIds[1] === 9 ? feesBetflag : feesBetfair),
                    coversToRecalculate: true
                };
            });
        });
    }, [feesBetfair, feesBetflag, fees]);

    //Add oddsMatch
    useEffect(() => {
        if(!calculators && initializationCompleted && oddsMatchToAdd) addToRollover(oddsMatchToAdd);
    }, [oddsMatchToAdd]);

    const addToRollover = (oddsMatch: OddsMatch) => {
        if(!calculators) {
            if(siteId<0) setSiteId(oddsMatch.odds[0].site.id);
            else if(siteId>0 && siteId !== oddsMatch.odds[0].site.id) {
                dispatch(addSnackbar({ type: 'error', message: 'Bookmaker non valido. Il bookmaker deve essere '+sites.get(siteId)?.name}));
                return;
            } 
        }
        if(bonus.errorMessage || multiplier.errorMessage || bets.errorMessage || avgRating.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Inserire prima i parametri del rollover' }));
            return;
        }
        const selections: string[] = oddsMatch.odds.map(x => x.type ?? '');
        if(selections.length<3) selections.push('');
        const odds: NumberInputState[] = oddsMatch.odds.map(x => ({value: x.odds.toFixed(2)}));
        if(odds.length<3) odds.push({value: '', errorMessage: 'NaN'});
        const oddsInfo: (Odds|null)[] = [...oddsMatch.odds];
        if(oddsInfo.length<3) oddsInfo.push(null);
        const mode = oddsMatch.odds[0].type === oddsMatch.odds[1].type ? 'pb' : (oddsMatch.odds.length>2 ? 'tri' : 'pp');
        const _fees = mode === 'pb' && oddsInfo[1] ? (oddsInfo[1].site.id===9 ? feesBetflag : feesBetfair) : null;
        const event = events.get(oddsMatch.event.id) ?? null;
        let eventText = '';
        if(event) {
            eventText = `${event.home?.name} - ${event.away?.name}`;
            const league = event.league;
            if(league) {
                const country = league.country;
                const leagueName = league.name;
                if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
            }
        }
        const om: RolloverOddsMatch = {
            mode,
            amount: {value: '', errorMessage: 'NaN'},
            covered: false,
            result: null,
            event,
            eventText,
            eventDate: (event!==null && event.datetime) ? event.datetime : '',
            fees: _fees,
            selections,
            sitesIds: oddsInfo.map((x,i) => (i===0 && siteId===0) ? 0 : (x?.site.id ?? -1)),
            sitesUrls: oddsInfo.map((x,i) => (i===0 && siteId===0) ? null : (x?.url ?? null)),
            odds,
            isOpened: true,
            coversToRecalculate: true
        }
        const _bets = parseInt(bets.value);
        const stage1BetsFinished = stage1Bets.every((x, i) => i>=_bets || x.result!==null);
        if(!stage1BetsFinished) {//stage1
            let index = -1;
            if(editingIndex<10 && editingIndex>-1) index = editingIndex;
            else {
                for (let i = 0; i < _bets; i++) {
                    const b: boolean =  editingIndex<10 && editingIndex>-1 && stage1Bets[i].eventText.length===0;//oneSelectedWithoutEvent
                    const selected = editingIndex===i;
                    if(stage1Bets[i].result===null && ((b && selected) || (!b && (stage1Bets[i].odds.some((y,j) => j<oddsMatch.odds.length && y.errorMessage) || i===_bets-1)))) {
                        index = i;
                        break;
                    }             
                }
            }
            if(index>-1) {
                if(editingIndex===index) {
                    setEditingOddsMatch(prevState => prevState ? {...om, amount: {...prevState.amount}} : om);
                    const eventId = (om.event || {id: -1}).id;
                    const eventText = om.eventText;
                    let d = new Date(om.eventDate);
                    const eventDate = om.eventDate.length>0
                        ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
                        : {day: '', month: '', year: '', hour: '', minute: '', errorMessage: 'Invalid date'};
                    setEditingEvent({eventId, eventText, eventDate});
                } else setStage1Bets(prevState => {
                    return prevState.map((x,i) => i===index ? {...om, amount: x.amount} : x);
                });
            }
        } else {//stage2
            let index = -1;
            if(editingIndex>=10) index = editingIndex-10;
            else {
                for (let i = 0; i < stage2Bets.length; i++) {
                    const b: boolean =  editingIndex>=10 && stage2Bets[i].eventText.length===0;//oneSelectedWithoutEvent
                    const selected = editingIndex>=10 && editingIndex-10===i;
                    if(stage2Bets[i].result===null && ((b && selected) || (!b && stage2Bets[i].odds.some((y,j) => j<oddsMatch.odds.length && y.errorMessage)))) {
                        index = i;
                        break;
                    }
                }
            }
            if(index === -1) {//Add new
                if(progress && (progress.bonusBalanceIncludingAllBets<1 || progress.remainingRolloverIncludingAllBets<0.01)) {
                    dispatch(addSnackbar({ type: 'error', message: 'Saldo bonus o rollover terminato'}));
                    return;
                }
                setStage2Bets(prevState => [...prevState, om]);
            } else {
                if(editingIndex-10===index) {
                    setEditingOddsMatch(prevState => prevState ? {...om, amount: {...prevState.amount}} : om);
                    const eventId = (om.event || {id: -1}).id;
                    const eventText = om.eventText;
                    let d = new Date(om.eventDate);
                    const eventDate = om.eventDate.length>0
                        ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
                        : {day: '', month: '', year: '', hour: '', minute: '', errorMessage: 'Invalid date'};
                    setEditingEvent({eventId, eventText, eventDate});
                    
                } else setStage2Bets(prevState => prevState.map((x,i) => i===index ? om : x));
            }
        }        
        dispatch(addSnackbar({ type: 'success', message: 'Evento aggiunto al rollover'}));
    }

    useEffect(() => {
        if(rolloversList && initializationCompleted) {
            setObjToSessionStorage(rolloverStorageKey, getRolloverData());
            setToSave(!(rolloversList!==undefined && rolloverIndex>-1 && rolloverIndex<rolloversList.length && getItemFromSessionStorage(rolloverStorageKey)===JSON.stringify(rolloversList[rolloverIndex].data)));
        }
    }, [rolloverIndex, description, siteId, stage1Bets, stage1Covers, stage2Bets, stage2Covers, stage2OddsMin]);
    
    //Set sitesList when sites changes
    const sitesList: Site[] = useMemo(() => {
        return (!calculators && sites && sites.size>0) ? Array.from(sites.values()) : [];
    }, [sites])

    const progress: RolloverProgress|undefined = useMemo(() => {
        if(bonus.errorMessage || multiplier.errorMessage || avgRating.errorMessage || bets.errorMessage) return;
        const _bonus = parseFloat(bonus.value);
        const _multiplier = parseInt(multiplier.value);
        const _avgRating = parseFloat(avgRating.value);
        const _bets = parseInt(bets.value);
        const stage1BetsOk = stage1Covers.every((x, i) => i>=parseInt(bets.value) || x.avgWinnings!==undefined);
        const stage1BetsFinished = stage1Bets.every((x, i) => i>=_bets || x.result!==null);
        const p: RolloverProgress = {bonusBalance: _bonus, bonusBalanceIncludingAllBets: _bonus, remainingRollover: _bonus*_multiplier, remainingRolloverIncludingAllBets: _bonus*_multiplier, expectedValue: stage1BetsOk ? _bonus : undefined, stage1Result: 0.0, stage2Result: 0.0, total: 0.0};
        stage1Covers.forEach((x,index) => {
            if(index>=_bets || x.avgWinnings===undefined) return;
            const bet = parseFloat(stage1Bets[index].amount.value);
            p.remainingRolloverIncludingAllBets -= bet;
            if(p.expectedValue) p.expectedValue += x.covers[1].winnings ?? 0.0;
            const result = stage1Bets[index].result;
            if(!result) return;
            const odds = parseFloat(stage1Bets[index].odds[0].value);
            p.remainingRollover -= bet;
            p.bonusBalance += result==='W' ? bet*(odds-1) : -bet;
            p.bonusBalanceIncludingAllBets += result==='W' ? bet*(odds-1) : -bet;
            const results = stage1Bets[index].mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2'];
            const resultIndex = results.findIndex(x => result===x);
            if(resultIndex>-1) p.stage1Result += x.covers[resultIndex].winnings ?? 0;
        })
        let remainingRolloverIncludingAllBetsWithOdds: number = p.remainingRollover;
        if(stage1BetsFinished && p.bonusBalance>0.01) {
            p.stage2Result = 0.00;
            // let stage2TotalAmount = 0.00;
            stage2Covers.forEach((x,index) => {
                if(index>=stage2Bets.length) return;
                const bet = parseFloat(stage2Bets[index].amount.value);
                if(!isNaN(bet)) {
                    p.bonusBalanceIncludingAllBets -= bet;
                    p.remainingRolloverIncludingAllBets -= bet;
                }
                if(x.avgWinnings===undefined) return;
                const result = stage2Bets[index].result;
                remainingRolloverIncludingAllBetsWithOdds -= bet;
                if(!result) {
                    p.stage2Result += x.avgWinnings ?? 0;
                    return;
                }
                const odds = parseFloat(stage2Bets[index].odds[0].value);
                p.bonusBalance += result==='W' ? bet*(odds-1) : -bet;
                p.bonusBalanceIncludingAllBets += result==='W' ? bet*odds : 0;
                p.remainingRollover -= bet;
                const results = stage2Bets[index].mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2'];
                const resultIndex = results.findIndex(x => result===x);
                if(resultIndex>-1) p.stage2Result += x.covers[resultIndex].winnings ?? 0;
            })
            p.realExpectedValue = _bonus + p.stage1Result + p.stage2Result + remainingRolloverIncludingAllBetsWithOdds*(_avgRating-100)/100;
        } else p.realExpectedValue = p.expectedValue;
        p.total = _bonus + p.stage1Result + (p.stage2Result ?? 0.0);
        return p;
    }, [stage1Covers, stage1Bets, stage2Covers, stage2Bets])

    //Stage1 bets change
    useEffect(() => {
        setStage1Bets(prevState => {
            return prevState.map(x => ({...x, coversToRecalculate: true}));
        });
    }, [multiplier, avgRating]);

    useEffect(() => {
        if(bonus.errorMessage || multiplier.errorMessage || bets.errorMessage || avgRating.errorMessage) return;
        setStage1Covers(prevState => prevState.map((x, index) => {
            if(!stage1Bets[index].coversToRecalculate) return x;
            const newCovers: Covers = {covers: [{}, {}, {}]};
            const mode = stage1Bets[index].mode;
            const n = mode === 'tri' ? 3 : 2;
            const amount = stage1Bets[index].amount;
            const odds = stage1Bets[index].odds;
            if(!amount.errorMessage && odds.every((y,i) => i>=n || !y.errorMessage)) {
                const bet = parseFloat(amount.value);
                const _multiplier = parseFloat(multiplier.value);
                const _avgRating = parseFloat(avgRating.value);
                const refund = Math.round((100-_avgRating)*(_multiplier-1)*bet)/100;
                const _fees = stage1Bets[index].fees ?? feesBetfair;
                const res = mode === 'pb' 
                    ? calculatePuntaBanca(bet, 0, refund, parseFloat(_fees)/100, odds.map(x => parseFloat(x.value)), 0.01)
                    : calculatePuntaPunta(bet, 0, refund, odds.map(x => parseFloat(x.value)), 1);
                newCovers.avgWinnings = res.avgWinnings;
                newCovers.avgRating = res.avgRating;
                for (let i = 0; i < res.bets.length; i++) {
                    if(i>=n) break; 
                    newCovers.covers[i].bet = res.bets[i];
                    newCovers.covers[i].resp = res.resp[i];
                    newCovers.covers[i].winnings = res.winnings[i]-(i>0 ? refund : 0);
                    newCovers.covers[i].rating = res.ratings[i];
                }
            }
            return newCovers;
        }));
    }, [stage1Bets]);

    useEffect(() => {
        if(bonus.errorMessage || multiplier.errorMessage || bets.errorMessage || avgRating.errorMessage) return;
        const newCovers: Covers[] = Array.from({length: stage2Bets.length}, () => ({covers: [{}, {}, {}]}));
        let changed: boolean = newCovers.length !== stage2Covers.length;
        stage2Covers.forEach((x, index) => {
            if(index>=stage2Bets.length) return;
            if(!stage2Bets[index].coversToRecalculate && stage2Covers.length === stage2Bets.length) {
                newCovers[index] = x;
                return;
            }
            const mode = stage2Bets[index].mode;
            const n = mode === 'tri' ? 3 : 2;
            const amount = stage2Bets[index].amount;
            const odds = stage2Bets[index].odds;
            if(!amount.errorMessage && odds.every((y,i) => i>=n || !y.errorMessage)) {
                const bet = parseFloat(amount.value);
                const _fees = stage2Bets[index].fees ?? feesBetfair;
                const res = mode === 'pb' 
                    ? calculatePuntaBanca(bet, 0, 0, parseFloat(_fees)/100, odds.map(x => parseFloat(x.value)), 0.01)
                    : calculatePuntaPunta(bet, 0, 0, odds.map(x => parseFloat(x.value)), 1);
                newCovers[index].avgWinnings = res.avgWinnings;
                newCovers[index].avgRating = res.avgRating;
                for (let i = 0; i < res.bets.length; i++) {
                    if(i>=n) break; 
                    newCovers[index].covers[i].bet = res.bets[i];
                    newCovers[index].covers[i].resp = res.resp[i];
                    newCovers[index].covers[i].winnings = res.winnings[i];
                    newCovers[index].covers[i].rating = res.ratings[i];
                }
            }
            changed = true;
        });
        if(changed) setStage2Covers(newCovers);
    }, [stage2Bets, initializationCompleted]);

    useEffect(() => {
        if(!bonus.errorMessage && !bets.errorMessage) {
            setStage1Bets(prevState => {
                const newAmounts = [...prevState.map(x => ({...x.amount}))];
                const b = parseFloat(bonus.value);
                const n = parseInt(bets.value);
                let rest = b;
                let divisor = n;
                for(let i = 0; i < n; i++) {
                    if(!newAmounts[i].errorMessage && prevState[i].result!==null) {//prevState[i].odds.every((y,j) => j>=(prevState[i].mode==='tri' ? 3 : 2) || !y.errorMessage)
                        rest -= parseFloat(newAmounts[i].value);
                        divisor--; 
                    }
                }
                if(divisor <= 0 && rest>0 && n<5) {
                    setBets({value: (n+1).toString()});
                    return prevState;
                }
                if((rest < 0 || (rest===0 && divisor>0)) && n>1) {
                    setBets({value: (n-1).toString()});
                    return prevState;
                }
                for(let i = 0; i < n; i++) {
                    if(!newAmounts[i].errorMessage && prevState[i].result!==null) continue;//&& prevState[i].odds.every((y,j) => j>=(prevState[i].mode==='tri' ? 3 : 2) || !y.errorMessage)
                    let value = Math.ceil(rest/divisor);
                    rest -= value;
                    divisor--;
                    newAmounts[i] = {value: value.toFixed(0)};
                }
                return prevState.map((x,i) => ({...x, amount: newAmounts[i], coversToRecalculate: x.coversToRecalculate || newAmounts[i].value !== x.amount.value}));
            })
        }
    }, [bonus, bets]);

    const handleStage1AmountChange = (index: number, newState: NumberInputState) => {
        if(!initializationCompleted) return;
        setStage1Bets(prevState => {
            const newAmounts = [...prevState.map(x => ({...x.amount}))];
            newAmounts[index] = newState;
            if(!newState.errorMessage) {
                const b = parseFloat(bonus.value);
                const n = parseInt(bets.value);
                let rest = b;
                let divisor = n;
                for(let i = 0; i < n; i++) {
                    if(i===index || (i<index && !newAmounts[i].errorMessage) || (!newAmounts[i].errorMessage && stage1Bets[i].result!==null)) {
                        rest -= parseFloat(newAmounts[i].value);
                        divisor--; 
                    }
                }
                for(let i = 0; i < n; i++) {
                    if(i===index || (i<index && !newAmounts[i].errorMessage) || (!newAmounts[i].errorMessage && stage1Bets[i].result!==null)) continue;
                    if(rest <= 0) return prevState;
                    let value = Math.ceil(rest/divisor);
                    rest -= value;
                    divisor--;
                    newAmounts[i] = {value: value.toFixed(0)};
                }
                if(Math.abs(rest)>0.001 && index>0) {
                    let value = rest + parseFloat(newAmounts[index-1].value);
                    newAmounts[index-1] = {value: value.toFixed(0)};
                }
            }
            return prevState.map((x,i) => ({...x, amount: newAmounts[i], coversToRecalculate: x.coversToRecalculate || newAmounts[i].value !== x.amount.value}));
        })
    }

    const oddsMatchAfterModeChange = (oddsMatch: RolloverOddsMatch, newValue: string) => {
        const selections = [...oddsMatch.selections];
        if(newValue === 'pb') selections[1] = selections[0];
        else if(newValue === 'pp') selections[1] = coverSelectionPuntaPunta.get(selections[0]) ?? '';
        else {
            const covers = coverSelectionsTriscasser.get(selections[0]);
            selections[1] = covers ? covers[0] : '';
            selections[2] = covers ? covers[1] : '';
        }
        oddsMatch.coversToRecalculate = true;
        return {
            ...oddsMatch, 
            mode: newValue, 
            selections, 
            fees: newValue==='pb' ? feesBetfair : null,
            sitesIds: [oddsMatch.sitesIds[0],-1,-1],
            sitesUrls: [oddsMatch.sitesUrls[0],null,null],
            odds: [oddsMatch.odds[0],{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}]
        };
    }

    const handleStage1ModeChange = (index: number, newValue: string) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterModeChange({...editingOddsMatch}, newValue));
        else setStage1Bets(prevState => {
            return prevState.map((x,i) => i===index ? oddsMatchAfterModeChange({...x}, newValue) : x);
        });
    }

    const oddsMatchAfterOddsChange = (oddsMatch: RolloverOddsMatch, index: number, newState: NumberInputState) => {
        return {...oddsMatch, odds: oddsMatch.odds.map((x,i) => i===index ? newState : x), coversToRecalculate: true};
    }

    const handleStage1OddsChange = (i: number, j: number, newState: NumberInputState) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex===i;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterOddsChange({...editingOddsMatch}, j, newState));
        else setStage1Bets(prevState => {
            return prevState.map((x,index) => i===index ? oddsMatchAfterOddsChange({...x}, j, newState) : x);
        });
    }

    //Stage2 bets change
    const handleStage2AmountChange = (index:number, newState: NumberInputState) => {
        if(!initializationCompleted) return;
        setStage2Bets(prevState => {
            const maxAmount: number = (progress?.bonusBalanceIncludingAllBets ?? 0)+0.0001;//Math.min(progress?.bonusBalanceIncludingAllBets ?? 0, progress?.remainingRolloverIncludingAllBets ?? 0);
            const oldAmount = !prevState[index].amount.errorMessage ? parseFloat(prevState[index].amount.value) : 0;
            const newAmount = !newState.errorMessage ? parseFloat(newState.value) : 0;
            return newAmount-oldAmount <= maxAmount ? prevState.map((x,i) => i===index ? {...x, amount: newState, coversToRecalculate: true} : x) : prevState;
        });
    }

    const handleStage2ModeChange = (index: number, newValue: string) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterModeChange({...editingOddsMatch}, newValue));
        else setStage2Bets(prevState => prevState.map((x,i) => i===index ? oddsMatchAfterModeChange({...x}, newValue) : x));
    }

    const handleStage2OddsChange = (i: number, j: number, newState: NumberInputState) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex-10===i;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterOddsChange({...editingOddsMatch}, j, newState));
        else setStage2Bets(prevState => prevState.map((x,index) => i===index ? oddsMatchAfterOddsChange({...x}, j, newState) : x));
    }

    const handleStage1ResultClick = (index: number) => {
        if(!initializationCompleted) return;
        if(stage2Bets.length>0) return;
        if(editingIndex===index) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima confermare o annullare le modifiche fatte' }));
            return;
        }
        const n = stage1Bets[index].mode === 'tri' ? 3 : 2;
        if(stage1Bets[index].odds.some((y,i) => i<n && y.errorMessage)) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima di inserire il risultato inserisci le quote' }));
            return;
        }
        const results = n === 2 ? [null, 'W', 'L'] : [null, 'W', 'L1', 'L2'];
        let result: string|null = null;
        for (let i = 0; i < results.length; i++) {
            if(results[i] === stage1Bets[index].result) result = results[(i+1)%results.length];
        }
        setStage1Bets(prevState => {
            return prevState.map((x,i) => i===index ? {...x, result} : x);
        });
    }

    const handleStage2ResultClick = (index: number) => {
        if(!initializationCompleted) return;
        if(editingIndex-10===index) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima confermare o annullare le modifiche fatte' }));
            return;
        }
        const n = stage2Bets[index].mode === 'tri' ? 3 : 2;
        if(stage2Bets[index].amount.errorMessage || stage2Bets[index].odds.some((y,i) => i<n && y.errorMessage)) {
            dispatch(addSnackbar({ type: 'error', message: "Prima di inserire il risultato inserisci l'importo e le quote" }));
            return;
        }
        const results = n === 2 ? [null, 'W', 'L'] : [null, 'W', 'L1', 'L2'];
        let result: string|null = null;
        for (let i = 0; i < results.length; i++) {
            if(results[i] === stage2Bets[index].result) result = results[(i+1)%results.length];
        }
        setStage2Bets(prevState => prevState.map((x,i) => i===index ? {...x, result} : x));
    }

    const addStage2Bet = () => { 
        if(progress && (progress.bonusBalanceIncludingAllBets<1 || progress.remainingRolloverIncludingAllBets<0.01)) {
            dispatch(addSnackbar({ type: 'error', message: 'Saldo bonus o rollover terminato'}));
            return;
        }
        setStage2Bets(prevState => [...prevState, {
            mode: 'pb',
            amount: {value: '', errorMessage: 'NaN'},
            covered: false,
            result: null,
            event: null,
            eventText: '',
            eventDate: '',
            fees: feesBetfair,
            selections: ['', '', ''],
            sitesIds: [-1, -1, -1],
            sitesUrls: [null, null, null],
            odds: Array.from({length:3}, () => ({value: '', errorMessage: 'NaN'})),
            isOpened: true,
            coversToRecalculate: true
        }]);
    }

    const deleteStage2Bet = (index: number) => {
        setStage2Bets(prevState => prevState.filter((x,i) => i!==index).map(x => ({...x, coversToRecalculate: true})));
    }
    
    const oddsMatchAfterSelectionChange = (oddsMatch: RolloverOddsMatch, oddsIndex: number, newValue: string, index: number) => {
        const mode = oddsMatch.mode;
        const selections = [...oddsMatch.selections];
        if(index>-1) {
            let filteredSelectionsValues = mode==='tri' ? selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text)) : (mode==='pb' ? selectionsValues.filter(x => !['1X','X2','12'].includes(x.text)) : selectionsValues);
            selections[oddsIndex] = filteredSelectionsValues[index].text;
            if(mode === 'pb') selections[1-oddsIndex] = filteredSelectionsValues[index].text;
            else if(oddsIndex === 0){
                if(mode === 'pp') selections[1] = coverSelectionPuntaPunta.get(filteredSelectionsValues[index].text) ?? '';
                else {
                    const covers = coverSelectionsTriscasser.get(filteredSelectionsValues[index].text);
                    selections[1] = covers ? covers[0] : '';
                    selections[2] = covers ? covers[1] : '';
                }
            }
        } else {
            selections[oddsIndex] = newValue;
            if(oddsIndex===0) {
                selections[1] = '';
                selections[2] = '';
            }
        }
        return {...oddsMatch, selections};
    }

    const handleStage1SelectionChange = (index: number, oddsIndex: number, newValue: string, selectionIndex: number) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSelectionChange({...editingOddsMatch}, oddsIndex, newValue, selectionIndex));
        else setStage1Bets(prevState => {
            return prevState.map((x,i) => i===index ? oddsMatchAfterSelectionChange({...x}, oddsIndex, newValue, selectionIndex) : x);
        });
    }

    const handleStage2SelectionChange = (index: number, oddsIndex: number, newValue: string, selectionIndex: number) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSelectionChange({...editingOddsMatch}, oddsIndex, newValue, selectionIndex));
        else setStage2Bets(prevState => prevState.map((x,i) => i===index ? oddsMatchAfterSelectionChange({...x}, oddsIndex, newValue, selectionIndex) : x));
    }

    const oddsMatchAfterSiteChange = (oddsMatch: RolloverOddsMatch, oddsIndex: number, siteIndex: number) => {
        return {...oddsMatch, 
            sitesIds: oddsMatch.sitesIds.map((z,k) => k===oddsIndex ? siteIndex : z), 
            sitesUrls: oddsMatch.sitesUrls.map((z,k) => k===oddsIndex ? null : z),
            fees: oddsMatch.mode==='pb' ? ((oddsIndex===1 ? siteIndex : oddsMatch.sitesIds[1])===9 ? feesBetflag : feesBetfair) : null
        };
    }

    const handleStage1SiteChange = (index: number, oddsIndex: number, siteIndex: number) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSiteChange({...editingOddsMatch}, oddsIndex, siteIndex));
        else setStage1Bets(prevState => {
            return prevState.map((x,i) => i===index ? oddsMatchAfterSiteChange({...x}, oddsIndex, siteIndex) : x);
        });
    }

    const handleStage2SiteChange = (index: number, oddsIndex: number, siteIndex: number) => {
        if(!initializationCompleted) return;
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSiteChange({...editingOddsMatch}, oddsIndex, siteIndex));
        else setStage2Bets(prevState => prevState.map((x,i) => i===index ? oddsMatchAfterSiteChange({...x}, oddsIndex, siteIndex) : x));
    }

    const getRolloverData = () => ({description, siteId, bonus, multiplier, bets, avgRating, stage1Bets, stage2Bets, stage2OddsMin});

    const stage1Search = () => {
        if(!search) return;
        const _multiplier = parseFloat(multiplier.value);
        const _avgRating = parseFloat(avgRating.value);
        const _bets = parseInt(bets.value);
        for (let i = 0; i < _bets; i++) {
            const n = stage1Bets[i].mode === 'tri' ? 3 : 2;
            if(stage1Bets[i].odds.some((y,j) => j<n && y.errorMessage) || i===_bets-1) {
                if(stage1Bets[i].amount.errorMessage) {
                    dispatch(addSnackbar({ type: 'error', message: 'Importo puntata non valido'}));
                    return;
                }
                const bet = parseFloat(stage1Bets[i].amount.value);
                const refund = Math.round((100-_avgRating)*(_multiplier-1)*bet)/100;
                search({
                    bet: bet.toString(), 
                    bonus: '0',
                    refund: refund.toString(),
                    isFreebet: false,
                    sites1: siteId.toString(), 
                    feesBetfair: getItemFromLocalStorage('feesBetfair') ?? '4.5', 
                    feesBetflag: getItemFromLocalStorage('feesBetflag') ?? '5',
                    order: 'rating',
                    page: 0
                })
                break;
            }             
        }
    }

    const stage2Search = () => {
        search && search({
            bet: '100', 
            bonus: '0',
            refund: '0',
            isFreebet: false,
            oddsMin: (stage2OddsMin.value && !stage2OddsMin.errorMessage) ? stage2OddsMin.value : filters?.oddsMin,
            sites1: siteId.toString(),
            feesBetfair: getItemFromLocalStorage('feesBetfair') ?? '4.5', 
            feesBetflag: getItemFromLocalStorage('feesBetflag') ?? '5',
            order: 'rating',
            page: 0
        });
    }

    useEffect(() => {
        if(editingIndex===-1) return;
        const om: RolloverOddsMatch = editingIndex<10 ? stage1Bets[editingIndex] : stage2Bets[editingIndex-10];
        setEditingOddsMatch({
            ...om,
            amount: {...om.amount},
            event: om.event!==null ? {...om.event} : null,
            selections: [...om.selections],
            sitesIds: [...om.sitesIds],
            sitesUrls: [...om.sitesUrls],
            odds: om.odds.map(x => ({...x}))
        });
        const eventId = (om.event || {id: -1}).id;
        const eventText = om.eventText;
        let d = new Date(om.eventDate);
        const eventDate = om.eventDate.length>0
            ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
            : {day: '', month: '', year: '', hour: '', minute: '', errorMessage: 'Invalid date'};
        setEditingEvent({eventId, eventText, eventDate});
    }, [editingIndex]);

    const handleEventInputChange = (newState: EventInputState) => {
        if(!initializationCompleted) return;
        if(editingEvent && editingEvent.eventId !== newState.eventId) resetEditingOdds(); 
        setEditingEvent(newState);
    }

    const resetEditingOdds = () => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            return {
                ...prevState, 
                selections: ['','',''],
                sitesIds: [prevState.sitesIds[0],-1,-1],
                sitesUrls: [null,null,null],
                odds: [{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}],
                coversToRecalculate: true
            };
        });
    }

    const resetStage1Bet = (index: number) => {
        setStage1Bets(prevState => {
            return prevState.map((x,i) => {
                if(i!==index) return x;
                return {
                    ...x, 
                    event: null,
                    eventText: '',
                    eventDate: '',
                    selections: ['','',''],
                    sitesIds: [x.sitesIds[0],-1,-1],
                    sitesUrls: [null,null,null],
                    odds: [{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}],
                    coversToRecalculate: true
                };
            });
        });
    }

    const saveChanges = () => {
        if(editingIndex===-1 || !editingEvent || !editingOddsMatch) return;
        const n = editingOddsMatch.mode === 'tri' ? 3 : 2;
        if(editingEvent.eventDate.errorMessage 
            // || editingEvent.eventText.length === 0
            || editingOddsMatch.sitesIds.some((x,i) => i<n && i>0 && x<0)
            || editingOddsMatch.odds.some((x,i) => i<n && x.errorMessage)
            || editingOddsMatch.selections.some((x,i) => i<n && x.length===0)
        ) {
            dispatch(addSnackbar({ type: 'error', message: 'Sono presenti degli errori o dei dati mancanti'}));
            return;
        }
        (editingIndex < 10 ? setStage1Bets : setStage2Bets)(prevState => {
            return prevState.map((x,i) => {
                if(i!==editingIndex-(editingIndex < 10 ? 0 : 10) || !editingOddsMatch || !editingEvent) return x;
                editingOddsMatch.eventText = editingEvent.eventText;
                if(editingEvent.eventId===-1) editingOddsMatch.event = null;
                else if(editingEvent.eventId !== editingOddsMatch.event?.id) editingOddsMatch.event = events.get(editingEvent.eventId) ?? null;
                const dateString = localeStringToUTCString(`${editingEvent.eventDate.year}-${editingEvent.eventDate.month.padStart(2, '0')}-${editingEvent.eventDate.day.padStart(2, '0')}T${editingEvent.eventDate.hour.padStart(2, '0')}:${editingEvent.eventDate.minute.padStart(2, '0')}`);
                if(editingOddsMatch.eventDate !== dateString) {
                    editingOddsMatch.eventDate = dateString;
                    if(editingOddsMatch.event) editingOddsMatch.event.datetime = dateString;
                }
                if(editingOddsMatch.sitesIds[0] !== siteId) {
                    editingOddsMatch.sitesIds[0] = siteId;
                    editingOddsMatch.sitesUrls[0] = null;
                }
                return editingOddsMatch;
            });
        });
        setEditingIndex(-1);
    }

    const save = async () => {
        const id = getItemFromLocalStorage(rolloverIdStorageKey);
        const data = getRolloverData();
        if(data.description.value.length === 0) {
            data.description = {value: 'Rollover '+formatDate(new Date())};
            setDescription(data.description);
        }
        const _id = await _saveRollover({id: id ? parseInt(id) : null, description: data.description.value, data}, calculators) as number;
        if(!_id) {
            dispatch(addSnackbar({ type: 'error', message: 'Errore nel salvataggio del rollover'}));
            return;
        }
        if(!id) {
            setItemToLocalStorage(rolloverIdStorageKey, _id.toString());
            setRolloverIndex(rolloversList?.length ?? -1);
            dispatch(calculators ? addCalculatorsRollover({id: _id, description: data.description.value, data}) : addRollover({id: _id, description: data.description.value, data}));
        } else {
            const index = rolloversList ? rolloversList.findIndex(x => x.id===_id) : -1;
            setRolloverIndex(index);
            if(index>-1) dispatch(calculators ? updateCalculatorsRollover({id: _id, description: data.description.value, data}) : updateRollover({id: _id, description: data.description.value, data}));//update data
        }
        setToSave(false);
        dispatch(addSnackbar({ type: 'success', message: 'Rollover salvato'}));
    }

    const _delete = async (index: number, id: number|null) => {
        if(!id) return;
        if(!await _deleteRollover(id, calculators)) {
            dispatch(addSnackbar({ type: 'error', message: "Errore nell'eliminazione del rollover"}));
            return;
        }
        if(rolloverIndex===index) reset();
        else if(index<rolloverIndex) setRolloverIndex(prevIndex =>  prevIndex-1);//Adjust rolloverIndex if needed
        dispatch(calculators ? removeCalculatorsRollover(id) : removeRollover(id));
        dispatch(addSnackbar({ type: 'success', message: 'Rollover eliminato'}));
        
    }

    const reset = () => {
        setEditingIndex(-1);
        removeItemFromLocalStorage(rolloverIdStorageKey);
        setRolloverIndex(-1);
        setObjToSessionStorage(rolloverStorageKey, initialData());
        loadFromSessionStorage();
        setToSave(true);
    }

    const duplicate = () => {
        removeItemFromLocalStorage(rolloverIdStorageKey);
        setRolloverIndex(-1);
    }
    
    const open = (checked: boolean[]) => {
        if(!rolloversList) return;
        setEditingIndex(-1);
        const index =  checked.findIndex(Boolean);
        setRolloverIndex(index);
        if(index<0) {
            reset();
            return;
        }
        const id = rolloversList[index].id?.toString();
        if(id) setItemToLocalStorage(rolloverIdStorageKey, id);
        setObjToSessionStorage(rolloverStorageKey, rolloversList[index].data);
        loadFromSessionStorage();
    }

    const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
    const stage1BetsSitePresent = !bets.errorMessage && stage1Bets.some((x, i) => i<parseInt(bets.value) && x.sitesIds[0]>-1);
    const rolloverSite: Site|undefined = siteId === 0 ? otherSite : sites.get(siteId);
    const rolloverSites: Site[] = !stage1BetsSitePresent ? sitesList.filter(site => site.type === 'bookmaker') : [];
    if(!stage1BetsSitePresent) rolloverSites.push(otherSite);
    const rolloverSitesChecked = !stage1BetsSitePresent ? rolloverSites.map(x => x.id===rolloverSite?.id) : [];

    const stage1BetsResults = !bets.errorMessage && stage1Bets.some((x, i) => i<parseInt(bets.value) && x.result!==null);
    const stage1BetsRemaining = !bets.errorMessage ? stage1Bets.filter((x, i) => i<parseInt(bets.value) && x.result===null).length : 0;//stage1Odds.filter((x, i) => i<parseInt(bets.value) && x.some((y,j) => j<(stage1Bets[i].mode==='tri' ? 3 : 2) && y.errorMessage)).length
    const stage1BetsFinished = !bets.errorMessage && stage1Bets.every((x, i) => i>=parseInt(bets.value) || x.result!==null);
    const rolloversChecked = rolloversList ? rolloversList.map((_, i) => i===rolloverIndex) : [];
    const stage1Columns = ['','Punta','Quota',isAppMobile ? 'Tipo' : 'Tipologia','Quota'];
    if(!isAppMobile) stage1Columns.push('Copertura');
    if(!calculators) stage1Columns.push('');
    const stage2Columns = ['','Punta','Quota',isAppMobile ? 'Tipo' : 'Tipologia','Quota'];
    if(!isAppMobile) stage2Columns.push.apply(stage2Columns, ['Copertura', 'Rating']);
    if(!calculators) stage2Columns.push('');
    let filteredSelectionsValues = [...selectionsValues];
    if(editingIndex>-1 && editingIndex<10 && editingOddsMatch && editingOddsMatch.mode==='tri') filteredSelectionsValues = selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text));
    if(editingIndex>-1 && editingIndex<10 && editingOddsMatch && editingOddsMatch.mode==='pb') filteredSelectionsValues = selectionsValues.filter(x => !['1X','X2','12'].includes(x.text));
    if(editingIndex>=10 && editingOddsMatch && editingOddsMatch.mode==='tri') filteredSelectionsValues = selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text));
    if(editingIndex>=10 && editingOddsMatch && editingOddsMatch.mode==='pb') filteredSelectionsValues = selectionsValues.filter(x => !['1X','X2','12'].includes(x.text));
    return(
        <BaseFieldset legend="Rollover" padding={10} fitContent={true} minWidth={(visible && !calculators) ? 1027 : undefined} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' openIcon={!visible} onOpenClick={!visible ? changeVisibility : undefined}>
            {visible && 
                <>
                    <ToolButtons>
                        <ToolButton hideOver={445} width='18px' backgroundColor='#800000' onClick={changeVisibility}><CloseSVG/></ToolButton>
                    </ToolButtons>
                    <ToolButtons changePosition={true}>
                        {rolloverIndex>-1 && <ToolButton backgroundColor='#843500' onClick={duplicate}>Duplica</ToolButton>}
                        {toSave && <ToolButton backgroundColor='rgb(1 79 1)' onClick={save}>Salva</ToolButton>}
                        {(rolloversList && rolloversList.length>0) && 
                            <Dropdown 
                                button={<ToolButton backgroundColor='rgb(114 71 13)'>Apri</ToolButton>}
                                buttonWidth={95}
                                content={
                                    <DropdownContent maxWidth='280px'> 
                                        <SavedElementsContainer>
                                            <CheckboxesGroup maxChecked={1} options={ rolloversList.map((rollover, index) =>  <SavedElementCheckbox checked={index===rolloverIndex} backgroundColor='dodgerblue'>{rollover.data.description.value}<TrashIconSVG title='Elimina rollover' onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {event.stopPropagation(); _delete(index, rollover.id);}}/></SavedElementCheckbox>) } checked={rolloversChecked} onOptionClick={(checked: boolean[]) => open(checked)}/>
                                        </SavedElementsContainer>  
                                    </DropdownContent>
                                }
                                top={23}
                                right={0}
                            />
                        }
                        <ToolButton backgroundColor='#023e74' onClick={reset}>Nuovo</ToolButton>
                        <ToolButton hideUnder={444} width='18px' backgroundColor='#800000' onClick={changeVisibility}><CloseSVG/></ToolButton>
                    </ToolButtons>
                </>
            }
            {visible && 
                <RolloverContainer>
                    <Parameters direction={calculators ? 'column' : 'row'}>
                        <TextInputWithLabel label='Descrizione' value={description.value} allowEmpty={true} onChange={(newState: TextInputState) => setDescription(newState)}/>
                        {(!calculators && rolloverSite && stage1BetsSitePresent) && 
                            <SiteBox checked={true} name={rolloverSite.name} notClickable={true} width={95} height={36} backgroundColor={!rolloverSite ? '#000000a3' : rolloverSite.color}>
                                {(rolloverSite && rolloverSite.id===0) && rolloverSite.name}
                                {(rolloverSite && rolloverSite.id>0) && <SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer>}
                            </SiteBox>
                        }
                        {(!calculators && !stage1BetsSitePresent) &&
                            <Dropdown 
                                buttonText={rolloverSite ? (rolloverSite.id===0 ? rolloverSite.name : undefined) : 'Bookmaker'}
                                button={(rolloverSite && rolloverSite.id>0) 
                                    ? <SiteBox checked={true} name={rolloverSite.name} width={95} height={36}><SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer></SiteBox>
                                    : undefined
                                }
                                buttonColor={rolloverSite ? rolloverSite.color : undefined}
                                buttonWidth={95}
                                content={
                                    <DropdownContent width={175}>   
                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                rolloverSites.map((site, index) => site.id===0 
                                                ? <StandardCheckbox checked={rolloverSitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                : <SiteBox checked={rolloverSitesChecked[index]} name={site.name}><SiteLogoContainer visible={rolloverSitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                            } checked={rolloverSitesChecked} onOptionClick={(checked: boolean[]) => setSiteId(checked.findIndex(Boolean)>-1 ? rolloverSites[checked.findIndex(Boolean)].id : -1)}/>
                                        </SitesCheckboxes>
                                    </DropdownContent>
                                }
                            />
                        }
                        <Parameters>
                            <NumberInput isDisabled={stage1BetsResults} label='Bonus' value={bonus.value} minValue={parseInt(bets.value)} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBonus(newState)}/>
                            <NumberInput isDisabled={stage1BetsResults} label='Rollover' value={multiplier.value} minValue={1} maxValue={20} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setMultiplier(newState)}/>
                        </Parameters>
                        <Parameters>
                            <NumberInput isDisabled={stage1BetsFinished} label='# Puntate' value={bets.value} minValue={1} maxValue={5} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBets(newState)}/>
                            <NumberInput isDisabled={stage1BetsResults} label='Rating Medio' value={avgRating.value} minValue={0} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setAvgRating(newState)}/>
                        </Parameters>
                        {calculators && <NumberInput label='Commissioni' value={fees.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFees(newState)}/>}
                        {(isAdmin && !calculators) && <NumberInput value={stage2OddsMin.value} label='Quota minima' small={false} labelWidth={60} inputWidth={50} minValue={1} allowEmpty={true} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => setStage2OddsMin(newState)}/>}
                    </Parameters>
                    {(!bonus.errorMessage && !multiplier.errorMessage && !bets.errorMessage && !avgRating.errorMessage) &&
                        <>
                            <RolloverStagesContainer>
                                <RolloverStageContainer>
                                    <RolloverStageTitle>
                                        <RolloverStageTitleLabel>Puntate Bonus</RolloverStageTitleLabel>
                                        {!stage1BetsFinished && !calculators &&
                                            <SVGIconContainer size={20} fill={!rolloverSite ? 'gray' : undefined} notClickable={!rolloverSite} title={!rolloverSite ? 'Seleziona il bookmaker' : 'Cerca quote per puntate bonus'}>
                                                <SearchIconSVG onClick={() => rolloverSite && stage1Search()}/>
                                            </SVGIconContainer>
                                        }
                                    </RolloverStageTitle>
                                    {!calculators &&
                                        <RolloverList>
                                            {stage1Bets.map((x, index) => {
                                                if(index >= parseInt(bets.value)) return null;
                                                const editing: boolean = editingIndex===index && !!editingOddsMatch;
                                                const om: RolloverOddsMatch = editing ? {...(editingOddsMatch ?? x), amount: x.amount} : x;
                                                const mode: string = om.mode;
                                                const res = om.result;
                                                const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
                                                const cover1Site: Site|undefined = om.sitesIds[1]===0 ? otherSite : sites.get(om.sitesIds[1]);
                                                const cover1Sites: Site[] = editing ? sitesList.filter(site => site.type === (mode==='pb' ? 'exchange' :'bookmaker')) : [];
                                                if(editing) cover1Sites.push(otherSite);
                                                const cover1SitesChecked = editing ? cover1Sites.map(x => x.id===cover1Site?.id) : [];
                                                const cover2Site: Site|undefined = mode==='tri' ? (om.sitesIds[2]===0 ? otherSite : sites.get(om.sitesIds[2])) : undefined;
                                                const cover2Sites: Site[] = (editing && mode==='tri') ? sitesList.filter(site => site.type === 'bookmaker') : [];
                                                if(editing && mode==='tri') cover2Sites.push(otherSite);
                                                const cover2SitesChecked = (editing && mode==='tri') ? cover2Sites.map(x => x.id===cover2Site?.id) : [];
                                                return (
                                                    <RolloverElement isDisabled={!!res} key={index}>
                                                        <RolloverElementEventRow>
                                                            <Result notClickable={stage2Bets.length>0} title={resultComment(res)} result={res ?? undefined} onClick={() => handleStage1ResultClick(index)}/>
                                                            <NumberInput isDisabled={stage1BetsRemaining<=1 || res!==null}
                                                                value={x.amount.value} 
                                                                inputPlaceholder='Puntata'
                                                                height={24} small={true}
                                                                minValue={1} includeLimits={true} stepFunction={stepIntegers} 
                                                                onChange={(newState: NumberInputState) => handleStage1AmountChange(index, newState)}
                                                            />
                                                            {!editing
                                                                ? <SVGIconContainer notClickable={res!==null || editingIndex>-1}><EditIconSVG title={!res ? 'Modifica' : ''} onClick={() => !res && editingIndex===-1 && setEditingIndex(prevState => editingIndex===index ? -1 : index)}/></SVGIconContainer>
                                                                : <>
                                                                    <SVGIconContainer><CheckmarkSVG title='Salva modifiche' onClick={saveChanges}/></SVGIconContainer>
                                                                    <SVGIconContainer size={13} fill='rgb(255, 66, 66)'><CloseSVG title='Annulla modifiche' onClick={() => setEditingIndex(-1)}/></SVGIconContainer>
                                                                </>
                                                            }
                                                            {(editing && editingEvent)
                                                                ? <EventInput isEventEditable={true} value={editingEvent} height={24} breakPoint={520} onChange={handleEventInputChange}/>
                                                                : <HorizontalScrollContainer hiddenScrollBar={true}><RolloverElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</RolloverElementEvent></HorizontalScrollContainer>
                                                            }
                                                            {(editingIndex===-1 && !res) && <SVGIconContainer><TrashIconSVG title="Resetta campi puntata" onClick={() => resetStage1Bet(index)}/></SVGIconContainer>}
                                                        </RolloverElementEventRow>
                                                        <RolloverElementOddsMatchRow>
                                                            <RolloverElementOdds>
                                                                <RolloverElementCell>
                                                                    {(!stage1BetsSitePresent && editing) ?
                                                                        <Dropdown 
                                                                            buttonText={rolloverSite ? (rolloverSite.id===0 ? rolloverSite.name : undefined) : 'Book'}
                                                                            button={(rolloverSite && rolloverSite.id>0) 
                                                                                ? <SiteBox checked={true} name={rolloverSite.name} width={60} height={36}><SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                : undefined
                                                                            }
                                                                            buttonColor={rolloverSite ? rolloverSite.color : undefined}
                                                                            buttonWidth={60}
                                                                            content={
                                                                                <DropdownContent width={175}>   
                                                                                    <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                            rolloverSites.map((site, index) => site.id===0 
                                                                                            ? <StandardCheckbox checked={rolloverSitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                            : <SiteBox checked={rolloverSitesChecked[index]} name={site.name}><SiteLogoContainer visible={rolloverSitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                        } checked={rolloverSitesChecked} onOptionClick={(checked: boolean[]) => setSiteId(checked.findIndex(Boolean)>-1 ? rolloverSites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                    </SitesCheckboxes>
                                                                                </DropdownContent>
                                                                            }
                                                                        />
                                                                        :
                                                                        <SiteBox 
                                                                            checked={!!rolloverSite} 
                                                                            notClickable={!(rolloverSite && om.sitesUrls[0] && copyToClipboard)}
                                                                            name={rolloverSite?.name ?? ''} 
                                                                            width={60}
                                                                            height={36}
                                                                            backgroundColor={!rolloverSite ? '#000000a3' : rolloverSite.color}
                                                                            onClick={() => rolloverSite && om.sitesUrls[0] && copyToClipboard && copyToClipboard(om.sitesUrls[0], `URL ${rolloverSite.name} copiato`)}
                                                                        >
                                                                            {(rolloverSite && rolloverSite.id===0) && rolloverSite.name}
                                                                            {(rolloverSite && rolloverSite.id>0) && <SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer>}
                                                                        </SiteBox>
                                                                    }
                                                                </RolloverElementCell>
                                                                <NumberInput isDisabled={res!==null} 
                                                                    value={om.odds.length>0 ? om.odds[0].value : ''} 
                                                                    inputPlaceholder='Quota'
                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                    onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 0, newState)}
                                                                />
                                                                <RolloverElementCell>
                                                                    {!editing
                                                                        ? <RolloverElementOddsSelection title='Esito'>{om.selections[0]}</RolloverElementOddsSelection>
                                                                        :   <TextInputWithAutocomplete 
                                                                                value={om.selections[0]} 
                                                                                possibleValues={filteredSelectionsValues} 
                                                                                inputPlaceholder='Esito'
                                                                                inputWidth={'60px'}
                                                                                height='100%'
                                                                                fontSize={11}
                                                                                allowInvalid={true}
                                                                                emptyIsInvalid={true}
                                                                                onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 0, newValue, selectionIndex)}
                                                                            />
                                                                            
                                                                    }
                                                                </RolloverElementCell>
                                                            </RolloverElementOdds>
                                                            <RolloverElementOddsCoversContainer>
                                                                <RolloverElementOddsCovers>
                                                                    {editing &&
                                                                        <Select name={"select-type"} value={mode} width={isAppMobile ? '43px' : '105px'} height='36px' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage1ModeChange(index, event.target.value)}>
                                                                            <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                            <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                            <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                        </Select>
                                                                    }
                                                                    <RolloverElementOdds>
                                                                        <RolloverElementCell>
                                                                            {!editing && 
                                                                                <SiteBox 
                                                                                    checked={!!cover1Site} 
                                                                                    notClickable={!(cover1Site && om.sitesUrls[1] && copyToClipboard)}
                                                                                    name={cover1Site?.name ?? ''} 
                                                                                    width={60}
                                                                                    backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                                    onClick={() => cover1Site && om.sitesUrls[1] && copyToClipboard && copyToClipboard(om.sitesUrls[1], `URL ${cover1Site.name} copiato`)}
                                                                                >
                                                                                    {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                                    {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                                </SiteBox>
                                                                            }
                                                                            {editing &&                                                   
                                                                                <Dropdown 
                                                                                    buttonText={cover1Site ? (cover1Site.id===0 ? cover1Site.name : undefined) : (mode==='pb' ? 'Exch' : 'Book')}
                                                                                    button={(cover1Site && cover1Site.id>0) 
                                                                                        ? <SiteBox checked={true} name={cover1Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                        : undefined
                                                                                    }
                                                                                    buttonColor={cover1Site ? cover1Site.color : undefined}
                                                                                    buttonWidth={60}
                                                                                    buttonHeight='100%'
                                                                                    content={
                                                                                        <DropdownContent width={175}>   
                                                                                            <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                    cover1Sites.map((site, index) => site.id===0 
                                                                                                    ? <StandardCheckbox checked={cover1SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                    : <SiteBox checked={cover1SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover1SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                } checked={cover1SitesChecked} onOptionClick={(checked: boolean[]) => handleStage1SiteChange(index, 1, checked.findIndex(Boolean)>-1 ? cover1Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                            </SitesCheckboxes>
                                                                                        </DropdownContent>
                                                                                    }
                                                                                />
                                                                            }
                                                                        </RolloverElementCell> 
                                                                        <NumberInput isDisabled={!!res} 
                                                                            value={om.odds.length>0 ? om.odds[1].value : ''} 
                                                                            inputPlaceholder='Quota'
                                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                            onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 1, newState)}
                                                                        />
                                                                        <RolloverElementCell>
                                                                            {!editing
                                                                                ? <RolloverElementOddsSelection title='Esito'>{om.selections[1]}</RolloverElementOddsSelection>
                                                                                :   <TextInputWithAutocomplete 
                                                                                        value={om.selections[1]} 
                                                                                        possibleValues={filteredSelectionsValues} 
                                                                                        inputPlaceholder='Esito'
                                                                                        inputWidth={'60px'}
                                                                                        height='100%' 
                                                                                        fontSize={11}
                                                                                        allowInvalid={true}
                                                                                        emptyIsInvalid={true}
                                                                                        onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 1, newValue, selectionIndex)}
                                                                                    />
                                                                            }
                                                                        </RolloverElementCell>   
                                                                    </RolloverElementOdds>
                                                                    {mode==='tri' &&
                                                                        <RolloverElementOdds>
                                                                            <RolloverElementCell>
                                                                                {!editing && 
                                                                                    <SiteBox 
                                                                                        checked={!!cover2Site} 
                                                                                        notClickable={!(cover2Site && om.sitesUrls[2] && copyToClipboard)}
                                                                                        name={cover2Site?.name ?? ''} 
                                                                                        width={60}
                                                                                        backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                                        onClick={() => cover2Site && om.sitesUrls[2] && copyToClipboard && copyToClipboard(om.sitesUrls[2], `URL ${cover2Site.name} copiato`)}
                                                                                    >
                                                                                        {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                                        {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                                    </SiteBox>
                                                                                }
                                                                                {editing &&
                                                                                    <Dropdown 
                                                                                    buttonText={cover2Site ? (cover2Site.id===0 ? cover2Site.name : undefined) : 'Book'}
                                                                                        button={(cover2Site && cover2Site.id>0) 
                                                                                            ? <SiteBox checked={true} name={cover2Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer></SiteBox> 
                                                                                            : undefined
                                                                                        }
                                                                                        buttonColor={cover2Site ? cover2Site.color : undefined}
                                                                                        buttonWidth={60}
                                                                                        buttonHeight='100%'
                                                                                        content={
                                                                                            <DropdownContent width={175}>   
                                                                                                <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                        cover2Sites.map((site, index) => site.id===0 
                                                                                                        ? <StandardCheckbox checked={cover2SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                        : <SiteBox checked={cover2SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover2SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                    } checked={cover2SitesChecked} onOptionClick={(checked: boolean[]) => handleStage1SiteChange(index, 2, checked.findIndex(Boolean)>-1 ? cover2Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                </SitesCheckboxes>
                                                                                            </DropdownContent>
                                                                                        }
                                                                                    />
                                                                                }
                                                                            </RolloverElementCell>
                                                                            <NumberInput isDisabled={!!res} 
                                                                                value={om.odds.length>0 ? om.odds[2].value : ''} 
                                                                                inputPlaceholder='Quota'
                                                                                minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                                onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 2, newState)}
                                                                            />
                                                                            <RolloverElementCell>
                                                                                {!editing
                                                                                    ? <RolloverElementOddsSelection title='Esito'>{om.selections[2]}</RolloverElementOddsSelection>
                                                                                    :   <TextInputWithAutocomplete 
                                                                                            value={om.selections[2]} 
                                                                                            possibleValues={filteredSelectionsValues} 
                                                                                            inputPlaceholder='Esito'
                                                                                            inputWidth={'60px'}
                                                                                            height='100%' 
                                                                                            fontSize={11}
                                                                                            allowInvalid={true}
                                                                                            emptyIsInvalid={true}
                                                                                            onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 2, newValue, selectionIndex)}
                                                                                        />
                                                                                }
                                                                            </RolloverElementCell>
                                                                        </RolloverElementOdds>
                                                                    }
                                                                </RolloverElementOddsCovers>
                                                            </RolloverElementOddsCoversContainer>
                                                        </RolloverElementOddsMatchRow>
                                                        {(res===null && !editing) &&
                                                            <CoverInstructionsContainer>
                                                                {
                                                                    stage1Covers[index].covers.map((c,i) => {
                                                                        const site = i===1 ? cover1Site : cover2Site;
                                                                        if(i===0 || !site) return null;
                                                                        return(
                                                                            <CoverInstructions key={'c'+i}>
                                                                                    <SiteBox 
                                                                                    checked={!!site} 
                                                                                    notClickable={!(site && om.sitesUrls[i] && copyToClipboard)}
                                                                                    name={site?.name ?? ''} 
                                                                                    width={60}
                                                                                    height={36}
                                                                                    backgroundColor={!site ? '#000000a3' : site.color}
                                                                                    onClick={() => site && om.sitesUrls[i] && copyToClipboard && copyToClipboard(om.sitesUrls[i], `URL ${site.name} copiato`)}
                                                                                >
                                                                                    {(site && site.id===0) && site.name}
                                                                                    {(site && site.id>0) && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                                                </SiteBox>
                                                                                <CoverInstructionsLabelContainer withSite={!calculators} backgroundColor='#323b42'>
                                                                                    <HorizontalScrollContainer hiddenScrollBar={true}>
                                                                                        <CoverInstructionsLabel>
                                                                                            <CoverInstructionsText>{mode==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                                            <BetContainer>
                                                                                                <Bet translateY={mode==='pb' ? 5 : 0}>{'€'+(c.bet ? c.bet.toFixed(mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                {mode==='pb' && <Resp title='Responsabilità' translateY={1}>{'€'+(c.resp ? c.resp.toFixed(2) : '-')}</Resp>}
                                                                                            </BetContainer>
                                                                                            {!calculators &&
                                                                                                <>
                                                                                                    <CoverInstructionsText>esito</CoverInstructionsText>
                                                                                                    <CoverInstructionsStrongText>{om.selections[i]}</CoverInstructionsStrongText> 
                                                                                                </>
                                                                                            }
                                                                                            <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                                            <CoverInstructionsStrongText>{om.odds[i].errorMessage ? '-' : parseFloat(om.odds[i].value).toFixed(2)}</CoverInstructionsStrongText>
                                                                                        </CoverInstructionsLabel>
                                                                                    </HorizontalScrollContainer>
                                                                                </CoverInstructionsLabelContainer>
                                                                            </CoverInstructions>
                                                                        );
                                                                    })
                                                                }
                                                            </CoverInstructionsContainer> 
                                                        }
                                                    </RolloverElement> 
                                                );
                                            })}
                                        </RolloverList>
                                    }
                                    {calculators &&
                                        <BetsTable>
                                            <BetsTableHead>
                                                <BetsTableRow>{stage1Columns.map((col, index) => <BetsTableHeaderCell key={index}>{col}</BetsTableHeaderCell>)}</BetsTableRow>
                                            </BetsTableHead>
                                            <BetsTableBody>
                                                {stage1Bets.map((om, i) => {
                                                    if(i >= parseInt(bets.value)) return null;
                                                    return(
                                                        <Fragment key={i}>
                                                            <BetsTableRow showSvg={true}>
                                                                <BetsTableCell><Result notClickable={stage2Bets.length>0} title={resultComment(om.result)} result={om.result ?? undefined} onClick={() => handleStage1ResultClick(i)}/></BetsTableCell>
                                                                <BetsTableCell><NumberInput value={stage1Bets[i].amount.value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60} isDisabled={stage1BetsRemaining<=1 || om.result!==null} minValue={1} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleStage1AmountChange(i, newState)}/></BetsTableCell>
                                                                <BetsTableCell><NumberInput value={stage1Bets[i].odds[0].value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60} isDisabled={om.result!==null} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => handleStage1OddsChange(i, 0, newState)}/></BetsTableCell>
                                                                <BetsTableCell>
                                                                    <Select name={"select-type-"+i} value={om.mode} disabled={om.result!==null} width={isAppMobile ? '43px' : '105px'} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage1ModeChange(i, event.target.value)}>
                                                                        <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                        <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                        <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                    </Select>
                                                                </BetsTableCell>
                                                                <BetsTableCell>
                                                                    <CoverOdds>
                                                                        {stage1Bets[i].odds.map((x, j) => {
                                                                            if(j>0 && (j<2 || om.mode==='tri'))
                                                                            return <NumberInput key={'I'+j} value={x.value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60} isDisabled={om.result!==null} minValue={1} includeLimits={false} decimals={2} stepFunction={(j===1 && om.mode==='pb') ? stepOdds : stepOddsPP} onChange={(newState: NumberInputState) => handleStage1OddsChange(i, j, newState)}/>
                                                                            return null;
                                                                        })}
                                                                    </CoverOdds>
                                                                </BetsTableCell>
                                                                {!isAppMobile && 
                                                                    <BetsTableCell>
                                                                        <CoverBets isDisabled={om.result!==null} boxed={true} height={36}>
                                                                            <Action>{om.mode==='pb' ? 'Banca' : 'Punta'}</Action>
                                                                            <CoverBetsContainer>
                                                                                <BetContainer>
                                                                                    <Bet translateY={om.mode==='pb' ? 5 : 0}>{'€'+(stage1Covers[i].covers[1].bet ? stage1Covers[i].covers[1].bet?.toFixed(om.mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                    {om.mode==='pb' && <Resp title='Responsabilità' translateY={2}>{'€'+(stage1Covers[i].covers[1].resp ? stage1Covers[i].covers[1].resp?.toFixed(2) : '-')}</Resp>}
                                                                                </BetContainer>
                                                                                {om.mode==='tri' && <BetContainer><Bet>{'€'+(stage1Covers[i].covers[2].bet ? stage1Covers[i].covers[2].bet?.toFixed(0) : '-')}</Bet></BetContainer>}
                                                                            </CoverBetsContainer>
                                                                        </CoverBets>
                                                                    </BetsTableCell>
                                                                }
                                                            </BetsTableRow>
                                                            {isAppMobile && 
                                                                <BetsTableRow>
                                                                    <BetsTableSingleDivCell colSpan={stage1Columns.length} paddingBottom={isAppMobile ? '5px' : undefined}>
                                                                        <CoverBets isDisabled={om.result!==null} boxed={true} height={24}>
                                                                            <Action>{om.mode==='pb' ? 'Banca' : 'Punta'}</Action>
                                                                            <CoverBetsContainer>
                                                                                <BetContainer flexDirection='row'>
                                                                                    <Bet>{'€'+(stage1Covers[i].covers[1].bet ? stage1Covers[i].covers[1].bet?.toFixed(om.mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                    {om.mode==='pb' && <Resp title='Responsabilità'>{'€'+(stage1Covers[i].covers[1].resp ? stage1Covers[i].covers[1].resp?.toFixed(2) : '-')}</Resp>}
                                                                                </BetContainer>
                                                                                {om.mode==='tri' && <BetContainer flexDirection='row'><Bet>{'€'+(stage1Covers[i].covers[2].bet ? stage1Covers[i].covers[2].bet?.toFixed(0) : '-')}</Bet></BetContainer>}
                                                                            </CoverBetsContainer>
                                                                        </CoverBets>
                                                                    </BetsTableSingleDivCell>
                                                                </BetsTableRow>
                                                            }
                                                        </Fragment>
                                                    )
                                                })}
                                            </BetsTableBody>
                                        </BetsTable>
                                    }
                                </RolloverStageContainer>
                                {(stage1BetsFinished && progress && (stage2Bets.length>0 || progress.bonusBalance>0.99)) &&
                                    <RolloverStageContainer isDisabled={!stage1BetsFinished}>
                                        <RolloverStageTitle>
                                            <RolloverStageTitleLabel>Completamento Rollover</RolloverStageTitleLabel>
                                            {(!calculators && rolloverSite) && <NumberInput value={stage2OddsMin.value} label='Quota minima' small={true} height={24} labelWidth={50} labelFontSize={10} inputWidth={50} minValue={1} allowEmpty={true} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => setStage2OddsMin(newState)}/>}
                                            {(!calculators && rolloverSite) && <SVGIconContainer><SearchIconSVG title='Cerca quote per completamento rollover' onClick={stage2Search}/></SVGIconContainer>}
                                        </RolloverStageTitle>
                                        <RolloverStage2Info>
                                            <RolloverInfoElement><RolloverInfoLabel>Saldo bonus</RolloverInfoLabel><RolloverInfoValue>€{progress.bonusBalance.toFixed(2)}</RolloverInfoValue></RolloverInfoElement>
                                            <RolloverInfoElement><RolloverInfoLabel>Rollover residuo</RolloverInfoLabel><RolloverInfoValue>€{progress.remainingRollover.toFixed(2)}</RolloverInfoValue></RolloverInfoElement>
                                        </RolloverStage2Info>
                                        {stage2Bets.length>0 &&
                                            <>
                                                {calculators && 
                                                    <BetsTable>
                                                        <BetsTableHead>
                                                            <BetsTableRow>{stage2Columns.map((col, index) => <BetsTableHeaderCell key={index}>{col}</BetsTableHeaderCell>)}</BetsTableRow>
                                                        </BetsTableHead>
                                                        <BetsTableBody>
                                                            {stage2Bets.map((om, i) => {
                                                                const resultIndex = (om.mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2']).findIndex(x => om.result===x);
                                                                const winnings: number|undefined = stage2Covers.length>i ? (resultIndex>-1 ? stage2Covers[i].covers[resultIndex].winnings : stage2Covers[i].avgWinnings) : undefined;
                                                                const ratings: number|undefined = stage2Covers.length>i ? (resultIndex>-1 ? stage2Covers[i].covers[resultIndex].rating : stage2Covers[i].avgRating) : undefined;
                                                                return(
                                                                    <Fragment key={i}>
                                                                        <BetsTableRow showSvg={true}>
                                                                            <BetsTableCell><Result title={resultComment(om.result)} result={om.result ?? undefined} onClick={() => handleStage2ResultClick(i)}/></BetsTableCell>
                                                                            <BetsTableCell><NumberInput value={om.amount.value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60} isDisabled={om.result!==null} minValue={1} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleStage2AmountChange(i, newState)}/></BetsTableCell>
                                                                            <BetsTableCell><NumberInput value={om.odds[0].value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60} isDisabled={om.result!==null} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => handleStage2OddsChange(i, 0, newState)}/></BetsTableCell>
                                                                            <BetsTableCell>
                                                                                <Select name={"select-type-"+i} value={om.mode} disabled={om.result!==null} width={isAppMobile ? '43px' : '105px'} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage2ModeChange(i, event.target.value)}>
                                                                                    <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                                    <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                                    <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                                </Select>
                                                                            </BetsTableCell>
                                                                            <BetsTableCell>
                                                                                <CoverOdds>
                                                                                    {om.odds.map((x, j) => {
                                                                                        if(j>0 && (j<2 || om.mode==='tri'))
                                                                                        return <NumberInput key={'I'+j} value={x.value} small={isAppMobile} inputWidth={isAppMobile ?  55 : 60}isDisabled={om.result!==null} minValue={1} includeLimits={false} decimals={2} stepFunction={(j===1 && om.mode==='pb') ? stepOdds : stepOddsPP} onChange={(newState: NumberInputState) => handleStage2OddsChange(i, j, newState)}/>
                                                                                        return null;
                                                                                    })}
                                                                                </CoverOdds>
                                                                            </BetsTableCell>
                                                                            {!isAppMobile && 
                                                                                <BetsTableCell>
                                                                                    <CoverBets isDisabled={om.result!==null} boxed={true} height={36}>
                                                                                        <Action>{om.mode==='pb' ? 'Banca' : 'Punta'}</Action>
                                                                                        <CoverBetsContainer>
                                                                                            <BetContainer>
                                                                                                <Bet translateY={om.mode==='pb' ? 5 : 0}>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[1].bet) ? stage2Covers[i].covers[1].bet?.toFixed(om.mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                {om.mode==='pb' && <Resp title='Responsabilità' translateY={2}>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[1].resp) ? stage2Covers[i].covers[1].resp?.toFixed(2) : '-')}</Resp>}
                                                                                            </BetContainer>
                                                                                            {om.mode==='tri' && <BetContainer><Bet>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[2].bet) ? stage2Covers[i].covers[2].bet?.toFixed(0) : '-')}</Bet></BetContainer>}
                                                                                        </CoverBetsContainer>
                                                                                    </CoverBets>
                                                                                </BetsTableCell>
                                                                            }
                                                                            {!isAppMobile &&                                                            
                                                                                <BetsTableCell>
                                                                                    <WinnigsAndRating flexDirection='column' boxed={false} padding='0 5px'>
                                                                                        <Winnings translateY={5} amount={winnings!==undefined ? winnings : 0}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Winnings>
                                                                                        <Rating translateY={2}>{ratings!==undefined ? ratings.toFixed(2) : '-'}%</Rating>
                                                                                    </WinnigsAndRating>
                                                                                </BetsTableCell>
                                                                            }
                                                                        </BetsTableRow>
                                                                        {isAppMobile && 
                                                                            <BetsTableRow>
                                                                                <BetsTableSingleDivCell colSpan={stage2Columns.length} paddingBottom={isAppMobile ? '5px' : undefined}>
                                                                                    <CoverBetsAndRatingContainer marginBottom={5}>
                                                                                        <CoverBets isDisabled={om.result!==null} boxed={true} height={24}>
                                                                                            <Action>{om.mode==='pb' ? 'Banca' : 'Punta'}</Action>
                                                                                            <CoverBetsContainer>
                                                                                                <BetContainer flexDirection='row'>
                                                                                                    <Bet>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[1].bet) ? stage2Covers[i].covers[1].bet?.toFixed(om.mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                    {om.mode==='pb' && <Resp title='Responsabilità'>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[1].resp) ? stage2Covers[i].covers[1].resp?.toFixed(2) : '-')}</Resp>}
                                                                                                </BetContainer>
                                                                                                {om.mode==='tri' && <BetContainer flexDirection='row'><Bet>{'€'+((stage2Covers.length>i && stage2Covers[i].covers[2].bet) ? stage2Covers[i].covers[2].bet?.toFixed(0) : '-')}</Bet></BetContainer>}
                                                                                            </CoverBetsContainer>
                                                                                        </CoverBets>
                                                                                        <WinnigsAndRating boxed={false} padding='0 5px'>
                                                                                            <Winnings amount={winnings!==undefined ? winnings : 0}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Winnings>
                                                                                            <Rating>{ratings!==undefined ? ratings.toFixed(2) : '-'}%</Rating>
                                                                                        </WinnigsAndRating>
                                                                                    </CoverBetsAndRatingContainer>
                                                                                </BetsTableSingleDivCell>
                                                                            </BetsTableRow>
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })}
                                                            {(progress?.remainingRolloverIncludingAllBets ?? 0)<-0.001 && 
                                                                <BetsTableRow showSvg={true}>
                                                                    <BetsTableSingleDivCell colSpan={stage2Columns.length} paddingBottom={isAppMobile ? '5px' : undefined}>
                                                                        <OverRolloverWarning>
                                                                            <WarningIconsContainer>
                                                                                {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                                                <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                                            </WarningIconsContainer>
                                                                            <OverRolloverWarningText>Stai Puntando più del Necessario</OverRolloverWarningText>
                                                                            <WarningIconsContainer>
                                                                                {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                                                <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                                            </WarningIconsContainer>
                                                                        </OverRolloverWarning>
                                                                    </BetsTableSingleDivCell>
                                                                </BetsTableRow>
                                                            }
                                                        </BetsTableBody>
                                                    </BetsTable>
                                                }
                                                {!calculators &&
                                                    <RolloverList>
                                                        {stage2Bets.map((x, index) => {
                                                            const editing: boolean = editingIndex-10===index && !!editingOddsMatch;
                                                            const om: RolloverOddsMatch = editing ? {...(editingOddsMatch ?? x), amount: x.amount} : x;
                                                            const mode: string = om.mode;
                                                            const res = om.result;
                                                            const resultIndex = (om.mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2']).findIndex(x => res===x);
                                                            const winnings: number|undefined = stage2Covers.length>index ? (resultIndex>-1 ? stage2Covers[index].covers[resultIndex].winnings : stage2Covers[index].avgWinnings) : undefined;
                                                            const ratings: number|undefined = stage2Covers.length>index ? (resultIndex>-1 ? stage2Covers[index].covers[resultIndex].rating : stage2Covers[index].avgRating) : undefined;
                                                            const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
                                                            const cover1Site: Site|undefined = om.sitesIds[1]===0 ? otherSite : sites.get(om.sitesIds[1]);
                                                            const cover1Sites: Site[] = editing ? sitesList.filter(site => site.type === (mode==='pb' ? 'exchange' :'bookmaker')) : [];
                                                            if(editing) cover1Sites.push(otherSite);
                                                            const cover1SitesChecked = editing ? cover1Sites.map(x => x.id===cover1Site?.id) : [];
                                                            const cover2Site: Site|undefined = mode==='tri' ? (om.sitesIds[2]===0 ? otherSite : sites.get(om.sitesIds[2])) : undefined;
                                                            const cover2Sites: Site[] = (editing && mode==='tri') ? sitesList.filter(site => site.type === 'bookmaker') : [];
                                                            if(editing && mode==='tri') cover2Sites.push(otherSite);
                                                            const cover2SitesChecked = (editing && mode==='tri') ? cover2Sites.map(x => x.id===cover2Site?.id) : [];
                                                            return (
                                                                <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'center'}>
                                                                    <RolloverElement isDisabled={!!res}>
                                                                        <RolloverElementEventRow>
                                                                            <Result title={resultComment(res)} result={res ?? undefined} onClick={() => handleStage2ResultClick(index)}/>
                                                                            <NumberInput isDisabled={res!==null}
                                                                                value={x.amount.value} 
                                                                                inputPlaceholder='Puntata'
                                                                                height={24} small={true}
                                                                                minValue={1} includeLimits={true} stepFunction={stepIntegers} 
                                                                                onChange={(newState: NumberInputState) => handleStage2AmountChange(index, newState)}
                                                                            />
                                                                            {!editing
                                                                                ? <SVGIconContainer notClickable={res!==null || editingIndex>-1}><EditIconSVG title={!res ? 'Modifica' : ''} onClick={() => !res && editingIndex===-1 && setEditingIndex(prevState => editingIndex-10===index ? -1 : index+10)}/></SVGIconContainer>
                                                                                : <>
                                                                                    <SVGIconContainer><CheckmarkSVG title='Salva modifiche' onClick={saveChanges}/></SVGIconContainer>
                                                                                    <SVGIconContainer size={13} fill='rgb(255, 66, 66)'><CloseSVG title='Annulla modifiche' onClick={() => setEditingIndex(-1)}/></SVGIconContainer>
                                                                                </>
                                                                            }
                                                                            {(editing && editingEvent)
                                                                                ? <EventInput isEventEditable={true} value={editingEvent} height={24} breakPoint={520} onChange={handleEventInputChange}/>
                                                                                : <HorizontalScrollContainer hiddenScrollBar={true}><RolloverElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</RolloverElementEvent></HorizontalScrollContainer>
                                                                            }
                                                                            {(editingIndex===-1 && !res) && <SVGIconContainer><TrashIconSVG title="Elimina puntata" onClick={() => deleteStage2Bet(index)}/></SVGIconContainer>}
                                                                        </RolloverElementEventRow>
                                                                        <RolloverElementOddsMatchRow>
                                                                            <RolloverElementOdds>
                                                                            <RolloverElementCell>
                                                                                <SiteBox 
                                                                                    checked={!!rolloverSite} 
                                                                                    notClickable={!(rolloverSite && om.sitesUrls[0] && copyToClipboard)}
                                                                                    name={rolloverSite?.name ?? ''} 
                                                                                    width={60}
                                                                                    backgroundColor={!rolloverSite ? '#000000a3' : rolloverSite.color}
                                                                                    onClick={() => rolloverSite && om.sitesUrls[0] && copyToClipboard && copyToClipboard(om.sitesUrls[0], `URL ${rolloverSite.name} copiato`)}
                                                                                >
                                                                                    {rolloverSite && <SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer>}
                                                                                </SiteBox>
                                                                            </RolloverElementCell>
                                                                                <NumberInput isDisabled={res!==null} 
                                                                                    value={om.odds.length>0 ? om.odds[0].value : ''} 
                                                                                    inputPlaceholder='Quota'
                                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                                    onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 0, newState)}
                                                                                />
                                                                                <RolloverElementCell>
                                                                                    {!editing
                                                                                        ? <RolloverElementOddsSelection title='Esito'>{om.selections[0]}</RolloverElementOddsSelection>
                                                                                        :   <TextInputWithAutocomplete 
                                                                                                value={om.selections[0]} 
                                                                                                possibleValues={filteredSelectionsValues} 
                                                                                                inputPlaceholder='Esito'
                                                                                                inputWidth={'60px'}
                                                                                                height='100%'
                                                                                                fontSize={11}
                                                                                                allowInvalid={true}
                                                                                                emptyIsInvalid={true}
                                                                                                onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 0, newValue, selectionIndex)}
                                                                                            />
                                                                                            
                                                                                    }
                                                                                </RolloverElementCell>
                                                                            </RolloverElementOdds>
                                                                            <RolloverElementOddsCoversContainer>
                                                                                <RolloverElementOddsCovers>
                                                                                    {editing &&
                                                                                        <Select name={"select-type"} value={mode} width={isAppMobile ? '43px' : '105px'} height='36px' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage2ModeChange(index, event.target.value)}>
                                                                                            <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                                            <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                                            <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                                        </Select>
                                                                                    }
                                                                                    <RolloverElementOdds>
                                                                                        <RolloverElementCell>
                                                                                            {!editing && 
                                                                                                <SiteBox 
                                                                                                    checked={!!cover1Site} 
                                                                                                    notClickable={!(cover1Site && om.sitesUrls[1] && copyToClipboard)}
                                                                                                    name={cover1Site?.name ?? ''} 
                                                                                                    width={60}
                                                                                                    backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                                                    onClick={() => cover1Site && om.sitesUrls[1] && copyToClipboard && copyToClipboard(om.sitesUrls[1], `URL ${cover1Site.name} copiato`)}
                                                                                                >
                                                                                                    {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                                                    {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                                                </SiteBox>
                                                                                            }
                                                                                            {editing &&                                                   
                                                                                                <Dropdown 
                                                                                                buttonText={cover1Site ? (cover1Site.id===0 ? cover1Site.name : undefined) : (mode==='pb' ? 'Exch' : 'Book')}
                                                                                                    button={(cover1Site && cover1Site.id>0) 
                                                                                                        ? <SiteBox checked={true} name={cover1Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                                        : undefined
                                                                                                    }
                                                                                                    buttonColor={cover1Site ? cover1Site.color : undefined}
                                                                                                    buttonWidth={60}
                                                                                                    buttonHeight='100%'
                                                                                                    content={
                                                                                                        <DropdownContent width={175}>   
                                                                                                            <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                                    cover1Sites.map((site, index) => site.id===0 
                                                                                                                    ? <StandardCheckbox checked={cover1SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                                    : <SiteBox checked={cover1SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover1SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                                } checked={cover1SitesChecked} onOptionClick={(checked: boolean[]) => handleStage2SiteChange(index, 1, checked.findIndex(Boolean)>-1 ? cover1Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                            </SitesCheckboxes>
                                                                                                        </DropdownContent>
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        </RolloverElementCell> 
                                                                                        <NumberInput isDisabled={!!res} 
                                                                                            value={om.odds.length>0 ? om.odds[1].value : ''} 
                                                                                            inputPlaceholder='Quota'
                                                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={om.mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                                            onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 1, newState)}
                                                                                        />
                                                                                        <RolloverElementCell>
                                                                                            {!editing
                                                                                                ? <RolloverElementOddsSelection title='Esito'>{om.selections[1]}</RolloverElementOddsSelection>
                                                                                                :   <TextInputWithAutocomplete 
                                                                                                        value={om.selections[1]} 
                                                                                                        possibleValues={filteredSelectionsValues} 
                                                                                                        inputPlaceholder='Esito'
                                                                                                        inputWidth={'60px'}
                                                                                                        height='100%' 
                                                                                                        fontSize={11}
                                                                                                        allowInvalid={true}
                                                                                                        emptyIsInvalid={true}
                                                                                                        onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 1, newValue, selectionIndex)}
                                                                                                    />
                                                                                            }
                                                                                        </RolloverElementCell>   
                                                                                    </RolloverElementOdds>
                                                                                    {mode==='tri' &&
                                                                                        <RolloverElementOdds>
                                                                                            <RolloverElementCell>
                                                                                                {!editing && 
                                                                                                    <SiteBox 
                                                                                                        checked={!!cover2Site} 
                                                                                                        notClickable={!(cover2Site && om.sitesUrls[2] && copyToClipboard)}
                                                                                                        name={cover2Site?.name ?? ''} 
                                                                                                        width={60}
                                                                                                        backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                                                        onClick={() => cover2Site && om.sitesUrls[2] && copyToClipboard && copyToClipboard(om.sitesUrls[2], `URL ${cover2Site.name} copiato`)}
                                                                                                    >
                                                                                                        {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                                                        {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                                                    </SiteBox>
                                                                                                }
                                                                                                {editing &&
                                                                                                    <Dropdown 
                                                                                                        buttonText={cover2Site ? (cover2Site.id===0 ? cover2Site.name : undefined) : 'Book'}
                                                                                                        button={(cover2Site && cover2Site.id>0) 
                                                                                                            ? <SiteBox checked={true} name={cover2Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer></SiteBox> 
                                                                                                            : undefined
                                                                                                        }
                                                                                                        buttonColor={cover2Site ? cover2Site.color : undefined}
                                                                                                        buttonWidth={60}
                                                                                                        buttonHeight='100%'
                                                                                                        content={
                                                                                                            <DropdownContent width={175}>   
                                                                                                                <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                                        cover2Sites.map((site, index) => site.id===0 
                                                                                                                        ? <StandardCheckbox checked={cover2SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                                        : <SiteBox checked={cover2SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover2SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                                    } checked={cover2SitesChecked} onOptionClick={(checked: boolean[]) => handleStage2SiteChange(index, 2, checked.findIndex(Boolean)>-1 ? cover2Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                                </SitesCheckboxes>
                                                                                                            </DropdownContent>
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </RolloverElementCell>
                                                                                            <NumberInput isDisabled={!!res} 
                                                                                                value={om.odds.length>0 ? om.odds[2].value : ''} 
                                                                                                inputPlaceholder='Quota'
                                                                                                minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                                                onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 2, newState)}
                                                                                            />
                                                                                            <RolloverElementCell>
                                                                                                {!editing
                                                                                                    ? <RolloverElementOddsSelection title='Esito'>{om.selections[2]}</RolloverElementOddsSelection>
                                                                                                    :   <TextInputWithAutocomplete 
                                                                                                            value={om.selections[2]} 
                                                                                                            possibleValues={filteredSelectionsValues} 
                                                                                                            inputPlaceholder='Esito'
                                                                                                            inputWidth={'60px'}
                                                                                                            height='100%' 
                                                                                                            fontSize={11}
                                                                                                            allowInvalid={true}
                                                                                                            emptyIsInvalid={true}
                                                                                                            onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 2, newValue, selectionIndex)}
                                                                                                        />
                                                                                                }
                                                                                            </RolloverElementCell>
                                                                                        </RolloverElementOdds>
                                                                                    }
                                                                                </RolloverElementOddsCovers>
                                                                            </RolloverElementOddsCoversContainer>
                                                                        </RolloverElementOddsMatchRow>
                                                                        {(res===null && !editing && stage2Covers.length>index) &&
                                                                            <CoverInstructionsContainer>
                                                                                {
                                                                                    stage2Covers[index].covers.map((c,i) => {
                                                                                        const site = i===1 ? cover1Site : cover2Site;
                                                                                        if(i===0 || (mode!=='tri' && i===2)) return null;
                                                                                        return(
                                                                                            <CoverInstructions key={'c'+i}>
                                                                                                <SiteBox 
                                                                                                    checked={!!site} 
                                                                                                    notClickable={!(site && om.sitesUrls[i] && copyToClipboard)}
                                                                                                    name={site?.name ?? ''} 
                                                                                                    width={60}
                                                                                                    height={36}
                                                                                                    backgroundColor={!site ? '#000000a3' : undefined}
                                                                                                    onClick={() => site && om.sitesUrls[i] && copyToClipboard && copyToClipboard(om.sitesUrls[i], `URL ${site.name} copiato`)}
                                                                                                >
                                                                                                    {site && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                                                                </SiteBox>
                                                                                                <CoverInstructionsLabelContainer withSite={!calculators} backgroundColor='#323b42'>
                                                                                                    <HorizontalScrollContainer hiddenScrollBar={true}>
                                                                                                        <CoverInstructionsLabel>
                                                                                                            <CoverInstructionsText>{mode==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                                                            <BetContainer>
                                                                                                                <Bet translateY={mode==='pb' ? 5 : 0}>{'€'+(c.bet ? c.bet.toFixed(mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                                {mode==='pb' && <Resp title='Responsabilità' translateY={1}>{'€'+(c.resp ? c.resp.toFixed(2) : '-')}</Resp>}
                                                                                                            </BetContainer>
                                                                                                            {!calculators &&
                                                                                                                <>
                                                                                                                    <CoverInstructionsText>esito</CoverInstructionsText>
                                                                                                                    <CoverInstructionsStrongText>{om.selections[i]}</CoverInstructionsStrongText> 
                                                                                                                </>
                                                                                                            }
                                                                                                            <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                                                            <CoverInstructionsStrongText>{om.odds[i].errorMessage ? '-' : parseFloat(om.odds[i].value).toFixed(2)}</CoverInstructionsStrongText>
                                                                                                        </CoverInstructionsLabel>
                                                                                                    </HorizontalScrollContainer>
                                                                                                </CoverInstructionsLabelContainer>
                                                                                            </CoverInstructions>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </CoverInstructionsContainer> 
                                                                        }
                                                                    </RolloverElement> 
                                                                    <WinnigsAndRatingContainer>
                                                                        <WinnigsAndRating flexDirection={isAppMobile ? 'row' : 'column'} boxed={false} padding='0 5px'>
                                                                            <Winnings translateY={isAppMobile ? 0 : 5} amount={winnings!==undefined ? winnings : 0}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Winnings>
                                                                            <Rating translateY={isAppMobile ? 0 : 2}>{ratings!==undefined ? ratings.toFixed(2) : '-'}%</Rating>
                                                                        </WinnigsAndRating>
                                                                    </WinnigsAndRatingContainer>
                                                                </Flex>
                                                            );
                                                        })}
                                                        {(progress?.remainingRolloverIncludingAllBets ?? 0)<-0.001 && 
                                                            <OverRolloverWarning>
                                                                <WarningIconsContainer>
                                                                    {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                                    <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                                </WarningIconsContainer>
                                                                <OverRolloverWarningText>Stai Puntando più del Necessario</OverRolloverWarningText>
                                                                <WarningIconsContainer>
                                                                    {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                                    <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                                </WarningIconsContainer>
                                                            </OverRolloverWarning>
                                                        }
                                                    </RolloverList>
                                                }
                                            </>
                                        }
                                        {(progress.bonusBalanceIncludingAllBets>0.99 && progress.remainingRolloverIncludingAllBets>0.01)  && <RolloverCompletionAdd title='Aggiungi puntata' onClick={addStage2Bet}>+</RolloverCompletionAdd>}
                                    </RolloverStageContainer>
                                }
                            </RolloverStagesContainer>
                            {progress && 
                                <RolloverInfoContainer>
                                    {(progress.bonusBalance>0.99 && progress.remainingRollover>0.01) && 
                                        <>
                                            <RolloverInfoElement><RolloverInfoLabel>Atteso iniziale</RolloverInfoLabel><RolloverInfoValue v={progress.expectedValue}>€{progress.expectedValue!==undefined ? progress.expectedValue.toFixed(2) : '-'}</RolloverInfoValue></RolloverInfoElement>
                                            <RolloverInfoElement><RolloverInfoLabel>Atteso reale</RolloverInfoLabel><RolloverInfoValue v={progress.realExpectedValue}>€{progress.realExpectedValue!==undefined ? progress.realExpectedValue.toFixed(2) : '-'}</RolloverInfoValue></RolloverInfoElement>
                                        </>
                                    }
                                    {progress.bonusBalance<0.99 && <><RolloverFinishedReason>Saldo bonus terminato</RolloverFinishedReason><RolloverFinishedValue v={progress.total}>€{progress.total.toFixed(2)}</RolloverFinishedValue></>}
                                    {(progress.bonusBalance>0.99 && progress.remainingRollover<0.01) && <><RolloverFinishedReason>Rollover completato</RolloverFinishedReason><RolloverFinishedValue v={progress.total}>€{progress.total.toFixed(2)}</RolloverFinishedValue></>}
                                </RolloverInfoContainer>
                            }
                        </>
                    }
                </RolloverContainer>
            }
        </BaseFieldset>
    );
}

export default memo(Rollover);