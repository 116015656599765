import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';

export const WhatsAppFlowCardBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 430px;
    height: auto;

    margin: 0;
    padding: 0;

    background-color: ${colors.darkGrey};
    border-radius: 15px;
    border: none;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};

    @media ${devices.tablet} {

        height: 145px;
    }
`;