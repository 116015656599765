import { FC, useEffect, useState } from 'react';

//routing
import { useNavigate } from 'react-router-dom';

//utils
import { getItemFromLocalStorage } from 'utils/localStorage';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//components
import CourseBox from './CourseBox/CourseBox.component';
import ServiceBox from './ServiceBox/ServiceBox.component';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import { getCourseWithBrand } from './services/ShopPage.services';
import FilterShopDesktop from './FilterShop/layout/FilterShopDesktop/FilterShopDesktop.component';
import FilterShopMobile from './FilterShop/layout/FilterShopMobile/FilterShopMobile.component';
import ProductInfoModal from 'components/ProductInfoModal/ProductInfoModal.component';
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as CleanIconSVG } from './assets/cleanIcon.svg';

//style
import {
    PageShop,
    ShopPageContent,
    ShopOperationBox,
    ShopTitleBox,
    ShopTitle,
    SubCategoryProductTitle,
    TypeProductTitle,
    ProductMainInteractionBox,
    ShopInteractionBox,
    FilterProductBox,
    FilterText,
    ProductFilterBox,
    SearchBarBox,
    SearchBar,
    ShopProductBox,
    CleanBox
} from './style/ShopPage.style';
import LoadingMask from 'components/ui/LoadingMask';
import store from 'redux/store';


/**
 * Shop Page
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

const ShopPage: FC<{}> = ({}) => {
    
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [searchInput, setSearchInput] = useState<any>("");
    const [productsList, setProductsList] = useState<any[]>([]);
    const [filterProductsList, setFilterProductsList] = useState<any[]>([]);
    const [subFilterProductsList, setSubFilterProductsList] = useState<any[]>([]);
    const [searchFilterProductsList, setSearchFilterProductsList] = useState<any[]>([]);
    const [isFilterOpenActive, setIsFilterOpenActive] = useState<string>("");
    const [isSubFilterOpenActive, setIsSubFilterOpenActive] = useState<string>("");
    const [isCourseFilterActive, setIsCourseFilterActive] = useState(true);
    const [isServiceFilterActive, setIsServiceFilterActive] = useState(true);
    const [isProductInfoModalVisible, setIsProductInfoModalVisible] = useState<boolean>(false);
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>('');
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);
    const [partialProductPrice, setPartialProductPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const closeProductInfoModal = () => setIsProductInfoModalVisible(false);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const onClickCourseFilterHandler = () => setIsCourseFilterActive(prevState => !prevState);     
    const onClickServiceFilterHandler = () => setIsServiceFilterActive(prevState => !prevState);     
    const searchHandler = (searchValue: any) => setSearchInput(searchValue.target.value);
    const changeFilterOpenActive = (text: string) => setIsFilterOpenActive(text);
    const changeSubFilterOpenActive = (text: string) => setIsSubFilterOpenActive(text);
    const [isSubMenuVisible, setIsSubMenuVisible] = useState<boolean>(false);
    const [selectedSubMenuText, setSelectedSubMenuText] = useState<string>('');
    const [selectedMenuPosition, setSelectedMenuPosition] = useState<number>(0);

    let prevSelectedSubMenuText = selectedMenuPosition;
    

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)     
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };
    
    const breadgrumbFilterHandler = () => {
        changeSubFilterOpenActive('');
        setSelectedSubMenuText('');
        setSelectedMenuPosition(prevSelectedSubMenuText)
    };
    
    const clearSearchHandler = () => {
        
        setSearchInput("");
        setFilterProductsList(subFilterProductsList);
        setSubFilterProductsList(subFilterProductsList);
    };
    
    const clearContollerHandler = () => {
        
        changeFilterOpenActive('');
        changeSubFilterOpenActive('');
        setSelectedMenuPosition(0);
        setSelectedSubMenuText('');
        setIsSubMenuVisible(false);
    };
    
    const modalGetPaymentID = (paymentID: number | undefined) => {
        setPaymentTransferID(paymentID);
    };

    const publicInfoPageTransferPrice = (grossPrice: number) => {
        setChosenProductPrice(`${grossPrice}`)  
    };
    
    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };
    
    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };
    
    const setNewProductId = (newProductId: string, productName: string, productPrice: string) => {
        
        setIsProductInfoModalVisible(true);
        setProductId(newProductId);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
    };
    
    const handleCourseFilter = () => {     
        
        if (subFilterProductsList === undefined) return;
        
        if (isCourseFilterActive){
            const newFilter = subFilterProductsList.filter((object) => object.productCourse === undefined);
            setSubFilterProductsList(newFilter);
            return;
        };
        
        if (!isServiceFilterActive){
            const newFilter = productsList.filter((object) => object.productCourse);
            setSubFilterProductsList([...subFilterProductsList, ...newFilter]);
            return;
        };
        
        if (!isCourseFilterActive) {
            if (isFilterOpenActive !== "") {
                const filteredCategory = productsList.filter((product :any) => {
                    for (let i=0; i <=  product.categories.length; i++) {
                        
                        if (product.categories[i] === undefined) return;
                        
                        if (product.categories[i].name === undefined) return;
                        
                        if (product.categories[i].name === isFilterOpenActive.toUpperCase()) return product;
                    }
                });
                setFilterProductsList(filteredCategory);
                setSubFilterProductsList(filteredCategory);
                return;
            };
            
            if (isSubFilterOpenActive !== "") {
                const subFilteredCategory = filterProductsList.filter((product :any) => {
                    for (let i=0; i <=  product.subCategories.length; i++) {
                        
                        if (product.subCategories[i] === undefined) return;
                        
                        if (product.subCategories[i].name === undefined) return;
                        
                        if (product.subCategories[i].name === isSubFilterOpenActive.toUpperCase()) return product;
                    }
                });
                setSubFilterProductsList(subFilteredCategory);
                return;
            };
        };
        
        setSubFilterProductsList(productsList);
    };
    
    const handleServiceFilter = () => {
        
        if (subFilterProductsList === undefined) return;
        
        if (isServiceFilterActive){
            const newFilter = subFilterProductsList.filter((object) => object.productService === undefined);
            setSubFilterProductsList(newFilter);
            return;
        };
        
        if (!isCourseFilterActive){
            const newFilter = productsList.filter((object) => object.productService);
            setSubFilterProductsList([...subFilterProductsList, ...newFilter]);
            return;
        };
        
        if (!isServiceFilterActive) {
            if (isFilterOpenActive !== "") {
                const filteredCategory = productsList.filter((product :any) => {
                    for (let i=0; i <=  product.categories.length; i++) {
                        
                        if (product.categories[i] === undefined) return;
                        
                        if (product.categories[i].name === undefined) return;
                        
                        if (product.categories[i].name === isFilterOpenActive.toUpperCase()) return product;
                    }
                });
                setFilterProductsList(filteredCategory);
                setSubFilterProductsList(filteredCategory);
                return;
            };
            
            if (isSubFilterOpenActive !== "") {
                const subFilteredCategory = filterProductsList.filter((product :any) => {
                    for (let i=0; i <=  product.subCategories.length; i++) {
                        
                        if (product.subCategories[i] === undefined) return;
                        
                        if (product.subCategories[i].name === undefined) return;
                        
                        if (product.subCategories[i].name === isSubFilterOpenActive.toUpperCase()) return product;
                    }
                });
                setSubFilterProductsList(subFilteredCategory);
                return;
            };
        };
        
        setSubFilterProductsList(productsList);
    };
    
    const getProducts = async () => {
        
        try {

            loadingOn();
            const response = await getCourseWithBrand();
            console.log("PRODOTTI:", response);
            
            const filteredList = response.filter((element: any) => ((element.productCourse !== undefined) || (element.productService !== undefined)) && ((element.salesData !== undefined) || (element.salesData !== null)))
            const weightList = filteredList.sort((elementA: any, elementB: any) => elementB.salesData?.weight - elementA.salesData?.weight)
            
            setProductsList(weightList);
            setFilterProductsList(weightList);
            setSubFilterProductsList(weightList);
            setSearchFilterProductsList(weightList);
            loadingOff();
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            loadingOff();
            console.warn(error);
        }
    };
    
    useEffect(() => {
        
        getProducts();
    },[])
    
    useEffect(() => {
        
        if (productsList === undefined) return;
        if (productsList.length === 0) return;
        
        console.log("Lista Prodotti", filterProductsList);
    }, [filterProductsList, productsList]);
    
    useEffect(() => {
        const isBankTransferPending = getItemFromLocalStorage('Pending Bank Transfer');
        
        if (isBankTransferPending === "true") {
            const pendingCoursePurchase = getItemFromLocalStorage('Pending purchase ID');
            
            if (pendingCoursePurchase === null) return;
            setIsBankTransferPending(true);
            setPaymentTransferID(+pendingCoursePurchase)
            setIsBankTransferModalVisible(true);
        }
    },[])
    
    useEffect(() => {
        
        // if (!isCourseFilterActive) return;
        
        
        if (isFilterOpenActive !== "") {
            const filteredCategory = productsList.filter((product :any) => {
                for (let i=0; i <=  product.categories.length; i++) {
                    
                    if (product.categories[i] === undefined) return;
                    
                    if (product.categories[i].name === undefined) return;
                    
                    if (product.categories[i].name === isFilterOpenActive.toUpperCase()) return product;
                }
            });
            setFilterProductsList(filteredCategory);
            setSubFilterProductsList(filteredCategory);
            return;
        };
        
        if (!isCourseFilterActive) {
            
            const newFilter = productsList.filter((object) => object.productCourse === undefined);
            console.log("lista filtrata", newFilter);
            
            setSubFilterProductsList(newFilter);
            setFilterProductsList(newFilter);
            
            if (!isServiceFilterActive) {
                
                const newFilter = subFilterProductsList.filter((object) => object.productService === undefined);
                console.log("lista filtrata", newFilter);
                
                setSubFilterProductsList(newFilter);
                setFilterProductsList(newFilter);
                return;
            };
            return;
        };
        
        if (!isServiceFilterActive) {
            
            const newFilter = productsList.filter((object) => object.productService === undefined);
            console.log("lista filtrata", newFilter);
            
            setSubFilterProductsList(newFilter);
            setFilterProductsList(newFilter);
            return;
        };
        
        setFilterProductsList(productsList);
        setSubFilterProductsList(productsList);
        
    }, [isFilterOpenActive]);
    
    useEffect(() => {
        
        if (isSubFilterOpenActive !== "") {
            const subFilteredCategory = filterProductsList.filter((product :any) => {
                for (let i=0; i <=  product.subCategories.length; i++) {
                    
                    if (product.subCategories[i] === undefined) return;
                    
                    if (product.subCategories[i].name === undefined) return;
                    
                    if (product.subCategories[i].name === isSubFilterOpenActive.toUpperCase()) return product;
                }
            });
            setSubFilterProductsList(subFilteredCategory);
            return;
        };
        
        if (isFilterOpenActive !== '') {
            setSubFilterProductsList(filterProductsList)
            setSearchFilterProductsList(filterProductsList)
            return
        };
        
        if (!isCourseFilterActive) {
            
            const newFilter = productsList.filter((object) => object.productCourse === undefined);
            console.log("lista filtrata", newFilter);
            
            setSubFilterProductsList(newFilter);
            setFilterProductsList(newFilter);

            if (!isServiceFilterActive) {
                
                const newFilter = subFilterProductsList.filter((object) => object.productService === undefined);
                console.log("lista filtrata", newFilter);
                
                setSubFilterProductsList(newFilter);
                setFilterProductsList(newFilter);
                return;
            };
            
            return;
        };
        
        if (!isServiceFilterActive) {
            
            const newFilter = productsList.filter((object) => object.productService === undefined);
            console.log("lista filtrata", newFilter);
            
            setSubFilterProductsList(newFilter);
            setFilterProductsList(newFilter);
            return;
        };
        
        setFilterProductsList(productsList);
        setSubFilterProductsList(productsList);
        
    }, [isSubFilterOpenActive]);
    
    useEffect(() => {
        
        if (searchInput === "") return setSearchFilterProductsList(subFilterProductsList);
        
        setSearchFilterProductsList(subFilterProductsList.filter(
            (prduct: any) => (
                (prduct.salesData &&
                    prduct.salesData.delivery &&
                    prduct.salesData.delivery.toLowerCase().includes(searchInput.toLowerCase())) || (
                    prduct.name &&
                    prduct.name.toLowerCase().includes(searchInput.toLowerCase()))
            )));
    }, [searchInput, subFilterProductsList]);
         
    // if (isLoading) return (
    //     <LoadingMask 
    //         paddingTop='200px'
    //         isCenter
    //         size='page'
    //     />
    // );

    //store.getState().ui.brand?.id

    return (
        <>
            <PageShop>
                <ShopPageContent>
                    <ShopOperationBox>
                        <ShopTitleBox>
                            <ShopTitle onClick={clearContollerHandler}>Negozio</ShopTitle>

                            {(isFilterOpenActive !== "") && (isSubMenuVisible !== false) && !isAppMobile && <ShopTitle>/</ShopTitle>}

                            {(isFilterOpenActive !== "") && isAppMobile && <ShopTitle>/</ShopTitle>}

                            {isSubMenuVisible !== false && !isAppMobile &&  <SubCategoryProductTitle onClick={breadgrumbFilterHandler}>{isFilterOpenActive}</SubCategoryProductTitle>}

                            {isFilterOpenActive !== "" && isAppMobile &&  <SubCategoryProductTitle onClick={breadgrumbFilterHandler}>{isFilterOpenActive}</SubCategoryProductTitle>}

                            {(isSubFilterOpenActive !== "") && <SubCategoryProductTitle>/</SubCategoryProductTitle>}

                            <TypeProductTitle>{isSubFilterOpenActive}</TypeProductTitle>
                        </ShopTitleBox>

                        {isAppMobile && store.getState().ui.brand?.id !== 4 && 
                            <FilterShopMobile 
                            changeFilterOpenActive={changeFilterOpenActive} 
                            changeSubFilterOpenActive={changeSubFilterOpenActive}
                            />
                        }
                        
                        <ProductMainInteractionBox>
                            { store.getState().ui.brand?.id !== 4 &&
                                <FilterProductBox>
                                    <ProductFilterBox onClick={handleCourseFilter}>
                                        <SwitchSelector isFilterActive={isCourseFilterActive} filterHandler={onClickCourseFilterHandler}/>

                                        <FilterText>Corsi</FilterText>
                                    </ProductFilterBox>

                                    <ProductFilterBox onClick={handleServiceFilter}>
                                        <SwitchSelector isFilterActive={isServiceFilterActive} filterHandler={onClickServiceFilterHandler}/>

                                        <FilterText>Abbonamenti</FilterText>
                                    </ProductFilterBox>
                                </FilterProductBox>
                            }

                            <ShopInteractionBox>
                                <SearchBarBox>
                                    <SearchBar                                
                                        onChange={searchHandler}
                                        value={searchInput} 
                                        />

                                    {searchInput !== "" &&
                                        <CleanBox onClick={clearSearchHandler}>
                                            <CleanIconSVG />
                                        </CleanBox>}
                                </SearchBarBox>

                                <SearchIconSVG></SearchIconSVG>
                            </ShopInteractionBox>
                        </ProductMainInteractionBox>
                    </ShopOperationBox>


                    {!isAppMobile && store.getState().ui.brand?.id !== 4 && 
                        <FilterShopDesktop 
                        changeFilterOpenActive={changeFilterOpenActive} 
                        changeSubFilterOpenActive={changeSubFilterOpenActive} 
                        clearContollerHandler={clearContollerHandler}
                        isSubMenuVisible={isSubMenuVisible}
                        setIsSubMenuVisible={setIsSubMenuVisible}
                        selectedSubMenuText={selectedSubMenuText}
                        setSelectedSubMenuText={setSelectedSubMenuText}
                        selectedMenuPosition={selectedMenuPosition}
                        setSelectedMenuPosition={setSelectedMenuPosition}
                        activeButtonClick={breadgrumbFilterHandler}
                        />
                    }

                    <ShopProductBox>
                        {searchFilterProductsList.map((product: any, index: any)  => {
                            if (product.productCourse !== undefined) return (
                                <CourseBox 
                                    key={index}
                                    courseElement={product}
                                    onInfoButtonClick={setNewProductId} 
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                />);

                            if (
                                (product.productService !== undefined) && 
                                (product.productService.paymentPlan !== undefined) && 
                                (product.productService.paymentPlan !== null)
                            ) return (
                                <ServiceBox 
                                    key={index}
                                    courseElement={product}
                                    onInfoButtonClick={setNewProductId}
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                />);
                        })}

                    </ShopProductBox>
                </ShopPageContent>
            </PageShop>

            {isProductInfoModalVisible && (
                <ProductInfoModal
                    onCloseButtonModal={closeProductInfoModal}
                    productInfoId={productId}
                    onBankModalOpen={openBankTransferModal}
                    onLibertyModalOpen={openLibertyPaymentModal}
                    modalGetPaymentID={modalGetPaymentID}
                    libertyProductID={libertyProductID}
                    publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                />
            )}

            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && (
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={chosenProductID!}
                    price={productLibertyPrice}
                />
            )}
        </>
    );
};

export default ShopPage;