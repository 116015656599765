import { callGET } from "network/network";
import store from "redux/store";

export const getCourseWithBrand = async () => {

    const payload = {brandCode: store.getState().ui.brand?.code}
        
    try {
        
        const response = await callGET('v1/productsforsell', true, payload);
        
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};