import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// assets
import { ReactComponent as WhatsappIconSVG } from './asset/whatsappIcon.svg';
import { ReactComponent as ComingSoonIconSVG } from './asset/comingsoon.svg';

// style
import {
    WhatsAppNeedCallBox,
    WhatsAppNeedCallTextContent,
    WhatsAppNeedCallBoxContent,
    WhatsAppNeedCallText,
    WhatsAppNeedCallButton,
    WhatsAppBox,
    ComingSoonBox
} from './style/WhatsAppCoomingSoon.style';
import { getEnvironmentProductId } from 'configs/config.helper';


const WhatsAppCoomingSoon: FC<{
    onButtonClick?: () => void
}> = ({
    onButtonClick
}) => {
    
    const [userHasBonusSicuroCourse, setUserHasBonusSicuroCourse] = useState<boolean>(false);
    const { products } = useAppSelector(state => state.user);

    useEffect(() => {
        const courseList = products?.courses;
        setUserHasBonusSicuroCourse((courseList !== undefined) ? courseList.some((course: any) => course.id === getEnvironmentProductId('bonus sicuro')) : false);
    }, [products]);

    console.log("HO BONUS SICURO?", userHasBonusSicuroCourse);
    
    
    return (
        <WhatsAppNeedCallBox>
            <WhatsAppNeedCallBoxContent>
                <WhatsAppNeedCallTextContent>

                    <WhatsAppBox>
                        <WhatsappIconSVG />
                    </WhatsAppBox>

                    {!userHasBonusSicuroCourse && 
                        <WhatsAppNeedCallText>
                            Vuoi parlare con qualcuno che ti spieghi il nostro Percorso?
                        </WhatsAppNeedCallText>}

                    {userHasBonusSicuroCourse && 
                        <WhatsAppNeedCallText>
                            Vuoi un aiuto a muovere i primi passi con il Percorso Bonus?
                        </WhatsAppNeedCallText>}
                </WhatsAppNeedCallTextContent>

                <WhatsAppNeedCallButton>Sì LO VOGLIO (!)</WhatsAppNeedCallButton>
            </WhatsAppNeedCallBoxContent>

            <ComingSoonBox>
                <ComingSoonIconSVG/>
            </ComingSoonBox>
        </WhatsAppNeedCallBox>
    )
};

export default WhatsAppCoomingSoon;