import { FC, useCallback, useEffect, useState } from 'react';


// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// types
import { ReportEarningModalProps } from './ReportEarningModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as EarningIconSVG } from './assets/earningIcon.svg';
import { ReactComponent as RecordEarningIconSVG } from './assets/recordEarningIcon.svg';
import { ReactComponent as MonthResultIconSVG } from './assets/monthResultIcon.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    ResultEaningBox,
    EarningBox,
    EarningText,
    ResultEarningText,
    AnnualEarningTableBox,
    AnnualBox,
    CurrentYarsText,
    MainTableBox,
    TableBox,
    MounthBox,
    TotalMounthBox,
    MiddleEarningBox,
    MounthrRecordBox,
    TableText,
    TopEarningBox,
    RecordBox,
    TotalEarningBox,
    TotalEarningText,
    TotalResultEarningText,
    Nuance,
    GeneralTableBox
} from './style/ReportEarningModal.style';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import MonthResult from './MonthResult/MonthResult.component';
import { getEarningRestults, getProductSubscribers } from './services/ReportEarningModal.services';
import { formattedCurrency } from 'utils/supportFunctions';
import LoadingMask from 'components/ui/LoadingMask';
import { MonthResultProps } from './MonthResult/MonthResult.component.d';


/**
 * Report Earning modal
 * 
 * @author Foschi Piero
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */

const ReportEarningModal: FC<ReportEarningModalProps> = ({ 
    onCloseButtonModal,
    onModalButtonClickHandler,
    saveResultsForBanner,
    annualTotal,
    earningDataForTable,
    globalMaxEarning
}) => {

    console.log("map",earningDataForTable)

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    // const [earningDataForTable, setEarningDataForTable] = useState<any>(undefined);
    // const [globalMaxEarning, setGlobalMaxEarning] = useState<any>(undefined);
    // const [annualTotal, setAnnualTotal] = useState<any>(undefined);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    
    // const userResults =  async () => {

    //     try {
            
    //         const response = await getEarningRestults();

    //         return response;
    //     } catch (error: any) {
    //         console.warn("ERROR:", error);
    //     }
    // };

    // const subscribers =  async (date: string) => {

    //     try {
            
    //         const response = await getProductSubscribers(177, date);

    //         return response;
    //     } catch (error: any) {
    //         console.warn("ERROR:", error);
    //     }
    // };

    // const earningData = async () => {

    //     try {
            
    //         const results = await userResults();

    //         const yearResults = results.filter((result: any) => result.year === 2023);
    //         const upgradedResults = yearResults.map((result: any) => {
                
    //             return {
    //                 avg_result: result.avg_result,
    //                 maxResult: result.maxResult,
    //                 month: result.month,
    //                 year: result.year,
    //                 nameOfMaxResult: result.nameOfMaxResult,
    //                 total_month_earning: 0
    //             }
    //         });

    //         for (let i = 0; i < upgradedResults.length; i++) {

    //             try {
                    
    //                 const monthSubscribers = await subscribers(new Date(upgradedResults[i].year, upgradedResults[i].month).toISOString());
    //                 upgradedResults[i].total_month_earning = upgradedResults[i].avg_result * monthSubscribers;
    //             } catch (error: any) {
    //                 console.warn("ERROR:", error);
    //             }
    //         }
            
    //         const maxResultInTime = Math.max(...results.flatMap((result: any) => result.maxResult));
    //         const maxResultData = results.filter((result: any) => result.maxResult === maxResultInTime);
            
    //         const totalYearEarnings = upgradedResults.flatMap((month: any) => month.total_month_earning).reduce((a: number, b: number) => a + b, 0);
            
    //         saveResultsForBanner(upgradedResults[upgradedResults.length - 1].total_month_earning, upgradedResults[upgradedResults.length - 1].avg_result);
    //         setAnnualTotal(totalYearEarnings)
    //         setGlobalMaxEarning(`${maxResultData[0].nameOfMaxResult} ${maxResultData[0].maxResult} €`)
    //         setEarningDataForTable(upgradedResults)
    //     } catch (error: any) {
    //         console.warn("ERROR:", error);
    //     }
    // };

    // useEffect(() => {
    //     earningData();
    // }, []);

    // console.log("TOTAL", earningDataForTable[earningDataForTable.length - 1])    
    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {(earningDataForTable === undefined) && 
                        <LoadingMask
                            isCenter
                            size='big'
                            paddingTop='100px'
                            paddingBottom='100px'
                        />
                    }
                    
                        {earningDataForTable !== undefined && 
                        <>
                            {isAppMobile && (
                                <Row 
                                    crossAxis='center'
                                    mainAxis='flex-end'
                                >
                                    <CloseButton onClick={closeButtonHandler}>
                                        <CloseSVG />
                                    </CloseButton>
                                </Row>
                            )}
                            <ResultEaningBox>
                                <EarningBox>
                                    <EarningText>
                                        Questo mese i nostri iscritti hanno Guadagnato con soli metodi SICURI un totale di:
                                        
                                        <ResultEarningText>
                                            {`${formattedCurrency(earningDataForTable[earningDataForTable.length - 1].total_month_earning, 0)} €`}
                                        </ResultEarningText>
                                    </EarningText>
                                </EarningBox>

                                <EarningBox>
                                    <EarningIconSVG />

                                        <EarningText>Il Guadagno per ogni Iscritto è stato mediamente di:
                                        
                                        <ResultEarningText>
                                            {`${formattedCurrency(earningDataForTable[earningDataForTable.length - 1].avgResultBonus + earningDataForTable[earningDataForTable.length - 1].avgResultColpo, 0)} €`} 
                                        </ResultEarningText>
                                    </EarningText>
                                </EarningBox>

                                <EarningBox>
                                    <RecordEarningIconSVG />

                                    <EarningText>
                                        Il record di Guadagno di questo mese è di:

                                        <ResultEarningText>
                                            {`${formattedCurrency(earningDataForTable[earningDataForTable.length - 1].nameOfMaxResult)} 
                                            ${formattedCurrency(earningDataForTable[earningDataForTable.length - 1].maxResult, 0)} €`}
                                        </ResultEarningText>
                                    </EarningText>
                                </EarningBox>
                            </ResultEaningBox>

                            <GeneralTableBox>
                                <AnnualEarningTableBox>
                                    <AnnualBox>
                                        <CurrentYarsText>ANNO 2023</CurrentYarsText>
                                    </AnnualBox>

                                    <MainTableBox>
                                        <TableBox>
                                            <MounthBox>
                                                <TableText>MESE</TableText>
                                            </MounthBox>

                                            <TotalMounthBox>
                                                <TableText>TOTALE MESE</TableText>
                                            </TotalMounthBox>

                                            <MiddleEarningBox>
                                                <TableText>GUADAGNO MEDIO</TableText>
                                                <TableText>Bonus</TableText>
                                            </MiddleEarningBox>

                                            <MiddleEarningBox>
                                                <TableText>GUADAGNO MEDIO</TableText>
                                                <TableText>Colpo Sicuro</TableText>
                                            </MiddleEarningBox>

                                            <MounthrRecordBox>
                                                <TableText>RECORD DEL MESE</TableText>
                                            </MounthrRecordBox>
                                        </TableBox>
                                            {(earningDataForTable !== undefined) && 
                                                <>
                                                    {earningDataForTable.map((rowData: any, index: number) => {
                                                        return (
                                                            <MonthResult
                                                                key={"result" + index}
                                                                resultData={rowData}
                                                            />
                                                        )
                                                    })
                                                }
                                                </>
                                            }
                                        {/* <Nuance /> */}
                                    </MainTableBox>
                                </AnnualEarningTableBox>
                            </GeneralTableBox>

                            <TopEarningBox>
                                <RecordBox>
                                    <RecordEarningIconSVG />

                                    <EarningText>Il record di Sempre lo detiene:</EarningText>
                                </RecordBox>

                                <ResultEarningText>{globalMaxEarning}</ResultEarningText>
                            </TopEarningBox>

                            <TotalEarningBox>
                                    <TotalEarningText>
                                        Totale dell’anno:
                                        <TotalResultEarningText>{`${formattedCurrency(annualTotal, 0)} €`}</TotalResultEarningText>
                                    </TotalEarningText>
                            </TotalEarningBox>
                        </>        
                    }
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default ReportEarningModal;
