import { Auth } from 'aws-amplify';

// types
import { SignInPayload } from './LoginForm.services.d';


export const awsSignIn = async (payload: SignInPayload) => {

    try {

        const response = await Auth.signIn(payload);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

export const awsRememberDevice = async () => {

    try {

        const response = await Auth.rememberDevice();

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

export const awsForgetDevice = async () => {

    try {

        const response = await Auth.forgetDevice();

        if (response === undefined || !response) {
            throw new Error();
        }
        
        return response;
        
    } catch (error: any) {
        throw error;
    }
};
