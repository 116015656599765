import { FC } from 'react';

// routing
import { Outlet } from 'react-router-dom';

const VdrToolsIndex: FC<{}> = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default VdrToolsIndex;
