import { FC, useEffect } from 'react';
import store from 'redux/store';

// routing
import { Outlet, useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// assets
import { ReactComponent as TitleLogoSVG } from './assets/page-title-logo.svg';
import { ReactComponent as MBLogoSVG } from './assets/logo-mb.svg';
import { ReactComponent as TCRLogoSVG } from './assets/logo-tutorialcrypto.svg';
import { ReactComponent as VDRLogoSVG } from './assets/logo-LVDR.svg';

import MakeMoneyWithNoMoneyImage from './assets/make-money-with-no-money.png';
import LacquaDelSuperviventeImage from './assets/MB-gift.png';
import TutorialCryptoImage from './assets/tutorial-crypto.png';

// style
import { BaseCard } from 'style/wrappers';
import { Page, Content, CardText, GiftImage } from './style/SignUp.style';
import { getEnvironmentAssetLinkUrl } from 'configs/config.helper';
import useAppSelector from 'hooks/useAppSelector';

/**
 * Sign up form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const SignUp: FC<{}> = () => {

    const { tokenID } = useAppSelector(state => state.user);
    const isUserLoggedIn = !!tokenID;
    const navigate = useNavigate();
    
    useEffect (() => {
        if (isUserLoggedIn) navigate('/reservedArea/');
    }, [isUserLoggedIn]);

    const isLDL = store.getState().ui.brand?.id === 1;
    const isTCR = store.getState().ui.brand?.id === 2;
    const isVDR = store.getState().ui.brand?.id === 3;
    const isMB = store.getState().ui.brand?.id === 4;

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdAKRwiAAAAAEED3nu84BJuwimtZpQNbmA5QNkv">
            <Page>
                {isLDL && <TitleLogoSVG />}
                {isTCR && <TCRLogoSVG />}
                {isVDR && <VDRLogoSVG />}
                {isMB && <MBLogoSVG />}

                <BaseCard
                    maxWidth='600px'
                    flavor='simpleGrey'
                >
                    {isLDL && 
                        <Content>
                            <CardText>
                                Iscriviti ora per ricevere in REGALO il corso che ti spiega
                                come avere i primi Guadagni SICURI:
                            </CardText>

                            <GiftImage
                                src={getEnvironmentAssetLinkUrl(MakeMoneyWithNoMoneyImage)}
                                alt="first gift"
                            />

                            <Outlet />
                        </Content>
                    }

                    {isMB && 
                        <Content>
                            <CardText>
                                Iscriviti e ricevi subito in REGALO un corso meraviglioso:
                            </CardText>

                            <GiftImage
                                src={getEnvironmentAssetLinkUrl(LacquaDelSuperviventeImage)}
                                alt="first gift"
                            />

                            <Outlet />
                        </Content>
                    }

                    {isTCR && 
                        <Content>
                            <CardText>
                                Iscriviti ora per ricevere il tuo corso in REGALO:
                            </CardText>

                            <GiftImage
                                src={getEnvironmentAssetLinkUrl(TutorialCryptoImage)}
                                alt="first gift"
                            />

                            <Outlet />
                        </Content>
                    }

                    {isVDR && 
                        <Content>
                            {/* <CardText>
                                Iscriviti ora per ricevere il tuo corso in REGALO:
                            </CardText> */}

                            <Outlet />
                        </Content>
                    }

                </BaseCard>
            </Page>
        </GoogleReCaptchaProvider>
    );
};

export default SignUp;
