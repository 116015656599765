import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 1920px;

    padding: 35px 20px 0;

    a {text-decoration: none;}

    @media ${devices.tablet} {

        padding: 35px 40px 0;
    }
`;

export const MyCourseInfoBox = styled.h4`

    display: flex;
    align-self: flex-start;

    width: 100%;

    margin: 0;
    padding: 0;
     
    font-weight: 400;
    font-size: 18px;

    color: ${colors.white};
`;

export const MyCourseBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 15px 0 0;
    padding: 0 25px 35px;

    overflow: none;

    background: ${colors.darkGrey};
    border-radius: 15px;

    @media ${devices.tablet} {

        padding: 45px 45px;
    }
`;

export const MyCourseContent = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    row-gap: 20px;

    flex-wrap: wrap;

    width: 100%;

    overflow: none;

    a:link {
        display: flex;
}
`;