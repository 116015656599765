// component
import BaseFieldset from "components/ui/BaseFieldset";
import { FC } from "react";
import Lesson from "../LessonContent/Lesson.component";

// style
import { 
    CoachText, 
    EndedGrid,  
    EndedTable, 
    InfoText, 
    RowLabel
} from "./style/EndedLessonMobile.style";


const EndedLessonMobile : FC <{
    lesson: any,
    choiceLessonHandler: (lessonId: number, typeOfLesson: 'new' | 'ended') => void,
    openPersonalAsistantNotesModal: () => void,
    typeOfLesson: 'new' | 'ended'
}>= ({
    lesson,
    choiceLessonHandler, 
    openPersonalAsistantNotesModal,
    typeOfLesson
}) => {

    return (
        <BaseFieldset legend="STORICO LEZIONI">
            <EndedGrid>
                <EndedTable>
                    <RowLabel>
                        <InfoText>SCADENZA</InfoText>

                        <CoachText>A.P. / COACH</CoachText>

                    </RowLabel>

                    {(lesson !== undefined) && lesson.map((lesson: any, index: number) => {
                        return (
                            <Lesson
                            key={"ENDED" + index}
                            lesson={lesson}
                            choiceLessonHandler={choiceLessonHandler}
                            onCommentClick={openPersonalAsistantNotesModal}
                            typeOfLesson={typeOfLesson}
                            />
                        );
                    })}

                </EndedTable>
            </EndedGrid>
        </BaseFieldset>
    );
};

export default EndedLessonMobile;
