import { FC, useState, useEffect } from 'react';

// routing
import BuildRoutes from 'routes/BuildRoutes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// types
import { Brand } from 'types/Brand';

// redux
import { 
    refreshUserSession,
    checkIfUserIsLoggedWithFacebook,
    saveFacebookPicture,
    saveLibertyPointsAvailableBalance,
    saveStackedLibertyPointsAvailableBalance,
    saveFederateds,
    saveMissions,
    saveClientId,
    saveProducts,
    saveRoles,
    saveIsUserDisplayingFromAppMobile
} from 'redux/actions/actions-user';
import { disableFacebookExceptioModal, enableAppMain } from 'redux/actions/actions-app-main';
import { setBrand } from 'redux/actions/actions-ui';

// hooks
import useAppIsMobile from 'hooks/useAppIsMobile';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsFirstRender } from 'usehooks-ts';

// utils
import config from 'configs/config';
import { getCurrentAuthUser, getUserData, getUsersProducts } from 'services/user.services';
import { getBrandsList } from 'services/brand.services';
import { facebookCheckConnection } from './App.helper';
import { HelmetProvider } from 'react-helmet-async';

// components
import Header from './layout/Header';
import Footer from './layout/Footer';
import TabBar from './layout/Header/TabBar';
import SnackbarList from 'components/ui/SnackbarList';
import UserProfileSubmenuModal from 'components/UserProfileSubmenuModal/UserProfileSubmenuModal.component';
import FacebookExceptionModal from 'components/FacebookExceptionModal/FacebookExceptionModal.component';

// style
import {
    MinRangeGuard,
    AppBox,
    Main,
    PageContainer,
    PageBackgroundOnSubmenuOpen
} from './style/App.style';
import { callGET } from 'network/network';
import AccountProblemError from 'pages/FallbackPages/components/AccountProblemError/AccountProblemError.component';
import { getItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';
import store from 'redux/store';
import useBrand from 'hooks/useBrand';

interface DomainSettings {
    [key: string]: {
      title: string;
      icons: {
        [rel: string]: {
          [size: string]: string;
        };
      };
    };
  }

const HELMET_CONTEXT = {};

const App: FC<{}> = () => {
    facebookCheckConnection();

    const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { isMainActive } = useAppSelector(state => state.appMain);
    const { tokenID, badges } = useAppSelector(state => state.user);
    const { isFacebookExceptionModalVisible } = useAppSelector(state => state.appMain);
    // const [isUserProfileSubmenuModalVisible, setIsUserProfileSubmenuModalVisible] = useState<boolean>(true);
    const isFirstRender = useIsFirstRender();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    // const navigate = useNavigate();
    useAppIsMobile();

    const isLandingPath = location.pathname.includes('/lp');
    const isPublicPath = location.pathname.includes('/public');
    const isOddsScasser = location.pathname.includes('reservedArea/oddsscasser');

    const isUserCrook = badges?.find((badge: any) => badge.id === 5)
    // const isUserFromAppMobile = (searchParams.get('appmobile') === 'true');

    const userSubmenuToggleHandler = () => {
        
        setIsSubmenuOpen(prevState => !prevState);
        if (!isMainActive) dispatch( enableAppMain() );
    };
    const userSubmenuCloseHandler = () => setIsSubmenuOpen(false);
    const facebookExceptionModalCloseHandler = ()  => dispatch( disableFacebookExceptioModal() );
    const UserProfileSubmenuModalCloseHandler = ()  => dispatch( disableFacebookExceptioModal() );

    const setRef = () => {

        const newRef = searchParams.get('ref');
        const nowDate = new Date().getTime();
        
        if (!newRef) return;
        
        setItemToLocalStorage('ref', newRef);
        setItemToLocalStorage('refSetDate', `${nowDate}`);
    };

    useEffect(() => {

        if (isFirstRender) {
            dispatch( checkIfUserIsLoggedWithFacebook() ); 
            dispatch( saveFacebookPicture() );
            setRef();
        }
        
        
        dispatch( refreshUserSession() );
        if (tokenID !== undefined) {
            
            dispatch( getUserData() );
            dispatch( getUsersProducts() );
        };
        const intervalID = setInterval(() => dispatch( refreshUserSession() ), 2700000);
        
        return () => clearInterval(intervalID);

    }, [useBrand(), isFirstRender, dispatch, tokenID]);
    
    useEffect(() => {
        
        const isUserFromAppMobile = (searchParams.get('appmobile') === 'true');
        // console.log("FROM APP MOBILE?", isUserFromAppMobile);
        
        if (isUserFromAppMobile === null) return;
        dispatch( saveIsUserDisplayingFromAppMobile(isUserFromAppMobile));
    }, [dispatch]);
    
	useEffect(() => {
        const gb = async () => {
            var localBrand: Brand | undefined = undefined;
            try {
                const brandList: Brand[] = await getBrandsList();
               
                localBrand = brandList.find((b) => window.location.hostname.includes(b.domain));
                if (searchParams.get('brand') && window.location.hostname.includes('localhost')) localBrand = brandList.find((b) => b.code === searchParams.get('brand'));
                if ((!localBrand) && (window.location.hostname.includes('localhost'))) localBrand = config.defaultBrand;
                dispatch(setBrand(localBrand));
            } catch (error) {

            }

            const brandSettings: DomainSettings = {
                'LDL': {
                    title: 'Liberi dal lavoro - Come liberarsi dal lavoro in meno di 12 mesi',
                    icons: {
                        'apple-touch-icon': {
                        '57x57': '/images/icons/LDL/apple-icon-57x57.png',
                        '60x60': '/images/icons/LDL/apple-icon-60x60.png',
                        '72x72': '/images/icons/LDL/apple-icon-72x72.png',
                        '76x76': '/images/icons/LDL/apple-icon-76x76.png',
                        '114x114': '/images/icons/LDL/apple-icon-114x114.png',
                        '120x120': '/images/icons/LDL/apple-icon-120x120.png',
                        '144x144': '/images/icons/LDL/apple-icon-144x144.png',
                        '152x152': '/images/icons/LDL/apple-icon-152x152.png',
                        '180x180': '/images/icons/LDL/apple-icon-180x180.png',
                        },
                        'icon': {
                        '192x192': '/images/icons/LDL/android-icon-192x192.png',
                        '32x32': '/images/iconsLDL//android-icon-32x32.png',
                        '96x96': '/images/icons/LDL/android-icon-96x96.png',
                        '16x16': '/images/icons/LDL/android-icon-16x16.png',
                        },
                    },
                },
                'MB': {
                    title: 'Missione Benessere',
                    icons: {
                        'apple-touch-icon': {
                        '57x57': '/images/icons/MB/apple-icon-57x57.png',
                        '60x60': '/images/icons/MB/apple-icon-60x60.png',
                        '72x72': '/images/icons/MB/apple-icon-72x72.png',
                        '76x76': '/images/icons/MB/apple-icon-76x76.png',
                        '114x114': '/images/icons/MB/apple-icon-114x114.png',
                        '120x120': '/images/icons/MB/apple-icon-120x120.png',
                        '144x144': '/images/icons/MB/apple-icon-144x144.png',
                        '152x152': '/images/icons/MB/apple-icon-152x152.png',
                        '180x180': '/images/icons/MB/apple-icon-180x180.png',
                        },
                        'icon': {
                        '192x192': '/images/icons/MB/android-icon-192x192.png',
                        '32x32': '/images/icons/MB/android-icon-32x32.png',
                        '96x96': '/images/icons/MB/android-icon-96x96.png',
                        '16x16': '/images/icons/MB/android-icon-16x16.png',
                        },
                    },
                },
                // 'TCR': {
                //     title: 'Tutorial Crypto',
                //     icons: {
                //         'apple-touch-icon': {
                //         '57x57': '/images/icons/TCR/apple-icon-57x57.png',
                //         '60x60': '/images/icons/TCR/apple-icon-60x60.png',
                //         '72x72': '/images/icons/TCR/apple-icon-72x72.png',
                //         '76x76': '/images/icons/TCR/apple-icon-76x76.png',
                //         '114x114': '/images/icons/TCR/apple-icon-114x114.png',
                //         '120x120': '/images/icons/TCR/apple-icon-120x120.png',
                //         '144x144': '/images/icons/TCR/apple-icon-144x144.png',
                //         '152x152': '/images/icons/TCR/apple-icon-152x152.png',
                //         '180x180': '/images/icons/TCR/apple-icon-180x180.png',
                //         },
                //         'icon': {
                //         '192x192': '/images/icons/TCR/android-icon-192x192.png',
                //         '32x32': '/images/icons/TCR/android-icon-32x32.png',
                //         '96x96': '/images/icons/TCR/android-icon-96x96.png',
                //         '16x16': '/images/icons/TCR/android-icon-16x16.png',
                //         },
                //     },
                // },
                'VDR': {
                    title: 'La Voce della Ricchezza',
                    icons: {
                        'apple-touch-icon': {
                        '57x57': '/images/icons/VDR/apple-icon-57x57.png',
                        '60x60': '/images/icons/VDR/apple-icon-60x60.png',
                        '72x72': '/images/icons/VDR/apple-icon-72x72.png',
                        '76x76': '/images/icons/VDR/apple-icon-76x76.png',
                        '114x114': '/images/icons/VDR/apple-icon-114x114.png',
                        '120x120': '/images/icons/VDR/apple-icon-120x120.png',
                        '144x144': '/images/icons/VDR/apple-icon-144x144.png',
                        '152x152': '/images/icons/VDR/apple-icon-152x152.png',
                        '180x180': '/images/icons/VDR/apple-icon-180x180.png',
                        },
                        'icon': {
                        '192x192': '/images/icons/VDR/android-icon-192x192.png',
                        '32x32': '/images/icons/VDR/android-icon-32x32.png',
                        '96x96': '/images/icons/VDR/android-icon-96x96.png',
                        '16x16': '/images/icons/VDR/android-icon-16x16.png',
                        },
                    },
                }
            };
          
            // if (!localBrand) return; 
            // const settings: any = brandSettings[localBrand.code];
        
            // document.title = settings.title;
        
            // const metaDescription = document.querySelector('meta[name="description"]');
            // if (metaDescription) {
            //   metaDescription.setAttribute('content', settings.title);
            // }

            // Object.keys(settings.icons).forEach((rel) => {
            //     Object.keys(settings.icons[rel]).forEach((size) => {
            //       const selector = size === 'default' ? `link[rel="${rel}"]` : `link[rel="${rel}"][sizes="${size}"]`;
            //       const links = document.querySelectorAll(selector);
            //       links.forEach(link => {
            //         const htmlLink = link as HTMLLinkElement;
            //         htmlLink.href = settings.icons[rel][size];
            //       });
            //     });
            //   });

        }
        gb();
    }, [location.pathname]);




    if (config.environment === 'production') console.log = function no_console() {}; /////// RIPRISTINARE SEMPRE, SE COMMENTATA, PRIMA DI MANDARE SU DEV O PROD
    
    const isHeaderHidden = (isLandingPath || isPublicPath);
    
    const isLDL = store.getState().ui.brand?.code === 'LDL';
    const isTCR = store.getState().ui.brand?.code === 'TCR';
    const isVDR = store.getState().ui.brand?.code === 'VDR';
    const isMB = store.getState().ui.brand?.code === 'MB';

    if (!useBrand()) return (<></>);

    return (
        <HelmetProvider context={HELMET_CONTEXT}>
            <MinRangeGuard />
            
            <AppBox>
                {!isAppMobile && (
                    <Header
                    isSubmenuOpen={isSubmenuOpen}
                    onSubmenuButtonClick={userSubmenuToggleHandler}
                        onSubmenuLinkClick={userSubmenuCloseHandler}
                    />
                )}

                {isUserCrook && <AccountProblemError />}
                {!isUserCrook && (
                    <Main 
                        isDisabled={!isMainActive}
                        isHeaderHidden={isHeaderHidden}
                        isLDL={isLDL} 
                        isVDR={isVDR}
                        isTCR={isTCR}
                        isMB={isMB}
                    >
                        <PageContainer maxWidth={isOddsScasser ? 'fit-content' : '1440px'}>
                            <BuildRoutes />
                        </PageContainer>

                        {/* {(isSubmenuOpen && isAppMobile) && <PageBackgroundOnSubmenuOpen />} */}
                        {(isSubmenuOpen && isAppMobile) && <UserProfileSubmenuModal onCloseButtonModal={userSubmenuCloseHandler} />}

                        {isFacebookExceptionModalVisible && <FacebookExceptionModal onCloseButtonModal={facebookExceptionModalCloseHandler}/>}
                        {/* <FacebookExceptionModal onCloseButtonModal={facebookExceptionModalCloseHandler}/> */}
                    </Main>
                    )
                }

                {!isAppMobile && <Footer />}

                {isAppMobile && (
                    <TabBar
                        isSubmenuOpen={isSubmenuOpen}
                        onSubmenuButtonClick={userSubmenuToggleHandler}
                        onSubmenuLinkClick={userSubmenuCloseHandler}
                    />
                )}
            </AppBox>

            <SnackbarList />
        </HelmetProvider>
    );
};

export default App;
