import { FC, useState, useEffect } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useIsMounted } from 'usehooks-ts';

// utils
import config from 'configs/config';
import { getUserVipPoints } from 'pages/LDL/HomePageLDL/services/HomePageLDL.services';
import { callGET } from 'network/network';
import { getEnvironmentMonolithLinkUrl, getEnvironmentProductId } from 'configs/config.helper';
import { 
	getMonthNameFromIndex,
	monthToRecover,
	svgToImg,
	svgToImgGraph
} from './LibertyDashboardPage.helper';
import { getHistoricalResults, getUsersCourseList, getUsersServicesList } from './services/LibertyDashboard.services';
import { formattedCurrency } from 'utils/supportFunctions';

// components
import LoadingMask from 'components/ui/LoadingMask';
import ShareModal from './ShareModal/ShareModal.component';
import AnnualTrendChart from './AnnualTrendGraph/AnnualTrendGraph.component';
import RecoverMonthModal from './RecoverMonthModal/RecoverMonthModal.component';

// assets
import { ReactComponent as ShareIconSVG } from './assets/shareIcon.svg';
import { ReactComponent as BonusSVG } from './assets/bonus.svg';
import { ReactComponent as SportTradingSVG } from './assets/sport3ding.svg';
import { ReactComponent as ShureHitSVG } from './assets/colpoSicuro.svg';

// style
import {
    Page,
    CongratulationsRow,
    PageTitle,
    ShareBox,
    CongratulationText,
    GoldText,
    ShareButton,
    ResultShareRow,
    RestultsText,
    RetultsRow,
    ResultBox,
    ResultTitle,
    ResultImage,
    ResultInput,
    ResultButton,
    TotalResult,
    TotalCashResult,
    GraphRow,
    GraphBox,
    GraphCard,
    AddButton
} from './style/LibertyDashboardPage.style';
import store from 'redux/store';


const LibertyDashboardPage: FC<{}> = () => {

    const { products } = useAppSelector(state => state.user);
    const [bonusIncoming, setBonusIncoming] = useState<number>(0);
    const [sureShotIncoming, setSureShotIncoming] = useState<number>(0);
    const [othersIncoming, setOthersIncoming] = useState<number>(0);
    const [totalIncoming, setTotalIncoming] = useState<number>(0);
    const [seasonIncoming, setSeasonIncoming] = useState<number>(0);
    const [annualIncoming, setAnnualIncoming] = useState<number>(0);
    const [last12MonthsIncoming, setLast12MonthsIncoming] = useState<number>(0);
    const [season, setSeason] = useState<'2023' | '2022/2023' | 'last 12 months'>('last 12 months');
    const [historicalData, setHistoricalData] = useState<any[]>([]);
    const [prevMonthData, setPrevMonthData] = useState<any>({});
    // const [courseList, setCourseList] = useState<any>([]);
    // const [servicesList, setServicesList] = useState<any>([]);
    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
    const [hasUserShared, setHasUserShared] = useState<boolean>(false);
    const [shareImg, setShareImg] = useState<any>({});
    // const [isCourseLoading, setIsCourseLoading] = useState<boolean>(true);
    const [isHistoricalLoading, setIsHistoricalLoading] = useState<boolean>(true);
    const [isServicesLoading, setIsServicesLoading] = useState<boolean>(true);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [vipPoints, setVipPoints] = useState<number | string | undefined>(undefined);
    const [unformattedVipPoints, setUnformattedVipPoints] = useState<number>(0);
    const [userVipPathLevel, setUserVipPathLevel] = useState(null);
    const [pathLevel, setPathLevel] = useState<number>(1);
    const [productSubscription, setProductSubscription] = useState<boolean | null>(null);
    const [monthsToRecover, setMonthsToRecover] = useState<any[]>([]);
    const [isRecoverMonthModalVisible, setIsRecoverMonthModalVisible] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const navigate = useNavigate();

    const courseList = products.courses;
    const servicesList = products.subscriptions;

	const closeModalHandler = () => setIsShareModalOpen(false);
	const userHasSharedHandler = () => setHasUserShared(true);
	const getLast12MonthsIncoming = (incoming: number) => setLast12MonthsIncoming(incoming);
    const closeRecoverMonthModaHandler = () => setIsRecoverMonthModalVisible(false);

	const historicalResults = async () => {
		setIsHistoricalLoading(true);
		const prevMonth = new Date().getMonth();
        const year = new Date().getFullYear();

		try {
			const historicalData = await getHistoricalResults();
			if (!isMounted) return;

			if (!historicalData || historicalData === undefined) {
				new Error("Historical Data are undefined");
			}

            if (historicalData.length === 0) {
                new Error("No Historical Data.")    
            };
            
            setHistoricalData(historicalData);
            const prevMonthHistoricalData = historicalData.filter((monthData: any) => (monthData.month === prevMonth) && (monthData.year === year));
            setPrevMonthData(prevMonthHistoricalData[0]);
            setIsHistoricalLoading(false);
            setMonthsToRecover(monthToRecover(historicalData));
            console.log("HIST", historicalData)
            console.log(monthToRecover(historicalData))
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable to get Historical Results", error);
            setIsHistoricalLoading(false);
        }
    };

    // const userCourseList =async () => {
        
    //     setIsCourseLoading(true);

	// 	try {
	// 		const courseListData = await getUsersCourseList();
	// 		if (!isMounted()) return;

	// 		if (courseListData === undefined || !courseListData) {
	// 			throw new Error("Response getting User's course list is undefined");
	// 		}

	// 		setCourseList(courseListData);
	// 		setIsCourseLoading(false);
	// 	} catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
	// 		console.warn("Unable to get User course list.", error);
	// 		setIsCourseLoading(false);
	// 	}
	// };

	// const userServicesList = async () => {
	// 	setIsServicesLoading(true);

	// 	try {
	// 		const servicesListData = await getUsersServicesList();
	// 		if (!isMounted()) return;

	// 		if (servicesListData === undefined || !servicesListData) {
	// 			throw new Error("Response getting User's services list is undefined");
	// 		}

	// 		setServicesList(servicesListData);
	// 		setIsServicesLoading(false);
	// 	} catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
	// 		console.warn("Unable to get User services list.", error);
	// 		setIsServicesLoading(false);
	// 	}
	// };

	const sharingInputValidate = (inputData: any, isOthersData?: boolean) => {
		if (isNaN(inputData)) return 0;
		if (!isOthersData && (inputData < 0)) return 0;
		return inputData;
	};

    const getSeasonIncoming = () => {

        if (!historicalData || historicalData === undefined) return;
        if (historicalData.length === 0) return;
        if (typeof historicalData[0].month !== 'number') return;

        const firstYearData = historicalData.filter((data: any) => data.year === 2022);
        const firstPartOfSeasonData = firstYearData.filter((data: any) => data.month >= 9);
        const firstPartOfYearIncomingData = firstPartOfSeasonData.map((el: any) => el.bonus + el.colpoSicuro + el.tradingSportivo);
        const firstPartOfYearIncoming = firstPartOfYearIncomingData.reduce((partial: number, a: number) => partial + a, 0);
        
        const secondYearData = historicalData.filter((data: any) => data.year === 2023);
        const secondPartOfSeasonData = secondYearData.filter((data: any) => data.month <= 8);
        const secondPartOfYearIncomingData = secondPartOfSeasonData.map((el: any) => el.bonus + el.colpoSicuro + el.tradingSportivo);
        const secondPartOfYearIncoming = secondPartOfYearIncomingData.reduce((partial: number, a: number) => partial + a, 0);

        setSeasonIncoming(+firstPartOfYearIncoming + +secondPartOfYearIncoming)
    };    

    const getAnnualIncoming = () => {

        if (!historicalData || historicalData === undefined) return;
        if (historicalData.length === 0) return;
        if (typeof historicalData[0].month !== 'number') return;

        const yearData = historicalData.filter((data: any) => data.year === 2023);
        const yearIncomingData = yearData.map((el: any) => el.bonus + el.colpoSicuro + el.tradingSportivo);
        const yearIncoming = yearIncomingData.reduce((partial: number, a: number) => partial + a, 0);
        
        setAnnualIncoming(yearIncoming);
    };
    
    const getVipPoints = async () => {
        
        try {
            const userVipPoints = await getUserVipPoints();
            if (!isMounted) return;
            
            if (!userVipPoints || userVipPoints === undefined) return setVipPoints(0);
            
            // console.log("VIPPOINTS:", userVipPoints);
            if (userVipPoints.length === 0) return setVipPoints(0);
            if (userVipPoints.length === 1) return setVipPoints(formattedCurrency(userVipPoints[0].amount, 0));
            
            const amount = userVipPoints.map((el: any) => +el.amount);
            const pointsToSet = amount.reduce((partial: number, next: number) => partial + next);
            setUnformattedVipPoints(pointsToSet);
            setVipPoints(formattedCurrency(pointsToSet, 0));    
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable getting vip points.");
            
        }
    };

    const getUserVipPathLevel = async () => {

        const apiParams = {
            lastMonth: totalIncoming
        };

        try {
            const response = await callGET('v1/self/vippathlevel', true, apiParams);
            setUserVipPathLevel(response);

            if (response && response.pathLevel) {
                setPathLevel(response.pathLevel);
            }
            
            return response.pathLevel

        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            throw error;
        }
    };

    const getLastDayOfPreviousMonth = () => {
        const today = new Date(); // Ottiene la data corrente
        const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Ottiene il primo giorno del mese corrente
        const lastDayOfPreviousMonth = new Date(firstDayOfThisMonth.getTime() - 24 * 60 * 60 * 1000); // Sottrae un giorno al primo giorno di questo mese per ottenere l'ultimo giorno del mese precedente
        lastDayOfPreviousMonth.setHours(23);
        lastDayOfPreviousMonth.setMinutes(59);
        lastDayOfPreviousMonth.setSeconds(59);
        return lastDayOfPreviousMonth.toISOString();
    };

    const checkProductSubscription = async () => {
        const productId = getEnvironmentProductId('cash academy'); 
        const date = getLastDayOfPreviousMonth(); // Sostituisci con la data corretta
        
        const apiParams = {
            brandCode: store.getState().ui.brand?.code,
            validIn: date
        };
        
        try {
            const response = await callGET(`v1/self/productsubscriptions/${productId}/activeindate`, true, apiParams);
            if (!response || response === undefined) throw new Error("Response undefined");
            setProductSubscription(response);
        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            setProductSubscription(false);
            console.warn(error);
        }
    };

    const getLast12MonthIncoming = () => {
        
        if (!historicalData || historicalData === undefined) return;
        if (historicalData.length === 0) return;
        if (typeof historicalData[0].month !== 'number') return;
        console.log("HIST DATA", historicalData);
        
        // setAnnualIncoming();
    };    

    const bonusChangeHandler = (event: any) => {

		const validatedInput = sharingInputValidate(event.target.value);
		setBonusIncoming(validatedInput);
	};

	const bonusClickHandler = () => {
        navigate(`/public/product/${getEnvironmentProductId('bonus automatici')}`)
	};

	const sureShotChangeHandler = (event: any) => {
		setSureShotIncoming(sharingInputValidate(event.target.value));
	};

	const sureShotClickHandler = () => {
        navigate(`/public/product/${getEnvironmentProductId('colpo sicuro')}`)
	};

	const othersChangeHandler = (event: any) => {
		setOthersIncoming(event.target.value);
	};

	const othersBlurHandler = (event: any) => {
		setOthersIncoming(sharingInputValidate(event.target.value, true));
	};

    const tradingClickHandler = () => {
        navigate(`/public/product/${getEnvironmentProductId('3ding guidato servizio')}`)
    };

    const addClickHandler = () => {
        setIsRecoverMonthModalVisible(true);
    };

    const shareClickHandler = async() => {

        console.log("12", finalIncomingForGraph)
        console.log("shareImg", shareImg)
    
        if (!!prevMonthData) {
            
            const graphBoxImage = document.getElementById('graphSVG')?.outerHTML; 
            
            const shareImage = svgToImgGraph(+finalIncomingForGraph, graphBoxImage);
            
            setShareImg(shareImage);
            setIsShareModalOpen(true);
            return
        };
        
        const isVip = (unformattedVipPoints >= 10000);
        if (productSubscription === null) return;
        const cashBadge = productSubscription;
		if (totalIncoming === 0) return;

        const userPathLevel: number = await getUserVipPathLevel();

		const shareImage = svgToImg(
			bonusIncoming,
			sureShotIncoming,
			othersIncoming,
            userPathLevel,
            cashBadge,
            isVip
		);
		setShareImg(shareImage);
		setIsShareModalOpen(true);
	};

	const prevMonthName = () => {

        const currentMonth = new Date().getMonth();
        return getMonthNameFromIndex(currentMonth - 1);
    };
    
    useEffect(() => {

        setTotalIncoming(+bonusIncoming + +sureShotIncoming + +othersIncoming);
        if (historicalData.length === 0) return;
        if (season === '2022/2023') return getSeasonIncoming();
        if (season === '2023') return getAnnualIncoming();
        // if (season === 'last 12 months') return getLast12MonthIncoming();
    }, [bonusIncoming, sureShotIncoming, othersIncoming, hasUserShared, historicalData]);

	useEffect(() => {

		if (!hasUserShared) return;

		setTimeout(() => {
			// userCourseList();
			// userServicesList();
			historicalResults();
			setHasUserShared(false);
		}, 1000);
	}, [hasUserShared]);

	useEffect(() => {

		// userCourseList();
		// userServicesList();
		historicalResults();
        getVipPoints();
        // getUserVipPathLevel();
        checkProductSubscription();
	}, []);

	useEffect(() => {

		if (!prevMonthData || prevMonthData === undefined) return;
		if (prevMonthData.bonus) setBonusIncoming(prevMonthData.bonus);
		if (prevMonthData.colpoSicuro) setSureShotIncoming(prevMonthData.colpoSicuro);
		if (prevMonthData.tradingSportivo) setOthersIncoming(prevMonthData.tradingSportivo);
	}, [prevMonthData, hasUserShared]);

	let userHasBonusActive: boolean = false;
	let userHasOtherActive: boolean = false;
	let userHasSureShotActive: boolean = false;

    userHasBonusActive = (servicesList !== undefined) ? servicesList.some((service: any) => service.id === getEnvironmentProductId('bonus automatici')) : false;
    userHasOtherActive = (bonusIncoming >= 500);
    userHasSureShotActive = (courseList !== undefined) ? courseList.some((service: any) => service.id === getEnvironmentProductId('colpo sicuro')) : false; //colpo sicuro??????

	const initialBonusIncome = bonusIncoming === 0 ? '' : bonusIncoming;
	const initialSureShotIncome = sureShotIncoming === 0 ? '' : sureShotIncoming;
	const initialOthersIncome = othersIncoming === 0 ? '' : othersIncoming;

    const isDataLoading = isHistoricalLoading || isUploading || hasUserShared;
    
    if (isDataLoading) return (
        <LoadingMask 
            isCenter
            height='100%'
            width='100%'
            size='page'
            paddingTop='200px'
        />
    )

    const shareMonthButtonText = (prevMonthData === undefined);
    const shareHistoricalButtonText = !!prevMonthData;
    const isHistoricalGraphVisible = historicalData.length >= 3;
    const finalIncomingForGraph = ((season === '2023') ? annualIncoming : ((season === '2022/2023') ? seasonIncoming : last12MonthsIncoming));
    
    return (
        <>
        <Page>
            <CongratulationsRow>
                <PageTitle>I Miei Guadagni</PageTitle>

                <ShareBox>
                    <CongratulationText><GoldText>
                        COMPLIMENTI (!) Hai iniziato il percorso e stai avendo Risultati.</GoldText><br />
                        Condividi i tuoi risultati per ricevere i feedback della community e creare il grafico dei tuoi guadagni.
                    </CongratulationText>

                    <ShareButton 
                        onClick={shareClickHandler}
                        disabled={(totalIncoming === 0) && !prevMonthData}    
                    >
                        <ShareIconSVG />

                        {shareMonthButtonText && `Condividi sul gruppo`}
                        
                        {shareHistoricalButtonText && `Condividi lo storico`}
                    </ShareButton>
                </ShareBox>
            </CongratulationsRow>

            <ResultShareRow>
                <RestultsText>
                    I risultati di {prevMonthName()}{!prevMonthData && ` che voglio pubblicare`}
                    {prevMonthData && `: questi sono i risultati che hai già pubblicato, continua così!`}
                </RestultsText>

                <RetultsRow>
                    <ResultBox>
                        <ResultTitle isDisabled={!userHasBonusActive}>bonus</ResultTitle>

                        <ResultImage isDisabled={!userHasBonusActive}>
                            <BonusSVG />
                        </ResultImage>

                        {!prevMonthData && 
                        userHasBonusActive && (
                        <ResultInput 
                            value={initialBonusIncome}
                            onChange={bonusChangeHandler}
                        />)}

                        {userHasBonusActive &&
                        prevMonthData && (
                            <ResultInput 
                                value={bonusIncoming}
                                disabled
                            />
                        )}

                        {!userHasBonusActive && <ResultButton onClick={bonusClickHandler}>ACQUISTA(!)</ResultButton>}
                    </ResultBox>

                    <ResultBox>
                        <ResultTitle isDisabled={!userHasSureShotActive}>colpo sicuro</ResultTitle>

                        <ResultImage isDisabled={!userHasSureShotActive}>
                            <ShureHitSVG />
                        </ResultImage>

                        {!prevMonthData && 
                        userHasSureShotActive && (
                        <ResultInput 
                            value={initialSureShotIncome}
                            onChange={sureShotChangeHandler}
                        />)}

                        {userHasSureShotActive &&
                        prevMonthData && (
                            <ResultInput 
                                value={sureShotIncoming}
                                disabled
                            />
                        )}

                        {!userHasSureShotActive && <ResultButton onClick={sureShotClickHandler}>ACQUISTA(!)</ResultButton>}
                    </ResultBox>

                    <ResultBox>
                        <ResultTitle isDisabled={!userHasOtherActive}>altro</ResultTitle>

                        <ResultImage isDisabled={!userHasOtherActive}>
                            <SportTradingSVG />
                        </ResultImage>

                        {!prevMonthData &&
                        userHasOtherActive && (
                        <ResultInput
                            value={initialOthersIncome}
                            onChange={othersChangeHandler}
                            onBlur={othersBlurHandler}
                        />)}

                        {userHasOtherActive && 
                        prevMonthData && (
                            <ResultInput 
                                value={othersIncoming}
                                disabled
                            />
                        )}

                        {/* {!userHasOtherActive && <ResultButton onClick={tradingClickHandler}>ACQUISTA(!)</ResultButton>} */}
                    </ResultBox>
                </RetultsRow>
            </ResultShareRow>

            <TotalResult>
                Totale del mese: <TotalCashResult>{totalIncoming.toLocaleString()} €</TotalCashResult>
            </TotalResult>

            {isHistoricalGraphVisible && (
                <GraphRow>
                    <RestultsText>Storico dei tuoi Risultati</RestultsText>

                    <GraphBox id='graphBox'>                        
                        <GraphCard>
                            <AnnualTrendChart data={historicalData} season={season} getResultsFunction={getLast12MonthsIncoming} />
                        </GraphCard>

                        {/* <GraphCard pieChart></GraphCard> */}
                    </GraphBox>

                    {/* {(monthsToRecover.length > 0) && <AddButton onClick={addClickHandler}>Recupera Mese</AddButton>} */}

                    {(season === '2023') && <TotalResult>
                        Totale dell'anno: <TotalCashResult>{annualIncoming.toLocaleString()} €</TotalCashResult>
                    </TotalResult>}

                    {(season === '2022/2023') && <TotalResult>
                        Totale della stagione: <TotalCashResult>{seasonIncoming.toLocaleString()} €</TotalCashResult>
                    </TotalResult>}                    

                    {(season === 'last 12 months') && <TotalResult>
                        Totale dei tuoi ultimi 12 mesi: <TotalCashResult>{last12MonthsIncoming.toLocaleString()} €</TotalCashResult>
                    </TotalResult>}                    
                </GraphRow>
            )}

        </Page>

			{isShareModalOpen && (
				<ShareModal
					onCloseButtonModal={closeModalHandler}
					prevMonth={prevMonthName()}
					previewShareImg={shareImg}
					resultBonus={bonusIncoming}
					resultColpo={sureShotIncoming}
					resultTrading={othersIncoming}
					refreshFunction={userHasSharedHandler}
					isDataLoading={isDataLoading}
					isHistorical={!!prevMonthData}
				/>
			)}

            {isRecoverMonthModalVisible && (
                <RecoverMonthModal 
                    onCloseButtonModal={closeRecoverMonthModaHandler} 
                    monthsToRecover={monthsToRecover}
                />
            )}
		</>
	);
};

export default LibertyDashboardPage;
