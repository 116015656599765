import styled from 'styled-components';


export const BestOddsResultsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export const EventTitle = styled.div`
    color: white;
    padding: 10px;
    border-bottom: 2px solid #dddddd;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
`;

export const OddsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    column-gap: 10px;
    row-gap: 30px;
    justify-content: center;
`;

export const SelectionContainer = styled.div`
    width: 145px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px;
    border-radius: 5px;
    row-gap: 3px;
    background: rgba(0,0,0,0.3);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.24);
`;

export const SelectionLabel = styled.div`
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3px;
    border-bottom: 1px solid #00000069;
    cursor: pointer;
`;
