import styled from 'styled-components';
import colors from 'style/palette/palette';


export const PageDesktop = styled.div`
    
    width: 100%;
    height: 100%;
    max-width: 1440px;

    margin: 0 auto;
    padding: 40px 40px 0;
`;

export const PageTitle = styled.h1`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    padding: 0 0 0 10px;

    font-size: 28px;
    font-weight: 400;

    color: ${colors.white070};
`;

export const TextSeparator = styled.p`

    margin: 0;
    padding: 0 10px;
`;

export const ProductSubMenu = styled.span`
    color: ${colors.white};
`;

export const ContentRow = styled.div`

    display: flex;
    justify-content: center;
    
    column-gap: 30px;

    margin: 20px 0 0;
    padding: 0;

    position: relative;
    top: 0;

    overflow: visible;
`;

export const ContentCard = styled.div`

    width: 100%;
    height: auto;

    background-color: ${colors.darkGrey};
    border-radius: 15px 15px 0 0;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
`;

export const TextContent = styled.div`

    padding: 30px 40px;
`;
