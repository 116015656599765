import { FC, useEffect, useState } from "react";

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";

// hooks
import useAppDispatch from "hooks/useAppDispatch";
import { useCopyToClipboard } from "usehooks-ts";

// helpers
import { getEnvironmentAssetLinkUrl, getEnvironmentBaseUrl } from "configs/config.helper";
import { TESTIMONIALS_IMAGES } from '../../SureBonusPublicPage.helper';

// components
import MediaPlayer from "components/MediaPlayer";
import ButtonShop from "components/ButtonShop/ButtonShop.component";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import Timer from "components/Timer/Timer.component";
import TestimonialsCarousel from "pages/LandingPage/TestimonialsCarousel/TestimonialsCarousel.component";
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";
import ImageCarouselMobile from "components/Carousel/ImageCarousel/ImageCarouselMobile";

// assets
import { ReactComponent as Vector } from './assets/Vector.svg';
import { ReactComponent as FacebookIconSVG } from '../../assets/facebookGreenIcon.svg';
import { ReactComponent as LifeUpdateSVG } from '../../assets/aggiornamentiavita-icon.svg';
import { ReactComponent as PhoneIconSVG } from '../../assets/whatsappIcon.svg';
import TotalEarningsIMG from '../../assets/totalEarningsIMG.png'
import { ReactComponent as CopySVG } from '../../assets/link-icon.svg';

// style
import { 
    CardPrice, 
    InfoPrice, 
    MediaGrid, 
    VideoImg,
    SecondaCard, 
    TextP, 
    TitleB, 
    TitleBox, 
    TitleCard, 
    TotalPage, 
    TextCheck, 
    FirstTwo,
    CardContent,
    BulletBox,
    TextBulllet,
    ButtonBox,
    BlockText,
    BlockImg,
    BlockRow,
    BlockGroup,
    ProductLimitationRow,
    LimitedSlotBox,
    LimitationDisclaimer,
    LimitedTimeBox,
    LimitedSlots,
    CarouselBox,
    ThreeBlockRow,
    BlockItem,
    IMGItem,
    IMGBox,
    IMGDescription,
    VideoCourseListBox
} from "./style/SureBonusPublicPageMobile.style";

const SureBonusPublicPageMobile: FC<{
    productData: any, 
    onTransferClick: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void
    isUserLoggedIn: boolean,
    onLibertyClick: () => void,
    openBankTransferModal: () => void,
    libertyInfoModalHandler?:() => void,
    paymentTransferID: number | undefined,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?:() => void,
    hasRequire?: boolean,
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    publicPageTransferPrice?: (grossPrice: number) => void,
    openImageHandler?: (imageUrl: string) => void
}> = ({
    productData, 
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isUserLoggedIn,
    onLibertyClick,
    openBankTransferModal,
    libertyInfoModalHandler,
    paymentTransferID,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    openImageHandler
}) => {

    const [product, setProduct] = useState<any>(productData);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductPurchasable, setIsProductPurchasable] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const { name, salesData, subscriptionMaxDate, subscriptions, maxSubscription } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const bulletList = salesData?.bulletList;
    const headLine = salesData?.headLine;
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;
    const salesPage = salesData?.salesPage;
    const blocks = salesPage?.blocks;
    const importantPhrase = salesData?.importantPhrase;

    const freeSlots = maxSubscription - subscriptions;

    const localMedia = {
        src: videoUrl ?? "https://www.viveredipoker.it/Libreria/Creare/Creare_Soldi_Bonus_Quanto_Guadagno.mp4",
        type: "video/mp4"
    };

    const setProductNoMorePurchasable = () => setIsProductPurchasable(false);
    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${productData.id}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    useEffect(() => {
        setProduct(productData);
    }, [productData]);

    const finalName = (name !== null) ? name : "TITOLO PRODOTTO";
    const finalHeadline = (headLine !== null) ? headLine : "HEADLINE";
    const finalBulletList1 = ((bulletList !== null) && (bulletList !== undefined)) ? bulletList[0] : "BULLET LIST";
    const finalBulletList2 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[1]) ? bulletList[1] : "BULLET LIST";
    const finalBulletList3 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[2]) ? bulletList[2] : "BULLET LIST";
    const finalPurchaseButtonText = (purchaseButtonText !== null) ? purchaseButtonText : "";
    const isBulletCardVisible = (headLine !== null) || (bulletList !== null) || (headLine !== undefined) || (bulletList !== undefined);
    const isBulletListVisible = (bulletList !== null) && (bulletList !== undefined);
    const isDescriptionCardVisible = (blocks !== null) && (blocks !== undefined);
    const isimportantPhraseVisible = (importantPhrase !== null) && (importantPhrase !== undefined);
    const productPrice = () => {
        if (product.type === 'SERVICE') return product.productService.paymentPlan.price;
        if (product.type === 'LESSON_PACK') return product.productLessonPack.price;
        return product.productCourse.price;
    };
    const finalImportantPhrase = importantPhrase != null ? importantPhrase : 'IMPORTANT PHRASE';
    const freeSlotText = ((maxSubscription !== null) && (maxSubscription !== undefined)) ? ((freeSlots > 0) ? freeSlots : "Posti Esauriti") : "Non ci sono limiti";
    // const isLimitedSlotBoxVisible = ((maxSubscription !== null) && (maxSubscription !== undefined) && isProductPurchasable);
    // const isLimitedTimeBoxVisible = ((subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined) && (freeSlotText !== 'Posti Esauriti'));
    const isLimitedSlotBoxVisible = (maxSubscription !== null) && (maxSubscription !== undefined);
    const isLimitedTimeBoxVisible = (subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined);
    const isSeatsAvailable = (isNaN(freeSlots)) ? true : ((freeSlots > 0) ? true : false);
    const isOfferValid = (subscriptionMaxDate !== undefined) ? (new Date(`${subscriptionMaxDate}Z`).getTime() > new Date().getTime()) : true;
        
    return(
        <>
            <MediaGrid>
                <MediaPlayer
                    media={localMedia}
                    preload='medatada'
                    poster={videoPosterUrl}
                />
            </MediaGrid>

            <TotalPage>

                <TitleBox>
                    <TitleB>
                        Bonus Sicuro 3.0

                        <CopySVG onClick={copyIntoClipboardHandler} />
                    </TitleB>
                </TitleBox>

                <FirstTwo>
                    <CardPrice>
                        <CardContent>
                            <InfoPrice>
                                <TitleCard>
                                    CREA UNA SECONDA ENTRATA SENZA RISCHIO
                                </TitleCard>
                            
                                <TextP notCenter>
                                    Grazie a <b>BONUS SICURO</b> anche TU puoi avere subito una seconda entrata
                                    Senza Rischio e puoi arrivare a guadagnare le Cifre Record che hanno 
                                    Liberato dal Lavoro tante persone. 
                                    Nel video qui accanto alcune <b>Importanti testimonianze</b>.
                                </TextP>
                                <BulletBox>
                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin>
                                            Senza Rischio
                                        </TextP>
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin>
                                            Senza Vendere Nulla
                                        </TextP>
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin>
                                            Senza dare Soldi a Nessuno
                                        </TextP>
                                    </TextBulllet>
                                </BulletBox>
                                
                                {/* problema 1: buttonShop troppo largo */}
                                <ButtonBox>
                                    <ButtonShop 
                                        PriceProduct={productPrice()} 
                                        TypeOfProduct={product.type}
                                        courseElement={product} 
                                        courseID={product.id} 
                                        payments={product.paymentTypes}
                                        onTransferClick={onTransferClick}
                                        isOpenInModal={isOpenInModal}
                                        buyInfoModalHandler={buyInfoModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={onLibertyClick}
                                        libertyInfoModalHandler={libertyInfoModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        isOnPublicProductPage
                                        modalGetPaymentID={modalGetPaymentID}
                                        productIdHandler = {libertyProductID}
                                        productPriceHandler = {libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        openProductRequisitesModal={openProductRequisitesModal}
                                        hasRequire={hasRequire}
                                        isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                                        publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                        publicPageTransferPrice={publicPageTransferPrice}
                                        vipPoints={vipPoints}
                                    />
                                </ButtonBox>
                            </InfoPrice>
                        </CardContent>
                    </CardPrice>
                </FirstTwo>

                <SecondaCard>              
                    <TitleCard>
                        QUANTO SI GUADAGNA?
                    </TitleCard>

                    <TextP>
                        Questi alcuni dei profitti che vengono pubblicati 
                        quotidianamente nel <b>nostro Gruppo Facebook</b>
                    </TextP>

                    {/* problema 1: CarouselBox troppo largo */}
                    <CarouselBox>
                        <ImageCarouselMobile testimonials={TESTIMONIALS_IMAGES} onClickHandler={openImageHandler} />
                    </CarouselBox>

                    <ThreeBlockRow>
                        <BlockItem>
                            <FacebookIconSVG />

                            <h3>Gruppo Facebook Dedicato</h3>

                            <TextP>
                                All'interno del Gruppo Facebook trovi i nostri
                                Coach specializzati e tutti i nostri utenti più esperti
                                pronti ad <span>aiutarti e supportarti</span>
                            </TextP>
                        </BlockItem>
                        
                        <BlockItem isCentral>
                            <LifeUpdateSVG />

                            <h3>Aggiornamenti a Vita</h3>

                            <TextP>
                                Quando si rendono disponibili nuovi bonus, 
                                il corso si aggiorna, in questo modo puoi <span>guadagnare per sempre.</span>
                            </TextP>
                        </BlockItem>
                        
                        <BlockItem>
                            <PhoneIconSVG />

                            <h3>Una Call Gratuita</h3>

                            <TextP>
                            Un assistente personale ti aiuta a partire col piede giusto 
                            grazie a <span>una lezione privata</span> inclusa nel prezzo del corso.
                            </TextP>
                        </BlockItem>
                    </ThreeBlockRow>

                    <IMGItem>
                        <IMGBox>
                            <img src={getEnvironmentAssetLinkUrl(TotalEarningsIMG)} alt="Guadagni tutti i mesi" />
                        </IMGBox>

                        <IMGDescription>
                            <TitleCard>
                                Guadagni tutti i mesi
                            </TitleCard>

                            <TextP>
                                Le centinaia di risultati che i nostri iscritti pubblicano 
                                ogni mese nel nostro Gruppo Facebook DIMOSTRANO che:
                                <br />
                                <br /> - ogni mese ci sono nuovi profitti
                                <br /> - ogni mese diventi più bravo
                                <br /> - ogni mese guadagni di più
                            </TextP>
                        </IMGDescription>
                    </IMGItem>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={productPrice()} 
                            TypeOfProduct={product.type} 
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                        />
                    </ButtonBox>

                    <TextP>
                        Liberi dal Lavoro ha il massimo rispetto per il tuo tempo e lo considera
                         un bene prezioso, proprio per questo il corso è strutturato per darti <span>RISULTATI SUBITO</span>, 
                         prima ancora di vedere tutti i video.
                    </TextP>

                    <TextP>
                        Si hai capito bene, non devi studiare tutto il corso prima di vedere 
                        i primi soldi, vogliamo che tu abbia <span>profitti nel minor tempo possibile 
                        e col minor sforzo possibile</span>. <br />
                        Ti basta scaricare la lista dei bonus inclusa nel corso, 
                        vedere solo il video con le istruzioni dei bonus più semplici e
                        puoi già fare i primi guadagni...
                    </TextP>

                    <TextP>
                        In questo modo <span>guadagni da subito</span> e guardi i video
                        solo quando ne hai bisogno.
                    </TextP>

                    <TextP>
                        Bonus Sicuro è l'unico metodo di guadagno OnLine che permette a tutti di 
                        avere risultati subito, <span>senza rischiare mai nulla</span>,
                        e senza dare soldi ad altre persone.
                    </TextP>

                    <TextP>
                        A differenza di tutti gli altri Business OnLine che richiedono investimenti,
                        Tempo e Sudore, Bonus Sicuro ti consente di avere risultati misurabili da subito,
                        <span>senza rischiare nemmeno un euro</span> e senza dover fare il venditore.
                    </TextP>

                    <TextP>
                        Conosci altre alternative per crearti una seconda entrata 
                        oggi stesso e che non comportino rischi o grandi investimenti di tempo,
                        denaro ed energia?
                    </TextP>

                    <TextP>
                        <span>
                            Pensi che una possibilità del genere valga il prezzo di una
                            pizza al ristorante per due persone?
                        </span>
                    </TextP>

                    <TextP>
                        Se sei diffidente, non preccuparti, è normale, girano tante fregature
                        e ti confesso che anche i nostri migliori allievi sono partiti diffidenti,
                        ma oggi molti di loro si mangiano le mani per non aver iniziato 
                        prima e aver speso mesi preziosi per prendere una decisione molto semplice:
                    </TextP>

                    <TextP>
                        <span>
                            Investire 48€ su se stessi e cambiare la priopria vita per sempre.
                        </span>
                    </TextP>

                    <ButtonBox paddingBottom='35px'>
                        <ButtonShop 
                            PriceProduct={productPrice()} 
                            TypeOfProduct={product.type} 
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                        />
                    </ButtonBox>

                    <TitleCard>
                        ESPLORA IL CONTENUTO DEL CORSO:
                    </TitleCard>

                    <VideoCourseListBox>
                        <VideoCourseList 
                            courseItem={productData.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos)}
                            collapseHandler={() => {}}
                            isOnPublicPage
                        />
                    </VideoCourseListBox>

                    <ButtonBox paddingTop='10px'>
                        <ButtonShop 
                            PriceProduct={productPrice()} 
                            TypeOfProduct={product.type} 
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                        />
                    </ButtonBox>

                </SecondaCard>
            </TotalPage>
        </>
    );
};

export default SureBonusPublicPageMobile;