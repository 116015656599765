import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const PageOddsScasser = styled.div`

    width: 100%;
    height: 100%;
`;

export const PageOddsScasserContent = styled.div`

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    padding: 10px;

    @media ${devices.mobile} {
        padding: 20px;
        padding-top: 0;
    }
    @media ${devices.tablet} {
        padding: 40px;
        padding-top: 0;
    }
    padding-top: 0;
`;

export const OddsScasserContentContainer = styled.div`

    width: 100%;
    max-width: 100%;
    height: fit-content;
    border-radius: 15px;
    border: none;
    background: ${colors.darkGrey};
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};
    margin-bottom: 70px;
`;

export const OddsScasserSectionContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;
	flex: 1;

    padding: 20px 10px;
    @media ${devices.tablet} {
        padding: 20px;
    }
	width: 100%;
	height: 100%;
    min-height: 290px;

	overflow: visible;
`;

export const SelectorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media ${devices.mobile} {
        gap: 30px;
    }
    margin-top: 20px;
    @media (min-width: 1520px) {
        min-width: 1440px;
        width: 100%;
    }
    @media (max-width: 1519px) {
        width: 1440px;
        max-width: 100%;
    }
    
`;

export const HorizontalScrollContainer = styled.div<{hiddenScrollBar?: boolean}>`
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: ${(props) => props.hiddenScrollBar ? '0px': '5px'};
    scrollbar-width: ${(props) => props.hiddenScrollBar ? 'none': 'thin'};
    scrollbar-color: #919191 transparent;
    /* Scrollbar*/
    ::-webkit-scrollbar {
        background-color:rgba(0,0,0,0);
        width:${(props) => props.hiddenScrollBar ? '0px': '5px'};
        height: ${(props) => props.hiddenScrollBar ? '0px': '5px'};
    }
    ::-webkit-scrollbar-track {
        background-color:rgba(0,0,0,0);
    }
    ::-webkit-scrollbar-track:hover {
        background-color:rgba(0,0,0,0);
    }
    ::-webkit-scrollbar-thumb {
        background-color:#919191;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color:#a6a6a6;
    }
    ::-webkit-scrollbar-button {display:none}
`;

export const VerticalScrollContainer = styled.div<{hiddenScrollBar?: boolean}>`
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding-right: ${(props) => props.hiddenScrollBar ? '0px': '5px'};
    /* Scrollbar*/
    ::-webkit-scrollbar {
        background-color:rgba(0,0,0,0);
        width:${(props) => props.hiddenScrollBar ? '0px': '5px'};
        height: ${(props) => props.hiddenScrollBar ? '0px': '5px'};
    }
    ::-webkit-scrollbar-track {
        background-color:rgba(0,0,0,0);
    }
    ::-webkit-scrollbar-track:hover {
        background-color:rgba(0,0,0,0);
    }
    ::-webkit-scrollbar-thumb {
        background-color:#919191;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color:#a6a6a6;
    }
    ::-webkit-scrollbar-button {display:none}
`;

export const Result = styled.div<{result?: string, notClickable?: boolean}>`
    height: 20px;
    width: 20px;
    line-height: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    transition: background-color .3s;
    background-color: ${(props) => props.result ? (props.result.startsWith('L') ? '#ff4242' : (props.result==='C' ? 'orange' : '#00c100')) : 'gray'};
    cursor: ${(props) => props.notClickable ? 'default' : 'pointer'};
    position: relative;
    ${(props) => props.result && (
        css`
            ::before {
                content: '${props.result==='C' ? '—' : (props.result.length>1 ? props.result.replace('L','') : (props.result==='W' ? 'v' : 'x'))}';
                color: black;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: ${(props.result==='W' || props.result==='L') ? '16px' : (props.result.length>1 ? '14px' : '10px')};
                font-weight: ${(props.result==='W' || props.result==='L') ? '600' : (props.result.length>1 ? '600' : '800')};
            }
        `
    )}
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const ToolButtons = styled.div<{changePosition?: boolean}>`
    display: flex;
    gap: 5px;
    height: 18px;
    position: absolute;
    top: -14px;
    right: 10px;
    ${(props) => props.changePosition && (
        css`
            @media (max-width: 445px) {
                position: relative;
                justify-content: center;
                top: -3px;
                right: 0;
            }
        `
    )}
    
`;

export const ToolButton = styled.button<{backgroundColor?: string, color?: string, width?: string, height?: string, hideOver?: number, hideUnder?: number}>`
    height: ${(props) => props.height ?? '18px'};
    width: ${(props) => props.width ?? 'auto'};
    font-size: 12px;
    font-weight: 400;
    background-color: ${(props) => props.backgroundColor ?? '#b3b3b3'};
    color: ${(props) => props.color ?? 'white'};
    padding: 5px;
    margin: 0;
    border: 1px solid #ffffff59;
    border-radius: 4px;
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    svg path {
        fill: white;
    }
    ${(props) => props.hideOver && (
        css`
            @media (min-width: ${props.hideOver}px) {
                display: none;
            }
        `
    )}
    ${(props) => props.hideUnder && (
        css`
            @media (max-width: ${props.hideUnder}px) {
                display: none;
            }
        `
    )}
    
`;

export const SVGIconContainer = styled.span<{notClickable?: boolean, size?: number, fill?: string, top?: number, right?: number, left?: number, bottom?: number, opacity?: number, inline?: boolean, margin?: string}>`
    display: ${props => props.inline ? 'inline': 'flex'};
    align-items: center;
    height: ${(props) => props.size ? props.size+'px' : '20px'};
    width: ${(props) => props.size ? props.size+'px' : '20px'};
    z-index: 1;
    svg {
        transition: opacity .3s ease;
        opacity: ${(props) => props.opacity ?? '1'};
        cursor: ${(props) => props.notClickable ? 'default' : 'pointer'};
        height: ${(props) => props.size ? props.size+'px' : '20px'};
        width: ${(props) => props.size ? props.size+'px' : '20px'};
        ${(props) => props.fill && css`path {fill: ${props.fill}!important;};`}
    }
    ${(props) => props.notClickable && (css`pointer-events: none;`)};
    ${(props) => props.margin && (css`margin: ${props.margin};`)};
    ${(props) => ([props.top,props.left,props.right,props.bottom].some(x => x!==undefined)) && (css`position: absolute;`)};
    ${(props) => props.top!==undefined && (css`top: ${props.top}px;`)};
    ${(props) => props.right!==undefined && (css`right: ${props.right}px;`)};
    ${(props) => props.left!==undefined && (css`left: ${props.left}px;`)};
    ${(props) => props.bottom!==undefined && (css`bottom: ${props.bottom}px;`)};
`;

export const Select = styled.select<{width?: string, height?: string, backgroundColor?: string}>`
    color: #dedede;
	text-align: center;
	text-align-last: center;
	border-radius: 3px;
    font-size: 14px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    ${(props) => css`background-color: ${props.backgroundColor ?? '#375e7a'};`};
    outline: none;
    height: ${props => props.height ?? '100%'};
    ${(props) => props.width && (css`width: ${props.width};`)}
    :disabled { opacity: 0.5 }
    :focus { 
        border: 1px solid #202a31;
        ${(props) => css`background-color: ${props.backgroundColor ?? '#375e7a'};`};
    }
    option { background: #29353ed6; }
`;

export const Label = styled.label`

`;

export const WinnigsAndRatingContainer = styled.div`
    min-width: 75px;
`;

export const WinnigsAndRating = styled.div<{flexDirection?: 'column'|'row', boxed?: boolean, padding?: string, mobileDirection?: string, justifyContent?: string}>`
    position: relative;
    display: flex;
    padding: ${(props) => props.padding || '0'};
    column-gap: 5px;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || 'center'};
    flex-wrap: nowrap;
    cursor: default;
    max-width: 310px;
    white-space: nowrap;
    color: white;
    font-size: 13px;
    ${(props) => props.boxed && 
        css`
            border-radius: 5px;
            background: #29353E66;
            border: 1px solid ${colors.grey};
        `
    }
    flex-direction: ${(props) => props.mobileDirection || props.flexDirection || 'row'};
    ${(props) => props.mobileDirection && 
        css`
            @media ${devices.mobile} {
                flex-direction: ${props.flexDirection || 'row'};
            }
        `
    }
    
`;
export const Winnings = styled.div<{amount?: number, size?: 's'|'m'|'l', translateY?: number, fontWeight?: number}>`
    font-weight: ${props => props.fontWeight ?? 500};
    color: ${props => props.amount ? (props.amount<0 ? '#ff4242' : (props.amount>0 ? '#00c100' : 'white')) : 'white'};
    font-size: ${(props) => props.size && props.size!=='m' ? (props.size==='l' ? '22px' : '14px') : '18px'};
    ${(props) => props.translateY && (css`transform: translateY(${props.translateY}px);`)};
`;
export const Rating = styled.div<{size?: 's'|'m'|'l', translateY?: number, amount?: number, top?: number, color?: string}>`
    color: ${props => props.color ? props.color : (props.amount ? (props.amount<0 ? '#ff4242' : (props.amount>0 ? '#00c100' : colors.primaryColor)) : colors.primaryColor)};
    font-size: ${(props) => props.size && props.size!=='m' ? (props.size==='l' ? '15px' : '11px') : '12px'};
    ${(props) => props.translateY && (css`transform: translateY(${props.translateY}px);`)};
    cursor: default;
    ${props => props.top!==undefined && css`position: absolute; top: ${props.top}px;`}
`;

export const BetContainer = styled.div<{flexDirection?: 'column'|'row', boxed?: boolean}>`
    display: flex;
    flex-direction: ${(props) => props.flexDirection || 'column'};
    column-gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: default;
    ${(props) => props.boxed && 
        css`
            background: #29353E66;
            border-radius: 5px;
            border: 1px solid ${colors.grey};
        `
    }
`;
export const Bet = styled.div<{translateY?: number}>`
    font-size: 16px;
    color: white;
    font-weight: 500;
    ${(props) => props.translateY && (css`transform: translateY(${props.translateY}px);`)}
`;
export const Resp = styled.div<{translateY?: number}>`
    color: #ffc700;
    font-size: 12px;
    ${(props) => props.translateY && (css`transform: translateY(${props.translateY}px);`)}
`;

export const BetsTable = styled.table`
    @media (max-width: 365px) {
        border-spacing: 0;
    };
`;
export const BetsTableHead = styled.thead`
    cursor: default;
    
`;
export const BetsTableBody = styled.tbody`
    
`;
export const BetsTableRow = styled.tr<{showSvg?: boolean}>`
    position: relative;
    margin-top: 5px;
    svg {
        opacity: ${props => props.showSvg ? '1' : '0'};
    }
    :hover svg {
        opacity: 1;
    }
`;
export const BetsTableHeaderCell = styled.th`
    height: 20px;
    color: white;
    font-size: 13px;
    white-space: nowrap;
    font-weight: 400;
`;
export const BetsTableCell = styled.td`
    
    position: relative;
    height: 36px;
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 365px) {
        padding: 0;
    };
`;
export const BetsTableSingleDivCell = styled.td<{paddingBottom?: string}>`
    padding-bottom: ${props => props.paddingBottom ?? '0'};
`;

export const BetsTableCellDiv = styled.div`
    display: flex;
    gap: 4px;
    @media (max-width: 365px) {
        gap: 0;
    };
`;

export const CoverOdds = styled.div`
    display: flex;
    gap: 4px;
    @media (max-width: 365px) {
        gap: 0;
    };
`;

export const StandardCheckbox = styled.div<{checked: boolean, backgroundColor?: string, width?: string}>`
    
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2px;
    color: white;
    background-color: ${(props) => props.checked ? (props.backgroundColor || '#1b7700') : 'transparent'};
    
    transition: background-color 0.3s ease;

    font-size: 12px;
    white-space: nowrap;
    width: ${props => props.width ?? '100%'};
    padding: 0 10px;
    height: 24px;
    cursor: pointer;

`;

export const StandardCheckboxesContainer = styled.div<{gridTemplateColumns?: string}>`
    display: grid;
    grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
    grid-auto-rows: 24px;
    gap: 5px;
    width: 100%;
`;

export const SelectAll = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${colors.primaryColor};
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 5px;
`;

export const DropdownContent = styled.div<{width?: number, maxWidth?: string}>`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width ? props.width+'px' : 'fit-content'};
    ${(props) => props.maxWidth && (css`max-width: ${props.maxWidth};`)}
`;

export const ArrowsContainer = styled.div`
    width: 18px;
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const CTALabel = styled.div<{fontSize?: string, fontWeight?: string, color?: string}>`
    color: ${props => props.color ?? 'white'};
    font-size: ${props => props.fontSize ?? '16px'};
    font-weight: ${props => props.fontWeight ?? '500'};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;

export const CTABox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    width: 160px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: ${colors.black020} 0px 3px 1px -2px, ${colors.black014} 0px 2px 2px, ${colors.black012} 0px 1px 5px;
    background-color: rgb(60 70 78);
`;

export const CTAButton = styled.a`

    color: black;
    margin: 0;
    padding: 15px 20px;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 5px;
    border: none;
    background-color: ${colors.primaryColor};

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    cursor: pointer;
`;

export const ExpirationLabel = styled.span`
    font-size: 14px;
    color: ${colors.primaryColor};
`;

export const RenewLabel = styled.span`
    font-size: 11px;
    color: white;
    text-align: center;
`;

export const PostFBButton = styled.a`
    @media ${devices.tablet} {
        right: 40px;
    }
    width: 170px;
    height: 24px;
    padding: 0;
    background-color: #0B84EE;
    color: white;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-radius: 4px;
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
`;

export const ExpirationCTAButton = styled.a`
    height: 16px;
    padding: 0;
    background-color: ${colors.primaryColor};
    color: black;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    margin: 0;
    padding: 5px;

    border: none;
    border-radius: 4px;
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    
`;

export const ExpirationContainer = styled.div`
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    @media ${devices.tablet} {
        height: 29px;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const ExpirationCTAContainer = styled.div`
    height: 29px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
`;

export const OldVersion = styled.div`
    color: white; 
    margin-bottom: 20px;
    text-align: center;
`;

export const SiteBox = styled.div<{checked?: boolean, name?: string, notClickable?: boolean, width?: number, height?: number, backgroundColor?: string}>`
    
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${(props) => props.backgroundColor ?? 'transparent'};
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    height: ${(props) => props.height ? props.height+'px' : '100%'};
    width: ${(props) => props.width ? props.width+'px' : '100%'};
    min-width: ${(props) => props.width ? props.width+'px' : '100%'};
    cursor: ${(props) => props.notClickable ? 'default' : 'pointer'};
    ${(props) => !props.checked && props.name && (
        css`            
            ::before {
                content: '${props.name}';
                position: absolute;
            }
        `
    )}
`;

export const SiteLogoContainer = styled.div<{visible?: boolean, backgroundColor?: string}>`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => props.visible ? '1' : '0'};
    background-color: ${(props) => props.backgroundColor ?? '#000000a3'};
    transition: opacity 0.3s;
`;

export const SiteLogo = styled.div<{imageUrl?: string, width?: string}>`
    width: ${(props) => props.width ? props.width : '80%'};
    height: 100%;
    background-image: url(${(props) => props.imageUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
    
`;

export const SitesCheckboxes = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 24px;
    gap: 5px;
    width: 100%;
`;

export const SiteAndOdds = styled.div`
    display: grid;
    grid-template-columns: 80px 40px;
    grid-template-rows: 19px;
    justify-items: center;
    align-items: center;
    column-gap: 5px;
`;

export const SiteSelectionAndOdds = styled.div`
    display: grid;
    grid-template-columns: 80px 20px 40px;
    grid-template-rows: 19px;
    justify-items: center;
    align-items: center;
    column-gap: 5px;
`;

export const Selection = styled.div`
    color: white;
    font-size: 13px;
    cursor: default;
    border-radius: 2px;
    background: #243038;
    width: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
`;

export const OddsValue = styled.div`
    color: white;
    font-size: 15px;
    cursor: default;
`;

export const CoverInstructionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
`;

export const CoverInstructions = styled.div`
    display: flex;
    gap: 2px;
`;

export const CoverInstructionsLabelContainer = styled.div<{withSite?: boolean, backgroundColor?: string}>`
    height: 36px;
    width: ${props => props.withSite ? 'calc(100% - 62px)' : '100%'};
    background: ${props => props.backgroundColor ?? '#3A454E'};
    border-radius: 5px;
    border: 1px solid #29353E;
    padding: 0 5px;
`;

export const CoverInstructionsLabel = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;
    color: white;
    height: 36px;
    width: fit-content;
    margin: 0 auto;
`;

export const CoverInstructionsText = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 11px;
    @media ${devices.mobile} {
        font-size: 13px;
    }
`;

export const CoverInstructionsStrongText = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
`;

export const SavedElementCheckbox = styled.div<{checked: boolean, backgroundColor?: string}>`
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 20px;
    margin-right: 24px;
    border-radius: 2px;
    color: white;
    background-color: ${(props) => props.checked ? (props.backgroundColor || '#1b7700') : 'transparent'};
    
    transition: all 0.3s ease;

    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 24px;

    overflow: visible;
    svg {
        position: absolute;
        top: 3px;
        right: -24px;
        cursor: pointer;
        height: 18px;
        width: 18px;
    }
`;

export const SavedElementsContainer = styled.div<{gridTemplateColumns?: string}>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    
    width: 100%;
`;

export const Flex = styled.div<{direction?: string, jusifyContent?: string, alignItems?: string, gap?: string}>`
    display: flex;
    flex-direction: ${props => props.direction ?? 'row'};
    justify-content: ${props => props.jusifyContent ?? 'center'};
    align-items: ${props => props.alignItems ?? 'center'};
    gap: ${props => props.gap ?? '5px'};
    width: 100%;
`;

export const SingleElement = styled.div<{isDisabled?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 5px;
    background: #3A454E;
    border-radius: 5px;
    border: 1px solid #29353E;
    width: 100%;
    max-width: 610px;
    transition: opacity 0.3s ease-in-out;
    ${(props) => props.isDisabled && (
        css`
            opacity: 0.5;
        `
    )}
`;

export const SingleElementEventRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    gap: 5px;
    height: 20px;
`;

export const SingleElementEvent = styled.div`
    white-space: nowrap;
    width: fit-content;
    color: white;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    flex-grow: 1;
    cursor: default;
`;

export const SingleElementOddsMatchRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5px;
    width: 100%;
`;

export const SingleElementOdds = styled.div<{breakPoint?: string}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 2px;
    ${(props) => props.breakPoint && (
        css`
            @media ${props.breakPoint} {
                flex-direction: column;
            };
        `
    )}
    
`;

export const SingleElementCell = styled.div<{breakPoint?: string}>`
    height: 36px;
    ${(props) => props.breakPoint && (
        css`
            @media ${props.breakPoint} {
                height: 24px;
            };
        `
    )}
    
`;

export const SingleElementOddsSelection = styled.div<{breakPoint?: string}>`

    width: fit-content;
    height: 36px;
    ${(props) => props.breakPoint && (
        css`
            @media ${props.breakPoint} {
                width: 60px;
                height: 24px;
            };
        `
    )}
    max-width: 60px;
    min-width: 24px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background: #243038;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
`;

export const SingleElementOddsCovers = styled.div`
    display: flex;
    align-items: center; 
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 5px;
`;

export const SingleElementOddsCoversContainer = styled.div`
    display: flex;
    align-items: center; 
    flex-wrap: nowrap;
    gap: 5px;
`;
