import { FC, useEffect, useState } from 'react';

// utils
import { getLimitTime } from './Timer.helper';

// types
import { TimerProps } from './Timer.component.d';

// style
import { 
    TimerBox,
    CounterBox,
    TimeNumber,
    TimeDeclaration
} from './style/Timer.style';


const Timer: FC<TimerProps> = ({ 
    limitTime,
    timerCount,
    productPurchasableHandler,
    color,
    size
}) => {
    
    const [daysCounter, setDaysCounter] = useState<number | undefined>(undefined);
    const [hoursCounter, setHoursCounter] = useState<number | undefined>(undefined);
    const [minutesCounter, setMinutesCounter] = useState<number | undefined>(undefined);
    const [secondsCounter, setSecondsCounter] = useState<number | undefined>(undefined);
    const [isOfferExpired, setIsOfferExpired] = useState<boolean>(false);
    
    useEffect(() => {

        if (!limitTime) return;
        
        const timeOBJ = getLimitTime(limitTime, timerCount);
        if ((timeOBJ.days! < 0) || (timeOBJ.hours! < 0) || (timeOBJ.minutes! < 0) || (timeOBJ.seconds! < 0)) {
         
            setIsOfferExpired(true);
            if (productPurchasableHandler) productPurchasableHandler();
            return;
        }
        setDaysCounter(timeOBJ.days);
        setHoursCounter(timeOBJ.hours);
        setMinutesCounter(timeOBJ.minutes);
        setSecondsCounter(timeOBJ.seconds);
    } ,[limitTime]);

    useEffect(() => {
        if (daysCounter === undefined) return;
        if (minutesCounter === undefined) return;
        if (secondsCounter === undefined) return;
        if (minutesCounter === undefined) return;
        
        const timerInterval = setInterval(() => {

            if (secondsCounter === 0) {

                if (minutesCounter === 0) {

                    if (hoursCounter === 0) {

                        if (daysCounter === 0) {

                            setIsOfferExpired(true);
                            if (productPurchasableHandler) productPurchasableHandler();
                            return () => clearInterval(timerInterval);
                        };
                        setDaysCounter(prevState => prevState! - 1);
                        setHoursCounter(23);
                        setMinutesCounter(59);
                        setSecondsCounter(59);
                        return;
                    };

                    setHoursCounter(prevState => prevState! - 1);
                    setMinutesCounter(59);
                    setSecondsCounter(59);
                    return;
                };

                setMinutesCounter(prevState => prevState! - 1);
                setSecondsCounter(59);
                return;
            };

        setSecondsCounter(prevState => prevState! - 1);
        }, 1000);
        return () => clearInterval(timerInterval);
    } ,[secondsCounter, minutesCounter, hoursCounter, daysCounter]);

    const isDaysCounterVisible = (timerCount === 'DAYS');
    const isHoursCounterVisible = (timerCount === 'DAYS') || (timerCount === 'HOURS');
    const isMinutesCounterVisible = (timerCount === 'DAYS') || (timerCount === 'HOURS') || (timerCount === 'MINUTES');

    if (!limitTime) return (

        <TimerBox size={size} color={color}>
            <TimeDeclaration size={size}>
                Data di fine offerta non disponibile...
            </TimeDeclaration>
        </TimerBox>
    );

    if (isOfferExpired) return (

        <TimerBox size={size} color={color}>
            <TimeDeclaration size={size}>
                Offerta scaduta...
            </TimeDeclaration>
        </TimerBox>
    );

    return (
        <TimerBox size={size} color={color}>
            {isDaysCounterVisible && (
                <CounterBox>
                    <TimeNumber size={size}>
                        {daysCounter}
                    </TimeNumber>

                    <TimeDeclaration size={size}>
                        giorni
                    </TimeDeclaration>
                </CounterBox>
                )
            }

            {isHoursCounterVisible && (
                <CounterBox>
                    <TimeNumber size={size}>
                        {hoursCounter}
                    </TimeNumber>

                    <TimeDeclaration size={size}>
                        ore
                    </TimeDeclaration>
                </CounterBox>
                )
            }

            {isMinutesCounterVisible && (
                <CounterBox>
                    <TimeNumber size={size}>
                        {minutesCounter}
                    </TimeNumber>

                    <TimeDeclaration size={size}>
                        minuti
                    </TimeDeclaration>
                </CounterBox>
                )
            }

            <CounterBox>
                <TimeNumber size={size}>
                    {secondsCounter}
                </TimeNumber>

                <TimeDeclaration size={size}>
                    secondi
                </TimeDeclaration>
            </CounterBox>

        </TimerBox>
    );
};

export default Timer;