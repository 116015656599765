import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette';
import styled from 'styled-components';

export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 35px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: center;

        max-width: 1200px;

        padding: 35px 45px 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const Title = styled.h4`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    color: ${colors.white};
    text-align: center;
`;

export const MonthToRecoverButton = styled.select`
   
    background: ${colors.transparent};
    border: none;
    border-bottom: 1px solid ${colors.white070};

    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${colors.primaryColor};

    :focus {
        outline: none;
    }
`;

export const MonthToRecover = styled.option`

    background: ${colors.veryDarkGrey};

    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${colors.primaryColor};
`;

export const IncomingContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 40px;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const IncomingBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
`;

export const IncomingType = styled.p`

    margin: 0;
    padding: 0;

    color: ${colors.primaryColor};
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
`;

export const InputBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 0;

    background: ${colors.darkGrey};
    border-bottom: 1px solid ${colors.white042};
    border-radius: 4px 4px 0 0;
    color: ${colors.white070};
`;

export const IncomingInput = styled.input`

    max-width: 100px;

    padding: 10px;

    background: ${colors.darkGrey};

    font-size: 25px;
    color: ${colors.white};
    border: none;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    text-align: right;

    :focus {
        outline: none;
    }
`;

export const RecoverButton = styled(Button)``;
