import { FC, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// redux
import { logout } from 'redux/actions/actions-user';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// components
import BaseFieldset from 'components/ui/BaseFieldset';
import FacebookLoginButton from 'components/ui/FacebookLoginButton';
import EmptyUserImageProfile from 'components/EmptyUserImageProfile';

// assets
import { ReactComponent as UserIcon } from './assets/user-icon.svg';

// style
import { UserImageProfile } from '../style/AccountPage.style';
import { Form, Content, UserNameRow, LoginRow } from './style/FormEditFacebookAccount.style';
import { facebookConnect } from 'services/facebook.services';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Facebook account edit form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */

const FormEditFacebookAccount: FC<{ userData: any }> = ({userData}) => {

    const { email } = useAppSelector(state => state.user)
    const [finalUserName, setFinalUserName] = useState<string | undefined>(undefined);
    const [facebookProfilePicture, setFacebookProfilePicture] = useState<string | undefined>(undefined);
    // const dispatch = useAppDispatch();
    // const {
        // name: userName,
        // surname: userSurname,
        // isLoggedInWithFacebook: isUserLoggedInWithFacebook,
        // facebookProfilePicture
        // } = useAppSelector(state => state.user);
        
    // const facebookLoginHandler = () => {
    //     Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
    // };
    
    const facebookButtonClickHandler = () => {

        // dispatch(logout());
        if (!userData) {
            if (!email || email === undefined) return;
            // dispatch( logout() );
            facebookConnect(false, email);
            return;
        }

        // if facebook account is not linked to a cognito account then call new LDL backend
        // try {
            
        // } catch (error: any) {
        //     console.warn(error);
        // }
    };

    useEffect(() => {
        if (!userData || userData === undefined) return setFinalUserName("NO_DATA");
        setFinalUserName(userData.nameOnFederated);
        
        if (!userData.pictureOnFederated || userData.pictureOnFederated === undefined) return;
        setFacebookProfilePicture(JSON.parse(userData.pictureOnFederated).data.url);  
    }, [userData]);
    
    const isLoading = (finalUserName === undefined);

    if (isLoading) return (
        <LoadingMask 
            size='medium'
            isCenter
            paddingTop='50px'   
        />
    );

    return (
        <Form>
            <BaseFieldset legend="Account Facebook">
                <Content>
                    {userData && (
                        <UserImageProfile
                            flavor='facebook'
                            src={facebookProfilePicture}
                            alt="User profile picture"
                        />
                    )}

                    {userData && (
                        <UserNameRow>
                            <UserIcon />

                            <span>
                                {finalUserName}
                            </span>
                        </UserNameRow>
                    )}

                    {!userData && (
                        <EmptyUserImageProfile
                            flavor='facebook'
                            isEditButtonDisabled
                        />
                    )}

                    {!userData &&(
                        <LoginRow>
                            <FacebookLoginButton onClick={facebookButtonClickHandler}>
                                Collega Facebook
                            </FacebookLoginButton>
                        </LoginRow>
                    )}
                </Content>
            </BaseFieldset>
        </Form>
    );
};

export default FormEditFacebookAccount;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

