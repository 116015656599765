import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const VideoPlayerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: relative;
  min-height: 162px;

  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;

    border: none;
  }
`;

export const VideoElement = styled.video`
  /* position: absolute;
  top: 0;
  left: 0; */
  /* width: 100%;
  height: 100%; */
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: none;
  width: 15%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.transparent};

  cursor: pointer;
`;

export const VideoImg = styled.img<{isIntoCarousel?: boolean}>`

  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0 0;

  cursor: pointer;

  ${({isIntoCarousel}) => isIntoCarousel && (

    css`
      border-radius: 10px;
    `
  )}
  
  @media ${devices.tablet} {
    border-radius: 15px 0 0 15px;

    ${({isIntoCarousel}) => isIntoCarousel && (

      css`
        height: 340px;
      `
    )}
  }
`;
