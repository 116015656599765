import { FC, useState, useEffect } from 'react';

//types
import SwitchSelectorBigProps from './SwitchSelectorBig.component.d'

//style
import {
    SwitchBox,
    SwtichActiveBg,
    SwitchOption
} from './style/SwitchSelectorBig.style';




const SwitchSelectorBig: FC<SwitchSelectorBigProps> = ({mode, option1, option2, isOption1Active, width, height, padding, isDisabled, isDisabledOpacity, onSwitch}) => {

    // const [isOption1Active, setIsOption1Active] = useState(true);

    // useEffect(() => {
    //     onSwitch && onSwitch(isOption1Active);
    // }, [isOption1Active]);

    return (
        <SwitchBox width={width} height={height} isDisabled={isDisabled===true} isDisabledOpacity={isDisabledOpacity}>
            <SwtichActiveBg isOption1Active={isOption1Active} padding={padding ?? 5}></SwtichActiveBg>
            <SwitchOption isActive={isOption1Active} isOption1={true} isDisabled={isDisabled===true} onClick={() => isDisabled!==true && onSwitch && onSwitch(true)} padding={padding ?? 5}>{option1}</SwitchOption>
            <SwitchOption isActive={!isOption1Active} isDisabled={isDisabled===true} onClick={() => isDisabled!==true && onSwitch && onSwitch(false)} padding={padding ?? 5}>{option2}</SwitchOption>
        </SwitchBox>
    );
};

export default SwitchSelectorBig;