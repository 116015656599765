import styled from 'styled-components'; 
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';



export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px 20px 20px;
    gap: 35px;

    @media ${devices.tablet}{
        
        gap: 40px;
        padding: 40px;
    }
`;

export const Selection = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0px;
    /* gap: 40px; */

    width: 100%;
    height: auto;

    /* @media ${devices.tablet}{
        
        padding: 0px;
        gap: 40px;
    } */
`;

//
export const HelpCardBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 20px;

    width: 100%;
    padding: 0px;

    @media ${devices.tablet}{       
    
    }
`;

export const Help = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
    
        align-items: stretch;
        justify-content: flex-start;
        
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }
`;

export const HelpTitle = styled.h2`

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 0px;
    padding: 0px 10px;

    font-weight: 400;
    font-size: 22px;

    color: ${colors.white};

    @media ${devices.tablet}{       
    
        padding: 0px 0px 0 10px;
    }
`;

//
export const CoachCardBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
    
    }
`;

export const Coach = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{      

        align-items: stretch;
        justify-content: flex-start;
        
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        padding: 0px;
    }
`;

export const CoachTitle = styled.h2`

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 0px;
    padding: 0px 10px;
    font-weight: 400;
    font-size: 22px;

    color: ${colors.white};

    @media ${devices.tablet}{       
    
        padding: 0px 0px 0px 10px;
    }
`;

