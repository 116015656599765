import styled, { css } from 'styled-components';
import { Button } from 'style/buttons';

export const SearchButton = styled(Button)`
    height: 28px;
    padding: 5px;
    font-size: 12px;
`;

export const ChooseButton = styled(Button)`
    height: 28px;
    padding: 5px;
    font-size: 12px;
    margin: 20px 0px 10px 0px;
`;

export const MultipleSelectorContainer = styled.div<{readOnly?: boolean}>`
    width: auto;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: 'center';
    justify-content: 'flex-start';
    gap: 20px;
    flex-direction: column;
    pointer-events: ${(props) => props.readOnly ? 'none' : 'auto'};
`;