import { FC, FormEvent, useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// routing
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// utils
import {
    validateEmailInput,
    validatePasswordInput,
    validateRepeatedPasswordInput
} from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { formatSearchParam } from '../SignUp.helper';
import { signUp } from './services/SignUpForm.services';
import { SignUpPayload } from './services/SignUpForm.services.d';
import {
    validateNameInput,
    validateSurnameInput,
    validatePrivacyAndCookiesCheckboxInput
} from './SignUpForm.helper';

// components
import TextInput from 'components/ui/TextInput';
import CheckboxInput from 'components/ui/CheckboxInput';
import FacebookLoginButton from 'components/ui/FacebookLoginButton';

// style
import { Row } from 'style/layout';
import {
    Form,
    UserInputsBox,
    LoginButton,
    PrivacyAndCookiesCheck,
    PrivacyAndCookiesText,
    NameInput,
    PasswordInput,
    CheckBoxErrorMessage,
    CheckBoxRow,
    LoginReturnLink
} from './style/SignUpForm.style';
import useAppSelector from 'hooks/useAppSelector';


/**
 * Sign up form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const SignUpForm: FC<{}> = () => {

    const { isUserDisplayingFromAppMobile } = useAppSelector(state => state.user)
    const [nameInput, setNameInput] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>(undefined);
    const [surnameInput, setSurnamelInput] = useState<string>("");
    const [surnameErrorMessage, setSurnameErrorMessage] = useState<string | undefined>(undefined);
    const [emailInput, setEmailInput] = useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
    const [passwordInput, setPasswordInput] = useState<string>("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>(undefined);
    const [repeatedPasswordInput, setRepeatedPasswordInput] = useState<string>("");
    const [repeatedPasswordErrorMessage, setRepeatedPasswordErrorMessage] = useState<string | undefined>(undefined);
    const [isPrivacyAndCookiesInputChecked, setIsPrivacyAndCookiesInputChecked] = useState<boolean>(false);
    const [privacyAndCookiesErrorMessage, setPrivacyAndCookiesErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const validateNameHandler = (newName: string) => {

        const error = validateNameInput(newName);
        setNameErrorMessage(error);

        return error;
    };

    const nameInputChangeHandler = (newName: string) => {

        validateNameHandler(newName);
        setNameInput(newName);
    };

    const validateSurnameHandler = (newSurname: string) => {

        const error = validateSurnameInput(newSurname);
        setSurnameErrorMessage(error);

        return error;
    };

    const surnameInputChangeHandler = (newSurname: string) => {

        validateSurnameHandler(newSurname);
        setSurnamelInput(newSurname);
    };

    const validateEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setEmailErrorMessage(error);

        return error;
    };

    const emailInputChangeHandler = (newEmail: string) => {

        validateEmailHandler(newEmail);
        setEmailInput(newEmail);
    };

    const validatePasswordHandler = (newPassword: string) => {

        const error = validatePasswordInput(newPassword);
        setPasswordErrorMessage(error);

        return error;
    };
    
    const passwordInputChangeHandler = (newPassword: string) => {
        
        validatePasswordHandler(newPassword);
        setPasswordInput(newPassword);
    };
    
    const validateRepeatedPasswordHandler = (newRepeatedPassword: string, prevPassword: string) => {

        const error = validateRepeatedPasswordInput(newRepeatedPassword, prevPassword);
        setRepeatedPasswordErrorMessage(error);

        return error;
    };
    
    const repeatedPasswordInputChangeHandler = useCallback((newRepeatedPassword: string) => {

        validateRepeatedPasswordHandler(newRepeatedPassword, passwordInput);
        setRepeatedPasswordInput(newRepeatedPassword);

    }, [passwordInput]);

    const validatePrivacyAndCookiesHandler = (newIsChecked: boolean) => {

        const error = validatePrivacyAndCookiesCheckboxInput(newIsChecked);
        setPrivacyAndCookiesErrorMessage(error);

        return error;
    };

    const privacyAndCookiesInputHandler = (newIsChecked: boolean) => {
        
        validatePrivacyAndCookiesHandler(newIsChecked);
        setIsPrivacyAndCookiesInputChecked(newIsChecked);
    };

    /* optional */
    const cleanFormInputs = () => {

        setNameInput("");
        setNameErrorMessage(undefined);
        setSurnamelInput("");
        setSurnameErrorMessage(undefined);
        setEmailInput("");
        setEmailErrorMessage(undefined);
        setPasswordInput("");
        setPasswordErrorMessage(undefined);
        setRepeatedPasswordInput("");
        setRepeatedPasswordErrorMessage(undefined);
        setIsPrivacyAndCookiesInputChecked(false);
    };

    const formSubmitHandler = useCallback( async (event: FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        if (isLoading) return;
        const nameError = validateNameHandler(nameInput);        
        if (nameError) return;
        const surnameError = validateSurnameHandler(surnameInput);
        if (surnameError) return;
        const emailError = validateEmailHandler(emailInput);
        if (emailError) return;
        const passwordError = validatePasswordHandler(passwordInput);
        if (passwordError) return;
        const repeatedPasswordError = validateRepeatedPasswordHandler(repeatedPasswordInput, passwordInput);
        if (repeatedPasswordError) return;
        const privacyAndCookiesError = validatePrivacyAndCookiesHandler(isPrivacyAndCookiesInputChecked);
        if (privacyAndCookiesError) return;
        if (!executeRecaptcha) return;

        const snackbarData: SnackbarOptions = {};
        const token = await executeRecaptcha('signUp');

        setIsLoading(true);
        
        try {
            
            const userPayload: SignUpPayload = {

                name: nameInput,
                surname: surnameInput,
                email: emailInput,
                password: passwordInput,
                validationToken: token
            };

            const response = await signUp(userPayload);
            if (!isMounted()) return;

            if (response === undefined || !response) {
                throw new Error();
            }

            if (!response.userConfirmed) {

                cleanFormInputs();
                snackbarData.type = 'success';
                snackbarData.message = "Ti abbiamo inviato un'email per completare la registrazione."
                dispatch( addSnackbar(snackbarData) );
                setIsLoading(false);
                const refSearchParam = searchParams.get("ref");
                const formattedRefSearchParam = formatSearchParam("ref", refSearchParam);
                navigate(`/sign-up/confirm-sign-up${formattedRefSearchParam}`, { replace: true });

                return;
            }

            cleanFormInputs();
            snackbarData.type = 'success';
            snackbarData.message = "Registrazione effettuata correttamente";
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
            navigate('/login');
            
        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    }, [dispatch, emailInput, executeRecaptcha, isLoading, isMounted, isPrivacyAndCookiesInputChecked, nameInput, navigate, passwordInput, repeatedPasswordInput, searchParams, surnameInput]);

    const facebookSignupHandler = () => {
        localStorage.setItem('fromFBsignup', 'true');
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
    };

    return (
        <>
            <Form onSubmit={formSubmitHandler}>
                <UserInputsBox>
                    <NameInput>
                        <TextInput
                            label="Nome:"
                            type="text"
                            flavor='filled'
                            value={nameInput}
                            errorMsg={nameErrorMessage}
                            onChange={nameInputChangeHandler}
                            onValidate={() => validateNameHandler(nameInput)}
                        />

                        <TextInput
                            label="Cognome:"
                            type="text"
                            flavor='filled'
                            value={surnameInput}
                            errorMsg={surnameErrorMessage}
                            onChange={surnameInputChangeHandler}
                            onValidate={() => validateSurnameHandler(surnameInput)}
                        />
                    </NameInput>
                    
                    <TextInput
                        label="Email:"
                        type="text"
                        flavor='filled'
                        value={emailInput}
                        errorMsg={emailErrorMessage}
                        onChange={emailInputChangeHandler}
                        onValidate={() => validateEmailHandler(emailInput)}
                    />

                    <PasswordInput>
                        <TextInput
                            label="Password:"
                            type="password"
                            flavor='filled'
                            value={passwordInput}
                            errorMsg={passwordErrorMessage}
                            onChange={passwordInputChangeHandler}
                            onValidate={() => validatePasswordHandler(passwordInput)}
                        />

                        <TextInput
                            label="Conferma Password:"
                            type="password"
                            flavor='filled'
                            value={repeatedPasswordInput}
                            errorMsg={repeatedPasswordErrorMessage}
                            onChange={repeatedPasswordInputChangeHandler}
                            onValidate={() => validateRepeatedPasswordHandler(repeatedPasswordInput, passwordInput)}
                        />
                    </PasswordInput>
                </UserInputsBox>

                <PrivacyAndCookiesCheck>
                    <CheckBoxRow>
                        <CheckboxInput
                            isChecked={isPrivacyAndCookiesInputChecked}
                            onChange={privacyAndCookiesInputHandler}
                            isError={!!privacyAndCookiesErrorMessage}
                        />

                        <PrivacyAndCookiesText>
                            Ho letto e accettato i documenti su{" "}
                            <Link to='/privacy'>Privacy</Link>
                            ,{" "}
                            <Link to='/terms'>T&C</Link>
                            {" "}e{" "}
                            <Link to='/cookies'>Cookies</Link>
                        </PrivacyAndCookiesText>
                    </CheckBoxRow>

                    {privacyAndCookiesErrorMessage && (
                        <CheckBoxErrorMessage>
                            {privacyAndCookiesErrorMessage}
                        </CheckBoxErrorMessage>
                    )}
                </PrivacyAndCookiesCheck>

                <LoginButton
                    type="submit"
                    value="login"
                    disabled={isLoading}
                >
                    Registrati ora
                </LoginButton>
                
            </Form>

            {!isUserDisplayingFromAppMobile && (
                <Row margin='24px 0 0 0'>
                    <FacebookLoginButton onClick={facebookSignupHandler}>
                        Registrati con Facebook
                    </FacebookLoginButton>
                </Row>
                )
            }

            <LoginReturnLink>
                <Link to="/login">
                    Ritorna al Login
                </Link>
            </LoginReturnLink>
        </>
    )
};

export default SignUpForm;
