import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const FormEditAccountBox = styled.div`
    
    width: 100%;
`;

export const Content = styled.div`

    width: 100%;
    height: 100%;

    padding: 27px 32px 30px 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media ${devices.tablet} {

        padding: 27px 32px 20px 16px;
        justify-content: flex-start;
    }
`;

export const Form = styled.form`

    width: 100%;

    margin: 10px 0 0;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    row-gap: 20px;
    column-gap: 10px;
    flex-direction: column;

    @media ${devices.desktop} {

        align-items: center;
        flex-direction: row;
        row-gap: 30px;
    }
`;

export const FormPhoneNumberBox = styled.div`

    width: 100%;

    margin: 40px 0 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    gap: 10px;
`;

export const PhoneTextTitle = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 15px;

    color: ${colors.white};
`;

export const PhoneNumber = styled.p`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 15px;

    color: rgba(255, 255, 255, 0.56);
`;
