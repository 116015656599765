import LoadingMask from "components/ui/LoadingMask";
import { FC, useEffect, useState } from "react";
import { formattedCurrency } from "utils/supportFunctions";

// types
import { MonthResultProps } from './MonthResult.component.d';

// style
import {
    SingleResultTableBox,
    MounthBox,
    TotalMounthBox,
    MiddleEarningBox,
    MounthrRecordBox,
    TableText
} from './style/MonthResult.style';


/**
 * Moth Result component
 * 
 * @author Foschi Piero
 * 
 * @returns JSX
 */

const MONTH_NAME: any = {
    1: 'GEN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAG',
    6: 'GIU',
    7: 'LUG',
    8: 'AGO',
    9: 'SET',
    10: 'OTT',
    11: 'NOV',
    12: 'DIC',
}

const MonthResult: FC<{resultData :any}> = ({ resultData }) => {
// const MonthResult: FC<MonthResultProps> = ({ resultData }) => {

    const [data, setData] = useState<any>({resultData});

    // useEffect(() => {
    //     setData({resultData})
    // }, [resultData]);

    if (data.resultData === undefined) return (
        <LoadingMask
            isCenter
            paddingTop="200px"
            size="big"
        />    
    );    
    
    return (
        <SingleResultTableBox>
            <MounthBox>
                <TableText>{MONTH_NAME[resultData.month]}</TableText>
            </MounthBox>

            <TotalMounthBox>
                <TableText>{`${formattedCurrency(resultData.total_month_earning, 0)} €`}</TableText> {/*chiedere a Davide x totale */}
            </TotalMounthBox>

            <MiddleEarningBox>
                <TableText>{`${formattedCurrency(resultData.avgResultBonus, 0)} €`}</TableText>
            </MiddleEarningBox>

            <MiddleEarningBox>
                <TableText>{`${formattedCurrency(resultData.avgResultColpo, 0)} €`}</TableText>
            </MiddleEarningBox>

            <MounthrRecordBox>
                <TableText>{`${resultData.nameOfMaxResult} ${formattedCurrency(resultData.maxResult, 0)} €`}</TableText>
            </MounthrRecordBox>
        </SingleResultTableBox>
    );
};

export default MonthResult;