import { FC, useCallback, useState } from 'react';


// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// types
import { VipPointsModalProps } from './VipPointsModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as VipSVG } from './assets/vip-icon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/VipPointsModal.style';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import { useNavigate } from 'react-router-dom';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const VipPointsModal: FC<VipPointsModalProps> = ({ onCloseButtonModal, onModalButtonClickHandler }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = useCallback(() => {

        if (!onCloseButtonModal) return;
        onCloseButtonModal();
        if (onModalButtonClickHandler) {
            onModalButtonClickHandler();
        }
        dispatch( enableAppMain() );

        navigate("/reservedArea/liberty-point");
    }, [dispatch, onCloseButtonModal, onModalButtonClickHandler, navigate]);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <VipSVG />
                    </IconBox>

                    <Title>
                        Guadagna punti VIP
                    </Title>

                    <TextContent>
                        I Punti VIP ti permettono di accedere a iniziative esclusive e 
                        di partecipare alle speciali Classifiche VIP di Liberi dal Lavoro.
                        <br/><br/>
                        <span>Per generarli ti basta mettere in staking i Punti Libertà 
                        che possono essere spesi liberamente per Corsi e Abbonamenti quando lo vorrai.
                        Finché restano in staking generano i Punti VIP.</span>
                        <br/><br/>
                        In questo modo stai generando un valore dal nulla che va in un conto chiamato 
                        " Conto me stesso", è il modo che hai per pagare prima te stesso in Liberi dal Lavoro.                        
                    </TextContent>

                    <StartButton onClick={startButtonHandler}>ottieni punti libertà</StartButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default VipPointsModal;
