import { getEnvironmentProductId } from 'configs/config.helper';
import { callGET, callOldGET } from 'network/network';
import { saveBadges, saveClientId, saveFederateds, saveLibertyPointsAvailableBalance, saveLicenseAmount, saveMessagesNotSeen, saveMissions, saveProducts, saveRoles, saveStackedLibertyPointsAvailableBalance, saveUserOffers, saveUsersPhoneNumber } from 'redux/actions/actions-user';
import store, { AppDispatch } from 'redux/store';


const _OLD_USER_PROFILE_PATH = 'user/getCurrentUser';

export const getCurrentAuthUser = async () => {
    console.log("GETTING CurrentAuthUser");
    try {
        const brandCode = store.getState().ui.brand?.code;
        if (!brandCode) return;
        const response = await callGET(`v1/users/self/currentuser?brandCode=${brandCode}`, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        console.log("GETTING CurrentAuthUser PROBLEM");
        throw error;
    }
};

export const oldGetCurrentAuthUser = async () => {

    try {

        const response = await callOldGET(_OLD_USER_PROFILE_PATH, true);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

export const getUserOffers = async () => {

    try {

        const response = await callGET('v1/self/offers', true);

        if (response === undefined || !response) {
            throw new Error();
        }
      
        return response;        
    } catch (error: any) {
        throw error;
    }
};

export const getLicenseAmount = async () => {

    try {

        const licensePayload = {
            brandCode: store.getState().ui.brand?.code,
            types: 'AUTOMATIC_INCOME'
        }

        const response = await callGET('v1/self/productsubscriptions', true, licensePayload);

        if (response === undefined || !response) {
            throw new Error();
        }
      
        return response;        
    } catch (error: any) {
        throw error;
    }
};

export const getUserData = () => {

    return async (dispatch: AppDispatch) => {

        try {
            const response = await getCurrentAuthUser();
            console.log("User services: response: ", JSON.stringify(response));
            if (!response) return;

            const pools = response.pools;
            
            const libertyPointsPool = pools.filter((pool: any) => pool.type === "PERSONAL");
            if (libertyPointsPool.length === 0) dispatch( saveLibertyPointsAvailableBalance(0) );
    
            if (libertyPointsPool.length !== 0) dispatch( saveLibertyPointsAvailableBalance(libertyPointsPool[0].availableBalance) );
    
            const stackedLibertyPointsPool = pools.filter((pool: any) => pool.type === "STACKING");
            if (stackedLibertyPointsPool.length === 0) dispatch( saveStackedLibertyPointsAvailableBalance(0) );
    
            if (stackedLibertyPointsPool.length !== 0) dispatch( saveStackedLibertyPointsAvailableBalance(stackedLibertyPointsPool[0].availableBalance) );
    
            const federateds = response.federateds;
            const federatedWithCognito = federateds.some((federated: any) => federated.federatedName === 'Cognito');
            const federatedWithFacebook = federateds.some((federated: any) => federated.federatedName === 'Facebook');
    
            dispatch( saveFederateds({cognito: federatedWithCognito, facebook: federatedWithFacebook}) );
    
            const missions = response.missions;
            const facebookMission = missions.some((mission: any) => (mission.completionDate !== null) && (mission.mission.name === 'Facebook'));
            const appMission = missions.some((mission: any) => (mission.completionDate !== null) && (mission.mission.name === 'App'));
            const betfairMission = missions.some((mission: any) => (mission.completionDate !== null) && (mission.mission.name === 'Betfair'));
    
            dispatch( saveMissions({facebook: facebookMission, app: appMission, betfair: betfairMission}) );
    
            dispatch( saveClientId(response.id) );
            
            dispatch( saveRoles(response.roles) );

            dispatch( saveBadges(response.badges) );

            dispatch( saveMessagesNotSeen(response.messagesNotSeen) );

            dispatch( saveUsersPhoneNumber(response.phoneNumber) );

            const userOffers = await getUserOffers();

            dispatch( saveUserOffers(userOffers) );

            const purchasedLicenses = await getLicenseAmount();

            if (purchasedLicenses.length === 0) dispatch( saveLicenseAmount(10) );
            if (purchasedLicenses.length > 0) {

                const licenseAmount = purchasedLicenses[0].productAutomaticIncome.levelPurchases.map((license: any) => license.purchase.amount).reduce((total: number, amount: number) => total + amount, 10);
                dispatch( saveLicenseAmount(licenseAmount) )
            };
        } catch (error: any) {
            // if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn(error);
        }
    
    };
};


export const getUsersProducts = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const brandCode = store.getState().ui.brand?.code;
            if (!brandCode) return;
            const products = await callGET(`v1/self/products?brandCode=${brandCode}`, true);

            if (products.length === 0) {
                const userProducts = {
                    courses: undefined,
                    subscriptions: undefined,
                    lessons: undefined,
                    automatic: undefined,
                    highway: {
                        active: false,
                        assigned: false,
                        subscriptionId: undefined
                    }
                };                
                dispatch( saveProducts(userProducts));
                return;
            }

            const courses = products.filter((product: any) => product.type === "COURSE");
            const subscriptions = products.filter((product: any) => product.type === "SERVICE");
            const lessons = products.filter((product: any) => product.type === "LESSON_PACK");     
            const automatic = products.filter((product: any) => product.type === "AUTOMATIC_INCOME");     

            const subscriptionsParams = {
                brandCode: store.getState().ui.brand?.code,
                types: 'LESSON_PACK'
            }
            const userSubscriptions = await callGET('v1/self/productsubscriptions', true, subscriptionsParams);
            if ((userSubscriptions.length === 0) || (!userSubscriptions.some((subscription: any) => subscription.product.id === getEnvironmentProductId('autostrada')))) {
                const userProducts = {
                    courses,
                    subscriptions,
                    lessons,
                    automatic,
                    highway: {
                        active: false,
                        assigned: false,
                        subscriptionId: undefined
                    }
                };              
                dispatch( saveProducts(userProducts));
                return;
            }
            
            const highwayActive = userSubscriptions.some((subscription: any) => (subscription.product.id === getEnvironmentProductId('autostrada')) && (subscription.productLessonPack.status === 'OPENED')); // 378??
            const highwayAssigned = highwayActive ? ((userSubscriptions.filter((subscription: any) => subscription.product.id === getEnvironmentProductId('autostrada'))[0].productLessonPack.coach) ? true : false) : false;
            const highwaySubscripionId = userSubscriptions.filter((subscription: any) => subscription.product.id === getEnvironmentProductId('autostrada'))[0].id;

            const userProducts = {
                courses,
                subscriptions,
                lessons,
                automatic,
                highway: {
                    active: highwayActive,
                    assigned: highwayAssigned,
                    subscriptionId: highwaySubscripionId
                }
            };
            // console.log('USERPRODUCTS:', userProducts);
            
            dispatch( saveProducts(userProducts));
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
}
