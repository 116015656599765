import { FC } from 'react';

// routing
import { NavLink } from 'react-router-dom';
import BrandedNavLink from 'components/BrandedNavLink/BrandedNavLink.component';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
// import { Navbar } from './Navbar.d';

//utils
import { checkBrandMap } from 'configs/brandMap/brandMap.helper';

// style
import { Nav } from './style/Navbar.style';
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { Link } from 'react-router-dom';


/**
 * NavBar
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const Navbar: FC<{ onClick: (() => void | undefined) | undefined }> = ({ onClick }) => {

    const { tokenID } = useAppSelector(state => state.user);

    const isUserLoggedIn = !!tokenID;
    const homeLinkTo = isUserLoggedIn ? '/reservedArea/' : '/landing';

    return (
        <Nav onClick={onClick}>
            <BrandedNavLink to={homeLinkTo}>Home</BrandedNavLink>
            { checkBrandMap('shop') && <BrandedNavLink to={'/reservedArea/shop'}>Negozio</BrandedNavLink>}
            { checkBrandMap('dirette-tematiche') && <BrandedNavLink to={'/reservedArea/dirette-tematiche'}>Dirette Tematiche</BrandedNavLink>}
            {/* <BrandedNavLink to={'/reservedArea/libertydashboard'}>I Miei Guadagni</BrandedNavLink> */}
            {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/home/prodotti')}>Negozio</a> */}
            { checkBrandMap('aiuto-privato') && <BrandedNavLink to={'/reservedArea/aiuto-privato'}>Aiuto Privato</BrandedNavLink>}
            {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/negoziolezioni')}>Aiuto Privato</a> */}
            {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/oddsScasser/puntabanca')}>OddsScasser</a> */}
            { checkBrandMap('oddsscasser') && <BrandedNavLink to={'/reservedArea/oddsscasser/puntabanca'}>OddsScasser</BrandedNavLink>}
            {/* <BrandedNavLink to={'/testimonials'}>Testimonianze</BrandedNavLink> */}
            {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/oddsScasser/tools')}>OddsScasser</a> */}
            { checkBrandMap('testimonials') && <BrandedNavLink to={'/testimonials'}>Testimonianze</BrandedNavLink>}
            { checkBrandMap('blog') && <a href='https://blog.gruppomissionebenessere.it/'>Blog</a>}
        </Nav>
    );
}

export default Navbar;
