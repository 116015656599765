import styled from "styled-components";
import devices from "style/breakpoints";

export const CtaBlockBox = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 50px;
    flex-direction: column;

    width: 100%;

    @media ${devices.tablet} {
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }

    @media ${devices.desktop} {

        align-items: stretch;
        flex-direction: row;
    }

`;