import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import { InputProps, LabelProps } from './TextInputWithAutocomplete.style.d';

export const InputLabelBox = styled.div<{height?: string, isDisabled?: boolean, minWidth?: string}>`
    
    display: flex;
    width: 100%;
    min-width: ${(props) => props.minWidth ?? '0px'};
    height: ${(props) => props.height ?? '36px'};
    border-radius: 5px;
    position: relative;
    transition: .3s ease-out all;
    opacity: ${(props) => props.isDisabled ? '0.5' : '1'};

`;

export const Label = styled.div<LabelProps>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.labelWidth!==undefined ? props.labelWidth+'px' : '160px'};
    border: ${(props) => props.focus ? '1px solid #202a31' : '1px solid colors.grey'};
    border-radius: 5px 0px 0px 5px;
    font-weight: 400;
    font-size: ${props => props.fontSize ? props.fontSize+'px' : '12px'};
    text-align: center;
    white-space: nowrap;
    padding: 10px;
    color: white;
    background-color: ${(props) => props.focus && !props.isDisabled ? '#202a31' : colors.grey};
    transition: .3s ease-out all;
    cursor: default;

`;

export const Input = styled.input<InputProps>`

    min-width: 0;
    ${(props) => props.width ? css`width: ${props.width};` :
        css`
            width: 0;
            flex-grow: 1;
        `
    }

    font-size: ${props => props.fontSize ? props.fontSize+'px' : '15px'};
    font-weight: 400;
    text-align: center;
    padding: ${(props) => props.padding ? props.padding+'px' : '0'};

    border: 1px solid ${colors.grey};
    
    border-radius: ${(props) => !props.label ? '5px' : '0px 5px 5px 0px'};
    background: #375e7a;
    color: ${colors.white};
    transition: .3s ease-out all;
    :focus {
        border: 1px solid #202a31;
    }
    ${(props) => props.isInputInvalid && (
        css`
            background: #8F4D53;
        `
    )}
    :focus-visible {
        outline: none;
    }
    :read-only {
        /* padding: 0; */
        color: ${colors.white056};
        cursor: default;
    }

    ::placeholder {
        color: #c3c3c3;
    }

`;

export const List = styled.div<{visible: boolean, labelWidth?: number, iconPresent: boolean, height?: string, listAllWidth?: boolean}>`
    position: absolute;
    background-color: #3b4851;
    border: 1px solid ${colors.grey};
    border-radius: 0px 0px 5px 5px;
    top: ${(props) => props.height ? 'calc('+props.height+' - 1px)' : '35px'};
    left: ${(props) => props.listAllWidth ? '2px' : (props.labelWidth!==undefined ? props.labelWidth+'px' : '160px')};
    padding: 0;
    width: ${(props) => props.listAllWidth ? 'calc(100% - '+(props.iconPresent ? '34' : '0')+'px)' : (props.iconPresent ? 'calc(100% - '+(props.labelWidth!==undefined ? props.labelWidth+28 : 188)+'px)' : 'calc(100% - '+(props.labelWidth!==undefined ? props.labelWidth+2 : 162)+'px)')};
    display: ${(props) => props.visible ? 'flex' : 'none'};
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div:last-of-type {
        border-radius: 0 0 4px 4px;
    }
`;

export const ListElement = styled.div<{isActive?: boolean, color?: string, fontSize?: number}>`
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 5px 10px;
    font-size: ${props => props.fontSize ? props.fontSize+'px' : '14px'};
    cursor: pointer;
    color: white;
    transition: background-color .5s, opacity .5s;
    background-color: ${(props) => props.isActive ? (props.color ?? '#00386c') : 'transparent'};
    :hover {
        background-color: ${(props) => props.color ?? '#00386c'};
        opacity: 0.76
    }
`;

export const IconContainer = styled.div<{isIconActive: boolean, height?: string}>`
    height: ${(props) => props.height ?? '36px'};
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: ${(props) => props.isIconActive ? '1' : '0.3'};
    transition: opacity .3s ease-out;
`;
