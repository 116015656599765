import styled from 'styled-components';
import colors from 'style/palette/palette';


export const Button = styled.button`

    width: 100%;
    max-width: 100px;

    margin: 0;
    padding: 0;

    border: none;
    background: ${colors.transparent};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    flex-direction: row;

    cursor: pointer;
    position: relative;
`;
