import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import { useIsMounted } from 'usehooks-ts';

// utils
import { getCurrentAuthUser } from 'services/user.services';

// components
import LoadingMask from 'components/ui/LoadingMask';
import FormEditAccount from './FormEditAccount';
import FormEditFacebookAccount from './FormEditFacebookAccount';

// style
import { BaseCard } from 'style/wrappers';
import {
    Page,
    TitlePage,
    TextSeparator,
    UserSubSectionText,
    UserDashboardContent
} from './style/AccountPage.style';


/**
 * Account Page
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const AccountPage: FC<{}> = () => {

    const [userData, setUserData] = useState<any>({});
    const [cognitoData, setCognitoData] = useState<any>([]);
    const [facebookData, setFacebookData] = useState<any>([]);
    const { name: userName, surname: userSurname } = useAppSelector(state => state.user);
    // const { isLoggedInWithFacebook } = useAppSelector(state => state.user);
    const isMounted = useIsMounted();

    // const checkUserConnectedAccount = (userData: any, accountType: string) => {

    //     if (!userData || userData === undefined) return false;
    //     if (!userData.federateds || userData.federateds === undefined) return false;

    //     return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    // };

    const getUserConnectedAccountData = (userData: any, accountType: string) => {

        if (!userData || userData === undefined) return;
        if (!userData.federateds || userData.federateds === undefined) return;

        return userData.federateds.filter((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };

    const getUserData = async () => {

        try {
            const userCompleteData = await getCurrentAuthUser();
            if (!isMounted) return;

            if (!userCompleteData || userCompleteData === undefined) {
                throw new Error ("User Data are undefined.");
            }
     
            setUserData(userCompleteData);
        } catch (error: any) {
            console.warn("Unable getting User Data.", error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {

        if (!userData || userData === undefined) return;

        const cognitoGlobalData = getUserConnectedAccountData(userData, 'Cognito');
        const facebookGlobalData = getUserConnectedAccountData(userData, 'Facebook');

        if (cognitoGlobalData) setCognitoData(cognitoGlobalData[0]);
        if (facebookGlobalData) setFacebookData(facebookGlobalData[0]);
    }, [userData]);

    const userNameAndSurname = `${userName} ${userSurname}`;
    // const hasUserCognitoAccount = checkUserConnectedAccount(userData, 'Cognito');
    // const hasUserFacebookAccount = checkUserConnectedAccount(userData, 'Facebook');
    // const userCognitoData = getUserConnectedAccountData(userData, 'Cognito')[0];
    // const userFacebookData = getUserConnectedAccountData(userData, 'Facebook')[0];

    // console.log("COGNITO DATA:", userCognitoData);
    // console.log("FACEBOOK DATA:", userFacebookData);
    
    const isLoading = (userData.id === undefined);
    
    if (isLoading) return (
        <LoadingMask 
            size='big'
            isCenter
            paddingTop='200px'    
        />
    );

    return (
        <Page>
            <TitlePage>
                {userNameAndSurname}

                <TextSeparator>/</TextSeparator>
                
                <UserSubSectionText>
                    Account
                </UserSubSectionText>
            </TitlePage>

            <BaseCard>
                <UserDashboardContent>
                    <FormEditAccount userData={cognitoData} />

                    <FormEditFacebookAccount userData={facebookData} />
                </UserDashboardContent>
            </BaseCard>
        </Page>
    );
};

export default AccountPage;
