import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// utils
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCurrentBaseUrlFromConfig } from "network/network.utils";
import config from "configs/config";
import { setItemToLocalStorage } from 'utils/localStorage';
import { setCookie } from 'utils/cookie';
import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';

let finalAuthConfiguration: any;
if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;

const { newBaseUrl: _baseURL } = getCurrentBaseUrlFromConfig();
const { oldBaseUrl: _baseOldURL } = getCurrentBaseUrlFromConfig();

// export const httpFacebookGraphClient = (token: string | null) => {
    
//     const defaultOptions: any = {
//       baseURL: "https://graph.facebook.com/v14.0",
//       method: "get",
//       // withCredentials: true,
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
  
//     // Create instance
//     let instance = axios.create(defaultOptions);
  
//     // Set the access token parameter for any request
//     instance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
//       console.log(config);
//       if (!config) {
//         config = {};
//       }
//       if (!config.params) {
//         config.params = {};
//       }
  
//       config.params.access_token = token;
//       if (config.method === "GET" || config.method === "get") config.params.limit = "999";
//       if (config.method === "POST" || config.method === "post") config.params.limit = "1";
//       return config;
//     });
  
//     return instance;
//   };
  
export const httpApiClient = (token: string | null) => {
    
    const defaultOptions: any = {
      baseURL: _baseURL,
      method: "get",
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    // Create instance
    let instance = axios.create(defaultOptions);
  
    // Set the access token parameter for any request
    instance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
      console.log(config);
      if (!config) {
        config = {};
      }
      if (!config.headers) {
        config.headers = {};
      }
  
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    //   if (config.method === "GET" || config.method === "get") config.params.limit = "999";
    //   if (config.method === "POST" || config.method === "post") config.params.limit = "1";
      return config;
    });
  
    return instance;
  };
  
  const FB_GROUP_ID = () => {

    if (!config || config === undefined) return;
    if (!config.socialLinks || config.socialLinks === undefined) return;
    if (config.environment === 'production') return config.socialLinks.fbGroupID;
    return config.socialLinks.fbGroupTestID;
};

  export const postSvgToFacebook = (
    cognitoToken: string | null,
    width: string,
    height: string,
    svgHtml: string,
    caption: string,
    successHandler: (res: AxiosResponse) => void,
    errorHandler: (res: AxiosResponse) => void,
    refreshFunction: (() => any) | undefined
  ) => {
    let svg = document.createElement("svg");
    svg.append(svgHtml);
    console.log("svg", svg);
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = +width;
    canvas.height = +height;
    canvas.style.width = width;
    canvas.style.height = height;
    console.log("canvas", canvas);
    let win = window.URL || window.webkitURL || window;
    let blob = new Blob([svgHtml], { type: "image/svg+xml" });
    let url = win.createObjectURL(blob);
    let img = new Image();
    img.onload = () => {
        ctx?.drawImage(img, 0, 0, +width, +height);
        let imgURI = canvas.toDataURL("image/png");
        console.log("imgURI", imgURI);
        canvas.toBlob((blob) => {
            if (blob !== null) {
              console.log("pngBlob", blob);
              const formData = new FormData();
              formData.append("source", blob);
              formData.append("caption", caption);
              httpApiClient(cognitoToken)
                .post("v1/users/self/postdataonfacebookgroup", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(successHandler)
                .catch(errorHandler);
            }
          }, "image/png");
          
        // canvas.toBlob((blob) => {
        //     if (blob !== null) {
        //         console.log("pngBlob", blob);
        //         const formData = new FormData();
        //         formData.append("source", blob);
        //         formData.append("caption", caption);
        //         console.log("FINAL FB", formData);
                
        //         httpFacebookGraphClient(facebookToken)
        //             .post("/" + FB_GROUP_ID() + "/photos", formData, {
        //             headers: {
        //                 "Content-Type": "multipart/form-data",
        //             },
        //             })
        //             .then(successHandler)
        //             .then(() => {if(refreshFunction) refreshFunction()})
        //             .catch(errorHandler);
        //     }
        // }, "image/png");
    };
    img.src = url;
  };

//   export const postSvgStandardToFacebook = (
//     cognitoToken: string | null,
//     svgImageUrl: string,
//     caption: string,
//     successHandler: (res: AxiosResponse) => void,
//     errorHandler: (res: AxiosResponse) => void,
//     refreshFunction: (() => any) | undefined
//   ) => {
//     console.log("entro in postSvgStandardToFacebook");
    
//     let img = new Image();
//     img.onload = () => {
//         // Converti l'immagine SVG in un Blob
//         fetch(svgImageUrl)
//             .then(response => response.blob())
//             .then(blob => {
//                 console.log("pngBlob", blob);
//                 const formData = new FormData();
//                 formData.append("source", blob, "image.svg");
//                 formData.append("caption", caption);
//                 httpApiClient(cognitoToken)
//                     .post("v1/users/self/postdataonfacebookgroup", formData, {
//                       headers: {
//                         "Content-Type": "multipart/form-data",
//                       },
//                     })
//                     .then(successHandler)
//                     .catch(errorHandler);
//             });
//     };
//     img.src = svgImageUrl;
// };

const imageBlobData = async (imageURL: string) => {

  try {
    
    const response = await fetch(imageURL);

    return await response.blob();
  } catch (error: any) {
    console.warn("ERROR:", error);
    return "NO IMAGE DATA";
  }
};


export const postSvgStandardToFacebook = async (
  cognitoToken: string | null,
  svgImageUrl: string,
  caption: string,
  successHandler: (res: AxiosResponse) => void,
  errorHandler: (res: AxiosResponse) => void,
  refreshFunction: (() => any) | undefined
) => {
  try {
    // const response = await fetch(svgImageUrl);
    // const blob = await response.blob();
    // console.log("response SVG", response);
    // console.log("blob", blob);
    // console.log("IMAGE", svgImageUrl);
    
    // const blob = await fetch(svgImageUrl)
    //   .then(response => response.blob())
    //   .then(blobData => {
    //     return blobData
    //   }) 
    //   .catch(err =>  {
        
    //     console.log("ERROR BLOB:", err )
    //     return "NO IMAGE"
    //   });

    const imageBlob = await imageBlobData(svgImageUrl);
      // const imageBlob = blob();  
    const formData = new FormData();
    formData.append("source", imageBlob);
    formData.append("caption", caption);
    
    httpApiClient(cognitoToken)
      .post("v1/users/self/postdataonfacebookgroup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(successHandler)
      .catch(errorHandler);
  } catch (error) {
    const fakeAxiosResponse: AxiosResponse = {
      data: {},
      status: 500,
      statusText: 'Image loading error',
      headers: {},
      config: {},
    };

    errorHandler(fakeAxiosResponse);
  }
};
   
  export const facebookConnect = async (hasFacebook: boolean, email: string, localStorageBody?: string | undefined) => {

    if (localStorageBody) setItemToLocalStorage('returnFrom', localStorageBody);

    if (hasFacebook) {
        // Eventualmente possiamo gestire qui il "rimuovi Facebook"
    } else {
        let nowTime = new Date().getTime();
        let fbaExp = 604800000; //Time in msec for 1 week
        let fbaExpDate = nowTime + fbaExp;
        setCookie("fba", '1', fbaExpDate, "/");
        setCookie("fbUrl", window.location.toString(), fbaExpDate, "/");
        setItemToLocalStorage('cognitoEmail', email);
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
    }
};
