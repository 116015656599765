import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import { useSwipeable } from 'react-swipeable';

// types
import { TestimonialCardProps } from './TestimonialCard.d';

//components
import VideoPlayerLight from 'components/VideoPlayerLight';

// style
import {
	TestimonialWrapper,
	VideoCard,
	InfoCard,
	TestimonialTitle,
	TestimonialTag,
	TestimonialInfo,
	TestimonialLabel,
	DarkOverlay,
	TestimonialProfilePic,
	TestimonialTagBox,
	TestimonialProfilePicBackground,
} from './style/TestimonialCard.style';
import { getLastPartOfUrl } from '../utils/lastUrlPart';

/**
 * Testimonial card
 *
 * @author Marco Braglia
 *
 *
 * @returns JSX
 */

const backgroundUrl =
	'https://viveredipoker.it/2021_Testimonianze/testimonialProfilePicBackground.webp';

const TestimonialCard: FC<TestimonialCardProps> = ({
    testimonial,
    cIndex,
    inCarousel,
    isTestimonialImg,
    handleClickCard,
    onSwipe,
    setIsVideoPaused,
    startCarousel,
    stopCarousel
}) => {

    const [opacity, setOpacity] = useState(onSwipe !== undefined ? 0 : 1);

    const { isAppMobile } = useAppSelector( state => state.ui);

    const onClickHandler = () => {
        if (handleClickCard && title && cIndex) handleClickCard(title, cIndex)
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (onSwipe)onSwipe('left');
        },
        onSwipedRight: () => {
            if (onSwipe) onSwipe('right');
        }});

    
    useEffect(() => {        
        setTimeout(() => {
            setOpacity(1);
          }, 50);
        return () => setOpacity(0);
    }, []);

    if (isTestimonialImg) return (
        <TestimonialWrapper
            key={cIndex}
            opacity={opacity}
            inCarousel={inCarousel} 
            cIndex={cIndex} 
            onClick={onClickHandler}
            {...swipeHandlers} // add swipe handlers
            hasIMGTestimonials={isTestimonialImg}
        >
            {cIndex !== undefined && !isAppMobile && <DarkOverlay cIndex={cIndex} /> }
            <VideoCard inCarousel={inCarousel} isIMGTestimonial>
                <img src={testimonial.imgSRC} alt="Testimonianza guadagni" />
            </VideoCard>
        </TestimonialWrapper>
    );

    // Handle null/undefined properties using optional chaining and default values
    const videoUrl = testimonial?.video?.url ?? '';
    const coverUrl = testimonial?.videoCover?.url ?? '';
    const profilePicUrl = testimonial?.profilePicture?.url ?? '';
    const title = testimonial?.title ?? '';
    const description = testimonial?.description ?? '';

    return (
        <TestimonialWrapper
            key={description}
            opacity={opacity}
            inCarousel={inCarousel} 
            cIndex={cIndex} 
            onClick={onClickHandler}
            {...swipeHandlers} // add swipe handlers
        >
            {cIndex !== undefined && !isAppMobile && <DarkOverlay cIndex={cIndex} /> }
            <VideoCard inCarousel={inCarousel}>
                <VideoPlayerLight 
                    videoUrl={videoUrl}
                    posterUrl={coverUrl}
                    setIsVideoPaused={setIsVideoPaused}
                    isIntoCarousel={inCarousel}
                    startCarousel={startCarousel}
                    stopCarousel={stopCarousel}
                />
            </VideoCard>

            {(!inCarousel || !isAppMobile) && 
                <InfoCard inCarousel={inCarousel}>
                    <TestimonialTitle>
                        {/* <TestimonialProfilePicBackground url={backgroundUrl} inCarousel={inCarousel}>
                            <TestimonialProfilePic src={testimonial.profilePicture?.url} alt='no profile pic'/>
                        </TestimonialProfilePicBackground> */}

                        <TestimonialLabel inCarousel={inCarousel}>
                            {title}
                        </TestimonialLabel>
                        {/* {!inCarousel && isAppMobile && 
                            <TestimonialTagBox>
                                <TestimonialTag inCarousel={inCarousel}>
                                    Testimonianza
                                </TestimonialTag>
                            </TestimonialTagBox> 
                        } */}
                    </TestimonialTitle>
                    {inCarousel && !isAppMobile && 
                        <TestimonialTagBox>
                            <TestimonialTag inCarousel={inCarousel}>
                                Testimonianza
                            </TestimonialTag>
                        </TestimonialTagBox> 
                    }

                    <TestimonialInfo inCarousel={inCarousel}>
                        {description.length > (isAppMobile ? 160 : 140) ? description.slice(0,(isAppMobile ? 160 : 140)) + '...' : description}
                        {/* {description} */}
                    </TestimonialInfo>
                </InfoCard>
            }
        </TestimonialWrapper>
    );


};

export default TestimonialCard;
