//hooks
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


import { 
    NormalText, 
    Page, 
    TextBox, 
    TextGreen
} from "./style/PresentBuying.style";
import useAppSelector from "hooks/useAppSelector";


const PresentBuying = () => {
    
    const { tokenID } = useAppSelector(state => state.user)
    const navigate = useNavigate();
    
    const isUserLoggedIn = !!tokenID;
    const delay = isUserLoggedIn ? 5000 : 10000;

    useEffect(() => {

        const timeout = setTimeout(() => {
            navigate('/reservedArea/mycourses');
        }, delay);
      return () => { clearTimeout(timeout); }
    }, [navigate])

    return (
        <Page>
            <TextBox>
                <TextGreen>COMPLIMENTI (!) Ti sei aggiudicato il TUO Regalo.</TextGreen>

                {isUserLoggedIn && <NormalText>Verrai a breve reindirizzato ai Tuoi Corsi.</NormalText>}

                {!isUserLoggedIn && <NormalText>A breve verrai reindirizzato al login per effettuare l'accesso o completare la registrazione al nostro sito.</NormalText>}
            </TextBox>
        </Page>
    )
};

export default PresentBuying;