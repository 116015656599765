import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const TitleSecondStepBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 50px 0 0;
`;

export const TitleSecondStepText = styled.h2`

    margin: 0px;
    padding: 0px;

    font-weight: 500;
    font-size: 16px;

    text-transform: uppercase;

    color: ${colors.primaryColor};
`;

export const ClientDataMainBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    gap: 15px;
`;

export const ClientDataRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 15px;
`;

export const ReceiptGlobalBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 25px;

    padding: 0px 0px 20px;
`;

export const TextReceipt = styled.p`

    padding: 0px;
    margin: 0px;

    text-align: center;

    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    color: ${colors.white};
`;

export const UpdloadReceiptBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 15px;

    padding: 0 15px 0;
`;

export const UploadButton = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 20px 10px;

    background: linear-gradient(180deg, #42494F 0%, rgba(33, 38, 43, 0) 100%);

    border: 0.5px solid ${colors.white};
    border-radius: 5px;

    cursor: pointer;
`;

export const UploadText = styled.p`

    padding: 0px;
    margin: 0px;

    text-align: center;

    font-weight: 400;
    font-size: 13px;

    color: ${colors.white050};
`;

export const SandButton = styled(Button)``;