import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';

export const DateInputContainer = styled.div<{isDisabled?: boolean, isDisabledOpacity?: number, height?: number, isInputInvalid?: boolean, breakPoint?: number}>`
    
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: ${(props) => props.height ? props.height+'px' : '36px'};
    padding: 0 1px;
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    background: #375e7a;

    position: relative;
    transition: .3s ease-out all;
    opacity: ${(props) => props.isDisabled ? (props.isDisabledOpacity ?? '0.75') : '1'};
    pointer-events: ${(props) => props.isDisabled ? 'none' : 'auto'};

    ${(props) => props.isInputInvalid && (
        css`
            background: #8F4D53;
        `
    )}
    width: 100%;
    ${(props) => props.breakPoint && (
        css`
            @media (min-width: ${props.breakPoint}px) {
                width: fit-content;
            }
        `
    )}

    ::placeholder {
        color: #c3c3c3;
    }
`;

export const Separator = styled.span<{height?: string}>`
    width: 5px;
    color: white;
    ${(props) => props.height && css`height: ${props.height};`}
`;

export const Input = styled.input<{width?: string}>`

    min-width: 0;
    width: ${props => props.width ?? '22px'};
    height: 100%;
    font-size: 14px;
    text-align: center;
    border: none;
    background: transparent;
    outline: none;
    color: ${colors.white};
`;
