/**
 * Create or modify single primitive value in session storage by key
 * @param {string} key - key of session storage, this last is created if not already present
 * @param {string} textToSave - actual value to save, must be first converted to string since sessionStorage.setItem only accepts string as value
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const setItemToSessionStorage = (key: string, textToSave: string): void | null => {
    if (typeof Storage === undefined) return null;

    if ( (typeof key !== 'string') || (typeof textToSave !== 'string') ) {
        return null;
    }

    try {

        sessionStorage.setItem(key, textToSave);
        
    } catch (error) {

        // aka session storage is full
        return null;
    }
};

/**
 * Create or modify derived object in session storage by key
 * @param {string} key - key of session storage, this last is created if not already present
 * @param {string} objToSave - actual value to save, expected to be as a map or an array, will be converted inside to string
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const setObjToSessionStorage = (key: string, objToSave: object): void | null => {
    if (typeof Storage === undefined) return null;

    if ( (typeof key !== 'string') || (typeof objToSave !== 'object') || objToSave === null ) {
        return null;
    }

    const stringifiedObj = JSON.stringify(objToSave);

    try {
        sessionStorage.setItem(key, stringifiedObj);

    } catch (error) {
        // aka session storage is full
        return null;
    }
};

/**
 * Return single primitive value from session storage by key
 * @param {string} key - key of session storage, this last is created if not already present
 * @returns {string | null} value from session storage by key as string, null if any errors were found
 */
export const getItemFromSessionStorage = (key: string): string | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;
    const itemFromSessionStorage = sessionStorage.getItem(key);

    return itemFromSessionStorage;
};

/**
 * Return derived object value from session storage by key
 * @param {string} key - key of session storage, this last is created if not already present
 * @returns {object | null} value from session storage by key as object, null if any errors were found
 */
export const getObjFromSessionStorage = (key: string): object | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;
    const itemFromSessionStorage = sessionStorage.getItem(key);
    if (!itemFromSessionStorage) return null;
    const parsedItem = JSON.parse(itemFromSessionStorage);
    
    return parsedItem;
};

/**
 * Delete value from session storage by key
 * @param {string} key - key of session storage, this last is created if not already present
 * @returns {void | null} void if all was good, null if any errors were found
 */
export const removeItemFromSessionStorage = (key: string): void | null => {

    if (typeof Storage === undefined) return null;
    if (typeof key !== 'string') return null;    
    sessionStorage.removeItem(key);
};
