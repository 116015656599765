import { FC, useEffect } from 'react';

// utils
import { useSearchParams } from 'react-router-dom';
import { postFacebookEvent } from '../services/MarketingLandingPage.services';
import TagManager from 'react-gtm-module';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import config from 'configs/config';
import ReactPixel from 'react-facebook-pixel';

// components
import LoadingMask from 'components/ui/LoadingMask/LoadingMask.component';


const _pixel_id = `184344131163699`;
const _conversion_token = 'EAARE8JnT8kkBAEqTnSR14ZAJkKvUxweDJ3ZA7OmwbS19LYNlblwZAJw79kiid6uqP5m8PIPjLwItnk5vPOGkGTxI4FJ83ALYoLSeLp3hi5HZA1EB31tWCj51Qc1Bj6qXqZBp9AP5ddsXzai4wpYKZB7IDonLxjZBlVqJdTVauYUSVoj3IXxmOqQ'
// const _tag_manager_args = {
//         gtmId: 'AW-10793452957'
//     };


const BridgePage: FC<{}> = () => {

    const [searchParams] = useSearchParams();

    const searchFrom = searchParams.get('from');
    const campaign = searchParams.get('campaign');

    useEffect(() => {

        const postEventOnFacebook = async () => {

            try {
                
                let conversionPayload = {
                    data: [
                        {
                            event_name: 'Lead',
                            event_time: ~~(+new Date() / 1000),
                            user_data: {
                                country: '2ad8a7049d7c5511ac254f5f51fe70a046ebd884729056f0fe57f5160d467153', // ita
                                client_user_agent: navigator.userAgent,
                                em: '74234e98afe7498fb5daf1f36ac2d78acc339464f950703b8c019892f982b90b', // null
                            },
                            event_source_url: `${getEnvironmentBaseUrl('app/lp/mk/b')}`,
                            action_source: 'website',
                        },
                    ],
                };
    
                if (config.environment !== 'production') conversionPayload = {...conversionPayload, ...{ test_event_code: 'Prova landing LDL' }} ;
                await postFacebookEvent(_pixel_id, _conversion_token, conversionPayload);
            } catch (error: any) {
                console.warn("ERROR:", error);
            }
        };
        
        if (searchFrom === '103111111103108101') { // google encoded result from stringCoding() in supportFunctions.ts

            // TagManager.initialize(_tag_manager_args);
            if (campaign === 'fromZero') {
                setTimeout(() => {
                    window.location.href = "https://rebrand.ly/go_facciamoibonusinsieme";
                }, 2000);
                return
            }

            setTimeout(() => {
                window.location.href = "https://rebrand.ly/go_facciamoibonusinsieme";
            }, 2000);
            return;
        };

        if (searchFrom === '102979910198111111107') { // facebook encoded result from stringCoding() in supportFunctions.ts

            ReactPixel.init(_pixel_id);
            ReactPixel.pageView();
            ReactPixel.trackSingle(_pixel_id, 'Lead');
            postEventOnFacebook();
            if (campaign === 'fromZero') {
                setTimeout(() => {
                    window.location.href = "https://rebrand.ly/fb_facciamoibonusinsieme"; 
                }, 500);
                return
            };

            setTimeout(() => {
                window.location.href = "https://rebrand.ly/fb_facciamoibonusinsieme"; 
            }, 500);
            return;
        }; 
        
        if (campaign === 'fromZero') {
            window.location.href = "https://rebrand.ly/nu_facciamoibonusinsieme";
            return;
        };
        
        window.location.href = "https://rebrand.ly/nu_facciamoibonusinsieme";
    }, []);

    return (
        <LoadingMask 
            isCenter
            paddingTop='200px'
            size='page'
        />
    );
};

export default BridgePage;