import { DateInputState } from "components/ui/DateInput/types/DateInputState";
import { NumberInputState } from "components/ui/NumberInput/types/NumberInputState";
import { TextInputState } from "components/ui/TextInputWithLabel/types/TextInputState";
import { LdlFile } from 'pages/OddsScasserPage/types/LdlFile';
import { SingleObj, SingleObjDb } from "pages/OddsScasserPage/OddsScasser/types/SingleObj";
import { RolloverObj, RolloverObjDb } from "../../Rollover/types/RolloverObj";
import { MultipleObj, MultipleObjDb } from "../../Multiple/types/MultipleObj";
import { FiltersObj, FiltersObjDb } from "pages/OddsScasserPage/OddsScasser/types/FiltersObj";

export const bonusTypes = ['Valida per TUTTI', 'Possibile ESCLUSIVA', 'Programma VIP', 'Benvenuto'] as const;
export type BonusType = typeof bonusTypes[number];

export const bonusStatuses = ['CREATED', 'Nuova Promozione', 'Promo Terminata'] as const;
export type BonusStatus = typeof bonusStatuses[number];

export const qualifyingModes = ['No', 'Quota Maggiorata Singola', 'Quota Maggiorata Multipla', 'Singola Semplice', 'Singola Rimborso', 'Singola Pending', 'Multipla Semplice', 'Multipla Rimborso', 'Multipla Pending'] as const;
export type QualifyingMode = typeof qualifyingModes[number];

export const bonusModes = ['No', 'Freebet Singola', 'Freebet Multipla', 'Singola Semplice', 'Singola Rollover', 'Multipla Semplice', 'Multipla Rollover', 'Freespin/Spin Minimi', 'Sport Virtuali'] as const;
export type BonusMode = typeof bonusModes[number];

export const hoursFromTypes = ['Attivazione', 'Conclusione evento'] as const;
export type HoursFromType = typeof hoursFromTypes[number];

export const betTypes = ['Singola', 'Multipla', 'Rollover'] as const;
export type BetType = typeof betTypes[number];

//Bonus
export interface BonusBase {
    id: number|null,
	productId: number,
	creationDate?: string,
	siteId: number,
	title?: string,
	type?: BonusType,
	status?: BonusStatus,
	expectedProfit?: number,
	qualifyingMode?: QualifyingMode,
	qualifyingLive?: boolean,
	bonusMode?: BonusMode,
	bonusLive?: boolean,
	expirationDate?: string,
	createdBy?: {
        id: number,
	    name?: string,
	    surname?: string
    }
}
interface BonusDb extends BonusBase {steps: BonusStepDb[], userBonus?: UserBonusDb}
interface Bonus extends BonusBase {steps: BonusStep[], userBonus?: UserBonus}

//BonusStep
export interface BonusStepBase {
    id: number|null,
	expirationDate?: string,
	expirationHours?: number,
	expirationHoursFrom?: HoursFromType,
	unlockingDate?: string,
	unlockingHours?: number,
	unlockingHoursFrom?: HoursFromType,
	type?: string,
	description?: string,
	files: LdlFile[],
	pos: number
}
interface BonusStepDb extends BonusStepBase {
	single?: SingleObjDb,
	multiple?: MultipleObjDb,
	rollover?: RolloverObjDb,
	filters?: FiltersObjDb
}
interface BonusStep extends BonusStepBase {
	single?: SingleObj,
	multiple?: MultipleObj,
	rollover?: RolloverObj,
	filters?: FiltersObj
}

//UserBonus
export interface UserBonusBase {
    id: number,
	bonusId: number,
	creationDate: string,
	closingDate?: string,
	reachedStepId: number|null,
	friendsNumber: number|null,
	profit: number|null,
}
interface UserBonusDb extends UserBonusBase {
	singles: SingleObjDb[],
	multiples: MultipleObjDb[],
	rollovers: RolloverObjDb[]
}
interface UserBonus extends UserBonusBase {
	singles: SingleObj[],
	multiples: MultipleObj[],
	rollovers: RolloverObj[]
}

//BonusResponse
export interface BonusesResponseBase {
    pageable: {
        pageNumber: number;
        pageSize: number;
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}
interface BonusesResponseDb extends BonusesResponseBase {content: BonusDb[]}
interface BonusesResponse extends BonusesResponseBase {content: Bonus[]}

//Editing Objects
export interface BonusEditing {
	siteId: number,
	title: TextInputState,
	type: BonusType,
	status: BonusStatus,
	expectedProfit: NumberInputState,
	qualifyingMode: QualifyingMode,
	qualifyingLive: boolean,
	bonusMode: BonusMode,
	bonusLive: boolean,
	expirationDate: DateInputState,
    steps: BonusStepEditing[],
}

export interface BonusStepEditing {
    id: number|null,
	expirationDate: DateInputState,
	expirationHours: NumberInputState,
	expirationHoursFrom: HoursFromType,
	unlockingDate: DateInputState,
	unlockingHours: NumberInputState,
	unlockingHoursFrom: HoursFromType,
	type: string,
	description: string,
	files: LdlFile[],
	filtersId?: string,
	betType: BetType,
	betId?: string,
	pos: number
}