import { FC } from 'react';

// types
import { EmptyUserImageProfileProps } from './EmptyUserImageProfile.d';

// assets
import { ReactComponent as UserImagePlaceholderSVG } from './assets/user-image-placeholder.svg';
import { ReactComponent as EditUserImageIconSVG } from './assets/edit-user-image-icon.svg';

// style
import { CircleFrame, CircleFrameRef, EditButton } from './style/EmptyUserImageProfile.style';


/**
 * Empty user image placeholder
 * 
 * @author Alessio Grassi
 * 
 * @param flavor - the flavor of the circle frame
 * @param isSmall - small size 40px x 40px circle frame
 * @param isEditButtonDisabled - boolean for disabling edit button
 * @param onEditButtonClick - function for editing place holder image profile
 * 
 * @returns JSX
 */


const EmptyUserImageProfile: FC<EmptyUserImageProfileProps> = ({
        flavor,
        isSmall,
        isEditButtonDisabled,
        onEditButtonClick
    }) => {

    const editImageButtonClickHandler = async () => {

        if (!onEditButtonClick) return;
        await onEditButtonClick();
    };

    const isFinalEditButtonDisabled = (isEditButtonDisabled !== undefined) && isEditButtonDisabled;

    return (
        <CircleFrameRef>
            <CircleFrame
                flavor={flavor}
                isSmall={isSmall}
            >
                <UserImagePlaceholderSVG />

                {!isFinalEditButtonDisabled && (
                    <EditButton
                        onClick={editImageButtonClickHandler}
                        disabled={isFinalEditButtonDisabled}
                    >
                        <EditUserImageIconSVG />
                    </EditButton>
                )}
            </CircleFrame>
        </CircleFrameRef>
    );
};

export default EmptyUserImageProfile;
