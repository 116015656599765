import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const StepBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    max-width: 600px;
    margin: 25px 0 0;
    padding: 0;
`;

export const StepText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: ${colors.white};
    text-align: center;
`;

export const ChoiceRow = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    gap: 20px;

    width: 100%;
`;

export const ChoiceButton = styled.button<{isActive?: boolean | undefined, isError?: boolean | undefined}>`

    margin: 0;
    padding: 20px;

    width: 100%;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 15px;
    border: none;
    background-color: ${colors.darkGrey};

    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.white};

    cursor: pointer;

    ${({isActive}) => isActive &&
        
        css`

            border: 0.5px solid ${colors.primaryColor};
            
            background-color: ${colors.veryDarkGrey};
            color: ${colors.primaryColor};
        `
    }

    ${({isError}) => isError &&
        
        css`

            border: 0.5px solid ${colors.red};
            
            background-color: ${colors.veryDarkGrey};
            color: ${colors.red};
        `
    }
`;

export const CTAButton = styled.button`

    margin: 0;
    padding: 15px 20px;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 5px;
    border: none;
    background-color: ${colors.primaryColor};

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    cursor: pointer;
`;

