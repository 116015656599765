import { FC } from 'react';

// routing
import { Outlet } from 'react-router-dom';


const FallbackPages: FC<{}> = () => {

    
    return (
        <Outlet />
    )
};

export default FallbackPages;