import { useEffect, useMemo, useRef, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

//services
import { getLessonWithBrand } from 'pages/AiutoPrivato/services/AiutoPrivato.services';

//utils
import { callGET } from 'network/network';
import { getItemFromLocalStorage } from 'utils/localStorage';
import { getEnvironmentProductId } from 'configs/config.helper';
import { getAutomaticIncomeLicenseAmount } from './AutomaticIncomes.helper';

//routing
import { useNavigate } from "react-router-dom";

//components
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import CardCoach from 'pages/AiutoPrivato/CardCoach/CardCoach.component';
import LoadingMask from 'components/ui/LoadingMask';
import MediaPlayer from 'components/MediaPlayer';
import HorizontalSlider from 'components/HorizontalSlider/HorizontalSlider.component';

// assets
import { ReactComponent as CheckSVG } from './assets/CheckIcon.svg';

// style
import { 
    FirstCardUp, 
    FirstCardButton, 
    FirstCardButtonText, 
    FirstCardList, 
    FirstCardListIcon, 
    FirstCardListText, 
    CardText, 
    CardTitle, 
    MainTitle, 
    SecondCard, 
    SecondCardButton, 
    SecondCardList, 
    SecondCardPrice, 
    ThirdCard, 
    Totalpage, 
    Video, 
    SecondCardText,
    FirstBlock,
    SecondBlock,
    FirstCardTitle,
    FirstCardP,
    FirstCardDown,
    FirstCardTotal,
    ThirdCardTitle,
    ThirdCardText,
    SecondCardPriceBox
} from "./style/AutomaticIncomes.style";
import { getCoursePayment } from 'services/payments.services';
import store from 'redux/store';

interface LicenseType {
    id: number;
    name: string;
    type: string;
    categories: any[];
    subCategories: any[];
    productAutomaticIncome: {
      productId: number;
      levels: {
        id: number;
        price: number;
        value: number;
        until: number | null;
      }[];
    };
    paymentTypes: any[];
    oldId: number;
    subscriptions: number;
};

const videoMedia = {
    src: 'https://www.viveredipoker.it/CorsoEntrateAutomatiche110/Intro_EA_Crypto.mp4',
    type: 'video/mp4'
}

const AutomaticIncomes = () => {

    const { licenseAmount } = useAppSelector(state => state.user.automatiIncomes);
    const { automatic } = useAppSelector(state => state.user.products);
    const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
    const [lessons, setLessons] = useState<any | undefined>(undefined);
    // const [selectedButton, setSelectedButton] = useState<number | null>(null);
    const [selectedButton, setSelectedButton] = useState<number>(150);
    const [isEditingPrice, setIsEditingPrice] = useState(false);
    const [hasAutomaticIncome, setHasAutomaticIncomet] = useState<any>(undefined);
    // const [selectedId, setSelectedId] = useState<number | null>(null);
    const [selectedLevel, setSelectedLevel] = useState<{ id: number; price: number; value: number; until: number | null } | null>(null);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const navigate = useNavigate();
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [startingPrice, setStartingPrice] = useState<number>(150);

    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };
    
    const onSliderChange = (newValue: number) => {
        setStartingPrice(newValue)        
    };

    const getLicenseTypes = async () => {
        // const payload: { brandCode: string, types: string } = { brandCode: store.getState().ui.brand?.code, types: "AUTOMATIC_INCOME" };
        const payload: { brandCode: string } = { brandCode: store.getState().ui.brand?.code! };
        
        
        try {
            //   const response = await callGET('v1/productsforsell/bytypes', true, payload);
            const response = await callGET(`v1/productsforsell/${getEnvironmentProductId('entrate automatiche')}`, true, payload);           
            
            if (!response || response === undefined) throw new Error("Licence response undefined");
          
            setLicenseTypes([response]);
            
        } catch (error: any) {
            console.warn(error);
        }
    };
    
    // const getAutomaticIncomes = async () => {
        
    //     const lessonParams = {
    //         brandCode: store.getState().ui.brand?.code,
    //         types: "AUTOMATIC_INCOME"
    //     }
        
    //     try {
    //         const response = await callGET('v1/self/productsubscriptions', true, lessonParams);
            
    //         setHasAutomaticIncomet(response);
            
    //     } catch (error:any) {
    //         console.warn(error);
    //     }
    // };
    
    const getLesson = async () => {
        
        try {
            loadingOn();
            const response = await getLessonWithBrand();
            
            setLessons(response);
            
            loadingOff();
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error')
            loadingOff();
            console.warn(error);
        }
    };
    
    // const firstLevelPrice = useMemo(() => {
    //     if (licenseTypes && licenseTypes[0]) {
    //       const firstLevel = licenseTypes[0].productAutomaticIncome.levels[0];
    //       setSelectedButton(firstLevel.price); // Set the selected button to the first button
    //       return firstLevel.price;
    //     }
    //     return null;
    // }, [licenseTypes]);

    // const firstLevelPrice = useMemo(() => {
    //     if (licenseTypes.length === 0) return;
    //     if (licenseTypes && licenseTypes[0]) {
    //         return licenseTypes[0].productAutomaticIncome.levels[0].price;
    //     }
    //     return null;
    // }, [licenseTypes]);

    const coachLessons = (lessons === undefined) ? [] : lessons.filter((lesson: any) => {
        if (lesson.productLessonPack.coach !== null) return lesson.productLessonPack.coach.name === "Cristian Vespo";
    });
    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };
    
    const secondCardPriceRef = useRef<HTMLHeadingElement>(null);

    const handlePriceClick = () => {
      setIsEditingPrice(true);
    };
  
    const onGuideButtonClick = async () => {

        try {
            
            if (!automatic.some((course: any) => course.id === getEnvironmentProductId('entrate automatiche'))) await getCoursePayment(getEnvironmentProductId('entrate automatiche'), 1);
            navigate(`/reservedArea/product-page/course/${getEnvironmentProductId('entrate automatiche')}`);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    // const handlePriceBlur = () => {
    //     const editedPrice = parseInt(secondCardPriceRef.current?.innerText || '0', 10);
    //     const newSelectedButton = () => {

    //         let supp = editedPrice;
    //         if (editedPrice < 150) supp = Math.max(editedPrice, 150);
    //         if (editedPrice > 12000) supp = Math.min(editedPrice, 11999);
    //         return supp;
    //     };
    //     if ((editedPrice < 150) || (editedPrice > 12000)) {
    //     //   secondCardPriceRef.current!.innerText = `${newSelectedButton()} €`;
    //       setStartingPrice(newSelectedButton());
    //     }
    //     if (!((editedPrice < 150) || (editedPrice > 12000))) {
    //     //   secondCardPriceRef.current!.innerText = `${editedPrice} €`;
    //     setStartingPrice(editedPrice);
    //     }
    //     // setStartingPrice(newSelectedButton());
    //     setSelectedButton(newSelectedButton());
    //     setIsEditingPrice(false);
    //   };

    const handleButtonClick = (price: number) => {
        
        const level = licenseTypes.find((l) =>
            l.productAutomaticIncome.levels.find((level) => level.price === price)
        )?.productAutomaticIncome.levels.find((level) => level.price === price);
        
        setStartingPrice(price);
        setSelectedLevel(level || { id: 0, price: 0, value: 0, until: null });
        setSelectedButton(price);
        setChosenProductPrice(`${price} €`); // set the value displayed in <SecondCardPrice> to the state variable
        setIsEditingPrice(true);
    };
        
    useEffect(() => {
        // getAutomaticIncomes();
        getLicenseTypes();
        getLesson();
    }, []);

    useEffect(() => {
        if (startingPrice < 10000) setStartingPrice(startingPrice);
    }, [startingPrice]);

    useEffect(() => {
        const isBankTransferPending = getItemFromLocalStorage('Pending Bank Transfer');
        
        if (isBankTransferPending === "true") {
            const pendingCoursePurchase = getItemFromLocalStorage('Pending purchase ID');
            
            if (pendingCoursePurchase === null) return;
            setIsBankTransferPending(true);
            setPaymentTransferID(+pendingCoursePurchase)
            setIsBankTransferModalVisible(true);
        }
    },[])

    if (isLoading) return (
        <LoadingMask 
            paddingTop='200px'
            isCenter
            size='page'
        />
    );

    return (
        <>
            <Totalpage>
                {!isAppMobile && <MainTitle>Entrate Automatiche 110%</MainTitle>}
                
                <FirstBlock>
                    <Video>
                        <MediaPlayer 
                            media={videoMedia}
                            preload='metadata'
                        />
                    </Video>

                    {isAppMobile && <MainTitle>PRIMA GUADAGNI, POI PAGHI</MainTitle>}
                    <FirstCardTotal>
                        <FirstCardUp>
                            <FirstCardTitle>PRIMA GUADAGNI, POI PAGHI</FirstCardTitle>

                            <FirstCardP>
                            Incredibile ma vero, solo dopo che hai guadagnato pagherai le licenze dei programmi. 
                            Le Entrate Automatiche 110% sono dei programmi che funzionano con delle strategie completamente automatiche su un conto di tua proprietà.
                            </FirstCardP>
                        </FirstCardUp>

                        <FirstCardDown>
                            <FirstCardList>
                                <FirstCardListIcon>
                                    <CheckSVG/>
                                    <FirstCardListText>Guida completa inclusa</FirstCardListText>
                                </FirstCardListIcon>

                                <FirstCardListIcon>
                                    <CheckSVG/>
                                    <FirstCardListText>Backtest inclusi</FirstCardListText>
                                </FirstCardListIcon>

                                <FirstCardListIcon>
                                    <CheckSVG/>
                                    <FirstCardListText>File excel per simulazioni e proiezioni incluso</FirstCardListText>
                                </FirstCardListIcon>
                            </FirstCardList>

                            <FirstCardButton onClick={onGuideButtonClick}>
                                <FirstCardButtonText>SCARICA IL PROGRAMMA, 50€ DI GUADAGNO SONO IN REGALO</FirstCardButtonText>
                            </FirstCardButton>
                        </FirstCardDown>
                    </FirstCardTotal>
                </FirstBlock>

                <SecondBlock>
                    <SecondCard>
                        <CardTitle>SCEGLI IL TUO PROFITTO</CardTitle>

                        <CardText>
                            Se hai già fatto i primi profitti in REGALO con Entrate Automatiche 110%, Congratulazioni!
                            Ora puoi scegliere la tua licenza in base al guadagno che desideri. 
                            <br/><br/>
                            Puoi aggiungere nuove licenze in qualsiasi momento ma ricorda che il programma smette di funzionare se superi il profitto acquistato. 
                            <br/><br/>
                            Per evitare di acquistare una licenza al giorno, scegli un profitto congruo al tuo capitale e alle tue esigenze”
                        </CardText>

                        {/* <SecondCardList>
                            {licenseTypes.map((licenseType) =>
                                licenseType.productAutomaticIncome.levels
                                    .sort((a, b) => a.id - b.id)
                                    .map((level: {
                                    id: number;
                                    price: number;
                                    value: number;
                                    until: number | null;
                                    }) => (
                                    <SecondCardButton 
                                        key={level.id}
                                        onClick={() => handleButtonClick(level.price)}
                                        isActive={selectedButton === level.price || (!selectedButton && level.price === firstLevelPrice)}
                                    >
                                        <SecondCardText>{level.price}</SecondCardText>
                                    </SecondCardButton>
                                    ))
                                )}
                        </SecondCardList> */}

                        <SecondCardList>
                            <SecondCardButton 
                                onClick={() => handleButtonClick(150)}
                                isActive={startingPrice === 150}
                            >
                                <SecondCardText>150</SecondCardText>
                            </SecondCardButton>

                            <SecondCardButton 
                                onClick={() => handleButtonClick(500)}
                                isActive={startingPrice === 500}
                            >
                                <SecondCardText>500</SecondCardText>
                            </SecondCardButton>

                            <SecondCardButton 
                                onClick={() => handleButtonClick(1000)}
                                isActive={startingPrice === 1000}
                            >
                                <SecondCardText>1000</SecondCardText>
                            </SecondCardButton>
                        </SecondCardList>

                        {/* <SecondCardPrice>{selectedButton ? `${selectedButton} €` : "-"}</SecondCardPrice> */}
                        <SecondCardPriceBox>
                            <SecondCardPrice
                                // onClick={handlePriceClick}
                                // contentEditable={isEditingPrice}
                                // onBlur={handlePriceBlur}
                                // suppressContentEditableWarning
                                ref={secondCardPriceRef}
                                >
                                {/* {selectedButton} € */}
                                {startingPrice} 
                            </SecondCardPrice>
                            €
                        </SecondCardPriceBox>

                        <HorizontalSlider 
                            startingValue={startingPrice}
                            minValue={150}
                            maxValue={10000}
                            onChange={onSliderChange}
                            step={50}
                        />

                        {/* <CardText>Con questa licenza potrai guadagnare fino a {hasAutomaticIncome.length === 0 ? 50 : selectedButton} € Totali.</CardText> */}
                        {/* <CardText>Con questa licenza potrai guadagnare fino a {startingPrice} € Totali.</CardText> */}

                            <ButtonShop 
                                // PriceProduct={selectedLevel?.price || firstLevelPrice || 0}
                                // PriceProduct={hasAutomaticIncome.length === 0 ? 0 : selectedButton}  
                                // PriceProduct={getAutomaticIncomeLicenseAmount(selectedButton, licenseAmount, licenseTypes[0].productAutomaticIncome.levels)}  
                                PriceProduct={getAutomaticIncomeLicenseAmount(startingPrice, licenseAmount, licenseTypes[0].productAutomaticIncome.levels)}  
                                TypeOfProduct="AUTOMATIC_INCOME" 
                                courseElement={licenseTypes} 
                                courseID={getEnvironmentProductId('entrate automatiche')} 
                                payments={licenseTypes.length > 0 ? licenseTypes[0].paymentTypes : []}
                                onTransferClick={transferModalHandler}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={libertyModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isAutomaticIncome
                            />
                    </SecondCard>

                    <ThirdCard>
                        <ThirdCardTitle>CHIEDI AIUTO A UN ESPERTO!</ThirdCardTitle>

                        <ThirdCardText>
                            Se vuoi richiedere aiuto ad un esperto informatico, prenota la tua sessione.
                        </ThirdCardText>

                        {(lessons !== undefined) && 
                            <CardCoach 
                                lessons={coachLessons}
                                transferModalHandler={transferModalHandler}
                                onLibertyClick={libertyModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                libertyProductID={libertyProductID}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}                           
                            />
                        }
                    </ThirdCard>
                </SecondBlock>
            </Totalpage>

            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && (
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={chosenProductID!}
                />
            )}
        </>
    )
};

export default AutomaticIncomes;