import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// types
import { AppDispatch } from '../store';

// slice actions
import {
    setTokenID,
    setClientId,
    setEmail,
    setName,
    setSurname,
    setPhoneNumber,
    setIsLoggedInWithFacebook,
    setFacebookProfilePicture,
    setLibertyPointsAvailableBalance,
    setStackedLibertyPointsAvailableBalance,
    setFederatedWithCognito,
    setFederatedWithFacebook,
    setFacebookMission,
    setAppMission,
    setBetfairMission,
    setCourses,
    setSubscriptions,
    setLessons,
    setHighwayAssigned,
    setHighwayActive,
    setUserRoles,
    setNewNotifications,
    setIsUserDisplayingFromAppMobile,
    setIsUserOffers,
    setLicenseAmount,
    setHighwaySubscriptionId,
    setBadges,
    setProductsLessonsStatus,
    setUserWarnings,
    setAutomatic,
    setMessagesNotSeen
} from '../slices/slice-user';

// utils
import { FnError } from 'utils/logErrors';
import {
    awsGetCurrentAuthenticatedUser,
    awsRefreshUserSession,
    awsSignOutUser
} from 'services/awsUser.services';


export const saveUserTokenID = (newUserTokenID?: string | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setTokenID(newUserTokenID) );
    };
};

export const saveClientId = (newCliendId?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setClientId(newCliendId) );
    };
};

export const clearUserTokenID = () => {

    return (dispatch: AppDispatch) => {
        dispatch( setTokenID(undefined) );
    };
};

export const saveUserEmail = (newUserEmail?: string | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setEmail(newUserEmail) );
    };
};

export const saveUserName = (newUserName?: string | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setName(newUserName) );
    };
};

export const saveUserSurname = (newUserSurname?: string | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setSurname(newUserSurname) );
    };
};

export const saveUsersPhoneNumber = (newUserPhoneNumber?: string | undefined | null) => {

    return (dispatch: AppDispatch) => {
        dispatch( setPhoneNumber(newUserPhoneNumber) );
    };
};

export const userIsLoggedInWithFacebook = () => {

    return (dispatch: AppDispatch) => {
        dispatch( setIsLoggedInWithFacebook(true) );
    };
};

export const userIsNotLoggedInWithFacebook = () => {

    return (dispatch: AppDispatch) => {
        dispatch( setIsLoggedInWithFacebook(false) );
    };
};

export const saveUserFacebookProfilePicture = (newFacebookProfilePicture?: string | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setFacebookProfilePicture(newFacebookProfilePicture) );
    };
};

export const saveLibertyPointsAvailableBalance = (newLibertyPointsAvailableBalance?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setLibertyPointsAvailableBalance(newLibertyPointsAvailableBalance) );
    };
};

export const saveStackedLibertyPointsAvailableBalance = (newStackedLibertyPointsAvailableBalance?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setStackedLibertyPointsAvailableBalance(newStackedLibertyPointsAvailableBalance) );
    };
};

export const saveFederateds = (federateds?: { cognito: boolean | undefined, facebook: boolean | undefined}) => {

    return (dispatch: AppDispatch) => {
        dispatch( setFederatedWithCognito(federateds?.cognito) );
        dispatch( setFederatedWithFacebook(federateds?.facebook) );
    };
};

export const saveMissions = (missions?: {facebook: boolean | undefined, app: boolean | undefined, betfair: boolean | undefined}) => {

    return (dispatch: AppDispatch) => {
        dispatch( setFacebookMission(missions?.facebook) );
        dispatch( setAppMission(missions?.app) );
        dispatch( setBetfairMission(missions?.betfair) );
    };
};

export const saveProducts = (products?: {
    courses: any,
    subscriptions: any,
    lessons: any,
    automatic: any,
    highway: {
        assigned: boolean | undefined,
        active: boolean | undefined,
        subscriptionId: number | undefined
    }
}) => {

    return (dispatch: AppDispatch) => {
        dispatch( setCourses(products?.courses) );
        dispatch( setSubscriptions(products?.subscriptions) );
        dispatch( setLessons(products?.lessons) );
        dispatch( setAutomatic(products?.automatic) );
        dispatch( setHighwayActive(products?.highway.active) );
        dispatch( setHighwayAssigned(products?.highway.assigned) );
        dispatch( setHighwaySubscriptionId(products?.highway.subscriptionId) );
    };
};

export const saveRoles = (roles?: [
    {
        id: undefined,
        permissions: [
            {
                brand: undefined,
                description: undefined,
                name: undefined,
                noun: undefined,
                verb: undefined
            }
        ],
        role: undefined
    }
]) => {

    return (dispatch: AppDispatch) => {
        dispatch( setUserRoles(roles) );
    };
};

export const saveNewNotifications = (newNotifications?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setNewNotifications(newNotifications) );
    };
};

export const saveIsUserDisplayingFromAppMobile = (isUserDisplayingFromMobile?: boolean | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setIsUserDisplayingFromAppMobile(isUserDisplayingFromMobile) );
    };
};

export const saveUserOffers = (userOffers?: any | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setIsUserOffers(userOffers) );
    };
};

export const saveLicenseAmount = (totalLicenseAmount?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setLicenseAmount(totalLicenseAmount) );
    };
};

export const saveBadges = (badge?: any | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setBadges(badge) );
    };
};

export const saveProductsLessonsStatus = (productsLessonsStatus?: {
        id: number | undefined,
        userId: number | undefined,
        lessonId: number | undefined,
        // currentSecond: number | undefined,
        completed: boolean | undefined,
        lastSeenDate: string | undefined,
        favorite: boolean | undefined,
        courseId: number | undefined
    }[] | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setProductsLessonsStatus(productsLessonsStatus) );
        ;
    };
};

export const saveUserWarnings = (userWarnings?: {
        id: number | undefined,
        color: string | undefined,
        copy: string | undefined,
        productId: number | undefined,
        sellImage: {
            id: number | undefined,
            fileName: string | undefined,
            url: string | undefined,
            fileType: {
                id: number | undefined,
                name: string | undefined,
                relativePath: string | undefined
            }
        },
        productType: string | undefined
    } | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setUserWarnings(userWarnings) );
        ;
    };
};

export const saveMessagesNotSeen = (messagesToSee?: number | undefined) => {

    return (dispatch: AppDispatch) => {
        dispatch( setMessagesNotSeen(messagesToSee) );
    };
};

const setUserInfo = (dispatch: AppDispatch, payload: any) => {

    dispatch( setTokenID(payload.signInUserSession.accessToken.jwtToken) );
    dispatch( setEmail(payload.attributes.email) );
    dispatch( setName(payload.attributes.name) );
    dispatch( setSurname(payload.attributes.family_name) );
};

const resetUserInfo = (dispatch: AppDispatch) => {

    dispatch( setTokenID(null) );
    dispatch( setEmail(undefined) );
    dispatch( setName(undefined) );
    dispatch( setSurname(undefined) );
    dispatch( setPhoneNumber(undefined) );
    dispatch( setClientId(undefined) );
    dispatch( saveLibertyPointsAvailableBalance(undefined) );
    dispatch( saveStackedLibertyPointsAvailableBalance(undefined) );
    dispatch( saveFederateds(undefined) );
    dispatch( saveMissions(undefined) );
    dispatch( saveProducts(undefined) );
    dispatch( saveRoles(undefined) );
    dispatch( saveNewNotifications(undefined) );
    dispatch( saveLicenseAmount(undefined) );
    dispatch( saveBadges(undefined) );
    dispatch( setProductsLessonsStatus(undefined) );
    dispatch( saveUserWarnings(undefined) );
    dispatch( saveMessagesNotSeen(undefined) );
};

export const saveUserLoginInfo = () => {
    return async (dispatch: AppDispatch) => {

        try {
            console.log("Trying to get current user in actions-user...")
            const response = await awsGetCurrentAuthenticatedUser();

            if (response === undefined || !response) {
                console.log("...but we got an ERROR in response");
                throw new FnError("actions-user/refreshUserSession()", "awsGetCurrentAuthenticatedUser() undefined response");
            }
            console.log("We got: ", JSON.stringify(response));
            setUserInfo(dispatch, response);

        } catch (error: any) {

            console.error(error);
            resetUserInfo(dispatch);
        }
    };
};

export const refreshUserSession = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const response = await awsRefreshUserSession();

            if (response === undefined || !response) {
                throw new FnError("actions-user/refreshUserSession()", "awsRefreshUserSession() undefined response");
            }

            // const currentUserAccessToken = response.getAccessToken().getJwtToken();
            // dispatch( setTokenID(currentUserAccessToken) );
            setUserInfo(dispatch, response);

        } catch (error: any) {
                        
            console.error(error);
            resetUserInfo(dispatch);
        }
    };
};

export const checkIfUserIsLoggedWithFacebook = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const response = await awsGetCurrentAuthenticatedUser();

            if (response === undefined || !response) {
                throw new FnError("actions-user/checkIfUserIsLoggedWithFacebook()", "awsGetCurrentAuthenticatedUser() undefined response");
            }

            if (!response.attributes?.identities) {

                dispatch( userIsNotLoggedInWithFacebook() );
                return;
            }

            const identities = JSON.parse(response.attributes.identities);

            for(let i=0; i < identities.length; i++) {

                if (identities[i].providerName === CognitoHostedUIIdentityProvider.Facebook) {
    
                    dispatch( userIsLoggedInWithFacebook() );
                    return;
                }
            }

            dispatch( userIsNotLoggedInWithFacebook() );
            
        } catch (error: any) {
            console.log("checkIfUserIsLoggedWithFacebook PROBLEM");            
            console.error(error);
            dispatch( userIsNotLoggedInWithFacebook() );
            resetUserInfo(dispatch);
        }
    };
};

export const saveFacebookPicture = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const response = await awsGetCurrentAuthenticatedUser();

            if (response === undefined || !response) {
                throw new FnError("actions-user/saveFacebookPicture()", "awsGetCurrentAuthenticatedUser() undefined response");
            }

            if (!response.attributes?.picture) {

                dispatch( saveUserFacebookProfilePicture(undefined) );
                return;
            }

            const userProfilePicture = ( JSON.parse(response.attributes.picture) ).data.url;
            dispatch( saveUserFacebookProfilePicture(userProfilePicture) );
            
        } catch (error: any) {
                        
            console.error(error);
            dispatch( saveUserFacebookProfilePicture(undefined) );
        }
    };
};

export const logout = () => {

    return async (dispatch: AppDispatch) => {

        try {

            const response = await awsSignOutUser();

            if (response === undefined || !response) {
                throw new FnError("actions-user/refreshUserSession()", "awsSignOutUser() undefined response");
            }

            resetUserInfo(dispatch);
            
        } catch (error: any) {
            
            console.error(error);
            resetUserInfo(dispatch);
        }
    };
};
