
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { 
    NormalText, 
    Page, 
    TextBox, 
    TextYellow, 
    Title, 
    TitleBox 
} from "./style/LoginError.style";

const LoginError = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const timeout = setTimeout(() => {
            navigate('/login');
        }, 10000);
      return () => { clearTimeout(timeout); }
    }, [navigate]);
    
    return (
        <Page>
            <TitleBox>
                <Title>Login richiesto per l’acquisto</Title>
            </TitleBox>

            <TextBox>
                <TextYellow>Per l'acquisto di questo prodotto devono essere soddisfatti dei requisiti.</TextYellow>

                <NormalText>A breve verrai indirizzato al Login.</NormalText>
            </TextBox>
        </Page>
    )
};

export default LoginError;