// asset
import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";

import styled from "styled-components";


export const Chat = styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 0px;
    gap: 10px;

    width: 100%;

    cursor: pointer;
    
    @media ${devices.tablet} {

    }
`;

export const Identifyer = styled.img`

    width: 50px;
    height: 50px;

    border-radius: 50%;
`;

export const Notification = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    box-sizing: border-box;

    padding: 6px 0px 15px;
    gap: 7px;

    width: 100%;

    border-bottom: 0.5px solid ${colors.darkGrey};

    @media ${devices.tablet} {

    }
`;

export const Information = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    

    flex-direction: row;
    width: 100%;

    padding: 0px;
    gap: 7px;

    @media ${devices.tablet} {
        
    }
`;

export const Name = styled.h3`

    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    padding: 0px;

    color: ${colors.white};

    @media ${devices.tablet} {
        
    }
`;

export const Hour = styled.p`

    display: flex;
    align-items: center;
    text-align: right;

    height: 9px;

    font-weight: 400;
    font-size: 13px;
    margin: 0px;
    padding: 0px;

    color: ${colors.white070};
    white-space: nowrap;

    @media ${devices.tablet} {
        
    }
`;

export const Message = styled.p`

    width: 100%;
    max-width: 250px;

    font-weight: 400;
    font-size: 12px;
    margin: 0px;
    padding: 0px;

    color: ${colors.white050};

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p, a, h1, h2, h3, h4, h5, h6{

        font-weight: 400;
        font-size: 12px;
        margin: 0px;
        padding: 0px;

        color: ${colors.white050};
    }


    @media ${devices.tablet} { }
`;

export const Notnumber = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    padding: 3px 5.5px;

    width: auto;
    height: 18px;

    background: ${colors.primaryColor};
    border-radius: 100px;

    @media ${devices.tablet} {
        
    }
`;

export const Number = styled.p`

    margin: 0;
    padding: 0;
    
    font-weight: 600;
    font-size: 12px;

    color: ${colors.veryDarkGrey};

    @media ${devices.tablet} {
        
    }
`;