import { FC } from 'react';

import Slider from 'react-input-slider';

// types
import { RoadMapSliderProps } from './RoadMapSlider';

// style
import colors from 'style/palette/palette';
import { Box } from './style/RoadMapSlider.style';


/**
 * RoadMap slider
 * 
 * @author Alessio Grassi
 * 
 * @param inputValue - value of the slide bar
 * @param maxValue - max value of the slide bar
 * @param step - step of the slide bar
 * @param onChange - handler for the values on the slide bar
 * 
 * @returns JSX
 */


const RoadMapSlider: FC<RoadMapSliderProps> = ({
        inputValue,
        maxValue,
        step,
        onChange
    }) => {

    const sliderChangeHandler = (newInputSliderValue: { x: number, y: number }) => {
        onChange(newInputSliderValue.x.toString());
    };


    return (
        <Box>
            <Slider
                axis='x'
                xmax={+maxValue}
                x={+inputValue}
                xstep={+step}
                onChange={sliderChangeHandler}
                styles={{
                    track: {
                        width: "calc(100% - 160px)",
                        height: "7px",
                        background: `${colors.transparent}`
                    },
                    active: {
                        background: `${colors.transparent}`
                    },
                }}
            />
        </Box>
    );
}

export default RoadMapSlider;
