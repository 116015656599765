import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;

    width: 100%;
    max-width: 640px;
    height: 100%;

    padding: 30px 30px 100px 30px;
    gap: 40px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    ::-webkit-scrollbar {
            display: none;
        }

    -ms-overflow-style: none;
    scrollbar-width: none;

    @media ${devices.tablet} {
        padding: 35px 45px 50px;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;

    @media  (max-height: 850px){
        margin-top: 200px;
    }

    @media  (max-height: 600px){
        margin-top: 450px;
    }
`;

export const LevelHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    gap: 20px;
`;

export const LevelTitle = styled.h3`
    color: ${colors.primaryColor};
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    padding: 0px;
    margin: 0px;
`;

export const LevelText = styled.p`
    color: ${colors.white};
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    padding: 0px;
    margin: 0px;
`;

export const TableBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-right: -10%;
    width: 108%;
    overflow-x: auto;
    /* overflow-x: auto; */
    min-height: 403px;

    @media ${devices.tablet} {
        margin-right: 0px;
        max-width: 405px;
        min-height: 410px;
        overflow-x: none;
        border-radius: none;

        ::-webkit-scrollbar{

            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`;

export const LevelTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 10px;
    background: ${colors.darkGrey};
    @media ${devices.tablet} {
        max-width: 405px;
        overflow-x: none;
        border-radius: none;

        ::-webkit-scrollbar{

            display: none;
        }
        
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`;
export const LevelTitleLine = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    padding: 10px 20px;
    gap: 10px;
    width: 100%;

    background: #353E45;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;

    @media ${devices.tablet} {
        justify-content: center;
        align-items: center;
    }
`;

export const LevelTableHeadColumn1 = styled.td`
    flex-direction: column;

    white-space: nowrap;

    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    width: 100%;
    min-width: 52px;

    margin: 0px;
    padding: 0px;
`;

export const LevelTableHeadColumn2 = styled.td`
    flex-direction: column;

    white-space: nowrap;

    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    width: 100%;
    min-width: 189px;

    margin: 0px;
    padding: 0px;
`;

export const LevelTableHeadColumn3 = styled.td`
    flex-direction: column;

    white-space: nowrap;

    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    width: 100%;
    min-width: 122px;

    margin: 0px;
    padding: 0px;
`;

export const TitleSmallText = styled.p`
    white-space: nowrap;

    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-family: Montserrat;
    font-size: 7px;
    font-style: normal;
    font-weight: 600;

    width: 100%;

    margin: 0px;
    padding: 0px;
`;

export const LevelLine = styled.tr`
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    padding: 10px 20px;
    gap: 10px;
    width: 100%;

    background: ${colors.darkGrey};
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;
`;

export const LevelMidLine = styled.tr`
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    padding: 10px 20px;
    gap: 10px;
    width: 100%;

    background: #51616E;
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;
`;

export const LevelLastLine = styled.tr`
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    padding: 10px 20px;
    gap: 10px;
    width: 100%;

    background: ${colors.darkGrey};
    border-radius:  0 0 10px 10px;
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;
`;

export const LevelTableColumn = styled.td<{ isLevel: number }>`
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    min-width: 52px;

    color: ${props => (props.isLevel ? colors.primaryColor : colors.white)};
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;

export const LevelTableColumn2 = styled.td<{ isLevel: number }>`
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    min-width: 189px;

    color: ${props => (props.isLevel ? colors.primaryColor : colors.white)};
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;

export const LevelTableColumn3 = styled.td<{ isLevel: number }>`
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    min-width: 122px;

    color: ${props => (props.isLevel ? colors.primaryColor : colors.white)};
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;

export const ButtonBox = styled.button<{isDisabled?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    position: relative;

    padding: 15px 20px;

    border-radius: 5px;
    border: none;
    background: ${colors.primaryColor};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    cursor: pointer;
    ${({isDisabled}) => isDisabled && (

        css`
            background: ${colors.lightGrey};
            cursor: default;
        `
    )}

    >svg{
        position: absolute;
        top: 2px;
        right: -20px;
        height: 40px;
        width: 40px;
    }
`;

export const ButtonText = styled.p`
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    padding: 0px;
    margin: 0px;

    text-transform: uppercase;
`;

export const LinkLevel = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    position: relative;

    text-decoration: none;
`;