import styled from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;
    margin: 24px 0 0;
`;

export const VerifyEmailButton = styled(Button)`

    width: 100%;
    
    margin-top: 24px;

    font-size: 15px;
    line-height: 15px;

    color: ${colors.black087};
`;
