import store from 'redux/store';
import { Config } from './config.d';


const getEnvironment = () => {
    const domain = window.location.hostname;

    if (domain.includes('localhost')) return 'development';
    if (domain.includes('test')) return 'test';

    return 'production';
};

const getDomain = () => {
    const domain = window.location.hostname;
    const port = window.location.port !== '' ? ':'+window.location.port : '';

    return domain+port;
}

const getUrl = () => {
    const domain = getDomain();

    return 'https://'+domain+"/";
}

const config: Config = {

    environment: getEnvironment(),
    baseTestFrontendUrl: "https://ldl-frontend-v2-test-build.s3.eu-central-1.amazonaws.com/",
    baseProductionFrontendUrl: "https://ldl-frontend-v2-prod-build.s3.eu-central-1.amazonaws.com/",
    baseTestURL: "https://api.ldl-test.eu/",
    baseProductionURL: "https://api.liberidalavoro.it/",
    baseOldTestURL: "https://ldl-test.eu/api/",
    baseOldProductionURL: "https://supporto.liberidalavoro.it/api/",
    testUrl: getUrl(),
    productionUrl: getUrl(),
    localStoragePrefix: "LDLFrontEnd_",
    defaultBrand: {
        "id": 1,
        "name": "Liberi dal Lavoro",
        "domain": "ldl-test.eu",
        "code": "LDL",
        "logo": {
            "id": 5427,
            "fileName": "ico-big-ldl.svg",
            "url": "https://www.viveredipoker.it/ldlapifile/brands/logos/ico-big-ldl.svg",
            "fileType": {
                "id": 29,
                "name": "Brand Logo",
                "relativePath": "brands/logos"
            }
        },
        "brandChannelId": 47,
        "personalChannelId": 48
    },
    
    socialLinks: {
        
        facebook: "https://www.facebook.com/LiberiDalLavoro",
        fbGroupTestID: "1101743300450841",
        fbGroupID: "492540681127066",
        telegram: "https://t.me/LiberiDalLavoroOfficial",
        youtube: "https://www.youtube.com/LiberiDalLavoro",
        instagram: "https://www.instagram.com/liberi_dal_lavoro/",
        twitch: "https://www.twitch.tv/liberidallavoro",
        tikTok: "https://www.tiktok.com/@liberidallavoro_official"    
    },

    socialLinksMB: {
        
        facebook: "https://www.facebook.com/groups/gruppomissionebenessere/",
        fbGroupTestID: "1101743300450841",
        fbGroupID: "492540681127066",
        telegram: "https://t.me/GruppoMissioneBenessere",
        youtube: "https://www.youtube.com/channel/UCQLrKwjCjlDgsGflYKr4COg",
        instagram: "https://www.instagram.com/liberi_dal_lavoro/",
        twitch: "https://www.twitch.tv/liberidallavoro",
        tikTok: "https://www.tiktok.com/@liberidallavoro_official"    
    },

    socialLinksVDR: {
        
        facebook: "https://www.facebook.com/LaVoceDellaRicchezzaPaginaUfficiale",
        fbGroupTestID: "1101743300450841",
        fbGroupID: "492540681127066",
        telegram: "https://t.me/LaVoceDellaRicchezzaCanale",
        youtube: "https://www.youtube.com/@LaVoceDellaRicchezza",
        instagram: "https://www.instagram.com/liberi_dal_lavoro/",
        twitch: "https://www.twitch.tv/liberidallavoro",
        tikTok: "https://www.tiktok.com/@liberidallavoro_official"    
    }
};

export default config;
