import styled, { css } from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const DropdownContainer = styled.div<{position?: string}>`
    position: ${props => props.position ?? 'relative'};
    height: 100%;
`;

export const DropdownButtonContainer = styled.div<{buttonWidth?: number}>`
    height: 100%;
    display: flex;
`;

export const DropdownButton = styled(Button)<{buttonWidth?: number, buttonColor?: string, buttonHeight?: string, highlighted?: boolean}>`

    background-color: ${props => props.buttonColor ? props.buttonColor : (props.highlighted ? '#002d4f' : colors.grey)};
    ${(props) => props.highlighted && (css`border: 1px solid ${colors.primaryColor};`)} 
    color: ${props => props.highlighted ? colors.primaryColor : 'white'};
    transition: background-color 0.3s ease;
    width: auto;
    padding: 5px;
    height: ${props => props.buttonHeight ?? '36px'};
    width: ${props => props.buttonWidth ? props.buttonWidth+'px' : 'fit-content'};
    gap: 10px;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
    padding: 10px;

    > svg {

        max-width: 20px;

        height: 100%;
        max-height: 18px;
    }

`;

export const DropdownContent = styled.div<{hidden: boolean, opacity: number, maxWidth?: string, width?: number, top?: number, left?: number, right?: number}>`
    position: absolute;
    display: ${props => props.hidden ? 'none' : 'block'};
    opacity: ${props => props.opacity};
    background-color: #3b4851;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    width: fit-content;
    padding: 10px;
    margin: 0 auto;
    z-index: 15;
    transition: opacity .5s ease;
    ${(props) => props.maxWidth && (css`max-width: ${props.maxWidth};`)} 
    //Center content if the width is provided
    ${(props) => props.width!==undefined && (css`left: calc((100% - ${props.width}px)/2);`)} 

    top: ${props => props.top!==undefined ? props.top+'px' : 'calc(100% + 3px)'};
    ${(props) => props.right!==undefined && (css`right: ${props.right}px;`)}
    ${(props) => props.left!==undefined && (css`left: ${props.left}px;`)}

`;