import { useState, FC, useEffect } from "react";

// hooks
import useAppSelector from "hooks/useAppSelector";

//routing
import { useNavigate } from "react-router-dom";

// utils
import { formattedCurrency } from "utils/supportFunctions";
import { getLessonTabText } from "./CardCoach.helper";
import { getPaypalUrl } from "utils/paypal";
import { useIsMounted } from "usehooks-ts";
import { getCoursePayment } from "services/payments.services";
import { setItemToLocalStorage } from "utils/localStorage";

// components
import EmptyImage from "components/EmptyImage";
import PaymentMenuHelper from "../PaymentMenuHelper/PaymentMenuHelper.component";

// assets
import { ReactComponent as ArrowPaymentIconSVG } from "./assets/arrowPaymentIcon.svg"
import { ReactComponent as PaypalIconSVG } from "./assets/PaypalIcon.svg"
import { ReactComponent as ArrowOpenPaymentSVG } from "./assets/arrowOpenPaymentIcon.svg"
import { ReactComponent as BankIconSVG } from "./assets/bankIcon.svg"
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';

// style
import { 
    CoachCard,
    CoachDescription,
    CoachDetails,
    CoachInfo,
    CoachPhoto,
    CoachProfile,
    Text, 
    CoachBgrid,
    CoachButton,
    CoachPrice,
    CoachDetailsInfo,
    CoachButtonText,
    CoachPaypalPrice,
    HelpCards,
    ButtonChoose,
    NumberLessonBox,
    LessonButtonTab,
    CoachInfoPayment,
    CoachPhotoMobile,
    CoachProfilePhotoBox,
    CoachProfileMobile
} from "./style/CardCoach.style";
import PartialLibertyPaymentModal from "components/PartialLibertyPaymentModal/PartialLibertyPaymentModal.component";


const CardCoach: FC<{ 
    lessons: any[], 
    transferModalHandler: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    openBankTransferModal?: () => void,
    libertyProductID?: (courseID: number) => void,
    productPriceHandler?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void
}> = ({ 
    lessons, 
    transferModalHandler,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    productPriceHandler,
    loadingOn,
    loadingOff
}) => {

    const { libertyPointsAvailableBalance } = useAppSelector(state => state.user)
    const { isAppMobile } = useAppSelector(state => state.ui)
    const [activeButton, setActiveButton] = useState<number>(0);
    const [isSelectorOpenActive, setIsSelectorOpenActive] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<'paypal' | 'bonifico'>("paypal");
    const [typePaymentTextCochButton, setTypePaymentTextCoachButton] = useState<string>("Euro");
    const isLibertyPointVisibal = (typePaymentTextCochButton === "Liberty");
    const isLibertyPointPartialVisibal = (typePaymentTextCochButton === "PartialLiberty");
    const isEuroVisibal = (typePaymentTextCochButton === "Euro");
    const [priceReturnFromBackend, setPriceReturnFromBackend] = useState<number>();
    const [isPartialLibertyPaymentModalVisible, setIsPartialLibertyPaymentModaVisible] = useState<boolean>(false);
    const [libertyPrice, setLibertyPrice] = useState<number>();
    const [partialLibertyPrice, setPartialLibertyPrice] = useState<number>();

    const isMounted = useIsMounted();
    const navigate = useNavigate();
    
    const { id: productId, productLessonPack, paymentTypes } = lessons[activeButton];
    const { coach } = productLessonPack;
    const { name, profilePicture, topic, description } = coach;
    const { url: pictureUrl } = profilePicture;
    
    const closePartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(false);
    const openPartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(true);

    const onTabClickButtonHandler = (index: number) => setActiveButton(index);
    const onClickSelectorOpenHandler = () => setIsSelectorOpenActive(prevState => !prevState);
    
    const closeSelectorHandler = () => {
        setIsSelectorOpenActive(false);
    };

    const handlePaymentMethod = (paymentMethod:string) => {
        if (paymentMethod === "paypal") {
            setSelectedPayment("paypal");
        }
        if (paymentMethod === "bonifico") {
            setSelectedPayment("bonifico");
        }
    };

    const CalculateLibertyPrice = () => {
        const libertyValue = 1.22;
        const result = (lessons[activeButton].productLessonPack.price / libertyValue)
        setLibertyPrice(+result.toFixed(2));
    };

    const CalculatePartialLibertyPrice = () => {
        const libertyValue = 1.22;
        const result = (lessons[activeButton].productLessonPack.price - (+libertyPoints * libertyValue))
        setPartialLibertyPrice(+result.toFixed(2));
    };

    const typeOfPayment = () => {
        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "paypal")) {
            const paypal = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Paypal'));
            console.log("Paypal", paypal);
            return paypal.length > 0 ? paypal[0].id : null;
        };

        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "bonifico")) {
            const transfer = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Bonifico'));
            console.log("Bonifico", transfer);
            return transfer.length > 0 ? transfer[0].id : null;
        };

            const liberty = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Punti'));
            console.log("Libertry", liberty);
            return liberty.length > 0 ? liberty[0].id : null;
    };

    const coachPayPal = async() => {
        try {
            loadingOn();
            const response = await getCoursePayment(productId, typeOfPayment());
            if (!isMounted) return;
            if (!response || response === undefined) return;
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);

            window.location.href = paypalUrl;
            setItemToLocalStorage('New Coach Lesson', 'true')
        } catch (error: any) {
            loadingOff();
            console.warn("ERROR:", error);
        }
    };

    const coachTransfer = async() => {
        try {
            loadingOn();
            const response = await getCoursePayment(productId, typeOfPayment());
            if (response.status !== 'CREATED') new Error ("Qualcosa è andato storto con la creazione dell'acquisto.");
            const createdTransactionGross = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].gross

            setPriceReturnFromBackend(createdTransactionGross);
            loadingOff();
            setItemToLocalStorage('New Coach Lesson', 'true')
            if(openBankTransferModal)openBankTransferModal();
            transferModalHandler(name, `${createdTransactionGross}`, response.id);

        } catch (error: any) {
            loadingOff();
            console.warn("ERROR:", error);
        }
    };
    

    const onPaymentButtonClickHandler = async () => {
        
        if (coachPaymentPayPal) {
            coachPayPal();
        }

        if (coachPaymentTransfer) {
            coachTransfer();
        };

        if (coachPaymentLibertyShop) {
            if (libertyProductID) libertyProductID(productId);
            if (productPriceHandler) productPriceHandler(lessons[activeButton].productLessonPack.price);
            // openLibertyPaymentModal();
            onLibertyClick();
        };

        if (coachPaymentPartialLibertyShop) {
            openPartialLibertyPaymentModal();
        };
    };

    const getCoachPicture = () => {

        if (lessons === undefined) return <EmptyImage isCircular />;
        if (lessons[0] === undefined) return <EmptyImage isCircular />;
        if (lessons[0].productLessonPack === undefined) return <EmptyImage isCircular />;
        if (lessons[0].productLessonPack.coach === undefined) return <EmptyImage isCircular />;
        if (lessons[0].productLessonPack.coach.profilePicture === undefined) return <EmptyImage isCircular />;
        if (lessons[0].productLessonPack.coach.profilePicture.url === undefined) return <EmptyImage isCircular />;

        return (
            <CoachProfilePhotoBox>
                <CoachPhoto 
                    alt="Coach profile picture"
                    src={pictureUrl}
                />
            </CoachProfilePhotoBox>
        )
    };

    const getProductId = () => {

        if (lessons === undefined) return;
        if (lessons[activeButton] === undefined) return;
        if (lessons[activeButton].id === undefined) return;
        return lessons[activeButton].id;
    };

    useEffect (()=>{
        console.log("Liberty Point", libertyPoints);
        
            if (libertyPoints === 0) return setTypePaymentTextCoachButton("Euro");

            if ((libertyPoints * 1.22) < lessons[activeButton].productLessonPack.price)
            {
                setTypePaymentTextCoachButton("PartialLiberty");
                return;
            }

            setTypePaymentTextCoachButton("Liberty");
    },[lessons[activeButton].productLessonPack.price])

    useEffect (()=>{
        CalculateLibertyPrice();
        CalculatePartialLibertyPrice();

    },[lessons[activeButton].productLessonPack.price, libertyPointsAvailableBalance])

    const buttonHasTabs = (lessons.length > 1);
    const coachProfilePhoto = getCoachPicture();
    // const productId = getProductId();

    const coachPaymentPayPal = (selectedPayment === "paypal" && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    const coachPaymentTransfer = (selectedPayment === "bonifico" && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    const coachPaymentPartialLibertyShop = (isLibertyPointPartialVisibal);
    const coachPaymentLibertyShop = (isLibertyPointVisibal && openLibertyPaymentModal);
    const libertyPoints = ((libertyPointsAvailableBalance === undefined) || (libertyPointsAvailableBalance <0)) ? 0 : libertyPointsAvailableBalance;



    return(
        <>
            <HelpCards>    
                <CoachCard>
                    <CoachInfo>

                        <CoachDetails>
                            {name}
                        </CoachDetails>

                        <CoachDetailsInfo dangerouslySetInnerHTML={{__html: topic}} />
                    </CoachInfo>

                    <CoachProfile>
                        {!isAppMobile && coachProfilePhoto}

                        <CoachInfoPayment>
                            <CoachProfileMobile>
                                {isAppMobile && coachProfilePhoto}

                                <CoachDescription>
                                    <Text dangerouslySetInnerHTML={{__html: description}} />
                                </CoachDescription>
                            </CoachProfileMobile>

                            <CoachBgrid>
                                <NumberLessonBox>
                                    {buttonHasTabs && (
                                        <>
                                            {lessons.map((lesson: any, index: number) => {

                                                const lessonText = getLessonTabText(lesson);
                                                return (
                                                    <LessonButtonTab 
                                                        key={index}
                                                        onClick={() => onTabClickButtonHandler(index)}
                                                        isActive={activeButton === index}
                                                    >
                                                        {lessonText}
                                                    </LessonButtonTab>
                                                )
                                            })}
                                        </>
                                    )}
                                </NumberLessonBox>

                                <CoachButton 
                                    isWithoutTab={!buttonHasTabs}
                                    onClick={onPaymentButtonClickHandler}
                                >

                                    <CoachButtonText>
                                        {isLibertyPointVisibal &&
                                            <>
                                                <LibertyPointIconSVG />{libertyPrice}
                                            </>    
                                        }

                                    {isLibertyPointPartialVisibal &&
                                            <>
                                                <LibertyPointIconSVG />{libertyPoints} + € {partialLibertyPrice}
                                            </>    
                                        }

                                        {isEuroVisibal && `€ ${formattedCurrency(lessons[activeButton].productLessonPack.price)}`}
                                    </CoachButtonText>

                                    <CoachButtonText>
                                        {lessons[activeButton].description}
                                    </CoachButtonText>
                                </CoachButton>

                                <CoachPrice>
                                    <CoachPaypalPrice>
                                        {(isLibertyPointVisibal || isLibertyPointPartialVisibal) && `anzichè € ${formattedCurrency(lessons[activeButton].productLessonPack.price)}`}

                                        {isEuroVisibal && `€ ${formattedCurrency(lessons[activeButton].productLessonPack.price)}`}
                                    </CoachPaypalPrice>
                                    
                                    {selectedPayment === 'paypal' && !isLibertyPointVisibal && <PaypalIconSVG/>}

                                    {selectedPayment === 'bonifico' && !isLibertyPointVisibal && <BankIconSVG/>}

                                    {(isEuroVisibal || isLibertyPointPartialVisibal) &&
                                        <ButtonChoose isOpen={isSelectorOpenActive} onClick={onClickSelectorOpenHandler}>
                                            { !isSelectorOpenActive && 
                                                <ArrowPaymentIconSVG/>}
                                            { isSelectorOpenActive && 
                                                <ArrowOpenPaymentSVG/>}
                                        </ButtonChoose>
                                    }

                                { isSelectorOpenActive && 
                                    <PaymentMenuHelper
                                        handlePaymentMethod={handlePaymentMethod}
                                        selectedPayment={selectedPayment}
                                        onClose={closeSelectorHandler}
                                    />
                                }
                                </CoachPrice>
                            </CoachBgrid>
                        </CoachInfoPayment>
                    </CoachProfile>
                </CoachCard>
            </HelpCards>

            {isPartialLibertyPaymentModalVisible && (
                <PartialLibertyPaymentModal
                    onCloseButtonModal={closePartialLibertyPaymentModal}
                    courseID={productId}
                    price={partialLibertyPrice}
                    LibertyPoint={libertyPointsAvailableBalance}
                    typeOfPayment={typeOfPayment}
                    paypalCoursePayment={coachPayPal}
                    transferCoursePayment={coachTransfer}
                    selectedPayment={selectedPayment}
                />
            )}
        </>
    );
};

    
export default CardCoach;