import styled, { css } from 'styled-components';

// types
import {
    TimeLineCircleProps,
    SmallCenterCirleProps,
    LinkLineProps,
    EventDetailsCellProps
} from './EventBox.style.d';

// style
import colors from 'style/palette/palette';


export const TimeLineCircle = styled.div<TimeLineCircleProps>`

    min-width: 12px;
    width: 12px;

    min-height: 12px;
    height: 12px;

    margin: 0 0 0 20px;

    border-radius: 50%;
    background: ${colors.primaryColor};
    
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;

    transform: translate(0, -50%);

    @media (min-width: 769px) {

        min-width: 15.36px;
        width: 15.36px;

        min-height: 15px;
        height: 15px;
    }

    ${({ isPast }) => isPast && (

        css`
            background: ${colors.simpleGrey};
        `
    )}

    ${({ isActive }) => isActive && (

        css`
            min-width: 19.2px;
            width: 19.2px;

            min-height: 19.2px;
            height: 19.2px;

            margin: 0 0 0 25px;

            background: ${colors.green};
        `
    )}
`;

export const SmallCenterCirle = styled.div<SmallCenterCirleProps>`

    min-width: 4px;
    width: 4px;

    min-height: 4px;
    height: 4px;

    border-radius: 50%;
    background: ${colors.black053};

    ${({ isActive }) => isActive && (

        css`
            background: ${colors.white};

            min-width: 6px;
            width: 6px;

            min-height: 6px;
            height: 6px;
        `
    )}
`;

export const CircleBoxRef = styled.div`

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
`;


export const LinkLine = styled.div<LinkLineProps>`

    width: 1px;
    height: 15px;

    background: ${colors.primaryColor};

    position: absolute;
    bottom: 100%;

    @media (min-width: 769px) {

        height: 25px;
    }

    ${({ isBottom }) => isBottom && (

        css`
            top: 100%;
            bottom: auto;
        `
    )}

    ${({ isPast }) => isPast && (

        css`
            background: ${colors.simpleGrey};
        `
    )}

    ${({ isActive }) => isActive && (

        css`
            height: 20px;
            background: ${colors.green};

            @media (min-width: 769px) {

                height: 23.2px;
            }
        `
    )}
`;

export const LinkLineBoxRef = styled.div`

    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    position: relative;
`;

export const EventDetailsCell = styled.div<EventDetailsCellProps>`

    position: absolute;
    bottom: 100%;
    left: -23px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    ${({ isActive }) => isActive && css`left:-30px;`}
    ${({ isBottom }) => isBottom && (

        css`
            top: 100%;
            bottom: auto;
        `
    )}
`;
