import styled from 'styled-components';


export const LessonsGrid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    //width: 100%;

    padding: 16px 0px 5px;
`;

export const LessonsTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 0px;

    gap: 0px;
`;

export const RowLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px 15px;
    gap: 20px;

    width: 100%;

    background: rgba(0, 0, 0, 0.002);
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);
`;

export const InfoText = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 10%;

    color: rgba(255, 255, 255, 0.7);
`;

export const CoachTextDesktop = styled.p`

    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    flex-basis: 15%;
    white-space: nowrap;

    color: rgba(255, 255, 255, 0.7);
`;

export const TitleDesktop = styled.p`

    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    flex-basis: 64%;

    color: rgba(255, 255, 255, 0.7);

`;

export const ActionDesktop = styled.p`

    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    justify-self: flex-end;
    text-align: center;
    /* flex-basis: 5%; */

    color: rgba(255, 255, 255, 0.7);
`;
