import { 
    MainTitle, 
    Paragraph, 
    ParagraphTitle, 
    TotalPage,
    Text,
    TextNumber,
    List,
    ListText,
    Link
} from "./style/Terms.style";

const Terms = () => {
    
    return (
        <TotalPage>
            <MainTitle>Termini e Condizioni generali del contratto</MainTitle>

            <Paragraph>
                <Text>Ai fini del rapporto obbligazionario instaurato, si intende per:</Text>

                <Text>
                    - “Cliente”, il soggetto sia persona fisica che persona giuridica, che ha acquistato il servizio di corsi on-line secondo le informazioni e 
                        condizioni espresse nel presente contratto e nella scheda descrittiva eventualmente disponibile sui siti di tutti i brand collegati con Easy 
                        TenerLife S.L., da intendersi come parte integrante del contratto medesimo;
                    <br />- “Produttore”, la persona giuridica (EASYTENERLIFE SL) che eroga il servizio di corsi on-line a pagamento, identificandolo con il proprio 
                        nome, marchio o altro segno distintivo;
                    <br />- “Prodotto”, il Corso on line;
                    <br />- “Contratto a distanza”, quel contratto concluso tra il Produttore e il Cliente senza la presenza fisica e simultanea delle parti, 
                        mediante l’uso esclusivo di uno o più mezzi di comunicazione a distanza;
                    <br />- “Sito”, il sito Internet del brand collegato ad EASYTENERLIFE S.L.;
                </Text>

                <Text>
                    Preventivamente all’acquisto on-line del prodotto (con contestuale obbligo di pagamento integrale e/o rateale) il Cliente esprime, con 
                    l’iscrizione o con il login al sito, il proprio consenso alle presenti condizioni di vendita, che in tal modo si assumono riconosciute ed 
                    approvate anche ai sensi degli artt. 1341 e 1342 c.c., unitamente alle disposizioni che regolano le procedure di registrazione, accesso, 
                    navigazione ed uso del sito collegato ad EASYTENERLIFE S.L.
                </Text>

                <Text>
                    Il Cliente autorizza espressamente EASYTENERLIFE SL ad utilizzare il proprio indirizzo e-mail in relazione alle attività inerenti al 
                    contratto e ad eventuali invii di informazioni sui servizi a distanza oggetto del presente accordo; 
                </Text>

                <Text>
                    Le presenti condizioni generali possono subire modifiche e/o aggiornamenti in qualsiasi momento da parte di EASYTENERLIFE SL, 
                    che provvederà a darne comunicazione attraverso i normali canali di comunicazione presenti sul sito.
                </Text>
            </Paragraph>

            <Paragraph>
                <Text>Le suddette premesse costituiscono parte integrante delle presenti condizioni generali</Text>

                <TextNumber>
                    Art.1 Oggetto del contratto
                </TextNumber>

                <Text>
                    Il presente contratto a distanza ha per oggetto l’erogazione, da parte del Produttore EASYTENERLIFE SL al Cliente, di corsi on-line.
                </Text>

                <Text>
                    Il Cliente, successivamente alla registrazione sul sito, ed al regolare acquisto del prodotto, riceverà una notifica via email della 
                    disponibilità di quest’ultimo sul proprio account.
                </Text>

                <Text>
                    Il corso prescelto dal cliente non prevede il rilascio di certificazioni.
                </Text>

                <TextNumber>
                    Art.2 Informazioni precontrattuali
                </TextNumber>

                <Text>
                    Il Cliente dichiara di aver letto, preventivamente all’acquisto on-line del prodotto, tutte le informazioni contenute nella “Pagina di vendita”, 
                    accessibile sul sito e da cui risultano evidenti le caratteristiche principali del prodotto, l’identità del Produttore, il prezzo e le modalità 
                    di pagamento.
                </Text>

                <TextNumber>
                    Art.3 Conclusione del contratto
                </TextNumber>

                <Text>
                    Ai sensi e per gli effetti dell’art. 1326 c.c., il presente contratto a distanza si intende perfezionato nel momento in cui EASYTENERLIFE SL 
                    (e per esso gli Operatori preposti alla ricezione di dati e comunicazioni afferenti alla conclusione ed esecuzione del rapporto contrattuale) 
                    ha conoscenza dell’adesione del Cliente all’offerta formativa, manifestata con la propria iscrizione on-line al Corso prescelto, ovvero 
                    attraverso il pagamento del prezzo o della quota di questo richiesta.
                </Text>

                <Text>
                    Il Cliente, perfezionatasi la procedura di iscrizione e di pagamento integrale e/o rateale della relativa quota, può accedere al prodotto sul proprio 
                    account. 
                </Text>

                <TextNumber>
                    Art.4 Tutela del diritto d’autore
                </TextNumber>

                <Text>
                    Il Cliente riconosce in capo a EASYTENERLIFE SL l’esclusiva proprietà di tutte le parti del Sito, quali a titolo meramente esemplificativo 
                    e non esaustivo, i loghi, le immagini, i testi, i contenuti dei corsi: ne consegue, in applicazione della Legge 22.04.1941 n° 633 
                    (Legge sul diritto d’autore), l’assoluto divieto d’uso commerciale da parte del Cliente, di riproduzione totale o parziale, di rielaborazione 
                    e di trasmissione sotto qualunque forma e con qualsiasi modalità, salvo preventiva autorizzazione scritta di EASYTENERLIFE SL.
                </Text>

                <Text>
                    È vietato copiare, distribuire, pubblicare o altrimenti utilizzare o sfruttare i contenuti acquisiti senza il preventivo consenso scritto di 
                    EASYTENERLIFE SL.
                    Il Cliente, responsabile della conservazione e della segretezza delle credenziali assegnategli, e titolare (per effetto dell’iscrizione al Corso) 
                    di un diritto personale e non cedibile di accesso all’area riservata, si impegna così a non far utilizzare e/o visionare, divulgare in nessun 
                    modo, i servizi a terzi, astenendosi dal compiere ogni atto che leda i diritti di esclusiva e di proprietà di EASYTENERLIFE SL; nonché 
                    manlevando e tenendo indenne il Produttore da ogni pretesa e/o rivendicazione derivante dall’uso e/o abuso di terzi.
                    In caso di violazione dei prescritti obblighi, il contratto si intenderà risolto di diritto, con consequenziale immediata sospensione 
                    dell’account, e EASYTENERLIFE SL sarà libera di agire giudizialmente a tutela di ogni diritto disatteso, ivi compreso il risarcimento dei danni 
                    patiti, i quali concordemente e preventivamente si stabiliscono in € 50.000,00.
                    Inoltre, qualora si ritenga esistente o altamente probabile un problema di sicurezza o di utilizzo non autorizzato, EASYTENERLIFE SL potrà 
                    sospendere l’uso dell’account attribuito al Cliente.
                </Text>

                <TextNumber>
                    Art.5 Fornitura del servizio
                </TextNumber>

                <Text>
                    Il Cliente dichiara di essere a conoscenza e di accettare i prerequisiti hardware e software necessari per poter accedere al Corso on-line 
                    acquistato quali:
                </Text>

                <Text>
                    •	Le piattaforme sono state realizzate in maniera da offrire a tutti i possibili utenti il massimo della compatibilità con i più comuni 
                    sistemi disponibili. Sono stati effettuati test su tutte le piattaforme più diffuse (Mac OS, Windows, Linux) e sui principali browser 
                    (Internet Explorer, Mozilla Firefox, Google Chrome, Apple Safari).
                    <br />•	I requisiti hardware e software richiesti sono minimi e sono generalmente soddisfatti su un normale sistema standard per uso casalingo. 
                    Per poter usufruire al meglio dei contenuti multimediali presenti e dei servizi di tutoring online, è necessario:
                    <br />•	avere una connessione alla rete Internet. Per un utilizzo ottimale è consigliata una connessione almeno ADLS;
                    <br />•	avere installato sulla propria macchina uno tra i principali browser disponibili regolarmente aggiornati;
                    <br />•	avere installato sulla propria macchina il software Adobe Acrobat Reader o equivalenti per visualizzare EVENTUALI materiali in formato 
                    pdf. Questo software è disponibile gratuitamente all’indirizzo: http://www.adobe.it/products/acrobat/readstep2.html
                </Text>

                <Text>
                    Il Cliente di impegna, inoltre, ad adeguare il proprio impianto hardware e/o software nel caso in cui fosse necessario a seguito di successivi 
                    aggiornamenti della piattaforma.
                    <br />
                    Attraverso i pagamenti il Cliente accede ai Corsi/Informazioni digitali, i quali vengono periodicamente aggiornati dal Produttore al fine di 
                    mantenere sempre attuali i contenuti.
                    <br />
                    EASYTENERLIFE SL avrà la facoltà di interrompere l’erogazione del Servizio acquistato dal Cliente, dandone immediata comunicazione via Web o 
                    mediante altra forma, qualora:
                </Text>

                <Text>
                    •	vi fossero fondati motivi per ritenere che si potrebbero verificare problemi di sicurezza e/o di tutela del diritto di riservatezza;
                    <br />
                    •	si ravvisi la necessità-opportunità di migliorare le procedure di accesso ai Corsi on-line, incrementandone l’efficienza;
                    <br />
                    •	non sia stato corrisposto il pagamento da parte del Cliente, ovvero di un solo rateo di esso;
                    <br />
                    •	allorquando il cliente utilizzi termini o epiteti offensivi e/o diffamatori attraverso i sistemi di intercomunicazione propri del sito, 
                    ovvero attraverso altre piattaforme, ovvero allorquando il cliente, attraverso qualsiasi mezzo, fornisca pubblicità, durante la vigenza del 
                    contratto, a siti concorrenti nel medesimo settore di servizi.
                    <br />
                </Text>

                <Text>
                    I suddetti oneri di comunicazione non possono considerarsi sussistenti in casi, alternativamente considerati, di forza maggiore, 
                    necessità od urgenza.
                </Text>

                <TextNumber>
                    Art.6 Sicurezza e riservatezza dei dati
                </TextNumber>

                <Text>
                    Il Cliente garantisce di disporre legittimamente di tutte le informazioni immesse all’interno del Sito Internet del brand collegato ad 
                    EASYTENERLIFE S.L. ai fini dell’acquisto del Corso e dell’accesso al relativo Servizio, assicurando altresì̀ che le stesse non violino 
                    in alcun modo, direttamente o indirettamente, il diritto di terzi. Pertanto, il Cliente si obbliga a non immettere dati di cui non possa 
                    liberamente disporre.
                    <br />
                    È fatto altresì̀ divieto al Cliente di inserire dati falsi e/o di fantasia nella procedura di registrazione-iscrizione al Corso e nelle 
                    successive ulteriori comunicazioni legate alla esecuzione del contratto.
                    <br />
                    All’uopo, il Cliente manleva EASYTENERLIFE SL:
                </Text>

                <Text>
                    •	da ogni responsabilità derivante dall’emissione di dati e documenti fiscali errati, essendo il Cliente stesso unico responsabile del 
                    corretto inserimento;
                    <br />
                    •	da ogni obbligo e/o onere di accertamento e controllo diretto e indiretto al riguardo.
                    <br />
                </Text>

                <Text>
                    Qualora venga accertata, da EASYTENERLIFE SL o su segnalazione di terzi, la falsità dei dati forniti, EASYTENERLIFE SL si riserva il diritto 
                    di impedire/sospendere/annullare la registrazione del Cliente.
                </Text>

                <TextNumber>
                    Art.7 Responsabilità e obblighi
                </TextNumber>

                <Text>
                    Il Cliente è obbligato ad usufruire del servizio acquistato in via esclusiva. Egli, pertanto, si impegna ad agire secondo buona fede ed 
                    in particolare a:
                    <br />
                    •	non cedere il prodotto a terzi;
                    <br />
	                •	svolgere le attività̀ di visualizzazione dei corsi personalmente.
                    <br />
                    Ogni comportamento contrario a buona fede da parte del Cliente, così come la violazione dei predetti obblighi e divieti, il cui elenco è da 
                    considerarsi non esaustivo, non può̀ in alcun modo considerarsi tollerato da EASYTENERLIFE SL in quanto idoneo a comprometterne l’immagine, 
                    l’onore ed il decoro, fondando la esclusiva responsabilità̀ del Cliente e il diritto della Società̀ ad interrompere l’erogazione del servizio 
                    e, se del caso, adire l’Autorità̀, nonché́ richiedere il risarcimento del danno nei termini di cui all’art.4.
                    <br />
                </Text>

                <Text>
                    Il Cliente, dunque, solleva Il Produttore EASYTENERLIFE SL da qualunque responsabilità̀ in caso di denunce, azioni legali, azioni governative 
                    o amministrative, perdite o danni scaturiti dall’uso dei servizi offerti da EASYTENERLIFE SL contrario a buona fede e/o illegale da parte 
                    del Cliente stesso, di terzi ad esso collegati o terzi in genere.
                    <br />
                    Salvo i casi di dolo o colpa grave del produttore, il Cliente esonera espressamente EASYTENERLIFE SL da qualsiasi responsabilità̀ per danni 
                    diretti 
                    e indiretti:
                    <br />
                    •	che il Cliente stesso o terzi possano subire in relazione o in dipendenza dell’erogazione del Corso, o per effetto della interruzione 
                    del funzionamento;
	                <br />
                    •	che il Cliente stesso o terzi possano in qualche modo accusare per la mancata erogazione della connettività̀ da parte del gestore della 
                    rete di telecomunicazione, o anche per il ricorso da parte del Cliente all’utilizzo di connettività̀ (non ad alta velocità) tecnicamente 
                    incompatibile con il Servizio, nonché́ per la mancata fruizione del Corso a seguito di difetti riscontrati dal Cliente nella fruizione dello 
                    stesso, qualora tutto ciò̀ sia dipeso dal mancato possesso dei requisiti minimi di sistema di cui all’Art. 6, la cui verifica è onere del Cliente;
	                <br />
                    •	causati da terzi che illecitamente accedessero al Corso on-line, a causa della mancanza da parte del Cliente di cautela nella custodia 
                    dello user name e della password attribuitegli, o per l’assenza di altre misure di sicurezza che il Cliente è tenuto ad adottare;
                    <br />
                </Text>

                <Text>
                    In nessun caso il Produttore sarà̀ ritenuto responsabile:
                    <br />
                    •	del malfunzionamento del Servizio derivante da guasti, sovraccarichi, interruzioni delle linee telefoniche, elettriche o riferite alla 
                    rete Internet;
	                <br />
                    •	per inadempimenti di terzi che pregiudichino la fruizione del Servizio, compresi, in via esemplificativa, i rallentamenti di velocità o 
                    il mancato funzionamento delle linee telefoniche e degli elaboratori che gestiscono il traffico telematico fra il Cliente e la piattaforma 
                    didattica da cui è erogato il Servizio, ovvero problemi esclusivamente addebitabili al server che ospita il Servizio;
	                <br />
                    •	per un eventuale uso fraudolento e/o indebito di conti correnti/carte di credito da parte del Cliente.
                    <br />
                </Text>

                <Text>
                    È esclusiva responsabilità̀ del Cliente assicurare l’interoperabilità̀ tra la connettività̀, di cui si sia dotato, e la piattaforma da 
                    cui è erogato il Servizio.
                    <br />
                    Il Produttore, inoltre, non potrà̀ essere ritenuto responsabile per inadempimenti alle proprie obbligazioni derivanti da cause non 
                    ragionevolmente prevedibili, da impedimenti che esulino dalla sfera del proprio diretto ed immediato controllo, o da cause di forza maggiore. 
                    Al verificarsi di uno dei predetti eventi di forza maggiore, EASYTENERLIFE SL ne darà informazione al Cliente tramite il Sito, insieme alle 
                    prospettive di riattivazione regolare del Servizio.
                    <br />
                    Il Cliente si obbliga a tenere indenne EASYTENERLIFE SL da tutte le perdite, i danni, le responsabilità̀, i costi, gli oneri e le spese 
                    (anche legali) che dovessero essere sostenute dal Produttore quale conseguenza di qualsiasi inadempimento agli obblighi assunti dal 
                    Cliente con la sottoscrizione del presente Contratto o Modulo di adesione, e comunque connesse alla immissione delle informazioni nel sito; 
                    il tutto, anche in ipotesi di richieste di risarcimento danni avanzate da terzi a qualunque titolo.
                    <br />
                    Il Cliente concorda nel sollevare il Produttore EASYTENERLIFE SL da qualunque responsabilità̀ in caso di denunce, azioni legali, azioni 
                    governative o amministrative, perdite o danni scaturiti dall’uso illegale del Servizio da parte del Cliente o di terzi.
                    <br />
                    Il Produttore non potrà̀ altresì̀ ritenersi responsabile per i Prodotti erroneamente acquistati dal Cliente.
                    <br />
                    Le informazioni e i materiali relativi ai Prodotti erogati vengono elaborati, rivisti ed aggiornati con accuratezza, completezza ed 
                    adeguatezza, grazie anche all’attività̀ di supporto e consulenza specialistica offerta da professionisti nell’attività̀ di progettazione e 
                    realizzazione del materiale.
                    <br />
                    Il Cliente, infine, non potrà̀ cedere a terzi alcun obbligo o diritto originato dal presente Contratto, in assenza di preventiva 
                    autorizzazione scritta, rilasciata ad insindacabile giudizio del Produttore e comunicata dal medesimo a mezzo raccomandata a/r o PEC.
                    <br />
                </Text>

                <TextNumber>
                    Art.8 Condizioni di recesso
                </TextNumber>

                <Text>
                    Il Cliente-Consumatore consapevolmente ed espressamente accetta di perdere ogni diritto di recesso dal presente contratto a distanza, 
                    trovando applicazione le eccezioni al diritto di recesso di cui all’ Art. 59, c.1 lett. a) e o) del Decreto Legislativo 6 settembre 
                    2005 n.206 (Codice del Consumo).               
                    <br />
                    Il Consumatore, infatti, con l’acquisto del presente corso on-line, acconsente alla erogazione di contenuti didattici digitali mediante un 
                    supporto non materiale, con l’accordo espresso e con l’accettazione del fatto che tale circostanza preclude ogni diritto di recesso.
                    <br />
                    Inoltre, il Consumatore accetta di perdere il proprio diritto di recesso dal presente servizio, in quanto reso completamente accessibile e 
                    fruibile all’utente tramite l’invio telematico delle credenziali di autenticazione.
                </Text>

                <TextNumber>
                    Art.9 Risoluzione di diritto del contratto
                </TextNumber>

                <Text>
                    Tutte le obbligazioni precedentemente assunte dal Cliente, nonché́ la garanzia del buon fine del pagamento, anche di un solo rateo, 
                    effettuato dal medesimo, hanno carattere essenziale e rilevante, cosicché́ l’inosservanza da parte del Cliente di una soltanto di 
                    esse determinerà̀ l’immediata risoluzione di diritto del contratto.
                    <br />
                    È fatto salva in ogni caso il diritto per EASYTENERLIFE SL di agire giudizialmente per il risarcimento di ogni danno e tutela di ogni diritto leso.
                    <br />
                </Text>

                <TextNumber>
                    Art.10 Informativa e consenso al trattamento dei dati personali. 
                </TextNumber>

                <Text>
                    EASYTENERLIFE SL si impegna a trattare tutti i dati personali acquisiti in relazione al contratto nel rispetto della normativa in materia di 
                    riservatezza. A riguardo si offre apposita informativa consultabile sul sito, la quale è da intendersi letta ed acconsentita dal Cliente con 
                    l’atto stesso della registrazione, ovvero con il login.
                    <br />
                    Il cliente può̀ esercitare i diritti di cui al Codice privacy inviando una mail all’indirizzo:
                </Text>

                <TextNumber>
                    Art.11 Ordinamento applicabile/Foro competente. 
                </TextNumber>

                <Text>
                    Il presente Accordo è governato dalle leggi italiane. Per ogni controversia derivante dal contratto o ad esso relativa sarà̀ competente 
                    in via esclusiva il Foro di Salerno (Italia).
                </Text>

                <TextNumber>
                    Art.12 Clausole finali
                </TextNumber>

                <Text>
                    Le obbligazioni e gli impegni derivanti dal presente accordo, che per loro natura spieghino efficacia anche dopo la scadenza, e 
                    la risoluzione dal contratto, rimarranno validi e operanti anche dopo tale data, fino alla loro soddisfazione.
                </Text>
            </Paragraph>
        </TotalPage>
    )
};

export default Terms;