import styled from 'styled-components';

export const FilterShopBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
    flex-direction: column;

    width: 100%;

    padding: 0;
`;

export const FilterReturnBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 100%;

    padding: 0;
`;


export const ReturnFilterBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
`;