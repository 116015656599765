import { FC, useEffect, useState } from 'react';

//types
import { Testimonial } from './LandingPageLDL.d';

// components
import SocietyInfoMobile from 'components/SocietyInfoMobile/SocietyInfoMobile';
import CtaBlock from './CtaBlock/CtaBlock.component';
import TestimonialCard from './TestimonialCard/TestimonialCard.component';
import TestimonialsCarousel from './TestimonialsCarousel';
import TestimonialsCarouselMobile from './TestimonialsCarousel/TestimonialsCarouselMobile'; 

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from 'react-router-dom';

//utils
import { getTestimonials } from './services/LandingPage.services';
import { testimonials } from './utils/testimonials';

//assets
import { ReactComponent as BulletIcon } from './assets/bulletIcon.svg';

//components
import LoadingMask from 'components/ui/LoadingMask';

// style
import { Page, TopCardsRow, ProfitsCard, BulletsCard, BulletsItem, BulletsBox, AllTestimonialsBox, AllTestimonialsTitle, AllTestimonials } from './style/LandingPage.style';
import useBrand from 'hooks/useBrand';

/**
 * Landing Page
 * 
 * @author   Frank Berni <frank.berni.79@gmail.com>
 * 
 * @returns JSX
 */

const LandingPage: FC<{}> = () => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { isAppMobile } = useAppSelector( state => state.ui);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const filterSortTestimonials = (testimonials: Testimonial[]) => {
        return [...testimonials
                .filter(t => t.visible && t.video)
                // .sort((a,b) => {
                //     if (a.weight < b.weight) return 1;
                //     if (a.weight > b.weight) return -1;
                //     return 0;
                // })
            ]
    }

    const getAllTestimonials = async () => {
        try {
            if (!executeRecaptcha || typeof window === 'undefined') return;
            setTimeout(async () => {
                const reCaptchaResponse = await executeRecaptcha('testimonials');
                const response: Testimonial[] = await getTestimonials(reCaptchaResponse);

                setTestimonials(filterSortTestimonials(response));
                setIsLoading(false);
            }, 100); // Add a delay of 500ms or adjust accordingly
        } catch (error) {
            console.log(error);
        }
    }

    // TO ACTIVATE AFTER ARUBA FIX

    useEffect(() => {
        if (executeRecaptcha) {
            getAllTestimonials();
        }
    }, [executeRecaptcha]);

    //  UNTIL TO ARUBA FIX REDIRECT TO HOME
    
    // const navigate = useNavigate();
    // useEffect(() => {
    //     navigate('/login');
    // }, []);

    return (
        <Page>
            <TopCardsRow>
                <ProfitsCard>
                    {!isAppMobile && <>Guadagni superiori a <span>3.000€ al mese</span><br/> in meno di 12 mesi</>}
                    {isAppMobile && <>Guadagni superiori a <br/><span>3.000€ al mese</span><br/> in meno di 12 mesi</>}
                </ProfitsCard>
                <BulletsCard>
                    <BulletsBox>
                        <BulletsItem>
                            <BulletIcon />
                            Senza dare SOLDI a Nessuno
                        </BulletsItem>
                        <BulletsItem>
                            <BulletIcon />
                            Senza Vendere Niente
                        </BulletsItem>
                        <BulletsItem>
                            <BulletIcon />
                            Senza Fare Network Marketing
                        </BulletsItem>
                    </BulletsBox>
                </BulletsCard>
            </TopCardsRow>
            {isLoading && (
                    <LoadingMask
                        isCenter
                        size='big'
                    />
            )}

            {isAppMobile && !isLoading && (
                <TestimonialsCarouselMobile testimonials={testimonials}/>
            )}

            {!isAppMobile && !isLoading && (
                <TestimonialsCarousel testimonials={testimonials}/>
            )}
            
            <CtaBlock />

            <AllTestimonials>
                <AllTestimonialsTitle>
                    Tutte le Testimonianze
                </AllTestimonialsTitle>
                
                {isLoading && (
                        <LoadingMask
                            isCenter
                            size='big'
                        />
                )}

                {!isLoading && <AllTestimonialsBox>
                    {testimonials.map((t: any, i: number)=> <TestimonialCard key={`${t.description}${i}SC`} testimonial={t}/>)}
                </AllTestimonialsBox>}
            </AllTestimonials>
            <CtaBlock />
            {isAppMobile && <SocietyInfoMobile />}
        </Page>
    );
}

export default LandingPage;
