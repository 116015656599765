import devices from 'style/breakpoints';
import colors from 'style/palette';
import styled from 'styled-components';


export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px;
    gap: 20px;

    @media ${devices.tablet}{       

    }
`;

export const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 60px;

    max-width: 663px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    @media ${devices.tablet}{       
        min-width: 747px;
    }
`;

export const TextGreen = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.green};

    padding: 0px;
    margin: 0px;

    @media ${devices.tablet}{       

    }
`;

export const NormalText = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.primaryColor};

    padding: 0px;
    margin: 0px;

    @media ${devices.tablet}{       

    }
`;