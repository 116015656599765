import { FC, useEffect, useState, useMemo, memo } from 'react';

//assets
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';

//style
import { MultipleTreeUL, MultipleTreeLI, MultipleTreeNode, MultipleTreeContainer, MultipleTreeNodeLabel } from './style/MultipleTree.style';

//types
import { MultipleTreeProps } from './MultipleTree.component.d';
import { MultipleNode } from '../types/MultipleNode';
import { Rating, WinnigsAndRating, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

const MultipleTree: FC<MultipleTreeProps> = ({ tree, result, backgroundColor, border }) => {

    const createNode = (node : MultipleNode) => {
        const leaf = !node.w && !node.l;
        const winnings = node.covers[Math.min(node.value.length, node.covers.length-1)].winnings ?? undefined;
        const parentCancelled = node.parent && node.parent.l && node.parent.l.prob<0.00001;//node.parent && node.parent.covers[Math.min(node.parent.value.length, node.parent.covers.length-1)].winnings===undefined;
        const cancelled = node.l && node.l.prob<0.00001;
        const res = (parentCancelled && node.calculated) ? 'C' : (node.value.length>0 ? node.value[node.value.length-1] : undefined);
        return(
            <MultipleTreeLI>
                <MultipleTreeNode leaf={leaf} borderColor={res ? (res==='L' ? '#ff424280' : (res==='C' ? '#ffa50073' : '#00c10061')) : undefined} isDisabled={!node.value.startsWith(result)}>
                    {leaf ? 
                        <>
                            {(node.winnings ?? [undefined]).map((x,i) => {
                                const rating = (node.ratings && x!==undefined) ? node.ratings[i] : undefined;
                                return(
                                    <WinnigsAndRating key={i} flexDirection='column' boxed={false} padding='0 5px'>
                                        <Winnings amount={(x!==undefined && node.calculated) ? x : 0} size='s'>€{(x!==undefined && node.calculated) ? x.toFixed(2) : '-'}</Winnings>
                                        {<Rating size='s'>{(rating!==undefined && node.calculated) ? rating.toFixed(2) : '-'}%</Rating>}
                                    </WinnigsAndRating>
                                );
                            })}
                        </>
                        :
                        <>
                            <MultipleTreeNodeLabel>Copertura {node.value.length+1}</MultipleTreeNodeLabel>
                            <MultipleTreeNodeLabel title='Rischio copertura' color='#ff4242'>€{(winnings!==undefined && node.calculated && !cancelled) ? (-winnings[0]).toFixed(2) : '-'}</MultipleTreeNodeLabel>
                            <MultipleTreeNodeLabel title='Profitto copertura' color='#00c100'>{(winnings!==undefined && node.calculated && !cancelled) ? winnings.slice(1).map(x => '€'+x.toFixed(2)).join(' ') : '€-'}</MultipleTreeNodeLabel>
                        </>
                    }
                </MultipleTreeNode>
                {!leaf &&
                    <MultipleTreeUL>
                        {node.w && node.w.prob>0.00001 && createNode(node.w)}
                        {node.l && node.l.prob>0.00001 && createNode(node.l)}
                    </MultipleTreeUL>
                }
            </MultipleTreeLI>
        );
    }
    // const error = tree.covers.length===0 || 
    return (
        <MultipleTreeContainer>
            <MultipleTreeUL root={true} backgroundColor={backgroundColor} border={border}>{createNode(tree)}</MultipleTreeUL>
        </MultipleTreeContainer>
    );
}

export default memo(MultipleTree);