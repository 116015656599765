import styled, { css } from 'styled-components';

// types
import { UserImageProfileProps } from './AccountPage.style.d';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Page = styled.div`

    max-width: 1440px;
    margin: 0 auto;
    
    padding: 35px 20px 20px;

    @media ${devices.tablet} {

        padding: 40px 40px 20px;
    }
`;

export const TitlePage = styled.h1`

    width: 100%;

    margin: 0 0 16px;
    padding: 0;

    font-size: 18px;
    font-weight: 400;
    line-height: 20px;

    color: ${colors.white070};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media ${devices.tablet} {

        margin: 0 0 21px;

        font-size: 28px;
        line-height: 30px;
    }
`;

export const TextSeparator = styled.span`

    display: inline-block;
    margin: 0 10px;

    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
`;

export const UserSubSectionText = styled.span`

    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    
    color: ${colors.white};

    @media ${devices.tablet} {

        font-size: 24px;
        line-height: 24px;
    }
`;

export const UserDashboardContent = styled.div`

    width: 100%;

    padding: 35px 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 40px;
    flex-direction: column;

    @media ${devices.tablet} {

        padding: 35px;

        align-items: stretch;

        flex-direction: row;
    }
`;

export const UserImageProfile = styled.img<UserImageProfileProps>`

    min-width: 80px;
    max-width: 80px;

    min-height: 80px;
    max-height: 80px;

    border-radius: 50%;
    border: 1px solid ${colors.white};

    ${({ flavor }) => (flavor === 'facebook') && (

        css`
            border: 3px solid ${colors.blue};
        `
    )}
`;
