import { Auth } from 'aws-amplify';

// types
import { SignUpPayload } from './SignUpForm.services.d';
import { SignUpParams } from '@aws-amplify/auth';
import store from 'redux/store';
import { getBrand } from 'configs/brandMap/brandMap.helper';


export const signUp = async (payload: SignUpPayload) => {

    try {

        const brand = getBrand();
        if (!brand) return;

        const userSignUpPayload: SignUpParams = {

            username: payload.email,
            password: payload.password,

            validationData: { captchaResponse: payload.validationToken },

            attributes: {

                email: payload.email,
                name: payload.name,
                family_name: payload.surname,
                'custom:signupBrand' : brand?.code
            },
            
            autoSignIn: { enabled: false }
        };

        const response = await Auth.signUp(userSignUpPayload);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;
        
    } catch (error: any) {
        throw error;
    }
};

/*
    temporary email used:
        godojej914@rxcay.com
        cekod80301@otodir.com
*/
