import styled from 'styled-components';
import colors from 'style/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';


export const CashAcademyBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 430px;

    border-radius: 15px;
    background-color: ${colors.darkGrey};

    @media ${devices.tablet} {
        height: 145px;
    }
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
    
    width: 100%;

    padding: 25px 30px;

    @media ${devices.tablet} {
        gap: 10px;
    }
`;

export const CashAcademyImg = styled.img``;

export const CardTitle = styled.div`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.white};
`;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
`;

export const CashAcademyButton = styled(Button)``;
