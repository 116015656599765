import styled, { css } from 'styled-components';

import devices from 'style/breakpoints';
import colors from 'style/palette';

interface SelectorOptionProps {
    isActive?: boolean;
}

export const SelectorWithTabsBox = styled.div<{}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    width: 100%;
    height: 49px;
    padding: 0 15px 0 15px;

    z-index: 1;

    button:not(:last-of-type) {
        border-right: 1px solid ${colors.darkGrey};
    }

    button:first-of-type {
        border-top-left-radius: 10px;
    }

    button:last-of-type {
        border-top-right-radius: 10px;
    }
`;

export const SelectorTab = styled.button<SelectorOptionProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${props => (props.isActive ? '49px' : '47px')};
    padding: 18px;
    padding: 10px;
    gap: 10px;
    border: none;
    outline: none;
    background-color: ${props => (props.isActive ? colors.darkGrey : colors.grey)};
    font-size: 15px;
    font-weight: 500;
    margin-right: 0;
    color: ${props => (props.isActive ? colors.primaryColor : colors.white050)};
    /* border-right: 1px solid rgba(0, 0, 0, 0.87); */
    cursor: pointer;
`;

export const SelectorWithButtonsBox = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 10px;
    @media ${devices.mobile} {
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        gap: 20px;
    }
`;

export const SelectorButton = styled.div<SelectorOptionProps>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 59px;

    padding: 17px 15px;
    gap: 10px;

    background: ${colors.darkGrey};
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    cursor: pointer;

    ${props => (props.isActive && css`background: ${colors.grey}; border: 0.5px solid ${colors.primaryColor};`)};

    @media ${devices.tablet} {

    }
`;

export const SelectorButtonText = styled.p`

    font-weight: 400;
    font-size: 15px;

    text-align: center;

    color: ${colors.white};

    text-transform: uppercase;
`;