import { FC } from 'react';

// assets
import { ReactComponent as DatePickerSVG } from './assets/calendar.svg';
import { ReactComponent as CheckCircleSVG } from './assets/check-circle.svg';
import { ReactComponent as CommentSVG } from './assets/comment.svg';
import { ReactComponent as VideocallSVG } from './assets/videocall.svg';

// type
import { FormLessonsContentProps } from './FormLessonsContent';
import LessonRow from './LessonRow/LessonRow.component';

// style
import { 
    Content, 
    LessonsText, 
    LessonsDisclaimer, 
    LessonBox,
    EmptyLessonBox,
    LessonsButton 
} from './style/FormLessonsContent.style';


/**
 * Lessons form content
 * 
 * @author Frank Berni
 * 
 * @param lessonsData - array of lessions data
 * @param isDatePickerButtonCompleted - state of the date picker button
 * @param isZoomLinkButtonCompleted - state of the zoom link button
 * @param isCommentButtonCompleted - state of the personal assistana comment button
 * @param isCustomerRatingButtonCompleted - state of the custome rating button
 * @param onCommentButtonClick - handler for open the personal assistant comment modal
 * @param onRatingButtonClick - handler for open the custome rating modal
 * 
 * @returns JSX
 */


const FormLessonsContent: FC<FormLessonsContentProps> = ({
        lessonsData,
        isDatePickerButtonCompleted,
        isZoomLinkButtonCompleted,
        isCommentButtonCompleted,
        isCustomerRatingButtonCompleted,
        isLoadingNewLesson,
        onCommentButtonClick,
        onRatingButtonClick,
        choiceLessonHandler
    }) => {

    // const commentClickButtonkHandler = () => {

    //     if (!onCommentButtonClick || !isCommentButtonCompleted) return;
    //     onCommentButtonClick();
    // };
    
    const isLessonListVisible = (lessonsData.productLessonPack.lessons.length !== 0);
    const isLessonButtonDisabled = () => {

        if (!lessonsData || lessonsData === undefined) return false;
        if (lessonsData.length === 0) return false;
        if (lessonsData.productLessonPack.lessons[lessonsData.productLessonPack.lessons.length - 1].closingDate !== null) return false;

        return true;
    };
    

    return (
        <Content>
            <LessonsText>
                <LessonsDisclaimer>Puoi prenotare MAX 1 Lezione per volta!</LessonsDisclaimer>
                <br />
                Prenota la tua lezione e completala con l'assistente che ti verrà assegnato.
                <br /><br/>
                <LessonsDisclaimer>Finita la call rileggi la nota sulla lezione lasciata dal tuo assistente e 
                clicca sull'ultima icona per recensire e chiudere la lezione. </LessonsDisclaimer><br />
                Fatto ciò potrai prenotare una nuova lezione.
            </LessonsText>

            {isLessonListVisible && 
            (lessonsData.productLessonPack.coach == null) &&
            (
                <EmptyLessonBox>
                    Stiamo provvedendo ad assegnarti l'Assistente Personale.
                </EmptyLessonBox>
            )}

            {isLessonListVisible && 
            (lessonsData.productLessonPack.coach !== null) &&
            (
                <LessonBox>
                    {lessonsData.productLessonPack.lessons.map((lesson: any, index: number) => (
                        <LessonRow 
                            key={index}
                            lesson={lesson}
                            index={index}
                            onCommentClick={onCommentButtonClick}
                            onRatingClick={onRatingButtonClick}
                            choiceLessonHandler={choiceLessonHandler}
                            coach={lessonsData.productLessonPack.coach}
                        />
                    ))}
                </LessonBox>
            )}

            <LessonsButton
                type='submit'
                disabled={isLessonButtonDisabled() || isLoadingNewLesson}
            >
                AGGIUNGI LEZIONE
            </LessonsButton>
        </Content>
    );
};

export default FormLessonsContent;
