import { FC, useEffect, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import useAppDispatch from 'hooks/useAppDispatch';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//utils
import { callGET } from 'network/network';
import { removeItemFromLocalStorage } from 'utils/localStorage';

//components
import CourseBox from './MyCourse/MyCourse.component';
import LoadingMask from 'components/ui/LoadingMask';

// style
import {
    Page,
    Content,
    MyCourseInfoBox,
    MyCourseBox,
    MyCourseContent,
} from './style/MyCoursePage.style';
import store from 'redux/store';


const MyCoursePage: FC<{}> = () => {

    const [courseList, setCourseList] = useState<any[]>([]);
    const [sortedCourseList, setSortedCourseList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const { name: userName, surname: userSurname} = useAppSelector(state => state.user);

    const sortCourseList = () => {

        const alphabeticSortedList = courseList.sort((courseA: any, courseB: any) => courseA.name.localeCompare(courseB.name));
        const coursesWithImage = alphabeticSortedList.filter((course: any) => course.sellImage !== undefined);
        const coursesWithOutImage = alphabeticSortedList.filter((course: any) => course.sellImage === undefined);
        const newCourseList = [...coursesWithImage, ...coursesWithOutImage];
        setSortedCourseList(newCourseList);
    };

    const isNewCourse = () => {
        const newProduct = 'New Course' in localStorage;
        
        if (!newProduct) return;
        const snackbarData: SnackbarOptions = {};
        snackbarData.type = 'success';
        snackbarData.message = "Il corso è stato accreditato con Successo!";
        dispatch( addSnackbar(snackbarData) );
        removeItemFromLocalStorage('New Course');
    };
    
    const getCourses = async () => {
        
        try {
            setIsLoading(true);
            
            const brandCode = store.getState().ui.brand?.code;
            const response = await callGET(`v1/self/products?brandCode=${brandCode}&types=course`, true);

            isNewCourse();
            setCourseList(response);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            console.warn(error);
        }
    };


    useEffect(() => {
        getCourses();
    },[])
    
    useEffect(() => {
        sortCourseList();
    },[courseList])
    
    if (isLoading) return (
        <LoadingMask 
            paddingTop='200px'
            isCenter
            size='page'
        />
    );
   
    return (
        <Page>
            <Content>
                <MyCourseInfoBox>
                    {userName} {userSurname} / I Miei Corsi
                </MyCourseInfoBox>

                <MyCourseBox>
                    <MyCourseContent>
                       {sortedCourseList.length === 0 && "Al momento non hai nessun corso."}
                        
                        {sortedCourseList !== undefined && sortedCourseList.length > 0 &&
                        sortedCourseList.map((course: any, index: any) => {
                            
                        return <CourseBox key={index} courseElement={course} />
                        })}

                    </MyCourseContent>
                 </MyCourseBox>
                
            </Content>
        </Page>
    )
};

export default MyCoursePage;