import config from "configs/config";

// config
import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';


let finalAuthConfiguration;
if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;

const baseCookieUrl = finalAuthConfiguration?.cookieStorage.domain;

export const setCookie = (cname: string, cvalue: string | null, exdays: number, path: string) => { 
    console.log("COOKIE BASE: ",baseCookieUrl);

    const data = new Date();
    data.setTime(exdays);
    let expires = "expires=" + data;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + baseCookieUrl + ";path=" + path + "; secure";
};

export const getCookie = (cname: string) => { 
    console.log("COOKIE BASE: ",baseCookieUrl);

    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const deleteAllNamedCookie = (cname: string) => {
  // let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    let suppArr = [];
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
        c = c.substring(1);      
        }

  suppArr.push(c.split('=')[0]);
  }

  const cookieToDelete = suppArr.filter((el: any) => el.includes(cname));
 
  for (let i=0; i<cookieToDelete.length; i++) {
    deleteCookie(cookieToDelete[i])
  }
  return "";
};

export const checkCookie = (cname: string) => {
    console.log("COOKIE BASE: ",baseCookieUrl);

    let val = getCookie(cname);
    if (val === "" || val === null) {
        return false;
    } else {
        return true;
    }
};

export const deleteCookie = (cname: string) => {

    let newCookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=' + baseCookieUrl + ';path=/;maxAge=-99999999;SameSite=Lax;secure';
    document.cookie = newCookie.replace('@', '%40');
};

