import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const Page = styled.div`
	min-height: inherit;
	height: 100%;

	padding: 0px 25px 0px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	flex-direction: column;
`;

export const PageTitleBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	> svg {
		cursor: pointer;
		width: 30px;
		height: 30px;
	}
`;

export const PageTitle = styled.h1`
	width: 100%;
	color: ${colors.primaryColor};
	font-weight: 300;
	font-variant: small-caps;
	font-size: 1.75rem;
`;

export const BlackCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	background-color: rgba(17, 17, 17, 1);
	border: 0.5px solid rgba(255, 255, 255, 0.2);
	border-radius: 1rem;
	padding: 1rem;
`;

export const CardTitle = styled.h2`
	color: rgba(255, 255, 255, 0.5);
	font-weight: 300;
	font-size: 0.8rem;
	font-variant: small-caps;
	margin: 0;
`;

export const DatePicker = styled.input`
	background: none;
	color: rgba(255, 255, 255, 1);
	font-size: 1rem;
	border: none;
	color-scheme: dark;
	width: fit-content;
`;

export const BirthdayResultBox = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-top: 0.75rem;
	align-self: center;
`;

export const BirthdayResultTitle = styled.span`
	color: ${colors.primaryColor};
	font-weight: 500;
	font-size: 0.85;
	font-variant: small-caps;
`;

export const BirthdayResultValue = styled.span`
	background-color: ${colors.primaryColor};
	color: rgba(0, 0, 0, 1);
	font-weight: 500;
	font-size: 1rem;
	font-variant: small-caps;
	padding: 0.25rem 0.5rem;
	border-radius: 0.75rem;
`;

export const TextInput = styled.input`
	background: none;
	color: rgba(255, 255, 255, 1);
	font-size: 1rem;
	font-variant: small-caps;
	border-width: 0 0 1px 0;
	border-color: white;
	border-style: solid;
	color-scheme: dark;
`;

export const TextResultBox = styled.div`
	display: flex;
	margin-top: 0.75rem;
	width: fit-content;
	align-self: center;
	gap: 1rem;
`;

export const TextResultValue = styled.div`
	background-color: ${colors.primaryColor};
	display: flex;
	align-items: center;
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	justify-content: center;
	justify-items: center;
`;

export const InstructionsPlayerCard = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 0.3rem;
	padding: 1rem;
`;

export const InstructionsPlayerIcon = styled.div`
	background-color: ${colors.primaryColor};
	width: 3.2rem;
	height: 3rem;
	border-radius: 0.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const InstructionsPlayerTrack = styled.div`
	display: flex;
	flex-direction: column;
	font-variant: small-caps;
	font-weight: 300;
`;

export const InstructionsPlayerTrackTitle = styled.div`
	color: white;
`;

export const InstructionsPlayerTrackAuthor = styled.div`
	color: ${colors.primaryColor};
	font-size: 0.75rem;
`;

export const ShareBox = styled.div`
	display: flex;
	width: 100%;
	padding: 0.75rem 0;
	flex-direction: column;
	align-items: center;
	gap: 0.75rem;
	border-top: 1px solid white;
`;

export const ShareText = styled.h2`
	font-size: 1rem;
	font-variant: small-caps;
	font-weight: 300;
	color: white;
`;

export const ShareButton = styled.button`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	padding: 0.5rem 1rem;
	background-color: ${colors.primaryColor};
	font-weight: 600;
	text-transform: uppercase;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;

export const CalculateButton = styled.button`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	padding: 0.5rem 1rem;
	background-color: ${colors.primaryColor};
	font-weight: 600;
	text-transform: uppercase;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;
