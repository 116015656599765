import { FC, useEffect, useState, useMemo, useRef } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import NumberInput from 'components/ui/NumberInput';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import OddsMatchSelector from '../OddsMatchSelector';

//types
import { SingleSelectorProps } from './SingleSelector.component.d';
import { OddsScasserFilters } from 'pages/OddsScasserPage/OddsScasser/OddsScasserFilter/types/OddsScasserFilters';
import { SingleObj, SingleData } from "pages/OddsScasserPage/OddsScasser/types/SingleObj";
import { Event } from 'pages/OddsScasserPage/types/Event';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';

//style
import { Row, Col } from 'style/layout';
import { Bet, BetContainer, CoverInstructions, CoverInstructionsContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, DropdownContent, Flex, HorizontalScrollContainer, Rating, Resp, Result, SVGIconContainer, Select, SingleElement, SingleElementCell, SingleElementEvent, SingleElementEventRow, SingleElementOdds, SingleElementOddsCovers, SingleElementOddsCoversContainer, SingleElementOddsMatchRow, SingleElementOddsSelection, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, StandardCheckbox, WinnigsAndRating, WinnigsAndRatingContainer, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { ChooseButton, SingleSelectorContainer } from './style/SingleSelector.style';

//utils
import { formatDate, stepOddsPP, stepOdds, calculatePuntaPunta, calculatePuntaBanca, stepIntegers, resultComment, resultId, getResult } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const SingleSelector: FC<SingleSelectorProps> = ({ single, filters, feesBetfair, feesBetflag, saveSingle, isHidden, readOnly, saveResult, copyToClipboard }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events } = useAppSelector(state => state.oddsScasser);
    
    const [chosenSingle, setChosenSingle] = useState<SingleObj>(single.id!==null ? single : {...single, data: {...single.data, oddsMatch: {event: {id: 0}, odds: [], rating: 0}}});

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(single.id !== null && JSON.stringify(chosenSingle)!==JSON.stringify(single)) setChosenSingle(single);
    }, [single]);

    //Save when the single changes
    const timer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        saveResult(((chosenSingle.data.oddsMatch.event.id!==0 && chosenSingle.data.result!==null) ? chosenSingle.data.oddsMatch.odds[resultId.get(chosenSingle.data.result ?? '') ?? 0].winnings : null) ?? null);
        if(chosenSingle.id !== null && JSON.stringify(chosenSingle)!==JSON.stringify(single)) {
            if(timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => saveSingle(chosenSingle), 2000);
            return () => clearTimeout(timer.current);
        }
    }, [chosenSingle]);

    const eventText: string = useMemo(() => {
        let eventText = '';
        if(chosenSingle.data.oddsMatch.event.id===0) return '';
        eventText = `${chosenSingle.data.oddsMatch.event.home?.name} - ${chosenSingle.data.oddsMatch.event.away?.name}`;
        const league = chosenSingle.data.oddsMatch.event.league;
        if(league) {
            const country = league.country;
            const leagueName = league.name;
            if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
        }
        return eventText;
    }, [chosenSingle]);

    const calculate = (_single: SingleData) => {
        if(_single.oddsMatch.event.id===0) return _single;
        const bet = parseFloat(_single.bet.value);
        const bonus = _single.isFreebet ? 0 : parseFloat(_single.bonus.value) || 0;
        const refund = parseFloat(_single.isFreebet ? _single.bet.value : _single.refund.value) || 0;
        const fees = parseFloat(_single.oddsMatch.odds[1].id === 9 ? feesBetflag : feesBetfair)/100;
        const odds = _single.oddsMatch.odds.map(x => x.input ? parseFloat(x.input.value) : x.odds);
        const errors = isNaN(bet) || bet<=0.01 || isNaN(bonus) || isNaN(refund) || odds.some(x => isNaN(x));
        const res = sites.get(_single.oddsMatch.odds[1].site?.id)?.type === 'exchange'
        ? calculatePuntaBanca(bet, bonus, refund, fees, odds, 0.01)
        : calculatePuntaPunta(bet, bonus, refund, odds, 1);
        for (let i = 0; i < res.bets.length; i++) {
            if(i>_single.oddsMatch.odds.length-1) break; 
            _single.oddsMatch.odds[i].coverBet = !errors ? res.bets[i] : undefined;
            _single.oddsMatch.odds[i].coverResp = !errors ? res.resp[i] : undefined;
            _single.oddsMatch.odds[i].winnings = !errors ? res.winnings[i] : undefined;
            _single.oddsMatch.odds[i].rating = !errors ? res.ratings[i] : undefined;
        }
        _single.oddsMatch.avgRating = !errors ? res.avgRating : undefined;
        _single.oddsMatch.avgWinnings = !errors ? res.avgWinnings : undefined;
        return _single;
    };
    const _sites: (Site|undefined)[] = useMemo(() => chosenSingle.data.oddsMatch.event.id!==0 ? chosenSingle.data.oddsMatch.odds.map(x => sites.get(x.site.id)) : [], [chosenSingle]);
    const isModePb: boolean = useMemo(() => chosenSingle.data.oddsMatch.event.id!==0 ? sites.get(chosenSingle.data.oddsMatch.odds[1].site?.id)?.type === 'exchange' : true, [chosenSingle]);

    const handleOddsMatchChoice = (om: OddsMatch) => {
        setChosenSingle(prevState => ({...prevState, id: null, data: calculate({...prevState.data, oddsMatch: {...om, odds: om.odds.map(x => ({...x, input: {value: x.odds.toFixed(2)}}))}})}));
    }

    //Set results of finished events
    useEffect(() => {
        if(readOnly) return;
        if(events.size === 0) return;
        if(chosenSingle.id === null) return;
        setChosenSingle(prevState => {
            let result: string|null = null;
            if(prevState.data.result === null) {
                const event: Event|undefined = events.get(prevState.data.oddsMatch.event?.id ?? 0);
                if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
                    result = getResult(isModePb ? 'pb' : (prevState.data.oddsMatch.odds.length===2 ? 'pp' : 'tri'), prevState.data.oddsMatch.odds.map(x => x.type ?? ''), event.home.score, event.away.score);
                }
                return result===null ? prevState : {...prevState, data: {...prevState.data, result}};
            }
            return prevState;
        });
    }, [events, chosenSingle]);
    
    const handleResultClick = () => {
        if(chosenSingle.data.oddsMatch.event.id===0) return;
        const n = chosenSingle.data.oddsMatch.odds.length;
        if(chosenSingle.data.oddsMatch.odds.some((y,i) => i<n && y.input?.errorMessage)) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima di inserire il risultato inserisci le quote' }));
            return;
        }
        const results = n === 2 ? [null, 'W', 'L'] : [null, 'W', 'L1', 'L2'];
        let result: string|null = null;
        for (let i = 0; i < results.length; i++) {
            if(results[i] === chosenSingle.data.result) result = results[(i+1)%results.length];
        }
        setChosenSingle({...chosenSingle, data: {...chosenSingle.data, result}});
    }

    const handleBetChange = (newState: NumberInputState) => setChosenSingle(prevState => ({...prevState, data: calculate({...prevState.data, bet: newState})}));
    const handleBonusChange = (newState: NumberInputState) => setChosenSingle(prevState => ({...prevState, data: calculate({...prevState.data, bonus: newState})}));
    const handleRefundChange = (newState: NumberInputState) => setChosenSingle(prevState => ({...prevState, data: calculate({...prevState.data, refund: newState})}));
    const handleOddsChange = (index: number, newState: NumberInputState) => setChosenSingle(prevState => ({...prevState, data: calculate({...prevState.data, oddsMatch: {...prevState.data.oddsMatch, odds: prevState.data.oddsMatch.odds.map((x,i) => i===index ? {...x, input: newState} : x)}})}));

    if(isHidden) return <></>;
    const _filters: OddsScasserFilters = {...filters, bet: chosenSingle.data.bet.value, bonus: chosenSingle.data.bonus.value, refund: chosenSingle.data.refund.value, isFreebet: chosenSingle.data.isFreebet, feesBetfair, feesBetflag}
    const winnings: number|undefined = chosenSingle.data.oddsMatch.event.id!==0 ? (!chosenSingle.data.result ? chosenSingle.data.oddsMatch.avgWinnings : chosenSingle.data.oddsMatch.odds[resultId.get(chosenSingle.data.result ?? '') ?? 0].winnings) : undefined;
    const rating: number|undefined = chosenSingle.data.oddsMatch.event.id!==0 ? (!chosenSingle.data.result ? chosenSingle.data.oddsMatch.avgRating : chosenSingle.data.oddsMatch.odds[resultId.get(chosenSingle.data.result ?? '') ?? 0].rating) : undefined;
    return(
        <SingleSelectorContainer readOnly={readOnly}>
            <Row gap='5px' mainAxis='center' crossAxis='flex-start' flexWrap='wrap'>
                <Col gap='5px' mainAxis='center'>
                    <NumberInput label='Puntata' isDisabled={chosenSingle.data.result!==null} isDisabledOpacity={1} value={chosenSingle.data.bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBetChange(newState)}/>
                    <NumberInput isDisabled={chosenSingle.data.isFreebet || chosenSingle.data.result!==null} isDisabledOpacity={chosenSingle.data.isFreebet ? undefined : 1} label='di cui Bonus' value={chosenSingle.data.isFreebet ? chosenSingle.data.bet.value : chosenSingle.data.bonus.value} minValue={0} maxValue={parseFloat(chosenSingle.data.bet.value) || 0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBonusChange(newState)}/>
                </Col>
                {(parseFloat(chosenSingle.data.refund.value)>0 || chosenSingle.data.isFreebet) &&
                    <Col gap='5px' mainAxis='center'>
                        <NumberInput isDisabled={chosenSingle.data.isFreebet || chosenSingle.data.result!==null} isDisabledOpacity={chosenSingle.data.isFreebet ? (isAppMobile ? 0.2 : 0) : 0.75} label='Rimborso' value={chosenSingle.data.refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleRefundChange(newState)}/>
                        <SwitchSelector isFilterActive={chosenSingle.data.isFreebet} width={150} height={36} label="Freebet"/>
                    </Col>
                }
            </Row>
            {chosenSingle.data.oddsMatch.event.id!==0 && 
                <Flex direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'stretch'}>
                    <SingleElement>
                        <SingleElementEventRow>
                            {chosenSingle.id!==null && <Result title={resultComment(chosenSingle.data.result)} result={chosenSingle.data.result ?? undefined} onClick={() => handleResultClick()}/>}
                            <HorizontalScrollContainer hiddenScrollBar={true}><SingleElementEvent>{formatDate(chosenSingle.data.oddsMatch.event.datetime ?? '')} &nbsp; {eventText}</SingleElementEvent></HorizontalScrollContainer>
                        </SingleElementEventRow>
                        <SingleElementOddsMatchRow>
                            <SingleElementOdds>
                                <SingleElementCell>
                                    <SiteBox 
                                        checked={!!_sites[0]} 
                                        notClickable={!(_sites[0] && chosenSingle.data.oddsMatch.odds[0].url && copyToClipboard)}
                                        name={_sites[0]?.name ?? ''} 
                                        width={60}
                                        backgroundColor={!_sites[0] ? '#000000a3' : _sites[0].color}
                                        onClick={() => _sites[0] && chosenSingle.data.oddsMatch.odds[0].url && copyToClipboard && copyToClipboard(chosenSingle.data.oddsMatch.odds[0].url, `URL ${_sites[0].name} copiato`)}
                                    >
                                        {_sites[0] && <SiteLogoContainer visible={true} backgroundColor={_sites[0].color}><SiteLogo width={'95%'} imageUrl={_sites[0].imageUrl}/></SiteLogoContainer>}
                                    </SiteBox>
                                </SingleElementCell>
                                <NumberInput
                                    value={chosenSingle.data.oddsMatch.odds[0].input?.value ?? chosenSingle.data.oddsMatch.odds[0].odds.toFixed(2)} 
                                    isDisabled={chosenSingle.data.result!==null} isDisabledOpacity={1}
                                    inputPlaceholder='Quota'
                                    minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                    onChange={(newState: NumberInputState) => handleOddsChange(0, newState)}
                                />
                                <SingleElementCell>
                                    <SingleElementOddsSelection title='Esito'>{chosenSingle.data.oddsMatch.odds[0].type}</SingleElementOddsSelection>
                                </SingleElementCell>
                            </SingleElementOdds>
                            <SingleElementOddsCoversContainer>
                                <SingleElementOddsCovers>
                                    <SingleElementOdds>
                                        <SingleElementCell>
                                            <SiteBox 
                                                checked={!!_sites[1]} 
                                                notClickable={!(_sites[1] && chosenSingle.data.oddsMatch.odds[1].url && copyToClipboard)}
                                                name={_sites[1]?.name ?? ''} 
                                                width={60}
                                                backgroundColor={!_sites[1] ? '#000000a3' : _sites[1].color}
                                                onClick={() => _sites[1] && chosenSingle.data.oddsMatch.odds[1].url && copyToClipboard && copyToClipboard(chosenSingle.data.oddsMatch.odds[1].url, `URL ${_sites[1].name} copiato`)}
                                            >
                                                {(_sites[1] && _sites[1].id>0) && <SiteLogoContainer visible={true} backgroundColor={_sites[1].color}><SiteLogo width={'95%'} imageUrl={_sites[1].imageUrl}/></SiteLogoContainer>}
                                            </SiteBox>
                                        </SingleElementCell> 
                                        <NumberInput
                                            value={chosenSingle.data.oddsMatch.odds[1].input?.value ?? chosenSingle.data.oddsMatch.odds[1].odds.toFixed(2)} 
                                            isDisabled={chosenSingle.data.result!==null} isDisabledOpacity={1}
                                            inputPlaceholder='Quota'
                                            minValue={1} includeLimits={false} decimals={2} stepFunction={isModePb ? stepOdds : stepOddsPP} inputWidth={60}
                                            onChange={(newState: NumberInputState) => handleOddsChange(1, newState)} 
                                        />
                                        <SingleElementCell>
                                                <SingleElementOddsSelection title='Esito'>{chosenSingle.data.oddsMatch.odds[1].type}</SingleElementOddsSelection>
                                        </SingleElementCell>   
                                    </SingleElementOdds>
                                    {chosenSingle.data.oddsMatch.odds.length>2 &&
                                        <SingleElementOdds>
                                            <SingleElementCell>
                                                <SiteBox 
                                                    checked={!!_sites[2]} 
                                                    notClickable={!(_sites[2] && chosenSingle.data.oddsMatch.odds[2].url && copyToClipboard)}
                                                    name={_sites[2]?.name ?? ''} 
                                                    width={60}
                                                    backgroundColor={!_sites[2] ? '#000000a3' : _sites[2].color}
                                                    onClick={() => _sites[2] && chosenSingle.data.oddsMatch.odds[2].url && copyToClipboard && copyToClipboard(chosenSingle.data.oddsMatch.odds[2].url, `URL ${_sites[2].name} copiato`)}
                                                >
                                                    {(_sites[2] && _sites[2].id>0) && <SiteLogoContainer visible={true} backgroundColor={_sites[2].color}><SiteLogo width={'95%'} imageUrl={_sites[2].imageUrl}/></SiteLogoContainer>}
                                                </SiteBox>
                                            </SingleElementCell>
                                            <NumberInput
                                                value={chosenSingle.data.oddsMatch.odds[2].input?.value ?? chosenSingle.data.oddsMatch.odds[2].odds.toFixed(2)} 
                                                isDisabled={chosenSingle.data.result!==null} isDisabledOpacity={1}
                                                inputPlaceholder='Quota'
                                                minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                onChange={(newState: NumberInputState) => handleOddsChange(2, newState)} 
                                            />
                                            <SingleElementCell>
                                                <SingleElementOddsSelection title='Esito'>{chosenSingle.data.oddsMatch.odds[2].type}</SingleElementOddsSelection>
                                            </SingleElementCell>
                                        </SingleElementOdds>
                                    }
                                </SingleElementOddsCovers>
                            </SingleElementOddsCoversContainer>
                        </SingleElementOddsMatchRow>
                        <CoverInstructionsContainer>
                            {
                                chosenSingle.data.oddsMatch.odds.slice(1).map((x,i) => {
                                    const site: Site|undefined = _sites.length>i ? _sites[i+1] : undefined;
                                    return(
                                        <CoverInstructions key={'c'+i}>
                                            <SiteBox 
                                                checked={!!site} 
                                                notClickable={!(site && chosenSingle.data.oddsMatch.odds[i].url && copyToClipboard)}
                                                name={site?.name ?? ''} 
                                                width={60}
                                                height={36}
                                                backgroundColor={!site ? '#000000a3' : undefined}
                                                onClick={() => site && chosenSingle.data.oddsMatch.odds[i].url && copyToClipboard && copyToClipboard(chosenSingle.data.oddsMatch.odds[i].url ?? null, `URL ${site.name} copiato`)}
                                            >
                                                {site && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                            </SiteBox>
                                            <CoverInstructionsLabelContainer backgroundColor='#323b42'>
                                                <HorizontalScrollContainer hiddenScrollBar={true}>
                                                    <CoverInstructionsLabel>
                                                        <CoverInstructionsText>{isModePb ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                        <BetContainer>
                                                            <Bet translateY={isModePb ? 5 : 0}>{'€'+(x.coverBet ? x.coverBet.toFixed(isModePb ? 2 : 0) : '-')}</Bet>
                                                            {isModePb && <Resp title='Responsabilità' translateY={1}>{'€'+(x.coverResp ? x.coverResp.toFixed(2) : '-')}</Resp>}
                                                        </BetContainer>
                                                        <CoverInstructionsText>esito</CoverInstructionsText>
                                                        <CoverInstructionsStrongText>{chosenSingle.data.oddsMatch.odds[i].type}</CoverInstructionsStrongText> 
                                                        <CoverInstructionsText>a quota</CoverInstructionsText>
                                                        <CoverInstructionsStrongText>{chosenSingle.data.oddsMatch.odds[i].odds.toFixed(2)}</CoverInstructionsStrongText>
                                                    </CoverInstructionsLabel>
                                                </HorizontalScrollContainer>
                                            </CoverInstructionsLabelContainer>
                                        </CoverInstructions>
                                    );
                                })
                            }
                        </CoverInstructionsContainer> 
                    </SingleElement> 
                    <Col mainAxis='space-evenly' gap='2px'>
                        <WinnigsAndRatingContainer>
                            <WinnigsAndRating flexDirection={isAppMobile ? 'row' : 'column'} boxed={false} padding='0 5px'>
                                <Winnings translateY={isAppMobile ? 0 : 5} amount={winnings!==undefined ? winnings : 0}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Winnings>
                                <Rating translateY={isAppMobile ? 0 : 2}>{rating!==undefined ? rating.toFixed(2) : '-'}%</Rating>
                            </WinnigsAndRating>
                        </WinnigsAndRatingContainer>
                        {chosenSingle.id===null && <ChooseButton onClick={() => saveSingle(chosenSingle)}>Conferma</ChooseButton>}
                    </Col>
                </Flex>
            }
            {(_filters && single.id===null) && <OddsMatchSelector buttonText='Cerca Singola' filters={_filters} onChoose={(om: OddsMatch) => handleOddsMatchChoice(om)} copyToClipboard={copyToClipboard}/>}
        </SingleSelectorContainer>
    );
}

export default SingleSelector;