import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';


// redux
import { disableAppMain, enableAppMain } from 'redux/slices/slice-app-main';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';
import { useCopyToClipboard } from "usehooks-ts";

// utils
import { getEnvironmentBackofficeUrl, getEnvironmentMonolithLinkUrl, getEnvironmentOLDAdminBackofficeUrl } from 'configs/config.helper';
import { formattedCurrency } from 'utils/supportFunctions';
import { getUserBalance, getUsersInviteFriends, getUsersRoles } from './services/UserProfileSubmenu.services';
import { checkIfUserIsAdmin, checkIfUserIsAuthor } from './UserProfileSubmenu.helper';
import { checkBrandMap } from 'configs/brandMap/brandMap.helper';

// components
import IconTextBox from './IconTextBox';
import LogoutButton from './LogoutButton';
import BrandedLink from 'components/BrandedLink/BrandedLink.component';

// types
import { UserProfileSubmenuProps } from './UserProfileSubmenu.d';

// assets
import { ReactComponent as UserBalanceIconSVG } from './assets/user-balance-icon.svg';
import { ReactComponent as AccountIconSVG } from './assets/account-icon.svg';
import { ReactComponent as ProductsIconSVG } from './assets/products-icon.svg';
import { ReactComponent as LessonsIconSVG } from './assets/lessons-icon.svg';
import { ReactComponent as SubscriptionSVG } from './assets/subscription-icon.svg';
import { ReactComponent as AutomaticSVG } from './assets/automatic-icon.svg';
import { ReactComponent as AdminSVG } from './assets/admin-icon.svg';
import { ReactComponent as AuthorSVG } from './assets/author-icon.svg';
import { ReactComponent as OddsscasserSVG } from './assets/oddsscasser-icon.svg';
import { ReactComponent as SupportSVG } from './assets/support-icon.svg';
import { ReactComponent as GuadagniSVG } from './assets/guadagni-icon.svg';
import { ReactComponent as StarSVG } from './assets/star-icon.svg';
import { ReactComponent as CopySVG } from './assets/copy.svg';
import { ReactComponent as InfoFriendsSVG } from './assets/infoFriedsIcon.svg';
import { ReactComponent as DirectSVG } from './assets/ThematicDirectB.svg';

// style
import { Row, Col } from 'style/layout';
import { 
    Container,
    SubmenuCard,
    Title,
    MenuBackdrop,
    IdUser,
    ClientUser, 
    PlusLiberty, 
    PlusLibertyButton, 
    PlusLibertyText,
    Separator,
    InvitationLink,
    NumberFriendsInviteBox,
} from './style/UserProfileSubmenu.style';
import store from 'redux/store';


/**
 * User Profile Submenu
 * 
 * @author Alessio Grassi
 * 
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onLinkClick - handler for Submenu's closure
 * 
 * @returns JSX
 */

type Friend = {
    id: number;
    name: string;
    surname: string;
    registrationDate: string;
    active: boolean;
};

const UserProfileSubmenu: FC<UserProfileSubmenuProps> = ({ isSubmenuOpen, onLinkClick }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);    
    const { name: userName, surname: userSurname, libertyPointsAvailableBalance: libertyPoints, roles: userRolesData, clientId } = useAppSelector(state => state.user);
    const { lessons, highway } = useAppSelector(state => state.user.products);
    // const [userRolesData, setUserRolesData] = useState<any>(undefined);
    const backdropPortalElement = document.getElementById('backdrop-root');
    // const isMounted = useIsMounted();
    // const dispatch = useAppDispatch();
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const [friendsData, setFriendsData] = useState<Friend[]>([]);
    const dispatch = useAppDispatch();


    // const userRoles = async () => {
         
    //     try {
    //         const roles = await getUsersRoles();
    //         if (!isMounted) return;

    //         setUserRolesData(roles);
    //      } catch (error: any) {
    //         console.warn("Unable getting User roles.", error);
    //      }
    // };
    
    // useEffect(() => {
    //     userRoles();
    // }, []);
    
    // useEffect(() => {

    //     // if ( isAppMobile ) {
                
    //         if (!isSubmenuOpen) {
    //             dispatch( enableAppMain() )
    //             return;
    //         }

    //         dispatch( disableAppMain() );
    //     // };
    // }, [dispatch, isAppMobile, isSubmenuOpen])

    useEffect(() => {
        const loadFriendsData = async () => {
            try {
                const response = await getUsersInviteFriends();
                setFriendsData(response);
                // setLoading(false);
                console.log("UTENTI INVITATI:", friendsData);
                
            } catch (error) {
                console.error("Error loading friends data:", error);
                // setLoading(false);
            }
        };

        loadFriendsData();
    }, []);

    const totalFriends = friendsData.length;
    const activeFriends = friendsData.filter(friend => friend.active).length;

    const userNameAndSurname = `${userName} ${userSurname}`;

    const isUserAdmin = checkIfUserIsAdmin(userRolesData);
    const isUserAuthor = checkIfUserIsAuthor(userRolesData);
    const isLessonLinkVisible = lessons !== undefined;
    const isHighwayLinkVisible = (highway.active !== undefined) && (highway.active === true);

    const copyIntoClipboardHandler = () => {
        const currentUrl = window.location.href;
        copyToClipboard(currentUrl.split('/app')[0]+`/app/?ref=${clientId}`); 
        const snackbarData: SnackbarOptions = {
            type: 'success',
            message: "Link copiato. Condivilo con chi vuoi!"
        };
        dispatch(addSnackbar(snackbarData));
    };
    
    // console.log("SA", isUserAdmin);
    // console.log("Auth", isUserAuthor);
    console.log("UTENTI INVITATI:", friendsData);
    
    
    if (backdropPortalElement == null) {
        return <></>;
    };

    if (!isAppMobile) {

        return (
            <>
                <Container isOpen={isSubmenuOpen}>
                    <SubmenuCard>
                        <Row
                            mainAxis='center'
                            margin='0 0 35px'
                        >
                            <BrandedLink
                                to='/reservedArea/liberty-point'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    
                                    label={formattedCurrency(libertyPoints, 0)}
                                    flavor='blue'
                                    SVGAsset={
                                        <UserBalanceIconSVG />
                                    }
                                    isPointBox
                                />
                            </BrandedLink>
                        </Row>

                        {!isAppMobile && (<Separator/>)}

                        <IdUser>ID utente: <span>{clientId}</span></IdUser>
                        
                        <InvitationLink onClick={copyIntoClipboardHandler}> <CopySVG/> Invita un Amico </InvitationLink>

                        {store.getState().ui.brand?.code === "LDL" && 
                            <NumberFriendsInviteBox>

                                {/* <InfoFriendsSVG /> */}

                                AMICI INVITATI: <span>{totalFriends}</span> | ATTIVI: <span>{activeFriends}</span>

                            </NumberFriendsInviteBox>
                        }

                        {store.getState().ui.brand?.code !== "LDL" && 
                            <NumberFriendsInviteBox>

                                {/* <InfoFriendsSVG /> */}

                                AMICI INVITATI: <span>{totalFriends}</span>

                            </NumberFriendsInviteBox>
                        }
            
                        <Col
                            mainAxis='center'
                            margin='0 0 35px'
                            gap='20.86px'
                        >
                            <BrandedLink
                                to='/reservedArea/account-page'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="Account"
                                    SVGAsset={
                                        <AccountIconSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
            
                            { checkBrandMap('mycourses') &&
                            <BrandedLink
                                to='/reservedArea/mycourses'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="I miei corsi"
                                    SVGAsset={
                                        <ProductsIconSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
                            }
            
                            { checkBrandMap('myservices') &&
                            <BrandedLink
                                to='/reservedArea/myservices'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="I miei abbonamenti"
                                    SVGAsset={
                                        <SubscriptionSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
                            }

                            { checkBrandMap('mie-dirette') &&
                            <BrandedLink
                                to='/reservedArea/mie-dirette'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="Le mie dirette"
                                    SVGAsset={
                                        <DirectSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
                            }
            
                            {isLessonLinkVisible && checkBrandMap('lesson-page') &&
                            <BrandedLink
                                to='/reservedArea/lesson-page'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="Le mie lezioni"
                                    SVGAsset={
                                        <LessonsIconSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>}
            
                            {isHighwayLinkVisible && checkBrandMap('highway-page') &&
                            <BrandedLink
                                to='/reservedArea/highway-page'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="L'Autostrada"
                                    SVGAsset={
                                        <LessonsIconSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>}
            
                            { checkBrandMap('libertydashboard') &&
                            <BrandedLink
                                to='/reservedArea/libertydashboard'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="I miei guadagni"
                                    SVGAsset={
                                        <GuadagniSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
                            }

                            { checkBrandMap('automatic-incomes') &&
                            <BrandedLink
                                to='/reservedArea/automatic-incomes'
                                onClick={onLinkClick}
                            >
                                <IconTextBox
                                    label="Entrate automatiche"
                                    SVGAsset={
                                        <AutomaticSVG
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                            </BrandedLink>
                            }

                            {(isUserAdmin || isUserAuthor) && (
                                <a href={getEnvironmentBackofficeUrl()}>
                                    <IconTextBox
                                        label="BACKOFFICE"
                                        SVGAsset={
                                            <AdminSVG />
                                        }
                                    />
                                </a>
                            )}

                            {isUserAdmin && (
                                <a href={getEnvironmentOLDAdminBackofficeUrl()}>
                                    <IconTextBox
                                        label="OLD ADMIN"
                                        SVGAsset={
                                            <AuthorSVG />
                                        }
                                    />
                                </a>
                            )}
                        </Col>
            
                        <Row mainAxis='center'>
                            <LogoutButton onClick={onLinkClick} />
                        </Row>
                    </SubmenuCard>
                </Container>

                {isSubmenuOpen && (
                    ReactDOM.createPortal(
                        <MenuBackdrop 
                            onClick={onLinkClick}
                        />,
                        backdropPortalElement
                    )
                )}
            </>
        );
    }

    return (
        <>
            <Container isOpen={isSubmenuOpen}>
                <Row>
                    <Title>
                        {userNameAndSurname}
                    </Title>
                </Row>


                <Row margin='0 0 20px'>
                    <SubmenuCard>
                        <Row
                            mainAxis='center'
                            >
                            <BrandedLink
                                to='/reservedArea/liberty-point'
                                onClick={onLinkClick}
                                >
                            <IconTextBox
                                label={formattedCurrency(libertyPoints, 0)}
                                flavor="blue"
                                SVGAsset={
                                    <UserBalanceIconSVG />
                                }
                                isPointBox
                            />
                            </BrandedLink>
                        </Row>
                    </SubmenuCard>
                </Row>
               


                <SubmenuCard>
                    <IdUser>ID utente: <span>{clientId}</span></IdUser>

                    <InvitationLink onClick={copyIntoClipboardHandler}> <CopySVG/> Invita un Amico </InvitationLink>

                    {store.getState().ui.brand?.code === "LDL" && 
                        <NumberFriendsInviteBox>

                            {/* <InfoFriendsSVG /> */}

                            AMICI INVITATI: <span>{totalFriends}</span> | ATTIVI: <span>{activeFriends}</span>

                        </NumberFriendsInviteBox>
                    }

                    {store.getState().ui.brand?.code !== "LDL" && 
                        <NumberFriendsInviteBox>

                            {/* <InfoFriendsSVG /> */}

                            AMICI INVITATI: <span>{totalFriends}</span>

                        </NumberFriendsInviteBox>
                    }

                    <Col
                        mainAxis='center'
                        margin='7.92px 0 9.37px'
                        gap='26.725px'
                    >
                        <BrandedLink
                            to='/reservedArea/account-page'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Account"
                                SVGAsset={
                                    <AccountIconSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
        
                        { checkBrandMap('mycourses') &&
                        <BrandedLink
                            to='/reservedArea/mycourses'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="I miei corsi"
                                SVGAsset={
                                    <ProductsIconSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }
        
                        { checkBrandMap('myservices') &&
                        <BrandedLink
                            to='/reservedArea/myservices'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="I miei abbonamenti"
                                SVGAsset={
                                    <SubscriptionSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }

                        { checkBrandMap('mie-dirette') &&
                        <BrandedLink
                            to='/reservedArea/mie-dirette'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Le mie dirette"
                                SVGAsset={
                                    <DirectSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }
        
                        { checkBrandMap('lesson-page') && isLessonLinkVisible &&
                        <BrandedLink
                            to='/reservedArea/lesson-page'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Le mie lezioni"
                                SVGAsset={
                                    <LessonsIconSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>}
        
                        { checkBrandMap('highway-page') && isHighwayLinkVisible &&
                        <BrandedLink
                            to='/reservedArea/highway-page'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="L'Autostrada"
                                SVGAsset={
                                    <LessonsIconSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>}

                        { checkBrandMap('automatic-incomes') &&
                        <BrandedLink
                            to='/reservedArea/automatic-incomes'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Entrate automatiche"
                                SVGAsset={
                                    <AutomaticSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }

                        { checkBrandMap('testimonials') &&
                        <BrandedLink
                            to='/testimonials'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Testimonianze"
                                SVGAsset={
                                    <StarSVG
                                        width={20}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }

                        {(isUserAdmin || isUserAuthor) &&  (
                            <a href={getEnvironmentBackofficeUrl()}>
                                <IconTextBox
                                    label="BACKOFFICE"
                                    SVGAsset={
                                        <AdminSVG />
                                    }
                                />
                            </a>
                        )}

                        {isUserAdmin && (
                                <a href={getEnvironmentOLDAdminBackofficeUrl()}>
                                    <IconTextBox
                                    label="OLD ADMIN"
                                    SVGAsset={
                                        <AuthorSVG />
                                    }
                                />
                            </a>
                        )}

                        { checkBrandMap('aiuto-privato') &&
                        <BrandedLink
                            to='/reservedArea/aiuto-privato'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Aiuto Privato"
                                SVGAsset={
                                    <LessonsIconSVG
                                        width={17.52}
                                        height={20}
                                    />
                                }
                            />
                        </BrandedLink>
                        }

                        { checkBrandMap('oddsscasser') &&
                        <BrandedLink
                            to='/reservedArea/oddsscasser/puntabanca'
                            onClick={onLinkClick}
                        >
                            <IconTextBox
                                label="Oddsscasser"
                                SVGAsset={
                                    <OddsscasserSVG />
                                }
                            />
                        </BrandedLink>
                        }

                        {/* <a href={getEnvironmentMonolithLinkUrl('reservedArea/oddsScasser/puntabanca')}>
                            <IconTextBox
                                label="OddsScasser"
                                SVGAsset={
                                    <OddsscasserSVG />
                                }
                            />
                        </a> */}

                        <a href={getEnvironmentMonolithLinkUrl('public/support')}>
                            <IconTextBox
                                label="Supporto"
                                SVGAsset={
                                    <SupportSVG />
                                }
                            />
                        </a>
                    </Col>
                </SubmenuCard>

                <Row
                    mainAxis='center'
                    margin='35px 0 0'
                >
                    <LogoutButton onClick={onLinkClick} />
                </Row>
            </Container>

            
            {isSubmenuOpen && (
                    ReactDOM.createPortal(
                        <MenuBackdrop 
                            onClick={onLinkClick}
                        />,
                        backdropPortalElement
                    )
                )}
        </>
    );
}

export default UserProfileSubmenu;
