import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { 
    NormalText, 
    Page, 
    TextBox, 
    TextYellow, 
    Title, 
    TitleBox 
} from "./style/GenericError.style";

const GenericError = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const timeout = setTimeout(() => {
            navigate('/reservedArea');
        }, 5000);
      return () => { clearTimeout(timeout); }
    }, [navigate]);
    
    return (
        <Page>
            <TitleBox>
                <Title>Errore generico</Title>
            </TitleBox>

            <TextBox>
                <TextYellow>A causa di un errore imprevisto non è possibile caricare la pagina.</TextYellow>

                <NormalText>Verrai a breve indirizzato alla Home..</NormalText>
            </TextBox>
        </Page>
    )
};

export default GenericError;