import { FC, useEffect, useState } from "react";

//assets
import { ReactComponent as OpenChapterIconSVG } from './assets/openChapterIcon.svg';
import { ReactComponent as ClosedChapterIconSVG } from './assets/closedChapterIcon.svg';

//style
import {
    Title, TitleText
} from './style/TitleListItem.style';

const TitleListItem: FC<{
    titleElement: any,
    children?: any,
    chapterIndex: number,
    isOpen: boolean,
    collapseHandler: () => void,
    tier: number,
    titlePos: number
}> = ({
    titleElement,
    children,
    chapterIndex,
    isOpen,
    collapseHandler,
    tier,
    titlePos
}) => {

    const [isButtonTitleActive, setIsButtonTitleActive] = useState<boolean>(false);

    const { title } = titleElement;

    const onClickButtonTitleHandler = () => {
        if (!isOpen){
            collapseHandler()
            // setIsButtonTitleActive(true)
        }
        setIsButtonTitleActive((prevState) => !prevState);       
    };

    useEffect(() => {
        setIsButtonTitleActive(isOpen);
    }, [isOpen]);

    console.log("TITLE ELEMENT:", titleElement)
    return (
        <>
            <Title 
                isActive={isButtonTitleActive}
                onClick={onClickButtonTitleHandler}
                tier={tier}
            >
                <TitleText> {chapterIndex + 1}.{titlePos} <span>{title ?? "Manca il titolo..."}</span> </TitleText>


                {!(isButtonTitleActive || isOpen) && <OpenChapterIconSVG />}

                {(isButtonTitleActive || isOpen) && <ClosedChapterIconSVG />}
            </Title>

            {isButtonTitleActive && children}
        </>
    )
};

export default TitleListItem;