import { FC } from 'react';
import { messageSendingDate, messageSendingHour } from './Message.helper';
import { MainPage, DateBox, MessageDate, FirstNot, Poster, Image, Info, BaseText, TimeNot, SecondNot, SecondInfo, SecondTimeNot } from './style/Message.style';


const Message: FC<{message: any, messageId: string}> = ({ message, messageId }) => {

    const { content: messageText, premiumContent: premiumMessageText, files, sendingDate } = message;  

    const messageDate = messageSendingDate(sendingDate);
    const messageTime = messageSendingHour(sendingDate);
    const finalMessageText = (premiumMessageText !== null) ? premiumMessageText : messageText;
    
    return (
        <MainPage id={messageId}>
            <DateBox>
                <MessageDate>
                    {messageDate}
                </MessageDate>
            </DateBox>

            <FirstNot>
                {(messageText !== '') && (
                    <Info>
                        <BaseText dangerouslySetInnerHTML={{__html: finalMessageText}}/>
                    </Info>
                )}

                {(files.length > 0) && (
                    files.map((file: any, index: number) => {

                        const fileIsToDownload = (file.fileType.id === 19);

                        if(fileIsToDownload) return (
                                <a 
                                    key={"FILE" + index} 
                                    href={file.url}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    {file.fileName}
                                </a>
                        );

                        return (
                        <Poster key={"PS" + index}>
                            <Image
                                key={"IMG" + index}
                                src={file.url}
                                alt=""
                            />
                        </Poster>
                        )
                    })
                )}
                
                <TimeNot>
                    {messageTime}
                </TimeNot>
            </FirstNot>
        </MainPage>
    );
};

export default Message;
