import { FC, useEffect, useState } from "react";

// hooks
import useAppSelector from "hooks/useAppSelector";

// routing
import { useSearchParams } from "react-router-dom";

//utils
import ChapterListItem from "./ChapterListItem/ChapterListItem.component";
import TitleListItem from "./TitleListItem/TitleListItem.component";
import LessonListItem from "./LessonListItem/LessonListItem.component";

//style
import {
    FlowBoxContainer,
} from './style/VideoCourseList.style';


const VideoCourseList: FC<{
    lessonSetting?: any, 
    courseItem?: any, 
    collapse?: boolean, 
    collapseHandler: () => void,
    lessonStatus?: any,
    nodeId?: number,
    isOnPublicPage?: boolean,
    // isLessonCompleted?: boolean | undefined
}> = ({
    lessonSetting, 
    courseItem, 
    collapse, 
    collapseHandler,
    lessonStatus,
    nodeId,
    isOnPublicPage,
    // isLessonCompleted
}) => {

    const [activeLesson, setActiveLesson] = useState<number>(nodeId ?? 0);     
    const [searchParams, setSearchParams] = useSearchParams();


    const activeLessonHandler = (lessonId: number) => {
        
        if (isOnPublicPage) return;
        
        setSearchParams({nodeID: lessonId.toString()});
        setActiveLesson(lessonId);
    }
    
    const hasChildrenWithId = (id: number, nodes: any): boolean => {
        return nodes.children && nodes.children.some((child: any) => (child.id === id) || (hasChildrenWithId(id, child)));
      };

    useEffect(() => {
        console.log("LESSON STATUS CHECK: "+JSON.stringify(lessonStatus) );
    }, [lessonStatus]);

    console.log("PPC :", isOnPublicPage) //undefined
    return (
        <FlowBoxContainer>

            {(courseItem !== undefined) && (
            courseItem.map((chapter: any, index: number) => {
                let titlePosition = 1;
                if (!chapter.parentId) {
                    return (
                        <ChapterListItem 
                            key={index}
                            chapterElement={chapter}
                            isOpen={collapse || (hasChildrenWithId(activeLesson, chapter))}
                            collapseHandler={collapseHandler}
                        >
                            {courseItem.map((title: any, index: number) => {
                                if (title.parentId === chapter.id) {
                                    if (title.parentId && !title.children) {
                                        return <LessonListItem 
                                            key={title.id}
                                            lessonElement={title}
                                            lessonSetting={lessonSetting}
                                            activeHandler={activeLessonHandler}
                                            activeId={activeLesson}
                                            // isLessonCompleted={isLessonCompleted}
                                            lessonStatus={lessonStatus}
                                            tier={2}
                                            isOnPublicPage={isOnPublicPage}
                                        />; 
                                    }
                                    titlePosition++;
                                    return (
                                        <TitleListItem 
                                            key={titlePosition}
                                            titleElement={title}
                                            chapterIndex={chapter.pos}
                                            isOpen={collapse || (hasChildrenWithId(activeLesson, title))}
                                            collapseHandler={collapseHandler}
                                            tier={2}
                                            titlePos={titlePosition - 1}
                                        >
                                            {courseItem.map((lesson: any, index: number) => {
                                                if ((lesson.parentId === title.id) && !lesson.children) {
                                                    return <LessonListItem
                                                        key={lesson.id}
                                                        lessonElement={lesson}
                                                        lessonSetting={lessonSetting}
                                                        activeHandler={activeLessonHandler}
                                                        activeId={activeLesson}
                                                        // isLessonCompleted={isLessonCompleted}
                                                        lessonStatus={lessonStatus}
                                                        tier={3}
                                                        isOnPublicPage={isOnPublicPage}
                                                    />;
                                                }
                                            })}
                                        </TitleListItem>
                                    )
                                }
                            })}
                        </ChapterListItem>
                    )
                }
                const lessonsId = courseItem.map((el: any, index: number) => el.id);
                if (!lessonsId.includes(chapter.parentId)) {
                    if (chapter.parentId &&  !chapter.children) {
                        return <LessonListItem 
                            key={chapter.id} 
                            lessonElement={chapter} 
                            lessonSetting={lessonSetting} 
                            activeHandler={activeLessonHandler} 
                            activeId={activeLesson}
                            // isLessonCompleted={isLessonCompleted}    
                            lessonStatus={lessonStatus}
                            tier={1}
                            isOnPublicPage={isOnPublicPage}
                        />; 
                    };
                    titlePosition++;
                    return (
                        <TitleListItem 
                            key={titlePosition - 1}
                            titleElement={chapter}
                            chapterIndex={chapter.pos}
                            isOpen={collapse || (hasChildrenWithId(activeLesson, chapter))}
                            collapseHandler={collapseHandler}
                            tier={1}
                            titlePos={titlePosition}
                        >
                            {courseItem.map((lesson: any, index: number) => {
                                if ((lesson.parentId === chapter.id) && !lesson.children) {
                                    return <LessonListItem
                                        key={lesson.id}
                                        lessonElement={lesson}
                                        lessonSetting={lessonSetting}
                                        activeHandler={activeLessonHandler}
                                        activeId={activeLesson}
                                        // isLessonCompleted={isLessonCompleted}
                                        lessonStatus={lessonStatus}
                                        tier={2}
                                        isOnPublicPage={isOnPublicPage}
                                    />;
                                }
                            })}
                        </TitleListItem>
                    );
                }
            }))}
        </FlowBoxContainer>
    );
};


export default VideoCourseList;