import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

// utils
import { fillArrayWithIndexNumbers } from './TimelineLeftDashedLines.helper';

// types
import { TimelineLeftDashedLinesProps } from './TimelineLeftDashedLines';

// style
import { DashedLinesBox, DashedLine } from './style/TimelineLeftDashedLines.style';


/**
 * RoadMap timeline
 * 
 * @author Alessio Grassi
 * 
 * @param numberOfDashedLines - number of dashed lines
 * 
 * @returns JSX
 */


const TimelineLeftDashedLines: FC<TimelineLeftDashedLinesProps> = ({
        numberOfDashedLines
    }) => {

    const dashedIndexElements: number[] = fillArrayWithIndexNumbers(numberOfDashedLines);

    return (
        <DashedLinesBox>
            {dashedIndexElements.map(() => (
                <DashedLine key={uuidv4()} />
            ))}
        </DashedLinesBox>
    );
};

export default TimelineLeftDashedLines;
