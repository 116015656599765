import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Event } from 'pages/OddsScasserPage/types/Event';
import { RolloverObj } from 'pages/OddsScasserPage/OddsScasser/Rollover/types/RolloverObj';
import { MultipleObj } from 'pages/OddsScasserPage/OddsScasser/Multiple/types/MultipleObj';
import { SingleObj } from 'pages/OddsScasserPage/OddsScasser/types/SingleObj';
import { FiltersObj } from 'pages/OddsScasserPage/OddsScasser/types/FiltersObj';

interface OddsScasserState {
    sites: Map<number, Site>,
    events: Map<number, Event>,
    rollovers?: RolloverObj[],
    calculatorsRollovers?: RolloverObj[],
    multiples?: MultipleObj[],
    calculatorsMultiples?: MultipleObj[],
    singles?: SingleObj[],
    filters?: FiltersObj[],
    userLevel: number
};

// initial slice state
const initialState: OddsScasserState = {
    sites: new Map<number, Site>(),
    events: new Map<number, Event>(),
    userLevel: -1//-1: unknown, 0: no access, 1: only calculators, 2: access with automatic renew enabled, >2: access until this timestamp
};

const oddsScasserSlice = createSlice(
    {
        name: 'oddsscasser',
        initialState,

        reducers: {

            setSites: (state, action: PayloadAction<Map<number, Site>>) => {
                state.sites = action.payload;
            },
            //////
            setEvents: (state, action: PayloadAction<Map<number, Event>>) => {
                state.events = action.payload;
            },
            //////
            setRollovers: (state, action: PayloadAction<RolloverObj[]>) => {
                state.rollovers = action.payload;
            },
            addRollover: (state, action: PayloadAction<RolloverObj>) => {
                state.rollovers = state.rollovers ? [...state.rollovers, action.payload] : [action.payload];
            },
            updateRollover: (state, action: PayloadAction<RolloverObj>) => {
                state.rollovers = state.rollovers ? state.rollovers.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeRollover: (state, action: PayloadAction<number>) => {
                state.rollovers = state.rollovers ? state.rollovers.filter(x => x.id!==action.payload) : [];
            },
            //////
            setCalculatorsRollovers: (state, action: PayloadAction<RolloverObj[]>) => {
                state.calculatorsRollovers = action.payload;
            },
            addCalculatorsRollover: (state, action: PayloadAction<RolloverObj>) => {
                state.calculatorsRollovers = state.calculatorsRollovers ? [...state.calculatorsRollovers, action.payload] : [action.payload];
            },
            updateCalculatorsRollover: (state, action: PayloadAction<RolloverObj>) => {
                state.calculatorsRollovers = state.calculatorsRollovers ? state.calculatorsRollovers.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeCalculatorsRollover: (state, action: PayloadAction<number>) => {
                state.calculatorsRollovers = state.calculatorsRollovers ? state.calculatorsRollovers.filter(x => x.id!==action.payload) : [];
            },
            //////
            setMultiples: (state, action: PayloadAction<MultipleObj[]>) => {
                state.multiples = action.payload;
            },
            addMultiple: (state, action: PayloadAction<MultipleObj>) => {
                state.multiples = state.multiples ? [...state.multiples, action.payload] : [action.payload];
            },
            updateMultiple: (state, action: PayloadAction<MultipleObj>) => {
                state.multiples = state.multiples ? state.multiples.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeMultiple: (state, action: PayloadAction<number>) => {
                state.multiples = state.multiples ? state.multiples.filter(x => x.id!==action.payload) : [];
            },
            //////
            setCalculatorsMultiples: (state, action: PayloadAction<MultipleObj[]>) => {
                state.calculatorsMultiples = action.payload;
            },
            addCalculatorsMultiple: (state, action: PayloadAction<MultipleObj>) => {
                state.calculatorsMultiples = state.calculatorsMultiples ? [...state.calculatorsMultiples, action.payload] : [action.payload];
            },
            updateCalculatorsMultiple: (state, action: PayloadAction<MultipleObj>) => {
                state.calculatorsMultiples = state.calculatorsMultiples ? state.calculatorsMultiples.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeCalculatorsMultiple: (state, action: PayloadAction<number>) => {
                state.calculatorsMultiples = state.calculatorsMultiples ? state.calculatorsMultiples.filter(x => x.id!==action.payload) : [];
            },
            //////
            setSingles: (state, action: PayloadAction<SingleObj[]>) => {
                state.singles = action.payload;
            },
            addSingle: (state, action: PayloadAction<SingleObj>) => {
                state.singles = state.singles ? [...state.singles, action.payload] : [action.payload];
            },
            updateSingle: (state, action: PayloadAction<SingleObj>) => {
                state.singles = state.singles ? state.singles.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeSingle: (state, action: PayloadAction<number>) => {
                state.singles = state.singles ? state.singles.filter(x => x.id!==action.payload) : [];
            },
            //////
            setFilters: (state, action: PayloadAction<FiltersObj[]>) => {
                state.filters = action.payload;
            },
            addFilters: (state, action: PayloadAction<FiltersObj>) => {
                state.filters = state.filters ? [...state.filters, action.payload] : [action.payload];
            },
            updateFilters: (state, action: PayloadAction<FiltersObj>) => {
                state.filters = state.filters ? state.filters.map(x => x.id===action.payload.id ? action.payload : x) : [action.payload];
            },
            removeFilters: (state, action: PayloadAction<number>) => {
                state.filters = state.filters ? state.filters.filter(x => x.id!==action.payload) : [];
            },
            //////
            setUserLevel: (state, action: PayloadAction<number>) => {
                state.userLevel = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    setSites,
    setEvents,
    setRollovers,
    addRollover,
    updateRollover,
    removeRollover,
    setCalculatorsRollovers,
    addCalculatorsRollover,
    updateCalculatorsRollover,
    removeCalculatorsRollover,
    setMultiples,
    addMultiple,
    updateMultiple,
    removeMultiple,
    setCalculatorsMultiples,
    addCalculatorsMultiple,
    updateCalculatorsMultiple,
    removeCalculatorsMultiple,
    setSingles,
    addSingle,
    updateSingle,
    removeSingle,
    setFilters,
    addFilters,
    updateFilters,
    removeFilters,
    setUserLevel
} = oddsScasserSlice.actions;

// reducer for store
export default oddsScasserSlice.reducer;
