import { FC, useState } from 'react';

//assets
import { ReactComponent as SwitchActiveIcon } from './assets/switchActiveIcon.svg';
import { ReactComponent as SwitchDisactiveIcon } from './assets/switchDisactiveIcon.svg';

//style
import {
    SwitchBox,
    SwitchSelectorWithLabel,
    SwitchLabel
} from './style/SwitchSelector.style';


/**
 * Switch Selector
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

const SwitchSelector: FC<{isFilterActive?: boolean, filterHandler?: () => void, label?: string, width?: number, height?: number, isDisabled?: boolean}> = ({isFilterActive, filterHandler, label, width, height, isDisabled}) => {

    // const [isFilterActive, setIsFilterActive] = useState(true);

    const onClickFilterHandler = () => {
        if (filterHandler) filterHandler();
    };

    return (
        <>
            {label 
                ?
                <SwitchSelectorWithLabel width={width} height={height} isDisabled={isDisabled}>
                    <SwitchLabel>{label}</SwitchLabel>
                    <SwitchBox isActive={isFilterActive} onClick={onClickFilterHandler}>
                        {isFilterActive && <SwitchActiveIcon />}
        
                        {!isFilterActive && <SwitchDisactiveIcon />}
                    </SwitchBox>
                </SwitchSelectorWithLabel>
                :
                <SwitchBox isActive={isFilterActive} width={width} height={height} isDisabled={isDisabled} onClick={onClickFilterHandler}>
                    {isFilterActive && <SwitchActiveIcon />}

                    {!isFilterActive && <SwitchDisactiveIcon />}
                </SwitchBox>
                
            }
        </>
        
    );
};

export default SwitchSelector;