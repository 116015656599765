import { FC, useCallback, useEffect, useState } from 'react';

//utils
// import { getItemFromLocalStorage } from 'utils/localStorage';

//hooks


//components
import Dropdown from 'components/Dropdown/Dropdown.component';
import DoubleSlider from 'components/DoubleSlider/DoubleSlider.component';

//assets
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';

//types
import { DatesRangeProps } from './DatesRange.component.d';

//style
import {DatesLimit, DatesLimitLabel, DatesRangeButtonContainer, DatesRangeLabel, DatesRangeLabels, DatesRangeOption, DatesRangeOptionsContainer, DatesRangeValues, DoubleSliderContainer, DateLabel, DatesRangeOptionContainer} from './style/DatesRange.style'
import { DropdownContent, HorizontalScrollContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Col, Row } from 'style/layout';

//helper functions
import { datesStepsArrays, formatDateWithHour } from 'pages/OddsScasserPage/OddsScasserPage.helper';


const  DatesRange: FC<DatesRangeProps> = ({dateMinValue, dateMaxValue, onDateMinChange, onDateMaxChange}) => {
    
    const [datesSteps, setDatesSteps] = useState<string[]>([]);
    const [datesStepsValues, setDatesStepsValues] = useState<(Date|undefined)[]>([]);
    
    const [dateMin, setDateMin] = useState<string>('');
    const [dateMax, setDateMax] = useState<string>('');
    
    const [dateMinSliderValue, setDateMinSliderValue] = useState<number>(0);
    const [dateMaxSliderValue, setDateMaxSliderValue] = useState<number>(0);

    // Get slider value
    const getSliderValue = useCallback(
        (value: string, mode: 'min'|'max') => {
            if(datesSteps.length === 0) return 0;
            const sliderValue = datesSteps.indexOf(value);
            if(sliderValue <= 0) return mode === 'min' ? 0 : datesSteps.length-1;
            else return sliderValue;
        }, [datesSteps]
    );

    useEffect(() => {
        const a = datesStepsArrays(8);
        setDatesSteps(a.steps);
        setDatesStepsValues(a.stepsValues);
        setDateMaxSliderValue(a.steps.length-1);
    }, [])

    const handleSliderMinChange = (newVal: number) => {
        if(datesSteps.length>0) {
            setDateMinSliderValue(newVal);
            setDateMin(datesSteps[newVal]);
            onDateMinChange(datesStepsValues[newVal]);
        }
    };

    const handleSliderMaxChange = (newVal: number) => {
        if(datesSteps.length>0) {
            setDateMaxSliderValue(newVal);
            setDateMax(datesSteps[newVal]);
            onDateMaxChange(datesStepsValues[newVal]);
        }
    };

    const changeRange = (value: string) => {
        let min: string|undefined = undefined;
        let max: string|undefined = undefined;
        if(value === "clear") {
            min = "";
            max = "";
        } else if(value === "today") {
            min = "";
            datesSteps.every(d => {
                if(d.substring(d.length-2) === "00") {
                    max = d;
                    return false;
                }
                return true;
            })
        } else if(value.substring(1) === "-day") {
            let day = parseInt(value.substring(0, 1));
            datesSteps.every((d) => {
                if(max) return false;
                if(d.substring(d.length-2) === "00") {
                    if(day>1) day--;
                    else {
                        if(!min) min = d;
                        else max = d;
                    }
                }
                return true;
            })
        } else if(value === "week") {
            min = "";
            let dayOfWeek = new Date().getDay();
            dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
            let days = 7-dayOfWeek;
            datesSteps.every((d) => {
                if(max) return false;
                if(d.substring(d.length-2) === "00") {
                    if(days>0) days--;
                    else max = d;
                }
                return true;
            })
        } else if(value === "next-week") {
            let dayOfWeek = new Date().getDay();
            dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
            let days = 7-dayOfWeek;
            datesSteps.every((d) => {
                if(max) return false;
                if(d.substring(d.length-2) === "00") {
                    if(days>0) days--;
                    else {
                        if(!min) {
                            min = d;
                            days = 6;
                        } else max = d;
                    }
                }
                return true;
            })
            if(!max) max = "";
        }
        if(min===undefined || max===undefined) return;
        //Min
        setDateMin(min);
        const minSliderValue = getSliderValue(min, 'min');
        setDateMinSliderValue(minSliderValue);
        onDateMinChange(datesStepsValues[minSliderValue]);
        //Max
        setDateMax(max);
        const maxSliderValue = getSliderValue(max, 'max');
        console.log(maxSliderValue);
        setDateMaxSliderValue(maxSliderValue);
        onDateMaxChange(datesStepsValues[maxSliderValue]);
    }

    return(
        <Dropdown 
            position='static'
            button={
                <DatesRangeButtonContainer>
                    <DatesRangeLabel>Data</DatesRangeLabel>
                    <DatesRangeLabels>
                        <DatesLimitLabel>da</DatesLimitLabel>
                        <DatesLimitLabel>a</DatesLimitLabel>
                    </DatesRangeLabels>
                    <DatesRangeValues>
                        <DatesLimit>{dateMinValue ? formatDateWithHour(dateMinValue) : '—'}</DatesLimit>
                        <DatesLimit>{dateMaxValue ? formatDateWithHour(dateMaxValue) : '—'}</DatesLimit>
                    </DatesRangeValues>
                </DatesRangeButtonContainer>
            }
            right={0}
            left={0}
            contentMaxWidth='100%'
            content={
                <DropdownContent maxWidth='100%'>
                    <Col gap='10px' width='100%'>
                        <Row gap='5px'>
                            <DateLabel>{dateMin}</DateLabel>
                            <HorizontalScrollContainer hiddenScrollBar={true}>
                                <DatesRangeOptionsContainer>
                                    <DatesRangeOption tabIndex={-1} onClick={() => changeRange("clear")}><TrashIconSVG/></DatesRangeOption>
                                    <DatesRangeOption tabIndex={-1} onClick={() => changeRange("today")}>Oggi</DatesRangeOption>
                                    <DatesRangeOption tabIndex={-1} onClick={() => changeRange("1-day")}>Domani</DatesRangeOption>
                                    <DatesRangeOptionContainer>
                                        Tra
                                        <DatesRangeOption tabIndex={-1} onClick={() => changeRange("2-day")}>2</DatesRangeOption>
                                        <DatesRangeOption tabIndex={-1} onClick={() => changeRange("3-day")}>3</DatesRangeOption>
                                        <DatesRangeOption tabIndex={-1} onClick={() => changeRange("4-day")}>4</DatesRangeOption>
                                        Giorni
                                    </DatesRangeOptionContainer>
                                    <DatesRangeOption tabIndex={-1} onClick={() => changeRange("week")}>Settimana</DatesRangeOption>
                                    <DatesRangeOption tabIndex={-1} onClick={() => changeRange("next-week")}>Prossima Settimana</DatesRangeOption>
                                </DatesRangeOptionsContainer>
                            </HorizontalScrollContainer>
                            <DateLabel>{dateMax}</DateLabel>
                        </Row>
                        <DoubleSliderContainer>
                            {datesSteps.length>0 && <DoubleSlider min={0} max={datesSteps.length-1} startingMin={dateMinSliderValue} startingMax={dateMaxSliderValue} onMinChange={handleSliderMinChange} onMaxChange={handleSliderMaxChange}/>}
                        </DoubleSliderContainer>
                    </Col>
                </DropdownContent>
            }
        />        
    );
}

export default DatesRange;