// import * as env from "../src/common/env";

// const url = window.location.origin;
// const online = !url.includes("localhost");
// const test = url.includes("ldl-test") || !online;

const getDomain = () => {
    const domain = window.location.hostname;
    const port = window.location.port !== '' ? ':'+window.location.port : '';
    
    return domain+port;
}

const getUrl = () => {
    const domain = getDomain();

    return 'https://'+domain+"/";
}

const authConfiguration = {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //   identityPoolId: "eu-central-1:8d474819-94a2-4e4b-9a2b-11020243e459",
    // REQUIRED - Amazon Cognito Region
    region: "eu-central-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-central-1_Gaq47fVqf",
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7tbdal5hjeth0oq9nlifs5m8e0",
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {

        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: getDomain(),
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
    },
    // OPTIONAL - customized storage object
    //   storage: localStorage,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",
    // OPTIONAL - Hosted UI configuration
    oauth: {
        
        domain: "ldl-pool.auth.eu-central-1.amazoncognito.com",
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: getUrl()+"app/reservedArea",
        redirectSignOut: getUrl()+"app",
        responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
};

export default authConfiguration;
