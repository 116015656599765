import styled, { css } from 'styled-components';

export const TimerBox = styled.div<{size?: 's'|'m', color?: string}>`

    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${props => props.size==='s' ? '4px' : '8px'};
    ${props => props.color && css`color: ${props.color};`};
`;

export const CounterBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const TimeNumber = styled.p<{size?: 's'|'m'}>`

    margin: 0;
    padding: 0;

    font-size: ${props => props.size==='s' ? '16px' : '22px'};
    font-weight: 400;
`;

export const TimeDeclaration = styled.p<{size?: 's'|'m'}>`

    margin: 0;
    padding: 0;

    font-size: ${props => props.size==='s' ? '8px' : '9px'};
    font-weight: 500;
    text-transform: uppercase;
`;
