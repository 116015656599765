// asset
import devices from "style/breakpoints";
import colors from "style/palette/palette";

import styled from "styled-components";


export const MainPage = styled.div`

    display: flex;
    align-items: center;

    flex-direction: column;

    padding: 20px 20px;
    gap: 10px;

    width: 100%;
    
    border-radius: 0px;
    
    position: relative;
    
    @media ${devices.tablet} {
        
        background: rgba(27, 31, 34, 0.3);

        padding: 10px 100px;
    }
`;

export const DateBox = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 5px 15px 6px;

    background: rgba(27, 31, 34, 0.3);
    border-radius: 100px;

    @media ${devices.tablet} {
        
    }
`;

export const MessageDate = styled.p`

    display: flex;
    align-items: center;
    text-align: right;

    white-space: nowrap;

    width: 75px;
    height: 9px;

    font-weight: 500;
    font-size: 12px;
    margin: 0px;
    padding: 0px;

    color: ${colors.white};

    @media ${devices.tablet} {
        
    }
`;

export const FirstNot = styled.div`

    display: flex;
    align-items: flex-start;

    flex-direction: column;

    padding: 0px;

    width: 100%;
    /* height: 444px; */

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 15px;

    overflow: hidden;

    a{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        /* width: 100%; */
        /* max-width: 50ch; */
        padding: 30px 0 0;

        overflow: hidden;
        /* text-overflow: ellipsis; */
        white-space: nowrap;
        color: ${colors.primaryColor};
        text-decoration: none;

        @media ${devices.desktop} {
            justify-content: center;
        }
    }

    @media ${devices.tablet} {

        align-items: center;

        flex-direction: row;
        flex-wrap: wrap;       
    }
`;

export const TimeNot = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin: 0;
    padding: 12px;

    width: 100%;
    font-weight: 400;
    font-size: 12px;

    color: ${colors.white050};

    @media ${devices.tablet} {
        
    }
`;

export const SecondTimeNot = styled.div`

    position: absolute;

    left: 308px;
    top: 72px;

    font-weight: 400;
    font-size: 12px;

    display: flex;
    align-items: center;

    color: ${colors.white050};

    @media ${devices.tablet} {

        left: 485px;
        top: 220px;

        font-weight: 400;
        font-size: 12px;

        display: flex;
        align-items: center;


        color: ${colors.white050};  
    }
`;

export const Poster = styled.div`

    width: 100%;
    height: auto;

    background: ${colors.white};
`;

export const Image = styled.img`

    width: 100%;
    height: auto;
`;

export const SecondNot = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    width: 100%;
    height: 444px;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 15px;

    @media ${devices.tablet} {

        align-items: center;

        height: 100%;
    }
`;

export const Info = styled.div`

    width: 100%;
    padding: 20px 20px 0;

    /* position: relative; */

    @media ${devices.tablet} {
        align-items: baseline;
        text-align: center;
        justify-content: center;

        margin: 0 auto;
    }
`;

export const SecondInfo = styled.div`
    padding: 20px;

    position: relative;

    @media ${devices.tablet} {
        align-items: baseline;
        text-align: center;
        justify-content: center;

        margin: 0 auto;
    }
`;

export const BaseText = styled.div`

    font-weight: 400;
    font-size: 13px;
    margin: 0;
    padding: 0px;

    color: ${colors.white};

    p {
        margin: 0;
        padding: 0;
    }

    a{
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
        margin: 0;
        padding: 10px 0;
    }

    @media ${devices.tablet} {
        
    }
`;