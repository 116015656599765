import { FC, useEffect, useState } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//util
import { getItemFromLocalStorage } from 'utils/localStorage';

//components 
import BaseFieldset from 'components/ui/BaseFieldset';
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete';
import CheckboxesGroup from 'components/CheckboxesGroup';
import Dropdown from 'components/Dropdown';
import NumberInput from 'components/ui/NumberInput';

//style
import { BestOddsFilterContainer, FiltersContainer, MarketsContainer, ResetButton, SearchButton } from './style/BestOddsFilter.style';
import { FeesContainer } from '../OddsScasserFilter/style/OddsScasserFilter.style';
import { DropdownContent, SelectAll, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, StandardCheckbox } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Row } from 'style/layout';

//types
import { BestOddsFilterProps } from './BestOddsFilter.component.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { AutocompletePossibleValuesProps } from 'components/TextInputWithAutocomplete/TextInputWithAutocomplete.component.d';
import { BestOddsFilters } from './types/BestOddsFilters';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';

//helper
import { getDate, getSportColor, stepEach } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const  BestOddsFilter: FC<BestOddsFilterProps> = ({ search }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    
    const { sites, events } = useAppSelector(state => state.oddsScasser);
    const dispatch = useAppDispatch();

    //Sites
    const [sitesList, setSitesList] = useState<Site[]>([]);
    const [sitesChecked, setSitesChecked] = useState<boolean[]>([]);
    //Fees
    const [feesBetfair, setFeesBetfair] = useState<NumberInputState>({value: getItemFromLocalStorage('feesBetfair') ?? '4.5'});
    const [feesBetflag, setFeesBetflag] = useState<NumberInputState>({value: getItemFromLocalStorage('feesBetflag') ?? '5'});
    //Search event
    const [eventsList, setEventsList] = useState<AutocompletePossibleValuesProps[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchTextIndex, setSearchTextIndex] = useState<number>(-1);
    //Markets
    const markets = ['Testa a Testa', 'Esito Finale', 'Doppia Chance', 'Under/Over', 'Under/Over 1.5', 'Under/Over 2.5', 'Under/Over 3.5', 'Goal/No Goal'];
    const marketsSelections = [['V1', 'V2'], ['1', 'X', '2'], ['1X', 'X2', '12'], ['Under 1.5', 'Over 1.5', 'Under 2.5', 'Over 2.5', 'Under 3.5', 'Over 3.5'], ['Under 1.5', 'Over 1.5'], ['Under 2.5', 'Over 2.5'], ['Under 3.5', 'Over 3.5'], ['Goal', 'No Goal']]
    const [marketsChecked, setMarketsChecked] = useState<boolean[]>(new Array(markets.length).fill(false));

    //Set sitesList when sites changes
    useEffect(() => {
        if(sites.size>0) setSitesList(Array.from(sites.values()));
    }, [sites])
    useEffect(() => {
        setSitesChecked(new Array(sitesList.length).fill(false));
    }, [sitesList])

    //Set eventsList when events changes
    useEffect(() => {
        const arr: AutocompletePossibleValuesProps[] = [];
        events.forEach(event => {
            if(event.status !== 'Prematch' || (event.datetime && getDate(event.datetime) < new Date())) return; 
            const home = event.home?.name;
            const away = event.away?.name;
            const league = event.league;
            if(!league) return;
            const country = league.country;
            const leagueName = league.name;
            const pieces = [`${country}, ${leagueName}`, `${leagueName}`, `${home} - ${away} (${country}, ${leagueName})`, `${away} (${country}, ${leagueName})`];
            if(country) pieces.push(...country?.split(' '));
            if(leagueName) pieces.push(...leagueName?.split(' '));
            if(home) pieces.push(...home?.split(' '));
            if(away) pieces.push(...away?.split(' '));
            arr.push({text: `${home} - ${away} (${country}, ${leagueName})`, pieces: pieces, key: 'eventId', value: event.id, color: getSportColor(league.sport?.id)+'85'});
        });
        arr.sort();
        setEventsList(arr);
    }, [events])

    const handleSearchInputChange = (newInputValue: string, index: number) => {
        setSearchText(newInputValue);
        setSearchTextIndex(index);
    }

    const resetFilters = () => {
        setSearchText('');
        setSearchTextIndex(-1);
        setSitesChecked(new Array(sitesList.length).fill(false));
        // setFeesBetfair({value: '4.5'});
        // setFeesBetflag({value: '5'});
        setMarketsChecked(new Array(markets.length).fill(false));
    }

    const onSearchClick = () => {
        if(searchTextIndex<0) {
            dispatch(addSnackbar({ type: 'error', message: 'Nessun evento selezionato!' }));
            return;
        }
        const selections: string[] = [];
        marketsChecked.forEach((b,i) => {
            if(b) selections.push(...marketsSelections[i]);
        })
        const filters: BestOddsFilters = {
            eventId: eventsList[searchTextIndex].value,
            sites: sitesChecked.map((b,i) => b ? sitesList[i].id : undefined).filter(s => s!==undefined).join(','),
            feesBetfair: feesBetfair.errorMessage ? '4.5' : feesBetfair.value,
            feesBetflag: feesBetflag.errorMessage ? '5' : feesBetflag.value,
            selections: selections.join(',')
        }
        search(filters);
    }

    const sitesSelected = sitesChecked.filter(Boolean).length;
    return(
        <BestOddsFilterContainer>
            <BaseFieldset legend="Filtri" padding={10} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017'>
                <ResetButton onClick={resetFilters}/>
                <FiltersContainer>
                    <Row gap='5px' mainAxis='center' flexWrap={isAppMobile ? 'wrap' : undefined}>
                        <TextInputWithAutocomplete label='Evento' labelWidth={65} value={searchText} possibleValues={eventsList} onChange={handleSearchInputChange}/>
                        <Dropdown 
                            buttonText={'Bookmakers'}
                            buttonWidth={100}
                            buttonHighlighted={sitesSelected>0 && sitesSelected<sitesChecked.length}
                            content={
                                <DropdownContent width={175}> 
                                    <SelectAll onClick={() => setSitesChecked(new Array(sitesList.length).fill(!sitesChecked.every(Boolean)))}>
                                        {sitesChecked.every(Boolean) ? 'Deseleziona tutti' : 'Seleziona tutti'}
                                    </SelectAll>  
                                    <SitesCheckboxes><CheckboxesGroup options={ sitesList.map((site, index) => <SiteBox checked={sitesChecked[index]} name={site.name}><SiteLogoContainer visible={sitesChecked[index]} backgroundColor={site.color}><SiteLogo imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>) } checked={sitesChecked} onOptionClick={(checked: boolean[]) => setSitesChecked(checked)}/></SitesCheckboxes>
                                </DropdownContent>
                            }
                        />
                        <Dropdown 
                            buttonText={'Commissioni'}
                            buttonWidth={100}
                            buttonHighlighted={feesBetfair.value!=='4.5' || feesBetflag.value!=='5'}
                            content={
                                <DropdownContent width={150}>
                                    <FeesContainer>
                                        <NumberInput label='Betfair' value={feesBetfair.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFeesBetfair(newState)}/>
                                        <NumberInput label='Betflag' value={feesBetflag.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFeesBetflag(newState)}/>
                                    </FeesContainer>
                                </DropdownContent>
                            }
                        />
                    </Row>
                    <Row gap='5px' mainAxis='center'>
                        <MarketsContainer>
                            <CheckboxesGroup options={ markets.map((market, index) =>  <StandardCheckbox checked={marketsChecked[index]} backgroundColor='dodgerblue'>{market}</StandardCheckbox>) } checked={marketsChecked} onOptionClick={(checked: boolean[]) => setMarketsChecked(checked)}/>
                        </MarketsContainer>
                    </Row>
                </FiltersContainer>
            </BaseFieldset>
            <SearchButton onClick={onSearchClick}>Cerca</SearchButton>
        </BestOddsFilterContainer>
    );
}

export default BestOddsFilter;