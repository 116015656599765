import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import WhatsAppNeedCallCard from './WhatsAppNeedCallCard/WhatsAppNeedCallCard.component';
import WhatsAppInsertNumberCard from './WhatsAppInsertNumberCard/WhatsAppInsertNumberCard.component';
import WhatsAppRequireCallCard from './WhatsAppRequireCallCard/WhatsAppRequireCallCard.component';
import WhatsAppWaitingCard from './WhatsAppWaitingCard/WhatsAppWaitingCard.component';
import WhatsAppCallAssignedCard from './WhatsAppCallAssignedCard/WhatsAppCallAssignedCard.component';

// style
import {
    WhatsAppFlowCardBox
} from './style/WhatsAppFlowCard.style';
import ShareNeedCallModal from './ShareNeedCallModal/ShareNeedCallModal.component';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';

// 

enum CallFlowStages {
    NEED_CALL,
    INSERT_NUMBER,
    REQUIRE_CALL,
    WAITING,
    CALL_ASSIGNED
};

const WhatsAppFlowCard: FC<{
    hasFreeCall?: boolean,
    lessons: any,
    activeLesson:() => void
}> = ({
    hasFreeCall,
    lessons,
    activeLesson
}) => {

    // const [hasUserPubblicRequirePost, setHasUserPubblicRequirePos] = useState<boolean>(false);
    const currentUser = useAppSelector(state => state.user);
    
    const {phoneNumber } = currentUser;

    const hasPhoneNumber = ((phoneNumber !== undefined) && (phoneNumber !== null));

    const initialStage = hasPhoneNumber ? CallFlowStages.REQUIRE_CALL : CallFlowStages.NEED_CALL;

    const [state, setState] = useState({
        currentStage: hasFreeCall ? initialStage : CallFlowStages.WAITING,
        coach: null,
        hasOpenLesson: false,
        hasOtherLessonStatus: false,
        hasNullCoach: false,
        hasNonNullCoach: false,
        lessonList: undefined
    });

    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);

    const handleNeedCallClick = () => {
        setState(prev => ({ ...prev, currentStage: CallFlowStages.INSERT_NUMBER }));
    };

    const openModalHandler = () => setIsShareModalOpen(true);   
    const closeModalHandler = () => setIsShareModalOpen(false);
    // const hasPubblicToFacebook = () => setHasUserPubblicRequirePos(true);

    useEffect(() => {
        const openedLesson = lessons.find((lesson: any) => lesson.productLessonPack.status === "OPENED");
        const openedLessonExists = lessons.some((lesson: any) => lesson.productLessonPack.status === "OPENED");
        const otherLessonStatusExists = lessons.some((lesson: any) => lesson.productLessonPack.status !== "OPENED");
        const nullCoachExists = lessons.some((lesson: any) => lesson.productLessonPack.coach === null);
        const nonNullCoachExists = lessons.some((lesson: any) => lesson.productLessonPack.coach !== null);
        
        const coach = openedLesson ? openedLesson.productLessonPack.coach : null;
        
        let newStage = state.currentStage;
        console.log("STATO ATTUALE?", state.currentStage);
        
        if (hasPhoneNumber) {
            if (openedLessonExists && nullCoachExists) {
                newStage = CallFlowStages.WAITING;
            } else if (openedLessonExists && nonNullCoachExists) {
                newStage = CallFlowStages.CALL_ASSIGNED;
            } else {
                newStage = CallFlowStages.REQUIRE_CALL;
            }
        }
        
        setState(prev => ({
            ...prev,
            currentStage: newStage,
            coach: coach,
            hasOpenLesson: openedLessonExists,
            hasOtherLessonStatus: otherLessonStatusExists,
            hasNullCoach: nullCoachExists,
            hasNonNullCoach: nonNullCoachExists,
            lessonList: lessons
        }));
    }, [lessons, hasPhoneNumber, state.currentStage]);

    // useEffect(() => {
    //     const missingFacebookAccount = getItemFromLocalStorage('Missing Facebook account');
    
    //     if (missingFacebookAccount) {
    //         const timer = setTimeout(() => {
    //             removeItemFromLocalStorage('Missing Facebook account');
    //         }, 4000);
    //         setIsShareModalOpen(true);
    //         return () => clearTimeout(timer);
    //     }
    // }, []);

    useEffect(() => {
        const missingFacebookAccount = getItemFromLocalStorage('Missing Facebook account');
        const missingPubblicToFacabook = getItemFromLocalStorage('Missing pubblic Facebook');
    
        if (missingFacebookAccount || missingPubblicToFacabook) {
            const timer = setTimeout(() => {
                if (missingFacebookAccount) {
                    removeItemFromLocalStorage('Missing Facebook account');
                    setIsShareModalOpen(true);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, []);
    
    const { currentStage, coach, hasOpenLesson, hasOtherLessonStatus, hasNullCoach, hasNonNullCoach } = state;

    return (
        <>
            <WhatsAppFlowCardBox>
                {currentStage === CallFlowStages.NEED_CALL && <WhatsAppNeedCallCard onButtonClick={handleNeedCallClick} />}
                {currentStage === CallFlowStages.INSERT_NUMBER && <WhatsAppInsertNumberCard />}
                {currentStage === CallFlowStages.REQUIRE_CALL && (lessons.length === 0 || hasOtherLessonStatus) && <WhatsAppRequireCallCard activeLesson={activeLesson} openModalHandler={openModalHandler}/>}
                {currentStage === CallFlowStages.WAITING && hasOpenLesson && hasNullCoach &&  <WhatsAppWaitingCard />}
                {currentStage === CallFlowStages.CALL_ASSIGNED && hasOpenLesson && hasNonNullCoach && <WhatsAppCallAssignedCard coach={coach} />}
            </WhatsAppFlowCardBox>

            {isShareModalOpen && (
                <ShareNeedCallModal
                    onCloseButtonModal={closeModalHandler}
                    // prevMonth={prevMonthName()}
                    // previewShareImg={shareImg}
                    // resultBonus={bonusIncoming}
                    // resultColpo={sureShotIncoming}
                    // resultTrading={tradingIncoming}
                    refreshFunction={activeLesson}
                    // isDataLoading={isDataLoading}
                    // isHistorical={!!prevMonthData}
                    // hasPubblicToFacebook={hasPubblicToFacebook}
                />
            )}
        </>
    )
};

export default WhatsAppFlowCard;