import { FC, useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// components
import TimelineLeftDashedLines from './TimelineLeftDashedLines';
import EventBox from './EventBox';
import RoadMapSlider from './RoadMapSlider';

// types
import { RoadMapProps } from './RoadMap';

// style
import {
    Box,
    Content,
    ViewArea,
    FakeTimelineMiddleLine,
    TimelineMiddleLine,
    TimelineRightArrowBox,
    TimelineEventCell,
    TimelineEventCellBoxRef,
    FadeArea,
    Line
} from './style/RoadMap.style';
import useAppSelector from 'hooks/useAppSelector';


const _EVENT_BOX_WIDTH: number = 200;
const _VIEW_AREA: number = 204;

/**
 * RoadMap content
 * 
 * @author Alessio Grassi
 * 
 * @param topLineEvents - [] Array bottom line
 * @param bottomLineEvents - [] Array top line
 * @param isExpanded - state of width of the roadmap
 * @param activeEventTimestamp - timestamp of the "next" event
 * 
 * @returns JSX - NOTE: odd and even are inverted in rendering, because they are derived by separation from index of array
 */

const RoadMap: FC<RoadMapProps> = ({
    topLineEvents,
    bottomLineEvents,
    isExpanded,
    activeEventTimestamp,
    nextEventIndex
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [viewAreaWidth, setViewAreaWidth] = useState<number>(0);
    const [inputSliderMultiplier, setInputSliderMultiplier] = useState<string>("0");
    const viewAreaRef = useRef<HTMLDivElement>(null);
    const eventsLength = bottomLineEvents.length + topLineEvents.length;
    const evenOffset = (eventsLength % 2 === 0) ? 100 : 0;
    const eventBoxMaxWidth = Math.ceil(eventsLength / 2) * _EVENT_BOX_WIDTH + evenOffset;

    const inputSliderChangeHandler = (newInputSliderValue: string) => {
        setInputSliderMultiplier(newInputSliderValue);
    };

    const getSliderStartingPosition = () => {

        const deviceOffset = isAppMobile ? 1 : 2;
        const roadmapWidth = document.getElementById('RoadMapArea')?.offsetWidth;
        if (roadmapWidth === undefined) return 0;
        const startPositionInPX = ((nextEventIndex - deviceOffset) * 100);
        const sliderStartPositionInPerc = ((startPositionInPX / (eventBoxMaxWidth - roadmapWidth)) * 100);
        if (sliderStartPositionInPerc > 100) return 100;
        return sliderStartPositionInPerc;
    };

    useEffect(() => {

        const startingSliderPosition = getSliderStartingPosition();
        setInputSliderMultiplier(`${startingSliderPosition}`);
    }, [nextEventIndex]);

    // resize on viewArea
    useEffect(() => {

        const suppViewAreaRef = viewAreaRef?.current;
        if (!suppViewAreaRef) return;

        const resizeHandler = () => {
        
            if (!viewAreaRef?.current) return;            
            const newViewAreaWidth = viewAreaRef.current.offsetWidth;

            setViewAreaWidth(newViewAreaWidth);
        };

        resizeHandler();
        window.addEventListener("resize", resizeHandler);

        return () => window.removeEventListener("resize", resizeHandler);
        
    }, [isExpanded]);

    // divide remaining hidden area in blocks
    const translateValue: number = ( (eventBoxMaxWidth - viewAreaWidth) / 100 ) * (+inputSliderMultiplier);
    const isSliderActive: boolean = (eventBoxMaxWidth >= viewAreaWidth);

    const getEventHour: any = (begin: any) => {
        let date = new Date(+begin);
        let italiandatestr = date.toLocaleString("en-US", { timeZone: "Europe/Rome" });
        let italiandate = new Date(italiandatestr);
        let localtime = "h " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
        let italiantime = "h " + ("0" + italiandate.getHours()).slice(-2) + ":" + ("0" + italiandate.getMinutes()).slice(-2);
        let time = "";
        if (italiantime === "h 00:00") {
            time = "";
        } else {
            time = localtime;
        }
        return time;
    };

    return (
        <Box>
            <Content>
                <TimelineLeftDashedLines numberOfDashedLines={3} />

                <ViewArea
                    ref={viewAreaRef}
                    height={`${_VIEW_AREA}px`}
                    id='RoadMapArea'
                >
                    <FadeArea isLeft />

                    <FakeTimelineMiddleLine />

                    <TimelineMiddleLine
                        width={`${eventBoxMaxWidth}px`}
                        effect={`-${translateValue.toString()}px`}
                    >
                        {topLineEvents.map((event: any, index: number) => (
                            <TimelineEventCell
                                key={uuidv4()}
                                maxWidth={`${_EVENT_BOX_WIDTH}px`}
                            >
                                <TimelineEventCellBoxRef>
                                    <EventBox
                                        imgSrc={event.img}
                                        date={event.date}
                                        hour={getEventHour(event.startTimestamp)}
                                        info={event.info}
                                        link={event.link}
                                        isPast={+event.startTimestamp < activeEventTimestamp}
                                        isActive={+event.startTimestamp === activeEventTimestamp}
                                        isTbd={event.tbd}
                                    />
                                </TimelineEventCellBoxRef>

                                <TimelineEventCellBoxRef>
                                    {(bottomLineEvents[index] !== undefined) && (
                                        <EventBox
                                            imgSrc={bottomLineEvents[index]?.img}
                                            date={bottomLineEvents[index]?.date}
                                            hour={getEventHour(bottomLineEvents[index]?.startTimestamp)}
                                            info={bottomLineEvents[index]?.info}
                                            link={bottomLineEvents[index]?.link}
                                            isPast={+bottomLineEvents[index].startTimestamp! < activeEventTimestamp}
                                            isActive={+bottomLineEvents[index].startTimestamp! === activeEventTimestamp}
                                            isBottom
                                            isTbd={bottomLineEvents[index]?.tbd}
                                        />
                                    )}
                                </TimelineEventCellBoxRef>
                            </TimelineEventCell>
                        ))}
                    </TimelineMiddleLine>

                    <FadeArea isRight isTop />

                    <FadeArea isRight isBottom />
                </ViewArea>

                <TimelineRightArrowBox>
                    <span />
                </TimelineRightArrowBox>
            </Content>

            {isSliderActive && (
                <>
                    <Line />

                    <RoadMapSlider
                        inputValue={inputSliderMultiplier}
                        maxValue="100"
                        step="1"
                        onChange={inputSliderChangeHandler}
                    />
                </>
            )}
        </Box>
    );
}

export default RoadMap;
