import styled from "styled-components";

// asset
import colors from "style/palette/palette";


export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const MediaGrid = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0px;
    gap: 10px;

    width: 100%;
    height: 219px;

    overflow: hidden;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}
        0px 2px 2px ${colors.black014}
        0px 1px 5px ${colors.black012}
    ;
    
    box-sizing: border-box;

    width: 100%;
    height: 219px;

    background: ${colors.white};
    border-bottom: 1px solid ${colors.white050};
`;

export const TitleBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 30px;
    
    width: 100%;
    padding: 20px 20px 10px;
`;

export const GridCard = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 0px 20px;
    gap: 20px;
`;

export const TitleB = styled.h2`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0px;
    padding: 0px 0px 0px 10px;

    font-weight: 500;
    font-size: 16px;

    color: ${colors.white};

    svg {
        margin: 0 0 0 15px;
    }
`;

export const CardPrice = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px 26px 30px;

    width: 100%;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
        ;
    border-radius: 15px;
`;

export const VideoImg = styled.img`

    width: 100%;
`;

export const TitleCard = styled.h2`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0;
    padding: 0;
    
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    
    color: ${colors.primaryColor};
`;

export const TextBulllet = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    padding: 4px 0px 4px 0px;
    margin: 0px;

    > svg {
        margin: 0 17px 0 0;
        flex-shrink: 0;
        width: 17px;
        height: 17px;
    }
`;

export const TextCheck = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 30px;
    
    padding: 4px 0px 4px 0px;
    margin: 0px;

    > svg {
        margin: 0 17px 0 0;
        flex-shrink: 0;
        width: 17px;
        height: 17px;
    }
`;

export const TextPrice = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0;
    gap: 8px;
`;


export const TextP = styled.p`

    display: flex;
    flex-direction: row;
    align-items: center;
    
    padding: 0px;
    margin: 0px;

    font-weight: 400;
    font-size: 13px;
    line-height: 22px;

    color: ${colors.white};
`;

export const ButtonP = styled.button`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 20px 0 0;
    padding: 4px 15px;

    border: none;
    box-shadow: none;

    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.46px;

    background: ${colors.primaryColor};

    border-radius: 4px;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
`;

export const SecondaCard = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 25px 26px 30px;
    width: 100%;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ;
    border-radius: 15px;

    iframe {
        width: 100%;
    }
`;

export const BlockText = styled.div`

    text-align: start;
    width: 100%;

    color: ${colors.white};
`;

export const BlockImg = styled.img`

    width: 100%;
    max-width: 400px;
`;

export const BlockRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const BlockGroup = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
    flex-direction: column;
    width: 100%;
`;

export const ProductLimitationRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
`;

export const LimitedSlotBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

`;

export const LimitedTimeBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    color: ${colors.white};
`;

export const LimitedSlots = styled.h4`

    margin: 0;
    padding: 0;

    color: ${colors.primaryColor};
    font-size: 20px;
    font-weight: 700;
`;

export const LimitationDisclaimer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;
    
    margin: 0;
    padding: 0;

    color: ${colors.white};
    font-size: 17px;
    font-weight: 400;
    
    > span {
        color: ${colors.red};
        font-weight: 500;
    }
`;


export const VideoCourseListBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 35px auto;

    width: 310px;
    gap: 20px;
`;

export const ListText = styled.h6`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    margin: 0px;
    padding: 0px;
    color: ${colors.primaryColor};
`;