import styled from 'styled-components';

export const CalculatorsContainer = styled.div`
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    
`;

export const CalculatorBox = styled.div`
    
    width: fit-content;

`;