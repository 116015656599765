import styled from 'styled-components';
import { Button as BaseButton } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const Button = styled(BaseButton)`

    width: 100%;

    padding: 11px 20px;

    background: ${colors.blue};
    color: ${colors.white};

    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    gap: 10px;

    > svg:first-child {

        flex-shrink: 0;
    }

    @media ${devices.tablet} {

        padding: 11px 20px;
        gap: 8px;
    }
`;
