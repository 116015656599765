import { FC, useEffect, useState, useMemo } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { setItemToLocalStorage, getItemFromLocalStorage } from 'utils/localStorage';
import { _deleteFilters, _getFilters, _saveFilters } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { setFilters, addFilters, updateFilters, removeFilters } from 'redux/actions/actions-oddsscasser';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import NumberInput from 'components/ui/NumberInput';
import CheckboxesGroup from 'components/CheckboxesGroup';
import Dropdown from 'components/Dropdown';
import OddsRange from './OddsRange';
import DatesRange from './DatesRange';
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete';
import SwitchSelectorBig from 'components/SwitchSelectorBig';
import TextInputWithLabel from 'components/ui/TextInputWithLabel';

//assets
import { ReactComponent as SoccerIconSVG } from 'pages/OddsScasserPage/assets/soccer.svg';
import { ReactComponent as TennisIconSVG } from 'pages/OddsScasserPage/assets/tennis.svg';
import { ReactComponent as BasketIconSVG } from 'pages/OddsScasserPage/assets/basket.svg';
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';
import { ReactComponent as TrophyIconSVG } from 'pages/OddsScasserPage/assets/trophy.svg';

//types
import { OddsScasserFilterProps } from './OddsScasserFilter.component.d';
import { OddsScasserFilters } from './types/OddsScasserFilters';
import { AutocompletePossibleValuesProps } from 'components/TextInputWithAutocomplete/TextInputWithAutocomplete.component.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { League } from 'pages/OddsScasserPage/types/League';
import { FiltersObj } from '../types/FiltersObj';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';

//style
import { 
    OddsScasserFilterContainer, 
    FiltersContainer,
    SportsContainer,
    Sport, 
    FeesContainer,
    BlacklistElement, BlacklistElementEvent, BlacklistElementSelection, BlacklistContainer, 
    SearchButton, ResetButton, OrderChoiceContainer, OrderChoiceLabel, EventAndOrderContainer, BlackListTitle
} from './style/OddsScasserFilter.style'
import { StandardCheckboxesContainer, StandardCheckbox, SelectAll, DropdownContent, SVGIconContainer, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, SavedElementCheckbox, SavedElementsContainer, ToolButton, ToolButtons } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Col, Row } from 'style/layout';

//helper functions
import { stepIntegers, stepEach, getDate, getSportColor, formatDate } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const  OddsScasserFilter: FC<OddsScasserFilterProps> = ({ mode, filtersNow, search, blacklist, removeFromBlacklist, onFeesBetfairChange, onFeesBetflagChange, copyToClipboard }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events, filters } = useAppSelector(state => state.oddsScasser);
    const { roles } = useAppSelector(state => state.user);

    const isAdmin: boolean = useMemo(() => roles ? roles.map((role: any) => role.id).includes(31) : false, [roles]);

    const dispatch = useAppDispatch();

    const [initializationCompleted, setInitializationCompleted] = useState<boolean>(false);
    const [filtersIndex, setFiltersIndex] = useState<number>(-1);
    const [toSave, setToSave] = useState<boolean>(false);
    const [description, setDescription] = useState<TextInputState>({value: ''});
    //BetInfo
    const [bet, setBet] = useState<NumberInputState>({value: '100'});
    const [bonus, setBonus] = useState<NumberInputState>({value: '0'});
    const [refund, setRefund] = useState<NumberInputState>({value: '0'});
    const [isFreebet, setIsFreebet] = useState<boolean>(false);
    //Filters
        //Sports
    const sports = [{id: 1, name: 'Calcio', icon: <SoccerIconSVG />, backgroundColor: '#1b7700'}, {id: 2, name: 'Tennis', icon: <TennisIconSVG />, backgroundColor: '#bf9a17'}, {id: 3, name: 'Basket', icon: <BasketIconSVG />, backgroundColor: '#91311d'}];
    const [sportsChecked, setSportsChecked] = useState<boolean[]>(new Array(sports.length).fill(true));
        //Sites
    const [sites1, setSites1] = useState<Site[]>([]);
    const [sites1Checked, setSites1Checked] = useState<boolean[]>([]);
    const [sites2PuntaBanca, setSites2PuntaBanca] = useState<Site[]>([]);
    const [sites2PuntaBancaChecked, setSites2PuntaBancaChecked] = useState<boolean[]>([]);
    const [sites2PuntaPunta, setSites2PuntaPunta] = useState<Site[]>([]);
    const [sites2PuntaPuntaChecked, setSites2PuntaPuntaChecked] = useState<boolean[]>([]);
        //Markets
    const markets = ['Testa a Testa', 'Esito Finale', 'Doppia Chance', 'Under/Over', 'Under/Over 1.5', 'Under/Over 2.5', 'Under/Over 3.5', 'Goal/No Goal'];
    const marketsSelections = [['V1', 'V2'], ['1', 'X', '2'], ['1X', 'X2', '12'], ['Under 1.5', 'Over 1.5', 'Under 2.5', 'Over 2.5', 'Under 3.5', 'Over 3.5'], ['Under 1.5', 'Over 1.5'], ['Under 2.5', 'Over 2.5'], ['Under 3.5', 'Over 3.5'], ['Goal', 'No Goal']]
    const [marketsChecked, setMarketsChecked] = useState<boolean[]>(new Array(markets.length).fill(false));
        //Types (2 outcomes/3 outcomes)
    const [typesChecked, setTypesChecked] = useState<boolean[]>(new Array(2).fill(false));
        //Fees
    const [feesBetfair, setFeesBetfair] = useState<NumberInputState>({value: getItemFromLocalStorage('feesBetfair') ?? '4.5'});
    const [feesBetflag, setFeesBetflag] = useState<NumberInputState>({value: getItemFromLocalStorage('feesBetflag') ?? '5'});
        //Rating
    const [rating, setRating] = useState<NumberInputState>({value: ''});
        //Available
    const [available, setAvailable] = useState<NumberInputState>({value: '0'});
        //Odds range
    const [oddsMin, setOddsMin] = useState<number|undefined>(undefined);
    const [oddsMax, setOddsMax] = useState<number|undefined>(undefined);
        //Date range
    const [dateMin, setDateMin] = useState<Date|undefined>(undefined);
    const [dateMax, setDateMax] = useState<Date|undefined>(undefined);
        //Search event/league
    const [eventsAndLeagues, setEventsAndLeagues] = useState<AutocompletePossibleValuesProps[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchTextIndex, setSearchTextIndex] = useState<number>(-1);
        //Order
    const [order, setOrder] = useState<'rating'|'date'>('rating');
    
    useEffect(() => {
        //Get filters
        const getFilters = async () => {
            try {
                let _filters = filters;
                if(!_filters) {
                    _filters = await _getFilters() as FiltersObj[];
                    dispatch(setFilters(_filters));
                }
                setFiltersIndex(_filters.findIndex(x => JSON.stringify(x.data)===JSON.stringify(filtersNow)));
            } catch (error: any) {
                console.warn(error);
            }
        };
        if(!initializationCompleted) getFilters().then(() => setInitializationCompleted(true));
    }, []);

    useEffect(() => {
        if(filters && initializationCompleted) {
            setToSave(!(filters!==undefined && filtersIndex>-1 && filtersIndex<filters.length && JSON.stringify(getFiltersData())===JSON.stringify(filters[filtersIndex].data)));
        }
    }, [filtersIndex, description, bet, bonus, refund, isFreebet, sportsChecked, sites1Checked, sites2PuntaBancaChecked, sites2PuntaPuntaChecked, marketsChecked, typesChecked, feesBetfair, feesBetflag, rating, available, oddsMin, oddsMax, dateMin, dateMax, searchTextIndex, order]);

    useEffect(() => {
        if(isFreebet && !bet.errorMessage) {
            setRefund({value: bet.value});
            setBonus({value: '0'});
        }
    }, [bet, isFreebet]);

    const handleIsFreebetChange = () => {
        setIsFreebet(prevState => {
            if(prevState) setRefund({value: '0'});
            return !prevState;
        })
    }

    //Set sites when mode/sites changes
    useEffect(() => {
        if(sites.size>0) {
            if(sites1.length === 0) setSites1(Array.from(sites.values()).filter(site =>  site.type === 'bookmaker'));
            if(sites2PuntaBanca.length === 0) setSites2PuntaBanca(Array.from(sites.values()).filter(site =>  site.type === 'exchange'));
            if(sites2PuntaPunta.length === 0) setSites2PuntaPunta(Array.from(sites.values()).filter(site =>  site.type === 'bookmaker'));
        }
    }, [sites])

    //Set eventsAndLeagues when events changes
    useEffect(() => {
        const arr: AutocompletePossibleValuesProps[] = [];
        const leagues: number[] = [];
        events.forEach(event => {
            if(event.status !== 'Prematch' || (event.datetime && getDate(event.datetime) < new Date())) return; 
            const home = event.home?.name;
            const away = event.away?.name;
            const league = event.league;
            if(!league) return;
            const country = league?.country;
            const leagueName = league?.name;
            const pieces = [`${country}, ${leagueName}`, `${leagueName}`];
            if(country) pieces.push(...country?.split(' '));
            if(leagueName) pieces.push(...leagueName?.split(' '));
            if(!leagues.includes(league.id)) {
                arr.push({text: `${country}, ${leagueName}`, pieces: pieces, key: 'leagueId', value: league.id, color: getSportColor(league.sport?.id)+'85'});
                leagues.push(league.id);
            }
            pieces.push(`${home} - ${away} (${country}, ${leagueName})`, `${away} (${country}, ${leagueName})`);
            if(home) pieces.push(...home?.split(' '));
            if(away) pieces.push(...away?.split(' '));
            arr.push({text: `${home} - ${away} (${country}, ${leagueName})`, pieces: pieces, key: 'eventId', value: event.id, color: getSportColor(league.sport?.id)+'85'});
        });
        arr.sort((x, y) => x.key !== y.key ? y.key.localeCompare(x.key) : x.text.localeCompare(y.text));
        setEventsAndLeagues(arr);
    }, [events])

    const leagues: League[] = useMemo(() => {
        const arr: League[] = [];
        const leaguesIds: number[] = [];
        events.forEach(event => {
            if(event.league && !leaguesIds.includes(event.league.id)) {
                arr.push(event.league);
                leaguesIds.push(event.league.id);
            }
        });
        return arr;
    }, [events])

    const handleSearchInputChange = (newInputValue: string, index: number) => {
        setSearchText(newInputValue);
        setSearchTextIndex(index);
    }

    const resetBetInfo = () => {
        setBet({value: '100'});
        setBonus({value: '0'});
        setRefund({value: '0'});
        setIsFreebet(false);
    }

    const resetFilters = () => {
        setSportsChecked(new Array(sports.length).fill(true));
        setSites1Checked(new Array(sites1.length).fill(false));
        setSites2PuntaBancaChecked(new Array(sites2PuntaBanca.length).fill(false));
        setSites2PuntaPuntaChecked(new Array(sites2PuntaPunta.length).fill(false));
        setMarketsChecked(new Array(markets.length).fill(false));
        setTypesChecked(new Array(2).fill(false));
        // setFeesBetfair({value: '4.5'});
        // setFeesBetflag({value: '5'});
        setRating({value: ''});
        setAvailable({value: '0'});
        setOddsMin(undefined);
        setOddsMax(undefined);
        setDateMin(undefined);
        setDateMax(undefined);
        setSearchText('');
        setSearchTextIndex(-1);
        removeFromBlacklist(Array.from(Array(blacklist.length).keys()));
    }

    useEffect(() => {
        if(sites1.length===0 || sites2PuntaBanca.length===0 || sites2PuntaPunta.length===0 || eventsAndLeagues.length===0) return;
        loadFilters(filtersNow);
    }, [filtersNow, sites1, sites2PuntaBanca, sites2PuntaPunta, eventsAndLeagues])

    const loadFilters = (_filters: OddsScasserFilters) => {
        //BetInfo
        if(_filters.bet) setBet({value: _filters.bet});
        if(_filters.bonus) setBonus({value: _filters.bonus});
        setIsFreebet(_filters.isFreebet);
        if(_filters.refund) setRefund({value: _filters.refund});
        //Sports
        if(_filters.sports) {
            const s = _filters.sports.split(',').map(x => parseInt(x));
            setSportsChecked(sports.map(x => s.includes(x.id)));
        } else setSportsChecked(new Array(sports.length).fill(true));
        //Sites
        if(_filters.sites1) {
            const s = _filters.sites1.split(',').map(x => parseInt(x));
            setSites1Checked(sites1.map(x => s.includes(x.id)));
        } else setSites1Checked(new Array(sites1.length).fill(false));
        if(_filters.sites2PuntaBanca) {
            const s = _filters.sites2PuntaBanca.split(',').map(x => parseInt(x));
            setSites2PuntaBancaChecked(sites2PuntaBanca.map(x => s.includes(x.id)));
        } else setSites2PuntaBancaChecked(new Array(sites2PuntaBanca.length).fill(false));
        if(_filters.sites2PuntaPunta) {
            const s = _filters.sites2PuntaPunta.split(',').map(x => parseInt(x));
            setSites2PuntaPuntaChecked(sites2PuntaPunta.map(x => s.includes(x.id)));
        } else setSites2PuntaPuntaChecked(new Array(sites2PuntaPunta.length).fill(false));
        //Markets
        if(_filters.selections) {
            const s = _filters.selections.split(',');
            setMarketsChecked(marketsSelections.map(x => s.includes(x[0])));
        } else setMarketsChecked(new Array(markets.length).fill(false));
        //Types (2 outcomes/3 outcomes)
        setTypesChecked((_filters.twoOutcomes === false || _filters.threeOutcomes === false) ? [!(_filters.twoOutcomes === false), !(_filters.threeOutcomes === false)] : [false, false])
        //Fees
        setFeesBetfair({value: _filters.feesBetfair ?? '4.5'});
        setFeesBetflag({value: _filters.feesBetflag ?? '5'});
        //Rating
        if(_filters.rating) setRating({value: _filters.rating ?? ''});
        //Available
        if(_filters.available) setAvailable({value: _filters.available ?? '0'});
        //Odds range
        setOddsMin((_filters.oddsMin && !isNaN(parseFloat(_filters.oddsMin))) ? parseFloat(_filters.oddsMin) : undefined);
        setOddsMax((_filters.oddsMax && !isNaN(parseFloat(_filters.oddsMax))) ? parseFloat(_filters.oddsMax) : undefined);
        //Date range
        setDateMin(_filters.dateFrom ? new Date(_filters.dateFrom) : undefined);
        setDateMax(_filters.dateTo ? new Date(_filters.dateTo) : undefined);
        //Search event/league
        if(_filters.leaguesIds) {
            const l = _filters.leaguesIds.split(',').map(x => parseInt(x));
            if(l.length>1) {
                setSearchText('Competizioni principali');
                setSearchTextIndex(eventsAndLeagues.length);
            } else if(l.length===1) {
                const index = eventsAndLeagues.findIndex(x => x.key==='leagueId' && x.value===l[0]);
                setSearchText(index>-1 ? eventsAndLeagues[index].text : '');
                setSearchTextIndex(index);
            }
        } else if(_filters.eventsIds) {
            const e = _filters.eventsIds.split(',').map(x => parseInt(x));
            if(e.length===1) {
                const index = eventsAndLeagues.findIndex(x => x.key==='eventId' && x.value===e[0]);
                setSearchText(index>-1 ? eventsAndLeagues[index].text : '');
                setSearchTextIndex(index);
            }
        } else {
            setSearchText('');
            setSearchTextIndex(-1);
        }
        //Order
        setOrder(_filters.order);
    }

    useEffect(() => {
        search(filtersNow);
    }, [mode])

    useEffect(() => {
        if(!feesBetfair.errorMessage) {
            setItemToLocalStorage('feesBetfair', feesBetfair.value);
            onFeesBetfairChange(feesBetfair.value);
        }
    }, [feesBetfair])
    useEffect(() => {
        if(!feesBetflag.errorMessage) {
            setItemToLocalStorage('feesBetflag', feesBetflag.value);
            onFeesBetflagChange(feesBetflag.value);
        }
    }, [feesBetflag])
    
    const onSearchClick = () => {
        search(getFilters());
    }

    const getFilters = () => {
        const selections: string[] = [];
        marketsChecked.forEach((b,i) => {
            if(b) selections.push(...marketsSelections[i]);
        })
        const isBetZero = parseFloat(bet.value)<0.01;
        const _filters: OddsScasserFilters = {
            bet: isBetZero ? '100' : (bet.errorMessage ? '0' : bet.value),
            bonus: bonus.errorMessage ? '0' : bonus.value,
            refund: refund.errorMessage ? '0' : refund.value,
            isFreebet,
            sports: sportsChecked.map((b,i) => b ? sports[i].id : undefined).filter(s => s!==undefined).join(','),
            sites1: sites1Checked.map((b,i) => b ? sites1[i].id : undefined).filter(s => s!==undefined).join(','),
            sites2PuntaBanca: sites2PuntaBancaChecked.map((b,i) => b ? sites2PuntaBanca[i].id : undefined).filter(s => s!==undefined).join(','),
            sites2PuntaPunta: sites2PuntaPuntaChecked.map((b,i) => b ? sites2PuntaPunta[i].id : undefined).filter(s => s!==undefined).join(','),
            selections: selections.join(','),
            blacklist: blacklist.map(o => o.id+'|'+o.type).join(','),
            rating: (rating.errorMessage || rating.value.length===0) ? undefined : rating.value,
            order: order==='rating' ? 'rating' : 'date',
            twoOutcomes: typesChecked[0],
            threeOutcomes: typesChecked[1],
            feesBetfair: feesBetfair.errorMessage ? '4.5' : feesBetfair.value,
            feesBetflag: feesBetflag.errorMessage ? '5' : feesBetflag.value,
            available: available.errorMessage ? '0' : available.value,
        }
        if(oddsMin) _filters['oddsMin'] = oddsMin.toFixed(2);
        if(oddsMax) _filters['oddsMax'] = oddsMax.toFixed(2);
        if(dateMin) _filters['dateFrom'] = dateMin.toISOString();
        if(dateMax) _filters['dateTo'] = dateMax.toISOString();
        if(searchTextIndex>-1) {
            if(searchTextIndex<eventsAndLeagues.length) _filters[eventsAndLeagues[searchTextIndex].key === 'eventId' ? 'eventsIds' : 'leaguesIds'] = eventsAndLeagues[searchTextIndex].value.toString();
            else if(leagues.length>0) _filters['leaguesIds'] = [...leagues.filter(x => x.top).map(x => x.id), 0].join(',');
        }
        return _filters;
    }

    const getFiltersData = () => ({description, filters: getFilters()});

    const save = async () => {
        if(!filters) return;
        const id = filtersIndex>-1 ? filters[filtersIndex].id : null;
        const data = getFiltersData();
        if(data.description.value.length === 0) {
            data.description = {value: 'Filtri '+formatDate(new Date())};
            setDescription(data.description);
        }
        const _id = await _saveFilters({id, description: data.description.value, data}) as number;
        if(!_id) return(dispatch(addSnackbar({ type: 'error', message: 'Errore nel salvataggio dei filtri'})));
        if(!id) {
            setFiltersIndex(filters.length ?? -1);
            dispatch(addFilters({id: _id, description: data.description.value, data}));
        } else {
            const index = filters ? filters.findIndex(x => x.id===_id) : -1;
            setFiltersIndex(index);
            if(index>-1) dispatch(updateFilters({id: _id, description: data.description.value, data}));//update data
        }
        setToSave(false);
        dispatch(addSnackbar({ type: 'success', message: 'Filtri salvati'}));
    }

    const _delete = async (index: number, id: number|null) => {
        if(!id) return;
        if(!await _deleteFilters(id)) return(dispatch(addSnackbar({ type: 'error', message: "Errore nell'eliminazione dei filtri"})));
        if(filtersIndex===index) reset();
        else if(index<filtersIndex) setFiltersIndex(prevIndex =>  prevIndex-1);//Adjust filtersIndex if needed
        dispatch(removeFilters(id));
        dispatch(addSnackbar({ type: 'success', message: 'Filtri eliminati'}));
        
    }

    const reset = () => {
        setFiltersIndex(-1);
        setDescription({value: ''});
        resetFilters();
    }

    const duplicate = () => {
        setFiltersIndex(-1);
    }
    
    const open = (checked: boolean[]) => {
        if(!filters) return;
        const index =  checked.findIndex(Boolean);
        setFiltersIndex(index);
        if(index<0) return reset();
        setDescription(filters[index].data.description);
        loadFilters(filters[index].data.filters);
    }

    const filtersChecked = filters ? filters.map((_, i) => i===filtersIndex) : [];

    const sites1Selected = sites1Checked.filter(Boolean).length;

    const sites2 = mode==='puntabanca' ? sites2PuntaBanca : sites2PuntaPunta;
    const sites2Checked = mode==='puntabanca' ? sites2PuntaBancaChecked : sites2PuntaPuntaChecked;
    const sites2Selected = sites2Checked.filter(Boolean).length;
    const setSites2Checked = mode==='puntabanca' ? setSites2PuntaBancaChecked : setSites2PuntaPuntaChecked;

    const sites2Alt = mode==='puntapunta' ? sites2PuntaBanca : sites2PuntaPunta;
    const sites2AltChecked = mode==='puntapunta' ? sites2PuntaBancaChecked : sites2PuntaPuntaChecked;
    const sites2AltSelected = sites2AltChecked.filter(Boolean).length;
    const setSites2AltChecked = mode==='puntapunta' ? setSites2PuntaBancaChecked : setSites2PuntaPuntaChecked;

    const marketsSelected = marketsChecked.filter(Boolean).length;

    const typesSelected = typesChecked.filter(Boolean).length; 
    return(
        <OddsScasserFilterContainer>
            {isAdmin &&
                <BaseFieldset legend="Salvataggio filtri" padding={10} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' fitContent={true}>
                    <Col gap='5px'>
                        <ToolButtons changePosition={true}>
                            {filtersIndex>-1 && <ToolButton backgroundColor='#843500' onClick={duplicate}>Duplica</ToolButton>}
                            {toSave && <ToolButton backgroundColor='rgb(1 79 1)' onClick={save}>Salva</ToolButton>}
                            {(filters && filters.length>0) && 
                                <Dropdown 
                                    button={<ToolButton backgroundColor='rgb(114 71 13)'>Apri</ToolButton>}
                                    buttonWidth={95}
                                    content={
                                        <DropdownContent maxWidth='280px'> 
                                            <SavedElementsContainer>
                                                <CheckboxesGroup maxChecked={1} options={ filters.map((_filters, index) =>  <SavedElementCheckbox checked={index===filtersIndex} backgroundColor='dodgerblue'>{_filters.data.description.value}<TrashIconSVG title='Elimina filtri' onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {event.stopPropagation(); _delete(index, _filters.id);}}/></SavedElementCheckbox>) } checked={filtersChecked} onOptionClick={(checked: boolean[]) => open(checked)}/>
                                            </SavedElementsContainer>  
                                        </DropdownContent>
                                    }
                                    top={23}
                                    right={0}
                                />
                            }
                            <ToolButton backgroundColor='#023e74' onClick={reset}>Nuovi</ToolButton>
                        </ToolButtons>
                        <Row gap='5px' mainAxis='center' flexWrap='wrap'>
                            <TextInputWithLabel label='Descrizione' value={description.value} allowEmpty={true} onChange={(newState: TextInputState) => setDescription(newState)}/>
                            <Row gap='5px' width='auto' flexWrap='wrap'>
                                <Dropdown 
                                    buttonText={mode==='puntapunta' ? 'Exchanges' : 'Bookmakers 2/3'}
                                    buttonWidth={mode==='puntapunta' ? 110 : 125}
                                    buttonHighlighted={sites2AltSelected>0 && sites2AltSelected<sites2AltChecked.length}
                                    button={sites2AltSelected===1 ? <SiteBox checked={true} width={mode==='puntapunta' ? 110 : 125} height={36}><SiteLogoContainer visible={true} backgroundColor={sites2Alt[Math.max(0, sites2AltChecked.findIndex(Boolean))].color}><SiteLogo width='75px' imageUrl={sites2Alt[Math.max(0, sites2AltChecked.findIndex(Boolean))].imageUrl}/></SiteLogoContainer></SiteBox> : undefined}
                                    content={
                                        <DropdownContent width={175}>
                                            <SelectAll onClick={() => setSites2AltChecked(new Array(sites2Alt.length).fill(sites2AltSelected===0))}>
                                                {sites2AltSelected===0 ? 'Seleziona tutti' : 'Deseleziona tutti'}
                                            </SelectAll>
                                            <SitesCheckboxes>
                                                <CheckboxesGroup options={ sites2Alt.map((site, index) =>  <SiteBox checked={sites2AltChecked[index]} name={site.name}><SiteLogoContainer visible={sites2AltChecked[index]} backgroundColor={site.color}><SiteLogo imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>) } checked={sites2AltChecked} onOptionClick={(checked: boolean[]) => setSites2AltChecked(checked)}/>
                                            </SitesCheckboxes>
                                        </DropdownContent>
                                    }
                                />
                                {mode === 'puntabanca' &&
                                    <Dropdown 
                                        buttonText={'Numero esiti'}
                                        buttonWidth={105}
                                        buttonHighlighted={typesSelected>0 && typesSelected<typesChecked.length}
                                        content={
                                            <DropdownContent width={83}>
                                                <StandardCheckboxesContainer gridTemplateColumns='1fr'>
                                                    <CheckboxesGroup options={ ['2 Esiti', '3 Esiti'].map((_type, index) =>  <StandardCheckbox checked={typesChecked[index]} backgroundColor='dodgerblue'>{_type}</StandardCheckbox>) } checked={typesChecked} onOptionClick={(checked: boolean[]) => setTypesChecked(checked)}/>
                                                </StandardCheckboxesContainer>
                                            </DropdownContent>
                                        }
                                    />
                                }
                                {mode==='puntapunta' && <NumberInput label='Liquidità' value={available.value} minValue={0} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setAvailable(newState)}/>}
                            </Row>
                        </Row>
                    </Col>
                </BaseFieldset>
            }
            <Row gap='5px' mainAxis='center' crossAxis='flex-start' flexWrap='wrap'>
                <BaseFieldset legend="Filtri" padding={10} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' fitContent={true}>
                    <ResetButton onClick={resetFilters}/>
                    <FiltersContainer>
                        <Row gap='5px' mainAxis='center' flexWrap='wrap'>
                            <SportsContainer>
                                {/* <SportsLabel>Sport</SportsLabel> */}
                                <CheckboxesGroup options={ sports.map((sport, index) =>  <Sport checked={sportsChecked[index]} backgroundColor={sport.backgroundColor}>{sport.icon}</Sport>) } checked={sportsChecked} onOptionClick={(checked: boolean[]) => setSportsChecked(checked)}/>
                            </SportsContainer>
                            <Dropdown 
                                buttonText={mode==='puntabanca' ? 'Bookmakers' : 'Bookmakers 1'}
                                buttonWidth={mode==='puntabanca' ? 110 : 125}
                                buttonHighlighted={sites1Selected>0 && sites1Selected<sites1Checked.length}
                                button={sites1Selected===1 ? <SiteBox checked={true} width={mode==='puntabanca' ? 110 : 125} height={36}><SiteLogoContainer visible={true} backgroundColor={sites1[Math.max(0, sites1Checked.findIndex(Boolean))].color}><SiteLogo width='75px' imageUrl={sites1[Math.max(0, sites1Checked.findIndex(Boolean))].imageUrl}/></SiteLogoContainer></SiteBox> : undefined}
                                content={
                                    <DropdownContent width={175}>   
                                        <SelectAll onClick={() => setSites1Checked(new Array(sites1.length).fill(sites1Selected===0))}>
                                            {sites1Selected===0 ? 'Seleziona tutti' : 'Deseleziona tutti'}
                                        </SelectAll>
                                        <SitesCheckboxes>
                                            <CheckboxesGroup options={ sites1.map((site, index) => <SiteBox checked={sites1Checked[index]} name={site.name}><SiteLogoContainer visible={sites1Checked[index]} backgroundColor={site.color}><SiteLogo  imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>) } checked={sites1Checked} onOptionClick={(checked: boolean[]) => setSites1Checked(checked)}/>
                                        </SitesCheckboxes>
                                    </DropdownContent>
                                }
                            />
                            <Dropdown 
                                buttonText={mode==='puntabanca' ? 'Exchanges' : 'Bookmakers 2/3'}
                                buttonWidth={mode==='puntabanca' ? 110 : 125}
                                buttonHighlighted={sites2Selected>0 && sites2Selected<sites2Checked.length}
                                button={sites2Selected===1 ? <SiteBox checked={true} width={mode==='puntabanca' ? 110 : 125} height={36}><SiteLogoContainer visible={true} backgroundColor={sites2[Math.max(0, sites2Checked.findIndex(Boolean))].color}><SiteLogo width='75px' imageUrl={sites2[Math.max(0, sites2Checked.findIndex(Boolean))].imageUrl}/></SiteLogoContainer></SiteBox> : undefined}
                                content={
                                    <DropdownContent width={175}>
                                        <SelectAll onClick={() => setSites2Checked(new Array(sites2.length).fill(sites2Selected===0))}>
                                            {sites2Selected===0 ? 'Seleziona tutti' : 'Deseleziona tutti'}
                                        </SelectAll>
                                        <SitesCheckboxes>
                                            <CheckboxesGroup options={ sites2.map((site, index) =>  <SiteBox checked={sites2Checked[index]} name={site.name}><SiteLogoContainer visible={sites2Checked[index]} backgroundColor={site.color}><SiteLogo imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>) } checked={sites2Checked} onOptionClick={(checked: boolean[]) => setSites2Checked(checked)}/>
                                        </SitesCheckboxes>
                                    </DropdownContent>
                                }
                            />
                            <Dropdown 
                                buttonText={'Mercati'}
                                buttonWidth={mode==='puntabanca' ? 75 : 90}
                                buttonHighlighted={marketsSelected>0 && marketsSelected<marketsChecked.length}
                                content={
                                    <DropdownContent>
                                        <SelectAll onClick={() => setMarketsChecked(new Array(markets.length).fill(marketsSelected===0))}>
                                            {marketsSelected===0 ? 'Seleziona tutti' : 'Deseleziona tutti'}
                                        </SelectAll>
                                        <StandardCheckboxesContainer>
                                            <CheckboxesGroup options={ markets.map((market, index) =>  <StandardCheckbox checked={marketsChecked[index]} backgroundColor='#3672ff'>{market}</StandardCheckbox>) } checked={marketsChecked} onOptionClick={(checked: boolean[]) => setMarketsChecked(checked)}/>
                                        </StandardCheckboxesContainer>
                                    </DropdownContent>
                                }
                            />
                            {mode === 'puntabanca' &&
                                <Dropdown 
                                    buttonText={'Commissioni'}
                                    buttonWidth={100}
                                    buttonHighlighted={feesBetfair.value!=='4.5' || feesBetflag.value!=='5'}
                                    content={
                                        <DropdownContent width={150}>
                                            <FeesContainer>
                                                <NumberInput label='Betfair' value={feesBetfair.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFeesBetfair(newState)}/>
                                                <NumberInput label='Betflag' value={feesBetflag.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFeesBetflag(newState)}/>
                                            </FeesContainer>
                                        </DropdownContent>
                                    }
                                />
                            }
                            <Dropdown 
                                buttonText={'Blacklist'}
                                buttonWidth={mode==='puntabanca' ? 75 : 90}
                                buttonHighlighted={blacklist.length>0}
                                position='static'
                                right={0}
                                left={0}
                                contentMaxWidth='100%'
                                content={blacklist.length>0 
                                    ? 
                                        <DropdownContent maxWidth='100%'>
                                            <BlacklistContainer>
                                                <BlackListTitle>Blacklist quote</BlackListTitle>
                                                {
                                                    blacklist.map((element, index) => {
                                                        const site = sites.get(element.site.id);
                                                        const event = events.get(element.event.id);
                                                        return(
                                                            <BlacklistElement key={index}>
                                                                <SiteBox checked={true} width={isAppMobile ? 50 : 60} notClickable={element.url===undefined} onClick={() => element.url && copyToClipboard(element.url, `URL ${site?.name} copiato`)}><SiteLogoContainer visible={true} backgroundColor={site?.color}><SiteLogo width='95%' imageUrl={site?.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                <BlacklistElementSelection>{element.type}</BlacklistElementSelection>
                                                                <BlacklistElementEvent>{event ? event.home?.name+' - '+event.away?.name : undefined}</BlacklistElementEvent>
                                                                <SVGIconContainer><TrashIconSVG onClick={() => removeFromBlacklist([index])}/></SVGIconContainer>
                                                            </BlacklistElement>
                                                        );
                                                    }) 
                                                }
                                            </BlacklistContainer>
                                        </DropdownContent>
                                    : 
                                    undefined
                                }
                            />
                        </Row>
                        <Row gap='5px' mainAxis='center' flexWrap='wrap'>
                            {mode === 'puntapunta' &&
                                    <Dropdown 
                                        buttonText={'Numero esiti'}
                                        buttonWidth={105}
                                        buttonHighlighted={typesSelected>0 && typesSelected<typesChecked.length}
                                        content={
                                            <DropdownContent width={83}>
                                                <StandardCheckboxesContainer gridTemplateColumns='1fr'>
                                                    <CheckboxesGroup options={ ['2 Esiti', '3 Esiti'].map((_type, index) =>  <StandardCheckbox checked={typesChecked[index]} backgroundColor='dodgerblue'>{_type}</StandardCheckbox>) } checked={typesChecked} onOptionClick={(checked: boolean[]) => setTypesChecked(checked)}/>
                                                </StandardCheckboxesContainer>
                                            </DropdownContent>
                                        }
                                    />
                            }
                            <NumberInput label={(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF' : 'Rating'} value={rating.value} negative={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setRating(newState)}/>
                            {mode==='puntabanca' && <NumberInput label='Liquidità' value={available.value} minValue={0} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setAvailable(newState)}/>}
                            <OddsRange oddsMinValue={oddsMin} oddsMaxValue={oddsMax} onOddsMinChange={(newOdds?: number) => setOddsMin(newOdds)} onOddsMaxChange={(newOdds?: number) => setOddsMax(newOdds)}/>
                            <DatesRange dateMinValue={dateMin} dateMaxValue={dateMax} onDateMinChange={(newDate?: Date) => setDateMin(newDate)} onDateMaxChange={(newDate?: Date) => setDateMax(newDate)}/>
                        </Row>
                        <EventAndOrderContainer>
                            <TextInputWithAutocomplete label={isAppMobile ? 'Ev./Comp.' : 'Evento/Competizione'} labelWidth={isAppMobile ? 90 : undefined} value={searchText} possibleValues={eventsAndLeagues} listAllWidth={true} inputPadding={5} onChange={handleSearchInputChange} specialIcon={<TrophyIconSVG/>} specialText="Competizioni principali"/>
                            <OrderChoiceContainer>
                                <OrderChoiceLabel>Ordina per</OrderChoiceLabel>
                                <SwitchSelectorBig isOption1Active={order==='rating'} mode='standard' option1={(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF' : 'Rating'} option2='Data' width={120} onSwitch={(isOption1Active: boolean) => setOrder(isOption1Active ? 'rating' : 'date')}/>
                            </OrderChoiceContainer>
                        </EventAndOrderContainer>
                    </FiltersContainer>
                </BaseFieldset>
                <Col gap='9px'>
                    <BaseFieldset legend="Informazioni puntata" padding={10} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' fitContent={true}>
                        <ResetButton onClick={resetBetInfo}/>
                        <Row gap='5px' mainAxis='center' crossAxis='flex-start' flexWrap='wrap'>
                            <Col gap='5px' mainAxis='center'>
                                <NumberInput label='Puntata' value={bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBet(newState)}/>
                                <NumberInput label='di cui Bonus' value={isFreebet ? bet.value : bonus.value} isDisabled={isFreebet} minValue={0} maxValue={parseFloat(bet.value) || 0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBonus(newState)}/>
                            </Col>
                            <Col gap='5px' mainAxis='center'>
                                <NumberInput label='Rimborso' value={refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} isDisabled={isFreebet} isDisabledOpacity={0} onChange={(newState: NumberInputState) => setRefund(newState)}/>
                                <SwitchSelector isFilterActive={isFreebet} width={150} height={36} filterHandler={handleIsFreebetChange} label="Freebet"/>
                            </Col>
                        </Row>
                    </BaseFieldset>
                    <SearchButton onClick={onSearchClick}>Cerca</SearchButton>
                </Col>
            </Row>
        </OddsScasserFilterContainer>
        
    );
}

export default OddsScasserFilter;