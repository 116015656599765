import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//redux
import { enableAppMain } from 'redux/actions/actions-app-main';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//services
import { getCoursePayment, subscriptionLibertyPayment } from 'services/payments.services';
import { getUserData } from 'services/user.services';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import LoadingMask from 'components/ui/LoadingMask';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as LibertyIconSVG } from './assets/libertyIcon.svg';
import { ReactComponent as VipIconSVG } from './assets/vipIcon.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    LibertyTitleBox,
    LibertyTitle,
    LibertyTextBox,
    LibertyText,
    LibertyButton
} from './style/ServiceLibertyPaymentModal.style';
import { removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';
import { getPaypalUrl } from 'utils/paypal';
import ProductRequisitesModal from 'components/ProductRequisitesModal/ProductRequisitesModal.component';
import { formattedCurrency } from 'utils/supportFunctions';


/**
 * Lyberty Payment modal
 * 
 * @author Piero Foschi
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */

const ServiceLibertyModal: FC<{
    onCloseButtonModal?: () => any,
    libertyPrice?: number,
    serviceID: number,
    courseElement: any,
    isAnnual: boolean,
    vipPoints: number,
    trialVipPoint: number,
    isServicePageRequest?: boolean,
    refreshFunction?: () => void
 }> = ({
    onCloseButtonModal,
    libertyPrice,
    serviceID,
    courseElement,
    isAnnual,
    vipPoints,
    // trialVipPoint,
    isServicePageRequest,
    refreshFunction
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [requisites, setRequisites] = useState([]);
    const [requisitesLogicalOperator, setRequisitesLogicalOperator] = useState<'AND' | 'OR'>('AND');
    const [isProductRequisitesModalVisible, setIsProductRequisitesModalVisible] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {productService} = courseElement;

    const paymentPlan = productService?.paymentPlan;
    const singlePrice = paymentPlan?.price;
    const trialVipPoints = paymentPlan?.trialVipPoints;
    const trialMonths = paymentPlan?.trialMonths;
    const moreMouth: number = paymentPlan?.frequency;

    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const closeProductRequisitesModal = () => setIsProductRequisitesModalVisible(false);
    const openRequisiteModal = () => setIsProductRequisitesModalVisible(true);

    const serviceLibertyPaymentHandler = async () => {

        try {      
            setIsLoading(true);
            await subscriptionLibertyPayment(serviceID,true);
            dispatch(enableAppMain());

            dispatch( getUserData() );
            if (isServicePageRequest) navigate('/reservedArea/myservices');

            const snackbarData: SnackbarOptions = {
                type: 'success',
                message: "Il tuo abbonamento in punti è stato attivato con Successo!"
            };
              
            dispatch(addSnackbar(snackbarData));
            if(refreshFunction) refreshFunction();
            setIsLoading(false);
            if(onCloseButtonModal) onCloseButtonModal();
            return;
        } catch (error: any) {
            setIsLoading(false);
            console.warn("ERROR:", error.response);

            if ((error.response) && (error.response.data) && (error.response.data.message === "Requisites not satisfied")) {               
                const requisiteProducts = error.response.data.error;
                setRequisites(requisiteProducts);
                openRequisiteModal();
                return;
            }

            let snackbarData: SnackbarOptions = {
                type: 'info',
                message: "Questo Prodotto non è acquistabile al momento, per maggiori info controlla la pagina del prodotto",
            };
          
            //   if (error.message === 'Max date for this product exceeded') {
            //     snackbarData = {
            //       type: 'info',
            //       message:
            //         "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo",
            //     };
            // }

            dispatch(addSnackbar(snackbarData));
            if(onCloseButtonModal) onCloseButtonModal();
            dispatch( enableAppMain() );
            // navigate('/reservedArea/shop');
        }
    };

    const isTrial = (trialMonths !== 0);
    const isPackMouths = (moreMouth > 1);
    const isSingolMouth = (!isAnnual && !isTrial && !isPackMouths)

    return (
        <>
            <BaseModal 
                onClose={onCloseButtonModal}
                isCloseButtonClicked={isCloseButtonClicked}
                isBackdropClickActive
            >
                {!isAppMobile && (
                    <Row 
                        padding='0 21px 12px 0'
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <BaseCard 
                    flavor='outlined'
                    isModal
                    >
                    <CardContent>
                    {isLoading && (
                        <LoadingMask 
                            paddingTop='200px'
                            isCenter
                            size='page'
                        />
                    )}

                    {!isLoading && 
                    <>
                        {isAppMobile && (
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={closeButtonHandler}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                        )}

                        <LibertyTitleBox>
                            <LibertyTitle>ABBONATI E RICEVI PUNTI VIP</LibertyTitle>
                        </LibertyTitleBox>

                        <LibertyTextBox>
                            {isSingolMouth &&
                            <LibertyText>
                                Ogni mese utilizza {libertyPrice}<LibertyIconSVG /> Punti Libertà e ricevi {vipPoints} <VipIconSVG /> Punti VIP.
                            </LibertyText>}

                            {isPackMouths &&
                           <LibertyText>
                                Ogni {moreMouth} mesi utilizza {libertyPrice}<LibertyIconSVG /> Punti Libertà e ricevi {vipPoints} <VipIconSVG /> Punti VIP.   
                            </LibertyText>}

                            {isTrial &&
                            <LibertyText>
                                {trialMonths > 1 ? `I primi ${trialMonths} mesi` : `Il primo mese`} utilizza {libertyPrice}<LibertyIconSVG /> Punti Libertà e ricevi {trialVipPoints} <VipIconSVG /> Punti VIP.
                                <br/>
                                <br/>
                                Poi utilizza ogni mese {formattedCurrency(singlePrice / 1.22)}<LibertyIconSVG /> Punti Libertà e ricevi {vipPoints} <VipIconSVG /> Punti VIP.    
                            </LibertyText>}
                        </LibertyTextBox>

                        <LibertyButton onClick={serviceLibertyPaymentHandler}>CONFERMA</LibertyButton>
                    </>
                    }
                    </CardContent>
                </BaseCard>
            </BaseModal>
            
            {isProductRequisitesModalVisible &&
                <ProductRequisitesModal
                    onCloseButtonModal={closeProductRequisitesModal}
                    requisites={requisites}
                    requisitesLogicalOperator={requisitesLogicalOperator}
                />
            }
        </>
    );
};

export default ServiceLibertyModal;
