import { FC, useCallback, useEffect, useState } from 'react';

//components
import Dropdown from 'components/Dropdown/Dropdown.component';
import NumberInput from 'components/ui/NumberInput/NumberInput.component';
import DoubleSlider from 'components/DoubleSlider/DoubleSlider.component';

//assets
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';

//types
import { OddsRangeProps } from './OddsRange.component.d';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';

//style
import {OddsLimit, OddsLimitLabel, OddsRangeButtonContainer, OddsRangeContainer, OddsRangeLabel, OddsRangeLabels, OddsRangeOption, OddsRangeOptionsContainer, OddsRangeValues, DoubleSliderContainer} from './style/OddsRange.style'
import { DropdownContent, HorizontalScrollContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

//helper functions
import { oddsStepsArray, stepOdds } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import { Col, Row } from 'style/layout';


const  OddsRange: FC<OddsRangeProps> = ({oddsMinValue, oddsMaxValue, onOddsMinChange, onOddsMaxChange}) => {
    
    const [oddsSteps, setOddsSteps] = useState<number[]>([]);
    const [oddsMin, setOddsMin] = useState<NumberInputState>({value: '', errorMessage: 'NaN'});
    const [oddsMax, setOddsMax] = useState<NumberInputState>({value: '', errorMessage: 'NaN'});

    const [oddsMinSliderValue, setOddsMinSliderValue] = useState<number>(0);
    const [oddsMaxSliderValue, setOddsMaxSliderValue] = useState<number>(0);

    // Get slider value
    const getSliderValue = useCallback(
        (value: number | undefined, mode: 'min'|'max') => {
            if(oddsSteps.length === 0) return 0;
            if(value) {
                if(mode === 'min') {
                    for (let i = 0; i < oddsSteps.length; i++) {
                        if(oddsSteps[i]+0.001 >= value) return i;
                    }
                } else {
                    for (let i = oddsSteps.length-1; i >= 0; i--) {
                        if(oddsSteps[i]-0.001 <= value) return i;
                    }
                }
            }
            return mode === 'min' ? 0 : oddsSteps.length-1;
        }, [oddsSteps]
    );

    useEffect(() => {
        const arr = oddsStepsArray(30)
        setOddsSteps(arr);
        setOddsMaxSliderValue(arr.length-1);
    }, [])

    useEffect(() => {
        handleOddsMinEditFinished({value: oddsMinValue?.toFixed(2) ?? ''});
    }, [oddsMinValue])

    useEffect(() => {
        handleOddsMaxEditFinished({value: oddsMaxValue?.toFixed(2) ?? ''});
    }, [oddsMaxValue])

    const handleOddsMinEditFinished = (newState: NumberInputState) => {
        if(oddsSteps.length === 0) return;
        const newValue = newState.errorMessage ? undefined : parseFloat(newState.value);
        //Set slider value
        const sliderValue = getSliderValue(newValue, 'min');
        if(sliderValue>=oddsMaxSliderValue) setOddsMin({value: oddsSteps[oddsMaxSliderValue-1].toFixed(2)});
        else {
            setOddsMinSliderValue(sliderValue);
            onOddsMinChange(newValue);
        }
    }

    const handleOddsMaxEditFinished = (newState: NumberInputState) => {
        if(oddsSteps.length === 0) return;
        const newValue = newState.errorMessage ? undefined : parseFloat(newState.value);
        //Set slider value
        const sliderValue = getSliderValue(newValue, 'max');
        if(sliderValue<=oddsMinSliderValue) setOddsMax({value: oddsSteps[oddsMinSliderValue+1].toFixed(2)});
        else {
            setOddsMaxSliderValue(sliderValue);
            onOddsMaxChange(newValue);
        }
    }

    const handleSliderMinChange = (newVal: number) => {
        if(oddsSteps.length>0) {
            setOddsMinSliderValue(newVal);
            setOddsMin({value: newVal>0 ? oddsSteps[newVal].toFixed(2) : ""});
            onOddsMinChange(newVal>0 ? oddsSteps[newVal] : undefined);
        }
    };

    const handleSliderMaxChange = (newVal: number) => {
        if(oddsSteps.length>0) {
            setOddsMaxSliderValue(newVal);
            setOddsMax({value: newVal<oddsSteps.length-1 && newVal>0 ? oddsSteps[newVal].toFixed(2) : ""});
            onOddsMaxChange(newVal<oddsSteps.length-1 && newVal>0 ? oddsSteps[newVal] : undefined);
        }
    };

    const changeRange = (min: string, max: string) => {
        //Min
        setOddsMin({value: min});
        const newMin = !min ? undefined : parseFloat(min);
        setOddsMinSliderValue(getSliderValue(newMin, 'min'));
        onOddsMinChange(newMin);
        //Max
        setOddsMax({value: max});
        const newMax = !max ? undefined : parseFloat(max);
        setOddsMaxSliderValue(getSliderValue(newMax, 'max'));
        onOddsMaxChange(newMax);
    }
    
    return(
        <Dropdown 
            position='static'
            button={
                <OddsRangeButtonContainer>
                    <OddsRangeLabel>Quota</OddsRangeLabel>
                    <OddsRangeLabels>
                        <OddsLimitLabel>da</OddsLimitLabel>
                        <OddsLimitLabel>a</OddsLimitLabel>
                    </OddsRangeLabels>
                    <OddsRangeValues>
                        <OddsLimit>{oddsMinValue ? oddsMinValue.toFixed(2) : '—'}</OddsLimit>
                        <OddsLimit>{oddsMaxValue ? oddsMaxValue.toFixed(2) : '—'}</OddsLimit>
                    </OddsRangeValues>
                </OddsRangeButtonContainer>
            }
            right={0}
            left={0}
            contentMaxWidth='100%'
            content={
                <DropdownContent maxWidth='100%'>
                    <Col gap='10px' width='100%'>
                        <Row gap='5px'>
                            <NumberInput value={oddsMin.value} allowEmpty={true} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOdds} onChange={(newState: NumberInputState) => setOddsMin(newState)} onEditFinished={handleOddsMinEditFinished}/>
                            <HorizontalScrollContainer hiddenScrollBar={true}>
                                <OddsRangeOptionsContainer>
                                    <OddsRangeOption tabIndex={-1} onClick={() => changeRange("","")}><TrashIconSVG/></OddsRangeOption>
                                    <OddsRangeOption tabIndex={-1} onClick={() => changeRange("1.50","5.00")}>1.50-5.00</OddsRangeOption>
                                    <OddsRangeOption tabIndex={-1} onClick={() => changeRange("2.00","5.00")}>2.00-5.00</OddsRangeOption>
                                    <OddsRangeOption tabIndex={-1} onClick={() => changeRange("3.00","")}>3.00+</OddsRangeOption>
                                    <OddsRangeOption tabIndex={-1} onClick={() => changeRange("","5.00")}>5.00-</OddsRangeOption>
                                </OddsRangeOptionsContainer>
                            </HorizontalScrollContainer>
                            <NumberInput value={oddsMax.value} allowEmpty={true} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOdds} onChange={(newState: NumberInputState) => setOddsMax(newState)} onEditFinished={handleOddsMaxEditFinished}/>
                        </Row>
                        <DoubleSliderContainer>
                            {oddsSteps.length>0 && <DoubleSlider min={0} max={oddsSteps.length-1} startingMin={oddsMinSliderValue} startingMax={oddsMaxSliderValue} onMinChange={handleSliderMinChange} onMaxChange={handleSliderMaxChange}/>}
                        </DoubleSliderContainer>
                    </Col>
                </DropdownContent>
            }
        />        
    );
}

export default OddsRange;