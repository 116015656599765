import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette/palette';
import styled, { css, keyframes } from 'styled-components';

export const Carousel = styled.div`
	position: relative;
	width: 100%;
	height: 340px;
	display: flex;
	justify-content: center;
	/* align-items: center; */

	@media ${devices.desktop} {
		height: 466px;
	}
`;

export const CardWrapper = styled.div`
	width: 90%;
`;

export const SliderWrapper = styled.div`
	display: flex;
	transition: transform 0.5s ease-in-out;
`;

export const TestimonialCard = styled.div<{ depth: number; isActive: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 20px;
	position: relative;
	transform: translateZ(${(props) => props.depth * -50}px)
		scale(${(props) => (props.isActive ? 1 : 0.8)});
	opacity: ${(props) => (props.isActive ? 1 : 0.5)};
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

	&:hover {
		transform: translateZ(${(props) => props.depth * -50}px) scale(1.1);
		opacity: 1;
		z-index: 2;
	}
`;

export const CardFront = styled.div`
	height: 200px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const CardBack = styled.div`
	padding: 20px;
	background-color: #f7f7f7;

	img {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		margin-bottom: 20px;
	}

	h4 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	p {
		font-size: 14px;
		line-height: 1.5;
		color: #666;
	}
`;

export const PrevButton = styled.button`
	/* position: absolute; */
	/* top: 50%; */
	/* left: 0; */
	/* transform: translateY(-50%); */
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* transition: all 0.3s ease; */
`;

export const NextButton = styled.button`
	/* position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); */
	background-color: transparent;
	border: none;
	cursor: pointer;
	/* transition: all 0.3s ease; */

	&:hover {
		color: #333;
	}

	${(props: { disabled?: boolean }) =>
		props.disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.5;
			&:hover {
				color: #ccc;
			}
		`}
`;

export const Dots = styled.div<{ isIMGTestimonials?: boolean }>`
	display: flex;
	align-items: center;
	gap: 1rem;

	${({ isIMGTestimonials }) =>
		isIMGTestimonials &&
		css`
			margin: 20px 0 0;

			@media ${devices.tablet} {
				margin: 80px 0 0;
			}
		`}
`;

export const Dot = styled.div<{ cIndex: number }>`
	position: absolute;
	width: 7px;
	width: 7px;
	border-radius: 50%;
	transition: background-color 1s ease-out, transform 1s ease-out;

	${({ cIndex }) =>
		cIndex !== undefined &&
		css`
			width: calc(7px - 1.5 * ${Math.abs(cIndex)}px);
			height: calc(7px - 1.5 * ${Math.abs(cIndex)}px);
			transform: translateX(calc(15 * ${cIndex}px));
			background-color: rgba(
				255,
				255,
				255,
				calc(1 - 0.3 * ${Math.abs(cIndex)})
			); // Adjust opacity as desired
		`}
`;
