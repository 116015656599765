import { FC } from 'react';

//component
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

//assets
import { ReactComponent as InfoIconSVG } from '../assets/infoIcon.svg';

//style
import {
    ProductGeneralBox,
    ProductTitle,
    ProductBox,
    CourseImage,
    ProductInfoBox,
    ProductIconBox,
    InfoIconBox,
    ProductDescriptionBox,
    ProductDesciptionEllipsis,
    ProductDesciption
} from './style/CourseBox.style';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { enableAppMain } from 'redux/slices/slice-app-main';
import store from 'redux/store';


/**
 * Course Box
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

 const CourseBox: FC<{
    courseElement: any,
    onInfoButtonClick: (productId: string, productName: string, productPrice: string) => void,
    onTransferClick: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    openBankTransferModal: () => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    libertyProductID: (courseID: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    isRequisite?: boolean
}> = ({
    courseElement,
    onInfoButtonClick,
    onTransferClick,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    loadingOn,
    loadingOff,
    libertyProductPrice,
    isRequisite
}) => {

    const navigate=useNavigate();
    const dispatch = useAppDispatch();
    const {name, salesData, sellImage, productCourse, id, paymentTypes} = courseElement;
    const brandCode = store.getState().ui.brand?.code;
    const productImage = sellImage? (sellImage.url ?? `https://api.ldl-test.eu/v1/productsforsell?brandCode=${brandCode}`) : `https://api.ldl-test.eu/v1/productsforsell?brandCode=${brandCode}`

    const finalDelivery = salesData !== undefined ? salesData.delivery : "";

    const infoButtonClickHandler = () => {
        if (isRequisite) {
            navigate(`/public/product/${id}`);
            dispatch(enableAppMain());
            return
        }
        onInfoButtonClick(id, name, `${productCourse.price}`);
    };

    return (
        <ProductGeneralBox>
            <ProductTitle>{name}</ProductTitle>

            <ProductBox>
                <ProductInfoBox>
                    <CourseImage alt='Confezione Corso' src={productImage}/>

                    <ProductIconBox>
                        <InfoIconBox onClick={infoButtonClickHandler}>
                            <InfoIconSVG />
                        </InfoIconBox>
                    </ProductIconBox>
                </ProductInfoBox>

                <ProductDescriptionBox>
                    <ProductDesciptionEllipsis>
                        <ProductDesciption dangerouslySetInnerHTML={{__html: finalDelivery}} />
                    </ProductDesciptionEllipsis>

                    <ButtonShop 
                        PriceProduct={productCourse.price} 
                        TypeOfProduct="COURSE" 
                        courseElement={courseElement} 
                        courseID={id} 
                        payments={paymentTypes}
                        onTransferClick={onTransferClick}
                        openBankTransferModal={openBankTransferModal}
                        onLibertyClick={onLibertyClick}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        productIdHandler = {libertyProductID}
                        productPriceHandler = {libertyProductPrice}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                    />
                </ProductDescriptionBox>
            </ProductBox>
        </ProductGeneralBox>
    );
 };

 export default CourseBox;