import styled from 'styled-components';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';
import { Form } from '../../../style/FormEditAccount.style'

export const ConfirmCodeFormBox = styled(Form)`

    margin: 0;

    justify-content: space-between;
`;

export const VerifyCodeButtonBox =styled.div`

    min-width: 180.45px;

    flex-shrink: 0;
    text-align: right;
`;

export const VerifyCodeButton = styled(Button)`

    padding-left: 25px;
    padding-right: 25px;

    font-size: 15px;

    @media ${devices.tablet} {

        padding-left: 25px;
        padding-right: 25px;

        font-size: 15px;
    }
`;
