import styled, { css } from 'styled-components';

// types
import { VisibleCheckboxProps } from './CheckBoxInput.style.d';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Label = styled.label`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: 7px;

    font-size: 15px;
    font-weight: 400;

    color: ${colors.white};

    position: relative;
    cursor: pointer;

    @media ${devices.tablet} {

        gap: 11px;
    }
`;

export const Input = styled.input`

    width: 0;
    height: 0;

    position: absolute;
    opacity: 0;
`;

export const VisibleCheckbox = styled.span<VisibleCheckboxProps>`

    width: 15px;
    height: 15px;

    padding: 0 0 3px;

    border-radius: 2px;
    border: 2px solid ${colors.white};
    background: ${colors.transparent};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    :after {

        content: "";
        display: none;

        width: 6.5px;
        height: 10px;

        border: solid ${colors.black};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    ${Input}:checked ~ & {

        background: ${colors.primaryColor};
        border: none;

        &:after {

            display: block;
        }
    }

    ${({isError}) => isError && (
        css`
            border-color: ${colors.red};
        `
    )}
`;
