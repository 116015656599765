import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const ContentCourseBorder = styled.div`

    display: flex;

    width: 100%;

    border-bottom: 1px solid ${colors.grey};

    @media ${devices.tablet} {
        
        width: 200px;
}
`;

export const MyCourse = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 5px;

    width: 142.5px;
    height: 200px;;

    margin: 25px 0 0;

    @media ${devices.tablet} {
        
        width: 200px;
}
`;

export const Title = styled.p`

    margin: 15px 0;

    min-height: 40px;
    
    padding: 0;

    font-weight: 500;
    font-size: 15px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.white};
    text-decoration: none;

    @media ${devices.tablet} {
        
        margin: 15px 0 35px;
}
`;

export const ImageCourse = styled.img`

    height: 120px;

    @media ${devices.tablet} {
        
        height: 150px;
}
`;