import { FC, ChangeEvent, useEffect, useState, useRef } from 'react';

// types
import { TextInputWithLabelProps } from './TextInputWithLabel.component.d';

// style
import {
    InputLabelBox,
    Label,
    Input
} from './style/TextInputWithLabel.style';


const TextInputWithLabel: FC<TextInputWithLabelProps> = ({
        label,
        value,
        isDisabled,
        allowEmpty,
        onChange,
        labelWidth,
        inputWidth,
        gap,
        height,
        width,
        textAlign
    }) => {

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [focus, setFocus] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        validate(event.target.value);
    };

    useEffect(() => {
        validate(value);
    }, [value]);

    const validate = (newValue: string) => {
        const newErrorMessage = newValue.length === 0 && !allowEmpty ? 'empty' : undefined;
        setErrorMessage(newErrorMessage);
        (value !== newValue || errorMessage !== newErrorMessage) && onChange && onChange({value: newValue, errorMessage: newErrorMessage});
    };

    const handleLabelClick = () => {
        if(!isDisabled && inputRef.current) {
            inputRef.current.select();
            setFocus(true);
        }
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if(!isDisabled) {
            event.target.select();
            setFocus(true);
        }
    }

    return (
        <InputLabelBox onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} hover={hover} focus={focus} isDisabled={isDisabled} label={label} labelWidth={labelWidth} inputWidth={inputWidth} height={height} width={width} gap={gap}>
            {label && <Label isInputInvalid={!!errorMessage} focus={focus} label={label} labelWidth={labelWidth} inputWidth={inputWidth} gap={gap} isDisabled={isDisabled} onClick={handleLabelClick}>{label}</Label>}
            <Input ref={inputRef} type='text' value={value} readOnly={isDisabled} isDisabled={isDisabled} isInputInvalid={!!errorMessage} label={label} labelWidth={labelWidth} inputWidth={inputWidth} width={width} gap={gap} textAlign={textAlign} onChange={inputChangeHandler} onFocus={handleFocus}/>
        </InputLabelBox>
    );
};

TextInputWithLabel.defaultProps = {
    label: '',
    value: '',
    isDisabled: false
};

export default TextInputWithLabel;
