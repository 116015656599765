// asset
import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";

import styled from "styled-components";


export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 10px 20px 0px;
    gap: 10px;

    width: 100%;

    @media ${devices.tablet}{
        
        max-width: 350px;  
        max-height: calc(100vh - 200px);
        padding: 0px;
        
        border-radius: 15px;
        overflow: auto;

        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
        position: fixed;
    }
`;

export const Id = styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-end;

    padding: 0px 0px 0px 10px;
    gap: 10px;

    @media ${devices.tablet} {

    }
`;

export const IdName = styled.p`

    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 17px;
    
    margin: 0px;
    padding: 0px;

    color: ${colors.white070};

    @media ${devices.tablet} {

    }
`;

export const IdNot = styled.div`

    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};

    @media ${devices.tablet} {

    }
`;

export const InputGrid = styled.div`

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    padding: 5px 2px ;
    gap: 20px;
    
    width: 100%;
    
    @media ${devices.tablet} {
        
}`;

export const Input = styled.input`
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 18px 10px;

    color: ${colors.white};

    width: 100%;

    border: 1px solid ${colors.white};
    border-radius: 100px;

    background: none;

    @media ${devices.tablet} {
        
}`;

export const CleanBox = styled.div`
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    cursor: pointer;
`;
