import styled from 'styled-components';
import { Button as AppButton } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Button = styled(AppButton)`

    width: auto;
    padding-right: 42px;
    padding-left: 38px;

    gap: 10px;

    font-size: 15px;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    color: ${colors.black087};

    > svg {

        max-width: 20px;

        height: 100%;
        max-height: 18px;
    }

    @media ${devices.tablet} {

        width: 100%;

        padding-right: 1.143em;
        padding-left: 1.143em;
    }
`;
