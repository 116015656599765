import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AppMainState {

    isMainActive: boolean,
    isFacebookExceptionModalVisible: boolean
};

// initial slice state
const initialState: AppMainState = {

    isMainActive: true,
    isFacebookExceptionModalVisible: false
};

const appMainSlice = createSlice(
    {
        name: 'appMain',
        initialState,

        reducers: {

            disableAppMain: (state) => {
                state.isMainActive = false;
            },

            enableAppMain: (state) => {
                state.isMainActive = true;
            },

            disableFacebookExceptioModal: (state) => {
                state.isFacebookExceptionModalVisible = false;
            },

            enableFacebookExceptioModal: (state) => {
                state.isFacebookExceptionModalVisible = true;
            },

            setIsMainActive: (state, action: PayloadAction<boolean>) => {
                state.isMainActive = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    
    disableAppMain,
    enableAppMain,
    disableFacebookExceptioModal,
    enableFacebookExceptioModal,
    setIsMainActive
} = appMainSlice.actions;

// reducer for store
export default appMainSlice.reducer;
