import { FC, useEffect, useState } from "react";

// hooks
import { useIsMounted } from "usehooks-ts";
import useAppSelector from "hooks/useAppSelector";

// utils
import { getCoursePayment } from "services/payments.services";
import { getPaypalUrl } from "utils/paypal";
import { formattedCurrency } from "utils/supportFunctions";
import { setItemToLocalStorage } from "utils/localStorage";
import { getEnvironmentProductId } from "configs/config.helper";

// component
import PaymentMenuHelper from "../PaymentMenuHelper/PaymentMenuHelper.component";
import EmptyImage from "components/EmptyImage";
import PartialLibertyPaymentModal from "components/PartialLibertyPaymentModal/PartialLibertyPaymentModal.component";

// assets
import { ReactComponent as ArrowPaymentIconSVG } from "./assets/arrowPaymentIcon.svg"
import { ReactComponent as PaypalIconSVG } from "./assets/PaypalIcon.svg"
import { ReactComponent as ArrowOpenPaymentSVG } from "./assets/arrowOpenPaymentIcon.svg"
import { ReactComponent as BankIconSVG } from "./assets/bankIcon.svg"
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';
import { ReactComponent as InfoIconSVG } from './assets/infoIcon.svg';

// style
import { 
    HelpCardS,
    Information,
    Intestation,
    LessonPhotoBox,
    Poster,
    Price,
    PriceButton,
    PriceGrid,
    PriceButtonText,
    PaypalPrice,
    HelpCards,
    IntestationDesktop,
    PosterDesktop,
    HelpCardDesktop,
    ButtonChoose,
    AutostradaDescription,
    Text,
    ProductIconBox,
    InfoIconBox,
    PriceButtonAutostrada
} from "./style/HelpCard.style";

const HelpCard: FC<{ 
    lesson: any, 
    transferModalHandler: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    libertyProductID?: (courseID: number) => void,
    productPriceHandler?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openBankTransferModal?: () => void,
    onInfoButtonClick: (productId: string, productName: string, productPrice: string) => void
}> = ({ 
    lesson, 
    transferModalHandler,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    productPriceHandler,
    loadingOn,
    loadingOff,
    openBankTransferModal,
    onInfoButtonClick
}) => {

    const { libertyPointsAvailableBalance } = useAppSelector(state => state.user)
    const  { isAppMobile } = useAppSelector(state => state.ui);
    const [isSelectorOpenActive, setIsSelectorOpenActive] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<'paypal' | 'bonifico'>("paypal");
    const [typePaymentTextCochButton, setTypePaymentTextCoachButton] = useState<string>("Euro");
    const isLibertyPointVisibal = (typePaymentTextCochButton === "Liberty");
    const isLibertyPointPartialVisibal = (typePaymentTextCochButton === "PartialLiberty");
    const isEuroVisibal = (typePaymentTextCochButton === "Euro");
    const [priceReturnFromBackend, setPriceReturnFromBackend] = useState<number>();
    const [isPartialLibertyPaymentModalVisible, setIsPartialLibertyPaymentModaVisible] = useState<boolean>(false);
    const [libertyPrice, setLibertyPrice] = useState<number>();
    const [partialLibertyPrice, setPartialLibertyPrice] = useState<number>();

    const isMounted = useIsMounted();

    const { name, paymentTypes, id } = lesson;
    const delivery = lesson.salesData?.delivery;

    
    const closePartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(false);
    const openPartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(true);  
    
    const infoButtonClickHandler = () => {

        onInfoButtonClick(id, name, `${lesson.productLessonPack.price}`);
    };
    
    const onClickSelectorOpenHandler = () =>{
        setIsSelectorOpenActive(prevState => !prevState);
    };

    const closeSelectorHandler = () => {
        setIsSelectorOpenActive(false);
    };

    const handlePaymentMethod = (paymentMethod:string) => {
        if (paymentMethod === "paypal") {
            setSelectedPayment("paypal");
        }
        if (paymentMethod === "bonifico") {
            setSelectedPayment("bonifico");
        }
    };

    const CalculateLibertyPrice = () => {
        const libertyValue = 1.22;
        const result = (lesson.productLessonPack.price / libertyValue)
        setLibertyPrice(+result.toFixed(2));
    };

    const CalculatePartialLibertyPrice = () => {
        const libertyValue = 1.22;
        const result = (lesson.productLessonPack.price - (+libertyPoints * libertyValue))
        setPartialLibertyPrice(+result.toFixed(2));
    };

    const typeOfPayment = () => {
        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "paypal")) {
            const paypal = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Paypal'));
            console.log("Paypal", paypal);
            return paypal.length > 0 ? paypal[0].id : null;
        };

        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "bonifico")) {
            const transfer = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Bonifico'));
            console.log("Bonifico", transfer);
            return transfer.length > 0 ? transfer[0].id : null;
        };

            const liberty = paymentTypes.filter((paymentType: any) => paymentType.name.includes('Punti'));
            console.log("Libertry", liberty);
            return liberty.length > 0 ? liberty[0].id : null;
    };

    const helpPaypal = async() => {
        const lessonID = lesson.id
        try {
            loadingOn();
            const response = await getCoursePayment(lesson.id, typeOfPayment());
            if (!isMounted) return;
            if (!response || response === undefined) return;
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);

            window.location.href = paypalUrl;
            if (lessonID === getEnvironmentProductId('autostrada')) setItemToLocalStorage('Autostrada', 'true');
            if ((lessonID !== getEnvironmentProductId('autostrada')) && (lessonID !== getEnvironmentProductId('assistente personale'))) setItemToLocalStorage('New Help', 'true')
        } catch (error: any) {
            loadingOff();
            console.warn("ERROR:", error);
        }
    };

    const helpTransfer = async() => {
        try {
            loadingOn();
            const response = await getCoursePayment(lesson.id, typeOfPayment());
            if (response.status !== 'CREATED') new Error ("Qualcosa è andato storto con la creazione dell'acquisto.");
            const createdTransactionGross = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].gross

            if (lesson.id === getEnvironmentProductId('assistente personale')) setItemToLocalStorage('Assistente', 'true');

            setPriceReturnFromBackend(createdTransactionGross);
            if(openBankTransferModal)openBankTransferModal();
            loadingOff();

            transferModalHandler(name, `${createdTransactionGross}`, response.id);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    

    const onHelpCardClickHandler = async () => {
        const lessonID = lesson.id

        if (helpPaymentPayPal) {
            helpPaypal();
        }

        if (helpPaymentTransfer) {
            helpTransfer();
        };
        
        if (coachPaymentLibertyShop) {
            if (libertyProductID) libertyProductID(lesson.id);
            if (productPriceHandler) productPriceHandler(lesson.productLessonPack.price);
            // openLibertyPaymentModal();
            onLibertyClick();
        };

        if (coachPaymentPartialLibertyShop) {
            openPartialLibertyPaymentModal();
        };
    };

    

    const lessonPhoto = ((lesson.sellImage === undefined ||
        (lesson.sellImage.url === null)) ?
        ( <EmptyImage /> ) : (
            <LessonPhotoBox>
                <Poster 
                    alt="Coach profile picture"
                    src={lesson.sellImage.url}
                />
            </LessonPhotoBox>));

useEffect (()=>{
    console.log("Liberty Point", libertyPoints);
    
        if (libertyPoints === 0) return setTypePaymentTextCoachButton("Euro");

        if ((libertyPoints * 1.22) < lesson.productLessonPack.price)
        {
            setTypePaymentTextCoachButton("PartialLiberty");
            return;
        }

        setTypePaymentTextCoachButton("Liberty");
},[])

useEffect (()=>{
    CalculateLibertyPrice();
    CalculatePartialLibertyPrice();

},[lesson.productLessonPack.price, libertyPointsAvailableBalance])

const helpPaymentPayPal = (selectedPayment === "paypal" && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
const helpPaymentTransfer = (selectedPayment === "bonifico" && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
const libertyPoints = ((libertyPointsAvailableBalance === undefined) || (libertyPointsAvailableBalance <0)) ? 0 : libertyPointsAvailableBalance;
const coachPaymentLibertyShop = (isLibertyPointVisibal && openLibertyPaymentModal);
const coachPaymentPartialLibertyShop = (isLibertyPointPartialVisibal);
const isAutostrada = (lesson.id === getEnvironmentProductId('autostrada'))


    return(

        <>
            <HelpCards>
                <HelpCardS>

                    <HelpCardDesktop>
                        <ProductIconBox>
                            {!isAppMobile && lessonPhoto}

                            {isAutostrada && !isAppMobile &&
                                <InfoIconBox onClick={infoButtonClickHandler}>
                                    <InfoIconSVG />
                                </InfoIconBox>}
                        </ProductIconBox>

                        <Intestation>
                            {lesson.name}
                        </Intestation>

                        {/* {isAutostrada && isAppMobile &&
                            <AutostradaDescription>
                                <Text dangerouslySetInnerHTML={{ __html: delivery }} />
                            </AutostradaDescription>} */}

                        <Information>

                            <ProductIconBox>
                                {isAppMobile && lessonPhoto}

                                {isAutostrada && isAppMobile &&
                                    <InfoIconBox onClick={infoButtonClickHandler}>
                                        <InfoIconSVG />
                                    </InfoIconBox>}
                            </ProductIconBox>

                            <IntestationDesktop>
                                {lesson.name}
                            </IntestationDesktop>

                            {/* {isAutostrada && !isAppMobile &&
                            <AutostradaDescription>
                                <Text dangerouslySetInnerHTML={{ __html: delivery }} />
                            </AutostradaDescription>} */}

                            <PriceGrid>
                                {!isAutostrada &&
                                    <PriceButton onClick={onHelpCardClickHandler}>
                                        <PriceButtonText>
                                            {lesson.name}
                                        </PriceButtonText>
                                    </PriceButton>
                                }

                                {isAutostrada &&
                                    <PriceButtonAutostrada disabled>
                                        <PriceButtonText>
                                            SOLD OUT
                                        </PriceButtonText>
                                    </PriceButtonAutostrada>
                                }

                                <PaypalPrice>

                                    {isLibertyPointVisibal && 
                                        <Price>
                                            <LibertyPointIconSVG />{libertyPrice}
                                        </Price>
                                    }

                                    {isLibertyPointPartialVisibal && 
                                        <Price>
                                            <LibertyPointIconSVG />{libertyPoints} + € {partialLibertyPrice}
                                        </Price>
                                    }

                                    {isEuroVisibal && <Price>€ {formattedCurrency(lesson.productLessonPack.price)}</Price>} 
                                    
                                    {selectedPayment === 'paypal' && !isLibertyPointVisibal && <PaypalIconSVG/>}

                                    {selectedPayment === 'bonifico' && !isLibertyPointVisibal && <BankIconSVG/>}

                                    {(isEuroVisibal || isLibertyPointPartialVisibal) &&
                                        <ButtonChoose isOpen={isSelectorOpenActive} onClick={onClickSelectorOpenHandler}>
                                            { !isSelectorOpenActive && 
                                                <ArrowPaymentIconSVG/>}
                                            { isSelectorOpenActive && 
                                                <ArrowOpenPaymentSVG/>}
                                        </ButtonChoose>
                                    }
                                    
                                    {isSelectorOpenActive && 
                                        <PaymentMenuHelper
                                            handlePaymentMethod={handlePaymentMethod}
                                            selectedPayment={selectedPayment}
                                            onClose={closeSelectorHandler}
                                        />
                                    }
                                </PaypalPrice>
                            </PriceGrid>
                        </Information>
                        
                    </HelpCardDesktop>
                </HelpCardS>
            </HelpCards>

            {isPartialLibertyPaymentModalVisible && (
                <PartialLibertyPaymentModal
                    onCloseButtonModal={closePartialLibertyPaymentModal}
                    courseID={lesson.id}
                    price={partialLibertyPrice}
                    LibertyPoint={libertyPointsAvailableBalance}
                    typeOfPayment={typeOfPayment}
                    paypalCoursePayment={helpPaypal}
                    transferCoursePayment={helpTransfer}
                    selectedPayment={selectedPayment}
                />
            )}
        </>
    );
};
    
export default HelpCard;
    