import { FC } from 'react';

// types
import { HamburgerButtonProps } from './HamburgerButton';

// assets
import { ReactComponent as HamburgerSVG } from './assets/hamburger.svg';

// style
import { HamburgerButton as Button } from './style/HamburgerButton.style';


/**
 * HamburgerButton
 * 
 * @author Alessio Grassi
 * 
 * @param onClick -  toggle handler of the Submenu
 * 
 * @returns JSX
 */


const HamburgerButton: FC<HamburgerButtonProps> = ({ onClick }) => {

    return (
        <Button onClick={onClick}>
            <HamburgerSVG />
        </Button>
    );
};

HamburgerButton.defaultProps = {

    onClick: () => {},
};

export default HamburgerButton;
