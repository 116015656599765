import { ReactElement } from 'react';

// pages
// import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import LoginForm from 'pages/LoginPage/LoginForm';
import PasswordRecoveryForm from 'pages/LoginPage/PasswordRecoveryForm';
import PasswordChangeForm from 'pages/LoginPage/PasswordChangeForm';
import SignUpPage from 'pages/SignUp';
import SignUpForm from 'pages/SignUp/SignUpForm';
import ConfirmSignUpForm from 'pages/SignUp/ConfirmSignUpForm';
import HomePageLDL from 'pages/LDL/HomePageLDL';
import AccountPage from 'pages/AccountPage';
import LibertyDashboardPage from 'pages/LibertyDashboardPage/LibertyDashboardPage.component';
import HighwayPage from 'pages/HighwayPage';
import ReservedArea from 'pages/ReservedArea/ReservedArea';
import { Navigate } from 'react-router-dom';
import LandingPages from 'pages/LandingPages/LandingPages';
import AiutoPrivato from 'pages/AiutoPrivato/AiutoPrivato';
import ShopPage from 'pages/ShopPage/ShopPage.component';
import Payments from 'pages/Payments/Payments.component';
import PublicProductPages from 'pages/PublicProductPages/PublicProductPage.component';
import MyCoursePage from 'pages/MyCoursePage/MyCoursePage.component';
import ProductPage from 'pages/ProductPage/ProductPage.component';
import MyServicePage from 'pages/MyServicePage/MyServicePage.component';
import NotificationPage from 'pages/NotificationPage/NotificationPage.component';
import NotificationMessages from 'pages/NotificationPage/NotificationMessages/NotificationMessages.component';
import FallbackPages from 'pages/FallbackPages/FallbackPages.component';
import LoginError from 'pages/FallbackPages/components/LoginError/LoginError.component';
import GenericError from 'pages/FallbackPages/components/GenericError/GenericError.component';
import AutorizationError from 'pages/FallbackPages/components/AutorizationError/AutorizationError.component';
import AccountProblemError from 'pages/FallbackPages/components/AccountProblemError/AccountProblemError.component';
import Error404 from 'pages/FallbackPages/components/Error404/Error404.component';
import ThanksForBuying from 'pages/FallbackPages/components/ThanksForBuying/ThanksForBuying.component';
import ThanksAndLogin from 'pages/FallbackPages/components/ThanksAndLogin/ThanksAndLogin.component';
// import LegalPageTerms from 'pages/LegalPages/LegalPageTerms';
// import LegalPagePrivacy from 'pages/LegalPages/LegalPagePrivacy';
// import LegalPageCookie from 'pages/LegalPages/LegalPageCookie';
import LessonPage from 'pages/LessonPage/LessonPage';
import PresentBuying from 'pages/FallbackPages/components/PresentBuying/PresentBuying.component';
import LibertyPoint from 'pages/LibertyPoint/LibertyPoint';
import AutomaticIncomes from 'pages/AutomaticIncomes/AutomaticIncomes.component';
import ProductError from 'pages/FallbackPages/components/ProductError/ProductError.component';
import BackendWaiting from 'pages/FallbackPages/components/BackendWaiting/BackendWaiting.component';
import BonusTogether from 'pages/LandingPages/MarketingLandingPage/BonusTogether/BonusTogether.component';
import BeginFromZero from 'pages/LandingPages/MarketingLandingPage/BeginFromZero/BeginFromZero.component';
import BridgePage from 'pages/LandingPages/MarketingLandingPage/BridgePage/BridgePage';
import Cookies from 'pages/LegalPages/Cookies/Cookies';
import Privacy from 'pages/LegalPages/Privacy/Privacy';
import Terms from 'pages/LegalPages/Terms/Terms';
import ResetPage from 'pages/ResetPage/ResetPage';
import Landing from 'pages/LandingPage/Landing';
import MyNewServicePage from 'pages/MyNewServicePage/MyNewServicePage.component';
import ComingSoonPage from 'pages/ComingSoonPage/ComingSoonPage.component';
import SuperViewPublicPage from 'pages/SpecificPublicPages/SuperViewPublicPage/SuperViewPublicPage.component';
import OddsScasserCalculatorsStandard from 'pages/OddsScasserPage/OddsScasserCalculatorsStandard';
import OddsScasserPage from 'pages/OddsScasserPage';
import OddsScasser from 'pages/OddsScasserPage/OddsScasser';
import UserLevelCard from 'pages/LDL/HomePageLDL/UserLevelCard/UserLevelCard.component';
import UserLevelModal from 'pages/LDL/HomePageLDL/UserLevelModal/UserLevelModal.component';
// import SuperViewPublicPage from 'pages/SpecificPublicPages/SuperViewPublicPage/SuperViewPublicPage.component';
// import LibertyPointPage from 'pages/SpecificPublicPages/LibertyPointPages/LibertyPointPage.component';
// import UserLevelCard from 'pages/HomePage/UserLevelCard/UserLevelCard.component';
import ThanksAndLoginMarketing from 'pages/LandingPages/MarketingLandingPage/ThanksAndLogin/ThanksAndLogin.component';
import ThanksAndLoginMarketing3 from 'pages/LandingPages/MarketingLandingPage/ThanksAndLogin_C3/ThanksAndLogin.component';
// import UserLevelCard from 'pages/HomePageLDL/UserLevelCard/UserLevelCard.component';
import TagManagerLandingPage from 'pages/LandingPages/TagManagerLandingPage/TagManagerLandingPage.component';
import PixelLandingPage from 'pages/LandingPages/PixelLandingPage/PixelLandingPage.component';
// import OddsScasserCalculatorsStandard from 'pages/OddsScasserPage/OddsScasserCalculatorsStandard';
// import OddsScasserPage from 'pages/OddsScasserPage';
// import OddsScasser from 'pages/OddsScasserPage/OddsScasser';
// import MyNewServicePage from 'pages/MyNewServicePage/MyNewServicePage.component';
// import ComingSoonPage from 'pages/ComingSoonPage/ComingSoonPage.component';
// import SuperViewPublicPage from 'pages/SpecificPublicPages/SuperViewPublicPage/SuperViewPublicPage.component';
// import LibertyPointPage from 'pages/SpecificPublicPages/LibertyPointPages/LibertyPointPage.component';
// import UserLevelCard from 'pages/HomePageLDL/UserLevelCard/UserLevelCard.component';
// import UserLevelModal from 'pages/HomePageLDL/UserLevelModal/UserLevelModal.component';
// import LibertyPointPage from 'pages/SpecificPublicPages/LibertyPointPages/LibertyPointPage.component';
import ThematicDirectCatalogue from 'pages/ThematicDirect/ThematicDirectCatalogue/ThematicDirectCatalogue.component';
import ThematicDirectPage from 'pages/ThematicDirect/ThematicDirectPage/ThematicDirectPage.component';
// import ThanksAndLoginMarketing3  from 'pages/LandingPages/MarketingLandingPage/ThanksAndLogin_C3/ThanksAndLogin.component';
import YourThematicDirect from 'pages/ThematicDirect/YourThematicDirect/YourThematicDirect.component';
import ThematicDirectPageShop from 'components/ThematicDirectModal/ThematicDirectPageShop/ThematicDirectPageShop.component';
import ThematicDirectPastPage from 'pages/ThematicDirect/ThematicDirectPastPage/ThematicDirectPastPage.component';
// import TagManagerLandingPage from 'pages/LandingPages/TagManagerLandingPage/TagManagerLandingPage.component';
// import PixelLandingPage from 'pages/LandingPages/PixelLandingPage/PixelLandingPage.component';
import ThematicDirectPageNotification from 'components/ThematicDirectModal/ThematicDirectPageNotification/ThematicDirectPageNotification.component';
import BonusPage from 'pages/OddsScasserPage/BonusPage';
import GeneralSupport from 'App/layout/Header/GeneralSupport/GeneralSupport';
import PresentBuyingBroadcast from 'pages/FallbackPages/components/PresentBuying/PresentBuyingBroadcast.component';
import VdrNumerology from '../pages/VdrNumerology/VdrNumerology.component';
import VdrCardsOfPower from 'pages/VdrCardsOfPower/VdrCardsOfPower.component';
import VdrTools from 'pages/VdrTools/VdrTools.component';
import VdrToolsIndex from 'pages/VdrTools/VdrToolsIndex.component';
import GeneralHome from 'pages/GeneralHome/GeneralHome.component';

export interface AppRoute {
	name: string;
	path: string;
	element: ReactElement;
	isIndex?: boolean | undefined;
	isProtected?: boolean | undefined;
	subRoutes?: AppRoute[];
}

/**
 * first item will always be root,
 * last item will always be "not found page"
 */
const appRoutes: AppRoute[] = [
	{
		name: 'root',
		path: '/',
		element: <Landing isRoot />,
	},
	{
		name: 'testimonials',
		path: '/testimonials',
		element: <Landing />,
	},
	// {
	//     name: 'root',
	//     path: "/",
	//     element: <LandingPage />,
	//     isIndex: true
	// },
	// {
	//     name: 'landing',
	//     path: "/landing",
	//     element: <LandingPage />
	// },
	{
		name: 'legalTerms',
		path: '/terms',
		element: <Terms />,
	},
	{
		name: 'legalPrivacy',
		path: '/privacy',
		element: <Privacy />,
	},
	{
		name: 'legalCookie',
		path: '/cookies',
		element: <Cookies />,
	},
    {
        name: 'supporto',
        path: '/supporto',
        element: <GeneralSupport />,
    },
    {
        name: 'coomingSoon',
        path: '/coomingSoon',
        element: <ComingSoonPage />,
    },

	{
		name: 'login',
		path: '/login',
		element: <LoginPage />,
		subRoutes: [
			{
				name: 'loginPasswordRecovery',
				path: 'password-recovery',
				element: <PasswordRecoveryForm />,
			},
			{
				name: 'loginChangePassword',
				path: 'change-password',
				element: <PasswordChangeForm />,
			},
			{
				name: 'loginForm',
				path: '/',
				element: <LoginForm />,
				isIndex: true,
			},
		],
	},
	{
		name: 'signUp',
		path: '/sign-up',
		element: <SignUpPage />,
		subRoutes: [
			{
				name: 'confirmSignUp',
				path: 'confirm-sign-up',
				element: <ConfirmSignUpForm />,
			},
			{
				name: 'signUpForm',
				path: '/',
				element: <SignUpForm />,
				isIndex: true,
			},
		],
	},
	{
		name: 'landingPages',
		path: '/lp',
		element: <LandingPages />,
		subRoutes: [
			{
				name: 'facebookLanding',
				path: '/',
				element: <TagManagerLandingPage />,
				isIndex: true,
			},
			{
				name: 'facebookLanding',
				path: 'f',
				element: <PixelLandingPage />,
			},
			{
				name: 'bonusTogether',
				path: 'mk',
				element: <BonusTogether />,
			},
			{
				name: 'marketingBridgePage',
				path: 'mk/b',
				element: <BridgePage />,
			},
			{
				name: 'beginFromZero',
				path: 'mk/begin-from-zero',
				element: <BeginFromZero />,
			},
			{
				name: 'thanksAndLogin',
				path: 'mk/thanks-and-login',
				element: <ThanksAndLoginMarketing />,
			},
			{
				name: 'thanksAndLogin3',
				path: 'mk/thanks-and-login-3',
				element: <ThanksAndLoginMarketing3 />,
			},
		],
	},
	{
		name: 'publicShopPage',
		path: '/public/:typeOfProduct/:productId',
		element: <PublicProductPages />,
	},
	{
		name: 'vdrTools',
		path: '/public/strumenti',
		element: <VdrToolsIndex />,
		subRoutes: [
            {
				name: 'tools',
				path: '/',
				element: <VdrTools />,
				isIndex: true,
			},
			{
				name: 'theCardsOfPower',
				path: 'carte-del-potere',
				element: <VdrCardsOfPower />,
			},
			{
				name: 'numerology',
				path: 'numerologia-caldea',
				element: <VdrNumerology />,
			},
		],
	},

    {
        name: 'root',
        path: "/",
        element: <Landing isRoot/>,
    },
    {
        name: 'testimonials',
        path: "/testimonials",
        element: <Landing />,
    },
    // {
    //     name: 'root',
    //     path: "/",
    //     element: <LandingPage />,
    //     isIndex: true
    // },
    // {
    //     name: 'landing',
    //     path: "/landing",
    //     element: <LandingPage />
    // },
    {
        name: 'legalTerms',
        path: "/terms",
        element: <Terms />
    },
    {
        name: 'legalPrivacy',
        path: "/privacy",
        element: <Privacy />
    },
    {
        name: 'legalCookie',
        path: "/cookies",
        element: <Cookies />
    },
    {
        name: 'login',
        path: "/login",
        element: <LoginPage />,
        subRoutes: [
            {
                name: 'loginPasswordRecovery',
                path: "password-recovery",
                element: <PasswordRecoveryForm />
            },
            {
                name: 'loginChangePassword',
                path: "change-password",
                element: <PasswordChangeForm />
            },
            {
                name: 'loginForm',
                path: "/",
                element: <LoginForm />,
                isIndex: true
            },
        ]
    },
    {
        name: 'signUp',
        path: "/sign-up",
        element: <SignUpPage />,
        subRoutes: [
            {
                name: 'confirmSignUp',
                path: "confirm-sign-up",
                element: <ConfirmSignUpForm />
            },
            {
                name: 'signUpForm',
                path: "/",
                element: <SignUpForm />,
                isIndex: true
            },
        ]
    },
    {
        name: 'landingPages',
        path: '/lp',
        element: <LandingPages />,
        subRoutes: [
            {
                name: 'facebookLanding',
                path: "/",
                element: <TagManagerLandingPage />,
                isIndex: true
            },
            {
                name: 'facebookLanding',
                path: "f",
                element: <PixelLandingPage />
            },
            {
                name: 'bonusTogether',
                path: "mk",
                element: <BonusTogether />
            },
            {
                name: 'marketingBridgePage',
                path: "mk/b",
                element: <BridgePage />
            },
            {
                name: 'beginFromZero',
                path: "mk/begin-from-zero",
                element: <BeginFromZero />
            },
            {
                name: 'thanksAndLogin',
                path: "mk/thanks-and-login",
                element: <ThanksAndLoginMarketing />
            },
            {
                name: 'thanksAndLogin3',
                path: "mk/thanks-and-login-3",
                element: <ThanksAndLoginMarketing3 />
            },
        ]
    },
    {
        name: 'publicShopPage',
        path: "/public/:typeOfProduct/:productId",
        element: <PublicProductPages />
    },
	{
		name: 'vdrTools',
		path: '/public/strumenti',
		element: <VdrToolsIndex />,
		subRoutes: [
            {
				name: 'tools',
				path: '/',
				element: <VdrTools />,
				isIndex: true,
			},
			{
				name: 'theCardsOfPower',
				path: 'carte-del-potere',
				element: <VdrCardsOfPower />,
			},
			{
				name: 'numerology',
				path: 'numerologia-caldea',
				element: <VdrNumerology />,
			},
		],
	},
    {
        name: 'Payments',
        path: "payments",
        element: <Payments />,
        subRoutes: [
            {
                name: 'Paypal',
                path: "pp",
                element: <Payments />,
                subRoutes: [
                    {
                        name: 'New Order',
                        path: "neworder",
                        element: <Payments />
                    },
                    {
                        name: 'Cancel Order',
                        path: "cancelorder",
                        element: <Payments />
                    },
                    {
                        name: 'New Subscription',
                        path: "newsubscription",
                        element: <Payments />
                    },
                    {
                        name: 'Cancel Subscription',
                        path: "cancelsubscription",
                        element: <Payments />
                    },
                    {
                        name: 'New Trial Subscription',
                        path: "newtrialsubscription",
                        element: <Payments />
                    }
                ]
            }
        ]
    },
    {
        name: 'reservedArea',
        path: '/reservedArea',
        isProtected: true,
        element: <ReservedArea />,
        subRoutes: [
            {
                name: 'home',
                path: "home",
                element: <GeneralHome />,
                isProtected: true,
                isIndex: true
            },
            {
                name: 'home-page',
                path: "home-page",
                element: <GeneralHome />,
                isProtected: true,
                // isIndex: true
            },
            {
                name: 'userLevel',
                path: "userLevel",
                element: <UserLevelModal />
            },
            {
                name: 'userProfile',
                path: "account-page",
                element: <AccountPage />,
                isProtected: true
            },
            {
                name: 'highway',
                path: "highway-page",
                element: <HighwayPage />,
                isProtected: true
            },
            {
                name: 'libertyDashboard',
                path: "libertydashboard",
                element: <LibertyDashboardPage />,
                isProtected: true
            },
            {
                name: 'aiutoPrivato',
                path: "aiuto-privato",
                element: <AiutoPrivato/>,
                isProtected: true
            },  
            {
               name: 'libertyPoint',
               path: "liberty-point",
               element: <LibertyPoint/>
            },  
            {
                name: 'AutomaticIncomes',
                path: "automatic-incomes",
                element: <AutomaticIncomes/>
            }, 
            {
                name: 'DiretteTematiche',
                path: "dirette-tematiche",
                element: <ThematicDirectCatalogue/>
            }, 
            {
                name: 'MieDirette',
                path: "mie-dirette",
                element: <YourThematicDirect/>
            }, 
            {
                name: 'PaginaDirettaTematica',
                path: "pagina-diretta-tematica/:productId",
                element: <ThematicDirectPage/>
            },
            {
                name: 'PaginaDirettaCatalogo',
                path: "pagina-diretta-catalogo/:productId",
                element: <ThematicDirectPastPage/>
            },
            {
                name: 'ProductDirettaTematicaNotifica',
                path: "product-diretta-tematica-n/:productId",
                element: <ThematicDirectPageNotification/>
            },
            {
                name: 'ProductDirettaTematica',
                path: "product-diretta-tematica/:productId",
                element: <ThematicDirectPageShop/>
            },
            // {
            //     name: 'notificationMobile',
            //     path: "notification-mobile",
            //     element: <NotificationListPage/>,
            //     isProtected: true
            // },
            // {
            //     name: 'notificationMessage',
            //     path: "notification-message",
            //     element: <NotificationMessagePage/>,
            //     isProtected: true
            // },
            {
                name: 'notification',
                path: "notification",
                element: <NotificationPage />,
                isProtected: true,
                subRoutes: [
                    {
                        name: 'notificationMessages',
                        path: ":channelId",
                        element: <NotificationPage />,
                        isProtected: true
                    }
                ]
            },
            {
                name: 'mycourses',
                path: "mycourses",
                element: <MyCoursePage />,
                isProtected: true
            },
            {
                name: 'myservices',
                path: "myservices",
                element: <MyNewServicePage />,
                isProtected: true
            },
            {
                name: 'products',
                path: "product-page/:typeOfProduct/:productId",
                element: <ProductPage />,
                isProtected: true
            },
            {
                name: 'shop',
                path: "shop",
                element: <ShopPage />,
                isProtected: true
            },
            {
                name: 'lessonPage',
                path: "lesson-page",
                element: <LessonPage/>
            },
            {
                name: 'OddsScasser',
                path: "oddsscasser",
                element: <OddsScasserPage/>,
                subRoutes:[
                    {
                        name: 'Base',
                        path: "calculators",
                        element: <OddsScasserCalculatorsStandard/>
                    },
                    {
                        name: 'Secret Bonus',
                        path: "calculators/secretbonus",
                        element: <div>Coming Soon</div>
                    },
                    {
                        name: 'Impossible Bonus',
                        path: "calculators/impossiblebonus",
                        element: <div>Coming Soon</div>
                    },
                    {
                        name: 'Punta Banca',
                        path: "puntabanca",
                        element: <OddsScasser mode='puntabanca'/>
                    },
                    {
                        name: 'Punta Punta',
                        path: "puntapunta",
                        element: <OddsScasser mode='puntapunta'/>
                    },
                    {
                        name: 'Quote Migliori',
                        path: "bestodds",
                        element: <OddsScasser mode='bestodds'/>
                    },
                    {
                        name: 'Bonus Sicuro',
                        path: "bonus/bonussicuro",
                        element: <BonusPage mode='bonus sicuro'/>
                    },
                    {
                        name: 'Bonus Sicuro Plus',
                        path: "bonus/bonussicuroplus",
                        element: <BonusPage mode='bonus sicuro plus'/>
                    },
                    {
                        name: 'Bonus Automatici',
                        path: "bonus/bonusautomatici",
                        element: <BonusPage mode='bonus automatici'/>
                    },
                    // {
                    //     name: 'Colpo Sicuro',
                    //     path: "colposicuro",
                    //     element: <div>colposicuro</div>
                    // },
                ]
            },
            {
                name: 'vdrToolsPrivate',
                path: 'strumenti',
                element: <VdrToolsIndex />,
                subRoutes: [
                    {
                        name: 'toolsPrivate',
                        path: '/',
                        element: <VdrTools />,
                        isIndex: true,
                    },
                    {
                        name: 'theCardsOfPowerPrivate',
                        path: 'carte-del-potere',
                        element: <VdrCardsOfPower />,
                    },
                    {
                        name: 'numerologyPrivate',
                        path: 'numerologia-caldea',
                        element: <VdrNumerology />,
                    },
                ],
            },
        ]
    },
    {
       name: 'fallback',
       path: "/fallback-page",
       element: <FallbackPages />,
       subRoutes:[
        {
            name: 'requiredLogin',
            path: "require-login",
            element: <LoginError />
        },
        {
            name: 'genericError',
            path: "generic-error",
            element: <GenericError />
        },
        {
            name: 'permissionDenied',
            path: "permission-denied",
            element: <AutorizationError />
        },
        {
            name: 'accountProblem',
            path: "accout-problem",
            element: <AccountProblemError />
        },
        {
            name: 'ThanksForBuying',
            path: "Thanks-for-buying",
            element: <ThanksForBuying />
        },
        {
            name: 'PresentBuying',
            path: "present",
            element: <PresentBuying />
        },
        {
            name: 'PresentBuyingBroadcast',
            path: "present-broadcast",
            element: <PresentBuyingBroadcast />
        },
        {
            name: 'ThanksAndLogin',
            path: "Thanks-and-login",
            element: <ThanksAndLogin />
        },
        {
            name: 'assignError',
            path: "assign-error",
            element: <ProductError />,
        },
        {
            name: 'backendWaiting',
            path: "check-payment",
            element: <BackendWaiting />,
        },
        {
            name: 'pageNotFound',
            path: "page-not-found",
            element: <Error404 />,
            isIndex: true
        }
       ]
    },
    {
       name: 'reset',
       path: "/reset",
       element: <ResetPage />
    },
    {
       name: 'default',
       path: "*",
       element: <Navigate to={'/fallback-page'} />
    }
    // {
    //    name: 'default',
    //    path: "*",
    //    element: <Navigate to={'/login/'} />
    // }
];

export default appRoutes;
