import { FC } from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

// types
import { CircularDoughnutChartProps } from './CircularDoughnutChart.d';

// style
import colors from 'style/palette/palette';
import {
    CircularDoughnutBox,
    ChartLabelBox,
    ValueLabel,
    MaxLabel
} from './style/CircularDoughnutChart.style';


/**
 * Circula progress bar (doughnut)
 * 
 * @author Alessio Grassi
 * 
 * @param value - number of the completed steps
 * @param max - total number of the steps
 * 
 * @returns JSX
 */


const CircularDoughnutChart: FC<CircularDoughnutChartProps> = ({ value, max }) => {
    
    Chart.register(ArcElement);

    const valueDiff = ( (+max) - (+value) );
    const isComplete = ( value === max );

    const finalColor = (isComplete ? `${colors.green}` : `${colors.primaryColor}`);

    const data = {

        labels: ["value", "diff"],
        datasets: [
            {
                label: '',
                backgroundColor: [finalColor, `${colors.black087}`],
                borderWidth: 0,
                data: [value, valueDiff.toString()],
                // Chart circle inner thickness
                cutout: "77.778%"
            },
        ],
    }

    const options = {

        // WARNING: if parent box change in size,
        //      we need to manually set padding top below
        layout: {

            padding: {
                top: 0
            }
        },
        plugins: {

            legend: {
                display: false
            }
        }
    }

    return (
        <CircularDoughnutBox>
            <Doughnut
                width="100%"
                height="100%"
                data={data}
                options={options}
            />

            <ChartLabelBox isComplete={isComplete}>
                <ValueLabel isComplete={isComplete}>
                    {value}
                </ValueLabel>

                /

                <MaxLabel isComplete={isComplete}>
                    {max}
                </MaxLabel>
            </ChartLabelBox>
        </CircularDoughnutBox>
    );
}

export default CircularDoughnutChart;
