// asset
import { ReactComponent as ErrorIconSVG } from "./assets/ErrorIcon.svg"

// style
import { 
    IconBox,
    NormalText, 
    Page, 
    TextBox, 
    TextRed, 
    Title, 
    TitleBox 
} from "./style/AccountProblemError.style";


const AccountProblemError = () => {
    
    return (
        <Page>
            <TitleBox>
                <IconBox>
                    <ErrorIconSVG/>
                </IconBox>

                <Title>Attenzione</Title>
            </TitleBox>

            <TextBox>
                <TextRed>Sono stati riscontrati problemi con il tuo account.</TextRed>

                <NormalText>Per ulteriori informazioni contattare il Supporto.</NormalText>
            </TextBox>
        </Page>
    )
};

export default AccountProblemError;