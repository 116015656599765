import styled from 'styled-components';

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const MainLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img, span {
        max-width: 60px;
        max-height: 60px;
        border-radius: 50%;
    }
`;

export const DropdownContainer = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: 2;
`;

export const DropdownItem = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
    img, span {
        max-width: 50px;
        max-height: 50px;
    }
`;

export const LogoSelect = styled.select`
    width: 100%;
    cursor: pointer;
`;

export const LogoOption = styled.option``;
