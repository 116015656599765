export const isTextEmpty = (text?: string | undefined): boolean => {

    if (text === undefined || ( typeof text !== "string" )) return true;
    const trimmedText = !!text.trim();

    return !trimmedText;
};

export const validateEmailInput = (emailInput: string) => {

    if (isTextEmpty(emailInput)) return "La email non può essere vuota";
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    const isCorrectEmailFormat = emailInput.match(regExp)
    if (!isCorrectEmailFormat) return "La email non rispetta il formato giusto";
    
    return;
};

export const validatePasswordInput = (passwordInput: string) => {

    if (isTextEmpty(passwordInput)) return "La password non può essere vuota";
    const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    const isCorrectPasswordFormat = passwordInput.match(regExp);
    if (!isCorrectPasswordFormat) return "La password deve contenere tra 8 e 20 caratteri, ed almeno un carattere maiuscolo, uno minuscolo, un carattere speciale e un numero";

    return;
};

export const validateRepeatedPasswordInput = (repeatedPasswordInput: string, prevPassword: string) => {

    if (isTextEmpty(repeatedPasswordInput)) return "La password non può essere vuota";
    if (repeatedPasswordInput !== prevPassword) return "La password deve essere uguale a quella inserita"
    return;
};

export const validateConfirmCodeInput = (confirmCode: string) => {

    if (isTextEmpty(confirmCode)) return "Il codice di verifica non può essere vuoto";
    return;
};
