import { FC, useEffect, useMemo, useState } from "react";

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// routing
import { Link, useSearchParams } from "react-router-dom";

// hooks
import useAppSelector from "hooks/useAppSelector";
import { useIsFirstRender } from "usehooks-ts";
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { postSubscibeTrial, updateLessonData } from "pages/ProductPage/services/ProductPage.services";

// types
import { MediaProp } from 'components/MediaPlayer/MediaPlayer.d';

//assets
import { ReactComponent as SearchIconSVG } from '../../assets/searchIcon.svg';
import { ReactComponent as ExpandIconSVG } from '../../assets/expandIcon.svg';
import { ReactComponent as CollapseIconSVG } from '../../assets/collapseIcon.svg';

//components
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";
import MediaPlayer from "components/MediaPlayer";
import DescriptionTabDesktop from "./DescriptionTabDesktop/DescriptionTabDesktop.component";

//style
import {
    // CurrentLesson,
    // CourseInfoBox,
    // ProgressBar,
    InterectionLessonBox,
    SearchBox,
    SearchBar,
    LessonSearch,
    ExpandLesson,
    PageCourse,
    PageContent,
    ReproductionBox,
    BreadCrumb,
    VideoBox,
    CourseTitle,
    InterectionBox,
    DescriptionBox,
    LessonTitle,
    LessonDescription,
    FlowBox,
    FreePromoButton
} from './style/ProductPageDesktop.style';
import { getPaypalUrl } from "utils/paypal";
import { getCoursePayment } from "services/payments.services";
import { setItemToLocalStorage } from "utils/localStorage";


const ProductPageDesktop: FC<{
    courseItem: any,
    lessonToDisplay: any,
    typeOfProduct: string,
    updateProductDetails: () => Promise<void>,
    lessonStatus: any,
    isTrialCourseButtonVisible: boolean
}> = ({
    courseItem,
    lessonToDisplay,
    typeOfProduct, 
    lessonStatus,
    updateProductDetails,
    isTrialCourseButtonVisible
}) => {

    const { productsLessonsStatus, clientId } = useAppSelector(state => state.user);
    const [lessonRendering, setLessonRendering] = useState<any>(lessonToDisplay ?? undefined);
    const [nodeRendering, setNodeRendering] = useState<any>(null);
    const [title, setTitle] = useState<string | undefined>(lessonToDisplay.title ?? undefined);
    const [lesson, setLesson] = useState<any>(lessonToDisplay.lesson ?? undefined);
    const [video, setVideo] = useState<any>(lessonToDisplay.lesson?.video ?? {});
    const [fileType, setFileType] = useState<any>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileType ?? undefined));
    const [videoUrl, setVideoUrl] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (
        lessonToDisplay.lesson?.video.url ? 
        lessonToDisplay.lesson?.video.url : 
        lessonToDisplay.lesson?.video.fileType.relativePath ? 
        `https://www.viveredipoker.it/ldlapifiletest/${lessonToDisplay.lesson?.video.fileType.relativePath}/${lessonToDisplay.lesson?.video.fileName}` :
         undefined
         ));
    const [fileName, setFileName] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileName ?? undefined));
    const [relativePath, setRelativePath] = useState<string | undefined>((lessonToDisplay.lesson?.video !== null) && (lessonToDisplay.lesson?.video.fileType.relativePath ?? undefined));
    const [finalUrl, setFinalUrl] = useState<string | undefined>(undefined);
    const [courseTitle, setcourseTitle] = useState<any>(undefined);
    const [searchInput, setSearchInput] = useState<any>("");
    const [filteredLessons, setFilteredLessons] = useState<any>(courseItem.course.content ?? undefined);
    const [collapse, setCollapse] = useState(false);
    const [isButtonDescriptionActive, setIsButtonDescriptionActive] = useState(true);
    // const [isLessonCompleted, setIsLessonCompleted] = useState<boolean | undefined>(undefined);
    const [lessonStatusData, setLessonStatusData] = useState<any>(lessonStatus);
    const [lessonStatusUpdated, setLessonStatusUpdated] = useState<boolean>(false);
    const [lessonInitialStatusUpdated, setLessonInitialStatusUpdated] = useState<boolean>(false);
    const [lessonVideoDuration, setLessonVideoDuration] = useState<number>(2);
    const [lessonVideoCurrentTime, setLessonVideoCurrentTime] = useState<number>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const isFirstRender = useIsFirstRender();

    const dispatch = useAppDispatch();

    const saveCompleteLessonData = (duration: number | undefined, currentTime: number | undefined) => {

        if (!currentTime || !duration) return;

        setLessonVideoDuration(duration);
        setLessonVideoCurrentTime(currentTime);
    };
    
    const onClickButtonDescriptionHandler = () => {
        setIsButtonDescriptionActive(true);
    };

    const collapseAll = () => {        
        setCollapse(prevState => !prevState);
    };

    const searchHandler = (searchValue: any) => {
        setSearchInput(searchValue.target.value)
    };
    
    const lessonHandler = (lessonItem: any) => {
        setLessonRendering(lessonItem)
        onClickButtonDescriptionHandler();
    };

    const closeCollapase = () => {
        setCollapse(false)
    };

    // const setLessonStatusOnChangeLesson = (lessonId: number) => {
    //     // console.log("STATUS???", productsLessonsStatus?.filter((lesson: any) => lesson.lessonId === lessonId)[0]);
        
    //     setLessonStatus(productsLessonsStatus?.filter((lesson: any) => lesson.lessonId === lessonId)[0]);
    // };

    const getLessonStatus = (lessonId: number) => {
        if (lessonStatusData === undefined) return;
        if (lessonStatusData.length === 0) return {};
        // console.log("STATUS???", lessonStatusData);
        console.log("STATUS desktop id: "+lessonId+"???", lessonStatusData);

        // return(lessonStatusData.filter((lesson: any) => lesson.lessonId === lessonId)[0]);
        return (lessonStatusData.filter((lesson: any) => lesson.lessonId === lessonId)[0]);
        // return lessonStatusData;
    };

    const getAssaingProductTrial = async () => {
        try {

           const response = await postSubscibeTrial(+courseItem.id!, 3);

           setItemToLocalStorage('Pending subscription ID', `${response.productService.productSubscriptionId}`)
            window.location.href = response.productService.approvalLink;
            return;            
        } catch (error: any) {
            const snackbarData: SnackbarOptions = {
                type: 'error',
                message: "Non è stato possibile assegnarti il periodo di prova."
            };
            dispatch(addSnackbar(snackbarData));
            console.warn(error);
        }
    };

    const updateLessonsDetails = async () => {

        const renderingLessonStatus = getLessonStatus(lessonRendering.id);
        console.log("LESSON STATUS desktop id "+lessonRendering.id+":", renderingLessonStatus);

        console.log("LESSON STATUS:", renderingLessonStatus);

        const baseStatus = {
            completed: false,
            courseId: courseItem.course.id,
            favorite: false,
            lessonId: lessonRendering.id,
            userId: clientId
        };
        
        const statusToUpdate = ((renderingLessonStatus !== undefined) && renderingLessonStatus.id) ? renderingLessonStatus : baseStatus;
        try {
            const hasUserCompletedTheLesson = (
                (lessonRendering.lesson.video === null) ? true : ((lessonVideoCurrentTime / lessonVideoDuration) > 0.9)
            );

            // console.log("COMPLETATO???", hasUserCompletedTheLesson);
            // console.log("lessonVideoCurrentTime???", lessonVideoCurrentTime);
            // console.log("lessonVideoDuration???", lessonVideoDuration);
            
            const updateData = {
                ...statusToUpdate,
                lastSeenDate: new Date().toISOString(),
            };

            if (!statusToUpdate.completed) updateData.completed = hasUserCompletedTheLesson;
            // console.log("TO UPDATE:", updateData);
            
            setLessonVideoCurrentTime(1);
            setLessonVideoDuration(2);
            await updateLessonData(updateData);
            // updateLessonData(updateData);
            // updateReduxLessonDetalis();
        } catch (error: any) {
            
        }
    };
    
    // console.log("COURSE ITEM", courseItem);
    
    useEffect(() => {
        setFilteredLessons(courseItem.course.content.filter(
            (lesson: any) => {
                if (lesson.title === undefined || lesson.title === '') return lesson;
                if (lesson.title.toLowerCase().includes(searchInput.toLowerCase())) return lesson;
                if (lesson.lesson){
                if (lesson.lesson.description) return lesson.lesson.description.toLowerCase().includes(searchInput.toLowerCase())};
            })
        )
    }, [searchInput]);
        
    useEffect(() => {
        if (lessonRendering !== undefined) {
            setTitle(lessonRendering.title);
            setLesson(lessonRendering.lesson);
        }

        if (lesson !== undefined) {
            setVideo(lesson.video);

        }

        if (video !== undefined && video !== null) {
            setVideoUrl(video.url);
            setFileType(video.fileType)
            setFileName(video.fileName);
        }
        
        if (fileType !== undefined) {
            setRelativePath(fileType.relativePath);
        }

        if (courseItem !== undefined) {
            if (typeOfProduct === 'level') setcourseTitle(courseItem.description);
            if ((typeOfProduct === 'course') || (typeOfProduct === 'service')) setcourseTitle(courseItem.name);
        }

        // setLessonStatusOnChangeLesson(lessonRendering.id)
        // console.log("LESSON RENDERING STATUS", getLessonStatus(lessonRendering.id));

    }, [courseItem, lesson, video, fileType, lessonRendering, nodeRendering, lessonToDisplay]);

    useEffect(() => {

        if (video === undefined && video === null) return;

        if (videoUrl && videoUrl !== null) return setFinalUrl(videoUrl);
        
        if (relativePath && 
            (relativePath !== null) &&
            fileName &&
            (fileName !== null)) return setFinalUrl(`https://www.viveredipoker.it/ldlapifiletest/${relativePath}/${fileName}`)
    }, [fileName, relativePath, videoUrl, video, lessonRendering]);

    useEffect(() => {
        // if (productsLessonsStatus === undefined) return;
        setLessonStatusUpdated(false);
        setLessonVideoDuration(2);
        setLessonVideoCurrentTime(1);
        setLessonInitialStatusUpdated(false);
        // console.log("LEZIONE:", lessonRendering);
        // console.log("UPLOAD DI BASE");
        // console.log("UPLOAD DI BASE SENZA VIDEO", lessonRendering.lesson.video === null);
        const newParams: any = {};
        // if (lessonRendering) newParams.lessonID = lessonRendering.id;
        if (nodeRendering) newParams.nodeID = nodeRendering;
        // setSearchParams(newParams);

    }, [lessonRendering, nodeRendering]);

    useEffect(() => {

        if (!isFirstRender) return;
        if (!searchParams.get('nodeID') ||searchParams.get('nodeID') === null ) return;

        // if (searchParams.get('nodeID')) {
        //     setNodeRendering(searchParams.get('nodeID'));
        // }

        const newLessonToRender = courseItem.course.content.filter((lesson: any) => lesson.id === lessonToDisplay.id);

        if (newLessonToRender.length === 0) return;
        setLessonRendering(newLessonToRender[0]);

    }, [searchParams]);

    useEffect(() => {
        if (lessonInitialStatusUpdated) return;
        if ((lessonVideoCurrentTime === 1) && (lessonVideoDuration === 2)) {
            updateLessonsDetails();
            updateProductDetails();
            setLessonInitialStatusUpdated(true);
        };
    }, [lessonInitialStatusUpdated]);

    // useEffect(() => {
    //     updateLessonsDetails();
    //     updateProductDetails();
    // }, [lessonRendering]);

    useEffect(() => {
        
        console.log("CURR", lessonVideoCurrentTime);
        console.log("DUR", lessonVideoDuration);
        // console.log("PERC", (lessonVideoCurrentTime / lessonVideoDuration));
        
        if (lessonStatusUpdated) return;
        const isVideoLesson = (lessonRendering && lessonRendering.lesson && lessonRendering.lesson.video !== null);
        
        if (!isVideoLesson) return;
        
        // console.log("HA IL VIDEO");
        if ((lessonVideoDuration === 0) || (lessonVideoCurrentTime === 0)) return;
        const lessonCompletePercentage = (lessonVideoCurrentTime / lessonVideoDuration);
        console.log("PERC", (lessonCompletePercentage));
        // console.log("PERCENTUALE:", `${lessonCompletePercentage * 100}%`);
        if (lessonCompletePercentage > 0.9) {
            console.log("DENTRO");
            setLessonStatusUpdated(true);
            // console.log("LEZIONE COMPLETATA!");
            // console.log("UPLOAD CON VIDEO");
            updateLessonsDetails();
        }
    }, [lessonVideoDuration, lessonVideoCurrentTime, lessonRendering, lessonStatusUpdated]);

    useEffect(() => {
        setLessonStatusData(lessonStatus);
    }, [lessonStatus]);

    const localMedia: MediaProp = useMemo(() => {

        return {
           src: finalUrl ?? "",
           type: 'video/mp4'
       };
    }, [finalUrl]);

    const finalLinkTo = (
        (typeOfProduct === 'course') ?
        '/reservedArea/mycourses' :
        (
            (typeOfProduct === 'service') ?
            '/reservedArea/myservices' :
            '/reservedArea'
        ));
    const finalPath = (
        (typeOfProduct === 'course') ?
        'Corsi' :
        (
            (typeOfProduct === 'service') ?
            'Servizi' :
            (
                (typeOfProduct === 'level') ? 
                'Area Riservata' :
                ''
            )
        ));

    const isDescriptionBoxVisible = lesson?.description || (lesson?.attachments.length > 0);

    return (
        <PageCourse>
            <PageContent>
                {lessonRendering &&
                <ReproductionBox>
                    <BreadCrumb>
                        <CourseTitle>
                            <Link to={finalLinkTo}>
                                {finalPath}    
                            </Link> {(typeOfProduct !== 'level') && "/"} {courseTitle}
                            
                        </CourseTitle>
                    </BreadCrumb>

                    {lessonRendering && lessonRendering.lesson && lessonRendering.lesson?.video && (
                    <VideoBox isVideoAlone={(lesson?.description === undefined) || (lesson?.description === '')}>
                        <MediaPlayer 
                            media={localMedia}
                            preload='metadata'
                            maxWidth="none"
                            saveCompleteLessonData={saveCompleteLessonData}
                            lessonId={lessonRendering.id}
                        />    
                    </VideoBox>
                    )}

                    {isDescriptionBoxVisible && 
                    <DescriptionBox isFirstElement={lessonRendering && lessonRendering.lesson && !lessonRendering.lesson?.video} >
                        {/* <LessonTitle>{title}</LessonTitle> */}

                        {lessonRendering.lesson && lessonRendering.lesson?.description && <LessonDescription dangerouslySetInnerHTML={{__html: lesson?.description}} />}
                        {isButtonDescriptionActive && <DescriptionTabDesktop courseItem={lessonRendering} />}

                    </DescriptionBox>}

                </ReproductionBox>
                }

                <InterectionBox>
                    <InterectionLessonBox>
                        <SearchBox>
                            <SearchBar 
                                onChange={searchHandler}
                                value={searchInput}
                                />

                            <LessonSearch>
                                <SearchIconSVG />
                            </LessonSearch>
                        </SearchBox>

                        <ExpandLesson onClick={collapseAll}>
                            {!collapse && <ExpandIconSVG />}
                            {collapse && <CollapseIconSVG />}
                        </ExpandLesson>
                    </InterectionLessonBox>

                    {isTrialCourseButtonVisible && 
                    (courseItem.productCourse?.paymentPlan !== undefined) &&
                    (courseItem.productCourse?.paymentPlan !== null) &&
                        <FreePromoButton onClick={getAssaingProductTrial}>
                            ATTIVA PERIODO IN REGALO DI
                            <br/>
                            “{courseItem.productCourse?.paymentPlan?.productName}”
                        </FreePromoButton>}

                    <FlowBox>
                        <VideoCourseList 
                            lessonSetting={lessonHandler} 
                            courseItem={filteredLessons} 
                            collapse={collapse} 
                            collapseHandler={closeCollapase} 
                            // isLessonCompleted={isLessonCompleted}
                            lessonStatus={lessonStatus}
                            nodeId={lessonToDisplay.id}
                        />
                    </FlowBox>
                </InterectionBox>
            </PageContent>
        </PageCourse>
    );
};

export default ProductPageDesktop;