export const sizes: any = {

    inline: {

        width: "20px",
        height: "20px"
    },
    small: {

        width: "40px",
        height: "40px"
    },
    medium: {

        width: "60px",
        height: "60px"
    },
    big: {

        width: "80px",
        height: "80px"
    },
    page: {

        width: "120px",
        height: "120px"
    }
};
