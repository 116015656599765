import { FC, useCallback, useState } from 'react';


// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// types
import { CryptoFlowModalProps } from './CryptoFlowModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as CryptoSVG } from './assets/crypto-img.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/CryptoFlowModal.style';
import { getEnvironmentBaseUrl } from 'configs/config.helper';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const CryptoFlowModal: FC<CryptoFlowModalProps> = ({ onCloseButtonModal, onModalButtonClickHandler }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = useCallback(() => {

        if (!onCloseButtonModal) return;
        onCloseButtonModal();
        if (onModalButtonClickHandler) {
            onModalButtonClickHandler();
        }
        dispatch( enableAppMain() );

        window.location.href = getEnvironmentBaseUrl("app/public/subscription/127");
    }, [dispatch, onCloseButtonModal, onModalButtonClickHandler]);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <CryptoSVG />
                    </IconBox>

                    <Title>
                        Percorso crypto
                    </Title>

                    <TextContent>
                        Il modo più sicuro e veloce di creare Soldi dal nulla e costruirsi un’entrata mensile senza rischio è il percorso bonus suggerito in "COSA DEVO FARE".
                        <br/><br/>
                        <span>Ricorda che lo farai per un periodo limitato e che richiede poco tempo al giorno; 
                        sempre meglio che lavorare ORE per due lire.
                        Potresti anche saltarlo, ma rinunceresti a un grande profitto ed è fortemente sconsigliato.</span>
                        <br/><br/>

                        Se hai consolidato il percorso bonus e hai capitali liberi da far lavorare, 
                        puoi iniziare il Percorso Crypto.                    
                    </TextContent>

                    <StartButton onClick={startButtonHandler}>INIZIA IL PERCORSO CRYPTO</StartButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default CryptoFlowModal;
