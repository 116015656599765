import colors from 'style/palette';
import styled from 'styled-components';

export const BestOddsMultipleContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

`;

export const MultipleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: fit-content;
`;

export const SelectionAndEventContainer = styled.div`
    width: 100px;
    min-width: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const RemoveButton = styled.button`
    text-transform: uppercase;
    color: #ff4545;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const EventContainer = styled.div`
    width: 100px;
    min-width: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
`;

export const Team = styled.div`
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
`;
export const Selection = styled.div<{backgroundColor?: string}>`
    font-size: 16px;
    font-weight: 500;
    border-bottom: ${(props) => props.backgroundColor ? '5px solid '+props.backgroundColor : '5px solid #808080'};
    width: 100%;
    text-align: center;
`;

export const OddsTotalValue = styled.div`
    width: 70px;
    min-width: 70px;
    color: white;
    font-size: 18px;
    cursor: default;
    text-align: center;
`;

export const OddsValue = styled.div<{notClickable?: boolean}>`
    width: 100px;
    min-width: 100px;
    color: #ffffffc9;
    font-size: 15px;
    text-align: center;
    cursor: ${(props) => props.notClickable ? 'default' : 'pointer'};
`;
