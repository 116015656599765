import { FC, useRef, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//utils
import { insertRecoveredResult } from './services/RecoverMonthModal.services';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import BaseModal from 'components/ui/BaseModal';
import { Row } from 'style/layout';
import { BaseCard } from 'style/wrappers';
import { 
    CardContent,
    CloseButton,
    Title,
    MonthToRecoverButton,
    MonthToRecover,
    IncomingContainer,
    IncomingBox,
    IncomingType,
    InputBox,
    IncomingInput,
    RecoverButton
} from './style/RecoverMonthModal.style';


const RecoverMonthModal: FC<{ 
    onCloseButtonModal: () => void,
    monthsToRecover: any[]
}> = ({ 
    onCloseButtonModal,
    monthsToRecover
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const selectREF = useRef<HTMLSelectElement>(null);
    const bonusREF = useRef<HTMLInputElement>(null);
    const sureShotREF = useRef<HTMLInputElement>(null);
    const otherREF = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const resultsRecoverHandler = async () => {
        
        if (selectREF.current !== null) console.log("VALUE", selectREF.current!.value);
        let snackbarData: SnackbarOptions = {
            type: 'success',
            message: "Dati salvati correttamente."
        };

        try {
            const year = monthsToRecover.filter((monthToRecover: {month: string, year: number}) => monthToRecover.month === selectREF.current!.value)[0].year;
            const month = selectREF.current!.value;
            const payload = {
                bonus: +bonusREF.current!.value,
                colpoSicuro: +sureShotREF.current!.value,
                tradingSportivo: +otherREF.current!.value,
            }

            const response = await insertRecoveredResult( +year, month, payload);

            console.log("RES:", response);
            dispatch(addSnackbar(snackbarData));
            
        } catch (error: any) {
            console.warn("ERROR:", error);
            snackbarData.message = "C'è stato un errore nel salvataggio, riprova più tardi";
            snackbarData.type = 'error';
            dispatch(addSnackbar(snackbarData));
            closeButtonHandler();
        }
    };

    return (
        <BaseModal 
        onClose={onCloseButtonModal}
        isCloseButtonClicked={isCloseButtonClicked}
        // isBackdropClickActive
        modalFixedStart
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                        <Title>
                            Puoi recuperare i mesi saltati inserendo qui i dati:
                        </Title>

                        <MonthToRecoverButton ref={selectREF}>
                            {monthsToRecover.map((monthToRecover: {month: string, year: number}, index: number) => (
                                <MonthToRecover key={index}>{monthToRecover.month}</MonthToRecover>
                                )
                            )}
                        </MonthToRecoverButton>

                        <IncomingContainer>
                            <IncomingBox>
                                <IncomingType>
                                    Bonus
                                </IncomingType>

                                <InputBox>
                                    <IncomingInput ref={bonusREF}/>
                                    €
                                </InputBox>
                            </IncomingBox>

                            <IncomingBox>
                                <IncomingType>
                                    Colpo Sicuro
                                </IncomingType>

                                <InputBox>
                                    <IncomingInput ref={sureShotREF}/>
                                    €
                                </InputBox>
                            </IncomingBox>

                            <IncomingBox>
                                <IncomingType>
                                    Altro
                                </IncomingType>

                                <InputBox>
                                    <IncomingInput ref={otherREF}/>
                                    €
                                </InputBox>
                            </IncomingBox>
                        </IncomingContainer>

                        <RecoverButton onClick={resultsRecoverHandler}>
                            Recupera mese
                        </RecoverButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default RecoverMonthModal;
