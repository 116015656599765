import { FC } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import BaseFieldset from 'components/ui/BaseFieldset';
import FormEditEmail from './EditEmailBox/EditEmailBox.component';
import FormEditPassword from './FormEditPassword/FormEditPassword.component';

// style
import {
    FormEditAccountBox,
    Content,
    FormPhoneNumberBox,
    PhoneTextTitle,
    PhoneNumber
} from './style/FormEditAccount.style';


/**
 * Edit account form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const FormEditAccount: FC<{ userData: any }> = ({userData}) => {

    const currentUser = useAppSelector(state => state.user);

    const {phoneNumber } = currentUser;

    // const phoneNumberSplit = phoneNumber ? phoneNumber.split(' ') : [];
    // const phonePrefix = phoneNumberSplit.length > 1 ? "+" + phoneNumberSplit[0] : '';
    // const phoneNumberWithoutPrefix = phoneNumberSplit.length > 1 ? phoneNumberSplit[1] : '';

    const trimmedPhoneNumber = phoneNumber ? phoneNumber.trim() : '';

    // const phonePrefix = trimmedPhoneNumber ? `+${trimmedPhoneNumber.slice(0, 2)}` : '';
    // const phoneNumberWithoutPrefix = trimmedPhoneNumber ? trimmedPhoneNumber.slice(2) : '';

    const phonePrefix = trimmedPhoneNumber && trimmedPhoneNumber[0] === '+' ? trimmedPhoneNumber.slice(0, 3) : '';
    const phoneNumberWithoutPrefix = trimmedPhoneNumber ? trimmedPhoneNumber.slice(3) : '';

    return (
        <FormEditAccountBox>
            <BaseFieldset legend="Account">
                <Content>
                    <FormEditEmail userData={userData} />

                    <FormEditPassword />

                    <FormPhoneNumberBox>
                        <PhoneTextTitle>
                            NUMERO DI TELEFONO:
                        </PhoneTextTitle>
                        
                        <PhoneNumber>
                            {phoneNumber ? `${phonePrefix} ${phoneNumberWithoutPrefix}` : 'Numero non inserito'}
                        </PhoneNumber> 
                    </FormPhoneNumberBox>
                </Content>
            </BaseFieldset>
        </FormEditAccountBox>
    );
};

export default FormEditAccount;
