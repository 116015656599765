import colors from 'style/palette';
import styled, { css } from 'styled-components';
import { LessonIconBoxProps } from '../../style/FormLessonsContent.style.d'; //to do move

export const RowBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 10px;

    width: 100%;

    > p {

        margin: 0;
        padding: 0;

        font-size: 15px;
        font-weight: 500;
        line-height: 15px;

        color: ${colors.white};
    }
`;

export const IconBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-shrink: 0;
    gap: 15px;

`;

export const LessonIconButton = styled.button<LessonIconBoxProps>`

    margin: 0;
    padding: 5px;

    background: ${colors.transparent};
    border-radius: 0;
    border: none;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    > svg path {

        // pure white
        fill: ${colors.white};
        fill-opacity: 1;
    }

    > svg {
        cursor: pointer;
    }

    :disabled {

        cursor: default;

        > svg path {

            // grey like
            fill: ${colors.white};
            fill-opacity: 0.5;
        }

        > svg {
            cursor: default;
        }

    }

    ${({isCompleted}) => isCompleted && (

        css`

            cursor: default;

            > svg path {

                // gold like
                fill: ${colors.primaryColor};
                fill-opacity: 1;
            };

            > svg {
                cursor: default;
            }
        `
    )}

    ${({isCompletedAndCliccable}) => isCompletedAndCliccable && (

        css`

            cursor: pointer;

            > svg path {

                // gold like
                fill: ${colors.primaryColor};
                fill-opacity: 1;
            };

            > svg {
                cursor: pointer;
            }
        `
    )}
`;
