import { callGET, callPOST, callPUT } from "network/network";
import store from "redux/store";

export const getCourseWithId = async (courseId: number) => {
        
    try {
        
        const productParams = {
            brandCode: store.getState().ui.brand?.code
        };
        const response = await callGET(`v1/self/products/${courseId}`, true, productParams);
        
        if (!response || response === undefined) throw new Error("Course ID response undefined");
        if (!response.course || response.course === undefined) throw new Error("response.course is undefined");
        if (!response.course.content || response.course.content === undefined) throw new Error("response.course.content is undefined");
        
        return response;
        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const getUserVipPathLevel = async () => {

    try {
        const response = await callGET('v1/self/vippathlevel', true);
        
        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getLessonData = async () => {
        
    try {
        
        const response = await callGET(`v1/self/courselessonuserdata`, true);
        
        if (!response || response === undefined) throw new Error("Unable to get lesson user data");
        
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const getLessonDataWithId = async (productID: number) => {
        
    try {
        
        const response = await callGET(`v1/self/courselessonuserdata/${productID}`, true);
        
        if (!response || response === undefined) throw new Error("Unable to get lesson user data");
        
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const updateLessonData = async (updateData: any) => {
        
    try {
        
        // const lessonUpdatePayload = {
        //     id: 0,
        //     userId: 0,
        //     lessonId: 0,
        //     currentSecond: 0,
        //     completed: true,
        //     lastSeenDate: "2023-03-18T19:33:22.445Z",
        //     favorite: true,
        //     courseId: 0
        //   };
        const response = await callPUT(`v1/self/courselessonuserdata`, updateData, true);
        
        if (!response || response === undefined) throw new Error("Course ID response undefined");

        return response;        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const getHasUserTrial = async (productID: number) => {
    try {
        const response = await callGET(`v1/self/productsubscriptions/productscourse/${productID}/istrialavailable`, true);

        if (response === undefined) throw new Error("Course ID response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const postSubscibeTrial = async (productID: number, paymentTypeId: number) => {

    const params = {
        brandCode: store.getState().ui.brand?.code,
        paymentTypeId 
    }

    try {
        const response = await callPOST(`v1/publicpurchase/courseservicesubscription/paypal/${productID}`,{}, true, params);

        if (!response || response === undefined) throw new Error("Course ID response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

