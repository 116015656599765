import { FC, useEffect } from 'react';

// utils
import config from 'configs/config';
import { getEnvironmentAssetLinkUrl } from 'configs/config.helper';
import { getCurrentAuthUser } from 'services/user.services';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// assets
import { ReactComponent as FacebookIconSVG } from '../../assets/facebookIcon.svg';
import { ReactComponent as AppIconSVG } from '../../assets/appIcon.svg';
import { ReactComponent as BetfairIconSVG } from '../../assets/betfairIcon.svg';
import { ReactComponent as StepTwoIconSVG } from './assets/stepTwoIcon.svg';
import { ReactComponent as StepThreeIconSVG } from './assets/stepThreeIcon.svg';
import googlePlaySVG from './assets/googlePlayStore.svg';
import appleStoreSVG from './assets/appleStore.svg';

// style
import {
    MissionsFlowBox,
    MissionsFlowRow,
    MissionBox,
    MissionName,
    MissionButtonRow,
    MissionButton,
    ButtonImg,
    Separator
} from './style/MissionsFlow.style';
import { facebookConnect } from 'services/facebook.services';
import useAppSelector from 'hooks/useAppSelector';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';

const FB_GROUP_ID = () => {

    if (!config || config === undefined) return;
    if (!config.socialLinks || config.socialLinks === undefined) return;
    if (config.environment === 'production') return config.socialLinks.fbGroupID;
    return config.socialLinks.fbGroupTestID;
};

const BETFAIR_FB_INSTRUCTION_POST_URL = "https://www.facebook.com/groups/LiberoDalLavoro/posts/1657283077986148";


const MissionsFlow: FC<{ 
    isFacebookMissionCompleted: boolean | undefined,
    isAppMissionCompleted: boolean | undefined,
}> = ({
    isFacebookMissionCompleted,
    isAppMissionCompleted,
}) => {
 
    const { email } = useAppSelector(state => state.user)

    const checkUserConnectedAccount = (userData: any, accountType: string) => {
        
        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;
        
        return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };

    const facebookGroupJoinClickHandler = async () => {

        const userData = await getCurrentAuthUser();
        if (!email) return;
        if (!checkUserConnectedAccount(userData, 'Facebook')) return await facebookConnect(false, email, 'facebook mission');

        window.open (
            `https://www.facebook.com/groups/${FB_GROUP_ID()}`,
            '_blank',
            'noopener noreferrer'
        );
    };

    const betfairInstructionClickHandler = () => {
        
        window.open (
            BETFAIR_FB_INSTRUCTION_POST_URL,
            '_blank',
            'noopener noreferrer'
        );
    };

    const googlePlayStoreClickHandler = () => {

        window.open (
            "https://play.google.com/store/apps/details?id=com.liberidallavoro",
            '_blank',
            'noopener noreferrer'
        )
    };

    const appleStoreClickHandler = () => {

        window.open (
            "https://apps.apple.com/it/app/liberi-dal-lavoro/id1491031542",
            '_blank',
            'noopener noreferrer'
        )
    };

    return (
        <MissionsFlowBox>
            <MissionsFlowRow>
                <MissionBox completed={isFacebookMissionCompleted}>
                    <FacebookIconSVG />

                    {!isFacebookMissionCompleted && <MissionName>Facebook</MissionName>}
                </MissionBox>

                <Separator><hr/></Separator>
                
                {!isFacebookMissionCompleted && <StepTwoIconSVG />}

                {isFacebookMissionCompleted && (
                <MissionBox completed={isAppMissionCompleted}>
                    <AppIconSVG />

                    {!isAppMissionCompleted && <MissionName>App</MissionName>}
                </MissionBox>
                )}

                <Separator><hr/></Separator>
                
                {(!isFacebookMissionCompleted || 
                !isAppMissionCompleted) &&
                <StepThreeIconSVG />}

                {isFacebookMissionCompleted && 
                isAppMissionCompleted && (
                <MissionBox>
                    <BetfairIconSVG />

                    <MissionName>Betfair</MissionName>
                </MissionBox>
                )}
            </MissionsFlowRow>

            {!isFacebookMissionCompleted &&
            <MissionButton 
                onClick={facebookGroupJoinClickHandler}
            >
                Entra nel gruppo
            </MissionButton>}
            
            {isFacebookMissionCompleted &&
            !isAppMissionCompleted && 
            (<MissionButtonRow>
                <MissionButton 
                    flavor='doubleYellow'
                    onClick={googlePlayStoreClickHandler}
                >
                    <ButtonImg src={getEnvironmentAssetLinkUrl(googlePlaySVG)} alt='Google Play Store' />
                </MissionButton>

                <MissionButton
                    flavor='doubleYellow'
                    onClick={appleStoreClickHandler}
                >
                    <ButtonImg src={getEnvironmentAssetLinkUrl(appleStoreSVG)} alt='Apple Store' />
                </MissionButton>
            </MissionButtonRow>
            )}
            
            {isFacebookMissionCompleted &&
            isAppMissionCompleted && (
                <MissionButton
                    flavor='yellow'
                    onClick={betfairInstructionClickHandler}       
                >
                    Istruzioni
                </MissionButton>
            )}
        </MissionsFlowBox>
    );
};

export default MissionsFlow;
