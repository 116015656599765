import { FC, ChangeEvent, useEffect, useState, useRef } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

//components
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete';

// types
import { EventInputProps } from './EventInput.component.d';
import { AutocompletePossibleValuesProps } from 'components/TextInputWithAutocomplete/TextInputWithAutocomplete.component.d';

// style
import { EventInputContainer } from './style/EventInput.style';
import DateInput from 'components/ui/DateInput/DateInput.component';
import { DateInputState } from 'components/ui/DateInput/types/DateInputState';
import { EventInputState } from './types/EventInputState';
import { getDate, getSportColor } from 'pages/OddsScasserPage/OddsScasserPage.helper';


const EventInput: FC<EventInputProps> = ({
    value,
    minDate,
    isEventEditable,
    height,
    breakPoint,
    onChange
}) => {
    const { events } = useAppSelector(state => state.oddsScasser);

    const [eventsList, setEventsList] = useState<AutocompletePossibleValuesProps[]>([]);

    //Set eventsList when events changes
    useEffect(() => {
        const arr: AutocompletePossibleValuesProps[] = [];
        events.forEach(event => {
            if(event.status !== 'Prematch' || (event.datetime && getDate(event.datetime) < new Date())) return; 
            const home = event.home?.name;
            const away = event.away?.name;
            const league = event.league;
            if(!league) return;
            const country = league.country;
            const leagueName = league.name;
            const pieces = [`${country}, ${leagueName}`, `${leagueName}`, `${home} - ${away} (${country}, ${leagueName})`, `${away} (${country}, ${leagueName})`];
            if(country) pieces.push(...country?.split(' '));
            if(leagueName) pieces.push(...leagueName?.split(' '));
            if(home) pieces.push(...home?.split(' '));
            if(away) pieces.push(...away?.split(' '));
            arr.push({text: `${home} - ${away} (${country}, ${leagueName})`, pieces: pieces, key: 'eventId', value: event.id, color: getSportColor(league.sport?.id)+'85'});
        });
        arr.sort();
        setEventsList(arr);
    }, [events])

    const handleDateInputChange = (newState: DateInputState) => {
        onChange && onChange({...value, eventDate: newState});
    }

    const handleEventInputChange = (newInputValue: string, index: number) => {
        const eventId = index>-1 ? eventsList[index].value : -1;
        const eventText = index>-1 ? eventsList[index].text : newInputValue;
        const newState: EventInputState = {...value, eventId, eventText};
        if(eventId>-1) {
            const event = events.get(eventId);
            if(event && event.datetime) {
                const d = new Date(event.datetime);
                newState.eventDate = {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')};
            }
        }
        onChange && onChange(newState);
    }

    return (
        <EventInputContainer breakPoint={breakPoint}>
            <DateInput value={value.eventDate} minDate={minDate} height={height} breakPoint={breakPoint} onChange={handleDateInputChange}/>
            <TextInputWithAutocomplete isDisabled={!isEventEditable} value={value.eventText} possibleValues={eventsList} height={height+'px'} allowInvalid={true} emptyIsInvalid={true} onChange={handleEventInputChange}/>
        </EventInputContainer>
    );
};

export default EventInput;
