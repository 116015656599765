import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const ProductGeneralBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;


    width: 290px;

    padding: 0 10px 33px;

    border-bottom: 1px solid ${colors.grey};


    @media ${devices.tablet} {

        width: 320px;

        padding: 0 15px 25px;

    }
`;

export const ProductTitle = styled.h2`

    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 15px;

    color: ${colors.primaryColor};

    text-transform: uppercase;

    @media ${devices.tablet} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        flex-grow: 1;

        height: 100%;
        max-height: 37px;
    }
`;

export const ProductBox = styled.div`

    margin: 16px 0 0;    
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;

    gap: 17px;

    @media ${devices.tablet} {
        
    }
`;

export const CourseImage = styled.img`
    max-width: 115px;
`;

export const ProductInfoBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const ProductIconBox = styled.div`

    margin: -15px 0 0 20px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InfoIconBox = styled.button`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;

    cursor: pointer;

    background: ${colors.black};

    border: 1px solid ${colors.white};;
    border-radius: 100px;
    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
`;

export const ProductDescriptionBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    flex-grow: 1;
`;

export const ProductDesciptionEllipsis = styled.div`

    overflow: hidden;

    @media ${devices.tablet} {
        min-height: 115px;
    }
`;

export const ProductDesciption = styled.div`

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    overflow: hidden;

    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 19px;

    color: ${colors.white};

    p{
        margin: 0;
        padding: 0;
    }
`;