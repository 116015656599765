import { FC, useState } from 'react';

// components
import MediaPlayer from 'components/MediaPlayer';
import CustomerRatingModal from 'components/CustomerRatingModal/CustomerRatingModal.component';
import CaptionText from '../../CaptionText/CaptionText.component';
import FormLessons from '../../FormLessons/FormLessons.component';
import PersonalAssistantNotesModal from '../../PersonalAssistantNotesModal/PersonalAssistantNotesModal';

// types
import { HighwayPageDesktopProps } from './HighwayPageDesktop.d';

// style
import { 
    PageDesktop,
    PageTitle,
    TextSeparator,
    ProductSubMenu,
    ContentCard,
    ContentRow,
} from './style/HighwayPageDesktop.style';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Product page desktop
 * 
 * @author Frank Berni
 * 
 * @param isDatePickerButtonCompleted - state of the date picker button
 * @param isZoomLinkButtonCompleted - state of the zoom link button
 * @param isCommentButtonCompleted - state of the personal assistant comment button
 * @param isCustomerRatingButtonCompleted - state of the customer rating button
 * @param isPersonalAssistantNotesModalVisible - state of the personal assistant modal
 * @param isCustomerRatingModalVisible - state of the custome rating modal
 * @param openPersonalAsistantNotesModal - handler for open the personal assistant modal
 * @param closePersonalAsistantNotesModal - handler to close the personal assistant modal
 * @param openCustomerRatingModal - handler to open custome rating modal
 * @param closeCustomerRatingModal - handler to close customer rating modal
 * 
 * @returns JSX
 */


const HighwayPageDesktop: FC<HighwayPageDesktopProps> = ({
    isDatePickerButtonCompleted,
    isZoomLinkButtonCompleted,
    isCommentButtonCompleted,
    isCustomerRatingButtonCompleted,
    isPersonalAssistantNotesModalVisible,
    isCustomerRatingModalVisible,
    lessons,
    refreshDataHandler,
    openPersonalAsistantNotesModal,
    closePersonalAsistantNotesModal,
    openCustomerRatingModal,
    closeCustomerRatingModal,
    newLessonHandler
}) => {

    const [clickedLessonId, setClickedLessonId] = useState<number | undefined>(undefined);

    let choicedLesson: any;
    if (clickedLessonId) choicedLesson = lessons.productLessonPack?.lessons?.filter((lesson: any) => lesson.id === clickedLessonId)[0];

    const choiceLessonClickHandler = (lessonId: number) => setClickedLessonId(lessonId);

    const isLoading = (lessons === undefined);

    return (
        <>
            <PageDesktop>
                <PageTitle>
                    <ProductSubMenu>L'Autostrada</ProductSubMenu>
                </PageTitle>

                <ContentRow>
                    <ContentCard>
                        <MediaPlayer 
                            media={{
                                src:"https://www.viveredipoker.it/VideoSitoLDL/AutostradaUtente.mp4",
                                type:'video/mp4'
                            }}
                            preload='metadata'
                            maxHeight='523px'
                        />

                        <CaptionText />
                    </ContentCard>
                    {isLoading && (
                        <LoadingMask 
                            size='big' 
                            width='400px'
                            paddingTop='200px'
                        />
                    )}

                    {!isLoading && (
                        <FormLessons 
                            isDatePickerButtonCompleted={isDatePickerButtonCompleted}
                            isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
                            isCommentButtonCompleted={isCommentButtonCompleted}
                            isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
                            onCommentButtonClick={openPersonalAsistantNotesModal}
                            onRatingButtonClick={openCustomerRatingModal}
                            lessons={lessons}
                            newLessonHandler={newLessonHandler}
                            choiceLessonHandler={choiceLessonClickHandler}
                        />
                    )}
                </ContentRow>
            </PageDesktop>
            
            {isPersonalAssistantNotesModalVisible && (
                <PersonalAssistantNotesModal 
                    onCloseButtonModal={closePersonalAsistantNotesModal}
                    choicedLesson={choicedLesson}
                />
            )}

            {isCustomerRatingModalVisible && (
                <CustomerRatingModal 
                    onCloseButtonModal={closeCustomerRatingModal}
                    isHighway
                    lessonId={lessons.productLessonPack?.lessons[lessons.productLessonPack?.lessons?.length - 1]?.id}
                    refreshFunction={refreshDataHandler}
                />
            )}
        </>
    );
};

export default HighwayPageDesktop;
