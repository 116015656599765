import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const MainComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    flex-shrink: 0;

    @media ${devices.tablet} {
        width: 290px;
    }
`;

export const TitleComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    gap: 11px;
    padding: 0px 10px;
    width: 100%;

    >svg{
        flex-shrink: 0;

        width: 35.29px;
        height: 35px;
    }

    >p{
        flex-shrink: 0;

        width: 35.29px;
        height: 35px;

        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const NoCategoryBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    width: 35.29px;
    height: 35px;
`;

export const TitleText = styled.h3`
    color: ${colors.white};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; 

    margin: 0;
    padding: 0;

    width: 179px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    max-height: 38px; 
    text-overflow: ellipsis;
    white-space: normal;
`;

export const InfoButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    border-radius: 66.667px;
    border: 0.667px solid ${colors.white};
    background: ${colors.black};
    box-shadow: 
        0px 0.6666668057441711px 3.333333730697632px 0px ${colors.black012}, 
        0px 1.3333336114883423px 1.3333336114883423px 0px ${colors.black014}, 
        0px 2.000000476837158px 0.6666668057441711px -1.3333336114883423px rgba(0, 0, 0, 0.20);
    
    padding: 6px 10px 7.333px 10px;
    margin-top: 20px;

    cursor: pointer;

    >svg{
        flex-shrink: 0;

        width: 8px;
        height: 14.667px;
        fill: ${colors.white};
    }

    @media ${devices.tablet} {

    }
`;

export const PlayButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    border-radius: 66.667px;
    border: 0.667px solid ${colors.white};
    background: ${colors.black};
    box-shadow: 
        0px 0.6666668057441711px 3.333333730697632px 0px ${colors.black012}, 
        0px 1.3333336114883423px 1.3333336114883423px 0px ${colors.black014}, 
        0px 2.000000476837158px 0.6666668057441711px -1.3333336114883423px rgba(0, 0, 0, 0.20);
    
    padding: 6px 10px 7.333px 10px;
    margin-top: 20px;

    cursor: pointer;

    >svg{
        flex-shrink: 0;

        width: 8px;
        height: 15px;
        fill: ${colors.white};
    }

    @media ${devices.tablet} {

    }
`;

export const DoubleActionBox = styled.div`
    
    display: flex;
    align-items: center;
    gap: 5px;
    @media ${devices.tablet} {

    }
`;


export const ButtonComponent = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    gap: 10px;
    padding: 0px 31px 0px 14px;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    gap: 7px;

    @media ${devices.tablet} {

    }
`;

export const MainButton = styled.div <{isOwned?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 0px;
    gap: 1px;
    
    width: 158px;
    height: 42px;

    margin-top: 15px;

    border-radius: 5px;
    background: ${({ isOwned }) => (isOwned ? '#626D78' : colors.primaryColor)};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20);

    cursor: ${({ isOwned }) => (isOwned ? 'auto' : 'pointer')};

    position: relative;

    >svg{
        position: absolute;

        top: 0px;
        right: 0;
    }

    @media ${devices.tablet} {

    }
`;

export const LibertyPrice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 0px;
    gap: 4px;

    >svg{
        flex-shrink: 0;

        width: 21.08px;
        height: 19px;

        fill: ${colors.black};
    }

    >p{
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        
        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const ButtonText = styled.p`
    
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-size: 11px;
    font-style: normal;
    font-weight: 500;

    padding: 0;
    margin: 0;
    @media ${devices.tablet} {

    }
`;

export const PaymentSelector = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    width: 100%;

    @media ${devices.tablet} {

    }
`;

export const PriceInfo = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    gap: 10px;

    >svg{
        flex-shrink: 0;
        
        width: 12.641px;
        height: 15px;
    }

    >p{
        color: ${colors.black};
        font-size: 11px;
        font-style: italic;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }
    
    @media ${devices.tablet} {

    }
`;

export const ArrowSelection = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 7px 8px 7px;
    gap: 10px;

    border-radius: 5px;
    background: ${colors.grey};


    >svg{
        flex-shrink: 0;

        width: 10px;
        height: 5px;

        fill: rgba(255, 255, 255, 0.50);
    }

    @media ${devices.tablet} {

    }
`;