import React from 'react';

// style
import { 
    MailText
} from '../style/GeneralSupport.style';

const MBSupport = () => {
  const encEmailMB = "c3VwcG9ydG9AZ3J1cHBvbWlzc2lvbmViZW5lc3NlcmUuaXQ=";
  const decodedEmailMB = atob(encEmailMB);

  const handleEmailClick = () => {
    window.location.href = `mailto:${decodedEmailMB}`;
  };

  return (
    <MailText id="contact" onClick={handleEmailClick}>{decodedEmailMB}</MailText>
  );
};

export default MBSupport;
