import { FC } from 'react';

// utils
import config from 'configs/config';

// assets
import { ReactComponent as LDLHorizontalLogoSVG } from './assets/ldlHorizontalLogo.svg';
import { ReactComponent as FacebookIconSVG } from './assets/facebookIcon.svg';
import { ReactComponent as TelegramIconSVG } from './assets/telegramIcon.svg';
import { ReactComponent as YouTubeIconSVG } from './assets/youTubeIcon.svg';
import { ReactComponent as InstagramIconSVG } from './assets/instagramIcon.svg';
import { ReactComponent as TwitchIconSVG } from './assets/twitchIcon.svg';
import { ReactComponent as TikTokIconSVG } from './assets/tikTokIcon.svg';

// style
import {
    InfoBox,
    SocietyName,
    SocietyAddress,
    SocialRow,
    SeparatorLine,
    PrivacyBox
} from './style/SocietyInfoMobile.style';


/**
 * Society info (for mobile)
 * 
 * @author Frank Berni
 * 
 * @returns JSX
 */


const SocietyInfoMobile: FC<{}> = () => {
    
    const { socialLinks } = config;

    return (
        <InfoBox>
            <LDLHorizontalLogoSVG />

            <SocietyName>EASYTENERLIFE SL</SocietyName>

            <SocietyAddress>
                Calle El Mojon 20 - puerta 26 
                38679 ADEJE S.C. Tenerife NIF: B76784420
            </SocietyAddress>

            <SocialRow>
                <a 
                    href={socialLinks?.facebook}
                    target={'_blank'}
                    rel='noreferrer noopener'
                >
                    <FacebookIconSVG />
                </a>

                <a 
                    href={socialLinks?.telegram}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <TelegramIconSVG />
                </a>

                <a 
                    href={socialLinks?.youtube}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <YouTubeIconSVG />
                </a>
                
                <a 
                    href={socialLinks?.instagram}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <InstagramIconSVG />
                </a>

                <a 
                    href={socialLinks?.twitch}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <TwitchIconSVG />
                </a>
                
                <a 
                    href={socialLinks?.tikTok}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <TikTokIconSVG />
                </a>
            </SocialRow>

            <PrivacyBox>
                <a  
                    href='https://www.liberidalavoro.it/app/legalCookie'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    Cookie  
                </a>
    
                <SeparatorLine>|</SeparatorLine>
    
                <a 
                    href='https://www.liberidalavoro.it/app/legalPrivacy'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    Privacy  
                </a> 
    
                <SeparatorLine>|</SeparatorLine>
        
                <a 
                    href='https://www.liberidalavoro.it/app/legalTerms'
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    T&C
                </a>
            </PrivacyBox>
        </InfoBox>
  );
};

export default SocietyInfoMobile;
