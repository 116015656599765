import { FC, ChangeEvent, useEffect, useState, useRef } from 'react';

// types
import { DateInputProps } from './DateInput.component.d';

// style
import {
    DateInputContainer,
    Separator,
    Input
} from './style/DateInput.style';
import { DateInputState } from './types/DateInputState';
import { shallowEqual } from 'react-redux';

const DateInput: FC<DateInputProps> = ({
    value,
    minDate,
    allowEmpty,
    isDisabled,
    isDisabledOpacity,
    height,
    breakPoint,
    onChange
}) => {

    useEffect(() => {
        validate(value);
    }, [value]);

    const validate = (newValue: DateInputState) => {
        let newErrorMessage: string|undefined = undefined;
        const d = Date.parse(`${newValue.year}-${newValue.month.padStart(2, '0')}-${newValue.day.padStart(2, '0')}T${newValue.hour.padStart(2, '0')}:${newValue.minute.padStart(2, '0')}`);
        if(isNaN(d)) newErrorMessage= (!allowEmpty || `${newValue.year || ''}${newValue.day || ''}${newValue.hour || ''}${newValue.minute || ''}`.length>0) ? 'Invalid date' : undefined;
        else if(minDate && d<=minDate.getTime()) newErrorMessage='Under minDate';
        if(!shallowEqual(value, newValue) && onChange) onChange({...newValue, errorMessage: newErrorMessage});
    }

    return (
        <DateInputContainer isInputInvalid={!!value.errorMessage} isDisabled={isDisabled} isDisabledOpacity={isDisabledOpacity} height={height} breakPoint={breakPoint}>
            <Input value={value.day || ''} title='Giorno' placeholder='00' onChange={(event: ChangeEvent<HTMLInputElement>) => validate({...value, day: event.target.value.substring(0,2)})} onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}/>
            <Separator>/</Separator>
            <Input value={value.month || ''} title='Mese' placeholder='00' onChange={(event: ChangeEvent<HTMLInputElement>) => validate({...value, month: event.target.value.substring(0,2)})} onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}/>
            <Separator>/</Separator>
            <Input value={value.year || ''} title='Anno' placeholder='0000' width='41px' onChange={(event: ChangeEvent<HTMLInputElement>) => validate({...value, year: event.target.value.substring(0,4)})} onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}/>
            <Input value={value.hour || ''} title='Ora' placeholder='00' onChange={(event: ChangeEvent<HTMLInputElement>) => validate({...value, hour: event.target.value.substring(0,2)})} onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}/>
            <Separator height='100%'>:</Separator>
            <Input value={value.minute || ''} title='Minuto' placeholder='00' onChange={(event: ChangeEvent<HTMLInputElement>) => validate({...value, minute: event.target.value.substring(0,2)})} onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select()}/>
        </DateInputContainer>
    );
};

export default DateInput;
