import styled, { css } from 'styled-components';

// types
import { StarBoxProps } from './RatingStars.style.d';

// style
import colors from 'style/palette/palette';


export const StarBox = styled.button<StarBoxProps>`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    ${({isActive}) => isActive && (

        css`
            > svg path {
                fill: ${colors.primaryColor};
                fill-opacity: 1;
            }
        `
    )}
`;
