import styled, { css } from 'styled-components';

// types
import { TabButtonProps } from './HighwayPageMobile.style.d'

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const PageMobile = styled.div`

    width: 100%;
    height: 100%;

    padding: 0;

    @media ${devices.tablet} {
        
        padding: 40px;
    }
`;

export const TextContent = styled.div`

    margin: 0;
    padding: 25px 20px 0;

    overflow-y: hidden;
    overflow-x: hidden;

    height: calc(100vh - 219px - 69px);
`;


export const PageTitle = styled.h1`

    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    color: ${colors.white070};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media ${devices.tablet} {

        margin: 0 0 26px;

        font-size: 28px;
        line-height: 30px;
        font-weight: 400;
    }
`;

export const TextSeparator = styled.div`

    display: inline-block;
    margin: 0 8px;

    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;

    @media ${devices.tablet} {
        margin: 0 13px;
    }
`;

export const ProductName = styled.span`

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    
    color: ${colors.white};

    @media ${devices.tablet} {

        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
    }
`;

export const TabButtonsRow = styled.div`

    margin: 25px 10px 0;

    button:first-child {
        border-radius: 10px 0 0 0;
    }

    button:last-child {
        border-radius: 0 10px 0 0;
    }
`;

export const TabButton = styled.button<TabButtonProps>`

    margin: 0;
    padding: 12.5px 14px;

    background-color: ${colors.darkGrey};
    color: ${colors.white070};

    border: none;

    font-size: 13px;
    font-weight: 500;

    text-transform: capitalize;

    cursor: pointer;

    ${({isActive}) => isActive && (
        css`
            color: ${colors.white};
            background-color: ${colors.veryDarkGrey};

            cursor: default;
        `) 
    }
`;

export const TabContent = styled.div`

    height: 100%;
    /* parent cell height - title height - tabbuttons height - tabbuttons top margin */
    max-height: calc(100% - 24px - 41px - 25px);

    margin: 0;
    padding: 30px 25px;

    background-color: ${colors.veryDarkGrey};

    border-radius: 15px 15px 0 0;

    overflow-y: auto;
    overflow-x: hidden;
`;
