//routing
import { useNavigate } from "react-router-dom";

// hook
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { useIsMounted } from "usehooks-ts";

// component
import FilterShopDesktop from "./FilterCoach/layout/FilterShopDesktop/FilterShopDesktop.component";
import FilterShopMobile from "./FilterCoach/layout/FilterShopMobile/FilterShopMobile.component";
import HelpCard from "./HelpCard/HelpCard.component";
import CardCoach from "./CardCoach/CardCoach.component";
import { getLessonWithBrand } from "./services/AiutoPrivato.services";
import BankTransferModal from "components/BankTransferModal/BankTransferModal.component";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import LoadingMask from "components/ui/LoadingMask";

// style
import { 
    Coach,
    CoachCardBox,
    CoachTitle,
    Help,
    HelpCardBox,
    HelpTitle,
    Selection,
    TotalPage,
} from "./style/AiutoPrivatoMobile.style";
import ProductInfoModal from "components/ProductInfoModal/ProductInfoModal.component";


const AiutoPrivato = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const navigate = useNavigate();

    // const [activeFilter, setActiveFilter] = useState<string>('');
    const [activeFilter, setActiveFilter] = useState<string>(isAppMobile ? 'SENZA RISCHIO' : '');
    const [lessons, setLessons] = useState<any | undefined>(undefined);
    const [lessonWithCoachList, setLessonWithCoachList] = useState<any | undefined>(undefined);
    const [lessonWithoutCoachList, setLessonWithoutCoachList] = useState<any | undefined>(undefined);
    const [coachesId, setCoachesId] = useState<any[]>();
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isProductInfoModalVisible, setIsProductInfoModalVisible] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>('');

    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeProductInfoModal = () => setIsProductInfoModalVisible(false);

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const changeFilterOpenActive = (text: string) => {
        setActiveFilter(text);
    };

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        // setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const modalGetPaymentID = (paymentID: number | undefined) => {
        setPaymentTransferID(paymentID);
    };

    const setNewProductId = (newProductId: string, productName: string, productPrice: string) => {
        
        setIsProductInfoModalVisible(true);
        setProductId(newProductId);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
    };

    const publicInfoPageTransferPrice = (grossPrice: number) => {
        setChosenProductPrice(`${grossPrice}`)  
    };

    const getFilteredList = (lessonsList: any, filterCategory: string) => {
      
        // if (isAppMobile) {
        //     return lessonsList.filter((lesson: any) =>
        //         lesson.categories.some((category: any) => category.name.includes('SENZA RISCHIO'))
        //     );
        // };

        if (!filterCategory) {
            return lessonsList;
        }
    
        if (filterCategory === 'Altri') {
            return lessonsList.filter((lesson: any) => lesson.categories.length === 0);
        };    

        return lessonsList.filter((lesson: any) => lesson.categories.some((category: any) => category.name.includes(filterCategory.toUpperCase())));
    };

    const getSeparatedLessons = (lessonsList: any[] | undefined) => {

        if (lessonsList === undefined) return;

        let separatedLessonsList: {lessonsWithC: any[], lessonsWithoutC: any[]} = {
            lessonsWithC: [],
            lessonsWithoutC: []
        };

        lessonsList.forEach((lesson: any) => {

            if (lesson.productLessonPack.coach) return separatedLessonsList.lessonsWithC.push(lesson);
            separatedLessonsList.lessonsWithoutC.push(lesson);
        });

        return separatedLessonsList;
    };

    const getCoaches = (lessonsWithC: any) => {
        
        const onlineCoachesId: Set<any[]> = new Set(lessonsWithC.map((lesson: any) => lesson.productLessonPack.coach.userId));
        return onlineCoachesId;
    };

    const getLesson = async () => {

        try {
            loadingOn();
            const response = await getLessonWithBrand();
            console.log("LEZIONI", response);
            
            setLessons(response);
            loadingOff();
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error')
            loadingOff();
            console.warn(error);
        }
    };

    useEffect(() => {
        getLesson()
        // userBalance();
    },[]);

    useEffect(() => {
        
        if (lessons === undefined) return;

        const separatedLessons = getSeparatedLessons(getFilteredList(lessons, activeFilter));            

        if (separatedLessons === undefined) return;
        setLessonWithCoachList(separatedLessons.lessonsWithC);
        setLessonWithoutCoachList(separatedLessons.lessonsWithoutC)

        if (separatedLessons.lessonsWithC === undefined) return;
        const onlineCoachesId: Set<any[]> = getCoaches(separatedLessons.lessonsWithC);
        console.log("COACHES ID:", onlineCoachesId);
        setCoachesId(Array.from(onlineCoachesId));           
    },[lessons]);

    // useEffect(() => {

    //     if (lessons === undefined) return;
        
    //     const filteredList = getSeparatedLessons(getFilteredList(lessons, activeFilter));
    //     if (filteredList ===  undefined) return;
        
    //     if (filteredList.lessonsWithC !== undefined) {
            
    //         setLessonWithCoachList(filteredList.lessonsWithC);
    //         setCoachesId(Array.from(getCoaches(filteredList.lessonsWithC)));
    //     };
    //     if (filteredList.lessonsWithoutC !== undefined) setLessonWithoutCoachList(filteredList.lessonsWithoutC);
    // }, [activeFilter]);

    useEffect(() => {
        if (lessons === undefined) return;
      
        const filteredList = getSeparatedLessons(getFilteredList(lessons, activeFilter));
        if (filteredList === undefined) return;
      
        if (filteredList.lessonsWithC !== undefined) {
          const sortedList = filteredList.lessonsWithC.sort(
            (lessonA: any, lessonB: any) =>
              lessonA.productLessonPack.price - lessonB.productLessonPack.price
          );
          setLessonWithCoachList(sortedList);
          setCoachesId(Array.from(getCoaches(sortedList)));
        }
        if (filteredList.lessonsWithoutC !== undefined)
          setLessonWithoutCoachList(filteredList.lessonsWithoutC);
      }, [lessons, activeFilter]);
    
    const isHelpTitleVisible = ((lessonWithoutCoachList !== undefined) && (lessonWithoutCoachList.length !== 0));
    const isCoachTitleVisible = ((lessonWithCoachList !== undefined) && (lessonWithCoachList.length !== 0));

    if (isLoading) return (
        <LoadingMask 
            paddingTop='200px'
            isCenter
            size='page'
        />
    );

    return(
        <>
            <TotalPage>
                <Selection>               
                    {isAppMobile && <FilterShopMobile changeFilterOpenActive={changeFilterOpenActive} />}

                    {!isAppMobile && <FilterShopDesktop changeFilterOpenActive={changeFilterOpenActive} />}
                </Selection>

                <HelpCardBox>
                    {isHelpTitleVisible && <HelpTitle>L’aiuto migliore per Te</HelpTitle>}
                    
                    <Help> 
                        {(lessonWithoutCoachList !== undefined) && lessonWithoutCoachList.map((lesson: any, index: number) => {
                            return (
                                <HelpCard 
                                    key={index}
                                    lesson={lesson}
                                    openBankTransferModal={openBankTransferModal}
                                    transferModalHandler={transferModalHandler}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    productPriceHandler={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    onInfoButtonClick={setNewProductId}
                                />
                            )
                        })}
                    </Help>
                </HelpCardBox>

                <CoachCardBox>
                    {isCoachTitleVisible && <CoachTitle>Coach</CoachTitle>}

                    <Coach>
                        {(lessonWithCoachList !== undefined) && coachesId && coachesId.map((coachId: any, index: number) => {
                            
                            const coachLessons = lessonWithCoachList.filter((lesson: any) => lesson.productLessonPack.coach.userId === coachId);                      
                            return (
                                <CardCoach 
                                    key={index}
                                    lessons={coachLessons}
                                    openBankTransferModal={openBankTransferModal}
                                    transferModalHandler={transferModalHandler}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    productPriceHandler={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}                           
                                />
                            )
                        })}
                    </Coach> 
                </CoachCardBox>
            </TotalPage>
        
            {isBankTransferModalVisible && (
                <BankTransferModal
                onCloseButtonModal={closeBankTransferModal}
                productName={chosenProductName}
                productPrice={chosenProductPrice}
                paymentTransferID={paymentTransferID}
                />
            )}

            {isLibertyPaymentModalVisible && (
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={chosenProductID!}
                    productType='Lesson'
                    price={productLibertyPrice}
                />
            )}

            {isProductInfoModalVisible && (
                <ProductInfoModal
                    onCloseButtonModal={closeProductInfoModal}
                    productInfoId={productId}
                    onBankModalOpen={openBankTransferModal}
                    onLibertyModalOpen={openLibertyPaymentModal}
                    modalGetPaymentID={modalGetPaymentID}
                    libertyProductID={libertyProductID}
                    publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                />
            )}
        </>
    );
};


export default AiutoPrivato;
