import styled, { css } from 'styled-components';

// types
import { ContainerProps, ControlsProps } from './MediaPlayer.style.d';

// style
import colors from 'style/palette/palette';


export const Controls = styled.div<ControlsProps>`

    width: 100%;

    background: linear-gradient(to bottom, ${colors.black000}, ${colors.black050});

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: ${({ isPaused }) => isPaused ? "1" : "0"};
    transition: opacity 150ms ease-in-out;
`;

export const Container = styled.div<ContainerProps>`

    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth ?? "1000px"};

    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight ?? "none"};

    border-radius: 15px 15px 0 0;
    margin: 0 auto;

    background: black;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    video {

        max-width: 100%;
        max-height: 100%;
    }

    :hover ${Controls},
    :focus-within ${Controls} {

        opacity: 1;
    }

    ${({ isAppMobile }) => isAppMobile &&
    
        css`
            border-radius: 0;
        `
    }

    ${({ isTheater, isFullScreen }) => (isTheater || isFullScreen) && (

        css`
            width: 100%;
            max-width: initial;
        `
    )}

    ${({ isTheater }) => isTheater && (

        // security check: this is to not take all screen in fullscreen mode
        css`
            max-height: 90vh;
        `
    )}

    ${({ isFullScreen }) => isFullScreen && (

        css`
            max-height: 100vh;
        `
    )}
`;

export const VideoContainer = styled.div`

    width: 100%;
    height: 100%;

    background: linear-gradient(to bottom, ${colors.black000}, ${colors.black050});
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonsBar = styled.div`

    width: 100%;

    padding: 8px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;
    flex-direction: row;
`;

export const LeftControls = styled.span`

    width: auto;

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
`;

export const RightControls = styled.span`

    width: auto;

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Button = styled.button`

    width: 48px;

    margin: 0;
    padding: 0 3px;

    border: none;
    background: ${colors.transparent};

    font-size: 15px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};
    opacity: 0.85;

    cursor: pointer;

    > svg {

        max-width: 48px;
        max-height: 48px;

        path {

            fill: ${colors.white};
        }
    }

    :hover {
        
        opacity: 1;
    }
`;

export const Volume = styled.span`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: 0;
    flex-direction: row;

    > div {

        min-width: 0;
        width: 0;
                
        transform-origin: left;
        transform: scaleX(0);
        transition: all 200ms ease-in-out;

        :focus {

            width: 80px;
            transform: scaleX(1);
        }
    }

    :hover > div {

        width: 80px;
        transform: scaleX(1);
    }
`;

export const Time = styled.span`

    padding: 0 8px;
    color: ${colors.white};
`;

export const VolumeSlider = styled.input`

    min-width: 0;
    width: 100%;

    margin: 0;
    padding: 0;

    cursor: pointer;

    /* basic reset */
    -webkit-appearance: none;
    background: ${colors.transparent};

    /* thumb stuff */
    &::-webkit-slider-thumb {

        -webkit-appearance: none;

        width: 12px;
        height: 12px;

        margin: 0;
        padding: 0;

        border-radius: 50%;
        border: none;
        box-shadow: none;
        background: ${colors.white};

        transform: translateY(-4px);

        cursor: pointer;
    }

    &::-moz-range-thumb {

        width: 12px;
        height: 12px;

        margin: 0;
        padding: 0;

        border-radius: 50%;
        border: none;
        box-shadow: none;
        background: ${colors.white};

        cursor: pointer;
    }

    &::-ms-thumb {

        width: 12px;
        height: 12px;

        margin: 0;
        padding: 0;

        border-radius: 50%;
        border: none;
        box-shadow: none;
        background: ${colors.white};

        cursor: pointer;
    }

    /* track staff */
    &::-webkit-slider-runnable-track {

        width: 100%;
        height: 3px;

        margin: 0;
        padding: 0;

        box-shadow: none;
        border-radius: 0;
        border: none;
        background: ${colors.white};

        cursor: pointer;
    }

    &::-moz-range-track {

        width: 100%;
        height: 3px;

        margin: 0;
        padding: 0;

        box-shadow: none;
        border-radius: 0;
        border: none;
        background: ${colors.white};

        cursor: pointer;
    }

    &::-ms-track {

        width: 100%;
        height: 3px;

        margin: 0;
        padding: 0;

        box-shadow: none;
        border-radius: 0;
        border: none;
        background: ${colors.white};

        cursor: pointer;
    }

    &::-ms-fill-lower {

        background: ${colors.white};
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    &::-ms-fill-upper {

        background: ${colors.white};
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
`;

export const PlayButtonOverlay = styled.div<{ backgroundAsset?: string | undefined}>`

    width: 100%;
    height: 100%;

    border: none;
    border-radius: 0;
    background: ${colors.transparent};
    background-image: ${({ backgroundAsset }) => backgroundAsset ?? "none"};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
`;

export const OverlayButton = styled.button`

    width: auto;

    margin: 0;
    padding: 0;

    border: none;
    border-radius: 0;
    background: ${colors.transparent};

    cursor: pointer;
`;