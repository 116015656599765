import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { PersonalAssistantNotesModalProps } from './PersonalAssistantNotesModal.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as AssistantNotesSVG } from './assets/assistant-note.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    NotesBox
} from './style/PersonalAssistantNotesModal.style';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const PersonalAssistantNotesModal: FC<PersonalAssistantNotesModalProps> = ({ onCloseButtonModal, choicedLesson }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [lessonToView, setLessonToView] = useState<any>(choicedLesson);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const personalAssistantNote = () => {

        // if (choicedLesson.coachNotes) return choicedLesson.coachNotes;
        if (lessonToView.coachNotes) return ((lessonToView.coachNotes !== undefined && lessonToView.coachNotes !== '') ?
            lessonToView.coachNotes :
            "Nessuna nota condivisa");
        return (lessonToView.noteCondivise !== undefined && lessonToView.noteCondivise !== '') ?
            lessonToView.noteCondivise :
            "Nessuna nota condivisa"
    };

    const finalPersAssNotes = personalAssistantNote();

    useEffect(() => {
        setLessonToView(choicedLesson);
    }, [choicedLesson]);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <AssistantNotesSVG />
                    </IconBox>

                    <Title>
                        NOTE DEL TUO ASSISTENTE PERSONALE
                    </Title>

                    <TextContent>
                        Qui sotto puoi leggere le note 
                        che il tuo assistente personale 
                        ti ha lasciato per questa lezione:
                    </TextContent>

                    <NotesBox>
                        {finalPersAssNotes}
                    </NotesBox>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default PersonalAssistantNotesModal;
