import styled, { css } from "styled-components";
import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";



export const VideoCard = styled.div<{inCarousel?: boolean, isIMGTestimonial?: boolean}>`
    
    /* MOBILE CAROUSEL */ 
    /* max-height: 196.875px; */

    display: flex;
    align-items: center;
    justify-content: center;
    
    overflow: hidden;

    background-color: ${colors.black};
    border-radius: 10px;

    > img {
        width: 272px;
        height: 300px;
        border-radius: 10px;
    }

    & video {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        padding: 0;
    }

    /* MOBILE ALL TESTIMONIALS */
    ${({inCarousel})=> !inCarousel && css`
        max-height: none;
        border-radius: 10px 10px 0 0;

        & video {
            border-radius: 10px 10px 0 0;
        }
    `}

    /* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
        min-width: 288px;
        width: 448px;
        height: 252px;
        border-radius: 15px 0 0 15px;

        & video {
            width: 100%;
            height: auto;
            border-radius: 15px 0 0 15px;
        }

		align-items: stretch;
		
		width: 448px;
		height: 252px;
		border-radius: 15px 0 0 15px;

		.youtube-video {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
		}

		& video {
			max-width: 100%;
			height: auto;
			border-radius: 15px 0 0 15px;
		}

		& iframe {
			border-radius: 15px 0 0 15px;
			width: 100%;
			height: 100%;
		}

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				max-width: 288px;
				max-height: 162px;
			`}

		${({ isIMGTestimonial }) =>
			isIMGTestimonial &&
			css`
				max-width: 272px;
				max-height: 300px;
				border-radius: 15px;

				> img {
					width: 272px;
					height: 300px;
					border-radius: 15px;
				}
			`}
	}
`;

export const InfoCard = styled.div<{ inCarousel?: boolean }>`
	/* MOBILE CAROUSEL */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.5rem;

	width: 110px;
	padding: 0.4rem 1.25rem 1.0625rem;

	background-color: ${colors.darkGrey};
	/* box-shadow: 0.431352px 0.431352px 1.29406px ${colors.black020}; */
	border-radius: 0px 10px 10px 0px;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			width: auto;
			border-radius: 0px 0px 10px 10px;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		width: 190px;
		padding: 1rem;
		gap: 1rem;
		border-radius: 0px 15px 15px 0px;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				width: 130px;
				gap: 0.7rem;
				border-radius: 0px 10px 10px 0px;
			`}
	}
`;

export const TestimonialTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 20%;
	width: 100%;
	gap: 0.5rem;
`;

export const TestimonialProfilePic = styled.img`
	font-size: 0.5rem;
	color: ${colors.white};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
`;

export const TestimonialProfilePicBackground = styled.div<{
	url: string;
	inCarousel?: boolean;
}>`
	position: relative;
	text-align: center;

	/* MOBILE CAROUSEL */
	background-image: url('${({ url }) => url}');
	background-size: cover;
	background-position: center;
	border-radius: 50%;
	width: 25px;
	height: 25px;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			width: 38px;
			height: 38px;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		width: 50px;
		height: 50px;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				width: 32px;
				height: 32px;
			`}
	}
`;

export const TestimonialLabel = styled.p<{ inCarousel?: boolean }>`
	/* max-width: 60%; riabilitare quando si mette profile Pic */

	font-weight: 600;
	color: ${colors.primaryColor};

	overflow: hidden; /* hide overflowed content */
	text-overflow: ellipsis; /* add ellipsis (...) to indicate truncated content */
	white-space: pre-line; /* preserve line breaks */

	/* MOBILE CAROUSEL */
	font-size: 0.6rem;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			font-size: 0.8125rem;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		font-size: 1rem;
		line-height: 1.2rem;
		max-height: 3.6rem;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				font-size: 0.6rem;
				line-height: 0.8rem;
				max-height: 2.4rem;
			`}
	}
`;

export const TestimonialTagBox = styled.div<{ inCarousel?: boolean }>`
	/* MOBILE CAROUSEL */
	display: flex;
	justify-content: center;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 200px;
	padding: 0.1rem;
	height: 10%;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			margin-left: auto;
			padding: 0.1rem 0.5rem;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		padding: 0;
		margin-left: 0;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) => !inCarousel && css``}
	}
`;

export const TestimonialTag = styled.h5<{ inCarousel?: boolean }>`
	/* MOBILE CAROUSEL */
	margin: 0;

	color: ${colors.blackGrey};

	width: 75.66px;

	font-size: 0.5rem;
	text-align: center;
	font-weight: 600;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			font-size: 0.625rem;
			width: auto;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		width: 123px;
		/* margin: 14px 0 0; */

		border-radius: 20px;

		font-size: 0.8rem;
		line-height: 1.2rem;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				width: 79.85px;
				font-size: 0.5rem;
				line-height: 0.8rem;
			`}
	}
`;

export const TestimonialInfo = styled.p<{ inCarousel?: boolean }>`
	/* MOBILE CAROUSEL */
	margin: 0;
	padding: 0;
	height: 70%;

	color: ${colors.white};

	font-size: 0.5rem;
	line-height: 0.8rem;
	max-height: 4.8rem;

	overflow: hidden; /* hide overflowed content */
	text-overflow: ellipsis; /* add ellipsis (...) to indicate truncated content */
	white-space: pre-line; /* preserve line breaks */

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			font-size: 0.75rem;
			line-height: 1.125rem;
			max-height: 3.375rem;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		font-size: 0.8rem;
		line-height: 1.3rem;
		max-height: 7.8rem;

		/* DESKTOP ALL TESTIMONIALS */
		${({ inCarousel }) =>
			!inCarousel &&
			css`
				font-size: 0.6rem;
				line-height: 0.8rem;
				max-height: 5.6rem;
			`}
	}
`;

export const DarkOverlay = styled.div<{ cIndex: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	border-radius: 15px;
	transition: background-color 1s ease-out, backdrop-filter 1s ease-out;

	${({ cIndex }) =>
		Math.abs(cIndex) === 1 &&
		css`
			z-index: 99; // Make sure it's above the TestimonialCards
			background-color: rgba(0, 0, 0, 0.4); // Adjust opacity as desired
			backdrop-filter: blur(1px); // Adjust blur radius as desired
		`}

	${({ cIndex }) =>
		Math.abs(cIndex) === 2 &&
		css`
			z-index: 99; // Make sure it's above the TestimonialCards
			background-color: ${colors.black060}; // Adjust opacity as desired
			backdrop-filter: blur(2px); // Adjust blur radius as desired
		`}
`;

export const TestimonialWrapper = styled.div<{
	inCarousel?: boolean;
	cIndex?: number;
	opacity?: number;
	hasIMGTestimonials?: boolean;
}>`
	/* MOBILE CAROUSEL */
	display: flex;
	justify-content: center;
	align-items: stretch;

	/* max-width: 350px; */

	border-radius: 10px;
	box-shadow: 1px 1px 2px ${colors.black020};

	opacity: ${({ opacity }) => opacity};
	transition: opacity 1s ease-in-out;

	/* MOBILE ALL TESTIMONIALS */
	${({ inCarousel }) =>
		!inCarousel &&
		css`
			/* max-height: 135px; */
			flex-direction: column;
		`}

	/* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
		flex-direction: row;

		max-width: 638px;
		max-height: 252px;

		border-radius: 15px;

		${({ inCarousel }) =>
			inCarousel &&
			css`
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: transform 1s ease-out;
			`}

		${({ cIndex }) =>
			cIndex !== undefined &&
			css`
				z-index: calc(10 - ${Math.abs(cIndex)});
				transform: translate(calc(-50% + 30% * ${cIndex}), -50%)
					scale(calc(100% - 20% * ${Math.abs(cIndex)}));
			`}

        ${({ inCarousel, cIndex }) =>
			inCarousel &&
			cIndex !== 0 &&
			css`
				cursor: pointer;
			`}

        /* DESKTOP ALL TESTIMONIALS */
        ${({ inCarousel }) =>
			!inCarousel &&
			css`
				max-width: 418px;
				max-height: 162px;
			`}
 
        ${({ hasIMGTestimonials, cIndex }) =>
			hasIMGTestimonials &&
			cIndex !== 0 &&
			cIndex !== undefined &&
			css`
				max-width: 272px;
				max-height: 300px;

				transform: translate(calc(-50% + 60% * ${cIndex}), -50%)
					scale(calc(100% - 20% * ${Math.abs(cIndex)}));
			`}

        ${({ hasIMGTestimonials, cIndex }) =>
			hasIMGTestimonials &&
			cIndex === 0 &&
			css`
				box-shadow: unset;
			`}
	}
`;
