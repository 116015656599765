import { FC, useRef } from 'react';

// types
import { BlurTogglerButtonProps } from './BlurTogglerButton.d';

// style
import { Button } from '../style/MediaPlayer.style';


/**
 * Blur toggle button
 * 
 * @author Alessio Grassi
 * 
 * @param isOpen - toggler of the assets of the button
 * @param onClick - open/close toggle function
 * @param assetOpen - asset visible when button is closed
 * @param assetClose - asset visible when button is open
 * 
 * @returns JSX
 */


const BlurTogglerButton: FC<BlurTogglerButtonProps> = ({
        isOpen,
        onClick,
        assetOpen,
        assetClose
    }) => {

    const buttonRef = useRef<HTMLButtonElement>(null);

    const clickHandler = () => {

        if (!onClick || onClick === undefined) return;
        onClick();
        if (!buttonRef || !buttonRef?.current) return;
        buttonRef.current.blur();
    };

    return (
        <Button
            onClick={clickHandler}
            ref={buttonRef}
        >
            {isOpen && assetOpen}
            {!isOpen && assetClose}
        </Button>
    );
};

BlurTogglerButton.defaultProps = {

    isOpen: true,
    onClick: () => {},
    assetOpen: <></>,
    assetClose: <></>
};

export default BlurTogglerButton;
