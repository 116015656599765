import styled from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const BestOddsFilterContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    max-width: 650px;

`;

export const FiltersContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;

`;

export const ResetButton = styled(Button)`   
    position: absolute;
    top: -13px;
    right: 10px;
    width: 50px;
    height: 18px;
    padding: 0;
    font-size: 12px;
    background-color: #b3b3b3;
    ::after{
        content: 'Reset';
    }
`

export const SearchButton = styled(Button)`
    width: 200px;
    height: 36px;
    padding: 0;
`;

export const MarketsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    width: 100%;
    background-color: #29353E66;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    padding: 10px;
`;