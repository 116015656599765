import { FC, useEffect, useState } from 'react';

// routing
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getEnvironmentMonolithLinkUrl } from 'configs/config.helper';
import { getNewNotifications } from './services/Header.services';

// components
import Navbar from './Navbar';
import BrandSelector from './BrandSelector';
import UserProfileSubmenu from './UserProfileSubmenu';
import EmptyUserImageProfile from 'components/EmptyUserImageProfile';

// types
import { HeaderProps } from './Header.d';

// assets
import { ReactComponent as BrandSVG } from './assets/brand-icon.svg';
import { ReactComponent as SupportSVG } from './assets/support-icon.svg';
import { ReactComponent as LoginSVG } from './assets/login-icon.svg';
import { ReactComponent as NotifySVG } from './assets/notify-icon.svg';
import { ReactComponent as LibertySVG } from './assets/libertyPoint.svg';
import { ReactComponent as LibertyBuySVG } from './assets/buy.svg';

// style
import {
    Container,
    Content,
    BrandBox,
    SignupText,
    LoginArea,
    SupportBox,
    NotifyBotton,
    NewNotificationAlert,
    LoginBox,
    UserArea,
    UserData,
    UserProfileImage,
    UserInfo,
    UserInfoTitle,
    LibertyBox,
    PlusPointIcon,
} from './style/Header.style';
import config from 'configs/config';
import store from 'redux/store';


/**
 * Header
 * 
 * @author Alessio Grassi
 * 
 * @param isSubmenuOpen -  the "open" state of the Submenu
 * @param onSubmenuButtonClick -  toggle handler of the Submenu
 * @param onSubmenuLinkClick -  handler for Submenu's closure
 * 
 * @returns JSX
 */


const Header: FC<HeaderProps> = ({ isSubmenuOpen, onSubmenuButtonClick, onSubmenuLinkClick }) => {

    
    // const [hasUserNewNotifications, setHasUserNewNotifications] = useState<boolean>(false);
    const { tokenID, name: userName, facebookProfilePicture, messagesNotSeen } = useAppSelector(state => state.user);
    const location = useLocation();
    const navigate = useNavigate();

    const isLandingPath = location.pathname.includes('/lp');
    const isPublicPath = location.pathname.includes('/public');

    const notifyButtonHandler = () => {
        
        // setHasUserNewNotifications(false);
        navigate('/reservedArea/notification');
    };

    const libertyButtonHandler = () => {
        
        // setHasUserNewNotifications(false);
        navigate('/reservedArea/liberty-point');
    };

    const generalSupport = () => {
        navigate('/supporto');
    }

    const ldlSupport = () => {
        window.open(`https://supporto.liberidalavoro.it/`, '_blank');
    }

    // const checkNotifications =async () => {
        
    //     try {
    //         const userHasNewNotifications = await getNewNotifications();

    //         setHasUserNewNotifications(userHasNewNotifications);
    //     } catch (error: any) {
    //         console.warn("Unable checking new Notifications.", error);
            
    //     }
    // };

    // useEffect(() => {

    //     if (!tokenID) return;
    //     checkNotifications();
    // }, [tokenID]);

    const finalUserName = `${userName}`;
    const isUserLoggedIn = !!tokenID;
    const brandSvgLinkTo = isUserLoggedIn ? '/reservedArea/' : '/login';
    const isHeaderHidden = (isLandingPath || isPublicPath);

    const isTest = config.environment === 'test'

    if (isHeaderHidden) {

        return (
            <></>
            // <Container>
            //     <Content>
            //         <BrandBox>
            //             <BrandSVG />
            //         </BrandBox>
            //     </Content>
            // </Container>
        )
    };

    return (
        <Container isTest={isTest}>
            <Content>
                {/* <BrandBox 
                    isNavbarVisible={isUserLoggedIn}
                    onClick={onSubmenuLinkClick}    
                >
                    <Link to={brandSvgLinkTo}>
                        <BrandSVG />
                        {isTest && <>TEST</>}
                    </Link>
                </BrandBox> */}

                <BrandBox 
                    isNavbarVisible={isUserLoggedIn}
                    onClick={onSubmenuLinkClick}    
                >
                    <BrandSelector />
                </BrandBox>
                
                {!isUserLoggedIn && (
                    <>
                        <SignupText>
                            <Link to='/sign-up'>
                                Iscriviti Ora!
                            </Link>
                        </SignupText>

                        <LoginArea>
                            {store.getState().ui.brand?.id === 1 && 

                                <SupportBox 
                                    onClick={ldlSupport}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <SupportSVG />
                                    Supporto
                                </SupportBox>
                            }

                            {(store.getState().ui.brand?.id === 2 || store.getState().ui.brand?.id === 3 || store.getState().ui.brand?.id === 4) &&
                                <SupportBox 
                                    onClick={generalSupport}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <SupportSVG />
                                    Supporto
                                </SupportBox>
                            }
                            <LoginBox>
                                <Link to='/login'>
                                    <LoginSVG />
                                    Accedi
                                </Link>
                            </LoginBox>
                        </LoginArea>
                    </>
                )}

                {isUserLoggedIn && (
                    <>
                        <Navbar onClick={onSubmenuLinkClick}/>

                        <UserArea>
                            <LibertyBox
                                onClick={libertyButtonHandler}
                            >
                                <LibertySVG/>

                                <PlusPointIcon>
                                    <LibertyBuySVG/>
                                </PlusPointIcon>
                            </LibertyBox>
                            
                            {store.getState().ui.brand?.id === 1 && 
                                <SupportBox 
                                    onClick={ldlSupport}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <SupportSVG />
                                </SupportBox>
                            }

                            {(store.getState().ui.brand?.id === 2 || store.getState().ui.brand?.id === 3 || store.getState().ui.brand?.id === 4) &&
                                <SupportBox 
                                    onClick={generalSupport}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <SupportSVG />
                                </SupportBox>
                            }

                            <NotifyBotton
                                onClick={notifyButtonHandler}
                            >
                                <NotifySVG />
                                
                                {(messagesNotSeen!== undefined) &&
                                (messagesNotSeen !== 0) && (
                                    <NewNotificationAlert>
                                        {messagesNotSeen}
                                    </NewNotificationAlert>
                                )}
                            </NotifyBotton>

                            <UserData onClick={onSubmenuButtonClick}>
                                {!facebookProfilePicture && (
                                    <EmptyUserImageProfile
                                        isSmall
                                        isEditButtonDisabled
                                    />
                                )}

                                {facebookProfilePicture && (
                                    <UserProfileImage
                                        src={facebookProfilePicture}
                                        alt="User profile picture"
                                    />
                                )}

                                <UserInfo>
                                    <UserInfoTitle>
                                        {finalUserName}
                                    </UserInfoTitle>

                                    <span>Il mio profilo</span>
                                </UserInfo>
                            </UserData>
                        </UserArea>

                        <UserProfileSubmenu
                            isSubmenuOpen={isSubmenuOpen}
                            onLinkClick={onSubmenuLinkClick}
                        />
                    </>
                )}
            </Content>
        </Container>
    );
}

export default Header;
