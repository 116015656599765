import styled, { css } from 'styled-components';
import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette/palette';

export const CardBox = styled.div<{
	cIndex?: number;
	duration: number;
}>`
	position: absolute;
	max-height: 340px;

	& svg {
		height: 340px;
	}

	@media ${devices.desktop} {
		max-height: 466px;
		& svg {
			height: 466px;
		}
	}



	${({ duration }) =>
		duration !== undefined &&
		css`
			transition: transform ${duration}ms ease-in-out;
		`}

	${({ cIndex }) =>
		cIndex !== undefined &&
		css`
			z-index: calc(10 - ${Math.abs(cIndex)});
			transform: translate(
					calc(17% * ${cIndex}),
					calc(7% * ${Math.abs(cIndex)})
				)
				scale(calc(100% - 20% * ${Math.abs(cIndex)}));
		`}
`;

export const DarkOverlay = styled.div<{ cIndex: number; duration: number }>`
	position: absolute;
	top: 1.25%;
	left: 2.25%;
	width: 95.5%;
	height: 97%;
	z-index: 0;
	border-radius: 15px;

	${({ duration }) =>
		duration !== undefined &&
		css`
			transition: background-color ${duration}ms ease-out,
				backdrop-filter ${duration}ms ease-out;
		`}

	${({ cIndex }) =>
		Math.abs(cIndex) === 1 &&
		css`
			z-index: 99; // Make sure it's above the TestimonialCards
			background-color: rgba(
				255,
				255,
				255,
				0.2
			); // Adjust opacity as desired
			backdrop-filter: blur(1px); // Adjust blur radius as desired
		`}

	${({ cIndex }) =>
		Math.abs(cIndex) === 2 &&
		css`
			z-index: 99; // Make sure it's above the TestimonialCards
			background-color: rgba(
				255,
				255,
				255,
				0.25
			); // Adjust opacity as desired
			backdrop-filter: blur(2px); // Adjust blur radius as desired
		`}

	${({ cIndex }) =>
		Math.abs(cIndex) === 3 &&
		css`
			z-index: 99; // Make sure it's above the TestimonialCards
			background-color: rgba(
				255,
				255,
				255,
				0.35
			); // Adjust opacity as desired
			backdrop-filter: blur(3px); // Adjust blur radius as desired
		`}
`;

export const CardImage = styled.img<{ isBlurred: boolean }>`
	max-height: 340px;
	border-radius: 15px;

	@media ${devices.desktop} {
		max-height: 466px;
	}

	filter: ${(props) => (props.isBlurred ? 'blur(15px)' : 'none')};
`;

export const LoginButton = styled.button`
	background-color: ${colors.primaryColor};
	border: none;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	font-weight: 600;
	text-transform: uppercase;
	align-self: center;
	cursor: pointer;
	position: absolute;
	bottom: 3rem;
	width: 90%;
	left: 5%;
	height: 15%;
	top: 42.5%;
	text-align: center;
`;
