import { FC, useState } from 'react';

// types
import { HighwayPageMobileProps } from './HighwayPageMobile.component.d';

// components
import MediaPlayer from 'components/MediaPlayer';
import CustomerRatingModal from 'components/CustomerRatingModal/CustomerRatingModal.component';
import CaptionText from '../../CaptionText/CaptionText.component';
import FormLessons from '../../FormLessons/FormLessons.component';
import PersonalAssistantNotesModal from '../../PersonalAssistantNotesModal/PersonalAssistantNotesModal';

// style
import { 
    PageMobile,
    PageTitle, 
    TextSeparator, 
    ProductName,
    TabButtonsRow,
    TabButton,
    TabContent,
    TextContent
} from './style/ProductPageMobile.style';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Product Page mobile
 * 
 * @author Frank Berni
 * 
 * @param isDatePickerButtonCompleted - state of the date picker button
 * @param isZoomLinkButtonCompleted - state of the zoom link button
 * @param isCommentButtonCompleted - state of the personal assistant comment button
 * @param isCustomerRatingButtonCompleted - state of the customer rating button
 * @param isPersonalAssistantNotesModalVisible - state of the personal assistant modal
 * @param isCustomerRatingModalVisible - state of the custome rating modal
 * @param openPersonalAsistantNotesModal - handler for open the personal assistant modal
 * @param closePersonalAsistantNotesModal - handler to close the personal assistant modal
 * @param openCustomerRatingModal - handler to open custome rating modal
 * @param closeCustomerRatingModal - handler to close customer rating modal
 * 
 * @returns JSX
 */


const HighwayPageMobile: FC<HighwayPageMobileProps> = ({
    isDatePickerButtonCompleted,
    isZoomLinkButtonCompleted,
    isCommentButtonCompleted,
    isCustomerRatingButtonCompleted,
    isPersonalAssistantNotesModalVisible,
    isCustomerRatingModalVisible,
    lessons,
    refreshDataHandler,
    openPersonalAsistantNotesModal,
    closePersonalAsistantNotesModal,
    openCustomerRatingModal,
    closeCustomerRatingModal,
    newLessonHandler
}) => {

    const [activeTabName, setActiveTabName] = useState<"caption" | "lessons">("caption");
    const [clickedLessonId, setClickedLessonId] = useState<number | undefined>(undefined);

    const choiceLessonClickHandler = (lessonId: number) => setClickedLessonId(lessonId);

    const captionButtonClickHandler = () => {

        if(activeTabName === "caption") return;
        setActiveTabName("caption");
    };

    const lessonsButtonClickHandler = () => {

        if(activeTabName === "lessons") return;
        setActiveTabName("lessons");
    };

    
    const isCaptionTabButtonActive = (activeTabName === "caption");
    const isLessonsTabButtonActive = (activeTabName === "lessons");
    
    let choicedLesson: any;
    if (clickedLessonId) choicedLesson = lessons.productLessonPack?.lessons?.filter((lesson: any) => lesson.id === clickedLessonId)[0];

    const isLoading = (lessons === undefined);

    return (
        <>
            <PageMobile>
                <MediaPlayer 
                    media={{
                        src:"https://www.viveredipoker.it/VideoSitoLDL/AutostradaUtente.mp4",
                        type:'video/mp4'
                    }}
                    preload='metadata'
                    maxHeight='219px'
                    isAppMobile
                />

                <TextContent>
                    <PageTitle>
                        <ProductName>L'Autostrada</ProductName>
                    </PageTitle>

                    <TabButtonsRow>
                        <TabButton 
                            isActive={isCaptionTabButtonActive}
                            disabled={isCaptionTabButtonActive}
                            onClick={captionButtonClickHandler}
                        >
                            Didascalia
                        </TabButton>

                        <TabButton 
                            isActive={isLessonsTabButtonActive}
                            disabled={isLessonsTabButtonActive}
                            onClick={lessonsButtonClickHandler}
                        >
                            Lezioni
                        </TabButton>
                    </TabButtonsRow>

                    <TabContent>
                        {isCaptionTabButtonActive && <CaptionText />}

                        {isLoading && 
                        isLessonsTabButtonActive && (
                            <LoadingMask 
                                isCenter
                                size='medium'
                            />
                        )}

                        {!isLoading && 
                        isLessonsTabButtonActive && (
                            <FormLessons 
                                isDatePickerButtonCompleted={isDatePickerButtonCompleted}
                                isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
                                isCommentButtonCompleted={isCommentButtonCompleted}
                                isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
                                onCommentButtonClick={openPersonalAsistantNotesModal}
                                onRatingButtonClick={openCustomerRatingModal}
                                lessons={lessons}
                                newLessonHandler={newLessonHandler}
                                choiceLessonHandler={choiceLessonClickHandler}
                            />
                        )}
                    </TabContent>
                </TextContent>
            </PageMobile>

            {isPersonalAssistantNotesModalVisible && (
                <PersonalAssistantNotesModal 
                    onCloseButtonModal={closePersonalAsistantNotesModal}
                    choicedLesson={choicedLesson}
                />
            )}

            {isCustomerRatingModalVisible && (
                <CustomerRatingModal 
                    onCloseButtonModal={closeCustomerRatingModal}
                    isHighway
                    lessonId={lessons.productLessonPack?.lessons[lessons.productLessonPack?.lessons?.length - 1]?.id}
                    refreshFunction={refreshDataHandler}
                />
            )}
        </>
    );
};

export default HighwayPageMobile;
