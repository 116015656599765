import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";
import styled, { css } from "styled-components";


export const TotalPage = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    padding: 0px;
    gap: 40px;

    border-radius: 15px 15px 0px 0px;
    
    @media ${devices.tablet} {

        padding: 40px 40px;
    }
`;

export const NotificationMessagesBox = styled.div<{isEmpty?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-grow: 1;
    flex-basis: calc(100% - 400px);

    width: 100%;
    text-align: center;

    ${({isEmpty}) => isEmpty && (

        css`
            justify-content: flex-start;
            padding: 0 0 0 400px;
        `
    )}
`;

export const NoMessagesText = styled.p`

    color: ${colors.white070};
`;
