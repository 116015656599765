import { FC, ReactElement, SVGProps } from 'react';

//assets
import { ReactComponent as FilterMenuIconSVG } from './assets/filterMenuIcon.svg';

//style
import {
    FilterShopSelector,
    DescriptionFilterBox,
    FilterText
} from './style/FilterShopButtonMobile.style';

const FilterShopButtonMobile: FC<{
    icon: ReactElement<SVGProps<SVGElement>>, 
    text: string, 
    isActive?: string, 
    onButtonClick: (text: string, position: number) => void, 
    showMenuIcon?: boolean, 
    position: number,
    visibleButtonText: string,
    clearSubMenuHandler?: () => void
}> = ({
    icon, 
    text, 
    isActive, 
    onButtonClick, 
    showMenuIcon = true, 
    position,
    visibleButtonText,
    clearSubMenuHandler
}) => {

    const onClickSelectorFilterHandler = () => {
        
        onButtonClick(text, position);
    };

    const isButtunActive = (isActive === text);
    return (
        
        <FilterShopSelector isActive={isButtunActive} onClick={onClickSelectorFilterHandler} text={visibleButtonText}>
            <DescriptionFilterBox>
                {icon}

                <FilterText>{text}</FilterText>
            </DescriptionFilterBox>

            {showMenuIcon && <FilterMenuIconSVG />}

        </FilterShopSelector>
    );
};

export default FilterShopButtonMobile;