import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const TableContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Table = styled.table<{borderSpacing?: string}>`
    background-color: #29353e5e;
    color: white;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
    ${(props) => props.borderSpacing && css`border-spacing: ${props.borderSpacing};`}
`;

export const THead = styled.thead`
    background-color: ${colors.grey};
    border-radius: 5px 5px 0 0;
    tr :first-child {
        border-radius: 5px 0 0 0;
    }
    tr :last-child {
        border-radius: 0 5px 0 0;
    }
    
`;

export const TBody = styled.tbody<{rowsHeight?: string}>`
    ${(props) => props.rowsHeight && (
        css`
            td {
                height: ${props.rowsHeight};
            }
        `
    )}
    /* tr:nth-of-type(even) {
        background-color: #29353E66;
    } */
`;

export const TR = styled.tr<{backgroundColor?: string, fontSize?: number, cellPaddingVert?: string, cellPaddingHor?: string}>`
    position: relative;
    :hover td:first-of-type>div {
        transform: scale(1);
    }
    ${(props) => props.backgroundColor && css`background-color: ${props.backgroundColor};`}
    ${(props) => props.fontSize && css`font-size: ${props.fontSize}px;`}
    td {
        padding: 5px;
        @media ${devices.mobile} {
            padding: 8px;
        };
        @media (max-width: 344px) {
            padding: 5px 3px;
        };
        ${(props) => props.cellPaddingVert && 
            css`
                padding-top: ${props.cellPaddingVert}!important;
                padding-bottom: ${props.cellPaddingVert}!important;
            `
        }
        ${(props) => props.cellPaddingHor && 
            css`
                padding-left: ${props.cellPaddingHor}!important;
                padding-right: ${props.cellPaddingHor}!important;
            `
        }
    }
`;

export const TH = styled.th`
    border: 0;
    white-space: nowrap;
    transition: all 0.5s;
    padding: 8px;
    font-size: 13px;
    @media (max-width: 369px) {
        padding: 5px 2px;
    };
`;

export const TD = styled.td<{borderLeft?: string, borderRight?: string, cursor?: string, fontSize?: number, fontWeight?: number, color?: string}>`
    position: relative;
    border: 0;
    white-space: nowrap;
    transition: all 0.5s;
    font-weight: 400;
    text-align: center;
    ${(props) => props.borderLeft && css`border-left: ${props.borderLeft};`}
    ${(props) => props.borderRight && css`border-right: ${props.borderRight};`}
    ${(props) => props.cursor && (
        css`
            cursor: ${props.cursor};
            :hover {
                opacity: 0.5;
            }
        `
    )}
    ${(props) => props.fontSize && css`font-size: ${props.fontSize}px;`}
    ${(props) => props.fontWeight && css`font-weight: ${props.fontWeight};`}
    ${(props) => props.color && css`color: ${props.color};`}
`;

export const TableFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	width: 90%;
	padding: 10px;
	margin-top: 10px;
`;

export const FooterNavigationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	> svg {
		fill: white;
		margin: auto;
	}
	&:disabled {
		cursor: not-allowed;
		> svg {
			fill: ${colors.white009};
		}
	}
`;

export const FooterPagesButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export const FooterPageButton = styled.button`
    font-size: 14px;
    color: white;
    background: none;
	border: none;
	cursor: pointer;
    outline: none;
    border-radius: 2px;
    :focus {
        background-color: #000b4355;
    }
	&:disabled {
		cursor: default;
        background-color: #000b43;
	}
`;

export const CoverBets = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    gap: 10px;
    svg {
        cursor: pointer;
        :hover {
            opacity: 0.8;
        }
        transition: opacity .3s ease-in-out;
    }
`;
export const OddsMatchIconsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    svg {
        cursor: pointer;
        :hover {
            opacity: 0.8;
        }
        transition: opacity .3s ease-in-out;
    }
`;

export const AddButtons = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    transform: scale(0);
    transition: all 0.5s;
    z-index: 2;
`;

export const AddButton = styled.div<{backgroundColor: string}>`
    cursor: pointer;
    background-color: ${props => props.backgroundColor};
    color: black;
    font-weight: 600;
    font-size: 16px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CellContainer = styled.div<{width?: string}>`
    display: flex;
    gap: 5px;
    justify-content: center;
    ${(props) => props.width && css`width: ${props.width};`}
`;

export const NoResults = styled.div`
    width: fit-content;
    background-color: ${colors.grey};
    border-radius: 5px;
    color: white;
    text-align: center;
    padding: 15px;
`;

export const SingleDivCell = styled.td`
    position: relative;
`;

export const EventInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 335px;
`;

export const EventCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;

export const EventInfoElement = styled.div`
    text-align: center;
`;