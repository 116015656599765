import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { BaseCard } from 'style/wrappers';


export const Page = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    padding: 40px 20px 20px;

    @media ${devices.tablet} {
        gap: 3rem;
        padding: 40px 40px 20px;
    }
`;

export const TopCardsRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
    width:100%;

    @media ${devices.tablet} {
        flex-direction: row;
        gap:1.25rem;
    }
`
export const ProfitsCard = styled(BaseCard)`
    background-color: ${colors.veryDarkGrey};
    color: ${colors.white};
    padding: 1rem;

    font-weight: 400;
    
    height: auto;
    
    
    border: 0.5px solid ${colors.darkGold};
    text-align: center;
    font-size: 30px;
    
    > span {
        color: ${colors.primaryColor}
    }
    
    @media ${devices.tablet} {
        width: 50%;
        padding: 2rem 1rem;
    }


`

export const BulletsCard = styled(BaseCard)`
    display: flex;
    font-style: italic;


    height: auto;

    color: ${colors.white};



    @media ${devices.tablet} {
        width: 50%;
        padding: 1rem 2.5rem;
    }
`

export const BulletsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-evenly;

    margin: auto;
    padding: 1rem 2rem;
`

export const BulletsItem = styled.div`
    display: flex;
    gap: 1rem;
`


export const GoldText = styled.span`

    font-weight: 600;
    color: ${colors.primaryColor};
`;

export const PageTitle = styled.h1` 

    color: ${colors.white};
    font-size: 24px;
    font-weight: 400;
    text-align: left;

    /* align-self: flex-start; */
    margin: 20px 0;
`

export const AllTestimonials = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
`


export const AllTestimonialsTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    margin: 0;
   


    color: ${colors.white};

    @media ${devices.tablet} {   
        font-size: 28px;
        margin-top: 7rem;
    }
`

export const AllTestimonialsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    width: 100%;

    @media ${devices.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`