import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    padding: 0 20px 25px;
    margin: 25px 0 0 0;

    @media ${devices.tablet} {

        padding: 30px 40px;
    }
`;

export const ModalPage = styled.div`

    gap: 0px;

  
`;


export const Title = styled.h1`

    width: 100%;
    margin: 0;
    padding: 0 0 0 10px;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.white};
    text-align: left;

    svg {
        margin: 8px 0 0 15px;
        cursor: pointer;
    }

    @media ${devices.tablet} {

        font-size: 28px;
    }
`;

export const FirstRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;
        gap: 40px;

        margin: 25px 0 0;
    }
`;

export const VideoBox = styled.div`

    width: 100%;
    max-width: 630px;
    height: 100%;
    max-height: 219px;

    margin: 0;
    background: ${colors.black};

    @media ${devices.tablet} {
        
        /* width: 100%; */
        width: 630px;
        max-width: 630px;
        height: 360px;
        max-height: none;

        margin: 0;

        border-radius: 15px;

        > div {
            height: inherit;
            border-radius: 15px !important;

            > div {
                background: none;
            }
        }

        video {
            height: inherit;
            border-radius: 15px !important;
        }
    }
`;

export const DescriptionBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 27px;

    margin: 20px 0 0;
    padding: 30px 25px 0;

    background: ${colors.darkGrey};
    color: ${colors.white};

    border-radius: 15px 15px 0 0;

    @media ${devices.tablet} {

        justify-content: space-between;
        gap: unset;
        
        width: 100%;
        max-width: 690px;
        height: 360px;

        margin: 0;

        border-radius: 15px;
    }

`;

export const DescriptionTitle = styled.h3`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 500;
    color: ${colors.primaryColor};
    text-transform: uppercase;
    text-align: left;
`;

export const TextDescriptionTitle = styled.h3`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 22px;
    font-weight: 400;
    color: ${colors.primaryColor};
    text-transform: uppercase;
    text-align: center;
`;


export const ImportantPhrase = styled.p<{isCenter?: boolean}>`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    text-align: ${({isCenter})=> isCenter? "center" : "left"};

    span {
        font-weight: 600;
    }


`;

export const BulletBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 10px;
`;

export const BulletRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {
        margin: 0 10px 0 0;
    }
`;

export const BulletPoint = styled.p`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-style: italic;
    font-weight: 400;
`;

export const ButtonBox = styled.div`

    height: 90px;
`;

export const CourseDescriptionContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    padding: 30px 35px;

    background: ${colors.darkGrey};
    color: ${colors.white};
    border-radius: 0 0 15px 15px;

    @media ${devices.tablet} {

        gap: 40px;

        margin: 40px 0 0;
        padding: 35px;

        border-radius: 15px;
    }

`;

export const FocusPointsBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    > div:nth-child(2) {

        padding: 20px 30px;

        border-top: 1px solid ${colors.veryDarkGrey};
        border-bottom: 1px solid ${colors.veryDarkGrey};
    }

    @media ${devices.tablet} {

        justify-content: space-between;
        flex-direction: row;
        gap: unset;

        > div {
            padding: 0 35px;
        }

        > div:nth-child(2) {
            padding: 0 40px;

            border-top: unset;
            border-bottom: unset;
            border-left: 1px solid ${colors.veryDarkGrey};
            border-right: 1px solid ${colors.veryDarkGrey};
        }

    }

`;

export const FocusPoint = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media ${devices.tablet} {

        flex-grow: 1;
        flex-basis: 100%;

        padding: 0 30px;
    }

`;

export const FocusTitle = styled.h5`

    margin: 0;
    padding: 0;

    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    color: ${colors.white050};

    white-space: nowrap;
`;

export const FocusDescription = styled.p`

    margin: 8px 0 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    text-align: center;

    span {
        color: ${colors.primaryColor};
    }
`;

export const ImageTextBox = styled.div<{isReverseDirection?: boolean, isDesktopDirectionColumn?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    ${({isReverseDirection}) => isReverseDirection && (

        css`
            flex-direction: column-reverse;
        `
    )}

    
    @media ${devices.tablet} {

        flex-direction: row;
        
        max-width: 900px;

        ${({isDesktopDirectionColumn}) => isDesktopDirectionColumn && (

            css`
                flex-direction: column;
            `
        )}


    }
`;

export const ImageBox = styled.div<{imageWidth?: string, imageHeight?: string}>`

    flex-shrink: 0;
    
    width: 100%;
    height: auto;

    border-radius: 15px;

    > img {

        width: 100%;
        height: auto;
        border-radius: 15px;
    }

    @media ${devices.tablet} {

        width: ${({imageWidth}) => imageWidth ?? '450px'};
        height: ${({imageHeight}) => imageHeight ?? 'auto'};
    }
`;

export const TextBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${colors.white};
    text-align: center;

`;

export const DescriptionText = styled.p`

    margin: 0;
    padding: 0;

    span {

        color: ${colors.primaryColor};
        font-weight: 500;
    }

    li {

        text-align: center;
        list-style-position: inside;
        
    }

    ul {

        padding: 0;

    }
`;

export const SimpleText = styled.p`

    max-width: 900px;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${colors.white};
    text-align: center;

    span {

        color: ${colors.primaryColor};
        font-weight: 500;
    }
`;

export const TitleCard = styled.h2`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0;
    padding: 0px;
    
    font-weight: 400;
    font-size: 20px;
    /* line-height: 35px; */
    
    color: ${colors.primaryColor};
`;

export const VideoCourseListBox = styled.div`
    margin: 20px 0 0;
`;