const globalRoles = [
    1, //SUPERADMIN 
    2, //CLIENTE 
    3, //TRADER 
    4, //APPRENDISTA TRADER 
    5, //ADMIN 
    6, //AUTORE 
    7, //DIVULGATORE 
    8, //BOTFOREX 
    9, //MARKETING 
    10, //DIVULGATORE_PRO 
    11, //NON_PAGATO 
    12, //SUPER MOTIVATO 
    13, //USER MANAGER 
    14, //ANIMATORE 
    15, //PERSONAL TRAINER 
    16, //MINIADMIN 
    17, //TIPSTER 
    18, //ANGOLO_STACKING 
    19, //ASPIRANTE_STAFF 
    20, //SUPERVIVENTE 
    21, //SOCCORRITORE 
    22, //PT 10K CERTIFIED 
    23, //COACH 
    24, //WEBINAR_MANAGER 
    25, //IMPRENDITORE 
    26, //HOSTESS 
    27, //ASPIRANTE_DIVULGATORE_1 
    28, //ASPIRANTE_DIVULGATORE_2 
    29, //WEBINAR_HOST 
    30, //PUNTATORE_CASINO 
    31, //ODDSSCASSER 
    32, //COLPO_SICURO 
    33, //DIRETTA 
    34, //ADMIN_AUTOSTRADA 
    35, //ASSISTENTE-PERSONALE 
    36, //COACH-BONUS 
    37, //SUPERVISORE_AUTOSTRADA 
    38, //ROADMAP 
    39, //MAIL 
    40, //ADMIN_SCHEDULATION 
    41, //SUPER_ADMIN_SCHEDULATION 
    42, //MOVE_POINTS 
    43, //CONTABILITÀ 
    44, //WITHDRAWAL_IVA 
    45, //WITHDRAWAL_SPA 
    46, //WITHDRAWAL_RIT 
];

const ADMIN_ROLES = [
    1, //SUPERADMIN 
    5, //ADMIN 
    16, //MINIADMIN 
    25, //IMPRENDITORE 
    26, //HOSTESS 
    34, //ADMIN_AUTOSTRADA 
    38, //ROADMAP 
    43, //CONTABILITÀ 
];

const AUTHOR_ROLES = [
    1, //SUPERADMIN 
    6, //AUTORE 
    7, //DIVULGATORE 
    9, //MARKETING 
    10, //DIVULGATORE_PRO 
    15, //PERSONAL TRAINER 
    18, //ANGOLO_STACKING 
    19, //ASPIRANTE_STAFF 
    21, //SOCCORRITORE 
    23, //COACH 
    24, //WEBINAR_MANAGER 
    26, //HOSTESS 
    29, //WEBINAR_HOST 
    32, //COLPO_SICURO 
    34, //ADMIN_AUTOSTRADA 
    35, //ASSISTENTE-PERSONALE 
    36, //COACH-BONUS 
    38, //ROADMAP 
];

export const checkIfUserIsAdmin = (userRolesData: any) => {
        
    if (!userRolesData) return false;
    return userRolesData.some((role: any) => ADMIN_ROLES.includes(role.id));
};

export const checkIfUserIsAuthor = (userRolesData: any) => {
    
    if (!userRolesData) return false;
    return userRolesData.some((role: any) => AUTHOR_ROLES.includes(role.id));
};
