import { FC, FormEvent, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

// components
import BaseFieldset from "components/ui/BaseFieldset";
import FormLessonsContent from "./FormLessonsContent/FormLessonsContent.component";

// hook
import useAppSelector from "hooks/useAppSelector";

// type
import { FormLessonsProps } from './FormLessons';

// style
import { Form } from './style/FormLessons.style';


// const lessonsData = [
//     {
//         id: uuidv4(),
//         lessonName: "Lezione 1"
//     },
//     {
//         id: uuidv4(),
//         lessonName: "Lezione 2"
//     },
//     {
//         id: uuidv4(),
//         lessonName: "Lezione 3"
//     }
// ];

/**
 * Lessons form
 * 
 * @author Frank Berni
 * 
 * @param isDatePickerButtonCompleted - state of the date picker button
 * @param isZoomLinkButtonCompleted - state of the zoom button
 * @param isCommentButtonCompleted - state of the comment button
 * @param isCustomerRatingButtonCompleted - state of the customer rating button
 * @param onCommentButtonClick - handler for the personal assisstant modal
 * @param onRatingButtonClick -  handler for the custome rating modal
 * 
 * @returns JSX
 */

const FormLessons: FC<FormLessonsProps> = ({
        isDatePickerButtonCompleted,
        isZoomLinkButtonCompleted,
        isCommentButtonCompleted,
        isCustomerRatingButtonCompleted,
        lessons,
        onCommentButtonClick,
        onRatingButtonClick,
        newLessonHandler,
        choiceLessonHandler
    }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        setIsLoading(true);
        await newLessonHandler();
        setIsLoading(false);
    };
    
    if (!isAppMobile) {

        return (
            <Form onSubmit={formSubmitHandler}>
                <BaseFieldset legend="Lezioni acquistate e da prenotare">
                    {lessons && 
                    <FormLessonsContent 
                        isDatePickerButtonCompleted={isDatePickerButtonCompleted}
                        isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
                        isCommentButtonCompleted={isCommentButtonCompleted}
                        isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
                        lessonsData={lessons}
                        onCommentButtonClick={onCommentButtonClick}
                        onRatingButtonClick={onRatingButtonClick}
                        choiceLessonHandler={choiceLessonHandler}
                        isLoadingNewLesson={isLoading}
                        />}
                </BaseFieldset>
            </Form>
        );
    }

    return (
        <Form onSubmit={formSubmitHandler}>
            <FormLessonsContent 
                isDatePickerButtonCompleted={isDatePickerButtonCompleted}
                isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
                isCommentButtonCompleted={isCommentButtonCompleted}
                isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
                lessonsData={lessons}
                onCommentButtonClick={onCommentButtonClick}
                onRatingButtonClick={onRatingButtonClick}
                choiceLessonHandler={choiceLessonHandler}
                isLoadingNewLesson={isLoading}
            />
        </Form>
    );
};

export default FormLessons;
