import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const MyServiceActiveBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;

    width: 100%; 

    @media ${devices.tablet} {

        flex-direction: row;
        align-items: baseline;        
        justify-content: space-around;

        flex-wrap: wrap;
    }

    @media ${devices.desktop} {

        flex-direction: row;
        align-items: baseline;        
        justify-content: space-between;

        flex-wrap: nowrap;
    }
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    /* max-width: 1920px; */

    padding: 35px 20px 0;

    a {text-decoration: none;}

    @media ${devices.tablet} {

        padding: 35px 40px 0;
    }
`;



export const MyServiceInfoBox = styled.h4`

    display: flex;
    align-self: flex-start;

    width: 100%;

    margin: 0;
    padding: 0;
     
    font-weight: 400;
    font-size: 18px;

    color: ${colors.white};
`;

export const MyFreeRisckServiceBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;

    width: 100%;
    max-width: 300px;

    margin: 15px 0 0;
    padding: 0 20px 35px;

    /* overflow: none; */

    @media ${devices.tablet} {
        min-width: 350px;

        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 15px;
    }

    @media ${devices.desktop} {
        min-width: unset;

        flex-basis: 25%;
        flex-shrink: 0;
        flex-grow: 0;
        gap: 15px;
    }
`;

export const MyPersonalGrowthServiceBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 300px;

    margin: 15px 0 0;
    padding: 0 20px 35px;

    overflow: none;

    background: ${colors.darkGrey};
    border-radius: 15px;

    @media ${devices.tablet} {
        min-width: 350px;

        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 15px;
    }

    @media ${devices.desktop} {
        min-width: unset;

        flex-basis: 25%;
        flex-shrink: 0;
        flex-grow: 0;
        gap: 15px;
    }
`;

export const MyCryptoServiceBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 300px;

    margin: 15px 0 0;
    padding: 0 20px 35px;

    overflow: none;

    background: ${colors.darkGrey};
    border-radius: 15px;

    @media ${devices.tablet} {
        min-width: 350px;

        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 15px;
    }

    @media ${devices.desktop} {
        min-width: unset;

        flex-basis: 25%;
        flex-shrink: 0;
        flex-grow: 0;
        gap: 15px;
    }
`;

export const MyServiceActvityBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 300px;

    margin: 15px 0 0;
    padding: 0 20px 35px;

    overflow: none;

    background: ${colors.darkGrey};
    border-radius: 15px;

    @media ${devices.tablet} {
        min-width: 350px;

        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 15px;
    }

    @media ${devices.desktop} {
        min-width: unset;

        flex-basis: 25%;
        flex-shrink: 0;
        flex-grow: 0;
        gap: 15px;
    }
`;

export const CategoryServiceBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0px 0px 10px;

    width: 100%;

    gap: 15px;

    border-bottom: 1px solid ${colors.grey};

    @media ${devices.tablet} {
    }
`;

export const CategoryServiceText = styled.p`
    display: flex;

    margin: 0;
    padding: 0;

    font-weight: 400;
    font-size: 18px;
    color: ${colors.primaryColor};

    @media ${devices.tablet} {
    }
`;

export const MyServiceBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    overflow: hidden;

    background: ${colors.darkGrey};
    border-radius: 15px;

    margin: 20px 0 0;

    @media ${devices.tablet} {

        align-items: first baseline;
        padding: 35px 50px;
        
    }
`;

export const MyServiceContent = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    flex-wrap: wrap;

    width: 100%;

    overflow: none;
`;

export const ServiceStatusSeparateBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 50px;

    color: ${colors.white050};

    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid ${colors.grey};

    padding: 0 0 10px;

    @media ${devices.tablet} {
        margin-top: 0px;
    }
`;

export const MyServiceDisactiveBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;
        align-items: first baseline;  
        justify-content: space-between;      
    }
`;

