import { 
    MainTitle, 
    Paragraph, 
    ParagraphTitle, 
    TotalPage,
    Text,
    List,
    ListText,
    Link,
    Span
} from "./style/Cookies.style";

const Cookies = () => {
    
    return (
        <TotalPage>
            <MainTitle>Cookie</MainTitle>

            <Paragraph>
                <ParagraphTitle>Utilizzo dei cookie</ParagraphTitle>

                <Text>
                    In questo sito sono utilizzati dei cookie. Con questo termine si indicano piccoli file di testo
                    che i siti visitati dall'utente inviano al suo terminale (solitamente al browser), dove vengono 
                    memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente. 
                    Nel corso della navigazione su un sito, l'utente può ricevere sul suo terminale anche cookie di siti 
                    o di web server diversi (c.d. cookie di "terze parti"); ciò accade perché sul sito web visitato possono 
                    essere presenti elementi come, ad esempio, immagini, mappe, suoni, specifici link a pagine web di altri 
                    domini che risiedono su server diversi da quello sul quale si trova la pagina richiesta. 
                    Con la presente, ai sensi degli articoli 13 e 122 del D.Lgs. n. 196/2003, <Span>EASYTENERLIFE SL</Span>
                    , in qualità di Titolare del trattamento, la informa che nel proprio sito web sono presenti 
                    cookie di due tipologie:
                </Text>

                <List>
                    <ListText>◉ cookie di sessione</ListText>
                    <ListText>◉ cookie persistenti di terze parti</ListText>
                </List>
            </Paragraph>

            <Paragraph>
                <ParagraphTitle>Cookie di sessione</ParagraphTitle>

                <Text>
                L’uso di c.d. cookies di sessione (che non vengono memorizzati in modo persistente sul computer dell’utente 
                e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di 
                sessione (costituiti da numeri casuali generati dal server) necessari per consentire l’esplorazione sicura 
                ed efficiente del sito.
                </Text>
            </Paragraph>

            <Paragraph>
                <ParagraphTitle>Cookie di terze parti</ParagraphTitle>

                <Text>
                    <Span>Cookie di Google Analytics.</Span> questo sito web utilizza Google Analytics esclusivamente 
                    per scopi statistici. I cookie in questione raccolgono informazioni in forma aggregata al fine di 
                    monitorare e analizzare gli accessi a tutte le aree del sito. Le informazioni generate dal cookie 
                    sull'utilizzo del sito web vengono comunicate a Google Inc. al cui sito web  
                    <Link href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout </Link> 
                    espressamente si rimanda per maggiori dettagli e per disabilitare il loro salvataggio sul terminale 
                    dell’utente giacché il loro utilizzo ai fini della navigazione sul sito è assolutamente facoltativo.
                </Text>

                <Text>
                    <Span>Cookie di Facebook, Twitter e Youtube. </Span> i cookie di Facebook, Twitter e Youtube non sono 
                    presenti sul sito e non vengono da questo inviati sul terminale dell’utente. Tali cookie, assolutamente 
                    non indispensabili ai fini della navigazione sul sito, si attivano solo nel momento in cui avviene 
                    l’accesso alla relativa pagina web, cliccando sull’icona di FB, TW o YT, ovvero entrando nei rispettivi 
                    siti social, ai quali si rimanda per avere per maggiori dettagli e per disabilitare il loro salvataggio 
                    sul terminale dell’utente:
                </Text>

                <List>
                    <ListText>◉ <Link href="https://www.facebook.com/help/cookies"> www.facebook.com/help/cookies</Link></ListText>
                    <ListText>◉ <Link href="https://twitter.com/privacy"> http://twitter.com/privacy</Link></ListText>
                    <ListText>◉ <Link href="https://support.google.com/youtube"> http://support.google.com/youtube</Link></ListText>
                </List>
            </Paragraph>

            <Paragraph>
                <ParagraphTitle>Impostazioni dei cookie</ParagraphTitle>

                <Text>
                    Puoi decidere di permettere o meno l'impostazione dei cookie sul tuo computer, ma questo sito web 
                    funziona in modo ottimale con tutte le tipologie di cookie abilitate. Le impostazioni riguardanti i 
                    cookie possono essere controllate e modificate dalle "Preferenze" del browser. Seleziona qui sotto il 
                    browser che stai utilizzando, per informazioni su come modificare le impostazioni dei cookie.
                </Text>

                <List>
                    <ListText>◉ <Link href="https://support.google.com/chrome/answer/95647?hl=it&topic=14666&ctx=topic"> Chrome</Link></ListText>
                    <ListText>◉ <Link href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it"> Firefox</Link></ListText>
                    <ListText>◉ <Link href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"> Internet Explorer</Link></ListText>
                    <ListText>◉ <Link href="https://help.opera.com/en/latest/web-preferences/"> Opera</Link></ListText>
                    <ListText>◉ <Link href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"> Safari</Link></ListText>
                </List>

                <Text>
                    Se disattivi i cookie, questo potrebbe comportare la disabilitazione di alcune funzionalità del sito. 
                    Anche con tutti i cookie disabilitati, il tuo browser continuerà a memorizzare una piccola quantità di 
                    informazioni. Queste informazioni sono necessarie per le funzionalità di base del sito. I dati personali 
                    eventualmente contenuti nei cookie presenti sul sito non vengono diffusi e sono trattati con strumenti 
                    automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. 
                    Specifiche misure di sicurezza sono applicate per prevenire la perdita dei dati, usi illeciti o non 
                    corretti ed accessi non autorizzati o non conformi alle finalità della raccolta. I soggetti cui si 
                    riferiscono i dati trattati godono dei diritti di cui all'articolo 7 del D.Lgs. 196/2003 che possono 
                    esercitare in qualsiasi momento scrivendo all’indirizzo di posta elettronica "comvisrl@pec.it", oppure 
                    presso la sede del Titolare.
                </Text>
            </Paragraph>
        </TotalPage>
    )
};

export default Cookies;
