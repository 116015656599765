import { FC, FormEvent, useCallback, useState } from 'react';

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// components
import TextInput from 'components/ui/TextInput';
import ModifyButtons from '../ModifyButtons';

// utils
import {
    validatePasswordInput,
    validateRepeatedPasswordInput,
} from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { awsChangePassword } from './services/FormEditPassword.services';

// types
import { ChangePasswordPayload } from './services/FormEditPassword.services.d';

// style
import {
    PasswordForm,
    PlaceholderPasswordText
} from './style/FormEditPassword.style';


/**
 * Password edit form
 * 
 * @author Alessio Grassi and Frank Berni
 * 
 * @returns JSX
 */


const FormEditPassword: FC<{}> = () => {

    const [oldPasswordInput, setOldPasswordInput] = useState<string>("");
    const [oldPasswordInputErrorMessage, setOldPasswordInputErrorMessage] = useState<string | undefined>(undefined);
    const [newPasswordInput, setNewPasswordInput] = useState<string>("");
    const [newPasswordInputErrorMessage, setNewPasswordInputErrorMessage] = useState<string | undefined>(undefined);
    const [repeatedNewPasswordInput, setRepeatedNewPasswordInput] = useState<string>("");
    const [repeatedNewPasswordInputErrorMessage, setRepeatedNewPasswordInputErrorMessage] = useState<string | undefined>(undefined);
    const [isUserModifyingPassword, setIsUserModifyingPassword] = useState<boolean>(false);
    const [isPasswordFormLoading, setIsPasswordFormLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();

    const userIsModifyingPasswordHandler = () => setIsUserModifyingPassword(true);

    const validateOldPasswordHandler = (oldPassword: string) => {
        
        const error = validatePasswordInput(oldPassword);
        setOldPasswordInputErrorMessage(error);
        
        return error;
    };
    
    const oldPasswordChangeHandler = (oldPassword: string) => {
        
        validateOldPasswordHandler(oldPassword);
        setOldPasswordInput(oldPassword);
    };
    
    const validateNewPasswordHandler = (newPassword: string) => {
        
        const error = validatePasswordInput(newPassword);
        setNewPasswordInputErrorMessage(error);
        
        return error;
    };
    
    const newPasswordChangeHandler = (newPassword: string) => {
        
        validateNewPasswordHandler(newPassword);
        setNewPasswordInput(newPassword);
    };
    
    const validateRepeatedNewPasswordHandler = (repeatedPassword: string, prevPassword: string) => {
        
        const error = validateRepeatedPasswordInput(repeatedPassword, prevPassword);
        setRepeatedNewPasswordInputErrorMessage(error);

        return error;
    };
    
    const repeatedNewPasswordChangeHandler = useCallback((newRepeatedPassword: string) => {
        
        validateRepeatedNewPasswordHandler(newRepeatedPassword, newPasswordInput);
        setRepeatedNewPasswordInput(newRepeatedPassword);
    }, [newPasswordInput]);

    const resetPasswordInputs = () => {

        setOldPasswordInput("");
        setOldPasswordInputErrorMessage(undefined);
        setNewPasswordInput("");
        setNewPasswordInputErrorMessage(undefined);
        setRepeatedNewPasswordInput("");
        setRepeatedNewPasswordInputErrorMessage(undefined);
    };

    const userIsNotModifyingPasswordHandler = () => {
        
        setIsUserModifyingPassword(false);
        resetPasswordInputs();
    };

    const passwordFormSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (isPasswordFormLoading) return;
        const oldPasswordError = validateOldPasswordHandler(oldPasswordInput);
        if (oldPasswordError) return;
        const newPasswordError = validateNewPasswordHandler(newPasswordInput);
        if (newPasswordError) return;
        const repeatedNewPasswordError = validateRepeatedNewPasswordHandler(repeatedNewPasswordInput, newPasswordInput);
        if (repeatedNewPasswordError) return;
        
        const snackbarData: SnackbarOptions = {};

        setIsPasswordFormLoading(true);

        try {

            const changePasswordPayload: ChangePasswordPayload = {
                
                oldPassword: oldPasswordInput,
                newPassword: newPasswordInput
            };
            
            const changePasswordResponse = await awsChangePassword(changePasswordPayload);
            if (!isMounted()) return;
            
            if (changePasswordResponse === undefined || !changePasswordResponse) {
                throw new Error();
            }

            snackbarData.type = 'success';
            snackbarData.message = "Password cambiata con successo";
            dispatch( addSnackbar(snackbarData) );
            setIsPasswordFormLoading(false);
            userIsNotModifyingPasswordHandler();

        } catch (error: any) {
            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsPasswordFormLoading(false);
        }
    };

    return (
        <PasswordForm 
            isUserModifyingInput={isUserModifyingPassword}
            onSubmit={passwordFormSubmitHandler}
        >

            {!isUserModifyingPassword && (
                <PlaceholderPasswordText>
                    Password
                </PlaceholderPasswordText>
            )}

            {isUserModifyingPassword && (
                <>
                    <TextInput
                        label="Password precedente"
                        type='password'
                        value={oldPasswordInput}
                        errorMsg={oldPasswordInputErrorMessage}
                        onChange={oldPasswordChangeHandler}
                        onValidate={() => validateOldPasswordHandler(oldPasswordInput)}
                    />
                    
                    <TextInput
                        label="Nuova Password"
                        type='password'
                        value={newPasswordInput}
                        errorMsg={newPasswordInputErrorMessage}
                        onChange={newPasswordChangeHandler}
                        onValidate={() => validateNewPasswordHandler(newPasswordInput)}
                    />

                    <TextInput
                        label="Conferma Password"
                        type='password'
                        value={repeatedNewPasswordInput}
                        errorMsg={repeatedNewPasswordInputErrorMessage}
                        onChange={repeatedNewPasswordChangeHandler}
                        onValidate={() => validateRepeatedNewPasswordHandler(repeatedNewPasswordInput, newPasswordInput)}
                    />
                </>
            )}

            <ModifyButtons
                isModifying={isUserModifyingPassword}
                isLoading={isPasswordFormLoading}
                onModifyButtonClick={userIsModifyingPasswordHandler}
                onCancelButtonClick={userIsNotModifyingPasswordHandler}
            />
        </PasswordForm>
    );
};

export default FormEditPassword;
