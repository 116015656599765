import { Auth } from 'aws-amplify';

// types
import { ChangeEmailPayload } from './EmailForm.services.d';

export const awsChangeEmail = async (payload: ChangeEmailPayload) => {

    try {

        const user = await Auth.currentAuthenticatedUser();
        
        if (user === undefined || !user) {
            throw new Error();
        }

        const response = await Auth.updateUserAttributes(user, { 'email': payload.newEmail });
        
        if (response === undefined || !response) {
            throw new Error();
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};
