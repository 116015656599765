import { LimitDataObjProps } from './Timer.component.d';

export const getLimitTime = (endTime: string, timerCount: 'DAYS' | 'HOURS' | 'MINUTES') => {

    const isDaysCounterVisible = (timerCount === 'DAYS');
    const isHoursCounterVisible = (timerCount === 'DAYS') || (timerCount === 'HOURS');
    const isMinutesCounterVisible = (timerCount === 'DAYS') || (timerCount === 'HOURS') || (timerCount === 'MINUTES');
    // const nowDate = new Date();
    // const limitDate = new Date(endTime)
    // const utcLimitDate = Date.UTC(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate(), limitDate.getHours(), limitDate.getMinutes(), limitDate.getSeconds())
    const nowDate = new Date();
    const limitDate = new Date(`${endTime}Z`);
    // const utcLimitDate = Date.UTC(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate(), limitDate.getHours(), limitDate.getMinutes(), limitDate.getSeconds())
    // const missingTime = Math.round((utcLimitDate - nowDate.getTime()) / 1000);
    let missingTime = Math.round((limitDate.getTime() - nowDate.getTime()) / 1000);
    const missingDays = isDaysCounterVisible ? Math.floor(missingTime / (24 * 60 * 60)) : 0;
    missingTime -= missingDays * 24 * 60 * 60;
    const missingHours = isHoursCounterVisible ? Math.floor(missingTime / (60 * 60)) : 0;
    missingTime -= missingHours * 60 * 60;
    const missingMinutes = isMinutesCounterVisible ? Math.floor(missingTime / (60)) : 0;
    missingTime -= missingMinutes * 60;
    const missingSeconds = Math.floor(missingTime);
    
    const limitDateObj: LimitDataObjProps = {
        days: missingDays,
        hours: missingHours,
        minutes: missingMinutes,
        seconds: missingSeconds
    };

    return limitDateObj;
};
