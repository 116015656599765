import styled from 'styled-components';
import colors from 'style/palette/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';

export const WhatsAppInsertNumberBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    max-width: 430px;

    padding: 25px 20px;

    @media ${devices.tablet} {

        height: 145px;
        padding: 24px 40px;
    }
`;

export const WhatsAppInsertNumberBoxContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 20px;

    width: 100%;

    @media ${devices.tablet} {
        
    }
`;

export const WhatsAppInsertNumberTextContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
`;

export const WhatsAppInsertNumberText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.white};
`;

export const WhatsAppInsertNumberCTABox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    /* gap: 10px; */
`;

export const WhatsAppNeedCallButton = styled(Button)`
    font-size: 14px;
    line-height: 13px;
`;

export const WhatsAppPhoneNumberPrefixInput = styled.input`

    width: 20%; 
    height: 37px;

    padding: 12px;
    margin: 0px;

    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    text-align: center;

    border-radius: 4px 0 0 4px;
    border: 1px solid ${colors.grey};
    background: ${colors.grey};

    color: ${colors.white050};

    &:disabled {
        background: ${colors.grey};
        color: ${colors.white050};
    }

    @media ${devices.tablet} {
        max-width: 130px; 
        height: 42px;
        font-size: 14px;
    }
`;

export const WhatsAppPhoneNumberInput = styled.input`

    width: 70%;
    max-width: 100px;
    height: 37px; 
    padding: 12px;
    margin: 0px;

    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: white;

    border-radius: 0px;
    border: 1px solid ${colors.grey};

    background: ${colors.grey};

    &:focus {
        outline: none;
        border-color: ${colors.blue};
    }

    @media ${devices.tablet} {
        max-width: 130px; 
        font-size: 14px;
        height: 42px; 
    }
`;