import { useEffect } from "react";
import useAppSelector from "./useAppSelector";

interface RequisitesProps {
    negation: boolean,
    id: {
        productId: number,
        requisiteProductId: number
    },
    requisiteProduct: {
        free: boolean,
        id: number,
        name: string,
        type: string,
        brands: [
            {
                code: string,
                domain: string,
                name: string,
                id: number,
                logo: {} | null
            }
        ],
        productCourse: {
            productId: number,
            price: number
        },
        salesData: {
            delivery: string,
            productId: number,
            weight: number
        },
        sellImage: {
            fileName: string,
            url: string,
            id: number,
            fileType: {
                id: number,
                name: string,
                relativaPath: string,
            }
        }
    }
};

const useHasRequisites = (requisites: RequisitesProps[]) => {

    const { courses, subscriptions, lessons } = useAppSelector(state => state.user.products);
    const { tokenID } = useAppSelector(state => state.user);
    
    const getProductsId: () => number[] = () => {
        
        const coursesId = courses.map((course: any) => course.id);
        const subscriptionsId = subscriptions.map((subscription: any) => subscription.id);
        const lessonsId = lessons.map((lesson: any) => lesson.id);
        
        return [...coursesId, ...subscriptionsId, ...lessonsId];
    };
    
    const userRequisitesMissing = () => {
        
        let suppArray: RequisitesProps[] = [];
        
        for (let i = 0; i < requisites.length; i++) {
            if (!getProductsId().includes(requisites[i].requisiteProduct.id)) suppArray.push(requisites[i]);
        };
        
        return suppArray;
    };
    
    if ((requisites === undefined) || (requisites.length === 0)) return {
        hasUserAllRequisites: true,
        userMissingRequisites: []
    };

    if (!tokenID) return  {
        hasUserAllRequisites: false,
        userMissingRequisites: []
    };

    if (courses === undefined || subscriptions === undefined || lessons === undefined) return  {
        hasUserAllRequisites: true,
        userMissingRequisites: []
    };

    return {
        hasUserAllRequisites: userRequisitesMissing().length === 0,
        userMissingRequisites: userRequisitesMissing()
    }
};

export default useHasRequisites;