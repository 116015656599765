//hooks
import { useNavigate } from "react-router-dom";

// style
import { 
    NormalText, 
    Page, 
    TextBox, 
    TextPrimary
} from "./style/BackendWaiting.style";


const BackendWaiting = () => {

    const navigate = useNavigate();
    
    const redirectHandler = () => {
        navigate('/reservedArea');
    };
    
    return (
        <Page>
            <TextBox>
                <TextPrimary>
                    Il tuo pagamento sembra andato a buon fine, 
                    ma c'è stato un problema nell'assegnazione del prodotto sulla tua pagina personale.
                </TextPrimary>

                <br />

                <TextPrimary>
                    IMPORTANTE:
                </TextPrimary>

                <TextPrimary>
                    Non effettuare nuovi tentativi di acquisto!
                </TextPrimary>

                <br />
                
                <NormalText>
                    Se dopo 24 ore non vedi il prodotto nella tua pagina personale, verifica su Paypal
                    che il pagamento sia andato a buon fine e se è andato a buon fine 
                    contatta il Supporto per ricevere assistenza.
                </NormalText>

                <br />

                <NormalText>
                    Per tornare in home page <span onClick={redirectHandler}>clicca qui</span>
                </NormalText>
            </TextBox>
        </Page>
    )
};

export default BackendWaiting;
