import { FC, ReactElement, SVGProps } from 'react';

//style
import {
    FilterShopSelector,
    FilterText
} from './style/FilterShopButtonDesktop.style';

const FilterShopButtonDesktop: FC<{
    icon: ReactElement<SVGProps<SVGElement>>, 
    text: string, 
    isActive?: string, 
    onButtonClick: (text: string, position: number) => void, 
    position: number,
    activeButtonClick?: () => void,
    isCategory?: boolean
}> = ({
    icon, 
    text, 
    isActive, 
    onButtonClick, 
    position,
    activeButtonClick,
    isCategory = true
}) => {

    const onClickSelectorFilterHandler = () => {
        if (activeButtonClick && isButtunActive) return activeButtonClick();
        onButtonClick(text, position);
    };

    const isButtunActive = (isActive === text);
    return (
        
        <FilterShopSelector isActive={isButtunActive} isCategory={isCategory} onClick={onClickSelectorFilterHandler}>
            {icon}

            <FilterText>{text}</FilterText>

        </FilterShopSelector>
    );
};

export default FilterShopButtonDesktop;