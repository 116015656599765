import { Auth } from 'aws-amplify';
import { callPUT } from 'network/network'

// types
import { ChangePasswordPayload } from './FormEditPassword.services.d';


export const awsChangePassword = async (payload: ChangePasswordPayload) => {

    try {
        
        const user = await Auth.currentAuthenticatedUser();

        if (user === undefined || !user) {
            throw new Error();
        }

        const response = await Auth.changePassword(user, payload.oldPassword, payload.newPassword);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};
