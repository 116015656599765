import styled from 'styled-components';
import colors from 'style/palette/palette';

// assets
import bar from '../assets/barra.png'

export const Box = styled.div`

    width: 100%;
    padding: 0 20px;
    margin: 10px 0 10px;

    > div {
        > div {
            > div {
                
                width: 100%;
                min-width: 160px;
                max-width: 160px;
                height: 100%;
                min-height: 7px;
                max-height: 7px;

                border-radius: 5px;
                background: ${colors.white};
                /* background-image: url({bar}); */

                left: 80px;
                right: 160px;
            }
        }
    }

    @media (min-width: 769px) {

        /* margin: 10px 0 10px; */

    }
`;
