import styled from 'styled-components';
import colors from 'style/palette/palette';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-direction: column;
    gap: 20px;

    width: 100%;
    height: 100%;

    padding: 20px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const CoversListContainer = styled.div`
    max-height: calc(100vh - 250px);
`;

export const CoversList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 6px;
    margin-left: 10px;
`;


export const CoverOddsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const CoverRow = styled.div<{withSelection?: boolean}>`
    position: relative;
    display: grid;
    grid-template-columns: ${props => props.withSelection ? '45px 150px' : '45px 125px'};
    grid-gap: 5px;
    align-items: center;
    padding-right: 30px;
`;
