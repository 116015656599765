import { FC, useCallback } from 'react';
import ReactDOM from 'react-dom';

// redux
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { removeSnackbarByID } from 'redux/actions/actions-snackbar';

// components
import SnackBar from './SnackBar';

// style
import { Container } from './style/SnackbarList.style';


/**
 * SnackBar Box
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const SnackbarList: FC<{}> = () => {

    const snackbarPortalElement = document.getElementById('snackbar-root');
    const { items } = useAppSelector(state => state.snackbar);
    const dispatch = useAppDispatch();

    const closeSnackbarHandler = useCallback((id: string) => {
        dispatch( removeSnackbarByID(id));
    }, [dispatch]);

    if (snackbarPortalElement == null || !items || items.length < 1) return <></>;

    return (
        <>
            {ReactDOM.createPortal(
                <Container>
                    {items.map((snackData, _) => (
                        <SnackBar
                            key={snackData!.id}
                            id={snackData!.id}
                            type={snackData.type}
                            message={snackData.message}
                            onSnackClose={closeSnackbarHandler}
                        />
                    ))}
                </Container>,
                snackbarPortalElement
            )}
        </>
    );
};

export default SnackbarList;
