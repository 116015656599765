import { callGET } from 'network/network';
import store from 'redux/store';


export const getLessonWithBrand = async () => {

    try {

        const lessonParams = {

            brandCode: store.getState().ui.brand?.code,
            types: 'LESSON_PACK'
        };

        const response = await callGET("v1/productsforsell/bytypes", true, lessonParams )
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;        
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};
