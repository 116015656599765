import React, { FC, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { axisBottom, axisLeft } from 'd3';

// hooks
import useD3 from 'hooks/useD3';

// types
import { AnnualTrendGraphProps } from './AnnualTrendGraph.component.d';
// style
import {
    PlotArea,
    Xaxis,
    Yaxis
} from './style/AnnualTrendGraph.style';

const MONTH_NAME = [
    'GEN',
    'FEB',
    'MAR',
    'APR',
    'MAG',
    'GIU',
    'LUG',
    'AGO',
    'SET',
    'OTT',
    'NOV',
    'DIC'
];


const AnnualTrendChart: FC<AnnualTrendGraphProps> = ({ data, season, getResultsFunction }) => {

    const [userData, setUserData] = useState<any>(undefined);
    const [graphData, setGraphData] = useState<any>();
    
    // console.log("GRAPHDATA:", graphData);
    
    const seasonYears = season.split('/');
    
    const getWidth = () => {
        
        const innerWidth = document.getElementById('graphSVG')?.parentElement?.clientWidth

        if (innerWidth === undefined) return 100;
        return innerWidth;
    };

    const getGraphArray = (dataArray: any, seasonToDisplay: string) => {

        if (!dataArray || dataArray === undefined) return;

        let dataToGraphArr: any[] = [];

        if (seasonToDisplay !== 'last 12 months') {
            
            for (let i = 0; i < seasonYears.length; i++) {

                const suppArr = dataArray.filter((record: any) => record.year === +seasonYears[i]);
                const suppArr2 = suppArr.sort((recA: any, recB: any) => recA.month - recB.month);
                let suppArr3 = [];
                if (i === 0) suppArr3 = suppArr2.filter((record: any) => record.month >= 9);
                if (i === 1) suppArr3 = suppArr2.filter((record: any) => record.month <= 8);
                const dataToPush = ((seasonYears.length === 1) ? suppArr2 : suppArr3);
                dataToGraphArr.push(dataToPush);           
            }
        }

        if (seasonToDisplay === 'last 12 months') {

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();
            const yearToSearch = (currentMonth === 0) ? (currentYear - 1) : currentYear;

            for (let i = 0; i < 12; i++) {

                if (currentMonth <= i) {

                    const suppArr = dataArray.filter((record: any) => record.year === (+yearToSearch - 1));
                    let suppArr2 =  suppArr.filter((record: any) => record.month === (i + 1));
                    dataToGraphArr.push(suppArr2);
                }

                if (currentMonth > i) {

                    const suppArr = dataArray.filter((record: any) => record.year === +yearToSearch);
                    let suppArr2 =  suppArr.filter((record: any) => record.month === (i + 1));
                    dataToGraphArr.push(suppArr2);           
                }
            }            
        }

        const dataToGraph = dataToGraphArr.flat();
        
        const graphArray = Array(12).join().split(',').map((_, i) => {
            
            if (seasonToDisplay === 'last 12 months') {
                
                const lastMonth = new Date().getMonth();
                if (lastMonth > i) return lastMonth - i;
                return lastMonth + 12 -i;
            }
            if (seasonYears.length === 1) return ++i;
            if (i < 4 ) return (9 + i);
            return (i - 3);
        });
        if (seasonToDisplay === 'last 12 months') graphArray.reverse();

        let finalArray = graphArray.map((el: any, index: number) => {
            
            const filteredData = dataToGraph.filter((dataEl: any) => dataEl.month === el);
            if (filteredData.length === 0) {
                const emptyMonthInSeason = (index < 4) ? (index + 9) : (index - 3);
                const emptyMonth = ((seasonYears.length === 1) ? (index + 1) : emptyMonthInSeason);
                return {
                    bonus: 0,
                    colpoSicuro: 0,
                    tradingSportivo: 0,
                    month: emptyMonth,
                    year: +seasonYears[0]
                }
            }

            return filteredData[0];
        });
        
        if (seasonToDisplay === 'last 12 months') {

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();
            
            finalArray = graphArray.map((el: any, index: number) => {
                
                const yearToGraph = (currentMonth - el < 0) ? (currentYear - 1) : currentYear;

                const filteredData = dataToGraph.filter((dataEl: any) => dataEl.month === el);
                if (filteredData.length === 0) {
                    return {
                        bonus: 0,
                        colpoSicuro: 0,
                        tradingSportivo: 0,
                        month: el,
                        year: yearToGraph
                    }
                }

                return filteredData[0];
            });

            const july2022Index = finalArray.findIndex((result: any) => (result.month === 7) && (result.year === 2022));
            finalArray.splice(0, july2022Index + 1);
        };
        
        const finalArrayWithMonthName = changeMonthName(finalArray);
        setGraphData(finalArrayWithMonthName);
    };

    const changeMonthName = (graphArray: any[]) => {
        
        if (!graphArray || graphArray === undefined) return;
        for (let i = 0; i < graphArray?.length; i++) {
            if (typeof graphArray[i].month === 'number') graphArray[i].month = MONTH_NAME[graphArray[i].month - 1];
        }
        return graphArray;
    };
          
    const ref = useD3((svg: any) => {
        
        if (!graphData || graphData === undefined) return;
        const width = 800;
        const height = width / 3.4;
        const padding = 35;
        const paddingAdj = (graphData.length - 5) * (12 - graphData.length);
        const lateralLeft = 50;
        const maxY = Math.ceil(Math.max(...graphData.map((el: any) => el.bonus + el.colpoSicuro + el.tradingSportivo)) / 100) * 100;
        const subGroups = ['bonus', 'colpoSicuro', 'tradingSportivo'];
        const blocksWidth = 25;
        const seasonLabel = ((season === 'last 12 months') ? "ULTIMI 12 MESI" : ((seasonYears.length === 1) ? `ANNO ${seasonYears[0]}` : `STAGIONE ${seasonYears[0]} / ${seasonYears[1]}`));

        const color = d3
            .scaleOrdinal()
            .domain(subGroups)
            .range(['#FF5957', '#FF8A00', '#5E2222']);

        const stackedData = d3
            .stack()
            .keys(subGroups)
            (graphData)
            
        const xScale = d3
            .scaleBand()
            .domain(graphData.map((d: any) => d.month))
            .range([padding, width - padding]);

        const yScale = d3
            .scaleLinear()
            .domain([0, maxY])
            .range([height - padding, padding]);

        const xAxis = (g: any) => {

            g
                .attr('transform', `translate(${lateralLeft - padding}, ${height - padding + (height / 3)})`)
                .attr('class', 'title')
                .call(
                    axisBottom(xScale)
                        .tickSizeOuter(0)
                )
        };
        
        const yAxis = (g: any) => {

            g
                .attr('transform', `translate(${lateralLeft}, ${height / 3})`)
                .attr('class', 'title')
                .call(
                    axisLeft(yScale)
                    .ticks(5)
                )
        };

        svg
            .attr('width', '100%')
            // .attr('height', '300')
            .attr('color', 'white')
            .attr('viewBox', '0 0 850 300')
            
        svg    
            .append('text')
            .text(seasonLabel)
            .attr('x', padding * 0.8)
            .attr('y', padding * 0.8)
            .style('fill', 'white')
            .attr('font', '13px Montserrat')
           
        svg
            .append('rect')
            .attr('width', '20')
            .attr('height', '20')
            .attr('fill', '#FF5957')
            .attr('x', width / 2 - 10)
            .attr('y', padding - 22)

        svg
            .append('text')
            .text('BONUS')
            .attr('x', (width / 2) + 20)
            .attr('y', padding * 0.8)
            .attr('font', '13px Montserrat')
            .style('fill', 'white')

        svg
            .append('rect')
            .attr('width', '20')
            .attr('height', '20')
            .attr('fill', '#FF8A00')
            .attr('x', width / 8 * 5 - 10)
            .attr('y', padding - 22)

        svg
            .append('text')
            .text('COLPO SICURO')
            .attr('x', (width / 8 * 5) + 20)
            .attr('y', padding * 0.8)
            .attr('font', '13px Montserrat')
            .style('fill', 'white')

        svg
            .append('rect')
            .attr('width', '20')
            .attr('height', '20')
            .attr('fill', '#5E2222')
            .attr('x', (width / 8 * 6) + (width * 0.075) - 10)
            .attr('y', padding - 22)

        svg
            .append('text')
            .text('ALTRO')
            .attr('x', (width / 8 * 6) + (width * 0.075) + 20)
            .attr('y', padding * 0.8)
            .attr('font', '13px Montserrat')
            .style('fill', 'white')

        svg
            .select('.x-axis')
            .call(xAxis);

        svg
            .select('.y-axis')
            .call(yAxis);

        svg
            .select('.plot-area')
            .attr('transform', `translate(${padding + paddingAdj}, ${height / 3})`)
            .selectAll('g')
            .data(stackedData)
            .enter()
            .append('g')
            .attr('fill', (d: any) => color(d.key))
            .attr('stroke', '#4C5862')
            .attr('stroke-width', '1px')
            .selectAll('rect')
            .data((d: any) => d)
            .enter()
            .append('rect')
            .attr('x', (d: any) => xScale(d.data.month))
            .attr('y', (d: any) => yScale(d[1]))
            .attr('height', (d: any) => yScale(d[0]) - yScale(d[1]))
            .attr('width', blocksWidth)
            .append('title')
            .text((d: any) => d[1] - d[0])
            
    }, [graphData]);

    useEffect(() => {

        if (!data || data === undefined) return;
        if (data.length === 0) return;
        setUserData(data);
    }, [data]);

    useEffect(() => {

        if (!userData || userData === undefined) return;
        if (userData.length === 0) return;
        getGraphArray(userData, season);
    }, [userData, season]);

    
    useEffect(() => {
        
        if (graphData === undefined) return;
        
        const incomingResults = graphData.map(
            (result: any) => (
                ((result.bonus !== null) ? result.bonus : 0) + 
                ((result.colpoSicuro !== null) ? result.colpoSicuro : 0) + 
                ((result.tradingSportivo !== null) ? result.tradingSportivo : 0)
            )
        ).reduce((resA: number, resB: number) => resA + resB, 0);

        getResultsFunction(incomingResults);
    }, [graphData]);

    // if (season === 'last 12 months') {
    
    //     console.log("LAST 12 MONTHS")
    //     return <></>;
    // };
    if (!ref || ref === undefined) return (<></>);
    if (!graphData || graphData === undefined) return (<></>);

    // console.log("SVG:", document.getElementById('graphSVG'));
    
    return (
        <svg id='graphSVG' ref={ref}>
            <PlotArea className="plot-area" />

            <Xaxis className="x-axis" />

            <Yaxis className="y-axis" />
        </svg>
    );
}; 

export default AnnualTrendChart;