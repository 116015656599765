import { FC, ChangeEvent } from 'react';

// types
import { CheckboxInputProps } from './CheckboxInput.d';

// style
import { Label, Input, VisibleCheckbox } from './style/CheckboxInput.style';


/**
 * CheckBox
 * 
 * @author Alessio Grassi
 * 
 * @param label - text of the checkbox
 * @param inputName - name of the input box
 * @param isChecked - initial check
 * @param onChange - toggle function for isChecked
 * @param isError - error state
 * 
 * @returns JSX
 */


const CheckboxInput: FC<CheckboxInputProps> = ({ label, inputName, isChecked, onChange, isError }) => {

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {

        const newIsChecked = event.target.checked;

        if (!onChange) return;
        onChange(newIsChecked);
    };

    return (
        <Label>
            <Input
                type="checkbox"
                name={inputName}
                checked={isChecked}
                onChange={inputChangeHandler}
            />

            <VisibleCheckbox isError={isError} />

            <span>
                {label}
            </span>
        </Label>
    );
};

export default CheckboxInput;
