import { FC, useEffect, useRef } from 'react';

//assets
import { ReactComponent as PaymentDisactiveIconSVG } from './assets/disactivePaymentIcon.svg';
import { ReactComponent as PaymentActiveIconSVG } from './assets/activePaymentIcon.svg';

//style
import {
    ProductMethodPaymentBox,
    PaypalMethodBox,
    TransferMethodBox,
    PaymentMethodText,
} from './style/PaymentMenuHelper.style';


const PaymentMenuHelper: FC<{
    handlePaymentMethod: (paymentMethod:string) => void, 
    selectedPayment: string,
    onClose: () => void
}> = ({
    handlePaymentMethod,
    selectedPayment,
    onClose
}) => {

    const internalHandlePaymentMethod = (paymentMethod:string) => {
        handlePaymentMethod(paymentMethod);
    };

    const menuRef = useRef<HTMLDivElement>(null);

    const paypalSelected = selectedPayment === "paypal";
    const bonificoSelected = selectedPayment === "bonifico";

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
          ) {
            // Clicked outside the menu, so close it
            onClose();
          }
        };
      
        document.addEventListener("click", handleClickOutside);
      
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [onClose]);
   

    return (
        <ProductMethodPaymentBox ref={menuRef}>  
            <PaypalMethodBox isActive={paypalSelected} onClick={() => internalHandlePaymentMethod("paypal")}>
                {!paypalSelected &&<PaymentDisactiveIconSVG />}

                {paypalSelected &&<PaymentActiveIconSVG />}

                <PaymentMethodText isActive={paypalSelected}>Paypal</PaymentMethodText>
            </PaypalMethodBox>

            <TransferMethodBox isActive={bonificoSelected} onClick={() => internalHandlePaymentMethod("bonifico")}>
                {!bonificoSelected &&<PaymentDisactiveIconSVG />}

                {bonificoSelected &&<PaymentActiveIconSVG />}

                <PaymentMethodText isActive={bonificoSelected}>Bonifico</PaymentMethodText>
            </TransferMethodBox>
    </ProductMethodPaymentBox>
    );
};

export default PaymentMenuHelper;



