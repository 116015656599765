import styled from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 100px 0 0;
`;
