import { FC, useState, memo } from 'react';

//components
import BaseModal from 'components/ui/BaseModal';
import MediaPlayer from 'components/MediaPlayer';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

//types
import { ClickableResourceProps } from './ClickableResource.component.d';

//style
import { Row } from 'style/layout';
import { BaseCard } from 'style/wrappers';
import {
    Box,
    CardContent,
    CloseButton,
    ImgToEnlarge
} from './style/ClickableResource.style';

const  ClickableResource: FC<ClickableResourceProps> = ({ fileType, url, alt, width }) => {
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false); 
    
    return(
        <>
            <Box width={width}>
                {fileType==='Image' &&
                    <img 
                        src={url} 
                        alt={alt} 
                        onClick={() => setIsVisible(true)}    
                    />
                }
                {fileType==='Video' &&
                    <MediaPlayer 
                        media={{src: url, type: 'video/mp4'}}
                        preload='metadata'
                    />
                }
            </Box>
            {isVisible && 
                <BaseModal 
                    onClose={() => {setIsVisible(false); setIsCloseButtonClicked(false);}}
                    isCloseButtonClicked={isCloseButtonClicked}
                    isBackdropClickActive
                    modalFixedStart
                >
                    <BaseCard 
                        flavor='outlined'
                        isModal
                    >
                        <CardContent>
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={() => setIsCloseButtonClicked(true)}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                            {fileType==='Image' &&
                                <ImgToEnlarge 
                                    src={url}
                                    alt='Immagine a tutto schermo'
                                />
                            }
                        </CardContent>
                    </BaseCard>
                </BaseModal>
            }
        </>
    );
}

export default memo(ClickableResource);