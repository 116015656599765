// component
import { FC } from "react";
import Lesson from "../LessonContent/Lesson.component";

// style
import { 
    ActiveLessonsBox,
    CoachText, 
    InfoText, 
    Label, 
    LabelBox, 
    LessonsGrid,
    LessonsTable,
    RowLabel
} from "./style/NewLessonMobile.style";


const NewLessonMobile : FC <{
    lesson: any,
    choiceLessonHandler: (lessonId: number, typeOfLesson: 'new' | 'ended') => void, 
    openPersonalAsistantNotesModal: () => void,
    openCustomerRatingModal: () => void,
    typeOfLesson?: 'new' | 'ended'
}>= ({
    lesson,
    typeOfLesson,
    choiceLessonHandler,
    openPersonalAsistantNotesModal,
    openCustomerRatingModal
}) => {

    return (
        <ActiveLessonsBox>
            <LabelBox>
                <Label>LEZIONI ATTIVE</Label>
            </LabelBox>

            <LessonsGrid>
                <LessonsTable>
                    <RowLabel>
                        <InfoText>SCADENZA</InfoText>

                        <CoachText>A.P. / COACH</CoachText>
                    </RowLabel>

                    {/* map di lesson */}
                    {(lesson !== undefined) && lesson.map((lesson: any, index: number) => {
                        return (
                            <Lesson
                                key={index}
                                lesson={lesson}
                                choiceLessonHandler={choiceLessonHandler}
                                onCommentClick={openPersonalAsistantNotesModal}
                                onRatingClick={openCustomerRatingModal}
                                typeOfLesson={typeOfLesson}
                            />
                        );
                    })}

                </LessonsTable>
            </LessonsGrid>
        </ActiveLessonsBox>
    );
};

export default NewLessonMobile;