// hooks
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import NewThematicCall from "components/ThematicCallComponents/NewThematicCall/NewThematicCall.component";
import PastThematicCall from "components/ThematicCallComponents/PastTematicCall/PastThematicCall.component";
import SwitchSelector from "components/SwitchSelector/SwitchSelector.componet";
import FilterShopDesktop from "pages/ShopPage/FilterShop/layout/FilterShopDesktop/FilterShopDesktop.component";
import FilterShopMobile from "pages/ShopPage/FilterShop/layout/FilterShopMobile/FilterShopMobile.component";
import { getCourseWithBrand, getCourseWithBrandHidden } from "../services/ThematicDirectCatalogue.services";

//assets
import { ReactComponent as SearchIconSVG } from './assets/searchIcon.svg';
import { ReactComponent as CleanIconSVG } from './assets/cleanIcon.svg';

// styles
import { 
    CleanBox,
    FilterBox,
    FilterProductBox,
    FilterText,
    MainCatalogue, 
    NewDirect, 
    PastDirect, 
    ProductFilterBox, 
    ProductMainInteractionBox, 
    SearchBar, 
    SearchBarBox, 
    SeparatorDirect, 
    ShopInteractionBox, 
    ShopOperationBox, 
    ShopTitle, 
    ShopTitleBox, 
    TitleSeparator, 
    TotalPage, 
    TypeProductTitle
} from "./style/ThematicDirectCatalogue.style";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import BankTransferModal from "components/BankTransferModal/BankTransferModal.component";
import { getItemFromLocalStorage } from "utils/localStorage";
import store from "redux/store";
import useBrand from "hooks/useBrand";


const ThematicDirectCatalogue = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const navigate = useNavigate();
    const [isDirectFilterActive, setIsDirectFilterActive] = useState(true);
    const [isVipDirectFilterActive, setIsVipDirectFilterActive] = useState(true);
    const [searchInput, setSearchInput] = useState<any>("");
    const [thematicBroadcastProducts, setThematicBroadcastProducts] = useState<any[]>([]);
    const [thematicBroadcastProductsHidden, setThematicBroadcastProductsHidden] = useState<any[]>([]);
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);

    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const [isLibertyPastPaymentModalVisible, setIsLibertyPastPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(false);
    const openLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(true);

    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);

    const [isFilterOpenActive, setIsFilterOpenActive] = useState<string>("");
    const [isSubFilterOpenActive, setIsSubFilterOpenActive] = useState<string>("");
    const [selectedMenuPosition, setSelectedMenuPosition] = useState<number>(0);
    const [selectedSubMenuText, setSelectedSubMenuText] = useState<string>('');
    const [isSubMenuVisible, setIsSubMenuVisible] = useState<boolean>(false);
    const [filterProductsList, setFilterProductsList] = useState<any[]>([]);
    const [filterProductsListHidden, setFilterProductsListHidden] = useState<any[]>([]);
    const [isType, setIsType] = useState<string>('');

    let prevSelectedSubMenuText = selectedMenuPosition;

    const changeFilterOpenActive = 
        (text: string) => setIsFilterOpenActive(text)
    ;
    const changeSubFilterOpenActive = (text: string) => 
        setIsSubFilterOpenActive(text)
    ;

    const clearContollerHandler = () => {
        
        changeFilterOpenActive('');
        changeSubFilterOpenActive('');
        setSelectedMenuPosition(0);
        setSelectedSubMenuText('');
        setIsSubMenuVisible(false);
    };

    const breadgrumbFilterHandler = () => {
        changeSubFilterOpenActive('');
        setSelectedSubMenuText('');
        setSelectedMenuPosition(prevSelectedSubMenuText)
    };
    
    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyPastModalHandler = () => {
        setIsLibertyPastPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const getProducts = async () => {
        
        try {
            const response = await getCourseWithBrand();

            if (response) {

                const thematicBroadcastProducts = response.filter((product: any) => ((product.type === "THEMATIC_BROADCAST")))
                console.log("DIRETTE:", thematicBroadcastProducts);
                
                setThematicBroadcastProducts(thematicBroadcastProducts);
            }
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };

    const getProductsHidden = async () => {
        
        try {
            const response = await getCourseWithBrandHidden();

            if (response) {

                const thematicBroadcastProductsHidden = response.filter((product: any) => ((product.type === "THEMATIC_BROADCAST")))
                console.log("DIRETTE Passate:", thematicBroadcastProductsHidden);
                
                setThematicBroadcastProductsHidden(thematicBroadcastProductsHidden);
            }
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {
        
        getProducts();
        getProductsHidden();
    },[useBrand()])

    useEffect(() => {
        const isBankTransferPending = getItemFromLocalStorage('Pending Bank Transfer');
        
        if (isBankTransferPending === "true") {
            const pendingCoursePurchase = getItemFromLocalStorage('Pending purchase ID');
            
            if (pendingCoursePurchase === null) return;
            setIsBankTransferPending(true);
            setPaymentTransferID(+pendingCoursePurchase)
            setIsBankTransferModalVisible(true);
        }
    },[useBrand()])

    const onClickDirectFilterHandler = () => {
        setIsDirectFilterActive((prevState) => !prevState);
    };
    
    const onClickVipDirectFilterHandler = () => {
        setIsVipDirectFilterActive((prevState) => !prevState);
    };
    
    useEffect(() => {
        handleDirectFilter();
        handleDirectFilterHidden();
    }, [useBrand(), isFilterOpenActive, isSubFilterOpenActive, isDirectFilterActive, isVipDirectFilterActive, searchInput, thematicBroadcastProducts, thematicBroadcastProductsHidden]);
      
    const handleDirectFilter = () => {
    
        const filteredByButton = thematicBroadcastProducts.filter((product: any) => {
            const isDiretteActive = isDirectFilterActive;
            const isDiretteVipActive = isVipDirectFilterActive;
        
            if (isDiretteVipActive && isDiretteActive) {
                return true;  // Show both "Dirette" and "Dirette VIP"
            }
        
            if (isDiretteVipActive) {
                return product.productThematicCall.vip;  // Only show "Dirette VIP"
            }
        
            if (isDiretteActive) {
                return !product.productThematicCall.vip;  // Only show "Dirette"
            }
        
            return false;  // Default, show nothing for other cases
        });

        const filteredCategory = filteredByButton.filter((product: any) => {
            const categoryMatches = isFilterOpenActive === "" || product.categories.some(
                (category: any) =>
                    category &&
                    category.name &&
                    category.name.toUpperCase() === isFilterOpenActive.toUpperCase()
            );
    
            const subCategoryMatches = isSubFilterOpenActive === "" || product.subCategories.some(
                (subCategory: any) =>
                    subCategory &&
                    subCategory.name &&
                    subCategory.name.toUpperCase() === isSubFilterOpenActive.toUpperCase()
            );
    
            const searchMatches = searchInput.trim() === "" ||
                product.name.toLowerCase().includes(searchInput.toLowerCase());
    
            return categoryMatches && subCategoryMatches && searchMatches;
        });
    
        setFilterProductsList(filteredCategory);
    };
        
    const handleDirectFilterHidden = () => {
    
        const filteredByButton = thematicBroadcastProductsHidden.filter((product: any) => {
            const isDiretteActive = isDirectFilterActive;
            const isDiretteVipActive = isVipDirectFilterActive;
        
            if (isDiretteVipActive && isDiretteActive) {
                return true;  // Show both "Dirette" and "Dirette VIP"
            }
        
            if (isDiretteVipActive) {
                return product.productThematicCall.vip;  // Only show "Dirette VIP"
            }
        
            if (isDiretteActive) {
                return !product.productThematicCall.vip;  // Only show "Dirette"
            }
        
            return false;  // Default, show nothing for other cases
        });

        const filteredCategoryHidden = filteredByButton.filter((product: any) => {
            const categoryMatches = isFilterOpenActive === "" || product.categories.some(
                (category: any) =>
                    category &&
                    category.name &&
                    category.name.toUpperCase() === isFilterOpenActive.toUpperCase()
            );
    
            const subCategoryMatches = isSubFilterOpenActive === "" || product.subCategories.some(
                (subCategory: any) =>
                    subCategory &&
                    subCategory.name &&
                    subCategory.name.toUpperCase() === isSubFilterOpenActive.toUpperCase()
            );
    
            const searchMatches = searchInput.trim() === "" ||
                product.name.toLowerCase().includes(searchInput.toLowerCase());
    
            return categoryMatches && subCategoryMatches && searchMatches;
        });
    
        setFilterProductsListHidden(filteredCategoryHidden);
    };
    
    const searchHandler = (searchValue: any) => {
        setSearchInput(searchValue.target.value);
    };
    
    const clearSearchHandler = () => {
        setSearchInput("");
    };

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };
    
    const isLiveDatePassed = (liveDate : string) => {
        const currentDate = new Date(+ 'Z').getTime();
        const liveDateObj = new Date(liveDate + 'Z').getTime();
        return liveDateObj < currentDate;
    };

    const filteredListCopy = [...filterProductsList];

    filteredListCopy.sort((a, b) => {
        const dateA = new Date(a.productThematicCall.liveDate + 'Z').getTime();
        const dateB = new Date(b.productThematicCall.liveDate + 'Z').getTime();
        return dateA - dateB;
    });

    const currentDate = new Date();

    const futureAndOngoingEvents = filteredListCopy.filter((product) => {
        const liveDate = new Date(product.productThematicCall.liveDate + 'Z');
      
        const twoHoursAfterLiveDate = new Date(liveDate.getTime() + 2 * 60 * 60 * 1000);
      
        return liveDate >= currentDate || currentDate <= twoHoursAfterLiveDate;
    });

    const futureAndOngoingEventsNotBuy = filteredListCopy.filter((product) => {
        const liveDate = new Date(product.productThematicCall.liveDate + 'Z');
      
        const twoHoursAfterLiveDate = new Date(liveDate.getTime());
      
        return liveDate >= currentDate || currentDate <= twoHoursAfterLiveDate;
    });

    const combinedList = futureAndOngoingEvents.concat(futureAndOngoingEventsNotBuy);

    const uniqueCombinedList = combinedList.filter((item, index, self) => self.indexOf(item) === index);
      
    const handleThematicTypeChange = (thematicType : any) => {
        setIsType(thematicType)
        console.log(thematicType);
    };
      

    return (
        <>
            <TotalPage>
                <ShopOperationBox>
                    <ShopTitleBox>
                        <ShopTitle>Dirette Tematiche</ShopTitle>

                        {isFilterOpenActive !== "" && <ShopTitle>/</ShopTitle>}

                        <TypeProductTitle>{isFilterOpenActive}</TypeProductTitle>

                        {isSubFilterOpenActive !== "" && <ShopTitle>/</ShopTitle>}

                        <TypeProductTitle>{isSubFilterOpenActive}</TypeProductTitle>
                    </ShopTitleBox>

                    {isAppMobile && store.getState().ui.brand?.id !== 4 &&
                        <FilterShopMobile 
                            changeFilterOpenActive={changeFilterOpenActive} 
                            changeSubFilterOpenActive={changeSubFilterOpenActive}
                        />
                    }
                    
                    <ProductMainInteractionBox>
                        <FilterProductBox>
                            <ProductFilterBox onClick={onClickDirectFilterHandler}>
                                <SwitchSelector isFilterActive={isDirectFilterActive}/>

                                <FilterText>Dirette</FilterText>
                            </ProductFilterBox>

                            <ProductFilterBox onClick={onClickVipDirectFilterHandler}>
                                <SwitchSelector isFilterActive={isVipDirectFilterActive}/>

                                <FilterText>Dirette VIP</FilterText>
                            </ProductFilterBox>
                        </FilterProductBox>

                        <ShopInteractionBox>
                            <SearchBarBox>
                                <SearchBar                                
                                    onChange={searchHandler}
                                    value={searchInput} 
                                    />

                                {searchInput !== "" &&
                                    <CleanBox onClick={clearSearchHandler}>
                                        <CleanIconSVG />
                                    </CleanBox>}
                            </SearchBarBox>

                            <SearchIconSVG/>
                        </ShopInteractionBox>
                    </ProductMainInteractionBox>
                </ShopOperationBox>

                {!isAppMobile && store.getState().ui.brand?.id !== 4 &&
                    <FilterBox>
                        <FilterShopDesktop 
                            changeFilterOpenActive={changeFilterOpenActive}
                            changeSubFilterOpenActive={changeSubFilterOpenActive}
                            clearContollerHandler={clearContollerHandler}
                            isSubMenuVisible={isSubMenuVisible}
                            selectedSubMenuText={selectedSubMenuText}
                            selectedMenuPosition={selectedMenuPosition} 
                            setIsSubMenuVisible={setIsSubMenuVisible} 
                            setSelectedSubMenuText={setSelectedSubMenuText} 
                            setSelectedMenuPosition={setSelectedMenuPosition} 
                            activeButtonClick={breadgrumbFilterHandler}                   
                        />
                    </FilterBox>
                }

                <MainCatalogue>
                    <SeparatorDirect>
                        <TitleSeparator>DIRETTE IN PARTENZA</TitleSeparator>
                    </SeparatorDirect>

                    <NewDirect>
                        {uniqueCombinedList.map((product, index) => (
                            (product.ownedAs === "SPECTATOR" || "PROTAGONIST" || "") &&
                            !isLiveDatePassed(product.productThematicCall.liveDate) && product.productThematicCall.status === 'SCHEDULED' && 
                                <NewThematicCall
                                    key={index}
                                    product={product}
                                    courseElement={product.requisitesSatisfied}
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    onThematicTypeChange={handleThematicTypeChange}
                                />
                                
                        ))}
                        {/* {futureAndOngoingEventsNotBuy.map((product, index) => (
                            (product.owned !== true ) && (product.ownedAs === "SPECTATOR" || "PROTAGONIST" || "") &&
                            !isLiveDatePassed(product.productThematicCall.liveDate) && product.productThematicCall.status === 'SCHEDULED' && 
                                <NewThematicCall
                                    key={index}
                                    product={product}
                                    courseElement={product.requisitesSatisfied}
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    onThematicTypeChange={handleThematicTypeChange}
                                />
                                
                        ))} */}
                    </NewDirect>

                    <SeparatorDirect>
                        <TitleSeparator>CATALOGO DIRETTE</TitleSeparator>
                    </SeparatorDirect>

                    <PastDirect>
                        {filterProductsList
                        .filter(product => {
                        return (
                            product.productThematicCall.onShop === true &&
                            product.productThematicCall.status === 'PUBLISHED' &&
                            new Date(product.productThematicCall.expirationDate) < new Date()
                        );
                        })
                        .sort((a, b) => {
                            const dateA = new Date(a.productThematicCall.liveDate).getTime();
                            const dateB = new Date(b.productThematicCall.liveDate).getTime();
                            return dateB - dateA; 
                        })
                        .map((product, index) => {
                            if (product.productThematicCall.onShop === true && product.productThematicCall.status === 'PUBLISHED') {
                            const expirationDate = new Date(product.productThematicCall.expirationDate);
                            const currentDate = new Date();
                            
                            if (expirationDate < currentDate) {
                                return (
                                <PastThematicCall
                                    key={index}
                                    product={product}
                                    courseElement={product.requisitesSatisfied}
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyPastModalHandler}
                                    openLibertyPaymentModal={openLibertyPastPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                />
                                );
                            }
                            }
                            return null;
                        })}
                    </PastDirect>                
                </MainCatalogue>
            </TotalPage>

            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    productType="ThematicBroadcast"
                    thematicBroadcastType={isType}
                />
            }

            {isLibertyPastPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPastPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    isPastBroadcast={true}
                />
            }
        </>
    );
};

export default ThematicDirectCatalogue;