import colors from "style/palette";
import styled from "styled-components";

export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0 60px;
    gap: 20px;

    width: 100%;
`;

export const MainTitle = styled.h1`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 600;
    font-size: 50px;

    color: ${colors.white};

    padding: 0px;
    margin: 20px 0 10px;
`;

export const Paragraph = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 10px;
`;

export const ParagraphTitle = styled.h3`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 350;
    font-size: 35px;

    color: ${colors.white};

    padding: 0px;
    margin: 0px;
`;

export const Text = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: ${colors.white};

    padding: 0px;
    margin: 10px 0;
`;

export const List = styled.ul`
    
`;

export const ListText = styled.li`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: ${colors.white};

    padding: 0px;
    margin: 10px 0;
`;

export const Link = styled.a`
    color: ${colors.white};

    padding: 0px;
    margin: 0px;
`;

export const Span = styled.span`
    font-weight: 600;
`;