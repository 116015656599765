import styled from 'styled-components';


export const SliderBox = styled.div`

    font-size: 10px;

    width: 100%;
    height: 60%;

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Container = styled.div`

    width: auto;
    height: 10px;

    margin: 0 10px;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    :hover ${SliderBox} {

        height: 100%;
        font-size: 20px;
    }
`;
