import { FC, useEffect, useState } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

//utils
import config from 'configs/config';

// Components
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LoadingMask from 'components/ui/LoadingMask/LoadingMask.component';
import { Brand } from 'types/Brand';
import { getBrandsList } from 'services/brand.services';
import LandingPageLDL from 'pages/LDL/LandingPageLDL';
import LandingPageVDR from 'pages/VDR/LandingPageVDR';

const Landing:FC<{isRoot?: boolean}> = ({isRoot}) => {
    const { tokenID } = useAppSelector(state => state.user);
    const [ actualBrand, setActualBrand ] = useState<Brand | undefined>();

    const navigate = useNavigate()
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const isUserLoggedIn = !!tokenID;

    function removeTrailingSlash(str: String): String {
        return str.endsWith('/') ? str.slice(0, -1) : str;
    }

    useEffect(()=>{
        if (!actualBrand) return;
        if (isUserLoggedIn && isRoot) navigate('reservedArea');
        console.log("isROOT: ",isRoot);
        console.log("BRAND in Landing: ",actualBrand?.code);
        if (removeTrailingSlash(window.location.href).endsWith('/app')) {
            var signup = 'sign-up';
            if (window.location.hostname.includes('localhost')) {
                signup += '?'+searchParams.toString();
            }
            if (actualBrand?.code === 'MB') navigate(signup);
            if (actualBrand?.code === 'TCR') navigate(signup);
        }
    },[isUserLoggedIn, isRoot, actualBrand, navigate]);

    useEffect(() => {
        const gb = async () => {
            try {
                console.log("GETTING BRANDS?");
                const brandList: Brand[] = await getBrandsList();
                console.log("GOT BRANDS: ", brandList);
                var localBrand: Brand | undefined = undefined;
                localBrand = brandList.find((b) => window.location.hostname.includes(b.domain));
                if (searchParams.get('brand') && window.location.hostname.includes('localhost')) localBrand = brandList.find((b) => b.code === searchParams.get('brand'));
                if (!localBrand) localBrand = config.defaultBrand;
                console.log("LANDING loaded brand: ",JSON.stringify(localBrand));
                setActualBrand(localBrand);
            } catch (error) {
                console.log("ERROR GETTING BRANDS: ",error);
            }
        }
        gb();
    }, [location.pathname, searchParams]);

    if (tokenID === undefined) return <LoadingMask size='page' paddingTop='200px' isCenter/>
    
    
	return (  
        <GoogleReCaptchaProvider reCaptchaKey="6LdAKRwiAAAAAEED3nu84BJuwimtZpQNbmA5QNkv">
            { (actualBrand?.code === 'LDL') && <LandingPageLDL />}
            { (actualBrand?.code === 'VDR') && <LandingPageVDR />}
        </GoogleReCaptchaProvider>
	);
};

export default Landing;
