import { FC } from 'react';

//redux
import { enableAppMain } from 'redux/slices/slice-app-main';

//routing
import { useNavigate } from 'react-router';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//component
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

//assets
import { ReactComponent as InfoIconSVG } from '../assets/infoIcon.svg';

//style
import {
    ProductGeneralBox,
    ProductTitle,
    ProductBox,
    ServiceImage,
    ProductInfoBox,
    ProductIconBox,
    InfoIconBox,
    ProductDescriptionBox,
    ProductDesciptionEllipsis,
    ProductDesciption,

} from './style/ServiceBox.style';
import store from 'redux/store';



/**
 * Service Box
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

 const ServiceBox: FC<{
    courseElement: any,
    onInfoButtonClick: (productId: string, productName: string, productPrice: string) => void,
    onTransferClick: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    openBankTransferModal: () => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    libertyProductID: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    isRequisite?: boolean
}> = ({
    courseElement,
    onInfoButtonClick,
    onTransferClick,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    isRequisite
}) => {

    const {name, salesData, sellImage, productService, id, paymentTypes} = courseElement;
    const brandCode = store.getState().ui.brand?.code;
    const productImage = sellImage? (sellImage.url ?? `https://api.ldl-test.eu/v1/productsforsell?brandCode=${brandCode}`) : `https://api.ldl-test.eu/v1/productsforsell?brandCode=${brandCode}`
    const vipPoint = productService?.paymentPlan?.vipPoints;
    const trialVipPoint = productService?.paymentPlan?.trialVipPoints;
    const navigate=useNavigate();
    const dispatch = useAppDispatch();

    // const infoButtonClickHandler = () => onInfoButtonClick(id, name, '0');

    const infoButtonClickHandler = () => {
        if (isRequisite) {
            navigate(`/public/product/${id}`);
            dispatch(enableAppMain());
            return
        }
        onInfoButtonClick(id, name, '0');
    };

    const finalDelivery = salesData !== undefined ? salesData.delivery : "";

    return (
        <ProductGeneralBox>
            <ProductTitle>{name}</ProductTitle>

            <ProductBox>
                <ProductInfoBox>
                    <ServiceImage alt='Confezione Corso' src={productImage}/>

                    <ProductIconBox>
                        <InfoIconBox onClick={infoButtonClickHandler}>
                            <InfoIconSVG />
                        </InfoIconBox>
                    </ProductIconBox>
                </ProductInfoBox>

                <ProductDescriptionBox>
                    <ProductDesciptionEllipsis>
                        <ProductDesciption dangerouslySetInnerHTML={{__html: finalDelivery}} />
                    </ProductDesciptionEllipsis>

                    <ButtonShop 
                        PriceProduct={productService.paymentPlan ? productService.paymentPlan.price : 0} 
                        TypeOfProduct="SERVICE"
                        courseElement={courseElement}
                        courseID={id}
                        payments={paymentTypes}
                        onTransferClick={onTransferClick}
                        openBankTransferModal={openBankTransferModal}
                        onLibertyClick={onLibertyClick}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        productIdHandler = {libertyProductID}
                        productPriceHandler = {libertyProductPrice}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                        vipPoints={vipPoint}
                        trialVipPoint={trialVipPoint}
                    />
                </ProductDescriptionBox>
            </ProductBox>
        </ProductGeneralBox>
    );
 };

 export default ServiceBox;