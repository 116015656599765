import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// types
import { JourneyInfoModalProps } from './JourneyInfoModal';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as EarnSVG } from 'assets/imgs/home-page/cards/earn-img.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/JourneyInfoModal.style';
import { getEnvironmentBaseUrl, getEnvironmentProductId } from 'configs/config.helper';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const JourneyInfoModal: FC<JourneyInfoModalProps> = ({ onCloseButtonModal }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = () => {
        window.open(
            getEnvironmentBaseUrl(`app/public/course/${getEnvironmentProductId('bonus sicuro')}`),
            '_blank',
            'noreferrer noopener'
        )
    };

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <EarnSVG />
                    </IconBox>

                    <Title>
                        COSA DEVO FARE PER GUADAGNARE
                    </Title>

                    <TextContent>
                        Il modo più sicuro e veloce di creare Soldi dal nulla e costruirsi un’entrata mensile senza rischio, 
                        è il percorso BONUS.<br/><br/>
                        <span>Se hai grossi capitali che puoi far lavorare per te, potresti anche saltarlo, 
                        ma rinunceresti a un grande profitto e NON è consigliato.</span><br/><br/>
                        Se non ti piacciono i Bonus, ricorda che li farai per un periodo limitato e che richiedono poco tempo al giorno, 
                        sempre meglio che lavorare ORE per due lire.<br/><br/>
                        <span>Se proprio li detesti, e non vuoi fare un piccolo sforzo nemmeno per un periodo limitato, puoi partire col percorso crypto.</span>
                    </TextContent>

                    <StartButton onClick={startButtonHandler}>INIZIA IL PERCORSO BONUS</StartButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default JourneyInfoModal;
