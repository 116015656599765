import { FC, useEffect, useState } from 'react';

// asset
import {ReactComponent as ReturnSVG} from './Message/assets/return.svg';

// routing
import { useNavigate, useParams } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utlis
import { getMessagesFromChannel, getUserChannels } from '../services/NotificationPage.services';

// components
import Message from './Message/Message.component';

// style
import { 
    Header,
    Return,
    Name,
    Img,
    Person,
    ReturnB,
    TotalPage,
    MessagesBox
} from './style/NotificationMessages.style';
import { scrollPage } from './NotificationMessages.helper';
import LoadingMask from 'components/ui/LoadingMask';

interface NotificationMessagesResponse {
    content: any[];
    pageable: {
      pageNumber: number;
      pageSize: number;
      offset: number;
      paged: boolean;
      unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    numberOfElements: number;
    first: boolean;
    empty: boolean;
  }

const NotificationMessage: FC<{channelData?: any, returnToChannelsList?: (n: number | undefined) => void}> = ({ channelData, returnToChannelsList }) => {
    
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [channelInfo, setChannelInfo] = useState<any>(channelData);
    // const [messages, setMessages] = useState<any>(undefined);
    const [isMessagesListScrolled, setIsMessagesListScrolled] = useState<boolean>(false);
    const { channelId: channelIdFromParams } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);


    const [messages, setMessages] = useState<any>({
        content: [],
        pageable: {
          pageNumber: 0,
          pageSize: 20,
        },
        totalPages: 0,
    });

    const [page, setPage] = useState<number>(0);
      

    const channelId: number = channelData.id ?? channelIdFromParams;
    const channel: any = channelInfo.channel;
    const channelName: string = channel.name ?? "";
    const icon: any = channel.icon ?? {}; 
    const channelImageUrl: string = icon.url ?? '';

    const loadingOn = () => {
      setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const returnOnChannelListHandler = () => {
        if (returnToChannelsList) returnToChannelsList(undefined);
    };

    const handleScroll = (event: any) => {
      
      const target = event.target;      
      // console.log("TARGET SCROLL:", target.scrollTop);
      
      if (target.scrollTop === 0) {
        loadNextPage();
      }
    };

    // const getMessages = async (channelId: number) => {

    //     try {
            
    //         const response: any[] = await getMessagesFromChannel(channelId);

    //         setMessages(response);
    //     } catch (error: any) {
    //         console.warn("ERROR:", error);
            
    //     }
    // };

    const getUpdateMessages = async (channelId: number, page?: number) => {
        try {
          const response: NotificationMessagesResponse = await getMessagesFromChannel(
            channelId,
            page
          );
          
          setMessages((prevMessages: any) => {
            const existingMessageIds = prevMessages.content.map(
              (message: any) => message.id
            );
            const newMessages = response.content.filter(
              (message: any) => !existingMessageIds.includes(message.id)
            );
            return {
              ...prevMessages,
              content: [...prevMessages.content, ...newMessages],
            };
          });
        } catch (error: any) {
          console.warn("ERROR:", error);
        }
    };
      
      const getMessages = async (channelId: number, page?: number) => {
        try {
          loadingOn();
          const response: NotificationMessagesResponse = await getMessagesFromChannel(
            channelId,
            page
          );
          setMessages(() => ({
            content: [...response.content],
            totalPages: response.totalPages,
            pageable: {
              pageNumber: response.pageable.pageNumber,
              pageSize: response.pageable.pageSize,
            },
          }));
          console.log("MESSAGES:", response);
          
          loadingOff();
        } catch (error: any) {
          loadingOff();
          console.warn("ERROR:", error);
        }
    };

    //   const loadNextPage = async () => {
    //     const nextPage = messages.pageable.pageNumber + 1;
    //     if (messages.totalPages === 0) return;
    //     if (nextPage <= messages.totalPages) {
    //       console.log("LOADING MESSAGES FROM NEXT PAGE");
    //       setPage(nextPage);
    //       try {
    //         const response: NotificationMessagesResponse = await getMessagesFromChannel(
    //           channelId,
    //           nextPage
    //         );
    //         setMessages((prevMessages: any) => ({
    //           ...prevMessages,
    //           content: [...prevMessages.content, ...response.content],
    //           pageable: {
    //             pageNumber: response.pageable.pageNumber,
    //             pageSize: 20,
    //           },      
    //         }));
    //       } catch (error: any) {
    //         console.warn("ERROR:", error);
    //       }
    //     }
    // };

    const loadNextPage = async () => {
      const nextPage = messages.pageable.pageNumber + 1;
      if (messages.totalPages === 0) return;
      if (nextPage <= messages.totalPages) {
        console.log("LOADING MESSAGES FROM NEXT PAGE");
        setPage(nextPage);
        const container = isAppMobile ? document.getElementById('messagesBoxPage') : document.getElementById('messagesBox');
        if (!container) {
          console.warn('Container element not found');
          return;
        }
        const previousHeight = container.scrollHeight;
        try {
          const response: NotificationMessagesResponse = await getMessagesFromChannel(
            channelId,
            nextPage
          );
          const newMessages = response.content.sort((messageA: any, messageB: any) => messageA.id - messageB.id);
          setMessages((prevMessages: any) => ({
            ...prevMessages,
            content: [...prevMessages.content, ...newMessages],
            pageable: {
              pageNumber: response.pageable.pageNumber,
              pageSize: 20,
            },
          }));
          const newHeight = container.scrollHeight;
          container.scrollTop = newHeight - previousHeight;
        } catch (error: any) {
          console.warn("ERROR:", error);
        }
      }
    };

    // console.log("Messaggi", messages);
    

    const getChannelInfo = async () => {

        try {
            
            const response = await getUserChannels();

            setChannelInfo(response.filter((channel: any) => channel.channel.id === channelIdFromParams)[0]);
        } catch (error: any) {
            console.warn("ERROR:", error);
            
        }
    };

    useEffect(() => {
        setIsMessagesListScrolled(false);
        if (channelId) {
          getMessages(channelId);
          // const interval = setInterval(() => {
          //   getUpdateMessages(channelId);
          // }, 2000);
          // return () => clearInterval(interval);
        }
    }, [channelId]);

    // useEffect(() => {
    //     // const interval = setInterval(() => {
    //       getUpdateMessages(channelId);
    //     // }, 2000);
    //     // return () => clearInterval(interval);
    //   }, [channelId]);

    useEffect(() => {
        
        if (channelData) return setChannelInfo(channelData);
        getChannelInfo();
    }, [channelId, channelData]);
    
    useEffect(() => {

        if (!channelInfo || channelInfo === undefined) return;
        if (isMessagesListScrolled) return;
        if (channelInfo.lastMessage === null) return;

        setTimeout(() => scrollPage(`${channelInfo.lastMessage.id}`), 1000);
        setIsMessagesListScrolled(true);
    }, [messages]);

    // const sortedMessages = (messages !== undefined) ? messages.content.sort((messageA: any, messageB: any) => (new Date(messageA.sendingDate).valueOf()) - (new Date(messageB.sendingDate).valueOf())) : [];
    const sortedMessages = (messages !== undefined) ? messages.content.sort((messageA: any, messageB: any) => messageA.id - messageB.id) : [];
    
    if (isLoading) return (
      <LoadingMask 
          isCenter
          size='big'
          paddingTop='200px'   
      />
  );
  
    return (
        <TotalPage id='messagesBoxPage' onScroll={handleScroll}>
            <Header>
                {isAppMobile && (
                    <ReturnB onClick={returnOnChannelListHandler}>
                        <ReturnSVG/>
                        <Return>Indietro</Return>
                    </ReturnB>
                )}

                <Name>
                    {channelName}
                </Name>

                <Img>
                    <Person 
                        alt=''
                        src={channelImageUrl}
                    />
                </Img>
            </Header>

            <MessagesBox id='messagesBox' onScroll={handleScroll}>
                {(messages !== undefined) && (
                    
                    sortedMessages.map((message: any, index: number) => (
                        <Message
                            key={"MS" + index}
                            message={message}
                            messageId={`${message.id}`}
                        />
                    ))
                )}
            </MessagesBox>
        </TotalPage> 
    );
}

export default NotificationMessage;