import styled, { keyframes } from 'styled-components';

// types
import { CardProps } from './RoadMapCard.style.d';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const ComingSoonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div<CardProps & {isOnMb: boolean}>`

    width: 100%;
    max-width: ${({ isExpanded }) => isExpanded ? "none" : "900px"};
    height: 320px;

    padding: 0 0 20px 0;

    border-radius: 15px;
    border: 0.5px solid ${colors.primaryColor};
    
    background: ${colors.veryDarkGrey};
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    overflow: hidden;


    @media ${devices.tablet} {

        width: ${({ isExpanded }) => isExpanded ? "none" : "67.67%"};
        height: ${({ isOnMb }) => isOnMb ? "374px" : "320px"};
    }

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
`;

export const Header = styled.div`

    width: 100%;
    /* padding: 14.5px 17.62px 9.35px 20.12px; */
    padding: 13px 25px 3px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 4px;
    flex-wrap: wrap;
    flex-direction: row;

    > h3 {

        margin: 0;
        padding: 0;

        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;

        color: ${colors.primaryColor};
    }

    > svg {

        display: none;

        width: 15.75px;
        height: 15.75px;

        cursor: pointer;

        @media screen and (min-width: 769px) {

            display: inline;

            width: 17.5px;
            height: 17.5px;
        }
    }

    @media screen and (min-width: 769px) {

        /* padding: 13px 25px 3px; */
    }
`;

export const EarningNews = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 34px;

    background: #131313;

    cursor: pointer;
`;

const scrollText = keyframes`

0% {
    transform: translateX(0); /* start with no translation */
  }
  100% {
    transform: translateX(-100%); /* end with full translation */
  }
`

export const StaticText = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 10px;

    margin: 0;
    padding: 0 0 0 10PX;

    font-size: 15px;
    font-weight: 500;
    color: ${colors.primaryColor};

    background: #131313;
    z-index: 10;

    > svg {

        margin: 0 10px;
        flex-shrink: 0;
    }
`;

export const NewsText = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    padding: 0 0 0 10PX;

    font-size: 12px;
    font-weight: 400;
    color: ${colors.white};
    white-space: nowrap;
    overflow: inherit;

    animation: ${scrollText} 20s linear infinite 3s;

    z-index: 5;
`;