import { useState } from 'react';
import ThematicDirectModal from 'components/ThematicDirectModal/ThematicDirectModal.component';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import { Link } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";

// assets
import { ReactComponent as InfoSVG } from '../assets/i.svg';
import { ReactComponent as LibertyIconSVG } from '../assets/libertyPoint.svg';
import { ReactComponent as PaypalIconSVG } from '../assets/paypal.svg';
import { ReactComponent as ArrowDSVG } from '../assets/arrowD.svg';

import { ReactComponent as RegisteredLDLSVG } from '../assets/registeredLDL.svg';
import { ReactComponent as LiveLDLSVG } from '../assets/liveLDL.svg';
import { ReactComponent as LaboratoryLDLSVG } from '../assets/laboratoryLDL.svg';

import { ReactComponent as RegisteredMBSVG } from '../assets/registeredMB.svg';
import { ReactComponent as LiveMBSVG } from '../assets/liveMB.svg';
import { ReactComponent as LaboratoryMBSVG } from '../assets/laboratoryMB.svg';
import { ReactComponent as PlaySVG } from '../assets/Play.svg';

import { ReactComponent as BuySVG } from '../assets/PastBuy.svg';

// styles
import { 
    ButtonComponent, 
    InfoButton, 
    MainComponent, 
    TitleComponent, 
    TitleText,
    ButtonBox,
    MainButton,
    LibertyPrice,
    ButtonText,
    PaymentSelector,
    PriceInfo,
    ArrowSelection,
    DoubleActionBox,
    PlayButton,
    NoCategoryBox
} from "./style/PastThematicCall.style";
import store from 'redux/store';


interface Product {
    name: string;
    productThematicCall: any;
    liveDate: string;
    subCategories: any;
    owned: boolean;
    categories: any;
    salesData: any;
    delivery: string;
    id: number;
    paymentTypes: any;
    subscriptionsAsProtagonist: number;
    subscriptionsAsSpectator: number;
    requisitesSatisfied: boolean;
    ownedAs: string;
    subscriptions:string;
    authorsNames: any;
}

const PastThematicCall: React.FC<{ 
    product: Product, 
    courseElement: any,
    onTransferClick: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    openBankTransferModal: () => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    libertyProductID: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void
}> = ({ 
    product, 
    courseElement,
    onTransferClick,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff
}) => {

    const [isModal, setIsModal] = useState <boolean> (false);

    const TypeName = product ? product.productThematicCall?.type : 'No Type';
    const Price = product ? product.productThematicCall.price : '';
    const id = product ? product.id : 0;
    const paymentTypes = product ? product.paymentTypes : 0;
    const owned = product ? product.owned : false;
    const name = product ? product.name : '';

    const dispatch = useAppDispatch();

    const handleInfoButtonClick = () => {
        setIsModal(prevIsModal => !prevIsModal);
    };

    const closeModal = () => {
        setIsModal(false);
    };
    
    // public purchase single e id

    return (
        <>
            <MainComponent>
                <TitleComponent>
                {TypeName === 'No Type' && <NoCategoryBox></NoCategoryBox>}

                {TypeName === 'LABORATORIO' && store.getState().ui.brand?.id === 1 && <LaboratoryLDLSVG/>}
                {TypeName === 'LIVE' && store.getState().ui.brand?.id === 1 && <LiveLDLSVG/>}
                {TypeName === 'REGISTRAZIONE' && store.getState().ui.brand?.id === 1 && <RegisteredLDLSVG/>}

                {TypeName === 'LABORATORIO' && store.getState().ui.brand?.id === 4 && <LaboratoryMBSVG/>}
                {TypeName === 'LIVE' && store.getState().ui.brand?.id === 4 && <LiveMBSVG/>}
                {TypeName === 'REGISTRAZIONE' && store.getState().ui.brand?.id === 4 && <RegisteredMBSVG/>}

                    {owned === true && 
                        <Link to={`/reservedArea/pagina-diretta-catalogo/${id}`} style={{ textDecoration: 'none' }}>
                            <TitleText>{name}</TitleText>
                        </Link>               
                    }

                    {owned !== true && owned !== false && 
                        <Link to={`/reservedArea/pagina-diretta-catalogo/${id}`} style={{ textDecoration: 'none' }}>
                            <TitleText>{name}</TitleText>
                        </Link>               
                    }

                    {owned === false &&
                        <TitleText>{name}</TitleText>
                    }             
                </TitleComponent>

                {owned === false &&
                    <ButtonComponent>
                        <InfoButton onClick={handleInfoButtonClick}>
                            <InfoSVG/>
                        </InfoButton>

                        <ButtonBox>
                            <ButtonShop 
                                PriceProduct={Price} 
                                TypeOfProduct={'COURSE'} 
                                courseElement={courseElement} 
                                courseID={id} 
                                payments={paymentTypes} 
                                onTransferClick={onTransferClick}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isPastBroadcast={true}
                            />
                        </ButtonBox>
                    </ButtonComponent>
                }

                {owned === true && 
                    <ButtonComponent>
                        <DoubleActionBox>
                            <InfoButton onClick={handleInfoButtonClick}>
                                <InfoSVG/>
                            </InfoButton>

                            <Link to={`/reservedArea/pagina-diretta-catalogo/${id}`} style={{ textDecoration: 'none' }}>
                                <PlayButton >
                                    <PlaySVG/>
                                </PlayButton>
                            </Link>               
                        </DoubleActionBox>

                        <ButtonBox>
                            <MainButton isOwned={owned}>

                                {Price !== '' && <ButtonText> € {Price}</ButtonText>}
                                {Price === '' && <ButtonText> IN REGALO</ButtonText>}

                                <BuySVG/>
                            </MainButton>
                        </ButtonBox>
                    </ButtonComponent>
                }

                {owned !== true && owned !== false &&
                    <ButtonComponent>
                        <DoubleActionBox>
                            <InfoButton onClick={handleInfoButtonClick}>
                                <InfoSVG/>
                            </InfoButton>

                            <Link to={`/reservedArea/pagina-diretta-catalogo/${id}`} style={{ textDecoration: 'none' }}>
                                <PlayButton >
                                    <PlaySVG/>
                                </PlayButton>
                            </Link>               
                        </DoubleActionBox>

                        <ButtonBox>
                            <MainButton isOwned={true}>

                                {Price !== '' && <ButtonText> € {Price}</ButtonText>}
                                {Price === '' && <ButtonText> IN REGALO</ButtonText>}

                                <BuySVG/>
                            </MainButton>
                        </ButtonBox>
                    </ButtonComponent>
                }
            </MainComponent>

            {isModal && <ThematicDirectModal onCloseButtonModal={closeModal} product={product}/>}
        </>
    );
};

export default PastThematicCall;