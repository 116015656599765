import { 
    NormalText, 
    Page, 
    TextBox, 
    TextYellow
} from "./style/ProductError.style";

const ProductError = () => {
    
    return (
        <Page>
            <TextBox>
                <TextYellow>
                    Il tuo pagamento è andato a buon fine, ma c'è stato un problema nell'assegnazione del prodotto sulla tua pagina personale.
                </TextYellow>

                <NormalText>Qualora il problema non dovesse risolversi entro 24h contatta il <a href="https://liberidalavoro.it/public/supporto"> supporto </a> per ricevere assistenza.</NormalText>
            </TextBox>
        </Page>
    )
};

export default ProductError;