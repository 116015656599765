import { CardOfPower } from '../VdrCards.d';

export const cardsOfPower: CardOfPower[] = [
	{ id: 1, assetName: '01_eros.webp' },
	{ id: 2, assetName: '02_gaia.webp' },
	{ id: 3, assetName: '03_i_limiti.webp' },
	{ id: 4, assetName: '04_il_cartografo.webp' },
	{ id: 5, assetName: '05_il_condottiero.webp' },
	{ id: 6, assetName: '06_il_consigliere.webp' },
	{ id: 7, assetName: '07_il_critico.webp' },
	{ id: 8, assetName: '08_il_maestro.webp' },
	{ id: 9, assetName: '09_il_mecenate.webp' },
	{ id: 10, assetName: '10_il_potere.webp' },
	{ id: 11, assetName: '11_il_pragmatico.webp' },
	{ id: 12, assetName: '12_il_realista.webp' },
	{ id: 13, assetName: '13_il_sacrificio.webp' },
	{ id: 14, assetName: '14_il_seme.webp' },
	{ id: 15, assetName: '15_il_sognatore.webp' },
	{ id: 16, assetName: '16_il_tempo.webp' },
	{ id: 17, assetName: '17_il_vampiro.webp' },
	{ id: 18, assetName: '18_il_veggente.webp' },
	{ id: 19, assetName: '19_l_abbondanza.webp' },
	{ id: 20, assetName: '20_l_adulatrice.webp' },
	{ id: 21, assetName: '21_l_amante.webp' },
	{ id: 22, assetName: '22_l_ansia.webp' },
	{ id: 23, assetName: '23_l_archeologo.webp' },
	{ id: 24, assetName: '24_l_artista.webp' },
	{ id: 25, assetName: '25_l_avaro.webp' },
	{ id: 26, assetName: '26_l_azione.webp' },
	{ id: 27, assetName: '27_l_eliminatore.webp' },
	{ id: 28, assetName: '28_l_energia.webp' },
	{ id: 29, assetName: '29_l_illusione.webp' },
	{ id: 30, assetName: '30_l_imprevisto.webp' },
	{ id: 31, assetName: '31_l_innamorato.webp' },
	{ id: 32, assetName: '32_l_intuizione.webp' },
	{ id: 33, assetName: '33_l_inventore.webp' },
	{ id: 34, assetName: '34_l_invidia.webp' },
	{ id: 35, assetName: '35_l_iracondo.webp' },
	{ id: 36, assetName: '36_l_oratore.webp' },
	{ id: 37, assetName: '37_la_ballerina.webp' },
	{ id: 38, assetName: '38_la_cecita.webp' },
	{ id: 39, assetName: '39_la_conoscenza.webp' },
	{ id: 40, assetName: '40_la_consapevolezza.webp' },
	{ id: 41, assetName: '41_la_creativita.webp' },
	{ id: 42, assetName: '42_la_fantasia.webp' },
	{ id: 43, assetName: '43_la_fecondita.webp' },
	{ id: 44, assetName: '44_la_flessibita.webp' },
	{ id: 45, assetName: '45_la_fortuna.webp' },
	{ id: 46, assetName: '46_la_genesi.webp' },
	{ id: 47, assetName: '47_la_lussuria.webp' },
	{ id: 48, assetName: '48_la_musa.webp' },
	{ id: 49, assetName: '49_la_pace.webp' },
	{ id: 50, assetName: '50_la_procrastinazione.webp' },
	{ id: 51, assetName: '51_la_resa.webp' },
	{ id: 52, assetName: '52_la_scelta.webp' },
	{ id: 53, assetName: '53_la_scienziata.webp' },
	{ id: 54, assetName: '54_la_voglia.webp' },
];
