// types
import { AppDispatch } from '../store';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Event } from 'pages/OddsScasserPage/types/Event';
import { RolloverObj } from 'pages/OddsScasserPage/OddsScasser/Rollover/types/RolloverObj';
import { MultipleObj } from 'pages/OddsScasserPage/OddsScasser/Multiple/types/MultipleObj';

// slice actions
import {
    setSites as actionSetSites,
    setEvents as actionSetEvents,
    setRollovers as actionSetRollovers,
    addRollover as actionAddRollover,
    updateRollover as actionUpdateRollover,
    removeRollover as actionRemoveRollover,
    setCalculatorsRollovers as actionSetCalculatorsRollovers,
    addCalculatorsRollover as actionAddCalculatorsRollover,
    updateCalculatorsRollover as actionUpdateCalculatorsRollover,
    removeCalculatorsRollover as actionRemoveCalculatorsRollover,
    setMultiples as actionSetMultiples,
    addMultiple as actionAddMultiple,
    updateMultiple as actionUpdateMultiple,
    removeMultiple as actionRemoveMultiple,
    setCalculatorsMultiples as actionSetCalculatorsMultiples,
    addCalculatorsMultiple as actionAddCalculatorsMultiple,
    updateCalculatorsMultiple as actionUpdateCalculatorsMultiple,
    removeCalculatorsMultiple as actionRemoveCalculatorsMultiple,
    setSingles as actionSetSingles,
    addSingle as actionAddSingle,
    updateSingle as actionUpdateSingle,
    removeSingle as actionRemoveSingle,
    setFilters as actionSetFilters,
    addFilters as actionAddFilters,
    updateFilters as actionUpdateFilters,
    removeFilters as actionRemoveFilters,
    setUserLevel as actionSetUserLevel,
} from '../slices/slice-oddsscasser';
import { SingleObj } from 'pages/OddsScasserPage/OddsScasser/types/SingleObj';
import { FiltersObj } from 'pages/OddsScasserPage/OddsScasser/types/FiltersObj';


export const setSites = (sites: Map<number, Site>) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetSites(sites) );
    };
};
////////
export const setEvents = (events: Map<number, Event>) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetEvents(events) );
    };
};
////////
export const setRollovers = (rollovers: RolloverObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetRollovers(rollovers) );
    };
};
export const addRollover = (rollover: RolloverObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddRollover(rollover) );
    };
};
export const updateRollover = (rollover: RolloverObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateRollover(rollover) );
    };
};
export const removeRollover = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveRollover(id) );
    };
};
////////
export const setCalculatorsRollovers = (rollovers: RolloverObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetCalculatorsRollovers(rollovers) );
    };
};
export const addCalculatorsRollover = (rollover: RolloverObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddCalculatorsRollover(rollover) );
    };
};
export const updateCalculatorsRollover = (rollover: RolloverObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateCalculatorsRollover(rollover) );
    };
};
export const removeCalculatorsRollover = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveCalculatorsRollover(id) );
    };
};
////////
export const setMultiples = (multiples: MultipleObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetMultiples(multiples) );
    };
};
export const addMultiple = (multiple: MultipleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddMultiple(multiple) );
    };
};
export const updateMultiple = (multiple: MultipleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateMultiple(multiple) );
    };
};
export const removeMultiple = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveMultiple(id) );
    };
};
////////
export const setCalculatorsMultiples = (multiples: MultipleObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetCalculatorsMultiples(multiples) );
    };
};
export const addCalculatorsMultiple = (multiple: MultipleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddCalculatorsMultiple(multiple) );
    };
};
export const updateCalculatorsMultiple = (multiple: MultipleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateCalculatorsMultiple(multiple) );
    };
};
export const removeCalculatorsMultiple = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveCalculatorsMultiple(id) );
    };
};
////////
export const setSingles = (singles: SingleObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetSingles(singles) );
    };
};
export const addSingle = (single: SingleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddSingle(single) );
    };
};
export const updateSingle = (single: SingleObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateSingle(single) );
    };
};
export const removeSingle = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveSingle(id) );
    };
};
////////
export const setFilters = (filters: FiltersObj[]) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetFilters(filters) );
    };
};
export const addFilters = (filters: FiltersObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionAddFilters(filters) );
    };
};
export const updateFilters = (filters: FiltersObj) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionUpdateFilters(filters) );
    };
};
export const removeFilters = (id: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionRemoveFilters(id) );
    };
};
///////
export const setUserLevel = (level: number) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetUserLevel(level) );
    };
};