import { FC } from 'react';

// types
import { ModifyButtonsProps } from './ModifyButtons.d';

// assets
import { ReactComponent as ModifyIconSVG } from './assets/modify-icon.svg';

// style
import { ModifyButtonBox, ButtonTextLink, SaveBox, Button } from './style/ModifyButtons.style';


/**
 * Modify buttons for form edit
 * 
 * @author Alessio Grassi
 * 
 * @param isModifying - state of the form
 * @param onModifyButtonClick - handler to set modify state
 * @param onCancelButtonClick - handler to exit modify mode
 * @param onSaveButtonClick - handler to save new values
 * @param isLoading - loading state
 * 
 * @returns JSX
 */


const ModifyButtons: FC<ModifyButtonsProps> = ({
        isModifying,
        onModifyButtonClick,
        onCancelButtonClick,
        onSaveButtonClick,
        isLoading
    }) => {

    const modifyButtonClickHandler = () => {

        if (!onModifyButtonClick) return;
        onModifyButtonClick();
    };

    const cancelButtonClickHandler = () => {

        if (!onCancelButtonClick) return;
        if (isLoading) return;
        onCancelButtonClick();
    };

    const saveButtonClickHandler = () => {

        if (!onSaveButtonClick) return;
        if (isLoading) return;
        onSaveButtonClick();
    };

    if (isModifying) {

        return (
            <SaveBox>
                <ButtonTextLink
                    type='button'
                    onClick={cancelButtonClickHandler}
                    disabled={isLoading}
                >
                    Annulla
                </ButtonTextLink>

                <Button 
                    type='submit'
                    onClick={saveButtonClickHandler}
                    disabled={isLoading}
                >
                    Salva
                </Button>
            </SaveBox>
        );
    }

    return (
        <ModifyButtonBox>
            <ModifyIconSVG />

            <ButtonTextLink
                type='button'
                onClick={modifyButtonClickHandler}
            >
                    Modifica
            </ButtonTextLink>
        </ModifyButtonBox>
    );
};

export default ModifyButtons;
