import styled from 'styled-components';
import colors from 'style/palette/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';

export const WhatsAppNeedCallBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    max-width: 430px;

    padding: 25px 20px;

    @media ${devices.tablet} {

        height: 145px;
        padding: 24px 40px;
    }
`;

export const WhatsAppNeedCallBoxContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 20px;

    width: 100%;

    @media ${devices.tablet} {
        
    }
`;

export const WhatsAppNeedCallTextContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    gap: 10px;
`;

export const WhatsAppNeedCallText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.white};
    opacity: 0.15;
`;

export const WhatsAppNeedCallButton = styled(Button)`
    font-size: 14px;
    line-height: 13px;
    opacity: 0.15;

    cursor: default;
`;

export const WhatsAppBox = styled.div`
    opacity: 0.15;
`;

export const ComingSoonBox = styled.div`
    position: absolute;
`;