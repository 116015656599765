import styled from "styled-components";
import devices from "style/breakpoints";
import colors from "style/palette/palette";


export const CaptionContent = styled.div`

    padding: 0;

    @media ${devices.tablet} {
        padding: 30px 40px 100px;
    }
`;

export const ContentTitle = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StepTitle = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;

    color: ${colors.white070};

    @media ${devices.tablet} {

        font-size: 18px;
    }
`;

export const TextSeparator = styled.p`

    margin: 0;
    padding: 0 8px;

    font-size: 13px;
    font-weight: 400;

    color: ${colors.white070};

    @media ${devices.tablet} {

        padding: 0 10px;
        font-size: 15px;
    }

`;

export const StepName = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;

    color: ${colors.white};

    @media ${devices.tablet} {

        font-size: 16px;
    }
`;

export const PageTitle = styled.h1`

    margin: 15px 0 0;
    padding: 0;

    font-size: 22px;
    font-weight: 400;
    line-height: 32px;

    color: ${colors.primaryColor};

    @media ${devices.tablet} {

        margin: 25px 0 0;
        font-size: 28px;
    }
`;

export const Content = styled.div`

    p {

        margin: 30px 0 0;

        font-size: 13px;
        font-weight: 400;
        line-height: 24px;

        color: ${colors.white};

        @media ${devices.tablet} {

            font-size: 15px;
            line-height: 28px;
        }

    }
`;

export const BoldText = styled.span`
    font-weight: 600;
`;

export const ItalicText = styled.span`
    font-style: italic;
`;

export const SubTitle = styled.span`
    
    font-weight: 600;
    color: ${colors.primaryColor};
`;
