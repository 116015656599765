import { FC, useEffect, useState } from "react";

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// helpers
import useAppDispatch from "hooks/useAppDispatch";
import { getEnvironmentAssetLinkUrl, getEnvironmentBaseUrl } from "configs/config.helper";
import { useCopyToClipboard } from "usehooks-ts";

// components
import MediaPlayer from "components/MediaPlayer";
import ButtonShop from "components/ButtonShop/ButtonShop.component";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import Timer from "components/Timer/Timer.component";
import TestimonialsCarousel from "pages/LandingPage/TestimonialsCarousel/TestimonialsCarousel.component";
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";
import TestimonialsCarouselMobile from "pages/LandingPage/TestimonialsCarousel/TestimonialsCarouselMobile/TestimonialsCarouselMobile.component";

// assets
import { ReactComponent as Vector } from './assets/Vector.svg';
import { ReactComponent as FacebookIconSVG } from '../../assets/facebookGreenIcon.svg';
import { ReactComponent as CheckIconSVG } from '../../assets/checkGreenIcon.svg';
import { ReactComponent as PhoneIconSVG } from '../../assets/whatsappIcon.svg';
import TotalEarningsIMG from '../../assets/totalEarningsIMG.png'
import { ReactComponent as CopySVG } from '../../assets/link-icon.svg';


// style
import { 
    CardPrice, 
    InfoPrice, 
    MediaGrid, 
    VideoImg,
    SecondaCard, 
    TextP, 
    TitleB, 
    TitleBox, 
    TitleCard, 
    TotalPage, 
    TextCheck, 
    FirstTwo,
    CardContent,
    BulletBox,
    TextBulllet,
    ButtonBox,
    BlockText,
    BlockImg,
    BlockRow,
    BlockGroup,
    ProductLimitationRow,
    LimitedSlotBox,
    LimitationDisclaimer,
    LimitedTimeBox,
    LimitedSlots,
    CarouselBox,
    ThreeBlockRow,
    BlockItem,
    IMGItem,
    IMGBox,
    IMGDescription,
    VideoCourseListBox
} from "./style/FreeFromFatiguePublicPageDesktop.style";

const FreeFromFatiguePublicPageDesktop: FC<{
    productData: any, 
    onTransferClick: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void
    isUserLoggedIn: boolean,
    onLibertyClick: () => void,
    openBankTransferModal: () => void,
    libertyInfoModalHandler?:() => void,
    paymentTransferID: number | undefined,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?:() => void,
    hasRequire?: boolean,
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    publicPageTransferPrice?: (grossPrice: number) => void,
    openImageHandler?: (imageUrl: string) => void,
    campaignId?: string | null,
}> = ({
    productData, 
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isUserLoggedIn,
    onLibertyClick,
    openBankTransferModal,
    libertyInfoModalHandler,
    paymentTransferID,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    openImageHandler,
    campaignId
}) => {

    const [product, setProduct] = useState<any>(productData);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductPurchasable, setIsProductPurchasable] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const { name, salesData, subscriptionMaxDate, subscriptions, maxSubscription } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const bulletList = salesData?.bulletList;
    const headLine = salesData?.headLine;
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;
    const salesPage = salesData?.salesPage;
    const blocks = salesPage?.blocks;
    const importantPhrase = salesData?.importantPhrase;

    const freeSlots = maxSubscription - subscriptions;

    const localMedia = {
        src: "https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/LDS_Presentazione.mp4",
        type: "video/mp4"
    };

    const setProductNoMorePurchasable = () => setIsProductPurchasable(false);
    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${productData.id}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    useEffect(() => {
        setProduct(productData);
    }, [productData]);

    const finalName = (name !== null) ? name : "TITOLO PRODOTTO";
    const finalHeadline = (headLine !== null) ? headLine : "HEADLINE";
    const finalBulletList1 = ((bulletList !== null) && (bulletList !== undefined)) ? bulletList[0] : "BULLET LIST";
    const finalBulletList2 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[1]) ? bulletList[1] : "BULLET LIST";
    const finalBulletList3 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[2]) ? bulletList[2] : "BULLET LIST";
    const finalPurchaseButtonText = (purchaseButtonText !== null) ? purchaseButtonText : "";
    const isBulletCardVisible = (headLine !== null) || (bulletList !== null) || (headLine !== undefined) || (bulletList !== undefined);
    const isBulletListVisible = (bulletList !== null) && (bulletList !== undefined);
    const isDescriptionCardVisible = (blocks !== null) && (blocks !== undefined);
    const isimportantPhraseVisible = (importantPhrase !== null) && (importantPhrase !== undefined);
    const productPrice = () => {
        if (product.type === 'SERVICE') return product.productService.paymentPlan.price;
        if (product.type === 'LESSON_PACK') return product.productLessonPack.price;
        return product.productCourse.price;
    };
    const finalImportantPhrase = importantPhrase != null ? importantPhrase : 'IMPORTANT PHRASE';
    const freeSlotText = ((maxSubscription !== null) && (maxSubscription !== undefined)) ? ((freeSlots > 0) ? freeSlots : "Posti Esauriti") : "Non ci sono limiti";
    // const isLimitedSlotBoxVisible = ((maxSubscription !== null) && (maxSubscription !== undefined) && isProductPurchasable);
    // const isLimitedTimeBoxVisible = ((subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined) && (freeSlotText !== 'Posti Esauriti'));
    const isLimitedSlotBoxVisible = (maxSubscription !== null) && (maxSubscription !== undefined);
    const isLimitedTimeBoxVisible = (subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined);
    const isSeatsAvailable = (isNaN(freeSlots)) ? true : ((freeSlots > 0) ? true : false);
    const isOfferValid = (subscriptionMaxDate !== undefined) ? (new Date(`${subscriptionMaxDate}Z`).getTime() > new Date().getTime()) : true;
        
    console.log("PRODOTTI :", productData)

    return(
        <TotalPage>
            <TitleBox>
                <TitleB>
                    Liberi dalla Stanchezza

                    <CopySVG onClick={copyIntoClipboardHandler} />
                </TitleB>
            </TitleBox>

            <FirstTwo>
                <MediaGrid>
                    <MediaPlayer
                        media={localMedia}
                        preload='medatada'
                        poster={'https://www.viveredipoker.it/ldlapifile/products/salespages/videocovers/liberi_dalla_stanchezza_bannersales_1683446896854.png'}
                    />
                </MediaGrid>

                <CardPrice>
                    <CardContent>
                        <InfoPrice>
                            <TitleCard>
                                LIBERI DALLA STANCHEZZA
                            </TitleCard>
                        
                            <TextP notCenter>
                            In collaborazione con Alex Viola di AlkaEnergy, 
                            il primo corso che ti insegna come funziona la nostra <b>"macchina biochimica"</b> e come mantenerla al meglio, 
                            senza dover fare diete o passare ore in palestra. <b>Strumenti semplici da applicare subito:</b>
                            </TextP>
                            <BulletBox>
                                <TextBulllet>
                                    <Vector/>

                                    <TextP noMargin>
                                        Basta dormire male
                                    </TextP>
                                </TextBulllet>

                                <TextBulllet>
                                    <Vector/>

                                    <TextP noMargin>
                                        Basta svegliarsi stanchi
                                    </TextP>
                                </TextBulllet>

                                <TextBulllet>
                                    <Vector/>

                                    <TextP noMargin>
                                        Basta abbiocco dopo pranzo
                                    </TextP>
                                </TextBulllet>
                            </BulletBox>
                        </InfoPrice>

                        <ButtonBox>
                            <ButtonShop 
                                PriceProduct={productPrice()} 
                                TypeOfProduct={product.type}
                                courseElement={product} 
                                courseID={product.id} 
                                payments={product.paymentTypes}
                                onTransferClick={onTransferClick}
                                isOpenInModal={isOpenInModal}
                                buyInfoModalHandler={buyInfoModalHandler}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                libertyInfoModalHandler={libertyInfoModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                isOnPublicProductPage
                                modalGetPaymentID={modalGetPaymentID}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                openProductRequisitesModal={openProductRequisitesModal}
                                hasRequire={hasRequire}
                                isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                                publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                publicPageTransferPrice={publicPageTransferPrice}
                                vipPoints={vipPoints}
                                campaignId={campaignId}
                            />
                        </ButtonBox>
                    </CardContent>
                </CardPrice>
            </FirstTwo>

            <SecondaCard>
                <IMGItem>
                    <IMGDescription>
                        <TextP>
                            Migliorare la propria salute e avere più energia è un diritto e anche un dovere verso te stesso, 
                            da oggi puoi farlo: <br />
                            <b>
                                - Senza passare ore in palestra <br />
                                - Senza fare diete <br />
                                - Senza fare ore di meditazione o Yoga <br/>
                            </b>
                            <br/>
                            Ti basta installare 13 semplici abitudini nel tuo stile di vita e 
                            hai <span>SUBITO RISULTATI (!)</span> <br />
                            Con queste abitudini, centinaia di persone hanno avuto da Subito:<br />
                            <b>+ Energia, + Tempo e anche + Soldi</b>
                        </TextP>
                    </IMGDescription>

                    <IMGBox imgWidth='400px'>
                        <img 
                            src="https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img1.jpg" 
                            alt="Testimonianza liberi dalla stanchezza" 
                            onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img1.jpg")}    
                        />
                    </IMGBox>
                </IMGItem>

                <IMGItem>
                    <IMGBox imgWidth="500px">
                        <img 
                            src="https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img2.jpg" 
                            alt="Testimonianza liberi dalla stanchezza" 
                            onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img2.jpg")}
                        />
                    </IMGBox>

                    <IMGDescription>
                        <TextP>
                            <b>NON è vero</b> che per stare bene bisogna soffrire, 
                            fare diete, andare in palestra ore, oppure fare lunghe meditazioni, 
                            sessioni di Yoga e chissà quante altre cose impegnative... <b>Sbagliato (!)</b><br />
                            <br />
                            L'unica cosa che ti serve per stare bene, è la consapevolezza di 
                            come funziona il tuo corpo, o meglio, la tua <span>"macchina bio-chimica".</span><br />
                            In questo corso trovi tanta conoscenza e strumenti pratici che 
                            puoi mettere subito in pratica e che portano <b>benefici immediati.</b>
                        </TextP>
                    </IMGDescription>
                </IMGItem>

                <ButtonBox>
                    <ButtonShop 
                        PriceProduct={productPrice()} 
                        TypeOfProduct={product.type} 
                        courseElement={product} 
                        courseID={product.id} 
                        payments={product.paymentTypes}
                        onTransferClick={onTransferClick}
                        isOpenInModal={isOpenInModal}
                        buyInfoModalHandler={buyInfoModalHandler}
                        openBankTransferModal={openBankTransferModal}
                        onLibertyClick={onLibertyClick}
                        libertyInfoModalHandler={libertyInfoModalHandler}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        isOnPublicProductPage
                        modalGetPaymentID={modalGetPaymentID}
                        productIdHandler = {libertyProductID}
                        productPriceHandler = {libertyProductPrice}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                        openProductRequisitesModal={openProductRequisitesModal}
                        hasRequire={hasRequire}
                        isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                        publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                        publicPageTransferPrice={publicPageTransferPrice}
                        vipPoints={vipPoints}
                        campaignId={campaignId}
                    />
                </ButtonBox>

                <IMGItem>
                    <IMGDescription>
                        <TextP>
                            Non occorre sottrarre tempo alle altre attività, 
                            anzi, usando questi strumenti avrai più tempo e più energie da subito e 
                            in effetti risparmierai anche dei bei soldi.<br />
                            <br />
                            Il corso ha un costo lancio estremamente conveniente e si ripromette 
                            di restituire 10 volte il prezzo del biglietto, inoltre, per assicurarci che tu abbia 
                            risultati, <b>hai a disposizione un gruppo facebook</b> per fare domande e 
                            ricevere assistenza in caso di bisogno...<br />
                            <br />
                            <span>C'è qualcosa di più importante della tua salute?</span> 
                            <br />
                            Abbi cura del tuo corpo, è l'unico posto dove sarai costretto a 
                            vivere per sempre (!)
                            <br />
                            <br />
                        </TextP>
                    </IMGDescription>

                    <IMGBox imgWidth="430px">
                        <img 
                            src="https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img3.jpg"
                            alt="Testimonianza liberi dalla stanchezza"
                            onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img3.jpg")}
                        />
                    </IMGBox>
                </IMGItem>

                <TextP>
                    <br />
                    <b>
                        Il corso include il modulo per tenere traccia dei tuoi miglioramenti. 
                        Questi sono i KPI del nostro Valentino:
                    </b>
                </TextP>

                <IMGItem>
                    <IMGBox imgWidth="750px">
                        <img 
                            src="https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img4.jpg"
                            alt="Testimonianza liberi dalla stanchezza"
                            onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoLiberiDallaStanchezza/img4.jpg")}
                        />
                    </IMGBox>
                </IMGItem>

                {productData.course !== undefined && productData.course.content !== undefined && (
                    <>
                        <TitleCard>
                            ESPLORA IL CONTENUTO DEL CORSO:
                        </TitleCard>

                        <VideoCourseListBox>
                            <VideoCourseList 
                                courseItem={productData.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos)}
                                collapseHandler={() => {}}
                                isOnPublicPage
                            />
                        </VideoCourseListBox> 
                    </>
                )}
                <ButtonBox paddingBottom='35px'>

                    <ButtonShop 
                        PriceProduct={productPrice()} 
                        TypeOfProduct={product.type} 
                        courseElement={product} 
                        courseID={product.id} 
                        payments={product.paymentTypes}
                        onTransferClick={onTransferClick}
                        isOpenInModal={isOpenInModal}
                        buyInfoModalHandler={buyInfoModalHandler}
                        openBankTransferModal={openBankTransferModal}
                        onLibertyClick={onLibertyClick}
                        libertyInfoModalHandler={libertyInfoModalHandler}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        isOnPublicProductPage
                        modalGetPaymentID={modalGetPaymentID}
                        productIdHandler = {libertyProductID}
                        productPriceHandler = {libertyProductPrice}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                        openProductRequisitesModal={openProductRequisitesModal}
                        hasRequire={hasRequire}
                        isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                        publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                        publicPageTransferPrice={publicPageTransferPrice}
                        vipPoints={vipPoints}
                        campaignId={campaignId}
                    />
                </ButtonBox>
            </SecondaCard>
        </TotalPage>
    );
};

export default FreeFromFatiguePublicPageDesktop;