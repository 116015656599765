import axios, { CancelToken, CancelTokenSource } from 'axios';
import { callDELETE, callGET, callPOST, callPUT } from "network/network";

//types
import { Site } from "../types/Site";
import { Event } from "../types/Event";
import { OddsScasserFilters } from "../OddsScasser/OddsScasserFilter/types/OddsScasserFilters";
import { BestOddsFilters } from "../OddsScasser/BestOddsFilter/types/BestOddsFilters";
import { RolloverObjDb, RolloverObj, RolloverData } from "../OddsScasser/Rollover/types/RolloverObj";
import { MultipleObjDb, MultipleObj, MultipleData } from "../OddsScasser/Multiple/types/MultipleObj";
import { SingleData, SingleObj, SingleObjDb } from '../OddsScasser/types/SingleObj';
import { FiltersObjDb, FiltersObj, FiltersData } from '../OddsScasser/types/FiltersObj';
import { OddsMatch } from "../types/OddsMatch";
import { Odds } from "../types/Odds";
import { LdlFile } from '../types/LdlFile';
import { BonusesResponseDb, Bonus, BonusDb, UserBonus, UserBonusDb } from '../BonusPage/types/BonusPage';

const endPointBase: string = 'v1/oddsscasser/'
const isAuthNeeded: boolean = true;

export const _getUserLevel = async () => {
    try {
        return parseInt(await makeRequest('GET', 'userlevel', {}));
    } catch (error: any) {
        console.warn(error);
        return -1;
    }
};

export const _getSites = async () => {
    try {
        const sites = new Map();
        const response = await makeRequest('GET', 'sites', {});
        if(!('error' in response)) response.forEach((site: Site) => sites.set(site.id, site));
        return sites;
    } catch (error: any) {
        console.warn(error);
        return new Map();
    }
};

export const _getEvents = async () => {
    try {
        const events = new Map();
        const response = await makeRequest('GET', 'events', {});
        if(!('error' in response)) response.forEach((event: Event) => events.set(event.id, {...event, datetime: event.datetime+'Z'}));
        return events;
    } catch (error: any) {
        console.warn(error);
        return new Map();
    }
};

let cancelToken: CancelTokenSource|undefined;
export const _getOddsMatches = async (endPoint: string, filters: OddsScasserFilters) => {
    try {
        if (typeof cancelToken != typeof undefined) {
            cancelToken && cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios.CancelToken.source();
        const response = await makeRequest('GET', endPoint, filters, {}, cancelToken.token);
        return response.map((x: OddsMatch) => ({...x, odds: x.odds.map(y => ({...y, lastUpdate: y.lastUpdate+'Z', lastChange: y.lastChange+'Z'}))}));
    } catch (error: any) {
        console.warn(error);
    }
};
export const _getBestEvents = async (filters: OddsScasserFilters) => {
    try {
        if (typeof cancelToken != typeof undefined) {
            cancelToken && cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios.CancelToken.source();
        const response = await makeRequest('GET', 'bestevents', filters, {}, cancelToken.token);
        return response.map((x: OddsMatch) => ({...x, odds: x.odds.map(y => ({...y, lastUpdate: y.lastUpdate+'Z', lastChange: y.lastChange+'Z'}))}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _getOdds = async (filters: BestOddsFilters) => {
    try {
        const response =  await makeRequest('GET', 'odds', filters);
        return response.map((x: Odds) => ({...x, lastUpdate: x.lastUpdate+'Z', lastChange: x.lastChange+'Z'}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _getCoverOdds = async (params: {eventId?: number, selection: string, minutes?: number}) => {
    try {
        const response = await makeRequest('GET', 'coverodds', params);
        return response.map((x: Odds) => ({...x, lastUpdate: x.lastUpdate+'Z', lastChange: x.lastChange+'Z'}));
    } catch (error: any) {
        console.warn(error);
    }
};

//Rollovers
export const _getRollovers = async (calculators?: boolean) => {
    try {
        const response = await makeRequest('GET', (calculators ? 'calculators/' : '')+'rollovers', {}) as RolloverObjDb[];
        return response.map(x => ({...x, data: JSON.parse(x.data) as RolloverData}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveRollover = async (payload: RolloverObj, calculators?: boolean) => {
    try {
        return await makeRequest('POST', (calculators ? 'calculators/' : '')+'rollovers', {...payload, data: JSON.stringify(payload.data)});
    } catch (error: any) {
        console.warn(error);
    }
};

export const _deleteRollover = async (id: number, calculators?: boolean) => {
    try {
        return await makeRequest('DELETE', (calculators ? 'calculators/' : '')+'rollovers/'+id.toString(), {});
    } catch (error: any) {
        console.warn(error);
    }
};

//Multiples
export const _getMultiples = async (calculators?: boolean) => {
    try {
        const response = await makeRequest('GET', (calculators ? 'calculators/' : '')+'multiples', {}) as MultipleObjDb[];
        return response.map(x => ({...x, data: JSON.parse(x.data) as MultipleData}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveMultiple = async (payload: MultipleObj, calculators?: boolean) => {
    try {
        return await makeRequest('POST', (calculators ? 'calculators/' : '')+'multiples', {...payload, data: JSON.stringify(payload.data)});
    } catch (error: any) {
        console.warn(error);
    }
};

export const _deleteMultiple = async (id: number, calculators?: boolean) => {
    try {
        return await makeRequest('DELETE', (calculators ? 'calculators/' : '')+'multiples/'+id.toString(), {});
    } catch (error: any) {
        console.warn(error);
    }
};

//Singles
export const _getSingles = async () => {
    try {
        const response = await makeRequest('GET', 'singles', {}) as SingleObjDb[];
        return response.map(x => ({...x, data: JSON.parse(x.data) as SingleData}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveSingle = async (payload: SingleObj) => {
    try {
        return await makeRequest('POST', 'singles', {...payload, data: JSON.stringify(payload.data)});
    } catch (error: any) {
        console.warn(error);
    }
};

export const _deleteSingle = async (id: number) => {
    try {
        return await makeRequest('DELETE', 'singles/'+id.toString(), {});
    } catch (error: any) {
        console.warn(error);
    }
};

//Filters
export const _getFilters = async () => {
    try {
        const response = await makeRequest('GET', 'filters', {}) as FiltersObjDb[];
        return response.map(x => ({...x, data: JSON.parse(x.data) as FiltersData}));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveFilters = async (payload: FiltersObj) => {
    try {
        return await makeRequest('POST', 'filters', {...payload, data: JSON.stringify(payload.data)});
    } catch (error: any) {
        console.warn(error);
    }
};

export const _deleteFilters = async (id: number) => {
    try {
        return await makeRequest('DELETE', 'filters/'+id.toString(), {});
    } catch (error: any) {
        console.warn(error);
    }
};

//BonusPage
const parseBonus = (bonus: BonusDb) => ({
    ...bonus,
    creationDate: bonus.creationDate ? bonus.creationDate+'Z' : undefined,
    expirationDate: bonus.expirationDate ? bonus.expirationDate+'Z' : undefined,
    steps: bonus.steps.map(y => ({
        ...y,
        expirationDate: y.expirationDate ? y.expirationDate+'Z' : undefined,
        unlockingDate: y.unlockingDate ? y.unlockingDate+'Z' : undefined,
        single: y.single ? {...y.single, data: JSON.parse(y.single.data) as SingleData} : undefined,
        multiple: y.multiple ? {...y.multiple, data: JSON.parse(y.multiple.data) as MultipleData} : undefined,
        rollover: y.rollover ? {...y.rollover, data: JSON.parse(y.rollover.data) as RolloverData} : undefined,
        filters: y.filters ? {...y.filters, data: JSON.parse(y.filters.data) as FiltersData} : undefined
    })),
    userBonus: bonus.userBonus ? parseUserBonus(bonus.userBonus) : bonus.userBonus
});

const stringifyBonus = (bonus: Bonus) => ({
    ...bonus,
    steps: bonus.steps.map(y => ({
        ...y,
        single: y.single ? {...y.single, data: JSON.stringify(y.single.data)} : undefined,
        multiple: y.multiple ? {...y.multiple, data: JSON.stringify(y.multiple.data)} : undefined,
        rollover: y.rollover ? {...y.rollover, data: JSON.stringify(y.rollover.data)} : undefined,
        filters: y.filters ? {...y.filters, data: JSON.stringify(y.filters.data)} : undefined
    })),
    // userBonus: bonus.userBonus ? stringifyUserBonus(bonus.userBonus) : bonus.userBonus
});

const parseUserBonus = (userBonus: UserBonusDb) => ({
    ...userBonus, 
    creationDate: userBonus.creationDate+'Z',
    singles: (userBonus.singles ?? []).map(y => ({...y, data: JSON.parse(y.data) as SingleData})),
    multiples: (userBonus.multiples ?? []).map(y => ({...y, data: JSON.parse(y.data) as MultipleData})),
    rollovers: (userBonus.rollovers ?? []).map(y => ({...y, data: JSON.parse(y.data) as RolloverData}))
});

const stringifyUserBonus = (userBonus: UserBonus) => ({
    ...userBonus, 
    creationDate: userBonus.creationDate+'Z',
    singles: (userBonus.singles ?? []).map(y => ({...y, data: JSON.stringify(y.data)})),
    multiples: (userBonus.multiples ?? []).map(y => ({...y, data: JSON.stringify(y.data)})),
    rollovers: (userBonus.rollovers ?? []).map(y => ({...y, data: JSON.stringify(y.data)}))
});

export const _getBonuses = async (productId: number, page: number, bonusToIncludeId?: number) => {
    try {
        const response = await makeRequest('GET', 'bonuses', {productId, bonusToIncludeId, page}) as BonusesResponseDb;
        return {...response, content: response.content.map(x => parseBonus(x))};
    } catch (error: any) {
        console.warn(error);
    }
};

export const _getBonusesInProgress = async (productId: number) => {
    try {
        const response = await makeRequest('GET', 'bonuses/inprogress', {productId}) as BonusDb[];
        return response.map(x => parseBonus(x));
    } catch (error: any) {
        console.warn(error);
    }
};

export const _getBonusesFinished = async (productId: number, page: number, bonusToIncludeId?: number) => {
    try {
        const response = await makeRequest('GET', 'bonuses/finished', {productId, bonusToIncludeId, page}) as BonusesResponseDb;
        return {...response, content: response.content.map(x => parseBonus(x))};
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveBonus = async (bonus: Bonus) => {
    try {
        const response = await makeRequest('POST', 'bonuses', stringifyBonus(bonus)) as BonusDb;
        return parseBonus(response);
    } catch (error: any) {
        console.warn(error);
    }
};

export const _uploadImage = async (productId: number, file?: File, url?: string) => {
    try {
        const imagePayload = new FormData();
        imagePayload.append('productId', productId.toString());
        if(url) imagePayload.append('url', url);
        else if(file) imagePayload.append('file', file);
        return await makeRequest('POST', 'bonuses/uploadimage', imagePayload) as LdlFile;
    } catch (error: any) {
        console.warn(error);
    }
};
export const _addVideo = async (productId: number, url?: string) => {
    try {
        return await makeRequest('POST', 'bonuses/addvideo', {productId, url}) as LdlFile;
    } catch (error: any) {
        console.warn(error);
    }
};

export const _startBonus = async (productId: number, bonusId: number) => {
    try {
        const response = await makeRequest('POST', 'bonuses/'+bonusId.toString()+'/start', {}, {productId}) as BonusDb;
        return parseBonus(response);
    } catch (error: any) {
        console.warn(error);
    }
};

export const _saveUserBonus = async (userBonus: UserBonus) => {
    try {
        const response = await makeRequest('PUT', 'bonuses/user', userBonus) as UserBonusDb;
        return parseUserBonus(response);
    } catch (error: any) {
        console.warn(error);
    }
};

const makeRequest = async (method: 'GET'|'POST'|'PUT'|'DELETE', endPoint: string, payload: object, params?: any, cancelToken?: CancelToken) => {       
    try {
        const response = method==='GET' ? await callGET(endPointBase+endPoint, isAuthNeeded, payload, cancelToken)
            : method==='POST' ? await callPOST(endPointBase+endPoint, payload, isAuthNeeded, params)
            : method==='PUT' ? await callPUT(endPointBase+endPoint, payload, isAuthNeeded)
            : method==='DELETE' ? await callDELETE(endPointBase+endPoint, isAuthNeeded)
            : undefined;
        if (response === undefined) throw new Error(endPoint+" response undefined");
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};