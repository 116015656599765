import { FC, useCallback, useState } from 'react';


// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// types
import { VipStandingInfoModalProps } from './VipStandingInfoModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as VipSVG } from './assets/vip-icon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton
} from './style/VipStandingInfoModal.style';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const VipStandingInfoModal: FC<VipStandingInfoModalProps> = ({ onCloseButtonModal, onModalButtonClickHandler }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = useCallback(() => {

        if (!onCloseButtonModal) return;
        onCloseButtonModal();
        if (onModalButtonClickHandler) {
            onModalButtonClickHandler();
        }
        dispatch( enableAppMain() );

        window.open(
            'https://www.facebook.com/groups/LiberoDalLavoro/posts/1780234059024382/',
            '_blank',
            'noreferrer noopener'
        );
    }, [dispatch, onCloseButtonModal, onModalButtonClickHandler]);

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <VipSVG />
                    </IconBox>

                    <Title>
                        CLASSIFICA PUNTI VIP
                    </Title>

                    <TextContent>
                        Guadagna Punti VIP e scala la classifica!
                        <br/><br/>
                        <span>Partecipare è molto semplice: ottieni Punti Libertà e mettili in staking nella sezione "GUADAGNA PUNTI VIP"</span><br/><br/>
                        In questo modo verranno generati Punti VIP.
                    </TextContent>

                    {/* <StartButton onClick={startButtonHandler}>settimana del supervivente</StartButton> */}
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default VipStandingInfoModal;
