import styled from 'styled-components';


export const RowLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px 0px;
    gap: 80px;

    width: 100%;

    background: rgba(0, 0, 0, 0.002);
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);
`;

export const InfoText = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    color: rgba(255, 255, 255, 0.7);
`;

export const CoachText = styled.p`
    font-weight: 600;
    font-size: 15px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    justify-self: flex-end;

    color: rgba(255, 255, 255, 0.7);
`;

export const EndedGrid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: -6px;

    padding: 14.4px 10px 20px 0px;
    gap: 15px;
`;

export const EndedTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 0px;
    gap: 5px;
`;
