import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ReactPixel from 'react-facebook-pixel';
import config from 'configs/config';

import { 
    YellowText, 
    Page, 
    TextBox, 
    TextGreen
} from "./style/ThanksAndLogin.style";
import { getEnvironmentBaseUrl } from "configs/config.helper";
import { postFacebookEvent } from "../services/MarketingLandingPage.services";
import TagManager from "react-gtm-module";

const _pixel_id = '2197239490305094';
// const _conversion_token = 'EAAOKCh1rViQBO8CdH9pRWn439oBM3ZBGwkPrYgCyuVKbF1PTUKqv5u7sX1H0FaZCF3A28aXMxhE2Mk9ZBBtqhNGGl5wXD2UB3FB263NVTXHQkZAJ4Ei6lTpOg66W5jRSjoOrdQUgZA2ShzWzd6JM7SeySCKsErIZA3J60keJzToaLFtdV80WtC9hjCnwrTxVK5TwZDZD'; 
// const _tag_manager_args = {
//         gtmId: 'GTM-KJF3WLD'
//     };

    
    /**
     * @author Frank Berni
     * 
     * @summary Thank you page for Grifone's marketing
     * 
     * @returns JSX
     */

const ThanksAndLogin = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const postEventOnFacebook = async () => {

            try {
                
                let conversionPayload = {
                    data: [
                        {
                            event_name: 'Purchase',
                            event_time: ~~(+new Date() / 1000),
                            user_data: {
                                country: '2ad8a7049d7c5511ac254f5f51fe70a046ebd884729056f0fe57f5160d467153', // ita
                                client_user_agent: navigator.userAgent,
                                em: '74234e98afe7498fb5daf1f36ac2d78acc339464f950703b8c019892f982b90b', // null
                            },
                            event_source_url: `${getEnvironmentBaseUrl('app/lp/mk/thanks-and-login')}`,
                            action_source: 'website',
                        },
                    ],
                };
    
                if (config.environment !== 'production') conversionPayload = {...conversionPayload, ...{ test_event_code: 'Prova acquisto LDL' }} ;
                await postFacebookEvent(_pixel_id, '', conversionPayload);
            } catch (error: any) {
                console.warn("ERROR:", error);
            }
        };

        // TagManager.initialize(_tag_manager_args);
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event: 'pageview',
        //         page: {
        //             url: `${getEnvironmentBaseUrl('app/lp/mk/thanks-and-login')}`,
        //             title: 'Ldl conversion'
        //         }
        //     }
        // })

        ReactPixel.init(_pixel_id);
        ReactPixel.pageView();
        ReactPixel.trackSingle(_pixel_id, 'Purchase');
        postEventOnFacebook();

        const timeout = setTimeout(() => {
            navigate('/login');
        }, 5000);
      return () => { clearTimeout(timeout); }
    }, [navigate])
    
    return (
        <Page>
            <TextBox>
                <TextGreen>COMPLIMENTI (!) Hai completato l'acquisto.</TextGreen>

                <YellowText>Per visualizzare il corso è necessario accedere al sito.</YellowText>

                <YellowText>A breve verrai reindirizzato al login per effettuare l'accesso o completare la registrazione al nostro sito.</YellowText>
            </TextBox>
        </Page>
    )
};

export default ThanksAndLogin;