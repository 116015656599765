import { RefObject } from 'react';

const useDraggableScroll = (ref: RefObject<HTMLElement>, direction?: 'vertical' | 'horizontal' | 'both') => {

    // The initial position (scroll progress and mouse location) when the mouse is pressed down on the element
    let position = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };

    const mouseMoveHandler = (event: MouseEvent) => {
        if (ref.current) {
            // Calculate differences to see how far the user has moved
            const dx = event.clientX - position.mouseX;
            const dy = event.clientY - position.mouseY;

            // Scroll the element according to those differences
            if (direction !== 'horizontal') ref.current.scrollTop = position.scrollTop - dy;
            if (direction !== 'vertical') ref.current.scrollLeft = position.scrollLeft - dx;
        }
    };

    const mouseUpHandler = () => {
        // Remove the event listeners since it is not necessary to track the mouse position anymore
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (ref.current) {
            // Save the position at the moment the user presses down
            position = {
                scrollLeft: ref.current.scrollLeft,
                scrollTop: ref.current.scrollTop,
                mouseX: event.clientX,
                mouseY: event.clientY,
            };

            // Add the event listeners that will track the mouse position for the rest of the interaction
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        }
    };

    return { onMouseDown };
}

export default useDraggableScroll;