import { FC, useCallback, useState } from 'react';


// redux
import { enableAppMain } from 'redux/slices/slice-app-main';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getEnvironmentBaseUrl } from 'configs/config.helper';

// types
import { OfferModalProps } from './OfferModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import Timer from 'components/Timer/Timer.component';
import { BaseCard } from 'style/wrappers';

// assets
import { ReactComponent as OfferIconSVG } from './assets/offerIcon.svg';
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as BonusSVG } from './assets/bonusIcon.svg';
import { ReactComponent as AssistentSVG } from './assets/assistenIcon.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    IconBox,
    TextContent,
    Title,
    StartButton,
    TimeContentBox,
    TimingText,
    TimingOffertBox,
    TimingBox,
    Timing,
    TypeTimingText,
    OffertComboBox,
    OffertText,
    OffertComboImageBox,
    ComboText
} from './style/OfferModal.style';
import EmptyImage from 'components/EmptyImage';
import { getOfferPurchase } from './services/OfferModal.services';
import { getPaypalUrl } from 'utils/paypal';


/**
 * Personal assistant modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */


const OfferModal: FC<OfferModalProps> = ({ onCloseButtonModal, onModalButtonClickHandler, limitTime }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { offers } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const startButtonHandler = useCallback( async () => {

        if (!onCloseButtonModal) return;

        await purchaseOffer();
        onCloseButtonModal();
        if (onModalButtonClickHandler) onModalButtonClickHandler();
        dispatch( enableAppMain() );
    }, [dispatch, onCloseButtonModal, onModalButtonClickHandler]);

    const purchaseOffer =  async () => {

        try {
            
            const response = await getOfferPurchase(offers[0].offer.id, offers[0].offer.paymentTypes[0].id);
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);
            window.location.href = paypalUrl;
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                    <IconBox>
                        <OfferIconSVG />
                    </IconBox>

                    <Title>
                        Offerta Lampo
                    </Title>

                    <TimeContentBox>
                        <TimingText>L'offerta scade:</TimingText>

                        <Timer 
                            limitTime={limitTime}
                            timerCount='MINUTES'
                        />
                        {/* <TimingOffertBox>
                            <TimingBox>
                                <Timing>13</Timing>

                                <TypeTimingText>MINUTI</TypeTimingText>
                            </TimingBox>

                            <TimingBox>
                                <Timing>36</Timing>

                                <TypeTimingText>SECONDI</TypeTimingText>
                            </TimingBox>
                        </TimingOffertBox> */}
                    </TimeContentBox>

                    <TextContent>
                        Hai chiesto un aiuto privato e noi vorremmo tanto regalartelo.
                        <br/><br/>
                        <span>
                            Facciamo un patto: tu ti impegni ad acquistare il secondo passo del percorso e noi ti regaliamo una sessione con un Assistente Personale.
                        </span>
                        <br/><br/>                   
                    </TextContent>

                    <OffertComboBox>
                        <OffertComboImageBox>
                            {(offers[0].offer.offerProducts[0].product.sellImage !== undefined) ? 
                            <img alt='offerta prodotto uno' src={offers[0].offer.offerProducts[0].product.sellImage.url} /> :
                            <EmptyImage />}
                            
                            <ComboText>+</ComboText>

                            {(offers[0].offer.offerProducts[1].product.sellImage !== undefined) ? 
                            <img alt='offerta prodotto due' src={offers[0].offer.offerProducts[1].product.sellImage.url} /> :
                            <EmptyImage />}
                        </OffertComboImageBox>

                        <OffertText>
                            {offers[0].offer.description}
                        </OffertText>
                    </OffertComboBox>

                    <StartButton onClick={startButtonHandler}>VAI ALL’OFFERTA LAMPO</StartButton>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default OfferModal;
