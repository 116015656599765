import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';


export const CircularDoughnutBox = styled.div`

    width: 100%;
    max-width: 45px;

    height: 100%;
    max-height: 45px;

    position: relative;
`;

export const ChartLabelBox = styled.div<{isComplete?: boolean | undefined}>`

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    
    gap: 3px;
    flex-direction: row;

    font-size: 9px;
    font-weight: 400;
    line-height: 9px;

    color: ${colors.white};

    ${({isComplete}) =>  isComplete && (
    
        css`
            color: ${colors.green};
        `
    )}
`;

export const ValueLabel = styled.span<{isComplete?: boolean | undefined}>`

    font-size: 9px;
    font-weight: 400;
    line-height: 9px;

    color: ${colors.white};

    ${({isComplete}) =>  isComplete && (
    
        css`
            color: ${colors.green};
        `
    )}
`;

export const MaxLabel = styled.span<{isComplete?: boolean | undefined}>`

    font-size: 9px;
    font-weight: 400;
    line-height: 9px;

    color: ${colors.white};

    ${({isComplete}) =>  isComplete && (
    
        css`
            color: ${colors.green};
        `
    )}
`;
