export const stringCodingToConsole = (stringToCode: string) => {

    const suppArray = stringToCode.split('');
    const finalArray = suppArray.map((el: string) => el.charCodeAt(0))

    console.log(`CODED STRING "${stringToCode}":`, finalArray.join(''));
};

// export const formattedCurrency = (numberToFormat: number | undefined, numberOfDecimals?: number) => {

//     const decimals = numberOfDecimals ?? 2;
//     if (numberToFormat === undefined || ! numberToFormat) return '0';
//     return numberToFormat.toLocaleString('it-IT', {
        
//         minimumFractionDigits: decimals,      
//         maximumFractionDigits: decimals,
//     })
// };

export const formattedCurrency = (numberToFormat: number | undefined, numberOfDecimals?: number) => {
  const decimals = numberOfDecimals ?? 2;
  if (numberToFormat === undefined || !numberToFormat) return '0';
  
  // Check if formatted number is greater than 1000
  if (numberToFormat >= 1000) return numberToFormat.toLocaleString('it-IT', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
;

    const formattedNumber = numberToFormat.toLocaleString('it-IT', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });

    // Check if the formatted number has a decimal part with value 0
    const decimalPart = formattedNumber.split(',')[1];
    if (decimalPart && Number(decimalPart) === 0) {
      // If decimal part equals 0, return the integer part only
      return formattedNumber.split(',')[0];
    }
  
    // Otherwise, return the formatted number
    return formattedNumber;
  };
