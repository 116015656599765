import { FC, useEffect, useState } from "react";

//assets
import { ReactComponent as PlayIconSVG } from './assets/play-icon.svg';
import { ReactComponent as DocIconSVG } from './assets/docIcon.svg';
import { ReactComponent as ToSeeIconSVG } from './assets/completed-icon.svg';
import { ReactComponent as ReproductionIconSVG } from './assets/pause-icon.svg';
import { ReactComponent as AttachmentIconSVG } from './assets/attachmentIcon.svg';

//style
import {
    Lesson,
    TypeLessonBox,
    StateLessonBox,
    NewBadge,
    PublicPageDot
} from './style/LessonListItem.style';
import useAppSelector from "hooks/useAppSelector";
import { updateLessonData } from "pages/ProductPage/services/ProductPage.services";

const LessonListItem: FC<{
    lessonElement: any,
    lessonSetting: any,
    activeHandler: (n: number) => void,
    activeId: number,
    lessonStatus?: any,
    // isLessonCompleted: boolean | undefined, 
    tier: number,
    isOnPublicPage?: boolean
}> = ({
    lessonElement,
    lessonSetting,
    activeHandler,
    activeId, 
    lessonStatus,
    // isLessonCompleted,
    tier,
    isOnPublicPage
}) => {

    
    // const { productsLessonsStatus } = useAppSelector(state => state.user);
    const [lessonStatusData, setLessonStatusData] = useState<any>(null);
    
    // console.log("LESSON STATUS:", lessonStatusData);
    let title;
    let lessonId: number = 0;
    let video;
    let attachments;
    let isAttachmentsVisible;

    if (lessonElement !== undefined) {
        title = lessonElement.title ?? 'Manca il titolo...';
        lessonId = lessonElement.id;
    }

    if (lessonElement !== undefined && lessonElement.lesson !== undefined) {
        video = lessonElement.lesson.video;
        attachments = lessonElement.lesson.attachments;
        if (attachments !== undefined) isAttachmentsVisible = attachments.length > 0;
    }

    const onClickButtonLessonHandler = () => {

        if (isOnPublicPage) return;

        lessonSetting(lessonElement);
        activeHandler(lessonId);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });      
    };

    // const getLessonStatus = (lessonId: number) => {
    //     return productsLessonsStatus?.filter((lesson: any) => lesson.lessonId === lessonId)[0];
    // };

    // const updateLessonsDetails = () => {

    //     const baseStatus = {
    //         completed: false,
    //         courseId: '000',
    //         favorite: false,
    //         id: '000',
    //         lessonId,
    //         userId: '000'
    //     };
         
    //     try {

    //         const updateData = {
    //             ...lessonStatusData,
    //             lastSeenDate: new Date().toISOString()
    //         };
    //         if (!lessonStatusData.completed && isLessonCompleted) updateData.completed = isLessonCompleted;

    //         updateLessonData(updateData);
    //     } catch (error: any) {
            
    //     }
    // };

    
    useEffect(() => {
        // if (activeId === lessonId) console.log("ACT & LESS ID", [activeId, lessonId]);
        // if (activeId === lessonId) console.log("STATUS:", lessonStatusData);
        
        
        // console.log("ACTIVE ID", activeId);
        // console.log("LESSON ID", lessonId);
        if (isOnPublicPage) return setLessonStatusData(null);
        if (lessonElement === undefined) return;
        // if (lessonStatus !== undefined) return;

        // const renderingLessonStatus = getLessonStatus(lessonId);
        setLessonStatusData(lessonStatus?.filter((lesson: any) => lesson.lessonId === lessonId)[0]);

        // return () => {
        //     if (activeId === lessonId) updateLessonsDetails();
        //     // updateLessonsDetails();
        // };
        
    }, [lessonElement, lessonId, lessonStatus]);

    const hasUserCompletedTheLesson = lessonStatusData?.completed;
    const isPlayIconVisible = (video && (video.id !== null)) && !hasUserCompletedTheLesson;
    const isPauseIconVisible = (activeId === lessonId);
    const isDocIconVisible = (!video || video === null);
    const isCompletedIconVisible = !(activeId === lessonId) && !isDocIconVisible && !isPlayIconVisible;
    // const isNewBadgeIconVisible = true;
    
    return (
        <Lesson 
            isActive={activeId === lessonId}
            onClick={onClickButtonLessonHandler}
            tier={tier}
        >
            <StateLessonBox
                isCompleted={lessonStatusData?.completed === true}
                // isCompleted
                isActive={activeId === lessonId}
                >
                {isPlayIconVisible && !isOnPublicPage && <PlayIconSVG />}
    
                {isDocIconVisible && !isOnPublicPage && <DocIconSVG />}

                {isCompletedIconVisible && !isOnPublicPage && <ToSeeIconSVG />}

                {isPauseIconVisible && !isOnPublicPage && <ReproductionIconSVG />}

                {isOnPublicPage && (<PublicPageDot>•</PublicPageDot>)}

                {title}          

                {(lessonStatusData === undefined) && <NewBadge>NEW !!</NewBadge>}
            </StateLessonBox>

            {isAttachmentsVisible && <AttachmentIconSVG />}
        </Lesson>
    )
};

export default LessonListItem;