import { callGET } from "network/network"

const _TESTIMONIALS_PATH = "v1/public/testimonials";


export const getTestimonials = async (reCaptchaResponse: string) => {
    try {
        const response = await callGET(_TESTIMONIALS_PATH, false, {reCaptchaResponse} )
        return response
    } catch (error: any) {
        throw error;
    }
}