import { 
    NormalText, 
    Page, 
    TextBox, 
    TextYellow, 
    Title, 
    TitleBox 
} from "./style/Error404.style";

const Error404 = () => {
    
    return (
        <Page>
            <TitleBox>
                <Title>Oops! Errore 404</Title>
            </TitleBox>

            <TextBox>
                <TextYellow>404. Pagina non trovata.</TextYellow>

                <NormalText>
                    L’url richiesto non è stato trovato sul nostro server, 
                    verifica di aver digitato correttamente o torna alla Home.
                </NormalText>
            </TextBox>
        </Page>
    )
};

export default Error404;