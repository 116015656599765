import { FC, useEffect, useState } from 'react';

// routing
import { useNavigate, useParams } from 'react-router-dom';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getUserChannels } from './services/NotificationPage.services';

// components
import ChannelList from './ChannelList/ChannelList.component';
import NotificationMessages from './NotificationMessages/NotificationMessages.component';
import LoadingMask from 'components/ui/LoadingMask';

// style
import { 
    TotalPage,
    NotificationMessagesBox,
    NoMessagesText
 } from './style/NotificationPage.style';
import useBrand from 'hooks/useBrand';


const NotificationPage: FC<{}> = () => {

    const {isAppMobile} = useAppSelector(state => state.ui);
    const { channelId: channelIdFromParams } = useParams();
    const [channels, setChannels] = useState<[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedChannel, setSelectedChannel] = useState<number | undefined>(channelIdFromParams ? +channelIdFromParams : undefined);
    const navigate = useNavigate();
    
    const brand = useBrand();

    const setNewChannel = (newChannelId: number | undefined) => setSelectedChannel(newChannelId);

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };
    
    const getChannels = async () => {

        try {
            loadingOn();
            const response = await getUserChannels();

            setChannels(response);
            loadingOff();
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            loadingOff();
            console.warn("ERROR:", error);
        }
    };

    useEffect(() => {
        getChannels();
    }, [brand]);
    
    useEffect(() => {

        const channelIdPath = selectedChannel ?? '';
        navigate(`/reservedArea/notification/${channelIdPath}`);
    }, [selectedChannel]);

    const channelData = channels?.filter((channel: any) => channel.channel.id === selectedChannel)[0];

    if (isLoading) return (
        <LoadingMask 
            paddingTop='200px'
            isCenter
            size='page'
        />
    );

    return (
        <TotalPage>
            {(channels === undefined) && `Non hai nessun canale di notifica aperto al momento.`}
            
            {(channels !== undefined) && 
            (!isAppMobile) &&
            (

                <ChannelList 
                    channelsList={channels}
                    onChannelClick={setNewChannel}
                />
            )}

            {(channels !== undefined) &&
            (isAppMobile) &&
            (!channelIdFromParams) &&
            (

                <ChannelList 
                    channelsList={channels}
                    onChannelClick={setNewChannel}
                />
            )}

            {!isAppMobile && (channelData === undefined) && (
                <NotificationMessagesBox isEmpty>
                    <NoMessagesText>Scegli un canale per vedere i messaggi.</NoMessagesText>
                </NotificationMessagesBox>
            )}

            {!isAppMobile && 
            (channelData !== undefined) && (
                <NotificationMessagesBox>
                    <NotificationMessages 
                        channelData={channelData}
                    />
                </NotificationMessagesBox>
            )}

            {isAppMobile && 
            (channelData !== undefined) && 
            (channelIdFromParams) &&
            (
                <NotificationMessagesBox>
                    <NotificationMessages 
                        channelData={channelData}
                        returnToChannelsList={setNewChannel}
                    />
                </NotificationMessagesBox>
            )}
        </TotalPage>
    );
};

export default NotificationPage;
