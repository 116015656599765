import { FC, useEffect, useState } from 'react';

//types
import { Testimonial } from './LandingPageVDR.d';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from 'react-router-dom';

//assets
import { ReactComponent as BulletIcon } from './assets/bulletIcon.svg';

//components
import LoadingMask from 'components/ui/LoadingMask';
import VdrTools from 'pages/VdrTools/VdrTools.component';


// style
import { Page, TopCardsRow, ProfitsCard, BulletsCard, BulletsItem, BulletsBox, AllTestimonialsBox, AllTestimonialsTitle, AllTestimonials } from './style/LandingPage.style';
import useBrand from 'hooks/useBrand';

/**
 * Landing Page
 * 
 * @author   Frank Berni <frank.berni.79@gmail.com>
 * 
 * @returns JSX
 */

const LandingPage: FC<{}> = () => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { isAppMobile } = useAppSelector( state => state.ui);
    const { executeRecaptcha } = useGoogleReCaptcha();
   
    return (
        <Page>
            <VdrTools />             
        </Page>
    );
}

export default LandingPage;
