import { FC, useEffect, useRef, useState } from 'react';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//routing
import { Link, useNavigate } from 'react-router-dom';

//asset
import { ReactComponent as GuideButtonIconSVG } from './assets/guideButtonIcon.svg';
import { ReactComponent as OpenDropIconSVG } from './assets/openDropIcon.svg';
import { ReactComponent as ClosedDropIconSVG } from './assets/closedDropIcon.svg';
import { ReactComponent as DeadlineIconSVG } from './assets/deadlineIcon.svg';
import { ReactComponent as ActiveIconSVG } from './assets/activeIcon.svg';
import { ReactComponent as LibertyPointIConSVG } from './assets/libertyPointIcon.svg';
import { ReactComponent as PaypalIconSVG } from './assets/paypalIcon.svg';

//style
import {
    ServiceContent,
    ServiceDescriptionBox,
    ServiceStatusMainBox,
    ServiceName,
    ServiceSelectorBox,
    ServiceStatusText,
    ServiceDeadlineDate,
    ServiceStatusBox,
    ServiceGuideButton,
    ServiceElipsisBox,
    ReactivationServiceBox,
    DisactiveBox,
    DisactiveTex,
    PaymentTooltip,
    RenewTooltip,
    TooltipArrow
} from './style/MyServiceActive.style';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import { callPUT } from 'network/network';

const MyServiceActive: FC<{
    serviceElement: any,
    disactiveRefresh: () => void,
    vipPoints: number
}> = ({
    serviceElement,
    disactiveRefresh,
    vipPoints
}) => {

    const [isSelectorOpenActive, setIsSelectorOpenActive] = useState(false);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isPaymentToolTipVisible, setIsPaymentToolTipVisible] = useState<boolean>(false);
    const [isRenewToolTipVisible, setIsRenewToolTipVisible] = useState<boolean>(false);

    const {id: productId, product, productService, id} = serviceElement;
    const paymentPlan = product?.productService?.paymentPlan;
    const expirationDate = productService?.expirationDate;
    const isLibertySubscription = productService?.paymentTypeId === 3;
    const isPayPalSubscription = productService?.paymentTypeId === (1 || 2);
    const isDisactive = productService?.status === 'CANCELLED';
    const isActive = productService?.status === 'ACTIVE';
    const finalDate = new Date(expirationDate);
    // const vipPoints = (productService?.paymentPlan.vipPoints);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const paymentRef = useRef<SVGSVGElement>(null);
    const renewRef = useRef<HTMLParagraphElement>(null);

    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const loadingOn = () => setIsLoading(true);
    const paymentTooltipHandler = () => setIsPaymentToolTipVisible(prevState => !prevState);
    const renewTooltipHandler = () => setIsRenewToolTipVisible(prevState => !prevState);

    const cancelLibertyServiceSubscriptions = async (id: string) => {

        try {
            
            const response = callPUT(`v1/self/productsubscriptions/${id}/cancel`, {}, true);
            // if (response === undefined || !response) throw new Error("ERROR: Unable to check paypal response.");
            disactiveRefresh();
  
            const snackbarData: SnackbarOptions = {
                type: 'success',
                message: "Il tuo abbonamento in punti è stato disattivato con Successo!"
            };
              
            dispatch(addSnackbar(snackbarData));

            return response;
        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            throw error;
        }
    };

    const disactiveSubscriptionsHandler = () => {
        cancelLibertyServiceSubscriptions(id);
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const onClickSelectorOpenHandler = () => {
        
        setIsSelectorOpenActive(prevState => !prevState);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const reNewStatus = ((isDisactive) ? (
        (finalDate.getTime() - (new Date().getTime()) < 604800000) ? 'toRenew' : 'disactive') : 
        'active'
    );
    // console.log("PREZZO SERVIZIO ATTIVO", paymentPlan.price);
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            paymentRef.current &&
            !paymentRef.current.contains(event.target as Node)
          ) {
            setIsPaymentToolTipVisible(false);
          }
          if (
            renewRef.current &&
            !renewRef.current.contains(event.target as Node)
          ) {
            setIsRenewToolTipVisible(false);
          }
        };
      
        document.addEventListener("click", handleClickOutside);
      
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, []);
    
    return (
        <ServiceContent>
            <ServiceDescriptionBox>
                {product !== undefined &&
                <Link to={`/reservedArea/product-page/service/${product.id}`} state={{productId}}>
                    <ServiceGuideButton>
                        <GuideButtonIconSVG />
                    </ServiceGuideButton>
                </Link>}

                <ServiceElipsisBox>
                    <ServiceName dangerouslySetInnerHTML={{__html: product.name}} />
                </ServiceElipsisBox>
            </ServiceDescriptionBox>

            <ServiceStatusMainBox>
                <ServiceStatusBox isToRenew={reNewStatus === 'disactive'}>

                    {(reNewStatus === 'toRenew') ? <DeadlineIconSVG /> : <ActiveIconSVG />}

                    <ServiceStatusText isActive={reNewStatus} onClick={renewTooltipHandler} ref={renewRef} >
                        {(reNewStatus === 'toRenew') ? "In scadenza" : "Attivo"}

                        {isRenewToolTipVisible && (reNewStatus !== 'toRenew') && (
                            <RenewTooltip>
                                {(reNewStatus === 'active') ? 
                                "Rinnovo automatico attivo" :
                                "Attenzione! Rinnovo automatico disattivo"}
                                <TooltipArrow isIntoRenewTooltip />
                            </RenewTooltip>
                        )} 
                    </ServiceStatusText>

                    {isLibertySubscription && <LibertyPointIConSVG onClick={paymentTooltipHandler} ref={paymentRef}/>}

                    {isPayPalSubscription && <PaypalIconSVG onClick={paymentTooltipHandler} ref={paymentRef}/>}

                    <ServiceDeadlineDate>{`${finalDate.getDate().toString().padStart(2, '0')}/${(finalDate.getMonth()+1).toString().padStart(2, '0')}/${finalDate.getFullYear()}`}</ServiceDeadlineDate>

                    {isPaymentToolTipVisible && (
                        <PaymentTooltip>
                            Acquistato con {isLibertySubscription ? "Punti Libertà": "PayPal"}
                            <TooltipArrow />
                        </PaymentTooltip> 
                    )}
                </ServiceStatusBox>

                {(isDisactive || isLibertySubscription) && serviceElement.isRenewable &&
                    <ServiceSelectorBox onClick={onClickSelectorOpenHandler}>
                        {!isSelectorOpenActive && <OpenDropIconSVG />}

                        {isSelectorOpenActive && <ClosedDropIconSVG />}
                    </ServiceSelectorBox>
                }

            </ServiceStatusMainBox>

            {isSelectorOpenActive && 
                <ReactivationServiceBox>
                    {isActive && isLibertySubscription &&
                    <DisactiveBox onClick={disactiveSubscriptionsHandler} isActive={isDisactive}>
                        <DisactiveTex>X disattiva</DisactiveTex>
                    </DisactiveBox>}

                    {isDisactive && serviceElement.isRenewable &&
                    <ButtonShop 
                        PriceProduct={paymentPlan.price} 
                        TypeOfProduct="SERVICE"
                        courseElement={serviceElement.product}
                        courseID={product.id}
                        payments={product.paymentTypes}
                        // onTransferClick={onTransferClick}
                        // openBankTransferModal={openBankTransferModal}
                        onLibertyClick={libertyModalHandler}
                        openLibertyPaymentModal={openLibertyPaymentModal}
                        loadingOn={loadingOn}
                        loadingOff={loadingOff}
                        vipPoints={vipPoints}
                        isServicePageRequest
                        isIntoServicesPage
                        refreshFunction={disactiveRefresh}
                    />}
                </ReactivationServiceBox>
            }
        </ServiceContent>
    )
};

export default MyServiceActive;