import colors from 'style/palette';
import styled from 'styled-components';


export const SliderBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 15px;

    width: 100%;
    max-width: 320px;
`;

export const ChangeValueBotton = styled.button`

    background: ${colors.transparent};
    border: unset;
    cursor: pointer;
`;
