import styled from 'styled-components';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';

export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-direction: column;
    gap: 20px;

    width: 100%;
    height: 100%;

    padding: 20px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const Div = styled.div<{width?: string, height?: string}>`
    width: ${props => props.width ?? '100%'};
    height: ${props => props.height ?? '36px'};
    color: white;
    font-size: 12px;
    text-align: center;
`;

export const SelectionLabel = styled.div`
    width: 100%;
    font-size: 14px;
    color: white;
    padding: 2px 10px;
    text-align: center;
    font-weight: 500;
    cursor: default;
    background: #305182a8;
    border-radius: 2px;
`;

export const PossibleResults = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${colors.primaryColor};
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    gap: 10px;
`;

export const BetsInstructionsContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    border-top: 2px solid #ffc000b0;
    border-bottom: 2px solid #ffc000b0;
`;

export const EventLabelContainer = styled.div`
    color: ${colors.primaryColor};
    width: calc(100% - 25px);
    max-width: 430px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    gap: 10px;
`;

export const EventDate = styled.label`
    white-space: nowrap;
`;

export const EventLabel = styled.label`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const SaveButton = styled(Button)`
    height: 34px;
    padding: 5px;
    font-size: 12px;
`;