import { getEnvironmentProductId } from "configs/config.helper";
import { callGET, callPOST, callPUT } from "network/network";
import store from "redux/store";
import { setItemToLocalStorage } from "utils/localStorage";


// endpoint link pagamento
export const getCoursePayment = async (productId: number, paymentType: number, isUserLoggedIn?: boolean, isForcePaymentNeeded?: boolean, campaignId?: string | null, isPastBroadcast?: boolean) => {
    const authNeeded = isUserLoggedIn ?? true;
    const forcePayment = isForcePaymentNeeded ?? false;

    const paymentPayload = {
        paymentTypeId: paymentType,
        brandCode: store.getState().ui.brand?.code,
        forcePayment,
        campaignId
    };

    try {
        
        const response = await callPOST(`v1/publicpurchase/single/${productId}`, {}, authNeeded, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");

        if ((response.transactions === null) || (response.status === "COMPLETED" )) {
        
            if (!isUserLoggedIn) setItemToLocalStorage('Pending purchase ID', `${response.id}`);
            return response
        };
        if ((response.transactions === null) || (response.status === "PRODUCT_ASSIGNED" )) return response;

        
        const pendingTransaction = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].id;
        if (paymentType === 1 || paymentType === 2) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            console.log("Transazione", pendingTransaction);
            
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };

        if (paymentType === 4) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };     
      
        if (productId === getEnvironmentProductId('autostrada')) setItemToLocalStorage('Autostrada', 'true');
        if (productId === getEnvironmentProductId('assistente personale')) setItemToLocalStorage('Assistente', 'true');
        if (isPastBroadcast === true) setItemToLocalStorage('PastBroadcast', 'true');
        return response;
    } catch (error: any) {
        if (error.response.data.message === "Already purchased") throw new Error ('Already purchased');
        // if (error.response.data.message === "Requisites not satisfied")  throw error;
        if (error.response.data.message === "Max date for this product exceeded") throw new Error ('Max date for this product exceeded');
        if (error.message.includes('403') && (error.response.data.message !== "Requisites not satisfied")) throw new Error ('403');
        throw error;
    }
};

export const getSubscriptionPayment = async (productId: number, paymentType: number, isUserLoggedIn?: boolean) => {
    
    const paymentPayload = {
        paymentTypeId: paymentType,
        brandCode: store.getState().ui.brand?.code
    };

    const authNeeded = isUserLoggedIn ?? true;
    try {
        
        const response = await callPOST(`v1/publicpurchase/subscription/paypal/${productId}`, {}, authNeeded, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");
        
        setItemToLocalStorage('Pending subscription ID', `${response.id}`);
        return response;
    } catch (error: any) {
        if (error.message.includes('403') && (error.response.data.message !== "Requisites not satisfied")) throw new Error ('403');
        throw error;
    }
};

export const getAutomaticIncomePayment = async (productId: number, paymentType: number, priceAmount: number) => {
    
    const paymentPayload = {
        paymentTypeId: paymentType,
        price: priceAmount,
        brandCode: store.getState().ui.brand?.code
    };

    try {
        
        const response = await callPOST(`v1/self/purchases/automaticincomes/${productId}`, {}, true, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");

        if ((response.transactions === null) || (response.status === "COMPLETED" )) return response;
        
        const pendingTransaction = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].id;
        if (paymentType === 1 || paymentType === 2) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            console.log("Transazione", pendingTransaction);
            
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };
        
        if (paymentType === 4) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };     
        
        setItemToLocalStorage('Automatic income', 'true');
        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getLibertyPoint = async (productId: number, paymentType: number, credits: number) => {
    
    const paymentPayload = {
        paymentTypeId: paymentType,
        credits,
        brandCode: store.getState().ui.brand?.code
    };

    try {
        
        const response = await callPOST(`v1/publicpurchase/creditpack/${productId}`, {}, true, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");

        if ((response.transactions === null) || (response.status === "COMPLETED" )) return response;
        
        const pendingTransaction = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].id;
        console.log("Transazione", pendingTransaction);
        
        if (paymentType === 1 || paymentType === 2) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            console.log("Transazione", pendingTransaction);
            
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };

        if (paymentType === 4) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };     
        
        setItemToLocalStorage('CREDIT_PACK', `true`);

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

//endpoint payment service with liberty point
export const subscriptionLibertyPayment = async (productId: number, isUserLoggedIn?: boolean) => {
    
    const paymentPayload = {
        brandCode: store.getState().ui.brand?.code
    };

    const authNeeded = isUserLoggedIn ?? true;
    try {
        
        const response = await callPOST(`v1/publicpurchase/subscription/credits/${productId}`, {}, authNeeded, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");
        
        setItemToLocalStorage('Pending subscription liberty ID', `${response.id}`);
        return response;
    } catch (error: any) {
        if (error.message.includes('403') && (error.response.data.message !== "Requisites not satisfied")) throw new Error ('403');
        throw error;
    }
};

// endpoint per controllo pagamento
export const checkPaypalPayment = async (transactionId: string, isUserLoggedIn?: boolean) => {

    const authNeeded = isUserLoggedIn ?? true;
    try {
        
        const response = await callPOST(`v1/publicpurchase/single/paypal/capture/${transactionId}`, {}, authNeeded);
        if (response === undefined || !response) throw new Error("ERROR: Unable to check paypal response.");

        if (!isUserLoggedIn) setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        return response;
    } catch (error: any) {
        if (error.response.data.message.includes("Already assigned")) throw new Error (error.response.data.message);
        if (error.response.data.message === "Waiting for paypal authorization") throw new Error ('Waiting for paypal authorization');
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const checkPaypalPaymentWhenLoggedIn = async (transactionId: string) => {

    try {
        
        const response = callPUT(`v1/self/purchases/${transactionId}/assign`, {}, true);
        if (response === undefined || !response) throw new Error("ERROR: Unable to check paypal response.");

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const checkPaypalSubscriptionPayment = async (subscriptionId: string, isUserLoggedIn?: boolean | undefined) => {

    try {
        
        const authNeeded = isUserLoggedIn ?? true;
        const response = callPOST(`v1/publicpurchase/subscription/paypal/activate/${subscriptionId}`, {}, authNeeded);
        if (response === undefined || !response) throw new Error("ERROR: Unable to check paypal activate response.");

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const addTransferPaymentInfo = async (transactionId: number | undefined, transferPayload: any, isUserLoggedIn?: boolean) => {

    if (transactionId === undefined) throw new Error("ERROR: transactionId can not be undefined!");
    const authNeeded = isUserLoggedIn ?? true;

    try {

        const response = callPUT(`v1/self/purchases/${transactionId}/invoicedata`, transferPayload, authNeeded);

        return response;
    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

// endpoint per annullamento

export const cancelProductPaypalPayment = async (purchaseId: string, isUserLoggedIn?: boolean) => {

    const authNeeded = isUserLoggedIn ?? true;
    try {
        
        await callPOST(`v1/publicpurchase/single/paypal/cancel/${purchaseId}`, {}, authNeeded);

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const cancelSubscriptionPaypalPayment = async (subscriptionId: string, isUserLoggedIn?: boolean) => {

    const authNeeded = isUserLoggedIn ?? true;
    try {
        
        await callPOST(`v1/publicpurchase/subscription/paypal/cancel/${subscriptionId}`, {}, authNeeded);

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        throw error;
    }
};

export const getThematicBroadcastPayment = async (
    productId: number, 
    thematicCallType: string | "SPECTATOR" | "PROTAGONIST" | "UPGRADE",
    paymentType: number, 
    isCallInDiscount: boolean,
    isForcePaymentNeeded?: boolean, 
    campaignId?: string | null
) => {

    const authNeeded = true;
    const forcePayment = isForcePaymentNeeded ?? false;

    const paymentPayload = {
        paymentTypeId: paymentType,
        brandCode: store.getState().ui.brand?.code,
        forcePayment,
        campaignId,
        type: thematicCallType,
        discount: isCallInDiscount
    };

    try {
        
        const response = await callPOST(`v1/publicpurchase/single/thematicbroadcast/${productId}`, {}, authNeeded, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");

        // if ((response.transactions === null) || (response.status === "COMPLETED" )) {
        
        //     if (!isUserLoggedIn) setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        //     return response
        // };
        if ((response.transactions === null) || (response.status === "PRODUCT_ASSIGNED" )) return response;

        
        const pendingTransaction = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].id;
        if (paymentType === 1 || paymentType === 2) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            console.log("Transazione", pendingTransaction);
            
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };

        if (paymentType === 4) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        };     

        setItemToLocalStorage("newThematicCall", "true")
      
        return response;
    } catch (error: any) {
        if (error.response.data.message === "Already purchased") throw new Error ('Already purchased');
        // if (error.response.data.message === "Requisites not satisfied")  throw error;
        if (error.response.data.message === "Max date for this product exceeded") throw new Error ('Max date for this product exceeded');
        if (error.message.includes('403') && (error.response.data.message !== "Requisites not satisfied")) throw new Error ('403');
        throw error;
    }
};

export const getDonationPayment = async (
    productId: number, 
    paymentType: number, 
    amount: number
) => {

    const authNeeded = true;

    const paymentPayload = {
        paymentTypeId: paymentType,
        brandCode: store.getState().ui.brand?.code,
        amount
    };

    try {
        
        const response = await callPOST(`v1/publicpurchase/single/thematicbroadcast/${productId}/donation`, {}, authNeeded, paymentPayload);
        if (response === undefined || !response) throw new Error("ERROR: Unable to get payment response.");

        // if ((response.transactions === null) || (response.status === "COMPLETED" )) {
        
        //     if (!isUserLoggedIn) setItemToLocalStorage('Pending purchase ID', `${response.id}`);
        //     return response
        // };
        if ((response.transactions === null) || (response.status === "PRODUCT_ASSIGNED" )) return response;    

        setItemToLocalStorage("donation", "true")
      
        return response;
    } catch (error: any) {
        if (error.response.data.message === "Already purchased") throw new Error ('Already purchased');
        if (error.response.data.message === "Requisites not satisfied")  throw error;
        if (error.response.data.message === "Max date for this product exceeded") throw new Error ('Max date for this product exceeded');
        if (error.message.includes('403') && (error.response.data.message !== "Requisites not satisfied")) throw new Error ('403');
        throw error;
    }
};