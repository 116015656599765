const DATE_NAME: any = {
    0: 'Gennaio',
    1: 'Febbraio',
    2: 'Marzo',
    3: 'Aprile',
    4: 'Maggio',
    5: 'Giugno',
    6: 'Luglio',
    7: 'Agosto',
    8: 'Settembre',
    9: 'Ottobre',
    10: 'Novembre',
    11: 'Dicembre',
};

const formattedTime = (timeNumber: number) => {

    if (timeNumber < 10) return `0${timeNumber}`;
    return `${timeNumber}`;
};

export const dateDifferenceInDays = (nowDate: Date, fullMessageSendingDate: Date) => {
    return Math.floor((nowDate.valueOf() - fullMessageSendingDate.valueOf()) / 1000 / 60 / 60 / 24);
};

export const messageSendingDate = (sendingDate: string) => {

    const fullMessageSendingDate = new Date(sendingDate);
    const nowDate = new Date();

    const difference = dateDifferenceInDays(nowDate, fullMessageSendingDate);

    if ((difference > 1) || (nowDate.getDay() !== fullMessageSendingDate.getDate())) return `${fullMessageSendingDate.getDate()} ${DATE_NAME[fullMessageSendingDate.getMonth()]}`;
    return "oggi";
};

export const messageSendingHour = (sendingDate: string) => {

    const fullMessageSendingDate = new Date(`${sendingDate}Z`);
    const hours = formattedTime(fullMessageSendingDate.getHours());
    const minutes = formattedTime(fullMessageSendingDate.getMinutes());
    
    return `${hours}:${minutes}`;
};
