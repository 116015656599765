import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//redux
import { enableAppMain } from 'redux/actions/actions-app-main';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//services
import { getCoursePayment, getDonationPayment, getThematicBroadcastPayment } from 'services/payments.services';
import { getUserData, getUsersProducts } from 'services/user.services';

// types
import { LibertyPaymentModalProps } from './LibertyPaymentModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import LoadingMask from 'components/ui/LoadingMask';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    LibertyTitleBox,
    LibertyTitle,
    LibertyTextBox,
    LibertyText,
    LibertyButton
} from './style/LibertyPaymentModal.style';
import ProductRequisitesModal from 'components/ProductRequisitesModal/ProductRequisitesModal.component';
import { getEnvironmentProductId } from 'configs/config.helper';


/**
 * Lyberty Payment modal
 * 
 * @author Piero Foschi
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */

const LibertyPaymentModal: FC<LibertyPaymentModalProps> = ({onCloseButtonModal, courseID, productType, price, thematicBroadcastType, yourThematicBroadcast, isBroadcastNot, isPastBroadcast, isDonationPage}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [libertyPrice, setLibertyPrice] = useState<number>();
    const [requisites, setRequisites] = useState([]);
    const [requisitesLogicalOperator, setRequisitesLogicalOperator] = useState<'AND' | 'OR'>('AND');
    const [isProductRequisitesModalVisible, setIsProductRequisitesModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const closeProductRequisitesModal = () => setIsProductRequisitesModalVisible(false);
    const openRequisiteModal = () => setIsProductRequisitesModalVisible(true);

    const CalculateLibertyPrice = () => {
        const libertyValue = 1.22;
        let result = 0;
        if (price) {
          result = (+price / libertyValue);
          console.log("risultato liberty", result);
          
        }
        setLibertyPrice(+result.toFixed(2));
    };


    const libertyPaymentHandler = async () => {

        console.log("C ID:", courseID);
        if (productType === 'ThematicBroadcast' && isDonationPage !== true) {
            if (thematicBroadcastType === undefined) return "SPECTATOR"

            try {      
                setIsLoading(true);
                if (isBroadcastNot === true ) await getThematicBroadcastPayment(courseID, thematicBroadcastType, 3, true);
                if (isBroadcastNot !== true ) await getThematicBroadcastPayment(courseID, thematicBroadcastType, 3, false);
                dispatch(enableAppMain());

                dispatch( getUserData() );
                navigate('/reservedArea/mie-dirette');

                const snackbarData: SnackbarOptions = {
                    type: 'success',
                    message: "La tua diretta in punti è stata accreditata con Successo!"
                };

                dispatch(addSnackbar(snackbarData));
                
                if (yourThematicBroadcast === "yourThematicBroadcast") window.location.reload();

                return;
            } catch (error: any) {
                setIsLoading(false);
                console.warn("ERROR:", error);

                // if (error.message === "Max date for this product exceeded") {
                //     const snackbarData: SnackbarOptions = {
                //         type: 'info',
                //         message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo attualmente!"
                //       };
                    
                //       dispatch(addSnackbar(snackbarData));
                // }

                // const snackbarData: SnackbarOptions = {
                //     type: 'error',
                //     message: "C'è stato un problema con il tuo acquisto, riprova più tardi!"
                // };

                if (error.response.data.message === "Requisites not satisfied") {               
                    const requisiteProducts = error.response.data.error;
                    setRequisites(requisiteProducts);
                    openRequisiteModal();
                    return;
                }

                let snackbarData: SnackbarOptions = {
                    type: 'error',
                    message: "C'è stato un problema con il tuo acquisto, riprova più tardi!",
                };
            
                if (error.message === 'Max date for this product exceeded') {
                    snackbarData = {
                    type: 'info',
                    message:
                        "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo",
                    };
                }

                dispatch(addSnackbar(snackbarData));
                if(onCloseButtonModal) onCloseButtonModal();
                dispatch( enableAppMain() );
                navigate('/reservedArea/dirette-tematiche');
            }
        }

        if (isDonationPage === true && productType === 'Donation' && isPastBroadcast !== true) {

            try {      
                setIsLoading(true);
                if (isDonationPage === true ) await getDonationPayment(courseID, 3, libertyPrice!);
                dispatch(enableAppMain());

                const snackbarData: SnackbarOptions = {
                    type: 'success',
                    message: "Hai effettuato correttamente la tua donazione per la diretta. Grazie!"
                };

                dispatch(addSnackbar(snackbarData));
                if(onCloseButtonModal) onCloseButtonModal();
                dispatch( enableAppMain() );

                return;
            } catch (error: any) {
                setIsLoading(false);
                console.warn("ERROR:", error);

                if (error.message === "Cannot read properties of undefined") {               
                    const requisiteProducts = error.response.data.error;
                    setRequisites(requisiteProducts);
                    openRequisiteModal();
                    return;
                }

                let snackbarData: SnackbarOptions = {
                    type: 'error',
                    message: "Non hai sufficienti punti libertà per effettuare la donazione, acquistali e poi effettua nuovamente la donazione",
                };
            
                if (error.message === 'Max date for this product exceeded') {
                    snackbarData = {
                    type: 'info',
                    message:
                        "C'è stato un problema con la tua donazione, riprova più tardi!",
                    };
                }

                dispatch(addSnackbar(snackbarData));
                if(onCloseButtonModal) onCloseButtonModal();
                dispatch( enableAppMain() );
            }
        }
        
        if (productType !== 'ThematicBroadcast' && isPastBroadcast !== true && isDonationPage !== true) {
            try {      
                setIsLoading(true);
                await getCoursePayment(courseID, 3, true);
                dispatch(enableAppMain());

                if (courseID === getEnvironmentProductId('autostrada')) {
                    dispatch( getUsersProducts() );
                    navigate('/reservedArea');
                    return  
                }

                if (productType === 'Lesson') {
                    navigate('/reservedArea/lesson-page');
                    return  
                }

                dispatch( getUserData() );
                navigate('/reservedArea/mycourses');

                const snackbarData: SnackbarOptions = {
                    type: 'success',
                    message: "Il tuo corso in punti è stato accreditato con Successo!"
                };

                dispatch(addSnackbar(snackbarData));
                
                return;
            } catch (error: any) {
                setIsLoading(false);
                console.warn("ERROR:", error);

                // if (error.message === "Max date for this product exceeded") {
                //     const snackbarData: SnackbarOptions = {
                //         type: 'info',
                //         message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo attualmente!"
                //       };
                    
                //       dispatch(addSnackbar(snackbarData));
                // }

                // const snackbarData: SnackbarOptions = {
                //     type: 'error',
                //     message: "C'è stato un problema con il tuo acquisto, riprova più tardi!"
                // };

                if (error.response.data.message === "Requisites not satisfied") {               
                    const requisiteProducts = error.response.data.error;
                    setRequisites(requisiteProducts);
                    openRequisiteModal();
                    return;
                }

                let snackbarData: SnackbarOptions = {
                    type: 'error',
                    message: "C'è stato un problema con il tuo acquisto, riprova più tardi!",
                };
            
                if (error.message === 'Max date for this product exceeded') {
                    snackbarData = {
                    type: 'info',
                    message:
                        "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo",
                    };
                }

                dispatch(addSnackbar(snackbarData));
                if(onCloseButtonModal) onCloseButtonModal();
                dispatch( enableAppMain() );
                navigate('/reservedArea/shop');
            }
        }

        if (productType !== 'ThematicBroadcast' && isPastBroadcast === true && isDonationPage !== true) {
            try {      
                setIsLoading(true);
                await getCoursePayment(courseID, 3, true);
                dispatch(enableAppMain());

                dispatch( getUserData() );
                navigate('/reservedArea/mie-dirette');

                const snackbarData: SnackbarOptions = {
                    type: 'success',
                    message: "La tua diretta in punti è stata accreditata con Successo!"
                };

                dispatch(addSnackbar(snackbarData));
                
                return;
            } catch (error: any) {
                setIsLoading(false);
                console.warn("ERROR:", error);

                // if (error.message === "Max date for this product exceeded") {
                //     const snackbarData: SnackbarOptions = {
                //         type: 'info',
                //         message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo attualmente!"
                //       };
                    
                //       dispatch(addSnackbar(snackbarData));
                // }

                // const snackbarData: SnackbarOptions = {
                //     type: 'error',
                //     message: "C'è stato un problema con il tuo acquisto, riprova più tardi!"
                // };

                if (error.response.data.message === "Requisites not satisfied") {               
                    const requisiteProducts = error.response.data.error;
                    setRequisites(requisiteProducts);
                    openRequisiteModal();
                    return;
                }

                let snackbarData: SnackbarOptions = {
                    type: 'error',
                    message: "C'è stato un problema con il tuo acquisto, riprova più tardi!",
                };
            
                if (error.message === 'Max date for this product exceeded') {
                    snackbarData = {
                    type: 'info',
                    message:
                        "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo",
                    };
                }

                dispatch(addSnackbar(snackbarData));
                if(onCloseButtonModal) onCloseButtonModal();
                dispatch( enableAppMain() );
                navigate('/reservedArea/dirette-tematiche');
            }
        }

    };

    useEffect(() => {
        if (price) {
          CalculateLibertyPrice();
        }
      }, [price]);
    
    // if (isLoading) return (
    //     <LoadingMask 
    //         paddingTop='200px'
    //         isCenter
    //         size='page'
    //     />
    // );

    return (
        <>
            <BaseModal 
                onClose={onCloseButtonModal}
                isCloseButtonClicked={isCloseButtonClicked}
                isBackdropClickActive
            >
                {!isAppMobile && (
                    <Row 
                        padding='0 21px 12px 0'
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <BaseCard 
                    flavor='outlined'
                    isModal
                >
                    <CardContent>
                        {isAppMobile && (
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={closeButtonHandler}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                        )}

                        <LibertyTitleBox>
                            <LibertyTitle>CONFERMA  DI PAGAMENTO</LibertyTitle>
                        </LibertyTitleBox>

                        <LibertyTextBox>
                            <LibertyText>Hai richiesto un pagamento di <LibertyPointIconSVG /> {libertyPrice} Punti Libertà. Vuoi Procedere?</LibertyText>
                        </LibertyTextBox>

                        <LibertyButton onClick={libertyPaymentHandler}>CONFERMA</LibertyButton>
                    </CardContent>
                </BaseCard>
            </BaseModal>

            {isProductRequisitesModalVisible &&
                <ProductRequisitesModal
                    onCloseButtonModal={closeProductRequisitesModal}
                    requisites={requisites}
                    requisitesLogicalOperator={requisitesLogicalOperator}
                />
            }
        </>
    );
};

export default LibertyPaymentModal;
