//hooks
import useAppDispatch from "hooks/useAppDispatch";
import { setPersonalAssistant } from "pages/LDL/HomePageLDL/services/HomePageLDL.services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData, getUsersProducts } from "services/user.services";

//utils
import { getItemFromLocalStorage, removeItemFromLocalStorage } from "utils/localStorage";

import { 
    NormalText, 
    Page, 
    TextBox, 
    TextGreen
} from "./style/ThanksForBuying.style";


const ThanksForBuying = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const personalAssistantCode = getItemFromLocalStorage('Personal Assistant Code');

    const pendingAutomaticPaymentExists = 'Automatic income' in localStorage;
    const pendingOfferPaymentExists = 'Offerta' in localStorage;
    const pendingHighwayPaymentExists = 'Autostrada' in localStorage;
    const pendingAssistantPaymentExists = 'Assistente' in localStorage;
    const pendingLessonPaymentExists = 'New Coach Lesson' in localStorage;
    const pendingHelpPaymentExists = 'New Help' in localStorage;
    const pendingPurchasePaymentExists = 'New Course' in localStorage;
    const pendingSubscriptionPaymentExists = 'New Service' in localStorage;
    const pendingCreditPackPaymentExists = 'CREDIT_PACK' in localStorage;
    const pendingPurchaseBroadcastExists = 'newThematicCall' in localStorage;
    const pendingPastThematicBroadcastExists = 'PastBroadcast' in localStorage;


    const assignPersonalAssistant = async () => {

        if (personalAssistantCode === null) return;
        try {                        
            await setPersonalAssistant(personalAssistantCode);
            removeItemFromLocalStorage('Personal Assistant Code');
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    useEffect(() => {
        
        if (pendingHighwayPaymentExists || pendingOfferPaymentExists || pendingAssistantPaymentExists) dispatch( getUsersProducts() );
        if (pendingCreditPackPaymentExists) dispatch( getUserData() );

        const timeout = setTimeout(() => {
            if (pendingHighwayPaymentExists){
                removeItemFromLocalStorage('Autostrada');
                removeItemFromLocalStorage('Pending purchase ID');
                assignPersonalAssistant();
                navigate('/reservedArea');
                return;
            }   
            if (pendingAssistantPaymentExists){
                removeItemFromLocalStorage('Assistente');
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea');
                return;
            }   
            if (pendingAutomaticPaymentExists){
                removeItemFromLocalStorage('Automatic income');
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea');
                return;
            }   
            if (pendingOfferPaymentExists){
                removeItemFromLocalStorage('Offerta');
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea');
                return;
            }   
            if (pendingCreditPackPaymentExists){
                removeItemFromLocalStorage('CREDIT_PACK');
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea');
                return;
            }      
            if (pendingPurchasePaymentExists){
                navigate('/reservedArea/mycourses');
            }      
            if (pendingPurchaseBroadcastExists){
                removeItemFromLocalStorage('newThematicCall');
                navigate('/reservedArea/mie-dirette');
            }   
            if (pendingPastThematicBroadcastExists){
                removeItemFromLocalStorage('PastBroadcast');
                navigate('/reservedArea/mie-dirette');
            }  
            if (pendingSubscriptionPaymentExists) {
                navigate('/reservedArea/myservices');
            }
            if (pendingLessonPaymentExists){
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea/lesson-page');
            }
            if (pendingHelpPaymentExists){
                removeItemFromLocalStorage('Pending purchase ID');
                navigate('/reservedArea/lesson-page');
            }

            return () => clearTimeout(timeout);
        }, 5000);
    }, [
        pendingPurchasePaymentExists,
        pendingCreditPackPaymentExists,
        pendingSubscriptionPaymentExists,
        pendingLessonPaymentExists,
        pendingHelpPaymentExists,
        dispatch,
        pendingHighwayPaymentExists,
        pendingOfferPaymentExists,
        navigate,
        pendingAssistantPaymentExists,
        pendingAutomaticPaymentExists,
        pendingPurchaseBroadcastExists,
        pendingPastThematicBroadcastExists
    ]);
    
    return (
        <Page>
            <TextBox>
                <TextGreen>COMPLIMENTI (!) Acquisto effettuato correttamente.</TextGreen>

                {((!pendingPurchasePaymentExists && 
                    !pendingSubscriptionPaymentExists &&
                    !pendingLessonPaymentExists &&
                    !pendingHelpPaymentExists &&
                    !pendingPurchaseBroadcastExists &&
                    !pendingPastThematicBroadcastExists) || (
                        pendingAssistantPaymentExists
                    )) && (
                        <NormalText>Verrai a breve reindirizzato sulla Home Page.</NormalText>
                    )
                }
                
                {((pendingLessonPaymentExists || 
                    pendingHelpPaymentExists) && 
                    !pendingAssistantPaymentExists) && (
                        <NormalText>Verrai a breve reindirizzato a Le Tue Lezioni.</NormalText>
                    )
                }
                
                {pendingPurchasePaymentExists && !pendingPurchaseBroadcastExists && !pendingPastThematicBroadcastExists && <NormalText>Verrai a breve reindirizzato ai Tuoi Corsi.</NormalText>}

                {pendingSubscriptionPaymentExists && <NormalText>Verrai a breve reindirizzato ai Tuoi Abbonamenti.</NormalText>}

                {(pendingPurchaseBroadcastExists || pendingPastThematicBroadcastExists) && <NormalText>Verrai a breve reindirizzato alle tue Dirette.</NormalText>}
            </TextBox>
        </Page>
    )
};

export default ThanksForBuying;