import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//redux
import { enableAppMain } from 'redux/actions/actions-app-main';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//services
import { getCoursePayment } from 'services/payments.services';
import { getUserData } from 'services/user.services';

// types
import { PartialLibertyPaymentModalProps } from './PartialLibertyPaymentModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import LoadingMask from 'components/ui/LoadingMask';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    LibertyTitleBox,
    LibertyTitle,
    LibertyTextBox,
    LibertyText,
    LibertyButton
} from './style/PartialLibertyPaymentModal.style';
import { removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';
import { getPaypalUrl } from 'utils/paypal';
import ProductRequisitesModal from 'components/ProductRequisitesModal/ProductRequisitesModal.component';


/**
 * Lyberty Payment modal
 * 
 * @author Piero Foschi
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * 
 * @returns JSX
 */

const PartialLibertyPaymentModal: FC<PartialLibertyPaymentModalProps> = ({
    onCloseButtonModal, 
    courseID, 
    productType, 
    price, 
    typeOfPayment, 
    LibertyPoint, 
    paypalCoursePayment, 
    transferCoursePayment,
    selectedPayment,
    paypalThematicBroadcastPayment,
    thematicCallType
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [requisites, setRequisites] = useState([]);
    const [requisitesLogicalOperator, setRequisitesLogicalOperator] = useState<'AND' | 'OR'>('AND');
    const [isProductRequisitesModalVisible, setIsProductRequisitesModalVisible] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    
    const personalAssistantCode = searchParams.get('ap');

    const closeButtonHandler = () => setIsCloseButtonClicked(true);
    const closeProductRequisitesModal = () => setIsProductRequisitesModalVisible(false);
    const openRequisiteModal = () => setIsProductRequisitesModalVisible(true);

    const PartialLibertyPaymentHandler = async () => {
        setIsLoading(true);

         if (selectedPayment === 'paypal' && productType !== 'ThematicBroadcast') {

            if (personalAssistantCode != null) setItemToLocalStorage('Personal Assistant Code', personalAssistantCode);

            if (paypalCoursePayment) { // Verifica se paypalCoursePayment è definito
                await paypalCoursePayment();
            }
        }

        if (selectedPayment === 'paypal' && productType === 'ThematicBroadcast') {

            if (personalAssistantCode != null) setItemToLocalStorage('Personal Assistant Code', personalAssistantCode);

            if (paypalThematicBroadcastPayment) { // Verifica se paypalThematicBroadcastPayment è definito
                await paypalThematicBroadcastPayment();
            }
        }

        if (selectedPayment === 'bonifico') {
            
            transferCoursePayment();
        }
        setIsLoading(false)
    };

    console.log("PARTIAL LIBERTY TYPE", thematicCallType)

    return (
        <>
            <BaseModal 
                onClose={onCloseButtonModal}
                isCloseButtonClicked={isCloseButtonClicked}
                isBackdropClickActive
            >
                {!isAppMobile && (
                    <Row 
                        padding='0 21px 12px 0'
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <BaseCard 
                    flavor='outlined'
                    isModal
                    >
                    <CardContent>
                    {isLoading && (
                        <LoadingMask 
                            paddingTop='0px'
                            isCenter
                            size='page'
                        />
                    )}

                    {!isLoading && 
                    <>
                        {isAppMobile && (
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={closeButtonHandler}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                        )}

                        <LibertyTitleBox>
                            <LibertyTitle>CONFERMA  DI PAGAMENTO</LibertyTitle>
                        </LibertyTitleBox>

                        <LibertyTextBox>
                            <LibertyText>
                                Hai richiesto un pagamento Parziale di <LibertyPointIconSVG /> {LibertyPoint} Punti Libertà e verrai reindirizzato per completare il restante pagamento di {price} €. 
                                <br />
                                Vuoi Procedere?
                            </LibertyText>
                        </LibertyTextBox>

                        <LibertyButton onClick={PartialLibertyPaymentHandler}>CONFERMA</LibertyButton>
                    </>
                    }
                    </CardContent>
                </BaseCard>
            </BaseModal>
            
            {isProductRequisitesModalVisible &&
                <ProductRequisitesModal
                    onCloseButtonModal={closeProductRequisitesModal}
                    requisites={requisites}
                    requisitesLogicalOperator={requisitesLogicalOperator}
                />
            }
        </>
    );
};

export default PartialLibertyPaymentModal;
function paypalThematicBroadcastPayment() {
    throw new Error('Function not implemented.');
}

