import { Auth } from 'aws-amplify';
import { callPUT } from 'network/network';

// types
import {
    ChangeEmailPayload,
    VerifyEmailConfirmCodePayload,
    VerifyEmailPayload
} from './ConfirmCodeForm.services.d';

const CHANGE_USER_EMAIL_PATH = 'v1/users/self/updatemail';

export const awsVerifyEmailConfirmCode = async (payload: VerifyEmailConfirmCodePayload) => {

    try {

        const response = await Auth.verifyCurrentUserAttributeSubmit('email', payload.confirmCode);

        if (response === undefined || !response) {
            throw new Error();
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};

export const changeUserEmail = async (payload: ChangeEmailPayload) => {

    try {

        const response = await callPUT(CHANGE_USER_EMAIL_PATH, payload.newEmail, true);

        if (response === undefined || !response) {
            console.warn("changeUserEmail undefined response");
        }

        return response;

    } catch (error: any) {
        throw error;
    }
};

export const verifyEmail = async (payload: VerifyEmailPayload) => {

    try {

        const awsVerifyCodePayload = { confirmCode: payload.confirmCode };  
        const awsVerifyEmailConfirmCodeResponse = await awsVerifyEmailConfirmCode(awsVerifyCodePayload);
        if (awsVerifyEmailConfirmCodeResponse === undefined || !awsVerifyEmailConfirmCodeResponse) {
            throw new Error();
        }

        const changeUserMailPayload = { newEmail: payload.newEmail };
        const changeUserEmailResponse = await changeUserEmail(changeUserMailPayload);
        if (changeUserEmailResponse === undefined || !changeUserEmailResponse) {
            throw new Error();
        }

        return changeUserEmailResponse;

    } catch (error: any) {
        throw error;
    }
};
