import { useEffect } from 'react';

// hooks
import { useSearchParams } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';

// utils
import TagManager from 'react-gtm-module';

// components
import MediaPlayer from 'components/MediaPlayer';

// assets
import { ReactComponent as FacebookIconSVG } from './assets/facebook-icon.svg';

// styles
import {
    Card,
    CardMedia,
    Desc,
    Frame,
    Page,
    Title,
    Button} from './style/TagManagerLandingPage.style';

    const tagManagerArgs = {
        gtmId: 'GTM-KJF3WLD'
    };


/** 
 * Facebook Landing Page
 * 
 * @author Oliver Megale
 * 
 * @returns JSX
*/

const TagManagerLandingPage = () => {

    const [searchParams] = useSearchParams();
    const isFirstRender = useIsFirstRender();

    // const searchFrom = searchParams.get('from');

    const videoData = {

        src:"https://www.viveredipoker.it/Marketing/Landing_gf/Liberi_dal_lavoro_video_testimonianze2.mp4", 
        type:"video/mp4"
    };

    const facebookClickhandler = () => {

        // if (searchFrom === '103111111103108101') { // google encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_g", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // };

        // if (searchFrom === '102979910198111111107') { // facebook encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_f", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // }; 
        
        // if (searchFrom === '116105107116111107') { // tiktok encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_t", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // }; 

        TagManager.dataLayer({
            dataLayer: {
                event: 'conversion'
            }
        });
        
        setTimeout(() => {

            window.open(
                "https://rebrand.ly/ldl_n",
                "_blank",
                "noreferrer noopener"
            ) 
        }, 500)
    };

    useEffect(() => {

        if (isFirstRender) {
            TagManager.initialize(tagManagerArgs)
        };
    }, []);

    return(
        <Page>
            <Frame>
                <Title>Puoi avere anche tu questi Risultati ogni mese:</Title>

                <CardMedia>
                    <MediaPlayer 
                        media={videoData} 
                        poster='https://www.viveredipoker.it/Marketing/Landing_gf/Video_screen.png'
                        preload='metadata'
                    />
                </CardMedia>
            </Frame>

            <Card>
                <Desc> 
                    I primi guadagni te li regaliamo noi (!)
                </Desc>

                <Button onClick={facebookClickhandler}>
                    <FacebookIconSVG/>
                    ENTRA NEL GRUPPO GRATUITO
                </Button>
                <Desc>
                    Fare 3.000€ al mese da casa è possibile tutti i mesi senza vendere niente, senza dover dare i tuoi soldi a nessuno. Insomma, senza rischio. 
                </Desc>

                <Desc>
                    Lo dimostrano le centinaia di testimonianze che tutti i mesi riceviamo sul nostro gruppo. <br />
                    E proprio per farti toccare da subito con mano, <br />
                    <span>I Primi GUADAGNI te li regaliamo noi (!)</span>
                </Desc>

                <Desc>
                    A differenza di tutti quelli che vendono fuffa online, noi siamo certi di farti ottenere un risultato e vogliamo che tu sia libero di scegliere. 
                </Desc>

                <Desc>
                    Proprio per questo non ti chiediamo i dati, non ti chiediamo una e-mail, non hai nessuno che ti telefona a casa per venderti qualcosa. <br />
                    <span>Noi non abbiamo venditori…</span>
                </Desc>

                <Desc>
                    Entra oggi nel gruppo, verifica tu stesso le testimonianze, fai domande. <br />
                    Sei libero di restare nel gruppo per tutto il tempo che ti serve,  oppure, quando vuoi, sei libero di uscire  senza dover dare spiegazioni a nessuno. 
                </Desc>

                <Desc>
                    <span>Trovi subito due REGALI importanti: </span>
                </Desc>

                <Desc>
                    <i>1) Il corso che ti permette di ottenere i primi guadagni sicuri <br />
                    2) Una chiamata gratuita con un nostro assistente personale. </i>
                </Desc>

                <Desc>
                    Ci vediamo nel gruppo…
                </Desc>
            </Card>
        </Page>
    );
};

export default TagManagerLandingPage;
