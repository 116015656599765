import colors from "style/palette";
import styled from "styled-components";

export const TotalPage = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0 60px;
    gap: 20px;

    width: 100%;
`;

export const MainTitle = styled.h1`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 600;
    font-size: 50px;

    color: ${colors.white};

    padding: 0px;
    margin: 20px 0 10px;
`;

export const Paragraph = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 5px;
`;

export const ParagraphTitle = styled.h5`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 550;
    font-size: 18px;

    color: ${colors.white};

    padding: 0px;
    margin: 0px;
`;

export const Text = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: ${colors.white};

    padding: 0px;
    margin: 5px 0;
`;

export const List = styled.ul`
    list-style: none;
    padding: 0;

    li {
        position: relative;
        padding-left: 1.5em;

        &::before {
            content: '\2022';
            position: absolute;
            left: 0;
        }
    }
`;

export const ListText = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: ${colors.white};

    padding: 0px;
    margin: 5px 0;
`;

export const Link = styled.a`
    color: ${colors.white};

    padding: 0px;
    margin: 0px;
`;

export const Table = styled.table`
    border: 1px;
`;

export const FirstLine = styled.tr`
    background-color: #358095;

`;

export const LineText = styled.td`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: ${colors.white};

    padding: 10px;
    margin: 0px;
`;

export const SecondLine = styled.tr`

`;

export const SecondLineTitle = styled.th`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: start;

    font-weight: 500;
    font-size: 18px;

    color: ${colors.white};

    padding: 10px;
    margin: 0px;   
`;