import styled, { css } from "styled-components";
import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";



export const VideoCard = styled.div<{inCarousel?: boolean, isIMGTestimonial?: boolean}>`
    
    /* MOBILE CAROUSEL */ 
    /* max-height: 196.875px; */

    background-color: ${colors.black};
    border-radius: 15px;

    > img {
        width: 272px;
        height: 300px;
        border-radius: 15px;
    }

    & video {
        max-width: 100%;
        height: auto;
        border-radius: 15px;
        padding: 0;
    }

    /* MOBILE ALL TESTIMONIALS */
    ${({inCarousel})=> !inCarousel && css`
        max-height: none;
        /* border-radius: 15px 15px 0 0; */
    `}

    /* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
        max-width: 448px;
        max-height: 252px;
        /* border-radius: 15px 0 0 15px; */

        /* DESKTOP ALL TESTIMONIALS */
        ${({inCarousel})=> !inCarousel && css`
            max-width: 288px;
            max-height: 162px;
        `}

        ${({isIMGTestimonial})=> isIMGTestimonial && css`
            max-width: 272px;
            max-height: 300px;
            border-radius: 15px;

            > img {
                width: 272px;
                height: 300px;
                border-radius: 15px;
            }
        `}
    }
`;

export const DarkOverlay = styled.div<{cIndex: number}>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    z-index: -10;
    border-radius: 15px;
    transition: background-color 1s ease-out, backdrop-filter 1s ease-out;
    
    ${({cIndex}) => Math.abs(cIndex) === 1 && css`
        z-index: 1; // Make sure it's above the TestimonialCards
        background-color: rgba(0, 0, 0, 0.4); // Adjust opacity as desired
        backdrop-filter: blur(1px); // Adjust blur radius as desired
    `}

    ${({cIndex}) => Math.abs(cIndex) === 2 && css`
        z-index: 1; // Make sure it's above the TestimonialCards
        background-color: ${colors.black060}; // Adjust opacity as desired
        backdrop-filter: blur(2px); // Adjust blur radius as desired
    `}
 
`;

export const TestimonialWrapper = styled.div<{
        inCarousel?: boolean,
        cIndex?: number,
        opacity?: number,
        hasIMGTestimonials?: boolean
    }>`

    /* MOBILE CAROUSEL */
    display: flex;
    justify-content: center;
    align-items: stretch;

    /* max-width: 350px; */

    border-radius: 15px;
    box-shadow: 1px 1px 2px ${colors.black020};

    opacity: ${({ opacity }) => opacity};
    transition: opacity 1s ease-in-out;

    
    /* MOBILE ALL TESTIMONIALS */
    ${({inCarousel})=> !inCarousel && css`
        /* max-height: 135px; */
        flex-direction: column;
    `}


    /* DESKTOP CAROUSEL */
    @media ${devices.tablet} {
        
        flex-direction: row;
        
        max-width: 272px;
        max-height: auto;
        
        border-radius: 15px;
        
        z-index: 0;
        /* cursor: pointer; */
        
        ${({inCarousel})=> inCarousel && css`
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: transform 1s ease-out;
        `}

        ${({cIndex}) => cIndex !== undefined && css`
            z-index: calc(10 - ${Math.abs(cIndex)});
            /* transform: translate(calc(-50% + 30% * ${cIndex}), -50%) scale(calc(100% - 20% * ${Math.abs(cIndex)})); */
            transform: translate(calc(-50% + 60% * ${cIndex}), -50%) scale(calc(100% - 20% * ${Math.abs(cIndex)}));
        `}

        ${({inCarousel, cIndex})=> inCarousel && cIndex === 0 && css`
            cursor: pointer;
        `}

        /* DESKTOP ALL TESTIMONIALS */
        ${({inCarousel})=> !inCarousel && css`
            /* max-width: 418px;
            max-height: 162px; */
        `} 
    }
`;
