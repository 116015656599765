import styled from 'styled-components';
import { Button } from 'style/buttons';

export const FileInput = styled.input`
    cursor: pointer;
`;

export const AddButton = styled(Button)`
    width: 100px;
    height: 23px;
    padding: 5px;
    font-size: 11px;
`;