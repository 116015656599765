// asset
import useAppSelector from 'hooks/useAppSelector';
import { callGET } from 'network/network';
import { FC, useEffect, useState } from 'react';

// component
import { ReactComponent as CalendarSVG } from './assets/Calendar.svg';
import { ReactComponent as CommentSVG } from './assets/Comment.svg';
import { ReactComponent as CompleteSVG } from './assets/Complete.svg';
import { ReactComponent as VideocallSVG } from './assets/Videocall.svg';

// style
import { 
    LessonInfo, 
    LessonBox, 
    Lessons, 
    DateLesson,
    CoachLesson,
    LessonsDescr,
    LessonIcons,
    LessonsDescrDesktop,
    IconButton
} from "./style/Lesson.component.style";


const Lesson: FC<{
    lesson?: any, 
    list?: any
    index?: number, 
    onCommentClick?: () => void, 
    onRatingClick?: () => void
    choiceLessonHandler?: (n: number, t: 'new' | 'ended') => void,
    typeOfLesson?: 'new' | 'ended'
}>  = ({ 
    lesson, 
    list,
    index, 
    onCommentClick, 
    onRatingClick,
    choiceLessonHandler,
    typeOfLesson
}) => {

    const { isAppMobile } = useAppSelector( state => state.ui);

    //true=green
    //false=gray
    //undefined=yellow

    // const [internalLesson, setInternalLesson] = useState<any | undefined>(lesson); 
    const [isDateCompleted, setIsDateCompleted] = useState<boolean | undefined>(false); 
    const [isZoomCompleted, setIsZoomCompleted] = useState<boolean | undefined>(false); 
    const [isAssistentCompleted, setIsAssistentCompleted] = useState<boolean | undefined>(false);
    const [isCompleted, setIsCompleted] = useState<boolean | undefined>(false);  

    const { expirationDate, lesson: internalLesson, isHighway, numberOfLessonInPack, numberOfLesson, creationDate, isFree} = lesson;

    const datePickerButtonClickHandler = () => {

        if (isDateCompleted || isCompleted) return;
        if (internalLesson.coach.calendly) {
            window.open(
                internalLesson.coach.calendly,
                '_blank',
                'noopener noreferrer'
            )
        }
    };

    const zoomButtonClickHandler = () => {

        if (isZoomCompleted || isCompleted) return;
        if (internalLesson.zoom) {
            window.open(
                internalLesson.zoom,
                '_blank',
                'noopener noreferrer'
            )
        }
    };

    const assistantCommentClickButtonkHandler = () => {

        if (!isZoomCompleted || (internalLesson.coachNotes === null)) return;        
        if (choiceLessonHandler && typeOfLesson) choiceLessonHandler(internalLesson.id, typeOfLesson);
        if (onCommentClick) onCommentClick();
    };

    // da sistemare
    const completeClickButtonHandler = () => {
        
        console.log("COMPLETE LESSON", internalLesson)
        if (!isClosureButtonClickable || isCompleted) return;
        if (choiceLessonHandler && typeOfLesson) choiceLessonHandler(internalLesson.id, typeOfLesson);
        if (onRatingClick) onRatingClick();
    };

    const doubleDate = (number: number) => {

        if (number < 10) return `0${number}`;
        return `${number}`;
    }
    
    const  expirationDateFromCreationDateConditions = (expirationDateFromCreationDate: Date, days: number) => {
        var dateForFreeLesson = new Date(expirationDateFromCreationDate);
        dateForFreeLesson.setDate(dateForFreeLesson.getDate() + days);
        return dateForFreeLesson;
    }
        
    const lessonDate = () =>  {
        const date = new Date(creationDate);
        const dateFromExpiration = new Date(expirationDate);

        const expirationDateFromExpirationDate = `${doubleDate(dateFromExpiration.getDate())}-${doubleDate(dateFromExpiration.getMonth()+1)}-${dateFromExpiration.getFullYear()}`;
        
        if ( expirationDate === null ){
            //autostrada
            if ( isHighway === true) return "";

            //regalo
            if ( isFree === true ) {
                const freeExpirationDate = expirationDateFromCreationDateConditions (date, 15);
                return `${doubleDate(freeExpirationDate.getDate())}-${doubleDate(freeExpirationDate.getMonth()+1)}-${freeExpirationDate.getFullYear()}`;
            } 

            //singola
            if ( numberOfLessonInPack === 1 ) {
                const singleExpirationDate =  expirationDateFromCreationDateConditions (date, 30);
                return `${doubleDate(singleExpirationDate.getDate())}-${doubleDate(singleExpirationDate.getMonth()+1)}-${singleExpirationDate.getFullYear()}`;
            }

            //gruppo
            if ( numberOfLessonInPack > 1 ) {
                const groupExpirationDate = expirationDateFromCreationDateConditions (date, 90);
                return `${doubleDate(groupExpirationDate.getDate())}-${doubleDate(groupExpirationDate.getMonth()+1)}-${groupExpirationDate.getFullYear()}`;
            }

        };

        if ( expirationDate !== undefined ) return expirationDateFromExpirationDate;
        
    };


    const lessonCoach = () =>  {
        
        if ( internalLesson.coach === null) return "";
        if ( internalLesson.coach.name !== undefined ) return internalLesson.coach.name;
        
    };
    
    const lessonTitle = () =>  {
        
        if ( isHighway ) return lessonNumber();
        if ( internalLesson.coach === null) return "";
        if ( internalLesson.coach.topic !== undefined ) return internalLesson.coach.topic;
        
    };

    const lessonHighway = () =>  {
        
        if ( isHighway !== false ) return "AP in assegnazione";
        
    }; 

    const lessonNumber = () => {

        if ( isHighway !== false ) return  `Lezione ${numberOfLesson} de L'Autostrada`;

    };
    
    useEffect(() => {

        if (internalLesson.status === 'CLOSED') {
            setIsDateCompleted(true);
            setIsZoomCompleted(true);
            setIsAssistentCompleted(true);
            setIsCompleted(true);
            return
        }

        if (internalLesson.status === 'CLOSED_BY_COACH') {
            setIsDateCompleted(true);
            setIsZoomCompleted(true);
            setIsAssistentCompleted(true);
            return
        }
        
        if (internalLesson.zoom !== null) {
            setIsDateCompleted(true);
        }

        if (internalLesson.coachClosingDate !== null) {
            setIsZoomCompleted(true);
            setIsAssistentCompleted(true);
        }

        if (internalLesson.closingDate !== null) {
            setIsCompleted(true);
        }
    }, [internalLesson.closingDate, internalLesson.coachClosingDate, internalLesson.status, internalLesson.zoom, lesson]);
    
    // useEffect(() => {
    //     setInternalLesson(internalLesson);
    // }, [lesson]);

    const isCoachNoteIconVisible = ((internalLesson.coachClosingDate === null) || (internalLesson.coachNotes !== null)) && (lesson.isHighway);
    const isClosureButtonClickable = (lesson.isHighway) ? isAssistentCompleted : isDateCompleted;
    console.log("INTERNAL", lesson);
    
    return (
        <LessonBox>
            <LessonInfo>
                <DateLesson>{lessonDate()}</DateLesson>
                <CoachLesson>{lessonCoach() || lessonHighway()}</CoachLesson>
                {!isAppMobile && (<LessonsDescrDesktop dangerouslySetInnerHTML={{__html:lessonTitle()}}/>)}
            </LessonInfo>

            <Lessons>
                {isAppMobile && (<LessonsDescr dangerouslySetInnerHTML={{__html:lessonTitle()}}/>)}

                <LessonIcons>
                    <IconButton
                        type="button"
                        isCompleted={isDateCompleted || isCompleted}
                        onClick={datePickerButtonClickHandler}>
                        <CalendarSVG/>
                    </IconButton>

                    <IconButton
                        type="button"
                        isCompleted={isZoomCompleted || isCompleted}
                        isDisabled={!isDateCompleted}
                        onClick={zoomButtonClickHandler}>
                        <VideocallSVG/>
                    </IconButton>

                    <IconButton
                        type="button"
                        isCompletedAndCliccable={isAssistentCompleted || isCompleted}
                        isDisabled={!isZoomCompleted}
                        onClick={assistantCommentClickButtonkHandler}
                        isIconInvisible={!isCoachNoteIconVisible}>
                        <CommentSVG/>
                    </IconButton>

                    <IconButton
                        type="button"
                        isCompleted={isCompleted}
                        isDisabled={!isClosureButtonClickable}
                        onClick={completeClickButtonHandler}>
                        <CompleteSVG/>
                    </IconButton>
                </LessonIcons>
            </Lessons>
        </LessonBox>
    );
};

export default Lesson;