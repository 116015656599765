import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// assets
import { ReactComponent as WhatsappIconSVG } from './asset/whatsappIcon.svg';

// style
import {
    WhatsAppNeedCallBox,
    WhatsAppNeedCallTextContent,
    WhatsAppNeedCallBoxContent,
    WhatsAppNeedCallText,
    WhatsAppNeedCallButton
} from './style/WhatsAppNeedCallCard.style';
import { getEnvironmentProductId } from 'configs/config.helper';


const WhatsAppNeedCallCard: FC<{
    onButtonClick: () => void
}> = ({
    onButtonClick
}) => {
    
    const [userHasBonusSicuroCourse, setUserHasBonusSicuroCourse] = useState<boolean>(false);
    const { products } = useAppSelector(state => state.user);

    useEffect(() => {
        const courseList = products?.courses;
        setUserHasBonusSicuroCourse((courseList !== undefined) ? courseList.some((course: any) => course.id === getEnvironmentProductId('bonus sicuro')) : false);
    }, [products]);

    console.log("HO BONUS SICURO?", userHasBonusSicuroCourse);
    
    
    return (
        <WhatsAppNeedCallBox>
            <WhatsAppNeedCallBoxContent>
                    <WhatsAppNeedCallTextContent>
                        <WhatsappIconSVG />

                        {!userHasBonusSicuroCourse && 
                            <WhatsAppNeedCallText>
                                Vuoi parlare con qualcuno che ti spieghi il nostro Percorso?
                            </WhatsAppNeedCallText>}

                        {userHasBonusSicuroCourse && 
                            <WhatsAppNeedCallText>
                                Vuoi un aiuto a muovere i primi passi con il Percorso Bonus?
                            </WhatsAppNeedCallText>}
                    </WhatsAppNeedCallTextContent>

                <WhatsAppNeedCallButton onClick={onButtonClick}>Sì LO VOGLIO (!)</WhatsAppNeedCallButton>
            </WhatsAppNeedCallBoxContent>
        </WhatsAppNeedCallBox>
    )
};

export default WhatsAppNeedCallCard;