import useAppDispatch from 'hooks/useAppDispatch';
import { FC } from 'react';

// redux
import { logout } from 'redux/actions/actions-user'; 

// types
import { LogoutButtonProps } from './LogoutButton.d';

// assets
import { ReactComponent as LogoutSVG } from './assets/logout-icon.svg';

// style
import { Button } from './style/LogoutButton.style';
import { useNavigate } from 'react-router-dom';


/**
 * Logou button
 * 
 * @author Alessio Grassi
 * 
 * @param onClick -  toggle handler of the Submenu
 * 
 * @returns JSX
 */


const LogoutButton: FC<LogoutButtonProps> = ({ onClick }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const logoutClickHandler = async () => {

        await dispatch(logout());
        navigate('login');
        if (!onClick) return;
        onClick();
    };

    return (
        <Button
            onClick={logoutClickHandler}    
        >
            <LogoutSVG />
            Log out
        </Button>
    );
}

export default LogoutButton;
