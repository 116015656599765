import config from "./config";

const checkPath = (path: string) => {
    
    if (path.charAt(0) === '/') return path.slice(1);
    return path;
};

export const getEnvironmentBaseUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.productionUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.testUrl}${finalPath}`;
    return `http://localhost:3000/${finalPath}`;
};

export const getEnvironmentMonolithLinkUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.productionUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.testUrl}${finalPath}`;
    return `http://localhost:8080/${finalPath}`;
};

export const getEnvironmentAPILinkUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.baseProductionURL}${finalPath}`;
    return `${config.baseTestURL}${finalPath}`;
};

export const getEnvironmentAssetLinkUrl = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `${config.baseProductionFrontendUrl}${finalPath}`;
    if (config.environment === 'test') return `${config.baseTestFrontendUrl}${finalPath}`;
    return `http://localhost:3000/${finalPath}`;
};

export const getEnvironmentLinkUrlForCSS = (path: string) =>{ 

    const finalPath = checkPath(path);

    if (config.environment === 'production') return `url(${config.baseProductionFrontendUrl}${finalPath})`;
    if (config.environment === 'test') return `url(${config.baseTestFrontendUrl}${finalPath})`;
    return `url(http://localhost:3000/${finalPath})`;
};

export const getEnvironmentBackofficeUrl = () =>{ 

    const domain = window.location.hostname;
    const topLevelDomain = domain.split('.').slice(-2).join('.');
    var brandBackofficeLinkTo = `https://backoffice.${topLevelDomain}`;
    if (domain.includes('gruppomissionebenessere.it')) brandBackofficeLinkTo = `https://backoffice.gruppomissionebenessere.it`;
    return brandBackofficeLinkTo;
    // if (config.environment === 'production') return `https://backoffice.liberidalavoro.it/app`
    // return `https://backoffice.ldl-test.eu/app`
};

export const getEnvironmentOLDAdminBackofficeUrl = () =>{ 

    if (config.environment === 'production') return `https://supporto.liberidalavoro.it/admin/home`
    return `https://ldl-test.eu/admin/home` //supporto.?
};

export const getEnvironmentProductId = (
    prodcutName: 
    '3ding vincente corso' |
    '3ding vincente servizio' |
    '3ding vincente servizio' |
    '3ding guidato servizio' |
    'aiuto privato' |
    'assistente personale' |
    'autostrada' |
    'bonus sicuro' | 
    'bonus sicuro plus' |
    'bonus automatici' |
    'cash academy' |
    'club dei 10k prima edizione () corso' |
    'club dei 10k stagione 2023() corso' |
    'club dei 10k servizio' |
    'colpo sicuro' |
    'colpo sicuro live' |
    'colpo sicuro live NDA' |
    'cryptoflow' |
    'entrate automatiche' |
    'fare soldi senza soldi' |
    'lezione in regalo' |
    'liberi dalla stanchezza' |
    'multibonus evolution' |
    'secret bonus 3.0 corso (nda)' |
    'secret bonus 3.0 corso completo' |
    'super vista' |
    'oddsscasser'
    ) => {

    let id: number =  0;
    const isProd = (config.environment === 'production');

    if (prodcutName === '3ding vincente corso') isProd ? (id = 91) : (id = 91);
    if (prodcutName === '3ding vincente servizio') isProd ? (id = 234) : (id = 205);
    if (prodcutName === '3ding guidato servizio') isProd ? (id = 245) : (id = 216);
    if (prodcutName === 'aiuto privato') isProd ? (id = 271) : (id = 298);
    if (prodcutName === 'assistente personale') isProd ? (id = 200) : (id = 169);
    if (prodcutName === 'autostrada') isProd ? (id = 151) : (id = 152);
    if (prodcutName === 'bonus sicuro') isProd ? (id = 3) : (id = 3);
    if (prodcutName === 'bonus sicuro plus') isProd ? (id = 5) : (id = 6);
    if (prodcutName === 'bonus automatici') isProd ? (id = 204) : (id = 177);
    if (prodcutName === 'cash academy') isProd ? (id = 249) : (id = 220);
    if (prodcutName === 'club dei 10k prima edizione () corso') isProd ? (id = 107) : (id = 108);
    if (prodcutName === 'club dei 10k stagione 2023() corso') isProd ? (id = 254) : (id = 225);
    if (prodcutName === 'club dei 10k servizio') isProd ? (id = 135) : (id = 136);
    if (prodcutName === 'colpo sicuro') isProd ? (id = 72) : (id = 73);
    if (prodcutName === 'colpo sicuro live') isProd ? (id = 162) : (id = 162);
    if (prodcutName === 'colpo sicuro live NDA') isProd ? (id = 161) : (id = 163);
    if (prodcutName === 'cryptoflow') isProd ? (id = 246) : (id = 217);
    if (prodcutName === 'entrate automatiche') isProd ? (id = 257) : (id = 228);
    if (prodcutName === 'fare soldi senza soldi') isProd ? (id = 93) : (id = 94);
    if (prodcutName === 'lezione in regalo') isProd ? (id = 201) : (id = 170);
    if (prodcutName === 'liberi dalla stanchezza') isProd ? (id = 270) : (id = 315);
    if (prodcutName === 'multibonus evolution') isProd ? (id = 87) : (id = 88);
    if (prodcutName === 'secret bonus 3.0 corso (nda)') isProd ? (id = 82) : (id = 83); //controlling
    if (prodcutName === 'secret bonus 3.0 corso completo') isProd ? (id = 97) : (id = 98); 
    if (prodcutName === 'super vista') isProd ? (id = 159) : (id = 316); 
    if (prodcutName === 'oddsscasser') isProd ? (id = 223) : (id = 194); 
    return id;
};
