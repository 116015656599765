import styled from 'styled-components';
import { Button as BaseButton } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const ModifyButtonBox = styled.div`

    width: auto;

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    gap: 10px;
    flex-wrap: nowrap;

    > svg:first-child {

        flex-shrink: 0;
    }
`;

export const ButtonTextLink = styled.button`

    margin: 0;
    padding: 0;

    border: none;
    border-radius: 0;
    background: ${colors.transparent};

    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    text-decoration: underline;

    color: ${colors.white};

    cursor: pointer;

    :disabled {

        cursor: default;
    }
`;

export const SaveBox = styled.div`

    width: auto;

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    gap: 20px;
    flex-wrap: nowrap;
`;

export const Button = styled(BaseButton)`

    padding-left: 25px;
    padding-right: 25px;

    font-size: 15px;

    @media ${devices.tablet} {

        padding-left: 25px;
        padding-right: 25px;

        font-size: 15px;
    }
`;
