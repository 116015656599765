import { FC, useEffect, useRef, useState } from 'react';

//component
import SwitchSelectorAnnual from './SwitchSelectorAnnual/SwitchSelectorAnnual.componet';

//assets
import { ReactComponent as PaymentDisactiveIconSVG } from './assets/disactivePaymentIcon.svg';
import { ReactComponent as PaymentActiveIconSVG } from './assets/activePaymentIcon.svg';

//style
import {
    ProductMethodPaymentBox,
    TimingSubscriptionBox,
    PaypalMethodBox,
    TransferMethodBox,
    PaymentMethodText,
    FilterText
} from './style/PaymentMenu.style';

/**
 * Payment Menu
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

const PaymentMenu: FC<{
    prodcut: boolean, 
    courseElement: any, 
    handlePaymentMethod: (paymentMethod:string) => void, 
    selectedPayment: string, 
    onClickAnnualHandler: () => void, 
    isAnnualActive: boolean,
    TypeOfProduct: string,
    onClose: () => void
}> = ({
    prodcut, 
    courseElement, 
    handlePaymentMethod, 
    selectedPayment, 
    onClickAnnualHandler, 
    isAnnualActive,
    TypeOfProduct,
    onClose
}) => {

    const menuRef = useRef<HTMLDivElement>(null);
   
    const internalHandlePaymentMethod = (paymentMethod:string) => {
        handlePaymentMethod(paymentMethod);
    };

    //Reactivate when the annual payment method is ready

    // const checkIsAnnual = () => {
    //     if (prodcut) return courseElement.productService.paymentPlan.annualOption;
    //     return true;
    // }
    // console.log("elemento", courseElement.productService);
    
    // const isAnnual = checkIsAnnual();

    const paypalSelected = selectedPayment === "paypal";
    const bonificoSelected = selectedPayment === "bonifico";

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
          ) {
            // Clicked outside the menu, so close it
            onClose();
          }
        };
      
        document.addEventListener("click", handleClickOutside);
      
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [onClose]);

    return (
        <ProductMethodPaymentBox ref={menuRef}>

            {/* Reactivate when the annual payment method is ready */}
            {/* {prodcut && isAnnual &&
                <TimingSubscriptionBox>
                    <SwitchSelectorAnnual onClickAnnualHandler={onClickAnnualHandler} isAnnual={isAnnualActive}/>

                    <FilterText>Annuale</FilterText>
                </TimingSubscriptionBox>
            } */}
    
            <PaypalMethodBox isActive={paypalSelected} onClick={() => internalHandlePaymentMethod("paypal")}>
                {!paypalSelected &&<PaymentDisactiveIconSVG />}

                {paypalSelected &&<PaymentActiveIconSVG />}

                <PaymentMethodText isActive={paypalSelected}>Paypal</PaymentMethodText>
            </PaypalMethodBox>

            {(TypeOfProduct !== 'SERVICE')  &&
            
                <TransferMethodBox isActive={bonificoSelected} onClick={() => internalHandlePaymentMethod("bonifico")}>
                    {!bonificoSelected &&<PaymentDisactiveIconSVG />}

                    {bonificoSelected &&<PaymentActiveIconSVG />}

                    <PaymentMethodText isActive={bonificoSelected}>Bonifico</PaymentMethodText>
                </TransferMethodBox>}
    </ProductMethodPaymentBox>
    );
};

export default PaymentMenu;



