import React from "react";
import ReactDOM from 'react-dom';

// routing
import { BrowserRouter as Router } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import store from 'redux/store';

// components
import App from "./App/App.component";

// utils
import { Amplify } from 'aws-amplify';
import config from 'configs/config';
import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';
import TagManager from "react-gtm-module";

// style
import "./index.css";

// import reportWebVitals from "./reportWebVitals";


// aws amplify stuff
// doc link:
//      https://docs.amplify.aws/lib/auth/start/q/platform/js/
//      https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
// refresh session:
//      https://github.com/aws-amplify/amplify-js/issues/2560
// facebook logout error (unresolved)
//      https://github.com/aws-amplify/amplify-js/issues/1298
//      https://github.com/aws-amplify/amplify-js/issues/2423
// facebook automatic refresh session (unresolved)
//      https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#pre-authentication-and-pre-sign-up-lambda-triggers

const ensureAppInRootPath = () => {
    const location = window.location;
    const pathname = location.pathname;
  
    if (pathname === '/' || pathname === '') {
        const newUrl = `${location.origin}/app/`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    } else {
        var blogUrl;
        const domain = window.location.hostname;
        if ((domain.includes('mbtest') || domain.includes('missionebenessere')) && !pathname.includes('/app/') && pathname !== '/app/' && pathname !== '/app') {
            blogUrl = window.location.href.replace('https://', 'https://blog.');
            console.log("blogURL: ",blogUrl);
            window.location.href = blogUrl;
        }
    }
  };

ensureAppInRootPath();

let finalAuthConfiguration;
if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;

const tagManagerArgs = {
    gtmId: 'GTM-T74JZZR'
};

Amplify.configure({ Auth: finalAuthConfiguration });
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <Router basename="/app">
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
