import styled, { css } from 'styled-components';

//utlis
import { getEnvironmentLinkUrlForCSS } from 'configs/config.helper';

// types
import { MainProps } from './App.style.d';

// assets
import appLogo from 'assets/imgs/LDL-icon.svg';
import mainBackground from 'assets/imgs/main-background.svg';

// style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const MinRangeGuard = styled.div`

    min-width: 100vw;
    min-height: 100vh;

    background: ${colors.grey};
    background-image: url(${appLogo});
    background-repeat: no-repeat;
    background-position: center;

    color: ${colors.white};

    overflow: hidden;

    @media ${devices.appMinWidth} {

        display: none;
    }
`;

export const AppBox = styled.div`

    width: 100%;

    display: none;

    overflow-x: hidden;
    overflow-y: auto;

    @media ${devices.appMinWidth} {

        display: block;
    }
`;

export const Main = styled.div<MainProps & { isLDL: boolean; isTCR: boolean; isMB: boolean; isVDR: boolean}>`

    width: 100%;
    
    background: ${colors.grey};

    background-image: ${props => {
        if (props.isLDL) {
        return getEnvironmentLinkUrlForCSS(mainBackground);
        } else if (props.isVDR) {
            return 'linear-gradient(180deg, #242115 14.94%, #000 88.18%)';
        } else if (props.isTCR) {
        return 'linear-gradient(95deg, #041927 0.01%, #082F4F 51.57%, #041927 100%)';
        } else if (props.isMB){
        return 'linear-gradient(95deg, #191B24 0.01%, #263A40 51.57%, #191B24 100%)';
        } else {
            return getEnvironmentLinkUrlForCSS(mainBackground);
        }
    }};
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
    background-attachment: fixed;

    overflow-y: auto;
    overflow-x: hidden;

    /* totalHeight - headerHeight */
    /* max-height: calc(100vh - 69.5px); */
    max-height: calc(100vh - (100vh - ${window.innerHeight}px) - 69.5px);

    @media ${devices.tablet} {

        padding: 69px 0 150px;

        background-size: 100%;
        background-position: 0 69.5px;

        overflow-y: visible;
        overflow-x: visible;

        height: auto;
        max-height: unset;
        /* totalHeight - headerHeight - footerHeight */
        min-height: calc(100vh - 69.5px);

        ${({isHeaderHidden}) => isHeaderHidden && (

            css`
                padding: 0 0 50px;
                background-position: unset;
            `
        )}
    }

    ${({ isDisabled }) => isDisabled && (

        css`
            filter: blur(15px);

            overflow-y: hidden;
            overflow-x: hidden;
        `
    )}
`;

export const PageContainer = styled.div<{maxWidth?: string}>`

    width: 100%;
    max-width: ${props => props.maxWidth ?? '1440px'};
    
    margin: 0 auto;

    height: inherit;
    /* min-height: calc(100vh - 69.5px - 69.5px); */
`;

export const PageBackgroundOnSubmenuOpen = styled.div`

    width: 100%;
    height: inherit;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;

    background: ${colors.grey};
    background-image: url(${mainBackground});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
`;
