import { LicenseLevels, UpgradedLicenseLevels } from './AutomaticIncomes.component.d';


const getUpgradedLevels = (purchasedLicenseAmount: number | undefined, productLicenseLevels: LicenseLevels[]) => {
    
    let totalMaxLevelSumLicensePurchase: number | null = 0;

    const levelUpgrade: UpgradedLicenseLevels[] = productLicenseLevels.map((level: LicenseLevels, index: number) => {
        const licenseLevelEarnAmount = ((level.until === null) || (index === 0)) ? ((level.until === null) ? null : (level.until - 50)) : (level.until - productLicenseLevels[index - 1].until!);
        const maxLicenseLevelPurchaseAmount = ((licenseLevelEarnAmount === null) ? null : (licenseLevelEarnAmount / level.value) * level.price);
        totalMaxLevelSumLicensePurchase = (((licenseLevelEarnAmount === null) || (maxLicenseLevelPurchaseAmount === null)) ? null : ( totalMaxLevelSumLicensePurchase! += maxLicenseLevelPurchaseAmount));

        return {
            price: level.price,
            value: level.value,
            maxEarningAmount: level.until,
            licenseLevelEarnAmount,
            maxLicenseLevelPurchaseAmount,
            totalMaxLevelSumLicensePurchase
        };
    })
    // console.log("UPG LEV:", levelUpgrade);
    return levelUpgrade;
};

const getEarningFromPurchasedAmount = (purchasedLicenseAmount: number | undefined, productLicenseLevels: UpgradedLicenseLevels[]) => {

    if (purchasedLicenseAmount === undefined) return { totalPurchasedEarnings: 0, purchasedEarningLevelIndex: 0};
    let purchasedEarningLevelIndex: number = productLicenseLevels.length - 1; 
    purchasedEarningLevelIndex = productLicenseLevels.findIndex((level: UpgradedLicenseLevels) => {
        if (level.totalMaxLevelSumLicensePurchase !== null) return level.totalMaxLevelSumLicensePurchase > (purchasedLicenseAmount - 10)
    });
    // console.log("LEV IND 1:", purchasedEarningLevelIndex);
    let totalPurchasedEarnings: number = 0;

    for (let i = 0; i <= purchasedEarningLevelIndex; i++) {
        if (i < purchasedEarningLevelIndex) {
            totalPurchasedEarnings += productLicenseLevels[i].maxEarningAmount!;
            continue;
        }
        let remainingPurchasedLicense = 0;
        let remainingPurchasedEarning = 0;
        if (i === 0) {
            remainingPurchasedLicense = (purchasedLicenseAmount - 10)
            remainingPurchasedEarning = (remainingPurchasedLicense / productLicenseLevels[i].price) * productLicenseLevels[i].value;
        }
        if (i !== 0) {

            remainingPurchasedLicense = (purchasedLicenseAmount - 10) - productLicenseLevels[i - 1].totalMaxLevelSumLicensePurchase!;
            remainingPurchasedEarning = (remainingPurchasedLicense / productLicenseLevels[i].price) * productLicenseLevels[i].value;
        }
        totalPurchasedEarnings += remainingPurchasedEarning;
    }
    // console.log("TOT ACQUISTATO:", {
    //     totalPurchasedEarnings,
    //     purchasedEarningLevelIndex
    // });
    return {
        totalPurchasedEarnings,
        purchasedEarningLevelIndex
    };
};

const getPurchasePrice = (purchasedLicenseAmount: number | undefined, totalEarningToPurchase: number | undefined, productLicenseLevels: UpgradedLicenseLevels[]) => {

    if (purchasedLicenseAmount === undefined) return 0;
    if (productLicenseLevels === undefined) return 0;
    if (totalEarningToPurchase === undefined) return 0;
    let purchasedEarningLevelIndex: number = productLicenseLevels.length; 
    purchasedEarningLevelIndex = productLicenseLevels.findIndex((level: UpgradedLicenseLevels) => {
        if (level.maxEarningAmount !== null) return level.maxEarningAmount > totalEarningToPurchase
    });
    // console.log("LEV IND 2:", purchasedEarningLevelIndex);
    
    let remainingPurchasePrice: number = 0;

    if (purchasedEarningLevelIndex === 0) {
        remainingPurchasePrice = ((totalEarningToPurchase / productLicenseLevels[purchasedEarningLevelIndex].value) * 
            productLicenseLevels[purchasedEarningLevelIndex].price - (purchasedLicenseAmount - 10));
    }

    if (purchasedEarningLevelIndex > 0) {

        remainingPurchasePrice = Math.ceil(
            (totalEarningToPurchase - productLicenseLevels[purchasedEarningLevelIndex - 1].maxEarningAmount!) / 
            productLicenseLevels[purchasedEarningLevelIndex].value
        ) * productLicenseLevels[purchasedEarningLevelIndex].price;
    }
    // console.log("ANCORA ACQUISTABILE:", remainingPurchasePrice);
    const totalPurchasePrice: number = (purchasedEarningLevelIndex === 0) ? 
        remainingPurchasePrice : 
        productLicenseLevels[purchasedEarningLevelIndex - 1].totalMaxLevelSumLicensePurchase! + remainingPurchasePrice;

    return totalPurchasePrice;
};

export const getAutomaticIncomeLicenseAmount = (userEarningAmount: number, userLicenseAmount: number | undefined, licenseLevels: LicenseLevels[]) => {
    
    if (licenseLevels === undefined) return 0;

    licenseLevels.sort((prevLevel: LicenseLevels, nextLevel: LicenseLevels) => {

        if((prevLevel.until !== null) && (nextLevel.until !== null)) return prevLevel.until - nextLevel.until
        if (prevLevel.until === null) return 1;
        return -1;
    });
    
    const upgradedLevels = getUpgradedLevels(userLicenseAmount, licenseLevels);
    // console.log("UP LEV:", upgradedLevels);
    
    // calcolo il guadagno con le licenze già acquistate
    const purchasedEarnings = getEarningFromPurchasedAmount(userLicenseAmount, upgradedLevels);
    const totalEarningToPurchase = userEarningAmount + purchasedEarnings.totalPurchasedEarnings;   
    // console.log("USER EARNINGS:", userEarningAmount);
    // console.log("TOTAL EARNINGS:", totalEarningToPurchase);
    
    const totalPurchasePrice = getPurchasePrice(userLicenseAmount, totalEarningToPurchase, upgradedLevels);    
     
    return totalPurchasePrice - (userLicenseAmount! - 10);
};
