import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';


export const ButtonBox = styled.div <{isPresentStyle?: boolean, minWidth?: string, maxWidth?: string}>`

    display: flex;
    justify-content: center; 
    flex-direction: column;
    align-self: center;
    
    width: 100%;

    min-width: ${({minWidth}) => minWidth ?? '158px'};

    max-width: ${({maxWidth}) => maxWidth ?? 'unset'};
`;

export const ProductPriceBox = styled.div<{isDuble?: boolean, isOnDirect?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 15px 0 0;

    width: 100%;

    color: ${colors.black087};

    ${({isDuble}) => isDuble && 
        css`
            align-items: flex-start;
        `
    }

    ${({isOnDirect}) => isOnDirect && 
        css`
            margin: 0;
        `
    }
`;

export const ProductCourseButton = styled.div<{isDisabled?: boolean, isIntoServicesPage?: boolean, nowrap?: boolean, isOnDirect?: boolean}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 42px; 

    margin: 0;
    /* padding: 7px 0 5px; */
    padding: 0 15px;

    background: ${colors.primaryColor};

    border: none;
    border-radius: 5px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};

    text-align: center;
    white-space: ${({nowrap}) => nowrap ? 'nowrap' : 'normal'};

    cursor: pointer;

    color: ${colors.black087};

    ${({isOnDirect}) => isOnDirect && 
        css`
            border-radius: 0 0 5px 5px;
            height: 32px; 
        `
    }

    ${({isDisabled}) => isDisabled && 
        css`

            background: ${colors.white050};
            color: ${colors.black087};

            cursor: default;
        `
    }

    ${({isIntoServicesPage}) => isIntoServicesPage && 
        css`

            flex-direction: column;

            font-size: 14px;
            font-weight: 500;
            color: ${colors.black087};
        `
    }

    :disabled {

        background: ${colors.white050};
        color: ${colors.black087};

        cursor: default;
    }
`;

export const ProductPriceText = styled.div<{isIntoServicesPage?: boolean}>`

    display: inline-flex;
    align-items: center;

    flex-direction: column;

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    color: ${colors.black087} !important;
    letter-spacing: 0.4px;
    /* text-transform: uppercase; */
    white-space: nowrap;

    svg {
        height: 19px;
        width: auto;
    }

    ${({isIntoServicesPage}) => isIntoServicesPage && 
        css`

            display: inline-flex;
            align-items: center;

            gap: 2px;

            font-size: 11px;
            font-weight: 500;
            color: ${colors.black060} !important;

            svg {
                fill-opacity: 0.6;
            }
        `
    }
`;

export const ButtonFirstLineText = styled.div<{isOnSecondLine?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    column-gap: 3px;

    font-size: 14px;
    font-weight: 500;
    color: ${colors.black087} !important;
    
    ${({isOnSecondLine}) => isOnSecondLine && 
    
    css`
            font-size: 11px;
            color: ${colors.black060} !important;
        `
    }
`;

export const ProductPriceTimingText = styled.span`

    display: inline-flex;
    align-items: center;

    font-size: 11px;
    font-weight: 600;

    text-transform: none;
    color: ${colors.black060};

    svg {
        fill-opacity: 0.6;
    }
`;


export const ProductAlternativePaymentGlobalBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: relative;

    margin: 7px 0 0;

    width: 100%;
    min-height: 23px;
`;

export const ProductAlternativePaymentBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;

    gap: 5px;
`;

export const TrialWithLibertyPointsText = styled.div`

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    flex-direction: column;
`;

export const ProductPriceSelectorBox = styled.button<{isActive?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    padding:  10px 7px 8px;

    background: ${colors.grey};

    border-radius: 5px;
    border: none;

    cursor: pointer;

    ${({isActive}) => isActive && 
        css`
            border-radius: 5px 5px 0 0;
        `
    }
`;

export const ProductAlternativePriceBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;


    gap: 3px;

    margin: 0;
    padding: 0;

    font-weight: 600;
    font-size: 11px;

    font-style: italic;

    color: #21262B;

    white-space: nowrap;
`;

export const PresentText = styled.div`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    color: ${colors.black087};
    letter-spacing: 0.4px;
    /* text-transform: uppercase; */
    white-space: nowrap;

    gap: 5px;
`;