import styled from 'styled-components';

// utils
import { getEnvironmentLinkUrlForCSS } from 'configs/config.helper';

// types
import { SubmenuCardProps } from './UserProfileSubmenu.style.d';

// assets
import mainBackground from 'assets/imgs/main-background.svg';

// style
import devices from 'style/breakpoints/breakpoints';
import colors from 'style/palette/palette';

const backgroundUrl = getEnvironmentLinkUrlForCSS(mainBackground);


export const Container = styled.div<SubmenuCardProps>`

    width: 100%;
    max-width: 390px;

    /* padding: 0 20px;

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 50;

    transform: translate(-50%, -55%); */

    display: ${({ isOpen }) => isOpen ? "block" : "none"};

    @media ${devices.tablet} {

        width: auto;
        max-width: none;

        margin: 0;
        padding: 0;

        position: absolute;
        top: 100%;
        left: auto;
        right: -20px;

        transform: translate(0, 0);
    }
`;

export const Title = styled.h2`

    margin: 0 0 30px 10px;
    padding: 0;

    font-size: 28px;
    font-weight: 400;
    
    color: ${colors.white};
`;

export const IdUser = styled.div`

    display:flex;
    justify-content: center;
    align-items: center;

    gap: 10px;

    width: 100%;
    margin: 0;
    padding: 0 15px 15px;

    font-size: 10px;
    font-weight: 400;
    
    color: ${colors.black};
    text-transform: uppercase;

    span {
        font-weight: 700;
    }

    @media ${devices.tablet} {
        margin: 0px;
    }
`;

export const InvitationLink = styled.div`

    display:flex;
    justify-content: center;
    align-items: center;

    gap: 8px;

    width: 100%;
    margin: 0;
    padding: 5px 15px 15px;

    color: ${colors.black};
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: italic;
    font-weight: 600;

    cursor: pointer;

    @media ${devices.tablet} {
        /* padding: 3px 15px 30px; */
        margin: 0px;
    }
`;

export const NumberFriendsInviteBox = styled.div`

    display:flex;
    justify-content: center;
    align-items: center;

    gap: 8px;

    width: 100%;
    margin: 0;
    padding: 5px 15px 25px;

    color: ${colors.black};
    font-size: 13px;
    font-weight: 400;

    >span {
        font-weight: 700;
        font-size: 15px;
    }

    @media ${devices.tablet} {
        padding: 3px 15px 30px;
        margin: 0px; 
    }
`;

export const Separator = styled.div`
    margin: 0 -30px 15px -30px;
    border-top: 1px solid ${colors.lightGrey};

`;

export const ClientUser = styled.div`
    margin: 0 0 0 5px;
    padding: 0 0 0 0;

    font-size: 10px;
    font-weight: 650;
    
    color: ${colors.black};

    @media ${devices.tablet} {
        
    }
`;

export const SubmenuCard = styled.div`

    width: 100%;

    padding: 20px 0;
    border-radius: 15px;

    background: ${colors.white};
    box-shadow:
        0 0 0 1px ${colors.white012};

    a {

        text-decoration: none;
    }

    @media ${devices.tablet} {

        min-width: 250px;
        width: auto;

        padding: 30px;
        border-radius: 0 0 15px 15px;
    }
`;

export const MenuBackdrop = styled.div`

    display: none;

    width: 100vw;
    height: 100vh;
    
    background: ${colors.veryDarkGrey};

	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	z-index: 20;

    @media ${devices.appMinWidth} {

        display: block;
    }

    @media ${devices.tablet} {

        background: ${colors.transparent};
    }
`;

export const PlusLiberty = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 17px 0px 0px;
    gap: 5px;

    @media ${devices.tablet} {
        margin: -20px 0px 27px;
    }
`;

export const PlusLibertyButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;

    width: 16px;
    height: 16px;

    background: #246EC6;
    border-radius: 100px;
    border: none;

    cursor: pointer;
`;

export const PlusLibertyText = styled.a`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;

    color: #246EC6;

    margin: 0px;
    padding: 0px;
    
    cursor: pointer;
`;


