import styled, { css } from 'styled-components';

// types
import { LessonIconBoxProps } from './FormLessonsContent.style.d'

// style
import { Button } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 0;
    padding: 0;

    color: ${colors.white};

    @media ${devices.tablet} {
        padding: 42px 28px 50px 12px;
    }
`;

export const LessonsText = styled.p`

    margin: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;

    color: ${colors.blueGrey};
`;

export const LessonsDisclaimer = styled.span`

    color: ${colors.white};
`;

export const LessonBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;

    margin: 35px 0 0;

    @media ${devices.tablet} {
        margin: 45px 0 0;
    }
`;

export const EmptyLessonBox = styled.p`

    margin: 30px 0 0;

    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
    color: ${colors.green};
`;

export const LessonsButton = styled(Button)`

    margin: 35px 0 0;

    @media ${devices.tablet} {

        margin: 50px 0 0;
    }
`;
