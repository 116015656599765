import { FC, useState, useEffect } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// routing
import { useLocation, useNavigate } from 'react-router-dom';

//utils
import {
	birthdayNumerologyValue,
	calculateChaldeanFrequency,
} from './utils/numerologyUtils';
import { appendBrandToUrl } from 'configs/brandMap/brandMap.helper';

// assets
import { ReactComponent as NumerologySVG } from './assets/numerologiaCaldea.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as BackIcon } from '../VdrTools/assets/back.svg';

//components
import VdrToolsAudioPlayer from 'components/VdrToolsAudioPlayer/VdrToolsAudioPlayer.component';

// style
import {
	BirthdayResultBox,
	BirthdayResultTitle,
	BirthdayResultValue,
	BlackCard,
	CalculateButton,
	CardTitle,
	DatePicker,
	Page,
	PageTitle,
	PageTitleBox,
	ShareBox,
	ShareButton,
	ShareText,
	TextInput,
	TextResultBox,
	TextResultValue,
} from './style/VdrNumerology.style';
import {
	getItemFromLocalStorage,
	setItemToLocalStorage,
} from 'utils/localStorage';

const VdrNumerology: FC<{}> = () => {
	const [date, setDate] = useState('');
	const [dateResult, setDateResult] = useState('');
	const [inputText, setInputText] = useState('');
	const [textResult, setTextResult] = useState<number[]>([]);

	const { isAppMobile } = useAppSelector((state) => state.ui);
	const { tokenID } = useAppSelector((state) => state.user);
	const isUserLoggedIn = tokenID !== undefined ? !!tokenID : false;

	const navigate = useNavigate();
	const location = useLocation();

	const onDateChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDate(event.currentTarget.value);
	};

	const onInputTextChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setInputText(event.currentTarget.value);
	};

	const onCalculateButtonClickHandler = () => {
		setItemToLocalStorage('numerologyDate', date);
		setItemToLocalStorage('numerologyText', inputText);
		const path =
			'/login?redirectPath=' +
			encodeURIComponent(
				isAppMobile
					? appendBrandToUrl('/reservedArea/strumenti/numerologia-caldea', location)
					: appendBrandToUrl('/reservedArea/strumenti', location)
			);
		navigate(path);
	};

	const onBackClickHandler = () => {
		navigate('/public/strumenti');
	};

	useEffect(() => {
		if (date === '' || !isUserLoggedIn) return;
		const realDate = new Date(date);
		setDateResult(birthdayNumerologyValue(realDate));
	}, [date, isUserLoggedIn]);

	useEffect(() => {
		if (inputText === '' || !isUserLoggedIn) return;
		setTextResult(calculateChaldeanFrequency(inputText));
	}, [inputText, isUserLoggedIn]);

	useEffect(() => {
		//check if date is in localStorage to preload it
		const storedDate = getItemFromLocalStorage('numerologyDate');
		var timeoutId: any;
		if (storedDate !== null && storedDate !== '') {
			setDate(storedDate);
			timeoutId = setTimeout(() => {
				localStorage.removeItem('numerologyDate');
			}, 1500);
		}

		//check if inputtext is in localStorage to preload it
		const storedText = getItemFromLocalStorage('numerologyText');
		var timeoutId2: any;
		if (storedText !== null && storedText !== '') {
			setInputText(storedText);
			timeoutId2 = setTimeout(() => {
				localStorage.removeItem('numerologyText');
			}, 1500);
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			if (timeoutId2) {
				clearTimeout(timeoutId2);
			}
		};
	}, []);

	return (
		<Page>
			<PageTitleBox>
				<PageTitle>Numerologia Caldea</PageTitle>
				{isAppMobile && <BackIcon onClick={onBackClickHandler} />}
			</PageTitleBox>
			<BlackCard>
				<CardTitle>Data</CardTitle>
				<DatePicker
					type='date'
					id='birthday'
					name='birthday'
					placeholder='__/__/____'
					value={date}
					onChange={onDateChangeHandler}
					// disabled={!isUserLoggedIn}
				></DatePicker>
				{date && isUserLoggedIn && (
					<BirthdayResultBox>
						<BirthdayResultTitle>
							Il Tuo numero è
						</BirthdayResultTitle>
						<BirthdayResultValue>{dateResult}</BirthdayResultValue>
					</BirthdayResultBox>
				)}
			</BlackCard>
			<BlackCard>
				<CardTitle>Firma, Parole o Frasi</CardTitle>
				<TextInput
					type='text'
					id='numerology-text'
					name='numerology-text'
					value={inputText}
					onChange={onInputTextChangeHandler}
					// disabled={!isUserLoggedIn}
				></TextInput>
				<TextResultBox>
					{textResult.map((t) => (
						<TextResultValue key={t}>{t}</TextResultValue>
					))}
				</TextResultBox>
			</BlackCard>
			{!isUserLoggedIn && (
				<CalculateButton onClick={onCalculateButtonClickHandler}>
					Calcola
				</CalculateButton>
			)}
			<VdrToolsAudioPlayer
				title='Come funziona la Numerologia?'
				author='Paolo Luini'
				audioSrc='https://www.viveredipoker.it/LaVoceDellaRicchezza/TracceAudio/come_funziona_la_numerologia.mp3'
			/>
			<NumerologySVG />
			{/* <ShareBox>
				<ShareText>Ti piace questo strumento?</ShareText>
				<ShareButton>
					<ShareIcon />
					condividi
				</ShareButton>
			</ShareBox> */}
		</Page>
	);
};

export default VdrNumerology;
