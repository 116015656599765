import styled from "styled-components";

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;
