import useAppSelector from "hooks/useAppSelector";
import { FC, useState } from "react";
import Channel from "./Channel/Channel.component";

//asset
import { ReactComponent as CleanIconSVG } from './asset/cleanIcon.svg';

// style
import { 
    TotalPage, 
    InputGrid,
    Input,
    Id,
    IdName,
    IdNot,
    CleanBox
} from "./style/ChannelList.style";


const ChannelList: FC<{channelsList: any[], onChannelClick: (channelId: number) => void}> = ({ channelsList, onChannelClick }) => {

    const { name, surname } = useAppSelector(state => state.user);
    const [searchInput, setSearchInput] = useState<string>("");

    const searchInputChangeHandler = (event: any) => {

        const newSearch = event.target.value
        setSearchInput(newSearch);
    };

    const clearSearchInput = () => {
        setSearchInput("");
      };

    const userName = `${name} ${surname}`;
    // const sortedChannelList = channelsList.sort((channelA: any, channelB: any) => {
        
    //     const timestampA = (channelA.lastMessage !== null) ? channelA.lastMessage.sendingDate : 0;
    //     const timestampB = (channelB.lastMessage !== null) ? channelB.lastMessage.sendingDate : 0;

    //     return (new Date(timestampB).valueOf()) - (new Date(timestampA).valueOf());
    // }) ;

    const sortedChannelList = channelsList
  .sort((channelA: any, channelB: any) => {
    const timestampA = channelA.lastMessage !== null ? channelA.lastMessage.sendingDate : 0;
    const timestampB = channelB.lastMessage !== null ? channelB.lastMessage.sendingDate : 0;

    return new Date(timestampB).valueOf() - new Date(timestampA).valueOf();
  })
  .filter((channel: any) => {
    const channelName = channel.channel.name.toLowerCase();
    const searchInputLower = searchInput.toLowerCase();

    return channelName.includes(searchInputLower);
  });

   
    return (
        <TotalPage>
            <Id>
                <IdName>
                    {userName}
                </IdName>
                <IdName> / </IdName>
                <IdNot>Notifiche</IdNot>
            </Id>
            
            <InputGrid>
                <Input 
                    placeholder="Cerca"
                    type="text"
                    value={searchInput}
                    onChange={searchInputChangeHandler}
                />
                {searchInput.length > 0 && (
                    <CleanBox onClick={clearSearchInput}>
                        <CleanIconSVG />
                    </CleanBox>
                )}
            </InputGrid>

            {(channelsList !== undefined) && (
                sortedChannelList.map((channel: any, index: number) => (
                    <Channel 
                        key={"CH" + index}
                        channelData={channel}
                        onChannelClick={onChannelClick}
                    />
                ))
            )}
        </TotalPage>
    );
}

export default ChannelList;