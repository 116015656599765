//types
import { MenuFilterProps } from './index.d';

//assets
import { ReactComponent as FreerisckIconSVG } from './assets/freerisckIcon.svg';
import { ReactComponent as GrowingIconSVG } from './assets/growingIcon.svg';
import { ReactComponent as CryptoIconSVG } from './assets/cryptoIcon.svg';
import { ReactComponent as ActivityIconSVG } from './assets/activityIcon.svg';

import { ReactComponent as FilterOpenIconSVG } from './assets/filterOpenIcon.svg';


export const MENU_FILTER: Array<MenuFilterProps> = [
    {
        text: 'Senza Rischio',
        icon: <FreerisckIconSVG />,
    },
    {
        text: 'Crescita Personale',
        icon: <GrowingIconSVG />,
        
    },
    {
        text: 'Crypto',
        icon: <CryptoIconSVG />,
    },
    {
        text: 'Attività',
        icon: <ActivityIconSVG />,
    },
    {
        text: 'Altri',
        icon: <></>,
    }
    ];
