import { FC, useEffect, useRef, useState } from 'react';

//component
import MyServiceActive from './MyServiceActive/MyServiceActive.component';
import MyServiceDisactive from './MyServiceDisactive/MyServiceDisactive.component';

//asset
import { ReactComponent as FreeRisckIconSVG } from './assets/freerisckIcon.svg';
import { ReactComponent as PersonalGrowthIconSVG } from './assets/personalIcon.svg';
import { ReactComponent as CryptoIconSVG } from './assets/cryptoIcon.svg';
import { ReactComponent as ActivityIconSVG } from './assets/activityIcon.svg';

//style
import {
    Page,
    Content,
    MyServiceInfoBox,
    MyFreeRisckServiceBox,
    MyPersonalGrowthServiceBox,
    CategoryServiceBox,
    CategoryServiceText,
    MyCryptoServiceBox,
    MyServiceActvityBox,
    MyServiceBox,
    MyServiceContent,
    MyServiceActiveBox,
    ServiceStatusSeparateBox,
    MyServiceDisactiveBox
} from './style/MyNewServicePage.style';
import { callGET } from 'network/network';
import useAppSelector from 'hooks/useAppSelector';
import ProductInfoModal from 'components/ProductInfoModal/ProductInfoModal.component';
import store from 'redux/store';


const MyNewServicePage: FC<{}> = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [serviceActivedList, setServiceActivedList] = useState<any[]>([]);
    const [serviceDisactivedList, setServiceDisactivedList] = useState<any[]>([]);
    const [allServicesOwned, setAllServicesOwned] = useState<any[]>([]);
    const [isProductInfoModalVisible, setIsProductInfoModalVisible] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>('');
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [isServiceDisactive, setIsServiceDisactive] = useState<boolean>(false);
    const [servicesWithBrand, setServicesWithBrand] = useState<any[]>([]);
    const [activeServices, setActiveServices] = useState([]);
    
    const { name: userName, surname: userSurname} = useAppSelector(state => state.user);
    const isAppLaptop = (useRef([window.innerWidth]).current[0] < 1200);

    const closeProductInfoModal = () => setIsProductInfoModalVisible(false);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const refreshPage = () => {
        setIsServiceDisactive(true);
    };

    const setNewProductId = (newProductId: string, productName: string, productPrice: string) => {
        
        setIsProductInfoModalVisible(true);
        setProductId(newProductId);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
    };

    const modalGetPaymentID = (paymentID: number | undefined) => {
        setPaymentTransferID(paymentID);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)     
    };

    const publicInfoPageTransferPrice = (grossPrice: number) => {
        setChosenProductPrice(`${grossPrice}`)  
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };
    
    type ServiceCategory = {
        SENZA_RISCHIO: any[];
        CRESCITA_PERSONALE: any[];
        CRYPTO: any[];
        ATTIVITA: any[];
    };

    const [activeServiceCategories, setActiveServiceCategories] = useState<ServiceCategory>({
        SENZA_RISCHIO: [],
        CRESCITA_PERSONALE: [],
        CRYPTO: [],
        ATTIVITA: [],
    });
    
    const [disactiveServiceCategories, setDisactiveServiceCategories] = useState<ServiceCategory>({
        SENZA_RISCHIO: [],
        CRESCITA_PERSONALE: [],
        CRYPTO: [],
        ATTIVITA: [],
    });

    const groupActiveServicesByCategory = (services: any[]): ServiceCategory => {
        const groupedServices: ServiceCategory = {
          SENZA_RISCHIO: [],
          CRESCITA_PERSONALE: [],
          CRYPTO: [],
          ATTIVITA: [],
        };
      
        services.forEach((service) => {
          const categoryName = service.product.categories[0]?.name;
          switch (categoryName) {
            case "SENZA RISCHIO":
              groupedServices.SENZA_RISCHIO.push(service);
              break;
            case "CRESCITA PERSONALE":
              groupedServices.CRESCITA_PERSONALE.push(service);
              break;
            case "CRYPTO":
              groupedServices.CRYPTO.push(service);
              break;
            case "ATTIVITÀ":
              groupedServices.ATTIVITA.push(service);
              break;
            default:
              groupedServices.SENZA_RISCHIO.push(service);
              break;
          }
        });
      
        return groupedServices;
    };

    const groupDisactiveServicesByCategory = (services: any[]): ServiceCategory => {
        const groupedServices: ServiceCategory = {
            SENZA_RISCHIO: [],
            CRESCITA_PERSONALE: [],
            CRYPTO: [],
            ATTIVITA: [],
        };
    
        services.forEach((service) => {
            const categoryName = service.categories[0]?.name;
            switch (categoryName) {
                case "SENZA RISCHIO":
                    groupedServices.SENZA_RISCHIO.push(service);
                    break;
                case "CRESCITA PERSONALE":
                    groupedServices.CRESCITA_PERSONALE.push(service);
                    break;
                case "CRYPTO":
                    groupedServices.CRYPTO.push(service);
                    break;
                case "ATTIVITÀ":
                    groupedServices.ATTIVITA.push(service);
                    break;
                default:
                    groupedServices.SENZA_RISCHIO.push(service);
                    break;
            }
        });
    
        // Funzione di ordinamento per mettere i servizi scaduti (expired=true) per primi
        const sortServicesByExpiration = (a: any, b: any) => {
            if (a.expired === b.expired) return 0;
            return a.expired ? -1 : 1;
        };
    
        // Ordina i servizi all'interno di ogni categoria
        groupedServices.SENZA_RISCHIO.sort(sortServicesByExpiration);
        groupedServices.CRESCITA_PERSONALE.sort(sortServicesByExpiration);
        groupedServices.CRYPTO.sort(sortServicesByExpiration);
        groupedServices.ATTIVITA.sort(sortServicesByExpiration);
    
        return groupedServices;
    };

    // FILTRANDO I SERVIZI PER STATUS CREATED

    // const getServices = async () => {
    //     try {
    //         const response = await callGET('v1/self/productsubscriptions?brandCode=LDL&types=SERVICE', true);
    //         console.log("SERVIZI AVUTI IN TOTALE", response);
    //         setAllServicesOwned(response);
    
    //         const currentDate = new Date();
    //         const filteredResponse = response
    //             .filter((service: any) => service.productService.status !== "CREATED")
    //             .map((service: any) => {
    //                 const correspondingService = servicesWithBrand.find(
    //                     (serviceWithBrand) => serviceWithBrand.id === service.id
    //                 );
    //                 const vipPoints = correspondingService ? correspondingService.vipPoints : 0;
    //                 return { ...service, vipPoints };
    //             });
    
    //         const sortedResponse = filteredResponse.sort((a:any, b:any) => {
    //             const dateA = new Date(`${a.productService.expirationDate}Z`);
    //             const dateB = new Date(`${b.productService.expirationDate}Z`);
    //             return dateA.getTime() - dateB.getTime();
    //         });
    //         setServiceActivedList(sortedResponse);
    
    //         const groupedActiveServices = groupActiveServicesByCategory(sortedResponse);
    //         setActiveServiceCategories(groupedActiveServices);
    
    //     } catch (error: any) {
    //         console.warn(error);
    //     }
    // };

    //FILTRANDO LO STATUS E LA DATA DI SCADENZA
    const getServices = async () => {
        try {
            const allServices = await getServiceWithBrand();

            const brandCode = store.getState().ui.brand?.code;
            const response = await callGET(`v1/self/productsubscriptions?brandCode=${brandCode}&types=SERVICE`, true);
            //   console.log("SERVIZI AVUTI IN TOTALE", response);
            setAllServicesOwned(response);
        
            const currentDate = new Date().getTime();

            const filteredResponse = response
                .filter((service: any) => {
                    const expirationDate = new Date(`${service.productService.expirationDate}Z`).getTime();
                    return service.productService.status !== "CREATED" && expirationDate >= currentDate;
                })
                .map((service: any) => {
                    const correspondingService = allServices.find(
                        (serviceWithBrand: any) => serviceWithBrand.id === service.product.id
                    );
                    const vipPoints = correspondingService ? correspondingService.productService.paymentPlan.vipPoints : 0;
                    const isRenewable = !!correspondingService;
                    return { ...service, vipPoints, isRenewable };
                });
            setActiveServices(filteredResponse); 
        
            const sortedResponse = filteredResponse.sort((a:any, b:any) => {
                const dateA = new Date(`${a.productService.expirationDate}Z`);
                const dateB = new Date(`${b.productService.expirationDate}Z`);
                return (dateB.getTime() - dateA.getTime());
            });
            const sortedResponseWithStatus = sortedResponse.sort((a:any, b:any) => {
                return (a.productService.status < b.productService.status ? 1 : -1);
            });
            setServiceActivedList(sortedResponseWithStatus);
        
            const groupedActiveServices = groupActiveServicesByCategory(sortedResponseWithStatus);
            setActiveServiceCategories(groupedActiveServices);
            
            const filteredServicesJustActive = allServices.filter(
                (serviceJustActive: any) =>
                    !sortedResponseWithStatus.some((activeService: any) => activeService.product.id === serviceJustActive.id)
            );
    
            setServicesWithBrand(filteredServicesJustActive);
            const groupedServices = groupDisactiveServicesByCategory(filteredServicesJustActive);
            setDisactiveServiceCategories(groupedServices);
            setServiceDisactivedList(filteredServicesJustActive);

        } catch (error: any) {
          console.warn(error);
        }
    };

    const getServiceWithBrand = async () => {
        try {
            const serviceParams = {
                brandCode: store.getState().ui.brand?.code,
                types: 'SERVICE'
            };
    
            const response = await callGET("v1/productsforsell/bytypes", true, serviceParams);
            if (!response || response === undefined) throw new Error("Products response undefined");
    
            return response;

            // const filteredServicesJustActive = response.filter(
            //     (serviceJustActive: any) =>
            //         !activeServiceList.some((activeService: any) => activeService.product.id === serviceJustActive.id)
            // );
    
            // setServicesWithBrand(filteredServicesJustActive);
            // const groupedServices = groupDisactiveServicesByCategory(filteredServicesJustActive);
            // setDisactiveServiceCategories(groupedServices);
            // setServiceDisactivedList(filteredServicesJustActive);
    
        } catch (error: any) {
            if (error.message.includes('403')) throw new Error('403');
            console.warn(error);
        }
    };

    useEffect(() => {
        getServices();
        // getServiceWithBrand();
    },[]);

    useEffect(() => {

        if (!isServiceDisactive) return;
        setTimeout(() => {

            getServices();
            // getServiceWithBrand();
            setIsServiceDisactive(false);
        }, 500);
    },[isServiceDisactive]);

    // useEffect(() => {
    //     console.log("STATO SERVICE ACTIVE", serviceActivedList);
    //     console.log("STATO SERVICE NO ACTIVE", serviceDisactivedList);
    //   }, [serviceActivedList, serviceDisactivedList]);
    
    return (
        <>
            <Page>
                <Content>
                    <MyServiceInfoBox>
                        {userName} {userSurname} / I Miei Abbonamenti
                    </MyServiceInfoBox>

                    <MyServiceBox>
                        <MyServiceActiveBox>
                            <MyFreeRisckServiceBox>
                                <CategoryServiceBox>
                                    <FreeRisckIconSVG /> 
                                    
                                    <CategoryServiceText>
                                        SENZA RISCHIO
                                    </CategoryServiceText>
                                </CategoryServiceBox>

                                <MyServiceContent>
                                    {activeServiceCategories.SENZA_RISCHIO !== undefined && activeServiceCategories.SENZA_RISCHIO.length > 0 &&
                                        activeServiceCategories.SENZA_RISCHIO.map((service: any, index: any) => {
                                            return (
                                                <MyServiceActive 
                                                    key={index}
                                                    serviceElement={service}
                                                    disactiveRefresh={refreshPage}
                                                    vipPoints={service.vipPoints}
                                                />
                                            );
                                        })
                                    }

                                    {(isAppMobile || isAppLaptop) && <ServiceStatusSeparateBox>Non sono ancora tuoi...</ServiceStatusSeparateBox>}

                                    {(isAppMobile || isAppLaptop) &&
                                    <MyServiceContent>
                                        {disactiveServiceCategories.SENZA_RISCHIO.map((service) => (
                                            <MyServiceDisactive 
                                                key={service.id} 
                                                service={service}
                                                onInfoButtonClick={setNewProductId}
                                                onLibertyClick={libertyModalHandler}
                                                openLibertyPaymentModal={openLibertyPaymentModal}
                                                servicesOwned={allServicesOwned}
                                                refreshFunction={refreshPage}
                                            />
                                        ))}
                                    </MyServiceContent>}

                                </MyServiceContent>
                            </MyFreeRisckServiceBox>

                            <MyPersonalGrowthServiceBox>
                                <CategoryServiceBox>
                                    <PersonalGrowthIconSVG /> 
                                    
                                    <CategoryServiceText>
                                        CRESCITA PERSONALE
                                    </CategoryServiceText>
                                </CategoryServiceBox>
                                <MyServiceContent>
                                    {activeServiceCategories.CRESCITA_PERSONALE !== undefined && activeServiceCategories.CRESCITA_PERSONALE.length > 0 &&
                                        activeServiceCategories.CRESCITA_PERSONALE.map((service: any, index: any) => {
                                            return (
                                                <MyServiceActive 
                                                    key={index}
                                                    serviceElement={service}
                                                    disactiveRefresh={refreshPage}
                                                    vipPoints={service.vipPoints}
                                                />
                                            );
                                        })
                                    }
                                </MyServiceContent>

                                {(isAppMobile || isAppLaptop) && <ServiceStatusSeparateBox>Non sono ancora tuoi...</ServiceStatusSeparateBox>}

                                {(isAppMobile || isAppLaptop) &&
                                <MyServiceContent>
                                    {disactiveServiceCategories.CRESCITA_PERSONALE.map((service) => (
                                        <MyServiceDisactive 
                                            key={service.id} 
                                            service={service}
                                            onInfoButtonClick={setNewProductId}
                                            onLibertyClick={libertyModalHandler}
                                            openLibertyPaymentModal={openLibertyPaymentModal}
                                            servicesOwned={allServicesOwned}
                                            refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>}
                            </MyPersonalGrowthServiceBox>

                            <MyCryptoServiceBox>
                                <CategoryServiceBox>
                                    <CryptoIconSVG /> 
                                    
                                    <CategoryServiceText>
                                        CRYPTO
                                    </CategoryServiceText>
                                </CategoryServiceBox>

                                <MyServiceContent>
                                    {activeServiceCategories.CRYPTO !== undefined && activeServiceCategories.CRYPTO.length > 0 &&
                                        activeServiceCategories.CRYPTO.map((service: any, index: any) => {
                                            return (
                                                <MyServiceActive 
                                                    key={index}
                                                    serviceElement={service}
                                                    disactiveRefresh={refreshPage}
                                                    vipPoints={service.vipPoints}
                                                />
                                            );
                                        })
                                    }
                                </MyServiceContent>

                                {(isAppMobile || isAppLaptop) && <ServiceStatusSeparateBox>Non sono ancora tuoi...</ServiceStatusSeparateBox>}

                                {(isAppMobile || isAppLaptop) &&
                                <MyServiceContent>
                                    {disactiveServiceCategories.CRYPTO.map((service) => (
                                        <MyServiceDisactive 
                                        key={service.id} 
                                        service={service}
                                        onInfoButtonClick={setNewProductId}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        servicesOwned={allServicesOwned}
                                        refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>}
                            </MyCryptoServiceBox>

                            <MyServiceActvityBox>
                                <CategoryServiceBox>
                                    <ActivityIconSVG /> 
                                    
                                    <CategoryServiceText>
                                        ATTIVITA'
                                    </CategoryServiceText>
                                </CategoryServiceBox>

                                <MyServiceContent>
                                    {activeServiceCategories.ATTIVITA !== undefined && activeServiceCategories.ATTIVITA.length > 0 &&
                                        activeServiceCategories.ATTIVITA.map((service: any, index: any) => {
                                            return (
                                                <MyServiceActive 
                                                    key={index}
                                                    serviceElement={service}
                                                    disactiveRefresh={refreshPage}
                                                    vipPoints={service.vipPoints}
                                                />
                                            );
                                        })
                                    }
                                </MyServiceContent>

                                {(isAppMobile || isAppLaptop) && <ServiceStatusSeparateBox>Non sono ancora tuoi...</ServiceStatusSeparateBox>}

                                {(isAppMobile || isAppLaptop) &&
                                <MyServiceContent>
                                    {disactiveServiceCategories.ATTIVITA.map((service) => (
                                        <MyServiceDisactive 
                                        key={service.id} 
                                        service={service}
                                        onInfoButtonClick={setNewProductId}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        servicesOwned={allServicesOwned}
                                        refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>}
                            </MyServiceActvityBox>
                        </MyServiceActiveBox>

                        {!isAppMobile && !isAppLaptop && <ServiceStatusSeparateBox>Non sono ancora tuoi...</ServiceStatusSeparateBox>}

                        {!isAppMobile && !isAppLaptop &&
                        <MyServiceDisactiveBox>
                            <MyFreeRisckServiceBox>
                                <MyServiceContent>
                                    {disactiveServiceCategories.SENZA_RISCHIO.map((service) => (
                                        <MyServiceDisactive 
                                            key={service.id} 
                                            service={service}
                                            onInfoButtonClick={setNewProductId}
                                            onLibertyClick={libertyModalHandler}
                                            openLibertyPaymentModal={openLibertyPaymentModal}
                                            servicesOwned={allServicesOwned}
                                            refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>
                            </MyFreeRisckServiceBox>

                            <MyPersonalGrowthServiceBox>
                                <MyServiceContent>
                                    {disactiveServiceCategories.CRESCITA_PERSONALE.map((service) => (
                                        <MyServiceDisactive 
                                            key={service.id} 
                                            service={service}
                                            onInfoButtonClick={setNewProductId}
                                            onLibertyClick={libertyModalHandler}
                                            openLibertyPaymentModal={openLibertyPaymentModal}
                                            servicesOwned={allServicesOwned}
                                            refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>
                            </MyPersonalGrowthServiceBox>

                            <MyCryptoServiceBox>
                                <MyServiceContent>
                                    {disactiveServiceCategories.CRYPTO.map((service) => (
                                        <MyServiceDisactive 
                                        key={service.id} 
                                        service={service}
                                        onInfoButtonClick={setNewProductId}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        servicesOwned={allServicesOwned}
                                        refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>
                            </MyCryptoServiceBox>

                            <MyServiceActvityBox>
                                <MyServiceContent>
                                    {disactiveServiceCategories.ATTIVITA.map((service) => (
                                        <MyServiceDisactive 
                                        key={service.id} 
                                        service={service}
                                        onInfoButtonClick={setNewProductId}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        servicesOwned={allServicesOwned}
                                        refreshFunction={refreshPage}
                                        />
                                    ))}
                                </MyServiceContent>
                            </MyServiceActvityBox>
                        </MyServiceDisactiveBox>}
                    </MyServiceBox>
                </Content>
            </Page>

            {isProductInfoModalVisible && (
                <ProductInfoModal
                    onCloseButtonModal={closeProductInfoModal}
                    productInfoId={productId}
                    // onBankModalOpen={openBankTransferModal}
                    onLibertyModalOpen={openLibertyPaymentModal}
                    modalGetPaymentID={modalGetPaymentID}
                    libertyProductID={libertyProductID}
                    publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                />
            )}
        </>
    );
};

export default MyNewServicePage;