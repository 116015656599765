import { FC, FormEvent, useState } from 'react';

// routing
import { useNavigate, useSearchParams } from 'react-router-dom';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// utils
import { validateEmailInput, validateConfirmCodeInput } from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { formatSearchParam } from '../SignUp.helper';
import { verifyConfirmCode } from './services/ConfirmSignUpForm.services';
import { VerifyConfirmCodePayload } from './services/ConfirmSignUpForm.services.d';

// components
import TextInput from 'components/ui/TextInput';

// style
import { Col } from 'style/layout';
import { Form, VerifyEmailButton } from './style/ConfirmSignUpForm.style';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';
import useBrand from 'hooks/useBrand';


/**
 * Confirm sign up form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const ConfirmSignUpForm: FC<{}> = () => {

    const [emailInput, setEmailInput] = useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
    const [confirmCodeValue, setConfirmCodeValue] = useState<string>("");
    const [confirmCodeErrorMessage, setConfirmCodeErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const brand = useBrand();

    const validateEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setEmailErrorMessage(error);

        return error;
    };

    const emailInputChangeHandler = (newEmail: string) => {

        validateEmailHandler(newEmail);
        setEmailInput(newEmail);
    };

    const validateConfirmCodeHandler = (newConfirmCode: string) => {

        const error = validateConfirmCodeInput(newConfirmCode);
        setConfirmCodeErrorMessage(error);

        return error;
    };

    const confirmCodeInputChangeHandler = (newConfirmCode: string) => {

        validateConfirmCodeHandler(newConfirmCode);
        setConfirmCodeValue(newConfirmCode);
    };

    const cleanFormInputs = () => {

        setEmailInput("");
        setEmailErrorMessage(undefined);
        setConfirmCodeValue("");
        setConfirmCodeErrorMessage(undefined);
    };

    const getRef = () => {

        const ref = getItemFromLocalStorage('ref');
        const refSetDate = getItemFromLocalStorage('refSetDate');

        const nowDate = new Date().getTime();
        
        if (ref === null) return 22579;
        if (refSetDate === null) return 22579;

        const itsTimeForGenericRef = (nowDate - +refSetDate) > 604800000 // one week in millisecond

        if (itsTimeForGenericRef) return 22579;

        return +ref;
    };

    const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (isLoading) return;
        const emailError = validateEmailHandler(emailInput);
        if (emailError) return;
        const confirmCodeError = validateConfirmCodeHandler(confirmCodeValue);
        if (confirmCodeError) return;
        if (!brand) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {

            const referral = getRef();

            const verifyConfirmCodePayload: VerifyConfirmCodePayload = {

                username: emailInput,
                code: confirmCodeValue,
                referral,
                brandCode : brand?.code
            };

            await verifyConfirmCode(verifyConfirmCodePayload);
            if (!isMounted()) return;

            cleanFormInputs();
            snackbarData.type = 'success';
            snackbarData.message = "Registrazione confermata! Ora puoi accedere al sito.";
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
            removeItemFromLocalStorage('ref');
            removeItemFromLocalStorage('refSetDate');
            navigate('/login', { replace: true });
            
        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    };

    return (
        <Form onSubmit={formSubmitHandler}>
            <Col gap='16px'>
                <TextInput
                    label="Email:"
                    type="text"
                    flavor='filled'
                    value={emailInput}
                    errorMsg={emailErrorMessage}
                    onChange={emailInputChangeHandler}
                    onValidate={() => validateEmailHandler(emailInput)}
                />

                <TextInput
                    label="Codice di verifica:"
                    flavor='filled'
                    value={confirmCodeValue}
                    errorMsg={confirmCodeErrorMessage}
                    onChange={confirmCodeInputChangeHandler}
                    onValidate={() => validateConfirmCodeHandler(confirmCodeValue)}
                />
            </Col>

            <VerifyEmailButton
                type="submit"
                value="login"
                disabled={isLoading}
            >
                Verifica la tua email
            </VerifyEmailButton>
        </Form>
    );
};

export default ConfirmSignUpForm;
