import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import {
    InputLabelBoxProps,
    LabelProps,
    PasswordToggleBoxProps,
    InputProps
} from './TextInput.style.d';


export const InputBox = styled.div`

    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    gap: 16px;
    flex-direction: column;
`;

/* default flavor is "standard" */
export const InputLabelBox = styled.div<InputLabelBoxProps>`
    
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    border-radius: 0;
    background: ${colors.transparent};

    border-bottom:
        1px solid ${colors.white042};

    ${({ isActive }) => isActive && (

        css`
            border-bottom: 2px solid ${colors.primaryColor};
        `
    )}

    ${({ isDisabled }) => isDisabled && (

        css`
            border-bottom: none;
        `
    )}

    ${({ isErrorMsgActive }) => isErrorMsgActive && (

        css`
            border-bottom: 2px solid ${colors.red};
        `
    )}

    ${({ flavor }) => (flavor === "filled") && (

        css`
            border-radius: 4px 4px 0 0;
            background: ${colors.white009};
        `
    )}
`;

/* default flavor is "standard" */
export const Label = styled.label<LabelProps>`

    width: 100%;
    padding: 4px 0;

    display: inline-flex;
    align-items: stretch;
    justify-content: flex-start;
    
    gap: 15px;
    flex-shrink: 2;
    
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;

    color: ${colors.white070};
    position: relative;

    cursor: text;

    > span:first-child {

        align-self: center;
        white-space: nowrap;

        transform: scale(1);
        transform-origin: left center;
        transition: transform 200ms linear;

        position: absolute;
    }

    ${({ isActive }) => isActive && (

        css`
            padding: 4px 0;

            color: ${colors.primaryColor};
            font-weight: 400;

            flex-wrap: wrap;
        `
    )}

    ${({ isDisabled }) => isDisabled && (

        css`
            padding: 0;

            flex-wrap: wrap;

            position: unset;
            cursor: default;

            > span:first-child {

                color: ${colors.white};
                position: unset;
            }
        `
    )}

    ${({ isErrorMsgActive }) => isErrorMsgActive && (

        css`
            color: ${colors.red};
            font-weight: 400;
        `
    )}

    ${({ flavor }) => (flavor === "filled") && (

        css`
            padding: 15px 0 12px 12px;

            gap: 4px;

            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.15px;
        `
    )}

    ${({ flavor, isActive }) => ( isActive && (flavor === "filled") ) && (

        css`
            /* padding: 9px 0 8px 12px; */
            padding: 15px 0 12px 12px;

            color: ${colors.primaryColor};
            font-weight: 400;

            flex-wrap: wrap;

            > span:first-child {

                transform: scale(0.75) translate(0, -15px);
                transition: transform 200ms linear;
            }
        `
    )}

    ${({ flavor, isErrorMsgActive }) => ( isErrorMsgActive && (flavor === "filled") ) && (

        css`
            color: ${colors.red};
            font-weight: 400;
        `
    )}
`;

/* default flavor is "standard" */
export const Input = styled.input<InputProps>`

    min-width: 0;
    width: 100%;

    padding: 0 0 8px;
    border: none;

    background: ${colors.transparent};
    color: ${colors.white};

    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    text-align: left;

    :focus {
        
        outline: 1px solid ${colors.primaryColor};
    }

    :focus-visible {

        outline: none;
    }

    :read-only {

        padding: 0;

        color: ${colors.white056};

        cursor: default;
    }

    ${({ flavor }) => (flavor === "filled") && (

        css`
            padding: 0 0 4px;

            font-size: 16px;
            text-align: center;
        `
    )}
`;

export const PasswordToggleBox = styled.div<PasswordToggleBoxProps>`

    padding: ${({ isActive }) => isActive ? "0 9.91px 0 5.24px" : " 0 9.91px 0 0"};

    display: inline-flex;
    align-items: stretch;
    justify-content: center;

    flex-shrink: 0;

    cursor: ${({ isActive }) => isActive ? "pointer" : "auto"};

    > svg {

        min-width: 29px;
        min-height: 18px;
        
        align-self: center;
    }
`;

export const ErrorRow = styled.p`

    margin: 0;
    padding: 0 0 0 14px;

    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: ${colors.red};
`;
