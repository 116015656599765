// routing
import { useNavigate } from 'react-router-dom';

// assets
import { ReactComponent as InfoSVG } from './assets/Info.svg';
import { ReactComponent as ComingSoonSVG } from '../UserPathCard/assets/coming-soon.svg';

// hooks
import { FC, useEffect, useState } from 'react';
import { callGET } from 'network/network';

// components
import UserLevelComponent from '../UserLevelComponent/UserLevelComponent.component';
import UserLevelModal from '../UserLevelModal/UserLevelModal.component';

// styles
import { 
    ButtonBox,
    ButtonText,
    InfoButton,
    LevelBox,
    LinkLevel,
    UserLevelCardBox 
} from "./style/UserCardLevel.styl";


const UserLevelCard =  () => {

    const [isLevel, setIsLevel] = useState <number> (0);
    const [isModal, setIsModal] = useState <boolean> (false);

    const navigate = useNavigate();

    const handleInfoButtonClick = () => {
        setIsModal(prevIsModal => !prevIsModal);
    };

    const closeModal = () => {
        setIsModal(false);
    };

    const levelClickHandler = () => {
        navigate('/reservedArea/product-page/level/'+isLevel)
    }

    const getUserVipPathLevel = async () => {

        try {
            const response = await callGET('v1/self/vippathlevel', true);
            
            return response.pathLevel

        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            throw error;
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const pathLevel = await getUserVipPathLevel();
                setIsLevel(pathLevel);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);   

    return (
        <>
            <UserLevelCardBox>

                <InfoButton>
                    <InfoSVG onClick={handleInfoButtonClick}/>
                </InfoButton>

                <LevelBox>
                    <UserLevelComponent isModal={isModal}/>
                </LevelBox>

                <LinkLevel onClick={levelClickHandler}>
                    <ButtonBox>
                        <ButtonText>
                            VIDEO IN REGALO PER TE
                        </ButtonText>
                        {/* <ComingSoonSVG /> */}
                    </ButtonBox>
                </LinkLevel>

            </UserLevelCardBox>

            {isModal && <UserLevelModal onCloseButtonModal={closeModal}/>}
        </>
    );
};

export default UserLevelCard;