import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const DoubleSliderContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
  
export const Input = styled.input<{zIndex: number}>`
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    z-index: ${(props) => props.zIndex};
    appearance: none;
    /* Removing the default appearance */
    ::-webkit-slider-thumb {
        appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
    /* For Chrome browsers */
    ::-webkit-slider-thumb {
        background-color: ${colors.white};
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
    /* For Firefox browsers */
    ::-moz-range-thumb {
        background-color: ${colors.white};
        border: none;
        border-radius: 50%;
        box-shadow: ${colors.black050} 0px 1px 1px;
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
`;

export const Slider = styled.div`
    position: relative;
    width: 100%;
`;

export const SliderTrack = styled.div`
    position: absolute;
    border-radius: 3px;
    height: 4px;
    background-color: ${colors.lightGrey};
    width: 100%;
    z-index: 1;
`;

export const SliderRange = styled.div`
    position: absolute;
    border-radius: 3px;
    height: 4px;
    background-color: ${colors.white};
    z-index: 2;
`;
