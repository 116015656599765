import { useEffect } from 'react';

// hooks
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';

// utils
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

// assets
import { ReactComponent as FacebookIconSVG } from '../assets/facebook-icon.svg';

// styles
import {
    Card,
    Desc,
    Page,
    Button,
    ButtonForGoogle
} from './style/BonusTogether.style';
import { getEnvironmentBaseUrl } from 'configs/config.helper';

// const _tag_manager_args = {
//     gtmId: 'AW-10793452957'
// };

const _pixel_id = `184344131163699`;

/** 
 * Facebook Landing Page
 * 
 * @author Frank Berni
 * 
 * @returns JSX
*/

const BonusTogether = () => {

    const [searchParams] = useSearchParams();
    const isFirstRender = useIsFirstRender();

    const searchFrom = searchParams.get('from');
    const navigate = useNavigate();

    useEffect(() => {

        if (isFirstRender && (searchFrom === '103111111103108101')) {
            // TagManager.initialize(_tag_manager_args);
        };

        if (isFirstRender && (searchFrom === '102979910198111111107')) {

            ReactPixel.init(_pixel_id);
            ReactPixel.pageView();
        };
    }, []);

    return(
        <Page>
            <Card>

                <ButtonForGoogle href={`${getEnvironmentBaseUrl(`/app/lp/mk/b?from=${searchFrom}&bridgeID=andste`)}`} >
                    <FacebookIconSVG/>
                    ENTRA NEL GRUPPO GRATUITO
                </ButtonForGoogle>

                <Desc>
                Guadagnare online è possibile e puoi farlo anche tu, <span>senza rischio</span> e senza nemmeno comprarti un costoso corso online, anzi, il corso te lo regaliamo noi.

                </Desc>

                <Desc>
                    Entra oggi nel gruppo facebook e potrai assistere gratuitamente alla spiegazione del metodo...
                </Desc>

                <Desc>
                    <span>Faremo insieme i primi guadagni</span> e potrai replicare liberamente le operazioni. 
                </Desc>

                <Desc>
                    Tocca con mano quanto è facile fare soldi online... se sai come fare...
                </Desc>

                <Desc>
                    <i>- Senza Rischio</i>
                </Desc>

                <Desc>
                    <i>- Senza fare Trading</i>
                </Desc>

                <Desc>
                    <i>- Senza Vendere Nulla </i>
                </Desc>

                <Desc>
                </Desc>
            </Card>
        </Page>
    );
};

export default BonusTogether;
