import { FC, useEffect, useMemo, useState } from 'react';

//redux
import useAppDispatch from 'hooks/useAppDispatch';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate, useSearchParams } from "react-router-dom";

//services
import { getUserData } from 'services/user.services';

//utils
import { getAutomaticIncomePayment, getCoursePayment, getLibertyPoint, getSubscriptionPayment, getThematicBroadcastPayment } from 'services/payments.services';
import { getPaypalUrl } from 'utils/paypal';
import { formattedCurrency } from 'utils/supportFunctions';
import { removeItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';

//component
import PaymentMenu from 'components/PaymentMenu/PaymentMenu.component';

//assets
import { ReactComponent as PriceSelectorIconOpenSVG } from './assets/dropdownIcon.svg';
import { ReactComponent as PriceSelectorIconClosedSVG } from './assets/dropupIcon.svg';
import { ReactComponent as LibertyPointIconSVG } from './assets/libertyPointIcon.svg';
import { ReactComponent as PaypalIconSVG } from './assets/paypalIcon.svg';
import { ReactComponent as BankIconSVG } from './assets/bankIcon.svg';
import { ReactComponent as PresentIconSVG } from './assets/presentIcon.svg';

//style
import {
    ButtonBox,
    ProductPriceBox,
    ProductAlternativePaymentGlobalBox,
    ProductAlternativePaymentBox,
    ProductCourseButton,
    ProductPriceSelectorBox,
    ProductAlternativePriceBox,
    ProductPriceTimingText,
    ProductPriceText,
    PresentText,
    ButtonFirstLineText,
    TrialWithLibertyPointsText
} from './style/ButtonShop.style';
import PartialLibertyPaymentModal from 'components/PartialLibertyPaymentModal/PartialLibertyPaymentModal.component';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { enableAppMain } from 'redux/slices/slice-app-main';
import ProductRequisitesModal from 'components/ProductRequisitesModal/ProductRequisitesModal.component';
import ServiceLibertyModal from 'components/ServiceLibertyPaymentModal/ServiceLibertyPaymentModal.component';
import LoadingMask from 'components/ui/LoadingMask/LoadingMask.component';


/**
 * Button Shop
 * 
 * @author   Piero Foschi <19pfox81@gmail.com>
 * 
 * @returns JSX
 */

const ButtonShop: FC<{
    PriceProduct: number, 
    TypeOfProduct: string, 
    courseElement: any, 
    courseID: number, 
    payments: any,
    onTransferClick?:(productName: string, productPrice: string, paymentID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void,
    isPresentStyle?: boolean,
    openBankTransferModal?:() => void,
    onLibertyClick:() => void,
    openLibertyPaymentModal?:() => void,
    libertyInfoModalHandler?:() => void,
    isOnPublicProductPage?: boolean,
    isLibertyPage?: boolean,
    isAutomaticIncome?: boolean,
    modalGetPaymentID?: (paymentID: number | undefined) => void,
    productIdHandler?: (courseID: number) => void,
    productPriceHandler?: (price: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?: () => void,
    hasRequire?: boolean,
    isDisabled?: boolean | undefined
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    publicPageTransferPrice?: (grossPrice: number) => void,
    vipPoints?: number,
    trialVipPoint?: number,
    isServicePageRequest?: boolean,
    refreshFunction?: () => void,
    isIntoServicesPage?: boolean,
    isSuperViewProductPage?: boolean,
    isDirectPurchase?: string |null,
    isPresentTextHidden?: boolean,
    campaignId?: string | null
    customLabel?: string,
    minWidth?: string,
    maxWidth?: string,
    isOnThematicDirect?: boolean,
    isOnThematicDirectNot?: boolean,
    thematicBroadcastType?: string,
    isPastBroadcast?: boolean
}> = ({
    PriceProduct, 
    TypeOfProduct, 
    courseElement, 
    courseID, 
    payments,
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isPresentStyle,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyInfoModalHandler,
    isOnPublicProductPage,
    isLibertyPage,
    isAutomaticIncome,
    modalGetPaymentID,
    productIdHandler,
    productPriceHandler,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    isDisabled,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    vipPoints,
    trialVipPoint,
    isServicePageRequest,
    refreshFunction,
    isIntoServicesPage,
    isSuperViewProductPage,
    isDirectPurchase,
    isPresentTextHidden,
    campaignId,
    customLabel,
    minWidth,
    maxWidth,
    isOnThematicDirect,
    isOnThematicDirectNot,
    thematicBroadcastType,
    isPastBroadcast
}) => {

    const { tokenID, libertyPointsAvailableBalance } = useAppSelector(state => state.user)
    const [isSelectorOpenActive, setIsSelectorOpenActive] = useState(false);
    const [typePaymentTextButton, setTypePaymentTextButton] = useState<string>("Euro");
    const [selectedPayment, setSelectedPayment] = useState<"paypal" | "bonifico">("paypal");
    const [isAnnualActive, setIsAnnualActive] = useState(false);
    const [priceReturnFromBackend, setPriceReturnFromBackend] = useState<number>();
    const [searchParams] = useSearchParams();
    const [libertyPrice, setLibertyPrice] = useState<number>();
    const [partialLibertyPrice, setPartialLibertyPrice] = useState<number>();
    const [isPartialLibertyPaymentModalVisible, setIsPartialLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isServiceLibertyPaymentModalVisible, setIsServiceLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductRequisitesModalVisible, setIsProductRequisitesModalVisible] = useState<boolean>(false);
    const [requisites, setRequisites] = useState([]);
    const [requisitesLogicalOperator, setRequisitesLogicalOperator] = useState<'AND' | 'OR'>('AND');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    
    const closePartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(false);
    const openPartialLibertyPaymentModal = () => setIsPartialLibertyPaymentModaVisible(true);
    const closeProductRequisitesModal = () => setIsProductRequisitesModalVisible(false);
    const openRequisiteModal = () => setIsProductRequisitesModalVisible(true);
    const closeServiceLibertyPaymentModal = () => setIsServiceLibertyPaymentModaVisible(false);
    const openServiceLibertyPaymentModal = () => setIsServiceLibertyPaymentModaVisible(true);

    const personalAssistantCode = searchParams.get('ap');
    const dispatch = useAppDispatch();
    
    // const assignPersonalAssistant = async () => {

    //     if (personalAssistantCode == null) return;
    //     try {
    //         await setPersonalAssistant(lessons.id, personalAssistantCode);
    //     } catch (error: any) {
    //         console.warn("ERROR:", error);
    //     }
    // };

    const { productService } = courseElement ?? {};
    const {name} = courseElement ?? {};

    const paymentPlan = productService?.paymentPlan;
    const trialMonths = paymentPlan?.trialMonths;
    const trialPrice = paymentPlan?.trialPrice;
    const moreMouth: number = paymentPlan?.frequency;
    const navigate = useNavigate();

    const onClickSelectorOpenHandler = () => {
        
        setIsSelectorOpenActive(prevState => !prevState);
    };

    const closeSelectorHandler = () => {
        setIsSelectorOpenActive(false);
      };

      const CalculateLibertyPrice = () => {
        const libertyValue = 1.22;
    
        if (TypeOfProduct === "SERVICE") {
            if (isSingolMouth) {
                const result = (finalServicePrice / libertyValue)
                setLibertyPrice(+result.toFixed(2));
            };
    
            if (isTrial) {
                const result = (trialPrice / libertyValue)
                setLibertyPrice(+result.toFixed(2));
            };
    
            if (isPackMouths) {
                const result = (finalServicePrice / libertyValue)
                setLibertyPrice(+result.toFixed(2));
            };
        } else { // Calcolo del prezzo in punti libertà per il corso
            const result = (PriceProduct / libertyValue)
            setLibertyPrice(+result.toFixed(2));
        }
    };

    const onClickAnnualHandler = () => {
        setIsAnnualActive(prevState => !prevState);
    };

    const CalculatePartialLibertyPrice = () => {
        const libertyValue = 1.22;
        const result= PriceProduct - (+LibertyPoint * libertyValue);
    
        setPartialLibertyPrice(+result.toFixed(2));
    };

    const handlePaymentMethod = (paymentMethod:string) => {
        if (paymentMethod === "paypal") {
            setSelectedPayment("paypal");
        }
        if (paymentMethod === "bonifico") {
            setSelectedPayment("bonifico");
        }
    };
    
    const typeOfPayment = () => {
        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "paypal")) {
            const paypal = payments.filter((paymentType: any) => paymentType.name.includes('Paypal'));
            console.log("Paypal", paypal);
            return paypal.length > 0 ? paypal[0].id : null;
        };

        if ((isEuroVisibal || isLibertyPointPartialVisibal) && (selectedPayment === "bonifico")) {
            const transfer = payments.filter((paymentType: any) => paymentType.name.includes('Bonifico'));
            console.log("Bonifico", transfer);
            return transfer.length > 0 ? transfer[0].id : null;
        };

            const liberty = payments.filter((paymentType: any) => paymentType.name.includes('Punti'));
            console.log("Libertry", liberty);
            return liberty.length > 0 ? liberty[0].id : null;
    };

    const paypalCoursePayment = async () => {

        try {
            loadingOn();
            const response = await getCoursePayment(courseID, typeOfPayment(), true, false, campaignId);
            if (isPastBroadcast === true) setItemToLocalStorage('PastBroadcast', 'true');
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);
            window.location.href = paypalUrl;
            return;
        } catch (error: any) {
            loadingOff();
            console.warn("ERROR:", error);
            removeItemFromLocalStorage('Personal Assistant Code');
            setIsLoading(false);            

            if (error.response.data.message === "Requisites not satisfied") {   
                const requisiteProducts = error.response.data.error;
                setRequisites(requisiteProducts);
                openRequisiteModal();
            }
            
            if (error.message === "Already purchased") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "Questo Prodotto è già in tuo possesso"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }

            if (error.message === "Max date for this product exceeded") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }

            dispatch(enableAppMain());
        }
    };

    const paypalThematicBroadcastPayment = async () => {

        if (thematicBroadcastType === undefined) return;

        try {
            loadingOn();
            const response = await getThematicBroadcastPayment(courseID, thematicBroadcastType, typeOfPayment(), false, false, null);
            
            const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
            if (typeof paypalId !== 'string' ) return;
            const paypalUrl = getPaypalUrl(paypalId);
            window.location.href = paypalUrl;
            return;
        } catch (error: any) {
            loadingOff();
            console.warn("ERROR:", error);
            removeItemFromLocalStorage('Personal Assistant Code');
            setIsLoading(false);            

            if (error.response.data.message === "Requisites not satisfied") {   
                const requisiteProducts = error.response.data.error;
                setRequisites(requisiteProducts);
                openRequisiteModal();
            }
            
            if (error.message === "Already purchased") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "Questo Prodotto è già in tuo possesso"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }

            if (error.message === "Max date for this product exceeded") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }

            dispatch(enableAppMain());
        }
    };


    const transferCoursePayment = async () => {
        try {
            loadingOn();
            const response = await getCoursePayment(courseID, typeOfPayment(), isUserLoggedIn);
            if (response.status !== 'CREATED') new Error ("Qualcosa è andato storto con la creazione dell'acquisto.");
            if (coursePaymentTransferModalInfo) buyInfoModalHandler();
            if (coursePaymentPartialLibertyTransferModalInfo) buyInfoModalHandler();
            if (coursePaymentTransferLogout) openBankTransferModal();
            if (isOpenInModal && modalGetPaymentID) modalGetPaymentID(response.id);

            const createdTransactionGross = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].gross
            setPriceReturnFromBackend(createdTransactionGross);
            if (publicInfoPageTransferPrice) publicInfoPageTransferPrice(createdTransactionGross);
            if (publicPageTransferPrice) publicPageTransferPrice(createdTransactionGross);
            dispatch( getUserData() );
            loadingOff();
            if(onTransferClick)onTransferClick(name, `${createdTransactionGross}`, response.id);
            console.log("prezzo gross", createdTransactionGross);
            

        } catch (error: any) {
            loadingOff();
            setIsLoading(false);
            console.warn("ERROR:", error);
            removeItemFromLocalStorage('Personal Assistant Code');
            if (error.message === "Already purchased") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "Questo Prodotto è già in tuo possesso"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }

            if (error.response.data.message === "Requisites not satisfied") {               
                const requisiteProducts = error.response.data.error;
                setRequisites(requisiteProducts);
                openRequisiteModal();
            }

            if (error.message === "Max date for this product exceeded") {
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo"
                  };
                  
                  dispatch(addSnackbar(snackbarData));
            }
        }
    };

    const paymentHandler = async () => {
        if (isDisabled) return;
        // if (isDisabled) {
        //     const snackbarData: SnackbarOptions = {
        //         type: 'info',
        //         message: "L'Offerta per questo prodotto è scaduta e NON è più possiible acquistarlo attualmente!"
        //       };
              
        //       dispatch(addSnackbar(snackbarData));
        //       return;
        // };

        setIsLoading(true);

        
        
        if(!hasRequire && !isUserLoggedIn) {
            setItemToLocalStorage('To redirect at', `${window.location.href}`);
            navigate('/fallback-page/require-login');
            return;
        };
        // to do navigate when no more places or time is over
        if(!hasRequire && openProductRequisitesModal) {
            setIsLoading(false);
            openProductRequisitesModal()
            return
        };
        
        if (TypeOfProduct === 'SERVICE') {
            try {
                
                if (servicePaymentPayPalShop) {
                    
                    try {
                        loadingOn();
                        const response = await getSubscriptionPayment(courseID, typeOfPayment());

                        window.location.href = response.productService.approvalLink;
                        // navigate("/fallback-page/Thanks-for-buying");
                    } catch (error: any) {
                        loadingOff();
                        setIsLoading(false);
                        console.warn("ERROR:", error);

                        if (error.response.data.message === "Requisites not satisfied") {               
                            const requisiteProducts = error.response.data.error;
                            setRequisites(requisiteProducts);
                            openRequisiteModal();
                            return
                        }

                        const snackbarData: SnackbarOptions = {
                            type: 'info',
                            message: "Questo Prodotto non è acquistabile al momento, per maggiori info controlla la pagina del prodotto"
                          };
                          
                          dispatch(addSnackbar(snackbarData));
        
                    }                    
                };

                if (servicePaymentPayPalLogout) {
                    
                    try {
                        loadingOn();
                        const response = await getSubscriptionPayment(courseID, typeOfPayment(), isUserLoggedIn);

                        window.location.href = response.productService.approvalLink;


                    } catch (error: any) {
                        loadingOff();
                        setIsLoading(false);
                        console.warn("ERROR:", error);
                    }                    
                };
                
                //Await payment Service with Transfer in Annual mode
                // if (selectedPayment === "bonifico") {

                //     if (servicePaymentTransferShop) {
                //         alert("Pago un Servizio con Bonifico da Loggato sullo Shop")
                //         openBankTransferModal();
                //         onTransferClick(name, `${finalServicePrice}`, response.id);
                //     }; 

                //     if (servicePaymentTransferModalInfo) {
                //         alert("Pago un Servizio con bonifico da Loggato su Modale")
                //         buyInfoModalHandler();
                //         onTransferClick(name, `${finalServicePrice}`);
                //     };

                //     if (servicePaymentTransferLogout) {
                //         alert("Pago un Servizio con Bonifico da Sloggato")
                //         openBankTransferModal();
                //         onTransferClick(name, `${finalServicePrice}`);
                //     }; 
                // };
                
                if (servicePaymentLibertyShop) {
                    setIsLoading(false);
                    openServiceLibertyPaymentModal();
                };

                if (servicePaymentLibertyModalInfo) {
                    setIsLoading(false);
                    openServiceLibertyPaymentModal();
                };

            } 
            
            catch (error: any) {
                setIsLoading(false);
                console.warn("ERROR:", error);
                
                const snackbarData: SnackbarOptions = {
                    type: 'info',
                    message: "Questo Prodotto non è acquistabile al momento, per maggiori info controlla la pagina del prodotto"
                  };
                  
                  dispatch(addSnackbar(snackbarData));                
                }
            return;

        };
        
        if (TypeOfProduct === 'COURSE' || TypeOfProduct === 'LESSON_PACK') {
                        
            if (personalAssistantCode != null) setItemToLocalStorage('Personal Assistant Code', personalAssistantCode);
            if ((coursePaymentPayPalShop || coursePaymentPayPalModalInfo) && !isPresent) {
                await paypalCoursePayment();
            };
            
            if (coursePaymentPayPalLogout || isPresent) {
                
                try {
                    loadingOn();
                    const response = await getCoursePayment(+courseID, typeOfPayment(), isUserLoggedIn, false, campaignId);
                    
                    if (isPresent && !isPastBroadcast) {
                        
                        dispatch( enableAppMain() );
                        navigate("/fallback-page/present")
                        return
                    };

                    if (isPresent && isPastBroadcast) {
                        
                        dispatch( enableAppMain() );
                        navigate("/fallback-page/present-broadcast")
                        return
                    };
                    
                    const paypalId = response.transactions[0].paymentId;
                    if (typeof paypalId !== 'string' ) return;
                    const paypalUrl = getPaypalUrl(paypalId);
                    
                    window.location.href = paypalUrl;
                    return;
                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                    // removeItemFromLocalStorage('Personal Assistant Code');
                    
                    if (error.response && error.response.data && error.response.data.message === "Requisites not satisfied") {               
                        const requisiteProducts = error.response.data.error;
                        setRequisites(requisiteProducts);
                        openRequisiteModal();
                    }

                }
            };
            
            if (coursePaymentTransferShop || coursePaymentTransferModalInfo || coursePaymentTransferLogout) {

                setIsLoading(false);
                transferCoursePayment();
            };
            
            if (coursePaymentPartialLibertyShop) {
                if (!isPresent) {
                    setIsLoading(false);
                    openPartialLibertyPaymentModal();
                };
            };
            
            if (coursePaymentPartialLibertyModalInfo) {
                if (!isPresent) {
                    setIsLoading(false);
                    openPartialLibertyPaymentModal();
                };
            };
            
            if (coursePaymentLibertyShop) {
                if (productIdHandler) productIdHandler(courseID);
                if (productPriceHandler) productPriceHandler(PriceProduct);
                setIsLoading(false);
                if (!isPresent) onLibertyClick();
            };
            
            if (coursePaymentLibertyModalInfo) {
                console.log("DENTRO");
                if (productIdHandler) productIdHandler(courseID);
                if (productPriceHandler) productPriceHandler(PriceProduct);
                setIsLoading(false);
                if (openLibertyPaymentModal) openLibertyPaymentModal();
                if (!isPresent) onLibertyClick();
            };

        };

        if (TypeOfProduct === 'THEMATIC_BROADCAST') {
                        
            
            if ((coursePaymentPayPalShop || coursePaymentPayPalModalInfo) && !isPresent) {
                await paypalThematicBroadcastPayment();
            };
            
            if (coursePaymentPayPalLogout || isPresent) {
                if (thematicBroadcastType === undefined) return "SPECTATOR"
                
                try {
                    loadingOn();
                    //add discount if on notification 
                    const response = await getThematicBroadcastPayment(courseID, thematicBroadcastType, typeOfPayment(), false, false, null);
                    
                    if (isPresent) {
                        
                        dispatch( enableAppMain() );
                        navigate("/fallback-page/present-broadcast")
                        return
                    };
                    
                    const paypalId = response.transactions[0].paymentId;
                    if (typeof paypalId !== 'string' ) return;
                    const paypalUrl = getPaypalUrl(paypalId);
                    
                    window.location.href = paypalUrl;
                    return;
                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                    // removeItemFromLocalStorage('Personal Assistant Code');
                    
                    if (error.response && error.response.data && error.response.data.message === "Requisites not satisfied") {               
                        const requisiteProducts = error.response.data.error;
                        setRequisites(requisiteProducts);
                        openRequisiteModal();
                    }

                }
            };
            
            if (coursePaymentTransferShop || coursePaymentTransferModalInfo || coursePaymentTransferLogout) {

                setIsLoading(false);
                transferCoursePayment();
            };
            
            if (coursePaymentPartialLibertyShop) {
                if (!isPresent) {
                    setIsLoading(false);
                    openPartialLibertyPaymentModal();
                };
            };
            
            if (coursePaymentPartialLibertyModalInfo) {
                if (!isPresent) {
                    setIsLoading(false);
                    openPartialLibertyPaymentModal();
                };
            };
            
            if (coursePaymentLibertyShop) {
                if (productIdHandler) productIdHandler(courseID);
                if (productPriceHandler) productPriceHandler(PriceProduct);
                setIsLoading(false);
                if (!isPresent) onLibertyClick();
            };
            
            if (coursePaymentLibertyModalInfo) {
                console.log("DENTRO");
                if (productIdHandler) productIdHandler(courseID);
                if (productPriceHandler) productPriceHandler(PriceProduct);
                setIsLoading(false);
                if (openLibertyPaymentModal) openLibertyPaymentModal();
                if (!isPresent) onLibertyClick();
            };

        };

        if (TypeOfProduct === 'AUTOMATIC_INCOME') {
            
            if ((coursePaymentPayPalShop || coursePaymentPayPalModalInfo)) {

                try {
                    loadingOn();                    
                    const response = await getAutomaticIncomePayment(courseID, typeOfPayment(), PriceProduct);
                    
                    const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
                    if (typeof paypalId !== 'string' ) return;
                    const paypalUrl = getPaypalUrl(paypalId);
                    window.location.href = paypalUrl;
                    return;
                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                }
            };

            if (coursePaymentTransferShop) {

                try {
                    loadingOn();
                    const response = await getAutomaticIncomePayment(courseID, typeOfPayment(),PriceProduct);
                    if (response.status !== 'CREATED') new Error ("Qualcosa è andato storto con la creazione dell'acquisto.");
                    // if (coursePaymentTransferModalInfo) buyInfoModalHandler();
                    if (openBankTransferModal) openBankTransferModal();
                    if (isOpenInModal && modalGetPaymentID) modalGetPaymentID(response.id);

                    const createdTransactionGross = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].gross
                    setPriceReturnFromBackend(createdTransactionGross);
                    dispatch( getUserData() );
                    loadingOff();
                    setIsLoading(false);
                    if(onTransferClick)onTransferClick(name, `${createdTransactionGross}`, response.id);


                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                }
                
            };

            if (coursePaymentLibertyShop) {
                if (productIdHandler) productIdHandler(courseID);
                setIsLoading(false);
                // openLibertyPaymentModal();
            };

            if (coursePaymentLibertyShop) {
                if (productIdHandler) productIdHandler(courseID);
                setIsLoading(false);
                // openLibertyPaymentModal();
            };
        };

        if (TypeOfProduct === 'CREDIT_PACK') {
            
            if ((coursePaymentPayPalShop || coursePaymentPayPalModalInfo)) {

                try {
                    loadingOn();
                    const response = await getLibertyPoint(courseID, typeOfPayment(), (PriceProduct / 1.22));
                    
                    const paypalId = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].paymentId;
                    if (typeof paypalId !== 'string' ) return;
                    const paypalUrl = getPaypalUrl(paypalId);
                    window.location.href = paypalUrl;
                    return;
                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                }
            };

            if (coursePaymentTransferShop) {

                try {
                    loadingOn();
                    const response = await getLibertyPoint(courseID, typeOfPayment(), (PriceProduct / 1.22));

                    if (response.status !== 'CREATED') new Error ("Qualcosa è andato storto con la creazione dell'acquisto.");
                    // if (coursePaymentTransferModalInfo) buyInfoModalHandler();
                    // if (coursePaymentTransferLogout) openBankTransferModal();
                    if (openBankTransferModal) openBankTransferModal();

                    if (isOpenInModal && modalGetPaymentID) modalGetPaymentID(response.id);
                    const createdTransactionGross = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].gross
                    setPriceReturnFromBackend(createdTransactionGross);
                    dispatch( getUserData() );
                    loadingOff();
                    setIsLoading(false);
                    if(onTransferClick)onTransferClick(name, `${createdTransactionGross}`, response.id);

                } catch (error: any) {
                    loadingOff();
                    setIsLoading(false);
                    console.warn("ERROR:", error);
                }
                
            };
        };
    };

    useEffect (()=>{
        // console.log("Liberty Point", LibertyPoint);
        
        if (TypeOfProduct === "COURSE" || TypeOfProduct === "LESSON_PACK" || TypeOfProduct === "SERVICE" || TypeOfProduct === "THEMATIC_BROADCAST") {
            if (LibertyPoint === 0  || ((LibertyPoint * 1.22) < (trialPrice))) return setTypePaymentTextButton("Euro");

            if (((LibertyPoint * 1.22) < (PriceProduct)))
            {
                setTypePaymentTextButton("PartialLiberty");
                return;
            }

            setTypePaymentTextButton("Liberty");
        }
    },[PriceProduct, TypeOfProduct, libertyPointsAvailableBalance]);

    useEffect (()=>{
        CalculateLibertyPrice();
        CalculatePartialLibertyPrice();

    },[PriceProduct, libertyPointsAvailableBalance])

    useEffect(() => {

        if (isDirectPurchase !== 'true') return;
        paymentHandler();
    }, []);
    
    const isUserLoggedIn = !!tokenID;
    const typeProdcut = (TypeOfProduct === "SERVICE");
    const isLibertyPointVisibal = (typePaymentTextButton === "Liberty") && isUserLoggedIn;
    const isLibertyPointPartialVisibal = (typePaymentTextButton === "PartialLiberty") && isUserLoggedIn;
    const isEuroVisibal = (typePaymentTextButton === "Euro") || !isUserLoggedIn;
    const isPriceSelectorVisibal = ((typePaymentTextButton !== "Liberty") || !isUserLoggedIn) && (TypeOfProduct !== 'SERVICE');
    const isTrial = (trialMonths !== 0);
    const isAnnual = (isAnnualActive);
    const isPackMouths = (moreMouth > 1);
    const isSingolMouth = (!isAnnual && !isTrial && !isPackMouths)
    const finalServicePrice = priceReturnFromBackend ? priceReturnFromBackend : (paymentPlan ? PriceProduct : 0);
    const isPresent = PriceProduct === 0 || !PriceProduct;
    const servicePaymentPayPalShop = (selectedPayment === "paypal" && isUserLoggedIn && !isOpenInModal && !isLibertyPointVisibal);
    const servicePaymentPayPalModalInfo = (selectedPayment === "paypal" && isOpenInModal && !isLibertyPointVisibal);
    const servicePaymentPayPalLogout = (selectedPayment === "paypal" && !isUserLoggedIn);
    const servicePaymentTransferShop = (isUserLoggedIn && isUserLoggedIn && !isOpenInModal && isAnnual && openBankTransferModal);
    const servicePaymentTransferModalInfo = (isOpenInModal && isAnnual && buyInfoModalHandler);
    const servicePaymentTransferLogout = (!isUserLoggedIn && !isOpenInModal && isAnnual && openBankTransferModal);
    const servicePaymentLibertyShop = (isLibertyPointVisibal && isUserLoggedIn && !isOpenInModal);
    const servicePaymentLibertyModalInfo = (isLibertyPointVisibal && isOpenInModal && isUserLoggedIn);
    // const coursePaymentPayPalShop = (selectedPayment === "paypal" && !isOpenInModal && isUserLoggedIn && (isEuroVisibal || isLibertyPointPartialVisibal));
    const coursePaymentPayPalShop = (selectedPayment === "paypal" && !isOpenInModal && isUserLoggedIn && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    // const coursePaymentPayPalModalInfo = (selectedPayment === "paypal" && isOpenInModal && (isEuroVisibal || isLibertyPointPartialVisibal));
    const coursePaymentPayPalModalInfo = (selectedPayment === "paypal" && isOpenInModal && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    const coursePaymentPayPalLogout = (selectedPayment === "paypal" && !isUserLoggedIn && isEuroVisibal);
    const coursePaymentTransferShop = (selectedPayment === "bonifico" && !isOpenInModal && isUserLoggedIn && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    const coursePaymentTransferModalInfo = (selectedPayment === "bonifico" && isOpenInModal && buyInfoModalHandler && !isLibertyPointPartialVisibal && !isLibertyPointVisibal);
    const coursePaymentPartialLibertyTransferModalInfo = (selectedPayment === "bonifico" && isOpenInModal && buyInfoModalHandler && isLibertyPointPartialVisibal);
    const coursePaymentTransferLogout = (selectedPayment === "bonifico" && !isUserLoggedIn && !isOpenInModal && openBankTransferModal);
    const coursePaymentLibertyShop = (isLibertyPointVisibal && isUserLoggedIn && !isOpenInModal && openLibertyPaymentModal);
    const coursePaymentLibertyModalInfo = (isLibertyPointVisibal && isOpenInModal && libertyInfoModalHandler);
    const coursePaymentPartialLibertyModalInfo = (isLibertyPointPartialVisibal && isOpenInModal);
    const coursePaymentPartialLibertyShop = (isLibertyPointPartialVisibal && isUserLoggedIn && !isOpenInModal);
    const LibertyPoint = ((libertyPointsAvailableBalance === undefined) || (libertyPointsAvailableBalance <0)) ? 0 : libertyPointsAvailableBalance;
    const puchaseButtonText =
        courseElement && courseElement.salesData
            ? courseElement.salesData.purchaseButtonText !== undefined &&
            courseElement.salesData.purchaseButtonText !== ""
            ? courseElement.salesData.purchaseButtonText
            : "LO VOGLIO SUBITO (!)"
            : "LO VOGLIO SUBITO (!)";
    // console.log("TRIAL VP", trialVipPoint);
    // console.log("PREZZO SERVIZIO", PriceProduct);
    
    

    const buttonText = useMemo(() => {

        if (isDisabled) return (
            <ProductPriceBox>
                <ProductCourseButton isDisabled>
                    SOLD OUT
                </ProductCourseButton>
            </ProductPriceBox>
        )
        return (
            <>
                <ProductPriceBox isDuble={isLibertyPointPartialVisibal} onClick={paymentHandler} isOnDirect={isOnThematicDirect}>
                    {customLabel && <ProductCourseButton nowrap><ButtonFirstLineText>{customLabel}</ButtonFirstLineText></ProductCourseButton>}
                    {isLibertyPage && <ProductCourseButton>€ {formattedCurrency(PriceProduct)}</ProductCourseButton>}
                    {isAutomaticIncome && <ProductCourseButton >CONFERMA L'ACQUISTO € {formattedCurrency(PriceProduct)}</ProductCourseButton>}
                    {/* {isOnPublicProductPage && <ProductCourseButton isDisabled={isDisabled}>{puchaseButtonText}</ProductCourseButton>} */}
                    {!isOnPublicProductPage && !isLibertyPage && !isAutomaticIncome && !isIntoServicesPage && !customLabel &&
                    <ProductCourseButton isDisabled={isDisabled} isOnDirect={isOnThematicDirect}>
                        {((isLibertyPointVisibal && !isPresent && !typeProdcut) || (isOnThematicDirectNot  && !isPresent && !typeProdcut)) &&
                        <ButtonFirstLineText>
                            <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)}
                        </ButtonFirstLineText>    
                        }

                        {typeProdcut && isLibertyPointVisibal &&
                            <ProductPriceText> 
                                {isTrial && 
                                    <>
                                        <ButtonFirstLineText>
                                            <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)}{" "}
                                            
                                            per {trialMonths} mesi
                                        </ButtonFirstLineText>
                                                                                
                                        <ProductPriceTimingText>
                                            poi <LibertyPointIconSVG /> {formattedCurrency(finalServicePrice / 1.22)} al mese
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isPackMouths && !isAnnual &&
                                    <>
                                        <ButtonFirstLineText>
                                            <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)}
                                        </ButtonFirstLineText>

                                        <ProductPriceTimingText>
                                            ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isPackMouths && isAnnual && !isDisabled &&
                                    <>
                                        <ButtonFirstLineText>
                                            <LibertyPointIconSVG /> {formattedCurrency(finalServicePrice*12)} all'anno
                                        </ButtonFirstLineText>

                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isAnnual && !isPackMouths && !isDisabled &&
                                    <>
                                        € {formattedCurrency(finalServicePrice*12)} all'anno
                                        <br />
                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} al mese
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isSingolMouth &&
                                    <>
                                        <ButtonFirstLineText>
                                            <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)}
                                        </ButtonFirstLineText>

                                        <ProductPriceTimingText>
                                            al mese
                                        </ProductPriceTimingText>
                                    </>
                                }
                            </ProductPriceText>}   

                        {isLibertyPointPartialVisibal && !typeProdcut && !isPresent && !isOnThematicDirectNot &&
                            <ProductPriceText>
                                <ButtonFirstLineText>
                                    <LibertyPointIconSVG />{formattedCurrency(LibertyPoint)}
                                </ButtonFirstLineText>

                                <ProductPriceTimingText>
                                    + € {formattedCurrency(partialLibertyPrice)}
                                </ProductPriceTimingText>
                            </ProductPriceText>
                        }

                        {typeProdcut && !isLibertyPointVisibal &&
                            <ProductPriceText> 
                                {isTrial && 
                                    <>
                                        € {formattedCurrency(trialPrice)} {" "}
                                        per {trialMonths} {trialMonths > 1 ? "mesi" : "mese"}

                                        <ProductPriceTimingText>
                                            poi {formattedCurrency(finalServicePrice)} al mese
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isPackMouths && !isAnnual &&
                                    <>
                                        € {formattedCurrency(finalServicePrice)}
                                        <br />
                                        <ProductPriceTimingText>
                                            ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isPackMouths && isAnnual && !isDisabled &&
                                    <>
                                        € {formattedCurrency(finalServicePrice*12)} all'anno
                                        <br />
                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isAnnual && !isPackMouths && !isDisabled &&
                                    <>
                                        € {formattedCurrency(finalServicePrice*12)} all'anno
                                        <br />
                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} al mese
                                        </ProductPriceTimingText>
                                    </>
                                }

                                {isSingolMouth &&
                                    <>
                                        € {formattedCurrency(finalServicePrice)}
                                        <br />
                                        <ProductPriceTimingText>
                                            al mese
                                        </ProductPriceTimingText>
                                    </>
                                }
                            </ProductPriceText>}   

                            {isEuroVisibal && !typeProdcut && !isPresent && !isOnThematicDirectNot &&`€ ${formattedCurrency(PriceProduct)}`}

                            {isPresent && !isPresentTextHidden &&
                                <PresentText>
                                    <PresentIconSVG /> IN REGALO
                                </PresentText>
                            }                       

                    </ProductCourseButton>}
                    {(isIntoServicesPage || isOnPublicProductPage) && !customLabel &&
                    <ProductCourseButton isDisabled={isDisabled} isIntoServicesPage={isIntoServicesPage || isOnPublicProductPage}>
                        {isDisabled && 'SOLD OUT'}

                        {isIntoServicesPage && `ATTIVA ORA`}

                        {isSuperViewProductPage && `Voglio la Super Vista`}

                        {isOnPublicProductPage && !isSuperViewProductPage && `${puchaseButtonText} `}

                        {isLibertyPointVisibal && !isPresent && !typeProdcut && !isDisabled &&
                            <ButtonFirstLineText>
                                <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)}
                            </ButtonFirstLineText>    
                        }

                        {typeProdcut && isLibertyPointVisibal &&
                            <ProductPriceText isIntoServicesPage={isIntoServicesPage || isOnPublicProductPage} > 
                                {isTrial && 
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        <LibertyPointIconSVG /> 
                                    
                                        <ProductPriceTimingText>
                                            {formattedCurrency(libertyPrice)}{" "}
                                            per {trialMonths} {trialMonths > 1 ? ' mesi ' : ' mese '} poi
                                        </ProductPriceTimingText>
                                    
                                        <LibertyPointIconSVG /> 

                                        <ProductPriceTimingText>
                                            {formattedCurrency(PriceProduct / 1.22)}{" "}
                                            mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isPackMouths && !isAnnual &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        <LibertyPointIconSVG /> 

                                        <ProductPriceTimingText>
                                            {formattedCurrency(libertyPrice)}{" "}
                                            ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isPackMouths && isAnnual && !isDisabled &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                         <LibertyPointIconSVG /> 

                                        <ProductPriceTimingText>
                                            {formattedCurrency(finalServicePrice*12)}{" "} all'anno
                                            anzichè {" "}{formattedCurrency(finalServicePrice)}{" "} ogni {" "}{moreMouth} {" "}mesi
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isAnnual && !isPackMouths && !isDisabled &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        <ProductPriceTimingText>
                                            €{" "} {formattedCurrency(finalServicePrice*12)} {" "}all'anno
                                            anzichè {" "}{formattedCurrency(finalServicePrice)}{" "} mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isSingolMouth &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        <LibertyPointIconSVG />

                                        <ProductPriceTimingText>
                                            {formattedCurrency(libertyPrice)} {" "}
                                            mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }
                            </ProductPriceText>}   

                        {isLibertyPointPartialVisibal && !typeProdcut && !isPresent && !isOnThematicDirectNot &&
                            <ProductPriceText isIntoServicesPage={isIntoServicesPage || isOnPublicProductPage} >
                                <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                    <LibertyPointIconSVG /> {formattedCurrency(LibertyPoint)}
                                
                                    <ProductPriceTimingText>
                                        + € {formattedCurrency(partialLibertyPrice)}
                                    </ProductPriceTimingText>
                                </ButtonFirstLineText>
                            </ProductPriceText>
                        }

                        {typeProdcut && !isLibertyPointVisibal &&
                            <ProductPriceText isIntoServicesPage={isIntoServicesPage || isOnPublicProductPage} > 
                                {isTrial && 
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        € {formattedCurrency(trialPrice)}

                                        <ProductPriceTimingText>
                                            per {trialMonths} {trialMonths > 1 ? ' mesi ' : ' mese '} poi €{formattedCurrency(finalServicePrice)} mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isPackMouths && !isAnnual &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        € {formattedCurrency(finalServicePrice)}

                                        <ProductPriceTimingText>
                                            ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isPackMouths && isAnnual && !isDisabled &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        € {formattedCurrency(finalServicePrice*12)} all'anno

                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} ogni {moreMouth} mesi
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isAnnual && !isPackMouths && !isDisabled &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        € {formattedCurrency(finalServicePrice*12)} all'anno

                                        <ProductPriceTimingText>
                                            anzichè {formattedCurrency(finalServicePrice)} mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }

                                {isSingolMouth &&
                                    <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage}>
                                        € {formattedCurrency(finalServicePrice)}

                                        <ProductPriceTimingText>
                                            mese
                                        </ProductPriceTimingText>
                                    </ButtonFirstLineText>
                                }
                            </ProductPriceText>}   

                            {isEuroVisibal && !typeProdcut && !isPresent && (
                                <ButtonFirstLineText isOnSecondLine={isIntoServicesPage || isOnPublicProductPage} >
                                    € {formattedCurrency(PriceProduct)}
                                </ButtonFirstLineText>
                            )}

                            {isPresent && !isPresentTextHidden &&
                                <PresentText>
                                    <PresentIconSVG /> IN REGALO
                                </PresentText>
                            }                      

                    </ProductCourseButton>}
                </ProductPriceBox>

                <ProductAlternativePaymentGlobalBox>
                    <ProductAlternativePaymentBox>

                    {isEuroVisibal && !typeProdcut && !isPresent &&
                        <ProductAlternativePriceBox>
                            € {formattedCurrency(PriceProduct)} 
                            
                            {selectedPayment === 'paypal' && <PaypalIconSVG />}

                            {selectedPayment === 'bonifico' && <BankIconSVG />}
                        </ProductAlternativePriceBox>}
                    
                    {isLibertyPointVisibal && !typeProdcut && !isPresent && isOnPublicProductPage && !isDisabled &&
                        <ProductAlternativePriceBox>
                            anzichè € {formattedCurrency(PriceProduct)}
                        </ProductAlternativePriceBox>}
                                                
                    {isLibertyPointVisibal && !typeProdcut && !isPresent && !isOnPublicProductPage && !isDisabled &&
                        <ProductAlternativePriceBox>
                            anzichè € {formattedCurrency(PriceProduct)}
                        </ProductAlternativePriceBox>}
                    

                    {isLibertyPointPartialVisibal && !typeProdcut && !isPresent && isOnPublicProductPage && !isDisabled &&
                        <ProductAlternativePriceBox>
                            anzichè € {formattedCurrency(PriceProduct)} 
                            
                            {selectedPayment === 'paypal' && <PaypalIconSVG />}

                            {selectedPayment === 'bonifico' && <BankIconSVG />}
                        </ProductAlternativePriceBox>}

                    {isLibertyPointPartialVisibal && !typeProdcut && !isPresent && !isOnPublicProductPage && !isDisabled &&
                        <ProductAlternativePriceBox>
                            anzichè € {formattedCurrency(PriceProduct)} 
                            
                            {selectedPayment === 'paypal' && <PaypalIconSVG />}

                            {selectedPayment === 'bonifico' && <BankIconSVG />}
                        </ProductAlternativePriceBox>}

                    {isPresent &&
                        <ProductAlternativePriceBox>
                            in regalo

                            <PaypalIconSVG />
                        </ProductAlternativePriceBox>}

                    {/* SERVICE STYLE     */}

                    {!isIntoServicesPage && (
                        <>
                            {isLibertyPointVisibal && typeProdcut && !isOnPublicProductPage && !isTrial && !isDisabled &&
                                <ProductAlternativePriceBox>
                                    anzichè € {formattedCurrency(finalServicePrice)}
                                </ProductAlternativePriceBox>}
        
                            {isLibertyPointVisibal && typeProdcut && isOnPublicProductPage && !isTrial && !isDisabled &&
                                <ProductAlternativePriceBox>
                                    anzichè € {formattedCurrency(finalServicePrice)}
                                </ProductAlternativePriceBox>}
        
                            {isTrial && typeProdcut && !isPresent && isLibertyPointVisibal && !isDisabled &&(
                                <TrialWithLibertyPointsText>
                                    <ProductAlternativePriceBox>
                                        anzichè € {formattedCurrency(trialPrice)} {" "} 
                                        per {trialMonths} {trialMonths === 1 ? "mese" : "mesi"}
                                    </ProductAlternativePriceBox>

                                    <ProductAlternativePriceBox>
                                        poi € {formattedCurrency(finalServicePrice)} {" "} mese
                                    </ProductAlternativePriceBox>
                                </TrialWithLibertyPointsText>)
                            }

                            {isTrial && typeProdcut && !isPresent && !isLibertyPointVisibal &&
                                <TrialWithLibertyPointsText>
                                    <ProductAlternativePriceBox>
                                            € {formattedCurrency(trialPrice)} {" "} 
                                        {/* <PaypalIconSVG /> */}
                                        poi € {formattedCurrency(finalServicePrice)} mese
                                        <PaypalIconSVG />
                                    </ProductAlternativePriceBox>

                                    <ProductAlternativePriceBox>

                                    </ProductAlternativePriceBox>
                                </TrialWithLibertyPointsText>
                            }

                            {!isTrial && typeProdcut && !isAnnual && !isPresent && !isLibertyPointVisibal &&
                                <ProductAlternativePriceBox>
                                    € {formattedCurrency(finalServicePrice)} 
                                    
                                    {selectedPayment === 'paypal' && <PaypalIconSVG />}
        
                                    {selectedPayment === 'bonifico' && <BankIconSVG />}
                                </ProductAlternativePriceBox>}
        
                            {isAnnual && typeProdcut && !isPresent && !isLibertyPointVisibal &&
                                <ProductAlternativePriceBox>
                                    € {formattedCurrency(finalServicePrice*12)} 
                                    
                                    {selectedPayment === 'paypal' && <PaypalIconSVG />}
        
                                    {selectedPayment === 'bonifico' && <BankIconSVG />}
                                </ProductAlternativePriceBox>}
                        </>)
                    }
                    {isIntoServicesPage && (
                        <>
                            {isLibertyPointVisibal && typeProdcut && !isOnPublicProductPage &&  !isTrial && !isDisabled &&
                                <ProductAlternativePriceBox>
                                    anzichè € {formattedCurrency(finalServicePrice)}
                                </ProductAlternativePriceBox>}
        
                            {isLibertyPointVisibal && typeProdcut && isOnPublicProductPage && !isDisabled &&
                                <ProductAlternativePriceBox>
                                    <LibertyPointIconSVG /> {formattedCurrency(libertyPrice)} anzichè € {formattedCurrency(finalServicePrice)}
                                </ProductAlternativePriceBox>}
        
                            {isTrial && typeProdcut && !isPresent && isLibertyPointVisibal && !isDisabled &&(
                                <>
                                    <ProductAlternativePriceBox>
                                        anzichè € {formattedCurrency(trialPrice)} per {trialMonths} {trialMonths > 1 ? " mesi " : " mese "}
                                    </ProductAlternativePriceBox>
                            
                                    <ProductAlternativePriceBox>
                                        poi € {formattedCurrency(finalServicePrice)} mese
                                    </ProductAlternativePriceBox>
                                </>)
                            }
        
                            {isTrial && typeProdcut && !isPresent && !isLibertyPointVisibal &&
                                <ProductAlternativePriceBox>
                                    € {formattedCurrency(trialPrice)} poi € {formattedCurrency(finalServicePrice)} mese
                                    <PaypalIconSVG />
                                </ProductAlternativePriceBox>}
        
                            {!isTrial && typeProdcut && !isAnnual && !isPresent && !isLibertyPointVisibal &&
                                <ProductAlternativePriceBox>
                                    € {formattedCurrency(finalServicePrice)} 
                                    
                                    {selectedPayment === 'paypal' && <PaypalIconSVG />}
        
                                    {selectedPayment === 'bonifico' && <BankIconSVG />}
                                </ProductAlternativePriceBox>}
        
                            {isAnnual && typeProdcut && !isPresent && !isLibertyPointVisibal &&
                                <ProductAlternativePriceBox>
                                    € {formattedCurrency(finalServicePrice*12)} 
                                    
                                    {selectedPayment === 'paypal' && <PaypalIconSVG />}
        
                                    {selectedPayment === 'bonifico' && <BankIconSVG />}
                                </ProductAlternativePriceBox>}
                        </>)
                    }
                    {isPriceSelectorVisibal && !isPresent &&  !isOnThematicDirectNot && TypeOfProduct !== 'THEMATIC_BROADCAST' &&
                        <ProductPriceSelectorBox isActive={isSelectorOpenActive} onClick={onClickSelectorOpenHandler}>
                            {!isSelectorOpenActive && <PriceSelectorIconOpenSVG />}

                            {isSelectorOpenActive && <PriceSelectorIconClosedSVG />}
                        </ProductPriceSelectorBox>}

                    </ProductAlternativePaymentBox>

                        {isSelectorOpenActive &&
                            <PaymentMenu 
                                prodcut={typeProdcut} 
                                courseElement= {courseElement} 
                                handlePaymentMethod={handlePaymentMethod} 
                                selectedPayment={selectedPayment}
                                onClickAnnualHandler={onClickAnnualHandler}
                                isAnnualActive={isAnnualActive}
                                TypeOfProduct={TypeOfProduct}
                                onClose={closeSelectorHandler}
                            />}
                    </ProductAlternativePaymentGlobalBox>

                    {TypeOfProduct !== 'THEMATIC_BROADCAST' && isPartialLibertyPaymentModalVisible && ( 
                        <PartialLibertyPaymentModal
                            onCloseButtonModal={closePartialLibertyPaymentModal}
                            courseID={courseID!}
                            price={partialLibertyPrice}
                            LibertyPoint={LibertyPoint}
                            typeOfPayment={typeOfPayment}
                            paypalCoursePayment={paypalCoursePayment}
                            transferCoursePayment={transferCoursePayment}
                            selectedPayment={selectedPayment}
                        />
                    )}

                    {TypeOfProduct === 'THEMATIC_BROADCAST' && isPartialLibertyPaymentModalVisible && !isOnThematicDirectNot && (
                        <PartialLibertyPaymentModal
                            onCloseButtonModal={closePartialLibertyPaymentModal}
                            courseID={courseID!}
                            price={partialLibertyPrice}
                            LibertyPoint={LibertyPoint}
                            typeOfPayment={typeOfPayment}
                            paypalThematicBroadcastPayment={paypalThematicBroadcastPayment}
                            transferCoursePayment={transferCoursePayment}
                            selectedPayment={selectedPayment}
                            productType='ThematicBroadcast'
                            thematicCallType={thematicBroadcastType}
                        />
                    )}

                    {isServiceLibertyPaymentModalVisible && vipPoints !== undefined  &&(
                        <ServiceLibertyModal
                            onCloseButtonModal={closeServiceLibertyPaymentModal}
                            libertyPrice={libertyPrice}
                            serviceID={courseID}
                            courseElement={courseElement}
                            isAnnual={isAnnual}
                            vipPoints={vipPoints}
                            trialVipPoint={trialVipPoint !== undefined ? trialVipPoint : 0}
                            isServicePageRequest
                            refreshFunction={refreshFunction}
                        />
                    )}
        
                    {isProductRequisitesModalVisible &&
                        <ProductRequisitesModal
                            onCloseButtonModal={closeProductRequisitesModal}
                            requisites={requisites}
                            requisitesLogicalOperator={requisitesLogicalOperator}
                        />
                    }
                </>
        )
    }, [libertyPointsAvailableBalance, paymentHandler]);

    if (isDirectPurchase === 'true') return (
        <LoadingMask 
            isCenter
            size='page'
            paddingTop='200px'
        />
    )

    if (isLoading) return (
        <LoadingMask 
            isCenter
            size='inline'
        />
    );

    return (
        <ButtonBox isPresentStyle={isPresent} minWidth={minWidth} maxWidth={maxWidth}>
            {buttonText}
        </ButtonBox>
    );
}; 

export default ButtonShop;