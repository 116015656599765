const dayOfWeekAbbrName: any = {

    0: "Dom.",
    1: "Lun.",
    2: "Mart.",
    3: "Merc.",
    4: "Giov.",
    5: "Ven.",
    6: "Sab."
};

export const convertAndAbbreviateDayOfWeekByIndex = (index?: number | undefined) => {

    if ( (index === undefined) || (typeof index !== 'number') ) {
        return "";
    }

    return dayOfWeekAbbrName[index];
};

export const scrollPage = (elementId: string) => {
    
    const target = document.querySelector(`#${elementId}`);
    if (!target || target === undefined) return;
    target.scrollIntoView({behavior: 'smooth'});
};

export const roundedPoints = (points: number) => {
    return Math.floor(points);
};
