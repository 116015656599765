import { FC, useCallback, useEffect, useState, useRef, ChangeEvent} from "react";

//types
import { DoubleSliderProps } from "./DoubleSlider.component.d";

//style
import { DoubleSliderContainer, Input, Slider, SliderTrack, SliderRange } from "./style/DoubleSlider.style";  
  
const DoubleSlider: FC<DoubleSliderProps> = ({min, max, startingMin, startingMax, onMinChange, onMaxChange}) => {

    const [minVal, setMinVal] = useState<number>(startingMin);
    const [maxVal, setMaxVal] = useState<number>(startingMax);
    const minValRef = useRef<HTMLInputElement>(null);
    const maxValRef = useRef<HTMLInputElement>(null);
    const range = useRef<HTMLDivElement>(null);
    const track = useRef<HTMLDivElement>(null);
  
    // Convert to Percage
    const getPerc = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );
  
    // Change minVal/maxVal when the starting value changes
    useEffect(() => {
        setMinVal(startingMin);
    }, [startingMin]);
    useEffect(() => {
        setMaxVal(startingMax);
    }, [startingMax]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPerc = getPerc(minVal);
            const maxPerc = getPerc(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number
            if (range.current) {
                range.current.style.left = `${minPerc}%`;
                range.current.style.width = `${maxPerc - minPerc}%`;
            }
        }
    }, [minVal, getPerc]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPerc = getPerc(+minValRef.current.value);
            const maxPerc = getPerc(maxVal);
            if (range.current) {
                range.current.style.width = `${maxPerc - minPerc}%`;
            }
        }
    }, [maxVal, getPerc]);
  
    // Handle slider values changes
    useEffect(() => {
        onMinChange(minVal);
    }, [minVal]);
    useEffect(() => {
        onMaxChange(maxVal);
    }, [maxVal]);


    // Handle click on the slider
    const mouseDownHandler = (event:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const minPerc = getPerc(minVal)/100;
        const maxPerc = getPerc(maxVal)/100;
        const r = event.currentTarget.getBoundingClientRect();
        const clickPerc = (event.clientX-r.x) / r.width;
        if(Math.abs(minPerc-clickPerc) < Math.abs(maxPerc-clickPerc)) setMinVal(Math.round(clickPerc*(max-min)));
        else setMaxVal(Math.round(clickPerc*(max-min)));
    }

    return (
        <DoubleSliderContainer onMouseDown={mouseDownHandler}>
            <Input ref={minValRef} type="range"
                min={min} max={max} value={minVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.min(+event.target.value, maxVal - 1);
                    setMinVal(value);
                    event.target.value = value.toString();
                }}
                zIndex={3}
            />
            <Input ref={maxValRef} type="range"
                min={min} max={max} value={maxVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.max(+event.target.value, minVal + 1);
                    setMaxVal(value);
                    event.target.value = value.toString();
                }}
                zIndex={4}
            />
            <Slider>
                <SliderTrack/>
                <SliderRange ref={range}/>
            </Slider>
        </DoubleSliderContainer>
    );
};
  
export default DoubleSlider;
  