//types
import { MenuFilterProps } from './index.d';

//assets
import { ReactComponent as FreerisckIconSVG } from './assets/freerisckIcon.svg';
import { ReactComponent as GrowingIconSVG } from './assets/growingIcon.svg';
import { ReactComponent as CryptoIconSVG } from './assets/cryptoIcon.svg';
import { ReactComponent as ActivityIconSVG } from './assets/activityIcon.svg';
import { ReactComponent as CloseSubfilterIconSVG } from './assets/closeSubfilterIcon.svg';
import { ReactComponent as PresentIconSVG } from './assets/presentIcon.svg';
import { ReactComponent as BonusIconSVG } from './assets/bonusIcon.svg';
import { ReactComponent as ColpoIconSVG } from './assets/colpoIcon.svg';
import { ReactComponent as ComplementIconSVG } from './assets/complementIcon.svg';
import { ReactComponent as MindsetIconSVG } from './assets/mindsetIcon.svg';
import { ReactComponent as SuperpowerIconSVG } from './assets/superpowerIcon.svg';
import { ReactComponent as ProblemIconSVG } from './assets/problemIcon.svg';
import { ReactComponent as WellnessIconSVG } from './assets/wellnessIcon.svg';
import { ReactComponent as AutomaticIconSVG } from './assets/automaticIcon.svg';
import { ReactComponent as StrategiesIconSVG } from './assets/strategiesIcon.svg';
import { ReactComponent as BaseIconSVG } from './assets/baseIcon.svg';
import { ReactComponent as ForexIconSVG } from './assets/forexIcon.svg';
import { ReactComponent as PokerIconSVG } from './assets/pokerIcon.svg';
import { ReactComponent as TipsterIconSVG } from './assets/tipsterIcon.svg';
import { ReactComponent as FilterOpenIconSVG } from './assets/filterOpenIcon.svg';


export const MENU_FILTER: Array<MenuFilterProps> = [
    {
        text: 'Tutti i Prodotti',
        icon: <></>,
        subCategory: []
    },
    {
        text: 'Senza Rischio',
        icon: <FreerisckIconSVG />,
        subCategory: [
            {
                icon: <PresentIconSVG />,
                text: 'Regali'
            },
            {
                icon: <BonusIconSVG />,
                text: 'Bonus'
            },
            {
                icon: <ColpoIconSVG />,
                text: 'Colpo Sicuro'
            },
            // {
            //     icon: <ComplementIconSVG />,
            //     text: 'Complementi'
            // }
        ],
    },
    {
        text: 'Crescita Personale',
        icon: <GrowingIconSVG />,
        subCategory: [
        {
                icon: <MindsetIconSVG />,
                text: 'Mindset'
            },
        {
                icon: <SuperpowerIconSVG />,
                text: 'Super Poteri'
            },
        {
                icon: <ProblemIconSVG />,
                text: 'Problem Solving'
            },
        {
                icon: <WellnessIconSVG />,
                text: 'Benessere'
            },
        ]
    },
    {
        text: 'Crypto',
        icon: <CryptoIconSVG />,
        subCategory: [
        {
                icon: <AutomaticIconSVG />,
                text: 'Entrate Guidate'
            },
        {
                icon: <StrategiesIconSVG />,
                text: 'Strategie'
            },
        {
                icon: <BaseIconSVG />,
                text: 'Le Basi'
            },
        ]
    },
    {
        text: 'Attività',
        icon: <ActivityIconSVG />,
        subCategory: [
            {
                icon: <ComplementIconSVG />,
                text: '3Ding Sportivo'
            },
            // {
            //     icon: <PokerIconSVG />,
            //     text: 'Poker'
            // },
            {
                icon: <TipsterIconSVG />,
                text: 'Profittazione'
            },
        ]
        }
    ];
