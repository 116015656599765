import { FC, FormEvent, useState } from 'react';

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// components
import TextInput from 'components/ui/TextInput';
import ModifyButtons from '../../ModifyButtons';

//utils
import { validateEmailInput } from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { awsChangeEmail } from './services/EmailForm.services';

// types
import { ChangeEmailPayload } from './services/EmailForm.services.d';

// style
import { EmailFormBox } from './style/EmailForm.style';


/**
 * Email form
 * 
 * @author Alessio Grassi and Frank Berni
 * 
 * @param onCancelButtonClick - handler for the closure of the edit mode
 * 
 * @returns JSX
 */


const EmailForm: FC<{ onCancelButtonClick: () => any }> = ({ onCancelButtonClick }) => {

    const [newEmailInput, setNewEmailInput] = useState<string>("");
    const [newEmailInputErrorMessage, setNewEmailInputErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();

    const validateNewEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setNewEmailInputErrorMessage(error);

        return error;
    };

    const newEmailChangeHandler = (newEmail: string) => {

        validateNewEmailHandler(newEmail);
        setNewEmailInput(newEmail);
    };

    const userEmailFormSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        if (isLoading) return;
        const newEmailError = validateNewEmailHandler(newEmailInput);
        if (newEmailError) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {

            const changeEmailPayload: ChangeEmailPayload = { newEmail: newEmailInput };
            
            const newEmailResponse = await awsChangeEmail(changeEmailPayload);
            if (!isMounted()) return;
            
            if (newEmailResponse === undefined || !newEmailResponse) {
                throw new Error();
            }
            
            snackbarData.type = 'success';
            snackbarData.message = "Ti abbiamo inviato un’email con il codice di verifica.";
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);

        } catch (error: any) {
            
            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            setIsLoading(false);
        }
    };
 
    return (
        <EmailFormBox onSubmit={userEmailFormSubmitHandler}>
            <TextInput
                label='Nuova Email'
                value={newEmailInput}
                errorMsg={newEmailInputErrorMessage}
                onChange={newEmailChangeHandler}
                onValidate={() => validateNewEmailHandler(newEmailInput)}
            />

            <ModifyButtons
                isModifying
                isLoading={isLoading}
                onCancelButtonClick={onCancelButtonClick}
            />
        </EmailFormBox>
    );
};

export default EmailForm;
