export const getLessonTabText = (lesson: any) => {

    if (lesson.description.toLowerCase().includes('consulenza')) {

        if (lesson.description.toLowerCase().includes('dal vivo')) return "Dal vivo";
        return "Consulenza";
    }

    if (lesson.description.toLowerCase().includes('organizzazione')) return "Organizz.";

    if (lesson.productLessonPack.lessonsNumber > 1) return `${lesson.productLessonPack.lessonsNumber} Lezioni`;

    return "1 Lezione";
};
