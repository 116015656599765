import { FC, useState, useEffect } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// routing
import { Outlet, useNavigate } from 'react-router-dom';

//utils

// assets
import { ReactComponent as Number38 } from './assets/38.svg';
import { ReactComponent as Number2 } from './assets/2.svg';
import { ReactComponent as CardMiniature } from './assets/card-miniature.svg';

//components

// style
import {
	Page,
	PageMobile,
	PageTitle,
	ToolsList,
	ToolsListItem,
	ToolsListItemLeft,
	ToolsListItemRight,
} from './style/VdrTools.style';
import VdrNumerology from 'pages/VdrNumerology/VdrNumerology.component';
import VdrCardsOfPower from 'pages/VdrCardsOfPower/VdrCardsOfPower.component';

const VdrTools: FC<{}> = () => {
	const { isAppMobile } = useAppSelector((state) => state.ui);

	const path = window.location.pathname;

	const navigate = useNavigate();
	const numerologyURL = path.includes('reservedArea') ? '/reservedAread/strumenti/numerologia-caldea' : '/public/strumenti/numerologia-caldea';
	const cardsURL = path.includes('reservedArea') ? '/reservedArea/strumenti/carte-del-potere' : '/public/strumenti/carte-del-potere';
	const numText = localStorage.getItem('numerologyText');
	const cardsQuestion = localStorage.getItem('vdrQuestion');

	if (isAppMobile && numText) { navigate(numerologyURL) } else if (isAppMobile && cardsQuestion) navigate(cardsURL);

	return (
		<>
			{isAppMobile && (
				<PageMobile>
					<PageTitle>Strumenti</PageTitle>
					<ToolsList>
						<ToolsListItem
							onClick={() => navigate(numerologyURL)}
						>
							<ToolsListItemLeft>
								<Number38 />
								<Number2 />
							</ToolsListItemLeft>
							<ToolsListItemRight>
								Numerologia Caldea
							</ToolsListItemRight>
						</ToolsListItem>
						<ToolsListItem
							onClick={() => navigate(cardsURL)}
						>
							<ToolsListItemLeft>
								<CardMiniature />
							</ToolsListItemLeft>
							<ToolsListItemRight>
								Carte del Potere
							</ToolsListItemRight>
						</ToolsListItem>
					</ToolsList>
				</PageMobile>
			)}
			{!isAppMobile && (
				<Page>
					<VdrNumerology />
					<VdrCardsOfPower />
				</Page>
			)}
		</>
	);
};

export default VdrTools;
