import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { 
    YellowText, 
    Page, 
    TextBox, 
    TextGreen
} from "./style/ThanksAndLogin.style";

const ThanksAndLogin = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const timeout = setTimeout(() => {
            navigate('/login');
        }, 5000);
      return () => { clearTimeout(timeout); }
    }, [navigate])
    
    return (
        <Page>
            <TextBox>
                <TextGreen>COMPLIMENTI (!) Hai completato l'acquisto.</TextGreen>

                <YellowText>A breve verrai reindirizzato al login per effettuare l'accesso o completare la registrazione al nostro sito.</YellowText>
            </TextBox>
        </Page>
    )
};

export default ThanksAndLogin;