import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import { Form } from '../../style/FormEditAccount.style';
import { EmailBoxProps, EmailFormProps } from './EditEmailBox.style.d';

export const EmailBox = styled.div<EmailBoxProps>`

    display: flex;
    align-items: flex-end;
    justify-content: center;

    flex-direction: column;
    row-gap: 20px;
    column-gap: 10px;

    width: 100%;

    @media ${devices.tablet} {

        row-gap: 30px;
    }

    ${({isUserModifyingInput}) => !isUserModifyingInput && (

        css`
            @media ${devices.desktop} {
                flex-direction: row;
            }
        `
    )}
`;

export const EmailForm = styled(Form)<EmailFormProps>`

    margin: 0;

    justify-content: space-between;
`;
