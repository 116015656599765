import { FC, useState, FormEvent } from 'react';

// routing
import { Link, useNavigate } from 'react-router-dom';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { saveUserLoginInfo } from 'redux/actions/actions-user';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useIsMounted } from 'usehooks-ts';

// components
import TextInput from 'components/ui/TextInput';

// utils
import { getCurrentAuthUser } from 'services/user.services';
import { updateFacebookDataOnDB } from 'pages/LibertyDashboardPage/ShareModal/services/ShareModal.services';
import { validateEmailInput, validatePasswordInput } from 'utils/inputValidation';
import { getAwsAmplifyErrorMessage } from 'utils/awsErrorMessages';
import { awsSignIn, awsRememberDevice, awsForgetDevice } from './services/LoginForm.services';
import { SignInPayload } from './services/LoginForm.services.d';

// style
import {
    Form,
    UserInputsBox,
    LoginButton,
    RememberUserAndRecoverPasswordRow,
    ForgotPasswordLink
} from './style/LoginForm.style';


/**
 * Login form
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX
 */


const LoginForm: FC<{}> = () => {

    const [emailInput, setEmailInput] = useState<string>("");
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
    const [passwordInput, setPasswordInput] = useState<string>("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>(undefined);
    const [isRememberMeChecked, setIsRememberMeChecked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMounted = useIsMounted();

    const checkUserConnectedAccount = (userData: any, accountType: string) => {
        
        if (!userData || userData === undefined) return false;
        if (!userData.federateds || userData.federateds === undefined) return false;
        
        return userData.federateds.some((federatedAccount: any) => federatedAccount.federatedName === accountType);
    };
    
    const validateEmailHandler = (newEmail: string) => {

        const error = validateEmailInput(newEmail);
        setEmailErrorMessage(error);

        return error;
    };

    const emailInputChangeHandler = (newEmail: string) => {

        validateEmailHandler(newEmail);
        setEmailInput(newEmail);
    };

    const validatePasswordHandler = (newPassword: string) => {

        const error = validatePasswordInput(newPassword);
        setPasswordErrorMessage(error);

        return error;
    };

    const passwordInputChangeHandler = (newPassword: string) => {

        validatePasswordHandler(newPassword);
        setPasswordInput(newPassword);
    };

    /* optional */
    const cleanFormInputs = () => {

        setEmailInput("");
        setEmailErrorMessage(undefined);
        setPasswordInput("");
        setPasswordErrorMessage(undefined);
        setIsRememberMeChecked(false);
    };

    const displayRememberDeviceSnackBar = () => {

        const snackbarData: SnackbarOptions = {};

        snackbarData.type = 'warning';
        snackbarData.message = "Il servizio di ricorda dispositivo non è disponibile";
        dispatch( addSnackbar(snackbarData) );
    };

    const rememberDevice = async () => {

        try {

            if (!isRememberMeChecked) return;
            await awsRememberDevice();
            
        } catch (error: any) {
            displayRememberDeviceSnackBar();
        }
    };

    const forgetDevice = async () => {

        try {
            
            if (isRememberMeChecked) return;
            await awsForgetDevice();

        } catch (error: any) {
            displayRememberDeviceSnackBar();
        }
    };

    const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        if (isLoading) return;
        const emailError = validateEmailHandler(emailInput);
        if (emailError) return;
        const passwordError = validatePasswordHandler(passwordInput);
        if (passwordError) return;

        const snackbarData: SnackbarOptions = {};

        setIsLoading(true);

        try {

            const userPayload: SignInPayload = {
                
                username: emailInput,
                password: passwordInput
            };

            console.log("Going to login...");
            const signInResponse = await awsSignIn(userPayload);
            console.log("Got: ",signInResponse);

            if (signInResponse === undefined || !signInResponse) {
                throw new Error();
            }
            // await rememberDevice();
            // await forgetDevice();
            dispatch( saveUserLoginInfo() );     
                    
            const userData = await getCurrentAuthUser();
            console.log("userData just checked in LOGIN: ",userData);
            if (checkUserConnectedAccount(userData, 'Facebook')) await updateFacebookDataOnDB();
            console.log("isMounted? ",isMounted());
            if ( !isMounted() ) return;
            cleanFormInputs();
            setIsLoading(false);
            navigate('/reservedArea/home-page', { replace: true });
            
        } catch (error: any) {

            snackbarData.type = 'error';
            snackbarData.message = getAwsAmplifyErrorMessage(error.code);
            dispatch( addSnackbar(snackbarData) );
            if ( !isMounted() ) return;
            setIsLoading(false);
        }
    };

    return (
        <Form onSubmit={formSubmitHandler}>
            <UserInputsBox>
                <TextInput
                    label="Email:"
                    type="text"
                    flavor="filled"
                    value={emailInput}
                    errorMsg={emailErrorMessage}
                    onChange={emailInputChangeHandler}
                    onValidate={() => validateEmailHandler(emailInput)}
                />

                <TextInput
                    label="Password:"
                    type="password"
                    flavor="filled"
                    value={passwordInput}
                    errorMsg={passwordErrorMessage}
                    onChange={passwordInputChangeHandler}
                    onValidate={() => validatePasswordHandler(passwordInput)}
                />
            </UserInputsBox>

            <LoginButton
                type="submit"
                value="login"
                disabled={isLoading}
            >
                Log In
            </LoginButton>

            <RememberUserAndRecoverPasswordRow>
                <ForgotPasswordLink>
                    <Link to="/login/password-recovery">
                        Password dimenticata ?
                    </Link>
                </ForgotPasswordLink>
            </RememberUserAndRecoverPasswordRow>
        </Form>
    );
}

export default LoginForm;
