import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

const breakPoint = '(max-width: 517px)';

export const RolloverContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
    margin-top: 5px;
`;

export const Parameters = styled.div<{direction?: string, justify?: string}>`
    display: flex;
    flex-direction: ${(props) => props.direction ?? 'row'};
    justify-content: ${(props) => props.justify ?? 'center'};
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    div:first-of-type {
        align-self: stretch;
    }
`;

export const RolloverStagesContainer = styled.div<{direction?: string}>`
    display: flex;
    flex-direction: ${(props) => props.direction ?? 'row'};
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
`;

export const RolloverStageContainer = styled.div<{isDisabled?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    min-width: 0;
    ${(props) => props.isDisabled && (
        css`
            opacity: 0.5;
            pointer-events: none;
        `
    )}
`;

export const RolloverStageTitle = styled.div`
    color: white;
    padding: 5px 20px;
    border-bottom: 2px solid #dddddd;
    text-align: center;
    font-weight: 500;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    svg {
        cursor: pointer;
    }
    
`;

export const RolloverStageTitleLabel = styled.div`
    color: white;
    text-align: center;
    font-weight: 500;
    cursor: default;
`;

export const RolloverList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    max-width: 100%;
    
`;

export const RolloverElementEventRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    height: 20px;
`;

export const RolloverElement = styled.div<{isDisabled?: boolean, backgroundColor?: string, border?: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 5px;
    background: ${props => props.backgroundColor ?? '#29353E66'};
    border: ${props => props.border ?? '1px solid #29353E'};
    border-radius: 5px;
    width: 100%;
    max-width: 610px;
    min-width: 250px;
    transition: opacity 0.3s ease-in-out;
    ${(props) => props.isDisabled && (
        css`
            opacity: 0.5;
        `
    )}
`;

export const RolloverElementEvent = styled.div`
    white-space: nowrap;
    width: fit-content;
    color: white;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    flex-grow: 1;
    cursor: default;
`;

export const RolloverElementOddsMatchRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5px;
    width: 100%;
`;

export const RolloverElementOdds = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 2px;
    @media ${breakPoint} {
        flex-direction: column;
    };
`;

export const RolloverElementCell = styled.div`
    height: 36px;
    @media ${breakPoint} {
        height: 24px;
    };
`;

export const RolloverElementOddsSelection = styled.div`

    width: fit-content;
    height: 36px;
    @media ${breakPoint} {
        width: 60px;
        height: 24px;
    };
    max-width: 60px;
    min-width: 24px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background: #243038;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    /* @media (max-width: 450px) {
        display: none;
    }; */
`;

export const RolloverElementOddsCovers = styled.div`
    display: flex;
    align-items: center; 
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 5px;
`;

export const RolloverElementOddsCoversContainer = styled.div`
    display: flex;
    align-items: center; 
    flex-wrap: nowrap;
    gap: 5px;
`;

export const CoverBetsAndRatingContainer = styled.div<{marginBottom?: number}>`
    display: flex;
    ${(props) => props.marginBottom && css`margin-bottom: ${props.marginBottom}px;`}
`;

export const CoverBetsContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export const CoverBets = styled.div<{isDisabled?: boolean, selected?: boolean, boxed?: boolean, height?: number, marginBottom?: number}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5px;
    text-align: left;
    white-space: nowrap;
    font-size: 13px;
    color: white;
    opacity: ${(props) => props.isDisabled ? '0.5' : '1'};
    svg {
        cursor: pointer;
        path {
            fill: ${(props) => props.selected ? '#ffffff75' : 'white'};
        }
        :hover {
            /* opacity: 0.8;
            path {
                fill: none;
            } */
        }
        transition: opacity .3s ease-in-out;
        margin-left: 10px;
    }
    padding-left: 5px;
    padding-right: 5px;
    ${(props) => props.boxed && (
        css`
            background: #29353E66;
            border-radius: 5px;
            border: 1px solid ${colors.grey};
        `
    )}
    ${(props) => props.height && css`height: ${props.height}px;`}
    ${(props) => props.marginBottom && css`margin-bottom: ${props.marginBottom}px;`}
`;

export const RolloverCompletionAdd = styled.div`
    width: 48px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #009b14;
    border-radius: 5px;
    font-size: 28px;
    color: #009b14;
    background: #009b1440;
    cursor: pointer;
`;

export const RolloverBetInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    padding-bottom: 5px;
    /* border-bottom: 2px solid #FFC000; */
`;

export const RolloverBetEventAndDeleteBtnContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 5px;
`;

export const RolloverBetOddsMatchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 15px;
    width: 100%;
`;

export const RolloverBetOdds = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 2px;
`;

export const RolloverBetOddsCovers = styled.div`
    display: flex;
    flex-direction: column;
    @media ${devices.tablet} {
        flex-direction: row;
    };
    gap: 4px;
`;

export const RolloverBetSelection = styled.input<{width: string}>`

    min-width: 0;
    max-width: 60px;
    width: ${(props) => props.width};
    height: 36px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.15px;
    text-align: center;

    border: 1px solid ${colors.grey};
    border-radius: 5px;
    background: #29353E66;
    color: white;
    transition: .3s ease-out all;
    :focus {
        border: 1px solid #202a31;
    }
    :focus-visible {
        outline: none;
    }

    :read-only {
        padding: 0;
        color: ${colors.white056};
        cursor: default;
    }

`;

export const RolloverStage2Info = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
`;

export const RolloverInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const RolloverInfoElement = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;
`;

export const RolloverInfoLabel = styled.div`
    font-size: 12px;
    color: white;
    font-weight: 500;
    text-align: center;
`;

export const RolloverInfoValue = styled.div<{v?: number}>`
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.v ? (props.v>0 ? '#00c100' : '#ff4242') : colors.primaryColor};
`;

export const RolloverFinishedReason = styled.div`
    color: white;
    font-weight: 500;
`;

export const RolloverFinishedValue = styled.div<{v?: number}>`
    color: ${(props) => props.v ? (props.v>0 ? '#00c100' : '#ff4242') : 'white'};
    font-weight: 500;
    font-size: 22px;
`;

// export const NoSite = styled.div`
//     height: 36px;
//     width: 55px;
//     background-color: transparent;
//     border-radius: 5px;
//     border: 1px solid #25343f;
// `;

// export const BetContainer = styled.div<{withResp?: boolean}>`
//     display: flex;
//     flex-direction: column;
//     column-gap: 5px;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
// `;

export const Action = styled.div`
    font-size: 16px;
    font-weight: 500;
`;

export const OverRolloverWarning = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    color: white;
    padding: 2px;
    background: #ff000069;
    border-radius: 5px;
    border: 1px solid #610000;
    font-size: 15px;
`;

export const WarningIconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const OverRolloverWarningText = styled.span`
    color: white;
    font-size: 15px;
    text-align: center;
`;
