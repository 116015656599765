import { Obj } from 'reselect/es/types';

// utils
import { getEnvironmentBaseUrl } from 'configs/config.helper';

// types
import { CardContentInfo, GetVisibleCardProps } from './ActiveHelpCard.utils.d';

// style
import {
    StrongText,
    GoldText
} from '../style/ActiveHelpCard.style';


export const CARD_CONTENT: Obj<CardContentInfo> = {
    defaultCard: {
        title: "Abbiamo notato che hai rallentato il Percorso, NON Procrastinare siamo qui per aiutarti:",
        buttonLink:{ path: '/reservedArea/aiuto-privato' },
        buttonText: "voglio aiuto privato",
        flavor: 'gold'
    },
    highwayActiveCard: {
        title: <p><StrongText>COMPLIMENTI (!)</StrongText> Hai acquistato L'Autostrada:</p>,
        waitText: "Stiamo scegliendo il Miglior Assistente per Te (!) Riceverai una Notifica una volta assegnato.",
        flavor: 'green'
    },
    freeLessonAssignedCard: {
        title: "Ti è stato assegnato l'Assistente Personale(!)",
        waitText: "Hai 15 giorni per usufruire della Lezione gratuita.",
        flavor: 'green'
    },
    freeLessonlWaitingCard: {
        title: "Ti verrà assegnato a breve un Assistente Personale. Riceverai una notifica sull'App di Liberi dal Lavoro e via email.",
        waitText: "il viaggio comincia (!)",
        flavor: 'invertedGreen'
    },
    facebookMissionCard: {
        title: <p>Accendi i 3 Badge e ti <GoldText>Regaliamo una Lezione</GoldText> con un Assistente Personale.</p>
    },
    appMissionCard: {
        title: <p>Accendi i 3 Badge e ti <GoldText>Regaliamo una Lezione</GoldText> con un Assistente Personale.</p>
    },
    betfairMissionCard: {
        title: <p>Accendi i 3 Badge e ti <GoldText>Regaliamo una Lezione</GoldText> con un Assistente Personale.</p>,
        flavor: 'big'        
    },
    missionsSuccessCard: {
        title: <p><StrongText>Complimenti (!)</StrongText> Ti sei guadagnato una Sessione in REGALO con un Assistente Personale:</p>,
        buttonText: "voglio il regalo (!)",
        buttonLink: { function: 'getFreeLessonAsAGift' },
        flavor: 'green'
    },
    buyedLessonAssignedCard: {
        title: "Ti è stato assegnato l'Assistente Personale, non ti resta che prenotare la tua Sessione...",
        flavor: 'green'
    },
    buyedLessonWaitingCard: {
        title: "Ti verrà assegnato a breve un Assistente Personale. Riceverai una notifica sull'App di Liberi dal Lavoro e via email.",
        waitText: "il viaggio comincia (!)",
        flavor: 'invertedGreen'
    },
    coachLessonCard: {
        title: "COMPLIMENTI (!) Ti stai impegnando nel Percorso:",
        buttonText: "prenota la prossima lezione",
        buttonLink: { path: '/reservedArea/lesson-page' },
        flavor: 'green'
    },
    congratulationsCard: {
        title: <p><StrongText>Congratulazioni</StrongText>, stai andando alla GRANDE!</p>,
        buttonLink: { path: '/reservedArea/aiuto-privato' },
        buttonText: "prenota la tua prossima lezione"
    }
};

export const getUsersLastLesson = (lessonsArray: any, lastLessonDate: string) => {

    if (lessonsArray.length === 1) return lessonsArray[0];

    for (let i=0; i<lessonsArray.lenght; i++) {
        if (lessonsArray[i].dataChiusuraAssistente === lastLessonDate) return lessonsArray[i];
    }

    return;
};

export const checkHasUserUsedFreeLesson = (lessonsArray: any) => {

    if (lessonsArray === null) return;

    if (lessonsArray.idPagameno) {
        return lessonsArray.idPagamento !== null;
    }

    return true;
};

export const checkTimeFromUsersLastLesson = (lastUsersLesson: string, setLastLessonDateBeforeTimeLimit: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
    const nowsTimestamp = new Date().getTime();

    if ((nowsTimestamp - +lastUsersLesson) < 2592000000) return setLastLessonDateBeforeTimeLimit(true);

    return setLastLessonDateBeforeTimeLimit(false);
};

export const getVisibleCard = ( {
    isHighwayActive,
    canUserHaveFreelesson,
    isLastLessonOpen,
    isMissionsCompleted,
    isFacebookMissionCompleted,
    isAppMissionCompleted,
    isBetfairMissionCompleted,
    isCoachLessonActive,
    isLastLessonDateBeforeTimeLimit,
    isAPLessonAssigned,
    hasFreeLesson,
    isAPLessonActive,
    isFreeLessonAssigned
}: GetVisibleCardProps,
) => {

    if (isHighwayActive) return 'highwayActiveCard';
    if (canUserHaveFreelesson && isFreeLessonAssigned && isLastLessonOpen && hasFreeLesson) return 'freeLessonAssignedCard';
    if (canUserHaveFreelesson && isLastLessonOpen && hasFreeLesson) return 'freeLessonlWaitingCard';
    if (isCoachLessonActive) return 'coachLessonCard';
    if (isAPLessonAssigned) return 'buyedLessonAssignedCard';
    if (isAPLessonActive) return 'buyedLessonWaitingCard';
    // if (canUserHaveFreelesson && isMissionsCompleted) return 'missionsSuccessCard';
    // if (canUserHaveFreelesson && !isFacebookMissionCompleted) return 'facebookMissionCard';
    // if (canUserHaveFreelesson && !isAppMissionCompleted) return 'appMissionCard';
    // if (canUserHaveFreelesson && !isBetfairMissionCompleted) return 'betfairMissionCard';
    if (isLastLessonDateBeforeTimeLimit) return 'congratulationsCard';
    return 'defaultCard';
};
