import { FC, useEffect, useState, useMemo, memo } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { setItemToLocalStorage, getItemFromLocalStorage, removeItemFromLocalStorage } from 'utils/localStorage';
import { setObjToSessionStorage, getObjFromSessionStorage, setItemToSessionStorage, getItemFromSessionStorage } from 'utils/sessionStorage';
import { _saveMultiple, _getMultiples, _deleteMultiple, _getCoverOdds } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { setMultiples, addMultiple, updateMultiple, removeMultiple, setCalculatorsMultiples, addCalculatorsMultiple, updateCalculatorsMultiple, removeCalculatorsMultiple } from 'redux/actions/actions-oddsscasser';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import BaseFieldset from 'components/ui/BaseFieldset';
import NumberInput from 'components/ui/NumberInput';
import Dropdown from 'components/Dropdown';
import TextInputWithLabel from 'components/ui/TextInputWithLabel';
import CheckboxesGroup from 'components/CheckboxesGroup';
import SwitchSelectorBig from 'components/SwitchSelectorBig';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import CoverOddsModal from './CoverOddsModal';
import MultipleTree from './MultipleTree';
import EventInput from './EventInput/EventInput.component';
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete/TextInputWithAutocomplete.component';

//assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';
import { ReactComponent as EditIconSVG } from 'pages/OddsScasserPage/assets/edit.svg';
import { ReactComponent as SearchIconSVG } from 'pages/OddsScasserPage/assets/searchIcon.svg';
import { ReactComponent as TreeIconSVG } from 'pages/OddsScasserPage/assets/tree.svg';
import { ReactComponent as CheckboxOnSVG } from 'pages/OddsScasserPage/assets/checkboxOn.svg';
import { ReactComponent as CheckboxOffSVG } from 'pages/OddsScasserPage/assets/checkboxOff.svg';
import { ReactComponent as WarningIconSVG } from 'pages/OddsScasserPage/assets/warning.svg';
import { ReactComponent as CheckmarkSVG } from 'pages/OddsScasserPage/assets/checkmark.svg';
import { ReactComponent as ArrowUpIcon } from 'pages/OddsScasserPage/assets/arrowUp.svg';
import { ReactComponent as ArrowDownIcon } from 'pages/OddsScasserPage/assets/arrowDown.svg';
import { ReactComponent as NotificationsOnIconSVG } from 'pages/OddsScasserPage/assets/notificationsOn.svg';
import { ReactComponent as NotificationsOffIconSVG } from 'pages/OddsScasserPage/assets/notificationsOff.svg';

//style
import { MultipleContainer, Parameters, MultipleSection, MultipleSectionsContainer, MultipleElement, MultipleElementEventRow, MultipleElementOdds, MultipleElementOddsCovers, MultipleElementOddsMatchRow, MultipleList, MultipleElementOddsSelection, MultipleElementEvent, MultipleElementOddsCoversContainer, MultipleBonusAndOdds, MultipleBonusContainer, MultipleOddsContainer, MultipleBonusLabel, MultipleFinishedReason, MultipleInfoContainer, MultipleInfoElement, MultipleInfoLabel, MultipleInfoValue, CoverTitle, NextCoverContainer, AddToCalculatorsMultiple, ConcurrentEventsWarning, MultipleElementCell, NotificationsLabel, MultipleAdd, MultipleAmountLabel, MultipleAmount } from './style/Multiple.style';
import { HorizontalScrollContainer, DropdownContent, Result, ToolButton, ToolButtons, SVGIconContainer, Rating, WinnigsAndRating, Winnings, Bet, BetContainer, Resp, BetsTable, BetsTableBody, BetsTableCell, BetsTableHead, BetsTableHeaderCell, BetsTableRow, CoverOdds, Select, ArrowsContainer, StandardCheckbox, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, CoverInstructions, CoverInstructionsContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, SavedElementCheckbox, SavedElementsContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Col, Row } from 'style/layout';

//types
import { MultipleProps } from './Multiple.component.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Odds } from 'pages/OddsScasserPage/types/Odds';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';
import { MultipleData, MultipleObj, MultipleOddsMatch } from './types/MultipleObj';
import { MultipleNode } from './types/MultipleNode';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { EventInputState } from './EventInput/types/EventInputState';
import { Event } from 'pages/OddsScasserPage/types/Event';

//helper functions
import { stepIntegers, stepOdds, stepEach, formatDate, resultComment, getRating, localeStringToUTCString, coverSelectionPuntaPunta, selectionsValues, coverSelectionsTriscasser, getResult, timestampToUTCString, stepOddsPP } from 'pages/OddsScasserPage/OddsScasserPage.helper';


const Multiple: FC<MultipleProps> = ({calculators, oddsMatchToAdd, visible, feesBetfair, feesBetflag, changeVisibility, copyToClipboard}) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { roles } = useAppSelector(state => state.user);

    const multipleIdStorageKey: string = calculators ? 'calculatorsMultipleId' : 'multipleId';
    const multipleStorageKey: string = calculators ? 'calculatorsMultiple' : 'multiple';

    const { sites, events, multiples, calculatorsMultiples } = useAppSelector(state => state.oddsScasser);
    const multiplesList: MultipleObj[]|undefined = useMemo(() => calculators ? calculatorsMultiples : multiples, [multiples, calculatorsMultiples, calculators]);
    const dispatch = useAppDispatch();

    const [initializationCompleted, setInitializationCompleted] = useState<boolean>(false);
    const [multipleIndex, setMultipleIndex] = useState<number>(-1);
    const [toSave, setToSave] = useState<boolean>(false);
    const [treeVisible, setTreeVisible] = useState<boolean>(getItemFromLocalStorage('multipleTreeVisible')==='true');

    const [description, setDescription] = useState<TextInputState>({value: ''});
    const [siteId, setSiteId] = useState<number>(-1);
    const [bet, setBet] = useState<NumberInputState>({value: ''});
    const [bonus, setBonus] = useState<NumberInputState>({value: ''});
    const [refund, setRefund] = useState<NumberInputState>({value: ''});
    const [isFreebet, setIsFreebet] = useState<boolean>(false);
    const [refundType, setRefundType] = useState<'normal'|'lose1'>('normal');
    const [multipleBonus, setMultipleBonus] = useState<NumberInputState>({value: '0'});
    const [multipleBonusType, setMultipleBonusType] = useState<'gross'|'net'>('net');
    const [notifications, setNotifications] = useState<boolean>(false);
    const [baNotifications, setBaNotifications] = useState<boolean>(false);
    const [multiple, setMultiple] = useState<MultipleOddsMatch[]>([]);
    //Edit event
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [editingEvent, setEditingEvent] = useState<EventInputState|undefined>(undefined);
    const [editingOddsMatch, setEditingOddsMatch] = useState<MultipleOddsMatch|undefined>(undefined);
    
    //Cover odds
    const [coverOdds, setCoverOdds] = useState<{eventId: number, selection: string, odds: number, coverOdds: Odds[]}|undefined>(undefined);
    //Calculators
    const [eventsNum, setEventsNum] = useState<number>(calculators ? 3 : 0);
    const [fees, setFees] = useState<NumberInputState>({value: feesBetfair});

    const initialData = () => {
        const data: MultipleData = {
            description: {value: ''},
            siteId: -1, 
            bet: {value: ''},
            bonus: {value: ''},
            refund: {value: ''},
            isFreebet: false,
            refundType: 'normal',
            multipleBonus: {value: ''},
            multipleBonusType: 'net',
            notifications: false,
            baNotifications: false,
            multiple: []
        }
        return data;
    }

    //Set sitesList when sites changes
    const sitesList: Site[] = useMemo(() => {
        return (!calculators && sites && sites.size>0) ? Array.from(sites.values()) : [];
    }, [sites, calculators])

    useEffect(() => {
        if(isFreebet && !bet.errorMessage) {
            setRefund({value: bet.value});
            setBonus({value: ''});
        }
    }, [isFreebet, bet]);

    const handleIsFreebetChange = () => {
        setIsFreebet(prevState => {
            if(prevState) {
                setRefund({value: ''});
                // setRefundType('normal');
            }
            return !prevState;
        })
    }


    useEffect(() => {
        setItemToLocalStorage('multipleTreeVisible', treeVisible ? 'true' : 'false');
    }, [treeVisible]);

    useEffect(() => {
        //Get multiples
        const getMultiples = async () => {
            try {
                let _multiples = multiples;
                if(!_multiples) {
                    _multiples = await _getMultiples(calculators) as MultipleObj[];
                    dispatch(calculators ? setCalculatorsMultiples(_multiples) : setMultiples(_multiples));
                }
                const id = getItemFromLocalStorage(multipleIdStorageKey);
                setMultipleIndex(id ? _multiples.findIndex(x => x.id===parseInt(id)) : -1);
                //If there's a multiple in the sessionStorage, load it
                if(getItemFromSessionStorage(multipleStorageKey)) {
                    setToSave(!(multiplesList!==undefined && multipleIndex>-1 && getItemFromSessionStorage(multipleStorageKey)===JSON.stringify(multiplesList[multipleIndex].data)));
                    loadFromSessionStorage();
                    return;
                } 
                if(!id) {
                    if(eventsNum>0) addToCalculatorsMultiple(eventsNum);
                    return;
                }
                //Otherwise, load the saved multiple
                let found = false;
                _multiples.forEach((x: MultipleObj) => {
                    if(x.id && x.id.toString() === id) {
                        found = true;
                        setItemToSessionStorage(multipleStorageKey, JSON.stringify(x.data));
                        loadFromSessionStorage();
                        return;
                    }
                })
                if(!found) removeItemFromLocalStorage(multipleIdStorageKey);
            } catch (error: any) {
                console.warn(error);
                // navigate(error.message === '403' ? '/fallback-page/permission-denied' : '/fallback-page/generic-error');
            }
        };
        if(!initializationCompleted) getMultiples().then(() => setInitializationCompleted(true));
        else loadFromSessionStorage();
    }, []);
    
    const loadFromSessionStorage = () => {
        const multipleData = getObjFromSessionStorage(multipleStorageKey) as MultipleData;
        if(!multipleData) return;
        setDescription(multipleData.description);
        setSiteId(multipleData.siteId ?? -1);
        setBet(multipleData.bet);
        setBonus(multipleData.bonus);
        setRefund(multipleData.refund);
        setIsFreebet(multipleData.isFreebet);
        setRefundType(multipleData.refundType);
        setMultipleBonus(multipleData.multipleBonus);
        setMultipleBonusType(multipleData.multipleBonusType);
        setNotifications(multipleData.notifications);
        setBaNotifications(multipleData.baNotifications);
        setMultiple(multipleData.multiple);
    }

    //Set results of finished events
    useEffect(() => {
        if(!initializationCompleted || events.size === 0) return;
        setMultiple(prevState => {
            if(prevState.length === 0) return prevState;    
            //Check results automatically inserted
            let results: (string|null)[] = [];
            const cancelled = prevState.map(x => x.cancelled[x.cancelled.length-1]);
            for (let i = 0; i < prevState.length; i++) {
                if(prevState[i].result === null && !cancelled[i]) break;
                results.push(cancelled[i] ? null: (prevState[i].result==='W' ? 'W' : 'L'));
            }
            if(results.length+1>prevState[0].modes.length) return getNewMultipleStateAfterResultChange(prevState, results.length-1, results[results.length-1], results[results.length-1]==null);
            //Check results in the events list
            let index: number = -1;
            let result: string|null = null;
            for (let i = 0; i < prevState.length; i++) {
                if(prevState[i].event !== null && prevState[i].result === null && !prevState[i].cancelled[prevState[i].cancelled.length-1]) {
                    const event: Event|undefined = events.get(prevState[i].event?.id ?? 0);
                    if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
                        index = i;
                        result = getResult(prevState[i].modes[prevState[i].modes.length-1], prevState[i].selections[prevState[i].selections.length-1], event.home.score, event.away.score);
                        break;
                    }
                }
            }
            return index<0 ? prevState : getNewMultipleStateAfterResultChange(prevState, index, result, false);
        });
    }, [initializationCompleted, events, multiplesList, multipleIndex]);

    useEffect(() => {
        setMultiple(prevState => {
            return prevState.map(x => {
                if(x.result!==null || x.cancelled[x.cancelled.length-1] || x.modes[x.modes.length-1] !== 'pb') return x;
                const _fees = calculators ? (!fees.errorMessage ? fees.value : x.fees[x.fees.length-1]) : (x.sitesIds[x.sitesIds.length-1][1] === 9 ? feesBetflag : feesBetfair);
                return {...x, fees: x.fees.map((y,i) => i===x.fees.length-1 ? _fees : y)};
            });
        });
    }, [feesBetfair, feesBetflag, fees]);

    //Add oddsMatch
    useEffect(() => {
        if(!calculators && initializationCompleted && oddsMatchToAdd) addToMultiple(oddsMatchToAdd);
    }, [oddsMatchToAdd]);

    const addToMultiple = (oddsMatch: OddsMatch) => {
        if(!calculators) {
            if(siteId<0) setSiteId(oddsMatch.odds[0].site.id);
            else if(siteId>0 && siteId !== oddsMatch.odds[0].site.id) {
                dispatch(addSnackbar({ type: 'error', message: 'Bookmaker non valido. Il bookmaker deve essere '+sites.get(siteId)?.name}));
                return;
            } 
            if(editingIndex>-1) {
                dispatch(addSnackbar({ type: 'error', message: 'Prima di aggiungere un evento salva o annulla le modifiche fatte'}));
                return;
            }
        }
        const eventId = oddsMatch.event.id;
        if(eventId>0 && multiple.some(x => x.event?.id === eventId)) {
            dispatch(addSnackbar({ type: 'error', message: 'Evento già presente nella multipla'}));
            return;
        }
        if(multiple.some(x => x.result || x.cancelled[x.cancelled.length-1])) {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile aggiungere un evento ad una multipla già iniziata'}));
            return;
        }
        const selections: string[] = oddsMatch.odds.map(x => x.type ?? '');
        if(selections.length<3) selections.push('');
        const odds: NumberInputState[] = oddsMatch.odds.map(x => ({value: x.odds.toFixed(2)}));
        if(odds.length<3) odds.push({value: '', errorMessage: 'NaN'});
        const oddsInfo: (Odds|null)[] = [...oddsMatch.odds];
        if(oddsInfo.length<3) oddsInfo.push(null);
        const mode = oddsMatch.odds[0].type === oddsMatch.odds[1].type ? 'pb' : (oddsMatch.odds.length>2 ? 'tri' : 'pp');
        const _fees = mode === 'pb' && oddsInfo[1] ? (oddsInfo[1].site.id===9 ? feesBetflag : feesBetfair) : null;
        const event = events.get(oddsMatch.event.id) ?? null;
        let eventText = '';
        if(event) {
            eventText = `${event.home?.name} - ${event.away?.name}`;
            const league = event.league;
            if(league) {
                const country = league.country;
                const leagueName = league.name;
                if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
            }
        }
        const newElement: MultipleOddsMatch = {
            result: null, 
            covered: false,
            event,
            eventText,
            eventDate: (event!==null && event.datetime) ? event.datetime : '',
            modes: [mode],
            fees: [_fees],
            selections: [selections],
            sitesIds: [oddsInfo.map((x,i) => (i===0 && siteId===0) ? 0 : (x?.site.id ?? -1))],
            sitesUrls: [oddsInfo.map((x,i) => (i===0 && siteId===0) ? null : (x?.url ?? null))],
            odds: [odds],
            cancelled: [false]
        }
        setMultiple(prevState => {
            const newState = [...prevState.map(x => ({...x})), newElement];
            if(!calculators) newState.sort((x,y) => (x.event?.datetime && y.event?.datetime && x.event.datetime<y.event.datetime) ? -1 : 1);
            return newState;
        });
        dispatch(addSnackbar({ type: 'success', message: 'Evento aggiunto alla multipla'}));
    }

    const addToCalculatorsMultiple = (n: number) => {
        if(multiple.some(x => x.result || x.cancelled[x.cancelled.length-1])) {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile aggiungere un evento ad una multipla già iniziata'}));
            return;
        }
        if(n<=0) return;
        const newElements: MultipleOddsMatch[] = [];
        for (let i = 0; i < n; i++) {
            newElements.push({
                modes: ['pb'],
                result: null, 
                covered: false,
                event: null,
                eventText: '',
                eventDate: '',
                fees: [null],
                selections: [['','','']],
                sitesIds: [[-1,-1,-1]],
                sitesUrls: [[null,null,null]],
                odds: [[{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}]],
                cancelled: [false]
            })
        }
        setMultiple(prevState => [...prevState, ...newElements]);
    }

    const addEmptyToMultiple = () => {
        if(multiple.some(x => x.result || x.cancelled[x.cancelled.length-1])) {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile aggiungere un evento ad una multipla già iniziata'}));
            return;
        }
        const newElement: MultipleOddsMatch = {
            modes: ['pb'],
            result: null, 
            covered: false,
            event: null,
            eventText: '',
            eventDate: '',
            fees: [null],
            selections: [['','','']],
            sitesIds: [[-1,-1,-1]],
            sitesUrls: [[null,null,null]],
            odds: [[{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}]],
            cancelled: [false]
        };
        setMultiple(prevState => {
            setEditingIndex(prevState.length);
            return [...prevState, newElement];
        });
    }

    useEffect(() => {
        if(multiplesList && initializationCompleted) {
            setObjToSessionStorage(multipleStorageKey, getMultipleData());
            setToSave(!(multiplesList!==undefined && multipleIndex>-1 && multipleIndex<multiplesList.length && getItemFromSessionStorage(multipleStorageKey)===JSON.stringify(multiplesList[multipleIndex].data)));
        }
    }, [multipleIndex, description, siteId, bet, bonus, refund, isFreebet, refundType, multipleBonus, multipleBonusType, notifications, baNotifications, multiple]);
    
    const handleResultClick = (index: number) => {
        if(editingIndex>-1) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima confermare o annullare le modifiche fatte' }));
            return;
        }
        if(bet.errorMessage || bonus.errorMessage || parseFloat(bet.value)<0.01 || refund.errorMessage || multipleBonus.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Inserire prima i parametri della multipla' }));
            return;
        }
        if(multiple.some((x,i) => i>index && x.result)) {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile impostare il risultato se sono presenti risultati delle partite successive' }));
            return;
        }
        setMultiple(prevState => {
            let result: string|null = null;
            let canc: boolean = false;
            const _cancelled = prevState[index].cancelled[prevState[index].cancelled.length-1];
            if(prevState.some((x,i) => i<index && !x.result && !x.cancelled[x.cancelled.length-1])) canc = !_cancelled;
            else if(!_cancelled) {
                const results = prevState[index].modes[prevState[index].modes.length-1] === 'tri' ? [null, 'W', 'L1', 'L2', 'C'] : [null, 'W', 'L', 'C'];
                for (let i = 0; i < results.length; i++) {
                    if(results[i] === prevState[index].result) result = results[(i+1)%results.length];
                }
            }
            if(result === 'C') {
                result = null;
                canc = true;
            }
            return getNewMultipleStateAfterResultChange(prevState, index, result, canc);
        });
    }

    const getNewMultipleStateAfterResultChange = (prevState: MultipleOddsMatch[], index: number, result: string|null, canc: boolean) => {
        let n: number = 1;
        for (let i = 0; i < prevState.length; i++) {
            const r = i===index ? result : prevState[i].result;
            const c = i===index ? canc : prevState[i].cancelled[prevState[i].cancelled.length-1];
            if(r === null && !c) break;
            n += 1;
        }
        return prevState.map((x,i) => {
            //modes
            let diff = n-x.modes.length; 
            const modes = diff<0 ? x.modes.slice(0, diff) : [...x.modes]; 
            if(diff>0) modes.push(...Array.from({length:diff}, () => x.modes[x.modes.length-1]));
            //fees
            diff = n-x.fees.length; 
            const _fees = diff<0 ? x.fees.slice(0, diff) : [...x.fees]; 
            if(diff>0) _fees.push(...Array.from({length:diff}, () => x.fees[x.fees.length-1]));
            //selections
            diff = n-x.selections.length; const selections = diff<0 ? x.selections.slice(0, diff) : [...x.selections]; 
            if(diff>0) selections.push(...Array.from({length:diff}, () => [...x.selections[x.selections.length-1]]));
            //sitesIds
            diff = n-x.sitesIds.length; const sitesIds = diff<0 ? x.sitesIds.slice(0, diff) : [...x.sitesIds]; 
            if(diff>0) sitesIds.push(...Array.from({length:diff}, () => [...x.sitesIds[x.sitesIds.length-1]]));
            //siteUrls
            diff = n-x.sitesUrls.length; const sitesUrls = diff<0 ? x.sitesUrls.slice(0, diff) : [...x.sitesUrls]; 
            if(diff>0) sitesUrls.push(...Array.from({length:diff}, () => [...x.sitesUrls[x.sitesUrls.length-1]]));
            //odds
            diff = n-x.odds.length; const odds = diff<0 ? x.odds.slice(0, diff) : [...x.odds]; 
            if(diff>0) odds.push(...Array.from({length:diff}, () => [...x.odds[x.odds.length-1].map(x => ({...x}))]));
            //cancelled
            diff = n-x.cancelled.length; const cancelled = diff<0 ? x.cancelled.slice(0, diff) : [...x.cancelled]; 
            if(diff>0) cancelled.push(...Array.from({length:diff}, () => x.cancelled[x.cancelled.length-1]));
            if(i===index) cancelled[cancelled.length-1] = canc;
            return {
                result: index===i ? result : x.result, 
                covered: x.covered,
                event: x.event,
                eventText: x.eventText,
                eventDate: x.eventDate,
                modes, fees: _fees, selections, sitesIds, sitesUrls, odds, cancelled
            };
        });
    }

    const handleOddsChange = (index: number, oddsIndex: number, newState: NumberInputState) => {
        setMultiple(prevState => prevState.map((x,i) => {
            if(i===index) {
                const odds = x.odds.map((y,j) => j===x.odds.length-1 ? y.map((z,k) => k===oddsIndex ? newState : {...z}) : y);
                return {...x, odds};
            }
            return x;
        }));
    }
    
    const handleCoverCheckboxClick = (index: number) => {
        setMultiple(prevState => prevState.map((x,i) => i===index ? {...x, covered: !x.covered} : x));
    }

    const removeFromMultiple = (index: number) => {
        setMultiple(prevState => {
            if(prevState.length===1 && index===0) setSiteId(-1);
            return prevState.filter((_,i) => i!==index)
        });
    }

    const handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        setMultiple(prevState => prevState.map((x,i) => i===index ? 
            {
                ...x, 
                modes: x.modes.map((y,j) => x.modes.length-1===j ? event.target.value : y),
                fees: x.fees.map((y,i) => i===x.fees.length-1 ? (event.target.value==='pb' ? fees.value : null) : y),
                odds: x.odds.map((y,i) => i===x.odds.length-1 ? [y[0],{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}] : y)
            } : x));
    }

    //Editing
    const handleSiteChangeEdit = (oddsIndex: number, siteIndex: number) => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            const _fees = prevState.modes[prevState.modes.length-1]==='pb' ? ((oddsIndex===1 ? siteIndex : prevState.sitesIds[prevState.sitesIds.length-1][1])===9 ? feesBetflag : feesBetfair) : null;
            return {...prevState, 
                sitesIds: prevState.sitesIds.map((y,j) => j===prevState.sitesIds.length-1 ? y.map((z,k) => k===oddsIndex ? siteIndex : z) : y), 
                sitesUrls: prevState.sitesUrls.map((y,j) => j===prevState.sitesUrls.length-1 ? y.map((z,k) => k===oddsIndex ? null : z) : y), 
                fees: prevState.fees.map((x,i) => i===prevState.modes.length-1 ? _fees : x)
            };
        });
    }

    const handleOddsChangeEdit = (oddsIndex: number, newState: NumberInputState) => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            const odds = prevState.odds.map((y,j) => j===prevState.odds.length-1 ? y.map((z,k) => k===oddsIndex ? newState : {...z}) : y);
            return {...prevState, odds};
        });
    }

    const handleModeChangeEdit = (newValue: string) => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            const newSelections = [...prevState.selections[prevState.selections.length-1]];
            if(newValue === 'pb') newSelections[1] = newSelections[0];
            else if(newValue === 'pp') newSelections[1] = coverSelectionPuntaPunta.get(newSelections[0]) ?? '';
            else {
                const covers = coverSelectionsTriscasser.get(newSelections[0]);
                newSelections[1] = covers ? covers[0] : '';
                newSelections[2] = covers ? covers[1] : '';
            }
            return {...prevState, 
                modes: prevState.modes.map((x,i) => i===prevState.modes.length-1 ? newValue : x),
                selections: prevState.selections.map((x,i) => i===prevState.odds.length-1 ? newSelections : x),
                sitesIds: prevState.sitesIds.map((x,i) => i===prevState.sitesIds.length-1 ? [x[0],-1,-1] : x),
                sitesUrls: prevState.sitesUrls.map((x,i) => i===prevState.sitesIds.length-1 ? [x[0],null,null] : x),
                fees: prevState.fees.map((x,i) => i===prevState.fees.length-1 ? (newValue==='pb' ? feesBetfair : null) : x),
                odds: prevState.odds.map((x,i) => i===prevState.odds.length-1 ? [x[0],{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}] : x)
            } 
        });
    }

    const handleSelectionChangeEdit = (oddsIndex: number, newValue: string, index: number) => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            const mode = prevState.modes[prevState.modes.length-1];
            const newSelections = [...prevState.selections[prevState.selections.length-1]];
            if(index>-1) {
                let filteredSelectionsValues = mode==='tri' ? selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text)) : (mode==='pb' ? selectionsValues.filter(x => !['1X','X2','12'].includes(x.text)) : selectionsValues);
                newSelections[oddsIndex] = filteredSelectionsValues[index].text;
                if(mode === 'pb') newSelections[1-oddsIndex] = filteredSelectionsValues[index].text;
                else if(oddsIndex === 0){
                    if(mode === 'pp') newSelections[1] = coverSelectionPuntaPunta.get(filteredSelectionsValues[index].text) ?? '';
                    else {
                        const covers = coverSelectionsTriscasser.get(filteredSelectionsValues[index].text);
                        newSelections[1] = covers ? covers[0] : '';
                        newSelections[2] = covers ? covers[1] : '';
                    }
                }
            } else {
                newSelections[oddsIndex] = newValue;
                if(oddsIndex===0) {
                    newSelections[1] = '';
                    newSelections[2] = '';
                }
            }
            return {...prevState, selections: prevState.selections.map((x,i) => i===prevState.odds.length-1 ? newSelections : x)};
        });
    }

    const searchCovers = async (index: number) => {
        const odds = multiple[index].odds[multiple[index].odds.length-1][0];
        if(odds.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Quota multipla non valida'}));
            return;
        }
        const selection =  multiple[index].selections[multiple[index].selections.length-1][0];
        if(!coverSelectionPuntaPunta.has(selection)) {
            dispatch(addSnackbar({ type: 'error', message: 'Esito non valido'}));
            return;
        }
        const params = {eventId: multiple[index].event?.id, selection};
        if(params.eventId) setCoverOdds({eventId: params.eventId, selection: params.selection, odds: parseFloat(odds.value), coverOdds: await _getCoverOdds(params) as Odds[]});
        else {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile fare la ricerca per un evento personalizzato inserito manualmente'}));
            return;
        }
    }
    
    const orderedCoverOddsMatches: OddsMatch[]|undefined = useMemo(() => {
        if(!coverOdds || siteId === -1) return undefined;
        const oddsMatches: OddsMatch[] = [];
        coverOdds.coverOdds.filter(x => (x.type===coverSelectionPuntaPunta.get(coverOdds.selection) && x.site.id!==siteId)).forEach(o => {
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/o.odds)});
        });
        coverOdds.coverOdds.filter(x => (x.type===coverOdds.selection && sites.get(x.site.id)?.type==='exchange')).forEach(o => {
            const _fees = parseFloat(o.site.id===9 ? feesBetflag : feesBetfair)/100;
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/((o.odds-_fees)/(o.odds-1)))});
        });
        if(['1','X','2'].includes(coverOdds.selection)) {
            let sels: string[] = ['1','X','2']; sels.splice(sels.indexOf(coverOdds.selection), 1);
            const cover1 = coverOdds.coverOdds.filter(x => (x.type===sels[0] && x.site.id!==siteId));
            const cover2 = coverOdds.coverOdds.filter(x => (x.type===sels[1] && x.site.id!==siteId));
            cover1.forEach(c1 => {
                cover2.forEach(c2 => {
                    if(siteId!==c1.site.id && siteId!==c2.site.id) oddsMatches.push({event: {id: coverOdds.eventId}, odds: [c1, c2], rating: coverOdds.odds*(1-1/c1.odds-1/c2.odds)});
                });
            });
        }
        oddsMatches.sort((x,y) => y.rating-x.rating);
        return oddsMatches;
    }, [coverOdds]);
    
    const changeCover = (eventId: number, oddsMatch: OddsMatch) => {
        setMultiple(prevState => prevState.map(x => {
            if(x.event && x.event.id === eventId) {
                const newMode = oddsMatch.odds.length>1 ? 'tri' : (sites.get(oddsMatch.odds[0].site.id)?.type==='exchange' ? 'pb' : 'pp');
                const modes = x.modes.map((y,j) => j===x.odds.length-1 ? newMode : y);
                const _fees = x.fees.map((y,j) => j===x.odds.length-1 ? (newMode==='pb' ? (oddsMatch.odds[0].site.id===9 ? feesBetflag : feesBetfair) : null) : y);
                const newSelections: string[] = oddsMatch.odds.map(y => y.type ?? ''); if(newSelections.length===1) newSelections.push('');
                const selections = x.selections.map((y,j) => j===x.selections.length-1 ? [y[0], ...newSelections] : y);
                const newSitesIds: number[] = oddsMatch.odds.map(y => y.site.id); if(newSitesIds.length===1) newSitesIds.push(-1);
                const sitesIds = x.sitesIds.map((y,j) => j===x.sitesIds.length-1 ? [y[0], ...newSitesIds] : y);
                const newSitesUrls: (string|null)[] = oddsMatch.odds.map(y => y.url ?? null); if(newSitesUrls.length===1) newSitesUrls.push(null);
                const sitesUrls = x.sitesUrls.map((y,j) => j===x.sitesUrls.length-1 ? [y[0], ...newSitesUrls] : y);
                const newOdds: NumberInputState[] = oddsMatch.odds.map(y => ({value: y.odds.toFixed(2)})); if(newOdds.length===1) newOdds.push({value: '', errorMessage: 'NaN'});
                const odds = x.odds.map((y,j) => j===x.odds.length-1 ? [y[0], ...newOdds] : y);
                return {...x, modes, fees: _fees, selections, sitesIds, sitesUrls, odds};
            }
            return x;
        }));
    }
    
    const generateTree = (node: MultipleNode, depth: number) => {
        const maxL = (refundType==='lose1' && !isFreebet) && (!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0.01)) ? 1 : 0;
        const countL = (node.value.match(/L/g) || []).length;
        if(countL <= maxL && depth>=0) {
            const odds = multiple[node.value.length].odds[0][0];
            const p = odds.errorMessage ? 0.5 : (multiple[node.value.length].cancelled[multiple[node.value.length].cancelled.length-1] ? 1.0 : 1/parseFloat(odds.value));
            node.w = {parent: node, value: node.value+'W', prob: node.prob<0.0001 ? 0 : p, covers: Array.from({length: multiple[node.value.length].odds.length}, () => ({}))};
            node.l = {parent: node, value: node.value+'L', prob: node.prob<0.0001 ? 0 : 1-p, covers: Array.from({length: multiple[node.value.length].odds.length}, () => ({}))};
            generateTree(node.w, depth-1);
            generateTree(node.l, depth-1);
        } else {//leaf
            node.multipleResult = [];
            if(node.value.includes('L')) {
                const r = (countL===1 || (countL>0 && maxL===0)) ? (parseFloat(isFreebet ? bet.value : refund.value) || 0) : 0.0;
                for (let i = 0; i < node.covers.length; i++) node.multipleResult.push(r);
            }
            else {
                const _bet = parseFloat(bet.value);
                for (let i = 0; i < node.covers.length; i++) {
                    const multipleOdds = multiple.reduce((a, b) => (b.cancelled[i] ? 1.0 : parseFloat(b.odds[i][0].value))*a, 1.0)*(1+(parseFloat(multipleBonus.value) || 0)/100.0) - (multipleBonusType==='net' ? (parseFloat(multipleBonus.value) || 0)/100.0 : 0);
                    node.multipleResult.push(Math.round(_bet*multipleOdds*100)*0.01);
                }
            }
        }
        return node;
    }

    const getDescOrderedNodes = (tree: MultipleNode) => {
        const queue = [tree];
        const nodes = [];
        while (queue.length !== 0) {
            const front = queue[0];
            if(front.w && front.w.w) queue.push(front.w);
            if(front.l && front.l.w) queue.push(front.l);
            nodes.push(front);
            queue.shift();
        }
        nodes.reverse();
        return nodes;
    }

    const getLeaves = (tree: MultipleNode) => {
        const queue = [tree];
        const nodes = [];
        while (queue.length !== 0) {
            const front = queue[0];
            if(front.w) queue.push(front.w);
            if(front.l) queue.push(front.l);
            else if(front.prob>0.0001) nodes.push(front);
            queue.shift();
        }
        return nodes;
    }

    const calculateCovers = (tree: MultipleNode) => {
        const nodes = getDescOrderedNodes(tree);
        for (let i = 0; i < tree.covers.length; i++) {
            nodes.forEach(node => {
                node.calculated = true;
                const index = node.value.length;
                if(multiple[index].result!==null && i>index) return;//Don't calculate covers already made
                if(multiple[index].cancelled[i]) return;
                let allW = 0.0;
                let curr = node.w;
                while(curr) {
                    allW += curr.multipleResult!==undefined ? curr.multipleResult[i] : (curr.covers[i].winnings ?? [0])[0];
                    curr = curr.w;
                }
                let allL = 0;
                curr = node.l;
                while(curr) {
                    allL += curr.multipleResult!==undefined ? curr.multipleResult[i] : (curr.covers[i].winnings ?? [0])[0];
                    curr = curr.w;
                }
                node.covers[i].result = multiple[index].result;
                if(multiple[index].modes[i] === 'pb') {
                    const round = 0.01;
                    const odds = parseFloat(multiple[index].odds[i][1].value);
                    const _fees = parseFloat(calculators ? (!fees.errorMessage ? fees.value : '4.5') : multiple[index].fees[i] ?? '4.5')/100.0;
                    const oddsP = (odds-_fees)/(odds-1);//Equivalent odds in punta
                    const resp = odds<1.001 ? 0 : (allW-allL)/oddsP;
                    const _bet = odds<1.001 ? 0 : Math.max(0, Math.round(resp*(oddsP-1)/(1-_fees)/round))*round;
                    node.covers[i].bets = [_bet];//set locked bet
                    node.covers[i].resp = [_bet*(odds-1)];
                    node.covers[i].winnings = [Math.round(-resp/round)*round, Math.round(_bet*(1-_fees)/round)*round];
                } else if(multiple[index].modes[i] === 'pp') {
                    const round = 1;
                    const odds = parseFloat(multiple[index].odds[i][1].value);
                    const _bet = Math.max(1, Math.round((allW-allL)/odds/round)*round);
                    node.covers[i].bets = [_bet];//set locked bet
                    node.covers[i].resp = node.covers[i].bets;
                    node.covers[i].winnings = [-_bet, _bet*(odds-1)];
                } else {
                    const round = 1;
                    const odds = multiple[index].odds[i].slice(1).map(x => parseFloat(x.value));
                    node.covers[i].bets = odds.map(x => Math.max(1, Math.round((allW-allL)/x/round)*round));//set locked bet
                    node.covers[i].resp = node.covers[i].bets;
                    const resp = (node.covers[i].resp ?? []).reduce((a,b) => a+b, 0.0);
                    node.covers[i].probCover1 = 1/odds[0]/(1/odds[0]+1/odds[1]);
                    node.covers[i].winnings = [-resp, (node.covers[i].bets ?? [0])[0]*odds[0]-resp, (node.covers[i].bets ?? [0,0])[1]*odds[1]-resp];
                }
            });
        }
    }

    const multipleTree: MultipleNode|undefined = useMemo(() => {
        if(multiple.length===0) return;
        const tree = generateTree({value: '', prob: 1.0, covers: Array.from({length: multiple[0].odds.length}, () => ({}))}, multiple.length-1);
        const oddsNotOk = multiple.some(x => {
            const n = x.modes[x.modes.length-1] === 'tri' ? 3 : 2;
            return x.odds[x.odds.length-1].some((y,i) => i<n && y.errorMessage)
        })
        if(!(bet.errorMessage || bonus.errorMessage || parseFloat(bet.value)<0.01 || refund.errorMessage || multipleBonus.errorMessage || oddsNotOk))
            calculateCovers(tree);
        return tree;
    }, [bet, bonus, refund, refundType, multipleBonus, multipleBonusType, multiple, fees])

    const info: {coversMax?: number, avgWinnings?: number, avgRating?: number, nextCover?: MultipleNode, finished: boolean} = useMemo(() => {
        if(!multipleTree || !multipleTree.calculated) return{finished: false};
        let result: string = '';
        const cancelled = multiple.map(x => x.cancelled[x.cancelled.length-1]);
        for (let i = 0; i < multiple.length; i++) {
            if(multiple[i].result === null && !cancelled[i]) break;
            result += (multiple[i].result==='W' || cancelled[i]) ? 'W' : 'L';
        }
        let coversMax: number = 0.0;
        let winningsTotal: number = 0.0;
        let probTotal: number = 0.0;
        let finished: boolean = false;
        let nextCover: MultipleNode|undefined = undefined;
        const _bet = parseFloat(bet.value);
        getLeaves(multipleTree).forEach(node => {
            if(result.startsWith(node.value)) {
                result = node.value;
                finished = true;
            }
            // if(node.value === result) finished = true;
            let coversTotal: number = 0.0;

            let winnings: number[] = [(node.multipleResult!==undefined ? node.multipleResult[node.multipleResult.length-1] : 0)-_bet];
            let probs: number[] = [1.0];

            let res: string = node.value[node.value.length-1];
            let prob = node.prob;
            let curr: MultipleNode|undefined = node.parent;
            while(curr) {
                if(curr.value === result) nextCover = curr;
                if(!cancelled[curr.value.length]) {
                    const c = curr.covers[Math.min(curr.value.length, curr.covers.length-1)];
                    const w = c.winnings ?? [0.0,0.0];
                    let p = [prob];
                    let covers = [w[res === 'W' ? 0 : 1]];
                    if(probs.length>1 || (res === 'L' && w.length===3 && c.result!=='L1' && c.result!=='L2')) {
                        p.push(prob);
                        covers.push(covers[0]);
                        if(winnings.length === 1) winnings.push(winnings[0]);
                        if(probs.length === 1) probs.push(probs[0]);
                    }
                    if(res === 'L' && w.length===3) {//L + triscasser
                        if(c.result==='L1' || c.result==='L2') {
                            covers[0] = w[c.result==='L1' ? 1 : 2];
                            if(covers.length>1) covers[1] = covers[0];
                        } else {
                            if(w[1]<w[2]) {covers[0] = w[1]; covers[1] = w[2]; p[0] *= c.probCover1 ?? 0; p[1] *= 1-(c.probCover1 ?? 0);}
                            else {covers[0] = w[2]; covers[1] = w[1]; p[0] *= 1-(c.probCover1 ?? 0); p[1] *= c.probCover1 ?? 0;}
                        }
                    }
                    if(node.value.startsWith(result) && curr.value.length >= result.length) coversTotal -= covers[0];
                    for (let i = 0; i < probs.length; i++) {
                        probs[i] *= p[i];
                        winnings[i] += covers[i];
                    }
                }
                res = curr.parent ? curr.value[curr.value.length-1] : '';
                prob = curr.prob;
                curr = curr.parent;
            }
            if(node.value.startsWith(result)) {
                winningsTotal += probs.reduce((a,p,i) => a+p*winnings[i], 0.0);
                probTotal += probs.reduce((a,p) => a+p, 0.0);
                coversMax = Math.max(coversMax, coversTotal);
            }
            node.winnings = winnings.map(x => x+(isFreebet ? 0 : parseFloat(bonus.value) || 0));
            node.ratings = winnings.map(w => getRating(w, _bet, (parseFloat(isFreebet ? bet.value : refund.value) || 0)));
            node.calculated = node.parent?.calculated;
        })
        const avgWinnings = winningsTotal/probTotal;
        return {coversMax, avgWinnings: avgWinnings+(isFreebet ? 0 : parseFloat(bonus.value) || 0), avgRating: getRating(avgWinnings, _bet, (parseFloat(isFreebet ? bet.value : refund.value) || 0)), nextCover, finished};
    }, [multipleTree])

    useEffect(() => {
        if(editingIndex===-1) return;
        const x: MultipleOddsMatch = {...multiple[editingIndex]};
        const oddsMatch: MultipleOddsMatch = {...x, event: x.event!==null ? {...x.event} : null, modes: [...x.modes], fees: [...x.fees], 
            selections: x.selections.map(y => [...y]), 
            sitesIds: x.sitesIds.map(y => [...y]), 
            sitesUrls: x.sitesUrls.map(y => [...y]),
            odds: x.odds.map(y => y.map(z => ({...z}))),
            cancelled: [...x.cancelled]
        }
        setEditingOddsMatch(oddsMatch);
        const eventId = (multiple[editingIndex].event || {id: -1}).id;
        const eventText = multiple[editingIndex].eventText;
        let d = new Date(multiple[editingIndex].eventDate);
        const eventDate = multiple[editingIndex].eventDate.length>0
            ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
            : {day: '', month: '', year: '', hour: '', minute: '', errorMessage: 'Invalid date'};
        setEditingEvent({eventId, eventText, eventDate});
    }, [editingIndex]);
    
    const handleEventInputChange = (newState: EventInputState) => {
        if(editingEvent && editingEvent.eventId !== newState.eventId) resetEditingOdds(); 
        setEditingEvent(newState);
    }

    const resetEditingOdds = () => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            return {
                ...prevState, 
                selections: prevState.selections.map((x,i) => i===prevState.odds.length-1 ? ['','',''] : x),
                sitesIds: prevState.sitesIds.map((x,i) => i===prevState.odds.length-1 ? [x[0],-1,-1] : x),
                sitesUrls: prevState.sitesUrls.map((x,i) => i===prevState.odds.length-1 ? [null,null,null] : x),
                odds: prevState.odds.map((x,i) => i===prevState.odds.length-1 ? [{value: ''},{value: ''},{value: ''}] : x)
            };
        });
    }

    const saveChanges = () => {
        if(editingIndex===-1 || !editingEvent || !editingOddsMatch) return;
        const n = editingOddsMatch.modes[editingOddsMatch.modes.length-1] === 'tri' ? 3 : 2;
        if(editingEvent.eventDate.errorMessage 
            || editingEvent.eventText.length === 0
            || editingOddsMatch.sitesIds[editingOddsMatch.sitesIds.length-1].some((x,i) => i<n && (i>0 || siteId===-1) && x<0)
            || editingOddsMatch.odds[editingOddsMatch.odds.length-1].some((x,i) => i<n && x.errorMessage)
            || editingOddsMatch.selections[editingOddsMatch.selections.length-1].some((x,i) => i<n && x.length===0)
        ) {
            dispatch(addSnackbar({ type: 'error', message: 'Sono presenti degli errori o dei dati mancanti'}));
            return;
        }
        setMultiple(prevState => {
            const newState = [...prevState.map(x => ({...x}))];
            if(!editingOddsMatch) return newState;
            const multipleStarted = newState.some(x => x.result || x.cancelled[x.cancelled.length-1]);
            newState[editingIndex] = {...editingOddsMatch};
            if(editingEvent) {
                if(!multipleStarted) {
                    newState[editingIndex].eventText = editingEvent.eventText;
                    if(editingEvent.eventId===-1) newState[editingIndex].event = null;
                    else if(editingEvent.eventId !== newState[editingIndex].event?.id) newState[editingIndex].event = events.get(editingEvent.eventId) ?? null;
                }
                const dateString = localeStringToUTCString(`${editingEvent.eventDate.year}-${editingEvent.eventDate.month.padStart(2, '0')}-${editingEvent.eventDate.day.padStart(2, '0')}T${editingEvent.eventDate.hour.padStart(2, '0')}:${editingEvent.eventDate.minute.padStart(2, '0')}`);
                if(newState[editingIndex].eventDate !== dateString) {
                    newState[editingIndex].eventDate = dateString;
                    newState.sort((x,y) => (x.eventDate.length>0 && y.eventDate.length>0 && x.eventDate<y.eventDate) ? -1 : 1);
                    if(editingOddsMatch.event) editingOddsMatch.event.datetime = dateString;
                }
            }
            if(newState[editingIndex].sitesIds[newState[editingIndex].sitesIds.length-1][0]!==siteId) {
                newState[editingIndex].sitesIds[newState[editingIndex].sitesIds.length-1][0] = siteId;
                newState[editingIndex].sitesUrls[newState[editingIndex].sitesUrls.length-1][0] = null;
            }
            return newState;
        });
        setEditingIndex(-1);
    }

    const discardChanges = () => {
        if(multiple.length>0 && multiple[multiple.length-1].eventText==='') removeFromMultiple(multiple.length-1);//Adding custom oddsMatch -> remove
        setEditingIndex(-1);
    }

    const concurrentEvents: boolean = useMemo(() => {
        if(calculators) return false;
        let date: number|null = null;
        let conc = false;
        multiple.forEach(x => {
            const nextDate: number = Date.parse(x.eventDate);
            if(!isNaN(nextDate)) {
                if(date!==null && nextDate-date<1000*60*60*2) {
                    conc = true;
                    return;
                }
                date = nextDate;
            }
        })
        return conc;
    }, [multiple])

    const handleEventsNumChange = (newState: NumberInputState) => {
        if(newState.errorMessage) return;
        const num = parseInt(newState.value);
        let diff = num-multiple.length;
        if(diff<0) setMultiple(prevState => prevState.slice(0, diff));
        if(diff>0) addToCalculatorsMultiple(diff);
    }

    const moveMultipleElement = (index: number, direction: string) => {
        setMultiple(prevState => {
            const newState = [...prevState];
            const i = index+(direction==='up' ? -1 : +1);
            [newState[index], newState[i]] = [newState[i], newState[index]];
            return newState;
        });
    }
    
    
    useEffect(() => {
        if(initializationCompleted) {
            setEventsNum(multiple.length);
        }
    }, [multiple, initializationCompleted])

    const getMultipleData = () => ({description, siteId, bet, bonus, refund, isFreebet, refundType, multipleBonus, multipleBonusType, notifications, baNotifications, multiple});

    const save = async () => {
        const id = getItemFromLocalStorage(multipleIdStorageKey);
        const data = getMultipleData();
        if(data.baNotifications && !(roles ?? []).some(x => x.id === 31)) {
            data.baNotifications = false;
            setBaNotifications(false);
        }
        if(data.description.value.length === 0) {
            data.description = {value: 'Multipla '+formatDate(new Date())};
            setDescription(data.description);
        }
        let _events = undefined;
        let nextCustomEventDatetime: string|undefined = undefined;
        if(!calculators && data.multiple.length>0 && (data.baNotifications || data.notifications) && !info.finished) {
            _events = '|'+data.multiple.map(x => `${x.event===null ? '0' : x.event.id}`).join('|')+'|';
            const cancelled = multiple.map(x => x.cancelled[x.cancelled.length-1]);
            for (let i = 0; i < multiple.length; i++) {
                if(multiple[i].result === null && !cancelled[i]) {
                    if(multiple[i].event == null) nextCustomEventDatetime = multiple[i].eventDate;
                    break;
                }
            }
        }
        const _id = await _saveMultiple({id: id ? parseInt(id) : null, description: data.description.value, data, events: _events, nextCustomEventDatetime}, calculators) as number;
        if(!_id) {
            dispatch(addSnackbar({ type: 'error', message: 'Errore nel salvataggio della multipla'}));
            return;
        }
        if(!id) {
            setItemToLocalStorage(multipleIdStorageKey, _id.toString());
            setMultipleIndex(multiplesList?.length ?? -1);
            dispatch(calculators ? addCalculatorsMultiple({id: _id, description: data.description.value, data}) : addMultiple({id: _id, description: data.description.value, data, events: _events, nextCustomEventDatetime}));
        } else {
            const index = multiplesList ? multiplesList.findIndex(x => x.id===_id) : -1;
            setMultipleIndex(index);
            if(index>-1) dispatch(calculators ? updateCalculatorsMultiple({id: _id, description: data.description.value, data}) : updateMultiple({id: _id, description: data.description.value, data, events: _events, nextCustomEventDatetime}));//update data
        }
        setToSave(false);
        dispatch(addSnackbar({ type: 'success', message: 'Multipla salvata'}));
    }

    const _delete = async (index: number, id: number|null) => {
        if(!id) return;
        if(!await _deleteMultiple(id, calculators)) {
            dispatch(addSnackbar({ type: 'error', message: "Errore nell'eliminazione della multipla"}));
            return;
        }
        if(multipleIndex===index) reset();
        else if(index<multipleIndex) setMultipleIndex(prevIndex =>  prevIndex-1);//Adjust multipleIndex if needed
        dispatch(calculators ? removeCalculatorsMultiple(id) : removeMultiple(id));
        dispatch(addSnackbar({ type: 'success', message: 'Multipla eliminata'}));
        
    }

    const reset = () => {
        removeItemFromLocalStorage(multipleIdStorageKey);
        setMultipleIndex(-1);
        setEditingIndex(-1);
        setObjToSessionStorage(multipleStorageKey, initialData());
        loadFromSessionStorage();
    }

    const duplicate = () => {
        removeItemFromLocalStorage(multipleIdStorageKey);
        setMultipleIndex(-1);
    }
    
    const open = (checked: boolean[]) => {
        if(!multiplesList) return;
        const index =  checked.findIndex(Boolean);
        setMultipleIndex(index);
        if(index<0) {
            reset();
            return;
        }
        const id = multiplesList[index].id?.toString();
        if(id) setItemToLocalStorage(multipleIdStorageKey, id);
        setObjToSessionStorage(multipleStorageKey, multiplesList[index].data);
        loadFromSessionStorage();
    }
    
    const multiplesChecked = multiplesList ? multiplesList.map((_, i) => i===multipleIndex) : [];

    const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
    const sitePresent = multiple.some(x => x.sitesIds[x.sitesIds.length-1][0]>-1);
    const multipleSite: Site|undefined = siteId === 0 ? otherSite : sites.get(siteId);
    const multipleSites: Site[] = (editingIndex>-1 && (!sitePresent || multiple.length===1)) ? sitesList.filter(site => site.type === 'bookmaker') : [];
    if(editingIndex>-1 && (!sitePresent || multiple.length===1)) multipleSites.push(otherSite);
    const multipleSitesChecked = (editingIndex>-1 && (!sitePresent || multiple.length===1)) ? multipleSites.map(x => x.id===multipleSite?.id) : [];
    
    const multipleStarted = multiple.some(x => x.result || x.cancelled[x.cancelled.length-1]);
    const initialMultipleOdds = (multiple.reduce((a, b) => parseFloat(b.odds[b.odds.length-1][0].value)*a, 1.0)*(1+(parseFloat(multipleBonus.value) || 0)/100.0) - (multipleBonusType==='net' ? (parseFloat(multipleBonus.value) || 0)/100.0 : 0)) || 0;
    const multipleOdds = (multiple.reduce((a, b) => (b.cancelled[b.cancelled.length-1] ? 1.0 : parseFloat(b.odds[b.odds.length-1][0].value))*a, 1.0)*(1+(parseFloat(multipleBonus.value) || 0)/100.0) - (multipleBonusType==='net' ? (parseFloat(multipleBonus.value) || 0)/100.0 : 0)) || 0;
    let result: string = '';
    const maxL = (refundType==='lose1' && !isFreebet) && !refund.errorMessage && parseFloat(isFreebet ? bet.value : refund.value)>0.01 ? 1 : 0;
    const cancelled = multiple.map(x => x.cancelled[x.cancelled.length-1]);
    let lastEventFinishedDate = new Date();
    for (let i = 0; i < multiple.length; i++) {
        if((multiple[i].result === null && !cancelled[i]) || (result.match(/L/g) || []).length>maxL) break;
        result += (multiple[i].result==='W' || cancelled[i]) ? 'W' : 'L';
        lastEventFinishedDate = new Date(multiple[i].eventDate);
    }
    const multipleTableRowsMovable = !info.finished || multiple.length-result.length>1 || (multiple.length-result.length===1 && multiple[multiple.length-1].result===null);
    const mutlipleTableColumns = multipleTableRowsMovable ? ['','','Quota',isAppMobile ? 'Tipo' : 'Tipologia','Quota',''] : ['','Quota',isAppMobile ? 'Tipo' : 'Tipologia','Quota',''];
    let filteredSelectionsValues = [...selectionsValues];
    if(editingIndex>-1 && editingOddsMatch && editingOddsMatch.modes[editingOddsMatch.modes.length-1]==='tri') filteredSelectionsValues = selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text));
    if(editingIndex>-1 && editingOddsMatch && editingOddsMatch.modes[editingOddsMatch.modes.length-1]==='pb') filteredSelectionsValues = selectionsValues.filter(x => !['1X','X2','12'].includes(x.text));
    const utcNow = timestampToUTCString((new Date()).getTime()/1000);
    return(
        <BaseFieldset legend="Multipla" padding={10} fitContent={true} minWidth={(visible && !calculators) ? 1027 : undefined} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' openIcon={!visible} onOpenClick={!visible ? changeVisibility : undefined}>
            {visible && 
                <>
                    <ToolButtons>
                        <ToolButton hideOver={445} width='18px' backgroundColor='#800000' onClick={changeVisibility}><CloseSVG/></ToolButton>
                    </ToolButtons>
                    <ToolButtons changePosition={true}>
                        {multipleIndex>-1 && <ToolButton backgroundColor='#843500' onClick={duplicate}>Duplica</ToolButton>}
                        {(toSave && editingIndex<0)  && <ToolButton backgroundColor='rgb(1 79 1)' onClick={save}>Salva</ToolButton>}
                        {(multiplesList && multiplesList.length>0) && 
                            <Dropdown 
                                button={<ToolButton backgroundColor='rgb(114 71 13)'>Apri</ToolButton>}
                                buttonWidth={95}
                                content={
                                    <DropdownContent maxWidth='280px'> 
                                        <SavedElementsContainer>
                                            <CheckboxesGroup maxChecked={1} options={ multiplesList.map((multiple, index) =>  <SavedElementCheckbox checked={index===multipleIndex} backgroundColor='dodgerblue'>{multiple.data.description.value}<TrashIconSVG title='Elimina multipla' onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {event.stopPropagation(); _delete(index, multiple.id);}}/></SavedElementCheckbox>) } checked={multiplesChecked} onOptionClick={(checked: boolean[]) => open(checked)}/>
                                        </SavedElementsContainer>  
                                    </DropdownContent>
                                }
                                top={23}
                            />
                        }
                        <ToolButton backgroundColor='#023e74' onClick={reset}>Nuova</ToolButton>
                        <ToolButton hideUnder={444} width='18px' backgroundColor='#800000' onClick={changeVisibility}><CloseSVG/></ToolButton>
                    </ToolButtons>
                </>
            }
            {visible && 
                <MultipleContainer>
                    <Parameters direction={'row'}>
                        <Col gap='5px' mainAxis='center'>
                            <TextInputWithLabel label='Descrizione' value={description.value} allowEmpty={true} onChange={(newState: TextInputState) => setDescription(newState)}/>
                            <Parameters>
                                {calculators && <NumberInput isDisabled={multipleStarted} label='# Eventi' value={eventsNum.toString()} minValue={0} maxValue={20} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleEventsNumChange(newState)}/>}
                                {!calculators && 
                                    <Row gap='10px' width='150px' >
                                        <Row gap='5px' mainAxis='center'>
                                            <NotificationsLabel>Notifiche:</NotificationsLabel>
                                            <SVGIconContainer size={25} onClick={() => setNotifications(prevState => !prevState)}>{notifications ? <NotificationsOnIconSVG/> : <NotificationsOffIconSVG/>}</SVGIconContainer>
                                        </Row>
                                        {(roles ?? []).some(x => x.id === 31) &&
                                            <Row gap='5px' mainAxis='center'>
                                                <NotificationsLabel title='Notifiche canale Bonus Automatici'>BA:</NotificationsLabel>
                                                <SVGIconContainer size={25} onClick={() => setBaNotifications(prevState => !prevState)}>{baNotifications ? <NotificationsOnIconSVG/> : <NotificationsOffIconSVG/>}</SVGIconContainer>
                                            </Row>
                                        }
                                    </Row>
                                }
                                <SwitchSelectorBig isOption1Active={refundType==='normal'} isDisabled={multipleStarted || isFreebet} isDisabledOpacity={isFreebet ? 0 : 0.75} mode='standard' option1='Normale' option2={'Perdi 1'} width={150} height={36} onSwitch={(isOption1Active: boolean) => setRefundType(isOption1Active ? 'normal' : 'lose1')}/>
                            </Parameters>
                        </Col>
                        {/* {(!calculators && multipleSite && eventsSiteId===siteId) && <SiteBox checked={true} name={multipleSite.name} notClickable={true} width={95} height={36}><SiteLogoContainer visible={true} backgroundColor={multipleSite.color}><SiteLogo imageUrl={multipleSite.imageUrl}/></SiteLogoContainer></SiteBox>}
                        {(!calculators && eventsSiteId!==siteId) &&
                            <Dropdown 
                                buttonText={multipleSite ? undefined : 'Bookmaker'}
                                button={multipleSite ? <SiteBox checked={true} name={multipleSite.name} width={95} height={36}><SiteLogoContainer visible={true} backgroundColor={multipleSite.color}><SiteLogo imageUrl={multipleSite.imageUrl}/></SiteLogoContainer></SiteBox> : undefined}
                                buttonWidth={95}
                                content={
                                    <DropdownContent width={175}>   
                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                multipleSitesChecked.map((site, index) => <SiteBox checked={multipleSitesChecked[index]} name={site.name}><SiteLogoContainer visible={multipleSitesChecked[index]} backgroundColor={site.color}><SiteLogo imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                            } checked={multipleSitesChecked} onOptionClick={(checked: boolean[]) => setSiteId(eventsSiteId ? eventsSiteId : (checked.findIndex(Boolean)>-1 ? multipleSitesChecked[checked.findIndex(Boolean)].id : -1))}/>
                                        </SitesCheckboxes>
                                    </DropdownContent>
                                }
                            />
                        } */}
                        <Col gap='5px' mainAxis='center'>
                            <NumberInput isDisabled={multipleStarted} label='Puntata' value={bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBet(newState)}/>
                            <NumberInput isDisabled={multipleStarted || isFreebet} label='di cui Bonus' value={isFreebet ? bet.value : bonus.value} minValue={0} maxValue={parseFloat(bet.value) || 0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBonus(newState)}/>
                        </Col>
                        <Col gap='5px' mainAxis='center'>
                            {!isFreebet && <NumberInput isDisabled={multipleStarted || isFreebet} isDisabledOpacity={isFreebet ? (isAppMobile ? 0.2 : 0) : 0.75} label='Rimborso' value={refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setRefund(newState)}/>}
                            <SwitchSelector isDisabled={multipleStarted} isFilterActive={isFreebet} width={150} height={36} filterHandler={handleIsFreebetChange} label="Freebet"/>
                        </Col>
                        
                    </Parameters>
                    {calculators &&
                        <Parameters>
                            <MultipleBonusContainer>
                                <NumberInput isDisabled={multipleStarted} label='Bonus multipla' value={multipleBonus.value} labelWidth={isAppMobile ? 65 : undefined} inputWidth={isAppMobile ? 50 : undefined} minValue={0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setMultipleBonus(newState)}/>
                                <MultipleBonusLabel>% sul</MultipleBonusLabel>
                                <SwitchSelectorBig isOption1Active={multipleBonusType==='net'} isDisabled={multipleStarted} mode='standard' option1='Netto' option2='Lordo' width={115} height={36} onSwitch={(isOption1Active: boolean) => setMultipleBonusType(isOption1Active ? 'net' : 'gross')}/>
                            </MultipleBonusContainer>
                            <NumberInput isDisabled={multipleStarted} label='Commissioni' value={fees.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFees(newState)}/>
                        </Parameters>
                    }  
                    <Col gap='20px' margin={(!multipleStarted || info.nextCover) ? (calculators ? '25px 0 15px 0' : '25px 0') : '10px 0'}>
                        {!multipleStarted &&
                            <Row gap='10px' mainAxis='center' height='20px'>
                                <MultipleAmountLabel>Puntata multipla</MultipleAmountLabel>
                                <MultipleAmount>€{bet.errorMessage ? '-' : bet.value}</MultipleAmount>
                            </Row>
                        }               
                        {info.nextCover &&
                            <NextCoverContainer isDisabled={multiple[info.nextCover.value.length].covered}>
                                <CoverTitle>Copertura {info.nextCover.value.length+1}</CoverTitle>
                                <SVGIconContainer top={12} right={12}>
                                    {multiple[info.nextCover.value.length].covered 
                                        ? <CheckboxOnSVG title={'Segna che la copertura è da fare'} onClick={() => info.nextCover && handleCoverCheckboxClick(info.nextCover.value.length)}/>
                                        : <CheckboxOffSVG title={'Segna che la copertura è stata fatta'} onClick={() => info.nextCover && handleCoverCheckboxClick(info.nextCover.value.length)}/>
                                    }
                                </SVGIconContainer>
                                <CoverInstructionsContainer>
                                    {
                                        info.nextCover.covers[info.nextCover.covers.length-1].bets?.map((b,i) => {
                                            if(!info.nextCover) return null;
                                            const oddsMatch = multiple[info.nextCover.value.length];
                                            const site = oddsMatch.sitesIds[oddsMatch.sitesIds.length-1][i+1]===0 ? otherSite : sites.get(oddsMatch.sitesIds[oddsMatch.sitesIds.length-1][i+1]);
                                            const siteUrl = oddsMatch.sitesUrls[oddsMatch.sitesUrls.length-1][i+1];
                                            const resp = info.nextCover.covers[info.nextCover.covers.length-1].resp;
                                            return(
                                                <CoverInstructions key={i}>
                                                    {!calculators && 
                                                        <SiteBox 
                                                            checked={!!site} 
                                                            notClickable={siteUrl===null}
                                                            name={site?.name ?? ''} 
                                                            width={60}
                                                            height={36}
                                                            backgroundColor={!site ? '#000000a3' : site.color}
                                                            onClick={() => site && siteUrl!==null && copyToClipboard && copyToClipboard(siteUrl, `URL ${site.name} copiato`)}
                                                        >
                                                            {(site && site.id===0) && site.name}
                                                            {(site && site.id>0) && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                        </SiteBox>
                                                    }  
                                                    <CoverInstructionsLabelContainer withSite={!calculators}>
                                                        <HorizontalScrollContainer hiddenScrollBar={true}>
                                                            <CoverInstructionsLabel>
                                                                <CoverInstructionsText>{oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                <BetContainer>
                                                                    <Bet translateY={oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 5 : 0}>{'€'+(b.toFixed(oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 2 : 0))}</Bet>
                                                                    {(resp && oddsMatch.modes[oddsMatch.modes.length-1]==='pb') && <Resp title='Responsabilità' translateY={1}>{'€'+(resp[i].toFixed(2))}</Resp>}
                                                                </BetContainer>
                                                                {!calculators &&
                                                                    <>
                                                                        <CoverInstructionsText>esito</CoverInstructionsText>
                                                                        <CoverInstructionsStrongText>{oddsMatch.selections[oddsMatch.selections.length-1][i+1]}</CoverInstructionsStrongText> 
                                                                    </>
                                                                }
                                                                <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                <CoverInstructionsStrongText>{parseFloat(oddsMatch.odds[oddsMatch.odds.length-1][i+1].value).toFixed(2)}</CoverInstructionsStrongText>
                                                            </CoverInstructionsLabel>
                                                        </HorizontalScrollContainer>
                                                    </CoverInstructionsLabelContainer>
                                                </CoverInstructions>
                                            );
                                        })
                                    }
                                </CoverInstructionsContainer>    
                            </NextCoverContainer>
                        }
                    </Col>  
                    <MultipleSectionsContainer>
                        {(!calculators && multiple.length === 0) && <MultipleAdd absolute={false} title='Aggiungi' onClick={addEmptyToMultiple}>+</MultipleAdd>}
                        {(!calculators && multiple.length > 0) &&
                            <MultipleSection>
                                <MultipleList>
                                    {concurrentEvents && 
                                        <ConcurrentEventsWarning>
                                            {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                            <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                            Eventi in contemporanea
                                            <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                            {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                        </ConcurrentEventsWarning>
                                    }
                                    {multiple.map((x, index) => {
                                        const editing: boolean = editingIndex===index && !!editingOddsMatch;
                                        const om: MultipleOddsMatch = editing ? (editingOddsMatch ?? x) : x;
                                        const mode: string = om.modes[om.modes.length-1];
                                        const res = om.cancelled[om.cancelled.length-1] ? 'C' : om.result;                                        
                                        const cover1Site: Site|undefined = om.sitesIds[om.sitesIds.length-1][1]===0 ? otherSite : sites.get(om.sitesIds[om.sitesIds.length-1][1]);
                                        const cover1Sites: Site[] = editing ? sitesList.filter(site => site.type === (mode==='pb' ? 'exchange' :'bookmaker')) : [];
                                        if(editing) cover1Sites.push(otherSite);
                                        const cover1SitesChecked = editing ? cover1Sites.map(x => x.id===cover1Site?.id) : [];
                                        const cover2Site: Site|undefined = mode==='tri' ? (om.sitesIds[om.sitesIds.length-1][2]===0 ? otherSite : sites.get(om.sitesIds[om.sitesIds.length-1][2])) : undefined;
                                        const cover2Sites: Site[] = (editing && mode==='tri') ? sitesList.filter(site => site.type === 'bookmaker') : [];
                                        if(editing && mode==='tri') cover2Sites.push(otherSite);
                                        const cover2SitesChecked = (editing && mode==='tri') ? cover2Sites.map(x => x.id===cover2Site?.id) : [];
                                        return (
                                            <MultipleElement isDisabled={!!res} key={index}>
                                                <MultipleElementEventRow>
                                                    <Result title={resultComment(res)} result={res ?? undefined} onClick={() => handleResultClick(index)}/>
                                                    {!editing
                                                        ? <SVGIconContainer notClickable={res!==null || editingIndex>-1}><EditIconSVG title={!res ? 'Modifica' : ''} onClick={() => !res && editingIndex===-1 && setEditingIndex(prevState => prevState===index ? -1 : index)}/></SVGIconContainer>
                                                        : <>
                                                            <SVGIconContainer><CheckmarkSVG title='Salva modifiche' onClick={saveChanges}/></SVGIconContainer>
                                                            <SVGIconContainer size={13} fill='rgb(255, 66, 66)'><CloseSVG title='Annulla modifiche' onClick={discardChanges}/></SVGIconContainer>
                                                        </>
                                                    }
                                                    {(editing && editingEvent)
                                                        ? <EventInput value={editingEvent} minDate={lastEventFinishedDate} isEventEditable={!multipleStarted} height={24} breakPoint={520} onChange={handleEventInputChange}/>
                                                        : <HorizontalScrollContainer hiddenScrollBar={true}><MultipleElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</MultipleElementEvent></HorizontalScrollContainer>
                                                    }
                                                    {(editingIndex===-1 && !multipleStarted) && <SVGIconContainer><TrashIconSVG onClick={() => removeFromMultiple(index)}/></SVGIconContainer>}
                                                </MultipleElementEventRow>
                                                <MultipleElementOddsMatchRow>
                                                    <MultipleElementOdds>
                                                        {((!sitePresent || multiple.length===1) && editing) ?
                                                            <Dropdown 
                                                                buttonText={multipleSite ? (multipleSite.id===0 ? multipleSite.name : undefined) : 'Book'}
                                                                button={(multipleSite && multipleSite.id>0) 
                                                                    ? <SiteBox checked={true} name={multipleSite.name} width={60} height={36}><SiteLogoContainer visible={true} backgroundColor={multipleSite.color}><SiteLogo width={'95%'} imageUrl={multipleSite.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                    : undefined
                                                                }
                                                                buttonColor={multipleSite ? multipleSite.color : undefined}
                                                                buttonWidth={60}
                                                                content={
                                                                    <DropdownContent width={175}>   
                                                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                multipleSites.map((site, index) => site.id===0 
                                                                                ? <StandardCheckbox checked={multipleSitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                : <SiteBox checked={multipleSitesChecked[index]} name={site.name}><SiteLogoContainer visible={multipleSitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                            } checked={multipleSitesChecked} onOptionClick={(checked: boolean[]) => setSiteId(checked.findIndex(Boolean)>-1 ? multipleSites[checked.findIndex(Boolean)].id : -1)}/>
                                                                        </SitesCheckboxes>
                                                                    </DropdownContent>
                                                                }
                                                            />
                                                            :
                                                            <SiteBox 
                                                                checked={!!multipleSite} 
                                                                notClickable={!(multipleSite && om.sitesUrls[om.sitesUrls.length-1][0] && copyToClipboard)}
                                                                name={multipleSite?.name ?? ''} 
                                                                width={60}
                                                                height={36}
                                                                backgroundColor={!multipleSite ? '#000000a3' : multipleSite.color}
                                                                onClick={() => multipleSite && om.sitesUrls[om.sitesUrls.length-1][0] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][0], `URL ${multipleSite.name} copiato`)}
                                                            >
                                                                {(multipleSite && multipleSite.id===0) && multipleSite.name}
                                                                {(multipleSite && multipleSite.id>0) && <SiteLogoContainer visible={true} backgroundColor={multipleSite.color}><SiteLogo width={'95%'} imageUrl={multipleSite.imageUrl}/></SiteLogoContainer>}
                                                            </SiteBox>
                                                        }
                                                        <NumberInput isDisabled={multipleStarted} 
                                                            value={om.odds.length>0 ? om.odds[om.odds.length-1][0].value : ''} 
                                                            inputPlaceholder='Quota'
                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                            onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 0, newState) : handleOddsChangeEdit(0, newState)}
                                                        />
                                                        <MultipleElementCell>
                                                            {(multipleStarted || !editing)
                                                                ? <MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][0]}</MultipleElementOddsSelection>
                                                                :   <TextInputWithAutocomplete 
                                                                        value={om.selections[om.selections.length-1][0]} 
                                                                        possibleValues={filteredSelectionsValues} 
                                                                        inputPlaceholder='Esito'
                                                                        inputWidth={'60px'}
                                                                        height='100%'
                                                                        fontSize={11}
                                                                        allowInvalid={true}
                                                                        emptyIsInvalid={true}
                                                                        onChange={(newValue: string, index: number) => handleSelectionChangeEdit(0, newValue, index)}
                                                                    />
                                                                    
                                                            }
                                                        </MultipleElementCell>
                                                    </MultipleElementOdds>
                                                    <MultipleElementOddsCoversContainer>
                                                        <MultipleElementOddsCovers>
                                                            {editing &&
                                                                <Select name={"select-type"} value={mode} width={isAppMobile ? '43px' : '105px'} height='36px' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleModeChangeEdit(event.target.value)}>
                                                                    <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                    <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                    <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                </Select>
                                                            }
                                                            <MultipleElementOdds>
                                                                <MultipleElementCell>
                                                                    {!editing && 
                                                                        <SiteBox 
                                                                            checked={!!cover1Site} 
                                                                            notClickable={!(cover1Site && om.sitesUrls[om.sitesUrls.length-1][1] && copyToClipboard)}
                                                                            name={cover1Site?.name ?? ''} 
                                                                            width={60}
                                                                            backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                            onClick={() => cover1Site && om.sitesUrls[om.sitesUrls.length-1][1] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][1], `URL ${cover1Site.name} copiato`)}
                                                                        >
                                                                            {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                            {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                        </SiteBox>
                                                                    }
                                                                    {editing &&                                                   
                                                                        <Dropdown 
                                                                            buttonText={cover1Site ? (cover1Site.id===0 ? cover1Site.name : undefined) : (mode==='pb' ? 'Exch' : 'Book')}
                                                                            button={(cover1Site && cover1Site.id>0)
                                                                                ? <SiteBox checked={true} name={cover1Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                : undefined
                                                                            }
                                                                            buttonColor={cover1Site ? cover1Site.color : undefined}
                                                                            buttonWidth={60}
                                                                            buttonHeight='100%'
                                                                            content={
                                                                                <DropdownContent width={175}>   
                                                                                    <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                            cover1Sites.map((site, index) => site.id===0 
                                                                                                ? <StandardCheckbox checked={cover1SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                : <SiteBox checked={cover1SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover1SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                        } checked={cover1SitesChecked} onOptionClick={(checked: boolean[]) => handleSiteChangeEdit(1, checked.findIndex(Boolean)>-1 ? cover1Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                    </SitesCheckboxes>
                                                                                </DropdownContent>
                                                                            }
                                                                        />
                                                                    }
                                                                </MultipleElementCell> 
                                                                <NumberInput isDisabled={!!res} 
                                                                    value={om.odds.length>0 ? om.odds[om.odds.length-1][1].value : ''} 
                                                                    inputPlaceholder='Quota'
                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                    onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 1, newState) : handleOddsChangeEdit(1, newState)}
                                                                />
                                                                <MultipleElementCell>
                                                                    {!editing
                                                                        ? <MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][1]}</MultipleElementOddsSelection>
                                                                        :   <TextInputWithAutocomplete 
                                                                                value={om.selections[om.selections.length-1][1]} 
                                                                                possibleValues={filteredSelectionsValues} 
                                                                                inputPlaceholder='Esito'
                                                                                inputWidth={'60px'}
                                                                                height='100%' 
                                                                                fontSize={11}
                                                                                allowInvalid={true}
                                                                                emptyIsInvalid={true}
                                                                                onChange={(newValue: string, index: number) => handleSelectionChangeEdit(1, newValue, index)}
                                                                            />
                                                                    }
                                                                </MultipleElementCell>   
                                                            </MultipleElementOdds>
                                                            {mode==='tri' &&
                                                                <MultipleElementOdds>
                                                                    <MultipleElementCell>
                                                                        {!editing && 
                                                                            <SiteBox 
                                                                                checked={!!cover2Site} 
                                                                                notClickable={!(cover2Site && om.sitesUrls[om.sitesUrls.length-1][2] && copyToClipboard)}
                                                                                name={cover2Site?.name ?? ''} 
                                                                                width={60}
                                                                                backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                                onClick={() => cover2Site && om.sitesUrls[om.sitesUrls.length-1][2] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][2], `URL ${cover2Site.name} copiato`)}
                                                                            >
                                                                                {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                                {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                            </SiteBox>
                                                                        }
                                                                        {editing &&
                                                                            <Dropdown 
                                                                                buttonText={cover2Site ? (cover2Site.id===0 ? cover2Site.name : undefined) : 'Book'}
                                                                                button={(cover2Site && cover2Site.id>0)
                                                                                    ? <SiteBox checked={true} name={cover2Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                    : undefined
                                                                                }
                                                                                buttonColor={cover2Site ? cover2Site.color : undefined}
                                                                                buttonWidth={60}
                                                                                buttonHeight='100%'
                                                                                content={
                                                                                    <DropdownContent width={175}>   
                                                                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                cover2Sites.map((site, index) => site.id===0 
                                                                                                ? <StandardCheckbox checked={cover2SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                : <SiteBox checked={cover2SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover2SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                            } checked={cover2SitesChecked} onOptionClick={(checked: boolean[]) => handleSiteChangeEdit(2, checked.findIndex(Boolean)>-1 ? cover2Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                        </SitesCheckboxes>
                                                                                    </DropdownContent>
                                                                                }
                                                                            />
                                                                        }
                                                                    </MultipleElementCell>
                                                                    <NumberInput isDisabled={!!res} 
                                                                        value={om.odds.length>0 ? om.odds[om.odds.length-1][2].value : ''} 
                                                                        inputPlaceholder='Quota'
                                                                        minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                        onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 2, newState) : handleOddsChangeEdit(2, newState)}
                                                                    />
                                                                    <MultipleElementCell>
                                                                        {!editing
                                                                            ? <MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][2]}</MultipleElementOddsSelection>
                                                                            :   <TextInputWithAutocomplete 
                                                                                    value={om.selections[om.selections.length-1][2]} 
                                                                                    possibleValues={filteredSelectionsValues} 
                                                                                    inputPlaceholder='Esito'
                                                                                    inputWidth={'60px'}
                                                                                    height='100%' 
                                                                                    fontSize={11}
                                                                                    allowInvalid={true}
                                                                                    emptyIsInvalid={true}
                                                                                    onChange={(newValue: string, index: number) => handleSelectionChangeEdit(2, newValue, index)}
                                                                                />
                                                                        }
                                                                    </MultipleElementCell>
                                                                </MultipleElementOdds>
                                                            }
                                                        </MultipleElementOddsCovers>
                                                        {editingIndex===-1 && <SVGIconContainer notClickable={res!==null || !om.event}>{om.eventDate>utcNow && <SearchIconSVG title={!res ? 'Cerca copertura alternativa' : ''} onClick={() => !res && searchCovers(index)}/>}</SVGIconContainer>}
                                                    </MultipleElementOddsCoversContainer>
                                                </MultipleElementOddsMatchRow>
                                            </MultipleElement> 
                                        );
                                    })}
                                    <MultipleBonusAndOdds>
                                        <MultipleOddsContainer altValue={Math.abs(multipleOdds-initialMultipleOdds)<0.01 ? undefined : multipleOdds.toFixed(2)}>
                                            {initialMultipleOdds>1.0 ? initialMultipleOdds.toFixed(2) : '-'}
                                            {(!multipleStarted && editingIndex===-1) && <MultipleAdd absolute={true} title='Aggiungi' onClick={addEmptyToMultiple}>+</MultipleAdd>}
                                        </MultipleOddsContainer>
                                        <MultipleBonusContainer>
                                            <MultipleBonusLabel>{isAppMobile ? 'Bonus' : 'Bonus multipla'}</MultipleBonusLabel>
                                            <NumberInput isDisabled={multipleStarted} inputWidth={45} small={true} height={24} value={multipleBonus.value} minValue={0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setMultipleBonus(newState)}/>
                                            <MultipleBonusLabel>{isAppMobile ? '%' : '% sul'}</MultipleBonusLabel>
                                            <SwitchSelectorBig isOption1Active={multipleBonusType==='net'} isDisabled={multipleStarted} mode='standard' option1='Netto' option2='Lordo' width={isAppMobile ? 100 : 115} height={24} padding={3} onSwitch={(isOption1Active: boolean) => setMultipleBonusType(isOption1Active ? 'net' : 'gross')}/>
                                        </MultipleBonusContainer>
                                    </MultipleBonusAndOdds>
                                    {multiple.length>0 &&
                                        <>
                                            <MultipleInfoContainer>
                                                {!info.finished && 
                                                    <>
                                                        <MultipleInfoElement>
                                                            <MultipleInfoLabel>Guadagno medio</MultipleInfoLabel>
                                                            <WinnigsAndRating mobileDirection='column'>
                                                                <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                                <Rating size='m'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                            </WinnigsAndRating>
                                                        </MultipleInfoElement>
                                                        <MultipleInfoElement><MultipleInfoLabel>Importo max coperture</MultipleInfoLabel><MultipleInfoValue>€{info.coversMax!==undefined ? info.coversMax.toFixed(2) : '-'}</MultipleInfoValue></MultipleInfoElement>
                                                        {/* {(multipleTree && !treeVisible) && <SVGIconContainer size={30} top={-30} right={13}><TreeIconSVG title={'Mostra albero multipla'} onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>} */}
                                                    </>
                                                }
                                                {info.finished && 
                                                    <>
                                                        <MultipleFinishedReason>Multipla conclusa</MultipleFinishedReason>
                                                        <WinnigsAndRating>
                                                            <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                            <Rating size='m'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                        </WinnigsAndRating>
                                                    </>
                                                }
                                            </MultipleInfoContainer>
                                            {(multipleTree && !treeVisible) && <SVGIconContainer size={30}><TreeIconSVG title={'Mostra albero multipla'} onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>}
                                        </>
                                    }
                                </MultipleList>
                            </MultipleSection>
                        }
                        {calculators &&
                            <MultipleSection>
                                <BetsTable>
                                    {multiple.length>0 &&
                                        <BetsTableHead>
                                            <BetsTableRow>{mutlipleTableColumns.map((col, index) => <BetsTableHeaderCell key={index}>{col}</BetsTableHeaderCell>)}</BetsTableRow>
                                        </BetsTableHead>
                                    }
                                    <BetsTableBody>
                                        {multiple.map((x, index) => {
                                            const res = x.cancelled[x.cancelled.length-1] ? 'C' : x.result;
                                            const mode = x.modes[x.modes.length-1];
                                            const up: boolean = index>result.length;
                                            const down: boolean = index>=result.length && index<multiple.length-1
                                            return(
                                                <BetsTableRow key={index} showSvg={true}>
                                                    {multipleTableRowsMovable &&
                                                        <BetsTableCell>
                                                            {(up || down) &&
                                                                <ArrowsContainer>
                                                                    <SVGIconContainer size={12} notClickable={!up} fill={up ? undefined : 'gray'}><ArrowUpIcon onClick={() => moveMultipleElement(index, 'up')}/></SVGIconContainer>
                                                                    <SVGIconContainer size={12} notClickable={!down} fill={down ? undefined : 'gray'}><ArrowDownIcon onClick={() => moveMultipleElement(index, 'down')}/></SVGIconContainer>
                                                                </ArrowsContainer>
                                                            }
                                                        </BetsTableCell>
                                                    }
                                                    <BetsTableCell><Result title={resultComment(res)} result={res ?? undefined} onClick={() => handleResultClick(index)}/></BetsTableCell>
                                                    <BetsTableCell><NumberInput isDisabled={multipleStarted} value={x.odds.length>0 ? x.odds[x.odds.length-1][0].value : ''} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => handleOddsChange(index, 0, newState)}/></BetsTableCell>
                                                    <BetsTableCell>
                                                        <Select name={"select-type-"+index} value={mode} disabled={res!==null} width={isAppMobile ? '48px' : '105px'} onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleModeChange(event, index)}>
                                                            <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                            <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                            <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                        </Select>
                                                    </BetsTableCell>
                                                    <BetsTableCell>
                                                        <CoverOdds>
                                                            <NumberInput isDisabled={!!res} value={x.odds.length>0 ? x.odds[x.odds.length-1][1].value : ''} minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} onChange={(newState: NumberInputState) => handleOddsChange(index, 1, newState)}/>
                                                            {mode==='tri' && <NumberInput isDisabled={!!res} value={x.odds.length>0 ? x.odds[x.odds.length-1][2].value : ''} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} onChange={(newState: NumberInputState) => handleOddsChange(index, 2, newState)}/>}
                                                        </CoverOdds>
                                                    </BetsTableCell>
                                                    <BetsTableCell><SVGIconContainer><TrashIconSVG onClick={() => removeFromMultiple(index)}/></SVGIconContainer></BetsTableCell>
                                                </BetsTableRow>
                                            )
                                        })}
                                        <BetsTableRow>
                                            {multipleTableRowsMovable && <BetsTableCell></BetsTableCell>}
                                            <BetsTableCell></BetsTableCell>
                                            <BetsTableCell>{multiple.length>0 && <MultipleOddsContainer altValue={Math.abs(multipleOdds-initialMultipleOdds)<0.01 ? undefined : multipleOdds.toFixed(2)}>{initialMultipleOdds>0.01 ? initialMultipleOdds.toFixed(2) : '-'}</MultipleOddsContainer>}</BetsTableCell>
                                            <BetsTableCell>{multiple.length<20 && <AddToCalculatorsMultiple title='Aggiungi puntata' onClick={() => addToCalculatorsMultiple(1)}>+</AddToCalculatorsMultiple>}</BetsTableCell>
                                            <BetsTableCell></BetsTableCell>
                                            <BetsTableCell></BetsTableCell>
                                        </BetsTableRow>
                                    </BetsTableBody>
                                </BetsTable>
                                {multiple.length>0 &&
                                    <>
                                        <MultipleInfoContainer marginTop='5px'>
                                            {!info.finished && 
                                                <>
                                                    <MultipleInfoElement>
                                                        <MultipleInfoLabel>Guadagno medio</MultipleInfoLabel>
                                                        <WinnigsAndRating mobileDirection='column'>
                                                            <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                            <Rating size='m'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                        </WinnigsAndRating>
                                                    </MultipleInfoElement>
                                                    <MultipleInfoElement><MultipleInfoLabel>Importo max coperture</MultipleInfoLabel><MultipleInfoValue>€{info.coversMax!==undefined ? info.coversMax.toFixed(2) : '-'}</MultipleInfoValue></MultipleInfoElement>
                                                    {/* {(multipleTree && !treeVisible) && <SVGIconContainer size={30} top={-30} right={13}><TreeIconSVG title={'Mostra albero multipla'} onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>} */}
                                                </>
                                            }
                                            {info.finished && 
                                                <>
                                                    <MultipleFinishedReason>Multipla conclusa</MultipleFinishedReason>
                                                    <WinnigsAndRating>
                                                        <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                        <Rating size='m'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                    </WinnigsAndRating>
                                                </>
                                            }
                                        </MultipleInfoContainer>
                                        {(multipleTree && !treeVisible) && <SVGIconContainer size={30}><TreeIconSVG title={'Mostra albero multipla'} onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>}
                                    </>
                                }
                            </MultipleSection>
                        }
                        {(multipleTree && treeVisible) &&
                            <MultipleSection>
                                <SVGIconContainer size={13} fill='#ffffff6e' top={5} right={5}><CloseSVG title='Chiudi albero multipla' onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>
                                <MultipleTree tree={multipleTree} result={result}/>
                            </MultipleSection>
                        }
                    </MultipleSectionsContainer>
                </MultipleContainer> 
            }           
            {(coverOdds && orderedCoverOddsMatches) && <CoverOddsModal eventId={coverOdds.eventId} selection={coverOdds.selection} oddsMatches={orderedCoverOddsMatches} onChangeCoverClick={changeCover} onCloseButtonModal={() => setCoverOdds(undefined)} copyToClipboard={copyToClipboard}/>}
        </BaseFieldset>
    );
}

export default memo(Multiple);