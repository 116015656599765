import { FC } from 'react';

// components
import EventDetails from './EventDetails';

// types
import { EventBoxProps } from './EventBox';

// style
import {
    CircleBoxRef,
    LinkLine,
    TimeLineCircle,
    SmallCenterCirle,
    LinkLineBoxRef,
    EventDetailsCell
} from './style/EventBox.style';


/**
 * RoadMap event box
 * 
 * @author Alessio Grassi
 * 
 * @param imgSrc - source of the rounded image of the event
 * @param date - formatted date of the event (string)
 * @param hour - formatted hour of the eventi (string)
 * @param info - event title
 * @param isBottom - the event is displayed on the bottom of the timeline
 * @param isActive - the event is the "next" one
 * @param isPast - the event is past
 * 
 * @returns JSX
 */


const EventBox: FC<EventBoxProps> = ({
        imgSrc,
        date,
        hour,
        info,
        link,
        isBottom,
        isActive,
        isPast,
        isTbd
    }) => {

    return (
        <TimeLineCircle
            isPast={isPast}
            isActive={isActive}
        >
            <CircleBoxRef>
                <SmallCenterCirle isActive={isActive} />

                <LinkLine
                    isBottom={isBottom}
                    isPast={isPast}
                    isActive={isActive}
                >
                    <LinkLineBoxRef>
                        <EventDetailsCell
                            isBottom={isBottom}
                            isActive={isActive}
                        >
                            <EventDetails
                                imgSrc={imgSrc}
                                date={date}
                                hour={hour}
                                info={info}
                                link={link}
                                isBottom={isBottom}
                                isPast={isPast}
                                isActive={isActive}
                                isTbd={isTbd}
                            />
                        </EventDetailsCell>
                    </LinkLineBoxRef>
                </LinkLine>
            </CircleBoxRef>
        </TimeLineCircle>
    );
}

export default EventBox;
