import colors from 'style/palette';
import styled from 'styled-components';

export const DescriptionBox = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 25px 20px;

    width: 100%;

    background: ${colors.veryDarkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    overflow: hidden;

    p {
        word-wrap: break-word;
        width: 100%;
    }

    img {
        max-width: 100%;
    }
`;


export const LessonTitle = styled.h3`

margin: 17px 0 20px;;
padding: 0; 

font-weight: 400;
font-size: 22px;

color: ${colors.primaryColor};
`;

export const LessonDescription = styled.p`

margin: 0;
padding: 0;

font-weight: 400;
font-size: 13px;
line-height: 24px;

color: ${colors.white};

a {
    color: #FFE662;
}
`;

// export const CurrentLesson = styled.h4`

//     margin: 0;
//     padding: 0;

//     font-weight: 400;
//     font-size: 13px;

//     color: ${colors.white};
// `;

export const AttachmentLink = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    overflow: hidden;

    padding: 5px;
`;

export const LinkAttachment = styled.button`

    display: inline-flex;

    width: 100%;
    /* max-width: 350px; */
    margin: 20px 0 0;
    padding: 15px;

    font-size: 14px;
    font-weight: 500;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border: none;
    border-radius: 5px;
    background: ${colors.primaryColor};
    color: ${colors.black};
    text-transform: uppercase;

    span {

        width: 100%;

        text-align: left;
        white-space: nowrap;
        overflow: hidden;
    }

    svg {
        margin: 0 0 0 10px;
        flex-shrink: 0;
    }
`;
