import styled, { css } from 'styled-components';

// types
import { IconTextBoxProps } from './IconTextBox.style.d';

// style
import colors from 'style/palette/palette';


export const Box = styled.div<IconTextBoxProps>`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 12.5px;
    flex-direction: column;

    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;

    color: ${colors.black};

    @media screen and (min-width: 769px) {

        gap: 12.5px;
    }

    ${({ flavor }) => (flavor === "blue") && (

        css`
            font-size: 24px;
            font-weight: 400;
            
            color: ${colors.blue};
        `
    )}

    ${({ isPointBox }) => isPointBox && (

        css`

            font-size: 30px;
        `
    )}

`;

export const PointsTitle = styled.h3`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 500;
    text-transform: none;
    color: ${colors.black};
`;

export const PointContainer = styled.div`

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        gap: 10px;
`;

export const PointsBox = styled.div``;

export const PointsName = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 600;
    text-transform: none;
`;

export const LibertyPlusIcon = styled.div`
    position: absolute;
    width: 9px;
    height: 9px;
    left: 43px;
    top: 20px;
`;
