import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const SingleContainer = styled.div`

    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    align-items: center;
    
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${devices.mobile} {
        display: grid;
        grid-template-columns: 150px 150px;
        grid-template-rows: 77px 36px;
    }

    row-gap: 5px;
    column-gap: 10px;
    margin-bottom: 20px;
`;

export const BetsContainer = styled.div`
    width: fit-content;
    padding: 10px 0;
    border-top: 2px solid #ffffff94;
    border-bottom: 2px solid #ffffff94;
`;

export const BetInfoHeaderElement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 13px;
`;

export const BetInfo = styled.div<{gridTemplateColumn: string, marginBottom?: string}>`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumn};
    width: fit-content;
    column-gap: 5px;
    margin-bottom: ${props => props.marginBottom ?? '0'};
`;

export const PartiallyMatchedContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
`;

export const PartiallyMatchedRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PartiallyMatchedQuestion = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
`;

export const AvgResult = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${colors.primaryColor};
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    gap: 10px;
`;
