import { FC, useState, useRef, useEffect } from 'react';

// utils
import { getImageFromYoutubeUrl, getYoutubeVideoId } from './VideoPlayerLight.helper';

// types
import {VideoPlayerLightProps} from './VideoPlayerLight.component.d'

// assets
import { ReactComponent as PlayOverlayButtonSVG } from './assets/play-overlay-button.svg';

// style
import { 
  PlayButton,
  VideoElement,
  VideoPlayerContainer,
  VideoImg
} from './style/VideoPlayerLight.component.style';


const VideoPlayerLight: FC<VideoPlayerLightProps> = ({ 
  videoUrl,
  posterUrl,
  isIntoCarousel,
  setIsVideoPaused,
  startCarousel,
  stopCarousel
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [showControls, setShowControls] = useState(false);
  
    const onImageClickHandler = () => {
    
      setIsVideoVisible(true);
      if (stopCarousel) stopCarousel();
      if (setIsVideoPaused) setIsVideoPaused(false);
    };

    const handlePlayButtonClick = () => {
      if (videoRef.current) {
        videoRef.current.play();
        setPlaying(true);
        setShowControls(true);        
        if (setIsVideoPaused) setIsVideoPaused(false);
      }
    };

    const handlePlayClick = () => {
      if (setIsVideoPaused) setIsVideoPaused(false);
    }

    const handlePauseClick = () => {
      // if (setIsVideoPaused) setIsVideoPaused(true); // if active will trigger the carousel to autoscroll if video paused
    }
  
    const onPause = () => {
      if (setIsVideoPaused) setIsVideoPaused(true);
    };
  
    const onPlay = () => {
      if (setIsVideoPaused) setIsVideoPaused(false);
    };
  
    useEffect(() => {

      if (!isVideoVisible) {
        
        // if (startCarousel) startCarousel();
        return;
      };
      handlePlayButtonClick()
    }, [isVideoVisible]);

    const videoCover = getImageFromYoutubeUrl(videoUrl) ?? posterUrl;

    return (
      <VideoPlayerContainer>
        {!isVideoVisible && (
          <VideoImg
            src={videoCover}
            alt='Immagine testimonianza'
            onClick={onImageClickHandler}
            isIntoCarousel={isIntoCarousel}
          />
        )}

        {/* {isVideoVisible && (
          <VideoElement
            ref={videoRef}
            // poster={posterUrl}
            onPlay={handlePlayClick}
            onPause={handlePauseClick}
            controls={showControls}
            // preload='none'
          >
            <source
              src={videoUrl}
              type='video/mp4'
            />
          </VideoElement>
        )} */}

        {isVideoVisible && (
          <iframe 
            title='testimonial video'
            src={`https://www.youtube.com/embed/${getYoutubeVideoId(videoUrl)}?autoplay=1&disablekb=1&modesbranding=1&rel=0&showinfo=0`}
            allow='autoplay'
            allowFullScreen
          />
        )}

        {(!playing || !isVideoVisible) && (
            <PlayButton onClick={onImageClickHandler}>
                <PlayOverlayButtonSVG/>
            </PlayButton>
        )}
      </VideoPlayerContainer>
    );
  };

export default VideoPlayerLight;
