// assets
import { ReactComponent as InfoSVG } from './assets/Info.svg';

//hooks
import { useEffect, useState } from 'react';
import { callGET } from 'network/network';

// styles
import { 
    ConectorDown,
    ConectorUp,
    Conectors,
    GrayText,
    LevelBall,
    LevelBox,
    Levels 
} from "./style/UserLevelComponent.style";

interface UserLevelComponentProps {
    isModal: boolean;
}

const UserLevelComponent: React.FC<UserLevelComponentProps> = ({ isModal }) => {

    const [isLevel, setIsLevel] = useState <number> (0);
    const [userVipPathLevel, setUserVipPathLevel] = useState(null);
    const [pathLevel, setPathLevel] = useState<number>(1);


    const getUserVipPathLevel = async () => {

        try {
            const response = await callGET('v1/self/vippathlevel', true);
            setUserVipPathLevel(response);

            if (response && response.pathLevel) {
                setPathLevel(response.pathLevel);
            }
            
            return response.pathLevel

        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            throw error;
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const pathLevel = await getUserVipPathLevel();
                setIsLevel(pathLevel);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);
    
    const evenLevels = [];
    const oddLevels = [];

    for (let i = 1; i <= 9; i++) {
        const level = (                
            <LevelBall 
                key={i}
                currentLevel={isLevel}
                index={i}
                isModal={isModal}
            >
                <GrayText isModal={isModal} >{i}</GrayText>
            </LevelBall>
        );

        if (i % 2 === 0) {
            evenLevels.push(
                <Conectors isModal={isModal}>
                    {level}
                    <ConectorUp
                        currentLevel={isLevel}
                        index={i}
                        isModal={isModal}
                    />
                </Conectors>
                );
        } else if(i !== 9) {
            oddLevels.push( 
                <Conectors isModal={isModal}>
                    {level}
                    <ConectorDown
                        currentLevel={isLevel}
                        index={i}
                        isModal={isModal}
                    />
                </Conectors>
            );
        } else {
            oddLevels.push(level)
        }
    }

    return (
        <LevelBox >
            <Levels isModal={isModal}>
                {oddLevels}
            </Levels>

            <Levels isModal={isModal}>
                {evenLevels}
            </Levels>
        </LevelBox>
    );
};

export default UserLevelComponent;