import colors from 'style/palette';
import styled from 'styled-components';


export const SwitchBox = styled.div<{width?: number, height?: number, isDisabled: boolean, isDisabledOpacity?: number}>`

    position: relative;
    display: flex;
    z-index: 2 !important;
    transition: 0.3s ease all;
    border-radius: 100px;
    background: ${colors.grey};
    height: ${props => (props.height ? props.height+'px' : '38px')};
    width: ${props => (props.width ? props.width+'px' : '224px')};
    opacity: ${(props) => props.isDisabled ? (props.isDisabledOpacity ?? '0.75') : '1'};
    margin: 0;

`;

export const SwtichActiveBg = styled.div<{isOption1Active?: boolean, padding: number}>`

    background-color: ${colors.primaryColor};
    position: absolute;
    left: ${props => props.isOption1Active ? '0' : '50%'};
    top: 0;
    width: ${props => 'calc(50% - '+props.padding+'px)'};
    height: ${props => 'calc(100% - '+2*props.padding+'px)'};
    z-index: -1;
    transition: all 0.3s ease;
    border-radius: 100px;
    margin: ${props => props.isOption1Active ? `${props.padding}px 0 ${props.padding}px ${props.padding}px` : `${props.padding}px ${props.padding}px ${props.padding}px 0`};
`;

export const SwitchOption = styled.div<{isActive?: boolean, isOption1?: boolean, padding: number, isDisabled: boolean}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.isOption1 ? `${props.padding}px 0 ${props.padding}px ${props.padding}px` : `${props.padding}px ${props.padding}px ${props.padding}px 0`};
    width: 50%;
    height: 100%;
    background: none;
    border: none;
    text-align: center;
    transition: 0.3s ease all;
    cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
    color: ${props => (props.isActive ? colors.grey : colors.white050)};
    font-size: 12px;
    font-weight: ${props => (props.isActive ? '600' : '400')};

`;
