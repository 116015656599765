import { FC, useEffect, useState, useMemo, memo, useRef } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { setItemToLocalStorage, getItemFromLocalStorage } from 'utils/localStorage';
import { _saveMultiple, _getMultiples, _deleteMultiple, _getCoverOdds } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import NumberInput from 'components/ui/NumberInput';
import SwitchSelectorBig from 'components/SwitchSelectorBig';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import MultipleTree from 'pages/OddsScasserPage/OddsScasser/Multiple/MultipleTree';
import CoverOddsModal from 'pages/OddsScasserPage/OddsScasser/Multiple/CoverOddsModal';
import OddsMatchSelector from '../OddsMatchSelector';

//assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as SearchIconSVG } from 'pages/OddsScasserPage/assets/searchIcon.svg';
import { ReactComponent as TreeIconSVG } from 'pages/OddsScasserPage/assets/tree.svg';
import { ReactComponent as CheckboxOnSVG } from 'pages/OddsScasserPage/assets/checkboxOn.svg';
import { ReactComponent as CheckboxOffSVG } from 'pages/OddsScasserPage/assets/checkboxOff.svg';
import { ReactComponent as WarningIconSVG } from 'pages/OddsScasserPage/assets/warning.svg';

//style
import { Parameters, MultipleSection, MultipleSectionsContainer, MultipleElement, MultipleElementEventRow, MultipleElementOdds, MultipleElementOddsCovers, MultipleElementOddsMatchRow, MultipleList, MultipleElementOddsSelection, MultipleElementEvent, MultipleElementOddsCoversContainer, MultipleBonusAndOdds, MultipleBonusContainer, MultipleOddsContainer, MultipleBonusLabel, MultipleFinishedReason, MultipleInfoContainer, MultipleInfoElement, MultipleInfoLabel, MultipleInfoValue, CoverTitle, NextCoverContainer, ConcurrentEventsWarning, MultipleElementCell, MultipleAmountLabel, MultipleAmount } from 'pages/OddsScasserPage/OddsScasser/Multiple/style/Multiple.style';
import { HorizontalScrollContainer, Result, SVGIconContainer, Rating, WinnigsAndRating, Winnings, Bet, BetContainer, Resp, SiteBox, SiteLogo, SiteLogoContainer, CoverInstructions, CoverInstructionsContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, Flex } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { ChooseButton, MultipleSelectorContainer, SearchButton } from './style/MultipleSelector.style';
import { Col, Row } from 'style/layout';

//types
import { MultipleSelectorProps } from './MultipleSelector.component.d';
import { Event } from 'pages/OddsScasserPage/types/Event';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Odds } from 'pages/OddsScasserPage/types/Odds';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { MultipleObj, MultipleOddsMatch } from 'pages/OddsScasserPage/OddsScasser/Multiple/types/MultipleObj';
import { MultipleNode } from 'pages/OddsScasserPage/OddsScasser/Multiple/types/MultipleNode';
import { OddsScasserFilters } from 'pages/OddsScasserPage/OddsScasser/OddsScasserFilter/types/OddsScasserFilters';

//helper functions
import { stepIntegers, stepOdds, formatDate, resultComment, getRating, coverSelectionPuntaPunta, timestampToUTCString, stepOddsPP, getResult } from 'pages/OddsScasserPage/OddsScasserPage.helper';


const MultipleSelector: FC<MultipleSelectorProps> = ({multiple, filters, feesBetfair, feesBetflag, saveMultiple, isHidden, readOnly, saveResult, copyToClipboard}) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events } = useAppSelector(state => state.oddsScasser);
    
    const [chosenMultiple, setChosenMultiple] = useState<MultipleObj>(multiple.id!==null ? multiple : {...multiple, data: {...multiple.data, multiple: multiple.data.multiple.map((x,i) => i===0 ? {
        result: null, covered: false, event: null, eventText: '', eventDate: '', modes: ['pb'], fees: [feesBetfair], selections: [['1', '1']], sitesIds: [[-1,-1]], sitesUrls: [[null,null]], odds: [[{value: ''}, {value: ''}]], cancelled: [false]
    } : x)}});
    const [eventToChoose, setEventToChoose] = useState<{index: number, externalSearch: number}>({index: -1, externalSearch: 0});
    
    const dispatch = useAppDispatch();
    
    const [treeVisible, setTreeVisible] = useState<boolean>(getItemFromLocalStorage('multipleTreeVisible')==='true');
    //Cover odds
    const [coverOdds, setCoverOdds] = useState<{eventId: number, selection: string, odds: number, coverOdds: Odds[]}|undefined>(undefined);

    useEffect(() => {
        if(multiple.id !== null && JSON.stringify(chosenMultiple)!==JSON.stringify(multiple)) setChosenMultiple(multiple);
    }, [multiple]);

    //Save when the multiple changes
    const timer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if(chosenMultiple.id !== null) setEventToChoose(prevState => ({...prevState, index: -1}));
        if(chosenMultiple.id !== null && JSON.stringify(chosenMultiple)!==JSON.stringify(multiple)) {
            if(timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(save, 2000);
            return () => clearTimeout(timer.current);
        }
    }, [chosenMultiple]);

    useEffect(() => {
        setItemToLocalStorage('multipleTreeVisible', treeVisible ? 'true' : 'false');
    }, [treeVisible]);

    //Set results of finished events
    useEffect(() => {
        if(readOnly) return;
        if(events.size === 0) return;
        if(chosenMultiple.id === null) return;
        setChosenMultiple(prevState => {
            if(prevState.data.multiple.length === 0) return prevState;
            //Check results automatically inserted
            let results: (string|null)[] = [];
            const cancelled = prevState.data.multiple.map(x => x.cancelled[x.cancelled.length-1]);
            for (let i = 0; i < prevState.data.multiple.length; i++) {
                if(prevState.data.multiple[i].result === null && !cancelled[i]) break;
                results.push(cancelled[i] ? null: (prevState.data.multiple[i].result==='W' ? 'W' : 'L'));
            }
            if(results.length+1>prevState.data.multiple[0].modes.length) return {...prevState, data: {...prevState.data, multiple: getNewMultipleStateAfterResultChange(prevState.data.multiple, results.length-1, results[results.length-1], results[results.length-1]==null)}};
            //Check results in the events list
            let index: number = -1;
            let result: string|null = null;
            for (let i = 0; i < prevState.data.multiple.length; i++) {
                if(prevState.data.multiple[i].event !== null && prevState.data.multiple[i].result === null && !prevState.data.multiple[i].cancelled[prevState.data.multiple[i].cancelled.length-1]) {
                    const event: Event|undefined = events.get(prevState.data.multiple[i].event?.id ?? 0);
                    if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
                        index = i;
                        result = getResult(prevState.data.multiple[i].modes[prevState.data.multiple[i].modes.length-1], prevState.data.multiple[i].selections[prevState.data.multiple[i].selections.length-1], event.home.score, event.away.score);
                        break;
                    }
                }
            }
            return index<0 ? prevState : {...prevState, data: {...prevState.data, multiple: getNewMultipleStateAfterResultChange(prevState.data.multiple, index, result, false)}};
        });
    }, [events, chosenMultiple]);

    const handleResultClick = (index: number) => {
        if(chosenMultiple.data.bet.errorMessage || chosenMultiple.data.bonus.errorMessage || parseFloat(chosenMultiple.data.bet.value)<0.01 || chosenMultiple.data.refund.errorMessage || chosenMultiple.data.multipleBonus.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Inserire prima i parametri della multipla' }));
            return;
        }
        if(chosenMultiple.data.multiple.some((x,i) => i>index && x.result)) {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile impostare il risultato se sono presenti risultati delle partite successive' }));
            return;
        }
        setChosenMultiple(prevState => {
            let result: string|null = null;
            let canc: boolean = false;
            const _cancelled = prevState.data.multiple[index].cancelled[prevState.data.multiple[index].cancelled.length-1];
            if(prevState.data.multiple.some((x,i) => i<index && !x.result && !x.cancelled[x.cancelled.length-1])) canc = !_cancelled;
            else if(!_cancelled) {
                const results = prevState.data.multiple[index].modes[prevState.data.multiple[index].modes.length-1] === 'tri' ? [null, 'W', 'L1', 'L2', 'C'] : [null, 'W', 'L', 'C'];
                for (let i = 0; i < results.length; i++) {
                    if(results[i] === prevState.data.multiple[index].result) result = results[(i+1)%results.length];
                }
            }
            if(result === 'C') {
                result = null;
                canc = true;
            }
            return {...prevState, data: {...prevState.data, multiple: getNewMultipleStateAfterResultChange(prevState.data.multiple, index, result, canc)}};
        });
    }

    const getNewMultipleStateAfterResultChange = (prevState: MultipleOddsMatch[], index: number, result: string|null, canc: boolean) => {
        let n: number = 1;
        for (let i = 0; i < prevState.length; i++) {
            const r = i===index ? result : prevState[i].result;
            const c = i===index ? canc : prevState[i].cancelled[prevState[i].cancelled.length-1];
            if(r === null && !c) break;
            n += 1;
        }
        return prevState.map((x,i) => {
            //modes
            let diff = n-x.modes.length; 
            const modes = diff<0 ? x.modes.slice(0, diff) : [...x.modes]; 
            if(diff>0) modes.push(...Array.from({length:diff}, () => x.modes[x.modes.length-1]));
            //fees
            diff = n-x.fees.length; 
            const _fees = diff<0 ? x.fees.slice(0, diff) : [...x.fees]; 
            if(diff>0) _fees.push(...Array.from({length:diff}, () => x.fees[x.fees.length-1]));
            //selections
            diff = n-x.selections.length; const selections = diff<0 ? x.selections.slice(0, diff) : [...x.selections]; 
            if(diff>0) selections.push(...Array.from({length:diff}, () => [...x.selections[x.selections.length-1]]));
            //sitesIds
            diff = n-x.sitesIds.length; const sitesIds = diff<0 ? x.sitesIds.slice(0, diff) : [...x.sitesIds]; 
            if(diff>0) sitesIds.push(...Array.from({length:diff}, () => [...x.sitesIds[x.sitesIds.length-1]]));
            //siteUrls
            diff = n-x.sitesUrls.length; const sitesUrls = diff<0 ? x.sitesUrls.slice(0, diff) : [...x.sitesUrls]; 
            if(diff>0) sitesUrls.push(...Array.from({length:diff}, () => [...x.sitesUrls[x.sitesUrls.length-1]]));
            //odds
            diff = n-x.odds.length; const odds = diff<0 ? x.odds.slice(0, diff) : [...x.odds]; 
            if(diff>0) odds.push(...Array.from({length:diff}, () => [...x.odds[x.odds.length-1].map(x => ({...x}))]));
            //cancelled
            diff = n-x.cancelled.length; const cancelled = diff<0 ? x.cancelled.slice(0, diff) : [...x.cancelled]; 
            if(diff>0) cancelled.push(...Array.from({length:diff}, () => x.cancelled[x.cancelled.length-1]));
            if(i===index) cancelled[cancelled.length-1] = canc;
            return {
                result: index===i ? result : x.result, 
                covered: x.covered,
                event: x.event,
                eventText: x.eventText,
                eventDate: x.eventDate,
                modes, fees: _fees, selections, sitesIds, sitesUrls, odds, cancelled
            };
        });
    }

    const handleOddsChange = (index: number, oddsIndex: number, newState: NumberInputState) => {
        setChosenMultiple(prevState => ({
            ...prevState, data: {
                ...prevState.data,
                multiple: prevState.data.multiple.map((x,i) => {
                    if(i===index) {
                        const odds = x.odds.map((y,j) => j===x.odds.length-1 ? y.map((z,k) => k===oddsIndex ? newState : {...z}) : y);
                        return {...x, odds};
                    }
                    return x;
                })
            }
        }));
    }
    
    const handleCoverCheckboxClick = (index: number) => {
        setChosenMultiple(prevState => ({
            ...prevState, data: {
                ...prevState.data,
                multiple: prevState.data.multiple.map((x,i) => i===index ? {...x, covered: !x.covered} : x)
            }
        }));
    }

    const searchCovers = async (index: number) => {
        const odds = chosenMultiple.data.multiple[index].odds[chosenMultiple.data.multiple[index].odds.length-1][0];
        if(odds.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Quota multipla non valida'}));
            return;
        }
        const selection =  chosenMultiple.data.multiple[index].selections[chosenMultiple.data.multiple[index].selections.length-1][0];
        if(!coverSelectionPuntaPunta.has(selection)) {
            dispatch(addSnackbar({ type: 'error', message: 'Esito non valido'}));
            return;
        }
        const params = {eventId: chosenMultiple.data.multiple[index].event?.id, selection};
        if(params.eventId) setCoverOdds({eventId: params.eventId, selection: params.selection, odds: parseFloat(odds.value), coverOdds: await _getCoverOdds(params) as Odds[]});
        else {
            dispatch(addSnackbar({ type: 'error', message: 'Impossibile fare la ricerca per un evento personalizzato inserito manualmente'}));
            return;
        }
    }
    
    const orderedCoverOddsMatches: OddsMatch[]|undefined = useMemo(() => {
        if(!coverOdds) return undefined;
        const siteId: number = chosenMultiple.data.siteId;
        const oddsMatches: OddsMatch[] = [];
        coverOdds.coverOdds.filter(x => (x.type===coverSelectionPuntaPunta.get(coverOdds.selection) && x.site.id!==siteId)).forEach(o => {
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/o.odds)});
        });
        coverOdds.coverOdds.filter(x => (x.type===coverOdds.selection && sites.get(x.site.id)?.type==='exchange')).forEach(o => {
            const _fees = parseFloat(o.site.id===9 ? feesBetflag : feesBetfair)/100;
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/((o.odds-_fees)/(o.odds-1)))});
        });
        if(['1','X','2'].includes(coverOdds.selection)) {
            let sels: string[] = ['1','X','2']; sels.splice(sels.indexOf(coverOdds.selection), 1);
            const cover1 = coverOdds.coverOdds.filter(x => (x.type===sels[0] && x.site.id!==siteId));
            const cover2 = coverOdds.coverOdds.filter(x => (x.type===sels[1] && x.site.id!==siteId));
            cover1.forEach(c1 => {
                cover2.forEach(c2 => {
                    if(siteId!==c1.site.id && siteId!==c2.site.id) oddsMatches.push({event: {id: coverOdds.eventId}, odds: [c1, c2], rating: coverOdds.odds*(1-1/c1.odds-1/c2.odds)});
                });
            });
        }
        oddsMatches.sort((x,y) => y.rating-x.rating);
        return oddsMatches;
    }, [coverOdds]);
    
    const changeCover = (eventId: number, oddsMatch: OddsMatch) => {
        setChosenMultiple(prevState => ({
            ...prevState, data: {
                ...prevState.data,
                multiple: prevState.data.multiple.map(x => {
                    if(x.event && x.event.id === eventId) {
                        const newMode = oddsMatch.odds.length>1 ? 'tri' : (sites.get(oddsMatch.odds[0].site.id)?.type==='exchange' ? 'pb' : 'pp');
                        const modes = x.modes.map((y,j) => j===x.odds.length-1 ? newMode : y);
                        const _fees = x.fees.map((y,j) => j===x.odds.length-1 ? (newMode==='pb' ? (oddsMatch.odds[0].site.id===9 ? feesBetflag : feesBetfair) : null) : y);
                        const newSelections: string[] = oddsMatch.odds.map(y => y.type ?? ''); if(newSelections.length===1) newSelections.push('');
                        const selections = x.selections.map((y,j) => j===x.selections.length-1 ? [y[0], ...newSelections] : y);
                        const newSitesIds: number[] = oddsMatch.odds.map(y => y.site.id); if(newSitesIds.length===1) newSitesIds.push(-1);
                        const sitesIds = x.sitesIds.map((y,j) => j===x.sitesIds.length-1 ? [y[0], ...newSitesIds] : y);
                        const newSitesUrls: (string|null)[] = oddsMatch.odds.map(y => y.url ?? null); if(newSitesUrls.length===1) newSitesUrls.push(null);
                        const sitesUrls = x.sitesUrls.map((y,j) => j===x.sitesUrls.length-1 ? [y[0], ...newSitesUrls] : y);
                        const newOdds: NumberInputState[] = oddsMatch.odds.map(y => ({value: y.odds.toFixed(2)})); if(newOdds.length===1) newOdds.push({value: '', errorMessage: 'NaN'});
                        const odds = x.odds.map((y,j) => j===x.odds.length-1 ? [y[0], ...newOdds] : y);
                        return {...x, modes, fees: _fees, selections, sitesIds, sitesUrls, odds};
                    }
                    return x;
                })
            }
        }));
    }
    
    const generateTree = (node: MultipleNode, depth: number) => {
        const maxL = (chosenMultiple.data.refundType==='lose1' && !chosenMultiple.data.isFreebet) && (!chosenMultiple.data.refund.errorMessage && (chosenMultiple.data.isFreebet || parseFloat(chosenMultiple.data.refund.value)>0.01)) ? 1 : 0;
        const countL = (node.value.match(/L/g) || []).length;
        if(countL <= maxL && depth>=0) {
            const odds = chosenMultiple.data.multiple[node.value.length].odds[0][0];
            const p = odds.errorMessage ? 0.5 : (chosenMultiple.data.multiple[node.value.length].cancelled[chosenMultiple.data.multiple[node.value.length].cancelled.length-1] ? 1.0 : 1/parseFloat(odds.value));
            node.w = {parent: node, value: node.value+'W', prob: node.prob<0.0001 ? 0 : p, covers: Array.from({length: chosenMultiple.data.multiple[node.value.length].odds.length}, () => ({}))};
            node.l = {parent: node, value: node.value+'L', prob: node.prob<0.0001 ? 0 : 1-p, covers: Array.from({length: chosenMultiple.data.multiple[node.value.length].odds.length}, () => ({}))};
            generateTree(node.w, depth-1);
            generateTree(node.l, depth-1);
        } else {//leaf
            node.multipleResult = [];
            if(node.value.includes('L')) {
                const r = (countL===1 || (countL>0 && maxL===0)) ? (parseFloat(chosenMultiple.data.isFreebet ? chosenMultiple.data.bet.value : chosenMultiple.data.refund.value) || 0) : 0.0;
                for (let i = 0; i < node.covers.length; i++) node.multipleResult.push(r);
            }
            else {
                const _bet = parseFloat(chosenMultiple.data.bet.value);
                for (let i = 0; i < node.covers.length; i++) {
                    const multipleOdds = chosenMultiple.data.multiple.reduce((a, b) => (b.cancelled[i] ? 1.0 : parseFloat(b.odds[i][0].value))*a, 1.0)*(1+(parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0) - (chosenMultiple.data.multipleBonusType==='net' ? (parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0 : 0);
                    node.multipleResult.push(Math.round(_bet*multipleOdds*100)*0.01);
                }
            }
        }
        return node;
    }

    const getDescOrderedNodes = (tree: MultipleNode) => {
        const queue = [tree];
        const nodes = [];
        while (queue.length !== 0) {
            const front = queue[0];
            if(front.w && front.w.w) queue.push(front.w);
            if(front.l && front.l.w) queue.push(front.l);
            nodes.push(front);
            queue.shift();
        }
        nodes.reverse();
        return nodes;
    }

    const getLeaves = (tree: MultipleNode) => {
        const queue = [tree];
        const nodes = [];
        while (queue.length !== 0) {
            const front = queue[0];
            if(front.w) queue.push(front.w);
            if(front.l) queue.push(front.l);
            else if(front.prob>0.0001) nodes.push(front);
            queue.shift();
        }
        return nodes;
    }

    const calculateCovers = (tree: MultipleNode) => {
        const nodes = getDescOrderedNodes(tree);
        for (let i = 0; i < tree.covers.length; i++) {
            nodes.forEach(node => {
                node.calculated = true;
                const index = node.value.length;
                if(chosenMultiple.data.multiple[index].result!==null && i>index) return;//Don't calculate covers already made
                if(chosenMultiple.data.multiple[index].cancelled[i]) return;
                let allW = 0.0;
                let curr = node.w;
                while(curr) {
                    allW += curr.multipleResult!==undefined ? curr.multipleResult[i] : (curr.covers[i].winnings ?? [0])[0];
                    curr = curr.w;
                }
                let allL = 0;
                curr = node.l;
                while(curr) {
                    allL += curr.multipleResult!==undefined ? curr.multipleResult[i] : (curr.covers[i].winnings ?? [0])[0];
                    curr = curr.w;
                }
                node.covers[i].result = chosenMultiple.data.multiple[index].result;
                if(chosenMultiple.data.multiple[index].modes[i] === 'pb') {
                    const round = 0.01;
                    const odds = parseFloat(chosenMultiple.data.multiple[index].odds[i][1].value);
                    const _fees = parseFloat(chosenMultiple.data.multiple[index].fees[i] ?? feesBetfair)/100.0;
                    const oddsP = (odds-_fees)/(odds-1);//Equivalent odds in punta
                    const resp = odds<1.001 ? 0 : (allW-allL)/oddsP;
                    const _bet = odds<1.001 ? 0 : Math.max(0, Math.round(resp*(oddsP-1)/(1-_fees)/round))*round;
                    node.covers[i].bets = [_bet];//set locked bet
                    node.covers[i].resp = [_bet*(odds-1)];
                    node.covers[i].winnings = [Math.round(-resp/round)*round, Math.round(_bet*(1-_fees)/round)*round];
                } else if(chosenMultiple.data.multiple[index].modes[i] === 'pp') {
                    const round = 1;
                    const odds = parseFloat(chosenMultiple.data.multiple[index].odds[i][1].value);
                    const _bet = Math.max(1, Math.round((allW-allL)/odds/round)*round);
                    node.covers[i].bets = [_bet];//set locked bet
                    node.covers[i].resp = node.covers[i].bets;
                    node.covers[i].winnings = [-_bet, _bet*(odds-1)];
                } else {
                    const round = 1;
                    const odds = chosenMultiple.data.multiple[index].odds[i].slice(1).map(x => parseFloat(x.value));
                    node.covers[i].bets = odds.map(x => Math.max(1, Math.round((allW-allL)/x/round)*round));//set locked bet
                    node.covers[i].resp = node.covers[i].bets;
                    const resp = (node.covers[i].resp ?? []).reduce((a,b) => a+b, 0.0);
                    node.covers[i].probCover1 = 1/odds[0]/(1/odds[0]+1/odds[1]);
                    node.covers[i].winnings = [-resp, (node.covers[i].bets ?? [0])[0]*odds[0]-resp, (node.covers[i].bets ?? [0,0])[1]*odds[1]-resp];
                }
            });
        }
    }

    const multipleTree: MultipleNode|undefined = useMemo(() => {
        if(chosenMultiple.data.multiple.length===0) return;
        const tree = generateTree({value: '', prob: 1.0, covers: Array.from({length: chosenMultiple.data.multiple[0].odds.length}, () => ({}))}, chosenMultiple.data.multiple.length-1);
        const oddsNotOk = chosenMultiple.data.multiple.some(x => {
            const n = x.modes[x.modes.length-1] === 'tri' ? 3 : 2;
            return x.odds[x.odds.length-1].some((y,i) => i<n && y.errorMessage)
        })
        if(!(chosenMultiple.data.multiple[0].event===null || chosenMultiple.data.bet.errorMessage || chosenMultiple.data.bonus.errorMessage || parseFloat(chosenMultiple.data.bet.value)<0.01 || chosenMultiple.data.refund.errorMessage || chosenMultiple.data.multipleBonus.errorMessage || oddsNotOk))
            calculateCovers(tree);
        return tree;
    }, [chosenMultiple, multiple])

    const info: {coversMax?: number, avgWinnings?: number, avgRating?: number, nextCover?: MultipleNode, finished: boolean} = useMemo(() => {
        if(!multipleTree || !multipleTree.calculated) return {finished: false};
        let result: string = '';
        const cancelled = chosenMultiple.data.multiple.map(x => x.cancelled[x.cancelled.length-1]);
        for (let i = 0; i < chosenMultiple.data.multiple.length; i++) {
            if(chosenMultiple.data.multiple[i].result === null && !cancelled[i]) break;
            result += (chosenMultiple.data.multiple[i].result==='W' || cancelled[i]) ? 'W' : 'L';
        }
        let coversMax: number = 0.0;
        let winningsTotal: number = 0.0;
        let probTotal: number = 0.0;
        let finished: boolean = false;
        let nextCover: MultipleNode|undefined = undefined;
        const _bet = parseFloat(chosenMultiple.data.bet.value);
        getLeaves(multipleTree).forEach(node => {
            if(result.startsWith(node.value)) {
                result = node.value;
                finished = true;
            }
            // if(node.value === result) finished = true;
            let coversTotal: number = 0.0;

            let winnings: number[] = [(node.multipleResult!==undefined ? node.multipleResult[node.multipleResult.length-1] : 0)-_bet];
            let probs: number[] = [1.0];

            let res: string = node.value[node.value.length-1];
            let prob = node.prob;
            let curr: MultipleNode|undefined = node.parent;
            while(curr) {
                if(curr.value === result) nextCover = curr;
                if(!cancelled[curr.value.length]) {
                    const c = curr.covers[Math.min(curr.value.length, curr.covers.length-1)];
                    const w = c.winnings ?? [0.0,0.0];
                    let p = [prob];
                    let covers = [w[res === 'W' ? 0 : 1]];
                    if(probs.length>1 || (res === 'L' && w.length===3 && c.result!=='L1' && c.result!=='L2')) {
                        p.push(prob);
                        covers.push(covers[0]);
                        if(winnings.length === 1) winnings.push(winnings[0]);
                        if(probs.length === 1) probs.push(probs[0]);
                    }
                    if(res === 'L' && w.length===3) {//L + triscasser
                        if(c.result==='L1' || c.result==='L2') {
                            covers[0] = w[c.result==='L1' ? 1 : 2];
                            if(covers.length>1) covers[1] = covers[0];
                        } else {
                            if(w[1]<w[2]) {covers[0] = w[1]; covers[1] = w[2]; p[0] *= c.probCover1 ?? 0; p[1] *= 1-(c.probCover1 ?? 0);}
                            else {covers[0] = w[2]; covers[1] = w[1]; p[0] *= 1-(c.probCover1 ?? 0); p[1] *= c.probCover1 ?? 0;}
                        }
                    }
                    if(node.value.startsWith(result) && curr.value.length >= result.length) coversTotal -= covers[0];
                    for (let i = 0; i < probs.length; i++) {
                        probs[i] *= p[i];
                        winnings[i] += covers[i];
                    }
                }
                res = curr.parent ? curr.value[curr.value.length-1] : '';
                prob = curr.prob;
                curr = curr.parent;
            }
            if(node.value.startsWith(result)) {
                winningsTotal += probs.reduce((a,p,i) => a+p*winnings[i], 0.0);
                probTotal += probs.reduce((a,p) => a+p, 0.0);
                coversMax = Math.max(coversMax, coversTotal);
            }
            node.winnings = winnings.map(x => x+(chosenMultiple.data.isFreebet ? 0 : parseFloat(chosenMultiple.data.bonus.value) || 0));
            node.ratings = winnings.map(w => getRating(w, _bet, (parseFloat(chosenMultiple.data.isFreebet ? chosenMultiple.data.bet.value : chosenMultiple.data.refund.value) || 0)));
            node.calculated = node.parent?.calculated;
        })
        const avgWinnings = winningsTotal/probTotal+(chosenMultiple.data.isFreebet ? 0 : parseFloat(chosenMultiple.data.bonus.value) || 0);
        
        return {coversMax, avgWinnings, avgRating: getRating(avgWinnings, _bet, (parseFloat(chosenMultiple.data.isFreebet ? chosenMultiple.data.bet.value : chosenMultiple.data.refund.value) || 0)), nextCover, finished};
    }, [multipleTree])

    //Send result to bonusPage
    useEffect(() => {
        if(chosenMultiple.id!==null) saveResult((info.finished ? info.avgWinnings : null) ?? null);
    }, [info]);

    const concurrentEvents: boolean = useMemo(() => {
        let date: number|null = null;
        let conc = false;
        chosenMultiple.data.multiple.forEach(x => {
            const nextDate: number = Date.parse(x.eventDate);
            if(!isNaN(nextDate)) {
                if(date!==null && nextDate-date<1000*60*60*2) {
                    conc = true;
                    return;
                }
                date = nextDate;
            }
        })
        return conc;
    }, [chosenMultiple, multiple])

    const save = async () => {
        let _events = undefined;
        let nextCustomEventDatetime: string|undefined = undefined;
        if(chosenMultiple.data.multiple.length>0 && !info.finished) {
            _events = '|'+chosenMultiple.data.multiple.map(x => `${x.event===null ? '0' : x.event.id}`).join('|')+'|';
            const cancelled = chosenMultiple.data.multiple.map(x => x.cancelled[x.cancelled.length-1]);
            for (let i = 0; i < chosenMultiple.data.multiple.length; i++) {
                if(chosenMultiple.data.multiple[i].result === null && !cancelled[i]) {
                    if(chosenMultiple.data.multiple[i].event == null) nextCustomEventDatetime = chosenMultiple.data.multiple[i].eventDate;
                    break;
                }
            }
        }
        saveMultiple({...chosenMultiple, events: _events, nextCustomEventDatetime});
    }

    const handleOddsMatchChoice = (oddsMatch: OddsMatch) => {
        const eventId = oddsMatch.event.id;
        if(eventId>0 && chosenMultiple.data.multiple.some((x,i) => i!==eventToChoose.index && x.event?.id === eventId)) {
            dispatch(addSnackbar({ type: 'error', message: 'Evento già presente nella multipla'}));
            return;
        }
        const selections: string[] = oddsMatch.odds.map(x => x.type ?? '');
        if(selections.length<3) selections.push('');
        const odds: NumberInputState[] = oddsMatch.odds.map(x => ({value: x.odds.toFixed(2)}));
        if(odds.length<3) odds.push({value: '', errorMessage: 'NaN'});
        const oddsInfo: (Odds|null)[] = [...oddsMatch.odds];
        if(oddsInfo.length<3) oddsInfo.push(null);
        const mode = oddsMatch.odds[0].type === oddsMatch.odds[1].type ? 'pb' : (oddsMatch.odds.length>2 ? 'tri' : 'pp');
        const _fees = mode === 'pb' && oddsInfo[1] ? (oddsInfo[1].site.id===9 ? feesBetflag : feesBetfair) : null;
        const event = events.get(oddsMatch.event.id) ?? null;
        let eventText = '';
        if(event) {
            eventText = `${event.home?.name} - ${event.away?.name}`;
            const league = event.league;
            if(league) {
                const country = league.country;
                const leagueName = league.name;
                if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
            }
        }
        const newElement: MultipleOddsMatch = {
            result: null, 
            covered: false,
            event,
            eventText,
            eventDate: (event!==null && event.datetime) ? event.datetime : '',
            modes: [mode],
            fees: [_fees],
            selections: [selections],
            sitesIds: [oddsInfo.map((x,i) => x?.site.id ?? -1)],
            sitesUrls: [oddsInfo.map((x,i) => x?.url ?? null)],
            odds: [odds],
            cancelled: [false]
        }
        setChosenMultiple(prevState => {
            const newMultiple = prevState.data.multiple.map((x,i) => i===eventToChoose.index ? newElement : x);
            newMultiple.sort((x,y) => (x.event?.datetime && y.event?.datetime && x.event.datetime<y.event.datetime) ? -1 : 1);
            setEventToChoose(prevState => ({...prevState, index: newMultiple.findIndex(x => x.event?.id===oddsMatch.event.id)}))
            return {...prevState, id: null, data: {...prevState.data, multiple: newMultiple}};
        });
    }

    const handleBetChange = (newState: NumberInputState) => setChosenMultiple(prevState => ({...prevState, data: {...prevState.data, bet: newState}}));
    const handleBonusChange = (newState: NumberInputState) => setChosenMultiple(prevState => ({...prevState, data: {...prevState.data, bonus: newState}}));
    const handleRefundChange = (newState: NumberInputState) => setChosenMultiple(prevState => ({...prevState, data: {...prevState.data, refund: newState}}));

    const search = (index: number) => {
        setEventToChoose({index, externalSearch: (new Date()).getTime()})
    }

    if(isHidden) return <></>;

    const multipleStarted = chosenMultiple && chosenMultiple.data.multiple.some(x => x.result || x.cancelled[x.cancelled.length-1]);
    const multipleSite: Site|undefined = sites.get(chosenMultiple.data.siteId);
    const initialMultipleOdds = (chosenMultiple.data.multiple.reduce((a, b) => parseFloat(b.odds[b.odds.length-1][0].value)*a, 1.0)*(1+(parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0) - (chosenMultiple.data.multipleBonusType==='net' ? (parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0 : 0)) || 0;
    const multipleOdds = (chosenMultiple.data.multiple.reduce((a, b) => (b.cancelled[b.cancelled.length-1] ? 1.0 : parseFloat(b.odds[b.odds.length-1][0].value))*a, 1.0)*(1+(parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0) - (chosenMultiple.data.multipleBonusType==='net' ? (parseFloat(chosenMultiple.data.multipleBonus.value) || 0)/100.0 : 0)) || 0;
    let result: string = '';
    const maxL = (chosenMultiple.data.refundType==='lose1' && !chosenMultiple.data.isFreebet) && !chosenMultiple.data.refund.errorMessage && parseFloat(chosenMultiple.data.isFreebet ? chosenMultiple.data.bet.value : chosenMultiple.data.refund.value)>0.01 ? 1 : 0;
    const cancelled = chosenMultiple.data.multiple.map(x => x.cancelled[x.cancelled.length-1]);
    let lastEventFinishedDate = new Date();
    for (let i = 0; i < chosenMultiple.data.multiple.length; i++) {
        if((chosenMultiple.data.multiple[i].result === null && !cancelled[i]) || (result.match(/L/g) || []).length>maxL) break;
        result += (chosenMultiple.data.multiple[i].result==='W' || cancelled[i]) ? 'W' : 'L';
        lastEventFinishedDate = new Date(chosenMultiple.data.multiple[i].eventDate);
    }
    const utcNow = timestampToUTCString((new Date()).getTime()/1000);
    // const dateTo: Date = new Date(chosenMultiple.data.multiple[1].eventDate); dateTo.setTime(dateTo.getTime()-2*60*60*1000);
    const _filters: OddsScasserFilters = {...filters, bet: '100', bonus: '0', refund: '0', isFreebet: false, feesBetfair, feesBetflag}
    return(
        <MultipleSelectorContainer readOnly={readOnly}>
            <Col gap='20px' width='100%'>
                <Parameters direction={'row'}>
                    <Col gap='5px' mainAxis='center'>
                        <NumberInput label='Puntata' isDisabled={multipleStarted} isDisabledOpacity={1} value={chosenMultiple.data.bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBetChange(newState)}/>
                        <NumberInput isDisabled={multipleStarted || chosenMultiple.data.isFreebet} isDisabledOpacity={chosenMultiple.data.isFreebet ? undefined : 1} label='di cui Bonus' value={chosenMultiple.data.isFreebet ? chosenMultiple.data.bet.value : chosenMultiple.data.bonus.value} minValue={0} maxValue={parseFloat(chosenMultiple.data.bet.value) || 0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBonusChange(newState)}/>
                    </Col>
                    {(parseFloat(chosenMultiple.data.refund.value)>0 || chosenMultiple.data.isFreebet) &&
                        <Col gap='5px' mainAxis='center'>
                            <NumberInput isDisabled={multipleStarted || chosenMultiple.data.isFreebet} isDisabledOpacity={chosenMultiple.data.isFreebet ? (isAppMobile ? 0.2 : 0) : 0.75} label='Rimborso' value={chosenMultiple.data.refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleRefundChange(newState)}/>
                            <SwitchSelector isFilterActive={chosenMultiple.data.isFreebet} width={150} height={36} label="Freebet"/>
                        </Col>
                    }
                    <Col gap='5px' mainAxis='center'>
                        <SwitchSelectorBig isOption1Active={chosenMultiple.data.refundType==='normal'} isDisabled={true} isDisabledOpacity={1} mode='standard' option1='Normale' option2={'Perdi 1'} width={150} height={36}/>
                    </Col>
                </Parameters>
                {chosenMultiple.id!==null && (!multipleStarted || info.nextCover) &&
                    <Col gap='20px'>
                        {!multipleStarted &&
                            <Row gap='10px' mainAxis='center' height='20px'>
                                <MultipleAmountLabel>Puntata multipla</MultipleAmountLabel>
                                <MultipleAmount>€{chosenMultiple.data.bet.errorMessage ? '-' : chosenMultiple.data.bet.value}</MultipleAmount>
                            </Row>
                        }               
                        {info.nextCover &&
                            <NextCoverContainer isDisabled={chosenMultiple.data.multiple[info.nextCover.value.length].covered}>
                                <CoverTitle>Copertura {info.nextCover.value.length+1}</CoverTitle>
                                <SVGIconContainer top={12} right={12}>
                                    {chosenMultiple.data.multiple[info.nextCover.value.length].covered 
                                        ? <CheckboxOnSVG title={'Segna che la copertura è da fare'} onClick={() => info.nextCover && handleCoverCheckboxClick(info.nextCover.value.length)}/>
                                        : <CheckboxOffSVG title={'Segna che la copertura è stata fatta'} onClick={() => info.nextCover && handleCoverCheckboxClick(info.nextCover.value.length)}/>
                                    }
                                </SVGIconContainer>
                                <CoverInstructionsContainer>
                                    {
                                        info.nextCover.covers[info.nextCover.covers.length-1].bets?.map((b,i) => {
                                            if(!info.nextCover) return null;
                                            const oddsMatch = chosenMultiple.data.multiple[info.nextCover.value.length];
                                            const site = sites.get(oddsMatch.sitesIds[oddsMatch.sitesIds.length-1][i+1]);
                                            const siteUrl = oddsMatch.sitesUrls[oddsMatch.sitesUrls.length-1][i+1];
                                            const resp = info.nextCover.covers[info.nextCover.covers.length-1].resp;
                                            return(
                                                <CoverInstructions key={i}>
                                                    <SiteBox 
                                                        checked={!!site} 
                                                        notClickable={siteUrl===null}
                                                        name={site?.name ?? ''} 
                                                        width={60}
                                                        height={36}
                                                        backgroundColor={!site ? '#000000a3' : site.color}
                                                        onClick={() => site && siteUrl!==null && copyToClipboard && copyToClipboard(siteUrl, `URL ${site.name} copiato`)}
                                                    >
                                                        {(site && site.id===0) && site.name}
                                                        {(site && site.id>0) && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                    </SiteBox>
                                                    <CoverInstructionsLabelContainer withSite={true}>
                                                        <HorizontalScrollContainer hiddenScrollBar={true}>
                                                            <CoverInstructionsLabel>
                                                                <CoverInstructionsText>{oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                <BetContainer>
                                                                    <Bet translateY={oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 5 : 0}>{'€'+(b.toFixed(oddsMatch.modes[oddsMatch.modes.length-1]==='pb' ? 2 : 0))}</Bet>
                                                                    {(resp && oddsMatch.modes[oddsMatch.modes.length-1]==='pb') && <Resp title='Responsabilità' translateY={1}>{'€'+(resp[i].toFixed(2))}</Resp>}
                                                                </BetContainer>
                                                                <CoverInstructionsText>esito</CoverInstructionsText>
                                                                <CoverInstructionsStrongText>{oddsMatch.selections[oddsMatch.selections.length-1][i+1]}</CoverInstructionsStrongText> 
                                                                <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                <CoverInstructionsStrongText>{parseFloat(oddsMatch.odds[oddsMatch.odds.length-1][i+1].value).toFixed(2)}</CoverInstructionsStrongText>
                                                            </CoverInstructionsLabel>
                                                        </HorizontalScrollContainer>
                                                    </CoverInstructionsLabelContainer>
                                                </CoverInstructions>
                                            );
                                        })
                                    }
                                </CoverInstructionsContainer>    
                            </NextCoverContainer>
                        }
                    </Col>  
                }
                <MultipleSectionsContainer>
                    {chosenMultiple.data.multiple.length > 0 &&
                        <MultipleSection>
                            {(chosenMultiple.id===null && chosenMultiple.data.multiple[0].event!==null) && <ChooseButton onClick={save}>Conferma</ChooseButton>}
                            <MultipleList>
                                {concurrentEvents && 
                                    <ConcurrentEventsWarning>
                                        {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                        <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                        Eventi in contemporanea
                                        <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                        {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                    </ConcurrentEventsWarning>
                                }
                                {chosenMultiple.data.multiple.map((om, index) => {
                                    if(om.event === null) return(
                                        <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'center'} gap='5px'>
                                            <MultipleElement isDisabled={false} key={index} backgroundColor='#3A454E' border={index!==eventToChoose.index ? '1px solid #29353E' : '1px solid #FFC000'}>
                                                <SearchButton onClick={() => search(index)}>{`Cerca ${index+1}° Evento`}</SearchButton>
                                            </MultipleElement>
                                        </Flex>
                                    );
                                    const mode: string = om.modes[om.modes.length-1];
                                    const res = om.cancelled[om.cancelled.length-1] ? 'C' : om.result;                                        
                                    const cover1Site: Site|undefined = sites.get(om.sitesIds[om.sitesIds.length-1][1]);
                                    const cover2Site: Site|undefined = mode==='tri' ? sites.get(om.sitesIds[om.sitesIds.length-1][2]) : undefined;
                                    return (
                                        <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'center'} gap='5px'>
                                            <MultipleElement isDisabled={!!res} backgroundColor='#3A454E' border={index!==eventToChoose.index ? '1px solid #29353E' : '1px solid #FFC000'}>
                                                <MultipleElementEventRow>
                                                    {chosenMultiple.id!==null && <Result title={resultComment(res)} result={res ?? undefined} onClick={() => handleResultClick(index)}/>}
                                                    <HorizontalScrollContainer hiddenScrollBar={true}><MultipleElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</MultipleElementEvent></HorizontalScrollContainer>
                                                </MultipleElementEventRow>
                                                <MultipleElementOddsMatchRow>
                                                    <MultipleElementOdds>
                                                        <SiteBox 
                                                            checked={!!multipleSite} 
                                                            notClickable={!(multipleSite && om.sitesUrls[om.sitesUrls.length-1][0] && copyToClipboard)}
                                                            name={multipleSite?.name ?? ''} 
                                                            width={60}
                                                            height={36}
                                                            backgroundColor={!multipleSite ? '#000000a3' : multipleSite.color}
                                                            onClick={() => multipleSite && om.sitesUrls[om.sitesUrls.length-1][0] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][0], `URL ${multipleSite.name} copiato`)}
                                                        >
                                                            {(multipleSite && multipleSite.id===0) && multipleSite.name}
                                                            {(multipleSite && multipleSite.id>0) && <SiteLogoContainer visible={true} backgroundColor={multipleSite.color}><SiteLogo width={'95%'} imageUrl={multipleSite.imageUrl}/></SiteLogoContainer>}
                                                        </SiteBox>
                                                        <NumberInput isDisabled={multipleStarted} 
                                                            value={om.odds.length>0 ? om.odds[om.odds.length-1][0].value : ''} 
                                                            inputPlaceholder='Quota'
                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                            onChange={(newState: NumberInputState) => handleOddsChange(index, 0, newState)}
                                                        />
                                                        <MultipleElementCell><MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][0]}</MultipleElementOddsSelection></MultipleElementCell>
                                                    </MultipleElementOdds>
                                                    <MultipleElementOddsCoversContainer>
                                                        <MultipleElementOddsCovers>
                                                            <MultipleElementOdds>
                                                                <MultipleElementCell>
                                                                    <SiteBox 
                                                                        checked={!!cover1Site} 
                                                                        notClickable={!(cover1Site && om.sitesUrls[om.sitesUrls.length-1][1] && copyToClipboard)}
                                                                        name={cover1Site?.name ?? ''} 
                                                                        width={60}
                                                                        backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                        onClick={() => cover1Site && om.sitesUrls[om.sitesUrls.length-1][1] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][1], `URL ${cover1Site.name} copiato`)}
                                                                    >
                                                                        {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                        {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                    </SiteBox>
                                                                </MultipleElementCell> 
                                                                <NumberInput isDisabled={!!res} 
                                                                    value={om.odds.length>0 ? om.odds[om.odds.length-1][1].value : ''} 
                                                                    inputPlaceholder='Quota'
                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                    onChange={(newState: NumberInputState) => handleOddsChange(index, 1, newState)}
                                                                />
                                                                <MultipleElementCell><MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][1]}</MultipleElementOddsSelection></MultipleElementCell>   
                                                            </MultipleElementOdds>
                                                            {mode==='tri' &&
                                                                <MultipleElementOdds>
                                                                    <MultipleElementCell>
                                                                        <SiteBox 
                                                                            checked={!!cover2Site} 
                                                                            notClickable={!(cover2Site && om.sitesUrls[om.sitesUrls.length-1][2] && copyToClipboard)}
                                                                            name={cover2Site?.name ?? ''} 
                                                                            width={60}
                                                                            backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                            onClick={() => cover2Site && om.sitesUrls[om.sitesUrls.length-1][2] && copyToClipboard && copyToClipboard(om.sitesUrls[om.sitesUrls.length-1][2], `URL ${cover2Site.name} copiato`)}
                                                                        >
                                                                            {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                            {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                        </SiteBox>
                                                                    </MultipleElementCell>
                                                                    <NumberInput isDisabled={!!res} 
                                                                        value={om.odds.length>0 ? om.odds[om.odds.length-1][2].value : ''} 
                                                                        inputPlaceholder='Quota'
                                                                        minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                        onChange={(newState: NumberInputState) => handleOddsChange(index, 2, newState)}
                                                                    />
                                                                    <MultipleElementCell><MultipleElementOddsSelection title='Esito'>{om.selections[om.selections.length-1][2]}</MultipleElementOddsSelection></MultipleElementCell>
                                                                </MultipleElementOdds>
                                                            }
                                                        </MultipleElementOddsCovers>
                                                        {chosenMultiple.id!==null && <SVGIconContainer notClickable={res!==null || !om.event}>{om.eventDate>utcNow && <SearchIconSVG title={!res ? 'Cerca copertura alternativa' : ''} onClick={() => !res && searchCovers(index)}/>}</SVGIconContainer>}
                                                    </MultipleElementOddsCoversContainer>
                                                </MultipleElementOddsMatchRow>
                                            </MultipleElement> 
                                            {(chosenMultiple.id===null && om.event!==null && chosenMultiple.data.multiple[0].event!==null) && <SearchButton onClick={() => search(index)}>Modifica</SearchButton>}
                                        </Flex>
                                    );
                                })}
                                <MultipleBonusAndOdds>
                                    <MultipleOddsContainer altValue={Math.abs(multipleOdds-initialMultipleOdds)<0.01 ? undefined : multipleOdds.toFixed(2)}>
                                        {initialMultipleOdds>1.0 ? initialMultipleOdds.toFixed(2) : '-'}
                                    </MultipleOddsContainer>
                                    <MultipleBonusContainer>
                                        {(!chosenMultiple.data.multipleBonus.errorMessage && parseFloat(chosenMultiple.data.multipleBonus.value)>0.01) &&
                                            <>
                                                <MultipleBonusLabel>{isAppMobile ? 'Bonus' : 'Bonus multipla'}</MultipleBonusLabel>
                                                <NumberInput isDisabled={true} isDisabledOpacity={1} inputWidth={45} small={true} height={24} value={chosenMultiple.data.multipleBonus.value} minValue={0} allowEmpty={true} includeLimits={true} stepFunction={stepIntegers}/>
                                                <MultipleBonusLabel>{isAppMobile ? '%' : '% sul'}</MultipleBonusLabel>
                                                <SwitchSelectorBig isOption1Active={chosenMultiple.data.multipleBonusType==='net'} isDisabled={multipleStarted} mode='standard' option1='Netto' option2='Lordo' width={isAppMobile ? 100 : 115} height={24} padding={3}/>
                                            </>
                                        }
                                    </MultipleBonusContainer>
                                </MultipleBonusAndOdds>
                                {chosenMultiple.data.multiple.length>0 &&
                                    <>
                                        <MultipleInfoContainer>
                                            {!info.finished && 
                                                <>
                                                    <MultipleInfoElement>
                                                        <MultipleInfoLabel>Guadagno medio</MultipleInfoLabel>
                                                        <WinnigsAndRating mobileDirection='column'>
                                                            <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                            <Rating size='m'>{(!chosenMultiple.data.refund.errorMessage && (chosenMultiple.data.isFreebet || parseFloat(chosenMultiple.data.refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                        </WinnigsAndRating>
                                                    </MultipleInfoElement>
                                                    <MultipleInfoElement><MultipleInfoLabel>Importo max coperture</MultipleInfoLabel><MultipleInfoValue>€{info.coversMax!==undefined ? info.coversMax.toFixed(2) : '-'}</MultipleInfoValue></MultipleInfoElement>
                                                </>
                                            }
                                            {info.finished && 
                                                <>
                                                    <MultipleFinishedReason>Multipla conclusa</MultipleFinishedReason>
                                                    <WinnigsAndRating>
                                                        <Winnings amount={info.avgWinnings ?? 0} size='m'>€{info.avgWinnings!==undefined ? info.avgWinnings.toFixed(2) : '-'}</Winnings>
                                                        <Rating size='m'>{(!chosenMultiple.data.refund.errorMessage && (chosenMultiple.data.isFreebet || parseFloat(chosenMultiple.data.refund.value)>0)) ? 'RF: ' : ''}{info.avgRating!==undefined ? info.avgRating.toFixed(2) : '-'}%</Rating>
                                                    </WinnigsAndRating>
                                                </>
                                            }
                                        </MultipleInfoContainer>
                                        {(multipleTree && !treeVisible) && <SVGIconContainer size={30}><TreeIconSVG title={'Mostra albero multipla'} onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>}
                                    </>
                                }
                            </MultipleList>
                        </MultipleSection>
                    }
                    {(multipleTree && treeVisible) &&
                        <MultipleSection>
                            <SVGIconContainer size={13} fill='#ffffff6e' top={5} right={5}><CloseSVG title='Chiudi albero multipla' onClick={() => setTreeVisible(prevState => !prevState)}/></SVGIconContainer>
                            <MultipleTree tree={multipleTree} result={result} backgroundColor='#3A454E' border='1px solid #29353E'/>
                        </MultipleSection>
                    }
                </MultipleSectionsContainer>
            </Col> 
            {(_filters && multiple.id===null) && <OddsMatchSelector filters={_filters} externalSearch={eventToChoose.externalSearch} onChoose={(om: OddsMatch) => handleOddsMatchChoice(om)} copyToClipboard={copyToClipboard}/>}
            {(coverOdds && orderedCoverOddsMatches) && <CoverOddsModal eventId={coverOdds.eventId} selection={coverOdds.selection} oddsMatches={orderedCoverOddsMatches} onChangeCoverClick={changeCover} onCloseButtonModal={() => setCoverOdds(undefined)} copyToClipboard={copyToClipboard}/>}
        </MultipleSelectorContainer>
    );
}

export default memo(MultipleSelector);