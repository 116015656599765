import { FC, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import ThematicDirectModalPage from './ThematicDirectModalPage/ThematicDirectModalPage.component';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';

// types
import { ThematicDirectModalProps } from './ThematicDirectModal.component.d' ;

// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
} from './style/ThematicDirectModal.style';
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';

interface Product {
    name: string;
    productThematicCall: any;
    liveDate: string;
    subCategories: any;
    categories: any;
    salesData: any;
    delivery: string;
    id: number;
    subscriptionsAsProtagonist: number;
    subscriptionsAsSpectator: number;
    paymentTypes: any;
    requisitesSatisfied: boolean;
    owned: boolean;
    ownedAs: string;
    subscriptions:string;
    authorsNames: any;
}

const ThematicDirectModal: FC<ThematicDirectModalProps & { product: Product }> = ({ onCloseButtonModal, onModalButtonClickHandler, product  }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const [isLibertyPastPaymentModalVisible, setIsLibertyPastPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(false);
    const openLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(true);
    const [isType, setIsType] = useState<string>('');

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyPastModalHandler = () => {
        setIsLibertyPastPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };


    const handleThematicTypeChange = (thematicType : any) => {
        setIsType(thematicType)
        console.log(thematicType);
    };
    
    return (
        <>
            <BaseModal 
                onClose={onCloseButtonModal}
                isCloseButtonClicked={isCloseButtonClicked}
                isBackdropClickActive
            >
                {!isAppMobile && (
                    <Row 
                        padding='0 21px 12px 0'
                        crossAxis='center'
                        mainAxis='flex-end'
                    >
                        <CloseButton onClick={closeButtonHandler}>
                            <CloseSVG />
                        </CloseButton>
                    </Row>
                )}

                <BaseCard 
                    flavor='outlined'
                    isModal
                >
                    <CardContent>
                        {isAppMobile && (
                            <Row 
                                crossAxis='center'
                                mainAxis='flex-end'
                            >
                                <CloseButton onClick={closeButtonHandler}>
                                    <CloseSVG />
                                </CloseButton>
                            </Row>
                        )}
                        <ThematicDirectModalPage 
                            product={product}
                            courseElement={product?.requisitesSatisfied}
                            onTransferClick={transferModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={libertyModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            libertyProductID={libertyProductID}
                            libertyProductPrice={libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            onThematicTypeChange={handleThematicTypeChange}
                            libertyPastModalHandler={libertyPastModalHandler}
                            openLibertyPastPaymentModal={openLibertyPastPaymentModal}
                        />
                    </CardContent>
                </BaseCard>
            </BaseModal>

            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    productType="ThematicBroadcast"
                    thematicBroadcastType={isType}
                />
            }

            {isLibertyPastPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPastPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                />
            }
        </>
    );
};

export default ThematicDirectModal;