import { FC, useEffect, useState } from "react";

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from "hooks/useAppDispatch";
import { useCopyToClipboard } from "usehooks-ts";
import { getEnvironmentBaseUrl } from "configs/config.helper";
import { useSearchParams } from "react-router-dom";

// components
import MediaPlayer from "components/MediaPlayer";
import ButtonShop from "components/ButtonShop/ButtonShop.component";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import Timer from "components/Timer/Timer.component";
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";

// asset
import { ReactComponent as Vector } from './assets/Vector.svg';
import { ReactComponent as CopySVG } from '../../assets/link-icon.svg';

// style
import { 
    CardPrice, 
    VideoImg,
    GridCard, 
    TextPrice, 
    MediaGrid, 
    SecondaCard, 
    TextP, 
    TitleB, 
    TitleBox, 
    TitleCard, 
    TotalPage, 
    TextCheck,
    TextBulllet,
    BlockText,
    BlockImg,
    BlockRow,
    BlockGroup,
    ProductLimitationRow,
    LimitedSlotBox,
    LimitationDisclaimer,
    LimitedTimeBox,
    LimitedSlots,
    VideoCourseListBox,
    ListText
} from "./style/PublicProductPage.style";


const PublicProductPage: FC<{
    productData: any, 
    onTransferClick: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void
    isUserLoggedIn: boolean,
    onLibertyClick: () => void,
    openBankTransferModal: () => void,
    libertyInfoModalHandler?:() => void,
    paymentTransferID: number | undefined,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?:() => void,
    hasRequire?: boolean,
    publicInfoPageTransferPrice?: (grossPrice: number) => void
    publicPageTransferPrice?: (grossPrice: number) => void,
    campaignId?: string | null
}> = ({
    productData, 
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isUserLoggedIn,
    onLibertyClick,
    openBankTransferModal,
    libertyInfoModalHandler,
    paymentTransferID,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    campaignId
}) => {

    const [product, setProduct] = useState<any>(productData);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductPurchasable, setIsProductPurchasable] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const [searchParams] = useSearchParams();
    const isDirectPurchase = searchParams.get('directPurchase');

    const { name, salesData, subscriptionMaxDate, subscriptions, maxSubscription } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const bulletList = salesData?.bulletList;
    const headLine = salesData?.headLine;
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;
    const salesPage = salesData?.salesPage;
    const blocks = salesPage?.blocks;
    const importantPhrase = salesData?.importantPhrase;

    
    const freeSlots = maxSubscription - subscriptions;
    
    const localMedia = {
        src: videoUrl ?? "https://www.viveredipoker.it/Libreria/Creare/Creare_Soldi_Bonus_Quanto_Guadagno.mp4",
        type: "video/mp4"
    };

    const setProductNoMorePurchasable = () => setIsProductPurchasable(false);
    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${productData.id}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    useEffect(() => {
        setProduct(productData);
    }, [productData]);

    const finalName = (name !== null) ? name : "TITOLO PRODOTTO";
    const finalHeadline = (headLine !== null) ? headLine : "HEADLINE";
    const finalBulletList1 = ((bulletList !== null) && (bulletList !== undefined)) ? bulletList[0] : "BULLET LIST";
    const finalBulletList2 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[1]) ? bulletList[1] : "BULLET LIST";
    const finalBulletList3 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[2]) ? bulletList[2] : "BULLET LIST";
    const finalPurchaseButtonText = (purchaseButtonText !== null) ? purchaseButtonText : "";
    const isBulletCardVisible = (headLine !== null) || (bulletList !== null);
    const isBulletListVisible = (bulletList !== null);
    const isDescriptionCardVisible = (blocks !== null) || (purchaseButtonText !== null);
    const productPrice = () => {
        if (product.type === 'SERVICE') return product.productService.paymentPlan.price;
        if (product.type === 'LESSON_PACK') return product.productLessonPack.price;
        return product.productCourse.price;
    };
    const isLimitedSlotBoxVisible = (maxSubscription !== null) && (maxSubscription !== undefined);
    const isLimitedTimeBoxVisible = (subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined);
    const freeSlotText = ((maxSubscription !== null) && (maxSubscription !== undefined)) ? ((freeSlots > 0) ? freeSlots : "Posti Esauriti") : "Non ci sono limiti";
    const finalImportantPhrase = importantPhrase != null ? importantPhrase : 'IMPORTANT PHRASE';
    const isSeatsAvailable = (isNaN(freeSlots)) ? true : ((freeSlots > 0) ? true : false);
    const isOfferValid = (subscriptionMaxDate !== undefined) ? (new Date(`${subscriptionMaxDate}Z`).getTime() > new Date().getTime()) : true;

    if (isDirectPurchase) return (
        <ButtonShop 
            PriceProduct={productPrice()} 
            TypeOfProduct={product.type}
            courseElement={product} 
            courseID={product.id} 
            payments={product.paymentTypes}
            onTransferClick={onTransferClick}
            isOpenInModal={isOpenInModal}
            buyInfoModalHandler={buyInfoModalHandler}
            openBankTransferModal={openBankTransferModal}
            onLibertyClick={onLibertyClick}
            libertyInfoModalHandler={libertyInfoModalHandler}
            openLibertyPaymentModal={openLibertyPaymentModal}
            isOnPublicProductPage
            modalGetPaymentID={modalGetPaymentID}
            productIdHandler = {libertyProductID}
            productPriceHandler = {libertyProductPrice}
            loadingOn={loadingOn}
            loadingOff={loadingOff}
            openProductRequisitesModal={openProductRequisitesModal}
            hasRequire={hasRequire}
            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
            publicPageTransferPrice={publicPageTransferPrice}
            vipPoints={vipPoints}
            isDirectPurchase={isDirectPurchase}
            campaignId={campaignId}
        />  
    );

    return(
        <>
            <TotalPage>
                <MediaGrid>
                {videoUrl && (
                            <MediaPlayer
                                media={localMedia}
                                preload='medatada'
                                poster={videoPosterUrl}
                            />
                        )}

                        {!videoUrl && videoPosterUrl && (
                            <VideoImg 
                                alt="Video Image Poster"
                                src={videoPosterUrl}
                            />
                        )}
                </MediaGrid>

                <TitleBox>
                    {/* <ProductLimitationRow>
                        {isLimitedSlotBoxVisible && (
                            <LimitedSlotBox>
                                <LimitationDisclaimer>
                                    <span>POSTI LIMITATI! </span>
                                    Ne restano solo 
                                    <LimitedSlots>
                                            {freeSlotText}
                                    </LimitedSlots>
                                </LimitationDisclaimer>
                            </LimitedSlotBox>
                            )
                        }

                        {isLimitedTimeBoxVisible && (
                            <LimitedTimeBox>
                                <LimitationDisclaimer>
                                    <span>L'offerta scade:</span>
                                </LimitationDisclaimer>

                                <Timer 
                                    limitTime={subscriptionMaxDate}
                                    timerCount="DAYS"
                                    productPurchasableHandler={setProductNoMorePurchasable}
                                />
                            </LimitedTimeBox>
                            )
                        }
                    </ProductLimitationRow> */}

                    {isSeatsAvailable && isOfferValid && (
                        <ProductLimitationRow>
                            {isLimitedSlotBoxVisible && (
                            <LimitedSlotBox>
                                <LimitationDisclaimer>
                                <span>POSTI LIMITATI! </span>
                                Ne restano solo 
                                <LimitedSlots>
                                    {freeSlots}
                                </LimitedSlots>
                                </LimitationDisclaimer>
                            </LimitedSlotBox>
                            )}

                            {isLimitedTimeBoxVisible && isOfferValid && (
                                <LimitedTimeBox>
                                    <LimitationDisclaimer>
                                    <span>L'offerta scade:</span>
                                    </LimitationDisclaimer>

                                    <Timer 
                                    limitTime={subscriptionMaxDate}
                                    timerCount="DAYS"
                                    productPurchasableHandler={setProductNoMorePurchasable}
                                    />
                                </LimitedTimeBox>
                            )}
                        </ProductLimitationRow>
                        )}

                        {!isSeatsAvailable && isOfferValid && (
                        <LimitedSlotBox>
                            <LimitationDisclaimer>
                            <span>POSTI ESAURITI!</span>
                            </LimitationDisclaimer>
                        </LimitedSlotBox>
                        )}

                        {!isOfferValid && (
                        <LimitedTimeBox>
                            <LimitationDisclaimer>
                            <span>OFFERTA SCADUTA</span>
                            </LimitationDisclaimer>
                        </LimitedTimeBox>
                        )}

                    <TitleB>
                        {finalName}

                        <CopySVG onClick={copyIntoClipboardHandler} />
                    </TitleB>
                </TitleBox>

                <GridCard>
                    {isBulletCardVisible && (
                        <CardPrice>
                            <TitleCard dangerouslySetInnerHTML={{__html: finalHeadline}} />
                            
                            <BlockText dangerouslySetInnerHTML={{ __html: finalImportantPhrase }}></BlockText>
                            <TextPrice>
                                <TextBulllet>
                                    <Vector/>

                                    <TextP dangerouslySetInnerHTML={{__html: finalBulletList1}} />
                                </TextBulllet>

                                <TextBulllet>
                                    <Vector/>
                                    
                                    <TextP dangerouslySetInnerHTML={{__html: finalBulletList2}} />
                                </TextBulllet>

                                <TextBulllet>
                                    <Vector/>

                                    <TextP dangerouslySetInnerHTML={{__html: finalBulletList3}} />
                                </TextBulllet>
                            </TextPrice>

                            <ButtonShop 
                                PriceProduct={productPrice()} 
                                TypeOfProduct={product.type} 
                                courseElement={product} 
                                courseID={product.id} 
                                payments={product.paymentTypes}
                                onTransferClick={onTransferClick}
                                isOpenInModal={isOpenInModal}
                                buyInfoModalHandler={buyInfoModalHandler}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                libertyInfoModalHandler={libertyInfoModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                isOnPublicProductPage
                                modalGetPaymentID={modalGetPaymentID}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                hasRequire={hasRequire}
                                isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                                publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                publicPageTransferPrice={publicPageTransferPrice}
                                vipPoints={vipPoints}
                                />

                        </CardPrice>
                    )}

                    {isDescriptionCardVisible && (
                        <SecondaCard>                  
                            <TextPrice>
                                {(salesPage !== null && salesPage !== undefined) && (
                                    <TextCheck>
                                        { blocks.map((block: any, index: number) => {

                                            if (block.layout === "SINGLE") {
                                                if (block.elements.length === 0) return;
                                                
                                                if (block.elements[0].type === 'TEXT') return <BlockText key={"B" + index} dangerouslySetInnerHTML={{__html: block.elements[0].data}} />;
                                                if (block.elements[0].type === 'VIDEO') return <MediaPlayer key={"B" + index} media={{src: block.elements[0].file.url, type: 'VIDEO/MP4'}} />;
                                                if (block.elements[0].type === 'IMAGE') return <BlockImg key={"B" + index} alt="" src={block.elements[0].file.url} />;
                                                if (block.elements[0].type === 'VSPACE') {
                                                    for (let i = 0; i <= +block.elements[0].data; i++) {
                                                        // return <br  key={"BRB" + index + i} />;
                                                        return <></>;
                                                    }
                                                };
                                            }

                                            if (block.layout === "ROW") {
                                                
                                                return (
                                                    <BlockRow>
                                                        {block.elements.map((element: any) => {

                                                            if (element.type === 'TEXT') return <BlockText key={"R" + index} dangerouslySetInnerHTML={{__html: element.data}} />;
                                                            if (element.type === 'VIDEO') return <MediaPlayer key={"R" + index} media={{src: element.file.url, type: 'VIDEO/MP4'}} />;
                                                            if (element.type === 'IMAGE') return <BlockImg key={"R" + index} alt="" src={element.file.url} />;
                                                            if (element.type === 'VSPACE') {
                                                                for (let i = 0; i <= +element.data; i++) {
                                                                    // return <br  key={"BRR" + index + i} />;
                                                                    return <></>;
                                                                }
                                                            };
                                                        })}
                                                    </BlockRow>
                                                );
                                            }

                                            if (block.layout === "GROUP") {
                                                
                                                return (
                                                    <BlockGroup>
                                                        {block.elements.map((element: any) => {

                                                            if (element.type === 'TEXT') return <BlockText key={"G" + index} dangerouslySetInnerHTML={{__html: element.data}} />;
                                                            if (element.type === 'VIDEO') return <MediaPlayer key={"G" + index} media={{src: element.file.url, type: 'VIDEO/MP4'}} />;
                                                            if (element.type === 'IMAGE') return <BlockImg key={"G" + index} alt="" src={element.file.url} />;
                                                            if (element.type === 'VSPACE') {
                                                                for (let i = 0; i <= +element.data; i++) {
                                                                    // return <br  key={"BRG" + index + i} />;
                                                                    return <></>;
                                                                }
                                                            };
                                                        })}
                                                    </BlockGroup>
                                                );
                                            }})
                                        }
                                    </TextCheck>
                                )}
                            </TextPrice>

                            <VideoCourseListBox>
                                <ListText>ESPLORA IL CONTENUTO DEL CORSO:</ListText>

                                <VideoCourseList 
                                    courseItem={productData.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos)}
                                    collapseHandler={() => {}}
                                    isOnPublicPage
                                />
                            </VideoCourseListBox>

                            <ButtonShop 
                                PriceProduct={productPrice()} 
                                TypeOfProduct={product.type}
                                courseElement={product} 
                                courseID={product.id} 
                                payments={product.paymentTypes}
                                onTransferClick={onTransferClick}
                                isOpenInModal={isOpenInModal}
                                buyInfoModalHandler={buyInfoModalHandler}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                libertyInfoModalHandler={libertyInfoModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                isOnPublicProductPage
                                modalGetPaymentID={modalGetPaymentID}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                hasRequire={hasRequire}
                                isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                                publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                publicPageTransferPrice={publicPageTransferPrice}
                                vipPoints={vipPoints}
                            />
                        </SecondaCard>
                    )}
                </GridCard>
            </TotalPage>

            {/* {!isUserLoggedIn && !isOpenInModal && isLibertyPaymentModalVisible && (
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={product.id}
                />
            )} */}
        </>
    );
};

export default PublicProductPage;