import { FC, useEffect, useState, useRef, useMemo, memo } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { getItemFromLocalStorage } from 'utils/localStorage';
import { _saveRollover, _getRollovers, _deleteRollover } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

//components
import NumberInput from 'components/ui/NumberInput';
import Dropdown from 'components/Dropdown';
import CheckboxesGroup from 'components/CheckboxesGroup';
import TextInputWithAutocomplete from 'components/TextInputWithAutocomplete';
import EventInput from 'pages/OddsScasserPage/OddsScasser/Multiple/EventInput';
import OddsMatchSelector from '../OddsMatchSelector';

//assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as TrashIconSVG } from 'pages/OddsScasserPage/assets/trash.svg';
import { ReactComponent as SearchIconSVG } from 'pages/OddsScasserPage/assets/searchIcon.svg';
import { ReactComponent as EditIconSVG } from 'pages/OddsScasserPage/assets/edit.svg';
import { ReactComponent as CheckmarkSVG } from 'pages/OddsScasserPage/assets/checkmark.svg';
import { ReactComponent as WarningIconSVG } from 'pages/OddsScasserPage/assets/warning.svg';

//style
import { RolloverContainer, Parameters, CoverBets, Action, RolloverStageContainer, RolloverStageTitle, RolloverStagesContainer, RolloverCompletionAdd, RolloverFinishedReason, RolloverFinishedValue, RolloverInfoContainer, RolloverInfoElement, RolloverInfoLabel, RolloverInfoValue, RolloverStage2Info, CoverBetsAndRatingContainer, CoverBetsContainer, RolloverStageTitleLabel, RolloverList, RolloverElementEventRow, RolloverElement, RolloverElementEvent, RolloverElementOddsMatchRow, RolloverElementCell, RolloverElementOdds, RolloverElementOddsSelection, RolloverElementOddsCoversContainer, RolloverElementOddsCovers, OverRolloverWarning, WarningIconsContainer, OverRolloverWarningText } from 'pages/OddsScasserPage/OddsScasser/Rollover/style/Rollover.style';
import { Result, ToolButton, ToolButtons, SVGIconContainer, Select, BetContainer, Bet, Resp, WinnigsAndRating, Rating, Winnings, BetsTable, BetsTableBody, BetsTableCell, BetsTableHead, BetsTableHeaderCell, BetsTableRow, BetsTableSingleDivCell, CoverOdds, DropdownContent, HorizontalScrollContainer, StandardCheckbox, CoverInstructions, CoverInstructionsContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, SiteBox, SiteLogo, SiteLogoContainer, SitesCheckboxes, SavedElementCheckbox, SavedElementsContainer, Flex, WinnigsAndRatingContainer } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Col, Row } from 'style/layout';
import { RolloverSelectorContainer, SearchButton, StartButton } from './style/RolloverSelector.style';

//types
import { RolloverSelectorProps } from './RolloverSelector.component.d';
import { Event } from 'pages/OddsScasserPage/types/Event';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Odds } from 'pages/OddsScasserPage/types/Odds';
import { Covers } from '../../types/Covers';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { RolloverObj, RolloverOddsMatch } from 'pages/OddsScasserPage/OddsScasser/Rollover/types/RolloverObj';
import { OddsScasserFilters } from 'pages/OddsScasserPage/OddsScasser/OddsScasserFilter/types/OddsScasserFilters';
import { EventInputState } from 'pages/OddsScasserPage/OddsScasser/Multiple/EventInput/types/EventInputState';
import { RolloverProgress } from 'pages/OddsScasserPage/OddsScasser/Rollover/types/RolloverProgress';

//helper functions
import { calculatePuntaPunta, calculatePuntaBanca, stepIntegers, stepOdds, stepEach, resultComment, formatDate, selectionsValues, coverSelectionsTriscasser, coverSelectionPuntaPunta, getResult, localeStringToUTCString, stepOddsPP } from 'pages/OddsScasserPage/OddsScasserPage.helper';

const RolloverSelector: FC<RolloverSelectorProps> = ({rollover, filters, feesBetfair, feesBetflag, saveRollover, isHidden, readOnly, saveResult, copyToClipboard}) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events } = useAppSelector(state => state.oddsScasser);

    const [chosenRollover, setChosenRollover] = useState<RolloverObj>(rollover.id!==null 
        ? 
        {...rollover, data: {...rollover.data, stage1Bets: rollover.data.stage1Bets.map((x: RolloverOddsMatch) => ({...x, coversToRecalculate: true})), stage2Bets: rollover.data.stage2Bets.map((x: RolloverOddsMatch) => ({...x, coversToRecalculate: true}))}}
        : 
        {...rollover, data: {...rollover.data, stage1Bets: Array.from({length:5}, () => ({
            mode: 'pb',
            amount: {value: '', errorMessage: 'NaN'},
            covered: false,
            result: null,
            event: null,
            eventText: '',
            eventDate: '',
            fees: feesBetfair,
            selections: ['', '', ''],
            sitesIds: [-1, -1, -1],
            sitesUrls: [null, null, null],
            odds: Array.from({length:3}, () => ({value: '', errorMessage: 'NaN'})),
            isOpened: true,
            coversToRecalculate: true
        })), stage2Bets: []}}
    );
    const [betToChoose, setBetToChoose] = useState<{index: number, externalSearch: number}>({index: -1, externalSearch: 0});
    
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if(rollover.id !== null && JSON.stringify(chosenRollover)!==JSON.stringify(rollover)) setChosenRollover(rollover);
    }, [rollover]);
    
    //Save when the rollover changes
    const timer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if(chosenRollover.id !== null && JSON.stringify(chosenRollover)!==JSON.stringify(rollover)) {
            if(timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => saveRollover(chosenRollover), 5000);
            return () => clearTimeout(timer.current);
        }
    }, [chosenRollover]);

    
    const [stage1Covers, setStage1Covers] = useState<Covers[]>(Array.from({length:5}, () => ({covers: [{}, {}, {}]})));
    //Rollover completion (stage2)
    const [stage2Covers, setStage2Covers] = useState<Covers[]>(Array.from({length: chosenRollover.data.stage2Bets.length}, () => ({covers: [{}, {}, {}]})));
    //Edit
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [editingEvent, setEditingEvent] = useState<EventInputState|undefined>(undefined);
    const [editingOddsMatch, setEditingOddsMatch] = useState<RolloverOddsMatch|undefined>(undefined);

    //Set results of finished events
    useEffect(() => {
        // if(readOnly) return;
        // if(events.size === 0) return;
        // if(chosenRollover.id === null) return;
        // setChosenRollover(prevState => {
        //     if(prevState.data.stage1Bets.length === 0 && prevState.data.stage2Bets.length) return prevState;
        //     return {
        //         ...prevState, 
        //         data: {
        //             ...prevState.data, 
        //             stage1Bets: prevState.data.stage1Bets.map((bet, i) => {
        //                 if(bet.result !== null) return bet;
        //                 const event: Event|undefined = bet.event!==null ? events.get(bet.event?.id ?? 0) : undefined;
        //                 if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
        //                     return {...bet, result: getResult(bet.mode, bet.selections, event.home.score, event.away.score)};
        //                 }
        //                 return bet;
        //             }),
        //             stage2Bets: prevState.data.stage2Bets.map((bet, i) => {
        //                 if(bet.result !== null) return bet;
        //                 const event: Event|undefined = bet.event!==null ? events.get(bet.event?.id ?? 0) : undefined;
        //                 if(event && event.home?.score!==undefined && event.away?.score!==undefined && ['Finale','Dopo Suppl.','Dopo Rigori'].includes(event.status ?? '')) {
        //                     return {...bet, result: getResult(bet.mode, bet.selections, event.home.score, event.away.score)};
        //                 }
        //                 return bet;
        //             })
        //         }
        //     }
        // });
    }, [events, chosenRollover]);

    const handleOddsMatchChoice = (oddsMatch: OddsMatch) => {
        if(chosenRollover.data.bonus.errorMessage || chosenRollover.data.multiplier.errorMessage || chosenRollover.data.bets.errorMessage || chosenRollover.data.avgRating.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Inserire prima i parametri del rollover' }));
            return;
        }
        const selections: string[] = oddsMatch.odds.map(x => x.type ?? '');
        if(selections.length<3) selections.push('');
        const odds: NumberInputState[] = oddsMatch.odds.map(x => ({value: x.odds.toFixed(2)}));
        if(odds.length<3) odds.push({value: '', errorMessage: 'NaN'});
        const oddsInfo: (Odds|null)[] = [...oddsMatch.odds];
        if(oddsInfo.length<3) oddsInfo.push(null);
        const mode = oddsMatch.odds[0].type === oddsMatch.odds[1].type ? 'pb' : (oddsMatch.odds.length>2 ? 'tri' : 'pp');
        const _fees = mode === 'pb' && oddsInfo[1] ? (oddsInfo[1].site.id===9 ? feesBetflag : feesBetfair) : null;
        const event = events.get(oddsMatch.event.id) ?? null;
        let eventText = '';
        if(event) {
            eventText = `${event.home?.name} - ${event.away?.name}`;
            const league = event.league;
            if(league) {
                const country = league.country;
                const leagueName = league.name;
                if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
            }
        }
        const om: RolloverOddsMatch = {
            mode,
            amount: {value: '', errorMessage: 'NaN'},
            covered: false,
            result: null,
            event,
            eventText,
            eventDate: (event!==null && event.datetime) ? event.datetime : '',
            fees: _fees,
            selections,
            sitesIds: oddsInfo.map((x,i) => x?.site.id ?? -1),
            sitesUrls: oddsInfo.map((x,i) => x?.url ?? null),
            odds,
            isOpened: true,
            coversToRecalculate: true
        }
        if(betToChoose.index<0) {//Add new stage2Bet
            if(progress && (progress.bonusBalanceIncludingAllBets<1 || progress.remainingRolloverIncludingAllBets<0.01)) {
                dispatch(addSnackbar({ type: 'error', message: 'Saldo bonus o rollover terminato'}));
                return;
            }
            setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: [...prevState.data.stage2Bets, om]}}));
            setBetToChoose(prevState => ({...prevState, index: chosenRollover.data.stage2Bets.length+10}))
        } else if(betToChoose.index<10) {
            setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => i===betToChoose.index ? {...om, amount: x.amount} : x)}}));
        } else {
            setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: chosenRollover.data.stage2Bets.map((x,i) => i===betToChoose.index-10 ? {...om, amount: x.amount} : x)}}));
        }
    }

    //Set sitesList when sites changes
    const sitesList: Site[] = useMemo(() => {
        return (sites && sites.size>0) ? Array.from(sites.values()) : [];
    }, [sites])

    const progress: RolloverProgress|undefined = useMemo(() => {
        if(chosenRollover.data.bonus.errorMessage || chosenRollover.data.multiplier.errorMessage || chosenRollover.data.avgRating.errorMessage || chosenRollover.data.bets.errorMessage) return;
        const _bonus = parseFloat(chosenRollover.data.bonus.value);
        const _multiplier = parseInt(chosenRollover.data.multiplier.value);
        const _avgRating = parseFloat(chosenRollover.data.avgRating.value);
        const _bets = parseInt(chosenRollover.data.bets.value);
        const stage1BetsOk = stage1Covers.every((x, i) => i>=parseInt(chosenRollover.data.bets.value) || x.avgWinnings!==undefined);
        const stage1BetsFinished = chosenRollover.data.stage1Bets.every((x, i) => i>=_bets || x.result!==null);
        const p: RolloverProgress = {bonusBalance: _bonus, bonusBalanceIncludingAllBets: _bonus, remainingRollover: _bonus*_multiplier, remainingRolloverIncludingAllBets: _bonus*_multiplier, expectedValue: stage1BetsOk ? _bonus : undefined, stage1Result: 0.0, stage2Result: 0.0, total: 0.0};
        stage1Covers.forEach((x,index) => {
            if(index>=_bets || x.avgWinnings===undefined) return;
            const bet = parseFloat(chosenRollover.data.stage1Bets[index].amount.value);
            p.remainingRolloverIncludingAllBets -= bet;
            if(p.expectedValue) p.expectedValue += x.covers[1].winnings ?? 0.0;
            const result = chosenRollover.data.stage1Bets[index].result;
            if(!result) return;
            const odds = parseFloat(chosenRollover.data.stage1Bets[index].odds[0].value);
            p.remainingRollover -= bet;
            p.bonusBalance += result==='W' ? bet*(odds-1) : -bet;
            p.bonusBalanceIncludingAllBets += result==='W' ? bet*(odds-1) : -bet;
            const results = chosenRollover.data.stage1Bets[index].mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2'];
            const resultIndex = results.findIndex(x => result===x);
            if(resultIndex>-1) p.stage1Result += x.covers[resultIndex].winnings ?? 0;
        })
        let remainingRolloverIncludingAllBetsWithOdds: number = p.remainingRollover;
        if(stage1BetsFinished && p.bonusBalance>0.01) {
            p.stage2Result = 0.00;
            // let stage2TotalAmount = 0.00;
            stage2Covers.forEach((x,index) => {
                if(index>=chosenRollover.data.stage2Bets.length) return;
                const bet = parseFloat(chosenRollover.data.stage2Bets[index].amount.value);
                if(!isNaN(bet)) {
                    p.bonusBalanceIncludingAllBets -= bet;
                    p.remainingRolloverIncludingAllBets -= bet;
                }
                if(x.avgWinnings===undefined) return;
                const result = chosenRollover.data.stage2Bets[index].result;
                remainingRolloverIncludingAllBetsWithOdds -= bet;
                if(!result) {
                    p.stage2Result += x.avgWinnings ?? 0;
                    return;
                }
                const odds = parseFloat(chosenRollover.data.stage2Bets[index].odds[0].value);
                p.bonusBalance += result==='W' ? bet*(odds-1) : -bet;
                p.bonusBalanceIncludingAllBets += result==='W' ? bet*odds : 0;
                p.remainingRollover -= bet;
                const results = chosenRollover.data.stage2Bets[index].mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2'];
                const resultIndex = results.findIndex(x => result===x);
                if(resultIndex>-1) p.stage2Result += x.covers[resultIndex].winnings ?? 0;
            })
            p.realExpectedValue = _bonus + p.stage1Result + p.stage2Result + remainingRolloverIncludingAllBetsWithOdds*(_avgRating-100)/100;
        } else p.realExpectedValue = p.expectedValue;
        p.total = _bonus + p.stage1Result + (p.stage2Result ?? 0.0);
        return p;
    }, [chosenRollover, stage1Covers, stage2Covers])

    //Send result to bonusPage
    useEffect(() => {
        saveResult((progress && (progress.bonusBalance<0.99 || progress.remainingRollover<0.01)) ? progress.total : null);
    }, [progress]);

    useEffect(() => {
        if(chosenRollover.data.bonus.errorMessage || chosenRollover.data.multiplier.errorMessage || chosenRollover.data.bets.errorMessage || chosenRollover.data.avgRating.errorMessage) return;
        setStage1Covers(prevState => prevState.map((x, index) => {
            if(!chosenRollover.data.stage1Bets[index].coversToRecalculate) return x;
            const newCovers: Covers = {covers: [{}, {}, {}]};
            const mode = chosenRollover.data.stage1Bets[index].mode;
            const n = mode === 'tri' ? 3 : 2;
            const amount = chosenRollover.data.stage1Bets[index].amount;
            const odds = chosenRollover.data.stage1Bets[index].odds;
            if(!amount.errorMessage && odds.every((y,i) => i>=n || !y.errorMessage)) {
                const bet = parseFloat(amount.value);
                const _multiplier = parseFloat(chosenRollover.data.multiplier.value);
                const _avgRating = parseFloat(chosenRollover.data.avgRating.value);
                const refund = Math.round((100-_avgRating)*(_multiplier-1)*bet)/100;
                const _fees = chosenRollover.data.stage1Bets[index].fees ?? feesBetfair;
                const res = mode === 'pb' 
                    ? calculatePuntaBanca(bet, 0, refund, parseFloat(_fees)/100, odds.map(x => parseFloat(x.value)), 0.01)
                    : calculatePuntaPunta(bet, 0, refund, odds.map(x => parseFloat(x.value)), 1);
                newCovers.avgWinnings = res.avgWinnings;
                newCovers.avgRating = res.avgRating;
                for (let i = 0; i < res.bets.length; i++) {
                    if(i>=n) break; 
                    newCovers.covers[i].bet = res.bets[i];
                    newCovers.covers[i].resp = res.resp[i];
                    newCovers.covers[i].winnings = res.winnings[i]-(i>0 ? refund : 0);
                    newCovers.covers[i].rating = res.ratings[i];
                }
            }
            return newCovers;
        }));
    }, [chosenRollover]);

    useEffect(() => {
        if(chosenRollover.data.bonus.errorMessage || chosenRollover.data.multiplier.errorMessage || chosenRollover.data.bets.errorMessage || chosenRollover.data.avgRating.errorMessage) return;
        const newCovers: Covers[] = Array.from({length: chosenRollover.data.stage2Bets.length}, () => ({covers: [{}, {}, {}]}));
        let changed: boolean = newCovers.length !== stage2Covers.length;
        stage2Covers.forEach((x, index) => {
            if(index>=chosenRollover.data.stage2Bets.length) return;
            if(!chosenRollover.data.stage2Bets[index].coversToRecalculate && stage2Covers.length === chosenRollover.data.stage2Bets.length) {
                newCovers[index] = x;
                return;
            }
            const mode = chosenRollover.data.stage2Bets[index].mode;
            const n = mode === 'tri' ? 3 : 2;
            const amount = chosenRollover.data.stage2Bets[index].amount;
            const odds = chosenRollover.data.stage2Bets[index].odds;
            if(!amount.errorMessage && odds.every((y,i) => i>=n || !y.errorMessage)) {
                const bet = parseFloat(amount.value);
                const _fees = chosenRollover.data.stage2Bets[index].fees ?? feesBetfair;
                const res = mode === 'pb' 
                    ? calculatePuntaBanca(bet, 0, 0, parseFloat(_fees)/100, odds.map(x => parseFloat(x.value)), 0.01)
                    : calculatePuntaPunta(bet, 0, 0, odds.map(x => parseFloat(x.value)), 1);
                newCovers[index].avgWinnings = res.avgWinnings;
                newCovers[index].avgRating = res.avgRating;
                for (let i = 0; i < res.bets.length; i++) {
                    if(i>=n) break; 
                    newCovers[index].covers[i].bet = res.bets[i];
                    newCovers[index].covers[i].resp = res.resp[i];
                    newCovers[index].covers[i].winnings = res.winnings[i];
                    newCovers[index].covers[i].rating = res.ratings[i];
                }
            }
            changed = true;
        });
        if(changed) setStage2Covers(newCovers);
    }, [chosenRollover]);

    const handleStage1AmountChange = (index: number, newState: NumberInputState) => {
        setChosenRollover(prevState => {
            const newAmounts = [...prevState.data.stage1Bets.map(x => ({...x.amount}))];
            newAmounts[index] = newState;
            if(!newState.errorMessage) {
                const b = parseFloat(prevState.data.bonus.value);
                const n = parseInt(prevState.data.bets.value);
                let rest = b;
                let divisor = n;
                for(let i = 0; i < n; i++) {
                    if(i===index || (i<index && !newAmounts[i].errorMessage) || (!newAmounts[i].errorMessage && prevState.data.stage1Bets[i].result!==null)) {
                        rest -= parseFloat(newAmounts[i].value);
                        divisor--; 
                    }
                }
                for(let i = 0; i < n; i++) {
                    if(i===index || (i<index && !newAmounts[i].errorMessage) || (!newAmounts[i].errorMessage && prevState.data.stage1Bets[i].result!==null)) continue;
                    if(rest <= 0) return prevState;
                    let value = Math.ceil(rest/divisor);
                    rest -= value;
                    divisor--;
                    newAmounts[i] = {value: value.toFixed(0)};
                }
                if(Math.abs(rest)>0.001 && index>0) {
                    let value = rest + parseFloat(newAmounts[index-1].value);
                    newAmounts[index-1] = {value: value.toFixed(0)};
                }
            }
            return {...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => ({...x, amount: newAmounts[i], coversToRecalculate: x.coversToRecalculate || newAmounts[i].value !== x.amount.value}))}};
        });
    }

    const oddsMatchAfterModeChange = (oddsMatch: RolloverOddsMatch, newValue: string) => {
        const selections = [...oddsMatch.selections];
        if(newValue === 'pb') selections[1] = selections[0];
        else if(newValue === 'pp') selections[1] = coverSelectionPuntaPunta.get(selections[0]) ?? '';
        else {
            const covers = coverSelectionsTriscasser.get(selections[0]);
            selections[1] = covers ? covers[0] : '';
            selections[2] = covers ? covers[1] : '';
        }
        oddsMatch.coversToRecalculate = true;
        return {
            ...oddsMatch, 
            mode: newValue, 
            selections, 
            fees: newValue==='pb' ? feesBetfair : null,
            sitesIds: [oddsMatch.sitesIds[0],-1,-1],
            sitesUrls: [oddsMatch.sitesUrls[0],null,null],
            odds: [oddsMatch.odds[0],{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}]
        };
    }

    const handleStage1ModeChange = (index: number, newValue: string) => {
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterModeChange({...editingOddsMatch}, newValue));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => i===index ? oddsMatchAfterModeChange({...x}, newValue) : x)}}));
    }

    const oddsMatchAfterOddsChange = (oddsMatch: RolloverOddsMatch, index: number, newState: NumberInputState) => {
        return {...oddsMatch, odds: oddsMatch.odds.map((x,i) => i===index ? newState : x), coversToRecalculate: true};
    }

    const handleStage1OddsChange = (i: number, j: number, newState: NumberInputState) => {
        const editing: boolean = editingIndex===i;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterOddsChange({...editingOddsMatch}, j, newState));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,index) => i===index ? oddsMatchAfterOddsChange({...x}, j, newState) : x)}}));
    }

    //Stage2 bets change
    const handleStage2AmountChange = (index:number, newState: NumberInputState) => {
        setChosenRollover(prevState => {
            const maxAmount: number = (progress?.bonusBalanceIncludingAllBets ?? 0)+0.0001;//Math.min(progress?.bonusBalanceIncludingAllBets ?? 0, progress?.remainingRolloverIncludingAllBets ?? 0);
            const oldAmount = !prevState.data.stage2Bets[index].amount.errorMessage ? parseFloat(prevState.data.stage2Bets[index].amount.value) : 0;
            const newAmount = !newState.errorMessage ? parseFloat(newState.value) : 0;
            return newAmount-oldAmount <= maxAmount ? {...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,i) => i===index ? {...x, amount: newState, coversToRecalculate: true} : x)}} : prevState;
        });
    }

    const handleStage2ModeChange = (index: number, newValue: string) => {
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterModeChange({...editingOddsMatch}, newValue));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,i) => i===index ? oddsMatchAfterModeChange({...x}, newValue) : x)}}));
    }

    const handleStage2OddsChange = (i: number, j: number, newState: NumberInputState) => {
        const editing: boolean = editingIndex-10===i;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterOddsChange({...editingOddsMatch}, j, newState));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,index) => i===index ? oddsMatchAfterOddsChange({...x}, j, newState) : x)}}));
    }

    const handleStage1ResultClick = (index: number) => {
        if(chosenRollover.data.stage2Bets.length>0) return;
        if(editingIndex===index) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima confermare o annullare le modifiche fatte' }));
            return;
        }
        const n = chosenRollover.data.stage1Bets[index].mode === 'tri' ? 3 : 2;
        if(chosenRollover.data.stage1Bets[index].odds.some((y,i) => i<n && y.errorMessage)) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima di inserire il risultato inserisci le quote' }));
            return;
        }
        const results = n === 2 ? [null, 'W', 'L'] : [null, 'W', 'L1', 'L2'];
        let result: string|null = null;
        for (let i = 0; i < results.length; i++) {
            if(results[i] === chosenRollover.data.stage1Bets[index].result) result = results[(i+1)%results.length];
        }
        if(betToChoose.index===index) setBetToChoose(prevState => ({index: -1, externalSearch: result===null ? prevState.externalSearch : -1}));
        setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => i===index ? {...x, result} : x)}}));
    }

    const handleStage2ResultClick = (index: number) => {
        if(editingIndex-10===index) {
            dispatch(addSnackbar({ type: 'error', message: 'Prima confermare o annullare le modifiche fatte' }));
            return;
        }
        const n = chosenRollover.data.stage2Bets[index].mode === 'tri' ? 3 : 2;
        if(chosenRollover.data.stage2Bets[index].amount.errorMessage || chosenRollover.data.stage2Bets[index].odds.some((y,i) => i<n && y.errorMessage)) {
            dispatch(addSnackbar({ type: 'error', message: "Prima di inserire il risultato inserisci l'importo e le quote" }));
            return;
        }
        const results = n === 2 ? [null, 'W', 'L'] : [null, 'W', 'L1', 'L2'];
        let result: string|null = null;
        for (let i = 0; i < results.length; i++) {
            if(results[i] === chosenRollover.data.stage2Bets[index].result) result = results[(i+1)%results.length];
        }
        setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,i) => i===index ? {...x, result} : x)}}));
    }

    const deleteStage2Bet = (index: number) => {
        if(index===betToChoose.index-10) setBetToChoose({externalSearch: -1, index: -1});
        setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.filter((x,i) => i!==index).map(x => ({...x, coversToRecalculate: true}))}}));
    }
    
    const oddsMatchAfterSelectionChange = (oddsMatch: RolloverOddsMatch, oddsIndex: number, newValue: string, index: number) => {
        const mode = oddsMatch.mode;
        const selections = [...oddsMatch.selections];
        if(index>-1) {
            let filteredSelectionsValues = mode==='tri' ? selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text)) : (mode==='pb' ? selectionsValues.filter(x => !['1X','X2','12'].includes(x.text)) : selectionsValues);
            selections[oddsIndex] = filteredSelectionsValues[index].text;
            if(mode === 'pb') selections[1-oddsIndex] = filteredSelectionsValues[index].text;
            else if(oddsIndex === 0){
                if(mode === 'pp') selections[1] = coverSelectionPuntaPunta.get(filteredSelectionsValues[index].text) ?? '';
                else {
                    const covers = coverSelectionsTriscasser.get(filteredSelectionsValues[index].text);
                    selections[1] = covers ? covers[0] : '';
                    selections[2] = covers ? covers[1] : '';
                }
            }
        } else {
            selections[oddsIndex] = newValue;
            if(oddsIndex===0) {
                selections[1] = '';
                selections[2] = '';
            }
        }
        return {...oddsMatch, selections};
    }

    const handleStage1SelectionChange = (index: number, oddsIndex: number, newValue: string, selectionIndex: number) => {
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSelectionChange({...editingOddsMatch}, oddsIndex, newValue, selectionIndex));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => i===index ? oddsMatchAfterSelectionChange({...x}, oddsIndex, newValue, selectionIndex) : x)}}));
    }

    const handleStage2SelectionChange = (index: number, oddsIndex: number, newValue: string, selectionIndex: number) => {
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSelectionChange({...editingOddsMatch}, oddsIndex, newValue, selectionIndex));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,i) => i===index ? oddsMatchAfterSelectionChange({...x}, oddsIndex, newValue, selectionIndex) : x)}}));
    }

    const oddsMatchAfterSiteChange = (oddsMatch: RolloverOddsMatch, oddsIndex: number, siteIndex: number) => {
        return {...oddsMatch, 
            sitesIds: oddsMatch.sitesIds.map((z,k) => k===oddsIndex ? siteIndex : z), 
            sitesUrls: oddsMatch.sitesUrls.map((z,k) => k===oddsIndex ? null : z),
            fees: oddsMatch.mode==='pb' ? ((oddsIndex===1 ? siteIndex : oddsMatch.sitesIds[1])===9 ? feesBetflag : feesBetfair) : null
        };
    }

    const handleStage1SiteChange = (index: number, oddsIndex: number, siteIndex: number) => {
        const editing: boolean = editingIndex===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSiteChange({...editingOddsMatch}, oddsIndex, siteIndex));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => i===index ? oddsMatchAfterSiteChange({...x}, oddsIndex, siteIndex) : x)}}));
    }

    const handleStage2SiteChange = (index: number, oddsIndex: number, siteIndex: number) => {
        const editing: boolean = editingIndex-10===index;
        if(editing) editingOddsMatch!==undefined && setEditingOddsMatch(oddsMatchAfterSiteChange({...editingOddsMatch}, oddsIndex, siteIndex));
        else setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage2Bets: prevState.data.stage2Bets.map((x,i) => i===index ? oddsMatchAfterSiteChange({...x}, oddsIndex, siteIndex) : x)}}));
    }

    useEffect(() => {
        if(editingIndex===-1) return;
        const om: RolloverOddsMatch = editingIndex<10 ? chosenRollover.data.stage1Bets[editingIndex] : chosenRollover.data.stage2Bets[editingIndex-10];
        setEditingOddsMatch({
            ...om,
            amount: {...om.amount},
            event: om.event!==null ? {...om.event} : null,
            selections: [...om.selections],
            sitesIds: [...om.sitesIds],
            sitesUrls: [...om.sitesUrls],
            odds: om.odds.map(x => ({...x}))
        });
        const eventId = (om.event || {id: -1}).id;
        const eventText = om.eventText;
        let d = new Date(om.eventDate);
        const eventDate = om.eventDate.length>0
            ? {day: d.getDate().toString().padStart(2, '0'), month: (d.getMonth()+1).toString().padStart(2, '0'), year: d.getFullYear().toString(), hour: d.getHours().toString().padStart(2, '0'), minute: d.getMinutes().toString().padStart(2, '0')}
            : {day: '', month: '', year: '', hour: '', minute: '', errorMessage: 'Invalid date'};
        setEditingEvent({eventId, eventText, eventDate});
    }, [editingIndex]);

    const handleEventInputChange = (newState: EventInputState) => {
        if(editingEvent && editingEvent.eventId !== newState.eventId) resetEditingOdds(); 
        setEditingEvent(newState);
    }

    const resetEditingOdds = () => {
        setEditingOddsMatch(prevState => {
            if(!prevState) return prevState;
            return {
                ...prevState, 
                selections: ['','',''],
                sitesIds: [prevState.sitesIds[0],-1,-1],
                sitesUrls: [null,null,null],
                odds: [{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}],
                coversToRecalculate: true
            };
        });
    }

    const resetStage1Bet = (index: number) => {
        setChosenRollover(prevState => ({...prevState, data: {...prevState.data, stage1Bets: prevState.data.stage1Bets.map((x,i) => {
            if(i!==index) return x;
            return {
                ...x, 
                event: null,
                eventText: '',
                eventDate: '',
                selections: ['','',''],
                sitesIds: [x.sitesIds[0],-1,-1],
                sitesUrls: [null,null,null],
                odds: [{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'},{value: '', errorMessage: 'NaN'}],
                coversToRecalculate: true
            };
        })}}));
    }

    const saveChanges = () => {
        if(editingIndex===-1 || !editingEvent || !editingOddsMatch) return;
        const n = editingOddsMatch.mode === 'tri' ? 3 : 2;
        if(editingEvent.eventDate.errorMessage 
            // || editingEvent.eventText.length === 0
            || editingOddsMatch.sitesIds.some((x,i) => i<n && i>0 && x<0)
            || editingOddsMatch.odds.some((x,i) => i<n && x.errorMessage)
            || editingOddsMatch.selections.some((x,i) => i<n && x.length===0)
        ) {
            dispatch(addSnackbar({ type: 'error', message: 'Sono presenti degli errori o dei dati mancanti'}));
            return;
        }
        setChosenRollover(prevState => {
            const newBets: RolloverOddsMatch[] = (editingIndex < 10 ? prevState.data.stage1Bets : prevState.data.stage2Bets).map((x,i) => {
                if(i!==editingIndex-(editingIndex < 10 ? 0 : 10) || !editingOddsMatch || !editingEvent) return x;
                editingOddsMatch.eventText = editingEvent.eventText;
                if(editingEvent.eventId===-1) editingOddsMatch.event = null;
                else if(editingEvent.eventId !== editingOddsMatch.event?.id) editingOddsMatch.event = events.get(editingEvent.eventId) ?? null;
                const dateString = localeStringToUTCString(`${editingEvent.eventDate.year}-${editingEvent.eventDate.month.padStart(2, '0')}-${editingEvent.eventDate.day.padStart(2, '0')}T${editingEvent.eventDate.hour.padStart(2, '0')}:${editingEvent.eventDate.minute.padStart(2, '0')}`);
                if(editingOddsMatch.eventDate !== dateString) {
                    editingOddsMatch.eventDate = dateString;
                    if(editingOddsMatch.event) editingOddsMatch.event.datetime = dateString;
                }
                if(editingOddsMatch.sitesIds[0] !== prevState.data.siteId) {
                    editingOddsMatch.sitesIds[0] = prevState.data.siteId ?? 0;
                    editingOddsMatch.sitesUrls[0] = null;
                }
                return editingOddsMatch;
            });
            return {...prevState, data: {...prevState.data, stage1Bets: editingIndex < 10 ? newBets : prevState.data.stage1Bets, stage2Bets: editingIndex >= 10 ? newBets : prevState.data.stage2Bets}};
        });
        setEditingIndex(-1);
    }

    
    const rolloverAfterBonusOrBetsChange = (prevState: RolloverObj, bonus: NumberInputState, bets: NumberInputState) => {
        if(chosenRollover.data.bonus.errorMessage || chosenRollover.data.bets.errorMessage) return prevState;
        const newAmounts = [...prevState.data.stage1Bets.map(x => ({...x.amount}))];
        const b = parseFloat(bonus.value);
        const n = parseInt(bets.value);
        let rest = b;
        let divisor = n;
        for(let i = 0; i < n; i++) {
            if(!newAmounts[i].errorMessage && prevState.data.stage1Bets[i].result!==null) {
                rest -= parseFloat(newAmounts[i].value);
                divisor--; 
            }
        }
        if(divisor <= 0 && rest>0 && n<5) return {...prevState, data: {...prevState.data, bets: {value: (n+1).toString()}, bonus}};
        if((rest < 0 || (rest===0 && divisor>0)) && n>1) return {...prevState, data: {...prevState.data, bets: {value: (n-1).toString()}, bonus}};
        for(let i = 0; i < n; i++) {
            if(!newAmounts[i].errorMessage && prevState.data.stage1Bets[i].result!==null) continue;
            let value = Math.ceil(rest/divisor);
            rest -= value;
            divisor--;
            newAmounts[i] = {value: value.toFixed(0)};
        }
        return {...prevState, data: {...prevState.data, bonus, bets, stage1Bets: prevState.data.stage1Bets.map((x,i) => ({...x, amount: newAmounts[i], coversToRecalculate: x.coversToRecalculate || newAmounts[i].value !== x.amount.value}))}};
    };

    const handleBonusChange = (newState: NumberInputState) => setChosenRollover(prevState => rolloverAfterBonusOrBetsChange(prevState, newState, prevState.data.bets));
    const handleBetsChange = (newState: NumberInputState) => setChosenRollover(prevState => rolloverAfterBonusOrBetsChange(prevState, prevState.data.bonus, newState));
    
    const search = (index: number) => {
        if(index===-1 && progress && (progress.bonusBalanceIncludingAllBets<1 || progress.remainingRolloverIncludingAllBets<0.01)) {
            dispatch(addSnackbar({ type: 'error', message: 'Saldo bonus o rollover terminato'}));
            return;
        }
        if(index<10 && index>-1 && chosenRollover.data.stage1Bets[index].amount.errorMessage) {
            dispatch(addSnackbar({ type: 'error', message: 'Importo puntata non valido'}));
            return;
        }
        setBetToChoose({index, externalSearch: (new Date()).getTime()});
    }

    if(isHidden) return <></>;

    const siteId: number = chosenRollover.data.siteId || 0;
    const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
    const rolloverSite: Site|undefined = siteId === 0 ? otherSite : sites.get(siteId);

    const stage1BetsResults = !chosenRollover.data.bets.errorMessage && chosenRollover.data.stage1Bets.some((x, i) => i<parseInt(chosenRollover.data.bets.value) && x.result!==null);
    const stage1BetsRemaining = !chosenRollover.data.bets.errorMessage ? chosenRollover.data.stage1Bets.filter((x, i) => i<parseInt(chosenRollover.data.bets.value) && x.result===null).length : 0;//stage1Odds.filter((x, i) => i<parseInt(bets.value) && x.some((y,j) => j<(stage1Bets[i].mode==='tri' ? 3 : 2) && y.errorMessage)).length
    const stage1BetsFinished = !chosenRollover.data.bets.errorMessage && chosenRollover.data.stage1Bets.every((x, i) => i>=parseInt(chosenRollover.data.bets.value) || x.result!==null);
    const stage1BetsEditable = !chosenRollover.data.bets.errorMessage && chosenRollover.data.stage1Bets.every((x, i) => i>=parseInt(chosenRollover.data.bets.value) || x.event===null || x.result!==null);
    let filteredSelectionsValues = [...selectionsValues];
    if(editingIndex>-1 && editingIndex<10 && editingOddsMatch && editingOddsMatch.mode==='tri') filteredSelectionsValues = selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text));
    if(editingIndex>-1 && editingIndex<10 && editingOddsMatch && editingOddsMatch.mode==='pb') filteredSelectionsValues = selectionsValues.filter(x => !['1X','X2','12'].includes(x.text));
    if(editingIndex>=10 && editingOddsMatch && editingOddsMatch.mode==='tri') filteredSelectionsValues = selectionsValues.filter(x => coverSelectionsTriscasser.has(x.text));
    if(editingIndex>=10 && editingOddsMatch && editingOddsMatch.mode==='pb') filteredSelectionsValues = selectionsValues.filter(x => !['1X','X2','12'].includes(x.text));
    const bet: number = (betToChoose.index<10 && betToChoose.index>-1) ? parseFloat(chosenRollover.data.stage1Bets[betToChoose.index].amount.value) : 0;
    const multiplier: number = (betToChoose.index<10 && betToChoose.index>-1) ? parseFloat(chosenRollover.data.multiplier.value) : 0;
    const avgRating: number = (betToChoose.index<10 && betToChoose.index>-1) ? parseFloat(chosenRollover.data.avgRating.value) : 0;
    const _filters: OddsScasserFilters = (betToChoose.index<10 && betToChoose.index>-1) 
        ? {...filters, bet: bet.toString(), bonus: '0', refund: (Math.round((100-avgRating)*(multiplier-1)*bet)/100).toString(), isFreebet: false, feesBetfair, feesBetflag}
        : {...filters, bet: '100', bonus: '0', refund: '0', isFreebet: false, oddsMin: (chosenRollover.data.stage2OddsMin.value && !chosenRollover.data.stage2OddsMin.errorMessage) ? chosenRollover.data.stage2OddsMin.value : undefined, oddsMax: undefined, feesBetfair, feesBetflag}
    return( 
        <RolloverSelectorContainer readOnly={readOnly}>
            <Col gap='20px' width='100%'>
                    <Parameters direction={'row'}>
                        <Parameters>
                            <NumberInput isDisabled={stage1BetsResults} isDisabledOpacity={1} label='Bonus' value={chosenRollover.data.bonus.value} minValue={parseInt(chosenRollover.data.bets.value)} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBonusChange(newState)}/>
                            <NumberInput isDisabled={true} label='Rollover' value={chosenRollover.data.multiplier.value} minValue={1} maxValue={20} includeLimits={true} stepFunction={stepIntegers}/>
                        </Parameters>
                        <Parameters>
                            <NumberInput isDisabled={stage1BetsFinished}  isDisabledOpacity={1} label='# Puntate' value={chosenRollover.data.bets.value} minValue={1} maxValue={5} includeLimits={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => handleBetsChange(newState)}/>
                            <NumberInput isDisabled={true} label='Rating Medio' value={chosenRollover.data.avgRating.value} minValue={0} includeLimits={true} stepFunction={stepIntegers}/>
                        </Parameters>
                    </Parameters>
                    {chosenRollover.id===null && <StartButton onClick={() => saveRollover(chosenRollover)}>Inizia Rollover</StartButton>}
                    {(chosenRollover.id!==null && !chosenRollover.data.bonus.errorMessage && !chosenRollover.data.multiplier.errorMessage && !chosenRollover.data.bets.errorMessage && !chosenRollover.data.avgRating.errorMessage) &&
                        <>
                            <RolloverStagesContainer>
                                <RolloverStageContainer>
                                    <RolloverStageTitle>
                                        <RolloverStageTitleLabel>Puntate Bonus</RolloverStageTitleLabel>
                                    </RolloverStageTitle>
                                    <RolloverList>
                                        {chosenRollover.data.stage1Bets.map((x, index) => {
                                            if(index >= parseInt(chosenRollover.data.bets.value)) return null;
                                            const editing: boolean = editingIndex===index && !!editingOddsMatch;
                                            const om: RolloverOddsMatch = editing ? {...(editingOddsMatch ?? x), amount: x.amount} : x;
                                            const mode: string = om.mode;
                                            const res = om.result;
                                            const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
                                            const cover1Site: Site|undefined = om.sitesIds[1]===0 ? otherSite : sites.get(om.sitesIds[1]);
                                            const cover1Sites: Site[] = editing ? sitesList.filter(site => site.type === (mode==='pb' ? 'exchange' :'bookmaker')) : [];
                                            if(editing) cover1Sites.push(otherSite);
                                            const cover1SitesChecked = editing ? cover1Sites.map(x => x.id===cover1Site?.id) : [];
                                            const cover2Site: Site|undefined = mode==='tri' ? (om.sitesIds[2]===0 ? otherSite : sites.get(om.sitesIds[2])) : undefined;
                                            const cover2Sites: Site[] = (editing && mode==='tri') ? sitesList.filter(site => site.type === 'bookmaker') : [];
                                            if(editing && mode==='tri') cover2Sites.push(otherSite);
                                            const cover2SitesChecked = (editing && mode==='tri') ? cover2Sites.map(x => x.id===cover2Site?.id) : [];
                                            return (
                                                <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'center'}>
                                                    <RolloverElement isDisabled={!!res} key={index} backgroundColor='#3A454E' border={index!==betToChoose.index ? '1px solid #29353E' : '1px solid #FFC000'}>
                                                        <RolloverElementEventRow>
                                                            {om.event!==null && <Result notClickable={chosenRollover.data.stage2Bets.length>0} title={resultComment(res)} result={res ?? undefined} onClick={() => handleStage1ResultClick(index)}/>}
                                                            <NumberInput isDisabled={stage1BetsRemaining<=1 || res!==null}
                                                                value={x.amount.value} 
                                                                inputPlaceholder='Puntata'
                                                                height={24} small={true}
                                                                minValue={1} includeLimits={true} stepFunction={stepIntegers} 
                                                                onChange={(newState: NumberInputState) => handleStage1AmountChange(index, newState)}
                                                            />
                                                            {om.event!==null && <HorizontalScrollContainer hiddenScrollBar={true}><RolloverElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</RolloverElementEvent></HorizontalScrollContainer>}
                                                            {/* {!editing
                                                                ? <SVGIconContainer notClickable={res!==null || editingIndex>-1}><EditIconSVG title={!res ? 'Modifica' : ''} onClick={() => !res && editingIndex===-1 && setEditingIndex(prevState => editingIndex===index ? -1 : index)}/></SVGIconContainer>
                                                                : <>
                                                                    <SVGIconContainer><CheckmarkSVG title='Salva modifiche' onClick={saveChanges}/></SVGIconContainer>
                                                                    <SVGIconContainer size={13} fill='rgb(255, 66, 66)'><CloseSVG title='Annulla modifiche' onClick={() => setEditingIndex(-1)}/></SVGIconContainer>
                                                                </>
                                                            }
                                                            {(editing && editingEvent)
                                                                ? <EventInput isEventEditable={true} value={editingEvent} height={24} breakPoint={520} onChange={handleEventInputChange}/>
                                                                : <HorizontalScrollContainer hiddenScrollBar={true}><RolloverElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</RolloverElementEvent></HorizontalScrollContainer>
                                                            }
                                                            {(editingIndex===-1 && !res) && <SVGIconContainer><TrashIconSVG title="Resetta campi puntata" onClick={() => resetStage1Bet(index)}/></SVGIconContainer>} */}
                                                        </RolloverElementEventRow>
                                                        <RolloverElementOddsMatchRow>
                                                            {om.event===null && <SearchButton onClick={() => search(index)}>{`Cerca ${index+1}ª Puntata`}</SearchButton>}
                                                            {om.event!==null &&
                                                                <>
                                                                    <RolloverElementOdds>
                                                                        <RolloverElementCell>
                                                                            <SiteBox 
                                                                                checked={!!rolloverSite} 
                                                                                notClickable={!(rolloverSite && om.sitesUrls[0] && copyToClipboard)}
                                                                                name={rolloverSite?.name ?? ''} 
                                                                                width={60}
                                                                                height={36}
                                                                                backgroundColor={!rolloverSite ? '#000000a3' : rolloverSite.color}
                                                                                onClick={() => rolloverSite && om.sitesUrls[0] && copyToClipboard && copyToClipboard(om.sitesUrls[0], `URL ${rolloverSite.name} copiato`)}
                                                                            >
                                                                                {(rolloverSite && rolloverSite.id===0) && rolloverSite.name}
                                                                                {(rolloverSite && rolloverSite.id>0) && <SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer>}
                                                                            </SiteBox>
                                                                        </RolloverElementCell>
                                                                        <NumberInput isDisabled={res!==null} 
                                                                            value={om.odds.length>0 ? om.odds[0].value : ''} 
                                                                            inputPlaceholder='Quota'
                                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                            onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 0, newState)}
                                                                        />
                                                                        <RolloverElementCell>
                                                                            {!editing
                                                                                ? <RolloverElementOddsSelection title='Esito'>{om.selections[0]}</RolloverElementOddsSelection>
                                                                                :   <TextInputWithAutocomplete 
                                                                                        value={om.selections[0]} 
                                                                                        possibleValues={filteredSelectionsValues} 
                                                                                        inputPlaceholder='Esito'
                                                                                        inputWidth={'60px'}
                                                                                        height='100%'
                                                                                        fontSize={11}
                                                                                        allowInvalid={true}
                                                                                        emptyIsInvalid={true}
                                                                                        onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 0, newValue, selectionIndex)}
                                                                                    />
                                                                                    
                                                                            }
                                                                        </RolloverElementCell>
                                                                    </RolloverElementOdds>
                                                                    <RolloverElementOddsCoversContainer>
                                                                        <RolloverElementOddsCovers>
                                                                            {editing &&
                                                                                <Select name={"select-type"} value={mode} width={isAppMobile ? '43px' : '105px'} height='36px' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage1ModeChange(index, event.target.value)}>
                                                                                    <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                                    <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                                    <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                                </Select>
                                                                            }
                                                                            <RolloverElementOdds>
                                                                                <RolloverElementCell>
                                                                                    {!editing && 
                                                                                        <SiteBox 
                                                                                            checked={!!cover1Site} 
                                                                                            notClickable={!(cover1Site && om.sitesUrls[1] && copyToClipboard)}
                                                                                            name={cover1Site?.name ?? ''} 
                                                                                            width={60}
                                                                                            backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                                            onClick={() => cover1Site && om.sitesUrls[1] && copyToClipboard && copyToClipboard(om.sitesUrls[1], `URL ${cover1Site.name} copiato`)}
                                                                                        >
                                                                                            {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                                            {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                                        </SiteBox>
                                                                                    }
                                                                                    {editing &&                                                   
                                                                                        <Dropdown 
                                                                                            buttonText={cover1Site ? (cover1Site.id===0 ? cover1Site.name : undefined) : (mode==='pb' ? 'Exch' : 'Book')}
                                                                                            button={(cover1Site && cover1Site.id>0) 
                                                                                                ? <SiteBox checked={true} name={cover1Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                                : undefined
                                                                                            }
                                                                                            buttonColor={cover1Site ? cover1Site.color : undefined}
                                                                                            buttonWidth={60}
                                                                                            buttonHeight='100%'
                                                                                            content={
                                                                                                <DropdownContent width={175}>   
                                                                                                    <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                            cover1Sites.map((site, index) => site.id===0 
                                                                                                            ? <StandardCheckbox checked={cover1SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                            : <SiteBox checked={cover1SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover1SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                        } checked={cover1SitesChecked} onOptionClick={(checked: boolean[]) => handleStage1SiteChange(index, 1, checked.findIndex(Boolean)>-1 ? cover1Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                    </SitesCheckboxes>
                                                                                                </DropdownContent>
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                </RolloverElementCell> 
                                                                                <NumberInput isDisabled={!!res} 
                                                                                    value={om.odds.length>0 ? om.odds[1].value : ''} 
                                                                                    inputPlaceholder='Quota'
                                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                                    onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 1, newState)}
                                                                                />
                                                                                <RolloverElementCell>
                                                                                    {!editing
                                                                                        ? <RolloverElementOddsSelection title='Esito'>{om.selections[1]}</RolloverElementOddsSelection>
                                                                                        :   <TextInputWithAutocomplete 
                                                                                                value={om.selections[1]} 
                                                                                                possibleValues={filteredSelectionsValues} 
                                                                                                inputPlaceholder='Esito'
                                                                                                inputWidth={'60px'}
                                                                                                height='100%' 
                                                                                                fontSize={11}
                                                                                                allowInvalid={true}
                                                                                                emptyIsInvalid={true}
                                                                                                onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 1, newValue, selectionIndex)}
                                                                                            />
                                                                                    }
                                                                                </RolloverElementCell>   
                                                                            </RolloverElementOdds>
                                                                            {mode==='tri' &&
                                                                                <RolloverElementOdds>
                                                                                    <RolloverElementCell>
                                                                                        {!editing && 
                                                                                            <SiteBox 
                                                                                                checked={!!cover2Site} 
                                                                                                notClickable={!(cover2Site && om.sitesUrls[2] && copyToClipboard)}
                                                                                                name={cover2Site?.name ?? ''} 
                                                                                                width={60}
                                                                                                backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                                                onClick={() => cover2Site && om.sitesUrls[2] && copyToClipboard && copyToClipboard(om.sitesUrls[2], `URL ${cover2Site.name} copiato`)}
                                                                                            >
                                                                                                {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                                                {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                                            </SiteBox>
                                                                                        }
                                                                                        {editing &&
                                                                                            <Dropdown 
                                                                                            buttonText={cover2Site ? (cover2Site.id===0 ? cover2Site.name : undefined) : 'Book'}
                                                                                                button={(cover2Site && cover2Site.id>0) 
                                                                                                    ? <SiteBox checked={true} name={cover2Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer></SiteBox> 
                                                                                                    : undefined
                                                                                                }
                                                                                                buttonColor={cover2Site ? cover2Site.color : undefined}
                                                                                                buttonWidth={60}
                                                                                                buttonHeight='100%'
                                                                                                content={
                                                                                                    <DropdownContent width={175}>   
                                                                                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                                cover2Sites.map((site, index) => site.id===0 
                                                                                                                ? <StandardCheckbox checked={cover2SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                                : <SiteBox checked={cover2SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover2SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                            } checked={cover2SitesChecked} onOptionClick={(checked: boolean[]) => handleStage1SiteChange(index, 2, checked.findIndex(Boolean)>-1 ? cover2Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                        </SitesCheckboxes>
                                                                                                    </DropdownContent>
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    </RolloverElementCell>
                                                                                    <NumberInput isDisabled={!!res} 
                                                                                        value={om.odds.length>0 ? om.odds[2].value : ''} 
                                                                                        inputPlaceholder='Quota'
                                                                                        minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                                        onChange={(newState: NumberInputState) => handleStage1OddsChange(index, 2, newState)}
                                                                                    />
                                                                                    <RolloverElementCell>
                                                                                        {!editing
                                                                                            ? <RolloverElementOddsSelection title='Esito'>{om.selections[2]}</RolloverElementOddsSelection>
                                                                                            :   <TextInputWithAutocomplete 
                                                                                                    value={om.selections[2]} 
                                                                                                    possibleValues={filteredSelectionsValues} 
                                                                                                    inputPlaceholder='Esito'
                                                                                                    inputWidth={'60px'}
                                                                                                    height='100%' 
                                                                                                    fontSize={11}
                                                                                                    allowInvalid={true}
                                                                                                    emptyIsInvalid={true}
                                                                                                    onChange={(newValue: string, selectionIndex: number) => handleStage1SelectionChange(index, 2, newValue, selectionIndex)}
                                                                                                />
                                                                                        }
                                                                                    </RolloverElementCell>
                                                                                </RolloverElementOdds>
                                                                            }
                                                                        </RolloverElementOddsCovers>
                                                                    </RolloverElementOddsCoversContainer>
                                                                </>
                                                            }
                                                        </RolloverElementOddsMatchRow>
                                                        {(res===null && !editing && om.event!==null) &&
                                                            <CoverInstructionsContainer>
                                                                {
                                                                    stage1Covers[index].covers.map((c,i) => {
                                                                        const site = i===1 ? cover1Site : cover2Site;
                                                                        if(i===0 || !site) return null;
                                                                        return(
                                                                            <CoverInstructions key={'c'+i}>
                                                                                    <SiteBox 
                                                                                    checked={!!site} 
                                                                                    notClickable={!(site && om.sitesUrls[i] && copyToClipboard)}
                                                                                    name={site?.name ?? ''} 
                                                                                    width={60}
                                                                                    height={36}
                                                                                    backgroundColor={!site ? '#000000a3' : site.color}
                                                                                    onClick={() => site && om.sitesUrls[i] && copyToClipboard && copyToClipboard(om.sitesUrls[i], `URL ${site.name} copiato`)}
                                                                                >
                                                                                    {(site && site.id===0) && site.name}
                                                                                    {(site && site.id>0) && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                                                </SiteBox>
                                                                                <CoverInstructionsLabelContainer withSite={true} backgroundColor='#323b42'>
                                                                                    <HorizontalScrollContainer hiddenScrollBar={true}>
                                                                                        <CoverInstructionsLabel>
                                                                                            <CoverInstructionsText>{mode==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                                            <BetContainer>
                                                                                                <Bet translateY={mode==='pb' ? 5 : 0}>{'€'+(c.bet ? c.bet.toFixed(mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                {mode==='pb' && <Resp title='Responsabilità' translateY={1}>{'€'+(c.resp ? c.resp.toFixed(2) : '-')}</Resp>}
                                                                                            </BetContainer>
                                                                                            <CoverInstructionsText>esito</CoverInstructionsText>
                                                                                            <CoverInstructionsStrongText>{om.selections[i]}</CoverInstructionsStrongText> 
                                                                                            <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                                            <CoverInstructionsStrongText>{om.odds[i].errorMessage ? '-' : parseFloat(om.odds[i].value).toFixed(2)}</CoverInstructionsStrongText>
                                                                                        </CoverInstructionsLabel>
                                                                                    </HorizontalScrollContainer>
                                                                                </CoverInstructionsLabelContainer>
                                                                            </CoverInstructions>
                                                                        );
                                                                    })
                                                                }
                                                            </CoverInstructionsContainer> 
                                                        }
                                                    </RolloverElement> 
                                                    {(!stage1BetsFinished && !stage1BetsEditable) && <SearchButton isHidden={res!==null || om.event===null} onClick={() => search(index)}>Modifica</SearchButton>}
                                                </Flex>
                                            );
                                        })}
                                    </RolloverList>
                                </RolloverStageContainer>
                                {(stage1BetsFinished && progress && (chosenRollover.data.stage2Bets.length>0 || progress.bonusBalance>0.99)) &&
                                    <RolloverStageContainer isDisabled={!stage1BetsFinished}>
                                        <RolloverStageTitle>
                                            <RolloverStageTitleLabel>Completamento Rollover</RolloverStageTitleLabel>
                                        </RolloverStageTitle>
                                        <RolloverStage2Info>
                                            <RolloverInfoElement><RolloverInfoLabel>Saldo bonus</RolloverInfoLabel><RolloverInfoValue>€{progress.bonusBalance.toFixed(2)}</RolloverInfoValue></RolloverInfoElement>
                                            <RolloverInfoElement><RolloverInfoLabel>Rollover residuo</RolloverInfoLabel><RolloverInfoValue>€{progress.remainingRollover.toFixed(2)}</RolloverInfoValue></RolloverInfoElement>
                                        </RolloverStage2Info>
                                        {chosenRollover.data.stage2Bets.length>0 &&
                                            <RolloverList>
                                                {chosenRollover.data.stage2Bets.map((x, index) => {
                                                    const editing: boolean = editingIndex-10===index && !!editingOddsMatch;
                                                    const om: RolloverOddsMatch = editing ? {...(editingOddsMatch ?? x), amount: x.amount} : x;
                                                    const mode: string = om.mode;
                                                    const res = om.result;
                                                    const resultIndex = (om.mode !== 'tri' ? ['W', 'L'] : ['W', 'L1', 'L2']).findIndex(x => res===x);
                                                    const winnings: number|undefined = stage2Covers.length>index ? (resultIndex>-1 ? stage2Covers[index].covers[resultIndex].winnings : stage2Covers[index].avgWinnings) : undefined;
                                                    const ratings: number|undefined = stage2Covers.length>index ? (resultIndex>-1 ? stage2Covers[index].covers[resultIndex].rating : stage2Covers[index].avgRating) : undefined;
                                                    const otherSite: Site = {id: 0, name: 'Altro', color: '#133480'};
                                                    const cover1Site: Site|undefined = om.sitesIds[1]===0 ? otherSite : sites.get(om.sitesIds[1]);
                                                    const cover1Sites: Site[] = editing ? sitesList.filter(site => site.type === (mode==='pb' ? 'exchange' :'bookmaker')) : [];
                                                    if(editing) cover1Sites.push(otherSite);
                                                    const cover1SitesChecked = editing ? cover1Sites.map(x => x.id===cover1Site?.id) : [];
                                                    const cover2Site: Site|undefined = mode==='tri' ? (om.sitesIds[2]===0 ? otherSite : sites.get(om.sitesIds[2])) : undefined;
                                                    const cover2Sites: Site[] = (editing && mode==='tri') ? sitesList.filter(site => site.type === 'bookmaker') : [];
                                                    if(editing && mode==='tri') cover2Sites.push(otherSite);
                                                    const cover2SitesChecked = (editing && mode==='tri') ? cover2Sites.map(x => x.id===cover2Site?.id) : [];
                                                    return (
                                                        <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'stretch'}>
                                                            <RolloverElement isDisabled={!!res} backgroundColor='#3A454E' border={index!==betToChoose.index-10 ? '1px solid #29353E' : '1px solid #FFC000'}>
                                                                <RolloverElementEventRow>
                                                                    <Result title={resultComment(res)} result={res ?? undefined} onClick={() => handleStage2ResultClick(index)}/>
                                                                    <NumberInput isDisabled={res!==null}
                                                                        value={x.amount.value} 
                                                                        inputPlaceholder='Puntata'
                                                                        height={24} small={true}
                                                                        minValue={1} includeLimits={true} stepFunction={stepIntegers} 
                                                                        onChange={(newState: NumberInputState) => handleStage2AmountChange(index, newState)}
                                                                    />
                                                                    {!editing
                                                                        ? <SVGIconContainer notClickable={res!==null || editingIndex>-1}><EditIconSVG title={!res ? 'Modifica' : ''} onClick={() => !res && editingIndex===-1 && setEditingIndex(prevState => editingIndex-10===index ? -1 : index+10)}/></SVGIconContainer>
                                                                        : <>
                                                                            <SVGIconContainer><CheckmarkSVG title='Salva modifiche' onClick={saveChanges}/></SVGIconContainer>
                                                                            <SVGIconContainer size={13} fill='rgb(255, 66, 66)'><CloseSVG title='Annulla modifiche' onClick={() => setEditingIndex(-1)}/></SVGIconContainer>
                                                                        </>
                                                                    }
                                                                    {(editing && editingEvent)
                                                                        ? <EventInput isEventEditable={true} value={editingEvent} height={24} breakPoint={520} onChange={handleEventInputChange}/>
                                                                        : <HorizontalScrollContainer hiddenScrollBar={true}><RolloverElementEvent>{formatDate(om.eventDate)} &nbsp; {om.eventText}</RolloverElementEvent></HorizontalScrollContainer>
                                                                    }
                                                                    {(editingIndex===-1 && !res) && <SVGIconContainer><TrashIconSVG title="Elimina puntata" onClick={() => deleteStage2Bet(index)}/></SVGIconContainer>}
                                                                </RolloverElementEventRow>
                                                                <RolloverElementOddsMatchRow>
                                                                    <RolloverElementOdds>
                                                                    <RolloverElementCell>
                                                                        <SiteBox 
                                                                            checked={!!rolloverSite} 
                                                                            notClickable={!(rolloverSite && om.sitesUrls[0] && copyToClipboard)}
                                                                            name={rolloverSite?.name ?? ''} 
                                                                            width={60}
                                                                            backgroundColor={!rolloverSite ? '#000000a3' : rolloverSite.color}
                                                                            onClick={() => rolloverSite && om.sitesUrls[0] && copyToClipboard && copyToClipboard(om.sitesUrls[0], `URL ${rolloverSite.name} copiato`)}
                                                                        >
                                                                            {rolloverSite && <SiteLogoContainer visible={true} backgroundColor={rolloverSite.color}><SiteLogo width={'95%'} imageUrl={rolloverSite.imageUrl}/></SiteLogoContainer>}
                                                                        </SiteBox>
                                                                    </RolloverElementCell>
                                                                        <NumberInput isDisabled={res!==null} 
                                                                            value={om.odds.length>0 ? om.odds[0].value : ''} 
                                                                            inputPlaceholder='Quota'
                                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                            onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 0, newState)}
                                                                        />
                                                                        <RolloverElementCell>
                                                                            {!editing
                                                                                ? <RolloverElementOddsSelection title='Esito'>{om.selections[0]}</RolloverElementOddsSelection>
                                                                                :   <TextInputWithAutocomplete 
                                                                                        value={om.selections[0]} 
                                                                                        possibleValues={filteredSelectionsValues} 
                                                                                        inputPlaceholder='Esito'
                                                                                        inputWidth={'60px'}
                                                                                        height='100%'
                                                                                        fontSize={11}
                                                                                        allowInvalid={true}
                                                                                        emptyIsInvalid={true}
                                                                                        onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 0, newValue, selectionIndex)}
                                                                                    />
                                                                                    
                                                                            }
                                                                        </RolloverElementCell>
                                                                    </RolloverElementOdds>
                                                                    <RolloverElementOddsCoversContainer>
                                                                        <RolloverElementOddsCovers>
                                                                            {editing &&
                                                                                <Select name={"select-type"} value={mode} width={isAppMobile ? '43px' : '105px'} height='36px' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleStage2ModeChange(index, event.target.value)}>
                                                                                    <option value="pb">{isAppMobile ? 'PB' : 'Punta Banca'}</option>
                                                                                    <option value="pp">{isAppMobile ? 'PP' : 'Punta Punta'}</option>
                                                                                    <option value="tri">{isAppMobile ? 'Tri' : 'Triscasser'}</option>
                                                                                </Select>
                                                                            }
                                                                            <RolloverElementOdds>
                                                                                <RolloverElementCell>
                                                                                    {!editing && 
                                                                                        <SiteBox 
                                                                                            checked={!!cover1Site} 
                                                                                            notClickable={!(cover1Site && om.sitesUrls[1] && copyToClipboard)}
                                                                                            name={cover1Site?.name ?? ''} 
                                                                                            width={60}
                                                                                            backgroundColor={!cover1Site ? '#000000a3' : cover1Site.color}
                                                                                            onClick={() => cover1Site && om.sitesUrls[1] && copyToClipboard && copyToClipboard(om.sitesUrls[1], `URL ${cover1Site.name} copiato`)}
                                                                                        >
                                                                                            {(cover1Site && cover1Site.id===0) && cover1Site.name}
                                                                                            {(cover1Site && cover1Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer>}
                                                                                        </SiteBox>
                                                                                    }
                                                                                    {editing &&                                                   
                                                                                        <Dropdown 
                                                                                        buttonText={cover1Site ? (cover1Site.id===0 ? cover1Site.name : undefined) : (mode==='pb' ? 'Exch' : 'Book')}
                                                                                            button={(cover1Site && cover1Site.id>0) 
                                                                                                ? <SiteBox checked={true} name={cover1Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover1Site.color}><SiteLogo width={'95%'} imageUrl={cover1Site.imageUrl}/></SiteLogoContainer></SiteBox>
                                                                                                : undefined
                                                                                            }
                                                                                            buttonColor={cover1Site ? cover1Site.color : undefined}
                                                                                            buttonWidth={60}
                                                                                            buttonHeight='100%'
                                                                                            content={
                                                                                                <DropdownContent width={175}>   
                                                                                                    <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                            cover1Sites.map((site, index) => site.id===0 
                                                                                                            ? <StandardCheckbox checked={cover1SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                            : <SiteBox checked={cover1SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover1SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                        } checked={cover1SitesChecked} onOptionClick={(checked: boolean[]) => handleStage2SiteChange(index, 1, checked.findIndex(Boolean)>-1 ? cover1Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                    </SitesCheckboxes>
                                                                                                </DropdownContent>
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                </RolloverElementCell> 
                                                                                <NumberInput isDisabled={!!res} 
                                                                                    value={om.odds.length>0 ? om.odds[1].value : ''} 
                                                                                    inputPlaceholder='Quota'
                                                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={om.mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                                                    onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 1, newState)}
                                                                                />
                                                                                <RolloverElementCell>
                                                                                    {!editing
                                                                                        ? <RolloverElementOddsSelection title='Esito'>{om.selections[1]}</RolloverElementOddsSelection>
                                                                                        :   <TextInputWithAutocomplete 
                                                                                                value={om.selections[1]} 
                                                                                                possibleValues={filteredSelectionsValues} 
                                                                                                inputPlaceholder='Esito'
                                                                                                inputWidth={'60px'}
                                                                                                height='100%' 
                                                                                                fontSize={11}
                                                                                                allowInvalid={true}
                                                                                                emptyIsInvalid={true}
                                                                                                onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 1, newValue, selectionIndex)}
                                                                                            />
                                                                                    }
                                                                                </RolloverElementCell>   
                                                                            </RolloverElementOdds>
                                                                            {mode==='tri' &&
                                                                                <RolloverElementOdds>
                                                                                    <RolloverElementCell>
                                                                                        {!editing && 
                                                                                            <SiteBox 
                                                                                                checked={!!cover2Site} 
                                                                                                notClickable={!(cover2Site && om.sitesUrls[2] && copyToClipboard)}
                                                                                                name={cover2Site?.name ?? ''} 
                                                                                                width={60}
                                                                                                backgroundColor={!cover2Site ? '#000000a3' : cover2Site.color}
                                                                                                onClick={() => cover2Site && om.sitesUrls[2] && copyToClipboard && copyToClipboard(om.sitesUrls[2], `URL ${cover2Site.name} copiato`)}
                                                                                            >
                                                                                                {(cover2Site && cover2Site.id===0) && cover2Site.name}
                                                                                                {(cover2Site && cover2Site.id>0) && <SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer>}
                                                                                            </SiteBox>
                                                                                        }
                                                                                        {editing &&
                                                                                            <Dropdown 
                                                                                                buttonText={cover2Site ? (cover2Site.id===0 ? cover2Site.name : undefined) : 'Book'}
                                                                                                button={(cover2Site && cover2Site.id>0) 
                                                                                                    ? <SiteBox checked={true} name={cover2Site.name} width={60}><SiteLogoContainer visible={true} backgroundColor={cover2Site.color}><SiteLogo width={'95%'} imageUrl={cover2Site.imageUrl}/></SiteLogoContainer></SiteBox> 
                                                                                                    : undefined
                                                                                                }
                                                                                                buttonColor={cover2Site ? cover2Site.color : undefined}
                                                                                                buttonWidth={60}
                                                                                                buttonHeight='100%'
                                                                                                content={
                                                                                                    <DropdownContent width={175}>   
                                                                                                        <SitesCheckboxes><CheckboxesGroup maxChecked={1} options={ 
                                                                                                                cover2Sites.map((site, index) => site.id===0 
                                                                                                                ? <StandardCheckbox checked={cover2SitesChecked[index]} backgroundColor={site.color}>{site.name}</StandardCheckbox>
                                                                                                                : <SiteBox checked={cover2SitesChecked[index]} name={site.name}><SiteLogoContainer visible={cover2SitesChecked[index]} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer></SiteBox>)
                                                                                                            } checked={cover2SitesChecked} onOptionClick={(checked: boolean[]) => handleStage2SiteChange(index, 2, checked.findIndex(Boolean)>-1 ? cover2Sites[checked.findIndex(Boolean)].id : -1)}/>
                                                                                                        </SitesCheckboxes>
                                                                                                    </DropdownContent>
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    </RolloverElementCell>
                                                                                    <NumberInput isDisabled={!!res} 
                                                                                        value={om.odds.length>0 ? om.odds[2].value : ''} 
                                                                                        inputPlaceholder='Quota'
                                                                                        minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                                        onChange={(newState: NumberInputState) => handleStage2OddsChange(index, 2, newState)}
                                                                                    />
                                                                                    <RolloverElementCell>
                                                                                        {!editing
                                                                                            ? <RolloverElementOddsSelection title='Esito'>{om.selections[2]}</RolloverElementOddsSelection>
                                                                                            :   <TextInputWithAutocomplete 
                                                                                                    value={om.selections[2]} 
                                                                                                    possibleValues={filteredSelectionsValues} 
                                                                                                    inputPlaceholder='Esito'
                                                                                                    inputWidth={'60px'}
                                                                                                    height='100%' 
                                                                                                    fontSize={11}
                                                                                                    allowInvalid={true}
                                                                                                    emptyIsInvalid={true}
                                                                                                    onChange={(newValue: string, selectionIndex: number) => handleStage2SelectionChange(index, 2, newValue, selectionIndex)}
                                                                                                />
                                                                                        }
                                                                                    </RolloverElementCell>
                                                                                </RolloverElementOdds>
                                                                            }
                                                                        </RolloverElementOddsCovers>
                                                                    </RolloverElementOddsCoversContainer>
                                                                </RolloverElementOddsMatchRow>
                                                                {(res===null && !editing && stage2Covers.length>index) &&
                                                                    <CoverInstructionsContainer>
                                                                        {
                                                                            stage2Covers[index].covers.map((c,i) => {
                                                                                const site = i===1 ? cover1Site : cover2Site;
                                                                                if(i===0 || (mode!=='tri' && i===2)) return null;
                                                                                return(
                                                                                    <CoverInstructions key={'c'+i}>
                                                                                        <SiteBox 
                                                                                            checked={!!site} 
                                                                                            notClickable={!(site && om.sitesUrls[i] && copyToClipboard)}
                                                                                            name={site?.name ?? ''} 
                                                                                            width={60}
                                                                                            height={36}
                                                                                            backgroundColor={!site ? '#000000a3' : undefined}
                                                                                            onClick={() => site && om.sitesUrls[i] && copyToClipboard && copyToClipboard(om.sitesUrls[i], `URL ${site.name} copiato`)}
                                                                                        >
                                                                                            {site && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                                                        </SiteBox>
                                                                                        <CoverInstructionsLabelContainer withSite={true} backgroundColor='#323b42'>
                                                                                            <HorizontalScrollContainer hiddenScrollBar={true}>
                                                                                                <CoverInstructionsLabel>
                                                                                                    <CoverInstructionsText>{mode==='pb' ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                                                                    <BetContainer>
                                                                                                        <Bet translateY={mode==='pb' ? 5 : 0}>{'€'+(c.bet ? c.bet.toFixed(mode==='pb' ? 2 : 0) : '-')}</Bet>
                                                                                                        {mode==='pb' && <Resp title='Responsabilità' translateY={1}>{'€'+(c.resp ? c.resp.toFixed(2) : '-')}</Resp>}
                                                                                                    </BetContainer>
                                                                                                    <CoverInstructionsText>esito</CoverInstructionsText>
                                                                                                    <CoverInstructionsStrongText>{om.selections[i]}</CoverInstructionsStrongText> 
                                                                                                    <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                                                    <CoverInstructionsStrongText>{om.odds[i].errorMessage ? '-' : parseFloat(om.odds[i].value).toFixed(2)}</CoverInstructionsStrongText>
                                                                                                </CoverInstructionsLabel>
                                                                                            </HorizontalScrollContainer>
                                                                                        </CoverInstructionsLabelContainer>
                                                                                    </CoverInstructions>
                                                                                );
                                                                            })
                                                                        }
                                                                    </CoverInstructionsContainer> 
                                                                }
                                                            </RolloverElement> 
                                                            <Col mainAxis='space-evenly' gap='2px'>
                                                                <WinnigsAndRatingContainer>
                                                                    <WinnigsAndRating flexDirection={isAppMobile ? 'row' : 'column'} boxed={false} padding='0 5px'>
                                                                        <Winnings translateY={isAppMobile ? 0 : 5} amount={winnings!==undefined ? winnings : 0}>€{winnings!==undefined ? winnings.toFixed(2) : '-'}</Winnings>
                                                                        <Rating translateY={isAppMobile ? 0 : 2}>{ratings!==undefined ? ratings.toFixed(2) : '-'}%</Rating>
                                                                    </WinnigsAndRating>
                                                                </WinnigsAndRatingContainer>
                                                                {res===null && <SearchButton onClick={() => search(index+10)}>Modifica</SearchButton>}
                                                            </Col>
                                                            
                                                        </Flex>
                                                    );
                                                })}
                                                {(progress?.remainingRolloverIncludingAllBets ?? 0)<-0.001 && 
                                                    <OverRolloverWarning>
                                                        <WarningIconsContainer>
                                                            {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                            <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                        </WarningIconsContainer>
                                                        <OverRolloverWarningText>Stai Puntando più del Necessario</OverRolloverWarningText>
                                                        <WarningIconsContainer>
                                                            {!isAppMobile && <><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer><SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer></>}
                                                            <SVGIconContainer notClickable={true}><WarningIconSVG/></SVGIconContainer>
                                                        </WarningIconsContainer>
                                                    </OverRolloverWarning>
                                                }
                                            </RolloverList>
                                        }
                                        {(progress.bonusBalanceIncludingAllBets>0.99 && progress.remainingRolloverIncludingAllBets>0.01)  && <RolloverCompletionAdd title='Aggiungi puntata' onClick={() =>  search(-1)}>+</RolloverCompletionAdd>}
                                    </RolloverStageContainer>
                                }
                            </RolloverStagesContainer>
                            {progress && 
                                <RolloverInfoContainer>
                                    {(progress.bonusBalance>0.99 && progress.remainingRollover>0.01) && 
                                        <>
                                            <RolloverInfoElement><RolloverInfoLabel>Atteso iniziale</RolloverInfoLabel><RolloverInfoValue v={progress.expectedValue}>€{progress.expectedValue!==undefined ? progress.expectedValue.toFixed(2) : '-'}</RolloverInfoValue></RolloverInfoElement>
                                            <RolloverInfoElement><RolloverInfoLabel>Atteso reale</RolloverInfoLabel><RolloverInfoValue v={progress.realExpectedValue}>€{progress.realExpectedValue!==undefined ? progress.realExpectedValue.toFixed(2) : '-'}</RolloverInfoValue></RolloverInfoElement>
                                        </>
                                    }
                                    {progress.bonusBalance<0.99 && <><RolloverFinishedReason>Saldo bonus terminato</RolloverFinishedReason><RolloverFinishedValue v={progress.total}>€{progress.total.toFixed(2)}</RolloverFinishedValue></>}
                                    {(progress.bonusBalance>0.99 && progress.remainingRollover<0.01) && <><RolloverFinishedReason>Rollover completato</RolloverFinishedReason><RolloverFinishedValue v={progress.total}>€{progress.total.toFixed(2)}</RolloverFinishedValue></>}
                                </RolloverInfoContainer>
                            }
                        </>
                    }
            </Col>
            {(_filters && betToChoose.externalSearch>-1) && <OddsMatchSelector filters={_filters} externalSearch={betToChoose.externalSearch} onChoose={(om: OddsMatch) => handleOddsMatchChoice(om)} copyToClipboard={copyToClipboard}/>}
        </RolloverSelectorContainer>
    );
}

export default memo(RolloverSelector);