import { FC, useEffect, useState, useMemo, Fragment } from 'react';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

// utils
import { _getBestEvents, _getCoverOdds } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

//components
import LoadingMask from 'components/ui/LoadingMask';
import NumberInput from 'components/ui/NumberInput';
import CoverOddsModal from 'pages/OddsScasserPage/OddsScasser/Multiple/CoverOddsModal';

//assets
import { ReactComponent as ChevronLeftIcon } from 'pages/OddsScasserPage/assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'pages/OddsScasserPage/assets/chevronRight.svg';
import { ReactComponent as SearchIconSVG } from 'pages/OddsScasserPage/assets/searchIcon.svg';
import { ReactComponent as CheckboxOnSVG } from 'pages/OddsScasserPage/assets/checkboxOn.svg';
import { ReactComponent as CheckboxOffSVG } from 'pages/OddsScasserPage/assets/checkboxOff.svg';

//types
import { OddsMatchSelectorProps } from './OddsMatchSelector.component.d';
import { OddsMatch } from 'pages/OddsScasserPage/types/OddsMatch';
import { Odds } from 'pages/OddsScasserPage/types/Odds';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { Event } from 'pages/OddsScasserPage/types/Event';

//style
import { FooterNavigationButton, FooterPageButton, FooterPagesButtonsContainer, TableFooter } from '../style/BonusPage.style';
import { SearchButton } from './style/OddsMatchSelector.style';
import { Col, Row } from 'style/layout';

//helpers
import { calculatePuntaBanca, calculatePuntaPunta, coverSelectionPuntaPunta, formatDate, stepOdds, stepOddsPP } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import { SingleElement, SingleElementEventRow, SVGIconContainer, HorizontalScrollContainer, SingleElementEvent, SingleElementOddsMatchRow, SingleElementOdds, SiteBox, SiteLogoContainer, SiteLogo, DropdownContent, SitesCheckboxes, StandardCheckbox, SingleElementCell, SingleElementOddsSelection, SingleElementOddsCoversContainer, SingleElementOddsCovers, Flex, WinnigsAndRatingContainer, Rating, WinnigsAndRating, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';

const OddsMatchSelector: FC<OddsMatchSelectorProps> = ({ externalSearch, buttonText, filters, onChoose, copyToClipboard }) => {
    const pageSize: number = 5;

    const { isAppMobile } = useAppSelector(state => state.ui);

    const { sites, events } = useAppSelector(state => state.oddsScasser);

    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [oddsMatches, setOddsMatches] = useState<OddsMatch[]>([]);
    const [coverOdds, setCoverOdds] = useState<{eventId: number, selection: string, odds: number, coverOdds: Odds[]}|undefined>(undefined);
    
    useEffect(() => {
        if(externalSearch) search(0);
    }, [externalSearch]);

    const search = async (_page: number) => {
        try {
            setIsLoading(true);
            setPage(_page);
            const results = await _getBestEvents({...filters, size: pageSize, page: _page});
            if(results) {
                setOddsMatches(results.map((x: OddsMatch) => calculate(x)));
                setIsLoading(false);
            }
        } catch (error: any) {
            console.warn(error);
            dispatch(addSnackbar({ type: 'error', message: 'Errore nella richiesta' }));
        }
    }

    useEffect(() => setOddsMatches(prevState => prevState.map((x: OddsMatch) => calculate(x))), [filters]);

    const calculate = (oddsMatch: OddsMatch) => {
        oddsMatch.event = events.get(oddsMatch.event.id) || oddsMatch.event;
        const bet = parseFloat(filters.bet) || 0;
        const bonus = filters.isFreebet ? 0 : parseFloat(filters.bonus) || 0;
        const refund = parseFloat(filters.isFreebet ? filters.bet : filters.refund) || 0;
        const fees = oddsMatch.odds[1].site?.id===9 ? parseFloat(filters.feesBetflag ?? '5')/100 : parseFloat(filters.feesBetfair ?? '4.5')/100;
        const odds = oddsMatch.odds.map(x => x.odds);
        const errors = isNaN(bet) || bet<=0.01 || isNaN(bonus) || isNaN(refund) || odds.some(x => isNaN(x));
        const res = sites.get(oddsMatch.odds[1].site?.id)?.type === 'exchange'
        ? calculatePuntaBanca(bet, bonus, refund, fees, odds, 0.01)
        : calculatePuntaPunta(bet, bonus, refund, odds, 1);
        for (let i = 0; i < res.bets.length; i++) {
            if(i>oddsMatch.odds.length-1) break; 
            oddsMatch.odds[i].coverBet = !errors ? res.bets[i] : undefined;
            oddsMatch.odds[i].coverResp = !errors ? res.resp[i] : undefined;
            oddsMatch.odds[i].winnings = !errors ? res.winnings[i] : undefined;
            oddsMatch.odds[i].rating = !errors ? res.ratings[i] : undefined;
        }
        oddsMatch.avgRating = !errors ? res.avgRating : undefined;
        oddsMatch.avgWinnings = !errors ? res.avgWinnings : undefined;
        return oddsMatch;
    };

    const searchCovers = async (index: number) => {
        const selection =  oddsMatches[index].odds[0].type;
        if(!selection || !coverSelectionPuntaPunta.has(selection)) {
            dispatch(addSnackbar({ type: 'error', message: 'Esito non valido'}));
            return;
        }
        const params = {eventId: oddsMatches[index].event.id, selection};
        setCoverOdds({eventId: params.eventId, selection: params.selection, odds: oddsMatches[index].odds[0].odds, coverOdds: await _getCoverOdds(params) as Odds[]});
    }

    const orderedCoverOddsMatches: OddsMatch[]|undefined = useMemo(() => {
        const siteId: number = parseInt(filters.sites1?.split(',')[0] ?? '-1');
        if(!coverOdds || siteId === -1) return undefined;
        const oddsMatches: OddsMatch[] = [];
        coverOdds.coverOdds.filter(x => (x.type===coverSelectionPuntaPunta.get(coverOdds.selection) && x.site.id!==siteId)).forEach(o => {
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/o.odds)});
        });
        coverOdds.coverOdds.filter(x => (x.type===coverOdds.selection && sites.get(x.site.id)?.type==='exchange')).forEach(o => {
            const _fees = parseFloat(o.site.id===9 ? (filters.feesBetflag ?? '4.5') : (filters.feesBetfair ?? '5'))/100;
            oddsMatches.push({event: {id: coverOdds.eventId}, odds: [o], rating: coverOdds.odds*(1-1/((o.odds-_fees)/(o.odds-1)))});
        });
        if(['1','X','2'].includes(coverOdds.selection)) {
            let sels: string[] = ['1','X','2']; sels.splice(sels.indexOf(coverOdds.selection), 1);
            const cover1 = coverOdds.coverOdds.filter(x => (x.type===sels[0] && x.site.id!==siteId));
            const cover2 = coverOdds.coverOdds.filter(x => (x.type===sels[1] && x.site.id!==siteId));
            cover1.forEach(c1 => {
                cover2.forEach(c2 => {
                    if(siteId!==c1.site.id && siteId!==c2.site.id) oddsMatches.push({event: {id: coverOdds.eventId}, odds: [c1, c2], rating: coverOdds.odds*(1-1/c1.odds-1/c2.odds)});
                });
            });
        }
        oddsMatches.sort((x,y) => y.rating-x.rating);
        return oddsMatches;
    }, [coverOdds]);
    
    const changeCover = (eventId: number, oddsMatch: OddsMatch) => {
        setOddsMatches(prevState => prevState.map((x: OddsMatch) => x.event.id === eventId ? calculate({...x, odds: [x.odds[0], ...oddsMatch.odds], rating: oddsMatch.rating}) : x));
    }

    const pages = page<3 ? [0,1,2,3,4] : [page-2,page-1,page,page+1,page+2];
    if(oddsMatches.length<(filters.size ?? pageSize)) pages.splice(pages.indexOf(page)+1);
    return(
        <Col gap='10px'>
            {!!buttonText && <SearchButton onClick={() => search(0)}>{buttonText}</SearchButton>}
            {isLoading && <LoadingMask paddingTop='20px' paddingBottom='10px' isCenter size='page'/>}
            {!isLoading && 
                <Col gap='5px'>
                    {oddsMatches.map((om, index) => {
                        let eventText = '';
                        eventText = `${om.event.home?.name} - ${om.event.away?.name}`;
                        const league = om.event.league;
                        if(league) {
                            const country = league.country;
                            const leagueName = league.name;
                            if(country && leagueName) eventText += ` (${country}, ${leagueName})`;
                        }
                        let mode: string = 'pb'
                        if(sites.get(om.odds[1].site.id)?.type !== 'exchange') mode = om.odds.length===3 ? 'tri' : 'pp';
                        const _sites: (Site|undefined)[] = om.odds.map(x => sites.get(x.site.id));
                        return (
                            <Flex key={index} direction={isAppMobile ? 'column' : 'row'} alignItems={isAppMobile ? 'flex-end' : 'center'}>
                                <Row gap='5px'>
                                    <SVGIconContainer><CheckboxOffSVG title={'Seleziona questo evento'} onClick={() => onChoose && onChoose(om)}/></SVGIconContainer>
                                    <SingleElement isDisabled={false}>
                                        <SingleElementEventRow>
                                            <HorizontalScrollContainer hiddenScrollBar={true}><SingleElementEvent>{formatDate(om.event.datetime ?? '')} &nbsp; {eventText}</SingleElementEvent></HorizontalScrollContainer>
                                        </SingleElementEventRow>
                                        <SingleElementOddsMatchRow>
                                            <SingleElementOdds>
                                                <SiteBox 
                                                    checked={!!_sites[0]}
                                                    notClickable={!(_sites[0] && om.odds[0].url && copyToClipboard)}
                                                    name={_sites[0]?.name ?? ''} 
                                                    width={60}
                                                    height={36}
                                                    backgroundColor={!_sites[0] ? '#000000a3' : _sites[0].color}
                                                    onClick={() => _sites[0] && om.odds[0].url && copyToClipboard && copyToClipboard(om.odds[0].url, `URL ${_sites[0].name} copiato`)}
                                                >
                                                    {(_sites[0] && _sites[0].id===0) && _sites[0].name}
                                                    {(_sites[0] && _sites[0].id>0) && <SiteLogoContainer visible={true} backgroundColor={_sites[0].color}><SiteLogo width={'95%'} imageUrl={_sites[0].imageUrl}/></SiteLogoContainer>}
                                                </SiteBox>
                                                <NumberInput isDisabled={true} isDisabledOpacity={1}
                                                    value={om.odds[0].odds.toFixed(2)} 
                                                    inputPlaceholder='Quota'
                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                    // onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 0, newState) : handleOddsChangeEdit(0, newState)}
                                                />
                                                <SingleElementCell>
                                                    <SingleElementOddsSelection title='Esito'>{om.odds[0].type}</SingleElementOddsSelection>
                                                </SingleElementCell>
                                            </SingleElementOdds>
                                            <SingleElementOddsCoversContainer>
                                                <SingleElementOddsCovers>
                                                    <SingleElementOdds>
                                                        <SingleElementCell>
                                                            <SiteBox 
                                                                checked={!!_sites[1]} 
                                                                notClickable={!(_sites[1] && om.odds[1].url && copyToClipboard)}
                                                                name={_sites[1]?.name ?? ''} 
                                                                width={60}
                                                                backgroundColor={!_sites[1] ? '#000000a3' : _sites[1].color}
                                                                onClick={() => _sites[1] && om.odds[1].url && copyToClipboard && copyToClipboard(om.odds[1].url, `URL ${_sites[1].name} copiato`)}
                                                            >
                                                                {(_sites[1] && _sites[1].id===0) && _sites[1].name}
                                                                {(_sites[1] && _sites[1].id>0) && <SiteLogoContainer visible={true} backgroundColor={_sites[1].color}><SiteLogo width={'95%'} imageUrl={_sites[1].imageUrl}/></SiteLogoContainer>}
                                                            </SiteBox>
                                                        </SingleElementCell> 
                                                        <NumberInput isDisabled={true} isDisabledOpacity={1}
                                                            value={om.odds[1].odds.toFixed(2)} 
                                                            inputPlaceholder='Quota'
                                                            minValue={1} includeLimits={false} decimals={2} stepFunction={mode==='pb' ? stepOdds : stepOddsPP} inputWidth={60} 
                                                            // onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 1, newState) : handleOddsChangeEdit(1, newState)}
                                                        />
                                                        <SingleElementCell>
                                                            <SingleElementOddsSelection title='Esito'>{om.odds[1].type}</SingleElementOddsSelection>
                                                        </SingleElementCell>   
                                                    </SingleElementOdds>
                                                    {mode==='tri' &&
                                                        <SingleElementOdds>
                                                            <SingleElementCell>
                                                                <SiteBox 
                                                                    checked={!!_sites[2]} 
                                                                    notClickable={!(_sites[2] && om.odds[2].url && copyToClipboard)}
                                                                    name={_sites[2]?.name ?? ''} 
                                                                    width={60}
                                                                    backgroundColor={!_sites[2] ? '#000000a3' : _sites[2].color}
                                                                    onClick={() => _sites[2] && om.odds[2].url && copyToClipboard && copyToClipboard(om.odds[2].url, `URL ${_sites[2].name} copiato`)}
                                                                >
                                                                    {(_sites[2] && _sites[2].id===0) && _sites[2].name}
                                                                    {(_sites[2] && _sites[2].id>0) && <SiteLogoContainer visible={true} backgroundColor={_sites[2].color}><SiteLogo width={'95%'} imageUrl={_sites[2].imageUrl}/></SiteLogoContainer>}
                                                                </SiteBox>
                                                            </SingleElementCell>
                                                            <NumberInput isDisabled={true} isDisabledOpacity={1}
                                                                value={om.odds[2].odds.toFixed(2)} 
                                                                inputPlaceholder='Quota'
                                                                minValue={1} includeLimits={false} decimals={2} stepFunction={stepOddsPP} inputWidth={60} 
                                                                // onChange={(newState: NumberInputState) => !editing ? handleOddsChange(index, 2, newState) : handleOddsChangeEdit(2, newState)}
                                                            />
                                                            <SingleElementCell>
                                                                <SingleElementOddsSelection title='Esito'>{om.odds[2].type}</SingleElementOddsSelection>
                                                            </SingleElementCell>
                                                        </SingleElementOdds>
                                                    }
                                                </SingleElementOddsCovers>
                                                <SVGIconContainer><SearchIconSVG title={'Cerca copertura alternativa'} onClick={() => searchCovers(index)}/></SVGIconContainer>
                                            </SingleElementOddsCoversContainer>
                                        </SingleElementOddsMatchRow>
                                    </SingleElement> 
                                </Row>
                                <WinnigsAndRatingContainer>
                                    <WinnigsAndRating flexDirection={isAppMobile ? 'row' : 'column'} boxed={false} padding='0 5px'>
                                        <Winnings translateY={isAppMobile ? 0 : 5} amount={om.avgWinnings!==undefined ? om.avgWinnings : 0}>€{om.avgWinnings!==undefined ? om.avgWinnings.toFixed(2) : '-'}</Winnings>
                                        <Rating translateY={isAppMobile ? 0 : 2}>{om.avgRating!==undefined ? om.avgRating.toFixed(2) : '-'}%</Rating>
                                    </WinnigsAndRating>
                                </WinnigsAndRatingContainer>
                            </Flex>
                        );
                    })}
                </Col>
            }
            {(oddsMatches.length>0 || filters.page) && (!isLoading || filters.page) &&
                <TableFooter>
                    <FooterNavigationButton onClick={() => search(page - 1)} disabled={page === 0}><ChevronLeftIcon /></FooterNavigationButton>
                    <FooterPagesButtonsContainer>
                        {pages.map(_page => <FooterPageButton key={_page} onClick={() => page!==_page && search(_page)} disabled={page===_page}>{_page+1}</FooterPageButton>)}
                    </FooterPagesButtonsContainer>
                    <FooterNavigationButton onClick={() => search(page + 1)} disabled={oddsMatches.length < (filters.size ?? pageSize)}><ChevronRightIcon /></FooterNavigationButton>
                </TableFooter>
            }
            {(coverOdds && orderedCoverOddsMatches) && <CoverOddsModal eventId={coverOdds.eventId} selection={coverOdds.selection} oddsMatches={orderedCoverOddsMatches} onChangeCoverClick={changeCover} onCloseButtonModal={() => setCoverOdds(undefined)} copyToClipboard={copyToClipboard}/>}
        </Col>
    );
}

export default OddsMatchSelector;