import { callPOST } from "network/network";
import store from "redux/store";
import { setItemToLocalStorage } from "utils/localStorage";

export const getOfferPurchase = async (offerId: number | undefined, paymentTypeId: number) => {

    if (offerId === undefined) return console.warn("ERROR: no offer ID");

    try {

        const offerParams = {
            brandCode: store.getState().ui.brand?.code,
            paymentTypeId 
        }
        const response = await callPOST(`v1/publicpurchase/single/offer/${offerId}`, {}, true, offerParams);
        if (!response || response === undefined) throw new Error("Unable creating offer purchase.");
        
        const pendingTransaction = response.transactions.filter((transaction: any) => transaction.status === 'CREATED')[0].id;
        if (paymentTypeId === 1 || paymentTypeId === 2) {
            setItemToLocalStorage('Pending transaction ID', `${pendingTransaction}`);           
            setItemToLocalStorage('Pending purchase ID', `${response.id}`);
            setItemToLocalStorage('Offerta', `true`);
        };

        return response
    } catch (error: any) {
        throw error;
    }
};