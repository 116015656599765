import { callPOST } from "network/network";


import devAuthConfig from 'configs/aws/cognito-config-development';
import testAuthConfig from 'configs/aws/cognito-config-test';
import prodAuthConfig from 'configs/aws/cognito-config-production';
import config from 'configs/config';

import { getCookie } from 'utils/cookie';
import store from "redux/store";

const _FACEBOOK_SIGNUP_PATH = 'v1/signup/facebook';

export const addFBuser = (ref: string | null) => {

    let finalAuthConfiguration: any;
    if (config.environment === "development") finalAuthConfiguration = devAuthConfig;
    if (config.environment === "test") finalAuthConfiguration = testAuthConfig;
    if (config.environment === "production") finalAuthConfiguration = prodAuthConfig;

    const COOKIE_PREFIX = "CognitoIdentityServiceProvider." + finalAuthConfiguration.userPoolWebClientId + ".";
    const initialLastAuthUser = getCookie(COOKIE_PREFIX + "LastAuthUser");
    let fbAccessToken = getCookie(COOKIE_PREFIX + initialLastAuthUser + ".accessToken");

    try {
        const brandCode = store.getState().ui.brand?.code;
        if (!brandCode) return;
        const payload = {
            accessToken: fbAccessToken,
            referral: ref ? ref : 16710,
            brandCode: brandCode,
            campaignId: 0,
        }
        const response = callPOST(_FACEBOOK_SIGNUP_PATH, payload);
        localStorage.removeItem('fromFBsignup');
        return response;
    }
    catch (error) {
        console.log("Error adding FB user...");
        throw error;
    }
}