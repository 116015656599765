import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';



export const TotalPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
    padding: 5px 20px 10px 20px;
    
    @media ${devices.tablet} {
        padding: 30px 40px 0px 40px;
    }
`;

export const TitlePage = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;

    width: 100%;

    padding: 15px 15px 15px 10px;
    
    @media ${devices.tablet} {

    }
`;

export const TitleText = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    
    width: 100%;

    padding: 7px 0px 10px 0px;
    gap: 15px;


    >h1{
        color: ${colors.white};
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        
        margin: 0;
        padding: 0;
    }
    
    >svg{
        margin-top: 10px;

        width: 17px;
        height: 17px;

        flex-shrink: 0;
    }

    @media ${devices.tablet} {

    }
`;

export const MainPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    width: 100%;
    
    @media ${devices.tablet} {
        flex-direction: row;
        gap: 30px;

    }
`;

export const DirectSelector = styled.div`
    display: flex;
    align-items: flex-end;
    align-self: stretch;

    padding: 0px 15px;
`;

export const DonationSelector = styled.div<{isActive?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px 0px 0px 0px;
    background: ${colors.darkGrey};

    height: 38px;
    padding: 8px 13px;
    
    gap: 10px;

    >p{
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 68px;
        height: 10px;
        flex-shrink: 0;

        color: rgba(255, 255, 255, 0.70);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }

    ${({isActive}) => isActive && 
        css`
            background: ${colors.veryDarkGrey};
        `
    }
`;

export const ChatSelector = styled.div<{isActive?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0px 10px 0px 0px;
    background: ${colors.darkGrey};

    height: 38px;
    padding: 8px 13px;

    gap: 8px;

    >svg{
        flex-shrink: 0;
        width: 16px;
        height: 15px;
    }

    >p{
        color: ${colors.white};
        font-size: 13px;
        font-style: normal;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }

    ${({isActive}) => isActive && 
        css`
            background: ${colors.veryDarkGrey};
        `
    }
`;

export const VideoDonation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    
    @media ${devices.tablet} {
        gap: 30px;

    }
`;

export const VideoBox = styled.div <{isStarted?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    width: 100%;
    height: 219px;
    overflow: hidden;

    border: none;

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

            
    @media ${devices.tablet} {
        border-radius: 15px;

        height: 523px;

        ${({isStarted}) => isStarted && 
        css`
            max-width: 930px;
        `
    }
    }
`;

export const Donation = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex-direction: column;

    width: 100%;
    height: 530px;
    gap: 13px;

    padding: 16px 0px 16px 0px;
    background: ${colors.darkGrey};

    border-radius: 15px;

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;
            
    @media ${devices.tablet} {
        justify-content: center;
        flex-direction: row;
        
        background: ${colors.darkGrey};
        height: 100%;
        gap: 50px;
    }
`;

export const DonationInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: 20px;

    @media ${devices.tablet} {
        gap: 0px;
    }
`;

export const DonationInfoText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 15px;

    >p {
        color: ${colors.primaryColor};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    >h3 {
        color: ${colors.white};
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    >svg {
        margin-top: -4px;
        margin-left: -8px;
        height: 30px;
        width: 30px;

        flex-shrink: 0;
    }
            
    @media ${devices.tablet} {

    }
`;

export const DonationSelection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 30px;
            
    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const DonationSelectionButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    gap: 10px;
            
    @media ${devices.tablet} {

    }
`;

export const ButtonTipSelection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 70px;
    height: 34px;
    padding: 10px 0px;
    gap: 6px;

    border-radius: 24px;
    background: ${colors.white};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    cursor: pointer;

    >p{
        color: ${colors.black};
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }
    @media ${devices.tablet} {

    }
`;

export const DonationRegulation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    
    @media ${devices.tablet} {
        
        gap: 12px;
    }
`;

export const DonationButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 11px;
    padding-top: 3px;
            
    @media ${devices.tablet} {

    }
`;

export const ButtonBox = styled.p`

    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;

    margin: 0;
    padding: 0;
            
    @media ${devices.tablet} {

    }
`;

export const LibertyValue = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 2px;

    >p {
        color: ${colors.black};
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    >svg {
        flex-shrink: 0;

        width: 16.642px;
        height: 15px;

        fill: ${colors.black};
    }
            
    @media ${devices.tablet} {

    }
`;

export const DonationButtonText = styled.p`
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;

    margin: 0;
    padding: 0;

    @media ${devices.tablet} {
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const MobileChatOpen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    padding: 15px;
    gap: 13px;

    border-radius: 10px;
    background: ${colors.veryDarkGrey};
`;

export const FirstLine = styled.div`
    display: flex;
    align-items: center;

    gap: 8px;

    width: 100%;

    
    >svg{
        flex-shrink: 0;
        width: 16px;
        height: 15px;
    }

    >h3{
        color: ${colors.white};
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
`;

export const InputChat = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    gap: 16px;
    padding: 7px 15px 8px 15px;

    border-radius: 10px;
    background: #323333;

    cursor: text;

    >p{
        color: rgba(255, 255, 255, 0.70);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }
`;



export const EmbededChat = styled.iframe`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    border-radius: 15px;
    
    background: ${colors.veryDarkGrey};

    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    border: none;

    width: 100%;
    height: 530px;

    .yt-spec-base-background{
        background: #4b5862 !important;
    } 

    .yt-live-chat-renderer{
        background: #4b5862 !important;
    } 

    @media ${devices.tablet} {
        max-width: 400px;
        height: 682px;
    }
`;

export const DescriptionText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    padding: 0px 30px 0px 30px;
    gap: 10px;

    >p{
        align-self: stretch;
        color: ${colors.white};
        font-size: 15px;
        font-style: normal;
        font-weight: 500;

        margin: 0;
        padding: 0;
    }

    >a{
        color: ${colors.primaryColor};
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        text-decoration-line: none;

        margin: 0;
        padding: 0;
    }

    > p {
        align-self: stretch;
        color: #FFFFFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;

        margin: 0;
        padding: 0;

    }

    @media ${devices.tablet} {
        align-items: flex-start;
        padding: 30px 30px 0px 30px;

        border-radius: 15px;
        background: #4C5862;

        box-shadow: 
            0px 1px 5px 0px rgba(0, 0, 0, 0.12), 
            0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
            0px 3px 1px -2px rgba(0, 0, 0, 0.20);

        max-width: 400px;
    }
`;
