import { FC, useState } from "react";

import { getEnvironmentProductId } from "configs/config.helper";
import FreeFromFatiguePublicPage from "pages/SpecificPublicPages/FreeFromFatiguePublicPage/FreeFromFatiguePublicPage.component";
import Highway from "pages/SpecificPublicPages/Highway/Highway.component";
import MakeMoneyWithoutMoney from "pages/SpecificPublicPages/MakeMoneyWithoutMoney/MakeMoneyWithoutMoney.component";
import SuperViewPublicPage from "pages/SpecificPublicPages/SuperViewPublicPage/SuperViewPublicPage.component";
import SureBonusPublicPage from "pages/SpecificPublicPages/SureBonusPublicPage/SureBonusPublicPage.component";
import PublicProductPageDesktop from "../layout/PublicProductPageDescktop/PublicProductPageDesktop";
import useAppSelector from "hooks/useAppSelector";
import PublicProductPage from "../layout/PublicProductPage/PublicProductPage";
// import LibertyPointPage from "pages/SpecificPublicPages/LibertyPointPages/LibertyPointPage.component";

const PublicProductPageSelector: FC<{
    productData: any, 
    onTransferClick: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void
    isUserLoggedIn: boolean,
    onLibertyClick: () => void,
    openBankTransferModal: () => void,
    libertyInfoModalHandler?:() => void,
    paymentTransferID: number | undefined,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?:() => void,
    hasRequire?: boolean,
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    publicPageTransferPrice?: (grossPrice: number) => void,
    productId: string | undefined,
    campaignId?: string | null
}> = ({
    productData, 
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isUserLoggedIn,
    onLibertyClick,
    openBankTransferModal,
    libertyInfoModalHandler,
    paymentTransferID,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    productId,
    campaignId
}) => {

    const { name, salesData, subscriptionMaxDate, subscriptions, maxSubscription } = productData;
    const [product, setProduct] = useState<any>(productData);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductPurchasable, setIsProductPurchasable] = useState<boolean>(true);
   
    const { isAppMobile } = useAppSelector(state => state.ui);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const setProductNoMorePurchasable = () => setIsProductPurchasable(false);
    const freeSlots = maxSubscription - subscriptions;
    const isOfferValid = (subscriptionMaxDate !== undefined) ? (new Date(`${subscriptionMaxDate}Z`).getTime() > new Date().getTime()) : true;

    const productPrice = () => {
        if (product.type === 'SERVICE') return product.productService.paymentPlan.price;
        if (product.type === 'LESSON_PACK') return product.productLessonPack.price;
        return product.productCourse.price;
    };

    if ((productId === `${getEnvironmentProductId('bonus sicuro')}`) || 
        (productData && ((`${productData.id}` === `${getEnvironmentProductId('bonus sicuro')}`)))
    ) return (
        <SureBonusPublicPage 
            isOpenInModal={isOpenInModal}
            product={productData}
            productInfoId={`${getEnvironmentProductId('bonus sicuro')}`}
            libertyInfoModalHandler={libertyInfoModalHandler}
        />
    );

    if ((productId === `${getEnvironmentProductId('liberi dalla stanchezza')}`) || 
        (productData && ((`${productData.id}` === `${getEnvironmentProductId('liberi dalla stanchezza')}`)))
    ) return (
        <FreeFromFatiguePublicPage 
            isOpenInModal={isOpenInModal}
            product={productData}
            campaignId={campaignId}
        />
    );

    if ((productId === `${getEnvironmentProductId('super vista')}`) || 
        (productData && ((`${productData.id}` === `${getEnvironmentProductId('super vista')}`)))
    ) return (

        <SuperViewPublicPage 
            isOpenInModal={isOpenInModal}
            product={productData}
        />
    );

    if ((productId === `${getEnvironmentProductId('fare soldi senza soldi')}`) || 
        (productData && ((`${productData.id}` === `${getEnvironmentProductId('fare soldi senza soldi')}`)))
    ) return (

        <MakeMoneyWithoutMoney 
            isOpenInModal={isOpenInModal}
            product={productData}
            hasRequire={hasRequire}
        />
    );

    if ((productId === `${getEnvironmentProductId('autostrada')}`) || 
    (productData && ((`${productData.id}` === `${getEnvironmentProductId('autostrada')}`)))
    ) return (

        <Highway 
            isOpenInModal={isOpenInModal}
            product={productData}
            productPrice={productPrice()}
            onTransferClick={onTransferClick}
            buyInfoModalHandler={buyInfoModalHandler}
            openBankTransferModal={openBankTransferModal}
            libertyInfoModalHandler={libertyInfoModalHandler}
            openLibertyPaymentModal={openLibertyPaymentModal}
            libertyProductID={libertyProductID}
            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
            publicPageTransferPrice={publicPageTransferPrice}
            onLibertyClick={onLibertyClick}
            libertyProductPrice={libertyProductPrice}
            hasRequire={hasRequire}
        />
    );

    if (!isAppMobile) {
        return (
            <PublicProductPageDesktop 
                productData={productData} 
                onTransferClick={onTransferClick}
                isOpenInModal={isOpenInModal}
                buyInfoModalHandler={buyInfoModalHandler}
                isUserLoggedIn={isUserLoggedIn!}
                onLibertyClick={onLibertyClick}
                libertyInfoModalHandler={libertyInfoModalHandler}
                openBankTransferModal={openBankTransferModal}
                paymentTransferID={paymentTransferID}
                modalGetPaymentID={modalGetPaymentID}
                libertyProductID={libertyProductID}
                libertyProductPrice={libertyProductPrice}
                loadingOn={loadingOn}
                loadingOff={loadingOff}
                openProductRequisitesModal={openProductRequisitesModal}
                hasRequire={hasRequire}
                publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                publicPageTransferPrice={publicPageTransferPrice}
                campaignId={campaignId}
            />
        );
    }

    return (
        <PublicProductPage 
            productData={productData} 
            onTransferClick={onTransferClick}
            isOpenInModal={isOpenInModal}
            buyInfoModalHandler={buyInfoModalHandler}
            isUserLoggedIn={isUserLoggedIn!}
            onLibertyClick={onLibertyClick}
            libertyInfoModalHandler={libertyInfoModalHandler}
            openBankTransferModal={openBankTransferModal}
            paymentTransferID={paymentTransferID}
            modalGetPaymentID={modalGetPaymentID}
            libertyProductID={libertyProductID}
            libertyProductPrice={libertyProductPrice}
            loadingOn={loadingOn}
            loadingOff={loadingOff}
            hasRequire={hasRequire}
            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
            publicPageTransferPrice={publicPageTransferPrice}
            campaignId={campaignId}
        />
    );
};

export default PublicProductPageSelector;

function setIsBankTransferModalVisible(arg0: boolean) {
    throw new Error("Function not implemented.");
}
