import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const SingleResultTableBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 642px;
    height: 30px;

    gap: 30px;
    padding: auto 20px;

    background: ${colors.darkGrey};
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.12);

    white-space: nowrap;

    :hover {
        color: #51616E;
    }
`;

export const MounthBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 10px;
`;

export const TotalMounthBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 87px;
    height: 10px;
`;

export const MiddleEarningBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 122px;
    height: 24px;
`;

export const MounthrRecordBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 121px;
    height: 10px;
`;

export const TableText = styled.p`
    
    margin: 0;
    padding: 0;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    
    color: ${colors.white};
    
    text-align: center;
    text-transform: capitalize;
`;