import { FC, useEffect, useState } from "react";

// routing
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

// redux
import { saveLibertyPointsAvailableBalance } from "redux/actions/actions-user";

// hook
import useAppSelector from "hooks/useAppSelector";
import { useIsMounted } from "usehooks-ts";
import useHasRequisites from "hooks/useHasRequisites";
import useAppDispatch from "hooks/useAppDispatch";

// utils
import { getOldIdProductData, getProductData } from "./services/SureBonusPublicPage.services";
import { getCurrentAuthUser } from "services/user.services";
import { getEnvironmentProductId } from "configs/config.helper";
import { removeItemFromLocalStorage } from "utils/localStorage";

// types
import { Product } from "./types/Product";

// components
import LoadingMask from "components/ui/LoadingMask";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import BankTransferModal from "components/BankTransferModal/BankTransferModal.component";
import ProductRequisitesModal from "components/ProductRequisitesModal/ProductRequisitesModal.component";
import FreeFromFatiguePublicPageMobile from "./layout/FreeFromFatiguePublicPageMobile/FreeFromFatiguePublicPageMobile.component";
import FreeFromFatiguePublicPageDesktop from "./layout/FreeFromFatiguePublicPageDesktop/FreeFromFatiguePublicPageDesktop.component";
import FullScreenImageModal from "components/FullScreenImageModal/FullScreenImageModal.component";

/**
 * Public Product Page
 * 
 * @author Oliver Megale
 * 
 * @returns JSX
 */


const FreeFromFatiguePublicPage: FC<{
    product?: Product, 
    productInfoId?: string, 
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void,
    libertyInfoModalHandler?:() => void,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    campaignId?: string | null
}> = ({
    product, 
    productInfoId, 
    isOpenInModal,
    buyInfoModalHandler,
    libertyInfoModalHandler,
    modalGetPaymentID,
    libertyProductID,
    publicInfoPageTransferPrice,
    campaignId
}) => {
    
    const [productData, setProductData] = useState<any>(undefined);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { tokenID } = useAppSelector(state => state.user)
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [isProductRequisitesModalVisible, setIsProductRequisitesModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [chosenProductID, setChosenProductID] = useState<number>();
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasRequire, setHasRequire] = useState<boolean>(product?.requisitesSatisfied ?? false);
    const [requisitesLogicalOperator, setRequisitesLogicalOperator] = useState<'AND' | 'OR'>('AND');
    const [imgToOpenUrl, setImgToOpenUrl] = useState<string>('');
    const [isImgModalVisible, setIsImgModalVisible] = useState<boolean>(false);
    const location = useLocation();
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const name = (productData !== undefined) ? productData.name : '';
    const requisites = (productData !== undefined) ? productData.requisites : [];
    const productPrice = (productData !== undefined) ? (productData.type === 'COURSE' ? productData.productCourse?.price : productData.productService?.paymentPlan.price) : '';

    const {productId, typeOfProduct} = useParams();
    const [searchParams] = useSearchParams();
    const { hasUserAllRequisites, userMissingRequisites } = useHasRequisites(requisites);

    const productType = searchParams.get('productType');
    // const campaignId = searchParams.get('campaignId');
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const closeProductRequisitesModal = () => setIsProductRequisitesModalVisible(false);
    const openProductRequisitesModal = () => setIsProductRequisitesModalVisible(true);
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };
    
    const publicPageTransferPrice = (grossPrice: number) => {
        setChosenProductPrice(`${grossPrice}`)  
    }; 

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {        
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const openImgInModal = () => setIsImgModalVisible(true);        
    const closeImgInModal = () => setIsImgModalVisible(false);
    const setImgToOpen = (imageUrl: string) => {
        setImgToOpenUrl(imageUrl)
        openImgInModal()
    };
        
    const getProduct = async (productToSearchId: string) => {

        try {
            loadingOn();
            const response = await getProductData(productToSearchId, isUserLoggedIn);
            if ( !isMounted ) return;
            if ( !response || response === undefined ) return;

            // console.log("PRODUCT DATA:", response);
            if (response.id === getEnvironmentProductId('autostrada')) {
                
                setHasRequire(response.requisitesSatisfied);
                setIsProductRequisitesModalVisible(!response.requisitesSatisfied);
                setRequisitesLogicalOperator(response.requisitesLogicalOperator);
                setProductData(response);
                loadingOff();
                return;
            }
            if (response.type !== 'COURSE' && response.type !== 'SERVICE') return navigate("/fallback-page/page-not-found");
            setHasRequire(response.requisitesSatisfied);
            setIsProductRequisitesModalVisible(!response.requisitesSatisfied);
            setRequisitesLogicalOperator(response.requisitesLogicalOperator);
            setProductData(response)
            loadingOff();
        } catch (error: any) {
            loadingOff();
            console.warn("Unable gettiing product data.");
        }
    };

    const getProductWithOldId = async (productToSearchId: string, productType: string) => {

        try {
            if (productType === null) return;
            const response = await getOldIdProductData(productToSearchId, productType, isUserLoggedIn);
            // const response = await getOldIdProductData(productToSearchId, 'COURSE', isUserLoggedIn);
            if ( !isMounted ) return;
            if ( !response || response === undefined ) return;

            setHasRequire(response.requisitesSatisfied);
            setIsProductRequisitesModalVisible(!response.requisitesSatisfied);
            setRequisitesLogicalOperator(response.requisitesLogicalOperator);
            setProductData(response)
            loadingOff();
        } catch (error: any) {
            setIsLoading(false);
            console.warn("Unable getting product data.");
        }
    };

    const getUserData = async () => {
        try {
            const response = await getCurrentAuthUser();
            
            const pools = response.pools;
            
            const libertyPointsPool = pools.filter((pool: any) => pool.type === "PERSONAL");
            if (libertyPointsPool.length === 0) return dispatch( saveLibertyPointsAvailableBalance(0) );
            dispatch( saveLibertyPointsAvailableBalance(libertyPointsPool[0].availableBalance) );
            // dispatch( saveLibertyPointsAvailableBalance(500) );
            // console.log("PUNTI??", libertyPointsPool[0].availableBalance);
            
        } catch (error) {
            console.warn(error);
        }
    };
    
    const isUserLoggedIn = (tokenID !== undefined) ? !!tokenID : undefined;

    useEffect(() => {

        console.log("USER LOGGED IN?", isUserLoggedIn);
        console.log("TOKEN?", tokenID);
        
        if (isUserLoggedIn === undefined) return; 
        if (product) return setProductData(product);
        if (productInfoId) {
            getProduct(productInfoId)
            return
        };
        if (!productId) return;
        if (typeOfProduct === 'oldid') {

            if (productType === null) return;
            getProductWithOldId(productId, productType);
            return
        };

        getProduct(productId);
    }, [product, productId, productInfoId, isUserLoggedIn, tokenID]);

    useEffect(() => {

        getUserData();
        removeItemFromLocalStorage('To redirect at');
    }, []);

    // useEffect(() => {
    //     if (productData === undefined) return;
    //     if (isProductRequisitesModalVisible === !hasUserAllRequisites) return;
    //     setIsProductRequisitesModalVisible(!hasRequire);
    //     // setHasRequire(!hasUserAllRequisites);
    // }, [productData, hasUserAllRequisites]);


    // console.log("HO I REQUISITI?", hasRequire);
    

    if (isLoading && (isUserLoggedIn === undefined)) return (
        <LoadingMask 
            isCenter
            size='page'
        />
    );

    if (!isAppMobile) return (
        <>            
            {(productData !== undefined) && 
                <FreeFromFatiguePublicPageDesktop 
                    productData={productData} 
                    onTransferClick={transferModalHandler}
                    isOpenInModal={isOpenInModal}
                    buyInfoModalHandler={buyInfoModalHandler}
                    isUserLoggedIn={isUserLoggedIn!}
                    onLibertyClick={libertyModalHandler}
                    libertyInfoModalHandler={libertyInfoModalHandler}
                    openBankTransferModal={openBankTransferModal}
                    paymentTransferID={paymentTransferID}
                    modalGetPaymentID={modalGetPaymentID}
                    libertyProductID={libertyProductID}
                    libertyProductPrice={libertyProductPrice}
                    loadingOn={loadingOn}
                    loadingOff={loadingOff}
                    openProductRequisitesModal={openProductRequisitesModal}
                    hasRequire={hasRequire}
                    publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                    publicPageTransferPrice={publicPageTransferPrice}
                    openImageHandler={setImgToOpen}
                    campaignId={campaignId}
                />
            }

            {!isOpenInModal && isBankTransferModalVisible && (
                    <BankTransferModal
                        onCloseButtonModal={closeBankTransferModal}
                        productName={name}
                        productPrice={chosenProductPrice}
                        paymentTransferID={paymentTransferID}
                    />
                )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+productId!}
                    price={productLibertyPrice}
                />}

            {isProductRequisitesModalVisible && isUserLoggedIn &&
                <ProductRequisitesModal
                    onCloseButtonModal={closeProductRequisitesModal}
                    requisites={userMissingRequisites}
                    requisitesLogicalOperator={requisitesLogicalOperator}
                />}

            {isImgModalVisible && (
                <FullScreenImageModal 
                    imgUrl={imgToOpenUrl}
                    onCloseButtonModal={closeImgInModal}
                />
            )}
        </>);
        
    return (
        <>
            {(productData !== undefined) && 
                <FreeFromFatiguePublicPageMobile 
                    productData={productData} 
                    onTransferClick={transferModalHandler}
                    isOpenInModal={isOpenInModal}
                    buyInfoModalHandler={buyInfoModalHandler}
                    isUserLoggedIn={isUserLoggedIn!}
                    onLibertyClick={libertyModalHandler}
                    libertyInfoModalHandler={libertyInfoModalHandler}
                    openBankTransferModal={openBankTransferModal}
                    paymentTransferID={paymentTransferID}
                    modalGetPaymentID={modalGetPaymentID}
                    libertyProductID={libertyProductID}
                    libertyProductPrice={libertyProductPrice}
                    loadingOn={loadingOn}
                    loadingOff={loadingOff}
                    hasRequire={hasRequire}
                    publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                    publicPageTransferPrice={publicPageTransferPrice}
                    openImageHandler={setImgToOpen}
                    campaignId={campaignId}
                />
            }

            {!isOpenInModal && isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={name}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                />
            )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+productId!}
                    price={productLibertyPrice}
            />}

            {isProductRequisitesModalVisible && isUserLoggedIn &&
                <ProductRequisitesModal
                onCloseButtonModal={closeProductRequisitesModal}
                requisites={userMissingRequisites}
                requisitesLogicalOperator={requisitesLogicalOperator}
            />}

                
            {isImgModalVisible && (
                <FullScreenImageModal 
                    imgUrl={imgToOpenUrl}
                    onCloseButtonModal={closeImgInModal}
                />
            )}
        </>
    );
};

export default FreeFromFatiguePublicPage;