import { FC, useState } from 'react';

//components
import FilterShopButtonDesktop from '../../FilterShopButton/layout/FilterShopButtonDesktop/FilterShopButtonDesktop.component';
import { MENU_FILTER } from '../..';

//assets

//style
import {
    FilterShopBox,
    ReturnFilterBox
} from './style/FilterShopDesktop.style';


const FilterShopDesktop: FC<{
    changeFilterOpenActive: (text: string) => void
}> = ({
    changeFilterOpenActive
}) => {

    const [selectedMenuPosition, setSelectedMenuPosition] = useState<number>(-1);

    // const filterHandler = (position: number) => {
        
    //     changeFilterOpenActive(MENU_FILTER[position].text);
    //     setSelectedMenuPosition(position);
    // };

    const filterHandler = (position: number) => {
        if (position === selectedMenuPosition) {
            changeFilterOpenActive(''); 
            setSelectedMenuPosition(-1); 
        } else {
            changeFilterOpenActive(MENU_FILTER[position].text);
            setSelectedMenuPosition(position);
        }
    };

    return (

        <FilterShopBox>
            {MENU_FILTER.map((element: any, index: number) => {
                
                return (
                    <FilterShopButtonDesktop 
                        key={index}
                        position={index}
                        icon={element.icon}
                        text={element.text}
                        onButtonClick={filterHandler}
                        isActive={MENU_FILTER[selectedMenuPosition]?.text}
                    />
                )
            })}
        </FilterShopBox>
    );
};

export default FilterShopDesktop;