import styled, { css } from 'styled-components';
import colors from 'style/palette';
import devices from 'style/breakpoints';
import { Button } from 'style/buttons';

// assets
import euroImg from '../assets/euroIcon.png';
import { color } from 'd3';


export const Page = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    margin: 0;
    padding: 35px 20px;

    @media ${devices.tablet} {
        padding: 45px 40px;
    }
`;

export const CongratulationsRow = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    width: 100%;
`;

export const PageTitle = styled.h1`

    margin: 0;
    padding: 0 0 0 10px;

    font-size: 22px;
    font-weight: 400;
    color: ${colors.white};
`;

export const ShareBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    width: 100%;

    margin: 20px 0 0;
    padding: 30px 35px;

    border-radius: 15px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    background-color: ${colors.darkGrey};
    
    @media ${devices.tablet} {

        justify-content: space-between;

        flex-direction: row;
        
        padding: 35px 60px;
    }
`;

export const CongratulationText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    color: ${colors.white};
`;

export const GoldText = styled.span`

    font-weight: 500;
    color: ${colors.primaryColor};
`;

export const ShareButton = styled.button`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: 15px;

    width: 100%;
    max-width: 285px;

    margin: 0;
    padding: 12px 30px;

    border: none;
    border-radius: 4px;
    background-color: ${colors.green};
    
    font-size: 15px;
    font-weight: 500;
    color: ${colors.white};
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;
    
    :disabled {
        cursor: default;
    }
    
    @media ${devices.tablet} {
        max-width: 305px;
    }

`;

export const ResultShareRow = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;

    margin: 40px 0 0;
    padding: 0;
`;

export const RestultsText = styled.h2`

    margin: 0;
    padding: 0 0 0 10px;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.white};
`;

export const RetultsRow = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;

    
    @media ${devices.tablet} {
        flex-direction: row;
        gap: 30px;
    }
`;

export const ResultBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
    padding: 30px 0;

    border-radius: 15px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    background-color: ${colors.darkGrey};

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        
    -webkit-appearance: none; 
     margin: 0; 
    }

    input[type=number] {
    -moz-appearance:textfield;
    }
`;

export const ResultTitle = styled.h3<{ isDisabled?: boolean | undefined}>`

    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.primaryColor};
    text-transform: uppercase;

    ${({isDisabled}) => isDisabled && 
        
        css`
            color: ${colors.white050};
        `
    }
`;

export const ResultImage = styled.div<{ isDisabled?: boolean | undefined}>`

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        max-height: 80px;
    }

    ${({isDisabled}) => isDisabled &&
        
        css`
            > svg path {

                    fill: ${colors.transparent};
                    fill-opacity: 0.5;
                    stroke: ${colors.white};
                    stroke-opacity: 0.5;
            }
        `
    }
`;

export const ResultInput = styled.input`

    width: 100%;
    max-width: 150px;
    height: 42px;

    border-radius: 4px 4px 0 0;
    border: none;
    border-bottom: 1px solid ${colors.white042};

    background: ${colors.white009} url(${euroImg}) no-repeat;
    background-position: 90%;

    font-size: 25px;
    text-align: center;
    color: ${colors.white};
`;

export const ResultButton = styled(Button)``;

export const TotalResult = styled.h2`

    width: 100%;
    
    margin: 45px 0 0;
    padding: 0 0 0 10px;

    font-size: 23px;
    font-weight: 400;
    color: ${colors.green};
    text-align: center;
`;

export const TotalCashResult = styled.span`

    margin: 0 0 0 15px;
    padding: 0;

    font-size: 30px;
    font-weight: 600;
`;

export const GraphRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    margin: 30px 0 0;

    @media ${devices.tablet} {

        margin: 50px 0 0;
    }
`;

export const GraphBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    /* max-width: 900px; */
    /* max-height: 370px; */

    padding: 20px 0 0;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const GraphCard = styled.div<{pieChart?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    max-width: 900px;
    /* max-height: 370px; */

    padding: 30px 25px;
    
    background: ${colors.darkGrey};
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 15px;

    ${({pieChart}) => pieChart && (

        css`
            max-width: 433px;
        `
    )}
`;

export const LegendRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin: 0 0 30px;
`;

export const Year = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;
    color: ${colors.white070};
    text-transform: uppercase;
`;

export const Legend = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 30px;
`;

export const GroupLegend = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
`;

export const ColorBox = styled.div<{flavor?: '#FF5957' | '#FF8A00' | '#5E2222'}>`

    width: 20px;
    height: 20px;
    background: ${colors.grey};

    ${({flavor}) => flavor && (
        
        css`
            background: ${flavor};
        `
    )}
`;

export const GroupName = styled.p`

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 400;
    color: ${colors.white};
    text-transform: uppercase;
`;

export const AddButton = styled(Button)`

    margin: 20px 0 0;
`;
