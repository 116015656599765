import { FC, useEffect, useState } from "react";

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// helpers
import useAppDispatch from "hooks/useAppDispatch";
import { getEnvironmentAssetLinkUrl, getEnvironmentBaseUrl } from "configs/config.helper";
import { useCopyToClipboard } from "usehooks-ts";

// components
import MediaPlayer from "components/MediaPlayer";
import ButtonShop from "components/ButtonShop/ButtonShop.component";
import LibertyPaymentModal from "components/LibertyPaymentModal/LibertyPaymentModal.component";
import Timer from "components/Timer/Timer.component";
import TestimonialsCarousel from "pages/LandingPage/TestimonialsCarousel/TestimonialsCarousel.component";
import VideoCourseList from "pages/ProductPage/VideoCourseList/VideoCourseList.component";
import TestimonialsCarouselMobile from "pages/LandingPage/TestimonialsCarousel/TestimonialsCarouselMobile/TestimonialsCarouselMobile.component";

// assets
import { ReactComponent as Vector } from './assets/Vector.svg';
import { ReactComponent as FacebookIconSVG } from '../../assets/facebookGreenIcon.svg';
import { ReactComponent as CheckIconSVG } from '../../assets/checkGreenIcon.svg';
import { ReactComponent as PhoneIconSVG } from '../../assets/whatsappIcon.svg';
import TotalEarningsIMG from '../../assets/totalEarningsIMG.png'
import { ReactComponent as CopySVG } from '../../assets/link-icon.svg';

// style
import { 
    CardPrice, 
    InfoPrice, 
    MediaGrid, 
    VideoImg,
    SecondaCard, 
    TextP, 
    TitleB, 
    TitleBox, 
    TitleCard, 
    TotalPage, 
    TextCheck, 
    FirstTwo,
    CardContent,
    BulletBox,
    TextBulllet,
    ButtonBox,
    BlockText,
    BlockImg,
    BlockRow,
    BlockGroup,
    ProductLimitationRow,
    LimitedSlotBox,
    LimitationDisclaimer,
    LimitedTimeBox,
    LimitedSlots,
    CarouselBox,
    ThreeBlockRow,
    BlockItem,
    IMGItem,
    IMGBox,
    IMGDescription,
    VideoCourseListBox,
    TitleImportant,
    TextStrongP,
    TextStrongL
} from "./style/SuperViewPublicPageMobile.style";


const SuperViewPublicPageMobile: FC<{
    productData: any, 
    onTransferClick: (productName: string, productPrice: string, paymentTransferID: number | undefined) => void,
    isOpenInModal?: boolean | undefined,
    buyInfoModalHandler?:() => void
    isUserLoggedIn: boolean,
    onLibertyClick: () => void,
    openBankTransferModal: () => void,
    libertyInfoModalHandler?:() => void,
    paymentTransferID: number | undefined,
    modalGetPaymentID?:(paymentID: number | undefined) => void,
    libertyProductID?: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    openProductRequisitesModal?:() => void,
    hasRequire?: boolean,
    publicInfoPageTransferPrice?: (grossPrice: number) => void,
    publicPageTransferPrice?: (grossPrice: number) => void,
    openImageHandler?: (imageUrl: string) => void,
}> = ({
    productData, 
    onTransferClick,
    isOpenInModal,
    buyInfoModalHandler,
    isUserLoggedIn,
    onLibertyClick,
    openBankTransferModal,
    libertyInfoModalHandler,
    paymentTransferID,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    openProductRequisitesModal,
    hasRequire,
    publicInfoPageTransferPrice,
    publicPageTransferPrice,
    openImageHandler
}) => {

    const [product, setProduct] = useState<any>(productData);
    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const [isProductPurchasable, setIsProductPurchasable] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();

    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);

    const { name, salesData, subscriptionMaxDate, subscriptions, maxSubscription } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const bulletList = salesData?.bulletList;
    const headLine = salesData?.headLine;
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;
    const salesPage = salesData?.salesPage;
    const blocks = salesPage?.blocks;
    const importantPhrase = salesData?.importantPhrase;

    const freeSlots = maxSubscription - subscriptions;

    const localMedia = {
        src: "https://www.viveredipoker.it/CorsoVedereConLaMente/Vedere_con_La_Mente_Sales.mp4",
        type: "video/mp4"
    };

    const setProductNoMorePurchasable = () => setIsProductPurchasable(false);
    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${productData.id}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    useEffect(() => {
        setProduct(productData);
    }, [productData]);

    const finalName = (name !== null) ? name : "TITOLO PRODOTTO";
    const finalHeadline = (headLine !== null) ? headLine : "HEADLINE";
    const finalBulletList1 = ((bulletList !== null) && (bulletList !== undefined)) ? bulletList[0] : "BULLET LIST";
    const finalBulletList2 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[1]) ? bulletList[1] : "BULLET LIST";
    const finalBulletList3 = (((bulletList !== null) && (bulletList !== undefined)) && bulletList[2]) ? bulletList[2] : "BULLET LIST";
    const finalPurchaseButtonText = (purchaseButtonText !== null) ? purchaseButtonText : "";
    const isBulletCardVisible = (headLine !== null) || (bulletList !== null) || (headLine !== undefined) || (bulletList !== undefined);
    const isBulletListVisible = (bulletList !== null) && (bulletList !== undefined);
    const isDescriptionCardVisible = (blocks !== null) && (blocks !== undefined);
    const isimportantPhraseVisible = (importantPhrase !== null) && (importantPhrase !== undefined);
    const productPrice = () => {
        if (product.type === 'SERVICE') return product.productService.paymentPlan.price;
        if (product.type === 'LESSON_PACK') return product.productLessonPack.price;
        return product.productCourse.price;
    };
    const finalImportantPhrase = importantPhrase != null ? importantPhrase : 'IMPORTANT PHRASE';
    const freeSlotText = ((maxSubscription !== null) && (maxSubscription !== undefined)) ? ((freeSlots > 0) ? freeSlots : "Posti Esauriti") : "Non ci sono limiti";
    // const isLimitedSlotBoxVisible = ((maxSubscription !== null) && (maxSubscription !== undefined) && isProductPurchasable);
    // const isLimitedTimeBoxVisible = ((subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined) && (freeSlotText !== 'Posti Esauriti'));
    const isLimitedSlotBoxVisible = (maxSubscription !== null) && (maxSubscription !== undefined);
    const isLimitedTimeBoxVisible = (subscriptionMaxDate !== null) && (subscriptionMaxDate !== undefined);
    const isSeatsAvailable = (isNaN(freeSlots)) ? true : ((freeSlots > 0) ? true : false);
    const isOfferValid = (subscriptionMaxDate !== undefined) ? (new Date(`${subscriptionMaxDate}Z`).getTime() > new Date().getTime()) : true;
        
    return(
        <>
            <MediaGrid>
                <MediaPlayer
                    media={localMedia}
                    preload='medatada'
                    poster={'https://www.viveredipoker.it/ldlapifile/products/salespages/videocovers/SuperVista_1687020677039.png'}
                />
            </MediaGrid>

            <TotalPage>

                <TitleBox>
                    <TitleB>
                        Super Vista

                        <CopySVG onClick={copyIntoClipboardHandler} />
                    </TitleB>
                </TitleBox>

                <FirstTwo>
                    <CardPrice>
                        <CardContent>
                            <InfoPrice>
                                <TitleCard>
                                    Super Vista
                                </TitleCard>
                            
                                <TextP notCenter>
                                    Lo Sapevi che la vista può arrivare fino a 20 Decimi? Lo so è strano, si chiamano decimi, 
                                    ma allora perchè per entrare nei pompieri sono richiesti minimo 14 decimi?
                                </TextP>

                                <BulletBox>
                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin noPadding>
                                            Prevenire la necessità degli Occhiali
                                        </TextP>
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin noPadding>
                                            Migliorare la vista
                                        </TextP>
                                    </TextBulllet>

                                    <TextBulllet>
                                        <Vector/>

                                        <TextP noMargin noPadding notCenter>
                                            Se porti già gli occhiali, sappi che alcuni sono arrivati a toglierli
                                        </TextP>
                                    </TextBulllet>
                                </BulletBox>

                                <ButtonBox>
                                    <ButtonShop 
                                        PriceProduct={productPrice()} 
                                        TypeOfProduct={product.type}
                                        courseElement={product} 
                                        courseID={product.id} 
                                        payments={product.paymentTypes}
                                        onTransferClick={onTransferClick}
                                        isOpenInModal={isOpenInModal}
                                        buyInfoModalHandler={buyInfoModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={onLibertyClick}
                                        libertyInfoModalHandler={libertyInfoModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        isOnPublicProductPage
                                        modalGetPaymentID={modalGetPaymentID}
                                        productIdHandler = {libertyProductID}
                                        productPriceHandler = {libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        openProductRequisitesModal={openProductRequisitesModal}
                                        hasRequire={hasRequire}
                                        isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                                        publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                        publicPageTransferPrice={publicPageTransferPrice}
                                        vipPoints={vipPoints}
                                        isSuperViewProductPage
                                    />
                                </ButtonBox>
                            </InfoPrice>
                        </CardContent>
                    </CardPrice>
                </FirstTwo>

                <SecondaCard>
                    <IMGItem>
                        <IMGDescription>
                            <TextP>
                                Sapere che si poteva arrivare a 20 decimi, per me, è stata una notizia incredibile...
                                <br /> <br />
                                Grazie a questo corso, non solo riesco a lavorare al computer senza gli occhiali che porto da trent'anni, 
                                ma ho anche una nuova consapevolezza: 
                                <br/> <br/>
                                si può migliorare la vista costantemente, arrivando anche a 20 Decimi.... ecco perché il corso si Chiama la Super Vista.
                                <br/> <br/>
                                Nell'immagine trovi i requisiti per entrare nei pompieri che dimostrano chiaramente la possibilità di superare i 10 decimi...
                                <br/> <br/>
                                I piloti ne hanno 16,
                                <br />
                                i campioni di tiro a segno ne hanno 18 o 20
                            </TextP>
                        </IMGDescription>

                        <IMGBox>
                            <img 
                                src="https://www.viveredipoker.it/CorsoVedereConLaMente/Pompieri.JPG" 
                                alt="REQUISITI PER DIVENTARE VIGILI DEL FUOCO" 
                                onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoVedereConLaMente/Pompieri.JPG")}    
                            />
                        </IMGBox>
                    </IMGItem>

                    <IMGDescription>
                        <TitleImportant>E non è solo una questione di vista, </TitleImportant>

                        <TextStrongP>
                            sistemando i blocchi Fisici e Mentali che ti impediscono
                            <br />
                            di vedere le cose in modo nitido,
                            <br />
                            abbiamo riscontrato casi di miglioramento di:
                            <br />
                        </TextStrongP>

                        <TextStrongL>
                            <em>
                                - Mal di testa cronici
                                <br />
                                - Stanchezza e Spossatezza
                                <br />
                                - Rossore agli Occhi
                                <br />
                                - Difficoltà di concentrazione
                                <br />
                                - Difficoltà della mente a vedere la realtà con chiarezza
                                <br />
                                - Difficoltà nel mettere a Fuoco
                                <br />
                                - Mal di Schiena
                                <br />
                                - Dolori Posturali
                            </em>
                            <br /><br />
                            e tanti altri...
                        </TextStrongL>

                        <IMGItem>
                            <IMGBox>
                                <img 
                                    src="https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Debora_priore.png" 
                                    alt="TESTIMONIANZA DEBORA" 
                                    onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Debora_priore.png")}    
                                />
                            </IMGBox>

                            <IMGBox>
                                <img 
                                    src="https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Raffy.png" 
                                    alt="TESTIMONIANZA RAFFY" 
                                    onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Raffy.png")}    
                                />
                            </IMGBox>

                            <IMGBox >
                                <img 
                                    src="https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Giuseppe_Sforza.png" 
                                    alt="TESTIMONIANZA GIUSEPPE" 
                                    onClick={() => openImageHandler!("https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Giuseppe_Sforza.png")}    
                                />
                            </IMGBox>
                        </IMGItem>

                        <TextP>
                        Il nostro corpo è una meravigliosa macchina Biochimica dove tutto è
                        <br /><br />
                        connesso, e comprendere il ruolo della vista ti aiuterà a
                        <br /><br />
                        migliorare la Salute di Tutto il tuo organismo,
                        <br /><br />
                        Fisico, Mentale, Emozionale.
                        <br /><br />
                        Nel corso trovi più di 10 Ore di Video e tanti esercizi da fare nel tuo quotidiano.
                        <br /><br />
                        NON si tratta di una palestra che porta via tempo alle altre tue attività,
                        <br /><br />
                        piuttosto di apprendere un NUOVO MODO di usare la Vista,
                        <br /><br />
                        che migliora da subito la Tua Salute.
                        <br /><br />
                        È come apprendere una Postura più sana, quando la userai?
                        <br /><br />
                        Sempre, ma senza doverla "fare",
                        <br /><br />
                        semplicemente TU diventi la Tua Nuova Postura (!)
                        <br /><br />
                        Con semplici esercizi che puoi fare per pochi minuti, dove vuoi, quando vuoi,
                        <br /><br />
                        il tuo modo di usare gli occhi cambierà per sempre...
                        </TextP>
                    </IMGDescription>

                    <br />
                    
                    <IMGItem>
                        <IMGBox>
                            <img alt="testimonianza" src='https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Daniela_Gnudi.jpg' />
                        </IMGBox>
                        
                        <IMGBox>
                            <img alt="testimonianza" src='https://www.viveredipoker.it/CorsoVedereConLaMente/Testimonianza_Simone_Ricetti.jpg' />
                        </IMGBox>
                    </IMGItem>

                    <ButtonBox paddingBottom='35px'>
                        {productData.course !== undefined && productData.course.content !== undefined && (
                            <>
                                <TitleCard>
                                    ESPLORA IL CONTENUTO DEL CORSO:
                                </TitleCard>

                                <VideoCourseListBox>
                                    <VideoCourseList 
                                        courseItem={productData.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos)}
                                        collapseHandler={() => {}}
                                        isOnPublicPage
                                    />
                                </VideoCourseListBox> 
                            </>
                        )}
                        
                        <ButtonShop 
                            PriceProduct={productPrice()} 
                            TypeOfProduct={product.type} 
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={(freeSlots <= 0) || !isProductPurchasable || !isOfferValid}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                            isSuperViewProductPage
                        />
                    </ButtonBox>
                </SecondaCard>
            </TotalPage>
        </>
    );
};

export default SuperViewPublicPageMobile;