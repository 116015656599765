import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import { Button } from 'style/buttons';


export const CardContent = styled.div<{ productType?: string }>`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 35px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow-y: auto;

    ::-webkit-scrollbar {
            display: none;
        }

    -ms-overflow-style: none;
    scrollbar-width: none;

    ${props => 
        (props.productType === 'bonus sicuro' || props.productType === 'liberi dalla stanchezza'|| props.productType === 'autostrada' || props.productType === 'fare soldi senza soldi') && `
            padding: 30px 0px 100px 0px;
            overflow-x: hidden;
        `
    }

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: flex-start;

        max-width: 1440px;

        padding: 0;

        ${props => 
            (props.productType === 'bonus sicuro' || props.productType === 'liberi dalla stanchezza' || props.productType === 'autostrada' || props.productType === 'fare soldi senza soldi') && `
                overflow-x: none;
            `
    }
    }

`;

export const IconBox = styled.div`

    margin: 10px 0 0;
    text-align: center;

    @media ${devices.tablet} {
        margin: 0;
    }
`;

export const CloseButton = styled.button <{ productType?: string }>`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;

    ${props => 
        (props.productType === 'bonus sicuro' || props.productType === 'liberi dalla stanchezza' || props.productType === 'autostrada' || props.productType === 'fare soldi senza soldi') && `
            margin: 0 25px;
        `
    }
    @media ${devices.tablet} {
        margin: 0;

        ${props => 
        (props.productType === 'bonus sicuro' || props.productType === 'liberi dalla stanchezza' || props.productType === 'autostrada' || props.productType === 'fare soldi senza soldi') && `
            margin: 0;
        `
    }
    }
`;

export const Title = styled.h2`
    
    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    white-space: nowrap;
    color: ${colors.primaryColor};

    text-align: center;
    text-transform: uppercase;
`;

export const TextContent = styled.p`
    
    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    color: ${colors.white};

    text-align: center;

    > span {
        color: ${colors.blueGrey};
    }
`;

export const NotesBox = styled.p`

    min-height: 200px;

    margin: 30px 0 0;
    padding: 25px;

    border: 1px solid ${colors.white023};
    border-radius: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    
    color: ${colors.white070};
`;

export const StartButton = styled(Button)`

    margin: 50px 0 0;
    padding: 13px 42px;

    font-size: 15px;

    @media ${devices.tablet} {
        margin: 30px 0 0;
    }
`;
