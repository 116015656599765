import React from 'react';

// style
import { 
    MailText
} from '../style/GeneralSupport.style';

const TCRSupport = () => {
    const encEmailTCR = "c3VwcG9ydG9AdHV0b3JpYWxjcnlwdG8uY29t";

    const decodedEmailTCR = atob(encEmailTCR);


    const handleEmailClickTCR = () => {
        window.location.href = `mailto:${decodedEmailTCR}`;
    };

  console.log("TCR :", decodedEmailTCR)
    return (
        <MailText id="contact" onClick={handleEmailClickTCR}>{decodedEmailTCR}</MailText>
    );
};

export default TCRSupport;
