import { FC, useEffect, useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { getEnvironmentBaseUrl } from 'configs/config.helper';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCopyToClipboard } from 'usehooks-ts';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// assets
import { ReactComponent as CopyLinkSVG } from '../assets/linkcopy.svg';
import { ReactComponent as GrayChairSVG } from '../assets/grayChair.svg';
import { ReactComponent as ButtonChairSVG } from '../assets/buttonChair.svg';
import { ReactComponent as ProtagonistSVG } from '../assets/person.svg';
import { ReactComponent as BlackProtagonistSVG } from '../assets/blackProtagonist.svg';
import { ReactComponent as LibertyIconSVG } from '../assets/libertyPoint.svg';
import { ReactComponent as PaypalIconSVG } from '../assets/paypal.svg';
import { ReactComponent as ArrowDSVG } from '../assets/arrowD.svg';
import { ReactComponent as DollarSVG } from '../assets/dollarSign.svg';
import { ReactComponent as ClockSVG } from '../assets/time.svg';
import { ReactComponent as YellowClockSVG } from '../assets/yellowTime.svg';
import { ReactComponent as ChairSVG } from '../assets/chair.svg';
import { ReactComponent as YellowChairDSVG } from '../assets/yellowChair.svg';
import { ReactComponent as YellowProtagonistSVG } from '../assets/yellowProtagonist.svg';
import { ReactComponent as BuySVG } from '../assets/ButtonBuy.svg';
import { ReactComponent as PastBuySVG } from '../assets/PastBuy.svg';

import { ReactComponent as ColpoSicuroSVG } from '../assets/iconColposicuro.svg';
import { ReactComponent as BonusSVG } from '../assets/ico-bonus.svg';
import { ReactComponent as ComplementiSVG } from '../assets/ico-complementi.svg';
import { ReactComponent as RegaliSVG } from '../assets/ico-regali.svg';
import { ReactComponent as MindsetSVG } from '../assets/ico-mindset.svg';
import { ReactComponent as BenessereSVG } from '../assets/ico-benessere.svg';
import { ReactComponent as BasiSVG } from '../assets/ico-le-basi.svg';
import { ReactComponent as EntrateGuidateSVG } from '../assets/ico-entrateguidate.svg';

// style
import {
    ArrowSelection,
    AutorText,
    ButtonBox,
    ButtonComponent,
    ButtonSelection,
    ButtonText,
    Card,
    CardBox,
    CardText,
    CardTextDescription,
    CardTitle,
    DirectInfo,
    DonationBox,
    LibertyPrice,
    LimitedBox,
    LimitedBoxText,
    LimitedCardText,
    LimitedNumberText,
    MainButton,
    MobileLimitBox,
    NewButtonShop,
    OutCardText,
    OverAllPage,
    PaymentSelector,
    PriceInfo,
    ProtagonistButton,
    ProtagonistButtonForSpectator,
    SecondCard,
    TextBoxSecondCard,
    TimeText,
    TitleCategory,
    TitleCategoryText,
    TitleCopy,
    TitleModal,
    TotalPage,
    WaiterButton,
} from './style/ThematicDirectPageShop.style';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCourseWithBrand, getCourseById } from 'pages/ThematicDirect/services/ThematicDirectCatalogue.services';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import LoadingMask from 'components/ui/LoadingMask/LoadingMask.component';
import useBrand from 'hooks/useBrand';
import { Product } from 'pages/PublicProductPages/types/Product';


const ThematicDirectPageShop = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isWaiterSelected, setIsWaiterSelected] = useState(true);
    const [isProtagonistSelected, setIsProtagonistSelected] = useState(false);
    const [isUpgradeSelected, setIsUpgradeSelected] = useState(false);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [thematicBroadcastProducts, setThematicBroadcastProducts] = useState<any[]>([]);
    const [product, setProduct] = useState<any>();
    const { productId } = useParams<{ productId: string }>();
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);

    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const [isLibertyPastPaymentModalVisible, setIsLibertyPastPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(false);
    const openLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(true);

    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const isDirectPurchase = searchParams.get('directPurchase')
    const typeOfPurchase = searchParams.get('typeOfPurchase')

    const localBrand = useBrand();

    const getProduct = async () => {
        if (!productId) return;
        try {

            const response = await getCourseById(Number(productId));

            if (!response) return;
            if (!localBrand) return;
            if (response?.brands[0]?.code !== localBrand.code) {
                var url = window.location.href.replace(window.location.hostname, response.brands[0].domain);
                window.location.href = url;
            } else {
                setProduct(response);
                loadingOff();
            }
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };
    
    useEffect(() => {
        if (!localBrand) return;
        if (!productId) return;
        getProduct();
    },[productId, localBrand]);

    // const product = thematicBroadcastProducts.find(product => product.id === Number(productId));

    const categoryName = product ? product.categories[0]?.name : 'No category';
    const subCategoryName = product ? product.subCategories[0]?.name : 'No subCategory';
    const textDescription = product ? product.salesData.delivery : 'No Description';

    var expirationDate = product ? product.productThematicCall.expirationDate : 0;
    var parsedDate = new Date(expirationDate);
    var day = parsedDate.getDate().toString().padStart(2, '0');
    var month = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); 
    var year = parsedDate.getFullYear();
    var formattedDate = day + '/' + month + '/' + year;

    var liveDate = product ? product.productThematicCall.liveDate : '0';
    var parsedliveDate = new Date(liveDate);
    var liveDay = parsedliveDate.getDate().toString().padStart(2, '0');
    var liveMonth = (parsedliveDate.getMonth() + 1).toString().padStart(2, '0'); 
    var liveYear = parsedliveDate.getFullYear();
    var formattedLiveDate = liveDay + '/' + liveMonth + '/' + liveYear;
    
    const formattedTime = `${new Date(liveDate + "Z").getHours() <10 ? '0' + new Date(liveDate + "Z").getHours() : new Date(liveDate + "Z").getHours()}:${new Date(liveDate + "Z").getMinutes() <10 ? '0' + new Date(liveDate + "Z").getMinutes() : new Date(liveDate + "Z").getMinutes()}`;

    const handleWaiterClick = () => {
        setIsWaiterSelected(true);
        setIsProtagonistSelected(false);
        setIsUpgradeSelected(false)
    };

    const handleProtagonistClick = () => {
        setIsWaiterSelected(false);
        setIsProtagonistSelected(true);
        setIsUpgradeSelected(false)
    };

    const handleUpgradeClick = () => {
        setIsWaiterSelected(false);
        setIsProtagonistSelected(false);
        setIsUpgradeSelected(true)
    };

    const copyIntoClipboardHandler = () => {
        if (!product) return;
        copyToClipboard(getEnvironmentBaseUrl(`app/reservedArea/product-diretta-tematica/${product.id}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };

    const libertyPastModalHandler = () => {
        setIsLibertyPastPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const maxProtagonistCheck = product ? (product?.productThematicCall?.maxProtagonist !== null ? product?.productThematicCall?.maxProtagonist : Infinity) : 0;
    const subscriptionsAsProtagonist = product ? (product?.subscriptionsAsProtagonist !== null ? product?.subscriptionsAsProtagonist : Infinity) : 0;
    const remainingProtagonistCheck = maxProtagonistCheck - subscriptionsAsProtagonist;

    const maxSpectatorCheck = product ? (product?.productThematicCall?.maxSpectator !== null ? product?.productThematicCall?.maxSpectator : Infinity) : 0;
    const subscriptionsAsSpectator = product ? (product?.subscriptionsAsSpectator !== null ? product?.subscriptionsAsSpectator : Infinity) : 0;
    const remainingSpectatorCheck = maxSpectatorCheck - subscriptionsAsSpectator;

    const remainingProtagonist = product ? (product?.productThematicCall?.maxProtagonist - product?.subscriptionsAsProtagonist) : 0;
    const remainingSpectator = product ? (product?.productThematicCall?.maxSpectator - product?.subscriptionsAsSpectator) : 0;

    const filteredAuthors = product ? (product?.authorsNames.filter((author: string) => author !== "Regista Dirette")) : null;

    const autor = filteredAuthors ? filteredAuthors.join(', ') : null;

    const name = product ? product.name : '';
    const priceProtagonist = product ? product.productThematicCall.protagonistPrice : 0;
    const priceSpectator = product ? product.productThematicCall.spectatorPrice : 0;
    let upgradePrice = product ? (product.productThematicCall.protagonistPrice - product.productThematicCall.spectatorPrice) : 0;

    const status = product ? product.productThematicCall.status : "";

    if (upgradePrice < 0) {
        upgradePrice = 0;
    }

    const protagonistNumber = product ? product.subscriptionsAsProtagonist : 0;
    const spectatorNumber = product ? product.subscriptionsAsSpectator : 0;
    const id = product ? product.id : 0;
    const paymentTypes = product ? product.paymentTypes : '';
    const requisites = product ? product.requisitesSatisfied : true ;
    const Price = product ? product.productThematicCall.price || '' : '';
    console.log("PRICE :", Price)

    const thematicType = () => {
        if (isWaiterSelected === false && isProtagonistSelected === false && isUpgradeSelected === true) return "UPGRADE"
        if (isWaiterSelected === true && isProtagonistSelected === false && isUpgradeSelected === false) return "SPECTATOR"
        if (isWaiterSelected === false && isProtagonistSelected === true && isUpgradeSelected === false) return "PROTAGONIST"
    }

    useEffect(() => {
        if (isDirectPurchase !== 'true') return;

        setTimeout(() => {setIsLoading(false)}, 1000)
    }, [isDirectPurchase])

    if (isDirectPurchase === 'true') {


        return (
            <>
                {isLoading && 
                    <>
                        <LoadingMask/>
                    </>
                }

                {!isLoading && <ButtonShop 
                    PriceProduct={isProtagonistSelected ? priceProtagonist : priceSpectator} 
                    TypeOfProduct={'THEMATIC_BROADCAST'} 
                    thematicBroadcastType={typeOfPurchase!}
                    courseElement={requisites} 
                    courseID={id} 
                    payments={paymentTypes} 
                    onTransferClick={transferModalHandler}
                    openBankTransferModal={openBankTransferModal}
                    onLibertyClick={libertyModalHandler}
                    openLibertyPaymentModal={openLibertyPaymentModal}
                    productIdHandler={libertyProductID}
                    productPriceHandler={libertyProductPrice}
                    loadingOn={loadingOn}
                    loadingOff={loadingOff}
                    isDirectPurchase={isDirectPurchase}
                    isOnThematicDirect
                />}
            </>
        )
    }

    const owned = product ? product.owned : false;
    const ownedAs = product ? product.ownedAs : null;
    const maxSpectator = product ? product.productThematicCall.maxSpectator : null;
    const maxProtagonist = product ? product.productThematicCall.maxProtagonist : null;
    const soldCopies = product ? product.subscriptions : '0';

    return (<>
        <OverAllPage>
            
        
            <TotalPage>
            {isLoading && 
                <>
                    <LoadingMask/>
                </>
            }

            {!isLoading && <>
                {!isAppMobile && status !== "PUBLISHED" &&
                    <TitleModal>
                        <TitleCopy>
                            <h1>{name}</h1>

                            <CopyLinkSVG onClick={copyIntoClipboardHandler}/>
                        </TitleCopy>

                        {autor !== null && <AutorText>{autor}</AutorText>}
                    </TitleModal>
                }

                {isAppMobile && status !== "PUBLISHED" &&
                    <TitleModal>
                        <TitleCopy>
                            <h1>{name}</h1>

                            <CopyLinkSVG onClick={copyIntoClipboardHandler}/>
                        </TitleCopy>

                        {autor !== null && <AutorText>{autor}</AutorText>}
                    </TitleModal>
                }

                {status === "PUBLISHED" &&
                    <TitleModal>
                        <TitleCopy>
                            <h1>{name}</h1>

                            <CopyLinkSVG onClick={copyIntoClipboardHandler}/>
                        </TitleCopy>

                        {autor !== null && <AutorText>{autor}</AutorText>}
                    </TitleModal>
                }

                <CardBox>
                    <Card>
                        <CardTextDescription dangerouslySetInnerHTML={{ __html: textDescription }} />

                        {status !== "PUBLISHED" &&
                            <DirectInfo>
                                <h2>{formattedLiveDate}</h2>

                                <TimeText>
                                    <ClockSVG/>

                                    {formattedTime}
                                </TimeText>
                            </DirectInfo>
                        }

                        {product && owned === false &&  maxSpectator !== 0 && maxProtagonist !== 0 && status !== "PUBLISHED" && remainingSpectatorCheck !== 0 && remainingProtagonistCheck !== 0 &&
                            <ButtonComponent>
                                <NewButtonShop>
                                    <ButtonSelection>
                                        <WaiterButton isSelected={isWaiterSelected} onClick={handleWaiterClick}>
                                            {isWaiterSelected === true && <ButtonChairSVG/>}
                                            {isWaiterSelected !== true && <GrayChairSVG/>}

                                            <p>SPETTATORE</p>
                                        </WaiterButton>

                                        <ProtagonistButton isSelected={isProtagonistSelected} onClick={handleProtagonistClick}>
                                            {isProtagonistSelected !== true && <ProtagonistSVG/>}
                                            {isProtagonistSelected === true && <BlackProtagonistSVG/>}
                                            
                                            <p>PROTAGONISTA</p>
                                        </ProtagonistButton>
                                    </ButtonSelection>

                                    <ButtonShop 
                                        PriceProduct={isProtagonistSelected ? priceProtagonist : priceSpectator} 
                                        TypeOfProduct={'THEMATIC_BROADCAST'} 
                                        thematicBroadcastType={thematicType()}
                                        courseElement={requisites} 
                                        courseID={id} 
                                        payments={paymentTypes} 
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        productIdHandler={libertyProductID}
                                        productPriceHandler={libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        isOnThematicDirect
                                    />
                                </NewButtonShop>
                            </ButtonComponent>
                        }

                        {((owned === false && (maxSpectator === 0 || remainingSpectatorCheck === 0) && maxProtagonist !== 0 && status !== "PUBLISHED" && remainingProtagonistCheck !== 0) ||
                            (product && owned === true && ownedAs === "SPECTATOR" && status !== "PUBLISHED" && remainingProtagonistCheck !== 0 && maxProtagonist !== 0 && maxSpectator !== 0)) &&
                            ((maxProtagonist > subscriptionsAsProtagonist) || (maxProtagonistCheck === Infinity && maxSpectatorCheck === 0)) &&
                            <ButtonComponent>
                                <NewButtonShop onClick={handleProtagonistClick}>
                                    <ButtonSelection>
                                        <ProtagonistButtonForSpectator>
                                            <BlackProtagonistSVG/>
                                            
                                            <p>PROTAGONISTA</p>
                                        </ProtagonistButtonForSpectator>
                                    </ButtonSelection>

                                    <ButtonShop 
                                        PriceProduct={priceProtagonist} 
                                        TypeOfProduct={'THEMATIC_BROADCAST'} 
                                        thematicBroadcastType={"PROTAGONIST"}
                                        courseElement={requisites} 
                                        courseID={id} 
                                        payments={paymentTypes} 
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        productIdHandler={libertyProductID}
                                        productPriceHandler={libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        isOnThematicDirect
                                    />
                                </NewButtonShop>
                            </ButtonComponent>
                        }

                        {owned === false && (maxProtagonist === 0 || remainingProtagonistCheck === 0) && maxSpectator !== 0 && status !== "PUBLISHED" && remainingSpectatorCheck !== 0 && 
                            ((maxSpectator > subscriptionsAsSpectator) || (maxSpectatorCheck === Infinity && maxProtagonistCheck === 0)) &&                
                            <ButtonComponent>
                                <NewButtonShop onClick={handleWaiterClick}>
                                    <ButtonSelection>
                                        <ProtagonistButtonForSpectator>
                                            <ButtonChairSVG/>
                                            
                                            <p>SPETTATORE</p>
                                        </ProtagonistButtonForSpectator>
                                    </ButtonSelection>

                                    <ButtonShop 
                                        PriceProduct={priceSpectator} 
                                        TypeOfProduct={'THEMATIC_BROADCAST'} 
                                        thematicBroadcastType={"SPECTATOR"}
                                        courseElement={requisites} 
                                        courseID={id} 
                                        payments={paymentTypes} 
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        productIdHandler={libertyProductID}
                                        productPriceHandler={libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        isOnThematicDirect
                                    />
                                </NewButtonShop>
                            </ButtonComponent>
                        }

                        {/* {product && owned === true && ownedAs === "SPECTATOR" && status !== "PUBLISHED" && remainingProtagonistCheck !== 0 &&
                            maxProtagonist !== 0 && maxSpectator !== 0 &&
                            <ButtonComponent>
                                <NewButtonShop onClick={handleUpgradeClick}>
                                    <ButtonSelection>
                                        <ProtagonistButtonForSpectator>
                                            <BlackProtagonistSVG/>
                                            
                                            <p>Upgrade a PROTAGONISTA</p>
                                        </ProtagonistButtonForSpectator>
                                    </ButtonSelection>

                                    <ButtonShop 
                                        PriceProduct={upgradePrice} 
                                        TypeOfProduct={'THEMATIC_BROADCAST'} 
                                        thematicBroadcastType={thematicType()}
                                        courseElement={requisites} 
                                        courseID={id} 
                                        payments={paymentTypes} 
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyModalHandler}
                                        openLibertyPaymentModal={openLibertyPaymentModal}
                                        productIdHandler={libertyProductID}
                                        productPriceHandler={libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        isOnThematicDirect
                                    />
                                </NewButtonShop>
                            </ButtonComponent>
                        } */}

                        { product && owned === false && ((maxSpectatorCheck !== 0 && remainingSpectatorCheck === 0 && maxProtagonistCheck !== 0 && remainingProtagonistCheck === 0) ||
                            (maxProtagonistCheck === 0 && remainingSpectatorCheck === 0) || (maxSpectatorCheck === 0 && remainingProtagonistCheck === 0) || 
                            ((maxProtagonistCheck === 0 && maxSpectatorCheck !== 0 && maxSpectatorCheck !== Infinity && maxSpectator <= subscriptionsAsSpectator) || 
                            (maxSpectatorCheck === 0 && maxProtagonistCheck !== 0 && maxProtagonistCheck !== Infinity && maxProtagonist <= subscriptionsAsProtagonist))) &&    
                            <ButtonComponent>
                                <ButtonBox>
                                    <MainButton isOwned={true}>
                                        <ButtonText>SOLD OUT!</ButtonText>
                                    </MainButton>
                                </ButtonBox>
                            </ButtonComponent>
                        }
                        
                        {product && owned === true && product.ownedAs === "SPECTATOR" && status !== "PUBLISHED" && remainingProtagonistCheck === 0 &&
                            maxProtagonist !== 0 && maxSpectator !== 0 &&
                                <ButtonComponent>
                                    <ButtonBox>
                                        <MainButton isOwned={true}>
                                            <ButtonText>{priceSpectator} €</ButtonText>

                                            <BuySVG/>
                                        </MainButton>
                                    </ButtonBox>
                                </ButtonComponent>
                        }

                        {owned === false && status === 'PUBLISHED' &&
                            <ButtonComponent>
                                <ButtonBox>
                                    <ButtonShop 
                                        PriceProduct={Price} 
                                        TypeOfProduct={'COURSE'} 
                                        courseElement={requisites} 
                                        courseID={id} 
                                        payments={paymentTypes} 
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyPastModalHandler}
                                        openLibertyPaymentModal={openLibertyPastPaymentModal}
                                        productIdHandler = {libertyProductID}
                                        productPriceHandler = {libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        isPastBroadcast={true}
                                    />
                                </ButtonBox>
                            </ButtonComponent>
                        }

                        {owned === true && status === 'PUBLISHED' &&
                            <ButtonComponent>
                                <ButtonBox>
                                    <MainButton isOwned={product.owned}>

                                        {Price !== '' && <ButtonText> € {Price}</ButtonText>}
                                        {Price === '' && <ButtonText> IN REGALO</ButtonText>}

                                        <PastBuySVG/>
                                    </MainButton>
                                </ButtonBox>
                            </ButtonComponent>
                        }

                        {((owned === true && ownedAs === "PROTAGONIST") || (owned === true && ownedAs === "SPECTATOR" && maxProtagonist === 0) || (owned === true && ownedAs === null)) && status !== 'PUBLISHED' &&
                            <ButtonComponent>
                                <ButtonBox>
                                    <MainButton isOwned={owned}>
                                    {ownedAs !== "SPECTATOR" && <ButtonText>{priceProtagonist} €</ButtonText>}
                                    {ownedAs === "SPECTATOR" && <ButtonText>{priceSpectator} €</ButtonText>}

                                        <BuySVG/>
                                    </MainButton>
                                </ButtonBox>
                            </ButtonComponent>
                        }

                        {status !== "PUBLISHED" && 
                            <SecondCard>
                                {(remainingProtagonist !==  0 || remainingSpectator !== 0) && (remainingProtagonist >  0 || remainingSpectator > 0) &&
                                    <LimitedBox>
                                        <h2>POSTI LIMITATI!</h2>

                                        <LimitedBoxText>
                                            {isAppMobile && remainingProtagonist !==  0 && remainingSpectator !== 0 && remainingProtagonist >  0 && remainingSpectator > 0 &&
                                                <MobileLimitBox>
                                                    <LimitedCardText>
                                                        Restano solo <h3> {remainingProtagonist} </h3> posti Protagonista
                                                    </LimitedCardText>

                                                    <LimitedCardText>
                                                        e <h3> {remainingSpectator} </h3> posti Spettatore.
                                                    </LimitedCardText>
                                                </MobileLimitBox>
                                            }

                                            {!isAppMobile && remainingProtagonist !== 0 && remainingSpectator !== 0 && remainingProtagonist >  0 && remainingSpectator > 0 &&
                                                <LimitedCardText>
                                                    Restano solo <h3> {remainingProtagonist} </h3> posti Protagonista e <h3> {remainingSpectator} </h3> posti Spettatore.
                                                </LimitedCardText>
                                            }

                                            {isAppMobile && remainingProtagonist !== 0 && remainingProtagonist >  0 && remainingSpectator === 0  && 
                                                <MobileLimitBox>
                                                    <LimitedCardText>
                                                        Restano solo <h3> {remainingProtagonist} </h3> posti Protagonista.
                                                    </LimitedCardText>
                                                </MobileLimitBox>
                                            }

                                            {!isAppMobile && remainingProtagonist !== 0 && remainingProtagonist >  0 && remainingSpectator === 0  && 
                                                <LimitedCardText>
                                                    Restano solo <h3> {remainingProtagonist} </h3> posti Protagonista 
                                                </LimitedCardText>
                                            }

                                            {isAppMobile && remainingSpectator !== 0  && remainingSpectator > 0 && remainingProtagonist === 0 &&
                                                <MobileLimitBox>
                                                    <LimitedCardText>
                                                        Restano solo <h3> {remainingSpectator} </h3> posti Spettatore.
                                                    </LimitedCardText>
                                                </MobileLimitBox>
                                            }

                                            {!isAppMobile && remainingSpectator !== 0  && remainingSpectator > 0 && remainingProtagonist === 0 &&
                                                <LimitedCardText>
                                                    Restano solo <h3> {remainingSpectator} </h3> posti Spettatore. 
                                                </LimitedCardText>
                                            }
                                        </LimitedBoxText>
                                    </LimitedBox>
                                }

                                <TextBoxSecondCard>
                                    {((maxProtagonist !== 0) || (maxProtagonist !== 0 && maxSpectator === 0)) && 
                                        <CardText>
                                            <ProtagonistSVG/>

                                            Posti Protagonista prenotati: <span> {protagonistNumber} </span>
                                        </CardText>
                                    }

                                    {((maxSpectator !== 0) || (maxSpectator !== 0 && maxProtagonist === 0)) &&
                                        <CardText>
                                            <GrayChairSVG/>

                                            Posti Spettatore prenotati: <span> {spectatorNumber} </span>
                                        </CardText>
                                    }
                                </TextBoxSecondCard>
                            </SecondCard>
                        }

                        {status === "PUBLISHED" && 
                            <SecondCard>
                                <DonationBox>
                                    <DollarSVG/>

                                    <CardText>

                                        Copie vendute: <span> {soldCopies} </span>
                                    </CardText>
                                </DonationBox>

                                {/* <DonationBox>
                                    <DollarSVG/>

                                    <CardText>
                                        Donazioni raccolte: <span> 2300 € </span>
                                    </CardText>
                                </DonationBox> */}
                            </SecondCard>
                        }
                    </Card>
                </CardBox>

                {formattedDate !== "01/01/1970" &&
                    <OutCardText>
                        I contenuti di questa DIretta non saranno più disponibili dal {formattedDate}.
                    </OutCardText>
                }
            </>}
            </TotalPage>
            
            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    productType="ThematicBroadcast"
                    thematicBroadcastType={thematicType()}
                />
            }

            {isLibertyPastPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPastPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    isPastBroadcast={true}
                />
            }
            </OverAllPage>
    </>);
};

export default ThematicDirectPageShop;