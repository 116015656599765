import { FC } from 'react';

// components
import RoadMap from './RoadMap';

// types
import { RoadMapCardProps } from './RoadMapCard';
import { Event } from '../HomePageTCR.component.d';

// assets
import { ReactComponent as ExpandSVG } from './assets/expand-icon.svg';
import { ReactComponent as OpenSVG } from './assets/open-icon.svg';

// style
import { 
    Card,
    Header,
    EarningNews,
    StaticText,
    NewsText
 } from './style/RoadMapCard.style';
import { formattedCurrency } from 'utils/supportFunctions';


/**
 * RoadMap
 * 
 * @author Alessio Grassi
 * 
 * @param roadmapEvents - array of events to display into the roadmap
 * @param isExpanded - state of the expansion width
 * @param onExpansion - handler to set the expansion state
 * 
 * @returns JSX
 */


const RoadMapCard: FC<RoadMapCardProps> = ({
    roadmapEvents,
    isExpanded,
    onExpansion,
    reportEarningModalOpen,
    resultsForNewsBanner,
    nextEventIndex
}) => {

    const newsClickHandler = () => {
        if (reportEarningModalOpen) reportEarningModalOpen()
    };

    const toggleRoadMapExpansionHandler = () => {
        
        if(onExpansion === undefined) return;
        onExpansion();
    };

    const oddEvents: Event[] = [];
    const evenEvents: Event[] = [];

    if (roadmapEvents !== undefined) {
        
        for (let i=0; i < roadmapEvents.length; i++) {
            
            if (i % 2 !== 0) {

                oddEvents.push(roadmapEvents[i]);
                continue;
            }

            evenEvents.push(roadmapEvents[i]);
        }
    }

    const nowTimestamp = Date.now();
    let activeEventTimestamp: number = nowTimestamp;

    if (roadmapEvents !== undefined) {

        const activeEventElement = roadmapEvents.find((event) => (
            (+event.startTimestamp!) > nowTimestamp
        ));

        if (activeEventElement !== undefined && activeEventElement?.startTimestamp) {
            activeEventTimestamp = +activeEventElement.startTimestamp;
        }
    }

    return (
        <Card isExpanded={isExpanded}>
            <Header>
                <h3>Roadmap:</h3>

                <ExpandSVG onClick={toggleRoadMapExpansionHandler} />
            </Header>

            <RoadMap
                topLineEvents={evenEvents}
                bottomLineEvents={oddEvents}
                isExpanded={isExpanded}
                activeEventTimestamp={activeEventTimestamp}
                nextEventIndex={nextEventIndex}
            />

            <EarningNews onClick={newsClickHandler}>
                <StaticText>
                    GUADAGNI:

                    <OpenSVG />
                </StaticText>

                {resultsForNewsBanner !== undefined && (
                    <NewsText>
                        {`Questo mese i nostri iscritti hanno Guadagnato un totale di 
                        ${formattedCurrency(resultsForNewsBanner.monthTotal, 0)} € con solo metodi SICURI. 
                        Il guadagno per ogni Iscritto è stato mediamente di ${formattedCurrency(resultsForNewsBanner.avgResultBonus 
                            + resultsForNewsBanner.avgResultColpo, 0)} euro`}
                    </NewsText>
                )}
            </EarningNews>
        </Card>
    );
};

export default RoadMapCard;
