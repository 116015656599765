const DAY_NAME: any = {
    0: 'Dom',
    1: 'Lun',
    2: 'Mar',
    3: 'Mer',
    4: 'Gio',
    5: 'Ven',
    6: 'Sab',
}

export const channelMessageText = (channelLastMessage: any) => {

    
    if (channelLastMessage === null) return 'Ancora non ci sono messaggi';
    if (channelLastMessage.premiumContent !== null) return channelLastMessage.premiumContent;
    if (channelLastMessage.content !== null) return channelLastMessage.content;
    return "Immagine";
};

const formattedTime = (timeNumber: number) => {

    if (timeNumber < 10) return `0${timeNumber}`;
    return `${timeNumber}`;
};

export const channelLastMessageDate = (lastMessageDate: any) => {

    if (lastMessageDate === null) return "";
    if (lastMessageDate.sendingDate === null) return "";

    const messageDate = new Date(`${lastMessageDate.sendingDate}Z`);
    const nowDate = new Date();
    const difference = dateDifferenceInDays(nowDate, messageDate);
    const dateHour = messageDate.getHours();
    const dateMinutes = messageDate.getMinutes();

    if (difference > 6) return `${messageDate.getDate()}/${messageDate.getMonth() + 1}/${messageDate.getFullYear()}`;
    if ((difference > 1) || (messageDate.getDate() !== nowDate.getDate())) return DAY_NAME[messageDate.getDay()];
    return `${formattedTime(dateHour)}:${formattedTime(dateMinutes)}`;
};

export const dateDifferenceInDays = (nowDate: Date, fullMessageSendingDate: Date) => {
    return Math.floor((nowDate.valueOf() - fullMessageSendingDate.valueOf()) / 1000 / 60 / 60 / 24);
};
