
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";

// routing
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { saveProductsLessonsStatus } from "redux/actions/actions-user";

//assets
import { ReactComponent as ComingSoonSVG } from './assets/coming-soon.svg';

//component
import ProductPageDesktop from "./layout/ProductPageDesktop/ProductPageDesktop.component";
import ProductPageMobile from "./layout/ProductPageMobile/ProductPageMobile.component";
import { getCourseWithId, getUserVipPathLevel, getHasUserTrial, getLessonDataWithId, postSubscibeTrial } from "./services/ProductPage.services";

//style
import {
    Page,
    MobileVersion,
    DesktopVersion,
    Title
} from './style/ProductPage.style';

const CoursePage: FC<{productData?: any}> = ({ productData }) => {

    // const { productsLessonsStatus } = useAppSelector(state => state.user);
    const [course, setCourse] = useState<any>(productData)
    const [firstLesson, setFirstLesson] = useState<any>({})
    const [lessonStatus, setLessonStatus] = useState<any>(undefined)
    const [isTrialCourseButtonVisible, setIsTrialCourseButtonVisible] = useState<boolean>(false);

    const location = useLocation();
    const { productId, typeOfProduct } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // const productId = location.pathname.split('/')[3];

    // const updateReduxLessonDetalis = () => getLessonsDetails();

    const getCourse= async () => {
        
        try {
            
            const response = await getCourseWithId(+productId!);
            // console.log("i miei corsi", response);
            
            response.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos);
            
            setCourse(response);
            
            var chapterPos = response.course.content.flatMap((el: any) => { 
                if (!el.parentId) return +el.pos;
                return 1000;
            });

            if (searchParams.get('nodeID')) { 
                console.log("Looking for children...");
                var requiredNode = response.course.content.find((node: any, index: number) => { if (node.id === Number(searchParams.get('nodeID'))) return true});
                while (requiredNode.children) {
                    requiredNode = requiredNode.children[0];
                }
                console.log("Done! Setting lesson node as: "+requiredNode.id);
                setFirstLesson(requiredNode);
            } else {
                const firstChapterPos = chapterPos.sort((elA: number, elB: number) => elA - elB)[0];
                const firstChapter = response.course.content.filter((el: any) => (!el.parentId) && (el.pos === firstChapterPos));
                const firstLessonOnVideo = () => {
                    if (firstChapter === undefined) return;
                    if (firstChapter[0].children[0].lesson) return firstChapter[0].children[0];
                    if (firstChapter[0].children[0].children[0].lesson) return firstChapter[0].children[0].children[0];
                    return firstChapter[0].children[0].children[0].children[0];
                }
                setFirstLesson(firstLessonOnVideo());
            } 

        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn(error);
        }
    };

    const getLevel= async () => {
        
        try {
            
            const response = await getUserVipPathLevel();
            // console.log("i miei corsi", response);
            
            response.course.content.sort((elA: any, elB: any) => elA.pos - elB.pos);
            
            setCourse(response);
            
            var chapterPos = response.course.content.flatMap((el: any) => { 
                if (!el.parentId) return +el.pos;
                return 1000;
            });

            if (searchParams.get('nodeID')) { 
                console.log("Looking for children...");
                var requiredNode = response.course.content.find((node: any, index: number) => { if (node.id === Number(searchParams.get('nodeID'))) return true});
                if (requiredNode === undefined) navigate('/fallback-page/permission-denied');
                while (requiredNode.children) {
                    requiredNode = requiredNode.children[0];
                }
                console.log("Done! Setting lesson node as: "+requiredNode.id);
                setFirstLesson(requiredNode);
            } else {
                const firstChapterPos = chapterPos.sort((elA: number, elB: number) => elA - elB)[0];
                const firstChapter = response.course.content.filter((el: any) => (!el.parentId) && (el.pos === firstChapterPos));
                const firstLessonOnVideo = () => {
                    if (firstChapter === undefined) return;
                    if (firstChapter[0].children[0].lesson) return firstChapter[0].children[0];
                    if (firstChapter[0].children[0].children[0].lesson) return firstChapter[0].children[0].children[0];
                    return firstChapter[0].children[0].children[0].children[0];
                }
                setFirstLesson(firstLessonOnVideo());
            } 

        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn(error);
        }
    };

    const getProductTrial = async () => {
        try {

            const response = await getHasUserTrial(+productId!);
            
            setIsTrialCourseButtonVisible(response);

        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn(error);
        }
    };

    const getLessonsDetails = async () => {

        try {
            const response = await getLessonDataWithId(course.course.id);

            setLessonStatus(response);
        } catch (error: any) {
            
        }
    };

    useEffect(() => {
        if (typeOfProduct === 'level') {
            getLevel();
            return;
        }
        getCourse();
        getProductTrial();
    },[]);

    useEffect(() => {

        getLessonsDetails();
    },[course]);

    if ((
        (typeOfProduct === 'level') &&
        (course !== undefined) && 
        (course.course !== undefined) &&
        (course.course.content !== undefined) &&
        (course.course.content.length <= 2)
        ) || (course === null)) return (
        <Page>
            <Title>
                <Link to='/reservedArea'>
                    Area Riservata
                </Link>
                
                {course !== null ? course.description ?? '' : ''}
            </Title>

            <ComingSoonSVG />
        </Page>
    )

    return (

        <Page>
            {lessonStatus && (
                <>
                    <MobileVersion>
                        {firstLesson.id && (
                            <ProductPageMobile
                                courseItem={course}
                                lessonToDisplay={firstLesson}
                                typeOfProduct={typeOfProduct!}
                                lessonStatus={lessonStatus}
                                updateProductDetails={getLessonsDetails}
                                isTrialCourseButtonVisible={isTrialCourseButtonVisible}
                            />)}
                    </MobileVersion>

                    <DesktopVersion>
                        {firstLesson.id && (
                            <ProductPageDesktop 
                                courseItem={course}
                                lessonToDisplay={firstLesson}
                                typeOfProduct={typeOfProduct!}    
                                updateProductDetails={getLessonsDetails}
                                lessonStatus={lessonStatus}
                                isTrialCourseButtonVisible={isTrialCourseButtonVisible}
                            />)}
                    </DesktopVersion>
                </>)
            }
        </Page>
    );
};

export default CoursePage;