import { callGET, callPOST } from "network/network";
import store from "redux/store";

export const getCourseWithBrand = async () => {

    const payload = {brandCode: store.getState().ui.brand?.code}
        
    try {
        
        const response = await callGET('v1/productsforsell/bytypes?types=THEMATIC_BROADCAST', true, payload);
        
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const getCourseWithBrandHidden = async () => {

    const payload = {brandCode: store.getState().ui.brand?.code}
        
    try {
        
        const response = await callGET('v1/self/products', true, payload);
        
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }
};

export const getPrivateInfoBroadcast = async (productId: string) => {
    const payload = {brandCode: store.getState().ui.brand?.code}

    try {
        
        const response = await callGET(`v1/self/products/${productId}?types=THEMATIC_BROADCAST`, true, payload);
        
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }

}

export const getDonation = async (productId: string) => {
    const payload = {brandCode: store.getState().ui.brand?.code}
    //ammount missing

    try {
        
        const response = await callPOST(`v1/publicpurchase/single/thematicbroadcast/${productId}/donation`, payload, true);
        
        if (!response || response === undefined) throw new Error("Products response undefined");

        return response;

    } catch (error: any) {
        if (error.message.includes('403')) throw new Error ('403');
        console.warn(error);
    }

}