import { FC, useEffect } from 'react';

// routing
import { useNavigate } from 'react-router-dom';

// components
import LoadingMask from 'components/ui/LoadingMask/LoadingMask.component';


const ResetPage: FC<{}> = () => {

    const navigate = useNavigate();

    useEffect(() => {

        localStorage.clear();
        navigate('/reservedArea');
    }, []);

    return (
      
        <LoadingMask 
            isCenter
            size='page'
            paddingTop='200px'
        />
    );
};

export default ResetPage;
