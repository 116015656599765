import { FC, useState } from 'react';

// redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCopyToClipboard } from 'usehooks-ts';

// utils
import { getEnvironmentBaseUrl, getEnvironmentProductId } from 'configs/config.helper';

// types
import { HighwayProps } from './Highway.component.d';

// assets
import { ReactComponent as BulletCheck } from './assets/bulletCheck.svg';
import { ReactComponent as CopySVG } from './assets/link-icon.svg';

// components
import MediaPlayer from 'components/MediaPlayer/MediaPlayer.component';
import ButtonShop from 'components/ButtonShop/ButtonShop.component';

// style
import {
    Page,
    Title,
    FirstRow,
    VideoBox,
    DescriptionBox,
    DescriptionTitle,
    TextDescriptionTitle,
    ImportantPhrase,
    BulletBox,
    BulletRow,
    BulletPoint,
    ButtonBox,
    CourseDescriptionContainer,
    FocusPointsBox,
    FocusPoint,
    FocusTitle,
    FocusDescription,
    ImageTextBox,
    ImageBox,
    TextBox,
    DescriptionText,
    SimpleText,
    ModalPage,
    TitleCard,
    VideoCourseListBox
} from './style/Highway.style';
import VideoCourseList from 'pages/ProductPage/VideoCourseList/VideoCourseList.component';


const Highway: FC<HighwayProps> = ({
    product,
    isOpenInModal,
    productPrice,
    onTransferClick,
    buyInfoModalHandler,
    openBankTransferModal,
    onLibertyClick,
    libertyInfoModalHandler,
    openLibertyPaymentModal,
    modalGetPaymentID,
    libertyProductID,
    libertyProductPrice,
    openProductRequisitesModal,
    hasRequire,
    isDisabled,
    publicInfoPageTransferPrice,
    publicPageTransferPrice
}) => {

    
    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ copied , copyToClipboard ] = useCopyToClipboard();
    const dispatch = useAppDispatch();
    
    if (product === undefined) return <></>;
    
    const { name, salesData } = product;
    const vipPoints = (product?.productService?.paymentPlan.vipPoints);
    const purchaseButtonText = salesData?.purchaseButtonText;
    const videoPoster = salesData?.videoCover;
    const videoPosterUrl = videoPoster?.url;
    const videoData = salesData?.videoSale;
    const videoUrl = videoData?.url;

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const copyIntoClipboardHandler = () => {
        
        copyToClipboard(getEnvironmentBaseUrl(`app/public/product/${getEnvironmentProductId('autostrada')}`));
        const snackbarData: SnackbarOptions = {
            type: 'info',
            message: "Link copiato negli appunti."
        };
        dispatch(addSnackbar(snackbarData));
    };

    const localMedia = {
        src: videoUrl ?? "https://www.viveredipoker.it/IlGiocoellaVita/L1_V07_partenza_Rapida.mp4",
        type: "video/mp4"
    };

    console.log("PRODOTTI : ", product)

    return (
        <ModalPage>
            {isAppMobile && (
                <VideoBox>
                    <MediaPlayer
                        media={localMedia}
                        preload='metadata'
                        poster={videoPosterUrl}
                    />
                </VideoBox>
            )}
            
            <Page>
                {!isAppMobile && (
                    <Title>
                        L’Autostrada

                        <CopySVG onClick={copyIntoClipboardHandler} />
                    </Title>
                )}

                <FirstRow>
                    {!isAppMobile && (
                        <VideoBox>
                            <MediaPlayer
                                media={localMedia}
                                preload='metadata'
                                poster={videoPosterUrl}
                            />
                        </VideoBox>
                    )}

                    {isAppMobile && (
                        <Title>
                            L’Autostrada

                            <CopySVG onClick={copyIntoClipboardHandler} />
                        </Title>
                    )}

                    <DescriptionBox>
                        <DescriptionTitle>
                            L’AUTOSTRADA: VELOCE E GARANTITA
                        </DescriptionTitle>

                        <ImportantPhrase>
                            Vuoi liberarti dal lavoro ma non sai da dove cominciare, <span>hai paura</span> di 
                            non riuscire o di non essere all’altezza? Prendi l’Autostrada. Con l’Autostrada hai:
                        </ImportantPhrase>

                        <BulletBox>
                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    Un Assistente Personale tutto per TE
                                </BulletPoint>
                            </BulletRow>

                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    Infinite Lezioni a disposizione
                                </BulletPoint>
                            </BulletRow>

                            <BulletRow>
                                <BulletCheck />

                                <BulletPoint>
                                    La GARANZIA del risultato
                                </BulletPoint>
                            </BulletRow>
                        </BulletBox>

                        <ImportantPhrase isCenter>Clicca qui sotto per accedere</ImportantPhrase>
                        
                        <ButtonBox>
                            <ButtonShop 
                                PriceProduct={productPrice} 
                                TypeOfProduct={product.type}
                                courseElement={product} 
                                courseID={product.id} 
                                payments={product.paymentTypes}
                                onTransferClick={onTransferClick}
                                isOpenInModal={isOpenInModal}
                                buyInfoModalHandler={buyInfoModalHandler}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                libertyInfoModalHandler={libertyInfoModalHandler}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                isOnPublicProductPage
                                modalGetPaymentID={modalGetPaymentID}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                openProductRequisitesModal={openProductRequisitesModal}
                                hasRequire={hasRequire}
                                isDisabled={isDisabled}
                                publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                                publicPageTransferPrice={publicPageTransferPrice}
                                vipPoints={vipPoints}
                                customLabel={`VOGLIO PRENDERE L'AUTOSTRADA`}
                            />
                        </ButtonBox>
                    </DescriptionBox>
                </FirstRow>

                <CourseDescriptionContainer>
                    <FocusPointsBox>
                        <FocusPoint>
                            <FocusTitle>
                                Un Assistente Personale:
                            </FocusTitle>

                            <FocusDescription>
                                Ti viene assegnato un Assistente Personale che ti segue passo per passo
                            </FocusDescription>
                        </FocusPoint>
                        
                        <FocusPoint>
                            <FocusTitle>
                                Garanzia di Risultato:
                            </FocusTitle>

                            <FocusDescription>
                                Hai infinite lezioni* fino a che non raggiungi il tuo obiettivo
                            </FocusDescription>
                        </FocusPoint>

                        <FocusPoint>
                            <FocusTitle>
                                Un Percorso su Misura:
                            </FocusTitle>

                            <FocusDescription>
                                NON sei TU a doverti adattare al percorso, è il
                                percorso che si adatta a TE e alle tue esigenze
                            </FocusDescription>
                        </FocusPoint>
                    </FocusPointsBox>

                    <ImageTextBox>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img1.jpg' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <TextDescriptionTitle>
                                SEI FORTUNATO (!)
                            </TextDescriptionTitle>

                            <DescriptionText>
                                A differenza di tutte quelle persone che hanno trovato Autostrada chiusa 
                                e che hanno dovuto percorrere la strada più lenta, piena di traffico, di buche e rallentamenti vari...
                            </DescriptionText>
                            
                            <DescriptionText>
                                Tu sei fortunato perché, al momento, ci sono posti liberi per prendere Autostrada, 
                                il percorso creato per chi vuole <span>GRANDI RISULTATI</span> e li vuole <span>SUBITO.</span>
                            </DescriptionText>

                            <DescriptionText>
                                Ma sappi che non è sempre così.
                            </DescriptionText>

                            <DescriptionText>
                                Abbiamo tante richieste e il numero di assistenti è limitato. 
                                Per questo motivo ti consiglio di cogliere questa occasione al volo.
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <TextBox>
                            <DescriptionText>
                                L'Autostrada è l'unico percorso che, da anni, porta a Liberarsi dal Lavoro in meno di 10 settimane... 
                                La cosa interessante è che NON sei TU a doverti adattare al percorso, è il percorso che si adatta a TE.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Ma la cosa ancora più importante è che <span>L’OBIETTIVO È GARANTITO.</span> Ed 
                                è proprio per questa ragione che L'Autostrada durerà tutto il tempo necessario a raggiungere l’obiettivo: 
                                arrivare a Secret Bonus e quindi Liberarti dal Lavoro (!)
                            </DescriptionText>
                        </TextBox>

                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img2.png' alt='Money'/>
                        </ImageBox>
                    </ImageTextBox>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={productPrice} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={isDisabled}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                            customLabel={`VOGLIO PRENDERE L'AUTOSTRADA`}
                        />
                    </ButtonBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img3.png' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <TextDescriptionTitle>
                                COME FUNZIONA?
                            </TextDescriptionTitle>
                            
                            <DescriptionText>
                                È molto semplice... Ti viene assegnato un <span>Assistente Personale</span> che vedi settimanalmente.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Il Tuo assistente ti indica <span>esattamente</span> cosa fare ogni settimana e, 
                                se segui le sue indicazioni, arrivi anche tu a Secret Bonus come tanti altri prima di te...
                            </DescriptionText>
                            
                            <DescriptionText>
                                Appena arrivi a MultiBonus, vieni promosso al livello successivo. 
                            </DescriptionText>

                            <DescriptionText>
                                Lascia che ti spieghi meglio questo passaggio perché è davvero importante. Quindi presta molta attenzione. Ok? 
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ImageTextBox isDesktopDirectionColumn>
                        <SimpleText>
                            <span>Il percorso si compone in 3 fasi.</span>
                        </SimpleText>    

                        <SimpleText> 
                            Nella <span>Fase 1</span>, quindi all’inizio del tuo percorso, il tuo assistente ti vede settimanalmente e ti indica, 
                            di volta in volta, cosa fare per la settimana successiva.
                        </SimpleText>
                        
                        <SimpleText>
                            Seguendo le sue indicazioni arrivi rapidamente a MultiBonus e vieni quindi promosso alla <span>Fase 2.</span>
                        </SimpleText>

                        <SimpleText>
                            Cosa cambia? Che non dovrai più aspettare una settimana per vedere il tuo assistente ma hai un contatto diretto con lui.
                        </SimpleText>

                        <SimpleText>
                            Questo significa che puoi porre tutte le domande che vuoi nel momento stesso in cui ti vengono eventuali dubbi. 
                            Detto in un altro modo: puoi parlare con il tuo Assistente ogni volta che ne hai bisogno.
                        </SimpleText>

                        <SimpleText>
                            Così facendo procedi ancora più spedito, perché ogni eventuale dubbio viene risolto 
                            immediatamente e tu puoi ripartire velocemente e senza perdere tempo.
                        </SimpleText>

                        <SimpleText>
                            E non è tutto... Ti viene dato accesso ad un gruppo Facebook all’interno del quale trovi i Coach specializzati. 
                            Nel gruppo trovi dirette aggiuntive che ti offrono ulteriore supporto durante il tuo percorso.
                        </SimpleText>

                        <SimpleText>
                            E poi c’è la <span>Fase 3.</span> <br />
                            Una volta arrivato a Secret Bonus e completata l’Autostrada non rimani solo.
                        </SimpleText>

                        <SimpleText>
                            Vieni promosso alla Fase 3. Hai accesso ad un altro gruppo Facebook dove ci sono tutti gli assistenti personali 
                            e tutti i Coach per assicurarci che tu abbia i massimi risultati possibili.
                        </SimpleText>
                    </ImageTextBox>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={productPrice} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={isDisabled}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                            customLabel={`VOGLIO PRENDERE L'AUTOSTRADA`}
                        />
                    </ButtonBox>

                    <ImageTextBox>
                        <TextBox>
                            <TextDescriptionTitle>
                                QUANTO DURA IL PERCORSO?
                            </TextDescriptionTitle>

                            <DescriptionText>
                                Tutto il tempo che ti serve.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Hai <span>infinite lezioni*</span> fino a che non raggiungi il tuo obiettivo. 
                                Normalmente le persone completano Autostrada in meno di 10 lezioni, 
                                alcuni addirittura in 4/5 lezioni, ma puoi prenderti i tuoi tempi, senza stress.
                            </DescriptionText>

                            <DescriptionText>
                                ATTENZIONE, però...<br />
                                C’è una condizione fondamentale che devi rispettare se vuoi che il percorso funzioni davvero.<br />
                                <span>TI DEVI IMPEGNARE!!</span>
                            </DescriptionText>
                        </TextBox>

                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img4.png' alt='Money'/>
                        </ImageBox>
                    </ImageTextBox>

                    <ImageTextBox isDesktopDirectionColumn>  
                        <SimpleText>
                            Infatti...
                            <br/>
                            L’assistente Personale è a tua disposizione MA non può fare il percorso al posto tuo.<br/> 
                            Ti aiuta, è al tuo servizio, MA <span>sei TU</span> a dover fare il percorso...
                        </SimpleText>

                        <SimpleText>
                            "L’unica cosa che Liberi dal Lavoro non può fare, è fare il percorso al posto tuo”
                            <br/>
                            Cit. Paolo Luini
                        </SimpleText>

                        <SimpleText>
                            “Per guadagnare coi Bonus, bisogna fare i bonus”
                            <br/>
                            Cit. Flavio Catapano
                        </SimpleText>
                    </ImageTextBox>

                    <ImageTextBox>
                        <TextBox>
                            <TextDescriptionTitle>
                                NOTE IMPORTANTI:
                            </TextDescriptionTitle>

                            <DescriptionText>
                                *Le lezioni sono infinite a patto che tu ti presenti regolarmente e svolgi i task assegnati.
                            </DescriptionText>
                            
                            <DescriptionText>
                                La Fase 1 di Autostrada può concludersi in 2 modi:
                            </DescriptionText>

                            <DescriptionText>
                                1) <span>SUCCESSO:</span> sei arrivato a Multi Bonus, 
                                hai tutto chiaro e ora puoi passare alla Fase 2
                            </DescriptionText>

                            <DescriptionText>
                                2) <span>INSUCCESSO:</span> nella Fase 1 non hai svolto i Task che ti hanno assegnato, 
                                oppure non ti sei presentato alle lezioni, o magari decidi di mollare il percorso per dedicarti ad altro, 
                                o perché sei costretto per emergenze familiari
                            </DescriptionText>
                        </TextBox>

                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img5.png' alt='Money'/>
                        </ImageBox>
                    </ImageTextBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img6.png' alt='Money'/>
                        </ImageBox>

                        <TextBox>                       
                            <DescriptionText>
                                In questo caso non puoi passare alla Fase 2, sei “bocciato” ma puoi in ogni caso ritentare.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Il successo di Autostrada, quindi, <span>dipende da te</span> e la quota pagata è considerato un “gettone” per poter avere questa occasione, 
                                ma il gettone NON è rimborsabile in nessun caso... per fare un altro giro di giostra serve un gettone nuovo.
                            </DescriptionText>
                            
                            <DescriptionText>
                                Se hai avuto la fortuna di trovare posto in Autostrada, NON sprecare questa occasione, 
                                IMPEGNATI, dai il massimo, puoi diventare <span>anche TU</span> un iscritto che posta guadagni a 5 cifre sul gruppo...
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ButtonBox>
                        <ButtonShop 
                            PriceProduct={productPrice} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={isDisabled}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                            customLabel={`VOGLIO PRENDERE L'AUTOSTRADA`}
                        />
                    </ButtonBox>

                    <ImageTextBox isReverseDirection={isAppMobile}>
                        <ImageBox>
                            <img src='https://www.viveredipoker.it/CorsoLAutostrada/img/img7.png' alt='Money'/>
                        </ImageBox>

                        <TextBox>
                            <TextDescriptionTitle>
                                COSA STAI ASPETTANDO?
                            </TextDescriptionTitle>
                            
                            <DescriptionText>
                                <span>Liberarti</span> finalmente dal lavoro
                                <ul>
                                    <li><span>Dire addio</span> al trauma giornaliero della sveglia che tutte le mattine tortura le tue orecchie proprio sul più bello</li>
                                    <li>Non vedere più la faccia del tuo capo</li>
                                    <li>Lavorare decine di ore a settimana per uno <span>stipendio da fame</span></li>
                                </ul>
                            </DescriptionText>
                            
                            <DescriptionText>
                                è tutto a un passo da te.
                            </DescriptionText>

                            <DescriptionText>
                                Devi solo decidere che <span>meriti di più</span>, molto di più e che è giunto il momento di cambiare marcia.
                            </DescriptionText>
                        </TextBox>
                    </ImageTextBox>

                    <ImageTextBox isDesktopDirectionColumn>
                        <SimpleText>
                            Clicca sul bottone qui sotto e fiondati a tutta velocità verso la tua nuova vita.
                        </SimpleText>

                        <SimpleText>
                            Io e le altre persone che hanno già seguito il percorso ti aspettiamo dall’altra parte.
                        </SimpleText>

                        <SimpleText>
                            Non perdere altro tempo, la procrastinazione costa cara.
                        </SimpleText>

                        <SimpleText>
                            Il bottone che ti apre la porta verso la <span>libertà</span> è quello che vedi qui sotto.
                        </SimpleText>
                    </ImageTextBox>

                    <ButtonBox>
                        
                        <ButtonShop 
                            PriceProduct={productPrice} 
                            TypeOfProduct={product.type}
                            courseElement={product} 
                            courseID={product.id} 
                            payments={product.paymentTypes}
                            onTransferClick={onTransferClick}
                            isOpenInModal={isOpenInModal}
                            buyInfoModalHandler={buyInfoModalHandler}
                            openBankTransferModal={openBankTransferModal}
                            onLibertyClick={onLibertyClick}
                            libertyInfoModalHandler={libertyInfoModalHandler}
                            openLibertyPaymentModal={openLibertyPaymentModal}
                            isOnPublicProductPage
                            modalGetPaymentID={modalGetPaymentID}
                            productIdHandler = {libertyProductID}
                            productPriceHandler = {libertyProductPrice}
                            loadingOn={loadingOn}
                            loadingOff={loadingOff}
                            openProductRequisitesModal={openProductRequisitesModal}
                            hasRequire={hasRequire}
                            isDisabled={isDisabled}
                            publicInfoPageTransferPrice={publicInfoPageTransferPrice}
                            publicPageTransferPrice={publicPageTransferPrice}
                            vipPoints={vipPoints}
                            customLabel={`VOGLIO PRENDERE L'AUTOSTRADA`}
                        />
                    </ButtonBox>

                </CourseDescriptionContainer>
            </Page>
        </ModalPage>
    );
};

export default Highway;
