import { FC, useState } from 'react';

//components
import FilterShopButtonDesktop from '../../FilterShopButton/layout/FilterShopButtonDesktop/FilterShopButtonDesktop.component';
import { MENU_FILTER } from '../..';

//assets
import { ReactComponent as ArrowCloseSubfilterIconSVG } from '../../assets/arrowCloseSubfilterIcon.svg';

//style
import {
    FilterShopBox,
    ReturnFilterBox
} from './style/FilterShopDesktop.style';


const FilterShopDesktop: FC<{
    changeFilterOpenActive: (text: string) => void,
    changeSubFilterOpenActive: (text: string) => void
    clearContollerHandler: () => void,
    isSubMenuVisible: boolean,
    setIsSubMenuVisible: (value: boolean) => void,
    selectedSubMenuText: string,
    setSelectedSubMenuText: (value: string) => void,
    selectedMenuPosition: number,
    setSelectedMenuPosition: (selectedMenuPosition: number) => void,
    activeButtonClick?: () => void
}> = ({
    changeFilterOpenActive,
    changeSubFilterOpenActive,
    clearContollerHandler,
    isSubMenuVisible,
    setIsSubMenuVisible,
    selectedSubMenuText,
    setSelectedSubMenuText,
    selectedMenuPosition,
    setSelectedMenuPosition,
    activeButtonClick
}) => {

    const filterHandler = (position: number) => {
        
        changeFilterOpenActive(MENU_FILTER[position].text);
        setSelectedMenuPosition(position);
        setIsSubMenuVisible(true);
    };    

    const filterSubCategoryHandler = (subMenuText: string) => {

        changeSubFilterOpenActive(subMenuText);
        setSelectedSubMenuText(subMenuText);
    };

    const fiterClearHandler = () => {

        clearContollerHandler();
        setSelectedMenuPosition(0);
        setSelectedSubMenuText('');
        setIsSubMenuVisible(false);
    };

    return (

        <FilterShopBox>
            {!isSubMenuVisible  && (
            <>
                {MENU_FILTER.map((element: any, index: number) => {
                    
                    if (index === 0) return;
                    return (
                        <FilterShopButtonDesktop 
                            key={"F" + index}
                            position={index}
                            icon={element.icon}
                            text={element.text}
                            onButtonClick={() => filterHandler(index)}
                            isCategory={true}
                        />
                    )
                })}
            </>)
            }

            {isSubMenuVisible && (
                <ReturnFilterBox onClick={() => fiterClearHandler()}>
                    <ArrowCloseSubfilterIconSVG />
                </ReturnFilterBox>
            )}     

            {isSubMenuVisible && (
                MENU_FILTER[selectedMenuPosition].subCategory.map((element: any, index: number) => {
                    
                        return (
                            <FilterShopButtonDesktop 
                            key={"SF" + index}
                            position={index}
                            icon={element.icon}
                            text={element.text}
                            onButtonClick={() => filterSubCategoryHandler(element.text)}
                            isActive={selectedSubMenuText}
                            activeButtonClick={activeButtonClick}
                            isCategory={false}
                            />
                        );
                    }
                )
            )}     
        </FilterShopBox>
    );
};

export default FilterShopDesktop;