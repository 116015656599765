import devices from "style/breakpoints";
import colors from "style/palette";
import styled, { css } from "styled-components";

export const CoachCard = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: 100%;

    padding: 25px 30px 15px;
    gap: 15px;

    background: ${colors.darkGrey};

    /* box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012}
    ; */
    border-radius: 15px;

    border: none;

    @media ${devices.tablet}{     

        padding: 25px 30px 15px 35px;
        gap: 20px;

        max-width: 433px;
    }
`;

export const CoachInfo = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0px;

    @media ${devices.tablet}{       
    
        
    }
`;

export const CoachDetails = styled.p`

    display: flex;
    align-items: center;

    margin: 0px;
    padding:0px;

    font-weight: 500;
    font-size: 20px;

    color: ${colors.primaryColor};

    @media ${devices.tablet}{       
    
        
    }
`;

export const CoachDetailsInfo = styled.p`

    display: flex;
    align-items: center;

    margin: 0px;
    padding:0px;

    font-style: italic;
    font-weight: 500;
    font-size: 15px;

    color: ${colors.white};

    @media ${devices.tablet}{       
        height: 100%;        
    }
`;

export const CoachProfile = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
        
        display: flex;
        align-items: flex-start;
        
        flex-direction: row;

        height: 100%;
    }
`;

export const CoachProfileMobile = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0px;
    gap: 20px;

    @media ${devices.tablet}{       
        
    }
`;

export const CoachPhotoMobile = styled.img`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    flex-shrink: 0;

    width: 100px;
    height: 100px;

    background-color: ${colors.white};
    border-radius: 100px;

    @media ${devices.tablet}{       
        display: none;
    }
`;

export const CoachProfilePhotoBox = styled.div`

    display: flex;

    flex-shrink: 0;

    width: 100px;
    height: 100px;

    border-radius: 50%;

    overflow: hidden;
    position: relative;
`;

export const CoachPhoto = styled.img`

    position: absolute;
    
    width: auto;
    height: 150px;
    margin: auto;

    left: -100%;
    right: -100%;
    top: -60%;
    bottom: -100%;
    
        /* display: none; */

    @media ${devices.tablet}{       
    
        /* display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        flex-shrink: 0;

        width: 100px;
        height: 100px;

        background-color: ${colors.white};
        border-radius: 100px; */
    }
`;

export const CoachDescription = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px;
    gap: 15px;

    @media ${devices.tablet}{       
    
        
    }
`;

export const Text = styled.p`

    margin: 0px;
    padding:0px;

    font-weight: 400;
    font-size: 13px;

    color: ${colors.white};

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    overflow: hidden;

    @media ${devices.tablet}{ }
`;

export const CoachBgrid = styled.div`

    display: flex;
    align-items:  center;
    justify-content: center;
    
    flex-direction: column;
    width: 100%;
    
    padding: 0px;
    
    @media ${devices.tablet}{       
        max-width: 250px;        
    }
`;

export const CoachButton = styled.button<{isWithoutTab?: boolean}>`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 7px 0px 5px;

    width: 100%;
    /* height: 42px; */

    background: ${colors.primaryColor};
    border-radius: 0px 0px 5px 5px;
    border: none;

    cursor: pointer;

    @media ${devices.tablet}{       
    
        
    }

    ${({isWithoutTab}) => isWithoutTab && (

        css`
            border-radius: 5px;
        `
    )}
`;

export const CoachButtonText = styled.p`

    font-weight: 500;
    font-size: 14px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    text-align: center;

    color: ${colors.black087};

    @media ${devices.tablet}{       
    
        
    }
`;

export const CoachPrice = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;

    padding: 0px;
    margin: 7px 0px;
    gap: 10px;

    position: relative;

    @media ${devices.tablet}{ }
`;

export const CoachPaypalPrice = styled.p`

    display: flex;
    flex-direction: row;
    align-items: flex-end;

    margin: 0px;
    padding:0px;
    gap: 2px;

    font-style: italic;
    font-weight: 500;
    font-size: 11px;

    color: ${colors.black};

    @media ${devices.tablet}{       
    
        
    }
`;

export const ButtonChoose = styled.button<{isOpen:boolean}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    padding: 10px 7px 8px;
    gap: 10px;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
    border: none;
    box-shadow: none;

    cursor: pointer;
    
    ${({isOpen}) => isOpen && 
        css` 
            border-radius: 5px 5px 0 0;
        `
    }
`;

export const HelpCards = styled.div`


    @media ${devices.tablet}{       
        
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        max-width: 434px;
        padding: 0px;
        gap: 30px;
    }
`;

export const CoachInfoPayment = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 3px 0px 0px;
    gap: 18px;

    @media ${devices.tablet}{     

        height: 100%;
        justify-content: space-between;
    }
`;

export const NumberLessonBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 0px;
    padding: 0px;

    border-radius: 0;

    button:first-child {
        border-radius: 5px 0 0 0;
    }

    button:last-child {
        border-radius: 0 5px 0 0;
    }

    button:only-child {
        border-radius: 5px 5px 0 0;
    }

`;

export const LessonButtonTab = styled.button<{isActive?: boolean}>`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    /* max-width: 82px; */
    padding: 9px 10px;

    border: none; 
    background: ${colors.grey};
    box-shadow: none;

    font-weight: 500;
    font-size: 12px;
    color: ${colors.white050};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
    cursor: pointer;
    
    ${({isActive}) => isActive && 
        css` 
            background: ${colors.primaryColor};
            color: rgba(0, 0, 0, 0.87);

        `
    }
`;
