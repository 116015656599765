import styled from 'styled-components';
import { Button } from 'style/buttons';
import colors from 'style/palette';

export const OddsScasserFilterContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    max-width: 100%;

`;

export const FiltersContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

`;

export const SportsContainer = styled.div`
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    gap: 3px;
`;

export const SportsLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.grey};
    transition: .3s ease-out all;
    cursor: default;
    height: 36px;
    width: fit-content;
`;

export const Sport = styled.div<{checked: boolean, backgroundColor?: string}>`

    background-color: ${(props) => props.checked ? (props.backgroundColor || '#1b7700') : 'transparent'};
    transition: background-color 0.3s ease;
    width: 28px;
    height: 36px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
`;

export const FeesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
`;

export const DateRangeContainer = styled.div`
    display: flex;
    cursor: pointer;
    height: 36px;
    box-shadow: 0 3px 1px -2px ${colors.black020}, 0 2px 2px ${colors.black014}, 0 1px 5px ${colors.black012};
`;

export const DateRangeLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.grey};
    border-radius: 5px 0 0 5px;
    border: 1px solid ${colors.grey};
    padding: 10px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${colors.white};
    height: 36px;
    width: fit-content;
`;

export const DateRangeLabels = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 36px;
    border: 1px solid ${colors.grey};
    border-right: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const DateLimitLabel = styled.div`
    color: white;
    padding: 0 5px;
    text-align: right;
`;

export const DateRange = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 65px;
    border-radius: 0 5px 5px 0;
    border: 1px solid ${colors.grey};
    border-left: 0;
    background-color: #29353E66;
    font-size: 14px;
`;

export const DateLimit = styled.div`
    color: white;
`;

export const BlacklistContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
`;

export const BlackListTitle = styled.div`
    width: 100%;
    color: ${colors.primaryColor};
    font-weight: 500;
    text-align: center;
    height: 22px;
`;

export const BlacklistElement = styled.div`
    position: relative;
    background-color: ${colors.grey};
    height: 30px;
    padding: 3px;
    border-radius: 5px;
    width: 100%;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const BlacklistElementSelection = styled.div`
    font-size: 13px;
    color: white;
    font-weight: 400;
    width: 75px;
    /* height: 18px; */
    padding: 0 5px;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 2px;
    /* background-color: #305182a8; */
`;

export const BlacklistElementEvent = styled.div`
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    flex-grow: 1;
`;

export const SearchButton = styled(Button)`
    width: calc(100% - 4px);
    height: 32px;
    padding: 0;
`;

export const ResetButton = styled(Button)`   
    position: absolute;
    top: -13px;
    right: 10px;
    width: 50px;
    height: 18px;
    padding: 0;
    font-size: 12px;
    background-color: #b3b3b3;
    ::after{
        content: 'Reset';
    }
`;

export const EventAndOrderContainer = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    @media (max-width: 540px) {
        flex-direction: column;
    };
`;

export const OrderChoiceContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 2px;
    height: 36px;
`;

export const OrderChoiceLabel = styled.div`
    color: white;
    font-size: 13px;
    white-space: nowrap;
`;
