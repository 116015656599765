import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Page = styled.div`

    min-height: inherit;
    height: 100%;

    padding: 40px 25px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    > svg:first-child {

        flex-shrink: 0;
    }

    @media ${devices.tablet} {
        
        padding: 40px 40px 20px;
    }
`;

export const PageTitle = styled.h1`

    margin: 20px 0 20px;
    padding: 0;

    font-size: 24px;
    font-weight: 400;

    color: ${colors.white};
`;

export const Content = styled.div`

    padding: 40px;

    @media ${devices.tablet} {

        padding: 40px;
    }
`;

export const SocialButtonsRow = styled.div`

    width: 100%;

    margin: 31.5px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 24px;
    flex-direction: column;

    @media ${devices.tablet} {

        margin: 24px 0 0;
    }
`;

export const NewUserLink = styled.div`

    width: 100%;

    margin: 30px 0 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    > a {
        
        display: inline-block;
        text-decoration: none;
        
        padding: 0 0 6px;

        border: none;
        border-bottom: 1px solid ${colors.white};

        font-size: 15px;
        font-weight: 400;

        color: ${colors.white};
    }

    @media ${devices.tablet} {

        margin: 24px 0 0;
    }
`;
