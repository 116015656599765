import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const ProductMethodPaymentBox = styled.div`

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    position: absolute;

    top: 23px;
    border-radius: 5px 0px 5px 5px;

    width: 100%;

    padding: 14px 0 14px 30px;
    margin: 0;

    gap: 11px;

    background: ${colors.grey};
    box-shadow: 0px 1px 8px -1px rgba(0, 0, 0, 0.4)
`;

export const TimingSubscriptionBox = styled.div<{isActive?: boolean}>`

    margin: 0 0 0 -5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const FilterText = styled.p`

    margin: 0 0 0 8px;
    padding: 0;

    font-weight: 400;
    font-size: 12px;

    background: ${colors.grey};

    color: ${colors.white};

    @media ${devices.tablet} {

    }
`;

export const PaypalMethodBox = styled.div<{isActive?: boolean}>`

    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TransferMethodBox = styled.div<{isActive?: boolean}>`

    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const PaymentMethodText = styled.p<{isActive?: boolean}>`

    margin: 0 0 0 8px;
    padding: 0;

    font-weight: 400;
    font-size: 12px;

    background: ${colors.grey};

    color: ${colors.white};

    ${({isActive}) => isActive && 
        css`
            color: ${colors.primaryColor};
        `
    }
`;