import { useEffect } from 'react';

// hooks
import { useSearchParams } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';

// utils
import config from 'configs/config';
// import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { postFacebookEvent } from '../MarketingLandingPage/services/MarketingLandingPage.services';
import { getEnvironmentBaseUrl } from 'configs/config.helper';

// components
import MediaPlayer from 'components/MediaPlayer';

// assets
import { ReactComponent as FacebookIconSVG } from './assets/facebook-icon.svg';

// styles
import {
    Card,
    CardMedia,
    Desc,
    Frame,
    Page,
    Title,
    Button
} from './style/PixelLandingPage.style';

const _pixel_id = '1691937477942360';
const _conversion_token = 'EAAOKCh1rViQBO8CdH9pRWn439oBM3ZBGwkPrYgCyuVKbF1PTUKqv5u7sX1H0FaZCF3A28aXMxhE2Mk9ZBBtqhNGGl5wXD2UB3FB263NVTXHQkZAJ4Ei6lTpOg66W5jRSjoOrdQUgZA2ShzWzd6JM7SeySCKsErIZA3J60keJzToaLFtdV80WtC9hjCnwrTxVK5TwZDZD';


/** 
 * Facebook Landing Page
 * 
 * @author Oliver Megale
 * 
 * @returns JSX
*/

const PixelLandingPage = () => {

    const [searchParams] = useSearchParams();
    const isFirstRender = useIsFirstRender();

    ReactPixel.init(_pixel_id);
    ReactPixel.pageView();

    // const searchFrom = searchParams.get('from');

    const videoData = {

        src:"https://www.viveredipoker.it/Marketing/Landing_gf/Liberi_dal_lavoro_video_testimonianze2.mp4", 
        type:"video/mp4"
    };

    const facebookClickhandler = async () => {

        // if (searchFrom === '103111111103108101') { // google encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_g", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // };

        // if (searchFrom === '102979910198111111107') { // facebook encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_f", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // }; 
        
        // if (searchFrom === '116105107116111107') { // tiktok encoded result from stringCoding() in supportFunctions.ts
        //     window.open(
        //         "https://rebrand.ly/ldl_t", 
        //         "_blank",
        //         "noreferrer noopener"
        //     ) 
        //     return;
        // }; 
        
        try {
            
            let conversionPayload = {
                data: [
                    {
                        event_name: 'Conversion',
                        event_time: ~~(+new Date() / 1000),
                        user_data: {
                            country: '2ad8a7049d7c5511ac254f5f51fe70a046ebd884729056f0fe57f5160d467153', // ita
                            client_user_agent: navigator.userAgent,
                            em: '74234e98afe7498fb5daf1f36ac2d78acc339464f950703b8c019892f982b90b', // null
                        },
                        event_source_url: `${getEnvironmentBaseUrl('app/lp/f')}`,
                        action_source: 'website',
                    },
                ],
            };

            if (config.environment !== 'production') conversionPayload = {...conversionPayload, ...{ test_event_code: 'Prova conversione link FB LDL' }} ;
            ReactPixel.trackSingle(_pixel_id, 'Conversion');
            await postFacebookEvent(_pixel_id, _conversion_token,conversionPayload);


            setTimeout(() => {

                window.open(
                    "https://rebrand.ly/ldl_n",
                    "_blank",
                    "noreferrer noopener"
                ) 
            }, 2000)    
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    return(
        <Page>
            <Frame>
                <Title>Puoi avere anche tu questi Risultati ogni mese:</Title>

                <CardMedia>
                    <MediaPlayer 
                        media={videoData} 
                        poster='https://www.viveredipoker.it/Marketing/Landing_gf/Video_screen.png'
                        preload='metadata'
                    />
                </CardMedia>
            </Frame>

            <Card>
                <Desc> 
                    I primi guadagni te li regaliamo noi (!)
                </Desc>

                <Button onClick={facebookClickhandler}>
                    <FacebookIconSVG/>
                    ENTRA NEL GRUPPO GRATUITO
                </Button>
                <Desc>
                    Fare 3.000€ al mese da casa è possibile tutti i mesi senza vendere niente, senza dover dare i tuoi soldi a nessuno. Insomma, senza rischio. 
                </Desc>

                <Desc>
                    Lo dimostrano le centinaia di testimonianze che tutti i mesi riceviamo sul nostro gruppo. <br />
                    E proprio per farti toccare da subito con mano, <br />
                    <span>I Primi GUADAGNI te li regaliamo noi (!)</span>
                </Desc>

                <Desc>
                    A differenza di tutti quelli che vendono fuffa online, noi siamo certi di farti ottenere un risultato e vogliamo che tu sia libero di scegliere. 
                </Desc>

                <Desc>
                    Proprio per questo non ti chiediamo i dati, non ti chiediamo una e-mail, non hai nessuno che ti telefona a casa per venderti qualcosa. <br />
                    <span>Noi non abbiamo venditori…</span>
                </Desc>

                <Desc>
                    Entra oggi nel gruppo, verifica tu stesso le testimonianze, fai domande. <br />
                    Sei libero di restare nel gruppo per tutto il tempo che ti serve,  oppure, quando vuoi, sei libero di uscire  senza dover dare spiegazioni a nessuno. 
                </Desc>

                <Desc>
                    <span>Trovi subito due REGALI importanti: </span>
                </Desc>

                <Desc>
                    <i>1) Il corso che ti permette di ottenere i primi guadagni sicuri <br />
                    2) Una chiamata gratuita con un nostro assistente personale. </i>
                </Desc>

                <Desc>
                    Ci vediamo nel gruppo…
                </Desc>
            </Card>
        </Page>
    );
};

export default PixelLandingPage;
