import { FC } from 'react';


//assets 
import { ReactComponent as OpenIconSVG } from 'pages/OddsScasserPage/assets/open-icon.svg';

// types
import { BaseFieldsetProps } from './BaseFieldset.d';

// style
import { Fieldset, Legend, SVGIconContainer } from './style/BaseFieldset.style';


/**
 * BaseField
 * 
 * @author Alessio Grassi
 * 
 * @param legend - name that display on the top left corner inside the border line
 * @param children - the content of the fieldset
 * 
 * @returns JSX
 */


const BaseFieldset: FC<BaseFieldsetProps> = ({ legend, children, padding, fitContent, minWidth, borderColor, legendColor, backgroundColor, openIcon, onOpenClick }) => {

    return (
        <Fieldset padding={padding} fitContent={fitContent} minWidth={minWidth} borderColor={borderColor} backgroundColor={backgroundColor}>
            {legend !== undefined && (
                <Legend color={legendColor}>
                    {legend}{openIcon && <SVGIconContainer size={16}><OpenIconSVG onClick={onOpenClick}/></SVGIconContainer>}
                </Legend>
            )}

            {children}
        </Fieldset>
    );
};

export default BaseFieldset;
