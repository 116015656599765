import { FC, useState, useEffect, Fragment, useMemo } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getItemFromLocalStorage } from 'utils/localStorage';
import { _saveSingle } from 'pages/OddsScasserPage/services/OddsScasserPage.services';

//redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import useAppDispatch from 'hooks/useAppDispatch';

// types
import { OddsMatchCalculatorModalProps } from './OddsMatchCalculatorModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import NumberInput from 'components/ui/NumberInput';

// assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';
import { ReactComponent as ArrowGoBackSVG } from 'pages/OddsScasserPage/assets/arrowGoBack.svg';

// style
import { BaseCard } from 'style/wrappers';
import { Col, Row } from 'style/layout';
import {
    BetsInstructionsContainer,
    CardContent,
    CloseButton,
    Div,
    EventDate,
    EventLabel,
    EventLabelContainer,
    PossibleResults,
    SelectionLabel,
    SaveButton
} from './style/OddsMatchCalculatorModal.style';
import { Bet, BetContainer, CoverInstructionsLabel, CoverInstructionsLabelContainer, CoverInstructionsStrongText, CoverInstructionsText, HorizontalScrollContainer, Rating, Resp, SiteBox, SiteLogo, SiteLogoContainer, WinnigsAndRating, Winnings } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import {  PartiallyMatchedQuestion } from 'pages/OddsScasserPage/OddsScasserCalculatorsStandard/Single/style/Single.style';

//types
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';
import { ResultsProps } from 'pages/OddsScasserPage/OddsScasserCalculatorsStandard/Single/Single.component.d';
import { Site } from 'pages/OddsScasserPage/types/Site';
import { TextInputState } from 'components/ui/TextInputWithLabel/types/TextInputState';
import { SingleObj } from '../../types/SingleObj';

//utils
import { stepIntegers, stepEach, stepRound, stepOdds, calculatePuntaPunta, calculatePuntaBanca, emptyResults, coverSelectionPuntaPunta, formatDateWithYear, stepOddsPP, formatDate } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import TextInputWithLabel from 'components/ui/TextInputWithLabel';

const OddsMatchCalculatorModal: FC<OddsMatchCalculatorModalProps> = ({ mode, oddsMatch, filters, onCloseButtonModal, copyToClipboard }) => {
    const { isAppMobile } = useAppSelector(state => state.ui);
    const { sites, events } = useAppSelector(state => state.oddsScasser);
    const { roles } = useAppSelector(state => state.user);
    
    const dispatch = useAppDispatch();
    const isAdmin: boolean = useMemo(() => roles ? roles.map((role: any) => role.id).includes(31) : false, [roles]);

    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    //NumberInputs
    const [bet, setBet] = useState<NumberInputState>({value: filters.bet});
    const [bonus, setBonus] = useState<NumberInputState>({value: filters.bonus});
    const [refund, setRefund] = useState<NumberInputState>({value: filters.refund});
    const [isFreebet, setIsFreebet] = useState<boolean>(filters.isFreebet);
    const [fees, setFees] = useState<NumberInputState>({value: (oddsMatch.odds[1].site.id === 9 ? getItemFromLocalStorage('feesBetflag') : getItemFromLocalStorage('feesBetfair')) ?? '4.5'});
    const [outcomes, setOutcomes] = useState<NumberInputState>({value: oddsMatch.odds.length.toString()});
    const [round, setRound] = useState<NumberInputState>({value: mode==='puntabanca' ? '0.01' : '1'});
    const [odds, setOdds] = useState<NumberInputState[]>(oddsMatch.odds.map(x => ({value: x.odds.toFixed(2)})));
    //Partially matched
    const [isPartiallyMatched, setIsPartiallyMatched] = useState<boolean>(false);
    const [matched, setMatched] = useState<NumberInputState>({value: ''});
    const [newOdds, setNewOdds] = useState<NumberInputState>({value: ''});
    //Save Single
    const [description, setDescription] = useState<TextInputState>({value: ''});
    const [id, setId] = useState<number|null>(null); 

    const handleOddsChange = (index: number, newState: NumberInputState) => {
        setOdds(prevState => prevState.map((x,i) => i===index ? newState : x));
    }
    
    useEffect(() => {
        if(isFreebet && !bet.errorMessage) {
            setRefund({value: bet.value});
            setBonus({value: '0'});
        }
    }, [bet, isFreebet]);

    const handleIsFreebetChange = () => {
        setIsFreebet(prevState => {
            if(prevState) setRefund({value: filters.refund});
            return !prevState;
        })
    }

    const results: ResultsProps = useMemo(() => {
        if(mode === 'puntapunta') {
            if(bet.errorMessage || parseFloat(bet.value)<0.01 || bonus.errorMessage || refund.errorMessage || outcomes.errorMessage || round.errorMessage || odds.some(((x, i) => i<parseInt(outcomes.value) && x.errorMessage))) return emptyResults;
            else return calculatePuntaPunta(parseFloat(bet.value), isFreebet ? 0 : parseFloat(bonus.value) || 0, parseFloat(isFreebet ? bet.value : refund.value) || 0, odds.slice(0, parseInt(outcomes.value)).map(x => parseFloat(x.value)), parseFloat(round.value));
        } else {
            if(bet.errorMessage || parseFloat(bet.value)<0.01 || bonus.errorMessage || refund.errorMessage || fees.errorMessage || round.errorMessage || odds.some(((x, i) => i<2 && x.errorMessage))) return emptyResults;// || (isPartiallyMatched && (matched.errorMessage || newOdds.errorMessage))
            return calculatePuntaBanca(parseFloat(bet.value), isFreebet ? 0 : parseFloat(bonus.value) || 0, parseFloat(isFreebet ? bet.value : refund.value) || 0, parseFloat(fees.value)/100, odds.slice(0, 2).map(x => parseFloat(x.value)), parseFloat(round.value), isPartiallyMatched && !matched.errorMessage ? parseFloat(matched.value) : undefined, isPartiallyMatched && !newOdds.errorMessage ? parseFloat(newOdds.value) : undefined);
        }
    }, [mode, bet, bonus, refund, isFreebet, outcomes, fees, round, odds, isPartiallyMatched, matched, newOdds])

    const oddsMatchSites: (Site|undefined)[]|undefined = useMemo(() => {
        if(!oddsMatch || sites.size === 0) return;
        return oddsMatch.odds.map(x => sites.get(x.site.id));
    }, [oddsMatch, sites]);

    const oddsMatchEventLabel: string[]|undefined = useMemo(() => {
        if(!oddsMatch || sites.size === 0) return;
        const event = events.get(oddsMatch.event.id);
        if(!event) return;
        const home = event.home?.name;
        const away = event.away?.name;
        const league = event.league;
        if(!league) return;
        const country = league?.country;
        const leagueName = league?.name;
        if(!home || !away || !country || !leagueName) return;
        return [formatDateWithYear(event.datetime ?? ''), `${home} - ${away} (${country}, ${leagueName})`]
    }, [oddsMatch, events]);

    const save = async () => {
        if(bet.errorMessage || bonus.errorMessage || refund.errorMessage) return dispatch(addSnackbar({ type: 'error', message: 'Sono presenti degli errori o dei dati mancanti'}));
        const single: SingleObj = {id, description: description.value, data: {description, result: null, bet, bonus, refund, isFreebet, oddsMatch: {...oddsMatch, event: events.get(oddsMatch.event.id) ?? oddsMatch.event}}};
        if(single.description.length === 0) {
            single.description = 'Singola '+formatDate(new Date());
            single.data.description = {value: single.description};
            setDescription({value: single.description});
        }
        const _id = await _saveSingle(single) as number;
        if(!_id) return dispatch(addSnackbar({ type: 'error', message: 'Errore nel salvataggio della singola'}));
        setId(_id);
        dispatch(addSnackbar({ type: 'success', message: 'Singola salvata'}));
    }

    const rowGap = '2px';
    const columnGap = '2px';
    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive={true}
            modalFixedStart={true}
            width='auto'
            paddingBottom='80px'
        >
            <BaseCard flavor='outlined'  height='fit-content' isModal>
                <CardContent>
                    <Row gap='10px' crossAxis='center' mainAxis='flex-end'>
                        {oddsMatchEventLabel ? <EventLabelContainer><EventDate>{oddsMatchEventLabel[0]}</EventDate><EventLabel>{oddsMatchEventLabel[1]}</EventLabel></EventLabelContainer> : <EventLabelContainer/>}
                        <CloseButton onClick={() => setIsCloseButtonClicked(true)}><CloseSVG /></CloseButton>
                    </Row>
                    <Col gap='0px' width='100%'>
                        <Row gap='5px' mainAxis='center' crossAxis='flex-start' flexWrap='wrap'>
                            <Col gap='5px' mainAxis='center'>
                                <NumberInput label='Puntata' value={bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBet(newState)}/>
                                <NumberInput label='di cui Bonus' value={isFreebet ? bet.value : bonus.value} isDisabled={isFreebet} minValue={0} maxValue={parseFloat(bet.value) || 0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBonus(newState)}/>
                            </Col>
                            <Col gap='5px' mainAxis='center'>
                                {!isFreebet && <NumberInput label='Rimborso' value={refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} isDisabled={isFreebet} isDisabledOpacity={0} onChange={(newState: NumberInputState) => setRefund(newState)}/>}
                                <SwitchSelector isFilterActive={isFreebet} width={150} height={36} filterHandler={handleIsFreebetChange} label="Freebet"/>
                            </Col>
                            <Col gap='5px' mainAxis='center'>
                                {mode === 'puntabanca' && <NumberInput label='Commissioni' value={fees.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFees(newState)}/>}
                                {/* {mode === 'puntapunta' && <NumberInput label='# Esiti' value={outcomes.value} minValue={2} maxValue={9} includeLimits={true} stepFunction={stepIntegers} noDecimals={true} onChange={(newState: NumberInputState) => setOutcomes(newState)}/>} */}
                                <NumberInput label='Arrotonda a' value={round.value} minValue={0.01} includeLimits={true} stepFunction={stepRound(mode === 'puntabanca' ? [0.01, 0.5, 1] : [1, 5, 10])} onChange={(newState: NumberInputState) => setRound(newState)}/>
                            </Col>
                        </Row>
                        {oddsMatchSites &&
                            <BetsInstructionsContainer>
                                <Col width='100%'>
                                    <Row gap={rowGap}>
                                        <Col gap={columnGap} width='60px'>{/*Bookmakers*/}
                                            <Div height='16px'/>
                                            {oddsMatchSites.map((site,i) => 
                                                <SiteBox
                                                    key={i} 
                                                    checked={!!site} 
                                                    name={site?.name ?? ''} 
                                                    width={60} height={36}
                                                    backgroundColor={!site ? '#000000a3' : undefined}
                                                    onClick={() => site && oddsMatch.odds[i].url && copyToClipboard && copyToClipboard(oddsMatch.odds[i].url ?? null, `URL ${site.name} copiato`)}
                                                >
                                                    {site && <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo width={'95%'} imageUrl={site.imageUrl}/></SiteLogoContainer>}
                                                </SiteBox>
                                            )}
                                        </Col>
                                        <Col gap={columnGap} width='60px'>{/*Odds*/}
                                            <Div height='16px'>Quota</Div>
                                            {odds.map((x,i) => 
                                                <NumberInput
                                                    key={'o'+i} 
                                                    value={x.value} 
                                                    minValue={1} includeLimits={false} decimals={2} stepFunction={(i===1 && mode==='puntabanca') ? stepOdds : stepOddsPP} inputWidth={60} 
                                                    onChange={(newState: NumberInputState) => handleOddsChange(i, newState)}
                                                />
                                            )}
                                        </Col>
                                        <Col gap={columnGap} width='calc(100% - 124px)'>{/*Instructions*/}
                                            <Div height='16px'/>
                                            {odds.map((x,i) => 
                                                <CoverInstructionsLabelContainer key={'i'+i}>
                                                    <HorizontalScrollContainer hiddenScrollBar={true}>
                                                        <CoverInstructionsLabel>
                                                            <CoverInstructionsText>{(mode==='puntabanca' && i>0) ? 'Banca' : 'Punta'}</CoverInstructionsText>
                                                            <BetContainer>
                                                                <Bet translateY={(mode==='puntabanca' && i>0) ? 5 : 0}>{'€'+(results.bets.length>i ? results.bets[i].toFixed((mode==='puntabanca' && i>0) ? 2 : 0) : '-')}</Bet>
                                                                {(mode==='puntabanca' && i>0 && results.resp) && <Resp title='Responsabilità' translateY={1}>{'€'+(results.resp.length>i ? results.resp[i].toFixed(2) : '-')}</Resp>}
                                                            </BetContainer>
                                                            <CoverInstructionsText>esito</CoverInstructionsText>
                                                            <CoverInstructionsStrongText>{oddsMatch.odds[i].type}</CoverInstructionsStrongText> 
                                                            {!isAppMobile &&
                                                                <>
                                                                    <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                    <CoverInstructionsStrongText>{parseFloat(x.value).toFixed(2)}</CoverInstructionsStrongText>
                                                                </>
                                                            }
                                                        </CoverInstructionsLabel>
                                                    </HorizontalScrollContainer>
                                                </CoverInstructionsLabelContainer>
                                            )}
                                        </Col>
                                    </Row>
                                    {mode === 'puntabanca' && 
                                        <Col gap={columnGap} width='100%' margin='10px 0 0 0'>
                                            <Row>
                                                <PartiallyMatchedQuestion onClick={() => setIsPartiallyMatched(prevState => !prevState)}>{isPartiallyMatched ? <ArrowGoBackSVG /> :'Bancata parzialmente abbinata?'}</PartiallyMatchedQuestion>
                                                {isPartiallyMatched && <NumberInput label='Abbinata' value={matched.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setMatched(newState)}/>}
                                            </Row>
                                            {
                                                isPartiallyMatched &&
                                                <Row gap={rowGap} margin='0 0 4px 0'>
                                                    <SiteBox
                                                        checked={!!oddsMatchSites[1]} 
                                                        name={oddsMatchSites[1]?.name ?? ''} 
                                                        width={60} height={36}
                                                        backgroundColor={!oddsMatchSites[1] ? '#000000a3' : undefined}
                                                        onClick={() => oddsMatchSites[1] && oddsMatch.odds[1].url && copyToClipboard && copyToClipboard(oddsMatch.odds[1].url ?? null, `URL ${oddsMatchSites[1].name} copiato`)}
                                                    >
                                                        {oddsMatchSites[1] && <SiteLogoContainer visible={true} backgroundColor={oddsMatchSites[1].color}><SiteLogo width={'95%'} imageUrl={oddsMatchSites[1].imageUrl}/></SiteLogoContainer>}
                                                    </SiteBox>
                                                    <NumberInput
                                                        value={newOdds.value} 
                                                        minValue={1} includeLimits={false} decimals={2} stepFunction={stepOdds} inputWidth={60} 
                                                        onChange={(newState: NumberInputState) => setNewOdds(newState)}
                                                    />
                                                    <CoverInstructionsLabelContainer>
                                                        <HorizontalScrollContainer hiddenScrollBar={true}>
                                                            <CoverInstructionsLabel>
                                                                <CoverInstructionsText>Banca</CoverInstructionsText>
                                                                <BetContainer>
                                                                    <Bet translateY={5}>{results.bets.length>2 && !matched.errorMessage && !newOdds.errorMessage ? '€'+results.bets[2].toFixed(2) : '€-'}</Bet>
                                                                    <Resp title='Responsabilità' translateY={1}>{results.resp && results.resp.length>2 && !matched.errorMessage && !newOdds.errorMessage ? '€'+results.resp[2].toFixed(2) : '€-'}</Resp>
                                                                </BetContainer>
                                                                <CoverInstructionsText>esito</CoverInstructionsText>
                                                                <CoverInstructionsStrongText>{oddsMatch.odds[1].type}</CoverInstructionsStrongText> 
                                                                {!isAppMobile &&
                                                                    <>
                                                                        <CoverInstructionsText>a quota</CoverInstructionsText>
                                                                        <CoverInstructionsStrongText>{newOdds.errorMessage ? '-' : parseFloat(newOdds.value).toFixed(2)}</CoverInstructionsStrongText>
                                                                    </>
                                                                }
                                                            </CoverInstructionsLabel>
                                                        </HorizontalScrollContainer>
                                                    </CoverInstructionsLabelContainer>
                                                </Row>
                                            }
                                        </Col>
                                    }
                                </Col>
                            </BetsInstructionsContainer>
                        }
                        <PossibleResults>{isAppMobile ? '' : 'Guadagno/Perdita'}
                            {(parseFloat(round.value) || 0)<0.02 && 
                                <WinnigsAndRating flexDirection='column'>
                                    <Winnings amount={results.avgWinnings} size='l'>€{results.bets.length>0 ? results.avgWinnings.toFixed(2) : '-'}</Winnings>
                                    <Rating size='l'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{results.bets.length>0 ? results.avgRating.toFixed(2) : '-'}%</Rating>
                                </WinnigsAndRating>
                            } 
                            {(parseFloat(round.value) || 0)>=0.02 && 
                                <>
                                    {odds.map((x,i) =>
                                        <Col key={'col'+i} gap='5px'>
                                            <SelectionLabel>{(mode==='puntabanca' && i>0) ? coverSelectionPuntaPunta.get(oddsMatch.odds[0].type ?? '') : oddsMatch.odds[i].type}</SelectionLabel>
                                            <WinnigsAndRating flexDirection='column'>
                                                <Winnings amount={results.winnings[i]} size='l'>€{results.bets.length>0 ? results.winnings[i].toFixed(2) : '-'}</Winnings>
                                                <Rating size='l'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{results.bets.length>0 ? results.ratings[i].toFixed(2) : '-'}%</Rating>
                                            </WinnigsAndRating>
                                        </Col>
                                    )}
                                </>
                            }             
                        </PossibleResults>
                        {isAdmin &&
                            <Row gap='5px' margin='10px 0 0 0'>
                                <TextInputWithLabel label='Descrizione' value={description.value} allowEmpty={true} width='100%' labelWidth={90} onChange={(newState: TextInputState) => setDescription(newState)}/>
                                <SaveButton onClick={save}>{id===null ? 'Salva' : 'Aggiorna'}</SaveButton>
                            </Row>
                        }
                    </Col>
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default OddsMatchCalculatorModal;
