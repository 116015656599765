import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const Page = styled.div`
	min-height: inherit;
	height: 100%;
	width: 100%;
	padding: 0px 25px 20px;

	display: flex;
	justify-content: space-evenly;
	gap: 5rem;

	> div {
		flex-grow: 1;
	}
`;

export const PageMobile = styled.div`
	min-height: inherit;
	height: 100%;
	width: 100%;
	padding: 40px 25px 20px;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 1rem;

	> div {
		flex-grow: 1;
	}
`;

export const PageTitle = styled.h1`
	width: 100%;
	color: ${colors.primaryColor};
	font-weight: 300;
	font-variant: small-caps;
	font-size: 1.75rem;
`;

export const ToolsList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	list-style: none;
`;

export const ToolsListItem = styled.li`
	display: flex;
	height: 4rem;
	align-items: center;
`;

export const ToolsListItemLeft = styled.div`
	display: flex;
    align-items: center;
    gap: 0.2rem;
    justify-content: center;
	background-color: ${colors.primaryColor};
	width: 20%;
	border-radius: 0.3rem 0 0 0.3rem;
	height: 100%;
`;

export const ToolsListItemRight = styled.div`
	flex-grow: 5;
    height: 100%;
	font-variant: small-caps;
	background: rgba(255, 255, 255, 0.1);
    font-weight: 300;
    font-size: 1.2rem;

	border-radius:0 0.3rem 0.3rem 0;
	padding: 1rem;
	color: white;
	display: flex;
    flex-direction: column;
    justify-content: center;
`;
