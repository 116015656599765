import { FC, useState, useEffect } from 'react';

// utils
import { getItemFromLocalStorage, setItemToLocalStorage } from 'utils/localStorage';

//components
import Single from './Single/Single.component'
import Multiple from '../OddsScasser/Multiple';
import Rollover from '../OddsScasser/Rollover';

//types
import { OddsScasserCalculatorsStandardProps } from './OddsScasserCalculatorsStandard.component.d';

//style
import {CalculatorsContainer} from './style/OddsScasserCalculatorsStandard.style'


const  OddsScasserCalculatorsStandard: FC<OddsScasserCalculatorsStandardProps> = () => {

    const [singleVisible, setSingleVisible] = useState<boolean>(getItemFromLocalStorage('calculatorsSingleVisible')!=='false');
    const [multipleVisible, setMultipleVisible] = useState<boolean>(getItemFromLocalStorage('calculatorsMultipleVisible')!=='false');
    const [rolloverVisible, setRolloverVisible] = useState<boolean>(getItemFromLocalStorage('calculatorsRolloverVisible')!=='false');

    useEffect(() => {
        setItemToLocalStorage('calculatorsSingleVisible', singleVisible ? 'true' : 'false');
    }, [singleVisible]);
    useEffect(() => {
        setItemToLocalStorage('calculatorsMultipleVisible', multipleVisible ? 'true' : 'false');
    }, [multipleVisible]);
    useEffect(() => {
        setItemToLocalStorage('calculatorsRolloverVisible', rolloverVisible ? 'true' : 'false');
    }, [rolloverVisible]);

    return(
        <CalculatorsContainer>
            <Single visible={singleVisible} changeVisibility={() => setSingleVisible(prevState => !prevState)}/>
            <Multiple calculators={true} visible={multipleVisible} feesBetfair={getItemFromLocalStorage('feesBetfair') ?? '4.5'} feesBetflag={getItemFromLocalStorage('feesBetflag') ?? '5'} changeVisibility={() => setMultipleVisible(prevState => !prevState)}/>
            <Rollover calculators={true} visible={rolloverVisible} feesBetfair={getItemFromLocalStorage('feesBetfair') ?? '4.5'} feesBetflag={getItemFromLocalStorage('feesBetflag') ?? '5'} changeVisibility={() => setRolloverVisible(prevState => !prevState)}/>
        </CalculatorsContainer>
        
    );
}

export default OddsScasserCalculatorsStandard;