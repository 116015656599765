import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { removeItemFromLocalStorage } from 'utils/localStorage';

// types
import { BankTransferModalProps } from './BankTransferModal.component.d';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';
import FirstStepBankTransfer from './components/FirstStepBankTransfer/FirstStepBankTransfer.component';
import SecondStepBankTransfer from './components/SecondStepBankTransfer/SecondStepBankTransfer.component';
import ThirdStepBankTransfer from './components/ThirdStepBankTransfer/ThirdStepBankTransfer.component';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as StepOneIconSVG } from './assets/stepOneIcon.svg';
import { ReactComponent as StepTwoIconSVG } from './assets/stepTwoIcon.svg';
import { ReactComponent as StepThreeIconSVG } from './assets/stepThreeIcon.svg';
import { ReactComponent as StepTwoAvtiveIconSVG } from './assets/stepTwoActiveIcon.svg';
import { ReactComponent as StepThreeActiveIconSVG } from './assets/stepThreeActiveIcon.svg';
import { ReactComponent as StepCompleteIconSVG } from './assets/stepCompleteIcon.svg';


// style
import { Row } from 'style/layout';
import {
    CardContent,
    CloseButton,
    TransferStepBox,
    FirstStepTransferBox,
    TextStep,
    SecondStepTransferBox,
    ThirdStepTransferBox,
    Line
} from './style/BankTransferModal.style';


/**
 * Product info modal
 * 
 * @author Frank Berni
 * 
 * @param onCloseButtonModal - handler for closing the modal
 * @param productName - name of the chosen product
 * @param productPrice - price of the chosen product
 * 
 * @returns JSX
 */

const BankTransferModal: FC<BankTransferModalProps> = ({ 
    onCloseButtonModal,
    productName,
    productPrice,
    paymentTransferID,
    isPending
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { tokenID } = useAppSelector(state => state.user);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);
    const [isFirstStepActive, setIsFirstStepActive] = useState<boolean>(false);
    const [isSecondStepActive, setIsSecondStepActive] = useState<boolean>(false);
    const [isThirdStepActive, setIsThirdStepActive] = useState<boolean>(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleFileUpload = () => {
        setFileUploaded(true);
    };

    const isTooLargeFile = () => setFileUploaded(false);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const onClickFirstHandler = () => {
        setIsFirstStepActive(true);
    };

    const onClickSecondHandler = () => {
        setIsSecondStepActive(true);
        setIsThirdStepActive(true);
    };

    const onCloseModalHandler = () => {

        if (isUserLoggedIn && !isFirstStepActive &&  !isThirdStepActive && !isError) {
            removeItemFromLocalStorage("Pending Bank Transfer")
            removeItemFromLocalStorage("Pending transaction ID")
            removeItemFromLocalStorage("Pending purchase ID")
            removeItemFromLocalStorage("New Coach Lesson")
            removeItemFromLocalStorage("Assistente")
        };
        
        if (isError) {
            setIsError(false);
        }

        if (onCloseButtonModal && !isError) onCloseButtonModal();
    }
    
    useEffect(() => {
        if (isPending) {
            setIsFirstStepActive(true)
        }
    },[isPending])

    useEffect(() => {
        if (isThirdStepActive) {
            removeItemFromLocalStorage("Pending purchase ID")
            removeItemFromLocalStorage("Pending transaction ID")
            removeItemFromLocalStorage("Pending Bank Transfer")
            removeItemFromLocalStorage("New Coach Lesson")
            removeItemFromLocalStorage("Assistente")
        }
    },[isThirdStepActive])

    useEffect(() => {
        if (isError) {
            onCloseModalHandler();
        }
    }, [isError]);
    
    const isUserLoggedIn = !!tokenID;
    
    return (
        <BaseModal 
            onClose={onCloseModalHandler}
            isCloseButtonClicked={isCloseButtonClicked}
            modalFixedStart
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}

                        {
                            <>
                                <TransferStepBox>
                                    <FirstStepTransferBox>
                                        {!isFirstStepActive && <StepOneIconSVG />}

                                        {isFirstStepActive && <StepCompleteIconSVG />}

                                        <TextStep> Bonifico</TextStep>
                                    </FirstStepTransferBox>

                                        <Line />

                                    <SecondStepTransferBox>
                                        {!isFirstStepActive && <StepTwoIconSVG />}

                                        {isFirstStepActive && !isSecondStepActive && <StepTwoAvtiveIconSVG />}

                                        {isSecondStepActive && <StepCompleteIconSVG />}
                                        <TextStep> Dati </TextStep>
                                    </SecondStepTransferBox>

                                    <Line />

                                    <ThirdStepTransferBox>
                                       {!isSecondStepActive &&<StepThreeIconSVG />}

                                       {isSecondStepActive && <StepThreeActiveIconSVG />}

                                        <TextStep> Accredito </TextStep>
                                    </ThirdStepTransferBox>
                                </TransferStepBox>

                                {!isFirstStepActive &&<FirstStepBankTransfer firstActive={onClickFirstHandler} productPrice={productPrice} productName={productName} />}

                                {isFirstStepActive &&  !isThirdStepActive && <SecondStepBankTransfer secondActive={onClickSecondHandler} paymentTransferID={paymentTransferID} onFileUpload={handleFileUpload} isTooLargeFile={isTooLargeFile} onError={() => onCloseModalHandler()}/>}
                                
                                {isSecondStepActive && isThirdStepActive && <ThirdStepBankTransfer fileUploaded={fileUploaded} productName={productName}/>}
                            </>
                    }
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default BankTransferModal;
