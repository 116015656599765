import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';

export const InstructionsPlayerCard = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	background: ${colors.darkGrey};
	border-radius: 0.3rem 0.3rem 0 0;
	padding: 1rem;
	position: relative;
`;

export const InstructionsPlayerIcon = styled.div<{ isPlaying: boolean }>`
	background-color: ${(props) =>
		props.isPlaying ? colors.veryDarkGrey : colors.primaryColor};
	width: 3.2rem;
	height: 3rem;
	border-radius: 0.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	> svg path {
		fill: ${(props) =>
			props.isPlaying ? colors.primaryColor : colors.black};
	}
`;

export const InstructionsPlayerTrack = styled.div`
	display: flex;
	flex-direction: column;
	font-variant: small-caps;
	font-weight: 300;
`;

export const InstructionsPlayerTrackTitle = styled.div`
	color: white;
`;

export const InstructionsPlayerTrackAuthor = styled.div`
	color: ${colors.primaryColor};
	font-size: 0.75rem;
`;

export const AudioRange = styled.input<{ value: number; max: number }>`
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 2.5px;
	/* background: #ddd;  */
	outline: none;
	opacity: 1;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0;
	cursor: pointer;

	/* Custom track styling */
	background: linear-gradient(
		to right,
		${colors.primaryColor} ${(props) => (props.value / props.max) * 100}%,
		${colors.darkGrey} ${(props) => (props.value / props.max) * 100}% 100%
	);

	/* Slider Thumb Styles (Chrome, Safari, Edge, Opera) */
	&::-webkit-slider-thumb {
		-webkit-appearance: none; /* Override default appearance */
		appearance: none;
		width: 0; /* Set to 0 to hide the thumb */
		height: 0; /* Set to 0 to hide the thumb */
		background: transparent; /* Make the thumb transparent */
	}

	/* Slider Thumb Styles (Firefox) */
	&::-moz-range-thumb {
		width: 0; /* Set to 0 to hide the thumb */
		height: 0; /* Set to 0 to hide the thumb */
		background: transparent; /* Make the thumb transparent */
	}
`;
