import styled from 'styled-components';
import devices from 'style/breakpoints';


export const FilterShopBox = styled.div`

@media ${devices.tablet} {

    display: flex;
    align-items: stretch;
    justify-content: center;

    width: 100%;

    padding: 0;

    gap: 40px;
}
`;

export const ReturnFilterBox = styled.div`

@media ${devices.tablet} {

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
`;