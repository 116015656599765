import { FC } from 'react';

//types
import { CheckboxesGroupProps } from './CheckboxesGroup.component.d';

//style
import { OptionContainer } from './style/CheckboxesGroup.style'

const  CheckboxesGroup: FC<CheckboxesGroupProps> = ({ options, checked, minChecked, maxChecked, onOptionClick }) => {

    const optionClickHandler = (index: number) => {
        const checkedCount = checked.filter(Boolean).length
        if(minChecked && checked[index] && checkedCount <= minChecked) return;//min already reached -> no change
        if(maxChecked && !checked[index] && checkedCount >= maxChecked) {//max already reached -> uncheck one
            const newChecked = [...checked];
            for (let i = 0; i < checked.length; i++) {
                if(checked[i]) {
                    newChecked[i] = false;
                    break;
                }
            }
            newChecked[index] = true;
            onOptionClick(newChecked);
            return;
        }
        onOptionClick(checked.map((c, i) => i === index ? !c : c));
    }

    return(
        <>
            { options.map((option, index) =>  {
                return(
                    <OptionContainer key={index} onClick={() => optionClickHandler(index)}>{option}</OptionContainer>
                );
            })}
        </>
    );
}

export default CheckboxesGroup;