import styled from "styled-components";
import colors from 'style/palette';
import devices from 'style/breakpoints';

export const Box = styled.div<{width?: string}>`

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${({width}) => width ?? '100%'};
    
    background: transparent;
    border-radius: 15px;
    
    img {

        justify-self: center;
        border-radius: 15px;
        max-width: ${({width}) => width ?? '100%'};

        cursor: pointer;
    }
`;

export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: column;
    gap: 5px;

    width: 100%;
    /* max-width: 640px; */

    height: 100%;

    padding: 10px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    overflow: auto;

    @media ${devices.tablet} {
    
        align-items: center;
        justify-content: center;

        max-width: 1440px;

    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const ImgToEnlarge = styled.img`

    width: 100%;
`;