import styled, { css } from 'styled-components';

// types
import { CircleFrameProps } from './EmptyImage.style.d';

// style
import colors from 'style/palette/palette';


export const CircleFrameRef = styled.div`

    width: auto;
    position: relative;
`;

export const Frame = styled.div<CircleFrameProps>`

    max-width: 180px;

    min-height: 100px;
    max-height: 100px;

    border-radius: 20px;
    border: 1px solid ${colors.primaryColor};
    background: ${colors.lightGrey};
    
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    
    ${({ isCircular }) => isCircular && (

        css`
            min-width: 100px;
            max-width: 100px;

            min-height: 100px;
            max-height: 100px;

            border-radius: 50%;
        `
    )}

    ${({ isProductBox }) => isProductBox && (

        css`
            width: 100%;
            max-width: 100px;

            min-height: 120px;
            max-height: 120px;

            border-radius: 20px;
        `
    )}
`;
