import styled, { css, keyframes } from 'styled-components';

// types
import { SnackBarCardProps } from './SnackBar.style.d';

// style
import colors from 'style/palette/palette';


const _SnackBarFadeInAnimation = keyframes`

    0% {

        opacity: 0;
    }

    100% {

        opacity: 1;
    }
`;

export const SnackBarCard = styled.div<SnackBarCardProps>`

    min-width: 300px;
    width: 111%;
    /* max-width: 500px; */

    margin-bottom: 20px;
    padding: 16px;

    border: none;
    border-radius: 5px;
    background: ${colors.orange};
    color: ${colors.white};

    display: flex;
    align-items: center;

    row-gap: 10px;
    flex-wrap: wrap;

    animation: ${_SnackBarFadeInAnimation} 150ms ease-in-out;

    > img {

        max-width: 20px;
        max-height: 20px;

        margin: 0 14px 0 0;
    }

    ${({ type }) => {

        if (type === undefined) return;
        if (type === 'success') return css`background: ${colors.green};`;
        if (type === 'error') return css`background: ${colors.red};`;
        if (type === 'warning') return css`background: ${colors.orange};`;
        if (type === 'info') return css`background: ${colors.lightBlue};`;
    }}
`;

export const MessageBox = styled.div`

    font-size: 15px;
    font-weight: 400;

    text-align: center;
    color: ${colors.white};
`;

export const CloseButton = styled.button`

    margin: 0 0 0 auto;
    padding: 0 0 10px 20px;

    border: none;
    border-radius: 6px;
    background: ${colors.transparent};

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${colors.white};

    cursor: pointer;
`;
