import { FC } from 'react';

// types
import { TabButtonProps } from './TabButton';

// style
import { Button } from './style/TabButton.style';


/**
 * TabButton
 * 
 * @author Alessio Grassi
 * 
 * @param SVGAsset - asset for the button text
 *  
 * @returns JSX
 */


const TabButton: FC<TabButtonProps> = ({ SVGAsset, onClick, children }) => {

    return (
        <Button onClick={onClick}>
            {SVGAsset}

            {children}
        </Button>
    );
}

export default TabButton;
