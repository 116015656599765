import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const FlowBoxContainer = styled.div`

    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-direction: column;
    /* gap: 1px; */

    width: 100%;

    padding: 0;

    >div:first-child {
        border-radius: 10px 10px 0 0;
    }

    >div:last-child {
        border-radius: 0 0 10px 10px;
    }

@media ${devices.tablet} {
    
}
`;
