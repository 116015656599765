import { AutocompletePossibleValuesProps } from "components/TextInputWithAutocomplete/TextInputWithAutocomplete.component.d";
import { OddsMatchBets } from "./types/OddsMatchBets";

export const coverSelectionPuntaPunta = new Map<string, string>([
    ['1','X2'],['X','12'],['2','1X'],['X2','1'],['12','X'],['1X','2'],
    // ['Under 0.5','Over 0.5'],['Over 0.5','Under 0.5'],
    ['Under 1.5','Over 1.5'],['Over 1.5','Under 1.5'],
    ['Under 2.5','Over 2.5'],['Over 2.5','Under 2.5'],
    ['Under 3.5','Over 3.5'],['Over 3.5','Under 3.5'],
    // ['Under 4.5','Over 4.5'],['Over 4.5','Under 4.5'],
    // ['Under 5.5','Over 5.5'],['Over 5.5','Under 5.5'],
    ['Goal','No Goal'],['No Goal','Goal'],
    ['V1','V2'],['V2','V1']
]);

export const selectionsValues: AutocompletePossibleValuesProps[] = Array.from(coverSelectionPuntaPunta.keys()).map((x,i) => ({text: x, pieces: [x, ...x.split(' ')], key: 'selectionId', value: i}));

export const coverSelectionsTriscasser = new Map<string, string[]>([
    ['1',['X','2']],['X',['1','2']],['2',['1','X']]
]);

export const getResult = (mode: string, selections: string[], home: number, away: number) => {
    let res: string|null = null;
    switch (selections[0]) {
        case '1':
            res = home>away ? 'W' : 'L';
            if(mode==='tri' && res==='L') {
                if(selections[1]==='X') res = home===away ? 'L1' : 'L2';
                else if(selections[2]==='X') res = home===away ? 'L2' : 'L1';
                else res = null;
            }
            break;
        case 'X':
            res = home===away ? 'W' : 'L';
            if(mode==='tri' && res==='L') {
                if(selections[1]==='1') res = home>away ? 'L1' : 'L2';
                else if(selections[2]==='1') res = home===away ? 'L2' : 'L1';
                else res = null;
            }
            break;
        case '2':
            res = home<away ? 'W' : 'L';
            if(mode==='tri' && res==='L') {
                if(selections[1]==='1') res = home>away ? 'L1' : 'L2';
                else if(selections[2]==='1') res = home===away ? 'L2' : 'L1';
                else res = null;
            }
            break;
        case '1X':
            res = home>=away ? 'W' : 'L';
            break;
        case 'X2':
            res = home<=away ? 'W' : 'L';
            break;
        case '12':
            res = home!==away ? 'W' : 'L';
            break;
        case 'Under 1.5':
            res = home+away<2 ? 'W' : 'L';
            break;
        case 'Over 1.5':
            res = home+away>1 ? 'W' : 'L';
            break;
        case 'Under 2.5':
            res = home+away<3 ? 'W' : 'L';
            break;
        case 'Over 2.5':
            res = home+away>2 ? 'W' : 'L';
            break;
        case 'Under 3.5':
            res = home+away<4 ? 'W' : 'L';
            break;
        case 'Over 3.5':
            res = home+away>3 ? 'W' : 'L';
            break;
        case 'Goal':
            res = (home>0 && away>0) ? 'W' : 'L';
            break;
        case 'No Goal':
            res = (home===0 || away===0) ? 'W' : 'L';
            break;
        case 'V1':
            res = home>away ? 'W' : 'L';
            break;
        case 'V2':
            res = home<away ? 'W' : 'L';
            break;
    }
    return res;
}

export const emptyResults: OddsMatchBets = {bets: [], resp: [], winnings: [], ratings: [], avgWinnings: 0, avgRating: 0};

export const calculatePuntaPunta = (bet: number, bonus: number, refund: number, odds: number[], round: number) => {
    if(!odds[odds.length-1]) odds.splice(-1);
    const outcomes = odds.length;
    //Bets
    const bets = Array(outcomes).fill(0);
    bets[0] = bet;
    for(let i = 1; i < outcomes; i++) bets[i] = Math.max(Math.round((bet*odds[0]-refund)/odds[i]/round)*round, Math.max(1, round));
    //Ratings
    const winnings = Array(outcomes).fill(0);
    const ratings = Array(outcomes).fill(0);
    let avgWinnings = 0; 
    const betTot = bets.reduce((a,b) => a+b);
    const prob = odds.map(x => 1/x);
    for(let i = 0; i < outcomes; i++) {
        winnings[i] = odds[i]*bets[i] - betTot + (i>0 ? refund : 0);
        ratings[i] = refund>0 ?  winnings[i]/refund*100 : (1+winnings[i]/bet)*100;
        winnings[i] += bonus;
        avgWinnings += winnings[i]*prob[i];
    }
    avgWinnings = avgWinnings/prob.reduce((a,b) => a+b);
    const avgRating = refund>0 ?  (avgWinnings-bonus)/refund*100 : (1+(avgWinnings-bonus)/bet)*100;
    return {odds, bets, resp: new Array(bets.length).fill(undefined), winnings, ratings, avgWinnings, avgRating} as OddsMatchBets;
}

export const calculatePuntaBanca = (bet: number, bonus: number, refund: number, fees: number, odds: number[], round: number, laid?: number, newOdds?: number) => {
    const partial = laid && newOdds;
    //Bets
    const bets: number[] = Array(3).fill(0);
    bets[0] = bet;
    const toLay = Math.max(Math.round((bet*odds[0]-refund)/(odds[1]-fees)/round)*round, 0);
    bets[1] = partial ? laid : toLay;
    const resp: number[] = Array(3).fill(0);
    resp[0] = bet;
    resp[1] = Math.round(bets[1]*(odds[1]-1)*100)/100;
    odds.push(partial ? newOdds : 1);
    bets[2] = partial ? Math.max(Math.round((bet*odds[0]-refund)*((toLay-laid)/toLay)/(newOdds-fees)/round)*round, 0) : 0;
    resp[2] = partial ? Math.round(bets[2]*(odds[2]-1)*100)/100 : 0;
    //Ratings
    const winnings = Array(3).fill(0);
    const ratings = Array(3).fill(0);
    odds[1] = (odds[1]-1)/(odds[1]-fees);
    odds[2] = (odds[2]-1)/(odds[2]-fees);
    const respTot = resp.reduce((a,b) => a+b);
    const prob = odds.map(x => x>0 ? 1/x : 0);
    winnings[0] = bet*odds[0] - respTot;
    winnings[1] = Math.round(bets[1]*(1-fees)*100)*0.01 + Math.round(bets[2]*(1-fees)*100)*0.01 - bets[0] + refund;
    winnings[2] = winnings[1];
    for(let i = 0; i < 3; i++) {
        ratings[i] = refund>0 ?  winnings[i]/refund*100 : (1+winnings[i]/bet)*100;
        winnings[i] += bonus;
    }
    const avgWinnings = (winnings[0]*prob[0] + winnings[1]*prob[1])/(prob[0] + prob[1]);
    const avgRating = refund>0 ?  (avgWinnings-bonus)/refund*100 : (1+(avgWinnings-bonus)/bet)*100;
    return {odds, bets, resp, winnings, ratings, avgWinnings, avgRating} as OddsMatchBets;    
}

export const getRating = (winnings: number, bet: number, refund: number) => refund>0 ?  winnings/refund*100 : (1+winnings/bet)*100;

export const stepIntegers = (value: number, direction: 'up' | 'down') => {
    return direction === 'up' ? Math.floor(value)+1 : Math.ceil(value)-1;
}

export const stepEach = (step: number, value: number, direction: 'up' | 'down') => {
    return direction === 'up' ? (Math.floor(value/step+0.1)+1)*step : (Math.ceil(value/step-0.1)-1)*step;
}

export const stepRound = (levels: number[]) => (value: number, direction: 'up' | 'down') => {
    value = value + (direction === 'up' ? 0.00001 : -0.00001);
    for (let i = 0; i < levels.length; i++) {
        if(value < levels[i]) return levels[i===0 ? (direction === 'up' ? 0 : levels.length-1) : (direction === 'down' ? i-1 : i)];
    }
    return  levels[direction === 'up' ? 0 : levels.length-1];
}

export const stepOdds = (value: number, direction: 'up' | 'down') => {
    const until = [2, 3, 4, 6, 10, 20, 30, 50, 100, 1000];
    const step = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10];
    value = value + (direction === 'up' ? 0.00001 : -0.00001);
    for (let i = 0; i < until.length; i++) {
        if(value < until[i]) return  direction === 'up' ? (Math.floor(value/step[i])+1)*step[i] : (Math.ceil(value/step[i])-1)*step[i];
    }
    return direction === 'up' ? (Math.floor(value/10)+1)*10 : (Math.ceil(value/10)-1)*10;
}

export const stepOddsPP = (value: number, direction: 'up' | 'down') => {
    return stepEach(0.01, value, direction);
}

export const oddsStepsArray = (max: number) => {
    const until = [2, 3, 4, 6, 10, 20, 30, 50, 100, 1000];
    const step = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10];
    let i = 0;
    let odds = 1;
    const steps = [];
    while(odds<max) {
        steps.push(odds)
        odds += step[i]
        if(odds+0.001>=until[i] && until[i]<1000) i++;
    }
   return steps;
}

export const formatDate = (date: string|Date) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    if(isNaN(d.getDate())) return '';
    return String(d.getDate() || '').padStart(2, '0') + '/' +  String(d.getMonth()+1 || '').padStart(2, '0') + ' ' + String(d.getHours() || '').padStart(2, '0')+ ':' + String(d.getMinutes() || '').padStart(2, '0');
}

export const formatDateWithHour = (date: string|Date) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    return String(d.getDate()).padStart(2, '0') + '/' +  String(d.getMonth()+1).padStart(2, '0') + ' ' + String(d.getHours()).padStart(2, '0');
}

export const formatDateWithYear = (date: string|Date) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    return  String(d.getDate()).padStart(2, '0') + '/' +  String(d.getMonth()+1).padStart(2, '0') + '/'+String(d.getFullYear())+ ' '+ String(d.getHours()).padStart(2, '0') + ':' + String(d.getMinutes()).padStart(2, '0');
}

export const getDate = (date: string|Date) => typeof date === 'string' ? new Date(date) : date;

export const localeStringToUTCString = (date: string) => {
    const d = new Date(date);
    return `${d.getUTCFullYear()}-${(d.getUTCMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}T${d.getUTCHours().toString().padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:00Z`;    
}

export const timestampToUTCString = (timestamp: number) => {
    const d = new Date(timestamp*1000);
    return `${d.getUTCFullYear()}-${(d.getUTCMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}T${d.getUTCHours().toString().padStart(2, '0')}:${d.getUTCMinutes().toString().padStart(2, '0')}:00`    
}

export const resultId: Map<string, number> = new Map<string, number>([['W', 0], ['L', 1], ['L1', 1], ['L2', 2]]);

export const resultComment = (result?: string|null) => {
    switch(result) {
        case 'W':
            return 'Vittoria';
        case 'L':
            return 'Vittoria copertura';
        case 'L1':
            return 'Vittoria prima copertura';
        case 'L2':
            return 'Vittoria seconda copertura';
        case 'C':
            return 'Evento annullato e copertura rimborsata';
        default:
            return;
    }
}

export const datesStepsArrays = (days: number) => {
    const d = new Date();
    const steps = [];
    const stepsValues = [];
    const startDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()).getTime();
    const endDate = startDate + days * 24 * 60 * 60 * 1000;
    let date = startDate;
    while(date<endDate) {
        const value = new Date(date)
        steps.push(formatDateWithHour(value));
        stepsValues.push(value);
        date += 60*60*1000;
    }
    steps[0] = '';
    steps[steps.length-1] = '';
    stepsValues[0] = undefined;
    stepsValues[steps.length-1] = undefined;
   return {steps, stepsValues};
}

export const getSportColor = (sportId: number|undefined) => {
    if(sportId === 1) return '#1b7700';
    if(sportId === 2) return '#bf9a17';
    if(sportId === 3) return '#91311d';
    return '#00386c';
}

