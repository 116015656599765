import styled from "styled-components";
import { Button } from 'style/buttons'
import { BaseCard } from "style/wrappers";
import devices from "style/breakpoints/breakpoints";
import colors from "style/palette/palette";


export const CtaBaseCard = styled(BaseCard)`

    display: flex;
    flex-direction: column;
    flex: 1;
`

export const CtaCardBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 500px;
`;

export const Title = styled.h3`

    font-size: 24px;
    font-weight: 400;
    text-align: left;

    margin: 0 0 20px;

    color: ${colors.white};

    @media ${devices.tablet} {

        padding: 0 0 0 10px;     
        font-size: 28px;
    }
`;

export const CtaCardContent = styled(BaseCard)`

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.5625rem;

    text-align: center;
    color: white;

    width: 100%;
    min-width: 350px;
    padding: 20px ;

    @media ${devices.tablet} {

        /* padding: 19px 78.85px; */
    }

    > svg {

        min-width: 78.21px;
        height: auto;

        @media ${devices.tablet} {
            max-width: 117.31px;
        }
    }
`;

export const CtaCardButton = styled(Button)`

    margin-left: 20px;
    padding: 15px 1.429em;

    font-size: 14px;
    line-height: 13px;

    @media ${devices.tablet} {

        margin-left: 30px;
        padding: 15px 1.429em;
    }
`;
