import { useEffect, useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';

// components
import ThematicDirectModal from 'components/ThematicDirectModal/ThematicDirectModal.component';

// assets
import { ReactComponent as InfoSVG } from '../assets/i.svg';
import { ReactComponent as ClockSVG } from '../assets/time.svg';
import { ReactComponent as ChairSVG } from '../assets/chair.svg';
import { ReactComponent as GrayChairSVG } from '../assets/grayChair.svg';
import { ReactComponent as YellowChairDSVG } from '../assets/yellowChair.svg';
import { ReactComponent as ButtonChairSVG } from '../assets/buttonChair.svg';
import { ReactComponent as ProtagonistSVG } from '../assets/person.svg';
import { ReactComponent as BlackProtagonistSVG } from '../assets/blackProtagonist.svg';
import { ReactComponent as YellowProtagonistSVG } from '../assets/yellowProtagonist.svg';
import { ReactComponent as ArrowTextSVG } from '../assets/arrowText.svg';
import { ReactComponent as BuySVG } from '../assets/ButtonBuy.svg';
import { ReactComponent as PlaySVG } from '../assets/Play.svg';

import { ReactComponent as RegisteredLDLSVG } from '../assets/registeredLDL.svg';
import { ReactComponent as LiveLDLSVG } from '../assets/liveLDL.svg';
import { ReactComponent as LaboratoryLDLSVG } from '../assets/laboratoryLDL.svg';

import { ReactComponent as RegisteredMBSVG } from '../assets/registeredMB.svg';
import { ReactComponent as LiveMBSVG } from '../assets/liveMB.svg';
import { ReactComponent as LaboratoryMBSVG } from '../assets/laboratoryMB.svg';

import { ReactComponent as LibertyIconSVG } from '../assets/libertyPoint.svg';
import { ReactComponent as PaypalIconSVG } from '../assets/paypal.svg';
import { ReactComponent as ArrowDSVG } from '../assets/arrowD.svg';
import { ReactComponent as ReservedDSVG } from '../assets/prenotato.svg';

// styles
import { 
    ButtonComponent, 
    DayTime, 
    DayText, 
    InfoButton, 
    InfoComponent, 
    MainComponent, 
    TitleComponent, 
    TitleText,
    TimeText,
    ButtonBox,
    ButtonSelection,
    MainButton,
    WaiterButton,
    ProtagonistButton,
    ButtonText,
    DescriptionIcon,
    IconText,
    NewButtonShop,
    IconBox,
    ProtagonistButtonForSpectator,
    DirectTvIconLinkBox,
    DirectTvIconBox,
    PlayButton,
    DoubleActionBox,
    NoCategoryBox
} from "./style/NewThematicCall.style";
import ButtonShop from 'components/ButtonShop/ButtonShop.component';
import { Link } from 'react-router-dom';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from "redux/actions/actions-snackbar";
import useAppDispatch from 'hooks/useAppDispatch';
import store from 'redux/store';

interface Product {
    name: string;
    productThematicCall: any;
    liveDate: string;
    subCategories: any;
    owned: boolean;
    categories:any;
    salesData: any;
    delivery: string;
    id: number;
    paymentTypes: any;
    ownedAs: string;
    subscriptionsAsProtagonist: number;
    subscriptionsAsSpectator: number;
    requisitesSatisfied: boolean;
    subscriptions:string;
    authorsNames: any;
    // altri elementi per il componente
}

const NewThematicCall: React.FC<{ 
    product: Product, 
    courseElement: any,
    onTransferClick: (productName: string, productPrice: string, paymentID: number | undefined) => void,
    openBankTransferModal: () => void,
    onLibertyClick: () => void,
    openLibertyPaymentModal: () => void,
    libertyProductID: (courseID: number) => void,
    libertyProductPrice?: (libertyProductPrice: number) => void,
    loadingOn: () => void,
    loadingOff: () => void,
    onThematicTypeChange: any
}> = ({ 
    product, 
    courseElement,
    onTransferClick,
    openBankTransferModal,
    onLibertyClick,
    openLibertyPaymentModal,
    libertyProductID,
    libertyProductPrice,
    loadingOn,
    loadingOff,
    onThematicTypeChange
}) => {

    const { isAppMobile } = useAppSelector(state => state.ui);

    const [isWaiterSelected, setIsWaiterSelected] = useState(true);
    const [isProtagonistSelected, setIsProtagonistSelected] = useState(false);
    const [isUpgradeSelected, setIsUpgradeSelected] = useState(false);
    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const [isModal, setIsModal] = useState <boolean> (false);
    const dispatch = useAppDispatch();

    //const categoryName = product.categories[0]?.name || 'No category';
    const TypeName = product.productThematicCall?.type || 'No Type';

    var liveDate = product.productThematicCall.liveDate;
    var parsedDate = new Date(liveDate);
    var day = parsedDate.getDate().toString().padStart(2, '0');
    var month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    var year = parsedDate.getFullYear();
    var formattedDate = day + '/' + month + '/' + year;

    const formattedTime = `${new Date(liveDate + "Z").getHours() <10 ? '0' + new Date(liveDate + "Z").getHours() : new Date(liveDate + "Z").getHours()}:${new Date(liveDate + "Z").getMinutes() <10 ? '0' + new Date(liveDate + "Z").getMinutes() : new Date(liveDate + "Z").getMinutes()}`;

    const priceSpectator = product.productThematicCall.spectatorPrice || 0;
    const priceProtagonist = product.productThematicCall.protagonistPrice || 0;
    let upgradePrice = product.productThematicCall.protagonistPrice - product.productThematicCall.spectatorPrice || 0;

    if (upgradePrice < 0) {
        upgradePrice = 0;
    }

    const id = product.id;
    const paymentTypes = product.paymentTypes;

    const maxProtagonist = product.productThematicCall.maxProtagonist !== null ? product.productThematicCall.maxProtagonist : Infinity;
    const subscriptionsAsProtagonist = product.subscriptionsAsProtagonist !== null ? product.subscriptionsAsProtagonist : Infinity;
    const remainingProtagonist = maxProtagonist - subscriptionsAsProtagonist;

    const maxSpectator = product.productThematicCall.maxSpectator !== null ? product.productThematicCall.maxSpectator : Infinity;
    const subscriptionsAsSpectator = product.subscriptionsAsSpectator !== null ? product.subscriptionsAsSpectator : Infinity;
    const remainingSpectator = maxSpectator - subscriptionsAsSpectator;

    const handleMouseEnter = () => {
        setIsShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setIsShowTooltip(false);
    };

    const handleClickIconMobile = () => {
        setIsShowTooltip(!isShowTooltip);
    };

    const handleWaiterClick = () => {
        setIsWaiterSelected(true);
        setIsProtagonistSelected(false);
        setIsUpgradeSelected(false)
    };

    const handleProtagonistClick = () => {
        setIsWaiterSelected(false);
        setIsProtagonistSelected(true);
        setIsUpgradeSelected(false)
    };

    const handleUpgradeClick = () => {
        setIsWaiterSelected(false);
        setIsProtagonistSelected(false);
        setIsUpgradeSelected(true)
    };

    const handleInfoButtonClick = () => {
        setIsModal(prevIsModal => !prevIsModal);
    };

    const closeModal = () => {
        setIsModal(false);
    };

    const handleDirectLinkClick = () => {
        if (product.productThematicCall.protagonistLink === null){
            const snackbarData: SnackbarOptions = {
                type: 'warning',
                message: "Il link della diretta non è ancora stato caricato."
            };
            dispatch(addSnackbar(snackbarData));
        }else{ 
            if (product.productThematicCall.protagonistLink) {
                window.open(product.productThematicCall.protagonistLink, '_blank');
            }
        }
    };

    const handleDirectLinkClickSpectator = () => {
        if (product.productThematicCall.spectatorLink === null){
            const snackbarData: SnackbarOptions = {
                type: 'warning',
                message: "Il link della diretta non è ancora stato caricato."
            };
            dispatch(addSnackbar(snackbarData));
        }else{ 
            if (product.productThematicCall.spectatorLink) {
                window.open(`/app/reservedArea/pagina-diretta-tematica/${product.id}`);
            }
        }
    };

    const thematicType = () => {
        if (isWaiterSelected === false && isProtagonistSelected === false && isUpgradeSelected === true) return "UPGRADE"
        if (isWaiterSelected === true && isProtagonistSelected === false && isUpgradeSelected === false) return "SPECTATOR"
        if (isWaiterSelected === false && isProtagonistSelected === true && isUpgradeSelected === false) return "PROTAGONIST"
    }

    useEffect(() => {
        let newThematicType = thematicType();

        onThematicTypeChange(newThematicType); 
    }, [isWaiterSelected, isProtagonistSelected, product]);

    return (
        <>
            <MainComponent>
                <TitleComponent>
                    {TypeName === 'No Type' && <NoCategoryBox></NoCategoryBox>}

                    {TypeName === 'LABORATORIO' && store.getState().ui.brand?.id === 1 && <LaboratoryLDLSVG/>}
                    {TypeName === 'LIVE' && store.getState().ui.brand?.id === 1 && <LiveLDLSVG/>}
                    {TypeName === 'REGISTRAZIONE' && store.getState().ui.brand?.id === 1 && <RegisteredLDLSVG/>}

                    {TypeName === 'LABORATORIO' && store.getState().ui.brand?.id === 4 && <LaboratoryMBSVG/>}
                    {TypeName === 'LIVE' && store.getState().ui.brand?.id === 4 && <LiveMBSVG/>}
                    {TypeName === 'REGISTRAZIONE' && store.getState().ui.brand?.id === 4 && <RegisteredMBSVG/>}

                    {((product.owned === true && (product.ownedAs === "SPECTATOR" || product.ownedAs === "PROTAGONIST") && product.productThematicCall.spectatorLink !== null) ||
                    (product.owned === true && (product.ownedAs !== "SPECTATOR" ||  "PROTAGONIST" || "UPGRADE") && product.productThematicCall.spectatorLink !== null)) &&
                        <Link to={`/reservedArea/pagina-diretta-tematica/${product.id}`} style={{ textDecoration: 'none' }}>
                            <TitleText>{product.name}</TitleText>
                        </Link>
                    }

                    {((product.owned === true && (product.ownedAs === "SPECTATOR" || product.ownedAs === "PROTAGONIST") && product.productThematicCall.spectatorLink === null) ||
                    (product.owned === true && (product.ownedAs !== "SPECTATOR" ||  "PROTAGONIST" || "UPGRADE") && product.productThematicCall.spectatorLink === null) ||
                    (product.owned !== true && product.ownedAs !== "SPECTATOR" && product.ownedAs !==  "PROTAGONIST")) &&
                        <TitleText>{product.name}</TitleText>
                    }

                </TitleComponent>

                <InfoComponent>
                    <DoubleActionBox>
                        <InfoButton onClick={handleInfoButtonClick}>
                            <InfoSVG/>
                        </InfoButton>

                        { product.owned === true && (product.ownedAs === "PROTAGONIST" || product.ownedAs === "UPGRADE") && 
                            <PlayButton onClick={handleDirectLinkClick}>
                                <PlaySVG/>
                            </PlayButton>
                        }

                        { product.owned === true && product.ownedAs === "SPECTATOR" && 
                            <PlayButton onClick={handleDirectLinkClickSpectator}>
                                <PlaySVG/>
                            </PlayButton>
                        }
                    </DoubleActionBox>

                    <DayTime>
                        <DayText>{formattedDate}</DayText>

                        <TimeText>
                            <ClockSVG/>

                            {formattedTime}
                        </TimeText>
                    </DayTime>

                    {!isAppMobile && product.owned === false && (
                        <ChairSVG onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                    )}

                    {!isAppMobile && product.owned === true && product.ownedAs === "SPECTATOR" &&(
                        <YellowChairDSVG onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                    )}

                    {!isAppMobile && product.owned === true && product.ownedAs === "PROTAGONIST" && product.productThematicCall.protagonistLink !== null &&(
                        <IconBox>
                            <YellowProtagonistSVG onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                        </IconBox>
                    )}

                    {!isAppMobile && product.owned === true && product.ownedAs === "PROTAGONIST" && product.productThematicCall.protagonistLink === null &&(
                        <IconBox>
                            <YellowProtagonistSVG onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                        </IconBox>
                    )}

                    {isAppMobile && product.owned === false && (
                        <ChairSVG onClick={handleClickIconMobile}/>
                    )}

                    {isAppMobile && product.owned === true && product.ownedAs === "SPECTATOR" &&(
                        <YellowChairDSVG onClick={handleClickIconMobile}/>
                    )}

                    {isAppMobile && product.owned === true && product.ownedAs === "PROTAGONIST" && product.productThematicCall.protagonistLink !== null &&(
                        <IconBox>
                            <YellowProtagonistSVG onClick={handleClickIconMobile}/>
                        </IconBox>
                    )}

                    {isAppMobile && product.owned === true && product.ownedAs === "PROTAGONIST" && product.productThematicCall.protagonistLink === null &&(
                        <IconBox>
                            <YellowProtagonistSVG onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                        </IconBox>
                    )}

                    {isShowTooltip === true && product.owned === false && (
                        <DescriptionIcon isOwned={product.owned}>
                            <IconText>
                                <p>Non hai ancora un posto</p> 
                            </IconText>

                            <ArrowTextSVG/>
                        </DescriptionIcon>
                    )}

                    {isShowTooltip === true && product.owned === true && product.ownedAs === "SPECTATOR" &&(
                        <DescriptionIcon isOwned={product.owned}>
                            <IconText>
                                <p>Hai un posto spettatore</p> 
                            </IconText>

                            <ArrowTextSVG/>
                        </DescriptionIcon>
                    )}

                    {isShowTooltip === true && product.owned === true && product.ownedAs === "PROTAGONIST" &&(
                        <DescriptionIcon isOwned={product.owned}>
                            <IconText>
                                <p>Hai un posto protagonista</p> 
                            </IconText>

                            <ArrowTextSVG/>
                        </DescriptionIcon>
                    )}
                </InfoComponent>

                {product.owned === false && product.productThematicCall.maxSpectator !== 0 && product.productThematicCall.maxProtagonist !== 0 && remainingSpectator !== 0 && remainingProtagonist !== 0 &&

                    <ButtonComponent>
                        <NewButtonShop>
                            <ButtonSelection>
                                <WaiterButton isSelected={isWaiterSelected} onClick={handleWaiterClick}>
                                    {isWaiterSelected === true && <ButtonChairSVG/>}
                                    {isWaiterSelected !== true && <GrayChairSVG/>}

                                    <p>SPETTATORE</p>
                                </WaiterButton>

                                <ProtagonistButton isSelected={isProtagonistSelected} onClick={handleProtagonistClick}>
                                    {isProtagonistSelected !== true && <ProtagonistSVG/>}
                                    {isProtagonistSelected === true && <BlackProtagonistSVG/>}
                                    
                                    <p>PROTAGONISTA</p>
                                </ProtagonistButton>
                            </ButtonSelection>

                            <ButtonShop 
                                PriceProduct={isProtagonistSelected ? priceProtagonist : priceSpectator} 
                                thematicBroadcastType={thematicType()}
                                TypeOfProduct={'THEMATIC_BROADCAST'} 
                                courseElement={courseElement} 
                                courseID={id} 
                                payments={paymentTypes} 
                                onTransferClick={onTransferClick}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isOnThematicDirect
                            />
                        </NewButtonShop>
                    </ButtonComponent>
                }

                {/* {product.owned === true && product.ownedAs === "SPECTATOR" && 
                    product.productThematicCall.maxSpectator !== 0 && product.productThematicCall.maxProtagonist !== 0 && remainingProtagonist !== 0 &&
                    <ButtonComponent>
                        <NewButtonShop onClick={handleUpgradeClick}>
                            <ButtonSelection>
                                <ProtagonistButtonForSpectator>
                                    <BlackProtagonistSVG/>
                                    
                                    <p>Upgrade a PROTAGONISTA</p>
                                </ProtagonistButtonForSpectator>
                            </ButtonSelection>

                            <ButtonShop 
                                PriceProduct={upgradePrice} 
                                TypeOfProduct={'THEMATIC_BROADCAST'} 
                                thematicBroadcastType={thematicType()}
                                courseElement={courseElement} 
                                courseID={id} 
                                payments={paymentTypes} 
                                onTransferClick={onTransferClick}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isOnThematicDirect
                            />
                        </NewButtonShop>
                    </ButtonComponent>
                } */}

                {((product.owned === false && (product.productThematicCall.maxSpectator === 0 || remainingSpectator === 0) && product.productThematicCall.maxProtagonist !== 0 && remainingProtagonist !== 0)
                 || (product.owned === true && product.ownedAs === "SPECTATOR" && 
                 product.productThematicCall.maxSpectator !== 0 && product.productThematicCall.maxProtagonist !== 0 && remainingProtagonist !== 0) ) && maxProtagonist >= subscriptionsAsProtagonist &&
                    <ButtonComponent>
                        <NewButtonShop onClick={handleProtagonistClick}>
                            <ButtonSelection>
                                <ProtagonistButtonForSpectator>
                                    <BlackProtagonistSVG/>
                                    
                                    <p>PROTAGONISTA</p>
                                </ProtagonistButtonForSpectator>
                            </ButtonSelection>

                            <ButtonShop 
                                PriceProduct={priceProtagonist} 
                                TypeOfProduct={'THEMATIC_BROADCAST'} 
                                thematicBroadcastType={"PROTAGONIST"}
                                courseElement={courseElement} 
                                courseID={id} 
                                payments={paymentTypes} 
                                onTransferClick={onTransferClick}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isOnThematicDirect
                            />
                        </NewButtonShop>
                    </ButtonComponent>
                }

                {product.owned === false && (product.productThematicCall.maxProtagonist === 0 || remainingProtagonist === 0) && product.productThematicCall.maxSpectator !== 0 && remainingSpectator !== 0 && 
                    maxSpectator >= subscriptionsAsSpectator &&
                    <ButtonComponent>
                        <NewButtonShop onClick={handleWaiterClick}>
                            <ButtonSelection>
                                <ProtagonistButtonForSpectator>
                                    <ButtonChairSVG/>
                                    
                                    <p>SPETTATORE</p>
                                </ProtagonistButtonForSpectator>
                            </ButtonSelection>

                            <ButtonShop 
                                PriceProduct={priceSpectator} 
                                TypeOfProduct={'THEMATIC_BROADCAST'} 
                                thematicBroadcastType={"SPECTATOR"}
                                courseElement={courseElement} 
                                courseID={id} 
                                payments={paymentTypes} 
                                onTransferClick={onTransferClick}
                                openBankTransferModal={openBankTransferModal}
                                onLibertyClick={onLibertyClick}
                                openLibertyPaymentModal={openLibertyPaymentModal}
                                productIdHandler = {libertyProductID}
                                productPriceHandler = {libertyProductPrice}
                                loadingOn={loadingOn}
                                loadingOff={loadingOff}
                                isOnThematicDirect
                            />
                        </NewButtonShop>
                    </ButtonComponent>
                }

                { product.owned === false && ((maxSpectator !== 0 && remainingSpectator === 0 && maxProtagonist !== 0 && remainingProtagonist === 0) ||
                     (maxProtagonist === 0 && remainingSpectator === 0) || (maxSpectator === 0 && remainingProtagonist === 0) || (( maxProtagonist === 0 && maxSpectator <= subscriptionsAsSpectator) ||( maxSpectator === 0 && maxProtagonist <= subscriptionsAsProtagonist))) && 
                    <ButtonComponent>
                        <ButtonBox>
                            <MainButton isOwned={true}>
                                <ButtonText>SOLD OUT!</ButtonText>
                            </MainButton>
                        </ButtonBox>
                    </ButtonComponent>
                }

                {product.owned === true && product.ownedAs === "SPECTATOR" && 
                    product.productThematicCall.maxSpectator !== 0 && product.productThematicCall.maxProtagonist !== 0 && remainingProtagonist === 0 &&
                        <ButtonComponent>
                            <ButtonBox>
                                <MainButton isOwned={true}>
                                    <ButtonText>{priceSpectator} €</ButtonText>

                                    <BuySVG/>
                                </MainButton>
                            </ButtonBox>
                        </ButtonComponent>
                }


                {((product.owned === true && product.ownedAs === "PROTAGONIST") || (product.owned === true && product.ownedAs === "SPECTATOR" && product.productThematicCall.maxProtagonist === 0)) &&
                    <ButtonComponent>
                        <ButtonBox>
                            <MainButton isOwned={product.owned}>
                                {product.ownedAs !== "SPECTATOR" && <ButtonText>{priceProtagonist} €</ButtonText>}
                                {product.ownedAs === "SPECTATOR" && <ButtonText>{priceSpectator} €</ButtonText>}

                                <BuySVG/>
                            </MainButton>
                        </ButtonBox>
                    </ButtonComponent>
                }

            </MainComponent>

            {isModal && <ThematicDirectModal onCloseButtonModal={closeModal} product={product}/>}
        </>
    );
};

export default NewThematicCall;