import styled, { css } from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const MultipleTreeContainer = styled.div`
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	max-width: 100%;
	cursor: default;
/* 	min-width: 410px; */
`;

export const MultipleTreeUL = styled.ul<{root?: boolean, backgroundColor?: string, border?: string}>`
    padding-left: 10px; 
	position: relative;
	transition: all 0.5s;
	display: flex;
	flex-direction: column;
    justify-content: center;
    ${props => props.root && 
        css`
            margin: 0;
            overflow-x: auto;
	        width: 100%;
            padding-left: 0;
	        padding-bottom: 5px;
            >li:nth-of-type(1) {
                margin: auto;
                padding: 20px;
                background: ${props.backgroundColor ?? '#29353E66'};
                border: ${props.border ?? '1px solid #29353E'};
                border-radius: 5px;
            }
        `
    }
`;

export const MultipleTreeLI = styled.li`
    text-align: center;
	list-style-type: none;
	position: relative;
	padding: 2px 0 2px 10px;
	display: flex;
	transition: all 0.5s;
    ::before{
        content: '';
        position: absolute; left: 0; bottom: 50%;
        border-left: 2px solid black;
        width: 10px; height: 50%;
    }
    ::after{
        content: '';
        position: absolute; left: 0; bottom: 50%;
        border-left: 2px solid black;
        width: 10px; height: 50%;
        bottom: auto; top: 50%;
	    border-top: 2px solid black;
    }
    :only-child {/*Remove space from the top of single children*/
        padding-left: 0;
    }
    :only-child::after, :only-child::before{/*We need to remove left-right connectors from elements without any siblings*/
        display: none;
    }
    :first-child::before, :last-child::after{/*Remove left connector from first child and right connector from last child*/
        border: 0 none;
    }
    :last-child::before{/*Adding back the vertical connector to the last nodes*/
        border-bottom: 2px solid black;
        border-radius: 0 5px;
    }
    :first-child::after{
        border-radius: 5px 0;
    }
    ul::before{/*Adding downward connectors from parents*/
        content: '';
        position: absolute; left: 0; top: 50%;
        border-top: 2px solid black;
        width: 10px; height: 0;
    }
`;

export const MultipleTreeNode = styled.div<{leaf?: boolean, borderColor?: string, isDisabled?: boolean}>`
    position: relative;
	background: rgba(0, 0, 0, 0.33);
	border: ${props => props.borderColor ? '2px solid '+props.borderColor : '2px solid #0000007d'};
	padding: 0px 5px;
	text-decoration: none;
	font-size: 11px;
	align-self: center;
	border-radius: 5px;
	transition: all 0.5s;
	/* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.24); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    opacity: ${props => props.isDisabled ? '0.4' : '1'};
    ${props => props.leaf && 
        css`
            min-width: 50px;
            flex-direction: row;
        `
    }
`;

export const MultipleTreeNodeLabel = styled.span<{color?: string}>`
    text-align: center;
    font-size: 13px;
    /* font-weight: 500; */
    color: ${props => props.color ?? 'white'};
    white-space: nowrap;
`;

// .tree .root {
// 	margin: auto;
// }
// .tree li>div:not(.leaf){
// 	width: fit-content;
// }
// .tree li.opaque>div {
// 	opacity: 0.2;
// }
// .tree li>div span {
//     line-height: 16px;
// }
// .tree li.win>div {
//     background: rgba(0, 0, 0, 0.33);
//     border-color: rgb(0,155,0);
// }
// .tree li.lose>div {
//     background: rgba(0, 0, 0, 0.33);
//     border-color: rgb(155,0,0);
// }
// .multiple-detail {
// 	width: fit-content;
//     background: #111;
//     padding: 10px;
//     border-radius: 10px;
//     display: grid;
//     grid-template-columns: 80px auto;
//     box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.24);
//     position: fixed;
//     opacity: 0;
//     top: -200px;
//     right: 50%;
//     z-index: -1;
//     transition: opacity .25s ease-in-out;
// }
// .leaf:hover .multiple-detail:not(:hover) {	
// 	opacity: 1;
// 	z-index: 2;
// }
// .multiple-detail span {
// 	height: 20px;
// 	line-height: 22px!important;
// }
// .multiple-detail span:nth-of-type(odd) {
// 	font-size: 11px;
//     text-align: right;
//     padding-right: 9px;
// }
// .multiple-detail span:nth-of-type(even) {
// 	font-size: 14px;
//     font-weight: 500;
//     text-align: right;
// }
// .multiple-detail span.total {
// 	font-size: 16px;
// 	font-weight: 500;
// 	border-top: 1px solid white;
// 	margin-top: 3px;
//     padding-top: 3px;
// }