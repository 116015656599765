import styled, { css } from "styled-components";

// types
import { CardTextProps, PathCardProps } from './UserPathCard.style.d'

//style
import devices from 'style/breakpoints';
import colors from "style/palette/palette";
import { BaseCard } from "style/wrappers";
import { Button } from "style/buttons";
import { HomePageLDLButton } from "style/buttons/buttons";


export const PathCardContainer = styled.div<PathCardProps>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    width: 100%;
    
    @media ${devices.tablet} {
        
        width: 325px;

        flex-shrink: 0;

        ${({isSmall}) => isSmall && (

            css`
                width: 225px;
            `
        )}
    }

`;

export const PathCardBox = styled.div<{isDisable?: boolean | undefined}>`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
    flex-grow: 1;

    width: 100%;

    cursor: pointer;
    z-index: 1;
    position: relative;
    
    ${({isDisable}) => isDisable && (

        css`

            cursor: default;
        `
    )}
`;

export const CompletedCard = styled.div<{isActive?: boolean | undefined, isShrunk?: boolean | undefined}>`

    width: 100%;
    max-width: 433.33px;
    height: 100%;

    border: 0.5px solid ${colors.green};
    border-radius: 15px;
    box-shadow:
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
        
    @media ${devices.tablet} {
        max-height: 210px;
    }

    ${({isActive}) => isActive && (

        css`
            background: ${colors.veryDarkGrey};
        `            
    )}
`;

export const PathBaseCard = styled(BaseCard)<{isActive?: boolean | undefined, isShrunk?: boolean | undefined}>`

    ${({isActive}) => isActive && (

        css`
            background: ${colors.veryDarkGrey};
            border: 1px solid ${colors.primaryColor};
        `
    )}

    @media ${devices.tablet} {
        
        display: flex;
        align-items: center;
        justify-content: center;

        flex-grow: 1;

        max-height: 210px;
    }
`

export const Content = styled.div<{isComplete?: boolean | undefined, isDisabled?: boolean | undefined}>`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;

    /* margin: 25px; */
    padding: 15px 15px 20px;
    height: 100%;
    min-height: 150px;

    position: relative;

    @media ${devices.tablet} {

        /* margin: 19.13px 20.08px 41.50px 21px; */
    }

    ${({isComplete}) => isComplete && (

        css`

            justify-content: flex-end;
            height: calc(100% - 60px);

            button {
                border: none;
            }
        `
    )}

    ${({isDisabled}) => isDisabled && (

        css`
            justify-content: center;
        `
    )}
`;

export const CardText = styled(HomePageLDLButton)``;

export const AssetBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: row;

    max-height: 50px;

    margin: 15px 0 0;

    font-size: 50px;
    color: ${colors.white050};

    > svg {
        
        height: 50px;
        max-height: 50px;

        @media ${devices.tablet} {

            height: 70px;
            max-height: 70px;
        }
    }

    @media ${devices.tablet} {

        max-height: 70px;
    }
`;

export const IconRow = styled.div<{isComplete?: boolean | undefined, isDisabled?: boolean | undefined}>`

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;

    padding: 15px;

    position: absolute;
    top: 0;
    left: 0;

    >button {
        margin-left: auto;
    }

    svg:nth-child(2) {
        margin-left: auto;
        z-index: 1000;

    }

    ${({isComplete}) => isComplete && (

        css`
            svg:nth-child(2) path {
                fill: ${colors.green};
                fill-opacity: 1;
            }
        `
    )}

    ${({isDisabled}) => isDisabled && (

        css`
            > svg {
                max-width: 36px;
                max-height: 36px;
            }
        `
    )}
`;

export const InfoButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const StepBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    margin: 25px 0 0;
    padding: 0;
`;

export const StepText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: ${colors.white};
    text-align: center;
`;

export const ChoiceRow = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    gap: 20px;

    width: 100%;
`;

export const ChoiceButton = styled.button<{isActive?: boolean | undefined, isError?: boolean | undefined}>`

    margin: 0;
    padding: 20px;

    width: 100%;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 15px;
    border: none;
    background-color: ${colors.darkGrey};

    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.white};

    cursor: pointer;

    ${({isActive}) => isActive &&
        
        css`

            border: 0.5px solid ${colors.primaryColor};
            
            background-color: ${colors.veryDarkGrey};
            color: ${colors.primaryColor};
        `
    }

    ${({isError}) => isError &&
        
        css`

            border: 0.5px solid ${colors.red};
            
            background-color: ${colors.veryDarkGrey};
            color: ${colors.red};
        `
    }
`;

export const CTAButton = styled.button`

    margin: 0;
    padding: 15px 20px;

    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    border-radius: 5px;
    border: none;
    background-color: ${colors.primaryColor};

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    cursor: pointer;
`;

