import styled, { keyframes, css } from 'styled-components';

// utils
import { sizes } from './LoadingMask.style.helper';

// types
import { 
    LoadingContainerProps,
    LoadingBoxProps,
    AssetBoxProps
} from './LoadingMask.style.d';


const _fromBottomToTopAnimation = keyframes`

    0% {
        
        transform: translateY(0%);
    }

    50% {

        transform: translateY(-45%);
    }

    100% {

        transform: translateY(0%);
    }
`;

const _fullRotationAnimation = keyframes`

    0% {
        
        transform: rotate(0deg);
    }

    100% {

        transform: rotate(360deg);
    }
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`

    display: inline-block;

    width: auto;
    height: auto;

    padding: 0;
    margin: 0;

    overflow: hidden;
    
    ${({ size }) => size && (

        css`
            width: ${sizes[size]?.width ?? "auto"};
            height: ${sizes[size]?.height ?? "auto"};
        `
    )}

    ${({ width }) => width && ( css`width: ${width ?? "auto"};` )}
    ${({ height }) => height && ( css`height: ${height ?? "auto"};` )}
    ${({ paddingTop }) => paddingTop && ( css`padding-top: ${paddingTop ?? "0"};` )}
    ${({ paddingBottom }) => paddingBottom && ( css`padding-bottom: ${paddingBottom ?? "0"};` )}

    ${({ isCenter }) => isCenter && (

        css`
            width: 100%;
            height: 100%;

            display: inline-flex;
            align-items: center;
            justify-content: center;
        `
    )}
`;

export const LoadingBox = styled.div<LoadingBoxProps>`

    width: auto;
    height: auto;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ isBouncing }) => isBouncing && (

        css`
            animation: ${_fromBottomToTopAnimation} 1.2s ease infinite 0.25s;
        `
    )}
`;

export const AssetBox = styled.div<AssetBoxProps>`

    width: auto;
    max-width: 100%;

    height: auto;
    max-height: 100%;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    animation: ${_fullRotationAnimation} 1.2s ease infinite 0.25s;

    > svg {

        min-width: 0;
        width: 100%;
        max-width: 100%;

        min-height: 0;
        height: 100%;
        max-height: 100%;

        ${({ size }) => size && (
    
            css`
                min-width: ${sizes[size]?.width ?? "auto"};
                max-width: ${sizes[size]?.width ?? "none"};
                min-height: ${sizes[size]?.height ?? "auto"};
                max-height: ${sizes[size]?.height ?? "none"};
            `
        )}
    
        ${({ width }) => width && (

            css`
                min-width: ${width ?? "auto"};
                max-width: ${width ?? "auto"};
            `
        )}

        ${({ height }) => height && (

            css`
                min-height: ${height ?? "auto"};
                max-height: ${height ?? "auto"};
            `
        )}
    }
`;
