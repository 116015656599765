import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import { InputProps, StepButtonProps } from './NumberInput.style.d';

export const InputLabelBox = styled.div<InputProps>`
    
    display: flex;
    width: ${(props) => (props.labelWidth || !props.label) && props.inputWidth ? ((props.label ? (props.labelWidth ?? 0) : 0) + props.inputWidth + (props.gap ? props.gap : 0))+'px' : (props.label ? '150px' : '60px')};
    min-width: ${(props) => (props.labelWidth || !props.label) && props.inputWidth ? ((props.label ? (props.labelWidth ?? 0) : 0) + props.inputWidth + (props.gap ? props.gap : 0))+'px' : (props.label ? '150px' : '60px')};
    column-gap: ${(props) => props.gap ? props.gap+'px' : '0px'};
    height: ${(props) => props.height ? props.height+'px' : '36px'};
    border-radius: 5px;
    position: relative;
    transition: .3s ease-out all;
    opacity: ${(props) => props.isDisabled ? (props.isDisabledOpacity ?? '0.75') : '1'};
    pointer-events: ${(props) => props.isDisabled ? 'none' : 'auto'};

`;

export const Label = styled.div<InputProps>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.labelWidth ? props.labelWidth+'px' : (90-(props.gap ? props.gap/2 : 0))+'px'};
    border: ${(props) => props.focus ? '1px solid #202a31' : '1px solid colors.grey'};
    border-radius: ${(props) => props.gap ? '5px' : '5px 0px 0px 5px'};
    font-weight: 400;
    font-size: ${(props) => props.fontSize ? props.fontSize+'px' : '12px'};
    line-height: ${(props) => props.fontSize ? (props.fontSize+1)+'px' : 'normal'};
    text-align: center;
    /* text-transform: uppercase; */
    color: white;
    /* color: ${(props) => props.isInputInvalid ? colors.red : colors.white}; */
    background-color: ${(props) => props.focus && !props.isDisabled ? '#202a31' : colors.grey};
    transition: .3s ease-out all;
    cursor: default;

`;

export const Input = styled.input<InputProps>`

    min-width: 0;
    width: ${(props) => props.inputWidth ? props.inputWidth+'px' : 'calc(100% - '+((props.label ? 90 : 0)-(props.gap ? props.gap/2 : 0))+'px)'};

    font-size: ${(props) => props.fontSize ? props.fontSize+'px' : (props.small ? '14px' : '15px')};
    font-weight: 400;
    letter-spacing: 0.15px;
    text-align: center;

    border: 1px solid ${colors.grey};
    border-radius: ${(props) => props.gap || !props.label ? '5px' : '0px 5px 5px 0px'};
    background: #375e7a;
    color: ${colors.white};
    transition: .3s ease-out all;
    :focus {
        border: 1px solid #202a31;
    }
    ${(props) => props.isInputInvalid && (
        css`
            background: #8F4D53;
        `
    )}
    :focus-visible {
        outline: none;
    }

    :read-only {
        padding: 0;
        color: ${colors.white056};
        cursor: default;
    }

    ::placeholder {
        color: #c3c3c3;
        font-size: 11px;
        transform: ${props => (props.height && props.height<30) ? 'translateY(-1px)' : 'translateY(-3px)'};
    }
`;

export const StepButton = styled.span<StepButtonProps>`
    display: block;
    opacity: ${(props) => !props.isDisabled && (props.hover || props.focus) ? '1' : '0'};
    width: ${(props) => props.small ? '5px' : '7px'};
    height: ${(props) => props.small ? '5px' : '7px'};
    border-top: 1px solid ${colors.black};
    border-left: 1px solid ${colors.black};
    transform: ${({ up }) => up ? 'rotate(135deg)' : 'rotate(-45deg)'};
    position: absolute;
    z-index: 1;
    left: ${(props) => props.up ? 'calc(100% - '+(props.small ? 8 : 12)+'px)' : (props.labelWidth ? props.labelWidth : (props.label ? 90 : 0))+4+'px'};
    top: ${(props) => (props.height && props.height<30) ? '10px' : '14px'};
    cursor: pointer;
    transition: .3s ease-out all;

    :hover {
        border-color: white;
    }
    
`;
