import { FC, ReactElement, SVGProps } from 'react';

//style
import {
    FilterShopSelector,
    FilterText
} from './style/FilterShopButtonDesktop.style';

const FilterShopButtonDesktop: FC<{icon?: ReactElement<SVGProps<SVGElement>>, text: string, isActive?: string, onButtonClick: (position: number) => void, position: number }> = ({icon, text, isActive, onButtonClick, position}) => {

    const onClickSelectorFilterHandler = () => {
        
        onButtonClick(position);
    };

    const isButtunActive = (isActive === text);
    return (
        
        <FilterShopSelector isActive={isButtunActive} onClick={onClickSelectorFilterHandler}>
            {icon}

            <FilterText>{text}</FilterText>

        </FilterShopSelector>
    );
};

export default FilterShopButtonDesktop;