//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import useAppDispatch from 'hooks/useAppDispatch';

// hooks
import useAppSelector from "hooks/useAppSelector";
import { callGET } from "network/network";
import { useEffect, useState } from "react";

//utils
import { removeItemFromLocalStorage } from 'utils/localStorage';

// component
import NewLessonMobile from "./components/NewLessonMobile/NewLessonMobile.component";
import NewLessonDesktop from "./components/NewLessonDesktop/NewLessonDesktop.component";
import EndedLessonMobile from "./components/EndedLessonMobile/EndedLessonMobile.component";
import EndedLessonDesktop from "./components/EndedLessonDesktop/EndedLessonDesktop.component";
import PersonalAssistantNotesModal from "./PersonalAssistantNotesModal/PersonalAssistantNotesModal";
import CustomerRatingModal from "components/CustomerRatingModal/CustomerRatingModal.component";
import LoadingMask from 'components/ui/LoadingMask';

// style
import { 
    ActiveLessons,
    BoxContent, 
    CardBox, 
    EndedLessons, 
    LessonBox,
    TotalPage
} from "./style/LessonPage.styles";
import { getEnvironmentProductId } from 'configs/config.helper';
import { enableAppMain } from 'redux/slices/slice-app-main';
import store from 'redux/store';


const LessonPage = () => {

    const { isAppMobile } = useAppSelector( state => state.ui);

    const [lessonList, setLessonList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [newLesson, setNewLesson] = useState<any | undefined>(undefined);
    const [endedLesson, setEndedLesson] = useState<any | undefined>(undefined);
    const [choicedLesson, setChoicedLesson] = useState<any | undefined>(undefined);
    const [isPersonalAssistantNotesModalVisible, setIsPersonalAssistantNotesModalVisible] = useState<boolean>(false);
    const [isCustomerRatingModalVisible, setIsCustomerRatingModalVisible] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const openPersonalAsistantNotesModal = () => setIsPersonalAssistantNotesModalVisible(true);
    const closePersonalAsistantNotesModal = () => setIsPersonalAssistantNotesModalVisible(false);
    const openCustomerRatingModal = () => setIsCustomerRatingModalVisible(true);
    const closeCustomerRatingModal = () => setIsCustomerRatingModalVisible(false);

    const refreshDataHandler = async () => {
        await getLesson();
        closeCustomerRatingModal();
        dispatch( enableAppMain() );
    };
   
    console.log("ENDED LESSON", endedLesson);
    const getChoicedLesson = (lessonId: number, typeOfLesson: 'new' | 'ended') => {

        let filteredEndedLesson;
        if (typeOfLesson === 'new') filteredEndedLesson = newLesson.filter((lesson: any) => lesson.lesson.id === lessonId);
        if (typeOfLesson === 'ended') filteredEndedLesson = endedLesson.filter((lesson: any) => lesson.lesson.id === lessonId);
        setChoicedLesson(filteredEndedLesson[0]);
    };

    const isNewLesson = () => {
        const newLesson = 'New Coach Lesson' in localStorage || 'New Help' in localStorage;

        if (!newLesson) return;
        const snackbarData: SnackbarOptions = {};
        snackbarData.type = 'success';
        snackbarData.message = "La tua lezione è stata accreditata con Successo!";
        dispatch( addSnackbar(snackbarData) );
        removeItemFromLocalStorage('New Coach Lesson');
        removeItemFromLocalStorage('New Help');
    };

    const getLesson = async () => {

        const lessonParams = {
            brandCode: store.getState().ui.brand?.code,
            types: "LESSON_PACK"
        }

        try {
            setIsLoading(true);
            const response = await callGET('v1/self/productsubscriptions', true, lessonParams); 
            const filterdLessons = response.filter((lesson:any) => lesson.product.name !== "Lezione Aiuto Attivo");
            isNewLesson();
            setLessonList(filterdLessons);
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            console.warn(error);
        }
    };

    const getSeparatedLessons = (lessonsList: any) => {
      
        let separatedLessonList: {newLessons: any[], endedLessons: any[]} = {
            newLessons: [],
            endedLessons: []
        };
        
    //     lessonList.forEach((lesson: any) => {
    //         if (lesson.productLessonPack.lessons[0].status === "TO_DO") return separatedLessonList.newLessons.push(lesson.productLessonPack.lessons[0])  
    //         separatedLessonList.endedLessons.push(lesson.productLessonPack.lessons[0])
    //         console.log("lezioni", lesson);
            
    //     });
        
    //     setNewLesson(separatedLessonList.newLessons);
    //     setEndedLesson(separatedLessonList.endedLessons);
    // };

    for (let i = 0; i < lessonsList.length; i++) {
        const productLessonPack = lessonsList[i].productLessonPack;
        const lessons = productLessonPack.lessons;
        
        for (let j = 0; j < lessons.length; j++) {
          const lesson = lessons[j];
          const lessonObj = {
              expirationDate: lessonList[i].productLessonPack.expirationDate,
              creationDate: lessonList[i].creationDate,
              lesson,
              isFree: lessonList[i].product.free,
              isHighway: (lessonList[i].product.id === getEnvironmentProductId('autostrada')),
              numberOfLessonInPack: lessonList[i].productLessonPack.lessons.length,
              numberOfLesson: j + 1
          }
          if (lesson.status === "CLOSED") {
              separatedLessonList.endedLessons.push(lessonObj);
            } else {
              separatedLessonList.newLessons.push(lessonObj);
          }
        }
      }
            
      setNewLesson(separatedLessonList.newLessons);
      setEndedLesson(separatedLessonList.endedLessons);
    };
    
    useEffect(() => {
        getLesson()
    },[]);

    useEffect(() => {
        getSeparatedLessons(lessonList);
    },[lessonList])
    
    if (isLoading) return (
        <LoadingMask 
            paddingTop='200px'
            isCenter
            size='page'
        />
    );

    console.log("CHOICED", choicedLesson);
    
    
    return (
        <>
            <TotalPage>
                <LessonBox>
                    <CardBox>
                        <BoxContent>
                            <ActiveLessons>
                                {isAppMobile && (
                                    <NewLessonMobile
                                        lesson={newLesson}
                                        choiceLessonHandler={getChoicedLesson}
                                        openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
                                        openCustomerRatingModal={openCustomerRatingModal}
                                        typeOfLesson='new'
                                />)}

                                {!isAppMobile && ( 
                                    <NewLessonDesktop
                                        lesson={newLesson}
                                        choiceLessonHandler={getChoicedLesson}
                                        openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
                                        openCustomerRatingModal={openCustomerRatingModal}
                                        typeOfLesson='new'
                                />)}
                            </ActiveLessons>
                            
                            <EndedLessons>
                                {isAppMobile && ( 
                                    <EndedLessonMobile
                                        lesson={endedLesson}
                                        choiceLessonHandler={getChoicedLesson}
                                        openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
                                        typeOfLesson='ended'
                                />)}

                                {!isAppMobile && ( 
                                    <EndedLessonDesktop
                                        lessons={endedLesson}
                                        choiceLessonHandler={getChoicedLesson}
                                        openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
                                        typeOfLesson='ended'
                                />)}
                            </EndedLessons>
                        </BoxContent>
                    </CardBox>
                </LessonBox>
            </TotalPage>

            {isPersonalAssistantNotesModalVisible && (
                <PersonalAssistantNotesModal 
                    onCloseButtonModal={closePersonalAsistantNotesModal}
                    choicedLesson={choicedLesson.lesson}
                />
            )}

            {isCustomerRatingModalVisible && (
                <CustomerRatingModal 
                    onCloseButtonModal={closeCustomerRatingModal}
                    isHighway={choicedLesson.isHighway}
                    lessonId={choicedLesson.lesson.id}
                    refreshFunction={refreshDataHandler}                
                />
            )}
        </>
    );
};

export default LessonPage;
