import { FC, useEffect, useState } from 'react';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

//utilis
import { updatePhoneNumber } from './services/WhatsAppFlowCard.services';
import { getUserData } from 'services/user.services';

// assets
import { ReactComponent as WhatsappIconSVG } from './asset/whatsappIcon.svg';

// style
import {
    WhatsAppInsertNumberBox,
    WhatsAppInsertNumberBoxContent,
    WhatsAppInsertNumberTextContent,
    WhatsAppInsertNumberText,
    WhatsAppInsertNumberCTABox,
    WhatsAppPhoneNumberPrefixInput,
    WhatsAppPhoneNumberInput,
    WhatsAppNeedCallButton
} from './style/WhatsAppInsertNumberCard.style';

const WhatsAppInsertNumberCard: FC<{}> = () => {
    
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phonePrefix, setPhonePrefix] = useState<string>("+39");
    const dispatch = useAppDispatch();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };

    // const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setPhonePrefix(event.target.value);
    // };

    const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value.length > 0 && value[0] !== '+') {
            value = '+' + value;
        }
        setPhonePrefix(value);
    };

    const handleSubmitPhoneNumber = async () => {
        try {
            const fullNumber = phonePrefix + phoneNumber;
            const response = await updatePhoneNumber(fullNumber);
            console.log('Risposta da updatePhoneNumber:', response);

        } catch (error) {
            console.log('Errore nell\'aggiornare il numero di telefono:', error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(getUserData());
        }, 3000);
    
        return () => clearTimeout(timer);  
    }, [phoneNumber]);

    return (
        <WhatsAppInsertNumberBox>
            <WhatsAppInsertNumberBoxContent>
                <WhatsAppInsertNumberTextContent>
                    <WhatsappIconSVG />

                    <WhatsAppInsertNumberText>
                        Per gestire le call private usiamo Whatsapp, inserisci il tuo numero.
                    </WhatsAppInsertNumberText>
                </WhatsAppInsertNumberTextContent>

                <WhatsAppInsertNumberCTABox>
                    <WhatsAppPhoneNumberPrefixInput
                        value={phonePrefix} 
                        onChange={handlePrefixChange} 
                    />

                    <WhatsAppPhoneNumberInput 
                        value={phoneNumber} 
                        onChange={handleInputChange} 
                        placeholder="" 
                    />
                    <WhatsAppNeedCallButton onClick={handleSubmitPhoneNumber}>INVIA</WhatsAppNeedCallButton>
                </WhatsAppInsertNumberCTABox>
            </WhatsAppInsertNumberBoxContent>
        </WhatsAppInsertNumberBox>
    )
};

export default WhatsAppInsertNumberCard;