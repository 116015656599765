import { callOldPOST, callPUT } from "network/network";

export const closeCurrentLesson = async ( lessonId: number, rating?: number, ratingComments?: string | undefined ) => {

    // try {
    //     const closeLessonPayload = {};
    //     const closeLessonParams = {
    //         idAutostradaLesson: idHighwayLesson,
    //         rating,
    //         ratingComments
    //     };
    //     const closeLessonResponse =  await callOldPOST('autostradalesson/chiudicliente', closeLessonPayload, true, closeLessonParams);
    //     if (closeLessonResponse === undefined || !closeLessonResponse) {
    //         throw new Error ("Close HIghway lesson response is undefined.");
    //     }

        
    // } catch (error: any) {
    //     throw error;
    // }
    try {
        const closeLessonPayload = {};
        const closeLessonParams = {
            rating,
            userPrivateNotes: ratingComments
        };
        await callPUT(`v1/self/productlessonpacks/closelessonuser/${lessonId}`, closeLessonPayload, true, closeLessonParams);
        // if (closeLessonResponse === undefined || !closeLessonResponse) {
        //     throw new Error ("Close HIghway lesson response is undefined.");
        // }

        
    } catch (error: any) {
        throw error;
    }
};

export const closeCurrentSingleLesson = async ( idLezioneRegaloAP: number ) => {

    try {
        const closeLessonPayload = null;
        const closeLessonParams = { idLezioneRegaloAP, rating: 5, ratingComments: "none" };
        const closeLessonResponse =  await callOldPOST('lezioneRegaloAP/chiudicliente', closeLessonPayload, true, closeLessonParams);
        if (closeLessonResponse === undefined || !closeLessonResponse) {
            throw new Error ("Close HIghway lesson response is undefined.");
        }

    } catch (error: any) {
        throw error;
    }
};