import { FC } from "react";

// assets
import { ReactComponent as DownloadSVG } from '../../../assets/download-icon.svg';

//style
import {
    DescriptionBox,
    AttachmentLink,
    LinkAttachment,
} from './style/DescriptionTabDesktop.style';


const DescriptionTabDesktop: FC<{courseItem?: any}> = ({courseItem}) => {

    let lesson;
    let attachments = null;

    if (courseItem !== undefined) {
    lesson = courseItem.lesson ?? null;
        if ( lesson !== undefined && lesson !== null){
        attachments = lesson.attachments ?? null;
        }
    }

    const buttonClickHandler = (elementUrl: string) => {
        window.open(
            elementUrl,
            '_blank',
            'noreferrer noopener'
        );
    };

    return (
        <DescriptionBox>
            {attachments !== null && 
                attachments.map((element:any, index:number) => {
                    return (<AttachmentLink key={index}>
                                <LinkAttachment onClick={() => buttonClickHandler(element.url)} >
                                    <span>{element.fileName}</span>
                                    
                                    <DownloadSVG />
                                </LinkAttachment>
                            </AttachmentLink>)
                })
            }
        </DescriptionBox>
    );
};


export default DescriptionTabDesktop;