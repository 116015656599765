import devices from 'style/breakpoints';
import colors from 'style/palette/palette';
import styled, { css } from 'styled-components';


export const Totalpage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    padding: 35px 20px;
`;

export const MainTitle = styled.h1`
    font-weight: 400;
    font-size: 20px;

    display: flex;
    align-items: flex-start;
    width: 100%;

    margin: 0px;
    padding: 0px 0px 13px 10px;

    color: ${colors.white};
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
    gap: 27px;
    width: 100%;

    background: ${colors.darkGrey};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};

    border-radius: 15px;
`;

export const TitleCard = styled.h2`
    font-weight: 500;
    font-size: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    margin: 0px;
    padding: 0px;

    color: ${colors.primaryColor};
`;

export const MainText = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
`;

export const InfoText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;

    margin: 0;
    padding: 0px;

    color: ${colors.white};
    @media ${devices.tablet} {

        margin: 0 0 17px 0;
    }
`;

export const ThanksText = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;

    margin: 0px;
    padding: 0px;

    color: ${colors.blueGrey};
`;

export const ButtonPointContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 0px;
    gap: 20px;
`;

export const ButtonPoint = styled.button`
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: none;

    border: 1px solid ${colors.white};
    border-radius: 5px;

    gap: 3px;

    font-weight: 500;
    font-size: 14px;
    color: ${colors.white};

    width: 85px;
    height: 42px;

    :focus{ 
        background: linear-gradient(180deg, #464A4F 0%, #1C2023 100%);
    }

    &.selected {
        background: linear-gradient(180deg, #464A4F 0%, #1C2023 100%);
        color: ${colors.white};
    }
`;

export const PointCounterCard = styled.h3`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 0px;
    margin: 0 70px 0 0;
    gap: 5px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
`;

export const PointCounter = styled.div`
    font-weight: 500;
    font-size: 40px;

    display: flex;
    align-items: center;
    text-align: center;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};
`;

export const PointCounterChange = styled.input`
    font-weight: 500;
    font-size: 40px;

    display: flex;
    align-items: center;
    text-align: right;

    margin: 0px;
    padding: 0px;

    color: ${colors.white};

    border: none;
    border-radius: 5px;
    background-color: ${colors.transparent};
    width: 150px;

    
    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const ButtonShopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 0px;
    gap: 7px;
`;

export const ButtonShop2 = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    background: ${colors.primaryColor};

    box-shadow: 
        0px 3px 1px -2px ${colors.black020}, 
        0px 2px 2px ${colors.black014}, 
        0px 1px 5px ${colors.black012};
    border-radius: 5px;

    border: none;
`;

export const ButtonPrice = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 3px;

    position: relative;
`;

export const ButtonShopText = styled.p`
    font-weight: 500;
    font-size: 11px;

    margin: 0px;
    padding: 0px;

    display: flex;
    align-items: center;
    justify-self: flex-end;

    color: rgba(0, 0, 0, 0.87);
`;

export const ButtonShopArrow = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 7px 8px;
    gap: 10px;

    border: none;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
`;

export const ButtonChoose = styled.button<{isOpen:boolean}>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    padding: 10px 7px 8px;
    gap: 10px;

    width: 24px;
    height: 23px;

    background: ${colors.grey};
    border-radius: 5px;
    border: none;
    box-shadow: none;

    cursor: pointer;
    
    ${({isOpen}) => isOpen && 
        css` 
            border-radius: 5px 5px 0 0;


        `
    }
`;

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    padding: 0 20px 25px;

    @media ${devices.tablet} {
        width: 100%;

        padding: 30px 40px;
    }
`;

export const Title = styled.h1`

    width: 100%;
    margin: 25px 0 0;
    padding: 0 0 0 10px;

    font-size: 20px;
    font-weight: 400;
    color: ${colors.white};
    text-align: left;

    svg {
        margin: 8px 0 0 15px;
        cursor: pointer;
    }

    @media ${devices.tablet} {

        font-size: 28px;
    }
`;

export const FirstRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;
        gap: 40px;

        margin: 25px 0 0;
    }
`;

export const VideoBox = styled.div`

    width: 100%;
    height: 219px;

    margin: 0;
    background: ${colors.black};

    img {
            height: inherit;
            width: inherit;
        }

    @media ${devices.tablet} {
        
        /* width: 100%; */
        width: 630px;
        max-width: 630px;
        height: 360px;

        margin: 0;

        border-radius: 15px;

        > div {
            height: inherit;
            border-radius: 15px !important;

            > div {
                background: none;
            }
        }

        video {
            height: inherit;
            border-radius: 15px !important;
        }

        img {
            height: inherit;
            width: inherit;
            border-radius: 15px !important;
        }
    }
`;

export const DescriptionBox = styled.div`

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 27px;

    margin: 20px 0 0;
    padding: 30px 25px;

    background: ${colors.darkGrey};
    color: ${colors.white};

    border-radius: 15px 15px 0 0;

    @media ${devices.tablet} {

        justify-content: space-between;
        gap: unset;
        
        width: 690px;
        max-width: 690px;
        height: 360px;

        margin: 0;
        padding: 35px 25px;

        border-radius: 15px;
    }

`;

export const DescriptionTitle = styled.h3`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 500;
    color: ${colors.primaryColor};
    text-transform: uppercase;
    text-align: left;
`;

export const TextDescriptionTitle = styled.h3`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 22px;
    font-weight: 400;
    color: ${colors.primaryColor};
    text-transform: uppercase;
    text-align: center;
`;


export const ImportantPhrase = styled.p`

    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    text-align: left;

    span {
        font-weight: 600;
    }
`;

export const BulletBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 15px;
`;

export const BulletRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {
        flex-shrink: 0;
        margin: 0 10px 0 0;
    }
`;

export const BulletPoint = styled.p`

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-style: italic;
    font-weight: 400;
`;

export const CourseDescriptionContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    padding: 30px 25px;

    background: ${colors.darkGrey};
    color: ${colors.white};
    border-radius: 0 0 15px 15px;

    @media ${devices.tablet} {
        width: 100%;

        margin: 40px 0 0;
        padding: 35px;

        border-radius: 15px;
    }

`;

export const FocusPointsBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    /* gap: 30px; */

    > div:nth-child(2) {

        padding: 0 30px;

        border-top: 1px solid ${colors.veryDarkGrey};
        border-bottom: 1px solid ${colors.veryDarkGrey};
    }

    @media ${devices.tablet} {

        justify-content: space-between;
        flex-direction: row;

        > div:nth-child(2) {

            border-top: unset;
            border-bottom: unset;
            border-left: 1px solid ${colors.veryDarkGrey};
            border-right: 1px solid ${colors.veryDarkGrey};
        }

    }

`;

export const FocusPoint = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media ${devices.tablet} {

        flex-grow: 1;
        flex-basis: 100%;

        padding: 0 30px;
    }

`;

export const FocusTitle = styled.h5`

    margin: 0;
    padding: 0;

    font-size: 24px;
    font-weight: 300;
    color: ${colors.white050};
`;

export const FocusDescription = styled.p`

    margin: 20px 0 0;
    padding: 0;

    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    text-align: center;

    span {
        color: ${colors.primaryColor};
    }
`;

export const ImageTextBox = styled.div<{isReverseDirection?: boolean, isDesktopDirectionColumn?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 25px;

    ${({isReverseDirection}) => isReverseDirection && (

        css`
            flex-direction: column-reverse;
        `
    )}

    
    @media ${devices.tablet} {

        flex-direction: row;
        
        width: 100%;

        ${({isDesktopDirectionColumn}) => isDesktopDirectionColumn && (

            css`
                flex-direction: column;
            `
        )}


    }
`;

export const ImageBox = styled.div<{imageWidth?: string, imageHeight?: string}>`

    flex-shrink: 0;
    
    width: 100%;
    height: auto;

    border-radius: 15px;

    > img {

        width: 100%;
        height: auto;
        border-radius: 15px;
    }

    @media ${devices.tablet} {

        width: ${({imageWidth}) => imageWidth ?? '450px'};
        height: ${({imageHeight}) => imageHeight ?? 'auto'};
    }
`;

export const TextBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${colors.white};
    text-align: center;

`;

export const DescriptionText = styled.p`

    margin: 0;
    padding: 0;

    span {

        color: ${colors.primaryColor};
        font-weight: 500;
    }

    li {

    text-align: left;
    }
`;

export const SimpleText = styled.p`

    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${colors.white};
    text-align: start;

    span {
        font-weight: 700;
    }
`;

export const SimpleTextLight = styled.p`

    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: ${colors.blueGrey};
    text-align: start;

    span {
        font-weight: 700;
    }
`;

export const ButtonUp = styled.button`
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background: ${colors.primaryColor};
    box-shadow: 
        0px 1px 5px 0px ${colors.black012}, 
        0px 2px 2px 0px ${colors.black014}, 
        0px 3px 1px -2px rgba(0, 0, 0, 0.20)
    ;

    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    
    border: none;

    cursor: pointer;
`;