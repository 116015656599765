import React from 'react';

// style
import { 
    MailText
} from '../style/GeneralSupport.style';

const VDRSupport = () => {
    const encEmailVDR = "c3VwcG9ydG9AbGF2b2NlZGVsbGFyaWNjaGV6emEuaXQ=";
    const decodedEmailVDR = atob(encEmailVDR);


    const handleEmailClickVDR = () => {
        window.location.href = `mailto:${decodedEmailVDR}`;
        };

    return (  
        <MailText id="contact" onClick={handleEmailClickVDR}>{decodedEmailVDR}</MailText>
    );
};

export default VDRSupport;
