import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Routes, Route, useNavigate } from 'react-router-dom';

// utils
import { AppRoute } from 'routes';
import appRoutes from 'routes';
import RedirectToDefault from 'routes/RedirectToDefault';
import { getBrand, checkActualUrlforBrand } from 'configs/brandMap/brandMap.helper';

const buildElementForRoute = (route: AppRoute) => {
    // console.log("Building Element for route "+route.name+"...");
    return (
        // <RedirectToDefault isRouteProtected={route?.isProtected}>
            route?.element ?? <></>
            // {route?.element ?? <></>}
        // </RedirectToDefault>
    );
};

const buildRoutes = (routes: AppRoute[]) => {
    // console.log("Building Routes...");
    return (
        <>
            {routes.map((route, _) => {

                if (!route.subRoutes) {

                    return (
                        <Route
                            key={uuidv4()}
                            path={route.isIndex === undefined ? route.path : ""}
                            index={route?.isIndex}
                            element={buildElementForRoute(route)}
                        />
                    );
                }

                return (
                    <Route
                        key={uuidv4()}
                        path={route.isIndex === undefined ? route.path : ""}
                        index={route?.isIndex}
                        element={buildElementForRoute(route)}
                    >
                        {buildRoutes(route.subRoutes)}
                    </Route>
                );
            })}
        </>
    );
};

/**
 * Routes builder
 * 
 * @author Alessio Grassi
 * 
 * @returns JSX - Routes
 */

const BuildRoutes: FC<{}> = () => {

    const navigateTo = useNavigate();

    const domain = window.location.hostname;
    const pathname = window.location.pathname;
    const currentUrl = window.location.href;
    const isReservedArea = pathname === '/app/reservedArea' || pathname === '/app/reservedArea/';


    console.log("domain: ",domain);
    console.log("pathname: ",pathname);
    console.log("currentUrl: ",currentUrl);
    console.log("isReservedArea: ",isReservedArea);

    // if (!pathname.includes('/login') && !getBrand() && !isReservedArea) return (<>LOADING ROUTE...</>); // QUESTA BLOCCA IL LOGIN

    var brandHome = `/reservedArea`;

    const redirectToSupport = localStorage.getItem('redirectToSupport');

    console.log("redirectToSupport: ", redirectToSupport);
    console.log("isReservedArea: ", isReservedArea);
    if ((redirectToSupport) && (isReservedArea)) {
      localStorage.removeItem('redirectToSupport');
      const supportUrl = `https://${domain}`;
      console.log("Going to support: ", supportUrl);
      window.location.href = supportUrl;
    } else {
        console.log("Going to check: ",checkActualUrlforBrand());
        if (!checkActualUrlforBrand()) {
            const url = new URL(currentUrl);
            const searchParams = new URLSearchParams(url.search);

            if (domain.includes('localhost')) {
                brandHome += '?'+searchParams.toString();
            }
            console.log("navigateTo: ",brandHome);
            navigateTo(brandHome);
        } else {

        if (!appRoutes || appRoutes === undefined || !Array.isArray(appRoutes) || appRoutes.length === 0) {
            console.log("Route 1.");
            return (
                <Routes>
                    <Route
                        index
                        element={<></>}
                    />

                    <Route
                        path='*'
                        element={<></>}
                    />
                </Routes>
            );
        }

        console.log("Route 2.");
        return (
            <Routes>
                {buildRoutes(appRoutes)}
            </Routes>
        );
        }
    }
    console.log("Route 3.");
    return (<></>);
};

export default BuildRoutes;
