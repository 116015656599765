import styled, { css } from 'styled-components';

// types
import {
    ViewAreaProps,
    TimelineMiddleLineProps,
    EventPointPivotProps,
    FadeAreaProps
} from './RoadMap.style.d';

// style
import colors from 'style/palette/palette';
import devices from 'style/breakpoints/breakpoints';


export const Box = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    width: 100%;

    position: relative;
`;

export const Content = styled.div`

    width: 100%;

    padding: 0 15px 0 0;

    display: flex;
    align-items: stretch;
    justify-content: center;

    position: relative;

    @media (min-width: 769px) {

        padding: 0 20px 0 0;
    }
`;

export const ViewArea = styled.div<ViewAreaProps>`

    width: 100%;
    height: ${({ height }) => height ?? "204px"};

    padding: 0;

    background: ${colors.veryDarkGrey};
    
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    flex-direction: column;
    flex-wrap: nowrap;
    
    position: relative;
    
    overflow: hidden;
`;

export const FakeTimelineMiddleLine = styled.div`

    width: 100%;
    height: 3px;

    background: ${colors.primaryColor};

    position: absolute;
    left: 0;
`;

export const TimelineMiddleLine = styled.div<TimelineMiddleLineProps>`

    width: ${({ width }) => width ?? "100%"};
    height: 3px;

    background: ${colors.primaryColor};

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    flex-direction: row;
    flex-wrap: nowrap;
    
    transform: translateX( ${({ effect }) => effect ?? "0"} );
    transition: all 150ms ease;

    position: relative;
`;

export const TimelineRightArrowBox = styled.div`

    width: auto;
    height: auto;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    > span {

        width: 0;
        height: 0;
    
        background: ${colors.transparent};
    
        border-top: 6px solid ${colors.transparent};
        border-right: none;
        border-bottom: 6px solid ${colors.transparent};
        border-left: 12px solid ${colors.primaryColor};
    }
`;

export const TimelineEventCell = styled.div<EventPointPivotProps>`

    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth ?? "100%"};

    height: 100%;

    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const TimelineEventCellBoxRef = styled.div`

    width: 100%;
    height: 100%;
    
    position: relative;
`;

export const FadeArea = styled.div<FadeAreaProps>`

    width: 10px;

    background: ${colors.transparent};

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;

    ${({ isLeft }) => isLeft && (
        
        css`
            left: 0;
            background-image: linear-gradient(to left, ${colors.veryDarkGrey000}, ${colors.veryDarkGrey019} 70%, ${colors.veryDarkGrey});
        `
    )}

    ${({ isRight }) => isRight && (
        
        css`
            right: 0;
            background-image: linear-gradient(to right, ${colors.veryDarkGrey000}, ${colors.veryDarkGrey});
        `
    )}

    ${({ isTop }) => isTop && (
        
        css`
            top: 0;
            bottom: calc(50% + 1.5px);
        `
    )}

    ${({ isBottom }) => isBottom && (
        
        css`
            top: calc(50% + 1.5px);
            bottom: 0;
        `
    )}
`;

export const Line = styled.div`

    width: 90%;
    height: 7px;

    background: ${colors.black012};
    border-radius: 5px;
    
    position: absolute;
    bottom: 14px;

    @media ${devices.tablet} {
        width: 95%;
    }
`;
