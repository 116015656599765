import store from "redux/store";

const colors = {
    
    primaryColor: () => {
        switch (store.getState().ui.brand?.id) {
            case 1: // LDL
                return "#FFC000";
            case 2: // VDR
                return "#2C88A2";
            case 3: // TCR
                return "#C89D4C"; 
            case 4: // MB
                return "#00959E";
            default:
                return "#FFC000"; // Colore di default
        }
    }, 
    primaryYellowTCR: "#FFE662", 
    primaryGradientVDR: "linear-gradient(180deg, #C89D4C -30.95%, #312A0F 100%)",
    orange: "#FFA726", 
    red: "#FF5C50", 
    green038: "#66BB6A60", 
    green: "#66BB6A", 
    lightBlue: "#29B6F6", 
    blue: "#1877F2", 
    black: "#000", 
    black087: "#000000de", 
    black060: "#00000099", 
    black053: "#00000087", 
    black050: "#00000080", 
    black020: "#00000033", 
    black014: "#00000024", 
    black012: "#0000001F", 
    black000: "#00000000", 
    blackGrey: "#222222", 
    darkGrey: () => {
        switch (store.getState().ui.brand?.id) {
            case 1: // LDL
                return "#4C5862";
            case 2: // VDR
                return "#34556F"; 
            case 3: // TCR
                return "#111111";
            case 4: // MB
                return "#2D3F4B";
            default:
                return "#4C5862"; // Colore di default
        }
    }, 
    darkGold: "#C89D4C", 
    veryDarkGrey:  () => {
        switch (store.getState().ui.brand?.id) {
            case 1: // LDL
                return "#1B1F22";
            case 2: // VDR
                return "#041927";
            case 3: // TCR
                return "#000000";
            case 4: // MB
                return "#20232E";
            default:
                return "#1B1F22"; // Colore di default
        }
    }, 
    veryDarkGrey019: "#1B1F2230", 
    veryDarkGrey000: "#1B1F2200", 
    grey: () => {
        switch (store.getState().ui.brand?.id) {
            case 1: // LDL
                return "#29353E";
            case 3 & 4: // TCR & MB
                return "#20232E";
            default:
                return "#29353E"; // Colore di default
        }
    },
    grey400: "#BDBDBD", 
    simpleGrey: "#616161", 
    lightSimpleGrey: "#474545", 
    blueGrey: "#A9B8C2", 
    lightGrey: "#9E9E9E", 
    veryLightGrey: "#A0A4AC", 
    white: "#FFFFFF", 
    white070: "#FFFFFFb3", 
    white060: "#FFFFFF99", 
    white056: "#FFFFFF8F", 
    white050: "#FFFFFF80", 
    white042: "#FFFFFF6b", 
    white023: "#FFFFFF3b", 
    white012: "#FFFFFF1F", 
    white009: "#FFFFFF17", 
    transparent: "transparent" 
};

// store.getState().ui.brand?.id (1 LDL, 2 VDR, 3 TCR, 4 MB)

export default colors;