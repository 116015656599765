import { callPOST } from "network/network";
import { MONTH_NUMBER_FROM_LONG } from "../RecoverMonthModal.helper";

export const insertRecoveredResult = async (
    year: number,
    month: string,
    results: {bonus?: number, colpoSicuro?: number, altro?: number}
) => {

    const payload = {
        year,
        month: MONTH_NUMBER_FROM_LONG[month],
        bonus: results.bonus ?? 0,
        colpoSicuro: results.colpoSicuro ?? 0,
        tradingSportivo: results.altro ?? 0,
        recuperato: true
    }

    try {
        const response = await callPOST('v1/userresults', payload, true);
        
        return response;
    } catch (error: any) {
        throw error;
    }
}