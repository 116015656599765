import styled, { css } from 'styled-components';

export const EventInputContainer = styled.div<{breakPoint?: number}>`
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100vw;
    z-index: 5;
    ${(props) => props.breakPoint && (
        css`
            @media (min-width: ${props.breakPoint}px) {
                flex-direction: row;
                transform: translateY(-2px);
                height: 20px;
            }
        `
    )}
    
`;