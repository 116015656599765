import styled, { css } from 'styled-components';

// types
import {
    EventDetailsBoxProps,
    RoundImgProps,
    InfoBoxProps,
    DateBoxProps
} from './EventDetails.style.d';

// style
import colors from 'style/palette/palette';


export const EventDetailsBox = styled.a<EventDetailsBoxProps>`

    width: 200px;
    max-width: 200px;

    height: 100%;

    display: inline-flex;
    align-items: ${({ isBottom }) => isBottom ? "flex-start" : "flex-end"};
    justify-content: flex-start;

    gap: 10px;
    flex-direction: row;

    text-decoration: none;
    cursor: pointer;
`;

export const AssetBox = styled.div`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: 0;
    flex-direction: column;
`;

export const RoundImg = styled.img<RoundImgProps>`

    min-width: 46px;
    width: 46px;

    min-height: 46px;
    height: 46px;

    border-radius: 50%;
    border: 1px solid ${colors.primaryColor};

    ${({ isPast }) => isPast && (

        css`
            border-color: ${colors.simpleGrey};
        `
    )}

    ${({ isActive }) => isActive && (

        css`
            min-width: 60px;
            width: 60px;

            min-height: 60px;
            height: 60px;

            border-color: ${colors.green};
        `
    )}
`;

export const InfoBox = styled.div<InfoBoxProps>`

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-direction: column;

    font-size: 14px;
    color: ${colors.white};

    > span {

        display: inline-block;
        max-width: 139px;

        overflow: hidden;
    }

    ${({ isPast }) => isPast && (

        css`
            color: ${colors.simpleGrey};
        `
    )}

    ${({ isActive }) => isActive && (

        css`
            font-size: 15px;
            color: ${colors.green};
        `
    )}
`;

export const DateBox = styled.div<DateBoxProps>`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    font-size: 13px;
    color: ${colors.white050};

    ${({ isPast }) => isPast && (

        css`
            color: ${colors.simpleGrey};
        `
    )}

    ${({ isActive }) => isActive && (

        css`
            font-size: 15px;

            background:
                linear-gradient(0deg, ${colors.green038}, ${colors.green038}),
                linear-gradient(0deg, ${colors.white060}, ${colors.white060});

            -webkit-background-clip: text;
            -webkit-text-fill-color: ${colors.transparent};
        `
    )}
`;
