import { FC, useState, useEffect } from 'react';

// routing
import { useNavigate, useSearchParams } from 'react-router-dom';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useBrand from 'hooks/useBrand';

import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { 
    useIsFirstRender,
    useIsMounted
} from 'usehooks-ts';

// utils
import { 
    getItemFromLocalStorage,
    removeItemFromLocalStorage
} from 'utils/localStorage';
import {
    checkPaypalPaymentWhenLoggedIn,
    checkPaypalSubscriptionPayment
} from 'services/payments.services';
import {
    getEnvironmentBaseUrl,
    getEnvironmentMonolithLinkUrl,
    getEnvironmentProductId
} from 'configs/config.helper';
import { formattedCurrency } from 'utils/supportFunctions';
import {  getCurrentAuthUser, getUserData } from 'services/user.services';
import { formatAmountToPadNumber } from 'utils/label';
import { 
    convertAndAbbreviateDayOfWeekByIndex,
    scrollPage
} from './HomePageTCR.helper';
import {
    getMaxSteps,
    getRoadmapEvents,
    getUsersBonusJourneyStep,
    getUsersWarnings,
    getUserVipPoints,
    getVipStandings,
    getWhatsAppCallLessons,
    setPersonalAssistant,
    setUsersWarnings,
    stakeLibertyPoints,
    unstakeLibertyPoints
} from './services/HomePageTCR.services';    
import { activateTimer } from './UserPathCard/services/UserPathCard.services';

// types
import {
    Event,
    PurchaseTipsCardProps
} from "./HomePageTCR.component.d";
import { StepInfo } from './UserPathCard/UserPathCard';

// components
import CustomerRatingModal from 'components/CustomerRatingModal/CustomerRatingModal.component';
import LoadingMask from 'components/ui/LoadingMask';
import Timer from 'components/Timer/Timer.component';
import RoadMapCard from './RoadMapCard';
import UserPathCard from './UserPathCard';
import PurchaseSuggestCard from './PurchaseSuggestCard';
import ActiveHelpCard from './ActiveHelpCard/ActiveHelpCard.component';
import JourneyInfoModal from './JourneyInfoModal/JourneyInfoModal.component';
import WarningModal from './WarningModal/WarningModal.component';
import TenKClubCard from './TenKClubCard/TenKClubCard.component';
import VipPointsModal from './VipPointsModal/VipPointsModal.component';
import UserStep from './UserPathCard/UserStep/UserStep.component';
import CryptoFlowModal from './CryptoFlowModal/CryptoFlowModal.component';
import VipStandingInfoModal from './VipStandingInfoModal/VipStandingInfoModal.component';
import ReportEarningModal from './ReportEarningModal/ReportEarningModal.component';
import OfferModal from './OfferModal/OfferModal.component';

// assets
import { ReactComponent as GlobalMissionsBadgeSVG } from 'assets/imgs/home-page/missions.svg';
import { ReactComponent as WarningBadgeSVG } from 'assets/imgs/home-page/warning.svg';
import { ReactComponent as EarnImgSVG } from 'assets/imgs/home-page/cards/earn-img.svg';
import { ReactComponent as CapitalImgSVG } from 'assets/imgs/home-page/cards/capital-img.svg';
import { ReactComponent as CryptoImgSVG } from 'assets/imgs/home-page/cards/ico-crypto.svg';
import { ReactComponent as VipStandingSVG } from 'assets/imgs/home-page/cards/ico-classifica-vip.svg';
import { ReactComponent as FacebookIconSVG } from './assets/facebookIcon.svg';
import { ReactComponent as AppIconSVG } from './assets/appIcon.svg';
import { ReactComponent as BetfairIconSVG } from './assets/betfairIcon.svg';
import { ReactComponent as LibertyPointsSVG } from './assets/liberty-points-icon.svg';
import { ReactComponent as ExchangeSVG } from './assets/exchange-icon.svg';
import { ReactComponent as UpArrowSVG } from './assets/up-arrow-icon.svg';
import { ReactComponent as DownArrowSVG } from './assets/down-arrow-icon.svg';
import { ReactComponent as VipSVG } from './assets/vip-point-icon.svg';
import { ReactComponent as InfoSVG } from './assets/info-icon.svg';
import { ReactComponent as VipStandingCup } from './assets/vip-standing-cup.svg';
import { ReactComponent as LightningSVG } from './assets/lightning-icon.svg';
import { ReactComponent as OpenBroadcastSVG } from './assets/OpenBroadcast.svg';

// style
import {
    Page,
    HeaderRow,
    InfoRow,
    GuidedFinancialJourneyRow,
    WellcomeText,
    OfferButton,
    BadgeBox,
    MissionsBox,
    MissionsBadgeButton,
    SingleMissionBadgeButton,
    WarningBadgeButton,
    InfoBox,
    JourneyText,
    JourneyBox,
    CenterCards,
    InteractionRow,
    StackingBox,
    BalancePointBox,
    BalanceText,
    BalancePoint,
    ActionsRow,
    StakeButton,
    UnstakeButton,
    StakingPointBox,
    StakingText,
    StakingPoint,
    BalanceBox,
    Balance,
    BalancePointText,
    StakingBox,
    StakingBalance,
    StakingPointText,
    VipPointCard,
    PointBox,
    PointText,
    VipPointBalance,
    VipPointsLabel,
    VipButton,
    VipCardContent,
    InfoButton,
    StandingTitle,
    StandingCard,
    StandingRow,
    PositionText,
    NameText,
    PointsText,
    CryptoTitle,
    CryptoButton,
    Offert,
    ThematicBroadCastCard,
    BroadcastBox,
} from './style/HomePageTCR.style';
import { getEarningRestults, getProductSubscribers } from './ReportEarningModal/services/ReportEarningModal.services';
import { saveUserWarnings } from 'redux/actions/actions-user';
import WhatsAppFlowCard from './WhatsAppFlowCard/WhatsAppFlowCard.component';
import UserLevelCard from './UserLevelCard/UserLevelCard.component';
import ShareNeedCallModal from './WhatsAppFlowCard/ShareNeedCallModal/ShareNeedCallModal.component';
import NewThematicCall from 'components/ThematicCallComponents/NewThematicCall/NewThematicCall.component';
import BankTransferModal from 'components/BankTransferModal/BankTransferModal.component';
import LibertyPaymentModal from 'components/LibertyPaymentModal/LibertyPaymentModal.component';
import { getCourseWithBrand } from './services/ThematicDirectCatalogue.services';
import PastThematicCall from 'components/ThematicCallComponents/PastTematicCall/PastThematicCall.component';

const earnCardText = "Cosa devo fare";
const capitalCardText = <span>Ho un capitale che<br />voglio far lavorare</span>;
const _BLANK_ARRAY = [
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0],
    ["...", 0]
];

/**
 * Home Page
 * 
 * @author Frank Berni and Alessio Grassi
 * 
 * @returns JSX
 */

const HomePageTCR: FC<{}> = () => {
    
    const { name: userName, 
        libertyPointsAvailableBalance: libertyPoints,
        stackedLibertyPointsBalance: stakedLibertyPoints,
        offers,
        warning, 
        tokenID,
        clientId
    } = useAppSelector(state => state.user);
    const { isAppMobile } = useAppSelector(state => state.ui)
    const [isRoadMapExpanded, setIsRoadMapExpanded] = useState<boolean>(false);
    const [nextRoadMapEventIndex, setNextRoadMapEventIndex] = useState<number>(0);
    const [roadmapEvents, setRoadmapEvents] = useState<Event[] | undefined>(undefined);
    const [isShoppingTipsCardVisible, setIsShoppingTipsCardVisible] = useState<boolean>(true);
    const [shoppingTipsInfo, setShoppingTipsInfo] = useState<PurchaseTipsCardProps>({});
    const [usersBonusStep, setUsersBonusStep] = useState<string | undefined>(undefined);
    const [totalBonusStep, setTotalBonusStep] = useState<string | undefined>(undefined);
    const [isJourneyInfoModalVisible, setIsJourneyInfoModalVisible] = useState<boolean | undefined>(undefined);
    const [journeyBonusStepInfo, setJourneyBonusStepInfo] = useState<any>(undefined);
    const [isWarningModalVisible, setIsWarningModalVisible] = useState<boolean | undefined>(undefined);
    const [newWarning, setNewWarning] = useState<boolean | undefined>(undefined);
    // const [warningData, setWarningData] = useState<{}>({});
    const [isClosingLessonModalVisible, setIsClosingLessonModalVisible] = useState<boolean | undefined>(undefined);
    const [lessonId, setLessonId] = useState<number | undefined>(undefined);
    // const [isUserPathCardShrunk, setIsUserPathCardShrunk] = useState<boolean | undefined>(false);
    const [isBonusCardActive, setIsBonusCardActive] = useState<boolean | undefined>(false);
    const [hasUserLessonDataToRefresh, setHasUserLessonDataToRefresh] = useState<boolean | undefined>(false);
    const [areMissionsBadgeVisible, setAreMissionsBadgeVisible] = useState<boolean | undefined>(true);
    const [isFacebookMissionCompleted, setIsFacebookMissionCompleted] = useState<boolean | undefined>(undefined);
    const [isAppMissionCompleted, setIsAppMissionCompleted] = useState<boolean | undefined>(undefined);
    const [isBetfairMissionCompleted, setIsBetfairCompleted] = useState<boolean | undefined>(undefined);
    // const [newPaymentChecked, setNewPaymentChecked] = useState<boolean>(false);
    const [isUserConnected, setIsUserConnected] = useState<boolean>(false);
    const [userClicksOpenCard, setUserClicksOpenCard] = useState<string | undefined>(undefined);
    // const [libertyPoints, setLibertyPoints] = useState<number | undefined>(undefined);
    // const [stakedLibertyPoints, setStakedLibertyPoints] = useState<number | undefined>(undefined);
    const [vipPoints, setVipPoints] = useState<number | string | undefined>(undefined);
    const [isExchangeLoading, setIsExchangeLoading] = useState<boolean>(false);
    const [isNewStaking, setIsNewStaking] = useState<boolean>(false);
    const [isStakingOpen, setIsStakingOpen] = useState<boolean>(false);
    const [isVipPointsModalVisible, setIsVipPointsModalVisible] = useState<boolean>(false);
    // const [userData, setUserData] = useState<any>({});
    const [isVipStandingCardActive, setIsVipStandingCardActive] = useState<boolean>(false);
    const [isCryptoFlowCardActive, setIsCryptoFlowCardActive] = useState<boolean>(false);
    const [isVipStandingVisible, setIsVipStandingVisible] = useState<boolean>(false);
    const [isCryptoFlowVisible, setIsCryptoFlowVisible] = useState<boolean>(false);
    const [isCryptoFlowModalVisible, setIsCryptoFlowModalVisible] = useState<boolean>(false);
    const [isOfferModalVisible, setIsOfferModalVisible] = useState<boolean>(false);
    const [isReportEarningModalVisible, setIsReportEarningModalVisible] = useState<boolean>(false);
    const [isStepBoxOpen, setIsStepBoxOpen] = useState<boolean>(false);
    const [vipStanding, setVipStanding] = useState<any[] | undefined>(undefined);
    const [isVipStandingInfoModalVisible, setIsVipStandinInfoModalVisible] = useState<boolean>(false);
    const [isChoiceOneClicked, setIsChoiceOneClicked] = useState<boolean | undefined>(undefined);
    const [isChoiceTwoClicked, setIsChoiceTwoClicked] = useState<boolean | undefined>(undefined);
    const [isChoiceTwoInError, setIsChoiceTwoInError] = useState<boolean | undefined>(false);
    const [isBoostActive, setIsBoostActive] = useState<boolean | undefined>(false);
    const [hasUserCryptoFlow, setHasUserCryptoFlow] = useState<boolean>(false);
    const [isBankTransferPending, setIsBankTransferPending] = useState<boolean>(false);
    const [resultsForNewsBanner, setResultsForNewsBanner] = useState<any>(undefined);
    const [earningDataForTable, setEarningDataForTable] = useState<any>(undefined);
    const [globalMaxEarning, setGlobalMaxEarning] = useState<any>(undefined);
    const [annualTotal, setAnnualTotal] = useState<any>(undefined);
    const [isFreeWhatsAppCallAvailable, setIsFreeWhatsAppCallAvailable] = useState<boolean>(false);
    const [lessonData, setLessonData] = useState<any | undefined>(undefined);
    const currentUser = useAppSelector(state => state.user);

    const isMounted = useIsMounted();
    const isFirstRender = useIsFirstRender();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const isUserComeFromPublicPageWithRequisites = 'To redirect at' in localStorage;

    // DIRETTE
    const [thematicBroadcastProducts, setThematicBroadcastProducts] = useState<any[]>([]);
    const [isBankTransferModalVisible, setIsBankTransferModalVisible] = useState<boolean>(false);
    const [chosenProductName, setChosenProductName] = useState<string>('');
    const [chosenProductPrice, setChosenProductPrice] = useState<string>('');
    const [paymentTransferID, setPaymentTransferID] = useState<number | undefined>();
    const openBankTransferModal = () => setIsBankTransferModalVisible(true);

    const [isLibertyPaymentModalVisible, setIsLibertyPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(false);
    const openLibertyPaymentModal = () => setIsLibertyPaymentModaVisible(true);

    const [isLibertyPastPaymentModalVisible, setIsLibertyPastPaymentModaVisible] = useState<boolean>(false);
    const closeLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(false);
    const openLibertyPastPaymentModal = () => setIsLibertyPastPaymentModaVisible(true);
    const closeBankTransferModal = () => setIsBankTransferModalVisible(false);


    const [chosenProductID, setChosenProductID] = useState<number>();
    const [productLibertyPrice, setProductLibertyPrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isType, setIsType] = useState<string>('');

    const brand = useBrand();

    const getProducts = async () => {
        
        try {
            const response = await getCourseWithBrand();

            const thematicBroadcastProducts = response.filter((product: any) => ((product.type === "THEMATIC_BROADCAST")))
            console.log("DIRETTE:", thematicBroadcastProducts);
            
            setThematicBroadcastProducts(thematicBroadcastProducts);
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            navigate('/fallback-page/generic-error');
            console.warn(error);
        }
    };

    useEffect(() => {
        getProducts();
    },[]);

    const isLiveDatePassed = (liveDate: any) => {
        const currentDate = new Date().getTime();
        const liveDateObj = new Date(liveDate).getTime();
        return liveDateObj < currentDate;
    };

    const transferModalHandler = (productName: string, productPrice: string, paymentID: number | undefined) => {
        setIsBankTransferModalVisible(true);
        setChosenProductName(productName);
        setChosenProductPrice(productPrice);
        setPaymentTransferID(paymentID);
    };

    const libertyModalHandler = () => {
        setIsLibertyPaymentModaVisible(true);
    };


    const libertyPastModalHandler = () => {
        setIsLibertyPastPaymentModaVisible(true);
    };

    const libertyProductID = (courseID: number) => {
        setChosenProductID(courseID)        
    };

    const libertyProductPrice = (price: number) => {
        setProductLibertyPrice(price)     
    };

    const loadingOn = () => {
        setIsLoading(true)
    };

    const loadingOff = () => {
        setIsLoading(false)
    };

    const handleThematicTypeChange = (thematicType : any) => {
        setIsType(thematicType)
        console.log(thematicType);
    };

    const handleBroadcastClick = () => {
        window.open(`/app/reservedArea/dirette-tematiche`, '_blank');
    }

// const saveResultsForBanner = (monthResult: number, averageMonthResult: number) => {
//     setResultsForNewsBanner({
//         monthTotal: monthResult,
//         averageMonth: averageMonthResult
//     })
// }

const saveResultsForBanner = (monthResult: number, averageMonthResult: number, avgResultBonus: number, avgResultColpo: number) => {
    setResultsForNewsBanner({
        monthTotal: monthResult,
        averageMonth: averageMonthResult,
        avgResultBonus: avgResultBonus,
        avgResultColpo: avgResultColpo
    })
};

    const toggelRoadMapExpansionHandler = () => {
        setIsRoadMapExpanded(prevState => !prevState);
    };

    const infoModalClosureHandler = () => setIsJourneyInfoModalVisible(false);
    const infoModalOpenerHandler = () => setIsJourneyInfoModalVisible(true);
    const newWarningSetting = () => setNewWarning(true);
    const warningModalClosureHandler = () => setIsWarningModalVisible(false);
    const warningModalOpenerHandler = () => {
    
        // if (!newWarning) return;
        setIsWarningModalVisible(true);
    };
    const closeLessonModalClosureHandler = () => setIsClosingLessonModalVisible(false);
    const ratingModalOpenerHandler = () => setIsClosingLessonModalVisible(true);
    const vipStandingModalOpen = () => setIsVipStandinInfoModalVisible(true);
    const vipStandingModalClose = () => setIsVipStandinInfoModalVisible(false);
    const cryptoModalOpen = () => setIsCryptoFlowModalVisible(true);
    const cryptoModalClose = () => setIsCryptoFlowModalVisible(false);
    const offerModalClose = () => setIsOfferModalVisible(false);
    const offerModalOpen = () => setIsOfferModalVisible(true);
    const reportEarningModalClose = () => setIsReportEarningModalVisible(false);
    const reportEarningModalOpen = () => setIsReportEarningModalVisible(true);
    const lessonIdHandler = (lessonId: number) => setLessonId(lessonId);
    // const shrinkClickHandler = () => setIsUserPathCardShrunk(prevState => !prevState);
    const bonusCardActiveHandler = () => {
        
        setIsStakingOpen(false);
        setIsCryptoFlowCardActive(false);
        setIsCryptoFlowVisible(false);
        setIsVipStandingCardActive(false);
        setIsVipStandingVisible(false);
        setIsBonusCardActive(prevState => !prevState);
        setIsStepBoxOpen(prevState => !prevState);
    };
    const refreshUserLessonStartHandler = () => setHasUserLessonDataToRefresh(true);
    const refreshUserLessonStopHandler = () => setHasUserLessonDataToRefresh(false);
    const missionBadgesVisibleHandler = () => setAreMissionsBadgeVisible(true);
    const missionBadgesHiddenHandler = () => setAreMissionsBadgeVisible(false);
    // const newWarningHandler = () => setNewWarning(true);
    const closeVipPointsModal = () => setIsVipPointsModalVisible(false);
    const openStakingExchange = () => setIsStakingOpen(true);

    // const facebookBadgeClickHandler = () => {

    //     if (userClicksOpenCard === 'facebookMissionCard') {
            
    //         setIsShoppingTipsCardVisible(true);
    //         setUserClicksOpenCard(undefined);
    //         return;
    //     };

    //     setIsShoppingTipsCardVisible(true);
    //     setUserClicksOpenCard('facebookMissionCard')
    // };
    // const appBadgeClickHandler = () => {
        
    //     if (userClicksOpenCard === 'appMissionCard') {
            
    //         setIsShoppingTipsCardVisible(true);
    //         setUserClicksOpenCard(undefined);
    //         return;
    //     }

    //     setIsShoppingTipsCardVisible(true);
    //     setUserClicksOpenCard('appMissionCard')
    // };
    // const betfairBadgeClickHandler = () => {
    
    //     if (userClicksOpenCard === 'betfairMissionCard') {
            
    //         setIsShoppingTipsCardVisible(true);
    //         setUserClicksOpenCard(undefined)
    //         return;
    //     };
    //     setUserClicksOpenCard('betfairMissionCard')
    // };
    const cryptoButtonClickHandler = () => {

        setIsCryptoFlowVisible(prevState => !prevState);
        setIsCryptoFlowCardActive(prevState => !prevState);
        setIsBonusCardActive(false);
        setIsVipStandingCardActive(false);
        setIsVipStandingVisible(false);
        setIsStakingOpen(false);
        setIsStepBoxOpen(false);

    };
    const vipStandingButtonClickHandler = () => {

        setIsVipStandingVisible(prevState => !prevState);
        setIsVipStandingCardActive(prevState => !prevState);
        setIsBonusCardActive(false);
        setIsCryptoFlowCardActive(false);
        setIsCryptoFlowVisible(false);
        setIsStakingOpen(false);
        getVipPointsStanding();
        setIsStepBoxOpen(false);
    };

    // const newStepHandler = (shoppingTipsPayload: any, newBonusStep: string, stepInfo: any) => {

    //     console.log("SALVIAMO?");
        
    //     setShoppingTipsInfo(shoppingTipsPayload);
    //     setUsersBonusStep(newBonusStep);  
    //     setJourneyBonusStepInfo(stepInfo);       
    // };

    // const choiceOneButtonHandler = () => {

    //     setIsChoiceOneClicked(true);
    //     setIsChoiceTwoClicked(false);
    // };

    // const choiceTwoButtonHandler = () => {

    //     setIsChoiceTwoClicked(true);
    //     setIsChoiceOneClicked(false);

    //     // to do set in the next if statement the watning setting
    //     if (journeyBonusStepInfo === undefined) return;
    //     if (journeyBonusStepInfo.typeOfDestinationChoiceTwo === 'warning') setIsChoiceTwoInError(true);

    //     if (journeyBonusStepInfo.typeOfDestinationChoiceTwo === 'step') {
    //         if (journeyBonusStepInfo.linkChoiceTwo) chargeNewStep(journeyBonusStepInfo.linkChoiceTwo);
    //     }

    // };

    const vipPointsButtonHandler = () => {
        
        setIsBonusCardActive(false);
        setIsStakingOpen(prevState => !prevState);
        setIsCryptoFlowCardActive(false);
        setIsCryptoFlowVisible(false);
        setIsVipStandingCardActive(false);
        setIsVipStandingVisible(false);
        setIsStepBoxOpen(false);
    };

    const vipPointsInfoButtonHandler = () => {
        setIsVipPointsModalVisible(true);
        setIsStakingOpen(prevState => !prevState);
    };

    const stakeHandler = () => {
        stakePoints()
    };

    const unstakeHandler = () => {
        unstakePoints()
    };

    const cryptoFlowButtonClickHandler = () => {
       navigate(`/public/product/${getEnvironmentProductId('cryptoflow')}`);
    };

    const checkForCompletedMissions = (facebookResult: boolean | undefined, appResult: boolean | undefined, betfairResult: boolean | undefined) => {
        if (setIsFacebookMissionCompleted) setIsFacebookMissionCompleted(facebookResult);
        if (setIsAppMissionCompleted) setIsAppMissionCompleted(appResult);
        if (setIsBetfairCompleted) setIsBetfairCompleted(betfairResult);
    };
    
    // const isUserRegistered = async () => {

    //     try {
    //         const response = await getCurrentAuthUser();
                      
    //         setIsUserConnected(!!response);
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable to get Current user.", error);
            
    //     }
    // };

    const clearRefCode = () => {

        removeItemFromLocalStorage('ref');
        removeItemFromLocalStorage('refSetDate');
};

    const updateRoadmapEvents = async () => {

        try {
            
            const response = await getRoadmapEvents();
            if (!isMounted()) return;

            if (response === undefined || !response) {
                throw new Error("Roadmap user undefined response!");
            }

            for (let i = 0; i < response.length; i++) if (response[i].tbd) response[i].begin = 2555575027000;         
            for (let i = 0; i < response.length; i++) if (new Date(response[i].begin).getHours() === 0) response[i].begin = response[i].begin + 10800000;

            response.sort((firstEvent: any, secondEvent: any) => (
                firstEvent.begin - secondEvent.begin
            ));
            
            const suppArray: any = [];

            for (let i=0; i < response.length; i++) {

                const currEventTimestamp = response[i].begin;
                const currEventDateObj = new Date(currEventTimestamp);
                // convert day of week from eng to ita
                const currEventDateDayOfWeek = `${convertAndAbbreviateDayOfWeekByIndex(currEventDateObj.getDay())}`;
                const formattedEventDay = formatAmountToPadNumber(currEventDateObj.getDate());
                const formattedEventMonth = formatAmountToPadNumber(currEventDateObj.getMonth() + 1);
                const currEventDate = `${formattedEventDay}/${formattedEventMonth}`;
                const finalCurrEventDate = `${currEventDateDayOfWeek} ${currEventDate}`;
                const formattedEventHour = formatAmountToPadNumber(currEventDateObj.getHours());
                const formattedEventMinutes = formatAmountToPadNumber(currEventDateObj.getMinutes());
                const currEventHour = `h ${formattedEventHour}:${formattedEventMinutes}`;

                const suppEvent: Event = {

                    img: response[i].customImageUrl,
                    date: finalCurrEventDate,
                    hour: currEventHour,
                    info: response[i].name,
                    startTimestamp: response[i].begin,
                    link: response[i].link,
                    tbd: response[i].tbd
                };

                suppArray.push(suppEvent);
            }

            setRoadmapEvents( [...suppArray] );          
            setNextRoadMapEventIndex(suppArray.findIndex((event: Event) => {
                if (event.startTimestamp) return +event.startTimestamp > (new Date().getTime())}
            ));
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn(error);
        }
    };
    
    const getUsersBonusSteps = async () => {

        // try {

        //     const bonusStep = await getUsersBonusJourneyStep();
        //     if (!isMounted()) return;

        //     if (bonusStep === undefined) {

        //         setJourneyBonusStepInfo({});
        //         new Error ("Users bonus step is undefined.")
        //     }

        //     const shoppingTipsPayload = {
        //         imgUrl: bonusStep.immagineConsiglio,
        //         text: bonusStep.copyConsiglio,
        //         buttonText: bonusStep.pulsanteConsiglio,
        //         link: bonusStep.destinazioneConsiglio
        //     };
         
        //     const journeyFlowPayload: StepInfo = {
        //         stepId: bonusStep.id,
        //         stepNumber: bonusStep.stepNum,
        //         stepText: bonusStep.copyStep,
        //         choiceOne: bonusStep.sceltaUno,
        //         choiceOneText: bonusStep.copySceltaUno,
        //         linkChoiceOne: bonusStep.destinazioneSceltaUno,
        //         typeOfDestinationChoiceOne: bonusStep.tipoDestinazioneUno,
        //         warningStepOne: bonusStep.idWarningSceltaUno,
        //         choiceTwo: bonusStep.sceltaDue,
        //         choiceTwoText: bonusStep.copySceltaDue,
        //         linkChoiceTwo: bonusStep.destinazioneSceltaDue,
        //         warningStepTwo: bonusStep.idWarningSceltaDue,
        //         typeOfDestinationChoiceTwo: bonusStep.tipoDestinazioneDue,
        //         textualRequirements: bonusStep.requisitiTestuali,
        //     };
            
        //     setShoppingTipsInfo(shoppingTipsPayload);
        //     setUsersBonusStep(`${bonusStep.stepNum - 1}`);  
        //     setJourneyBonusStepInfo(journeyFlowPayload);       
        // } catch (error: any) {
        //     console.warn("Unable getting Users bonus step.");
        //     setJourneyBonusStepInfo({});
        // }

        try {

            const bonusStep = await getUsersBonusJourneyStep();
            if (!isMounted()) return;

            if (bonusStep === undefined) {

                setJourneyBonusStepInfo({});
                new Error ("Users bonus step is undefined.")
            }

            // console.log("BONUS STEP:", bonusStep);
            const finalProductAdviceSellImage = ((bonusStep.productAdviceSellImage !== null) && (bonusStep.productAdviceSellImage !== undefined)) ? bonusStep.productAdviceSellImage.url : '';

            const shoppingTipsPayload = {
                imgUrl: finalProductAdviceSellImage, //to do image is not present into the steps obj
                text: bonusStep.copyAdvice,
                buttonText: bonusStep.buttonAdvice,
                link: bonusStep.productAdviceId,
                type: bonusStep.productAdviceType
            };
         
            const journeyFlowPayload: StepInfo = {
                id: bonusStep.id,
                stepNumber: bonusStep.stepNumber,
                copy: bonusStep.copy,
                copyChoiceLeft: bonusStep.copyChoiceLeft,
                // choiceOneText: bonusStep.copyChoiceLeft,
                // linkChoiceOne: bonusStep.destinazioneSceltaUno,
                // typeOfDestinationChoiceOne: bonusStep.tipoDestinazioneUno,
                // warningStepOne: bonusStep.idWarningSceltaUno,
                copyChoiceRight: bonusStep.copyChoiceRight,
                // choiceTwoText: bonusStep.copyChoiceRight,
                // linkChoiceTwo: bonusStep.destinazioneSceltaDue,
                // warningStepTwo: bonusStep.idWarningSceltaDue,
                // typeOfDestinationChoiceTwo: bonusStep.tipoDestinazioneDue,
                // textualRequirements: bonusStep.requisitiTestuali,
            };
            
            const totalJourneyBonusSteps = await getMaxSteps();
            
            if (totalJourneyBonusSteps !== undefined || totalJourneyBonusSteps !== null) setTotalBonusStep(`${totalJourneyBonusSteps - 1}`);
            setShoppingTipsInfo(shoppingTipsPayload);
            setUsersBonusStep(`${bonusStep.stepNumber - 1}`);  
            setJourneyBonusStepInfo(journeyFlowPayload);       
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable getting Users bonus step.");
            setJourneyBonusStepInfo({});
        }
    };
    // const getBonusSteps = async () => {

    //     try {

    //         const totalBonusJourneySteps = await getBonusJourneyTotalSteps();
    //         if (!isMounted()) return;

    //         if (totalBonusJourneySteps === undefined) {

    //             new Error ("Users bonus step is undefined.")
    //         }
            
    //         setTotalBonusStep(`${totalBonusJourneySteps}`);
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable getting total Bonus steps.");      
    //     }
    // };

    // const chargeNewStep = async (step: string) => {

    //     try {
    //         const response = await newStep(step);
    //         if (!isMounted()) return;
            
    //         if (response === undefined) {
    //             new Error ("New step response is undefined.")
    //         }
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable getting user new step.");
    //     }
    // };

    // const newStep = async (step: string) => {

        // try {
        //     const bonusStep = await callOldGET('flowutentestep/' + step, true);
        //     if (!bonusStep || bonusStep === undefined) return;

        //     const shoppingTipsPayload = {
        //         imgUrl: bonusStep.immagineConsiglio,
        //         text: bonusStep.copyConsiglio,
        //         buttonText: bonusStep.pulsanteConsiglio,
        //         link: bonusStep.destinazioneConsiglio
        //     };
         
        //     const journeyFlowPayload: StepInfo = {
        //         stepId: bonusStep.id,
        //         stepNumber: bonusStep.stepNum,
        //         stepText: bonusStep.copyStep,
        //         choiceOne: bonusStep.sceltaUno,
        //         choiceOneText: bonusStep.copySceltaUno,
        //         linkChoiceOne: bonusStep.destinazioneSceltaUno,
        //         typeOfDestinationChoiceOne: bonusStep.tipoDestinazioneUno,
        //         warningStepOne: bonusStep.idWarningSceltaUno,
        //         choiceTwo: bonusStep.sceltaDue,
        //         choiceTwoText: bonusStep.copySceltaDue,
        //         linkChoiceTwo: bonusStep.destinazioneSceltaDue,
        //         warningStepTwo: bonusStep.idWarningSceltaDue,
        //         typeOfDestinationChoiceTwo: bonusStep.tipoDestinazioneDue,
        //         textualRequirements: bonusStep.requisitiTestuali,
        //     };

        //     console.log("SHOPPING",shoppingTipsPayload);
        //     console.log("STEP",bonusStep.stepNum.toString());
        //     console.log("JOURNEY",journeyFlowPayload);
        //     console.log(newStepHandler);
        //     if (newStepHandler) {               
        //         newStepHandler(shoppingTipsPayload, `${bonusStep.stepNum - 1}`, journeyFlowPayload);
        //         setIsChoiceTwoClicked(false);
        //     };
        // } catch (error: any) {
        //     throw error;
        // }
    // };

    // const buttonCTAHandler = async () => {

    //     if (isChoiceOneClicked) {

    //         if (!journeyBonusStepInfo || journeyBonusStepInfo === undefined) return;
    //         if (!journeyBonusStepInfo.linkChoiceOne || journeyBonusStepInfo.linkChoiceOne === undefined) return;
    //         if (journeyBonusStepInfo.choiceOneText?.includes('offerta')) await activateOfferTimer();
    //         if (journeyBonusStepInfo.typeOfDestinationChoiceOne === 'url') {
    //             window.open(
    //                 getEnvironmentMonolithLinkUrl(journeyBonusStepInfo.linkChoiceOne),
    //                 '_blank',
    //                 'noreferrer noopener'
    //             );
    //         }
    //     }

    //     if (isChoiceTwoClicked) {

    //         console.log("CLICKED CHOICE 2");
            
    //         if (!journeyBonusStepInfo || journeyBonusStepInfo === undefined) return;
    //         if (!journeyBonusStepInfo.linkChoiceTwo || journeyBonusStepInfo.linkChoiceTwo === undefined) return;
    //         if (journeyBonusStepInfo.choiceTwoText?.includes('offerta')) await activateOfferTimer();
    //         if (journeyBonusStepInfo.typeOfDestinationChoiceTwo === 'url') {
    //             window.open(
    //                 getEnvironmentMonolithLinkUrl(journeyBonusStepInfo.linkChoiceTwo),
    //                 '_blank',
    //                 'noreferrer noopener'
    //             );
    //         }

    //         if (journeyBonusStepInfo.typeOfDestinationChoiceTwo === 'warning') {

    //             if (journeyBonusStepInfo.warningStepTwo === undefined || !journeyBonusStepInfo.warningStepTwo) return;
    //             setWarnings(journeyBonusStepInfo.warningStepTwo.toString());
    //         }

    //         if (journeyBonusStepInfo.typeOfDestinationChoiceTwo === 'step') {
    //             chargeNewStep(journeyBonusStepInfo.linkChoiceTwo);
    //         }
    //     }
    //     return;
    // };

    // const setWarnings = async (warningCode: string) => {

    //     try {
            
    //         if (warningCode === undefined || !warningCode) return;
    //         const warningSetting = await setUsersWarnings(warningCode);
    //         if (!isMounted()) return;
            
    //         if (warningSetting === undefined) {
    //             new Error ("Users warning setting response is undefined.")
    //         }
            
    //         newWarningHandler();
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable setting User's Warnings.", error);
    //     }
    // };

    // const activateOfferTimer = async () => {

    //     const snackbarData: SnackbarOptions = {};

    //     try {
    //         const response = await activateTimer();
    //         if (!isMounted) return;

    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable to activate offer timer.");
    //         snackbarData.type = 'error';
    //         snackbarData.message = "C'è stato un errore nell'attivare la promozione."
    //     }
    // };

    const getWarnings = async () => {

        try {
            
            const warningInfo = await getUsersWarnings();
            if (!isMounted()) return;

            if (warningInfo === undefined) {

                new Error ("Users warning info is undefined.")
            }

            if (!warningInfo) return;
            if (warningInfo.length === 0) return;

            dispatch( saveUserWarnings(warningInfo[0]) );
            // setWarningData(warningInfo);
            setNewWarning(true);
            // setIsWarningModalVisible(true);
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable getting Warnings info.", error);
            
        }
    };

    // const getUserData = async () => {
    //     try {
    //         const response = await getCurrentAuthUser();
    //         setUserData(response);
            
    //         const pools = response.pools;
            
    //         const libertyPointsPool = pools.filter((pool: any) => pool.type === "PERSONAL");
    //         console.log("POOL L", libertyPointsPool);
    //         if (libertyPointsPool.length === 0) dispatch( saveLibertyPointsAvailableBalance(0) );

    //         if (libertyPointsPool.length !== 0) dispatch( saveLibertyPointsAvailableBalance(libertyPointsPool[0].availableBalance) );

    //         const stackedLibertyPointsPool = pools.filter((pool: any) => pool.type === "STACKING");
    //         console.log("POOL S", stackedLibertyPointsPool);
    //         if (stackedLibertyPointsPool.length === 0) dispatch( saveStackedLibertyPointsAvailableBalance(0) );

    //         if (stackedLibertyPointsPool.length !== 0) dispatch( saveStackedLibertyPointsAvailableBalance(stackedLibertyPointsPool[0].availableBalance) );
    //         // dispatch( saveLibertyPointsAvailableBalance(500) );


    //         console.log("PUNTI??", libertyPointsPool[0].availableBalance);
            
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn(error);
    //     }
    
    // };

    // const checkPayments = async () => {

    //     const pendingCoursePurchase = getItemFromLocalStorage('Pending purchase ID');
    //     const pendingSubscription = getItemFromLocalStorage('Pending subscription ID');
    //     const pendingBankTransfer = getItemFromLocalStorage('Pending Bank Transfer');
    //     const personalAssistantCode = getItemFromLocalStorage('Personal Assistant Code');
    //     if (
    //         !pendingCoursePurchase && 
    //         !pendingSubscription && 
    //         !pendingBankTransfer
    //     ) return removeItemFromLocalStorage('Personal Assistant Code');
        
    //     if (pendingCoursePurchase) {

    //         const snackbarData: SnackbarOptions = {};
            
    //         try {
                
    //             console.log("Controllo Pagamento Pendente", pendingCoursePurchase);
    //             await checkPaypalPaymentWhenLoggedIn(pendingCoursePurchase);
                
    //             if (!isMounted()) return;
                
    //             if (pendingBankTransfer === "true") {
    //                 setIsBankTransferPending(true)
    //                 return
    //             };

    //             if (personalAssistantCode != null) {
                    
    //                 try {                        
    //                     await setPersonalAssistant(personalAssistantCode);
    //                     removeItemFromLocalStorage('Personal Assistant Code');
    //                 } catch (error: any) {
    //                     console.warn("ERROR:", error);
    //                 }
    //             };
    
    //             removeItemFromLocalStorage('Pending purchase ID');
    //             setNewPaymentChecked(true);
    //             navigate('/reservedArea/mycourses');
    //             snackbarData.type = 'success';
    //             snackbarData.message = "COMPLIMENTI! Il tuo corso è stato caricato con Successo!";
    //             dispatch( addSnackbar(snackbarData) );
    //         } catch (error: any) {
    //             // alert("son già in errore")
    //             const response = error.response;
    //             console.log("ERRORE RISPOSTA", response); 
                
    //             if (response && response.data && response.data.message) {
    //                 console.log("ERRORE MESSAGGIO", response.data.message);
    //                 if(response.data.message.includes("already assigned")) {
    //                     if(response.data.message.includes(clientId)) {
    //                         // TO DO gestisce ID sbagliato quando abbiamo sezione pagamenti
    //                         localStorage.clear();
    //                     }
    //                     localStorage.clear();
    //                     return;
    //                 };
    //             };

    //             if (error.message === '403') return navigate('/fallback-page/permission-denied');
    //             console.warn("Unable to check offline payments.", error);
    //             snackbarData.type = 'error';
    //             snackbarData.message = "Qulcosa è andato storto nella procedura di acquisto! Riprova più tardi";
    //             dispatch( addSnackbar(snackbarData) );
    //         }
    //     }

    //     if (pendingSubscription) {

    //         const snackbarData: SnackbarOptions = {};

    //         try {

    //             const response = await checkPaypalSubscriptionPayment(pendingSubscription);
    //             if (!isMounted()) return;
    //             if (!response || response === undefined) {
    //                 new Error ("Offline payments check response is undefined.")
    //             }
    
    //             removeItemFromLocalStorage('Pending subscription ID');
    //             setNewPaymentChecked(true);
    //             navigate('/reservedArea/myservices');
    //             snackbarData.type = 'success';
    //             snackbarData.message = "COMPLIMENTI! Il tuo Servizio è stato caricato con Successo!";
    //             dispatch( addSnackbar(snackbarData) );
    //         } catch (error: any) {
    //             if (error.message === '403') return navigate('/fallback-page/permission-denied');
    //             // TO DO error.message case
    //             console.warn("Unable to check offline payments.", error);
    //             snackbarData.type = 'error';
    //             snackbarData.message = "Qualcosa è andato storto nella procedura di acquisto! Riprova più tardi";
    //             dispatch( addSnackbar(snackbarData) );
    //         }
    //     }
    // };
    
    // const getLibertyPoints = async () => {

    //     try {
    //         const userLibertyPoints = await getUserLibertyPoints();
    //         if (!isMounted) return;

    //         if (!userLibertyPoints || userLibertyPoints === undefined) return setLibertyPoints(0);

    //         setLibertyPoints(roundedPoints(+userLibertyPoints));           
    //     } catch (error: any) {
        // if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable getting liberty points.");
    //         setLibertyPoints(0);
    //     }
    // };

    // const getStakedLibertyPoints = async () => {

    //     try {
    //         const userStakedLibertyPoints = await getUserStakedLibertyPoints();
    //         if (!isMounted) return;

    //         if (!userStakedLibertyPoints || userStakedLibertyPoints === undefined) return setStakedLibertyPoints(0);
    //         if (userStakedLibertyPoints.length === 0) return setStakedLibertyPoints(0);
    //         console.log("USER POOLS:", userStakedLibertyPoints)
    //         setStakedLibertyPoints(roundedPoints(userStakedLibertyPoints));           
    //     } catch (error: any) {
        // if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Unable getting staked liberty points.");
    //         setStakedLibertyPoints(0);
    //     }
    // };

    const getVipPoints = async () => {
        
        try {
            const userVipPoints = await getUserVipPoints();
            if (!isMounted) return;

            if (!userVipPoints || userVipPoints === undefined) return setVipPoints(0);

            // console.log("VIPPOINTS:", userVipPoints);
            if (userVipPoints.length === 0) return setVipPoints(0);
            if (userVipPoints.length === 1) return setVipPoints(formattedCurrency(userVipPoints[0].amount, 0));
            
            const amount = userVipPoints.map((el: any) => +el.amount);
            const pointsToSet = amount.reduce((partial: number, next: number) => partial + next);
            setVipPoints(formattedCurrency(pointsToSet, 0));    
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable getting vip points.");
            
        }
    };

    const stakePoints = async () => {

        if (libertyPoints === undefined || libertyPoints <= 0) return;
        setIsExchangeLoading(true);        
        try {
            const response = await stakeLibertyPoints();
            if (!isMounted()) return;

            if (!response || response === undefined) {

                new Error ("Stake liberty points response is undefined.")
            }

            setIsNewStaking(prevState => !prevState);
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable to stake liberty points.", error);
            setIsExchangeLoading(false);        
        }
    };
    
    const unstakePoints = async () => {

        setIsExchangeLoading(true);               
        try {
            const response = await unstakeLibertyPoints();
            if (!isMounted()) return;

            // if (!response || response === undefined) {

            //     new Error ("Unstake liberty points response is undefined.")
            // }

            setIsNewStaking(prevState => !prevState);
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Unable to unstake liberty points.", error);
            setIsExchangeLoading(false);
        }
    };

    const getVipPointsStanding = async () => {

        try {
            const response = await getVipStandings();

            if (response.length === 0) return setVipStanding(_BLANK_ARRAY);
            response.sort((standingA: any, standingB: any) => standingB[1] - standingA[1]);
            setVipStanding(response);
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("ERROR:", error);
            setVipStanding(_BLANK_ARRAY);
        }
    };

    const userResults =  async () => {

        try {
            
            const response = await getEarningRestults();

            return response;

        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const subscribers =  async (date: string) => {

        try {
            
            const response = await getProductSubscribers(getEnvironmentProductId('bonus automatici'), date);

            return response;
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    const earningData = async () => {

        try {
            
            const results = await userResults();

            
            const yearResults = results.filter((result: any) => result.year === 2023);

            const upgradedResults = yearResults.map((result: any) => {

                return {
                    avgResultBonus:result.avgResultBonus,
                    avgResultColpo:result.avgResultColpo,
                    maxResult: result.maxResult,
                    month: result.month,
                    year: result.year,
                    nameOfMaxResult: result.nameOfMaxResult,
                    total_month_earning: 0,
                    sumMaxResultColpo: result.sumMaxResultColpo
                }


            });
            console.log("UPGRADE RESULT", upgradedResults)


            for (let i = 0; i < upgradedResults.length; i++) {

                try {
                    
                    const monthSubscribers = await subscribers(new Date(upgradedResults[i].year, upgradedResults[i].month).toISOString());
                    upgradedResults[i].total_month_earning = upgradedResults[i].avgResultBonus * monthSubscribers + (upgradedResults[i].sumMaxResultColpo * 4);
                } catch (error: any) {
                    console.warn("ERROR:", error);
                }
            }
            
            const maxResultInTime = Math.max(...results.flatMap((result: any) => result.maxResult));
            const maxResultData = results.filter((result: any) => result.maxResult === maxResultInTime);
            
            const totalYearEarnings = upgradedResults.flatMap((month: any) => month.total_month_earning).reduce((a: number, b: number) => a + b, 0);
            
            // saveResultsForBanner(upgradedResults[upgradedResults.length - 1].total_month_earning, upgradedResults[upgradedResults.length - 1].avg_result);
            saveResultsForBanner(
                upgradedResults[upgradedResults.length - 1].total_month_earning, 
                upgradedResults[upgradedResults.length - 1].avg_result,
                upgradedResults[upgradedResults.length - 1].avgResultBonus,
                upgradedResults[upgradedResults.length - 1].avgResultColpo
            );
            setAnnualTotal(totalYearEarnings)
            setGlobalMaxEarning(`${maxResultData[0].nameOfMaxResult} ${formattedCurrency(maxResultData[0].maxResult, 0)} €`)
            setEarningDataForTable(upgradedResults)
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    
    const checkFreeWhatsAppCallAvailability = async () => {
        try {
            const addFreeCall = await getWhatsAppCallLessons();
            setLessonData(addFreeCall);
        
            if (addFreeCall.length <= 2) {
                setIsFreeWhatsAppCallAvailable(true);
            }
        } catch (error) {
            console.log('Errore nel verificare la disponibilità di chiamate WhatsApp gratuite:', error);
        }
    };

    // useEffect(() => { 

    //     if (isUserConnected) return;

    //     const timer = setTimeout(() => {
    //         isUserRegistered()
    //     }, 2000);
        
    //     return () => clearInterval(timer);
    // }, [isUserConnected]);

    useEffect(() => {

        earningData()
        // isUserRegistered();
        updateRoadmapEvents();
        getUsersBonusSteps();
        // getBonusSteps();
        getWarnings();
        // checkPayments();
        // getLibertyPoints();
        // getStakedLibertyPoints();
        getVipPoints();
        clearRefCode();
        checkFreeWhatsAppCallAvailability();
        if (searchParams.get('bonusFlow') === '1') bonusCardActiveHandler();
    }, []);

    useEffect(() => {

        if (isFirstRender) return;

        // getLibertyPoints();
        // getStakedLibertyPoints();
        getVipPoints();
        dispatch( getUserData() );
        setInterval(() => setIsExchangeLoading(false), 1000);
    }, [isNewStaking]);

    // useEffect(() => {

    //     if (newPaymentChecked === undefined || !newPaymentChecked) return;
    //     dispatch( getUserData() );
    // }, [newPaymentChecked])

    // useEffect(() => {

    //     if (!isUserComeFromPublicPageWithRequisites || (isUserComeFromPublicPageWithRequisites === null)) return;
    //     navigate(getItemFromLocalStorage('To redirect at')!);
    // }, [isUserComeFromPublicPageWithRequisites])

    // useEffect(() => {

    //     if (newWarning === undefined || !newWarning) return;
    //     if (newWarning) getWarnings();
    // }, [newWarning])

    useEffect(() => {

        if (!isStakingOpen) return;

        scrollPage('exchange');
    }, [isStakingOpen]);

    useEffect(() => {

        if (!isVipStandingVisible) return;

        scrollPage('standing');
    }, [isVipStandingVisible]);

    useEffect(() => {

        if (!isBonusCardActive) return;

        setTimeout(() => scrollPage('bonusFlow'), 1000);
    }, [isBonusCardActive]);

    // useEffect(() => {
    //     if (isBankTransferPending === false) return;
    //     navigate("/reservedArea/shop")
    // },[isBankTransferPending]);

    const isTenKClubCardVisible = (usersBonusStep !== undefined) && (usersBonusStep === totalBonusStep);
    const isBonusJourneyCompleted = (usersBonusStep !== undefined) && (usersBonusStep === totalBonusStep);
    const areAllMissionsCompleted = isAppMissionCompleted && isFacebookMissionCompleted && isBetfairMissionCompleted;
    const isStakeButtonDisabled = (libertyPoints === 0);
    const isUnstakeButtonDisabled = (stakedLibertyPoints === 0);
    const isExchangeDisabled = isStakeButtonDisabled && isUnstakeButtonDisabled;
    const isInteractionRowOpen = isStakingOpen;
    const finalCTAButtonText = (isChoiceOneClicked && !isChoiceTwoClicked) ? journeyBonusStepInfo?.choiceOneText : (!isChoiceOneClicked && isChoiceTwoClicked) ? journeyBonusStepInfo?.choiceTwoText : undefined;
    const areCardsLoading = (journeyBonusStepInfo === undefined) || (libertyPoints === undefined);    
    const isOfferVisible = (offers !== undefined) && (offers.length > 0) && (offers[0].status === 'OPEN');
    const offerLimitTime = isOfferVisible && offers[0].expirationDate;
    const isWhattsAppFreeCall = isFreeWhatsAppCallAvailable;

    // console.log('Risposta da getWhatsAppCallLessons:', lessonData);

    if (typeof tokenID !== 'string') {
        return (
            <LoadingMask
                size='big'
                paddingTop='40vh'
                width='100%'
                isCenter
            />
        )};

    return (
        <>
            <Page>
                <HeaderRow>
                    <WellcomeText>
                        Ciao{" "}
                        {userName}!
                    </WellcomeText>

                    <BadgeBox>
                        {!isAppMobile && isOfferVisible && (
                        <OfferButton onClick={offerModalOpen}>
                            <LightningSVG />
                            Offerta lampo:
                            <Timer 
                                limitTime={offerLimitTime}
                                timerCount='MINUTES'
                            />
                        </OfferButton>
                        )}

                        {(!areMissionsBadgeVisible || areAllMissionsCompleted) && (
                            <MissionsBadgeButton disabled={!areAllMissionsCompleted}>
                                <GlobalMissionsBadgeSVG />
                            </MissionsBadgeButton>)
                        }

                        {areMissionsBadgeVisible && 
                        !areAllMissionsCompleted && (
                            <MissionsBox>
                                <SingleMissionBadgeButton
                                    isCompleted={isFacebookMissionCompleted}
                                    // onClick={facebookBadgeClickHandler}    
                                >
                                    <FacebookIconSVG />
                                </SingleMissionBadgeButton>

                                <SingleMissionBadgeButton 
                                    isCompleted={isAppMissionCompleted}
                                    // onClick={appBadgeClickHandler}    
                                >
                                    <AppIconSVG />
                                </SingleMissionBadgeButton>

                                <SingleMissionBadgeButton 
                                    isCompleted={isBetfairMissionCompleted}
                                    // onClick={betfairBadgeClickHandler}    
                                >
                                    <BetfairIconSVG />
                                </SingleMissionBadgeButton>
                            </MissionsBox>
                        )}
                        
                        <WarningBadgeButton 
                            disabled={!newWarning}
                            onClick={warningModalOpenerHandler}    
                        >
                            <WarningBadgeSVG />
                        </WarningBadgeButton>
                    </BadgeBox>
                </HeaderRow>
                            
                <Offert>
                    {isAppMobile && isOfferVisible && (
                        <OfferButton onClick={offerModalOpen}>
                            <LightningSVG />
                            Offerta lampo:
                            <Timer 
                                limitTime={offerLimitTime}
                                timerCount='MINUTES'
                            />
                        </OfferButton>
                    )}
                </Offert>

                <InfoRow>
                {!isAppMobile && (
                    <RoadMapCard
                        roadmapEvents={roadmapEvents}
                        isExpanded={isRoadMapExpanded} 
                        onExpansion={toggelRoadMapExpansionHandler} 
                        reportEarningModalOpen={reportEarningModalOpen}
                        resultsForNewsBanner={resultsForNewsBanner}
                        nextEventIndex={nextRoadMapEventIndex}
                    />
                )}
                    <InfoBox isRoadMapExpanded={isRoadMapExpanded}>
                        {!isWhattsAppFreeCall && 
                            <ActiveHelpCard
                                showOtherCards={setIsShoppingTipsCardVisible}
                                openCloseLessonModalHandler={ratingModalOpenerHandler}
                                lessonIdHandler={lessonIdHandler}
                                refreshStopFunction={refreshUserLessonStopHandler}
                                hasUserLessonToRefresh={hasUserLessonDataToRefresh}
                                missionBadgesVisibleHandler={missionBadgesVisibleHandler}
                                missionBadgesHiddenHandler={missionBadgesHiddenHandler}
                                completedMissionsHandler={checkForCompletedMissions}
                                userClicksOpenCard={userClicksOpenCard}
                            />}
                        {isWhattsAppFreeCall && lessonData &&
                            <WhatsAppFlowCard 
                                hasFreeCall={isFreeWhatsAppCallAvailable}
                                lessons={lessonData}
                                activeLesson={checkFreeWhatsAppCallAvailability}
                            />}
                
                        {/* {isShoppingTipsCardVisible && 
                        !isTenKClubCardVisible && 
                        shoppingTipsInfo.link && (
                            <PurchaseSuggestCard
                                imgSrc={shoppingTipsInfo.imgUrl}
                                cardText={shoppingTipsInfo.text}
                                buttonRoutePath={shoppingTipsInfo.link}
                                buttonText={shoppingTipsInfo.buttonText}
                                productType={shoppingTipsInfo.type}
                            />
                        )} */}

                        <UserLevelCard/>

                        {isTenKClubCardVisible && <TenKClubCard />}
                    </InfoBox>
                    
                    {isAppMobile && (
                    <RoadMapCard
                        roadmapEvents={roadmapEvents}
                        isExpanded={isRoadMapExpanded} 
                        onExpansion={toggelRoadMapExpansionHandler} 
                        reportEarningModalOpen={reportEarningModalOpen}
                        resultsForNewsBanner={resultsForNewsBanner}
                        nextEventIndex={nextRoadMapEventIndex}
                    />
                    )}
                </InfoRow>

                {areCardsLoading && (
                    <LoadingMask
                        isCenter
                        size='medium'
                        paddingTop='100px'
                    />
                )}

                {!areCardsLoading && 
                <JourneyBox>
                    <JourneyText>In Evidenza...</JourneyText>

                    <GuidedFinancialJourneyRow>
                        <UserPathCard
                            // cardType='outlined'
                            cardAsset={<EarnImgSVG />}
                            label={earnCardText}
                            stepInfo={journeyBonusStepInfo}
                            numberOfCompletedSteps={usersBonusStep}
                            maxNumberOfSteps={totalBonusStep}
                            // newWarning={newWarningHandler}
                            openModalHandler={infoModalOpenerHandler}
                            isComplete={isBonusJourneyCompleted}
                            // isShrunk={isUserPathCardShrunk}
                            // shrinkClickHandler={shrinkClickHandler}
                            isActive={isBonusCardActive}
                            activeCardHandler={bonusCardActiveHandler}
                            isInfoButtonVisible
                        />
                        
                        {journeyBonusStepInfo && 
                        isStepBoxOpen && 
                        isAppMobile && (
                            <InteractionRow>
                                <UserStep 
                                    stepInfo={journeyBonusStepInfo}
                                    isChoiceOneClicked={isChoiceOneClicked}
                                    isChoiceTwoClicked={isChoiceTwoClicked}
                                    isChoiceTwoInError={isChoiceTwoInError}
                                    finalCTAButtonText={finalCTAButtonText}
                                    warningModalOpenHandler={warningModalOpenerHandler}
                                    newWarningSetting={newWarningSetting}
                                />
                            </InteractionRow>
                        )}

<BroadcastBox>
                        <ThematicBroadCastCard>
                            <OpenBroadcastSVG onClick={handleBroadcastClick}/>

                            {(() => {
                                let nearestDateProduct: typeof thematicBroadcastProducts[0] | null = null;
                                const currentDate = new Date(); // Data odierna

                                thematicBroadcastProducts.forEach((product) => {
                                if (
                                    ((product.ownedAs === "SPECTATOR" || product.ownedAs === "PROTAGONIST" || "") || product.owned === false) &&
                                    !isLiveDatePassed(product.productThematicCall.liveDate) &&
                                    product.productThematicCall.status === 'SCHEDULED'
                                ) {
                                    const liveDate = new Date(product.productThematicCall.liveDate);
                                    const currentDateTimestamp = currentDate.getTime();
                                    const liveDateTimestamp = liveDate.getTime();

                                    if (!nearestDateProduct || Math.abs(liveDateTimestamp - currentDateTimestamp) < Math.abs(new Date(nearestDateProduct.productThematicCall.liveDate).getTime() - currentDateTimestamp)) {
                                    nearestDateProduct = product;
                                    }
                                }
                                });

                                if (nearestDateProduct) {
                                return (
                                    <NewThematicCall
                                    product={nearestDateProduct}
                                    courseElement={nearestDateProduct.requisitesSatisfied}
                                    onTransferClick={transferModalHandler}
                                    openBankTransferModal={openBankTransferModal}
                                    onLibertyClick={libertyModalHandler}
                                    openLibertyPaymentModal={openLibertyPaymentModal}
                                    libertyProductID={libertyProductID}
                                    libertyProductPrice={libertyProductPrice}
                                    loadingOn={loadingOn}
                                    loadingOff={loadingOff}
                                    onThematicTypeChange={handleThematicTypeChange}
                                    />
                                    );
                                } else {
                                    let nearestExpirationProduct: typeof thematicBroadcastProducts[0] | null = null;

                                    thematicBroadcastProducts
                                    .filter((product) => {
                                        return (
                                        product.productThematicCall.onShop === true &&
                                        product.productThematicCall.status === 'PUBLISHED' &&
                                        new Date(product.productThematicCall.expirationDate) < currentDate
                                        );
                                    })
                                    .sort((a, b) => {
                                        const dateA = new Date(a.productThematicCall.expirationDate).getTime();
                                        const dateB = new Date(b.productThematicCall.expirationDate).getTime();
                                        return dateA - dateB;
                                    })
                                    .forEach((product) => {
                                        if (
                                        product.productThematicCall.onShop === true &&
                                        product.productThematicCall.status === 'PUBLISHED'
                                        ) {
                                        const expirationDate = new Date(product.productThematicCall.expirationDate);
                                        if (expirationDate < currentDate) {
                                            nearestExpirationProduct = product;
                                        }
                                        }
                                    });

                                    if (nearestExpirationProduct) {
                                    return (
                                        <PastThematicCall
                                        product={nearestExpirationProduct}
                                        courseElement={nearestExpirationProduct.requisitesSatisfied}
                                        onTransferClick={transferModalHandler}
                                        openBankTransferModal={openBankTransferModal}
                                        onLibertyClick={libertyPastModalHandler}
                                        openLibertyPaymentModal={openLibertyPastPaymentModal}
                                        libertyProductID={libertyProductID}
                                        libertyProductPrice={libertyProductPrice}
                                        loadingOn={loadingOn}
                                        loadingOff={loadingOff}
                                        />
                                    );
                                    } else {
                                    return null;
                                    }
                                }
                                })()}
                        </ThematicBroadCastCard>

                        <ThematicBroadCastCard>
                            <OpenBroadcastSVG onClick={handleBroadcastClick} />

                            {(() => {
                                const currentDate = new Date();
                                let liveProducts: any = [];
                                let pastProducts: any = [];

                                console.log("LIVE :", liveProducts)
                                console.log("PAST :", pastProducts)

                                thematicBroadcastProducts.forEach((product) => {
                                    if (
                                        ((product.ownedAs === "SPECTATOR" || product.ownedAs === "PROTAGONIST" || "") || product.owned === false) &&
                                        ((product.productThematicCall.status === 'SCHEDULED' && !isLiveDatePassed(product.productThematicCall.liveDate)) ||
                                        (product.productThematicCall.status === 'PUBLISHED' && new Date(product.productThematicCall.liveDate) < currentDate))
                                    ) {
                                        if (product.productThematicCall.status === 'SCHEDULED') {
                                            liveProducts.push(product);
                                        } else {
                                            pastProducts.push(product);
                                        }
                                    }
                                });

                                if (liveProducts.length === 1) {
                                    pastProducts.sort((a: any, b: any) => {
                                        const dateA = new Date(a.productThematicCall.liveDate).getTime();
                                        const dateB = new Date(b.productThematicCall.liveDate).getTime();
                                        return dateB - dateA;
                                    });

                                    return (
                                        <PastThematicCall
                                            product={pastProducts[0]}
                                            courseElement={pastProducts[0].requisitesSatisfied}
                                            onTransferClick={transferModalHandler}
                                            openBankTransferModal={openBankTransferModal}
                                            onLibertyClick={libertyPastModalHandler}
                                            openLibertyPaymentModal={openLibertyPastPaymentModal}
                                            libertyProductID={libertyProductID}
                                            libertyProductPrice={libertyProductPrice}
                                            loadingOn={loadingOn}
                                            loadingOff={loadingOff}
                                        />
                                        );
                                    
                                } else if (liveProducts.length > 2 || liveProducts.length === 2) {
                                    liveProducts.sort((a: any, b: any) => {
                                        const dateA = new Date(a.productThematicCall.liveDate).getTime();
                                        const dateB = new Date(b.productThematicCall.liveDate).getTime();
                                        return dateA - dateB;
                                    });

                                    return (
                                        <NewThematicCall
                                            product={liveProducts[1]}
                                            courseElement={liveProducts[1].requisitesSatisfied}
                                            onTransferClick={transferModalHandler}
                                            openBankTransferModal={openBankTransferModal}
                                            onLibertyClick={libertyModalHandler}
                                            openLibertyPaymentModal={openLibertyPaymentModal}
                                            libertyProductID={libertyProductID}
                                            libertyProductPrice={libertyProductPrice}
                                            loadingOn={loadingOn}
                                            loadingOff={loadingOff}
                                            onThematicTypeChange={handleThematicTypeChange}
                                        />
                                        );
                                    
                                } else if (liveProducts.length < 2 && pastProducts.length >= 2) {
                                    pastProducts.sort((a: any, b: any) => {
                                        const dateA = new Date(a.productThematicCall.liveDate).getTime();
                                        const dateB = new Date(b.productThematicCall.liveDate).getTime();
                                        return dateB - dateA;
                                    });

                                    return (
                                        <PastThematicCall
                                            product={pastProducts[1]}
                                            courseElement={pastProducts[1].requisitesSatisfied}
                                            onTransferClick={transferModalHandler}
                                            openBankTransferModal={openBankTransferModal}
                                            onLibertyClick={libertyPastModalHandler}
                                            openLibertyPaymentModal={openLibertyPastPaymentModal}
                                            libertyProductID={libertyProductID}
                                            libertyProductPrice={libertyProductPrice}
                                            loadingOn={loadingOn}
                                            loadingOff={loadingOff}
                                        />
                                        );
                                    
                                } else {
                                    return null;
                                }
                            })()}
                        </ThematicBroadCastCard>
                        </BroadcastBox>
                            {/* 
                                <UserPathCard
                                    cardAsset={<CryptoImgSVG />}
                                    label='Percorso Crypto'
                                    numberOfCompletedSteps='0'
                                    isSmall
                                    isActive={isCryptoFlowCardActive}
                                    activeCardHandler={cryptoButtonClickHandler}
                                    isInfoButtonVisible
                                    openModalHandler={cryptoModalOpen}
                                />
                                
                                {isCryptoFlowVisible && isAppMobile && (
                                    <InteractionRow>
                                        <CryptoTitle id='standing'>
                                            <span>Percorso Crypto</span><br/>
                                            Se stai facendo il percorso bonus e hai già un capitale libero da far lavorare, puoi <br/>
                                            partire con il percorso crypto. Il percorso inizia da Crypto Flow:
                                        </CryptoTitle>

                                        <CryptoButton onClick={cryptoFlowButtonClickHandler}>
                                            Acquista Crypto Flow
                                        </CryptoButton>
                                    </InteractionRow>
                                )}

                                <UserPathCard
                                    cardAsset={<CapitalImgSVG />}
                                    label={capitalCardText}
                                    isDisabled
                                    isSmall
                                />
                                
                                <UserPathCard
                                    cardAsset={<VipStandingSVG />}
                                    label='Classifica VIP'
                                    numberOfCompletedSteps='0'
                                    isSmall
                                    isVip
                                    isActive={isVipStandingCardActive}
                                    activeCardHandler={vipStandingButtonClickHandler}
                                    openModalHandler={vipStandingModalOpen}
                                    isInfoButtonVisible
                                /> 

                                <UserPathCard
                                    cardAsset={<CapitalImgSVG />}
                                    label={capitalCardText}
                                    isDisabled
                                    isSmall
                                />
                            */}

                            {isVipStandingVisible && isAppMobile && vipStanding && (
                                <InteractionRow>
                                    <StandingTitle id='standing'>
                                        <span>CLASSIFICA VIP (!)</span><br/>
                                        Classifica punti vip: dal 13 febbraio al 13 marzo in palio TRE slot per la settimana del Supervivente.<br/>
                                        Per approfondire <a href='https://www.facebook.com/groups/LiberoDalLavoro/posts/1780234059024382/'>clicca qui.</a><br/>
                                        Per farne parte guadagna anche tu Punti VIP: 
                                    </StandingTitle>

                                    <StandingCard>
                                        {vipStanding.map((standing: any, index: number) => {
                                            
                                            return (
                                                <StandingRow isOver={index >2}>
                                                        {(index < 3) && <VipStandingCup />}
                                                        
                                                        <PositionText isOver={index >2}>{index + 1}° posto:</PositionText>

                                                        <PointsText isOver={index >2}>{formattedCurrency(standing[1], 0)}</PointsText>
                                                        
                                                        <NameText isOver={index >2}>{standing[0]}</NameText>
                                                </StandingRow>
                                            )
                                        })}
                                    </StandingCard>
                                </InteractionRow>
                            )}

                        <VipPointCard 
                            isActive={isStakingOpen}
                            onClick={vipPointsButtonHandler}
                        >
                            <VipSVG />

                            <VipCardContent>
                                <PointBox>
                                    <PointText>
                                        Hai accumulato:
                                    </PointText>

                                    <VipPointBalance>
                                        {vipPoints}
                                    </VipPointBalance>

                                    <VipPointsLabel>
                                        Punti VIP
                                    </VipPointsLabel>
                                </PointBox>

                                <VipButton>
                                    guadagna punti vip
                                </VipButton>
                            </VipCardContent>

                            <InfoButton 
                                onClick={vipPointsInfoButtonHandler}
                            >
                                <InfoSVG />
                            </InfoButton>
                        </VipPointCard>
                    </GuidedFinancialJourneyRow>
                </JourneyBox>}

                {journeyBonusStepInfo && 
                isStepBoxOpen && 
                !isAppMobile && (
                    <InteractionRow>
                        <UserStep 
                            stepInfo={journeyBonusStepInfo}
                            isChoiceOneClicked={isChoiceOneClicked}
                            isChoiceTwoClicked={isChoiceTwoClicked}
                            isChoiceTwoInError={isChoiceTwoInError}
                            finalCTAButtonText={finalCTAButtonText}
                            warningModalOpenHandler={warningModalOpenerHandler}
                            newWarningSetting={newWarningSetting}
                        />
                    </InteractionRow>
                )}

                {isInteractionRowOpen && (
                    <InteractionRow>
                        {isStakingOpen && (
                            <StackingBox flavor='outlined'>
                                {isExchangeLoading && 
                                    <LoadingMask 
                                        isCenter 
                                        height='254px'
                                        size='small'
                                    />
                                }

                                {!isExchangeLoading && (
                                    <>
                                        <BalancePointBox id='exchange'>
                                            <BalanceText>Hai un saldo corrente di:</BalanceText>

                                            <BalancePoint>
                                                <LibertyPointsSVG />

                                                <BalanceBox>
                                                    <Balance>{formattedCurrency(libertyPoints, 0)}</Balance>

                                                    <BalancePointText>Punti Libertà</BalancePointText>
                                                </BalanceBox>
                                            </BalancePoint>
                                        </BalancePointBox>

                                        <ActionsRow isDisabled={isExchangeDisabled}>
                                            <StakeButton 
                                                disabled={isStakeButtonDisabled}
                                                onClick={stakeHandler}
                                            >
                                                <DownArrowSVG />

                                                Stake
                                            </StakeButton>

                                            <ExchangeSVG />

                                            <UnstakeButton 
                                                disabled={isUnstakeButtonDisabled}
                                                onClick={unstakeHandler}
                                            >
                                                Unstake
                                            
                                                <UpArrowSVG />
                                            </UnstakeButton>
                                        </ActionsRow>

                                        <StakingPointBox>
                                            <StakingText>In Staking hai:</StakingText>

                                            <StakingPoint>
                                                <LibertyPointsSVG />

                                                <StakingBox>
                                                    <StakingBalance>{formattedCurrency(stakedLibertyPoints, 0)}</StakingBalance>

                                                    <StakingPointText>Punti Libertà</StakingPointText>
                                                </StakingBox>
                                            </StakingPoint>
                                        </StakingPointBox>
                                    </>
                                )}
                            </StackingBox>
                        )}
                    </InteractionRow>
                )}

                {isCryptoFlowVisible && !isAppMobile && (
                    <InteractionRow>
                        <CryptoTitle id='standing'>
                            <span>Percorso Crypto</span><br/>
                            Se stai facendo il percorso bonus e hai già un capitale libero da far lavorare, puoi <br/>
                            partire con il percorso crypto. Il percorso inizia da Crypto Flow:
                        </CryptoTitle>

                        <CryptoButton onClick={cryptoFlowButtonClickHandler}>
                            Acquista Crypto Flow
                        </CryptoButton>
                    </InteractionRow>
                )}

                {isVipStandingVisible && !isAppMobile && !areCardsLoading && vipStanding && (
                    <InteractionRow>
                        <StandingTitle id='standing'>
                            <span>CLASSIFICA VIP (!)</span><br/>
                            Classifica punti vip: dal 13 febbraio al 13 marzo in palio TRE slot per la settimana del Supervivente.<br/>
                            Per approfondire <a href='https://www.facebook.com/groups/LiberoDalLavoro/posts/1780234059024382/'>clicca qui.</a><br/>
                            Per farne parte guadagna anche tu Punti VIP: 
                        </StandingTitle>

                        <StandingCard>
                            {vipStanding.map((standing: any, index: number) => {
                                
                                return (
                                    <StandingRow isOver={index >2}>
                                        {(index < 3) && <VipStandingCup />}
                                        
                                        <PositionText isOver={index >2}>{index + 1}° posto:</PositionText>

                                        <PointsText isOver={index >2}>{formattedCurrency(standing[1], 0)}</PointsText>

                                        <NameText isOver={index >2}>{standing[0]}</NameText>
                                    </StandingRow>
                                )
                            })}
                        </StandingCard>
                    </InteractionRow>
                )}
            </Page>

            {isJourneyInfoModalVisible && 
            <JourneyInfoModal onCloseButtonModal={infoModalClosureHandler} />}

            {isWarningModalVisible &&
            // warningData &&
            <WarningModal 
                onCloseButtonModal={warningModalClosureHandler}
                warningData={warning}    
            />}

            {isClosingLessonModalVisible && 
            <CustomerRatingModal 
                onCloseButtonModal={closeLessonModalClosureHandler}
                lessonId={lessonId}
                refreshFunction={refreshUserLessonStartHandler}
            />}

            {isVipPointsModalVisible && (
            <VipPointsModal 
                onCloseButtonModal={closeVipPointsModal}
                onModalButtonClickHandler={openStakingExchange}
            />
            )}

            {isVipStandingInfoModalVisible && (
            <VipStandingInfoModal 
                onCloseButtonModal={vipStandingModalClose}
            />
            )}

            {isCryptoFlowModalVisible && (
            <CryptoFlowModal 
                onCloseButtonModal={cryptoModalClose}
            />
            )}

            {isOfferModalVisible && (
            <OfferModal
                onCloseButtonModal={offerModalClose}
                limitTime={offerLimitTime}
            />
            )}

            {isReportEarningModalVisible && (
                <ReportEarningModal
                    onCloseButtonModal={reportEarningModalClose}
                    saveResultsForBanner={saveResultsForBanner}
                    annualTotal={annualTotal}
                    earningDataForTable={earningDataForTable}
                    globalMaxEarning={globalMaxEarning}
                />
            )}

            {isBankTransferModalVisible && (
                <BankTransferModal
                    onCloseButtonModal={closeBankTransferModal}
                    productName={chosenProductName}
                    productPrice={chosenProductPrice}
                    paymentTransferID={paymentTransferID}
                    isPending={isBankTransferPending}
                />
            )}

            {isLibertyPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    productType="ThematicBroadcast"
                    thematicBroadcastType={isType}
                />
            }

            {isLibertyPastPaymentModalVisible && 
                <LibertyPaymentModal
                    onCloseButtonModal={closeLibertyPastPaymentModal}
                    courseID={+chosenProductID!}
                    price={productLibertyPrice}
                    isPastBroadcast={true}
                />
            }
        </>
    );
};

export default HomePageTCR;
