import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface UserState {

    tokenID: string | undefined | null,
    clientId: number | undefined,
    email: string | undefined,
    name: string | undefined,
    surname: string | undefined,
    phoneNumber: string | undefined | null,
    isLoggedInWithFacebook: boolean | undefined,
    facebookProfilePicture: string | undefined,
    libertyPointsAvailableBalance: number | undefined,
    stackedLibertyPointsBalance: number | undefined,
    federateds: {
        cognito: boolean | undefined,
        facebook: boolean | undefined
    },
    missions: {
        facebook: boolean | undefined, 
        app: boolean | undefined, 
        betfair: boolean | undefined
    },
    products: {
        courses: any,
        subscriptions: any,
        lessons: any,
        automatic: any,
        highway: {
            active: boolean | undefined,
            assigned: boolean | undefined,
            subscriptionId: number | undefined
        }
    },
    roles: [
        {
            id: number | undefined,
            permissions: [
                {
                    brand: string | undefined,
                    description: string | undefined,
                    name: string | undefined,
                    noun: string | undefined,
                    verb: string | undefined
                }
            ],
            role: string | undefined
        }
    ] | undefined,
    newNotifications: number | undefined,
    isUserDisplayingFromAppMobile: boolean | undefined,
    offers: any | undefined,
    automatiIncomes : {
        licenseAmount: number | undefined
    },
    badges: [
        {
            badge: string | undefined
        }
    ] | undefined,
    productsLessonsStatus: {
            id: number | undefined,
            userId: number | undefined,
            lessonId: number | undefined,
            // currentSecond: number | undefined,
            completed: boolean | undefined,
            lastSeenDate: string | undefined,
            favorite: boolean | undefined,
            courseId: number | undefined

        } [] | undefined,
    warning: {
        id: number | undefined,
        color: string | undefined,
        copy: string | undefined,
        productId: number | undefined,
        sellImage: {
            id: number | undefined,
            fileName: string | undefined,
            url: string | undefined,
            fileType: {
                id: number | undefined,
                name: string | undefined,
                relativePath: string | undefined
            }
        },
        productType: string | undefined
    } | undefined,
    messagesNotSeen: number | undefined
};

// initial slice state
const initialState: UserState = {

    tokenID: undefined,
    clientId: undefined,
    email: undefined,
    name: undefined,
    surname: undefined,
    phoneNumber: undefined,
    isLoggedInWithFacebook: undefined,
    facebookProfilePicture: undefined,
    libertyPointsAvailableBalance: undefined,
    stackedLibertyPointsBalance: undefined,
    federateds: {
        cognito: undefined,
        facebook: undefined
    },
    missions: {
        facebook: undefined,
        app: undefined,
        betfair: undefined
    },
    products: {
        courses: undefined,
        subscriptions: undefined,
        lessons: undefined,
        automatic: undefined,
        highway: {
            active: undefined,
            assigned: undefined,
            subscriptionId: undefined
        }
    },
    roles: [
        {
            id: undefined,
            permissions: [
                {
                    brand: undefined,
                    description: undefined,
                    name: undefined,
                    noun: undefined,
                    verb: undefined
                }
            ],
            role: undefined
        }
    ],
    newNotifications: undefined,
    isUserDisplayingFromAppMobile: undefined,
    offers: undefined,
    automatiIncomes: {
        licenseAmount: undefined
    },
    badges: [
        {
            badge: undefined
        }
    ],
    productsLessonsStatus: undefined,
    warning: undefined,
    messagesNotSeen: undefined
};

const userSlice = createSlice(
    {
        name: 'user',
        initialState,

        reducers: {

            setTokenID: (state, action: PayloadAction<string | undefined | null>) => {
                state.tokenID = action.payload;
            },

            setClientId: (state, action: PayloadAction<number | undefined>) => {
                state.clientId = action.payload;
            },

            setEmail: (state, action: PayloadAction<string | undefined>) => {
                state.email = action.payload;
            },

            setName: (state, action: PayloadAction<string | undefined>) => {
                state.name = action.payload;
            },

            setSurname: (state, action: PayloadAction<string | undefined>) => {
                state.surname = action.payload;
            },

            setPhoneNumber: (state, action: PayloadAction<string | undefined | null>) => {
                state.phoneNumber = action.payload;
            },

            setIsLoggedInWithFacebook: (state, action: PayloadAction<boolean | undefined>) => {
                state.isLoggedInWithFacebook = action.payload;
            },

            setFacebookProfilePicture: (state, action: PayloadAction<string | undefined>) => {
                state.facebookProfilePicture = action.payload;
            },

            setLibertyPointsAvailableBalance: (state, action: PayloadAction<number | undefined>) => {
                state.libertyPointsAvailableBalance = action.payload;
            },

            setStackedLibertyPointsAvailableBalance: (state, action: PayloadAction<number | undefined>) => {
                state.stackedLibertyPointsBalance = action.payload;
            },

            setFederatedWithCognito: (state, action: PayloadAction<boolean | undefined>) => {
                state.federateds.cognito = action.payload;
            },

            setFederatedWithFacebook: (state, action: PayloadAction<boolean | undefined>) => {
                state.federateds.facebook = action.payload;
            },

            setFacebookMission: (state, action: PayloadAction<boolean | undefined>) => {
                state.missions.facebook = action.payload;
            },

            setAppMission: (state, action: PayloadAction<boolean | undefined>) => {
                state.missions.app = action.payload;
            },

            setBetfairMission: (state, action: PayloadAction<boolean | undefined>) => {
                state.missions.betfair = action.payload;
            },

            setCourses: (state, action: PayloadAction<any>) => {
                state.products.courses = action.payload;
            },

            setSubscriptions: (state, action: PayloadAction<any>) => {
                state.products.subscriptions = action.payload;
            },

            setLessons: (state, action: PayloadAction<any>) => {
                state.products.lessons = action.payload;
            },

            setAutomatic: (state, action: PayloadAction<any>) => {
                state.products.automatic = action.payload;
            },

            setHighwayActive: (state, action: PayloadAction<boolean | undefined>) => {
                state.products.highway.active = action.payload;
            },
            setHighwayAssigned: (state, action: PayloadAction<boolean | undefined>) => {
                state.products.highway.assigned = action.payload;
            },

            setHighwaySubscriptionId: (state, action: PayloadAction<number | undefined>) => {
                state.products.highway.subscriptionId = action.payload;
            },

            setUserRoles: (state, action: PayloadAction<[
                {
                    id: undefined,
                    permissions: [
                        {
                            brand: undefined,
                            description: undefined,
                            name: undefined,
                            noun: undefined,
                            verb: undefined
                        }
                    ],
                    role: undefined
                }
            ] | undefined>) => {
                state.roles = action.payload;
            },
            
            setNewNotifications: (state, action: PayloadAction<number | undefined>) => {
                state.newNotifications = action.payload;
            },
            
            setIsUserDisplayingFromAppMobile: (state, action: PayloadAction<boolean | undefined>) => {
                state.isUserDisplayingFromAppMobile = action.payload;
            },
            
            setIsUserOffers: (state, action: PayloadAction<boolean | undefined>) => {
                state.offers = action.payload;
            },
            
            setLicenseAmount: (state, action: PayloadAction<number | undefined>) => {
                state.automatiIncomes.licenseAmount = action.payload;
            },
            
            setBadges: (state, action: PayloadAction<[{ badge: any | undefined}] | undefined>) => {
                state.badges = action.payload;
            },
            
            setProductsLessonsStatus: (state, action: PayloadAction<{
                id: number | undefined,
                userId: number | undefined,
                lessonId: number | undefined,
                // currentSecond: number | undefined,
                completed: boolean | undefined,
                lastSeenDate: string | undefined,
                favorite: boolean | undefined,
                courseId: number | undefined
            }[] | undefined>) => {
                state.productsLessonsStatus = action.payload;
            },

            setUserWarnings: (state, action: PayloadAction< {
                    id: number | undefined,
                    color: string | undefined,
                    copy: string | undefined,
                    productId: number | undefined,
                    sellImage: {
                        id: number | undefined,
                        fileName: string | undefined,
                        url: string | undefined,
                        fileType: {
                            id: number | undefined,
                            name: string | undefined,
                            relativePath: string | undefined
                        }
                    },
                    productType: string | undefined
                } | undefined>) => {
                state.warning = action.payload;
            },

            setMessagesNotSeen: (state, action: PayloadAction<number | undefined>) => {
                state.messagesNotSeen = action.payload;
            },
        }
    }
);

// slice actions mapping
export const {
    
    setTokenID,
    setClientId,
    setEmail,
    setName,
    setSurname,
    setPhoneNumber,
    setIsLoggedInWithFacebook,
    setFacebookProfilePicture,
    setLibertyPointsAvailableBalance,
    setStackedLibertyPointsAvailableBalance,
    setFederatedWithCognito,
    setFederatedWithFacebook,
    setFacebookMission,
    setAppMission,
    setBetfairMission,
    setCourses,
    setSubscriptions,
    setLessons,
    setAutomatic,
    setHighwayActive,
    setHighwayAssigned,
    setHighwaySubscriptionId,
    setUserRoles,
    setNewNotifications,
    setIsUserDisplayingFromAppMobile,
    setIsUserOffers,
    setLicenseAmount,
    setBadges,
    setProductsLessonsStatus,
    setUserWarnings,
    setMessagesNotSeen
} = userSlice.actions;

// reducer for store
export default userSlice.reducer;
