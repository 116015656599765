import { FC, useEffect, useState, useRef } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';
import useDraggableScroll from 'hooks/useDraggableScroll';

//components 
import BaseFieldset from 'components/ui/BaseFieldset';

//assets
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';

//types
import { BestOddsMultipleProps } from './BestOddsMultiple.component.d';

//style
import {BestOddsMultipleContainer, MultipleContainer, OddsTotalValue, OddsValue, SelectionAndEventContainer, Selection, Team, EventContainer, RemoveButton} from './style/BestOddsMultiple.style'
import { HorizontalScrollContainer, SiteBox, SiteLogo, SiteLogoContainer, ToolButton, ToolButtons } from 'pages/OddsScasserPage/style/OddsScasserPage.style';
import { Row } from 'style/layout';

const BestOddsMultiple: FC<BestOddsMultipleProps> = ({ multiple, removeFromMultiple, visible, changeVisibility, copyToClipboard }) => {
    const { sites } = useAppSelector(state => state.oddsScasser);

    const ref = useRef(null);
    const { onMouseDown } = useDraggableScroll(ref, 'horizontal');
    
    const [orderedSites, setOrderedSites] = useState<number[][]>([]);

    useEffect(() => {
        const arr: number[][] = [];
        multiple.sites.forEach((value, key) => {
            if(value.every(x => !!x)) arr.push([key, value.reduce((p,x) => p*(x ? x.odds : 1),1)]);
            return;
        });
        arr.sort((x,y) => y[1]-x[1]);
        setOrderedSites(arr);
    }, [multiple])
    
    return(
        <BestOddsMultipleContainer>
            <BaseFieldset legend="Multipla" padding={10} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' openIcon={!visible} onOpenClick={!visible ? changeVisibility : undefined}>
                {visible && <ToolButtons><ToolButton width='18px' onClick={changeVisibility}><CloseSVG/></ToolButton></ToolButtons>}
                {visible &&
                    <HorizontalScrollContainer ref={ref} onMouseDown={onMouseDown}>
                        <MultipleContainer>
                            <Row gap='5px' mainAxis='center'>
                                <SiteBox width={75}/>
                                <OddsTotalValue/>
                                {multiple.events.map((event,index) => {
                                    const sportColor = event.league?.sport?.id === 1 ? '#1b7700' : (event.league?.sport?.id === 2 ? '#bf9a17' : '#91311d');
                                    return(
                                        <SelectionAndEventContainer key={index} >
                                            <EventContainer>
                                                <Team>{event.home?.name}</Team>
                                                <Team>{event.away?.name}</Team>
                                            </EventContainer>
                                            <Selection backgroundColor={sportColor}>{multiple.selections[index]}</Selection>
                                            <RemoveButton onClick={() => removeFromMultiple(index)}>Rimuovi</RemoveButton>
                                        </SelectionAndEventContainer>
                                    );
                                })}
                            </Row>
                            {orderedSites.map((element,index) => {
                                const site = sites.get(element[0]);
                                const odds = multiple.sites.get(element[0]);
                                if(site && odds) {
                                    return(
                                        <Row key={index} gap='5px' mainAxis='center'>
                                            <SiteBox checked={true} width={75} height={22}>
                                                <SiteLogoContainer visible={true} backgroundColor={site.color}><SiteLogo imageUrl={site.imageUrl}/></SiteLogoContainer>
                                            </SiteBox>
                                            <OddsTotalValue>{element[1].toFixed(2)}</OddsTotalValue>
                                            {odds.map((x, i) => {
                                                return(
                                                    <OddsValue key={'O'+i} notClickable={!x || x.url===undefined} onClick={() => x && x.url && copyToClipboard(x.url, `URL ${site.name} copiato`)}>{x ? x.odds.toFixed(2) : '-'}</OddsValue>
                                                );
                                            })}
                                        </Row>
                                    );
                                }
                            })}
                        </MultipleContainer>
                    </HorizontalScrollContainer>
                }
            </BaseFieldset>
        </BestOddsMultipleContainer>
    );
}

export default BestOddsMultiple;