import styled, { css } from 'styled-components';
import colors from 'style/palette/palette';
import { InputProps } from './TextInputWithLabel.style.d';

export const InputLabelBox = styled.div<InputProps>`
    
    display: flex;
    width: ${(props) => props.width ? props.width : (props.labelWidth!==undefined && props.inputWidth!==undefined ? ((props.label ? props.labelWidth : 0) + props.inputWidth + (props.gap ? props.gap : 0))+'px' : (props.label ? 'auto' : '60px'))};
    column-gap: ${(props) => props.gap ? props.gap+'px' : '0px'};
    height: ${(props) => props.height ? props.height+'px' : '36px'};
    border-radius: 5px;
    position: relative;
    transition: .3s ease-out all;
    opacity: ${(props) => props.isDisabled ? '0.5' : '1'};
    pointer-events: ${(props) => props.isDisabled ? 'none' : 'auto'};

`;

export const Label = styled.div<InputProps>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.labelWidth ? props.labelWidth+'px' : (90-(props.gap ? props.gap/2 : 0))+'px'};
    border: ${(props) => props.focus ? '1px solid #202a31' : '1px solid colors.grey'};
    border-radius: ${(props) => props.gap ? '5px' : '5px 0px 0px 5px'};
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    /* text-transform: uppercase; */
    color: white;
    /* color: ${(props) => props.isInputInvalid ? colors.red : colors.white}; */
    background-color: ${(props) => props.focus && !props.isDisabled ? '#202a31' : colors.grey};
    transition: .3s ease-out all;
    cursor: default;
    padding: 3px;

`;

export const Input = styled.input<InputProps>`

    min-width: 0;
    width: ${(props) => props.width ? props.width : (props.inputWidth ? props.inputWidth+'px' : 'calc(100% - '+((props.label ? 90 : 0)-(props.gap ? props.gap/2 : 0))+'px)')};

    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    text-align: ${(props) => props.textAlign ?? 'center'};

    border: 1px solid ${colors.grey};
    border-radius: ${(props) => props.gap || !props.label ? '5px' : '0px 5px 5px 0px'};
    background: #375e7a;
    color: ${colors.white};
    transition: .3s ease-out all;
    padding-left: 5px;
    :focus {
        border: 1px solid #202a31;
    }
    ${(props) => props.isInputInvalid && (
        css`
            background: #8F4D53;
        `
    )}
    :focus-visible {
        outline: none;
    }

    :read-only {
        color: ${colors.white056};
        cursor: default;
    }

    ::placeholder {
        color: #c3c3c3;
    }
`;
