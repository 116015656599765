import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette';

export const OddsScasserContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: 1100px;
    align-items: center;
    width: 100%;

`;

export const ToolsContainer = styled.div<{direction?: 'row'|'column', allNotVisible?: boolean}>`
    
    display: flex;
    flex-direction: ${props => props.direction ?? 'row'};
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    width: fit-content;
    max-width: 100%;
    align-items: center;
`;
