import styled from 'styled-components'; 

import { Button as BaseButton } from 'style/buttons';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Page = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 40px 20px 20px;

    @media screen and (min-width: 500px) {

        padding: 40px 0 0 0;

        width: auto;
    }
`;

export const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 350px;
    height: 278px;
    
    @media screen and (min-width: 500px) {
        
        padding: 0px;

        width: 750px;
        height: 448.67px;
    }
`;

export const Title = styled.p`
    display: flex;
    align-items: center;
    text-align: center;

    width: 350px;
    height: 53px;
    margin: 0px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    color: ${colors.white};

    @media screen and (min-width: 500px) {

        width: 563px;
        height: 27px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;

        color: ${colors.white};
    }
`;

export const CardMedia = styled.div`

    box-sizing: border-box;

    width: 350px;
    height: 200px;

    border-bottom: 1px solid ${colors.white050};
    border-radius: 20px;
    margin: 25px 0px 0px 0px;

    overflow: hidden;

    @media screen and (min-width: 500px) {

        box-sizing: border-box;

        width: 750px;
        height: 396.67px;

    }
`;

export const Card = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 40px 30px;
    gap: 30px;
    margin: 40px 0 0 0;

    width: 350px;

    box-shadow: 0px 3px 1px -2px ${colors.black020}, 0px 2px 2px ${colors.black014}, 0px 1px 5px ${colors.black012};
    border-radius: 15px;

    background: ${colors.darkGrey};

    @media screen and (min-width: 500px) {

        padding: 40px 30px;

        width: 750px;

        background: ${colors.darkGrey};
    }
`;

export const Desc = styled.p`

    /* width: 290px; */
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: ${colors.white};
    
    span {
        color: ${colors.primaryColor};        
    }
    @media screen and (min-width: 500px) {

        /* width: 536px; */

        line-height: 28px;
    }
`;

export const Button = styled(BaseButton)`

    padding: 11px 20px;

    background: ${colors.blue};
    color: ${colors.white};

    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    gap: 10px;

    cursor: pointer;

    > svg:first-child {
        flex-shrink: 0;
    }

    @media ${devices.tablet} {

        padding: 11px 20px;
        gap: 8px;
    }
`;
