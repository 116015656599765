import { FC } from 'react';

// types
import { EmptyImageProps } from './EmptyImage.d';

// style
import {
    Frame, 
    CircleFrameRef
} from './style/EmptyImage.style';


/**
 * Empty image placeholder
 * 
 * @author Frank Berni
 * 
 * @param isSmall - small size 40px x 40px circle frame
 * 
 * @returns JSX
 */


const EmptyImage: FC<EmptyImageProps> = ({
        isCircular,
        isProductBox
    }) => {

    return (
        <CircleFrameRef>
            <Frame
                isCircular={isCircular}
                isProductBox={isProductBox}
            >
                Immagine non disponibile
            </Frame>
        </CircleFrameRef>
    );
};

export default EmptyImage;
