import styled, { css } from 'styled-components';
import devices from '../breakpoints';
import colors from 'style/palette/palette';
import { CardTextProps } from './buttons.d';

export const Button = styled.button`

    width: auto;

    margin: 0;
    padding: 12px 30px;

    background: ${colors.primaryColor};

    border: none;
    border-radius: 4px;
    box-shadow:
        0 3px 1px -2px ${colors.black020},
        0 2px 2px ${colors.black014},
        0 1px 5px ${colors.black012};

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    line-height: 13px;
    font-weight: 600;
    color: ${colors.black087};
    letter-spacing: 0.4px;
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;

    :disabled {

        background: ${colors.white050};
        color: ${colors.black087};

        cursor: default;
    }

    @media ${devices.tablet} {

        font-size: 15px;
        padding: 15px 20px;
    }
`;

export const HomePageLDLButton = styled.button<CardTextProps>`

    margin: 12px 0 0;
    padding: 12px;

    border:0.5px solid ${colors.primaryColor};
    border-radius: 5px;
    box-shadow: 
        0px 3px 1px -2px ${colors.black020},
        0px 2px 2px ${colors.black014},
        0px 1px 5px ${colors.black012};
    background: linear-gradient(180deg, #464A4F 0%, #1C2023 100%);
    
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    color: ${colors.primaryColor};

    cursor: pointer;

    :disabled {

        background: ${colors.white050};
        color: ${colors.black087};

        cursor: default;
    }

    ${({isComplete}) => isComplete &&
    
        css`

            box-shadow: none;
            background: ${colors.transparent};
            color: ${colors.green038};
        `
    }
`;
