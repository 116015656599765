import { FC, useEffect, useState } from 'react';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//routing
import { useNavigate, useSearchParams } from 'react-router-dom';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useIsMounted } from 'usehooks-ts';

// utils
import { getCurrentAuthUser } from 'services/user.services';
import { 
    checkForHighway,
    getHighwayId,
    getHighwayLessons,
    getNewHighwayLesson,
    setPersonalAssistant
} from './services/HighwayPage.services';

// components
import HighwayPageMobile from './layout/HighwayPageMobile/HighwayPageMobile.component';
import HighwayPageDesktop from './layout/HighwayPageDesktop/HighwayPageDesktop.component';
import LoadingMask from 'components/ui/LoadingMask';


/**
 * Product Page
 * 
 * @author Frank Berni
 * 
 * @returns JSX
 */


const HighwayPage: FC<{}> = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const { active: isHighwayActive, assigned: isHighwayAssigned, subscriptionId: highwaySubscriptionId } = useAppSelector(state => state.user.products.highway);
    const [isPersonalAssistantNotesModalVisible, setIsPersonalAssistantNotesModalVisible] = useState<boolean>(false);
    const [isCustomerRatingModalVisible, setIsCustomerRatingModalVisible] = useState<boolean>(false);
    const [lessons, setLessons] = useState<any>(undefined);
    const [hasUserClosedLesson, setHasUserCloedLesson] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openPersonalAsistantNotesModal = () => setIsPersonalAssistantNotesModalVisible(true);
    const closePersonalAsistantNotesModal = () => setIsPersonalAssistantNotesModalVisible(false);
    const openCustomerRatingModal = () => setIsCustomerRatingModalVisible(true);
    const closeCustomerRatingModal = () => setIsCustomerRatingModalVisible(false);
    const refreshDataHandler = () => setHasUserCloedLesson(true);

    const isDatePickerButtonCompleted = undefined;
    const isZoomLinkButtonCompleted = false;
    const isCommentButtonCompleted = true;
    const isCustomerRatingButtonCompleted = true;

    // const checkHighway =async () => {
        
    //     try {
    //         const userData = await getCurrentAuthUser();
    //         if (!isMounted) return;
            
    //         if (!userData || userData === undefined) {
    //             throw new Error ("User Data response undefined.");
    //         }

    //         const checkResponse = await checkForHighway({idCliente: userData.id});
    //         if (!isMounted) return;
            
    //         if (!checkResponse || checkResponse === undefined) {
    //             throw new Error ("Highway check response undefined.");
    //         }

    //         if (checkResponse.length > 0) await highwayLessons(userData.id);         
    //     } catch (error: any) {
    //         if (error.message === '403') navigate('/fallback-page/permission-denied');
    //         console.warn("Not able to check Highway.", error);
    //     }
    // };

    const highwayLessonsData = async (userId?: number) => {

        try {
            // const highwayId = await getHighwayId({idCliente: userId});
            // if (!isMounted) return;
            
            // if (!highwayId || highwayId === undefined) {
            //     throw new Error ("Highway ID response undefined.");
            // }

            // if (highwayId.length === 0) return;
            
            const highwayLessons = await getHighwayLessons()
            setLessons(highwayLessons);                     
        } catch (error: any) {
            if (error.message === '403') navigate('/fallback-page/permission-denied');
            console.warn("Not able to check Highway Lessons.", error);
        }
    };

    const getNewLesson = async () => {

        if (highwaySubscriptionId === undefined) return;
        const snackbarData: SnackbarOptions = {};

        try {

            // const userData = await getCurrentAuthUser();
            // if (!isMounted) return;
            
            // if (!userData || userData === undefined) {
            //     throw new Error ("User Data response undefined.");
            // }

            // const highwayId = await getHighwayId({idCliente: userData.id});
            // if (!isMounted) return;
            
            // if (!highwayId || highwayId === undefined) {
            //     throw new Error ("Highway ID response undefined.");
            // }

            // if (highwayId.length === 0) return;
            
            await getNewHighwayLesson(highwaySubscriptionId);

            const highwayLessons = await getHighwayLessons();
            setLessons(highwayLessons);                     

            snackbarData.type = 'success';
            snackbarData.message = "Nuova Lezione aggiunta!";
            dispatch( addSnackbar(snackbarData) );
        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            snackbarData.type = 'error';
            snackbarData.message = ("C'è stato un errore nell'aggiungere la nuova lezione.");
            dispatch( addSnackbar(snackbarData) );

            throw error;
        }
    };
    
    useEffect(() => {
        highwayLessonsData();
    }, [hasUserClosedLesson]);

    // console.log("LESSONS:", lessons);
    
    if ((lessons === undefined) || (lessons.productLessonPack === undefined)) return (
        <LoadingMask
            isCenter
            paddingTop='200px'
            size='page'
        />
    );

    if (!isAppMobile) {
        return (
            <HighwayPageDesktop 
                isPersonalAssistantNotesModalVisible={isPersonalAssistantNotesModalVisible}
                isCustomerRatingModalVisible={isCustomerRatingModalVisible}
                openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
                closePersonalAsistantNotesModal={closePersonalAsistantNotesModal}
                openCustomerRatingModal={openCustomerRatingModal}
                closeCustomerRatingModal={closeCustomerRatingModal}
                isDatePickerButtonCompleted={isDatePickerButtonCompleted}
                isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
                isCommentButtonCompleted={isCommentButtonCompleted}
                isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
                lessons={lessons}
                newLessonHandler={getNewLesson}
                refreshDataHandler={refreshDataHandler}
            />
        );
    }

    return (
        <HighwayPageMobile
            isPersonalAssistantNotesModalVisible={isPersonalAssistantNotesModalVisible}
            isCustomerRatingModalVisible={isCustomerRatingModalVisible}
            openPersonalAsistantNotesModal={openPersonalAsistantNotesModal}
            closePersonalAsistantNotesModal={closePersonalAsistantNotesModal}
            openCustomerRatingModal={openCustomerRatingModal}
            closeCustomerRatingModal={closeCustomerRatingModal}
            isDatePickerButtonCompleted={isDatePickerButtonCompleted}
            isZoomLinkButtonCompleted={isZoomLinkButtonCompleted}
            isCommentButtonCompleted={isCommentButtonCompleted}
            isCustomerRatingButtonCompleted={isCustomerRatingButtonCompleted}
            lessons={lessons}
            newLessonHandler={getNewLesson}
            refreshDataHandler={refreshDataHandler}
        />
    );
};

export default HighwayPage;
