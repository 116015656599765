import brandMap from './brandMap';
import { BrandMapObject, BrandMappers } from './brandMap.d';
import { Location } from 'history';


//store
import store from 'redux/store';

//types
import { Brand } from 'types/Brand';

const getCurrentPageAddress = (): string => {
    return window.location.href;
};

export const getBrand = (): Brand | undefined => {
    const state = store.getState();
    return state.ui.brand; // Adjust this path according to your state structure
};

const isBrandInKey = (key: string, brand: string, brandMap: BrandMappers): boolean => {
    const item = brandMap.find((item: BrandMapObject) => item.key === key);
    return item?.brands.includes(brand) ?? false;
};

const isBrandInBrandArray = (brand: string, brandArray: Brand[]): boolean => {
    const items: Brand | undefined = brandArray.find((item: Brand) => item.code === brand);
    return !(!items);
};

export const checkBrandArray = (brandArray: Brand[]): boolean => {
    const brand: Brand | undefined = getBrand();
    if (!brand) return false;
    const check = isBrandInBrandArray(brand.code, brandArray);
    return check;
}

export const checkBrandMap = (key: string) => {
    const brand: Brand | undefined = getBrand();
    if (!brand) return true;
    const check = isBrandInKey(key, brand.code, brandMap);
    return check;
}

export const checkActualUrlforBrand = () => {
    const location: string = getCurrentPageAddress();
    const segments: string[] = location.split('/'); 
    const reservedAreaIndex: number = segments.findIndex(segment => segment === 'reservedArea');
    const fallbackIndex: number = segments.findIndex(segment => segment === 'fallback-page');

    const hasContentAfterReservedArea: boolean = reservedAreaIndex >= 0 && reservedAreaIndex < (segments.length - 1);

    if (fallbackIndex > 0) return true;
    if (!hasContentAfterReservedArea) return true;

    let check: boolean = false;
    let unprotected: boolean = true;

    brandMap.forEach((item: BrandMapObject) => {
        if (location.includes('/'+item.key)) {
            unprotected = false;
            check = checkBrandMap(item.key); 
        }
    });
    
    // console.log("RETURNING ",check || unprotected);

    return check || unprotected;
    
}

export const appendBrandToUrl = (originalUrl: string, location: Location): string => {
    const isLocalhost = window.location.hostname === 'localhost';
    let modifiedUrl = originalUrl;
  
    if (isLocalhost) {
      const searchParams = new URLSearchParams(location.search);
      const brand = searchParams.get('brand');
  
      if (brand) {
        const newSearchParams = new URLSearchParams({ brand });
        const delimiter = originalUrl.includes('?') ? '&' : '?';
        modifiedUrl = `${originalUrl}${delimiter}${newSearchParams}`;
      }
    }
  
    return modifiedUrl;
  };