import { FC, useEffect, useState, useMemo, Fragment } from 'react';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { getItemFromLocalStorage } from 'utils/localStorage';

//components
import SwitchSelectorBig from 'components/SwitchSelectorBig';
import SwitchSelector from 'components/SwitchSelector/SwitchSelector.componet';
import NumberInput from 'components/ui/NumberInput';
import BaseFieldset from 'components/ui/BaseFieldset';

//assets
import { ReactComponent as ArrowGoBackSVG } from 'pages/OddsScasserPage/assets/arrowGoBack.svg';
import { ReactComponent as CloseSVG } from 'pages/OddsScasserPage/assets/close.svg';

//types
import { SingleProps, ResultsProps } from './Single.component.d';
import { NumberInputState } from 'components/ui/NumberInput/types/NumberInputState';

//style
import { 
    SingleContainer,
    Grid, 
    Header,
    BetInfoHeaderElement,
    BetInfo,
    AvgResult, 
    PartiallyMatchedContainer, 
    PartiallyMatchedRow, 
    PartiallyMatchedQuestion, 
    BetsContainer
} from './style/Single.style'
import { Col } from 'style/layout';

//helper functions
import { emptyResults, calculatePuntaPunta, calculatePuntaBanca, stepIntegers, stepRound, stepOdds, stepEach, stepOddsPP } from 'pages/OddsScasserPage/OddsScasserPage.helper';
import { BetContainer, Bet, Resp, WinnigsAndRating, Rating, Winnings, ToolButton, ToolButtons } from 'pages/OddsScasserPage/style/OddsScasserPage.style';


const  Single: FC<SingleProps> = ({visible, changeVisibility}) => {
    const { isAppMobile } = useAppSelector(state => state.ui);

    //Mode
    const [mode, setMode] = useState<'puntabanca' | 'puntapunta'>('puntabanca');
    const onModeSwitch = (isOption1Active: boolean) => setMode(isOption1Active ? 'puntabanca' : 'puntapunta');
    //NumberInputs
    const [bet, setBet] = useState<NumberInputState>({value: ''});
    const [bonus, setBonus] = useState<NumberInputState>({value: ''});
    const [refund, setRefund] = useState<NumberInputState>({value: ''});
    const [isFreebet, setIsFreebet] = useState<boolean>(false);
    const [fees, setFees] = useState<NumberInputState>({value: getItemFromLocalStorage('feesBetfair') ?? '4.5'});
    const [outcomes, setOutcomes] = useState<NumberInputState>({value: '2'});
    const [roundPuntaBanca, setRoundPuntaBanca] = useState<NumberInputState>({value: '0.01'});
    const [roundPuntaPunta, setRoundPuntaPunta] = useState<NumberInputState>({value: '1'});
    const [odds, setOdds] = useState<NumberInputState[]>(Array.from({length: 9}, () => ({value: ''})));
    //Partially matched
    const [isPartiallyMatched, setIsPartiallyMatched] = useState<boolean>(false);
    const [matched, setMatched] = useState<NumberInputState>({value: ''});
    const [newOdds, setNewOdds] = useState<NumberInputState>({value: ''});
    
    const handleOddsChange = (index: number, newState: NumberInputState) => {
        setOdds(prevState => prevState.map((x,i) => i===index ? newState : x));
    }
    
    useEffect(() => {
        if(isFreebet && !bet.errorMessage) {
            setRefund({value: bet.value});
            setBonus({value: ''});
        }
    }, [isFreebet, bet]);

    const handleIsFreebetChange = () => {
        setIsFreebet(prevState => {
            if(prevState) setRefund({value: ''});
            return !prevState;
        })
    }

    const results: ResultsProps = useMemo(() => {
        if(mode === 'puntapunta') {
            if(bet.errorMessage || parseFloat(bet.value)<0.01 || bonus.errorMessage || refund.errorMessage || outcomes.errorMessage || roundPuntaPunta.errorMessage || odds.some(((x, i) => i<parseInt(outcomes.value) && x.errorMessage))) return emptyResults;
            else return calculatePuntaPunta(parseFloat(bet.value), isFreebet ? 0 : parseFloat(bonus.value) || 0,  parseFloat(isFreebet ? bet.value : refund.value) || 0, odds.slice(0, parseInt(outcomes.value)).map(x => parseFloat(x.value)), parseFloat(roundPuntaPunta.value));
        } else {
            if(bet.errorMessage || parseFloat(bet.value)<0.01 || bonus.errorMessage || refund.errorMessage || fees.errorMessage || roundPuntaBanca.errorMessage || odds.some(((x, i) => i<2 && x.errorMessage))) return emptyResults;// || (isPartiallyMatched && (matched.errorMessage || newOdds.errorMessage))
            return calculatePuntaBanca(parseFloat(bet.value), isFreebet ? 0 : parseFloat(bonus.value) || 0, parseFloat(isFreebet ? bet.value : refund.value) || 0, parseFloat(fees.value)/100, odds.slice(0, 2).map(x => parseFloat(x.value)), parseFloat(roundPuntaBanca.value), isPartiallyMatched && !matched.errorMessage ? parseFloat(matched.value) : undefined, isPartiallyMatched && !newOdds.errorMessage ? parseFloat(newOdds.value) : undefined);
        }
    }, [mode, bet, bonus, refund, isFreebet, outcomes, fees, roundPuntaBanca, odds, isPartiallyMatched, matched, newOdds, roundPuntaPunta])

    return(
        <BaseFieldset legend="Singola" padding={10} fitContent={true} borderColor='#ffffff94' legendColor='white' backgroundColor='#00000017' openIcon={!visible} onOpenClick={!visible ? changeVisibility : undefined}>
            {visible &&
                <ToolButtons>
                    <ToolButton width='18px' backgroundColor='#800000' onClick={changeVisibility}><CloseSVG/></ToolButton>
                </ToolButtons>
            }
            {visible && 
                <SingleContainer>
                    <Header><SwitchSelectorBig isOption1Active={mode==='puntabanca'} mode='standard' option1='Punta Banca' option2='Punta Punta' onSwitch={onModeSwitch}/></Header>
                    <Grid>
                        <Col gap='5px' mainAxis='center'>
                            <NumberInput label='Puntata' value={bet.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBet(newState)}/>
                            <NumberInput label='di cui Bonus' isDisabled={isFreebet} value={isFreebet ? bet.value : bonus.value} minValue={0} maxValue={parseFloat(bet.value) || 0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setBonus(newState)}/>
                        </Col>
                        <Col gap='5px' mainAxis='center'>
                            <NumberInput label='Rimborso' value={refund.value} minValue={0} includeLimits={true} allowEmpty={true} stepFunction={stepIntegers} isDisabled={isFreebet} isDisabledOpacity={0} onChange={(newState: NumberInputState) => setRefund(newState)}/>
                            <SwitchSelector isFilterActive={isFreebet} height={36} filterHandler={handleIsFreebetChange} label="Freebet"/>
                        </Col>
                        {mode === 'puntabanca' && <NumberInput label='Commissioni' value={fees.value} minValue={0} maxValue={99.99} includeLimits={true} stepFunction={(value, direction) => stepEach(0.5, value, direction)} onChange={(newState: NumberInputState) => setFees(newState)}/>}
                        {mode === 'puntapunta' && <NumberInput label='# Esiti' value={outcomes.value} minValue={2} maxValue={9} includeLimits={true} stepFunction={stepIntegers} noDecimals={true} onChange={(newState: NumberInputState) => setOutcomes(newState)}/>}
                        {
                            mode === 'puntabanca'
                            ? <NumberInput label='Arrotonda a' value={roundPuntaBanca.value} minValue={0.01} includeLimits={true} stepFunction={stepRound([0.01, 0.5, 1])} onChange={(newState: NumberInputState) => setRoundPuntaBanca(newState)}/>
                            : <NumberInput label='Arrotonda a' value={roundPuntaPunta.value} minValue={0.01} includeLimits={true} stepFunction={stepRound([1, 5, 10])} onChange={(newState: NumberInputState) => setRoundPuntaPunta(newState)}/>
                        }
                    </Grid>
                    <BetsContainer>
                        <BetInfo gridTemplateColumn={isAppMobile ? '80px 60px 75px' : '80px 60px 75px 75px'}  marginBottom='4px'>
                            <BetInfoHeaderElement></BetInfoHeaderElement>
                            <BetInfoHeaderElement>Quota</BetInfoHeaderElement>
                            <BetInfoHeaderElement>Importo</BetInfoHeaderElement>
                            {!isAppMobile && <BetInfoHeaderElement>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF' : 'Rating'}</BetInfoHeaderElement>}
                        </BetInfo>
                        { odds.map((element, index) =>  {
                            if((mode === 'puntabanca' && index<2) || (mode === 'puntapunta' && index<parseInt(outcomes.value)))
                                return (
                                    <Fragment key={index}>
                                        <BetInfo gridTemplateColumn={isAppMobile ? '150px 75px' : '150px 75px 75px'} marginBottom={isAppMobile ? undefined : '4px'}>
                                            <NumberInput label={index===0 || mode === 'puntapunta' ? 'Punta' : 'Banca'} value={element.value} minValue={1} includeLimits={false} decimals={2} stepFunction={(index===1 && mode === 'puntabanca') ? stepOdds : stepOddsPP} gap={5} onChange={(newState: NumberInputState) => handleOddsChange(index, newState)}/>
                                            <BetContainer boxed={true}>
                                                <Bet translateY={mode === 'puntabanca' && index>0 ? 5 : 0}>{results.bets.length>index ? '€'+results.bets[index].toFixed(2) : '€-'}</Bet>
                                                {mode === 'puntabanca' && index>0 && <Resp translateY={2}>{results.resp && results.resp.length>index ? '€'+results.resp[index].toFixed(2) : '€-'}</Resp>}
                                            </BetContainer>
                                            {!isAppMobile &&
                                                <WinnigsAndRating flexDirection='column' boxed={false}>
                                                    <Winnings amount={results.winnings.length>index ? results.winnings[index] : 0}>€{results.winnings.length>index ? results.winnings[index].toFixed(2) : '-'}</Winnings>
                                                    <Rating>{results.ratings.length>index ? results.ratings[index].toFixed(2) : '-'}%</Rating>
                                                </WinnigsAndRating>
                                            }
                                        </BetInfo>
                                        {isAppMobile &&
                                            <WinnigsAndRating flexDirection='row' boxed={false} justifyContent='flex-end' padding='0 0 5px 0'>
                                                {(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : 'Rating: '}
                                                <Winnings amount={results.winnings.length>index ? results.winnings[index] : 0}>€{results.winnings.length>index ? results.winnings[index].toFixed(2) : '-'}</Winnings>
                                                <Rating>{results.ratings.length>index ? results.ratings[index].toFixed(2) : '-'}%</Rating>
                                            </WinnigsAndRating>
                                        }
                                    </Fragment>
                                );
                        })}
                        {mode === 'puntabanca' && 
                            <PartiallyMatchedContainer>
                                <PartiallyMatchedRow>
                                    <PartiallyMatchedQuestion onClick={() => setIsPartiallyMatched(prevState => !prevState)}>{isPartiallyMatched ? <ArrowGoBackSVG /> :'Bancata parzialmente abbinata?'}</PartiallyMatchedQuestion>
                                    {isPartiallyMatched && <NumberInput label='Abbinata' value={matched.value} minValue={0} includeLimits={false} stepFunction={stepIntegers} onChange={(newState: NumberInputState) => setMatched(newState)}/>}
                                </PartiallyMatchedRow>
                                {
                                    isPartiallyMatched &&
                                    <>
                                        <BetInfo gridTemplateColumn={isAppMobile ? '150px 75px' : '150px 75px 75px'} marginBottom={isAppMobile ? undefined : '4px'}>
                                            <NumberInput label='Banca' value={newOdds.value} minValue={1} includeLimits={false} decimals={2} stepFunction={stepOdds} onChange={(newState: NumberInputState) => setNewOdds(newState)}/>
                                            <BetContainer boxed={true}>
                                                <Bet translateY={5}>{results.bets.length>2 && !matched.errorMessage && !newOdds.errorMessage ? '€'+results.bets[2].toFixed(2) : '€-'}</Bet>
                                                <Resp title='Responsabilità' translateY={2}>{results.resp && results.resp.length>2 && !matched.errorMessage && !newOdds.errorMessage ? '€'+results.resp[2].toFixed(2) : '€-'}</Resp>
                                            </BetContainer>
                                            {!isAppMobile &&
                                                <WinnigsAndRating flexDirection='column' boxed={false}>
                                                    <Winnings translateY={5} amount={results.winnings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.winnings[2] : 0}>€{results.winnings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.winnings[2].toFixed(2) : '-'}</Winnings>
                                                    <Rating translateY={2}>{results.ratings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.ratings[2].toFixed(2) : '-'}%</Rating>
                                                </WinnigsAndRating>
                                            }
                                        </BetInfo>
                                        {isAppMobile &&
                                            <WinnigsAndRating flexDirection='row' boxed={false} justifyContent='flex-end' padding='0 0 5px 0'>
                                                {(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : 'Rating: '}
                                                <Winnings translateY={5} amount={results.winnings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.winnings[2] : 0}>€{results.winnings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.winnings[2].toFixed(2) : '-'}</Winnings>
                                                <Rating translateY={2}>{results.ratings.length>2 && !matched.errorMessage && !newOdds.errorMessage ? results.ratings[2].toFixed(2) : '-'}%</Rating>
                                            </WinnigsAndRating>
                                        }
                                    </>
                                }
                            </PartiallyMatchedContainer>
                        }
                    </BetsContainer>
                    <AvgResult>
                        Guadagno medio
                        <WinnigsAndRating>
                            <Winnings amount={results.avgWinnings} size='l'>€{results.bets.length>0 ? results.avgWinnings.toFixed(2) : '-'}</Winnings>
                            <Rating size='l'>{(!refund.errorMessage && (isFreebet || parseFloat(refund.value)>0)) ? 'RF: ' : ''}{results.bets.length>0 ? results.avgRating.toFixed(2) : '-'}%</Rating>
                        </WinnigsAndRating>
                    </AvgResult>
                </SingleContainer>
            }
        </BaseFieldset>
    );
}

export default Single;