import colors from 'style/palette';
import styled, { css } from 'styled-components';

export const Lesson = styled.div<{isActive?: boolean, isCompleted?: boolean, tier: number}>`

display: flex;
justify-content: space-between;
align-items: center;

gap: 10px;

width: 100%;
height: 40px;

/* padding: 0 25px 0 0; */
padding: ${({tier}) => `0 30px 0 ${tier * 15}px`};

font-weight: 500;
font-size: 13px;
color: ${colors.white};

border-right: 1px solid #76838D;
border-bottom: 1px solid #76838D;
border-left: 1px solid #76838D;

background: #3C4B57;

cursor: pointer;

svg {
    flex-shrink: 0;
}

    ${({isCompleted}) => isCompleted && 
        css`
            color: ${colors.green};
        `
    }

    ${({isActive}) => isActive && 
        css`
            color: ${colors.primaryColor};
        `
    }
`;

export const TypeLessonBox = styled.div<{ isCompleted: boolean | undefined}>`

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 33px;
    min-width: 33px;

    padding: 0;

    background: rgba(255, 255, 255, 0.1);
    border-right: 1px solid ${colors.black020};

    > svg {
        max-width: 13px;
                
    }

    ${({isCompleted}) => isCompleted &&
    
        css`
            color: ${colors.green};

            svg path {
                fill: ${colors.green};
            }
        `
    }
`;

export const StateLessonBox = styled.div<{ isCompleted?: boolean | undefined, isActive?: boolean}>`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    /* margin: 0 0 0 25px; */

    gap: 10px;

    background: transparent;

    > svg {
        max-width: 20px;
                
    }

    ${({isCompleted}) => isCompleted &&
    
        css`
            color: ${colors.green};

            > svg path {
                fill: ${colors.green};
            }
        `
    }

    ${({isActive}) => isActive &&
    
        css`
            color: ${colors.primaryColor};

            > svg path {
                fill: ${colors.primaryColor};
            }
        `
    }

`;

export const NewBadge = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2px 6.5px;

    box-shadow: 
        0px 2px 1px -1px ${colors.black020},
        0px 1px 1px ${colors.black014},
        0px 1px 3px ${colors.black012};
    border-radius: 64px;
    background: ${colors.red};

    white-space: nowrap;
`;

export const PublicPageDot = styled.p`

    margin: 0 10px 0 0;
    padding: 0px;
`;