import { FC, useEffect, useState } from 'react';

// hooks
import useAppSelector from 'hooks/useAppSelector';

// components
import BaseModal from 'components/ui/BaseModal';
import { BaseCard } from 'style/wrappers';

// types
import { UserLevelModalProps } from './UserLevelModal.component.d';

// assets
import { ReactComponent as CloseSVG } from './assets/close.svg';
import { ReactComponent as LevelsSVG } from './assets/levels.svg';
import { ReactComponent as ArrowSVG } from './assets/arrow.svg';
import { ReactComponent as ComingSoonSVG } from '../UserPathCard/assets/coming-soon.svg';

// style
import { Row } from 'style/layout';
import {
    ButtonBox,
    ButtonText,
    CardContent,
    CloseButton,
    LevelHeader,
    LevelLastLine,
    LevelLine,
    LevelMidLine,
    LevelTable,
    LevelTableColumn,
    LevelTableColumn2,
    LevelTableColumn3,
    LevelTableHeadColumn1,
    LevelTableHeadColumn2,
    LevelTableHeadColumn3,
    LevelText,
    LevelTitle,
    LevelTitleLine,
    LinkLevel,
    TableBox,
    TitleSmallText,

} from './style/UserLevelModal.style';
import { callGET } from 'network/network';
import UserLevelComponent from '../UserLevelComponent/UserLevelComponent.component';


const UserLevelModal: FC<UserLevelModalProps> = ({ onCloseButtonModal, onModalButtonClickHandler }) => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState<boolean>(false);

    const closeButtonHandler = () => setIsCloseButtonClicked(true);

    const [isLevel, setIsLevel] = useState <number> (0);
    const [userVipPathLevel, setUserVipPathLevel] = useState(null);
    const [pathLevel, setPathLevel] = useState<number>(1);


    const getUserVipPathLevel = async () => {

        try {
            const response = await callGET('v1/self/vippathlevel', true);
            setUserVipPathLevel(response);

            if (response && response.pathLevel) {
                setPathLevel(response.pathLevel);
            }
            
            return response.pathLevel

        } catch (error: any) {
            if (error.message.includes('403')) throw new Error ('403');
            throw error;
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const pathLevel = await getUserVipPathLevel();
                setIsLevel(pathLevel);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);
    
    return (
        <BaseModal 
            onClose={onCloseButtonModal}
            isCloseButtonClicked={isCloseButtonClicked}
            isBackdropClickActive
        >
            {!isAppMobile && (
                <Row 
                    padding='0 21px 12px 0'
                    crossAxis='center'
                    mainAxis='flex-end'
                >
                    <CloseButton onClick={closeButtonHandler}>
                        <CloseSVG />
                    </CloseButton>
                </Row>
            )}

            <BaseCard 
                flavor='outlined'
                isModal
            >
                <CardContent>
                    {isAppMobile && (
                        <Row 
                            crossAxis='center'
                            mainAxis='flex-end'
                        >
                            <CloseButton onClick={closeButtonHandler}>
                                <CloseSVG />
                            </CloseButton>
                        </Row>
                    )}
                    
                    <LevelHeader>
                        <UserLevelComponent isModal={true}/>
                        
                        <LevelTitle>IL NOSTRO PERCORSO È STRUTTURATO IN 9 LIVELLI</LevelTitle>
                    </LevelHeader>

                    <LevelText>
                        Per ogni Livello è presente un’Area Riservata con contenuti esclusivi che ti permettono di raggiungere 
                        velocemente il livello successivo.
                    </LevelText>

                    <LevelText>
                        Per raggiungere i livelli dal 4 in avanti, devi aver condiviso i tuoi risultati degli ultimi 3 mesi con 
                        l'apposita funzione del sito, accessibile da Il Mio Profilo {">"} I Miei Guadagni.
                    </LevelText>

                    <TableBox>
                        <LevelTable>
                            <LevelTitleLine>
                                {/* <ArrowSVG/> */}
                                <LevelTableHeadColumn1>LIVELLO</LevelTableHeadColumn1>
                                <LevelTableHeadColumn2>REQUISITI</LevelTableHeadColumn2>
                                <LevelTableHeadColumn3>
                                    GUADAGNO MEDIO 
                                    <TitleSmallText>ultimi 3 Mesi</TitleSmallText>
                                </LevelTableHeadColumn3>
                            </LevelTitleLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 1 ? 1 : 0}>1</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 1 ? 1 : 0}>Iscritto al sito</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 1 ? 1 : 0}>-</LevelTableColumn3>
                            </LevelLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 2 ? 2 : 0}>2</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 2 ? 2 : 0}>Bonus Sicuro</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 2 ? 2 : 0}>-</LevelTableColumn3>
                            </LevelLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 3 ? 3 : 0}>3</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 3 ? 3 : 0}>Bonus Automatici</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 3 ? 3 : 0}>-</LevelTableColumn3>
                            </LevelLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 4 ? 4 : 0}>4</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 4 ? 4 : 0}>Multi Bonus</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 4 ? 4 : 0}>500 €</LevelTableColumn3>
                            </LevelLine>

                            <LevelMidLine>
                                <LevelTableColumn isLevel={isLevel === 5 ? 5 : 0}>5</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 5 ? 5 : 0}>Secret Bonus</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 5 ? 5 : 0}>1.000 €</LevelTableColumn3>
                            </LevelMidLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 6 ? 6 : 0}>6</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 6 ? 6 : 0}>Secret Bonus</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 6 ? 6 : 0}>1.500 €</LevelTableColumn3>
                            </LevelLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 7 ? 7 : 0}>7</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 7 ? 7 : 0}>Ca$h Academy</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 7 ? 7 : 0}>2.500 €</LevelTableColumn3>
                            </LevelLine>

                            <LevelLine>
                                <LevelTableColumn isLevel={isLevel === 8 ? 8 : 0}>8</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 8 ? 8 : 0}>Ricchi si Diventa</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 8 ? 8 : 0}>5.000 €</LevelTableColumn3>
                            </LevelLine>

                            <LevelLastLine>
                                <LevelTableColumn isLevel={isLevel === 9 ? 9 : 0}>9</LevelTableColumn>
                                <LevelTableColumn2 isLevel={isLevel === 9 ? 9 : 0}>IMMORTALI</LevelTableColumn2>
                                <LevelTableColumn3 isLevel={isLevel === 9 ? 9 : 0}>10.000 €</LevelTableColumn3>
                            </LevelLastLine>
                        </LevelTable>
                    </TableBox> 
                </CardContent>
            </BaseCard>
        </BaseModal>
    );
};

export default UserLevelModal;
