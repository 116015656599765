import styled, { css } from 'styled-components';

// types
import { PasswordFormProps } from './FormEditPassword.style.d';

//style
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const Form = styled.form`

    width: 100%;

    margin: 10px 0 0;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    row-gap: 20px;
    column-gap: 10px;
    flex-direction: column;

    @media ${devices.desktop} {

        align-items: center;
        flex-direction: row;
        row-gap: 30px;
    }
`;

export const PasswordForm = styled(Form)<PasswordFormProps>`

    margin: 40px 0 0;
 
    align-items: center;
    flex-direction: row;
    
    ${({isUserModifyingInput}) => isUserModifyingInput && 

        css`
            align-items: flex-end;
            flex-direction: column;
        `
    }
`;

export const PlaceholderPasswordText =  styled.p`

    width: 100%;

    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;

    color: ${colors.white};
    white-space: nowrap;
`;
