import { useEffect, useState } from 'react';

// routing
import { 
    useLocation,
    useNavigate,
    useSearchParams
} from 'react-router-dom';

//redux
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { 
    cancelProductPaypalPayment,
    cancelSubscriptionPaypalPayment,
    checkPaypalPayment,
    checkPaypalSubscriptionPayment
} from 'services/payments.services';

import {
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
    setItemToLocalStorage
} from 'utils/localStorage';

// components
import LoadingMask from 'components/ui/LoadingMask';

//style
import { Page } from './style/Payments.style';
import useAppSelector from 'hooks/useAppSelector';
import { getUserData } from 'services/user.services';


const Payments = () => {

    const { tokenID } = useAppSelector(state => state.user);
    const [delay, setDelay] = useState<number>(3000);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    // const [params, setParams] = useSearchParams();

    const { pathname } = location;

    const confirmCoursePurchase = async () => {
        
        
        const pendingTransactionId = getItemFromLocalStorage('Pending transaction ID');
        if (pendingTransactionId === null) return removeItemFromLocalStorage('Pending transaction ID');    
        
        try {
            
            const response = await checkPaypalPayment(pendingTransactionId, isUserLoggedIn);
            
            if ((response.campaign !== null) && (response.campaign.id === 1)) navigate('/lp/mk/thanks-and-login');
            if ((response.campaign !== null) && (response.campaign.id === 3)) navigate('/lp/mk/thanks-and-login-3');
            if (!isUserLoggedIn) navigate('/fallback-page/Thanks-and-login');

            const pendingThematicBroadcastExists = 'newThematicCall' in localStorage;
            const pendingPastThematicBroadcastExists = 'PastBroadcast' in localStorage;
            const pendinAutomaticExists = 'Automatic income' in localStorage;
            const pendinOfferExists = 'Offerta' in localStorage;
            const pendinHighwayExists = 'Autostrada' in localStorage;
            const pendinAssistantExists = 'Assistente' in localStorage;
            const pendingCreditPackExists = 'CREDIT_PACK' in localStorage;
            const pendingLessonExists = 'New Coach Lesson' in localStorage;
            const pendingHelpExists = 'New Help' in localStorage;
            const pendingPurchaseExists = ('Pending purchase ID' in localStorage) && 
                !pendingLessonExists && 
                !pendingHelpExists && 
                !pendinHighwayExists && 
                !pendingCreditPackExists && 
                !pendinOfferExists &&
                !pendinAutomaticExists;

            if (isUserLoggedIn && 
                (
                    pendingCreditPackExists || 
                    pendingLessonExists || 
                    pendinHighwayExists || 
                    pendinAssistantExists ||
                    pendingHelpExists || 
                    pendinOfferExists ||
                    pendinAutomaticExists
                )
            ) {

                removeItemFromLocalStorage('Pending purchase ID');
                removeItemFromLocalStorage('Pending transaction ID');
                navigate('/fallback-page/Thanks-for-buying');
                return;
            }

            if (isUserLoggedIn && pendingPurchaseExists && !pendingThematicBroadcastExists && !pendingPastThematicBroadcastExists) {

                removeItemFromLocalStorage('Pending purchase ID');
                removeItemFromLocalStorage('Pending transaction ID');
                setItemToLocalStorage('New Course', 'true');
                navigate('/fallback-page/Thanks-for-buying');
            }

            if (isUserLoggedIn && pendingThematicBroadcastExists) {

                removeItemFromLocalStorage('Pending purchase ID');
                removeItemFromLocalStorage('Pending transaction ID');
                setItemToLocalStorage('newThematicCall', 'true');
                navigate('/fallback-page/Thanks-for-buying');
            }

            if (isUserLoggedIn && pendingPastThematicBroadcastExists) {

                removeItemFromLocalStorage('Pending purchase ID');
                removeItemFromLocalStorage('Pending transaction ID');
                setItemToLocalStorage('PastBroadcast', 'true');
                navigate('/fallback-page/Thanks-for-buying');
            }

            if (isUserLoggedIn && pendingPastThematicBroadcastExists) {

                removeItemFromLocalStorage('Pending purchase ID');
                removeItemFromLocalStorage('Pending transaction ID');
                navigate('/fallback-page/Thanks-for-buying');
            }

        } catch (error: any) {
            if (delay !== 7000) {
                if (delay === 3000) setDelay(5000);
                if (delay === 5000) setDelay(7000);
                return;
            };
            const isBackendWaitingPaypal: boolean = (error.message === 'Waiting for paypal authorization');
            if ((delay === 7000) && isBackendWaitingPaypal) {
                setItemToLocalStorage('Backend is waiting from', `${new Date().getTime()}`);
                navigate('/fallback-page/check-payment');
                return;
            }; 
            if ((delay === 7000) && !isBackendWaitingPaypal && (error.message !== '403')) return navigate('/fallback-page/assign-error');
            console.warn(error);
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
        }
    };
    
    const confirmServicePurchase = async () => {
        
        const subscriptionId = getItemFromLocalStorage('Pending subscription ID');
        
        if (subscriptionId === null) return;
        
        console.log("SUB ID", subscriptionId);
        try {
            
            await checkPaypalSubscriptionPayment(subscriptionId, isUserLoggedIn);

            if (!isUserLoggedIn) return navigate("/fallback-page/Thanks-and-login"); 

            const pendingSubscriptionExists = 'Pending subscription ID' in localStorage

            if (isUserLoggedIn && pendingSubscriptionExists) {
                removeItemFromLocalStorage('Pending subscription ID');
                setItemToLocalStorage('New Service', 'true');
                navigate('/fallback-page/Thanks-for-buying');
            } 

        } catch (error: any) {
            if (error.message === '403') return navigate('/fallback-page/permission-denied');
            if (delay !== 7000) {
                if (delay === 3000) setDelay(5000);
                if (delay === 5000) setDelay(7000);
            };
            if (delay === 7000) navigate('/fallback-page/assign-error');
            console.warn(error);
        }
    };


    const productCancelOrder = async () => {
        const pendingPurchaseId = getItemFromLocalStorage('Pending purchase ID');

        if (pendingPurchaseId === null) return removeItemFromLocalStorage('Pending purchase ID');

        try {
          await cancelProductPaypalPayment(pendingPurchaseId, isUserLoggedIn);
          removeItemFromLocalStorage('Pending purchase ID');
          removeItemFromLocalStorage('Pending transaction ID');
          dispatch(getUserData());
          navigate('/reservedArea/');
  
          const snackbarData: SnackbarOptions = {
            type: 'success',
            message: "Il tuo ordine è stato cancellato con Successo!"
          };
          
          dispatch(addSnackbar(snackbarData));
        } catch (error) {
          console.warn(error);
          navigate('/reservedArea/');
        }
    };

    const subscriptionCancelOrder = async () => {
        const pendingSubscriptionId = getItemFromLocalStorage('Pending subscription ID');

        if (pendingSubscriptionId === null) return removeItemFromLocalStorage('Pending subscription ID');

        try {
          await cancelSubscriptionPaypalPayment(pendingSubscriptionId, isUserLoggedIn);
          removeItemFromLocalStorage('Pending subscription ID');
          dispatch(getUserData());
          navigate('/reservedArea/');
  
          const snackbarData: SnackbarOptions = {
            type: 'success',
            message: "Il tuo ordine è stato cancellato con Successo!"
          };
          
          dispatch(addSnackbar(snackbarData));
        } catch (error) {
            console.warn(error);
            navigate('/reservedArea/');
        }
    };

    useEffect(() => {
       
        if (tokenID === undefined) return;

        if (pathname.includes('pp/neworder')) { 
            setTimeout(() => {
                confirmCoursePurchase();
            }, delay);
        };
        if (pathname.includes('pp/newsubscription')) {
            setTimeout(() => {
                confirmServicePurchase();
            }, delay);
        };
        if (pathname.includes('pp/newsubscription')) {
            setTimeout(() => {
                confirmServicePurchase();
            }, delay);
        };
        // if (pathname.includes('pp/newbroadcast')) {
        //     setTimeout(() => {
        //         confirmBroadcastPurchase();
        //     }, delay);
        // };
        if (pathname.includes('pp/cancelorder')) {
            productCancelOrder();
          };
        if (pathname.includes('pp/cancelsubscription')) {
            subscriptionCancelOrder();
        };

        const goToHome = setTimeout(() => {
            navigate('/fallback-page/assign-error');
        }, 20000);

        return () => clearTimeout(goToHome);
    }, [tokenID, delay]);
    
    const isUserLoggedIn = !!tokenID;
    
    return (
        <Page>
            <LoadingMask
                isCenter
                paddingTop='400px'
                size='big'
            />
        </Page>
    )
};

export default Payments;
