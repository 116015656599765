import { FC, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// routing
import {
	Outlet,
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';

// components
import FacebookLoginButton from 'components/ui/FacebookLoginButton';

// assets
import { ReactComponent as TitleLogoSVG } from './assets/page-title-logo.svg';
import { ReactComponent as MBOrizontalIconSVG } from './assets/logo-mb.svg';
import { ReactComponent as VDRIconSVG } from './assets/VDR.svg';
import { ReactComponent as TCRIconSVG } from './assets/logo-tutorialcrypto.svg';

// style
import { BaseCard } from 'style/wrappers';
import {
	Page,
	PageTitle,
	Content,
	SocialButtonsRow,
	NewUserLink,
} from './style/LoginPage.style';
import useAppSelector from 'hooks/useAppSelector';
import {
	getItemFromLocalStorage,
	setItemToLocalStorage,
} from 'utils/localStorage';
import store from 'redux/store';

/**
 * Login Page
 *
 * @author Alessio Grassi
 *
 * @returns JSX
 */

const LoginPage: FC<{}> = () => {
	const { tokenID, isUserDisplayingFromAppMobile } = useAppSelector(
		(state) => state.user
	);
	const isUserLoggedIn = !!tokenID;
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectBrand = searchParams.get('brand');

	const facebookSignupHandler = () => {
		localStorage.setItem('fromFBsignup', 'true');
		Auth.federatedSignIn({
			provider: CognitoHostedUIIdentityProvider.Facebook,
		});
	};

    useEffect(() => {
        const currentUrl = window.location.href;
        const searchParams = new URLSearchParams(new URL(currentUrl).search);
        if (searchParams.get('supporto') === 'true') {
          localStorage.setItem('redirectToSupport', 'true');
        }
    }, []);
    
	useEffect(() => {
		if (isUserLoggedIn) { 
			let path = getItemFromLocalStorage('afterLoginRedirectPath');
			if (redirectBrand) path?.includes('?') ? path += '&brand='+redirectBrand : path += '?brand='+redirectBrand;
			setItemToLocalStorage('afterLoginRedirectPath', '');
			navigate(path === null || path === '' ? '/reservedArea/' : path);
		}
	}, [isUserLoggedIn]);

	useEffect(() => {
		console.log('FROM APP MOB?', isUserDisplayingFromAppMobile);
	}, [isUserDisplayingFromAppMobile]);

	useEffect(() => {
		if (redirectPath)
			setItemToLocalStorage('afterLoginRedirectPath', redirectPath);
	}, [redirectPath]);

	const isLDL = store.getState().ui.brand?.id === 1;
    const isTCR = store.getState().ui.brand?.id === 2;
    const isVDR = store.getState().ui.brand?.id === 3;
    const isMB = store.getState().ui.brand?.id === 4;


	return (
		<Page>
			{isLDL &&
				<>
					<TitleLogoSVG />

					<PageTitle>Accedi a Liberi dal Lavoro!</PageTitle>
				</>
			}

			{isVDR &&
				<>
					<VDRIconSVG />

					<PageTitle>Accedi alla Voce Della Ricchezza!</PageTitle>
				</>
			}

			{isTCR &&
				<>
					<TCRIconSVG />

					<PageTitle>Accedi a Tutorial Crypto!</PageTitle>
				</>
			}

			{isMB &&
				<>
					<MBOrizontalIconSVG />

					<PageTitle>Accedi a Missione Benessere!</PageTitle>
				</>
			}

			<BaseCard
				flavor='simpleGrey'
				maxWidth='500px'
			>
				<Content>
					<Outlet />

					{!isUserDisplayingFromAppMobile && (
						<SocialButtonsRow>
							<FacebookLoginButton
								onClick={facebookSignupHandler}
							>
								Accedi con Facebook
							</FacebookLoginButton>
						</SocialButtonsRow>
					)}

					<NewUserLink>
						<Link to='/sign-up'>Sei nuovo? Crea un account</Link>
					</NewUserLink>
				</Content>
			</BaseCard>
		</Page>
	);
};

export default LoginPage;
