import styled from 'styled-components';
import devices from 'style/breakpoints';
import colors from 'style/palette/palette';


export const CardContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    width: 100%;
    max-width: 900px;
    height: 100%;

    padding: 30px 30px 100px 30px;

    background-color: ${colors.veryDarkGrey};
    border-radius: 15px;

    @media ${devices.tablet} {
        padding: 35px 40px 50px;
    }
`;

export const IconBox = styled.div`

    margin: 30px 0 0;
    text-align: center;

    @media ${devices.tablet} {
        margin: 0;
    }
`;

export const CloseButton = styled.button`

    margin: 0;
    padding: 0;

    background-color: ${colors.transparent};
    border: none;

    cursor: pointer;
`;

export const Title = styled.h2`
    
    width: 100%;
    margin: 30px 0 0;
    padding: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    color: ${colors.primaryColor};

    text-align: center;
    text-transform: uppercase;
`;

export const TextContent = styled.p`
    
    margin: 30px 0 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;

    color: ${colors.white};

    text-align: center;
`;

export const NotesBox = styled.p`

    width: 100%;
    min-height: 200px;

    margin: 30px 0 0;
    padding: 25px;

    border: 1px solid ${colors.white023};
    border-radius: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    
    color: ${colors.white070};
`;
